import React, { memo, useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import { Helmet } from 'react-helmet';
import {useHistory, useParams} from 'react-router-dom';
import {Box, Button, Container, Skeleton, Grid} from '@sprnova/nebula';
import { Action } from 'api/accessControl/Action';
import { PricingTier } from 'api/crudGraphQL/strategies/types';
import { useGetTasksQuery } from 'api/crudGraphQL/tasks/getTasks';
import { taskProjection } from 'api/crudGraphQL/tasks/taskProjection';
import { useUpdateTaskMutation } from 'api/crudGraphQL/tasks/updateTask';
import { PageHero } from 'layouts/components';
import { isEmpty } from 'lodash';
import omit from 'lodash/omit';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import {convertToAscii, useMixpanelTrack} from 'utils';
import { useAccessControl } from 'features/global';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import { convertSpecifiedValuesToBase64 } from '../../../../api/utils';
import { Spin, AccessControl, notification } from '../../../../components';
import Alert from '../../../../components/Alert/Alert';
import {
  Resource,
  Role,
  useDepartments,
} from '../../../entitiesRedux';
import {DEFINE_SCROLL_MARGIN_TOP} from '../../../strategies/constants';
import {TaskIdRequiredType} from '../AdditionalStrategy/AdditionalStrategy';
import { PricingTypeEnum } from '../constants';
import {EditAdditionalStrategy} from '../EditAdditionalStrategy';
import EditContractDetails from '../EditContractDetails/EditContractDetails';
import EditStrategyOverview from '../EditStrategyOverview/EditStrategyOverview';
import NavigationBox from '../NavigationBox/NavigationBox';
import { FormDataType, ActionType } from '../packageStrategyTypes';
import { getPricingTiersWithFloatValues, validateFormData } from '../packageStrategyUtil';
import { formatInitialPackageStrategyMultiplePricingTiers, formatInitialPackageStrategySinglePricingTier } from './utils';
import css from '../package-strategies.module.scss';


const EditStrategyPackagePage = (): JSX.Element => {
  const { can } = useAccessControl();
  const canEditLibraryStrategyPackage = can(Action.update, Resource.libraryStrategy);

  const [pricingVersionQueryParam,] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );
  const { id } = useParams<{ [x: string]: string }>();

  const { data: tasks, isFetching, isLoading } = useGetTasksQuery({
    id: Number(id),
    projection: taskProjection,
    pricing_version: pricingVersionQueryParam,
  });
  // we need to return the first task because the query returns an array of tasks and we don't have a singular query yet.
  const task = useMemo(() => {
    if (tasks) {
      return tasks[0];
    }
  }, [tasks]);

  const mixpanel = useMixpanelTrack();

  const { refresh } = useDepartments();
  const history = useHistory();
  const [formData, setFormData] = useState<Partial<FormDataType>>({});
  const [formDataCached, setFormDataCached] = useState<Partial<FormDataType>>({}); // state to cache the form data, this will never be reset even if pricing type changes unlike formData state.
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [editPackageStrategyMutation, { isLoading: isEditTask }] = useUpdateTaskMutation();

  const reducer = (state: any, action: any): Partial<FormDataType> => {
    switch (action.type) {
      case ActionType.ON_CHANGE_STRATEGY_OVERVIEW_DATA: {
        const newValue = action.payload;
        setFormData((previousValue) => ({...previousValue, ...newValue}));
        setFormDataCached((previousValue) => ({...previousValue, ...newValue}));
        return newValue;
      }
      case ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS: {
        const newValue = action.payload;
        // We want to 'cache' the data when the pricing type is equal to the initial value
        if (task?.pricing_type?.slug === formData.pricing_type_slug) {
          setFormDataCached((previousValue) => ({...previousValue, pricing_tiers: newValue }));
        }
        setFormData((previousValue) => ({...previousValue, pricing_tiers: newValue }));
        return newValue;
      }
      case ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_SINGLE_TIER: {
        const newValue = action.payload;
        // We want to 'cache' the data when the pricing type is equal to the initial value
        if (task?.pricing_type?.slug === formData.pricing_type_slug) {
          setFormDataCached((previousValue) => ({...previousValue, pricing_tiers: [{...previousValue?.pricing_tiers?.[0], ...newValue}] }));
        }
        setFormData((previousValue) => ({...previousValue, pricing_tiers: [{...previousValue?.pricing_tiers?.[0], ...newValue}] }));
        return newValue;
      }
      case ActionType.ON_CHANGE_CONTRACT_DETAILS_DATA: {
        const newValue = action.payload;
        // We want to 'cache' the data when the pricing type is equal to the initial value
        if (task?.pricing_type?.slug === formData.pricing_type_slug) {
          setFormDataCached((previousValue) => ({ ...previousValue, ...newValue }));
        }
        setFormData((previousValue) => ({ ...previousValue, ...newValue }));
        return newValue;
      }
      case ActionType.ON_CHANGE_PRICING_TYPE: {
        const newValue = action.payload;
        /**
         * A user can change pricing type but then set it back to the initial value.
         * If that happens, we set the form data with the cached data.
         */
        if ((task?.pricing_type?.slug === newValue.pricing_type_slug) && !isEmpty(formDataCached)) {
          setFormData(formDataCached);
        } else {
          setFormData((previousValue) => ({...previousValue, ...newValue, pricing_tiers: [], snippet_summary: '' }));
        }
        return newValue;
      }
      case ActionType.ON_CHANGE_ADDITIONAL_STRATEGY_DATA: {
        const newValue = action.payload;
        const childrenTasks = action.payload.map((task: TaskIdRequiredType) => {
          return (
            { id: task.id }
          );
        });
        setFormData((previousValue) => ({...previousValue, children: childrenTasks }));
        setFormDataCached((previousValue) => ({...previousValue, children: childrenTasks }));
        return newValue;
      }
      default: {
        return state;
      }
    }

  };
  const [,dispatch] = useReducer(reducer, {});

  const mixpanelTracking = useCallback((): void => {
    const options = {
      name: formData.name,
      service_id: formData.service_id,
      strategy_frequency_id: formData.strategy_frequency_id,
      pricing_tiers: formData.pricing_tiers,
      pricing_type_id: formData.pricing_type_id,
      children: formData?.children, // required when there are additional strategies to create Parent / Children task relations
      pricing_version: pricingVersionQueryParam,
    };
    const eventTitle = 'Library Strategy edit';
    mixpanel(eventTitle, options);
  }, [formData?.children, formData.name, formData.pricing_tiers, formData.pricing_type_id, formData.service_id, formData.strategy_frequency_id, mixpanel, pricingVersionQueryParam]);

  const handleSubmitForm = useCallback(async (id: number | undefined) => {
    setIsSubmitted(true);
    // Remove non-required fields from the form data
    const formDataWithoutNonRequired = { ...omit(formData, 'snippet_summary')};
    const formErrorMessage = validateFormData(formDataWithoutNonRequired);
    if(id && formData.name && formData.service_id && formErrorMessage == null) {
      try {
        if(formData?.pricing_tiers !== undefined && formData?.pricing_tiers?.length > 0) {
          formData?.pricing_tiers.map((pricingTierItem: PricingTier) => {
            if (pricingTierItem.snippet) {
              const formattedSnippet = convertToAscii(pricingTierItem.snippet);
              if (pricingTierItem.snippet !== formattedSnippet) {
                pricingTierItem.snippet = formattedSnippet;
              }
              convertSpecifiedValuesToBase64(pricingTierItem, ['snippet']);
            }
          });
        }

        await editPackageStrategyMutation({
          id,
          name: formData.name,
          service_id: formData.service_id,
          pricing_type_id: formData.pricing_type_id,
          snippet_summary: formData.snippet_summary,
          strategy_frequency_id: formData?.strategy_frequency_id,
          pricing_tiers: formData.pricing_tiers ? getPricingTiersWithFloatValues(formData.pricing_tiers) : [],
          children: formData?.children, // required when there are additional strategies to create Parent / Children task relations
          pricing_version: pricingVersionQueryParam,
          projection: {
            id: true,
            name: true,
          }
        }).unwrap();

        mixpanelTracking();

        notification.success({
          message: 'Package strategy edited successfully',
        });
        setIsSubmitted(false);

        history.replace('/library/tasks?pricingVersion=package');

      } catch (e) {
        console.error('Error editing package strategy', e);
        notification.error({
          message: 'Error editing package strategy',
        });
      }
    } else {
      notification.error({
        message: formErrorMessage
      });
    }
  }, [editPackageStrategyMutation, formData, history, mixpanelTracking, pricingVersionQueryParam]);

  /**
   * Function to initialize the pricing tiers when the pricing type is loaded
   * 2 cases:
   *  - 1 pricing tier only
   *  - multiple pricing tiers (Pricing type = Spend or Quantity)
   */
  const initializePricingTiers = useCallback(async () => {
    if (task && task?.pricing_tiers && task?.pricing_type?.slug && task.pricing_tiers?.length >= 1) {
      if (task.pricing_type.slug === PricingTypeEnum.Spend || task.pricing_type.slug === PricingTypeEnum.Quantity) {
        const pricingTiers = formatInitialPackageStrategyMultiplePricingTiers(task.pricing_type.slug, task.pricing_tiers);
        dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS, payload: pricingTiers });
      } else {
        const pricingTier = formatInitialPackageStrategySinglePricingTier(task?.pricing_type?.slug, task.pricing_tiers);
        dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_SINGLE_TIER, payload: pricingTier });
      }
    }
  }, [task]);

  useEffect(() => {
    if (task && !formData.service_id && !formData.strategy_frequency_id && !formData.name && !formData.pricing_type_id && !formData.pricing_type_slug && !formData.snippet_summary) {
      dispatch({ type: ActionType.ON_CHANGE_STRATEGY_OVERVIEW_DATA, payload: { name: task?.name, service_id: task?.service?.id, strategy_frequency_id: task?.strategy_frequency?.id } });
      initializePricingTiers();
      dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_DATA, payload: { snippet_summary: task?.snippet_summary } });

      // Need to initialize the 2 forms data with the pricing type otherwise the code won't reach some if statement in the reducer
      setFormData((previousValue) => ({...previousValue, pricing_type_id: task?.pricing_type?.id, pricing_type_slug: task.pricing_type?.slug }));
      setFormDataCached((previousValue) => ({...previousValue, pricing_type_id: task?.pricing_type?.id, pricing_type_slug: task.pricing_type?.slug }));

    }
  }, [formData.name, formData.pricing_type_id, formData.pricing_type_slug, formData.service_id, formData.snippet_summary, formData.strategy_frequency_id, initializePricingTiers, task]);

  /**
   * Render the content of the page
   */
  const renderContent = useCallback((): JSX.Element => {
    if (!task && !isFetching) {
      return (
        <Alert type="error" message="Task could not be found" showIcon />
      );
    }
    if (isLoading) {
      return (
        <Skeleton height={120} />
      );
    }
    return (
      <>
        <Grid container spacing={2.4} columns={15}>
          <Grid item xs={15} lg={3}>
            <Box sx={{position: 'sticky', top: '96px'}}>
              <NavigationBox />
            </Box>
          </Grid>
          <Grid item xs={15} lg={12}>
            <Box id={'strategy-overview-navigation'} sx={DEFINE_SCROLL_MARGIN_TOP}>
              <EditStrategyOverview
                task={task}
                isSubmitted={isSubmitted}
                dispatch={dispatch}
                formData={formData}
              />
            </Box>
            <Box id={'contract-details-navigation'} sx={DEFINE_SCROLL_MARGIN_TOP} >
              <EditContractDetails
                task={task}
                isSubmitted={isSubmitted}
                dispatch={dispatch}
                formData={formData}
                formDataCached={formDataCached}
              />
            </Box>
            <Box id={'combined-strategies-navigation'} sx={DEFINE_SCROLL_MARGIN_TOP} >
              <EditAdditionalStrategy
                task={task}
                isSubmitted={isSubmitted}
                dispatch={dispatch}
                formData={formData}
              />
            </Box>
            <Grid mt={3.2} sx={{ textAlign: 'end' }}>
              <Button
                onClick={(): void => history.replace(`/library/package-strategies/${task?.id}?${pricingVersionString}=${PricingVersion.PACKAGE}`)}
                variant="secondary"
                size="large"
                sx={{ mr: '16px' }}
                className={css.button}
                disabled={isEditTask}
              >
                Cancel
              </Button>
              <Button
                onClick={(): Promise<void> => handleSubmitForm(task?.id)}
                variant="primary"
                size="large"
                className={css.button}
                disabled={isEditTask}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }, [formData, formDataCached, handleSubmitForm, history, isEditTask, isFetching, isLoading, isSubmitted, task]);

  const pageTitle = useMemo(() => {
    if (!canEditLibraryStrategyPackage) {
      return 'Edit Strategy (Package)';
    }
    return task?.name ? `Edit ${task?.name} Strategy (Package)` : 'Edit Strategy (Package)';
  }, [canEditLibraryStrategyPackage, task?.name]);

  const isFetchingSpin = useMemo(() => {
    if (isFetching) return <Spin loading={isFetching} />;
  }, [isFetching]);

  const content = useMemo(() => {
    return (
      <AccessControl
        action={[Action.update]}
        resource={Resource.libraryStrategy}
        warning='You are not authorized to edit strategies.'
      >
        {renderContent()}
      </AccessControl>
    );
  }, [renderContent]);

  return (
    <>
      <Helmet>
        <title>
          Edit Strategy Package
        </title>
      </Helmet>
      <PageHero
        title={pageTitle}
        description="Edit Strategy (Package)"
      />
      {isFetchingSpin}
      <Container hasVerticalPadding>
        {content}
      </Container>
    </>
  );
};

export default memo(EditStrategyPackagePage);
