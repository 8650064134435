import React, { createContext, useContext } from 'react';
import { noop } from 'lodash';

type ClientRefetchContextType = {
  refetch: () => void;
};

export const ClientRefetchContext = createContext<ClientRefetchContextType>({
  refetch: () => noop, // default implementation
});

export const useRefetch = () => useContext(ClientRefetchContext);

