
interface ResultActionError {
  response: {
    errors: ResponseErrors[];
    data: {
      [key: string]: null;
    }
  }
}

interface ResponseErrors {
  debugMessage: string;
  message: string;
}

/**
 * Parse graphql error message using redux result action.
 * @param errorMessage - resultAction?.error?.message
 * @returns - proper error message
 */
export const parseResultActionError = (errorMessage?: string): string => {
  const errorStringFormatted = errorMessage?.substring(errorMessage?.indexOf(':') + 1);

  if (errorStringFormatted) {
    const errorObject: ResultActionError = JSON.parse(errorStringFormatted);

    if (errorObject?.response?.errors.length === 1) {
      if (errorObject?.response?.errors[0]?.debugMessage) {
        const errorMessage = errorObject?.response?.errors[0]?.debugMessage;
        return errorMessage;
      }
      else {
        const errorMessage = errorObject?.response?.errors[0]?.message;
        return errorMessage;
      }
    }
  }

  return 'An unknown error occurred. Please try again or contact us through intercom.';
};
