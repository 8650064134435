import React, { memo, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Box, Chip, Grid, KeyValue, Tooltip } from '@sprnova/nebula';
import { getGuaranteedTermCommission } from 'utils/blueprints';
import { addMonthsToDate, subtractDaysToDate } from 'utils/date/calculateNewDate';
import capitalize from 'utils/format/capitalize';
import formatCurrency from 'utils/format/formatCurrency';
import formatPartnerReferralName from 'utils/format/formatPartnerReferralName';
import { formatNumber } from 'features/audits/utils';
import { Strategy } from 'features/entitiesRedux/models/strategy';
import InfoTooltipBlueprintVabo from '../../../../../InfoTooltipBlueprintVabo';
import { ContractDetailsFieldNames } from '../constants';
import cssShared from '../ContractDetailsComponentsShared.module.scss';

type ContractDetailsReadProps = {
  blueprint: Strategy
}

export type ContractDetailsFormType = {
  audit_id: number | null | undefined,
  commission_flat_fee: number | string | undefined,
  type: string,
  salesforce_is_primary: boolean,
  length: number,
  pillar_id: number,
  lead_source_id: number,
  lead_source_employee_id: number | undefined,
  partner_id: number | undefined,
  date: string,
  guaranteed_term: number | undefined,
  author_id: number,
  ad_spend: 'yes' | 'no' | undefined,
  is_vabo: boolean | null,
  vabo_type_id: number | undefined,
}

type BlueprintDateType = {
  startDate: string; // date MM/DD/YYYY
  endDate: string; // date MM/DD/YYYY
  endDateGuaranteedTerm: string; // guaranteed term end date MM/DD/YYYY
  endDateChurn: string; // churn end date MM/DD/YYYY
};

const ContractDetailsRead = ({ blueprint }: ContractDetailsReadProps): JSX.Element => {
  /** Commission **/
  const guaranteedTermCommission = getGuaranteedTermCommission(blueprint);
  const commissionPercent = blueprint?.adjusted_commission_percent
    ? `(${blueprint.adjusted_commission_percent}%)`
    : blueprint?.commission_percent
      ? `(${blueprint.commission_percent}%)`
      : '';
  const commissionAmount = blueprint?.total_commission_expense
    ? formatNumber(blueprint.total_commission_expense, 'currency', 2)
    : '-';

  /**
   * Date options to format date
   */
  const dateOptions: Intl.DateTimeFormatOptions = useMemo(() => {
    return ({
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'UTC' // to double check if needed
    });
  }, []);

  /**
   * Get end date of the blueprint:
   * Equals to: Start date + length of contract - 1 day
   * @returns {string} End date of the blueprint
   */
  const getEndDate = useCallback((date: Date, length: number): string => {
    let endDate = addMonthsToDate(date, length);
    endDate = subtractDaysToDate(endDate, 1);
    return endDate.toLocaleDateString('en-US', dateOptions);
  }, [dateOptions]);

  const createEndChurnDate = useCallback((blueprintEndDate: string, blueprintEndChurnDate: string): JSX.Element => {
    return <Box sx={{display: 'flex'}}><Box sx={{mr: '12px'}}><s>{blueprintEndDate}</s></Box><Box>{new Date(blueprint?.churn_reason?.early_churn_date).toLocaleDateString('en-US', dateOptions)}</Box></Box>;
  }, [blueprint?.churn_reason?.early_churn_date, dateOptions]);

  const blueprintDate: BlueprintDateType = useMemo(() => {
    const blueprintEndDate = getEndDate(new Date(blueprint?.date), blueprint.length);
    const blueprintEndDateGuaranteedTerm = blueprint?.guaranteed_term ? getEndDate(new Date(blueprint?.date), blueprint?.guaranteed_term) : '-';
    const blueprintEndDateChurn = blueprint?.churn_reason?.early_churn_date ? new Date(blueprint?.churn_reason?.early_churn_date).toLocaleDateString('en-US', dateOptions) : '';

    return (
      {
        startDate: new Date(blueprint?.date).toLocaleDateString('en-US', dateOptions),
        endDate: blueprintEndDate,
        endDateGuaranteedTerm: blueprintEndDateGuaranteedTerm,
        endDateChurn: blueprintEndDateChurn,
      }
    );
  }, [blueprint?.date, blueprint?.guaranteed_term, blueprint.length, dateOptions, getEndDate, blueprint?.churn_reason?.early_churn_date]);

  /**
   * Boolean to check if the blueprint has referral. Yes if:
   * - Lead source is channel partner
   * - or Lead source is people
   */
  const hasReferral = blueprint?.lead_source?.slug === 'channel-partner' || blueprint?.lead_source?.slug === 'people';

  /**
   * Render key/value field for referral: partner or employee referral
   */
  const renderReferral = useCallback(() => {
    if (hasReferral) {
      let title = '';
      let value = '';
      if (blueprint?.lead_source?.slug === 'channel-partner') {
        title = ContractDetailsFieldNames.PartnerReferral;
        value = blueprint?.partner ? formatPartnerReferralName(blueprint?.partner) : '-';
      }
      if (blueprint?.lead_source?.slug === 'people') {
        title = ContractDetailsFieldNames.EmployeeReferral;
        value = blueprint?.lead_source_employee?.name || '-';
      }
      return (
        <>
          <Grid item xs={6}>
            <KeyValue label={title} value={value} />
          </Grid>
        </>
      );
    }
    return <></>;
  }, [blueprint?.lead_source?.slug, blueprint?.lead_source_employee?.name, blueprint?.partner, hasReferral]);

  const opportunityName = useMemo(() => {
    if (blueprint?.salesforce_opportunity_name) {
      return blueprint?.salesforce_opportunity_name;
    } else if (blueprint?.salesforce_opportunity_id) {
      return blueprint?.salesforce_opportunity_id;
    } else {
      return '-';
    }
  }, [blueprint?.salesforce_opportunity_id, blueprint?.salesforce_opportunity_name]);

  const labelSalesforceOpportunity = useMemo(() => {
    if (blueprint?.salesforce_is_primary) {
      return (
        <>{ContractDetailsFieldNames.SalesforceOpportunity}
          <Tooltip title="Primary Salesforce Opportunity" placement="right">
            <Badge
              variant="dot"
              overlap="circular"
              sx={{
                marginLeft: 0.6,
                '& .MuiBadge-badge': {
                  color: '#01579B',
                  backgroundColor: '#01579B'
                }
              }}
            />
          </Tooltip>
        </>
      );
    }
    return ContractDetailsFieldNames.SalesforceOpportunity;
  }, [blueprint?.salesforce_is_primary]);

  const handleClick = useCallback(() => {
    window.open(`/users/${blueprint.author.id}`, '_blank', 'noopener,noreferrer');
  }, [blueprint?.author?.id]);

  const blueprintAuthor = useMemo(() => blueprint?.author, [blueprint?.author]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <KeyValue label={ContractDetailsFieldNames.Client} value={blueprint.client.name} />
      </Grid>
      <Grid item xs={6}>
        <KeyValue label={ContractDetailsFieldNames.Appraisal} value={blueprint?.audit?.id ? <Link className={cssShared.link} to={`/appraisals/${blueprint?.audit?.id}`} target="_blank">{blueprint?.audit?.name}</Link> : '-'} />
      </Grid>
      <Grid item xs={6}>
        <KeyValue label={ContractDetailsFieldNames.SalesforceAccount} value={blueprint?.client?.salesforce_client_id ||'-'} />
      </Grid>
      <Grid item xs={6}>
        <KeyValue label={labelSalesforceOpportunity} value={opportunityName} />
      </Grid>
      <Grid item xs={6}>
        <KeyValue label={ContractDetailsFieldNames.TypeOfContract} value={capitalize(blueprint.type)} />
      </Grid>
      <Grid item xs={6}>
        <KeyValue label={ContractDetailsFieldNames.LengthOfContract} value={`${blueprint.length} Month${blueprint.length > 1 ? 's' : ''}`} />
      </Grid>
      <Grid item xs={6}>
        <KeyValue label={ContractDetailsFieldNames.Pillar} value={blueprint?.pillar?.name} />
      </Grid>
      <Grid item xs={6}>
        <KeyValue label={ContractDetailsFieldNames.TypeOfLead} value={blueprint?.lead_source?.name} />
      </Grid>
      {renderReferral()}
      <Grid item xs={6}>
        <KeyValue label={ContractDetailsFieldNames.StartDate} value={blueprintDate.startDate} />
      </Grid>
      <Grid item xs={6}>
        <KeyValue label={ContractDetailsFieldNames.EndDate} value={blueprintDate.endDateChurn === '' ? blueprintDate.endDate : createEndChurnDate(blueprintDate.endDate, blueprintDate.endDateChurn) } />
      </Grid>
      <Grid item xs={6}>
        <KeyValue label={ContractDetailsFieldNames.GuaranteedTerm} value={`${blueprint?.guaranteed_term} Month${blueprint.length > 1 ? 's' : ''}`} />
      </Grid>
      <Grid item xs={6}>
        <KeyValue label={ContractDetailsFieldNames.EndDateGuaranteedTerm} value={blueprintDate.endDateGuaranteedTerm} />
      </Grid>
      <Grid item xs={6}>
        <KeyValue label={ContractDetailsFieldNames.InternalCommission} value={`${commissionPercent} ${commissionAmount}`} />
      </Grid>
      <Grid item xs={6}>
        <KeyValue label={ContractDetailsFieldNames.InternalCommissionGuaranteedTerm} value={typeof guaranteedTermCommission === 'number' && guaranteedTermCommission ? formatCurrency(guaranteedTermCommission) : '-'} />
      </Grid>
      <Grid item xs={hasReferral ? 6 : 12}>
        <KeyValue label={ContractDetailsFieldNames.SalesRep} value={ blueprintAuthor.name ? (
          <Chip
            avatarProps={{
              src: blueprintAuthor?.avatar,
              alt: 'chip-avatar-image-' + blueprintAuthor.name,
              children: !blueprintAuthor.avatar ? blueprintAuthor.name[0] : '',
            }}
            label={blueprintAuthor.name}
            onClick={handleClick}
          /> )
          :
          <span style={{
            fontWeight: 600
          }}>-</span>
        } />
      </Grid>
      <Grid item xs={12}>
        <KeyValue label={ContractDetailsFieldNames.IsVabo} afterLabel={<InfoTooltipBlueprintVabo />} value={blueprint?.is_vabo === true ? 'Yes - ' + blueprint?.vabo_type?.name : 'No'} />
      </Grid>
    </Grid>
  );
};

export default memo(ContractDetailsRead);
