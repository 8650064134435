import React from 'react';
import { Grid, SxProps } from '@mui/material';
import { Card } from 'features/intelligence/components/library-components';
import { cardHeaderStyles, cardContentStyles, cardContainerStyles } from './AboutThisTool.styles';

interface CardType {
  title: string;
  content: JSX.Element | boolean;
}

export interface AboutThisToolType {
  cards?: CardType[];
  value?: number;
  cardSx?: SxProps;
}

export const AboutThisTool = ({
  cards = [],
  value,
  cardSx
}: AboutThisToolType): JSX.Element => {

  return (
    <Card
      type='container'
      title='About This Tool'
      overflow='auto'
      content={
        <Grid container display='flex' wrap='wrap' spacing={2} sx={{ marginBottom: '8px' }}>
          {
            cards.map((card, index) => (
              <Grid item xs={12} md={4} sx={cardContainerStyles} key={index}>
                <Card
                  height='100%'
                  title={card.title}
                  backgroundColor='#2C2C2C'
                  headerStyles={cardHeaderStyles}
                  contentStyles={cardContentStyles}
                  content={card.content}
                  sx={cardSx}
                />
              </Grid>
            ))
          }
        </Grid>
      }
    />
  );
};
