/* eslint-disable complexity */
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import {
  Button,
  Avatar,
  Box,
  EditIcon,
  Grid,
  Input,
  InputLabel,
  useForm,
  useSnackbar,
  Spinner,
  PageLayoutSidebarCardAvatarHeader,
  Link,
  Typography
} from '@sprnova/nebula';
import versionConfig from 'api/utils/versionConfig';
import { ExternalClient } from 'features/entitiesRedux/models/external_client/external_client';
import { ExternalUser } from 'features/entitiesRedux/models/user/user';
import * as styles from './Header.styles';
import css from './Header.module.scss';

interface Props {
  client: Pick<ExternalClient, 'id' | 'name' |'logo'>;
  externalUser: ExternalUser;
  route: string;
  setExternalUser?: (externalUser:ExternalUser)=> void;
}

const ExternalUserProfileSidebarHeader: FC<Props> = ({ client, externalUser, route}) => {
  const { addSnackbar } = useSnackbar();
  const [showEditUserBaseFields, setShowEditUserBaseFields] = useState(false);
  const [avatar, setAvatar] = useState(externalUser?.avatar ?? '');
  const [logo, setLogo] = useState(client?.logo ?? '');
  const [tempLogo, setTempLogo] = useState<string>();
  const [tempAvatar, setTempAvatar] = useState<string>();
  const [disableButtonUntilImageUpload, setDisableButtonUntilImageUpload] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [file, setFile] = useState<File>();
  const isProfile = route === 'profile';

  /**
   * reset avatar or logo when user cancels the update process
   */
  const onCancel = useCallback(async () => {
    setTempAvatar(undefined);
    setTempLogo(undefined);
    setShowEditUserBaseFields(false);
  }, []);

  /**
   * Calls mutation to update either the user avatar or client logo based on current view
   * Updates logo and avatar state variables based on the mutation response
   * Uses the file state variable that is set in the handleFileChange function
   */
  const onSubmit = useCallback(async () => {
    setSubmitting(true);
    try {
      if (file) {
        setDisableButtonUntilImageUpload(true);
        const operations = `{"query":"mutation ($file: Upload!) { ${isProfile ? 'updateClientUser(avatar: $file) { id, avatar }' : `updateExternalClient(id:${client.id}, logo: $file) { id, logo }`} }", "variables": { "file": null }}`;
        const map = '{"file": ["variables.file"]}';
        const formData = new FormData();
        formData.append('operations', operations);
        formData.append('map', map);
        formData.append('file', file);
        const headers = versionConfig[import.meta.env.REACT_APP_PROSPECT_API_VERSION || 'public'];
        const apiUrl = import.meta.env.REACT_APP_PROSPECT_API_HOST;
        if(apiUrl) {
          await fetch(apiUrl, {
            headers: headers,
            method: 'POST',
            body: formData,
          })
            .then((response) => response.json())
            .then((responseJson) => {
              setTempAvatar(undefined);
              setTempLogo(undefined);
              if (responseJson?.data?.updateClientUser || responseJson?.data?.updateExternalClient) {
                setAvatar(responseJson?.data?.updateClientUser?.avatar);
                setLogo(responseJson?.data?.updateExternalClient?.logo);
                setDisableButtonUntilImageUpload(false);
                setShowEditUserBaseFields(false);

                addSnackbar({
                  variant: 'success',
                  message: 'Saved successfully',
                });
              } else if (responseJson?.errors){
                addSnackbar({
                  variant: 'error',
                  message: 'Error updating photo'
                });

                console.error('Error updating photo:', responseJson?.errors?.[0]?.message);
                setDisableButtonUntilImageUpload(false);
              }
            });
        }
      }
    } catch (error) {
      addSnackbar({
        variant: 'error',
        message: 'Error updating photo',
      });

      console.error('Error updating photo', error);
      setDisableButtonUntilImageUpload(false);
    }
    setSubmitting(false);
  }, [client.id, file, isProfile, addSnackbar]);

  /**
   * Set temporary avatar or logo based on user submission
   * @param e event reference object
   */
  const handleFileChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    setFile(e.target.files[0]);
    if (isProfile) {
      setTempAvatar(URL.createObjectURL(e.target.files[0]));
    } else {
      setTempLogo(URL.createObjectURL(e.target.files[0]));
    }
  }, [isProfile]);

  const { handleSubmit } = useForm();

  const title = useMemo(() => {
    if (isProfile) {
      return externalUser?.name;
    }

    if (client?.name) {
      return client?.name;
    }

    return '-';
  }, [
    client?.name,
    externalUser?.name,
    isProfile,
  ]);

  if (!client || !externalUser) {
    return <PageLayoutSidebarCardAvatarHeader skeleton />;
  }

  /**
   * Edit
   */
  if (showEditUserBaseFields) {
    return (
      <styles.Root>
        <Grid container justifyContent={'center'} alignItems={'center'}>
          <Box
            component="form"
            width="100%"
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            {isProfile ?
              <Grid item xs={12} justifyContent={'center'} alignItems={'center'} sx={{ mt: 3, textAlign: 'center', display: 'flex' }}>
                {(avatar && avatar != ' ') || tempAvatar ? <Avatar src={tempAvatar ?? avatar} alt="user avatar" className={css.avatar} /> : '-'}
              </Grid>
              :
              <Grid item xs={12} justifyContent={'center'} alignItems={'center'} sx={{ mt: 3, textAlign: 'center', display: 'flex' }}>
                {(logo && logo != ' ') || tempLogo ? <Avatar src={tempLogo ?? logo} alt={`${client.name} logo`} className={css.avatar} /> : '-'}
              </Grid>
            }
            <Grid item xs={12} sx={{ mt: 3,  mb: 3, textAlign: 'center' }} justifyContent={'center'} alignItems={'center'}>
              <Box>
                <div className={css.uploadButton}>
                  <InputLabel sx={{paddingTop: '5px', paddingBottom: '5px'}} htmlFor="upload-photo-button" className={'tertiary'}>
                    <Input
                      id="upload-photo-button"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): Promise<void> => handleFileChange(e)}
                      inputProps={{
                        accept:
                        'image/*',
                      }}
                      style={{ display: 'none' }}
                      type="file"
                      value={''}
                    />
                    {isProfile ?
                      <Input
                        value={tempAvatar ?? (avatar ? avatar : ' ')}
                        name={'avatar'}
                        id={'avatar-field'}
                        type="hidden"
                      />
                      :
                      <Input
                        value={tempLogo ?? (logo ? logo : ' ')}
                        name={'logo'}
                        id={'logo-field'}
                        type="hidden"
                      />
                    }
                      Upload Photo
                  </InputLabel>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} className={css.buttonContainer}>
              <Grid sx={{textAlign: 'center'}} item xs={6}>
                <Button
                  variant="secondary"
                  fullWidth
                  disabled={disableButtonUntilImageUpload}
                  size="medium"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid sx={{textAlign: 'center'}} item xs={6}>
                <Button
                  variant="primary"
                  fullWidth
                  disabled={disableButtonUntilImageUpload}
                  size="medium"
                  type="submit"
                  startIcon={submitting ? <Spinner size="small"/> : undefined}
                >
                  {submitting ? 'Saving' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </styles.Root>
    );
  }

  /**
   * Display profile header
   */
  return (
    <PageLayoutSidebarCardAvatarHeader
      title={title}
      subTitle={(isProfile && externalUser?.title) ? externalUser?.title : undefined}
      avatarProps={avatar ? { src: avatar, alt: `${client.name} photo` } : undefined}
      end={
        <Button
          variant="tertiary"
          size="medium"
          startIcon={<EditIcon />}
          onClick={(): void => setShowEditUserBaseFields(true)}
        >
          Edit
        </Button>
      }
    >
      {isProfile && externalUser?.email ? (
        <Typography title={externalUser.email} textOverflow={'ellipsis'} overflow={'hidden'}>
          <Link
            component="a"
            href={`mailto:${externalUser.email}`}
          >
            {externalUser.email}
          </Link>
        </Typography>
        
      ) : null}
    </PageLayoutSidebarCardAvatarHeader>
  );


};

export default memo(ExternalUserProfileSidebarHeader);

