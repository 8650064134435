import React from 'react';
import loadable from '@loadable/component';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LoadableComponent: any = loadable(
  () => import('./EditAnalystQuestionGroupPage'),
  {
    fallback: <PageSkeleton />
  }
);

export { LoadableComponent as EditAnalystQuestionGroupPage };
