/**
 * Icon -> Plus
 */

import React, { FC } from 'react';

const Plus: FC = (props) => (
  <span role="img" className="anticon anticon-user" {...props}>
    <svg
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M11.25 18.75v-6h-6v-1.5h6v-6h1.5v6h6v1.5h-6v6z" />
    </svg>
  </span>
);

export default Plus;
