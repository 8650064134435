import loadable from '@loadable/component';

const LoadableComponent = loadable(
  () => import('./Popconfirm')
  /* , {
  fallback: <div>Loading...</div>,
  } */
);

export type { PopconfirmProps } from './Popconfirm';
export { LoadableComponent as Popconfirm };
