export enum Resource {
  analystSurvey = 'analyst-survey',
  audit = 'audit',
  beta = 'beta',
  client = 'client',
  contact = 'contact',
  department = 'department',
  discoveryCall = 'discovery-call',
  forecast = 'forecast',
  integration = 'integration',
  libraryDepartment = 'library-department',
  libraryService = 'library-service',
  libraryStrategy = 'library-strategy',
  libraryOutsourceCost = 'library-outsource-cost',
  packageStrategy = 'package-strategy',
  partner = 'partner',
  recommendedServices = 'recommended-services',
  report = 'report',
  rule = 'rule',
  service = 'service',
  strategy = 'strategy',
  talent = 'talent',
  talentSurvey = 'talent-survey',
  task = 'task',
  user = 'user',
  cohorts = 'cohorts',
  customerInsights = 'customer-insights',
  creativeAffinity = 'creative-affinity',
}
