import { schema } from 'normalizr';
import { BusinessType } from '../types';

/**
 * EntityType (from API)
 */
export type Entity = {
  id: number;
  name: string;
  slug: string;
  type: string;
  entity_id: number;
  entity_type: string;
  business_type?: BusinessType;
  value: string;
  parent: {
    name: string;
    entity_id: number;
    entity_type: string;
  }
  created_at: string;
  updated_at: string;
};

/**
 * Define schema entities (for Normalizr)
 */
export const audit = new schema.Entity('audits');
export const client = new schema.Entity('clients');
export const external_client = new schema.Entity('external_clients');
export const contact = new schema.Entity('contacts');
export const datasource = new schema.Entity('datasources');
export const department = new schema.Entity('departments');
export const forecast = new schema.Entity('forecasts');
export const organization = new schema.Entity('organizations');
export const partner = new schema.Entity('partners');
export const prospect = new schema.Entity('prospects');
export const report = new schema.Entity('reports');
export const rule = new schema.Entity('rules');
export const service = new schema.Entity('services');
export const strategy = new schema.Entity('strategies');
export const task = new schema.Entity('tasks');
export const user = new schema.Entity('users');
export const survey = new schema.Entity('surveys');
export const alert = new schema.Entity('alerts');
export const dashboard = new schema.Entity('dashboards');
export const dashboard_churn = new schema.Entity('dashboard_churns');
export const dashboard_rapport = new schema.Entity('dashboard_rapports');
export const dashboard_pace = new schema.Entity('dashboard_paces');

/**
 * Define entity relationships
 */
datasource.define({});

client.define({
  audits: [audit],
  author: user,
  users: [user],
  strategies: [strategy],
});

external_client.define({
  reports: [report],
});

service.define({
  department,
  tasks: [task],
});

task.define({
  service,
  children: [task],
});

contact.define({
  client,
});

audit.define({
  client,
  author: user,
  forecasts: [forecast],
});

forecast.define({
  audit,
  author: user
});

report.define({
  client,
  author: user,
});

rule.define({
  task,
});

strategy.define({
  client,
  author: user,
  departments: [department],
});

prospect.define({
  prospect,
});

user.define({
  surveys: [survey],
});

survey.define({
  author: user,
});

alert.define({});

dashboard.define({});

dashboard_churn.define({});

dashboard_rapport.define({});

dashboard_pace.define({});

partner.define({
  users: [user],
  strategies: [strategy],
});

/**
 * Combined Normalizr Schema
 */
export const entitiesSchema = {
  audits: [audit],
  clients: [client],
  external_clients: [external_client],
  contacts: [contact],
  datasources: [datasource],
  departments: [department],
  forecasts: [forecast],
  organizations: [organization],
  partners: [partner],
  prospects: [prospect],
  reports: [report],
  rules: [rule],
  services: [service],
  strategies: [strategy],
  tasks: [task],
  users: [user],
  surveys: [survey],
  alerts: [alert],
  dashboards: [dashboard],
  dashboard_churns: [dashboard_churn],
  dashboard_rapports: [dashboard_rapport],
  dashboard_paces: [dashboard_pace],
};
