
/**
 * Executes an asynchronous function with exponential backoff in case of errors.
 *
 * @example
 *   try {
 *     await retryWithBackoff(async (retryCount: number) => {
 *       const res = await fetch( ... );
 *       if (res?.error) {
 *         // Throw error to trigger retry mechanism.
 *         throw new Error(res.error);
 *       } else {
 *         // Return result after retryCount successful retries.
 *         return result;
 *       }
 *     }, { maxRetries: 3 });
 *   } catch (error) {
 *     // Handle retry exhaustion.
 *   }
 *
 * @param fn The asynchronous function to be retried.
 * This function receives the current retry count as an argument.
 * @param options Optional configuration options for retry behavior.
 * @param options.maxRetries The maximum number of retries to attempt before giving up (default: 3).
 * @param options.maxWait The maximum wait time in milliseconds between retries (default: 60000).
 * @param options.onRetry An optional callback function to be called on retry.
 * This callback receives the current retry count as an argument.
 * @param options.onRetryExhaust An optional callback function to be called when all retries are exhausted.
 * @returns A promise that resolves with the successful result of the function
 * or rejects with an instance of ExhaustedRetriesError after all retries fail.
 */
const retryWithBackoff = async<T>(
  fn: (retryCount: number) => Promise<T>,
  options: {
    maxRetries?: number;
    maxWait?: number;
    onRetry?: (retryCount: number) => void;
    onRetryExhaust?: () => void;
  } = {},
): Promise<T> => {
  const { maxRetries, maxWait, onRetry, onRetryExhaust } = { ...{ maxRetries: 3, maxWait: 60000 }, ...options };
  let retryCount = 0;

  do {
    try {
      return await fn(retryCount);
    } catch (error) {
      if (error instanceof ShouldRetryWithBackoffError) {
        retryCount++;

        onRetry?.(retryCount);

        // Retry after 200ms, 400ms, 800ms, 1600ms, and so on, up to the max wait.
        const binaryExponentialBackoff = Math.pow(2, retryCount) * 100;
        const wait = Math.min(binaryExponentialBackoff, maxWait);
        await new Promise((resolve) => setTimeout(resolve, wait));
      } else {
        throw error;
      }
    }
  } while (retryCount <= maxRetries);

  onRetryExhaust?.();

  return Promise.reject(new ExhaustedRetriesError(maxRetries));
};

/**
 * Thrown by `retryWithBackoff` if all retry attempts fail.
 */
export class ExhaustedRetriesError extends Error {
  constructor(retryCount: number) {
    super(`Failed after ${retryCount} retries.`);
    this.name = this.constructor.name;
  }
}

/**
 * Thrown from within the async function passed to `retryWithBackoff` to trigger a retry.
 */
export class ShouldRetryWithBackoffError extends Error {
  constructor(message?: string) {
    super(message || 'This error triggers a retry with backoff.');
    this.name = this.constructor.name;
  }
}

export default retryWithBackoff;
