/**
 * Audits -> DiscoveryForm -> SalesforceOpportunityStage -> Modal -> StageSelection -> Option
 */

import React from 'react';
import classNames from 'classnames';
import { OpportunityStage } from 'features/entitiesRedux/models/types';
import css from './StageOption.module.scss';

type Props = {
  className?: string;
  onSelectStage?: (option: string) => void;
  option: Pick<OpportunityStage, 'id' | 'name' | 'slug'>;
  radio?: boolean;
  selectedStage?: string;
};

const StageOption = ({
  className,
  option,
  onSelectStage,
  radio = true,
  selectedStage,
}: Props) => {
  const handleSelect = () => {
    if (onSelectStage) onSelectStage(option.slug);
  };

  return (
    <div className={classNames(css.root, className)}>
      <span
        className={classNames(css.inner, option.slug, { [css.active]: option.slug === selectedStage })}
        onClick={handleSelect}
      >
        {radio && <span className={classNames(css.dot, { [css.selected]: option.slug === selectedStage })} />}
        <span>{option?.name}</span>
      </span>
    </div>
  );
};

export default StageOption;
