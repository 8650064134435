
import { Note } from 'features/entitiesRedux/models/dashboard';
import { NoteConfig } from 'features/entitiesRedux/models/notes/notes';
import { defaultNotesProjection } from 'features/entitiesRedux/models/notes/projections';
import { Projection } from '..';
import { getClient, toGraphQL } from '../utils';

export const createNoteMutation = async (
  __args: NoteConfig,
  { projection }: { projection?: Projection } = {}
): Promise<{ createNote: Note }> => {
  // GraphQL client.
  const graphQlClient = getClient();

  const query = toGraphQL({
    mutation: {
      createNote: {
        __args,
        ...(projection || defaultNotesProjection),
      },
    },
  });

  return await graphQlClient.sendRequest(query);
};
