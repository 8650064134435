import React, { FC, useEffect, useMemo, useState } from 'react';
import { formatNumber } from 'features/audits/utils';
import { HorizonBudget } from 'features/entitiesRedux/models/horizon';
import { BudgetTableValue } from '../../BudgetTable';
import { Cell } from '../Cell';
import { FunnelCell, FunnelCellProps } from './components/FunnelCell';
import css from '../../BudgetTable.module.scss';

export type FunnelProps = {
  id: number;
  initialValues?: Record<string, { value: number; id?: number, appearance?: BudgetTableValue['appearance'] }>;
  dates: string[];
  name: string;
  onUpdateCell?: ({ id, date, value }: { id?: number, date: string, value: number, funnelId: number }) => Promise<HorizonBudget>
  onPaste: (args: { 
    date: string;
    yIndex: number;
    event: React.ClipboardEvent<HTMLInputElement>;
   }) => void
}

export const Funnel: FC<FunnelProps> = ({ initialValues, name, dates, onUpdateCell, id: funnelId, onPaste }) => {
  const [values, setValues] = useState(initialValues ?? {});

  useEffect(() => {
    setValues(initialValues ?? {});
  }, [initialValues]);

  // Local state is used to calculate the total for the funnel
  const handleUpdateTotal = (date: string, id: number, value: number): void => setValues(curr => (
    {
      ...curr,
      [date]: {
        value,
        id,
      }
    }
  ));

  const total = useMemo(() => {
    const dateSet = new Set(dates);
    const t = Object.entries(values)
      .filter(([date]) => dateSet.has(date)) // Only run total on the dates in the table (if length got changed for some reason)
      .reduce((acc, [, { value }]) => acc + value, 0);

    return formatNumber(t,'',2);
  }, [dates, values]);

  return (
    <div className={css.funnel}>
      <div className={css.stickyFunnel}>
        <Cell color="gray" align="top" id={`budget-table-funnel-label-${funnelId}`}>
          {name}
        </Cell>
      </div>
      {dates.map((date, yIndex) => {
        const { id, value, appearance } = values?.[date] || { value: 0, id: null };
        
        const handleUpdateCell: FunnelCellProps['onUpdateCell'] = onUpdateCell 
          ? (args): Promise<HorizonBudget> =>  onUpdateCell({ ...args, funnelId }) 
          : undefined;

        const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>): void => onPaste({
          date,
          yIndex,
          event
        });

        return (
          <FunnelCell
            key={date}
            date={date}
            onUpdateTotal={handleUpdateTotal}
            onUpdateCell={handleUpdateCell}
            id={id}
            value={value}
            onPaste={handlePaste}
            appearance={appearance}
          />
        );
      })}
      <Cell isBold prefix="$" id={`budget-table-funnel-total-${funnelId}`}>
        {total}
      </Cell>
    </div>
  );
};
