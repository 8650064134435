/**
 * Blueprints -> Review List -> Filters
 */

import React, { useEffect } from 'react';
import { Form as AntdForm } from 'antd';
import classnames from 'classnames';
import omitBy from 'lodash/omitBy';
import {  FetchStrategiesFilter } from 'features/entitiesRedux';
import {
  Form,
  Input,
  FormItem,
  Button,
  SelectUser,
} from 'components';
import css from './Filters.module.scss';

interface BlueprintsReviewListFilterProps {
  setFilter?: (values: FetchStrategiesFilter) => void;
  filter: FetchStrategiesFilter;
  isLoading?: boolean;
  hasFilters: boolean;
}

const BlueprintsReviewListFilter = ({
  filter = {},
  setFilter,
  hasFilters,
}: BlueprintsReviewListFilterProps): JSX.Element => {
  const [form] = AntdForm.useForm();

  // Update form if filters updates externally
  useEffect(() => {
    form.setFieldsValue(filter);
  }, [filter, form]);

  const initialFilterValues = {
    author_id: undefined,
    name: undefined,

  };

  return (
    <div className={css.root}>
      <Form
        initialValues={{ ...initialFilterValues, ...filter }}
        form={form}
        className={css.form}
        layout="vertical"
        onValuesChange={(change: FetchStrategiesFilter, values: FetchStrategiesFilter) => {
          if (setFilter) setFilter({ ...omitBy(values, val => !val) });
        }}
      >
        <div className={css.columns}>
          <div className={classnames(css.column, css.column__sales_rep)}>
            <FormItem smallLabel name="author_id" label="Sales Rep." className={css.formItem}>
              <SelectUser placeholder="All" allowClear disableSkeleton />
            </FormItem>
          </div>
          <div className={classnames(css.column, css.column__name)}>
            <FormItem smallLabel name="name" label="Search" className={css.formItem}>
              <Input
                allowClear
                placeholder="Name or company"
              />
            </FormItem>
          </div>
          <div className={classnames(css.column, css.column__reset, { [css.show]: hasFilters })}>
            <Button
              className={css.resetButton}
              type="text"
              onClick={() => {
                if (setFilter) {
                  setFilter(initialFilterValues);
                }
                form.setFieldsValue(initialFilterValues);
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default BlueprintsReviewListFilter;
