/**
 * SelectCurrency
 */

import React, { forwardRef } from 'react';
import Select, { Props as SelectProps } from '../Select/Select';
import { currencyTypes } from './types';

const SelectCurrency = forwardRef(({ skeleton, ...props }: SelectProps, ref: React.Ref<HTMLInputElement>) => {
  return (
    <Select
      showSearch
      skeleton={skeleton}
      optionFilterProp="children"
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      {...props}
    >
      {currencyTypes.map((type: string, index) => (
        <Select.Option
          key={`${type}-${index}`}
          value={type === 'USD $' ? '$' : type}
        >
          {type}
        </Select.Option>
      ))}
    </Select>
  );
});
SelectCurrency.displayName = 'SelectCurrency';

export default SelectCurrency;
