/**
 * SelectOrganization
 */

import React, { forwardRef } from 'react';
import { useOrganizations } from 'utils/hooks';
import { Organization } from 'features/entitiesRedux';
import Select, { Props as SelectProps } from '../Select/Select';

// eslint-disable-next-line react/display-name
const SelectOrganization = forwardRef(
  ({ skeleton: skeletonProp, ...props }: SelectProps, ref: any) => {
    const { organizations, loading } = useOrganizations();
    const skeleton = loading && !organizations.length;

    return (
      <Select
        showSearch
        optionFilterProp="children"
        skeleton={skeleton || skeletonProp}
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        ref={ref}
        {...props}
      >
        {organizations.map((organization: Partial<Organization>) => (
          <Select.Option key={organization.id} value={organization.id || ''}>
            {organization.name}
          </Select.Option>
        ))}
      </Select>
    );
  }
);

export default SelectOrganization;
