import { YesNo } from 'constants/constants';
import React, { memo, useCallback, useState, useMemo, useReducer } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  DataGrid,
  EmptyState,
  Grid,
  PlusIcon,
  Skeleton,
} from '@sprnova/nebula';
import { novaGraphQLPublicClient } from 'api/entityGraphQL';
import { ExternalClient } from 'features/entitiesRedux';
import {
  ActionCell,
  ContactInfoCell,
  InviteStatusCell,
  TeammateCell,
  UpsertDialog,
} from './components';
import { UpsertFieldValues } from './components/UpsertDialog/UpsertDialog';

type TeamOverviewProps = {
  client?: ExternalClient;
  isLoading?: boolean;
  authenticatedUserId?: number;
  onError?: (error: string) => void;
  onTriggerClientRefresh?: () => void;
}

export enum InviteStatus {
  Accepted = 'accepted',
  Invited = 'invited',
}

export type TeammateTableRow = {
  id: number;
  isAuthenticatedUser: boolean;
  teammate: {
    avatar: string;
    name: string;
    title?: string;
  };
  contactInfo: {
    email: string;
  };
  inviteStatus: InviteStatus;
}

const TeamOverview: React.FC<TeamOverviewProps> = ({ client, isLoading, authenticatedUserId, onError, onTriggerClientRefresh }) => {
  const clientId = client?.id;

  const [selectedRow, setSelectedRow] = useState<TeammateTableRow | null>(null);
  const [isUpsertDialogOpen, toggleUpsertDialog] = useReducer((open) => !open, false);

  const handleAddTeammate = useCallback((): void => {
    toggleUpsertDialog();
  }, [toggleUpsertDialog]);

  const handleEditTeammate = useCallback((): void => {
    console.log('handle edit teammate');
  }, []);

  const handleDeleteTeammate = useCallback((): void => {
    console.log('handle delete teammate');
  }, []);

  const inviteClientUser = useCallback(async (clientId: number, data: UpsertFieldValues) => {
    try {
      const { name, title, email } = data;
      const projection = {
        id: true,
      };

      return await novaGraphQLPublicClient.inviteClientUser({ client_id: clientId, email, name, title, projection });
    } catch (e) {
      const error: any = e;
      const message = error?.response?.errors?.[0]?.message || 'An unknown error occurred. Please try again.';
      onError?.(message);
    }
  }, [onError]);

  const handleUpsertSubmit = useCallback(async (data: UpsertFieldValues) => {
    if (clientId) {
      inviteClientUser(clientId, data).then(() => {
        onTriggerClientRefresh?.();
      });
    }
  }, [clientId, inviteClientUser, onTriggerClientRefresh]);

  const handleActionDropdownClick = (row: TeammateTableRow): void => {
    setSelectedRow(row);
  };

  const columns: GridColDef<TeammateTableRow>[] = useMemo(() => {
    return [
      {
        field: 'teammate',
        headerName: 'Teammate',
        sortable: false,
        flex: 1,
        renderCell: (params) => <TeammateCell {...params.value} isAuthenticatedUser={params?.row?.isAuthenticatedUser} />
      },
      {
        field: 'inviteStatus',
        headerName: 'Invite Status',
        sortable: false,
        flex: 0.5,
        renderCell: (params) => <InviteStatusCell status={params.value} />
      },
      {
        field: 'contactInfo',
        headerName: 'Contact Info',
        sortable: false,
        flex: 1,
        renderCell: (params) => <ContactInfoCell {...params.value} />
      },
      // {
      //   field: 'action',
      //   headerName: 'Action',
      //   sortable: false,
      //   flex: 0.25,
      //   renderCell: (params): JSX.Element => {
      //     return (
      //       <ActionCell
      //         onClick={(): void => handleActionDropdownClick(params?.row)}
      //         onEdit={handleEditTeammate}
      //         onDelete={handleDeleteTeammate}
      //         disabled={params?.row?.isAuthenticatedUser}
      //       />
      //     );
      //   }
      // }
    ];
  }, []);

  const rows: TeammateTableRow[] = useMemo(() => {
    return client?.users?.map(({
      avatar,
      email,
      id,
      is_accepted,
      name,
      title,
    }) => ({
      id,
      isAuthenticatedUser: id === authenticatedUserId,
      teammate: { avatar, name, title },
      contactInfo: { email },
      inviteStatus: is_accepted === YesNo.Yes
        ? InviteStatus.Accepted
        : InviteStatus.Invited,
    })) ?? [];
  }, [client, authenticatedUserId]);

  const header = (
    <CardHeader
      title="Team Overview"
      end={
        isLoading ? <Skeleton sx={{width: '175px', height: '50px'}} /> : (
          <CardActions
            actions={[
              <Button
                startIcon={<PlusIcon />}
                variant="tertiary"
                key="action-1"
                onClick={handleAddTeammate}
                disabled={client?.users && client.users.length >= 10}
              >
              Add Contact
              </Button>,
            ]}
          />
        )}
    />
  );

  const renderSkeleton = (rowCount: number, gap = '10px'): JSX.Element => {
    const rows: JSX.Element[] = [];

    for (let i = 0; i < rowCount; i++) {
      rows.push(<Skeleton key={i} sx={{
        flex: '1',
        transform: 'scale(1)',
      }} />);
    }

    return (
      <div style={{
        minHeight: '20vh',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap,
      }}
      >
        {rows}
      </div>
    );
  };

  const skeleton = useMemo(() => renderSkeleton(4), []);

  return (
    <>
      <Box style={{marginBottom: '75px'}}>
        <Card
          header={header}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {isLoading ? skeleton
                : rows.length ?
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    rowHeight={100}
                    columnHeaderHeight={48}
                    hideFooter
                  />
                  :
                  <EmptyState
                    size="small"
                    title="No teammates found"
                  />
              }
            </Grid>
          </Grid>
        </Card>
      </Box>
      <UpsertDialog
        open={isUpsertDialogOpen}
        onClose={toggleUpsertDialog}
        onSubmit={handleUpsertSubmit}
      />
    </>
  );
};

export default memo(TeamOverview);
