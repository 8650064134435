import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import loadable from '@loadable/component';
import { selectTaskById } from 'features/entitiesRedux/models/task';

const EditTaskPage = loadable(
  () => import('./EditTaskPage')
);

const Title = () => {
  const { id } = useParams<{ [x: string]: any }>();
  const task = useSelector(selectTaskById(Number.parseInt(id)));

  if (!task) {
    return <span>Edit</span>;
  }

  return <>{task.name}</>;
};

Title.displayName = 'EditTaskPageTitle';

export default Object.assign(EditTaskPage, { Title });
