import { novaGraphQLClient } from 'api/entityGraphQL';
import { Projection } from 'api/entityGraphQL/types';
import { defaultClientProjection } from 'features/entitiesRedux';

export const projection: Projection = {
  ...defaultClientProjection,
  id: true,
  reports: {
    id: true,
    name: true,
    website: true,
    logo: true,
    widgets: {
      id: true,
      name: true,
      size: true,
      position: true,
      row: true,
    },
    excluded_data_sources: {
      id: true
    },
    fusion_integrations: {
      data_source: {
        id: true,
        slug: true,
        group: true
      }
    },
  },
};

export const reportProjection: Projection = {
  id: true,
  name: true,
  website: true,
  logo: true,
  // revenue: true,
  // spend: true,
  // roas: true,
  // leads: true,
  // sqls: true,
  // customers: true,
  // sessions: true,
  // cost_per_lead: true,
  // opportunities: true,
  Client: true,
  widgets: {
    id: true,
    name: true,
    size: true,
    position: true,
    row: true,
    is_active: true,
    data: {
      id: true,
      title: true,
      content: true,
    },
  },
  excluded_data_sources: {
    id: true
  },
  fusion_integrations: {
    data_source: {
      id: true,
      slug: true,
      group: true
    }
  },
};

export const fusionIntegrationsProjection: Projection = {
  id: false,
  classic_integration_id: true,
  client_id: true,
  warehouse_source: true,
  data_source: {
    id: true,
    name: true,
    slug: true,
  },
};
