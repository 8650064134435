/**
 * ConditionalField
 * 
 * Determine whether or not to show a field based on the value of another field
 */

import React, { FC, useMemo, useCallback } from 'react';
import { FormInstance } from 'antd/lib/form';
import { FormItem } from 'components';

// Conditional value to check for
const CONDITIONAL_VALUE = 'other';

// The conditional field name would typically be "{current field name}_other"
const getConditionalFieldName = (name: string) => (name || '').replace('_other', '');

type Props = {
  children: React.ReactNode;
  name: string;
}

const ConditionalField: FC<Props> = ({ name, children }) => {
  // Get the matching conditional field name
  const conditionalFieldName = useMemo(() => getConditionalFieldName(name), [name]);

  // Determine whether or not the field should render based on the conditional field value
  const render = (form: FormInstance) => {
    const conditionalValue = form.getFieldValue(conditionalFieldName);

    if (conditionalValue === CONDITIONAL_VALUE) {
      return children;
    }

    return null;
  };

  // Only update if the current field value or conditional field value changes
  const shouldUpdate = useCallback((prevValues, curValues) => {
    return  (prevValues[name] !== curValues[name]) || 
            (prevValues[conditionalFieldName] !== curValues[conditionalFieldName]);
  }, [conditionalFieldName, name]);

  return (
    <FormItem noStyle shouldUpdate={shouldUpdate}>
      {render}
    </FormItem>
  );
};

export default ConditionalField;