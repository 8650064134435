import React, { memo, useCallback } from 'react';
import { Card,  Grid, KeyValue } from '@sprnova/nebula';
import { PricingTier } from 'api/crudGraphQL/strategies/types';
import { Task } from 'features/entitiesRedux/models/task';

type ViewSpendContractDetailsProps = {
  task?: Task;
}

const ViewSpendContractDetails = ({ task }: ViewSpendContractDetailsProps): JSX.Element => {
  const renderTierCards = useCallback(() => task?.pricing_tiers?.map((pricingTier: PricingTier, index: number) => {
    return (
      <Card
        sx={{ paddingTop: 0, marginBottom: '24px' }}
        key={`quantity-pricing-tier-${index}`}
      >
        <Grid
          container
          justifyContent={'space-around'}
          spacing={3}
        >
          <Grid item xs={6}>
            <KeyValue
              label='Tier Name'
              value={pricingTier.name ?? 'N/A'}
            />
          </Grid>
          <Grid item xs={6}>
            <KeyValue
              label='Tier Price'
              value={pricingTier.price ?? 'N/A'}
            />
          </Grid>
          <Grid item xs={6}>
            <KeyValue
              label='Spend Minimum Price'
              value={pricingTier?.spend_min ?? 'N/A'}
            />
          </Grid>
          <Grid item xs={6}>
            <KeyValue
              label='Spend Maximum Price'
              value={pricingTier?.spend_max ?? 'N/A'}
            />
          </Grid>
          <Grid item xs={12}>
            <KeyValue
              label='Proposal Snippet'
              value={pricingTier?.snippet
                ? <div dangerouslySetInnerHTML={{ __html: pricingTier?.snippet }} />
                : 'N/A'}
            />
          </Grid>
        </Grid>
      </Card>
    );
  }
  ), [task?.pricing_tiers]);


  return (
    <Grid
      container
      justifyContent={'space-around'}
      spacing={3}
    >
      <Grid item xs={12}>
        <KeyValue
          label='Pricing Type'
          value={task?.pricing_type?.name ?? 'N/A'}
        />
      </Grid>
      <Grid item xs={12}>
        <KeyValue
          label='Snippet Summary'
          value={task?.snippet_summary ? <div dangerouslySetInnerHTML={{ __html: task?.snippet_summary }} /> : 'N/A'}
        />
      </Grid>
      <Grid item xs={12}>
        {renderTierCards()}
      </Grid>
    </Grid>
  );
};

export default memo(ViewSpendContractDetails);
