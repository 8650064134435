import React, { FC, memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  AppNav,
  AppSidebar,
  NavigationTableChartFilledIcon,
  NavigationTableChartIcon,
  NavigationUserGroupFilledIcon,
  NavigationUserGroupIcon,
  NavigationConnectionIcon,
  NavigationConnectionFilledIcon,
  NavigationAddChartIcon,
  NavigationAddChartFilledIcon,
  NavigationFolderIcon,
  NavigationFolderFilledIcon,
  NavigationBarChartIcon,
  NavigationBarChartFilledIcon,
  NavigationInsightsFilledIcon,
  NavigationInsightsIcon,
  NavigationArticleIcon,
  NavigationArticleFilledIcon,
  LockIcon,
  Tooltip,
  Box,
  Typography,
  useAppLayout,
} from '@sprnova/nebula';
import { useGetNovaFeaturesPublicQuery } from 'api/crudGraphQL/public/nova_features/getNovaFeaturesPublic';
import { SidebarSkeleton } from 'layouts/components/SidebarSkeleton';
import { useExternalAccount } from 'utils/hooks/useExternalAccount';
import { useMixpanelTrack } from 'utils';
import NovaGptButton from 'features/clients/External/Components/NovaGptButton/NovaGptButton';
import { selectExternalClientById } from 'features/entitiesRedux/models/external_client/selectors';
import { ExternalUser } from 'features/entitiesRedux/models/user';
import { ClientDropdown } from './components';
import { IconLookup, MenuItem } from './types';

const Sidebar: FC = () => {
  const { pathname: currentPath } = useLocation();
  const { clientId: idRaw } = useParams<{ [x: string]: string }>();
  const clientId = parseInt(idRaw);
  const client = useSelector(selectExternalClientById(clientId));
  const externalUser: ExternalUser = useExternalAccount();
  const { sidebarCollapsed } = useAppLayout();

  // fetch menu items from API
  const { data: nova_features = [], isLoading: isLoadingFeatures } = useGetNovaFeaturesPublicQuery({
    client_id: clientId,
    projection: {
      id: true,
      name: true,
      slug: true,
      is_active: true,
      category: true,
    }
  }, { skip: !clientId });

  const mixpanel = useMixpanelTrack();
  /** Mixpanel Analytics */
  const trackSidebarClickMixpanelEvent = useCallback((linkName: string ): void => {
    const eventName = `Client Sidebar - ${linkName} Clicked`;
    const options = {
      userId: externalUser?.id,
      userName: externalUser?.name,
      userEmail: externalUser?.email,
      clientId: client?.id,
      clientName: client?.name,
      isPdmEmployee: externalUser?.is_pdm_employee,
      pageName: linkName
    };
    mixpanel(eventName, options);
  },[client?.id, client?.name, externalUser?.email, externalUser?.id, externalUser?.is_pdm_employee, externalUser?.name, mixpanel]);

  // array of icons for mapping to their related menu items
  const menuIcons = useMemo(() => [
    {
      slug: 'scoreboards',
      icon: <NavigationTableChartIcon />,
      iconFilled: <NavigationTableChartFilledIcon />,
    },
    {
      slug: 'reports',
      icon: <NavigationBarChartIcon />,
      iconFilled: <NavigationBarChartFilledIcon />,
    },
    {
      slug: 'custom-reports',
      icon: <NavigationAddChartIcon />,
      iconFilled: <NavigationAddChartFilledIcon />,
    },
    {
      slug: 'insights-ai',
      icon: <NavigationInsightsIcon />,
      iconFilled: <NavigationInsightsFilledIcon />,
    },
    {
      slug: 'weekly-updates',
      icon: <NavigationArticleIcon />,
      iconFilled: <NavigationArticleFilledIcon />,
    },
    {
      slug: 'documents',
      icon: <NavigationFolderIcon />,
      iconFilled: <NavigationFolderFilledIcon />,
    },
    {
      slug: 'company',
      icon: <NavigationUserGroupIcon />,
      iconFilled: <NavigationUserGroupFilledIcon />,
    },
    {
      slug: 'integrations',
      icon: <NavigationConnectionIcon />,
      iconFilled: <NavigationConnectionFilledIcon />,
    }
  ], []);

  // Create a lookup object for the menu item icons
  const iconLookup: IconLookup = useMemo(() => menuIcons.reduce((acc: IconLookup, { slug, icon, iconFilled }) => {
    acc[slug] = { icon, iconFilled };
    return acc;
  }, {}), [menuIcons]);

  // Group the nova_features by category and transform each object to match the expected type
  const groupedFeatures = useMemo(() => {
    const result: { [key: string]: MenuItem[] } = {};

    nova_features.forEach((feature) => {
      const { category, name, slug, is_active } = feature;

      // Initialize the category array if it doesn't exist
      if (!result[category]) {
        result[category] = [];
      }

      // Determine icons based on `is_active` status
      const icons = is_active
        ? iconLookup[slug] || { icon: null, iconFilled: null }
        : { icon: <LockIcon />, iconFilled: <LockIcon /> };

      // Push the feature as a MenuItem into the corresponding category array
      result[category].push({
        label: name,
        path: slug,
        icon: icons.icon,
        iconFilled: icons.iconFilled,
        isActive: is_active,
      });
    });

    return result;
  }, [iconLookup, nova_features]);

  // Build array from groupedFeatures object so it can be consumed by <AppNav>
  const navSections = useMemo(() => {
    return Object.keys(groupedFeatures).filter((category) => category !== 'null').map(category => ({
      legend: category.charAt(0).toUpperCase() + category.slice(1),
      items: groupedFeatures[category]
    }));
  }, [groupedFeatures]);

  const renderNavSections = useMemo(() => navSections.map(({ legend, items }) => {
    return (
      <AppNav.Section label={legend} key={legend}>
        {items.map(({ label, icon, iconFilled, path, isActive }) => {
          const combinedPath = `/c/${idRaw}/${path}`;

          // TODO: Remove this conditional rendering once landing pages are ready
          if (!isActive) {
            return (
              <Tooltip
                key={`${label}-tooltip`}
                content="To unlock this feature, contact Sales"
                variant="plain"
                placement="right"
              >
                <AppNav.Item
                  key={label}
                  icon={icon}
                  iconFilled={iconFilled}
                >
                  {label}
                </AppNav.Item>
              </Tooltip>
            );
          }

          return (
            <AppNav.Item
              key={label}
              component={Link}
              to={combinedPath}
              icon={icon}
              iconFilled={iconFilled}
              isActive={currentPath.includes(combinedPath)}
              onClick={() => trackSidebarClickMixpanelEvent(label)}
            >
              {label}
            </AppNav.Item>
          );
        }
        )}
      </AppNav.Section>
    );
  }), [currentPath, idRaw, navSections, trackSidebarClickMixpanelEvent]);

  const dropdownButtonProps = client?.name ? {
    label: client?.name,
    children: <ClientDropdown user={externalUser} client={client} />
  } : undefined;

  const handleTrackMixpanelAskGPTEvent = useCallback((): void => {
    try {
      const eventName = 'Access GPT Sidebar';
      const options = {
        clientId: client?.id,
        clientName: client?.name,
        accountId: externalUser?.id,
        accountName: externalUser?.name,
        accountEmail: externalUser?.email,
        isPdmEmployee: externalUser?.is_pdm_employee,
      };
      if (process.env.NODE_ENV !== 'production') console.log('🛤 Track: askGPT from the Client Sidebar', options);
      mixpanel(eventName, options);
    } catch (error) {
      console.error('Mixpanel error', error);
    }
  },[client?.id, client?.name, externalUser?.email, externalUser?.id, externalUser?.is_pdm_employee, externalUser?.name, mixpanel]);

  /**
   * novaGPT Button
   */

  const renderBottom = useMemo(() => {

    const button = <NovaGptButton handleMixPanelEvent={handleTrackMixpanelAskGPTEvent} isSidebar />;

    return sidebarCollapsed ? button : (
      <Box pt={2} pb={2} pl={1.75} pr={1.75} sx={{
        backgroundColor: '#424242',
        textAlign: 'center',
        borderRadius: '0.75em',
        boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.30), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)',
      }}>
        <Typography sx={{color: '#fff'}} fontSize="1rem" fontWeight="600">Chat with Your Data</Typography>
        <Typography sx={{color: '#fff'}} fontSize="0.75rem" mt={0.5} mb={1.5}>Get insights instantly.</Typography>
        {button}
      </Box>
    );
  }, [handleTrackMixpanelAskGPTEvent, sidebarCollapsed]);

  return (
    <>
      <AppSidebar
        logo={
          <AppSidebar.Logo
            component={Link}
            to={`/c/${idRaw}/`} //Use logo to redirect to client home page for external users
            onClick={() => trackSidebarClickMixpanelEvent('Homepage')}
            dropdownButtonProps={dropdownButtonProps}
          />
        }
        renderBottom={renderBottom}
      >
        {
          isLoadingFeatures ? (
            <SidebarSkeleton
            // Define how many loading skeleton sections, the amount of children in each section, and the length of each value
              sections={
                [
                  [80, [80, 80, 80]],
                  [80, [80, 105]],
                ]
              }
            />
          ) : (
            <AppNav>
              {renderNavSections}
            </AppNav>
          )
        }
      </AppSidebar>
    </>
  );
};

export default memo(Sidebar);
