import { RootState } from 'app/store';
import { selectEntitiesDenormalized } from '../_entity/selectors';

export const selectAuditsByIds = (auditIds: number[]) => (state: RootState) => {
  const input = { audits: auditIds };
  return selectEntitiesDenormalized(state, input).audits;
};

export const selectAuditById = (auditId: number) => (state: RootState) => {
  return selectAuditsByIds([auditId])(state)[0];
};
