import { Projection } from 'api/entityGraphQL/types';
import { LookerPerformanceSignedUrl } from 'features/entitiesRedux';
import { cleanArguments } from 'api/crudGraphQL/utils';
import { basePublicApi } from '../basePublicApi';

export type CreateLookerUrlPublicMutation = {
  client_id: number;
  dashboard?: string;
  projection?: Projection;
}

export type CreateLookerUrlPublicResult = LookerPerformanceSignedUrl;

export const CREATE_LOOKER_URL_PUBLIC_ENDPOINT_KEY = 'createLookerUrlPublic';

export const { useCreateLookerUrlPublicMutation } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [CREATE_LOOKER_URL_PUBLIC_ENDPOINT_KEY]: build.mutation<CreateLookerUrlPublicResult, CreateLookerUrlPublicMutation>({
      transformResponse: (response: { createLookerURL: LookerPerformanceSignedUrl }) =>  response.createLookerURL,
      query: ({ projection = { signed_url: true }, ...values }) => {
        const __args: Partial<CreateLookerUrlPublicMutation> = values;
        return ({
          body: {
            mutation: {
              createLookerURL: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        }
        );
      }
    }),
  }),
});
