/**
 * Components -> SurveyQuestion -> QuestionComments
 */

import React, { FC } from 'react';
import { DebouncedField } from 'components/DebouncedField';
import { TextArea } from 'components/TextArea';
import { TalentSurveyBucket } from 'features/talent/types';

type Types = {
  question: TalentSurveyBucket;
  onUpdate: (value: string) => void;
  placeholder: string;
  authorized?: boolean;
};

const QuestionComments: FC<Types> = ({
  question,
  onUpdate,
  placeholder,
  authorized,
}) => {

  return (
    <DebouncedField
      component={TextArea}
      onUpdate={onUpdate}
      placeholder={placeholder}
      syncValue={false}
      value={question.notes}
      disabled={!authorized}
    />
  );
};

export default QuestionComments;
