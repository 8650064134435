import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetClientQuery } from 'api/crudGraphQL/clients/getClient';
import { NumberParam, useQueryParam } from 'use-query-params';
import { defaultClientIntegrationProjection, useDataSources } from 'features/entitiesRedux';

type RouteParams = {
  clientId: string;
};

const CreateNovaCloudIntegrationPageTitle = (): JSX.Element => {
  // Obtain client and integration ID from URL parameters
  const { clientId: id } = useParams<RouteParams>();
  const [integrationId ] = useQueryParam('integrationId', NumberParam);
  const clientId = parseInt(id);

  // Query client data
  const { data: client } = useGetClientQuery(
    { id: clientId, projection: defaultClientIntegrationProjection },
  );

  // Find the integration and related datasource
  const currentFivetranIntegration = client?.fivetran_connectors?.find(fivetranIntegration => fivetranIntegration?.id === integrationId);
  const datasources = useDataSources();
  const datasource = datasources.find(datasource => datasource.id === currentFivetranIntegration?.data_source_id);

  return <span>{currentFivetranIntegration ? datasource?.name : 'nova Cloud'}</span>;
};

export default memo(CreateNovaCloudIntegrationPageTitle);
