import React, { useMemo, useCallback } from 'react';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import { Grid, formatCurrency, Typography, Box, InfoTooltip } from '@sprnova/nebula';
import { useGetPlaybookPlatformCalculatedMetricsQuery } from 'api/crudGraphQL/intelligence/creative_affinity/getPlaybookPlatformCalculatedMetrics';
import pluralize from 'pluralize';
import { PlaybookAd, PlaybookAdset, PlaybookCampaign } from 'features/entitiesRedux/models/creative_playbook';
import { getPlatformIcon } from 'features/intelligence/creative-playbook/CreativeAffinityUtils';
import AffinityScore from 'features/intelligence/creative-playbook/ViewCreativePlaybook/components/PlaybookTable/components/PlaybookTableBody/components/TableBodyData/components/AffinityScore';
import { Ad, Adset, Campaign } from './ColumnTableComponents';
import EquationTooltip from '../../DataGrid/components/EquationTooltip';
import { tooltips } from '../../DataGrid/tooltips';
import { CreativeAffinityValueTypes, TabNames } from '../../utils/constants';
import { dateFormatter, formatRelativeDate, formatValuesBasedOnType } from '../../utils/formatValuesBasedOnType';

type Props = {
  data: Partial<PlaybookCampaign | PlaybookAdset | PlaybookAd>[] | undefined;
  activeTab?: string;
  isLoading?: boolean;
};

export const ColumnsTable = ({ data = [], activeTab }: Props): JSX.Element => {
  const type = useMemo(() => {
    switch (activeTab) {
      case TabNames.campaigns:
        return 'campaign';
      case TabNames.adsets:
        return 'adset';
      case TabNames.ads:
        return 'ad';
      default:
        return '';
    }
  }, [activeTab]);

  const { data: calculatedMetrics } = useGetPlaybookPlatformCalculatedMetricsQuery({});

  const firstRow = useMemo(() => {
    /**
     * Get the first row of the table
     * [title, path, type]
     */
    if (data.length) {
      const keys = Object.keys(data[0]);
      // Campaigns tab
      if (keys.includes(TabNames.adsets)) {
        return [pluralize(TabNames.adsetTitle), TabNames.adsets, TabNames.adsets];
      }
      // Adsets tab
      if (keys.includes(TabNames.ads)) {
        return [pluralize(TabNames.adTitle), TabNames.ads, TabNames.ads];
      }
      if (!keys.includes('images') && keys.includes(TabNames.adset)) {
        return [pluralize(TabNames.adTitle), TabNames.ads, TabNames.ads];
      }
      if (keys.includes('images')) {
        return [TabNames.adTitle, 'first_asset', TabNames.ad];
      }
      return null;
    }
  }, [data]);

  const titles = useMemo(() => ([
    /**
     * [title, path, type]
     */
    firstRow,
    type === 'ad' && ['Format', 'formats', 'string[]'],
    ['Ad Platform', 'platform.name', 'platform'],
    [
      'Industry',
      type === 'campaign'
        ? 'client.industry.name'
        : type === 'adset'
          ? 'campaign.client.industry.name'
          : type === 'ad'
            ? 'adset.campaign.client.industry.name'
            : '',
      'string'
    ],
    ['Funnel Stage', 'funnel', 'string'],
    ['Affinity Score', 'score', 'affinity'],
    [
      'LTV',
      'ecomm_matched_metric.ltv',
      'currency'
    ],
    /**
     * TODO: Uncomment when CAC has been tested
     */
    // ['CAC', 'ecomm_matched_metric.cac', 'currency'],
    ['Ad Spend', 'spend', 'currency'],
    ['ROAS', 'roas', 'decimal'],
    ['Revenue', 'revenue', 'currency'],
    ['CPA', 'cpa', 'currency'],
    ['Clicks', 'clicks', 'number'],
    ['Impressions', 'impressions', 'number'],
    ['Conversions', 'conversions', 'number'],
    ['Stop Rate', 'stop_rate', 'percent'],
    ['Hold Rate', 'hold_rate', 'percent'],
    ['CTR', 'ctr', 'percent'],
    ['CVR', 'cvr', 'percent'],
    ['CPC', 'cpc', 'currency'],
    ['CPM', 'cpm', 'currency'],
    /**
     * TODO: Uncomment when and if results become available (most likely for future platforms)
     */
    // ['Results', 'result', 'number'],
    ['Last Updated', 'last_refreshed', 'date'],
  ].filter(title => title)), [firstRow, type]);

  const formatValues = useCallback((
    values: unknown[],
    type: 'string' | 'string[]' | 'currency' | 'percent' | 'affinity' | 'platform' | 'number' | 'decimal' | 'date' | TabNames.adsets | TabNames.ads | TabNames.ad,
    path?: string) => {
    switch (type) {
      case 'currency':
        return values.map(value => typeof value === 'number' ? formatCurrency(value) : '-');
      case 'percent':
        return values.map(value => value &&
          path ? formatValuesBasedOnType({ [path]: value }, CreativeAffinityValueTypes)[path]
          : typeof value === 'number' ? `${(value * 100).toFixed(2)}%` : '-');
      case 'date':
        return values.map(value => <Typography variant='body1'>{formatRelativeDate(dateFormatter(value as string))}</Typography> || '-');
      case TabNames.adsets:
        return values.map((value, i) => <Campaign values={value} key={`adsets_${i}`} />);
      case TabNames.ads:
        return values.map((value, i) => <Adset values={value} key={`adset_${i}`} />);
      case TabNames.ad:
        return values.map((value, i) => <Ad value={{ ...data[i], first_asset: value }} key={`ad_${i}`} />);
      case 'affinity':
        return values.map((value, i) => (
          <AffinityScore
            key={`affinity_${i}`}
            score={typeof value === 'string' || typeof value === 'number' ? value : undefined}
            variant='h3'
            gridSx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          />
        ));
      case 'platform':
        return values.map((value, i) => (
          <div key={`platform_${value}_${i}`}>
            {getPlatformIcon(value as string)}&nbsp;{value}
          </div>
        ));
      case 'string':
        return values.map(value => value || '-');
      case 'number':
        return values.map(value => typeof value === 'number' ? value.toLocaleString('en-US') : '-');
      case 'decimal':
        return values.map(value => typeof value === 'number' ? value.toFixed(2) : '-');
      case 'string[]':
        return (values as string[][]).map(value => value.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(', ') || '-');
      default:
        return values;
    }
  }, [data]);

  const findMatchedString = (str: string): string => {
    /**
     * Because the value of string is used as a nested object key,
     * we need to find the matched string for the following cases:
     */
    const parts = str.split('.');
    const arr = ['ltv', 'roas', 'cpa', 'stop_rate', 'hold_rate', 'ctr', 'cvr', 'cpc', 'cpm'];
    for (const part of parts) {
      if (arr.includes(part)) {
        return part;
      }
    }
    // If no match is found, return the original string
    return str;
  };

  const formatLabelWithTooltip = useCallback((label: string, tooltip?: string) => {
    const formattedDescription = tooltip && tooltips({ tabName: activeTab })[findMatchedString(tooltip)];
    const equations = calculatedMetrics?.filter(metric => metric.name === tooltip);
    // Don't render the tooltip if there is no description or equations
    const tooltipComponent = formattedDescription || equations?.length
      ? <InfoTooltip
        sx={{ ml: '10px' }}
        content={<EquationTooltip description={formattedDescription} equations={equations} />}
      />
      : <></>;
    return (
      <Box>
        {label}
        {tooltipComponent}
      </Box>
    );
  }, [activeTab, calculatedMetrics]);

  const formattedData = useMemo(() => {
    if (data.length) {
      return titles?.map(([label, path, type]: any) => {
        const keys = path.split('.');
        const values = data.map((item) => {
          return keys.reduce((obj: any, key: any) => {
            return obj?.[key];
          }, item);
        });
        return [formatLabelWithTooltip(label, path), formatValues(values, type, path)];
      });
    }
    return [];
  }, [data, formatLabelWithTooltip, formatValues, titles]);

  return (
    <Table>
      <TableBody>
        {
          formattedData.length
            ? formattedData.map((item: any, i: number) => (
              <TableRow sx={{ width: 700 }} key={`columnsTable_${i}`}>
                <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                  <Typography variant="h5">
                    {item[0]}
                  </Typography>
                  <Grid
                    container
                    sx={{ margin: '20px 0 10px 0' }}
                  >
                    {
                      item?.[1]?.map((value: any, i: number) => (
                        <Grid
                          item xs={12 / data.length}
                          style={{
                            textAlign: 'center',
                            padding: '0 10px',
                            display: 'flex',
                            /**
                             * Since Ad Sets is scrollable, we need to set the alignment to initial
                             */
                            alignItems: value?.key?.includes('adset') ? 'flex-start' : 'center',
                            justifyContent: value?.key?.includes('adset') ? 'initial' : 'center',
                            maxHeight: '500px',
                          }}
                          key={`columnsTable_item_${i}`}
                        >
                          <Typography
                            variant='h3'
                            component='div'
                            sx={{
                              maxWidth: '100%',
                              '& > div': {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }
                            }}
                          >
                            {value}
                          </Typography>
                        </Grid>
                      ))
                    }
                  </Grid>
                </TableCell>
              </TableRow>
            ))
            : <></>
        }
      </TableBody>
    </Table>
  );
};
