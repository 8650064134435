import { config } from 'config';
import Cookies from 'js-cookie';
import { Prospect, Audit, Report, ExternalClient, ExternalUser, Partner, LookerCategory, ClientDocumentSectionType } from 'features/entitiesRedux';
import { FeatureFlag } from 'features/entitiesRedux/models/feature_flag';
import { LookerUserFavorite } from 'features/entitiesRedux/models/looker_user_favorites/looker_user_favorite';
import { Projection } from './types';
import { NovaGraphQLClient } from '.';

export class NovaGraphQLPublicClient extends NovaGraphQLClient {
  constructor() {
    super();

    this.endpoint = config.prospect.api.host + '/';
    this.client = this.getClient();
  }

  resetClient(): NovaGraphQLPublicClient {
    this.client = this.getClient();

    return this;
  }

  async createProspect(
    {
      company,
      business_type_id,
      contact,
      email,
      website,
      recaptcha,
    }: {
      company: string;
      business_type_id: number;
      contact: string;
      email: string;
      website: string;
      recaptcha: string;
    },
    { projection }: { projection?: Projection } = {}
  ): Promise<{ prospects: Partial<Prospect>[] }> {
    const __args = {
      company,
      business_type_id,
      contact,
      email,
      website,
      recaptcha,
    };
    const query = this.toGraphQL({
      mutation: {
        createProspect: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultProspectProjection),
        },
      },
    });
    const response = await this.sendRequest(query);

    return { prospects: [response.createProspect] };
  }

  async saveLookerFavorite(
    {
      client_id,
      report_id,
      report_type
    }: {
      client_id: number;
      report_id: number;
      report_type: string;
    },
    { projection }: { projection?: Projection } = {}
  ): Promise<{ looker_user_favorites: Partial<LookerUserFavorite>[] }> {
    const __args = {
      client_id,
      report_id,
      report_type
    };
    const query = this.toGraphQL({
      mutation: {
        saveLookerFavorite: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultLookerFavoriteProjection),
        },
      },
    });
    const response = await this.sendRequest(query);

    return { looker_user_favorites: [response.saveLookerFavorite] };
  }


  async deleteLookerFavorite(
    {
      id
    }: {
      id: number;
    },
    { projection }: { projection?: Projection } = {}
  ): Promise<{ looker_user_favorites: Partial<LookerUserFavorite>[] }> {
    const __args = {
      id
    };
    const query = this.toGraphQL({
      mutation: {
        deleteLookerFavorite: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultLookerFavoriteProjection),
        },
      },
    });
    const response = await this.sendRequest(query);

    return { looker_user_favorites: [response.deleteLookerFavorite] };
  }


  async fetchAuditById(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ audits: Partial<Audit>[] }> {
    const __args = { id };
    const query = this.toGraphQL({
      query: {
        audit: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultAuditProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    const audit = response.audit;

    return { audits: [audit] };
  }

  async fetchReportById(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ reports: Partial<Report>[] }> {
    const __args = { id };
    const query = this.toGraphQL({
      query: {
        report: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultPublicReportProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    const report = response.report;

    return { reports: [report] };
  }

  async fetchClientByIdPublic(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<ExternalClient[]> {
    const __args = { id };

    const query = this.toGraphQL({
      query: {
        client: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultPublicClientProjection),
        },
      },
    });

    const { client: external_client } = await this.sendRequest(query);

    return [ external_client ];
  }

  async updateClient(
    {
      id,
      name,
      website,
      status,
      business_type_id,
      industry_id,
      lead_source_id,
    }: {
      id: number;
      name?: string;
      website?: string;
      status?: string;
      business_type_id?: number;
      industry_id?: number;
      lead_source_id?: number;
    },
    { projection }: { projection?: Projection } = {}
  ) {
    // TODO: write a GQL query builder function @Bill
    // use this.toGraphQL(...)
    const query = `
      mutation {
        updateClient(
          id: ${id}
          ${name ? `name: "${name}"` : ''}
          ${website ? `website: "${website}"` : ''}
          ${status ? `status: "${status}"` : ''}
          ${business_type_id ? `business_type_id: ${business_type_id}` : ''}
          ${industry_id ? `industry_id: ${industry_id}` : ''}
          ${lead_source_id ? `lead_source_id: ${lead_source_id}` : ''}
        ) {
          ${projection || this.toGraphQL(this.defaultProspectProjection.client)}
        }
      }
    `;
    const response = await this.sendRequest(query);
    return { clients: [response.updateClient] };
  }

  defaultMeProjection = {
    id: true,
    name: true,
    email: true,
    avatar: false,
    permissions: true,
    roles: true,
    accounts: false,
    organization: false,
    department: false,
    departments: false,
    highest_talent_role: {
      id: true,
      name: true,
      slug: true
    },
    manager: false,
    partners: {
      id: true,
      name: true,
      company: true,
    },
    title: false,
    job_title: true,
  };

  defaultLookerFavoriteProjection = {
    id: true,
    user_id: true,
    report_id: true,
    report_type: true
  };

  defaultProspectProjection = {
    id: true,
    signature: true,
    audit: {
      id: true,
      discovery: {
        id: true,
      },
    },
    url: true,
    name: true,
    client: {
      id: true,
      name: true,
      website: true,
      description: true,
      logo: true,
      business_type: { id: true },
      industry: { id: true },
      status: true,
      contacts: {
        id: true,
        name: true,
        title: true,
        email: true,
        phone: true,
        is_decision_maker: true,
        notes: true,
        created_at: true,
        updated_at: true,
      },
      created_at: true,
      updated_at: true,
    },
    status: true,
    business_type: { id: true },
    departments: {
      id: true,
      name: true,
      slug: true,
      services: { id: true, name: true },
      scores: {
        id: true,
        name: true,
        max: true,
        notes: true,
        buckets: {
          id: true,
          name: true,
          max: true,
          criteria: {
            id: true,
            name: true,
            score: true,
          },
        },
      },
      score: true,
      max: true,
      notes: true,
      analyst_notes: true,
      in_survey: true,
    },
    services: { id: true, name: true },
    questions: {
      id: true,
      business_type: { id: true },
      question: true,
      group_name: true,
      is_public: true,
      topic: { id: true },
      is_required: true,
      answer: true,
    },
    topics: { id: true },
    budgets: { id: true, budget: true, ad_spend: true, notes: true },
    channels: { id: true, name: true },
    goals: { id: true, type: true, metric: true },
    products: { id: true, name: true },
    competitors: { id: true, name: true },
    customers: { id: true, name: true, industry: true },
    growths: { id: true, goal: true },
    scores: { id: true, name: true },
    buckets: { id: true, name: true },
    created_at: true,
    updated_at: true,
  };

  defaultPublicReportProjection = {
    ...this.defaultReportProjection,
    accounts: false,
    client: {
      ...this.defaultReportProjection.client,
      signature: false,
      business_type: false,
      accounts: false,
    },
    author: false,
  };

  defaultPublicClientProjection = {
    id: true,
    name: true,
    website: true,
    logo: true,
    is_accepted: true,
    account_manager: {
      id: true,
      name: true,
      email: true,
      avatar: true,
    },
    executive_sponsor: {
      id: true,
      name: true,
      email: true,
      avatar: true,
    },
    group_director: {
      id: true,
      name: true,
      email: true,
      avatar: true,
    },
    business_type: {
      id: true,
      name: true,
    },
    reports: {
      id: true,
      name: true,
      website: true,
      logo: true,
      business_type: {
        id: true,
        name: true,
      },
      client: {
        id: true,
        name: true,
        business_type: {
          id: true,
          name: true,
        },
      },
    },
    accounts: {
      id: true,
      auth_method: true,
      is_enabled: true,
      is_ingesting: true,
      data_source_account_id: true,
      url: true,
      value2: true,
      progress: true,
      is_created_by_client: true,
      reauthenticate_url: true,
      account: {
        id: true,
        slug: true,
        name: true,
        description: true,
        group: true,
        disclaimer: true,
      },
    },
    integration_requests: {
      reminded_at: true,
      requests: {
        data_source_id: true,
        access_request_id: true,
        access_request_status: true,
        access_request_created_at: true,
        manual_access_request_id: true,
        manual_access_request_status: true,
        manual_access_request_created_at: true,
      }
    },
  };

  /**
   * Get the current user
   *
   * @deprecated Use `useGetMePublicQuery` instead.
   */
  async getUser() {
    const query = this.toGraphQL({
      query: {
        me: {
          id: true,
          avatar: true,
          title: true,
          name: true,
          email: true,
          roles: true,
          is_pdm_employee: true,
          partners: {
            id: true,
            is_accepted: true,
            name: true,
            company: true,
          },
          clients: {
            id: true,
            invited_at: true,
            is_accepted: true,
            logo: true,
            name: true,
            reports: {
              id: true,
              name: true,
            },
            accounts: {
              id: true,
            }
          },
        },
      },
    });

    const { me } = await this.sendRequest(query);
    return me;
  }

  /**
   * Accept client or partner invitation
   */
  async acceptInvitation({
    client_id,
    partner_id,
    password: user_password,
    name: user_name,
    is_pdm_employee,
  }: {
    client_id?: number | null; // Client user
    partner_id?: number | null; // Partner user
    password?: string;
    name?: string;
    is_pdm_employee?: boolean;
  }): Promise<ExternalUser | null> {

    // Accept invitation and create client user
    if (client_id) {
      const { createClientUser } = await this.sendRequest(
        this.toGraphQL({
          mutation: {
            createClientUser: {
              __args: this.filterUndefinedNullEmpty({
                client_id,
                user_password,
                user_name,
                is_pdm_employee,
              }),
              id: true,
            }
          },
        })
      );

      return createClientUser;
    }

    // Accept invitation and create partner user
    if (partner_id) {
      const { createPartnerUser } = await this.sendRequest(
        this.toGraphQL({
          mutation: {
            createPartnerUser: {
              __args: this.filterUndefinedNullEmpty({
                partner_id,
                user_password,
                user_name,
              }),
              id: true,
              name: true,
              company: true,
            }
          },
        })
      );

      return createPartnerUser;
    }

    return null;
  }

  /**
   * External partners
  */
  defaultExternalPartnerProjection = {
    id: true,
    email: true,
    name: true,
    company: true,
    percent: true,
    report: {
      key: true,
      value: true,
    },
    strategies: {
      date: true,
      id: true,
      name: true,
      length: true,
      type: true,
      status: true,
      total_retainer: true,
      total_partner: true,
      total_profit: true,
      departments: {
        id: true,
        name: true,
      },
      client: {
        id: true,
        name: true,
        logo: true,
      },
    }
  };

  async fetchExternalPartnerById(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ partners: Partial<Partner>[] }> {
    Cookies.set('entityId', String(id));

    const __args = { id };
    const query = this.toGraphQL({
      query: {
        partner: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultExternalPartnerProjection),
        },
      },
    });

    const { partner } = await this.sendRequest(query);
    return { partners: [partner] };
  }
  async updateExternalClient({
    id,
    name,
    website,
    business_type_id,
    industry_id,
    employee_size_id,
    annual_revenue_id,
  }  : {
    id: number,
    name?: string,
    website?: string,
    business_type_id?: number,
    industry_id?:number
    employee_size_id?:number,
    annual_revenue_id?:number,
  }): Promise<ExternalClient> {

    const __args = {id, name, website, business_type_id,industry_id,employee_size_id,annual_revenue_id};
    const query = this.toGraphQL({
      mutation: {
        updateExternalClient: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
          annual_revenue: {
            id: true,
            name: true,
            slug: true
          },
          business_type: {
            id: true,
            name: true,
            slug: true
          },
          employee_size : {
            id: true,
            name: true,
            slug: true
          },
          website: true,
          industry: {
            id: true,
            name: true,
            slug: true
          },
        },
      },
    });

    const { updateExternalClient } = await this.sendRequest(query);
    return updateExternalClient;
  }

  /**
   * Update client user information
   */
  async updateClientUser({
    name,
    title,
    password,
    avatar
  }  : {
    name?: string,
    title?: string,
    password?: string,
    avatar?: File
  }): Promise<ExternalUser> {
    const __args = {name, title, password, avatar};
    const query = this.toGraphQL({
      mutation: {
        updateClientUser: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
          name: true,
          email: true,
          title: true,
          avatar: true,
          clients: {
            id: true,
            logo: true,
            name: true,
            is_accepted: true,
          }
        },
      },
    });

    const { updateClientUser } = await this.sendRequest(query);
    return updateClientUser;
  }

  async inviteClientUser({
    client_id,
    email,
    name,
    title,
    projection
  }  : {
    client_id: number,
    email: string,
    name?: string,
    title?: string,
    projection?: Projection,
  }): Promise<ExternalUser> {
    const __args = { client_id, email, name, title };
    const query = this.toGraphQL({
      mutation: {
        inviteClientUser: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || { id: true })
        },
      },
    });

    const { inviteClientUser } = await this.sendRequest(query);
    return inviteClientUser;
  }

  async getLookerCategories({ projection } : { projection?: Projection }): Promise<LookerCategory[]> {
    const query = this.toGraphQL({
      query: {
        looker_categories: {
          ...(projection || { id: true })
        },
      },
    });

    const { looker_categories } = await this.sendRequest(query);
    return looker_categories;
  }

  /**
   * Client Document Sections
   */
  defaultPublicClientDocumentSectionsProjection = {
    id: true,
    name: true,
    document_files: {
      id: true,
      file_name: true,
      file_url: true,
      updated_at: true
    },
    external_available: true,
  };

  async fetchClientDocumentSections(
    client_id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ document_sections: ClientDocumentSectionType[] }> {
    const __args = { client_id };
    const query = this.toGraphQL({
      query: {
        document_sections: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultPublicClientDocumentSectionsProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    const { document_sections } = response;
    return { document_sections };
  }

  async getCustomReportsLookerCategories({ client_id, projection } : { client_id: number, projection?: Projection }): Promise<LookerCategory[]> {
    const __args = { client_id };
    const query = this.toGraphQL({
      query: {
        looker_categories_custom_reports: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || { id: true })
        },
      },
    });

    const { looker_categories_custom_reports } = await this.sendRequest(query);
    return looker_categories_custom_reports;
  }

  async getBaseReportsLookerCategories({ projection } : { projection?: Projection }): Promise<LookerCategory[]> {
    const query = this.toGraphQL({
      query: {
        looker_categories_base_reports: {
          ...(projection || { id: true })
        },
      },
    });

    const { looker_categories_base_reports } = await this.sendRequest(query);
    return looker_categories_base_reports;
  }

  async getLookerUserFavorites({ client_id, projection } : { client_id: number, projection: {[key: string]: any} }): Promise<LookerUserFavorite[]> {
    const __args = { client_id };
    const query = this.toGraphQL({
      query: {
        looker_user_favorites: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection)
        },
      },
    });

    const { looker_user_favorites } = await this.sendRequest(query);
    return looker_user_favorites;
  }

  /**
   * Feature Flags
   */
  defaultPublicFeatureFlagsProjection = {
    id: true,
    name: true,
    is_enabled: true,
  };

  async fetchFeatureFlags(
    client_id?: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ feature_flags: FeatureFlag[] }> {
    const __args = { client_id };
    const query = this.toGraphQL({
      query: {
        feature_flags: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultPublicFeatureFlagsProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    const { feature_flags } = response;
    return { feature_flags };
  }
}

export const novaGraphQLPublicClient = new NovaGraphQLPublicClient();
