import React, { memo, useEffect } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { TextField, Controller, Box } from '@sprnova/nebula';
import { CreateScoreboardFormType } from '../CreateScoreboardForm';

type CreateScoreboardNameTextFieldProps = {
  control: Control<CreateScoreboardFormType, any>;
  setValue: UseFormSetValue<CreateScoreboardFormType>;
  selectedClientName?: string;
  isEdit?: boolean;
}

/**
 * Renders a text field for naming a scoreboard.
 * Updates the name field when the selected client changes.
*
* @param {CreateScoreboardNameTextFieldProps} props - The component props
* @returns {JSX.Element} The rendered component
*/
const CreateScoreboardNameTextfield: React.FC<CreateScoreboardNameTextFieldProps> = ({control, selectedClientName, setValue, isEdit}) => {
  const NAME = 'name';

  // Update the name field when selectedClient changes
  useEffect(() => {
    if (!isEdit) {
      if (selectedClientName) {
        setValue(NAME, selectedClientName);
      }
    }
  }, [isEdit, selectedClientName, setValue]);

  return (
    <Box sx={{ marginBottom: '1.5rem' }}>
      <Controller
        name={NAME}
        control={control}
        defaultValue={selectedClientName || ''}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            id="CreateScoreboardNameTextfield-name"
            label="Scoreboard Name"
            value={field.value ?? ''}
            helperText="Choose a name for your scoreboard"
            error={error?.message}
            onChange={(e) => {
              field.onChange(e);
            }}
          />
        )}
      />
    </Box>
  );
};

export default memo(CreateScoreboardNameTextfield);
