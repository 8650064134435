import React, { useMemo, memo, useCallback } from 'react';
import { Row, Col } from 'antd';
import classnames from 'classnames';
import { SurveyScoreType } from 'features/talent/constants';
import { TalentSurveyBucket, TalentSurveyType } from 'features/talent/types';
import { surveyHasCommentBox } from 'features/talent/utils/surveys/surveyHasCommentBox';
import { QuestionComments, QuestionSlider, QuestionTextarea, QuestionSwitch } from './components';
import { SurveyCriteriaPopover } from '../SurveyCriteriaPopover';
import { SurveyQuestionScore } from '../SurveyQuestionScore';
import css from './SurveyQuestion.module.scss';

interface SurveyQuestionProps {
  question: TalentSurveyBucket;
  onChange: (question: TalentSurveyBucket, change: Partial<TalentSurveyBucket>) => void;
  authorized?: boolean;
  surveyBucket: Partial<TalentSurveyBucket> | undefined;
  surveyId: number;
  surveyType?: TalentSurveyType;
  scoreType?: string;
}

const SurveyQuestion = ({
  question,
  onChange: handleUpdateBucket,
  authorized,
  surveyBucket,
  surveyType,
  scoreType,
}: SurveyQuestionProps): JSX.Element => {
  // When score.max is below 1 we either show a textarea or a checkbox and these aren't measurable
  const requiredField = scoreType === SurveyScoreType.Regular;
  const showScore = requiredField || question.max > 1;
  const hasTextArea = surveyType && scoreType ? surveyHasCommentBox(surveyType, scoreType) : false;

  /**
   * Determine the score for the question:
   * - From api response or from user input
   */
  const questionScore = useMemo(() => {
    return surveyBucket && surveyBucket.score ? surveyBucket.score : question.score;
  }, [question.score, surveyBucket]);

  /**
   * Update notes field
   */
  const handleNotesChange = useCallback((notes: string) => {
    handleUpdateBucket(question, { notes });
  }, [handleUpdateBucket, question]);

  /**
   * Update score field
   */
  const handleUpdateScore = useCallback((score: number) => {
    handleUpdateBucket(question, { score });
  }, [handleUpdateBucket, question]);

  /**
   * Determine which form element to use
   */
  const renderQuestionElement = useMemo(() => {
    const { max } = question || {};

    // Textarea
    if (max === 0) {
      return (
        <QuestionTextarea
          onChange={handleNotesChange}
          question={surveyBucket ?? question}
          disabled={!authorized}
        />
      );
    }

    // Switch
    if (max === 1 && !requiredField) {
      return (
        <QuestionSwitch
          onChange={handleUpdateScore}
          question={surveyBucket ?? question}
          unauthorized={!authorized}
        />
      );
    }

    // Default to slider
    return (
      <QuestionSlider
        disabled={!authorized}
        onChange={handleUpdateScore}
        question={question}
      />
    );
  }, [question, requiredField, authorized, handleUpdateScore, handleNotesChange, surveyBucket]);

  const renderScore = useMemo(() => {
    if (!showScore) {
      return null;
    }

    return (
      <Col span={4}>
        <div className={css.right}>
          <SurveyQuestionScore
            score={questionScore}
            max={question.max}
          />
        </div>
      </Col>
    );
  }, [showScore, questionScore, question.max]);

  /** A single row (bucket) in the analyst survey */
  return (
    <div className={classnames(css.root, { [css.hasTextArea]: hasTextArea })}>
      <Row justify="space-between" align="middle" style={{ height: '100%' }}>
        <Col span={6}>
          <div className={css.left}>
            {question.name}
            {(Array.isArray(question.criteria) && question.criteria.length) || question?.description ? (
              <SurveyCriteriaPopover
                bucket={question}
              />
            ) : null}
          </div>
        </Col>
        <Col span={showScore ? 12 : 17}>
          <div className={css.center}>
            {renderQuestionElement}
            {hasTextArea && (
              <QuestionComments
                question={question}
                placeholder="Add comments..."
                onUpdate={handleNotesChange}
                authorized={authorized}
              />
            )}
          </div>
        </Col>
        {renderScore}
      </Row>
    </div>
  );
};

export default memo(SurveyQuestion);
