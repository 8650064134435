/**
 * SectionBuilder -> Fields -> ClientLogoField
 */

import React, { FC } from 'react';
import { FormItem, LogoUpload } from '../../../../../../../../components';
import { FieldProps } from '../../types';

const ClientLogoField: FC<FieldProps> = ({ name, form }) => {
  const clientId = form.getFieldValue('client_id');
  const clientLogo = form.getFieldValue('client_logo');

  return (
    <FormItem name={name}>
      <LogoUpload
        mode="edit"
        id={clientId}
        logo={clientLogo}
        version="internal"
      />
    </FormItem>
  );
};

export default ClientLogoField;
