import { useSelector } from 'react-redux';
import { User } from 'features/entitiesRedux';
import { selectAccountMeta } from 'features/global';

/**
 * Return Boolean if isGoogleAuthenticated
 */
export const isGoogleAuthenticated = (account: User | null): boolean | undefined => {
  const [isLoading] = useSelector(selectAccountMeta);

  const googleAccount = account?.accounts?.find((acct) => acct?.type === 'google');
  let checkIfGoogleAccountIsAuthenticated = false;

  if (googleAccount) {
    /*
      * Check if the Google account is authenticated within 5 months, if so return true
      * If not, return false
      *
      * This is to prevent the user from having to re-authenticate every time they want to generate a proposal.
      * Convert the updated_at to UTC time and compare it to the current time
     */
    const updated_at = new Date(new Date(googleAccount?.updated_at).toUTCString());
    const now = new Date(new Date().toUTCString());
    const timeDiff = Math.abs(now.getTime() - updated_at.getTime());
    const diffHours = timeDiff / (1000 * 60 * 60);

    const hoursInFiveMonths = 3650;
    checkIfGoogleAccountIsAuthenticated = diffHours < hoursInFiveMonths;
  }

  return (!isLoading && checkIfGoogleAccountIsAuthenticated);
};
