/**
* Get department
*/

import { Projection } from 'api/entityGraphQL/types';
import { Department } from 'features/entitiesRedux/models/department/department';
import { baseApi } from '..';
import { GetDepartmentsResult } from './getDepartments';
import tagTypes from './tagTypes';

export const GET_DEPARTMENT_ENDPOINT_KEY = 'getDepartment';

export type GetDepartmentQuery = {
  id: number;
  projection: Projection;
};

export type GetDepartmentResult = Department;

export const {
  useGetDepartmentQuery,
  useLazyGetDepartmentQuery,
  util
} = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_DEPARTMENT_ENDPOINT_KEY]: build.query<GetDepartmentResult, GetDepartmentQuery>({
      transformResponse: (response: { departments: GetDepartmentsResult }) => response.departments[0],
      providesTags: (department) => [
        department?.id ? { id: department.id, type: tagTypes.DEPARTMENT } : tagTypes.DEPARTMENT,
      ],
      query: ({
        projection = { id: true },
        id,
      }) => ({
        body: {
          query: {
            departments: {
              __args: {
                id
              },
              ...projection
            },
          }
        }
      }),
    }),
  }),
});

/**
 * Utils
 */

// Updates the getDepartment cache when the arguments match
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateGetDepartmentCache = async (args: any, { dispatch, queryFulfilled }: any) => {
  try {
    const { data } = await queryFulfilled;

    const { patches } = dispatch(
      util.updateQueryData(GET_DEPARTMENT_ENDPOINT_KEY, args, (draft) => {
        Object.assign(draft, data);
        return draft;
      })
    );

    // No patches were made (possibly because no cached entries with the given arguments were found) –
    // Try invalidating tags instead which will trigger a refetch
    if (!patches.length) {
      dispatch(util.invalidateTags([
        {
          type: tagTypes.DEPARTMENT,
          id: args.id
        }
      ]));
    }
  } catch {
    console.error('Error updating getDepartment data', { args });
  }
};
