/**
 * SelectRuleTask
 */

import React, {forwardRef, useEffect, useMemo} from 'react';
import { Empty } from 'antd';
import { TreeSelect } from 'components/TreeSelect';
import {StringParam, useQueryParam, withDefault} from 'use-query-params';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import { sortByName, useTasks } from '../../utils';
import { useDepartments } from '../../utils/hooks';
import formatOptionTitle from '../SelectCriteriaEntity/formatOptionTitle';
import { Props as TreeSelectProps } from '../TreeSelect/TreeSelect';
import css from './SelectRuleTask.module.scss';

interface SelectRuleTaskProps extends TreeSelectProps {
  value?: string;
  onChange?: (value: string) => void;
  onLoadData: (value: boolean) => void;
}

const SelectRuleTask = forwardRef(({ skeleton: skeletonProp, onChange, onLoadData, value, ...props }: SelectRuleTaskProps, ref: any) => {
  const [pricingVersionQueryParam, setPricingVersionQueryParam] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );
  const { tasks, loading } =  useTasks( { pricingVersion: pricingVersionQueryParam });
  const skeleton = loading && !tasks?.length;
  const { departments } = useDepartments();

  /** Let parent component know if data is still loading */
  useEffect(() => {
    if (loading) {
      onLoadData(true);
    } else {
      onLoadData(false);
    }
  }, [loading, onLoadData]);

  const getTasksByDepartment = (departmentId: number) => {
    return tasks.filter(task => task.service?.department?.id === departmentId).sort(sortByName);
  };

  // filter out departments that don't have any associated tasks
  const ruleDepartments = departments.filter(dept => {
    return getTasksByDepartment(dept.id)?.length > 0;
  });

  const treeData = ruleDepartments?.map(dept => {
    return {
      title: dept.name,
      selectable: false,
      key: dept.name,
      children: getTasksByDepartment(dept.id).map(task => {
        return {
          title: formatOptionTitle(task.name, task.service?.name),
          value: task.id,
          key: task.id,
          searchTitle: [task.name, task.service?.name].join(' '),
        };
      })
    };
  });

  const handleSelect = (val: string) => {
    if (onChange) {
      onChange(val);
    }
  };

  return (
    <TreeSelect
      showSearch
      placeholder="Select task"
      treeNodeFilterProp="searchTitle"
      filterTreeNode={(input, option) =>
        typeof option?.searchTitle === 'string' ? option?.searchTitle?.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
      }
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeData={treeData}
      ref={ref}
      className={css.root}
      listHeight={224}
      skeleton={skeleton || skeletonProp}
      allowClear
      notFoundContent={
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description='No tasks matched your search'
        />
      }
      {...props}
      onChange={handleSelect}
      value={value}
    />
  );
});

SelectRuleTask.displayName = 'SelectRuleTask';

export default SelectRuleTask;
