/**
 Blueprints -> Review List Page -> List
 */

import React from 'react';
import { EntitiesListProps } from 'components/List/List';
import get from 'lodash/get';
import omitBy from 'lodash/omitBy';
import { FetchStrategiesFilter, Strategy } from 'features/entitiesRedux';
import { Alert, BlockList, Button, List } from 'components';
import { Filters, Header, Row } from './components';
import css from './BlueprintsReviewList.module.scss';

type BlueprintsReviewListProps = EntitiesListProps & {
  strategies: Strategy[];
  error?: string | null;
  filter: FetchStrategiesFilter;
  setFilter?: (values: FetchStrategiesFilter) => void;
  reviewerType: 'Finance' | 'Department';
}

const BlueprintsReviewList = ({
  strategies,
  isLoading,
  error,
  filter,
  setFilter,
  pagination,
  reviewerType,
}: BlueprintsReviewListProps): JSX.Element => {

  const hasFilters = !!Object.keys(omitBy(filter, (val) => !val)).length;
  const loading = isLoading || (strategies?.length === 0 && !hasFilters);

  const handleReset = () => {
    if (setFilter) setFilter({
      author_id: undefined,
      name: undefined,
    });
  };

  const renderItem = (strategy: Strategy) => (
    <Row
      reviewerType={reviewerType}
      key={strategy?.id}
      strategy={strategy}
    />
  );

  if (error) {
    return <Alert message={error} type="error" />;
  }

  return (
    <div className={css.root}>
      <Filters
        hasFilters={hasFilters}
        filter={filter}
        setFilter={setFilter}
        isLoading={loading}
      />
      {loading ? (
        <BlockList.Skeleton rows={10} />
      ) : (
        <List
          header={strategies?.length ? <Header /> : null}
          className={css.list}
          itemLayout="horizontal"
          dataSource={strategies}
          /* Don't show pagination if there's only one page */
          pagination={
            get(pagination, 'total', 0) <= 10 ? undefined : pagination
          }
          renderItem={renderItem}
          locale={{
            emptyText:
              Array.isArray(strategies) &&
              strategies?.length === 0 &&
              !hasFilters &&
              !isLoading ? (
                  'No blueprints needing approval yet'
                ) : (
                  <>
                  No blueprints matching the applied filters were found.{' '}
                    <Button onClick={handleReset} size="small" type="link">
                    Reset
                    </Button>
                  </>
                ),
          }}
        />
      )}
    </div>
  );
};

export default BlueprintsReviewList;
