import React from 'react';
import css from './SelectCriteriaEntity.module.scss';

const formatOptionTitle = (primary: string | undefined, secondary: string | undefined): JSX.Element => {
  return (
    <div className={css.entityOption}>
      {primary}{' '}<small className={css.muted}>{secondary}</small>
    </div>
  );
};

export default formatOptionTitle;