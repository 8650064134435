const allOutsourceGuideItemsQueryString = `
query($pricing_version: String) {
  outsource_costs_guide_items(pricing_version: $pricing_version) {
    id
    internal_contact {
      id
      name
    }
    amount
    service
    department {
      id
      name
    }
    frequency_of_cost
    notes_or_description
    pricing_version {
      id
      name
      slug
    }
  }
}
`;

export default allOutsourceGuideItemsQueryString;
