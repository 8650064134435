import React, { ReactNode } from 'react';
import { titleChange } from 'features/reports/ReportTabs/components/utils/titleChange';

/**
 * Add new or update existing descriptions 
 * @param slug 
 */
export const getDescription = (slug?: string) => {
  switch (slug) {
    case 'cost-per-conversion':
    case 'cost-per-lead':
      return 'The average amount spent per lead acquired.';
    case 'opportunities':
      return <>New leads or prospects generated in the <em>CRM</em></>;
    case 'sessions':
      return 'Total user interactions with your website.';
    case 'customers':
      return 'Total, historic users who have purchased a product or service.';
    case 'revenue':
      return 'Total sales generated on site.';
    case 'total-spend':
    case 'spend':
      return 'Amount spend on advertising like PPC, Facebook, Ads, etc.';
    case 'conversion-rate':
      return 'The number of purchases per session.';
    case 'goal-completions':
    case 'leads':
      return <>Amount of conversions for a specific goal (ie. Phone calls).
        <br />
        <small>Smart Goals are not included in the total for this metric.</small>
      </>;
    case 'blended-roas':
    case 'roas':
      return <><em>ROAS</em> stands for return on ad spend{' '}
      — a marketing metric that measures the amount of revenue your business{' '}
      earns for each dollar it spends on advertising.</>;
    case 'sqls':
      return <><em>SQLs</em> stands for <em>Sales Qualified Leads</em>{' '}
      - meaning prospects or submissions qualified to fit your sales and business model.</>;
    case 'customer-acquisition-cost':
    case 'cac':
      return 'The average Spend per Goal Conversion.  Represents the average cost for every new customer. You can adjust this calculation by editing Goal Filters.';
    case 'average-cost-of-sale':
    case 'acos':
      return 'The average Spend per Goal Conversion. Represents the average cost for each sale.  You can adjust this calculation by editing Goal Filters.';
    case 'total-revenue':
    case 'ecommerce-revenue':
      return 'Total sales generated from orders on site.';
    case 'number-of-orders':
      return 'Total number of orders placed by customers on website.';
    case 'lost-revenue':
    case 'abandoned-cart-revenue':
      return 'Total value of items in abandoned carts that did not convert into E-commerce sales.';
    case 'avg-order-value':
      return 'Average dollar amount spent each time a customer placed an order.';
    case 'repurchase-rate':
      return 'The rate of customers who placed another order within a particular time frame.';
    case 'avg-repurchase-timeframe':
      return 'The average time it took for your customers to repeat a purchase.';
    case 'avg-customer-lifetime-value':
      return 'The average monetary value that a customer provides over their lifetime relationship with your company.';
    default: 
      return 'No information';
  }
};

/**
 * Add new or update existing equation values with an array;
 * Produces Array[0]/Array[1] when used with equation()
 * @param value 
 */
export const equationValues = (value: string): string[] => {
  switch (value) {
    case 'cost-per-conversion':
    case 'cost-per-lead':
      return ['Spend', 'Leads'];
    case 'conversion-rate':
      return ['Purchases', 'Sessions'];
    case 'blended-roas':
    case 'roas':
      return ['Revenue', 'Spend'];
    case 'customer-acqusition-cost':
    case 'cac':
    case 'average-cost-of-sale':
    case 'acos':
      return ['Spend', 'Goal Conversions'];
    case 'avg-order-value':
      return ['Revenue', 'Number of Orders'];
    case 'sqls':
    case 'goal-completions':
    case 'leads':
    case 'opportunities':
    case 'sessions':
    case 'customers':
    case 'revenue':
    case 'total-spend':
    case 'spend':
    case 'abandoned-cart-revenue':
    case 'number-of-orders':
    case 'ecommerce-revenue':
    case 'total-revenue':
    case 'lost-revenue':
    case 'undefined-kpi-metric':
    default:
      return [];
  }
};

export const equation = (values: string[]) => {
  return (
    values.length === 2 ?
      <pre>
      = {titleChange(values[0])} <br /> / {values[1]} 
      </pre> :
      <></>
  );
};

export type KPITerm =
  | 'undefined-kpi-metric'
  | 'cost-per-conversion'
  | 'opportunities'
  | 'sessions'
  | 'customers'
  | 'revenue'
  | 'total-spend'
  | 'conversion-rate'
  | 'goal-completions'
  | 'blended-roas'
  | 'sqls'
  | 'customer-acquisition-cost'
  | 'average-cost-of-sale'
  | 'avg-order-value'
  | 'number-of-orders'
  | 'total-revenue'
  | 'ecommerce-revenue'
  | 'lost-revenue'
  | 'abandoned-cart-revenue';
  

export const kpiterms: {
  [title: string]: { title: string; content: ReactNode };
} = {
  'undefined-kpi-metric': {
    title: 'Undefined KPI Metric',
    content: (
      <p>
        {getDescription()}
      </p>
    ),
  },
  'cost-per-conversion': {
    title: 'Cost Per Conversion',
    content: (
      <>
        <p>
          {getDescription('cost-per-conversion')}
        </p>
        {equation(equationValues('cost-per-conversion'))}
      </>
    ),
  },
  'opportunities': {
    title: 'Opportunities',
    content: (
      <>
        <p>
          {getDescription('opportunities')}
        </p>
        {equation(equationValues('opportunities'))}
      </>
    ),
  },
  'sessions': {
    title: 'Sessions',
    content: (
      <>
        <p>
          {getDescription('sessions')}
        </p>
        {equation(equationValues('sessions'))}
      </>
    ),
  },
  'customers': {
    title: 'Customers',
    content: (
      <>
        <p>
          {getDescription('customers')}
        </p>
        {equation(equationValues('customers'))}
      </>
    ),
  },
  'revenue': {
    title: 'Revenue',
    content: (
      <>
        <p>
          {getDescription('revenue')}
        </p>
        {equation(equationValues('revenue'))}
      </>
    ),
  },
  'total-spend': {
    title: 'Total Spend',
    content: (
      <>
        <p>
          {getDescription('total-spend')}
        </p>
        {equation(equationValues('total-spend'))}
      </>
    ),
  },
  'conversion-rate': {
    title: 'Conversion Rate',
    content: (
      <>
        {equation(equationValues('conversion-rate'))}
      </>
    ),
  },
  'goal-completions': {
    title: 'Goal Completions',
    content: (
      <>
        <p>
          {getDescription('goal-completions')}
        </p>
        {equation(equationValues('goal-completions'))}
      </>
    ),
  },
  'blended-roas': {
    title: 'Blended Roas',
    content: (
      <>
        <p>
          {getDescription('blended-roas')}
        </p>
        {equation(equationValues('blended-roas'))}
      </>
    ),
  },
  'sqls': {
    title: 'SQLs',
    content: (
      <>
        <p>
          {getDescription('sqls')}
        </p>
        {equation(equationValues('sqls'))}
      </>
    ),
  },
  'customer-acquisition-cost': {
    title: 'Customer Acquisition Cost',
    content: (
      <>
        <p>
          {getDescription('customer-acquisition-cost')}
        </p>
        {equation(equationValues('customer-acquisition-cost'))}
      </>
    )
  },
  'average-cost-of-sale': {
    title: 'Average Cost of Sale',
    content: (
      <>
        <p>
          {getDescription('average-cost-of-sale')}
        </p>
        {equation(equationValues('average-cost-of-sale'))}
      </>
    )
  },
  'avg-order-value': {
    title: 'Avg Order Value',
    content: (
      <>
        <p>
          {getDescription('avg-order-value')}
        </p>
        {equation(equationValues('avg-order-value'))}
      </>
    )
  },
  'number-of-orders': {
    title: 'Number of Orders',
    content: (
      <>
        <p>
          {getDescription('number-of-orders')}
        </p>
        {equation(equationValues('number-of-orders'))}
      </>
    )
  },
  'ecommerce-revenue': {
    title: 'E-commerce Revenue',
    content: (
      <>
        <p>
          {getDescription('total-revenue')}
        </p>
        {equation(equationValues('total-revenue'))}
      </>
    )
  },
  'abandoned-cart-revenue': {
    title: titleChange('Lost Revenue'),
    content: (
      <>
        <p>
          {getDescription('lost-revenue')}
        </p>
        {equation(equationValues('lost-revenue'))}
      </>
    )
  },
};
