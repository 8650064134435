/**
 * SectionBuilder -> Fields -> SalesforceAccount
 */

import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { projection } from 'features/audits/AuditOverviewPage/projection';
import { ClientSalesforceSettings } from 'features/clients/ClientOverviewPage/components';
import { updateAudit, useAuditRefreshById } from 'features/entitiesRedux/models/audit';
import { FormItem, notification } from 'components';
import { AppDispatch } from '../../../../../../../../app/store';
import { FieldProps } from '../../types';
import css from '../SalesforceAccount/SalesforceAccount.module.scss';

const SalesforceAccount: FC<FieldProps> = ({ name, form }) => {
  const dispatch: AppDispatch = useDispatch();
  const { id: idRaw = '' } = useParams<{ [x: string]: any }>();
  const auditId = Number.parseInt(idRaw);
  const { audit, refresh: refreshAudit } = useAuditRefreshById(auditId, {
    projection,
  });

  const handleUnselect = async () => {
    form?.setFieldsValue({ salesforce_opportunity_id: '' });
    try {
      const updateAction = await dispatch(updateAudit({
        id: audit?.id,
        salesforce_opportunity_id: ''
      }));

      if (updateAudit.fulfilled.match(updateAction)) {
        notification.success({
          message: 'Appraisal unlinked from Salesforce',
        });
        refreshAudit();
      }

      if (updateAudit.rejected.match(updateAction)) {
        notification.error({
          message: 'There was an error unlinking the Appraisal from Salesforce. Please try again.',
        });
      }
    } catch (e) {
      console.error('Error updating appraisal', e);
    }
  };

  return (
    <FormItem name={name} className={css.root}>
      <ClientSalesforceSettings
        client={audit?.client}
        onUnselectAccount={handleUnselect}
      />
    </FormItem>
  );
};

export default SalesforceAccount;
