/**
 * Create pacing goal
 */

import { Projection } from 'api/entityGraphQL/types';
import { baseApi, cleanArguments } from '../..';
import clientTagTypes from '../../clients/tagTypes';
import tagTypes from '../tagTypes';
import { Scoreboard } from '../types';

export type CreatePacingGoalMutation = {
  report_id: number;
  type: 'monthly' | 'long_term';
  metric: string;
  start?: string;
  term?: number;
  value?: number;
  months?: {
    date: string;
    value: number;
  }[];
  projection?: Projection;
}

export type CreatePacingGoalResult = Scoreboard;

export const CREATE_PACING_GOAL = 'createPacingGoal';

export const { useCreatePacingGoalMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [CREATE_PACING_GOAL]: build.mutation<CreatePacingGoalResult, CreatePacingGoalMutation>({
      transformResponse: (response: { createPacingGoal: Scoreboard }) => response.createPacingGoal,
      invalidatesTags: [
        tagTypes.SCOREBOARD,
        tagTypes.SCOREBOARDS,
        clientTagTypes.CLIENTS,
        clientTagTypes.CLIENT,
      ],
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<CreatePacingGoalMutation> = values;
        return ({
          body: {
            mutation: {
              createPacingGoal: {
                __args: cleanArguments(__args),
                ...projection,
                id: true
              }
            }
          }
        }
        );
      }
    }),
  }),
});
