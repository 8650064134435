/**
 * Icon -> Trash
 */

import React, { FC } from 'react';

const Trash: FC = (props) => (
  <span role="img" className="anticon anticon-user" {...props}>
    <svg
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M7.3 20.4c-.5 0-.9-.2-1.3-.5-.4-.4-.5-.8-.5-1.3V5.9h-1V4.4H9v-.8h6v.9h4.5V6h-1v12.7c0 .5-.2.9-.5 1.3-.4.4-.8.5-1.3.5H7.3zM17 5.9H7v12.7c0 .1 0 .2.1.2.1.1.1.1.2.1h9.4c.1 0 .1 0 .2-.1s.1-.1.1-.2V5.9zm-7.6 11h1.5v-9H9.4v9zm3.7 0h1.5v-9h-1.5v9zM7 5.9v13-13z" />
    </svg>
  </span>
);

export default Trash;
