import React, { useCallback } from 'react';

export interface TagType {
  type?: 'text' | 'color';
  tagName?: string;
  color?: 'green' | 'blue' | 'red' | 'yellow' | 'purple' | 'gray' | 'orange' | 'pink';
  size?: 'small' | 'large';
  width?: string;
  padding?: string;
  margin?: string;
  selected?: boolean;
}

export const Tag = ({
  type = 'text',
  tagName,
  color = 'green',
  size = 'small',
  width = '84px',
  padding,
  margin,
  selected = false,
}: TagType): JSX.Element => {

  const setColor = useCallback((color: string) => {
    switch (color) {
      case 'green':
        return '#A6D7A8';
      case 'blue':
        return '#9ACFED';
      case 'red':
        return '#EDACAC';
      case 'yellow':
        return '#FFE99D';
      case 'purple':
        return '#D4C9EF';
      case 'gray':
        return '#D9D9D9';
      case 'orange':
        return '#F8C49A';
      case 'pink':
        return '#FFBFED';
    }
  }, [])

  switch (type) {
    case 'text':
      return (
        <div
          style={{
            backgroundColor: setColor(color),
            height: '24px',
            width: width,
            borderRadius: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: margin,
            padding: padding,
          }}
        >
            <h6
              style={{
                fontFamily: 'Inter',
                fontSize: '10px',
                fontWeight: 600,
                lineHeight: '12px',
                color: '#2C2C2C',
                textTransform: 'uppercase',
                letterSpacing: '1px',
                margin: 0,
              }}
            >
              {tagName}
            </h6>
        </div>
      );
    case 'color':
      switch (size) {
        case 'small':
          switch (selected) {
            case true:
              return (
                <div
                  style={{
                    backgroundColor: 'none',
                    height: '34px',
                    width: '34px',
                    borderRadius: '16px',
                    border: '1px solid #AEAEAE',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: margin,
                    padding: padding,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: setColor(color),
                      height: '24px',
                      width: '24px',
                      borderRadius: '16px',
                    }}
                  />
                </div>
              );
            case false:
              return (
                <div
                  style={{
                    backgroundColor: setColor(color),
                    height: '24px',
                    width: '24px',
                    borderRadius: '16px',
                    margin: margin,
                    padding: padding,
                  }}
                />
              );
          }
        case 'large':
          return (
            <div
              style={{
                backgroundColor: setColor(color),
                height: '26px',
                width: '26px',
                borderRadius: '16px',
                margin: margin,
                padding: padding,
              }}
            />
          )
      }
  }
};
