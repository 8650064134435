import React, { memo } from 'react';
import classNames from 'classnames';
import { useTheme } from '../../utils';
import css from './SurveyQuestionScore.module.scss';

interface SurveyQuestionScoreProps {
    className?: string;
    score: number;
    max: number;
}

const SurveyQuestionScore = ({ className, score, max }: SurveyQuestionScoreProps) => {
  const { isTalent } = useTheme();
  const scoreClass = typeof score === 'number' ? 'hasValue' : '';

  /** The score displayed on the right side of the question/row **/
  return (
    <div className={classNames(css.root, className)}>
      <div className="question-score-wrapper">
        <span className={classNames(scoreClass, css.score, { [css.talent]: isTalent })}>{typeof score === 'number' ? score : '-'}</span>
        <span className={css.separator}>/</span>
        <span className={css.max}>{typeof max === 'number' ? max : '-'}</span>
      </div>
    </div>
  );
};

export default memo(SurveyQuestionScore);
