import React, { memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, Spinner, useSnackbar } from '@sprnova/nebula';
import { useDeletePacingGoalMutation } from 'api/crudGraphQL/scoreboards/pacing_goals/deletePacingGoal';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { startCase } from 'lodash';
import { useAccount } from 'features/global';
import { track } from 'features/scoreboards/components/mixpanelEvents';
import { useFilterContext } from 'features/scoreboards/utils/Filters';

type DeleteGoalModalProps = {
  open: boolean;
  toggle: React.DispatchWithoutAction;
  type: 'monthly' | 'long_term';
  goalId: number;
};
const DeleteGoalModal = ({ open, toggle, type, goalId }: DeleteGoalModalProps): JSX.Element => {
  const [submitting, setSubmitting] = useState(false);
  const { addSnackbar } = useSnackbar();
  const { id } = useParams<{ [x: string]: string }>();
  const mixpanel = useMixpanel();
  const { account } = useAccount();
  const { setFilter } = useFilterContext();

  const [deletePacingGoal] = useDeletePacingGoalMutation();

  const handleDelete = async (): Promise<void> => {
    try {
      setSubmitting(true);
      await deletePacingGoal({ id: goalId });
      addSnackbar({
        message: `Deleted ${type.replace(/_/g, ' ')} goal`,
        variant: 'success',
        persist: false,
      });
      const { id: accountId, name } = account ?? {};
      track({ mixpanel, type: 'delete-pacing-goal', options: {
        [`${type}_pacing_id`]: goalId,
        scoreboard_id: id,
        user_id: accountId,
        user_name: name,
      } });
    } catch (error) {
      console.error(error);
      addSnackbar({
        message: `Error deleting ${type.replace(/_/g, ' ')} goal`,
        variant: 'error',
        persist: false,
      });

    } finally {
      setSubmitting(false);
      setFilter({ type: 'add', value: { deleted: true } });
      toggle();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={toggle}
      title={`Are you sure you want to delete this ${startCase(type.replace(/_/g, ' '))} Goal?`}
      description='This action cannot be undone.'
      secondaryButtonProps={{
        onClick: toggle, children: 'Cancel', disabled: submitting
      }}
      primaryButtonProps={{
        onClick: handleDelete,
        children: 'Confirm',
        disabled: submitting,
        startIcon: submitting && <Spinner size='small' />
      }}
      maxWidth={312}
    />
  );
};

export default memo(DeleteGoalModal);
