import { RootState } from 'app/store';

/**
 * Loading Flag & Shared Error
 */

// Select global loading state
export const selectIsLoading = (state: RootState) => state.entities.isLoading;

// Select global error object
export const selectError = (state: RootState) => state.entities.error;

// Select errors by action type(s)
export const selectErrorByActionType = (action: string[] | string) => (state: any) => {
  const { error } = state.entities;
  const allowedActionTypesSet = new Set(Array.isArray(action) ? action : [action]);

  if (!allowedActionTypesSet.has(error?.actionType)) {
    return null;
  }

  return error;
};

/**
 * Selectors for entities, clients, contacts, discovery, and user
 * are located in `./models/[MODEL_NAME]/selectors.ts`
 */
