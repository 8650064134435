import { createAsyncThunk } from '@reduxjs/toolkit';
import { novaGraphQLPublicClient } from 'api/entityGraphQL/NovaGraphQLPublicClient';
import { normalizeEntities } from '../../utils/normalizeEntities';
import {
  ActionFetchOneConfig,
} from '../types';

/** Fetch one */
export const fetchExternalClientById = createAsyncThunk(
  'entities/fetchExternalClientById',
  async ({ id, projection }: ActionFetchOneConfig) => {
    const external_clients = await novaGraphQLPublicClient.fetchClientByIdPublic(id, {
      projection,
    });

    return { ...normalizeEntities({ external_clients }) };
  }
);
