import React from 'react';
import { AuditsSurveyStatus, Department } from '../../../../entitiesRedux';
import { ANALYST_SURVEY_STATUS_TYPES } from '../../../auditsSlice';
import AnalystScore from './AnalystScore/AnalystScore';
import { OpportunityScore } from '.';

export type Placement =
  | 'left'
  | 'right'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'leftTop'
  | 'leftBottom'
  | 'rightTop'
  | 'rightBottom';

export interface ScoreBreakdownPopoverProps {
  children?: React.ReactNode;
  score?: number;
  scores?: number[];
  isOpportunityScore?: boolean;
  departments?: Department[];
  analystScore?: number;
  analystScoreMax?: number;
  placement?: Placement;
  surveyStatus?: AuditsSurveyStatus | ANALYST_SURVEY_STATUS_TYPES;
}

export const ScoreBreakdownPopover = ({
  children,
  score,
  scores,
  isOpportunityScore,
  departments,
  placement = 'bottom',
  analystScore,
  analystScoreMax,
  surveyStatus,
}: ScoreBreakdownPopoverProps): JSX.Element => {
  const props = {
    departments,
    score,
    scores,
    isOpportunityScore,
    analystScore,
    analystScoreMax,
    children,
    placement,
    surveyStatus,
  };
  return isOpportunityScore ? (
    <OpportunityScore {...props} />
  ) : (
    <AnalystScore {...props} />
  );
};
