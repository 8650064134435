/**
 * Entities -> Strategies -> Selectors
 */

import { RootState } from 'app/store';
import { selectEntitiesDenormalized } from '../_entity/selectors';
import { Strategy, StrategyFormValues } from './strategy';

export const selectStrategiesByIds = (strategyIds: number[]) => (state: RootState) => {
  const input = { strategies: strategyIds };
  return selectEntitiesDenormalized(state, input).strategies;
};

export const selectStrategyById = (strategyId: number) => (state: RootState) => {
  return selectStrategiesByIds([strategyId])(state)[0];
};

/**
 * Converts a strategy object to a strategy form compatible object
 */
export const selectStrategyFormValues = (strategy: Strategy): StrategyFormValues => {
  const {
    audit,
    author,
    account_manager,
    executive_sponsor,
    business_type,
    client,
    commission_flat_fee,
    commission_percent_adjustment,
    departments,
    lead_source,
    pillar,
    users,
    partner,
    lead_source_employee,
    salesforce_opportunity_id,
    salesforce_is_primary,
    guaranteed_term,
  } = strategy || {};

  return {
    ...strategy,
    audit_id: audit?.id,
    author_id: author?.id,
    client_id: client?.id,
    commission_flat_fee: commission_flat_fee,
    commission_percent_adjustment: commission_percent_adjustment,
    departments: Array.isArray(departments) ? departments.map(department => department.id) : [],
    executive_sponsor_id: executive_sponsor?.id || null,
    lead_source_id: lead_source?.id,
    lead_source_employee_id: lead_source_employee?.id,
    business_type_id: business_type?.id,
    account_manager_id: account_manager?.id || null,
    pillar_id: pillar?.id,
    partner_id: partner?.id,
    users: Array.isArray(users) ? users.map(user => user.id) : [],
    salesforce_opportunity_id: salesforce_opportunity_id || null,
    salesforce_is_primary: salesforce_is_primary,
    guaranteed_term: guaranteed_term,
  };
};
