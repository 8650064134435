import { useRef, useState } from 'react';
import { useLazyGetNovaAIResponseQuery } from 'api/crudGraphQL/nova_ai/getNovaAIResponse';
import { useStartNovaAIPromptMutation } from 'api/crudGraphQL/nova_ai/startNovaAIPrompt';
import { useLazyGetNovaAIResponsePublicQuery } from 'api/crudGraphQL/public/nova_ai/getNovaAIResponsePublic';
import { useStartNovaAIPromptPublicMutation } from 'api/crudGraphQL/public/nova_ai/startNovaAIPromptPublic';
import isExternalUser from 'utils/helpers/isExternalUser';
import retryWithBackoff from 'utils/http/retryWithBackoff';
import shortPoll from 'utils/http/shortPoll';
import { NovaAIResponseStatus, NovaAIResponseType } from 'features/entitiesRedux/models/nova_ai';
import { validateNovaAIResponse, validateNovaAIResponseStatusCode } from './utils';
import { ValidationResult } from './utils/validateNovaAIResponse';

export enum AskNovaAIApi {
  Start,
  Response,
}

export type AskNovaAIResponse = {
  result: NovaAIResponseType,
  api: AskNovaAIApi,
}

type SessionGuidType = `${string}-${string}-${string}-${string}-${string}`;

const SHORT_POLLING_DURATION_SECONDS = 5;

/**
 * Used to ask a question to nova AI, returning a boolean indicating whether
 * the response is loading and a function to be used to initiate the request to ask the question.
 *
 * @returns An object with the following properties:
 *   - `isLoading`: A boolean indicating whether a request is currently in progress.
 *   - `askNovaAI`: A function that makes an asynchronous request and executes a multi-step process
 *                  to retrieve a natural language answer from nova AI.
 *        @param question The question that is to be asked.
 *        @param clientId The client identifier.
 *        @param currentSessionGuid The session identifier.
 *        @returns A promise that resolves to an `AskNovaAIResponse` object containing the response data.
 *   - `refresh`: A function used to re-initiated the multi-step process to retrieve another natural language
 *                answer for an existing prompt.
 *        @param question The question that was asked.
 *        @param clientId The client identifier.
 *        @param promptGuid The GUID of the prompt whose response is to be regenerated.
 *        @param currentSessionGuid The session identifier.
 *        @returns A promise that resolves to an `AskNovaAIResponse` object containing the regenerated response data.
 */
const useAskNovaAI = (): {
  isLoading: boolean;
  askNovaAI: (question: string, clientId: number, currentSessionGuid?: string) => Promise<AskNovaAIResponse>;
  refresh: (question: string, clientId: number, promptGuid: string, currentSessionGuid: string) => Promise<AskNovaAIResponse>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const promptGuidRef = useRef<string | undefined>();
  let sessionGuid: string | SessionGuidType;

  const isExternal = isExternalUser();

  const [startNovaAIPrompt] = useStartNovaAIPromptMutation();
  const [startNovaAIPromptPublic] = useStartNovaAIPromptPublicMutation();
  const [novaAIResponse] = useLazyGetNovaAIResponseQuery();
  const [novaAIResponsePublic] = useLazyGetNovaAIResponsePublicQuery();

  /**
   * Executes the first step in the multi-step process to retrieve a natural language response from nova AI.
   * This step kicks off the backend processing of the user's prompt, returning a prompt GUID and status, among other things.
   *
   * @param question The question that is to be asked.
   * @param clientId The client identifier.
   * @param sessionGuid The session identifier for tracking the user session.
   * @param ref A reference to a mutable ref object to which the prompt GUID is to be written.
   * @returns A promise that resolves to an `AskNovaAIResponse` object containing:
   *          - `api`: AskNovaAIApi.Start
   *          - `response`: The response from the mutation.
   *
   * @throws Error Thrown if the API fails to return a valid prompt GUID.
   */
  const executeStartNovaAIPromptStep = async (
    question: string,
    clientId: number,
    sessionGuid: string,
    ref: React.MutableRefObject<string | undefined>
  ): Promise<AskNovaAIResponse> => {
    const requestFn = isExternal
      ? startNovaAIPromptPublic
      : startNovaAIPrompt;

    const response = await requestFn({
      client_id: clientId,
      projection: {
        error: true,
        prompt_guid: true,
        prompt_text: true,
        session_guid: true,
        status: true,
      },
      prompt_guid: ref.current,
      question,
      session_guid: sessionGuid,
    }).unwrap();

    if (!response.prompt_guid || response.prompt_guid?.trim() === '') {
      throw new Error('The startNovaAIPrompt mutation failed to return a GUID for the user prompt.');
    }

    ref.current = response.prompt_guid.trim();

    return {
      result: response,
      api: AskNovaAIApi.Start,
    };
  };

  /**
   * Retrieves the response for the prompt with the provided GUID. This function utilizes short-polling at a 5-second interval
   * to retrieve the response status, among other pieces of info, until one of the following conditions is met:
   *   - The request fails.
   *   - The request is complete.
   *   - The response contains an invalid or unexpected status code.
   *
   * @param promptGuid The GUID of the prompt.
   * @returns A promise that resolves to an `AskNovaAIResponse` object containing:
   *          - `api`: AskNovaAIApi.Response
   *          - `response`: The response from the mutation.
   */
  const executeNovaAIResponseStep = async (promptGuid: string): Promise<AskNovaAIResponse> => {
    const requestFn = isExternal
      ? novaAIResponsePublic
      : novaAIResponse;

    /**
     * Enter short-polling at 5s intervals and do not stop until
     * the request failed, is complete, or the response contains an
     * invalid/unexpected status code.
     */
    const { promise } = shortPoll<NovaAIResponseType>(
      async () => await requestFn({
        prompt_guid: promptGuid,
        projection: {
          created_at: true,
          displayed_at: true,
          ended_at: true,
          error: true,
          json_response: true,
          prompt_guid: true,
          prompt_text: true,
          response: true,
          session_guid: true,
          status: true,
          version: true,
        }}).unwrap(),
      (response: NovaAIResponseType) => {
        const statusCodeValidationResult = validateNovaAIResponseStatusCode(response);

        return statusCodeValidationResult !== ValidationResult.Valid
          || response.status === NovaAIResponseStatus.Failed
          || response.status === NovaAIResponseStatus.Completed
          || response.status === NovaAIResponseStatus.Cancelled;
      },
      { interval: SHORT_POLLING_DURATION_SECONDS * 1000 }
    );

    return promise
      .then((response) => validateNovaAIResponse(response))
      .then((response) => ({
        result: response,
        api: AskNovaAIApi.Response,
      }));
  };

  /**
   * Executes a multi-step process with retries to retrieve a natural language response from nova AI.
   *
   * @param question The question that is to be asked.
   * @param clientId The client identifier.
   * @param sessionGuid The session identifier for tracking the user session.
   * @param promptGuidRef A reference to a mutable ref object to which the prompt GUID is to be written.
   * @param maxRetries [Optional] The maximum number of retries before giving up. Defaults to 0.
   * @returns A promise that resolves to an `AskNovaAIResponse` object containing the response data.
   *
   * @throws Error Thrown if the prompt GUID is missing during execution.
   */
  const executeAskNovaAISteps = async (
    question: string,
    clientId: number,
    sessionGuid: string,
    promptGuidRef: React.MutableRefObject<string | undefined>,
    maxRetries = 0
  ): Promise<AskNovaAIResponse> => {
    return retryWithBackoff(async () => {
      // Step 1 - Send a request to the nova Insights AI.
      const startNovaAIPromptResult = await executeStartNovaAIPromptStep(question, clientId, sessionGuid, promptGuidRef);

      const promptGuid = promptGuidRef.current;
      if (promptGuid === undefined) {
        throw new Error('Missing prompt GUID.');
      }

      if (startNovaAIPromptResult.result.error) {
        return startNovaAIPromptResult;
      }

      // Step 2 - Enter short-polling with the nova backend to get an update on the status of the request.
      return await executeNovaAIResponseStep(promptGuid);
    }, { maxRetries });
  };

  /**
   * Initiates a multi-step process with short-polling to retrieve a natural language response from nova AI.
   *
   * @param question The question that is to be asked.
   * @param clientId The client identifier.
   * @param currentSessionGuid The session identifier.
   * @returns A promise that resolves to an `AskNovaAIResponse` object containing the response data.
   */
  const askNovaAI = async (question: string, clientId: number, currentSessionGuid?: string): Promise<AskNovaAIResponse> => {
    setIsLoading(true);
    try {
      sessionGuid = crypto.randomUUID();
      return await executeAskNovaAISteps(question, clientId, currentSessionGuid || sessionGuid, promptGuidRef);
    } finally {
      setIsLoading(false);
      promptGuidRef.current = undefined;
    }
  };

  /**
   * Used to regenerate a response to a user prompt. Re-initiates a multi-step process with short-polling
   * to retrieve a natural language response from nova AI.
   *
   * @param question The question that was asked.
   * @param clientId The client identifier.
   * @param promptGuid The GUID of the prompt whose response is to be regenerated.
   * @param currentSessionGuid The session identifier.
   * @returns A promise that resolves to an `AskNovaAIResponse` object containing the regenerated response data.
   */
  const refresh = async (question: string, clientId: number, promptGuid: string, currentSessionGuid: string): Promise<AskNovaAIResponse> => {
    promptGuidRef.current = promptGuid;
    return askNovaAI(question, clientId, currentSessionGuid);
  };

  return { isLoading, askNovaAI, refresh };
};

export default useAskNovaAI;
