import React, { FC, useContext ,useState} from 'react';
import { Form as AntdForm } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import SkeletonInput from 'components/SkeletonInput/SkeletonInput';
import {
  Button as ButtonComponent,
  Form as FormComponent,
  FormItem,
  Spin,
  Alert,
} from 'components';
import { AddNotesContext } from '../context';
import css from './Form.module.scss';

type FormProps = { help?: string };

const Form: FC<FormProps> = ({ help = 'Enter your notes here' }) => {
  const {
    mode,
    notes,
    onSave,
    onCancel,
    loading,
    submitting,
    error,
  } = useContext(AddNotesContext);


  const [note, setNote] = useState<string>('');
  const [form] = AntdForm.useForm();
  const skeleton = !notes && loading;
  const initialValues = { notes: notes };
  
  // Only render in form mode
  if (mode !== 'form') {
    return null;
  }

  function handleOnFinish({ notes }: { notes?: string }): void {
    onSave(notes || '');
  }
  function handleOnCancel(): void {
    onCancel();
  }

  const handleMessageChange = (event: { target: { value: React.SetStateAction<string>; }; }): void => {
    setNote(event.target.value);
  };

  return (
    <>
      <FormComponent
        form={form}
        name="editNotesForm"
        layout="inline"
        onFinish={handleOnFinish}
        initialValues={initialValues}
        className={css.form}
      >
        {/** Notes Input */}
        <FormItem name="notes" help={help} style={{ flex: 1 }}>
          {!skeleton ? (
            <TextArea
              className={css.textArea}
              autoSize={{ minRows: 2, maxRows: 6 }}
              autoFocus
              onChange={handleMessageChange}
            />
          ) : (
            <SkeletonInput />
          )}
        </FormItem>

        {/** Submit */}
        <FormItem>
          <ButtonComponent
            type="primary"
            htmlType="submit"
            disabled={submitting}
            onClick={(): void => {
              onSave(note);
            }}
          >
            Save
          </ButtonComponent>
          <ButtonComponent
            type="link"
            disabled={submitting}
            onClick={handleOnCancel}
          >
            Cancel
          </ButtonComponent>
          <Spin loading={submitting} />
        </FormItem>
      </FormComponent>
      {error && <Alert type="error" message={error} />}
    </>
  );
};

export { Form };
