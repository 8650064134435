/**
 * Card
 */

import React, { FC } from 'react';
import classNames from 'classnames';
import css from './Card.module.scss';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
  hasShadow?: boolean;
}

export const Card: FC<CardProps> = ({ 
  children,
  className,
  hasShadow,
  ...props
}) =>(
  <div 
    className={classNames(
      css.root, 
      { [css.hasShadow]: hasShadow },
      className
    )}
    {...props}
  >
    {children}
  </div>
);