
import React from 'react';
import loadable from '@loadable/component';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';

export const LibraryRoot = loadable(
  () => import('./LibraryRoot'),
  {
    fallback: <PageSkeleton />
  }
);

export * from './departments';
export * from './services';
export * from './tasks';
export * from './demo-client';
