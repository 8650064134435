/**
 * Strategies -> List -> Row -> ActionsColumn
 */

import React, { FC, memo, MouseEvent, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  CopyOutlined,
  DashboardOutlined,
  DesktopOutlined,
  EyeOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { EditIcon, EyeIcon } from '@sprnova/nebula';
import { Form as AntdForm, DatePicker, Modal } from 'antd';
import { Action, Resource } from 'api/accessControl';
import { Props as ActionsDropdownProps } from 'components/ActionsDropdown';
import moment from 'moment';
import pluralize from 'pluralize';
import useMixpanelTrack from 'utils/hooks/useMixpanelTrack';
import {
  createStrategyDuplicate,
  Strategy,
  StrategyDuplicateValues
} from 'features/entitiesRedux';
import { useAccount } from 'features/global/hooks/useAccount';
import { PricingVersion } from 'features/library/constants';
import {
  BlockList,
  Form,
  FormItem,
  notification,
  Option,
  Select,
  SelectClient,
} from 'components';
import css from './ActionsColumn.module.scss';

type Props = {
  strategy: Pick<Strategy, 'id' | 'client' | 'type' | 'length' | 'date' | 'presentation' | 'revenue_calculation_version' | 'pillar' | 'pricing_version'>;
  page?: string;
  menuItemClassName?: string;
  dropdownClassName?: string;
  nebula?: boolean;
};

const ActionsColumn: FC<Props> = ({
  strategy: { id, client, type, date, length, presentation, revenue_calculation_version, pillar, pricing_version },
  page,
  menuItemClassName,
  dropdownClassName,
  nebula,
}) => {
  const dispatch = useDispatch();
  const [form] = AntdForm.useForm();
  const months = useMemo(
    () => Array.from(new Array(24)).map((_, i) => i + 1),
    []
  );
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [clientId, setClientId] = useState(client?.id);
  const mixpanel = useMixpanelTrack();
  const { account, isLoading: isLoadingAccount, error: errorLoadingAccount } = useAccount();
  /**
   * Duplication
   */
  const handleSelectClient = (id: any) => setClientId(id);
  const handleDuplicateClick = (event: MouseEvent) => {
    event.preventDefault();
    setShowDuplicateModal(true);
  };
  const handleDuplicateStrategy = async (values: any) => {
    const duplicateAction: any = await dispatch(
      createStrategyDuplicate({
        ...values,
        id,
        date: values.date.format('YYYY/MM/DD'),
      })
    );

    if (createStrategyDuplicate.fulfilled.match(duplicateAction)) {
      const strategyId = duplicateAction?.payload?.result?.strategies[0];

      notification.success({
        message: 'Blueprint duplicated',
      });

      const path = `/blueprints/${strategyId}#edit-deal-info`;
      trackActionMenuItemClickOnMixpanel(path);
      window.open(path, '_blank', 'noopener,noreferrer');

    }
  };

  const redirectToBlueprintPage = useCallback((): string => {
    let pathToBlueprint = '';
    if (pricing_version?.slug === PricingVersion.PACKAGE) {
      pathToBlueprint = `/blueprints/package/${id}`;
    } else {
      pathToBlueprint = `/blueprints/${id}`;
    }
    return pathToBlueprint;
  }, [id, pricing_version?.slug]);

  const redirectToEditBlueprintPage = useCallback((): string => {
    let pathToEditBlueprint = '';
    if (pricing_version?.slug === PricingVersion.PACKAGE) {
      pathToEditBlueprint = `/blueprints/package/${id}`;
    } else {
      pathToEditBlueprint = `/blueprints/${id}#edit-deal-info`;
    }
    return pathToEditBlueprint;
  }, [id, pricing_version?.slug]);

  const trackActionMenuItemClickOnMixpanel = useCallback((path: string) => {
    if(account && !isLoadingAccount && !errorLoadingAccount) {
      const options = {
        path,
        clientName: client.name,
        clientId: client.id,
        blueprintId: id,
        userName: account.name,
        userDepartmentName: account.department?.name,
      };
      mixpanel('Blueprint Action Menu: Open New Tab', options);
    }
    else if(errorLoadingAccount) {
      console.error('Error loading user account', errorLoadingAccount);
    }
  }, [account, client.id, client.name, errorLoadingAccount, id, isLoadingAccount, mixpanel]);

  const actions = useMemo(() => {
    const items: ActionsDropdownProps['actions'] = [
      {
        label: 'View Blueprint',
        icon: nebula ? <EyeIcon /> : <EyeOutlined />,
        to: redirectToBlueprintPage(),
        target: '_blank',
        rel: 'noopener noreferrer',
        onClick: () => {
          trackActionMenuItemClickOnMixpanel(redirectToBlueprintPage());
        },
      },
      {
        label: 'Edit Blueprint',
        icon: nebula ? <EditIcon /> : <FormOutlined />,
        to: redirectToEditBlueprintPage(),
        target: '_blank',
        rel: 'noopener noreferrer',
        accessControl: {
          action: Action.update,
          resource: Resource.strategy
        },
        onClick: () => {
          trackActionMenuItemClickOnMixpanel(redirectToEditBlueprintPage());
        },
      },
    ];

    if (client?.id) {
      items.push({
        label: 'Duplicate',
        icon: <CopyOutlined />,
        onClick: handleDuplicateClick,
        accessControl: {
          action: Action.create,
          resource: Resource.strategy
        }
      });
      if (page !== 'client') {
        items.push({
          label: 'Client Overview',
          to: `/power-view/accounts/${client?.id}`,
          target: '_blank',
          rel: 'noopener noreferrer',
          icon: <DashboardOutlined />,
          accessControl: {
            action: Action.read,
            resource: Resource.client,
          }
        });
      }
    }

    if (presentation?.deck_url) {
      items.push({
        label: 'View Kickoff Deck',
        href: presentation?.deck_url,
        icon: <DesktopOutlined />,
        target: '_blank',
        rel: 'noopener noreferrer',
        accessControl: {
          action: Action.read,
          resource: Resource.client,
        }
      });
    }

    return items;
  }, [client?.id, nebula, page, presentation?.deck_url, redirectToBlueprintPage, redirectToEditBlueprintPage, trackActionMenuItemClickOnMixpanel]);

  const initialValues: StrategyDuplicateValues = {
    id,
    client_id: client?.id,
    type,
    length,
    date: date ? moment(date) : undefined,
  };

  return (
    <>
      <BlockList.ActionsColumn
        actions={actions}
        dropdownClassName={dropdownClassName}
        menuItemClassName={menuItemClassName}
      />
      <Modal
        destroyOnClose
        title="Duplicate blueprint"
        visible={showDuplicateModal}
        okText="Duplicate"
        onOk={() => form.submit()}
        onCancel={() => setShowDuplicateModal(false)}
        okButtonProps={{
          disabled: !clientId,
        }}
      >
        <Form
          form={form}
          onFinish={handleDuplicateStrategy}
          initialValues={initialValues}
          preserve={false}
          layout="vertical"
        >
          <FormItem label="Client" name="client_id">
            <SelectClient
              placeholder="Select client"
              onSelect={handleSelectClient}
            />
          </FormItem>
          <FormItem
            label="Type"
            name="type"
            rules={[
              { required: true, message: 'Please select a blueprint type' },
            ]}
          >
            <Select placeholder="Retainer or Project">
              <Select.Option value="Retainer">Retainer</Select.Option>
              <Select.Option value="Project">Project</Select.Option>
            </Select>
          </FormItem>
          <FormItem
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.type !== currentValues.type
            }
          >
            {({ getFieldValue }) => {
              return getFieldValue('type') === 'retainer' ? (
                <FormItem
                  label="Length of Contract"
                  name="length"
                  rules={[
                    {
                      required: true,
                      message: 'Please specify the length of the contract',
                    },
                  ]}
                >
                  <Select>
                    {months.map((month) => (
                      <Option value={month} key={month}>
                        {pluralize('month', month, true)}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              ) : null;
            }}
          </FormItem>
          <FormItem
            className={css.lastFormItem}
            label="Start Date"
            name="date"
            rules={[
              { required: true, message: 'Please select a start date' },
            ]}
          >
            <DatePicker format="MM/DD/YYYY" style={{ width: '100%' }} />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default memo(ActionsColumn);
