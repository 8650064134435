import React, { memo, useMemo } from 'react';
import { Box, EmptyState, NavigationAddChartIcon } from '@sprnova/nebula';
import { startCase } from 'lodash';
import CreateLongTermGoalDrawer from './LongTerm/CreateLongTermGoalDrawer';
import CreateMonthlyGoalDrawer from './Monthly/CreateMonthlyGoalDrawer';


type NoGoalsProps = {
  type: 'monthly' | 'long_term';
  isClient?: boolean;
};
const NoGoals = ({ type, isClient }: NoGoalsProps): JSX.Element => {
  const formattedType = type.replace(/_/g, ' ');
  const renderChildren = useMemo(() => {
    if (type === 'monthly') {
      return (
        <Box style={{ paddingTop: '16px' }}>
          <CreateMonthlyGoalDrawer
            buttonProps={{ variant: 'primary', startIcon: null, }}
            title='Create New Monthly Goal'
          />
        </Box>
      );}
    return (
      <Box style={{ paddingTop: '16px' }}>
        <CreateLongTermGoalDrawer
          buttonProps={{ variant: 'primary', startIcon: null, }}
          title='Create New Long Term Goal'
        />
      </Box>
    );
  }, [type]);

  const renderEmptyState = useMemo(() => {
    if (isClient) {
      return (
        <EmptyState
          title='Nothing to see here'
          description={`You don't have any ${formattedType} goals set up. If you have any questions, reach out to your account team.`}
          size='large'
        />
      );
    }
    return (
      <EmptyState
        title={`No ${startCase(formattedType)} Goals`}
        description={`Create a new ${formattedType} goal to get started`}
        icon={<NavigationAddChartIcon />}
        size='medium'
        buttonProps={{
          children: renderChildren
        }}
        padding='24px'
        marginBottom='24px'
      />
    );
  }, [formattedType, isClient, renderChildren]);
  return renderEmptyState;
};

export default memo(NoGoals);
