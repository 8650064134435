import { baseApi, cleanArguments } from 'api/crudGraphQL';
import { Projection } from 'api/entityGraphQL/types';

export type DeleteNovaAIChatMutation = {
  session_guid: string;
  prompt_guid?: string
  projection?: Projection;
}

export type DeleteNovaAIChatResult = { status: string };

export const DELETE_NOVA_AI_CHAT_ENDPOINT_KEY = 'deleteNovaAIChat';

export const { useDeleteNovaAIChatMutation } = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    [DELETE_NOVA_AI_CHAT_ENDPOINT_KEY]: builder.mutation<DeleteNovaAIChatResult, DeleteNovaAIChatMutation>({
      transformResponse: (response: { deleteNovaAIChat: DeleteNovaAIChatResult }) =>  response.deleteNovaAIChat,
      query: ({ projection = {status: true}, ...values}) => {
        const __args: Partial<DeleteNovaAIChatMutation> = values;
        return ({
          body: {
            mutation: {
              deleteNovaAIChat: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        })
      }
    }),
  }),
});
