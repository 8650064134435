/**
 * Get Horizon by URL param
 * and expose useful utility methods
 */

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  GET_HORIZON_ENDPOINT_KEY,
  GetHorizonResult,
  useGetHorizonQuery,
  util
} from 'api/crudGraphQL/horizons/getHorizon';
import { Projection } from 'api/entityGraphQL';
import { Horizon } from 'features/entitiesRedux/models/horizon';
import { horizonsProjection } from '../ViewHorizonPage/projection';

export type UseGetHorizonByUrlParam = (args?: {
  pollingInterval?: number;
  refetchOnMountOrArgChange?: boolean;
}) => {
  // Utils
  updateHorizonCache: (horizon: Partial<Horizon>) => void;
  horizonId: number;
  horizonsProjection: Projection;
  horizonArguments: {
    id: number;
    projection: Projection;
  }

  // RTK Query
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  data?: GetHorizonResult;
}

export const useGetHorizonByUrlParam: UseGetHorizonByUrlParam = ({ pollingInterval = 0, refetchOnMountOrArgChange = true } = {}) => {
  const dispatch = useDispatch();

  const { id } = useParams<{ [x: string]: string }>();
  const horizonId = Number(id ?? 0);
  const horizonArguments = {
    id: horizonId,
    projection: horizonsProjection,
  };

  //  Util: Update the horizon cache in redux for this request
  const updateHorizonCache = (data: Partial<Horizon>) => dispatch(
    util.updateQueryData(GET_HORIZON_ENDPOINT_KEY, {
      ...horizonArguments,
      id: data.id ?? horizonArguments.id, // Allow updating horizon based on the passed ID instead of the URL param ID
    }, (draft) => {
      Object.assign(draft, data);
      return draft;
    })
  );

  return {
    horizonId,
    horizonArguments,
    horizonsProjection,
    updateHorizonCache,

    // RTK Query
    ...useGetHorizonQuery(horizonArguments, {
      skip: !horizonId,
      pollingInterval,
      refetchOnMountOrArgChange,
    })
  };
};
