import React, { FC, useCallback, useReducer } from 'react';
import { ActionMenu } from '@sprnova/nebula';
import { novaGraphQLPublicClient } from 'api/entityGraphQL';
import useMixpanelTrack from 'utils/hooks/useMixpanelTrack';
import { UpsertDialog } from 'features/clients/MyCompanyPage/components/TeamOverview/components';
import {
  UpsertFieldValues
} from 'features/clients/MyCompanyPage/components/TeamOverview/components/UpsertDialog/UpsertDialog';
import { ExternalClient } from 'features/entitiesRedux/models/external_client';
import { ExternalUser } from 'features/entitiesRedux/models/user';

interface Props {
  user?: ExternalUser;
  client?: ExternalClient;
}

const ClientDropdown: FC<Props> = ({ user, client, ...props }) => {
  /**
   * Invite modal
   */
  const [isUpsertDialogOpen, toggleUpsertDialog] = useReducer((open) => !open, false);
  const handleAddTeammate = useCallback((): void => {
    toggleUpsertDialog();
  }, [toggleUpsertDialog]);

  const inviteClientUser = useCallback(async (clientId: number, data: UpsertFieldValues) => {
    try {
      const { name, title, email } = data;
      const projection = {
        id: true,
      };

      return await novaGraphQLPublicClient.inviteClientUser({ client_id: clientId, email, name, title, projection });
    } catch (e) {
      const error: any = e;
      const message = error?.response?.errors?.[0]?.message || 'An unknown error occurred. Please try again.';
      console.error(message);
    }
  }, []);

  const handleUpsertSubmit = useCallback(async (data: UpsertFieldValues) => {
    if (client?.id) {
      await inviteClientUser(client?.id, data);
    }
  }, [client?.id, inviteClientUser]);

  /**
   * Assemble redirect url based on current route
   * @param clientId
   */
  const getRedirectUrl = useCallback((clientId: number): string => {
    const urlParts = window.location.pathname.split('/');
    const currentRouteIndex = urlParts?.indexOf('c') + 2;
    const currentRoute = urlParts[currentRouteIndex];
    return `/c/${clientId}/${currentRoute ?? ''}`;
  }, []);

  const mixpanel = useMixpanelTrack();
  /** Mixpanel Analytics */
  const trackSidebarClickMixpanelEvent = useCallback((client: ExternalClient ): void => {
    const eventName = 'Client Dropdown - Client Clicked';
    const options = {
      userId: user?.id,
      userName: user?.name,
      userEmail: user?.email,
      clientId: client?.id,
      clientName: client?.name,
      isPdmEmployee: user?.is_pdm_employee,
    };
    mixpanel(eventName, options);
  },[mixpanel, user?.email, user?.id, user?.is_pdm_employee, user?.name]);

  return (
    <>
      <ActionMenu {...props}>
        {user?.clients?.map((externalClient: ExternalClient, index: number) => {
          const currentClient = externalClient?.id === client?.id;
          return (
            <ActionMenu.Item
              key={`client-${index}`}
              isActive={currentClient}
              isSelected={currentClient}
              component="a"
              href={getRedirectUrl(externalClient?.id)}
              onClick={() => trackSidebarClickMixpanelEvent(externalClient)}
              sx={currentClient ? { pointerEvents: 'none' } : { pointerEvents: 'initial' }}
            >
              {externalClient?.name}
            </ActionMenu.Item>
          );
        })}
        <ActionMenu.Divider />
        <ActionMenu.Item onClick={handleAddTeammate} sx={{ cursor: 'not-allowed' }}>
          Invite to {client?.name}
        </ActionMenu.Item>
      </ActionMenu>
      <UpsertDialog
        open={isUpsertDialogOpen}
        onClose={toggleUpsertDialog}
        onSubmit={handleUpsertSubmit}
      />
    </>
  );
};

export default ClientDropdown;
