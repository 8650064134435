/**
 * useMultipliers
 * Fetches all multipliers
 */

import { useState, useEffect } from 'react';
import { novaGraphQLClient } from 'api/entityGraphQL';
import { Multiplier } from 'features/entitiesRedux';

type Type = {
  loading: boolean;
  error: string;
  multipliers: Multiplier[];
}

export const useMultipliers: () => Type = () => {
  let isCancelled = false;
  const [multipliers, setMultipliers] = useState<Multiplier[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { multipliers } = await novaGraphQLClient.fetchMultipliers({});
        if (!isCancelled) {
          setError('');
          setMultipliers(multipliers);
          setLoading(false);
        }
      } catch(error) {
        if (!isCancelled) {
          setLoading(false);
          setError(error?.response?.errors[0]?.message || 'Unknown error');
        }
      }
    })();

    return () => {
      isCancelled = true;
    };
  }, []);

  return { multipliers, loading, error };
};

export default useMultipliers;
