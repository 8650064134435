import React, { memo } from 'react';
import { Card as AntdCard } from 'antd';
import { CardProps as AntCardProps } from 'antd/lib/card';
import classNames from 'classnames';
import isExternalUser from 'utils/helpers/isExternalUser';
import css from './Card.module.scss';

export interface CardProps extends AntCardProps {
  className?: string;
  removeInnerMargin?: boolean;
}

const Card = ({ className, removeInnerMargin = false, ...props }: CardProps): JSX.Element => {
  const isClient = isExternalUser();

  return (
    <AntdCard
      {...props}
      className={
        classNames(
          className,
          css.root,
          {
            [css.isClient]: isClient,
            [css.removeInnerMargin]: removeInnerMargin
          },
        )
      }
    />
  );
};

export default memo(Card);
