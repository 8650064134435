import { getClient } from 'api/entityGraphQL/utils';
import { QuestionHandler } from '../actions';

export const auditNameHandler: QuestionHandler = (id, key, value, additionalInfo) => {
  const apiClient = getClient();
  return apiClient.updateAudit({
    id: additionalInfo?.auditId,
    name: additionalInfo?.auditName,
  }, {projection: additionalInfo?.auditProjection});
};
