/**
* Get strategy
*/

import { baseApi } from 'api/crudGraphQL';
import { Projection } from 'api/entityGraphQL';
import { Strategy, YesNo } from 'features/entitiesRedux';
import { strategyServiceSummaryProjection } from 'features/strategies/strategyProjection';

export type GetStrategyQuery = {
  id: number;
  round_nearest?: YesNo;
  projection?: Projection;
};

export type GetStrategyResult = Strategy;

export const GET_STRATEGY_ENDPOINT_KEY = 'getStrategy';

export const { useGetStrategyQuery, useLazyGetStrategyQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_STRATEGY_ENDPOINT_KEY]: build.query<GetStrategyResult, GetStrategyQuery>({
      transformResponse: (response: { strategy: GetStrategyResult }) => response.strategy,
      query: ({
        projection = { id: true, name: true },
        id,
        round_nearest
      }) => {

        if (round_nearest) {
          projection = {
            ...projection,
            department_summary: {
              __args: { round_nearest },
              ...strategyServiceSummaryProjection,
              calculation_notes: true,
            },
            service_summary: {
              __args: { round_nearest },
              ...strategyServiceSummaryProjection,
            }
          };
        }

        return {
          body: {
            query: {
              strategy: {
                __args: { id },
                ...projection,
              }
            }
          }
        };
      }
    })
  })
});
