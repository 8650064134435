import { Projection } from 'api/entityGraphQL';

export const departmentsProjection: Projection = {
  id: true,
  name: true,
  services: {
    id: true,
    name: true,
    pricing_version: {
      slug: true,
    },
    tasks: {
      id: true,
      children: {
        id: true,
        name: true,
      },
      name: true,
      next_revision: {
        id: true,
        name: true,
      },
      pricing_tiers: {
        id: true,
        name: true,
        price: true,
        deliverable: true,
        spend_min: true,
        spend_max: true,
      },
      pricing_type: {
        id: true,
        name: true,
        slug: true,
        description: true
      },
      pricing_version: {
        slug: true,
      },
      service: {
        id: true,
        name: true,
      },
      snippet: true,
      snippet_summary: true,
      strategy_frequency: {
        id: true,
        name: true
      }
    },
    __args: {
      pricing_version: 'package'
    }
  },
};
