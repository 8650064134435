export enum ReviewType {
  Self = 'self',
  Manager = 'manager',
  Peer = 'peer',
  Report = 'report',
  Reviewee = 'reviewee',
}

export enum SurveyStatus {
  Disabled = 'disabled',
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Partial = 'partial',
  Pending = 'pending',
  Complete = 'complete',
}

export enum Progress {
  INCREASE = 'increase',
  DECREASE = 'decrease',
  SAME = 'same',
}

export enum SurveyScoreType {
  Regular = 'regular',
  Extra = 'extra',
}

export enum TalentMenuItems {
  ReviewDashboard = 'Review Dashboard',
  MyReviewCycle = 'My Review Cycle',
  HedgeHog = 'Hedgehog',
  Vital5 = 'Vital 5',
  ReviewLog = 'Review Log',
  AllCycles = 'All Cycles',
  UpdateSurvey = 'Update Survey',
}
