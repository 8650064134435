/**
 * Requirements document
 * https://docs.google.com/spreadsheets/d/1ueOMaVZaWGU9FoDC4qMEGnU0DPJ3WTMx_BmjAzt4cPA/edit#gid=0
 */

import { AccessControl } from 'accesscontrol';
import {
  accountDirectors,
  accounting,
  accountManagers,
  anonymous,
  cLevel,
  client,
  clientServices,
  contractor,
  departmentDirectors,
  humanResources,
  groupDirector,
  newHire,
  operationsAdmin,
  salesPersons,
  strategists,
  superAdmin,
  vpLevel,
  externalProviderVerification,
} from './grants';

const grants = Object.assign(
  accountDirectors,
  accounting,
  accountManagers,
  anonymous,
  cLevel,
  client,
  clientServices,
  contractor,
  departmentDirectors,
  humanResources,
  groupDirector,
  newHire,
  operationsAdmin,
  salesPersons,
  strategists,
  superAdmin,
  vpLevel,
  // role for internal reviewers to verify data
  externalProviderVerification,
  {}
); // { ...superAdmin };
export const accessControl = new AccessControl(grants);
