import React, { memo, useCallback, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, TextField, MonetaryField, Textarea } from '@sprnova/nebula';
import classNames from 'classnames';
import { TextEditor } from 'components/TextEditor';
import { maxCharactersSnippetSummary } from '../constants';
import { ActionType, FormDataType } from '../packageStrategyTypes';
import css from '../package-strategies.module.scss';

type AddOnMultiplierContractDetailsProps = {
  isSubmitted: boolean;
  dispatch: React.Dispatch<any>;
  formData: Partial<FormDataType>;
  formDataCached: Partial<FormDataType>;
}

const AddOnMultiplierContractDetails = ({ isSubmitted, dispatch, formData, formDataCached }: AddOnMultiplierContractDetailsProps): JSX.Element => {
  /**
   * Initialize form data with empty values otherwise the form keys won't exist
   * causing the form to be submit-able
   */
  const initializeEmptyForm = useCallback(() => {
    if (formDataCached.pricing_type_slug !== formData.pricing_type_slug) {
      dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_SINGLE_TIER, payload: {
        deliverable: undefined,
        price: undefined,
        snippet: '',
      }});
      dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_DATA, payload: { snippet_summary: '' }});
    }
  }, [dispatch, formData.pricing_type_slug, formDataCached.pricing_type_slug]);

  useEffect(() => {
    initializeEmptyForm();
  }, [initializeEmptyForm]);

  const handleAddOnMultiplierDeliverablePriceChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_SINGLE_TIER, payload: { price: Number.parseFloat(event.target.value) }});
  }, [dispatch]);

  const handleAddOnMultiplierDeliverableNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_SINGLE_TIER, payload: {
      deliverable: event.target.value
    }});
  }, [dispatch]);

  const handleAddOnMultiplierSnippetChange = useCallback((value: string) => {
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_SINGLE_TIER, payload: { snippet: value }});
  }, [dispatch]);

  return (
    <>
      <Grid container spacing={2} sx={{ marginBottom: '24px', marginTop: '24px' }}>
        <Grid xs={6}>
          <TextField
            id="addOnMultiplierDeliverableName"
            error={!formData?.pricing_tiers?.[0]?.deliverable && isSubmitted}
            label="Deliverable Name"
            onChange={handleAddOnMultiplierDeliverableNameChange}
            value={formData?.pricing_tiers?.[0]?.deliverable}
          />
        </Grid>
        <Grid xs={6}>
          <MonetaryField
            id="addOnMultiplierDeliverablePrice"
            error={!formData?.pricing_tiers?.[0]?.price && isSubmitted}
            helperText="Price per 1 deliverable"
            label="Single Deliverable Price"
            onChange={(event): void => handleAddOnMultiplierDeliverablePriceChange(event)}
            value={formData?.pricing_tiers?.[0]?.price}
          />
        </Grid>
      </Grid>
      <Textarea
        id="snippetSummaryField"
        error={formData?.snippet_summary && formData?.snippet_summary.length > maxCharactersSnippetSummary && isSubmitted}
        placeholder="This will appear in a tooltip when a user is adding this strategy to a Blueprint"
        label="Snippet summary"
        maxCharacters={maxCharactersSnippetSummary}
        minRows={2}
        resizeable
        sx={{ marginBottom: '24px' }}
        value={formData?.snippet_summary}
        onChange={(event): void => dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_DATA, payload: { snippet_summary: event.target.value } })}
      />
      <Typography sx={{ fontWeight: 600 }} display="block" gutterBottom>
        Proposal Snippet
      </Typography>
      <TextEditor
        value={formData?.pricing_tiers?.[0]?.snippet}
        className={classNames(css.text_editor, !formData?.pricing_tiers?.[0]?.snippet && isSubmitted ? css.snippet_error : undefined)}
        onChange={handleAddOnMultiplierSnippetChange}
      />
    </>
  );
};

export default memo(AddOnMultiplierContractDetails);
