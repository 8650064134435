import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAvailableDataSourcesQuery,
  novaGraphQLClient,
} from 'api/entityGraphQL';
import { normalizeEntities } from 'features/entitiesRedux/utils';
import { UpdateDataQIntegrationApiParameters } from '.';

/** Fetch Available Data Sources */
export const getAvailableDataSources = createAsyncThunk(
  'entities/getAvailableDataSources',
  async () => {
    const { datasources } = await getAvailableDataSourcesQuery();

    return { ...normalizeEntities({ datasources }) };
  }
);

/** Update DataQ Integration */
export const updateDataQIntegration = createAsyncThunk(
  'entities/updateDataQIntegration',
  async ({
    id,
    form_values
  }: UpdateDataQIntegrationApiParameters) => {
    await novaGraphQLClient.updateDataQIntegration({
      id,
      form_values
    });
  }
);
