import React, { FC } from 'react';
import loadable from '@loadable/component';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';
import { Skeleton } from 'components';
import { Status } from '../../components';
import { useGetAnalystSurveyQuestionGroupByUrlParam } from '../../hooks/useGetAnalystSurveyQuestionGroupByUrlParam';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LoadableComponent: any = loadable(
  () => import('./AnalystSurveysQuestionsPage'),
  {
    fallback: <PageSkeleton />
  }
);

const Title: FC = () => {
  const {
    data: questionGroup,
    isLoading,
  } = useGetAnalystSurveyQuestionGroupByUrlParam();

  if (!questionGroup && isLoading) {
    return <Skeleton title={{ width: 150 }} paragraph={false} active />;
  }

  return !questionGroup ? <>Not found</> : <>{questionGroup.name}<Status status={questionGroup.status} circle hideLabel /></>;
};

LoadableComponent.Title = Title;

export { LoadableComponent as AnalystSurveysQuestionsPage };
