/**
 * useIndicators
 * Fetches all indicators
 */

import { useState, useEffect } from 'react';
import { getClient } from 'api/entityGraphQL/utils';
import { Indicators, YesNo } from 'features/entitiesRedux';


type Type = {
  loading: boolean;
  error: string;
  indicators: Indicators[];
}
type Args = {
  id?: number;
  name?: string;
  business_type_id?: number;
  goal_pacing?: YesNo;
}

export const useIndicators: ({ ...args }: Args) => Type = ({ ...args }: Args) => {
  const [indicators, setIndicators] = useState<Indicators[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { indicators } = await getClient().fetchReportIndicators({ ...args });
        setError('');
        setIndicators(indicators);
        setLoading(false);
      } catch(error) {
        setLoading(false);
        setError(error?.response?.errors?.[0]?.message || 'Unknown error');
      }
    })();
  }, []);

  return { indicators, loading, error };
};

export default useIndicators;
