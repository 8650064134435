/**
* Get analyst question group
*/

import { baseApi, cleanArguments } from 'api/crudGraphQL';
import { Projection } from 'api/entityGraphQL/types';
import { GetAnalystQuestionGroupsResult } from './getAnalystQuestionGroups';
import tagTypes from './tagTypes';
import { AnalystQuestionGroup } from './types';

export const GET_ANALYST_QUESTION_GROUP_ENDPOINT_KEY = 'getAnalystQuestionGroup';

export type GetAnalystQuestionGroupQuery = {
  projection?: Projection;
  id: number;
  revision?: number | null;
}

export type GetAnalystQuestionGroupResult = AnalystQuestionGroup;

export const { useGetAnalystQuestionGroupQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_ANALYST_QUESTION_GROUP_ENDPOINT_KEY]: build.query<GetAnalystQuestionGroupResult, GetAnalystQuestionGroupQuery>({
      transformResponse: (response: { analyst_question_groups: GetAnalystQuestionGroupsResult }) => response.analyst_question_groups?.data?.[0],
      providesTags: (analystQuestion) => {
        return analystQuestion ? [
          {
            id: analystQuestion.id,
            type: tagTypes.ANALYST_SURVEY_QUESTION_GROUP
          }
        ] : [tagTypes.ANALYST_SURVEY_QUESTION_GROUP];
      },
      query: ({
        projection = { id: true, name: true },
        ...args
      }) => ({
        body: {
          query: {
            analyst_question_groups: {
              __args: cleanArguments({
                ...args,
                limit: 1,
                page: 1,
              }),
              data: projection,
            },
          }
        }
      }),
    }),
  }),
});
