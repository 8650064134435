import React from 'react';
import { Redirect } from 'react-router';
import { RouteConfig } from 'routes';

type RedirectConfig = {
  from: string;
  to: string;
  exact?: boolean;
};
const redirectConfigs: RedirectConfig[] = [
  { from: '/audits/:id/:any', to: '/appraisals/:id/:any', exact: false },
  { from: '/audits/:id', to: '/appraisals/:id', exact: false },
  { from: '/audits', to: '/appraisals', exact: true },
  { from: '/strategies/:id/:any', to: '/blueprints/:id/:any', exact: false },
  { from: '/strategies/:id', to: '/blueprints/:id', exact: false },
  { from: '/strategies', to: '/blueprints', exact: true },
  { from: '/clients*', to: '/power-view/accounts' },
  { from: '/performance*', to: '/power-view/performance' },
  { from: '/account-assignments*', to: '/power-view/account-assignments' },
  { from: '/service-expansion*', to: '/power-view/service-expansion' },
  { from: '/am-dashboards*', to: '/power-view/am-dashboards' },
  // { from: "/reports/:id/:any", to: "/scoreboard/:id/:any", exact: false },
  // { from: "/reports/:id", to: "/scoreboard/:id", exact: false },
  // { from: "/reports", to: "/scoreboard", exact: true },
];

export const redirects: RouteConfig[] = redirectConfigs.map((redirect) => ({
  path: redirect.from,
  name: 'redirect',
  component: ({ ...props }) => {
    // Wildcard redirect
    if (redirect.from.slice(-1) === '*') {
      return <Redirect to={props.match.url.replace(redirect.from.replace('*', ''), redirect.to)} />;
    }

    if (props?.match?.params) {
      const to = redirect.to
        .split('/')
        .map<string>((part) => {
          if (part.charAt(0) === ':') {
            const key = part.substr(1);
            if (Object.keys(props.match.params).includes(key)) {
              return props.match.params[key];
            }
          }
          return part;
        })
        .join('/');
      return <Redirect to={to} />;
    }

    return <Redirect to={redirect.to} />;
  },
  exact: redirect?.exact !== undefined ? redirect.exact : true,
}));
