/**
 * Library -> Departments
 */

import React, {FC, useMemo, memo, useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Container, PlusIcon, Button, Skeleton } from '@sprnova/nebula';
import { Action } from 'api/accessControl';
import { useDeleteDepartmentMutation } from 'api/crudGraphQL/departments/deleteDepartment';
import { useGetDepartmentsQuery } from 'api/crudGraphQL/departments/getDepartments';
import {StringParam, useQueryParam, withDefault} from 'use-query-params';
import { useMixpanelTrack } from 'utils';
import { Resource, Department } from 'features/entitiesRedux';
import { useAccount } from 'features/global/hooks/useAccount';
import LibraryPageHero from 'features/library/components/LibraryPageHero/LibraryPageHero';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import {
  AccessControl,
  notification,
  NoResultsFound,
} from 'components';
import { List } from './components';

const Departments: FC = () => {
  /**
   * Fetch departments
   */
  const { data: departments = [], isLoading, isFetching } = useGetDepartmentsQuery({
    projection: {
      id: true,
      name: true,
    }
  });

  const [pricingVersionQueryParam,] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );
  const isPackageVersion = pricingVersionQueryParam === PricingVersion.PACKAGE;

  const mixpanel = useMixpanelTrack();

  /**
   * Use ref to trigger useEffect only once
   */
  const didSendPageViewEventRef = useRef(false);

  const { account } = useAccount();

  // Track mixpanel event when page is visited for package version only
  useEffect(() => {
    if (!didSendPageViewEventRef.current && account && isPackageVersion) {
      didSendPageViewEventRef.current = true;
      mixpanel('Departments Page Viewed', {
        title: account?.name,
        userId: account?.id,
        userName: account?.name,
        department: account?.department?.name,
        departments: JSON.stringify(account?.departments?.map((department: Department) => department.name))
      });
    }
  }, [account, isPackageVersion, mixpanel]);

  /**
   * Delete department
   */
  const [deleteDepartmentRequest, { isLoading: isDeletingDepartment }] = useDeleteDepartmentMutation();

  const handleDelete = useCallback(async (department: Partial<Department>): Promise<void> => {
    try {
      if (!department || !department.id || !department.name) {
        throw new Error('Department not found');
      }

      await deleteDepartmentRequest({ id: department.id });

      notification.success({
        message: 'Department deleted',
      });

      mixpanel('Department Deleted', { departmentName: department.name });

    } catch (error) {
      notification.error({
        message: 'Failed to delete department',
      });
      console.error('Error deleting department', error);
    }
  }, [deleteDepartmentRequest, mixpanel]);

  /**
   * Render the hero button
   * @returns JSX.Element - The hero button
   */
  const renderHeroButton = useCallback((): JSX.Element => {
    return (
      <Button
        variant="primary"
        size="large"
        to={`/library/departments/new?pricingVersion=${pricingVersionQueryParam}`}
        component={Link}
        startIcon={<PlusIcon />}
      >
       New Department
      </Button>
    );
  }, [pricingVersionQueryParam]);

  /**
   * If data are loading, display a skeleton
   * else display the hero button:
   * - If pricing version is Package, use Library Package Access Control.
   * @returns JSX.Element - The hero button
   */
  const renderHeroButtonContainer = useCallback((): JSX.Element => {
    if (isLoading) {
      return <Skeleton width={200} height={50} />;
    } else {
      const resource = isPackageVersion ? Resource.libraryDepartment : Resource.department;
      return (
        <AccessControl
          key="create-department"
          action={[Action.create]}
          resource={resource}
          showWarning={false}
        >
          {renderHeroButton()}
        </AccessControl>
      );
    }
  }, [isLoading, isPackageVersion, renderHeroButton]);

  /**
   * If no departments in the library exist, display a no results found message
   * Else display list of library departments.
   * @returns JSX.Element - The library Departments content
   */
  const renderContent = (): JSX.Element => {
    if(!departments.length && !isLoading) {
      return <NoResultsFound title="No departments created" />;
    } else {
      return (
        <List
          onDelete={handleDelete}
          loading={isDeletingDepartment || isFetching || isLoading}
          departments={departments}
        />
      );
    }
  };

  /**
   * If pricing version is Package, use Library Package Access Control.
   * @returns JSX.Element - The library Departments content container
   */
  const renderContentContainer = (): JSX.Element => {
    const resource = isPackageVersion ? Resource.libraryDepartment : Resource.department;
    return (
      <AccessControl action={[Action.read]} resource={resource}>
        {renderContent()}
      </AccessControl>
    );
  };

  return (
    <>
      <LibraryPageHero
        title="Departments"
        end={renderHeroButtonContainer()}
      />
      <Container hasVerticalPadding>
        {isLoading ? <Skeleton height={200} /> : renderContentContainer()}
      </Container>
    </>
  );
};

export default memo(Departments);
