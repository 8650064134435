import { Pagination, Projection } from 'api/entityGraphQL';
import { baseApi, cleanArguments, paginationProjection } from '..';
import { CustomerInsightsDefaultProjection } from './customerInsightDefaultProjection';
import tagTypes from './tagTypes';
import { CustomerInsightType } from './types';

export type GetCustomerInsightsQuery = {
  id?: number;
  date_range_id?: number;
  workspace_id?: string;
  client_id?: number;
  page?: number;
  limit?: number
  sort?: number[];
  projection?: Projection;
  /*
   consumer_insight_ltvs args
  */
  group_id?: number;
  state_id?: number;
  region_id?: number;
}

export type GetCustomerInsightsResult = Pagination & {
  data: CustomerInsightType[]
};

export const { useGetCustomerInsightsQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCustomerInsights: build.query<GetCustomerInsightsResult, GetCustomerInsightsQuery>({
      transformResponse: (response: { consumer_insights: GetCustomerInsightsResult }) => response.consumer_insights,
      providesTags: (result) => {
        const { data } = result ?? { data: [] };
        return [
          tagTypes.CUSTOMER_INSIGHTS,
          ...data.map((insight: CustomerInsightType) => ({
            id: insight.id,
            type: tagTypes.CUSTOMER_INSIGHTS,
          }))
        ];
      },
      query: ({
        projection = CustomerInsightsDefaultProjection,
        client_id,
        date_range_id,
        group_id,
        state_id,
        region_id,
      }) => {
        const __args = cleanArguments({ client_id, date_range_id });
        const ltvArgs = cleanArguments({ group_id, state_id, region_id });
        const ltvProjection = projection.consumer_insight_ltvs
          ? { consumer_insight_ltvs: { __args: ltvArgs, ...projection.consumer_insight_ltvs } }
          : {};
        return {
          body: {
            query: {
              consumer_insights: {
                __args,
                data: {
                  ...projection,
                  ...ltvProjection,
                },
                ...paginationProjection,
              }
            }
          }
        };
      }
    })
  })
});
