/**
 * Components -> ScrollForm -> Sidebar -> Item
 */

import React, { FC, memo, useMemo } from 'react';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import { IScrollFormSection } from '../../../../types';
import css from './Item.module.scss';

type Props = IScrollFormSection & {
  active: boolean;
  onClick: (id: string, e: React.MouseEvent<HTMLElement>) => void;
};

const Item: FC<Props> = ({ active, id, label, progress, onClick }) => {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    onClick(id, e);
  };

  const status = useMemo(() => {
    const [completed, total] = progress || [0, 0];
    const percentage = Math.floor((completed / total) * 100);
    
    if (percentage < 50) {
      return 'less-than-50';
    }
    
    if (percentage >= 50 && percentage < 85) {
      return 'between-50-and-85';
    }
    
    if (percentage >= 85) {
      return 'above-85';
    }

    return null;
  }, [progress]);

  const renderProgress = useMemo(() => {
    if (!Array.isArray(progress) || typeof progress[0] === 'undefined') {
      return null;
    }

    return (
      <span className={css.button__progress}>
        {`${progress[0]} / ${progress[1]}`}
      </span>
    );
  }, [progress]);

  return (
    <li className={classnames(
      css.root, 
      { [css.active]: active },
      { [css[`status-${status}`]]: status }
    )}>
      <a className={css.button} href={`#${id}`} onClick={handleClick}>
        <span className={css.button__label}>
          {label}
        </span>
        {renderProgress}
      </a>
      <span className={css.arrow} />
    </li>
  );
};

export default memo(Item, (prev, next) => isEqual(prev.progress, next.progress) && prev.active === next.active);
