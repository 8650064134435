import React, { memo } from 'react';
import { Box, Card, CardHeader, Chip, Typography } from '@sprnova/nebula';
import css from './NewSessionPage.module.scss';

type NewSessionPageProps = {
  onQuestionSelect: (question: string) => void;
  questionLimitReached?: boolean;
  trackHelpCenterMixpanel: (buttonClicked: string) => void;
}

const questions = [
  'What are the top 10 average lifetime net sales per customer?',
  'What product sold the highest quantity last month?',
  'How many customers do I have in each state?',
  'What product had the highest number of repeat purchases last month?'
];

const links = [
  {
    href: 'https://help.novapower.io/en/articles/9736314-how-to-talk-to-your-data-nova-insights-ai',
    text: 'How to Talk to Your Data'
  },
  {
    href: 'https://help.novapower.io/en/articles/9400632-nova-gtp-prompt-examples',
    text: 'novaGPT Question Guide'
  },
  {
    href: 'https://help.novapower.io/en/articles/9376739-nova-insights-ai-faq',
    text: 'FAQs'
  },
];

const NewSessionPage = ({ onQuestionSelect, questionLimitReached, trackHelpCenterMixpanel }: NewSessionPageProps): JSX.Element => {
  return (
    <Box margin={'0 25%'} display={'flex'} flexDirection={'column'} height={'100%'} className={css.root}>
      <Typography variant='h1' textAlign={'center'} marginBottom={'25px'} marginTop={'10px'}>Meet novaGPT</Typography>
      <Card className={css.capabilities} header={<CardHeader className={css.capabilitiesHeader} title='Getting started with novaGPT'/>}>
      At Power Digital, we&apos;ve harnessed the power of AI to create nova Insights AI—an advanced application designed to make it fast and easy to uncover critical insights about your business. 
      To maximize the value of this innovative application, it&apos;s essential to learn how to ask AI questions to quickly get the insights you are looking for. 
      Below are some tips to help you get started using this first-of-its-kind AI application.
        <br/>
        <br/>
        <Typography fontWeight={'bold'}>
        Top 3 Tips to a Successful Question:
        </Typography>
        <ol>
          <li>
            Be Specific with Time Frames - use dates, date ranges, named periods, e.g., week 14, months/quarter VS terms like yesterday, last month, or summer.
          </li>
          <li>
            Use Clear and Defined Metrics
          </li>
          <li>
            Avoid Ambiguity - even as we continue to train our AI, novaGPT will alert you if it considers a question not specific enough to provide a defined insight, and will prompt for clarification.
          </li>
        </ol>
        Making sure to follow the tips above, you can maximize the potential of your data, making informed decisions that drive your business forward. We’ve included a few questions to get you started below.
        <br/>
        <br/>
        <Typography fontWeight={'bold'}>
          More Resources to Help you Get Started:
        </Typography>
        <ul style={{paddingLeft: '20px'}} className={css.resources}>
          {links.map((link, key) => (
            <li key={key}>
              <a href={link.href} target='_blank' rel='noreferrer' onClick={() => trackHelpCenterMixpanel(link.text)}>
                {link.text}
              </a>
            </li>
          ))}
        </ul>
      </Card>
      <Card className={css.capabilities} header={<CardHeader className={css.capabilitiesHeader} title='What’s Coming Next'/>}>
        <ul style={{paddingLeft: '20px'}}>
          <li>
            Adding and training more of your business data for novaGPT. We’re committed to continuing to expand and train novaGPT allowing for access to broader insights to move the needle for your business.
          </li>
          <li>
            Expanded visual presentation. Throughout the beta, novaGPT will be advancing to support new formats and ways to present insights making it easier to understand your data and share directly to reports.
          </li>
        </ul>
      </Card>
      <div className={css.suggestionsContainer}>
        <Card className={css.suggestions} header={<CardHeader className={css.suggestionsHeader}  title='Questions to ask'/>}>
          {questions.map((question, key) => (
            <div key={key}>
              <Chip label={question} clickable onClick={() => onQuestionSelect(question)} disabled={questionLimitReached}/>
            </div>
          ))}
        </Card>
      </div>
      <Box mt={1} pb={2}>
        <Typography textAlign={'center'} fontSize={12}>This Beta product may display inaccurate information, please double check the response and select the thumbs down if you encounter inaccurate information.</Typography>
      </Box>
    </Box>
  );
};

export default memo(NewSessionPage);