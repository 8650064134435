import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';

const useMixpanelTrack = () => {

  const mixpanel = useMixpanel();

  return (
    eventName: string,
    options: any
  ): void => {
    if (!mixpanel) return;
    try {
      const mixpanelOptions = {
        title: options?.title,
        appraisalId: options?.audit?.id,
        appraisal: options?.audit?.name,
        clientId: options?.client?.id,
        clientName: options?.client?.name,
        partnerId: options?.partner?.id,
        businessType: options?.client?.business_type?.name,
        businessTypeId: options?.client?.business_type?.id,
        businessTypeSlug: options?.client?.business_type?.slug,
        ...options,
      };
      console.log('🛤 Track: ' + eventName, mixpanelOptions);
      if (mixpanel?.track) {
        const trackingResponse = mixpanel.track(eventName, mixpanelOptions);
        console.log('mixpanel tracking response', trackingResponse);
      } else {
        throw new Error('Mixpanel.event is not defined');
      }
    } catch (error) {
      console.error('Track Mixpanel error', error);
    }
  };
};
export default useMixpanelTrack;
