import { Projection } from 'api/entityGraphQL/types';
import { getClient } from 'api/entityGraphQL/utils';

export const projection: Projection = {
  ...getClient().defaultAuditProjection,
  id: true,
  salesforce_opportunity_id: true,
  client: {
    ...(getClient().defaultAuditProjection?.client || {}),
    id: true,
    website: true,
    logo: true,
    salesforce_client_id: true,
    salesforce_client_name: true,
    business_type: {
      id: true,
      name: true,
    },
    industry: {
      id: true,
      name: true,
    },
    lead_source: {
      id: true,
      name: true,
    },
    status: true,
    contacts: {
      id: true,
      name: true,
      email: true,
      title: true,
      linkedin: true,
      notes: true,
      is_decision_maker: true,
    },
  },
  discovery: {
    ...getClient().defaultAuditProjection.discovery,
    opportunity_score: true,
    analyst_score: true,
    analyst_score_max: true,
    departments: {
      ...getClient().defaultAuditProjection.discovery.departments,
      score: true,
      max: true,
      notes: true,
      in_survey: true,
      scores: {
        ...getClient().defaultAuditProjection.discovery.departments
          .scores,
        score: true,
        buckets: {
          ...getClient().defaultAuditProjection.discovery.departments
            .scores.buckets,
          score: true,
        },
      },
    },
    discovery_score: true,
    products: {
      ...getClient().defaultAuditProjection.discovery.products,
      cost: true,
      margin: true,
    },
    channels: {
      ...getClient().defaultAuditProjection.discovery.channels,
      percent: true,
      notes: true,
    },
    lead_channels: {
      ...getClient().defaultAuditProjection.discovery.lead_channels,
      percent: true,
      notes: true,
    },
    competitors: {
      ...getClient().defaultAuditProjection.discovery.competitors,
      website: true,
    },
    questions: {
      id: true,
      group_name: true,
      answer: true,
    },
    topics: {
      id: true,
      slug: true,
      name: true,
      order: true,
      count: true,
      total: true,
      notes: true,
      questions: {
        prospect_question: true,
        type: true,
        question: true,
        id: true,
        fields: true,
        options: true,
        placeholder: true,
        format: true,
        group_name: true,
        answer: true,
        is_active: true,
        subtext: true,
        business_type: {
          id: true,
          name: true,
        },
        topic: {
          id: true,
        }
      }
    },
    services: {
      ...getClient().defaultAuditProjection.discovery.services,
      id: true,
      is_active: true,
      // is_current: true,
      // producer: true,
      // grade: true,
      in_survey: true,
      // is_priority: true,
      department: {
        id: true,
      },
      templates: {
        id: true,
        name: true
      }
    },
    considered_services: {
      id: true,
      name: true
    },
  },
  presentation: {
    id: true,
    name: true,
    status: true,
    percent: true,
    deck_url: true,
    logo: true,
    notes: true,
    url: true,
    audit: {
      ...getClient().defaultAuditProjection
    },
    author: {
      id: true,
      name: true,
    },
    templates: {
      id: true,
      name: true,
      slug: true,
      links: {
        id: true,
        name: true,
        slug: true,
      },
    },
    variables: {
      id: true,
      value: true
    },
    revisions: {
      id: true,
      author: {
        name: true
      },
      status: true,
      deck_url: true,
      templates: {
        id: true,
        name: true
      },
      created_at: true,
      updated_at: true,
    },
    created_at: true,
    updated_at: true,
  },
  forecasts: {
    ...getClient().defaultAuditProjection.forecasts,
  },
};
