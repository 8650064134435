
const allStrategyFrequencies = `
query {
  strategy_frequencies {
    id
    name
    slug
  }
}
`;

export default allStrategyFrequencies;
