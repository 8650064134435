/**
 * Components -> Modal
 */

import React, { FC, memo, useMemo } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Modal as AntdModal } from 'antd';
import { ModalProps as DefaultModalProps } from 'antd/lib/modal';
import classNames from 'classnames';
import { Button } from 'components/Button';
import css from './Modal.module.scss';

export type ModalProps = DefaultModalProps & {
  sub?: React.ReactNode;
  contentClassName?: string;
  headerClassName?: string;
}

export const Modal: FC<ModalProps> = memo(function Modal({
  title,
  sub,
  children,
  className,
  contentClassName,
  headerClassName,
  closable = true,
  onCancel,
  ...defaultModalProps
}) {
  const renderHeader = useMemo(() => {
    if (!sub && !title) {
      return null;
    }

    return (
      <div className={classNames(css.header, headerClassName)}>
        <div className={css.header__start}>
          {title ? <div className={css.title}>{title}</div> : null}
          {sub ? <div className={css.sub}>{sub}</div> : null}
        </div>
        {closable ? (
          <div className={css.header__end}>
            <Button
              type="text"
              onClick={onCancel}
              icon={<CloseOutlined />}
              aria-label="Close modal"
            />
          </div>
        ) : null}
      </div>
    );
  }, [sub, title, headerClassName, closable, onCancel]);

  return (
    <AntdModal
      className={classNames(css.root, className)}
      onCancel={onCancel}
      {...defaultModalProps}
      closable={false} // Hide original close button since we provide our own
    >
      {renderHeader}
      <div className={classNames(css.content, contentClassName)}>
        {children}
      </div>
    </AntdModal>
  );
});
