import React, { memo, useCallback } from 'react';
import { Chip } from '@mui/material';
import { InfoTooltip } from '@sprnova/nebula';
import classNames from 'classnames';
import { getBlueprintStageDefinition } from 'utils/blueprints/getBlueprintStageDefinition';
import { BLUEPRINT_STAGES_ENUM } from 'features/strategies/constants';
import { BlueprintStage } from '../../types';
import css from './BlueprintStatus.module.scss';

type BlueprintStatusProps = {
  status: BlueprintStage;
  className?: string;
  tooltip?: boolean;
};

const BlueprintStatus = ({ status, className, tooltip }: BlueprintStatusProps): JSX.Element => {
  const renderLabel = useCallback((): JSX.Element => {
    const tooltipElement = tooltip ? <InfoTooltip
      content={getBlueprintStageDefinition(status)}
      iconSize='small'
      placement='right'
      variant='plain'
      buttonProps={{
        sx: {
          verticalAlign: 'bottom',
          '&:hover': {
            backgroundColor: 'unset !important', // needed because there is a default background color on hover
          },
          marginLeft: '10px',
        },
      }}
    /> : <></>;
    if (status === BLUEPRINT_STAGES_ENUM.Open || status === BLUEPRINT_STAGES_ENUM.Draft) {
      return <div>{BLUEPRINT_STAGES_ENUM.Draft}{tooltipElement}</div>;
    } else {
      return <div>{status}{tooltipElement}</div>;
    }
  }, [status, tooltip]);

  return (
    <>
      <Chip
        className={classNames(className, {
          [css.draft]: status === BLUEPRINT_STAGES_ENUM.Draft || status === BLUEPRINT_STAGES_ENUM.Open,
          [css.appraisal]: status === BLUEPRINT_STAGES_ENUM.Appraisal,
          [css.blueprint]: status === BLUEPRINT_STAGES_ENUM.Blueprint,
          [css.legal]: status === BLUEPRINT_STAGES_ENUM.Legal,
          [css.won]: status === BLUEPRINT_STAGES_ENUM.Won,
          [css.nurture]: status === BLUEPRINT_STAGES_ENUM.Nurture,
          [css.lost]: status === BLUEPRINT_STAGES_ENUM.Lost,
          [css.archive]: status === BLUEPRINT_STAGES_ENUM.Archive,
        })}
        label={renderLabel()}
      />
    </>
  );
};

export default memo(BlueprintStatus);
