/**
 * useOrganizations
 * Fetches all organizations
 */

import { useState, useEffect } from 'react';
import { novaGraphQLClient, Projection } from 'api/entityGraphQL';
import { Organization } from 'features/entitiesRedux';

type Type = {
  loading: boolean;
  error: string;
  organizations: Organization[];
};

type UseOrganizationArgs = { projection?: Projection };
export const useOrganizations = ({
  projection: defaultProjection,
}: UseOrganizationArgs = {}): Type => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [projection /* , setProjection */] = useState(defaultProjection);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      setLoading(true);
      try {
        const {
          organizations,
          // ...rest
        } = await novaGraphQLClient.fetchOrganizations({
          projection,
        });
        if (!isCancelled) {
          setError('');
          setOrganizations(organizations);
          setLoading(false);
        }
      } catch (error) {
        if (!isCancelled) {
          setLoading(false);
          setError(error?.response?.errors?.[0]?.message || 'Unknown error');
        }
      }
    })();

    return () => {
      isCancelled = true;
    };

  }, [projection]);

  return { organizations, loading, error };
};

export default useOrganizations;
