import React, { memo, useCallback, useMemo } from 'react';
import { Card, CardContent, CardHeader } from '@sprnova/nebula';
import { Task } from 'features/entitiesRedux/models/task';
import ViewAddOnMultiplierContractDetails from './ViewAddOnMultiplierContractDetails';
import ViewAddOnPricingContractDetails from './ViewAddOnPricingContractDetails';
import ViewCustomPricingContractDetails from './ViewCustomPricingContractDetails';
import ViewFixedPricingContractDetails from './ViewFixedPricingContractDetails';
import ViewMultiplierPricingContractDetails from './ViewMultiplierPricingContractDetails';
import ViewQuantityPricingContractDetails from './ViewQuantityPricingContractDetails';
import ViewSpendContractDetails from './ViewSpendContractDetails';

interface PricingTypeViewToMapInterface {
  [key: string]: JSX.Element
}

type ViewContractDetailsProps = {
  task?: Task;
}

const ViewContractDetails = ({ task }: ViewContractDetailsProps): JSX.Element => {
  /**
   * Object to map pricing type to a view.
   */
  const pricingTypeToViewMap: PricingTypeViewToMapInterface = useMemo(() => {
    return ({
      'fixed': <ViewFixedPricingContractDetails task={task} />,
      'add-on-fixed': <ViewAddOnPricingContractDetails task={task} />,
      'add-on-multiplier': <ViewAddOnMultiplierContractDetails task={task} />,
      'custom': <ViewCustomPricingContractDetails task={task} />,
      'multiplier': <ViewMultiplierPricingContractDetails task={task} />,
      'quantity': <ViewQuantityPricingContractDetails task={task} />,
      'spend': <ViewSpendContractDetails task={task} />,
    });
  }, [task]);

  /**
   * Render card content: Pricing type form.
   */
  const cardContent = useCallback(() => {
    return (
      <div>
        {pricingTypeToViewMap?.[task?.pricing_type?.slug as keyof typeof pricingTypeToViewMap]}
      </div>
    );
  }, [pricingTypeToViewMap, task?.pricing_type?.slug]);

  const renderContractDetailsCard = useMemo(() => {
    return (
      <Card
        disablePadding
        header={
          <CardHeader title="Contract Details" />
        }
      >
        <CardContent>
          {cardContent()}
        </CardContent>
      </Card>
    );
  }, [cardContent]);

  return (
    <>
      {renderContractDetailsCard}
    </>
  );
};

/**
 * Contract Details card in Read mode
 */
export default memo(ViewContractDetails);
