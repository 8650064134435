/**
* RTK Query -> Utils
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cleanArguments = (args: Record<string, any> = {}): Record<string, unknown> | undefined => {
  const cleanedArgs = Object.entries(args).reduce((acc, [key, value]) => {
    // Remove undefined values
    if (typeof value === 'undefined') {
      return acc;
    }

    return {
      ...acc,
      [key]: value
    };
  }, {});

  // No arguments supplied
  if (!Object.keys(cleanedArgs).length) {
    return undefined;
  }

  return cleanedArgs;
};
