import React from 'react';
import loadable from '@loadable/component';
import { HorizonLayout } from 'features/intelligence/horizon/HorizonLayout';
import HorizonSkeleton from './HorizonSkeleton';


const BudgetScenarios = loadable(
  () => import('./BudgetScenarios'), {
    fallback: (
      <HorizonLayout isLoading>
        <HorizonSkeleton />
      </HorizonLayout>
    )
  }
);

export default BudgetScenarios;
