import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  CalculatorOutlined,
  CheckCircleOutlined,
  FileTextOutlined,
  PhoneOutlined,
  AuditOutlined,
  EditOutlined,
  ApartmentOutlined,
  LineChartOutlined,
  EyeOutlined,
  MonitorOutlined
} from '@ant-design/icons';
import { Action, Resource, Role } from 'api/accessControl';
import classNames from 'classnames';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { Audit, Rule, Task } from 'features/entitiesRedux';
import { useRoleAccess } from 'features/global';
import { AccessControl, Button, Menu, MenuItem } from 'components';
import CreateBlueprintFromAppraisalDialog from './components/CreateBlueprintFromAppraisalDialog';
import { RecommendedServicesModal } from './components/RecommendedServicesModal';
import { DataQIcon, KeyIcon } from '../../../components/Icon';
import css from './AuditContextMenu.module.scss';

interface AuditContextMenuProps {
  audit?: Audit;
  className?: string;
  viewPresentation?: boolean;
  hideButtons?: 'discovery'[];
  onMenuClick?: () => void;
  auditOverviewPage?: boolean;
}

const AuditContextMenu = ({
  audit,
  className,
  viewPresentation = false,
  hideButtons,
  onMenuClick,
  auditOverviewPage = false,
}: AuditContextMenuProps) => {
  const isSuperAdmin = useRoleAccess(Role.superAdmin);
  const mixpanel = useMixpanel();
  const recommendedServices = audit?.rules?.reduce((acc: any, rule: Rule) => acc.concat(rule.tasks), [])
    .sort((a: Task, b: Task) => a?.service?.department?.id - b?.service?.department?.id);
  const dataQAccounts = audit?.client?.accounts?.filter(account => account?.account?.name === 'DataQ');
  const dataQAccountId = dataQAccounts?.length ? dataQAccounts?.[0]?.url : null;

  const [showCreateBlueprintFromAppraisalDialog, setShowCreateBlueprintFromAppraisalDialog] = useState<boolean>(false);

  /**
   * Return the correct path to the appraisal blueprint depending on the pricing version
   * of the blueprint
   */
  const viewBlueprintPath = useMemo(()=> {
    if(audit?.strategy) {
      let pathPrefix = '/blueprints';
      if(audit.strategy.pricing_version && audit.strategy.pricing_version.slug === 'package') {
        pathPrefix = '/blueprints/package';
      }
      return `${pathPrefix}/${audit?.strategy?.id}`;
    }
    return '';
  }, [audit?.strategy]);

  /** Hide dropdown when a menu item is clicked */
  const handleMenuClick = () => {
    if (onMenuClick) {
      onMenuClick();
    }
  };

  /** Mixpanel Analytics */
  const handleTrackMixpanelEvent = () => {
    // track "Create Blueprint" button click event
    try {
      const options = {
        title: audit?.name,
        appraisalId: audit?.id,
        appraisal: audit?.name,
        clientId: audit?.client?.id,
        clientName: audit?.client?.name,
        businessType: audit?.client?.business_type?.name,
        businessTypeId: audit?.client?.business_type?.id,
        businessTypeSlug: audit?.client?.business_type?.slug,
        recommendedServices: recommendedServices?.map((task: Task) => task.name),
      };
      console.log('🛤 Track: Create Blueprint button clicked', options);
      if (mixpanel?.track) {
        mixpanel.track('Create Blueprint - Wizard', options);
      }
    } catch (error) {
      console.error('Mixpanel error', error);
    }
  };

  if (!audit) {
    return null;
  }

  const forecastsLink = audit && audit.client ? `/intelligence/forecasts?client_id=${audit.client.id}` : '/intelligence/forecasts';

  return (
    <Menu className={classNames(css.root, className)} onClick={handleMenuClick}>
      <MenuItem>
        <Link to={`/power-view/accounts/${audit?.client?.id}/integrations#data-access-requests`}>
          <Button
            type="default"
            icon={<span role="img" className="anticon"><KeyIcon /></span>}
            header
            menuItem
          >
            Data Access Requests
          </Button>
        </Link>
      </MenuItem>
      {viewPresentation ? (
        <AccessControl
          action={Action.read}
          resource={Resource.audit}
          showWarning={false}
        >
          <MenuItem>
            <a
              href={audit?.presentation?.deck_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="default" icon={<EditOutlined />} header menuItem>
                Edit Slides
              </Button>
            </a>
          </MenuItem>
        </AccessControl>
      ) : (
        <></>
      )}
      {viewPresentation ? (
        <AccessControl
          action={Action.read}
          resource={Resource.audit}
          showWarning={false}
        >
          <MenuItem>
            <Link to={`/appraisals/${audit?.id}`}>
              <Button type="default" icon={<AuditOutlined />} header menuItem>
                Appraisal Overview
              </Button>
            </Link>
          </MenuItem>
        </AccessControl>
      ) : (
        <></>
      )}
      {!hideButtons?.includes('discovery') ? (
        <AccessControl
          action={Action.read}
          resource={Resource.discoveryCall}
          showWarning={false}
        >
          <MenuItem>
            <Link to={`/appraisals/${audit?.id}/discovery`}>
              <Button type="default" icon={<PhoneOutlined />} header menuItem>
                Discovery Call
              </Button>
            </Link>
          </MenuItem>
        </AccessControl>
      ) : (
        <></>
      )}
      <AccessControl
        action={Action.read}
        resource={Resource.audit}
        showWarning={false}
      >
        <MenuItem>
          <Link to={`/appraisals/${audit?.id}/survey`}>
            <Button
              type="default"
              icon={<CalculatorOutlined />}
              header
              menuItem
            >
              Analyst Survey
            </Button>
          </Link>
        </MenuItem>
      </AccessControl>
      {isSuperAdmin && (
        <MenuItem>
          <Link to={`/appraisals/${audit?.id}/marketing-health`}>
            <Button
              type="default"
              icon={<MonitorOutlined />}
              header
              menuItem
            >
            Marketing Health
            </Button>
          </Link>
        </MenuItem>
      )}
      <AccessControl
        action={Action.read}
        resource={Resource.audit}
        showWarning={false}
      >
        <MenuItem>
          <Link to={`/appraisals/${audit?.id}/scorecard`}>
            <Button
              type="default"
              icon={<CheckCircleOutlined />}
              header
              menuItem
            >
              {auditOverviewPage ? 'Marketing Scorecard' : 'Marketing Efficiency Scorecard'}
            </Button>
          </Link>
        </MenuItem>
      </AccessControl>
      <AccessControl
        action={Action.read}
        resource={Resource.audit}
        showWarning={false}
      >
        <MenuItem>
          <Link to={forecastsLink}>
            <Button
              type="default"
              icon={<LineChartOutlined />}
              header
              menuItem
            >
              Forecasts
            </Button>
          </Link>
        </MenuItem>
      </AccessControl>

      {recommendedServices?.length ?
        <AccessControl
          action={Action.read}
          resource={Resource.recommendedServices}
          showWarning={false}
        >
          <MenuItem>
            <RecommendedServicesModal recommendedServices={recommendedServices} audit={audit} />
          </MenuItem>
        </AccessControl>
        : <></>
      }

      {audit?.strategy?.id ?
        <AccessControl
          action={Action.read}
          resource={Resource.strategy}
          showWarning={false}
        >
          <MenuItem>
            <Link to={{
              pathname: viewBlueprintPath,
              state: {from: 'internal'}
            }}>
              <Button
                type="default"
                icon={<EyeOutlined />}
                header
                menuItem
              >
                View Blueprint
              </Button>
            </Link>
          </MenuItem>
        </AccessControl>
        : <></>
      }

      <AccessControl
        action={Action.create}
        resource={Resource.strategy}
        showWarning={false}
      >
        <MenuItem>
          <Button
            type="default"
            icon={<ApartmentOutlined />}
            header
            menuItem
            onClick={() => setShowCreateBlueprintFromAppraisalDialog(true)}
          >
              Create Blueprint
          </Button>
          <CreateBlueprintFromAppraisalDialog
            audit={audit}
            setShowCreateBlueprintFromAppraisalDialog={setShowCreateBlueprintFromAppraisalDialog}
            open={showCreateBlueprintFromAppraisalDialog}
          />
        </MenuItem>
      </AccessControl>
      <MenuItem>
        <a
          href="https://docs.google.com/document/d/1l15iVHa9s73m_A4wULQcHqJ6nWZ6zBQ8xYKZ7-v_syk/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button type="default" icon={<FileTextOutlined />} header menuItem>
            Elevator Pitch
          </Button>
        </a>
      </MenuItem>
      {dataQAccountId ?
        <MenuItem>
          <a
            href={`https://app.dataq.ai/workspaces/${dataQAccountId}/segments`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              type="default"
              icon={<span role="img" className="anticon"><DataQIcon /></span>}
              header
              menuItem
            >
              DataQ Workspace
            </Button>
          </a>
        </MenuItem>
        : <></>
      }
    </Menu>
  );
};

export default AuditContextMenu;
