/**
 * StrategyForm -> RecommendedServices -> TaskItem
 */

import React, { forwardRef } from 'react';
import { Checkbox, Popover } from '../../../../../../../../components';
import { Task } from '../../../../../../../entitiesRedux';
import css from './TaskItem.module.scss';

interface TaskItemProps {
  task: Task;
  departmentStatus?: string;
  existsInBlueprint?: boolean;
  newBlueprint?: boolean;
}

const TaskItem = forwardRef(({
  task,
  departmentStatus,
  existsInBlueprint,
  newBlueprint,
}: TaskItemProps, ref: any) => {
  const item =
    <Checkbox
      className={css.root}
      value={task?.id}
      disabled={!newBlueprint && (departmentStatus !== 'complete' || existsInBlueprint)}
      checked={newBlueprint || existsInBlueprint}
      ref={ref}
    >
      {task?.name}
    </Checkbox>;

  if (!newBlueprint && existsInBlueprint) {
    return (
      <Popover content="Task already added to Blueprint">
        {item}
      </Popover>
    );
  } else {
    return item;
  }
});

TaskItem.displayName = 'TaskItem';

export default TaskItem;
