import React from 'react';
import { Avatar as AntdAvatar } from 'antd';
import { AvatarProps as AntAvatarProps } from 'antd/lib/avatar';
import classNames from 'classnames';
import { Tooltip } from '../Tooltip';
import css from './Avatar.module.scss';

export interface AvatarProps extends AntAvatarProps {
  className?: string;
  name?: string;
  title?: string;
  children?: any;
  showTooltip?: boolean;
}

const Avatar = ({ className, children, name, title, showTooltip, ...props }: AvatarProps) => {
  const content = (
    <AntdAvatar {...props} className={classNames(css.root, className)}>
      {children || (name && typeof name === 'string' ? name[0] : '')}
    </AntdAvatar>
  );

  if (showTooltip && (name || title)) {
    return (
      <Tooltip
        trigger="hover"
        title={name}
        sub={title}
        destroyTooltipOnHide
      >
        {content}
      </Tooltip>
    );
  }

  return content;
};

export default Object.assign(Avatar, {
  Group: AntdAvatar.Group
});
