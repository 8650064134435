import tagTypes from './tagTypes';
import { baseApi } from '..';

export type CreateFivetranConnectorQuery = {
  group_id?: string;
  service?: string;
  is_created_by_client?: boolean;
  attributions?: string;
  auth_email?: string;
  customer_id?: string;
  account_id?: string;
  property_id?: string;
  site_url?: string
};

export type CreateFivetranConnectorResult = {
  connector_id: string;
  connector_card_uri: string;
  schema_name: string
};

/**
 * Custom hook for creating a new Fivetran connector.
 * This mutation is integrated into the baseApi's endpoints using RTK Query.
 *
 * @param {CreateFivetranConnectorQuery} args - The arguments for creating a Fivetran connector.
 * - `group_id` (string | undefined): The ID of the group where the connector will be created.
 * - `service` (string | undefined): The type of service for the connector.
 * - `is_created_by_client` (boolean | undefined): Flag indicating if the connector is created by the client.
 * - `attributions` (string | undefined): Additional attributions or notes for the connector.
 *
 * @returns A RTK Query mutation hook (`useCreateFivetranConnectorMutation`) that provides
 * the mutate function and the mutation result (data, error, isLoading).
 *
 * The mutation:
 * - Invalidates FIVETRAN_CONNECTOR tag upon a successful creation.
 * - Transforms the GraphQL response to return only the createFivetranConnector data.
 *
 * @example
 * const [createConnector, { data, error, isLoading }] = useCreateFivetranConnectorMutation();
 * createConnector({ group_id: '123', service: 'salesforce', is_created_by_client: true, attributions: 'initial setup' });
 */
export const { useCreateFivetranConnectorMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createFivetranConnector: build.mutation<CreateFivetranConnectorResult, CreateFivetranConnectorQuery>({
      transformResponse: (response: { createFivetranConnector: CreateFivetranConnectorResult }) => response.createFivetranConnector,
      invalidatesTags: [tagTypes.FIVETRAN_CONNECTOR],
      query: ({
        group_id,
        service,
        is_created_by_client,
        attributions = '',
        auth_email = '',
        customer_id = '',
        account_id = '',
        property_id = '',
        site_url = ''
      }) => ({
        body: {
          mutation: {
            createFivetranConnector: {
              __args: {
                group_id,
                service,
                is_created_by_client,
                attributions,
                auth_email,
                customer_id,
                account_id,
                property_id,
                site_url
              },
              connector_id: true,
              connector_card_uri: true,
              schema_name: true
            }
          }
        }
      })
    })
  })
});
