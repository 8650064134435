import React from 'react';
import { getOpportunityScoreStatus } from 'features/audits/utils';
import { AuditsSurveyStatus, Department } from 'features/entitiesRedux';
import { Tag } from 'components';
import { ANALYST_SURVEY_STATUS_TYPES } from '../../auditsSlice';
import css from './AppraisalScoreStatusTag.module.scss';

interface Props {
  departments?: Department[];
  type: 'analyst-score' | 'opportunity-score';
  surveyStatus?: AuditsSurveyStatus | ANALYST_SURVEY_STATUS_TYPES;
}

const AppraisalScoreStatusTag = ({ departments, type, surveyStatus }: Props): JSX.Element => {
  const opportunityScoreStatus = getOpportunityScoreStatus(departments);
  const getStatus = () => {
    let text;
    let statusClass;
    switch(type) {
      case 'analyst-score':
        text = surveyStatus === 'partial' ? 'In Progress' : surveyStatus;
        statusClass = surveyStatus || 'pending';
        break;
      case 'opportunity-score':
        text = opportunityScoreStatus === 'partial' ? 'In Progress' : opportunityScoreStatus;
        statusClass = opportunityScoreStatus;
        break;
      default:
        text = 'Pending';
        statusClass = 'pending';
        break;
    }
    return { text, statusClass };
  };

  const status = getStatus();

  return (
    <div className={css.root}>
      <Tag className={status.statusClass}>{status.text}</Tag>
    </div>
  );
};

export default AppraisalScoreStatusTag;
