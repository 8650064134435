import React, { FC, memo, useCallback } from 'react';
import { Dialog, Grid, TextField, useForm } from '@sprnova/nebula';

export type UpsertFieldValues = {
  id: number;
  name: string;
  title: string;
  email: string;
}

type UpsertDialogProps = {
  onClose: () => void;
  onSubmit?: (validatedFields: UpsertFieldValues) => void;
  open?: boolean;
};

const UpsertDialog: FC<UpsertDialogProps> = ({ open = false, onClose, onSubmit }) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm<UpsertFieldValues>({
    mode: 'onSubmit',
    defaultValues: {
      id: 0,
      name: '',
      title: '',
      email: '',
    },
  });

  const handleCancel = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  const handleSave = useCallback((validatedFields: UpsertFieldValues): void => {
    onSubmit?.(validatedFields);
    reset();
    onClose();
  }, [onClose, onSubmit, reset]);

  return (
    <Dialog
      title="Add Teammate"
      description="Add a new team contact to invite them to the nova portal"
      open={open}
      onClose={onClose}
      primaryButtonProps={{ onClick: handleSubmit(handleSave), children: 'Save' }}
      secondaryButtonProps={{ onClick: handleCancel, children: 'Cancel' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="name"
            label="Contact Name"
            error={errors?.name?.message}
            {...register('name', { required: 'A name is required' })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="title"
            label="Title"
            {...register('title')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="email"
            label="Email"
            error={errors?.email?.message}
            {...register('email', { required: 'An email address is required' })}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default memo(UpsertDialog);
