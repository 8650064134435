/**
 * Utility to properly format business unit labels in the analyst survey library
 * @param id
 */

import { LABELS_TYPES } from 'utils';

const getBusinessTypeLabel = (id?: number | string) => {
  // B2B/B2C - LeadGen
  if (id === 1001) return 'B2B, B2C - LeadGen';
  // B2C - eComm
  if (id === 1002) return 'B2C - eComm';
  // all business units
  if (!id) return `All ${LABELS_TYPES.BUSINESS_UNIT}s`;

  return `No ${LABELS_TYPES.BUSINESS_UNIT}`;
};

export default getBusinessTypeLabel;
