import React from 'react';
import loadable from '@loadable/component';

const EditPartnerPage = loadable(
  () => import('./EditPartnerPage')
);

const Title = () => <>Edit</>;
Title.displayName = 'EditPartnerPageTitle';

export default Object.assign(EditPartnerPage, { Title });