/**
 * Get categories
 */
import { baseApi } from '..';
import tagTypes from './tagTypes';

export type Category = {
  category: string;
};

export type GetCategoriesQuery = Record<string, never>;

export type GetCategoriesResult = Category[]

export const GET_CATEGORIES_ENDPOINT_KEY = 'getCategories';

export const { useGetCategoriesQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_CATEGORIES_ENDPOINT_KEY]: build.query<GetCategoriesResult, GetCategoriesQuery>({
      transformResponse: (response: { indicator_categories: GetCategoriesResult }) => response.indicator_categories,
      providesTags: (result) => {
        return [tagTypes.CATEGORIES];
      },
      query: ({
        projection = { id: false, category: true },
      }) => {

        return {
          body: {
            query: {
              indicator_categories: {
                ...projection,
              }
            }
          }
        };
      }
    })
  })
});
