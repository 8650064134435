import React, { ReactNode } from 'react';
import { GetScoreboardsQuery } from 'api/crudGraphQL/scoreboards/getScoreboards';
import { isEqual } from 'lodash';

type ReducerType = { type: 'add' | 'remove' | 'reset'; value?: Partial<FilterReturnType> };
export type FilterReturnType = {
  filter: Partial<GetScoreboardsQuery>;
  setFilter: React.Dispatch<ReducerType>;
};

export const Filters = (initialState?: Partial<GetScoreboardsQuery>): FilterReturnType => {
  const [state, dispatch] = React.useReducer((state: Partial<GetScoreboardsQuery>, action: any): any => {
    switch (action.type) {
      case 'add': {
        const currentState: Partial<GetScoreboardsQuery> = { ...state };
        if (action.value) {
          Object.keys(action.value).forEach((key) => {
            // Check if the key already exists in the state and if the value is different
            // before updating the state
            if (
              action.value &&
              !isEqual(
                currentState[key as keyof GetScoreboardsQuery],
                action.value[key as keyof GetScoreboardsQuery]
              )) {
              currentState[key as keyof GetScoreboardsQuery] = action.value[key as keyof GetScoreboardsQuery] as undefined;
            }
          });
        }
        return currentState;
      }
      case 'remove': {
        const newState: Partial<GetScoreboardsQuery> = { ...state };
        if (action.value) {
          delete newState[action.value as keyof GetScoreboardsQuery];
        }
        return newState;
      }
      case 'reset': {
        return {};
      }
      default:
        return state;
    }
  }, { ...initialState } as Partial<GetScoreboardsQuery>);

  return {
    filter: state,
    setFilter: dispatch,
  };
};

export const FilterContext = React.createContext<FilterReturnType | undefined>(undefined);

type FilterProviderProps = {
  children: ReactNode;
  filter: FilterReturnType | undefined; // Explicitly allow filter to be undefined
};
export const FilterProvider: React.FC<FilterProviderProps> = ({ children, filter }) => {
  if (filter) {
    return (
      <FilterContext.Provider value={filter}>
        {children}
      </FilterContext.Provider>
    );
  }
  return <>{children}</>;
};

export const useFilterContext: () => FilterReturnType | Record<string, any> = () => React.useContext(FilterContext) || {};
