/**
 * BlockList -> Row
 */

import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import css from './Row.module.scss';

type Props = {
  title?: string;
  'aria-label'?: string;
  children: React.ReactNode;
  className?: string;
  innerClassName?: string;
  disabled?: boolean;
  onClick?: () => void;
  to?: string;
};

const BlockListRow: FC<Props> = ({
  title,
  children,
  className,
  to,
  'aria-label': ariaLabel,
  disabled,
  innerClassName,
  onClick,
  ...rest
}) => {
  const clickableProps = {
    className: css.clickableLayer,
    'aria-label': ariaLabel,
    onClick,
  };

  return (
    <li title={title} className={classnames(css.root, className, { [css.disabled]: disabled })} {...rest}>
      {(!to && typeof onClick === 'function') && (<button {...clickableProps} />)}
      {to && (
        <Link
          to={{
            pathname: to,
            state: {from: 'internal'}
          }}
          {...clickableProps}
        />
      )}
      <div className={classnames(css.columns, innerClassName)}>
        {children}
      </div>
    </li>
  );
};

export default BlockListRow;
