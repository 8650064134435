export const emptyContact = {
  name: '',
  email: '',
  title: '',
  linkedin: '',
  notes: ''
};

export const emptyCompetitor = {
  name: '',
  website: ''
};

export const emptyGoal = {
  type: null,
  metric: ''
};

export const emptyChannel = {
  name: null,
  percent: ''
};

export const emptyLeadChannel = {
  name: null,
  percent: ''
};