import { Projection } from 'api/entityGraphQL';

const projection: Projection = {
  id: true,
  name: true,
  department: {
    id: true,
    name: true,
  },
  business_types: {
    id: true,
    name: true
  },
  pricing_version: {
    name: true,
    slug: true
  }
};

export default projection;
