/**
 * useClientById
 * Fetches a single client
 */

import { useState, useEffect } from 'react';
import { fetchClientByIdQuery, Projection } from 'api/entityGraphQL';
import { getClient } from 'api/entityGraphQL/utils';
import { Client } from 'features/entitiesRedux';

type ReturnType = {
  loading: boolean;
  error: string;
  client: Partial<Client>;
}

export const useClientById = (
  clientId: number | undefined,
  projection?: Projection
): ReturnType => {
  const [client, setClient] = useState<Partial<Client>>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (clientId) {
          const { clients } = await fetchClientByIdQuery(parseInt(String(clientId)), { projection });
          setError('');
          setClient(clients[0]);
          setLoading(false);
        }
      } catch(error) {
        setLoading(false);
        setError(error as string);
      }
    })();
  }, [clientId, projection]);

  return { client, loading, error };
};

export default useClientById;
