import React, { memo, useMemo } from 'react';
import { Grid, GridProps, Typography, Card, theme, Legend as NebulaLegend } from '@sprnova/nebula';
import { Indicator } from 'api/crudGraphQL/scoreboards/types';
import { format } from 'date-fns';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  TooltipProps,
} from 'recharts';
import { useFilterContext } from 'features/scoreboards/utils/Filters';
import { formatRangeLabel, formatScoreboardsDate, formatTitle } from '../../components/utils';

type KPIGraphProps = {
  indicatorData: Indicator;
  lastUpdated?: string | null;
};

const commonGridProps: GridProps = {
  display: 'flex',
  alignItems: 'center',
};

const KPIChart = ({ indicatorData, lastUpdated }: KPIGraphProps): JSX.Element => {
  const { filter } = useFilterContext();
  const firstDate = indicatorData?.data?.[0]?.date;

  /**
   * Use date from the last data point if available,
   * otherwise use the custom date if available,
   * otherwise use the current date
   */
  const lastDate = useMemo(() => {
    return indicatorData?.data?.[indicatorData.data.length - 1]?.date || filter?.to || format(new Date(), 'yyyy-MM-dd');
  }, [indicatorData?.data, filter?.to]);

  const chartData = useMemo(() => {
    return indicatorData?.data?.map(item => ({
      ...item,
      date: item.date ? formatScoreboardsDate(item.date, 'axis_value') : 'no date'
    }));
  }, [indicatorData?.data]);

  const chartTitle = indicatorData?.name;
  const ComparisonTimeframeColor = theme.variables?.colors.data_visualization.sequential[90];
  const PreviousPeriodColor = theme.variables?.colors.data_visualization.sequential[50];
  const HeaderFooterColor = theme.variables?.colors.primary.charcoal[200];
  const CartesianGridStroke  = theme.variables.colors.neutrals.ghost[400];

  const renderLegend = useMemo((): JSX.Element => {
    return (
      <Grid
        container
        display='flex'
        flexDirection='row'
        wrap='nowrap'
        justifyContent='center'
        width='100%'
        paddingTop='32px'
        marginLeft='8px'
      >
        <NebulaLegend.Group direction="horizontal">
          <NebulaLegend
            variant="line"
            color={ComparisonTimeframeColor}
            label={formatRangeLabel(filter?.range, filter?.from, filter?.to)}
          />
          <NebulaLegend
            variant="line"
            color={PreviousPeriodColor}
            label={formatTitle(filter?.compared)}
          />
        </NebulaLegend.Group>
      </Grid>
    );
  }, [filter?.compared, filter?.from, filter?.range, filter?.to, ComparisonTimeframeColor, PreviousPeriodColor]);

  const renderKPIChartHeader = useMemo(() => {
    return (
      <Grid direction={'column'} padding={'16px'} {...commonGridProps}>
        <Typography variant='h3'>{chartTitle}</Typography>
        <Typography variant='caption' color={HeaderFooterColor} padding={'8px 0'}>
          {formatScoreboardsDate(firstDate, 'timeframe')} - {formatScoreboardsDate(lastDate, 'timeframe')}
        </Typography>
      </Grid>
    );
  }, [chartTitle, firstDate, lastDate, HeaderFooterColor]);

  const renderKPIChartFooter = useMemo(() => {
    if (!lastUpdated) return null;
    return (
      <Typography variant='caption' color={HeaderFooterColor} paddingTop={'32px'}>
        Last updated: {formatScoreboardsDate(lastUpdated, 'timestamp')}
      </Typography>
    );
  }, [HeaderFooterColor, lastUpdated]);

  const CustomTooltip = memo(({ active, payload }: TooltipProps) => {
    if (active && Array.isArray(payload) && payload.length) {
      const { value } = payload?.[0] ?? {};
      const { compared_value } = payload?.[0]?.payload ?? {};
      const { date } = payload?.[0]?.payload ?? {};
      return (
        <Card
          contentPadding='16px'
          textAlign='left'
          sx={{
            padding: '0 0 16px 0',
          }}
        >
          <Typography variant='body1'>{date}</Typography>
          <NebulaLegend.Group direction='vertical'>
            <NebulaLegend
              color={ComparisonTimeframeColor}
              variant='fill'
              label={
                <Typography variant='caption'>
                  {formatRangeLabel(filter?.range, filter?.from, filter?.to)}: <b>{value}</b>
                </Typography>
              }
            />
            {
              filter?.compared && (
                <NebulaLegend
                  color={PreviousPeriodColor}
                  variant='fill'
                  label={
                    <Typography variant='caption'>{formatTitle(filter?.compared)}: <b>{compared_value}</b></Typography>
                  }
                />
              )
            }
          </NebulaLegend.Group>
        </Card>
      );
    }
    return null;
  });

  CustomTooltip.displayName = 'CustomTooltip';

  return (
    <Grid direction='column' height={'100%'} padding={'24px 0'} {...commonGridProps}>
      {renderKPIChartHeader}
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} stroke={CartesianGridStroke} />
          <XAxis
            tickLine={false}
            axisLine={false}
            dataKey="date"
            interval={Math.floor(chartData?.length / 4)}
          >
          </XAxis>
          <YAxis
            tickLine={false}
            axisLine={false}
          >
          </YAxis>
          <Tooltip content={<CustomTooltip />}/>
          <Legend
            verticalAlign='bottom'
            content={renderLegend}
            height={50}
            align='center'
          />
          <Line
            type="monotone"
            dataKey="value"
            dot={false}
            stroke={ComparisonTimeframeColor}
            strokeWidth={2}
            isAnimationActive={true}
          />
          <Line
            type="monotone"
            dataKey="compared_value"
            dot={false}
            stroke={PreviousPeriodColor}
            strokeWidth={2}
            isAnimationActive={true}
          />
        </LineChart>
      </ResponsiveContainer>
      {renderKPIChartFooter}
    </Grid>
  );
};

export default memo(KPIChart);

