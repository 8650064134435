import { Partner } from 'features/entitiesRedux';

/**
 * Return the partner referral text based on what data is available
 *
 * @param partner
 * @returns string - partner referral name based on what data is available
 */
const formatPartnerReferralName = (partner: Partial<Partner>): string => {
  let text = `Partner #${partner.id}`;
  if (partner.company && partner.name) {
    text = `${partner.company} - ${partner.name}`;
  }
  if (!partner.company && partner.name) {
    text = partner.name;
  }
  if (partner.company && !partner.name) {
    text = partner.company;
  }
  return text;
};

export default formatPartnerReferralName;
