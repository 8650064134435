import React, { memo, useCallback } from 'react';
import { ApiResponse } from '../../../../NovaGpt';

type DatasetBulletsProps = {
  data: ApiResponse[]
}

const DatasetBullets = ({ data }: DatasetBulletsProps): JSX.Element => {

  const removeUnderscores = useCallback((value: string): string => {
    return value.replace(/_/g, ' ');
  },[]);

  return (
    <>
      {data.map((row, rowIndex) => {
        const entries = Object.entries(row);

        // Finding ID and title keys, if they exist
        const idKey = entries?.find(([key]) => key?.includes('ID'))?.[0] || '';
        const titleKey = entries?.find(([key]) => key?.toLowerCase()?.includes('title'))?.[0] || '';
        const otherEntries = entries?.filter(([key]) => key !== idKey && key !== titleKey);

        return (
          <div key={rowIndex}>
            {idKey && (
              <div>
                <strong>{removeUnderscores(idKey)?.toUpperCase()}:</strong> {row[idKey]}
              </div>
            )}
            {titleKey && (
              <div>
                <strong>{removeUnderscores(titleKey)?.toUpperCase()}:</strong> {row[titleKey]}
              </div>
            )}
            {otherEntries?.length > 0 && (
              <ul style={{ paddingLeft: '20px' }}>
                {otherEntries.map(([key, value], index) => (
                  <li key={index} style={{ marginBottom: '0.5rem' }}>
                    <strong>{removeUnderscores(key)?.toUpperCase()}:</strong> {value}
                  </li>
                ))}
              </ul>
            )}
          </div>
        );
      })}
    </>
  );
};

export default memo(DatasetBullets);
