import { Client } from 'features/entitiesRedux';
import { PlaybookAd, PlaybookAdset, PlaybookCampaign } from 'features/entitiesRedux/models/creative_playbook';
import { PlaybookDataType } from './constants';

export const extractClientIdsFromCreativeAffinityData = (
  items: PlaybookCampaign[] | PlaybookAdset[] | PlaybookAd[],
  type: PlaybookDataType.Campaign | PlaybookDataType.AdSet | PlaybookDataType.Ad
): number[] => {
  const clientIds: number[] = items.map(item => {
    let clientId: number | undefined;

    switch (type) {
      case PlaybookDataType.Campaign:
        clientId = (item as PlaybookCampaign).client?.id;
        break;
      case PlaybookDataType.AdSet:
        clientId = (item as PlaybookAdset).campaign?.client?.id;
        break;
      case PlaybookDataType.Ad:
        clientId = (item as PlaybookAd).adset.campaign?.client?.id;
        break;
      default:
        break;
    }

    return clientId;
  }).filter((id): id is number => id !== undefined);

  return clientIds;
};

export const hasSalesforceCommerceCloudConnector = (
  clientsData: Client[],
): boolean => {
  return clientsData?.some(client =>
    client.fivetran_connectors?.some(connector =>
      connector?.data_source?.slug === 'salesforce-commerce-cloud' && connector.data_feed_created
    )
  ) ?? false;
};
