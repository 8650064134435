import React from 'react';
import loadable from '@loadable/component';

const CreatePartnerPage = loadable(
  () => import('./CreatePartnerPage')
);

const Title = () => <>Add partner</>;
Title.displayName = 'CreatePartnerPageTitle';

export default Object.assign(CreatePartnerPage, { Title });