import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { formatCurrency, Select, useForm } from '@sprnova/nebula';
import { useGetScoreboardPublicQuery } from 'api/crudGraphQL/public/scoreboards/getScoreboardPublic';
import { GoalPacingArgs, useGetScoreboardQuery } from 'api/crudGraphQL/scoreboards/getScoreboard';
import { capitalize } from 'lodash';
import { useFilterContext } from 'features/scoreboards/utils/Filters';

export type GoalViewProps = {
  isClient?: boolean;
  type: GoalPacingArgs['type'];
};
export type SelectChangeEvent<Value = string> =
  | (Event & { target: { value: Value; name: string } })
  | React.ChangeEvent<HTMLInputElement>;
const GoalView = ({ isClient = false, type, }: GoalViewProps): JSX.Element => {
  const { filter, setFilter } = useFilterContext();
  const { register, setValue, getValues } = useForm({
    defaultValues: {
      goal: filter?.[type || '']?.id || 0,
    },
  });
  const { id } = useParams<{ [x: string]: string }>();
  const { data, isLoading, isFetching } = (isClient ? useGetScoreboardPublicQuery : useGetScoreboardQuery)({
    id: parseInt(id),
    goalPacingArgs: { type, limit: 10 },
    projection: {
      id: false,
      pacing_goals: {
        id: true,
        type: true,
        metric: true,
        metric_name: true,
        value: true,
        indicator: {
          format: true,
        }
      },
    },
  }, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  const handleChange = useCallback((event: SelectChangeEvent<unknown>): void => {
    const { value } = event?.target ?? {};
    if (!type) return;
    setFilter({ type: 'add', value: { [type]: { id: value } } });
  }, [setFilter, type]);

  const pacingGoals = useMemo(() => {
    if (!data) return [];
    if (!filter?.[type || '']?.id || filter?.[type || '']?.type === 'create') {
      if (data?.pacing_goals?.length > 0) {
        if (type && !filter?.[type || '']?.type) {
          setFilter({ type: 'add', value: { [type]: { id: data?.pacing_goals?.[0]?.id } } });
        }
        setValue('goal', data?.pacing_goals?.[0]?.id);
      }
    }
    return data.pacing_goals;
  }, [data, filter, setFilter, setValue, type]);

  useEffect(() => {
    if (filter.deleted) {
      if (type) {
        if (pacingGoals?.filter(goal => goal.id === filter?.[type]?.id).length === 0) {
          // Change the active goal to the first goal in the list after a goal is deleted
          setFilter({ type: 'add', value: {
            [type]: { id: pacingGoals?.[0]?.id },
            deleted: false,
          } });
          setValue('goal', pacingGoals?.[0]?.id);
        }
      }
    }
  }, [pacingGoals, filter, setFilter, setValue, type]);

  const renderSelect = useMemo(() => {
    if (!pacingGoals) return <></>;
    return (
      <Select
        {...register('goal')}
        value={filter?.[type || '']?.id || getValues().goal}
        onChange={handleChange}
        id='GoalView'
        label="Goal view"
        sx={{ textAlign: 'left' }}
      >
        {
          pacingGoals.map((goal) => {
            const { id, type, metric_name, value, indicator: { format } } = goal;
            const formattedValue = format === '$' ? formatCurrency(value) : value.toLocaleString();
            return (
              <Select.Item value={id} subTitle={metric_name} key={id}>
                {capitalize(type).replace(/_/g, ' ')} goal for {metric_name} - {formattedValue}
              </Select.Item>
            );
          })
        }
      </Select>
    );
  }, [pacingGoals, register, filter, type, getValues, handleChange]);

  if (isLoading || isFetching) {
    return <Select id='GoalView-skeleton' skeleton />;
  }
  return renderSelect;
};

export default memo(GoalView);
