/**
 * Update scoreboard (report) indicators
 */

import { Projection } from 'api/entityGraphQL';
import { Report } from 'features/entitiesRedux';
import { baseApi, cleanArguments } from '../..';
import tagTypes from '../tagTypes';

export type IndicatorInput = {
  indicator_id: number;
  is_active: string;
  report_widget_id: number;
  settings: string;
};
export type UpdateScoreboardIndicatorsMutation = {
  report_id: number;
  ad_networks_indicators?: IndicatorInput[];
  ecommerce_indicators?: IndicatorInput[];
  calculated_indicators?: IndicatorInput[];
  web_analytics_indicators?: IndicatorInput[];
  goal_completion_indicators?: IndicatorInput[];
  projection?: Projection;
}

export type UpdateScoreboardIndicatorsResult = Report;

export const UPDATE_SCOREBOARD_INDICATORS = 'updateScoreboardIndicators';

export const { useUpdateScoreboardIndicatorsMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [UPDATE_SCOREBOARD_INDICATORS]: build.mutation<UpdateScoreboardIndicatorsResult, UpdateScoreboardIndicatorsMutation>({
      transformResponse: (response: { updateReportIndicators: Report }) => response.updateReportIndicators,
      invalidatesTags: result => [
        result?.id ? { id: result.id, type: tagTypes.SCOREBOARD } : tagTypes.SCOREBOARD,
        /**
         * TODO: Add invalidation for all related entities
         */
        tagTypes.SCOREBOARDS,
        tagTypes.SCOREBOARD,
        tagTypes.SELECTOR_INDICATORS,
        tagTypes.SELECTOR_INDICATOR,
      ],
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<UpdateScoreboardIndicatorsMutation> = {
          ...values
        };
        return {
          body: {
            mutation: {
              updateReportIndicators: {
                __args: cleanArguments(__args),
                ...projection,
                id: true
              }
            }
          }
        };
      }
    })
  })
});
