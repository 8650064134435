import { captureException } from '@sentry/react';
import formatErrorToHumanReadable from './formatErrorToHumanReadable';

const formatError = (error: string, message?: string): string => {
  // Capture error in Sentry
  const errorString = JSON.stringify(error);
  const errorMessage = JSON.parse(errorString)['message'];

  captureException(new Error(errorMessage));

  console.error(error);

  if (message) {
    return message;
  }

  return formatErrorToHumanReadable(errorMessage);
};

export default formatError;