import React, { FC, forwardRef } from 'react';
import { Form as AntdForm } from 'antd';
import { FormInstance , FormProps as AntFormProps } from 'antd/lib/form';
import classNames from 'classnames';
import css from './Form.module.scss';

export interface FormProps extends AntFormProps {
  className?: string;
  layout?: 'inline' | 'horizontal' | 'vertical';
  tail?: boolean;
  colon?: boolean;
  ref?: React.Ref<FormInstance<Record<string, unknown>>>;
}

const Form: FC<FormProps> = forwardRef(({
  className,
  layout = 'horizontal',
  tail = true,
  colon = false,
  children,
  ...props
}, ref) => {
  // const [form] = AntdForm.useForm();

  const formItemLayout =
    layout === 'horizontal'
      ? {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      }
      : null;

  const tailLayout = {
    wrapperCol: { offset: 4, span: 14 },
  };

  return (
    <AntdForm
      ref={ref}
      {...formItemLayout}
      layout={layout}
      colon={colon}
      {...props}
      className={classNames(className, css.root)}
    >
      {!!children &&
        React.Children.map(children, (child: any, i) => {
          if (!child) {
            return child;
          }

          return React.cloneElement(child, {
            // apply tail layout to last item in child array
            ...(Array.isArray(children) &&
            i === children.length - 1 &&
            tail &&
            layout === 'horizontal'
              ? tailLayout
              : null),
          });
        })}
    </AntdForm>
  );
});

export default Form;
