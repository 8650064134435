import React from 'react';
import { Layout as AntLayout } from 'antd';
import { Spin } from 'components/Spin';
import NovaLogo from 'src/nova-logo.svg';
import css from './LoadingLayout.module.scss';

const LoadingLayout = (): JSX.Element => {
  return (
    <AntLayout className={css.root}>
      <div className={css.novaLogoContainer}>
        <NovaLogo/>
      </div>
      <div className={css.spinContainer}>
        <Spin fontSize={45} />
      </div>
    </AntLayout>
  );
};

export default LoadingLayout;
