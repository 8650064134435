/**
 * AddTasksModal -> SelectionList
 */

import React, { Component } from 'react';
import { Input } from 'components/Input';
import debounce from 'lodash/debounce';
import { Department, Service, Task } from 'features/entitiesRedux';
import { PricingVersion } from 'features/library/constants';
import { Item, ServiceItem, TaskItem } from './components';
import css from './SelectionList.module.scss';

type Props = {
  departments: Department[],
  onSelect: (tasks: Task[]) => void;
  onUnselect: (tasks: Task[]) => void;
  selectedIds: number[];
  currentId?: number;
};

type State = {
  query: string;
  filteredDepartments: Department[];
  filteredTasks: Task[];
  tasks: Task[];
}

export default class SelectionList extends Component<Props, State> {
  readonly state: State = {
    query: '',
    filteredDepartments: this.props.departments,
    filteredTasks: [],
    tasks: [],
  }

  public departmentsCache: {[q: string]: Department[]} = {};
  public tasksCache: {[q: string]: Task[]} = {};

  componentDidMount() {
    this.setAllTasks();
  }

  componentDidUpdate(prevProps: Props, { query: prevQuery }: State) {
    const { query: currQuery } = this.state;
    if (currQuery && prevQuery !== currQuery) {
      this.debouncedSetFilter(currQuery);
    }
  }

  setAllTasks = () => {
    const { departments } = this.props;
    const allTasks = departments?.reduce((acc, department: Department) => acc.concat(department?.services?.reduce((a: Task[], b) => a.concat(b.tasks), []), []), [] as Task[]);
    this.setState({
      tasks: allTasks,
    });
  }

  setFilter = (query: string) => {
    const departments = this.departmentsCache[query] || this.props.departments;
    const tasks = this.tasksCache[query] || this.state.tasks;
    const q = query.trim().toLowerCase();

    const filteredDepartments = departments?.reduce((acc: Department[], department: Department) => {
      const filteredServices = department?.services?.filter((service: Service) => service.name.toLowerCase().includes(q) && service.pricing_version?.slug === PricingVersion.HOURLY);
      const nameMatches = department?.name?.toLowerCase().includes(q);

      if (!nameMatches && !filteredServices?.length) {
        return acc;
      } else {
        return acc.concat({ ...department, services: (nameMatches && !filteredServices?.length) ? department.services: filteredServices });
      }
    }, []);
    this.departmentsCache[query] = filteredDepartments;

    const filteredTasks = tasks?.filter(
      (task: Task) => task?.name?.toLowerCase()?.includes(q)
        /**
         * Filter out tasks with the same ID and whose children have the same ID
         * to prevent infinite looping
         */
        && task?.id !== this.props.currentId
        && task?.pricing_version?.slug === PricingVersion.HOURLY
        && task?.children?.filter((x: any) => x.id === this.props.currentId).length === 0
    );
    this.tasksCache[query] = filteredTasks;

    this.setState({
      filteredDepartments,
      filteredTasks,
    });
  }

  debouncedSetFilter = debounce(this.setFilter, 100);

  onQueryChange = (e: any) => {
    this.setState({
      query: e.target.value
    });
  }

  renderDepartment = (department: Department) => {
    const { onSelect, onUnselect, selectedIds } = this.props;

    return (
      <Item
        key={department.id}
        label={department.name}
      >
        {Array.isArray(department?.services) && department?.services?.filter((s: Service) => s?.tasks?.length && s?.pricing_version?.slug === PricingVersion.HOURLY)?.map((service: Service) => (
          <ServiceItem
            currentId={Number(this.props.currentId)}
            key={service.id}
            service={service}
            onSelect={onSelect}
            onUnselect={onUnselect}
            selectedIds={selectedIds}
          />
        ))}
      </Item>
    );
  }

  renderTask = (task: Task) => {
    const { selectedIds, onUnselect, onSelect } = this.props;
    const checked = selectedIds.includes(task.id);
    const handleToggle = () => checked ? onUnselect([task]) : onSelect([task]);

    return (
      <TaskItem
        key={task.id}
        task={task}
        onToggle={handleToggle}
        checked={checked}
      />
    );
  }

  renderFilteredTasks = () => {
    const { filteredTasks, query } = this.state;

    if (!query || !filteredTasks?.length) {
      return null;
    }

    return (
      <Item label="Tasks">
        {filteredTasks.map(this.renderTask)}
      </Item>
    );
  }


  render() {
    const { query, filteredDepartments, filteredTasks } = this.state;
    const departments = query ? filteredDepartments : this.props.departments;
    const noResults = query && !filteredDepartments?.length && !filteredTasks?.length;

    return (
      <div className={css.root}>
        <div className={css.filters}>
          <Input
            value={query}
            onChange={this.onQueryChange}
            placeholder="Filter by department, service or strategy"
            allowClear
            autoFocus
          />
        </div>
        <div className={css.content}>
          {noResults ?
            <div className={css.noResults}>
              {`No departments, services or strategies matching "${query}" was found.`}
            </div>
            : (
              <ul className={css.list}>
                {this.renderFilteredTasks()}
                {departments?.map(this.renderDepartment)}
              </ul>
            )}
        </div>
      </div>
    );
  }
}
