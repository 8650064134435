import React from "react";
import { Skeleton } from "components";

const ContactsListSkeleton = () => {
  return (
    <>
      {Array(7)
        .fill(null)
        .map((e, i) => {
          return <Skeleton avatar active key={i} paragraph={{ rows: 1 }} />;
        })}
    </>
  );
};

export { ContactsListSkeleton };
