/**
 * Helper function for determining whether or not to show the lost modal
 */

import { StrategyFormValues } from 'features/entitiesRedux';


const shouldShowLostOrWonModal: (blueprint: Pick<StrategyFormValues,
  'status' | 'salesforce_opportunity_id' | 'salesforce_is_primary'>) => boolean | undefined = (blueprint) =>
    (blueprint.status === 'lost') &&
    !!blueprint.salesforce_opportunity_id &&
    blueprint.salesforce_is_primary;

export default shouldShowLostOrWonModal;
