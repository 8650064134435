import React, { memo } from 'react';
import { Button, Card, ChevronRightIcon, Dialog, Grid } from '@sprnova/nebula';
import HourlyBlueprintPricingIcon from 'components/Icon/svg/HourlyBlueprintPricingIcon';
import PackageBlueprintPricingIcon from 'components/Icon/svg/PackageBlueprintPricingIcon';
import { Audit } from 'features/entitiesRedux/models/audit/audit';

type CreateBlueprintFromAppraisalDialog = {
  audit: Audit;
  setShowCreateBlueprintFromAppraisalDialog: (show: boolean) => void;
  open: boolean;
};

const CreateBlueprintFromAppraisalDialog = (props: CreateBlueprintFromAppraisalDialog): JSX.Element => {

  const { audit, open, setShowCreateBlueprintFromAppraisalDialog } = props;

  const hourlyBlueprintPath = audit && audit.id ?  `/blueprints/new?appraisal_id=${audit.id}` : '/blueprints/new';
  const packageBlueprintPath = audit && audit.id ?  `/blueprints/package/new?appraisal_id=${audit.id}` : '/blueprints/package/new';

  const close = () => {
    setShowCreateBlueprintFromAppraisalDialog(false);
  };

  return (
    <Dialog
      maxWidth={776}
      open={open}
      onClose={close}
      title="Create Blueprint"
      description="Choose which pricing type you want to create a Blueprint from"
    >
      <Grid container spacing={'24px'}>
        <Grid item xs={6}>
          <Card
            maxWidth={356}
            title="Package Pricing"
            isInteractive
            endIcon={<ChevronRightIcon />}
            onClick={() => window.open(packageBlueprintPath, '_blank')}
          >
            <div style={{textAlign: 'center'}}>
              <PackageBlueprintPricingIcon />
            </div>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card
            maxWidth={356}
            title="Hourly Pricing"
            isInteractive
            endIcon={<ChevronRightIcon />}
            onClick={() => window.open(hourlyBlueprintPath, '_blank')}
          >
            <div style={{textAlign: 'center'}}>
              <HourlyBlueprintPricingIcon
              />
            </div>
          </Card>
        </Grid>
      </Grid>
      <Button
        disableRipple
        onClick={close}
        size="large"
        sx={{ marginTop: '24px', width: '100%'}}
      >
        Cancel
      </Button>
    </Dialog>
  );
};

export default memo(CreateBlueprintFromAppraisalDialog);
