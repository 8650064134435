/**
 * SectionBuilder -> Fields -> ContactsField
 *
 * Dynamic FormList for adding multiple client contact records
 */

import React, { FC } from 'react';
import { MinusCircleOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';
import {
  Button,
  FormItem,
  FormList,
  Input,
  TextEditor, Tooltip
} from 'components';
import { FieldProps } from '../../types';
import css from '../fieldgroup_shared.module.scss';

const ContactsField: FC<FieldProps> = ({ name }) => {
  return (
    <div className={css.root}>
      <FormList name={name}>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field) => (
                <Row key={field.key} align="top" justify="space-between">
                  <Col xs={23} className={css.column_fieldset}>
                    <Row gutter={[12, 0]}>
                      <Col xs={24} lg={12}>
                        <FormItem
                          name={[field.name, 'name']}
                          label="Name"
                          fieldKey={field.fieldKey}
                          rules={[{ max: 255, message: 'Name cannot exceed 255 characters' }]}
                        >
                          <Input placeholder="Name" />
                        </FormItem>
                      </Col>
                      <Col xs={24} lg={12}>
                        <FormItem
                          name={[field.name, 'title']}
                          label="Title"
                          fieldKey={field.fieldKey}
                          rules={[{ max: 255, message: 'Title cannot exceed 255 characters' }]}
                        >
                          <Input placeholder="Title" />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={[12, 0]}>
                      <Col xs={24} lg={12}>
                        <FormItem
                          name={[field.name, 'linkedin']}
                          label="LinkedIn"
                          fieldKey={field.fieldKey}
                          rules={[{ max: 255, message: 'LinkedIn URL cannot exceed 255 characters' }]}
                        >
                          <Input placeholder="LinkedIn" />
                        </FormItem>
                      </Col>
                      <Col xs={24} lg={12}>
                        <FormItem
                          name={[field.name, 'email']}
                          label="Email"
                          fieldKey={field.fieldKey}
                          rules={[{ max: 255, message: 'Email cannot exceed 255 characters' }]}
                          //rules={form?.getFieldValue(['contacts', field.name, 'name']) ? emailRules : []}
                        >
                          <Input placeholder="Email" />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={[12, 12]}>
                      <Col xs={24}>
                        <FormItem
                          name={[field.name, 'notes']}
                          label="Other Notes"
                          fieldKey={field.fieldKey}
                        >
                          <TextEditor className={css.textEditor} />
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={1} className={css.column_delete}>
                    <Tooltip title="Delete">
                      <MinusCircleOutlined
                        className={css.delete}
                        onClick={() => remove(field.name)}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              ))}
              <FormItem>
                <Button type="text" header icon={<PlusCircleFilled />} onClick={() => add()}>
                  Add contact
                </Button>
              </FormItem>
            </div>
          );
        }}
      </FormList>
    </div>
  );
};

export default ContactsField;
