import React, {FC} from 'react';

interface DropDownListIconProps {
  height?: string;
  width?: string;
}

const DropDownListIcon: FC<DropDownListIconProps> = ({ height = '1em', width = '1em' }) => (
  <svg
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="M12.0001 14.65L7.6001 10.25H16.4001L12.0001 14.65Z" fill="#4A1FFF"/>
    </g>
  </svg>

);

export default DropDownListIcon;
