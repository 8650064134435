import { Projection } from 'api/entityGraphQL/types';
import { NovaAISessionType } from 'features/entitiesRedux/models/nova_ai';
import { baseApi, cleanArguments } from '..';

export type GetNovaAIChatSessionsQuery = {
  client_id: number;
  projection?: Projection;
};

export type GetNovaAIChatSessionsResult = NovaAISessionType[];

export const GET_NOVA_AI_CHAT_SESSIONS_ENDPOINT_KEY = 'getNovaAIChatSessions';

export const { useLazyGetNovaAIChatSessionsQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_NOVA_AI_CHAT_SESSIONS_ENDPOINT_KEY]: build.query<GetNovaAIChatSessionsResult, GetNovaAIChatSessionsQuery>({
      transformResponse: (response: { nova_ai_chat_sessions: GetNovaAIChatSessionsResult }) => response.nova_ai_chat_sessions,
      query: ({
        projection = { guid: true },
        ...args
      }) => ({
        body: {
          query: {
            nova_ai_chat_sessions: {
              __args: cleanArguments(args),
              ...projection
            },
          }
        }
      })
    }),
  }),
});
