import React, { forwardRef } from 'react';
import { DatePicker as AntdDatePicker } from 'antd';
import classNames from 'classnames';
import { SkeletonInput } from '../SkeletonInput';
import css from './DatePicker.module.scss';

type Props = {
  className?: string;
  skeleton?: boolean;
  format?: string | string[];
} & {[key: string]: any;};

const DatePicker = forwardRef(({ className, skeleton = false, ...props }: Props, ref: any) => {
  // Show skeleton when loading is true
  if (skeleton) return <SkeletonInput loading={false} active={true} />;

  return (
    <AntdDatePicker {...props} className={classNames(className, css.root)} ref={ref} />
  );
});

DatePicker.displayName = 'DatePicker';

export default DatePicker;
