/**
 * OverviewCards
 *
 * Renders a grid of cards with a title, illustration, and link to a path
 * Useful for rendering cards on an overview page
 */

import React, { FC, ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, ChevronRightIcon, Container, Grid, Skeleton } from '@sprnova/nebula';
import * as styles from './OverviewCards.styles';

export type OverviewCardsItemProps = {
  title: string;
  path: string;
  illustration: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

export type OverviewCardsProps = {
  items?: OverviewCardsItemProps[];
  skeleton?: boolean;
  container?: boolean;
  skeletonCount?: number;
}

const gridItemProps = {
  xs: 12,
  md: 6,
  lg: 4
};

const IllustrationWrapper: FC = ({ children }) => (
  <Box display="flex" justifyContent="center">
    {children}
  </Box>
);

export const OverviewCards: FC<OverviewCardsProps> = ({
  container = false,
  items = [],
  skeleton = false,
  skeletonCount
}) => {
  const renderSkeletonCards = useMemo(() => Array.from({ length: skeletonCount || items?.length || 3 }).map((_, index) => (
    <Grid key={index} item {...gridItemProps}>
      <styles.OverviewCard
        title={<Skeleton width={175} height={35} />}
      >
        <IllustrationWrapper>
          <Skeleton variant="circular" width={90} height={90} />
        </IllustrationWrapper>
      </styles.OverviewCard>
    </Grid>
  )), [
    skeletonCount,
    items.length
  ]);

  const renderCards = useMemo(() => {
    if (!items?.length) {
      return null;
    }

    return items.map((item) => {
      const { title, path, illustration, disabled, onClick } = item;
      return <Grid key={path} item {...gridItemProps}>
        <styles.OverviewCard
          isInteractive
          component={Link}
          endIcon={<ChevronRightIcon />}
          isDisabled={disabled}
          key={path}
          title={title}
          to={path}
          onClick={onClick}
        >
          <IllustrationWrapper>
            {illustration}
          </IllustrationWrapper>
        </styles.OverviewCard>
      </Grid>;
    });
  }, [items]);

  const content = (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
    >
      {skeleton ? renderSkeletonCards : renderCards}
    </Grid>
  );

  if (container) {
    return (
      <Container hasVerticalPadding>
        {content}
      </Container>
    );
  }

  return content;
};
