import { MigrationManifest, PersistedState } from 'redux-persist';
import { entitiesInitialState } from 'features/entitiesRedux/entitiesSlice';

const flushEntitiesState = (state: PersistedState): PersistedState => {
  const defaultVersion = -1;
  return {
    _persist: {
      version: state?._persist?.version || defaultVersion,
      rehydrated: true,
    },
    ...state,
    // replace persisted state with initial state
    ...entitiesInitialState,
  };
};

// TODO: generate list automatically
const migrations: { [x: string]: MigrationManifest } = {
  entities: {
    0: flushEntitiesState,
    1: flushEntitiesState,
    2: flushEntitiesState,
    3: flushEntitiesState,
    4: flushEntitiesState,
    5: flushEntitiesState,
    6: flushEntitiesState,
    7: flushEntitiesState,
    8: flushEntitiesState,
    9: flushEntitiesState,
    10: flushEntitiesState,
    11: flushEntitiesState,
    12: flushEntitiesState,
    13: flushEntitiesState,
    14: flushEntitiesState,
    15: flushEntitiesState,
    16: flushEntitiesState,
    17: flushEntitiesState,
    18: flushEntitiesState,
    19: flushEntitiesState,
    20: flushEntitiesState,
    21: flushEntitiesState,
    22: flushEntitiesState,
    23: flushEntitiesState,
    24: flushEntitiesState,
    25: flushEntitiesState,
    26: flushEntitiesState,
    27: flushEntitiesState,
    28: flushEntitiesState,
    29: flushEntitiesState,
    30: flushEntitiesState,
    31: flushEntitiesState,
    32: flushEntitiesState,
    33: flushEntitiesState,
    34: flushEntitiesState,
  },
};

export { migrations };
