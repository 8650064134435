import React, { memo, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AppFooter } from '@sprnova/nebula';
import { useGetClientPublicQuery } from 'api/crudGraphQL/public/clients/getClient';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import PrivacyPolicyLink from 'components/PrivacyPolicyLink/PrivacyPolicyLink';
import { openIntercom, useExternalAccount } from 'utils';
import { ExternalUser } from 'features/entitiesRedux';
import { version } from '../../../../../package.json';

const ClientFooter = (): JSX.Element => {
  const { clientId: idRaw } = useParams<{ [x: string]: string }>();
  const { data: client } = useGetClientPublicQuery({
    id: parseInt(idRaw),
    projection: {
      id: true,
      name: true,
    }
  }, { skip: !parseInt(idRaw) });
  const mixpanel = useMixpanel();
  const externalUser: ExternalUser = useExternalAccount();

  /** Mixpanel Analytics */
  const handleTrackMixpanelEvent = useCallback((buttonClicked: string): void => {
    try {
      const options = {
        clientId: client?.id,
        clientName: client?.name,
        accountId: externalUser?.id,
        accountName: externalUser?.name,
        accountEmail: externalUser?.email,
        buttonClicked
      };
      if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: Client View Footer ${buttonClicked} Button Clicked`, options);
      if (mixpanel?.track) {
        mixpanel.track(`Client View Footer ${buttonClicked} Button Clicked`, options);
      }
    } catch (error) {
      console.error('Mixpanel error', error);
    }
  },[client?.id, client?.name, externalUser?.id, externalUser?.name, externalUser?.email, mixpanel]);

  const links = useMemo(() => [
    { label: 'Support Chat', onClick: (): void => {handleTrackMixpanelEvent('Support Chat'); openIntercom();}},
    { label: 'FAQs', href: 'https://help.novapower.io/en/collections/9820203-nova-faqs', target: '_blank',  rel: 'noopener noreferrer', onClick: (): void => {handleTrackMixpanelEvent('FAQs');} },
    { label: 'Help Articles', href: 'https://help.novapower.io/en/', target: '_blank',  rel: 'noopener noreferrer', onClick: (): void => {handleTrackMixpanelEvent('Help Articles');} },
    { label: 'Privacy Policy', component: PrivacyPolicyLink, onClick: (): void => {handleTrackMixpanelEvent('Privacy Policy');} },
  ], [handleTrackMixpanelEvent]);

  const content = useMemo(() =>
    `Copyright ©${new Date().getFullYear()} – nova v${version} – Power Digital Marketing`
  , []);

  return <AppFooter links={links} content={content} />;
};

export default memo(ClientFooter);
