/**
 * Entities -> DataSources -> Adapter
 */

import { createEntityAdapter } from '@reduxjs/toolkit';
import { compareField } from '../../utils/compareField';
import { DataSource } from './data_source';

/**
 * Redux toolkit entity adapter for storing/caching entities by id
 */
export const datasourcesAdapter = createEntityAdapter<DataSource>({
  selectId: (datasource) => datasource.id,
  sortComparer: compareField('name'),
});
