/* eslint-disable complexity */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SxProps } from '@mui/system';
import { Button, Dialog, DialogContent, TimesIcon, theme, Grid, Divider, Chip, Box, Avatar, Tooltip, Typography, Skeleton, Stack, CarouselProps } from '@sprnova/nebula';
import { Carousel as AntdCarousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { useGetPlaybookAdsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookAds';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { EmptyImage } from 'components/nebula/EmptyImage/EmptyImage';
import { capitalize, isEmpty } from 'lodash';
// eslint-disable-next-line import-x/no-named-as-default
import styled from 'styled-components';
import { PlaybookAd, PlaybookAdset, PlaybookCampaign } from 'features/entitiesRedux/models/creative_playbook';
import { useAccount } from 'features/global';
import { PlaybookDataType } from 'features/intelligence/creative-playbook/ViewCreativePlaybook/constants';
import Carousel from './Carousel';
import { NextArrow, PrevArrow } from './CarouselComponents';
import ImageComponent from './ImageComponent';
import { CreativeAffinityValueTypes } from '../../CreativeAffinity/utils/constants';
import { formatValuesBasedOnType, Data, formatRelativeDate } from '../../CreativeAffinity/utils/formatValuesBasedOnType';
import { removeExtension } from '../../CreativeAffinity/utils/removeExtension';
import { getPlatformIcon } from '../../CreativeAffinityUtils';
import { adProjection } from '../../ViewCreativePlaybook/components/PlaybookTable/components/PlaybookTableBody/components/CollapsibleData/projections';
import ColumnInfoPopper from '../../ViewCreativePlaybook/components/PlaybookTable/components/PlaybookTableBody/components/InfoPopper/ColumnInfoPopper';
import AffinityScore from '../../ViewCreativePlaybook/components/PlaybookTable/components/PlaybookTableBody/components/TableBodyData/components/AffinityScore';
import { useFilterContext } from '../Filters/Filters';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import css from './AdPreviewModalCard.module.scss';

//use the styled library to style the image
const StyledImage = styled.div`
  margin-right: 20px;
  min-width: fit-content;
`;

const StyledDialogTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 12px;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 24px 0px;
  display: flex;
`;

const StyledHeading = styled('div')(({ theme }) => ({
  textAlign: 'center',
  color: theme.colors.primary.charcoal[600],
  fontSize: theme.typography.h2.fontSize,
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: theme.typography.h2.fontWeight,

}));

const StyledNameContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`;

const StyledNameHeader = styled('div')(({ theme }) => ({
  textAlign: 'center',
  color: theme.colors.primary.charcoal[300],
  fontSize: theme.typography.h6.fontSize,
  fontFamily: theme.typography.h6.fontFamily,
  fontWeight: theme.typography.h6.fontWeight,
  display: 'flex',
  justifyContent: 'flex-start',
}));

const StyledName = styled('div')(({ theme }) => ({
  fontSize: theme.typography.h5.fontSize,
  fontFamily: theme.typography.h5.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  paddingTop: '4px',
  justifyContent: 'flex-start',
  textAlign: 'left',
  maxWidth: '240px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  display: 'inline-block',
  textOverflow: 'ellipsis',
}));

const StyledAffinityScoreContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.colors.neutrals.ghost[200],
  height: '51px',
  padding: '16px',
  marginBottom: '8px',
  borderRadius: '6px',
}));

const StyleLTVandCACContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: theme.colors.neutrals.ghost[200],
  height: '90px',
  width: '100%',
  padding: '16px',
  borderRadius: '6px',
}));

const StyledTable = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-between',
  backgroundColor: theme.colors.neutrals.ghost[200],
  minHeight: '390px',
  maxHeight: '550px',
  overflowY: 'auto',
  marginTop: '24px',
  borderRadius: '6px 0px 0px 6px',
}));

const StyledListItem = styled.li`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const StyledListContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.colors.primary.charcoal[300],
  fontSize: theme.typography.h6.fontSize,
  fontFamily: theme.typography.h6.fontFamily,
  fontWeight: theme.typography.h6.fontWeight,
}));

const StyledValue = styled('div')(({ theme }) => ({
  color: '#000',
  fontSize: theme.typography.h4.fontSize,
  fontFamily: theme.typography.h4.fontFamily,
  fontWeight: theme.typography.h4.fontWeight,
}));

interface AdPreviewModalCardProps {
  adPreviewModalData: PlaybookAd | PlaybookAdset | PlaybookCampaign;
  dataType?: Partial<PlaybookDataType>;
  height?: string;
  width?: string;
  radius?: string;
  border?: string;
  imageStyles?: React.CSSProperties;
  maxWidth?: string | null;
  minWidth?: string | null;
  sx?: SxProps;
  missingImageSize?: 'xl' | 'large' | 'medium' | 'small' | 'xs';
  skipFormatting?: boolean;
  adId?: number;
}

const AdPreviewModalCard: React.FC<AdPreviewModalCardProps> = ({
  adPreviewModalData,
  height = '48px',
  width = '47px',
  radius = '4px',
  border = 'none',
  imageStyles,
  maxWidth = '47px',
  minWidth = '47px',
  sx,
  missingImageSize = 'xs',
  adId,
}) => {
  const [open, setOpen] = React.useState(false);
  /**
   * Carousel refs
   */
  const imageRef = useRef<CarouselRef>(null);
  const videoRef = useRef<CarouselRef>(null);
  const { filter } = useFilterContext();
  const { start_date, end_date, time_period } = filter;
  const { data: playbookAdsData, isLoading: adsLoading } = useGetPlaybookAdsQuery({
    id: adId,
    start_date,
    end_date,
    time_period,
    projection: adProjection
  },
  { skip: !open || !adPreviewModalData?.id }
  );

  const queriedData: Partial<Data> = useMemo(() => {
    if (playbookAdsData) {
      return formatValuesBasedOnType(playbookAdsData.data[0], CreativeAffinityValueTypes);
    }
    return {};
  }, [playbookAdsData]);

  /**
   * Mixpanel event tracking
   */
  const mixpanel = useMixpanel();
  const { account } = useAccount();
  const trackAdPreviewModalCardClick = useCallback((): void => {
    try {
      if (!mixpanel) return;
      const mixpanelTitle = 'Creative Affinity - Ad Preview Modal Card Click';
      const options = {
        title: queriedData?.name,
        clientId: queriedData?.adset?.campaign?.client?.id,
        clientName: queriedData?.adset?.campaign?.client?.name,
        businessTypeId: queriedData?.adset?.campaign?.client?.business_type?.id,
        businessType: queriedData?.adset?.campaign?.client?.business_type?.name,
        adFormats: queriedData?.formats,
        platform: queriedData?.platform?.name,
        userId: account?.id,
        userName: account?.name,
      };
      mixpanel.track(mixpanelTitle, options);
      if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options, queriedData });
    } catch (error) {
      console.error('Track Mixpanel error', error);
    }
  }, [mixpanel, queriedData, account?.id, account?.name]);

  const firstImageUrl = useMemo(() => {
    const domain = adPreviewModalData?.first_asset?.domain_url;
    const url = adPreviewModalData?.first_asset?.url;
    const thumbnail = adPreviewModalData?.first_asset?.thumbnail;
    const type = url?.split('.')?.pop()?.toLowerCase();
    if (type === 'mp4') {
      if (!domain || !thumbnail) return undefined;
      return `${domain}/${thumbnail}`;
    } else {
      if (!domain || (!url && !thumbnail)) return undefined;
      return `${domain}/${url || thumbnail}`.toLowerCase();
    }
  }, [adPreviewModalData]);

  const [activeSlide, setActiveSlide] = useState<string>(queriedData?.formats?.split(', ')[0] || '');

  const defaultValues = { width: 0, height: 0, slide: 0 };
  const [activeInnerSlides, setActiveInnerSlides] = useState<Record<string, number | undefined>>({}); // to control pausing of video
  const [videoActiveSlide, setVideoActiveSlide] = useState(defaultValues);
  const [imageActiveSlide, setImageActiveSlide] = useState(defaultValues);

  useEffect(() => {
    if (open && !isEmpty(queriedData)) {
      /**
       * Track Mixpanel event when the ad preview modal card is clicked
       * and the data is available
       */
      trackAdPreviewModalCardClick();
    }
  }, [open, queriedData, trackAdPreviewModalCardClick]);

  const setActiveSlideBasedOnTallestImage = useCallback((url: string | undefined, index: number, active: Record<string, number>, setActive) => {
    if (!url) return;
    /**
     * Create a new image object and set the src to the url
     * When the image is loaded, check if the naturalHeight is greater than the current active height
     * If it is, set the active height to the naturalHeight and the active slide to the current index
     * This will ensure that the tallest image is the active image in the carousel (on load)
     */
    const type = url.split('.').pop();
    if (type === 'mp4') {
      const video = document.createElement('video');
      video.src = url?.toLowerCase();
      video.onloadedmetadata = (): void => {
        if (video.videoHeight >= active.height) {
          if (video.videoHeight === active.height && video.videoWidth < active.width) {
            return;
          }
          setActive({ width: video.videoWidth, height: video.videoHeight, slide: index });
        }
      };
      video.removeEventListener('loadedmetadata', () => {}); // remove the event listener to prevent memory leaks
      video.remove();
      return;
    }
    const img = new Image();
    img.src = url?.toLowerCase();
    img.onload = (): void => {
      if (img.naturalHeight >= active.height) {
        if (img.naturalHeight === active.height && img.naturalWidth < active.width) {
          return;
        }
        setActive({ width: img.naturalWidth, height: img.naturalHeight, slide: index });
      }
    };
    let hasAttemptedFallback = false;
    img.onerror = (e): void => {
      if (hasAttemptedFallback) {
        return; // Don't attempt fallback if we've already tried
      }
      // If the image fails to load, try to load the jpg version
      if (typeof e !== 'string') {
        hasAttemptedFallback = true;
        const target = e.target as HTMLImageElement;
        target.src = `${removeExtension(url)}.jpg`;
      }
    };
    img.removeEventListener('load', () => {}); // remove the event listener to prevent memory leaks
    img.remove();
  }, []);

  const handleClickOpen: () => void = () => {
    setOpen(true);
  };

  const handleClose: () => void = () => {
    setOpen(false);
  };

  const handleChipOnClick = (clientId: number): void => {
    window.open(`/power-view/accounts/${clientId}`, '_blank', 'noopener,noreferrer');
  };

  const { colors, typography } = useMemo(() => {
    return {
      colors: theme.variables.colors,
      typography: theme.typography,
    };
  }, []);

  type adPreviewListDataType = Array<{
    id: string;
    label: string;
    value: string | number | undefined;
  }>;

  const adPreviewListData: adPreviewListDataType = useMemo(() => {
    return [
      { id: 'platformName', label: 'PLATFORM', value: queriedData?.platform?.name },
      { id: 'playbookType', label: 'AD FORMAT', value: queriedData?.type },
      { id: 'industryName', label: 'INDUSTRY', value: queriedData?.adset?.campaign?.client?.industry?.name },
      { id: 'funnel', label: 'FUNNEL STAGE', value: queriedData?.funnel },
      { id: 'adSpend', label: 'AD SPEND', value: queriedData?.spend },
      { id: 'roas', label: 'ROAS', value: queriedData?.roas },
      { id: 'revenue', label: 'REVENUE', value: queriedData?.revenue },
      { id: 'cpa', label: 'CPA', value: queriedData?.cpa },
      { id: 'clicks', label: 'CLICKS', value: queriedData?.clicks },
      { id: 'impressions', label: 'IMPRESSIONS', value: queriedData?.impressions },
      { id: 'conversions', label: 'CONVERSIONS', value: queriedData?.conversions },
      { id: 'stopRate', label: 'STOP RATE', value: queriedData?.stop_rate },
      { id: 'holdRate', label: 'HOLD RATE', value: queriedData?.hold_rate },
      { id: 'ctr', label: 'CTR', value: queriedData?.ctr },
      { id: 'cvr', label: 'CVR', value: queriedData?.cvr },
      { id: 'cpc', label: 'CPC', value: queriedData?.cpc },
      { id: 'cpm', label: 'CPM', value: queriedData?.cpm },
      { id: 'last_refreshed', label: 'LAST UPDATED', value: formatRelativeDate(queriedData?.last_refreshed)},
    ];
  }, [queriedData]);

  const renderIcon = useCallback((
    platformName: string,
    item: string | number,
    adFormats: string[],
    index: number): JSX.Element | string | number | undefined | string[] => {
    switch (item) {
      case 'playbookType':
        return adFormats.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(', ');
      case 'platformName':
        return getPlatformIcon(platformName);
      default:
        return adPreviewListData[index].value;
    }
  },
  [adPreviewListData]
  );

  useEffect(() => {
    if (imageActiveSlide.slide !== 0) {
      imageRef.current?.goTo(imageActiveSlide.slide);
    }
  }, [imageActiveSlide.slide]);

  useEffect(() => {
    if (videoActiveSlide.slide !== 0) {
      videoRef.current?.goTo(videoActiveSlide.slide);
    }
  }, [videoActiveSlide.slide]);

  const renderImageCarousel = useCallback((format: string, images: Partial<Data>) => (
    <Carousel
      initialSlide={imageActiveSlide.slide}
      slides={
        images?.map((image: { domain_url: string; url: string; }, index: number) => {
          const url = `${image?.domain_url}/${image?.url}`.toLowerCase();
          if (!imageActiveSlide.height) {
            setActiveSlideBasedOnTallestImage(url, index, imageActiveSlide, setImageActiveSlide);
          }
          return ({
            content: <ImageComponent key={`${queriedData?.name}_${format}_${index}`} url={url} />
          });
        })
      }
    />
  ), [imageActiveSlide, queriedData?.name, setActiveSlideBasedOnTallestImage]);

  const renderVideoCarousel = useCallback((format: string, videos: Partial<Data>) => (
    <Carousel
      initialSlide={videoActiveSlide.slide}
      slides={
        videos?.map((video: { domain_url: string; thumbnail: string; url?: string; }, index: number) => {
          const { domain_url, url } = video;
          const source = domain_url && url ? `${video?.domain_url}/${video?.url}`.toLowerCase() : undefined;
          if (activeInnerSlides.video === undefined) {
            setActiveInnerSlides({ video: videoActiveSlide.slide });
            if (!videoActiveSlide.height) {
              setActiveSlideBasedOnTallestImage(source, index, videoActiveSlide, setVideoActiveSlide);
            }
          }
          return ({
            content: <VideoPlayer
              key={`${queriedData?.name}_${format}_${index}`}
              videoSource={source}
              currentSlide={activeInnerSlides.video}
              index={index}
            />
          });
        })
      }
      beforeChange={
        (currentSlide: number, nextSlide: number): void => {
          setActiveInnerSlides({
            video: nextSlide
          });
        }
      }
    />
  ), [activeInnerSlides.video, queriedData?.name, setActiveSlideBasedOnTallestImage, videoActiveSlide]);

  const renderCarouselCarousel = useCallback((format: string, carousels: Partial<Data>) => {
    let slides: CarouselProps['slides'] = [];
    if (carousels.length === 0) return <EmptyImage size='large' />;
    // Check if there is only one carousel because there may be cases where there are multiple carousels and we want to handle them correctly
    if (carousels.length === 1) {
      slides = carousels?.map((carousel: Data) => (
        carousel.assets.map((asset: { type: string; domain_url: string; url: string; thumbnail: string; }, index: number) => {
          const url = asset.type === 'image' ? `${asset?.domain_url}/${asset?.url}` : `${asset?.domain_url}/${asset?.thumbnail}`;
          return {
            content: <ImageComponent key={`${queriedData?.name}_${format}_${index}`} url={url} />
          };
        })?.[0]
      ));
    } else {
      slides = carousels?.map((carousel: Data) => ({
        content:
        <Carousel
          slides={
            carousel.assets.map((asset: { type: string; domain_url: string; url: string; thumbnail: string; }, index: number) => {
              const url = asset.type === 'image' ? `${asset?.domain_url}/${asset?.url}` : `${asset?.domain_url}/${asset?.thumbnail}`;
              return {
                content: <ImageComponent key={`${queriedData?.name}_${format}_${index}`} url={url} />
              };
            })
          }
        />
      }));
    }
    return (
      <Carousel
        slides={slides}
        // Removes padding from the Card inside the Nebula Carousel
        sx={{ '& .slick-slide': {
          '& > div > div': {
            p: 0
          },
          '& div > .MuiBox-root > .MuiBox-root': {
            p: 0,
            '& > div': {
              overflow: 'visible'
            }
          }
        }}}
      />
    );
  }, [queriedData?.name]);

  const renderCarousel = useCallback((format: string) => {
    if (adsLoading || !queriedData) return <Skeleton variant="rectangular" width="100%" height="100%" />;
    const { images, videos, carousels } = queriedData;
    if (format.toLowerCase() === 'image' && images?.length > 0) {
      return renderImageCarousel(format, images);
    }
    if (format.toLowerCase() === 'video' && videos?.length > 0) {
      return renderVideoCarousel(format, videos);
    }
    if (format.toLowerCase() === 'carousel' && carousels?.[0]?.assets?.length > 0) {
      return renderCarouselCarousel(format, carousels);
    }
    return <EmptyImage size='large' />;
  }, [adsLoading, queriedData, renderCarouselCarousel, renderImageCarousel, renderVideoCarousel]);

  const renderDialog = useMemo(() => {
    if (!adsLoading && isEmpty(queriedData)) {
      return;
    }
    const formats = queriedData?.formats?.split(', ') || [];
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: 0,
          backgroundColor: 'transparent !important',
        }}
        size='lg'
      >
        {
          adsLoading
            ? Array.from(new Array(5)).map((_, i) => (
              <Skeleton key={`skeleton_${i}`} variant="rectangular" width="100%" height="40px" sx={{ marginBottom: '20px' }} />
            ))
            : <>
              {/* TODO: Replace this with Nebula when we update to not for placement of contents into Card */}
              <AntdCarousel
                dotPosition={'bottom'}
                nextArrow={<NextArrow />}
                prevArrow={<PrevArrow />}
                arrows
                beforeChange={
                  (currentSlide: number, nextSlide: number) => {
                    setActiveSlide(formats[nextSlide]);
                  }
                }
                className={css.largeCarousel}
              >
                {formats?.map((format: string) => {
                  return <div key={`${queriedData?.name}_${format}`}>
                    <StyledDialogTitle id="alert-dialog-title">
                      <StyledHeading theme={{ colors, typography }}>
                        {'Ad Preview'}
                      </StyledHeading>
                      <Button
                        variant="text"
                        key="action-1"
                        sx={{
                          color: 'black'
                        }}
                        onClick={handleClose}
                        startIcon={<TimesIcon />}
                      />
                    </StyledDialogTitle>
                    <StyledDialogContent>
                      <Grid container justifyContent='space-between' sx={{ paddingBottom: '20px' }}>
                        <Grid item xs={5}>
                          <Stack spacing={2}>
                            {renderCarousel(format)}
                          </Stack>
                          <Box className={css.dialogContentLeftGrid_chip}>
                            <StyledNameHeader theme={{ colors, typography }}>CLIENT</StyledNameHeader>
                            <Chip
                              avatar={
                                queriedData?.adset?.campaign?.client?.logo ?
                                  <Avatar
                                    alt={`${queriedData?.adset?.campaign?.client?.name} logo`}
                                    src={queriedData?.adset?.campaign?.client?.logo} size='xs'
                                    sx={{ backgroundColor: '#FFFFFF !important' }}/>
                                  :
                                  <Avatar
                                    alt={`${queriedData?.adset?.campaign?.client?.name} logo`}
                                    size='xs'>
                                    {queriedData?.adset?.campaign?.client?.name[0]}
                                  </Avatar>
                              }
                              label={queriedData?.adset?.campaign?.client?.name}
                              onClick={(): void => {
                                if (queriedData?.adset?.campaign?.client?.id) {
                                  handleChipOnClick(queriedData?.adset?.campaign?.client?.id);
                                }
                              }}
                              style={{marginTop: '8px'}}
                            />
                            <br />
                            <br />
                          </Box>

                          <div className={css.dialogContentLeftGrid_namesDiv}>
                            {queriedData?.adset?.campaign?.name &&
                            <StyledNameContainer>
                              <StyledNameHeader theme={{ colors, typography }}>CAMPAIGN</StyledNameHeader>
                              <Tooltip content={queriedData?.adset?.campaign?.name} variant='rich' placement='right'>
                                <StyledName theme={{ colors, typography }}>
                                  {queriedData?.adset?.campaign?.name}
                                </StyledName>
                              </Tooltip>
                            </StyledNameContainer>
                            }
                            {queriedData?.adset?.name &&
                            <StyledNameContainer>
                              <StyledNameHeader theme={{ colors, typography }}>AD SET</StyledNameHeader>
                              <Tooltip content={queriedData?.adset?.name} variant='rich' placement='right'>
                                <StyledName theme={{ colors, typography }}>
                                  {queriedData?.adset?.name}
                                </StyledName>
                              </Tooltip>
                            </StyledNameContainer>
                            }
                            {queriedData?.name &&
                            <StyledNameContainer>
                              <StyledNameHeader theme={{ colors, typography }}>AD</StyledNameHeader>
                              <Tooltip content={queriedData?.name} variant='rich' placement='right'>
                                <StyledName theme={{ colors, typography }}>
                                  {queriedData?.name}
                                </StyledName>
                              </Tooltip>
                            </StyledNameContainer>
                            }
                          </div>
                        </Grid>

                        <Grid item xs={6}>
                          <StyledAffinityScoreContainer theme={{ colors }}>
                            <StyledLabel variant='h6' theme={{ colors, typography }}>AFFINITY SCORE</StyledLabel>
                            <StyledValue theme={{ colors, typography }}>
                              {queriedData?.score ?
                                <AffinityScore score={queriedData?.score} sx={{ fontSize: '16px!important' }} />
                                :
                                <div style={{display: 'flex'}}>
                                  Unavailable
                                  <ColumnInfoPopper
                                    info={'The Affinity Score is currently unavailable due to the unavailability of certain metrics necessary for calculating the score.'}
                                  />
                                </div>
                              }
                            </StyledValue>
                          </StyledAffinityScoreContainer>
                          <Grid className={css.dialogContentRightGrid_LTVandCACContainerGrid}>
                            <StyleLTVandCACContainer theme={{ colors }}>
                              <StyledLabel variant='h6' theme={{ colors, typography }}>LTV</StyledLabel>
                              <StyledValue theme={{ colors, typography }}>
                                {queriedData?.ltv && queriedData?.ltv !== '-' ? (
                                  <>
                                    {queriedData?.ltv}
                                  </>
                                ) : (
                                  <div style={{ display: 'flex' }}>
                                    Unavailable
                                    <ColumnInfoPopper
                                      info={'LTV is unavailable because there were no matches that occurred.'}
                                    />
                                  </div>
                                )}
                              </StyledValue>
                            </StyleLTVandCACContainer>
                          </Grid>
                          <StyledTable theme={{ colors }}>
                            <ul className={css.dialogContentRightGrid_list}>
                              {adPreviewListData.map((item, i) => {
                                return <StyledListItem key={`adPreviewListOver_${i}`} style={{paddingTop: i === 0 ? '0px' : '5px',}}>
                                  <StyledListContent>
                                    <StyledLabel variant='h6' theme={{ colors, typography }}>{adPreviewListData[i].label}</StyledLabel>
                                    <StyledValue theme={{ colors, typography }}>
                                      {queriedData?.platform?.name && item && formats && (
                                        renderIcon(queriedData?.platform.name, item.id, formats, i)
                                      )}
                                    </StyledValue>
                                  </StyledListContent>
                                  <Divider />
                                </StyledListItem>;
                              })}
                            </ul>
                          </StyledTable>
                        </Grid>
                      </Grid>
                    </StyledDialogContent>
                  </div>;
                }
                )}
              </AntdCarousel>
              {
                formats?.length > 1 &&
                <Typography
                  variant='h4'
                  sx={{
                    borderTop: '1px solid #D9D9D9',
                    paddingTop: '20px',
                    bottom: '55px',
                    position: 'relative',
                    textAlign: 'center'
                  }}
                >
                  {capitalize(activeSlide)}
                </Typography>
              }
            </>
        }
      </Dialog>
    );
  }, [activeSlide, adPreviewListData, adsLoading, colors, open, queriedData, renderCarousel, renderIcon, typography]);

  return (
    <Box
      sx={{
        maxWidth: maxWidth || width,
        minWidth: minWidth || width,
        overflow: 'hidden',
        marginRight: '20px',
        ...sx,
      }}
    >
      {
        firstImageUrl === undefined
          ? <EmptyImage size={missingImageSize} onClick={handleClickOpen} className={css.image} boxProps={{ sx: { borderRadius: radius, border: border }} } />
          : <StyledImage
            style={{
              borderRadius: radius,
              ...imageStyles,
              background: `url(${firstImageUrl}), url(${removeExtension(firstImageUrl)}.jpg)`,
              width,
              height,
              border: 'none',
              backgroundSize: 'cover',
            }}
            onClick={handleClickOpen}
            className={css.image}
          />
      }
      {renderDialog}
    </Box>
  );
};

export default AdPreviewModalCard;
