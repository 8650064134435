import { useParams } from 'react-router-dom';
import { useGetNovaChallengesPublicQuery } from 'api/crudGraphQL/public/nova_ai/getNovaChallengesPublic';
import { useExternalAccount } from 'utils';
import { NovaAiChallengeType } from 'features/entitiesRedux/models/nova_ai';

type ReturnType = {
    show: boolean;
    challengeData: NovaAiChallengeType[];
    refetch: () => void;
}

export const useNovaChallengesBanner = (): ReturnType => {
  // Get current user account
  const externalUser = useExternalAccount();

  // Get client ID from url and fetch challenge data
  const { clientId: idRaw } = useParams<{ [clientId: string]: string }>();
  const { data: challengeData = [], refetch } = useGetNovaChallengesPublicQuery(
    {
      client_id: Number.parseInt(idRaw),
      projection: {
        step: true,
        status: true,
        question_limit: true,
        question_count: true,
        message: true,
        question_trigger: true,
        action: true,
      },
    }, { skip: (externalUser?.is_pdm_employee === true || externalUser?.is_pdm_employee === undefined) }
  );

  const show = !!challengeData?.length;

  return { show, challengeData, refetch };

};

export default useNovaChallengesBanner;
