import { getClient } from 'api/entityGraphQL/utils';
import { QuestionHandler } from '../actions';

export const preferredChannelsHandler: QuestionHandler = (id, key, value, additionalInfo): any => {
  if (Array.isArray(value)) {
    if (!value?.length) {
      return getClient().deleteDiscoveryQuestion({
        discovery_id: additionalInfo?.discoveryId as number,
        question_id: id as number
      });
    }

    const preferredChannels = value?.filter(channel => !!channel).join(',');
    return getClient().updateDiscoveryQuestion({
      discovery_id: additionalInfo?.discoveryId as number,
      question_id: id as number,
      answer: preferredChannels
    });
  }
};
