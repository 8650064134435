/**
 * SelectGoal
 */

import React, { forwardRef, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { uniq } from 'lodash';
import { Divider, Input } from '../../../../../../../../../../components';
import Select, { Props as SelectProps } from '../../../../../../../../../../components/Select/Select';
import { useGoals } from '../../../../../../../../../../utils';
import { Goal } from '../../../../../../../../../entitiesRedux';

interface Props extends SelectProps {
  discoveryGoals: Goal[];
}

let index = 1;

const SelectGoal = forwardRef(({ skeleton, discoveryGoals, ...props }: Props, ref: any) => {
  const { goals, loading } = useGoals();

  // get the default goal options from the goals table
  const defaultGoalNames = goals?.map(goal => goal?.name);
  // get the discovery_goal names for this discovery
  const discoveryGoalNames = discoveryGoals?.map(goal => goal?.name);
  // combine both arrays and de-dupe to get our final list for the dropdown
  const goalNameOptions = uniq([...defaultGoalNames, ...discoveryGoalNames]);

  // local state variables for custom input and tracking the dropdown options
  const [customInputName, setCustomInputName] = useState<string>();
  const [items, setItems] = useState<Array<string | null>>([]);

  useEffect(() => {
    if (!loading) setItems(goalNameOptions);
  }, [loading]);

  const addItem = () => {
    setItems([...items, customInputName || `Other goal ${index++}`]);
    setCustomInputName('');
  };

  return (
    <Select
      allowClear
      showArrow
      skeleton={loading || skeleton}
      optionFilterProp="children"
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      dropdownRender={menu => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
            <Input
              placeholder="Enter goal name"
              style={{ flex: 'auto' }}
              value={customInputName}
              onChange={(e) => setCustomInputName(e.target.value)}
              onPressEnter={addItem}
              onKeyDown={e => e.stopPropagation()} // prevents removing selection when pressing delete key
            />
            <a
              style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
              onClick={addItem}
            >
              <PlusOutlined /> Add goal
            </a>
          </div>
        </div>
      )}
      {...props}
    >
      {items?.map(item => {
        if (item !== null) {
          return (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          );
        }
      })}
    </Select>
  );
});

SelectGoal.displayName = 'SelectGoal';
export default SelectGoal;
