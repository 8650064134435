import { Projection } from 'api/entityGraphQL/types';
import { basePublicApi } from '../basePublicApi';
import { cleanArguments } from 'api/crudGraphQL/utils';

export type DeleteNovaAIChatPublicMutation = {
  session_guid: string;
  prompt_guid?: string
  projection?: Projection;
}

export type DeleteNovaAIChatPublicResult = { status: string };

export const DELETE_NOVA_AI_CHAT_PUBLIC_ENDPOINT_KEY = 'deleteNovaAIChatPublic';

export const { useDeleteNovaAIChatPublicMutation } = basePublicApi.injectEndpoints({
  endpoints: (builder) => ({
    [DELETE_NOVA_AI_CHAT_PUBLIC_ENDPOINT_KEY]: builder.mutation<DeleteNovaAIChatPublicResult, DeleteNovaAIChatPublicMutation>({
      transformResponse: (response: { deleteNovaAIChat: DeleteNovaAIChatPublicResult }) =>  response.deleteNovaAIChat,
      query: ({ projection = {status: true}, ...values}) => {
        const __args: Partial<DeleteNovaAIChatPublicMutation> = values;
        return ({
          body: {
            mutation: {
              deleteNovaAIChat: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        })
      }
    }),
  }),
});
