import { useUser } from './useUser';

/**
 * Configures Intercom for the current internal or external user.
 */
export const useIntercom = (): void => {
  const { internalUser, externalUser } = useUser();
  const hasUser = Boolean(internalUser || externalUser);
  const Intercom = (window as Window).Intercom;

  try {
    if (Intercom && hasUser) {

      // Load the Intercom messenger
      Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'ng1rnwct'
      });

      let intercomConfig = {};

      /**
       * When using a share link, the user will be set as "anonymous" with an ID of 0;
       * We don't want this because different users (clients) will all have access to the same Intercom messages
       * especially because some of these messages may contain sensitive information
       */

      if (internalUser?.id) {
        intercomConfig = {
          created_at: internalUser?.created_at,
          department: internalUser?.department?.name,
          email: internalUser?.email,
          name: internalUser?.name,
          title: internalUser?.job_title?.name,
          user_id: internalUser.id,
        };
      } else if (externalUser?.id) {
        intercomConfig = {
          created_at: externalUser?.created_at,
          email: externalUser?.email,
          name: externalUser?.name,
          user_id: externalUser.id,
        };
      } else {
        intercomConfig = {};
      }

      Intercom('update', intercomConfig);
    }
  } catch (e) {
    console.error('Error initializing Intercom', e);
  }
};
