/**
 * Library -> LibraryPageHero
 */

import React, {FC, useCallback, useMemo} from 'react';
import { useHistory } from 'react-router-dom';
import { HeroProps, HeroTabs, HeroTabsProps } from '@sprnova/nebula';
import { PageHero } from 'layouts/components';
import {StringParam, useQueryParam, withDefault} from 'use-query-params';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
export interface LibraryPageHeroProps extends HeroProps {
  tabs?: HeroTabsProps['items'];
}

const LibraryPageHero: FC<LibraryPageHeroProps> = ({ end, title, description }) => {
  const history = useHistory();
  const [pricingVersionQueryParam, setPricingVersionQueryParam] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );
  const activeTab =  useMemo ( () => history.location.pathname + '?pricingVersion=' + pricingVersionQueryParam
    , [history, pricingVersionQueryParam]);

  const handleChangeTab = useCallback ( (path: string): void => { history.replace(path); }, [history]);
  const tabsHourly =  useMemo(() => [
    {
      value: '/library/departments?pricingVersion=' + pricingVersionQueryParam,
      label: 'Departments'
    },
    {
      value: '/library/services?pricingVersion=' + pricingVersionQueryParam,
      label: 'Services'
    },
    {
      value: '/library/tasks?pricingVersion=' + pricingVersionQueryParam,
      label: 'Strategies'
    },
    {
      value: '/library/rules?pricingVersion=' + pricingVersionQueryParam,
      label: 'Rules'
    },
    {
      value: '/library/outsource-cost-guide?pricingVersion=' + pricingVersionQueryParam,
      label: 'Outsource Cost Guide'
    }
  ], []);

  const tabsPackage = useMemo(() => [
    {
      value: '/library/departments?pricingVersion=' + pricingVersionQueryParam,
      label: 'Departments'
    },
    {
      value: '/library/services?pricingVersion=' + pricingVersionQueryParam,
      label: 'Services'
    },
    {
      value: '/library/tasks?pricingVersion=' + pricingVersionQueryParam,
      label: 'Strategies'
    },
    {
      value: '/library/outsource-cost-guide?pricingVersion=' + pricingVersionQueryParam,
      label: 'Outsource Cost Guide'
    }
  ], []);

  const tabs = useMemo(() => (pricingVersionQueryParam === PricingVersion.PACKAGE) ? tabsPackage : tabsHourly
    , [pricingVersionQueryParam, tabsPackage, tabsHourly]);

  return (
    <PageHero
      title={title}
      description={description}
      end={end}
      tabs={
        <HeroTabs
          value={activeTab}
          items={tabs}
          onChange={(e, key: string): void => handleChangeTab(key)}
        />
      }
    />
  );
};

export default LibraryPageHero;
