import React, { memo } from 'react';
import { Dialog, DialogActions } from '@sprnova/nebula';

type DeleteTierModalProps = {
  openDialog: boolean;
  handleDeleteTier: () => void;
  handleCloseDialog: () => void;
}

/**
 * Dialog box to confirm deletion of a pricing tier
 * @param openDialog Boolean that controls the dialog open state
 * @param handleDeleteTier Function to handle the confirmation of the deletion of the tier
 * @param handleCloseDialog Function to handle the closing of the dialog
 * @returns
 */
const DeleteTierModal = ({ openDialog, handleDeleteTier, handleCloseDialog }: DeleteTierModalProps ): JSX.Element => {
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        title="Delete this tier?"
        size="sm"
        footer={
          <DialogActions
            primaryButtonProps={{ onClick: () => handleDeleteTier(), children: 'Yes', size: 'small' }}
            secondaryButtonProps={{ onClick: () => handleCloseDialog(), children: 'No',size: 'small', variant: 'text' }}
          />
        }
      >
        Are you sure you want to delete this tier item?
      </Dialog>
    </>
  );
};

export default memo(DeleteTierModal);
