/**
 * View strategy -> Hourly Breakdown -> Table -> HourField
 */
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ReloadIcon, WarningIcon } from '@sprnova/nebula';
import { useUpdateStrategyMutation } from 'api/crudGraphQL/strategies/updateStrategy';
import classNames from 'classnames';
import { CheckmarkIcon } from 'components/Icon';
import { Button, notification } from 'components';
import css from './AlertBar.module.scss';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';

type AlertBarProps = {
  className?: string;
  hoursPending: number;
  strategyId: number;
  hasChanged: boolean;
  setHasChanged: (hasChanged: boolean) => void;
  refetchAll: () => void;
  isFetchingStrategyTasks: boolean;
};

const AlertBar = ({
  className,
  hoursPending,
  hasChanged,
  setHasChanged,
  strategyId,
  refetchAll,
  isFetchingStrategyTasks,
}: AlertBarProps): JSX.Element => {

  const [isSaving, setIsSaving] = useState(false); // State that is set to true when the mutation is pending
  const [requestHasFinished, setRequestHasFinished] = useState(false); // State to check when the mutation call is done
  // const { sidebarIsCollapsed } = useContext(DefaultLayoutContext);

  /** Mutation to update the strategy */
  const [updateStrategy] = useUpdateStrategyMutation();

  /** Mixpanel Event Tracking */
  const mixpanel = useMixpanel();
  const mixpanelOptions = useCallback(() =>{
    return {
      blueprintId: strategyId,
    };
  }, [strategyId]);

  /**
   * On Save button click.
   * Call updateStrategy mutation.
   * Update saving states to be able to show loading and success text messages and update the button style accordingly.
   *
   * @returns void
   */
  const onSaveChanges = useCallback(async () => {
    try {
      setIsSaving(true);
      await updateStrategy({
        id: strategyId
      });

      notification.success({
        message: 'Blueprint recalculated. Fetching new data...',
      });
      setHasChanged(false);
      refetchAll();
      if (mixpanel?.track) {
        mixpanel.track('Blueprint recalculated.', mixpanelOptions);
      }
    } catch (error) {
      console.error('Error deleting task(s)', error);
    } finally {
      setRequestHasFinished(true);
      setIsSaving(false);
    }
  }, [mixpanel, mixpanelOptions, refetchAll, setHasChanged, strategyId, updateStrategy]);


  const isDisabled = useMemo(() => !hasChanged || isSaving || hoursPending > 0, [hasChanged, isSaving, hoursPending]);
  const pendingHoursText = hoursPending > 1 ? `Please wait while ${hoursPending} changes are being saved.` : `Please wait while ${hoursPending} change is being saved.`;

  /**
   * Render save action container: Loading / Success text and Save action button.
   *
   * @returns JSX.Element
   */
  const renderSaveActionContainer = useCallback((): JSX.Element => {
    return (
      <div className={css.container__save}>
        {isSaving && (<div className={css.container__save_text}><ReloadIcon className={css.icon_loading} htmlColor='#989898' /><span>Please wait while your changes are being processed.</span></div>)}
        {requestHasFinished && !hasChanged && (<div className={css.container__save_text_success}><CheckmarkIcon className={css.icon_check}/><span>Your changes have been processed!</span></div>)}
        {hoursPending > 0 && <><ReloadIcon className={css.icon_loading} htmlColor='#989898' /><div className={css.container__save_text}><span>{pendingHoursText}</span></div></>}
        {isFetchingStrategyTasks && <><ReloadIcon className={css.icon_loading} htmlColor='#989898' /><div className={css.container__save_text}>Totals and task data updating. Please wait...</div></>}
        <div className={classNames(css.container__save_action, { [css.container__save_action_disabled]: isDisabled })}>
          <Button
            onClick={onSaveChanges}
            disabled={isDisabled}
          >
            Recalculate
          </Button>
        </div>
      </div>
    );
  }, [isSaving, requestHasFinished, hasChanged, hoursPending, pendingHoursText, isFetchingStrategyTasks, isDisabled, onSaveChanges]);

  return (
    <div className={classNames(css.root)}>
      <div className={css.progress_bar}>
      </div>
      <div className={css.alert__container}>
        <div className={css.alert__container_warning}>
          <WarningIcon className={css.icon__warning} /><b>Changes detected to Blueprint service hours</b>
        </div>
        <div className={css.alert__container_action}>
          {renderSaveActionContainer()}
          <div className={css.alert__container_action_text}></div>
          <div className={css.alert__container_action_button}></div>
        </div>
      </div>
    </div>
  );
};

export default memo(AlertBar);
