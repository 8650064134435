export const playbookCampaignProjection = {
  id: true,
  name: true,
  client: {
    id: true,
    name: true,
    logo: true,
    industry: {
      id: true,
      name: true
    },
    business_type: {
      id: true,
      name: true
    },
    playbook_platform_match_rates: {
      platform: {
        id: true,
        name: true,
        slug: true,
      },
      match_rate: true,
    },
  },
  first_asset:{
    domain_url: true,
    url: true,
    thumbnail: true,
  },
  funnel: true,
  objective: true,
  status: true,
  spend: true,
  roas: true,
  revenue: true,
  // results: true,
  stop_rate: true,
  retention_rate: true,
  hold_rate: true,
  score: true,
  ctr: true,
  cpc: true,
  cpm: true,
  cpa: true,
  cvr: true,
  clicks: true,
  impressions: true,
  conversions: true,
  slug: true,
  updated_at: true,
  created_at: true,
  last_refreshed: true,
  ecomm_matched_metric: {
    id: true,
    ltv: true,
    cac: true,
  }
};

