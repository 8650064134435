/**
 * Delete scoreboard (report)
 */

import { Projection } from 'api/entityGraphQL/types';
import { baseApi } from '..';
import clientTagTypes from '../clients/tagTypes';
import tagTypes from './tagTypes';
import { Scoreboard } from './types';

export type DeleteScoreboardMutation = {
  id: number;
  projection?: Projection;
}

export type DeleteScoreboardResult = Scoreboard;

export const DELETE_SCOREBOARD = 'deleteScoreboard';

export const { useDeleteScoreboardMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [DELETE_SCOREBOARD]: build.mutation<DeleteScoreboardResult, DeleteScoreboardMutation>({
      invalidatesTags: [
        tagTypes.SCOREBOARDS,
        tagTypes.SCOREBOARD,
        clientTagTypes.CLIENTS,
        clientTagTypes.CLIENT,
      ],
      query: ({ id, projection = { id: true } }) => ({
        body: {
          mutation: {
            deleteReport: {
              __args: {
                id
              },
              ...projection,
              id: true
            }
          }
        }
      })
    })
  })
});
