/**
 * Generate Proposal Button
 * Utility component for rendering a "Generate proposal button"
 */

import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GoogleOutlined, SyncOutlined } from '@ant-design/icons';
import { config } from 'config';
import { createStrategyProposal, User } from 'features/entitiesRedux';
import { Button, ButtonProps, Menu } from 'components';
import { BLUEPRINT_PAUSE_TIME_UNTIL_NEXT_ACTION } from '../../../constants';
import GoogleAuthRedirectUrl
  from '../../../ViewBlueprintPage/components/GenerateDocument/components/GenerateProposalButton/components/GoogleAuthRedirectUrl';
import { handleError, handleSuccess, isGoogleAuthenticated } from '../../utils';
import css from './GenerateProposalButton.module.scss';

type RenderProps = {
  onClick: () => void;
  loading: boolean;
};
interface GenerateProposalButtonProps extends ButtonProps {
  onComplete?: () => void;
  children?: any;
  strategyId: number;
  account: User | null;
  setIsButtonDisabled: (value: boolean) => void;
  isButtonDisabled: boolean;
  // reviewer_status?: string;
}

const GenerateProposalButton = ({
  onComplete,
  children,
  icon,
  strategyId,
  account,
  setIsButtonDisabled,
  isButtonDisabled,
  ...rest
} : GenerateProposalButtonProps) : JSX.Element => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const redirect = `${config?.host}${location.pathname}` || '/';
  const document = 'Proposal';

  /**
   * Handle Generate Proposal
   */
  const handleGenerateProposal = async () => {
    setLoading(true);

    try {
      const createProposalAction: any = await dispatch(
        createStrategyProposal({ id: strategyId })
      );

      if (createStrategyProposal.fulfilled.match(createProposalAction)) {
        handleSuccess(document, onComplete);
      }

      if (createStrategyProposal.rejected.match(createProposalAction)) {
        setIsButtonDisabled(true);
        setTimeout(() => setIsButtonDisabled(false), BLUEPRINT_PAUSE_TIME_UNTIL_NEXT_ACTION);
        handleError(document);
      }
    } catch (error) {
      console.error('Error creating strategy proposal', error);
      setIsButtonDisabled(true);
      setTimeout(() => setIsButtonDisabled(false), BLUEPRINT_PAUSE_TIME_UNTIL_NEXT_ACTION);
    }
    setLoading(false);
  };

  const generateButton = useMemo(() => {
    return (<Button
      {...rest}
      onClick={handleGenerateProposal}
      disabled={loading || isButtonDisabled}
      icon={loading ? <SyncOutlined spin className={css.spinIcon} /> : icon}
    >
      {loading ? 'Generating...' : children}
    </Button>);
  }, [loading, isButtonDisabled, handleGenerateProposal, children, icon, rest]);

  /**
   * Handle if NOT Google Authenticated
   */
  if(!isGoogleAuthenticated(account)) {
    return (
      <Menu>
        <Menu.Item key='proposal-google-redirect'>
          <GoogleAuthRedirectUrl
            redirect={redirect}
            buttonLabel={'Reauthorize Google Account'}
            sx={{ border: '0px none !important' }}
            startIcon={<GoogleOutlined />}
          />
        </Menu.Item>
      </Menu>
    );
  }

  const renderProps: RenderProps = {
    loading,
    onClick: handleGenerateProposal,
  };

  // Use as a render prop component
  if (typeof children === 'function') {
    return children(renderProps);
  }


  // Show the generation button
  return (
    <>
      {generateButton}
    </>
  );
};

export default GenerateProposalButton;
