import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Projection } from 'api/entityGraphQL';
import { useEntitiesPaginated, useEntityByParamId } from '../_entity/hooks';
import { HookPaginatedConfig, HookPaginatedReturn } from '../types';
import { fetchAuditsPaginated, fetchAuditById } from './actions';
import { /* Discovery, */ Audit } from './audit';
import { selectAuditsByIds, selectAuditById } from './selectors';

export const useAuditsPaginated = ({
  page: defaultPage,
  limit: defaultLimit,
  filter: defaultFilter,
  projection,
  trackHistory = true,
}: HookPaginatedConfig = {}): HookPaginatedReturn & {
  audits: Partial<Audit>[];
} => {
  const {
    entities: { audits: auditIds },
    isLoading,
    error,
    filter,
    setFilter,
    pagination,
  } = useEntitiesPaginated(fetchAuditsPaginated, {
    page: defaultPage,
    limit: defaultLimit,
    filter: defaultFilter,
    projection,
    trackHistory,
  });
  const audits = useSelector(selectAuditsByIds(auditIds));

  return { audits, isLoading, error, filter, setFilter, pagination };
};

export const useAuditByParamId = (
  config: {
    projection?: Projection;
  } = {}
): Audit => useEntityByParamId(selectAuditById, fetchAuditById, config);

export const useAuditRefreshById = (
  auditId: number,
  config: { projection?: Projection } = {}
) => {
  const dispatch = useDispatch();

  const refresh = () => {
    dispatch(fetchAuditById({ id: auditId, ...config }));
  };

  useEffect(() => {
    dispatch(fetchAuditById({ id: auditId, ...config }));
  }, [auditId]); // eslint-disable-line

  return {
    audit: useSelector(selectAuditById(auditId)),
    refresh
  };
};

export const useAuditById = (
  auditId: number,
  config: { projection?: Projection } = {}
) => {
  const { audit, refresh } = useAuditRefreshById(auditId, config);

  useEffect(() => {
    refresh();
  }, []); // eslint-disable-line

  return audit;
};
