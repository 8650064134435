import React, { FC, forwardRef } from 'react';
import { useGetUsersQuery } from 'api/crudGraphQL/users/getUsers';
import classnames from 'classnames';
import { unionBy } from 'lodash';
import { User } from 'features/entitiesRedux';
import { useAccount } from 'features/global';
import Select, { Props as SelectProps } from '../Select/Select';
import { surveyUserProjection } from './projection';
import css from './SelectSurveyUser.module.scss';

type Props = SelectProps & {
  disableSkeleton?: boolean;
  isFromTalent?: boolean;
}

const SelectSurveyUser: FC<Props> = forwardRef(({
  className,
  mode,
  skeleton,
  disableSkeleton = false,
  isFromTalent,
  ...props
}, ref: any) => {
  const {
    data,
    isLoading,
    isFetching,
  } = useGetUsersQuery({
    page: 1,
    limit: 9999,
    talent: isFromTalent
  });

  const { account } = useAccount();

  const { data: users } = data ?? { data: [] };
  const loading = isLoading || isFetching;

  const orderedUsers = unionBy([account], users, 'id');

  return (
    <Select
      mode={mode}
      showSearch
      skeleton={!disableSkeleton && (loading || skeleton)}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props}
      className={classnames(css.root, className)}
      ref={ref}
    >
      {(orderedUsers as (Partial<User> & { id: number })[])
        .filter((user) => user?.id !== undefined)
        .map((user) => (
          <Select.Option key={user?.id} value={user?.id}>{user?.name}</Select.Option>
        ))}
    </Select>
  );
});

export default SelectSurveyUser;
