import React from 'react';
import { Box, Grid, Typography } from '@sprnova/nebula';
import { EmptyImage } from 'components/nebula/EmptyImage/EmptyImage';
import pluralize from 'pluralize';
import { removeExtension } from '../../utils/removeExtension';

type Props = {
  url?: string;
  name: string;
  label?: string;
  index: number;
};

export const ComparisonModal = ({ url, name, label, index, ...props }: Props): JSX.Element => {
  return (
    <Grid item xs={3} {...props}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {
          url
            ? <Box
              sx={{
                background: `url("${url}"),url("${removeExtension(url)}.jpg")`,
                width: '48px',
                height: '48px',
                minWidth: '48px',
                minHeight: '48px',
                borderRadius: '4px',
                marginRight: '10px',
                backgroundColor: '#FFFFFF',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'
              }}
            />
            : <EmptyImage size='xs' boxProps={{ sx: { marginRight: '10px', borderRadius: '4px' }}} />
        }
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Typography variant="h6" gutterBottom component="div">
            {label ? pluralize(label, 1) : ''} {index + 1}
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '80%'
            }}
          >
            {name}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};
