/**
 * SectionBuilder -> Fields -> UserScoreField
 */

import React, { FC, useMemo, useState } from 'react';
import { SliderProps } from '@sprnova/nebula';
import { Col, Row } from 'antd';
import { QuestionScore } from 'features/audits/ViewSurveyPage/components';
import { FormItem, Slider } from 'components';
import { FieldProps } from '../../types';
import css from './UserScoreField.module.scss';

const UserScoreField: FC<FieldProps> = ({ name, form, onValuesChange }) => {
  const [localValue, setLocalValue] = useState(form.getFieldValue('user_score'));

  const marks = useMemo(() => {
    return Array.from({ length: 11 }, (_, index) => ({ value: index }));
  }, []);

  const handleOnChange: SliderProps['onChange'] = (_: Event, value: number | number[]) => {
    if (Array.isArray(value)) {
      throw new Error('question expects a single value');
    }

    setLocalValue(value);

    if (!onValuesChange) return;

    form.setFieldsValue({user_score: value});

    return onValuesChange({ user_score: value });
  };

  return (
    <FormItem
      name={name}
      className={css.root}
      extra={
        <>
          Does this opportunity fit with the type of company that we want to
          work with and can succeed?
          <ul>
            <li>
              Do they have a strong <em>market fit</em>?
            </li>
            <li>
              Do they have a <em>winning product</em>?
            </li>
            <li>
              Are they willing to take <em>feedback and direction?</em>
            </li>
            <li>
              Do they have <em>values alignment</em> with PDM?
            </li>
          </ul>
        </>
      }
    >
      <Row justify="space-between" align="middle" className={css.userScoreQuestion}>
        <Col span={18}>
          <div className={css.center}>
            <Slider
              id={`user-score-field-${name}`}
              min={0}
              max={10}
              marks={marks}
              onChange={handleOnChange}
              defaultValue={localValue}
              variant='discrete'
            />
          </div>
        </Col>
        <Col span={4}>
          <div className={css.right}>
            <QuestionScore score={localValue} max={10} />
          </div>
        </Col>
      </Row>
    </FormItem>
  );};

export default UserScoreField;
