import { notification } from 'components';
/**
 * Display Error Notification
 */
export const handleError = (
  name: string,
  error?: string,
): void => {
  if(error) {
    notification.error({
      message: `Failed to generate ${name}. Please try again. `,
    });
    console.error(error);
  }
};
