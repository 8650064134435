/**
 * Library -> Rules
 */

import React, {
  FC,
  useCallback,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Container, PlusIcon } from '@sprnova/nebula';
import { Action } from 'api/accessControl';
import { AppDispatch } from 'app/store';
import omitBy from 'lodash/omitBy';
import { NumberParam, StringParam, useQueryParam, useQueryParams, withDefault } from 'use-query-params';
import {
  FetchRulesFilter,
  Resource,
  Rule,
  deleteRule,
  useRules,
} from 'features/entitiesRedux';
import LibraryPageHero from 'features/library/components/LibraryPageHero/LibraryPageHero';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import {
  AccessControl,
  NoResultsFound,
  notification,
} from 'components';
import {
  Filters,
  List
} from './components';
import { initialValues as initialFilterValues } from './components/Filters';

const Rules: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const { rules: allRules, loading } = useRules();
  const [pricingVersionQueryParam] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );

  const [/* queryParams */, setQueryParams] = useQueryParams({
    department_id: NumberParam,
    service_id: NumberParam,
    name: StringParam,
  });

  const handleSetFilter = useCallback((filterValues) => {
    setFilter(filterValues);
  }, []);

  const handleDelete = async (rule: Pick<Rule, 'id'>): Promise<void> => {
    try {
      const updateAction = await dispatch(deleteRule(rule.id));

      if (deleteRule.fulfilled.match(updateAction)) {
        notification.success({
          message: 'Rule deleted',
        });
      }
    } catch (error) {
      console.error('Error deleting rule', error);
    }
  };

  const hasFilters = !!Object.keys(omitBy(filter, (val) => !val)).length;
  const ruleFilterFunc = useCallback(
    (rule: Rule) => {
      const result = [];
      const { name } = rule;
      const {
        name: nameFilter,
        // service_id: serviceFacetId,
        // department_id: departmentFacetId,
      }: FetchRulesFilter = filter;

      // Filter by rule name
      if (nameFilter && name) {
        result.push(name.toLowerCase().includes(nameFilter.toLowerCase()));
      }

      // Filter by service
      // if (serviceFacetId && service) {
      //   result.push(service.id === serviceFacetId);
      // }

      // Filter by department
      // const department = service?.department;
      // if (departmentFacetId && department) {
      //   result.push(department.id === departmentFacetId);
      // }

      // Filter by criteria
      // think about 3 entity types - question, questiongroup or department from the analyst survey

      // console.log(service, result, !result.filter(x => x === false).length)

      // Only return rules if all filters match
      return !result.filter((x) => x === false).length;
    },
    [filter]
  );

  const filteredRules = useMemo(
    () => (hasFilters ? allRules.filter(ruleFilterFunc) : allRules),
    [hasFilters, allRules, ruleFilterFunc]
  );

  return (
    <>
      <LibraryPageHero
        title="Rules"
        end={[
          <AccessControl
            key="create-rule"
            action={[Action.create]}
            resource={Resource.rule}
            showWarning={false}
          >
            <Button
              variant="primary"
              size="large"
              to={`/library/rules/new?pricingVersion=${pricingVersionQueryParam}`}
              component={Link}
              startIcon={<PlusIcon />}
            >
              New Rule
            </Button>
          </AccessControl>,
        ]}
      />
      <Container hasVerticalPadding>
        <AccessControl action={[Action.read]} resource={Resource.rule}>
          {!allRules.length && !hasFilters && !loading ? (
            <NoResultsFound title="No rules created yet" />
          ) : (
            <>
              <Filters
                filter={filter}
                hasFilters={hasFilters}
                setFilter={handleSetFilter}
              />
              {hasFilters && !filteredRules.length ? (
                <NoResultsFound
                  title="No rules matched the applied filters"
                  buttonProps={{
                    children: 'Reset filters',
                    type: 'primary',
                    onClick: (): void => {
                      handleSetFilter(initialFilterValues);
                      setQueryParams({ name: undefined, service_id: undefined, department_id: undefined });
                    },
                  }}
                />
              ) : (
                <List
                  onDelete={handleDelete}
                  loading={loading && !allRules.length}
                  rules={filteredRules}
                />
              )}
            </>
          )}
        </AccessControl>
      </Container>
    </>
  );
};

export default Rules;
