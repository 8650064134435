import React from 'react';
import { Link } from 'react-router-dom';
import { SkeletonInput } from '../../../../../../../../components';
import { User } from '../../../../../../../entitiesRedux';

interface Props {
  user?: Partial<User>;
  isLoading?: boolean;
}

const SalesRepCommission = ({ user, isLoading }: Props): JSX.Element => {
  const renderContent = () => {
    if (!user?.commission_override) {
      return (
        <>
          Commission % not set (<Link to={`/users/${user?.id}/edit`}>edit user</Link>)
        </>
      );
    } else {
      return (
        <>
          {user?.commission_override}% (<Link to={`/users/${user?.id}/edit`}>edit user</Link>)
        </>
      );
    }
  };

  if (isLoading) return <SkeletonInput />;

  return (
    <span style={{paddingLeft: 8}}>
      {renderContent()}
    </span>
  );
};

export default SalesRepCommission;
