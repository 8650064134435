import React from 'react';
import { Col, Row } from 'antd';
import { FormItem, Input } from '../../../../../../components';

interface ClientContactFieldsProps {
  isLoading?: boolean;
  isDealWon?: boolean;
}

const ClientContactFields = ({ isLoading, isDealWon }: ClientContactFieldsProps): JSX.Element => {
  return (
    <Row justify="space-between" gutter={30}>
      <Col xs={24} lg={24}>
        <FormItem
          label="Name"
          name="billing_name"
          rules={[{ required: isDealWon, message: 'Please enter a name for this billing contact' }]}
        >
          <Input skeleton={isLoading} />
        </FormItem>

        <FormItem
          label="Address Line 1"
          name="billing_address"
          rules={[{ required: isDealWon, message: 'Please enter a street address for this billing contact' }]}
        >
          <Input skeleton={isLoading} />
        </FormItem>

        <FormItem
          label="City"
          name="billing_city"
          rules={[{ required: isDealWon, message: 'Please enter a city for this billing contact' }]}
        >
          <Input skeleton={isLoading} />
        </FormItem>
        <Row justify='space-between' gutter={30}>
          <Col xs={24} lg={16}>
            <FormItem
              label="State"
              name="billing_state"
              rules={[{ required: isDealWon, message: 'Please enter a state for this billing contact' }]}
            >
              <Input skeleton={isLoading} />
            </FormItem>
          </Col>
          <Col xs={24} lg={8}>
            <FormItem
              label="Zip"
              name="billing_zip"
              rules={[{ required: isDealWon, message: 'Please enter a zip code for this billing contact' }]}
            >
              <Input skeleton={isLoading} />
            </FormItem>
          </Col>
        </Row>


        <FormItem
          label="Country"
          name="billing_country"
          rules={[{ required: isDealWon, message: 'Please enter a country for this billing contact' }]}
        >
          <Input skeleton={isLoading} />
        </FormItem>

        <FormItem
          label="Phone"
          name="billing_phone"
          rules={[{ required: isDealWon, message: 'Please enter a phone number for this billing contact' }]}
        >
          <Input skeleton={isLoading} />
        </FormItem>
        <FormItem
          label="Email"
          name="billing_email"
          rules={[{ required: isDealWon, message: 'Please enter an email address for this billing contact' }]}
        >
          <Input skeleton={isLoading} />
        </FormItem>

      </Col>

    </Row>
  );
};

export default ClientContactFields;
