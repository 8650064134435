/**
* Get Horizon
*/

import { Projection } from 'api/entityGraphQL/types';
import { Horizon } from 'features/entitiesRedux/models/horizon';
import { baseApi, cleanArguments } from '..';
import tagTypes from './tagTypes';

export type GetHorizonQuery = {
  projection: Projection;
  id: number
};

export type GetHorizonResult = Horizon;

export const GET_HORIZON_ENDPOINT_KEY = 'getHorizon';

export const { useGetHorizonQuery, useLazyGetHorizonQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_HORIZON_ENDPOINT_KEY]: build.query<GetHorizonResult, GetHorizonQuery>({
      transformResponse: (response: { horizon: GetHorizonResult }) => response.horizon,
      providesTags: (horizon) => {
        return [horizon?.id ? { id: horizon.id, type: tagTypes.HORIZON } : tagTypes.HORIZON ];
      },
      query: ({
        projection = { id: true },
        id,
        ...args
      }) => {
        const __args: Partial<GetHorizonQuery> = {
          id,
          ...args,
        };

        return {
          body: {
            query: {
              horizon: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        };
      }
    }),
  }),
});
