import React from 'react';
import { Grid, Box, Typography, Tooltip } from '@sprnova/nebula';
import { EmptyImage } from 'components/nebula/EmptyImage/EmptyImage';
import { singular } from 'pluralize';
import { PlaybookAd, PlaybookAdset, PlaybookCampaign } from 'features/entitiesRedux/models/creative_playbook';
import { columnSpacing } from '../../utils/columnSpacing';
import { formatImageUrl } from '../../utils/formatImageUrl';
import { removeExtension } from '../../utils/removeExtension';

type Props = {
  data?: Partial<PlaybookCampaign | PlaybookAdset | PlaybookAd>[];
  handleRemove?: (index: number) => void;
  activeTab?: string;
};

export const CampaignHeader = ({data = [], handleRemove, activeTab}: Props): JSX.Element  => {
  return (
    <Grid
      container
      columnSpacing={6}
      sx={{
        maxWidth: '100%',
        margin: 0
      }}
    >
      {
        data.length
          ? data.map((item: any, index: number) => {
            return (
              <Grid
                item
                xs={12 / data.length}
                sx={{
                  textAlign: 'center',
                  padding: `0 ${columnSpacing}`,
                }}
                key={`campaignHeader_${index}`}
              >
                <Box>
                  {formatImageUrl(item) ?
                    <Box
                      sx={{
                        backgroundImage: `url("${formatImageUrl(item)}"), url("${removeExtension(formatImageUrl(item) || '')}.jpg")`,
                        width: '72px',
                        height: '72px',
                        margin: 'auto',
                        borderRadius: '4px',
                        backgroundSize: 'cover',
                      }}
                    />
                    :
                    <div style={{display: 'flex', justifyContent: 'center', height: '72px'}}>
                      <EmptyImage size='small'/>
                    </div>
                  }
                  <Typography variant='overline' display='block' sx={{ fontWeight: 600 }}>
                    {singular(activeTab as string)} {index + 1}
                  </Typography>
                  <Tooltip
                    content={<>{item.name}</>}
                    variant='plain'
                    placement='top'
                    sx={{
                      background: '#fff',
                    }}
                  >
                    <Typography
                      variant='h5'
                      gutterBottom
                      component='div'
                      sx={{
                        fontWeight: 600,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        cursor: 'default'
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Tooltip>
                </Box>
                {/* TODO: add remove column functionality */}
                {/* <Grid item>
                  <Button variant="outlined" color="error" onClick={() => handleRemove(index)}>
                    Remove
                  </Button>
                </Grid> */}
              </Grid>
            );
          })
          : <></>
      }
    </Grid>
  );
};
