import React from 'react';
import {Divider as AntdDivider} from 'antd';
import { DividerProps } from 'antd/lib/divider';
import classNames from 'classnames';
import css from './Divider.module.scss';

interface Props extends DividerProps {
  className?: string;
  style?: React.CSSProperties;
  label?: React.ReactNode,
  height?: number;
}

const Divider = ({ className, style, label, height, ...props }: Props): JSX.Element => {
  return (
    <>
      {label && <div className={css.label}>{label}</div>}
      <AntdDivider
        {...props}
        className={classNames(css.root, {[css.hasLabel]: !!label }, className)}
        style={{backgroundColor: '#ebf2f6', height: height ? height : 2, ...style}}
      />
    </>
  );
};

export default Divider;
