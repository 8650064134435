import React, { memo } from 'react';
import { Control } from 'react-hook-form';
import { Box } from '@mui/material';
import { useGetClientsQuery } from 'api/crudGraphQL/clients/getClients';
import VirtualizedAutocomplete from 'components/VirtualizedAutocomplete/VirtualizedAutocomplete';
import { Client } from 'features/entitiesRedux';
import { CreateScoreboardFormType } from '../CreateScoreboardForm';

interface CreateScoreboardSelectClientProps {
  control: Control<CreateScoreboardFormType, any>;
  setSelectedClient: React.Dispatch<React.SetStateAction<Partial<Client> | undefined>>;
  isEdit?: boolean;
}

/**
 * Component for selecting clients for a new scoreboard.
 * Utilizes a virtualized autocomplete dropdown to handle large datasets efficiently,
 * fetching 'Active' and 'Prospect' clients from the server.
 *
 * @param {CreateScoreboardSelectClientProps} props - The component props
 * @returns {JSX.Element} The rendered component
 */
const CreateScoreboardSelectClient: React.FC<CreateScoreboardSelectClientProps> = ({ control, setSelectedClient, isEdit }) => {
  // Query list of 'Active' and 'Prospect' Clients
  const { data: getClientsData, isLoading } = useGetClientsQuery({
    account_status_ids: [
      1001, // Prospect
      1003  // Active
    ],
    limit: -1,
    projection: {
      id: true,
      name: true,
    }
  });

  type Client = {
    id: number;
    name: string;
  };

  const handleClientSelect = (client: Client): void => {
    setSelectedClient(client);
  };

  return (
    <Box sx={{ marginBottom: '1.5rem' }}>
      <VirtualizedAutocomplete
        id="create_scoreboard_select_client"
        name="client"
        label="Select Client"
        control={control}
        data={getClientsData?.data ?? []}
        loading={isLoading}
        setSelected={handleClientSelect}
        getOptionLabel={(option) => (option as Client).name}
        getOptionValue={(option) => option as Client}
        disabled={isEdit}
      />
    </Box>
  );
};

export default memo(CreateScoreboardSelectClient);
