import { BLUEPRINT_STAGES_ENUM } from 'features/strategies/constants';

/**
 * Get the BP stage definition
 * @param blueprintStage The blueprint stage
 * @returns string The blueprint stage definition
*/
export const getBlueprintStageDefinition = (blueprintStage: string): string => {
  switch (blueprintStage) {
    case BLUEPRINT_STAGES_ENUM.Draft:
    case BLUEPRINT_STAGES_ENUM.Open:
      return 'Open Blueprint in progress, not yet proposed to the client.';
    case BLUEPRINT_STAGES_ENUM.Appraisal:
      return 'Testing Blueprint used alongside the Appraisal.';
    case BLUEPRINT_STAGES_ENUM.Blueprint:
      return 'The Blueprint has been proposed to the client.';
    case BLUEPRINT_STAGES_ENUM.Legal:
      return 'The Blueprint is in the hands of the client\'s legal team.';
    case BLUEPRINT_STAGES_ENUM.Nurture:
      return 'The Blueprint was proposed to the client, but has not yet been accepted. We are continuing to nurture the deal.';
    case BLUEPRINT_STAGES_ENUM.Won:
      return 'Deal is accepted and signed by client.';
    case BLUEPRINT_STAGES_ENUM.Lost:
      return 'Deal is rejected and not signed by the client, therefore archived.';
    case BLUEPRINT_STAGES_ENUM.Archive:
      return 'The Blueprint is archived because of inactivity.';
    default:
      return 'N/A';
  }
};
