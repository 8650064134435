import React from 'react';

export const ServiceExpansionIcon = () => (
  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="32.916" y="86" width="16" height="65" rx="8.00001" transform="rotate(-90 32.916 86)" fill="#D9D9D9"/>
    <rect x="32.916" y="64" width="16" height="65" rx="8.00001" transform="rotate(-90 32.916 64)" fill="#D9D9D9"/>
    <rect x="25" y="41" width="16" height="73" rx="8" transform="rotate(-90 25 41)" fill="#4A1FFF"/>
    <path d="M14 43.5898V85.9298H56.34C56.34 85.9298 56.34 85.9298 56.34 85.9298C56.34 62.5461 37.3837 43.5898 14 43.5898C14.0001 43.5898 14 43.5898 14 43.5898Z" fill="#A94882"/>
    <ellipse cx="18.7406" cy="18.7406" rx="18.7406" ry="18.7406" transform="matrix(-1 0 0 1 51.4805 25)" fill="#FFA600"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14 43.6021V43.8819C14.0752 54.1677 22.4366 62.4826 32.7401 62.4826C37.6287 62.4826 42.08 60.6109 45.4167 57.5449C37.6687 48.9748 26.4633 43.5898 14.0002 43.5898H14.0001C14.0001 43.5939 14 43.598 14 43.6021Z" fill="#E65957"/>
    <circle cx="90" cy="33" r="8" transform="rotate(90 90 33)" fill="#958AE2"/>
    <circle cx="90" cy="56" r="8" transform="rotate(90 90 56)" fill="#E65957"/>
    <circle cx="90" cy="78" r="8" transform="rotate(90 90 78)" fill="#FFA600"/>
  </svg>

);
