/**
 * Entities -> Services -> Actions
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { novaGraphQLClient, Projection } from 'api/entityGraphQL';
import { normalizeEntities } from 'features/entitiesRedux/utils';
import { FetchServicesFilter, Service, ServiceFormValues } from './service';

/** Fetch Many */
export const fetchServices = createAsyncThunk(
  'entities/fetchServices',
  async (config: {
    projection?: Projection,
    filter?: FetchServicesFilter;
  }): Promise<any> => {
    const { services } = await novaGraphQLClient.fetchServices(config);
    return { ...normalizeEntities({ services }) };
  }
);

/** Fetch one */
export const fetchServiceById = createAsyncThunk(
  'entities/fetchServiceById',
  async ({ id, ...config }: { id: number; projection?: Projection }) => {
    const { services } = await novaGraphQLClient.fetchServiceById(id, {
      ...config,
    });
    return { ...normalizeEntities({ services }) };
  }
);

/** Create */
export const createService = createAsyncThunk(
  'entities/createService',
  async (values: Partial<ServiceFormValues>) => {
    const response = await novaGraphQLClient.createService(values);
    return { ...normalizeEntities(response) };
  }
);

/* Update */
export const updateService = createAsyncThunk(
  'entities/updateService',
  async (values: Partial<Service>) => {
    const response = await novaGraphQLClient.updateService(values);
    return { ...normalizeEntities(response) };
  }
);

/** Delete */
export const deleteService = createAsyncThunk(
  'entities/deleteService',
  async (id: number) => {
    try {
      await novaGraphQLClient.deleteService(id);
      return id;
    } catch (error) {
      console.error('Error deleting service', error);
      return { ...normalizeEntities({}) };
    }
  }
);
