import { PricingTier } from 'api/crudGraphQL/strategies/types';
import { PricingTypeEnum } from './constants';
import { FormDataType } from './packageStrategyTypes';

export const spendMinTierHelperText = 'Spend ranges should not overlap with other tiers';
export const spendMaxTierHelperText = 'Spend maximum should be above the minimum';
export const spendTierMinimumErrorHelperText = 'Minimum value is more than the maximum value';
export const spendTierMaximumErrorHelperText = 'Maximum value is less than the minimum value';
export const duplicateTierNameErrorHelperText = 'Tier name must be unique';

/**
 * Verify if spend ranges are overlapping
 *
 * @param packageStrategyPricingTiers
 * @returns boolean - true if there are overlapping spend ranges, false otherwise
 */
export const spendRangesAreOverlapping = (packageStrategyPricingTiers: Array<PricingTier>): boolean => {

  for(let pricingTierIndex = 0; pricingTierIndex < packageStrategyPricingTiers.length; pricingTierIndex++) {
    const tier = packageStrategyPricingTiers[pricingTierIndex];

    const restOfTiers: Array<PricingTier> = packageStrategyPricingTiers.filter((tierToCompare: PricingTier, tierToCompareIndex: number) => {
      return tierToCompareIndex != pricingTierIndex;
    });
    for(let tierToCompareIndex = 0; tierToCompareIndex < restOfTiers.length; tierToCompareIndex++) {
      const tierToCompare = restOfTiers[tierToCompareIndex];
      if (tier.spend_min >= tierToCompare.spend_min && tier.spend_min <= tierToCompare.spend_max
          || tier.spend_max >= tierToCompare.spend_min && tier.spend_max <= tierToCompare.spend_max) {
        return true;
      }
    }
  }
  return false;

};

/**
 * Verify if spend ranges input are wrong (e.g Spend Min > Spend Max)
 *
 * @param packageStrategyPricingTiers
 * @returns boolean - true if spend ranges inputs are wrong, false otherwise.
 */
export const spendRangesInputAreWrong = (packageStrategyPricingTiers: Array<PricingTier>): boolean => {
  for(let pricingTierIndex = 0; pricingTierIndex < packageStrategyPricingTiers.length; pricingTierIndex++) {
    const tier = packageStrategyPricingTiers[pricingTierIndex];
    if (tier.spend_min > tier.spend_max) {
      return true;
    }
  }
  return false;
};

/**
 * Validate pricing tier spend ranges.
 * - Input wrong (e.g Spend Min > Spend Max)
 * - Overlapping spend ranges with other tiers
 *
 * @param pricingTiers The pricing tiers whose spend ranges are to be validated.
 * @returns An error message if there is a validation error; `null` otherwise.
 */
const spendRangesValidation = (pricingTiers: PricingTier[]): string | null => {
  const overlappingSpendRangesError = 'Error creating package strategy, please make sure the spend ranges do not overlap';
  const spendRangesWrongInputError = 'Error creating package strategy, please make sure the spend range minimum is less than the spend range maximum';
  if (spendRangesInputAreWrong(getPricingTiersWithFloatValues(pricingTiers)))
  {
    return spendRangesWrongInputError;
  }
  if(pricingTiers.length > 1 && spendRangesAreOverlapping(getPricingTiersWithFloatValues(pricingTiers)))
  {
    return overlappingSpendRangesError;
  }
  return null;
};

/**
 * Performs validation on the names of the pricing tiers.
 *
 * @param pricingTiers The pricing tiers whose names are to be validated.
 * @returns An error message if there is a validation error; `null` otherwise.
 */
const tierNamesValidation = (pricingTiers: PricingTier[]): string | null => {
  const formattedTierNames = pricingTiers.map(({ name }) => name.trim().toLowerCase());
  const hasDuplicateTierNames = formattedTierNames.length !== new Set(formattedTierNames).size;

  let validationError: string | null = null;

  if (hasDuplicateTierNames) {
    validationError = 'Error creating package strategy, please make sure each pricing tier has a unique name';
  }

  return validationError;
};

/**
 * Validates pricing tiers of a spend package strategy.
 *
 * @param pricingTiers The pricing tiers that are to be validated.
 * @returns An error message if there is a validation error; `null` otherwise.
 */
const spendValidation = (pricingTiers: PricingTier[]): string | null => {
  const spendRangesValidationResult = spendRangesValidation(pricingTiers);
  const tierNamesValidationResult = tierNamesValidation(pricingTiers);

  return spendRangesValidationResult || tierNamesValidationResult;
};

/**
 * Validates pricing tiers of a quantity package strategy.
 *
 * @param pricingTiers The pricing tiers that are to be validated.
 * @returns An error message if there is a validation error; `null` otherwise.
 */
const quantityValidation = (pricingTiers: PricingTier[]): string | null => {
  const tierNamesValidationResult = tierNamesValidation(pricingTiers);

  return tierNamesValidationResult;
};

/**
   * Validate all values of the form.
   *
   * Final form data example:
   *  {
   *    name: 'Strategy 1',
   *    children: [{ id: 1 }, { id: 2 }]
   *    service_id: 1,
   *    strategy_frequency_id: 1,
   *    pricing_type_id: 1,
   *    pricing_tiers: [
   *      {
   *        price: 1,
   *        deliverable: 'Deliverable 1',
   *        snippet: 'Snippet 1',
   *      },
   *      {
   *        price: 2,
   *        deliverable: 'Deliverable 2',
   *        snippet: 'Snippet 2',
   *      },
   *    ],
   * @param {Partial<FormDataType>} packageStrategiesFormData - The form data to validate.
   * @returns {string | null} errorMessage - The error message to display. If null there are no errors
   */
export const validateFormData = (packageStrategiesFormData: Partial<FormDataType>): string | null => {
  const requiredFieldsError = 'Error creating package strategy, please fill out all the required fields';

  const hasPricingTiers = packageStrategiesFormData.pricing_tiers && packageStrategiesFormData.pricing_tiers?.length > 0;

  if (!hasPricingTiers) {
    return requiredFieldsError;
  } else {
    // Snippet summary exceeds max characters
    const snippetSummaryExceedsMaxCharacters = packageStrategiesFormData.snippet_summary && packageStrategiesFormData.snippet_summary.length > 500;

    // If at least a value (name or service_id or strategy_frequency_id or pricing_type_id) is null, return true.
    const isMissingValue = Object.values(packageStrategiesFormData).some(packageStrategyFormDataValue => {
      if (!packageStrategyFormDataValue) {
        return true;
      }
      // if statement to check if the key/value is an array
      if (Array.isArray(packageStrategyFormDataValue)) {
        const pricingTiersValidation = packageStrategyFormDataValue.map((pricingTierItem: any) => {
          // If any value in pricing_tiers is null, return true.
          return Object.values(pricingTierItem).some((pricingTierItemValue: any) => {
            if (!pricingTierItemValue) {
              return true;
            }
            return false;
          });
        });
        return pricingTiersValidation.includes(true) ? true : false;
      }
      return false;
    });

    if(isMissingValue) {
      return requiredFieldsError;
    }

    if (packageStrategiesFormData.pricing_type_slug === PricingTypeEnum.Spend) {
      return spendValidation(packageStrategiesFormData?.pricing_tiers ?? []);
    } else if (packageStrategiesFormData.pricing_type_slug === PricingTypeEnum.Quantity) {
      return quantityValidation(packageStrategiesFormData?.pricing_tiers ?? []);
    }

    if(snippetSummaryExceedsMaxCharacters) {
      return 'Error creating package strategy, please make sure the snippet summary does not exceed 500 characters';
    }

    return null;
  }
};

/**
 * Return pricing tiers object with floating point values
 * so backend can process them, even if user entered them
 * as integers for number inputs
 *
 * @param pricingTiers
 * @returns PricingTier[] | undefined
 */
export const getPricingTiersWithFloatValues = (pricingTiers: PricingTier[]): PricingTier[] => {
  return pricingTiers?.map((pricingTier: PricingTier) => {
    return {
      ...pricingTier,
      price: parseFloat(pricingTier?.price ? pricingTier?.price?.toString() : '0'),
      quantity_price: parseFloat(pricingTier?.quantity_price ? pricingTier?.quantity_price.toString() :  '0'),
      spend_min: parseFloat(pricingTier.spend_min ? pricingTier?.spend_min?.toString(): '0'),
      spend_max: parseFloat(pricingTier?.spend_max ? pricingTier?.spend_max?.toString(): '0'),
    };
  });

};
