import React, { memo } from 'react';
import { Card, Typography, Legend, theme } from '@sprnova/nebula';
import { TooltipProps } from 'recharts';
import { formatScoreboardsDate, formatValue } from '../../components/utils';

interface CustomTooltipProps extends TooltipProps {
  metricName?: string;
  format?: string;
}

const CustomTooltip = ({ active, payload, metricName, format }: CustomTooltipProps): JSX.Element => {
  if (!active || !payload) return <></>;
  const tooltipPayload = payload?.[0]?.payload ?? {};
  const date = tooltipPayload?.date;
  const actual = tooltipPayload?.actual;
  const goal = tooltipPayload?.goal;
  const pace = tooltipPayload?.pace;

  return (
    <Card
      contentPadding='16px'
      textAlign='left'
      sx={{
        padding: '0 0 16px 0',
        width: '500px'
      }}
    >
      <Typography variant='body1' sx={{ paddingBottom: '10px' }}>{formatScoreboardsDate(date, 'tooltip')}</Typography>
      <Legend.Group direction='vertical'>
        {
          actual ? (
            <Legend
              variant='line'
              label={<>Actual {metricName}:</>}
              color={theme.variables?.colors.data_visualization.sequential[90]}
              value={<b>{formatValue(Number(actual), format)}</b>}
            />
          )
            : <></>
        }
        {
          pace !== 0 && pace !== actual ? (
            <Legend
              variant='line'
              color={theme.variables?.colors.data_visualization.sequential[90]}
              label={<>Est. Pace {metricName}:</>}
              value={<b>{formatValue(Number(pace), format)}</b>}
              sx={{
                '& > div:first-of-type': {
                  opacity: 0.5,
                },
              }}
            />
          )
            : <></>
        }
        <Legend
          variant='line'
          color={theme.variables?.colors.data_visualization.sequential[50]}
          label={<>Goal for {metricName}:</>}
          value={<b>{formatValue(Number(goal), format)}</b>}
        />
      </Legend.Group>
    </Card>
  );
};

export default memo(CustomTooltip);
