/**
 * Entities -> Rules -> Actions
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { novaGraphQLClient, Projection } from 'api/entityGraphQL';
import { normalizeEntities } from 'features/entitiesRedux/utils';
import { FetchRulesFilter, RuleFormValues } from './rule';

/** Fetch Many */
export const fetchRules = createAsyncThunk(
  'entities/fetchRules',
  async (config: {
    projection?: Projection;
    filter?: FetchRulesFilter;
  }): Promise<any> => {
    const { rules } = await novaGraphQLClient.fetchRules(config);
    return { ...normalizeEntities({ rules }) };
  }
);

/** Fetch one */
export const fetchRuleById = createAsyncThunk(
  'entities/fetchRuleById',
  async ({ id, ...config }: { id: number; projection?: Projection }) => {
    const { rules } = await novaGraphQLClient.fetchRuleById(id, {
      ...config,
    });
    return { ...normalizeEntities({ rules }) };
  }
);

/** Create */
export const createRule = createAsyncThunk(
  'entities/createRule',
  async (values: Partial<RuleFormValues>) => {
    const response = await novaGraphQLClient.createRule(values);
    return { ...normalizeEntities(response) };
  }
);

/* Update */
export const updateRule = createAsyncThunk(
  'entities/updateRule',
  async (values: Partial<RuleFormValues>) => {
    const response = await novaGraphQLClient.updateRule(values);
    return { ...normalizeEntities(response) };
  }
);

/** Delete */
export const deleteRule = createAsyncThunk(
  'entities/deleteRule',
  async (id: number) => {
    try {
      await novaGraphQLClient.deleteRule(id);
      return id;
    } catch (error) {
      console.error('Error deleting rule', error);
      return { ...normalizeEntities({}) };
    }
  }
);
