import React, { memo } from 'react';
import { Grid, KeyValue } from '@sprnova/nebula';
import { Task } from 'features/entitiesRedux/models/task';

type ViewCustomPricingContractDetailsProps = {
  task?: Task;
}

const ViewCustomPricingContractDetails = ({ task }: ViewCustomPricingContractDetailsProps): JSX.Element => {
  return (
    <Grid
      container
      justifyContent={'space-around'}
      spacing={3}
    >
      <Grid item xs={12}>
        <KeyValue
          label='Pricing Type'
          value={task?.pricing_type?.name ?? 'N/A'}
        />
      </Grid>
      <Grid item xs={12}>
        <KeyValue
          label='Snippet Summary'
          value={task?.snippet_summary
            ? <div dangerouslySetInnerHTML={{ __html: task?.snippet_summary }} />
            : 'N/A'}
        />
      </Grid>
      <Grid item xs={12}>
        <KeyValue
          label='Proposal Snippet'
          value={task?.pricing_tiers?.[0]?.snippet
            ? <div dangerouslySetInnerHTML={{ __html: task?.pricing_tiers?.[0]?.snippet }} />
            : 'N/A'}
        />
      </Grid>
    </Grid>
  );
};

export default memo(ViewCustomPricingContractDetails);
