import { Projection } from 'api/entityGraphQL/types';
import { cleanArguments } from "api/crudGraphQL/utils";
import { basePublicApi } from "../basePublicApi";
import tagTypes from './tagTypes';
import { ExternalClient } from 'features/entitiesRedux';

export type GetClientPublicQuery = {
  id: number;
  projection: Projection;
};

export type GetClientPublicResult = ExternalClient;

export const GET_CLIENT_PUBLIC_ENDPOINT_KEY = 'getClientPublic';

/**
 * Query endpoint to fetch a client from the public API.
 */
export const { useGetClientPublicQuery, util } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_CLIENT_PUBLIC_ENDPOINT_KEY]: build.query<GetClientPublicResult, GetClientPublicQuery>({
      transformResponse: (response: { client: GetClientPublicResult }) => response.client,
      providesTags: (client) =>  {
        return client
          ? [{ id: client.id, type: tagTypes.CLIENT }]
          : [tagTypes.CLIENT];
      },
      query: ({
        projection = { id: true },
        ...args
      }) => {
        const __args: Partial<GetClientPublicQuery> = args;

        return {
          body: {
            query: {
              client: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        };
      }
    })
  })
});
