/**
 * Get nova features (nova_features)
 */

import { Projection } from 'api/entityGraphQL/types';
import { NovaFeature } from 'features/entitiesRedux/models/nova_features';
import { cleanArguments } from "api/crudGraphQL/utils";
import { basePublicApi } from "../basePublicApi";
import tagTypes from './tagTypes';

export type GetNovaFeaturesPublicQuery = {
  client_id: number;
  projection: Projection;
};

export type GetNovaFeaturesPublicResult = NovaFeature[];

export const GET_NOVA_FEATURES_PUBLIC_ENDPOINT_KEY = 'getNovaFeaturesPublic';

export const { useGetNovaFeaturesPublicQuery, util } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_NOVA_FEATURES_PUBLIC_ENDPOINT_KEY]: build.query<GetNovaFeaturesPublicResult, GetNovaFeaturesPublicQuery>({
      transformResponse: (response: { nova_features: GetNovaFeaturesPublicResult }) => response.nova_features,
      providesTags: (nova_features = []) => [
        tagTypes.NOVA_FEATURES,
        ...nova_features.map(feature => ({
          id: feature.id,
          type: tagTypes.NOVA_FEATURE
        }))
      ],
      query: ({
        projection = { id: true },
        ...args
      }) => {
        const __args: Partial<GetNovaFeaturesPublicQuery> = args;

        return {
          body: {
            query: {
              nova_features: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        };
      }
    })
  })
});
