import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { PageNotFound } from 'components/PageNotFound';
import { routes } from 'routes';

export const Routes: FC = () => (
  <Switch>
    {routes.map((route, i) => (
      <Route
        key={`route-${i}`}
        path={route.path}
        component={route.component}
        exact={route.exact || true}
      />
    ))}
    <Route component={PageNotFound} />
  </Switch>
);