/**
 * Blueprints -> Lost Reason Modal
 */

import React, { FC } from 'react';
import { Modal, Form as AntForm } from 'antd';
import moment from 'moment';
import { Strategy } from 'features/entitiesRedux';
import { Form } from 'components';
import { LostReasonFormFields } from './components';
import {LostModalFormValues } from './types';
import formatInitialValues from './utils/formatInitialValues';
import css from './LostOrWonModal.module.scss';

 type Props = {
   open: boolean;
   loading: boolean;
   isCancellable?: boolean;
   onCancel?: () => void;
   onUpdate: (values: LostModalFormValues) => void;
   initialValues?: Partial<LostModalFormValues>
   status?: Partial<Strategy['status']>;
 }

const LostOrWonModal: FC<Props> = ({ open, onCancel, onUpdate, loading, isCancellable = true, initialValues = {}, status = 'lost' }) => {
  const [form] = AntForm.useForm();

  const formattedInitialValues = formatInitialValues(initialValues);

  const onSubmit = () => {
    form.submit();
  };

  const handleFinish = (values: LostModalFormValues) => {
    onUpdate({
      ...values,
      status: status,
      follow_up_date: moment.isMoment(values.follow_up_date) ? values.follow_up_date.format('YYYY-MM-DD HH:mm:ss') : undefined
    });
  };

  const sharedButtonProps = {
    disabled: loading
  };

  return (
    <Modal
      width={600}
      visible={open}
      destroyOnClose
      maskClosable={false}
      closable={false}
      onCancel={onCancel}
      okText={loading ? 'Please wait...' : 'Save'}
      onOk={onSubmit}
      cancelButtonProps={{
        ...sharedButtonProps,
        className: !isCancellable ? css.hideCancelButton : undefined
      }}
      okButtonProps={sharedButtonProps}
    >
      <Form
        form={form}
        initialValues={formattedInitialValues}
        layout="vertical"
        onFinish={handleFinish}
        name="lostForm"
        autoComplete="off"
      >
        {
          status === 'lost' ?
            <>
              <LostReasonFormFields
                form={form}
                autoFocusFields
              />
              <div style={{ marginTop: '20px' }}>
                <div>Are you sure you want to mark this blueprint as Lost?</div>
                <div>You won&apos;t be able to edit it later.</div>
              </div>
            </> :
            <div>
              <div>Are you sure you want to mark this blueprint as Won?</div>
              <div>You won&apos;t be able to edit it later.</div>
            </div>
        }
      </Form>
    </Modal>
  );
};

export default LostOrWonModal;
