import React, { memo, useCallback, useMemo } from 'react';
import { Alert, Grid, IntegrationAcousticCampaignIcon, IntegrationAdobeCommerceIcon, IntegrationAmazonIcon, IntegrationBigCommerceIcon, IntegrationCriteoIcon, IntegrationDataQIcon, IntegrationFacebookIcon, IntegrationFunnelIoIcon, IntegrationGoogleAdsIcon, IntegrationGoogleAnalyticsGAIcon, IntegrationGoogleSearchConsoleIcon, IntegrationHubspotIcon, IntegrationInstagramIcon, IntegrationKlaviyoIcon, IntegrationLinkedinIcon, IntegrationMagentoIcon, IntegrationMailChampIcon, IntegrationMicrosoftBingAdsIcon, IntegrationPinterestIcon, IntegrationSalesforceIcon, IntegrationSemrushIcon, IntegrationShopifyIcon, IntegrationTikTok1Icon, IntegrationTwitterIcon, Tag, Typography } from '@sprnova/nebula';
import { capitalize } from 'lodash';
import { formatValue } from '../../components/utils';
import { IntegrationType } from '../types';
import { formatPlatformCategory } from '../utils';

const platformStatusFailed: { [key: string]: boolean } = {
  broken: true,
  failed: true,
  error: true,
  auth: true,
  paused: true,
  active: false,
  completed: false,
  synced: false,
  in_progress: false,
};

const integrationIcons: { [key: string]: React.ReactElement } = {
  'Funnel.io': <IntegrationFunnelIoIcon />,
  'Shopify': <IntegrationShopifyIcon />,
  'Microsoft Ads': <IntegrationMicrosoftBingAdsIcon />,
  'Google Ads': <IntegrationGoogleAdsIcon />,
  'Google Analytics': <IntegrationGoogleAnalyticsGAIcon />,
  'TikTok Ads': <IntegrationTikTok1Icon />,
  'Criteo': <IntegrationCriteoIcon />,
  'Salesforce Commerce Cloud': <IntegrationSalesforceIcon />,
  'Salesforce Organization': <IntegrationSalesforceIcon />,
  'Amazon Search Ads': <IntegrationAmazonIcon />,
  'Instagram': <IntegrationInstagramIcon />,
  'Amazon Ads DSP': <IntegrationAmazonIcon />,
  'Acoustic Campaign': <IntegrationAcousticCampaignIcon />,
  'BigCommerce': <IntegrationBigCommerceIcon />,
  'Pinterest Ads': <IntegrationPinterestIcon />,
  'DataQ': <IntegrationDataQIcon />,
  'Adobe Commerce (Magento)': <IntegrationMagentoIcon />,
  'SEMRush': <IntegrationSemrushIcon />,
  'Klaviyo': <IntegrationKlaviyoIcon />,
  'Google Search Console': <IntegrationGoogleSearchConsoleIcon />,
  'Facebook': <IntegrationFacebookIcon />,
  'Facebook Ads': <IntegrationFacebookIcon />,
  'LinkedIn': <IntegrationLinkedinIcon />,
  'Twitter': <IntegrationTwitterIcon />,
  'MailChimp': <IntegrationMailChampIcon />,
  'Adobe Commerce': <IntegrationAdobeCommerceIcon />,
  'HubSpot': <IntegrationHubspotIcon />,
};

type PlatformsProps = {
  data?: IntegrationType[];
  category?: string;
  statusOnly?: boolean;
  format?: string | null;
};

const Platforms: React.FC<PlatformsProps> = ({
  data = [],
  category = '[category]',
  statusOnly = false,
  format,
}) => {

  const updatedPlatformName = useMemo(() => {
    return data.map(item => {
      switch (item.data_source_name) {
        case 'Google Analytics 4':
          return { ...item, data_source_name: 'Google Analytics' };
        case 'Ad Networks (Combined) via Funnel.io':
          return { ...item, data_source_name: 'Funnel.io' };
        default:
          return item;
      }
    });
  }, [data]);

  const uniqueData = useMemo(() => {
    const uniqueMap = new Map<string, IntegrationType>();
    updatedPlatformName.forEach(item => {
      if (!uniqueMap.has(item.data_source_name)) {
        uniqueMap.set(item.data_source_name, item);
      }
    });
    return Array.from(uniqueMap.values());
  }, [updatedPlatformName]);

  const renderIcon = useCallback((dataSource: string) => {
    if (!dataSource) {
      return <></>;
    }
    return <div style={{paddingRight: '8px'}}>{integrationIcons[dataSource]}</div>;

  }, []);

  const renderValue = useCallback(({ value, last_refresh_status }) => {
    if (!platformStatusFailed[last_refresh_status] && !statusOnly) {
      return formatValue(value, format);
    }
    return <Tag height='24px' color={platformStatusFailed[last_refresh_status] ? 'red' : 'green'}>{last_refresh_status.toUpperCase()}</Tag>;
  }, [format, statusOnly]);

  const renderedPlatforms = useMemo(() => {
    if (!uniqueData || uniqueData.length === 0) {
      return (
        <Alert severity="warning" sx={{padding: '8px 16px', marginTop: '12px'}}>
        At least one {formatPlatformCategory(category)} platform is required to enable this KPI.
        </Alert>
      );
    }
    return uniqueData.map((d: any, index) => {
      const title = d.data_source_name === 'ga4' ? 'GA4' : capitalize(d.data_source_name) || 'Uncategorized';
      return (
        <Grid
          container
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          key={`Platforms-${index}`}
          sx={{
            width: '100%',
            paddingTop: '12px',
          }}
        >
          <Grid item>
            <Grid item display={'flex'} alignItems='center' justifyContent='center'>
              {renderIcon(d.data_source_name)}
              <Typography variant='caption' sx={{ padding: `${d.platform ? '0 8px' : 0 }` }}>
                {title}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant='caption' sx={{ paddingRight: '8px' }}>
              {renderValue(d)}
            </Typography>
          </Grid>
        </Grid>
      );
    });
  }, [category, renderIcon, renderValue, uniqueData]);
  return (
    <Grid
      item
      sx={{ width: '100%' }}
    >
      {renderedPlatforms}
    </Grid>
  );
};

export default memo(Platforms);
