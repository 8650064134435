/**
 * Get audits
 */

import { baseApi, cleanArguments, paginationProjection } from 'api/crudGraphQL';
import { Pagination, Projection } from 'api/entityGraphQL';
import { Audit } from 'features/entitiesRedux';

export type GetAuditsQuery = {
  id?: number;
  name?: string;
  client_id?: number;
  author_id?: number;
  sort?: string[];
  status?: string;
  status_in?: string[];
  survey_status_in?: string[];
  survey_departments?: string[];
  forecasts?: number[];
  projection?: Projection;
  limit?: number;
  page?: number;
};

export type GetAuditsResult = Pagination & {
  data: Audit[];
};

export const GET_AUDITS_ENDPOINT_KEY = 'getAudits';

export const { useGetAuditsQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_AUDITS_ENDPOINT_KEY]: build.query<GetAuditsResult, GetAuditsQuery>({
      transformResponse: (response: { audits: GetAuditsResult }) => response.audits,
      query: ({
        projection = { id: true, name: true },
        page = 1,
        limit = 10,
        name,
        ...args
      }) => {
        const __args: Partial<GetAuditsQuery> = {
          page,
          limit,
          ...args,
        };

        if (name) {
          __args.name = `*${name}*`;
        }

        return {
          body: {
            query: {
              audits: {
                __args: cleanArguments(__args),
                data: projection,
                ...paginationProjection
              }
            }
          }
        };
      }
    })
  })
});
