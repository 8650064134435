/**
 * SectionBuilder -> Fields -> AnnualLTV
 */

import React, { FC, useEffect } from 'react';
import { FormItem, Input } from 'components';
import { MagicOutlined } from '../../../../../../../../../components/Icon';
import { formatCurrencyInput } from '../../../../../../../utils';
import { FieldProps } from '../../../types';
import css from '../../outputs_shared.module.scss';

const AnnualLTV: FC<FieldProps> = ({ name, form }) => {
  // get form values for calculation
  const timeBetweenPurchases = form.getFieldValue('question_time_between_purchases');
  const averageOrderValue = form.getFieldValue('question_aov');

  const calculateOutput = () => {
    // check if both values are truthy
    if (!!timeBetweenPurchases && !!averageOrderValue) {
      // remove all non-numeric characters and cast as a number
      const aov = typeof averageOrderValue === 'number'
        ? averageOrderValue
        : parseInt(averageOrderValue?.replace(/[^0-9]+/g, ''));
      // calculate output
      const multiplier = 1 / (timeBetweenPurchases / 365);
      const output = '$ ' +  formatCurrencyInput((multiplier * aov).toFixed(0));
      return form.setFieldsValue({[name]: output});
    }
  };

  useEffect(() => {
    calculateOutput();
  }, [form, timeBetweenPurchases, averageOrderValue]);

  return (
    <FormItem
      name={name}
      className={css.root}
      extra={
        <div className={css.extra}>Annual LTV = (1 / (Time Between Purchases in Days / 365 )) * AOV</div>
      }
    >
      <Input 
        addonAfter={
          <span role="img" className="anticon">
            <MagicOutlined />
          </span>
        }
        readOnly
      />
    </FormItem>
  );
};

export default AnnualLTV;
