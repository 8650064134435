import { HorizonActual, HorizonBudget, HorizonPrediction, HorizonScenario } from 'features/entitiesRedux/models/horizon';

type HorizonData = HorizonScenario | HorizonActual[] | HorizonPrediction[];

interface FormatHorizonDataInput {
  title: string;
  moduleName?: string;
  data?: HorizonData;
  funnels: string[];
  channels?: string[];
  firstDate: string;
  length: number;
}

type GroupType = {
  date: string;
  value: number;
  channel: { id: number, name: string };
  funnel: { id: number, name: string };
}
/**
 * Formats horizon data for CSV export.
 *
 * @param {FormatHorizonDataInput} input - An object containing parameters required for formatting horizon data.
 * @param {string} title - The title for the CSV data.
 * @param {string} moduleName - Optional: The module name.
 * @param {HorizonData} data - Optional: The horizon data to be formatted.
 * @param {string[]} funnels - An array of funnel names.
 * @param {string[]} channels - Optional: An array of channel names. Defaults to an empty array.
 * @param {string} firstDate - The start date of the horizon data.
 * @param {number} length - The length of the horizon data.
 *
 * @returns {string[]} An array of strings representing the formatted CSV data.
 */
export const formatHorizonDataForCSVExport = ({
  title,
  moduleName,
  data,
  funnels,
  channels = [],
  firstDate,
  length,
}: FormatHorizonDataInput): string[] => {
  const outputRows: string[] = [];
  outputRows.push(`${title}`);
  const channelFunnelHasValue: { [key: string]: { [key: string]: { status: boolean, total: number } } } = {};

  /**
 * Iterates over the provided channel names array to construct a dynamic object.
 *
 * This forEach iterates over the `channels` array and constructs a dynamic object
 * `channelFunnelHasValue`, which maps each channel name to a funnel object. The funnel
 * object contains keys representing each funnel name and their corresponding status
 * (initialized to `false`) and total (initialized to `0`). The function initializes
 * the funnel object for each channel and sets the initial status of all funnels to `false`.
 *
 */
  channels.forEach(channel => {
  // Initialize the funnel object for the current channel
    const funnelObject: { [key: string]: { status: boolean, total: number } } = {};

    // Iterate over funnels for the current channel and set their initial status to false
    funnels.forEach(funnel => {
      funnelObject[funnel] = { status: false, total: 0 };
    });

    // Set the funnel object for the current channel in the dynamic object
    channelFunnelHasValue[channel] = funnelObject;
  });

  const formatByMonth: Map<string, any[]> = new Map<string, any[]>();
  const startDate: Date = new Date(firstDate);

  // Create a Map that takes each month starting the startDate as keys and default value to an empty {}
  for (let i = 1; i <= length; i++) {
    const currentDate = new Date(startDate.getFullYear(), startDate.getMonth() + i, 1);
    const formattedDate = currentDate.toISOString().slice(0, 7);
    formatByMonth.set(formattedDate, []);
  }

  /**
 * Various helper functions for grouping data in the groupDataByDate function.
 *
 * @param item - The item containing month_date and budgets information (for processDataForSameBudgetSameChannels and updateDataFromSameBudgetSameChannels).
 * @param dataToGroup - The array to which the processed data is pushed (for processDataForSameBudgetSameChannels).
 * @param updatedGroups - The array to which the updated data is added (for updateDataFromSameBudgetSameChannels and updateNewDataFromBudgets).
 * @param horizonScenario - The HorizonScenario object containing budgets information (for updateNewDataFromBudgets).
 * @param formatByMonth - The map to be updated with formatted data (for formatDataByMonth).
 */
  function processDataForSameBudgetSameChannels(item: any, dataToGroup: GroupType[]): void {
    const { month_date, budgets } = item;
    budgets?.forEach((budget: any) => {
      const { value, channel, funnel } = budget;
      dataToGroup.push({
        date: month_date,
        value,
        channel,
        funnel
      });
    });
  }

  function updateDataFromSameBudgetSameChannels(item: any, updatedGroups: GroupType[]): void {
    const { month_date, budgets } = item;
    budgets?.forEach((budget: HorizonBudget) => {
      const { value, channel, funnel } = budget;
      updatedGroups.push({
        date: month_date,
        value,
        channel,
        funnel
      });
    });
  }

  function updateNewDataFromBudgets(horizonScenario: HorizonScenario, updatedGroups: GroupType[]): void {
    horizonScenario.budgets?.forEach((budget: HorizonBudget) => {
      updatedGroups.forEach((group: GroupType) => {
        if (group.date === budget.date && group.channel.id === budget.channel.id && group.funnel.id === budget.funnel.id) {
          group.value = budget.value;
        }
      });
    });
  }

  function formatDataByMonth(entry: GroupType, formatByMonth: Map<string, any[]>): void {
    const { date, channel, funnel, value } = entry;
    const formattedDate = new Date(date).toISOString().slice(0, 7);

    // Check if the formatByMonth object already has an entry for the current date
    if (formatByMonth.has(formattedDate)) {
    // If an entry already exists, push the formatted object into its array
      const entryArray = formatByMonth.get(formattedDate);
      if (entryArray) {
        entryArray.push({
          channel: { id: channel.id, name: channel.name },
          funnel: { id: funnel.id, name: funnel.name },
          value: value
        });
      }
    }
  }

  /**
 * Groups data by date and prepares it for further processing.
 *
 * If `data` is an array of `GroupType` objects(from Historical Overview or Forecast Output module), it's directly used.
 * If `data` is a `HorizonScenario` object (from Budget Scenarios module), it's processed as follows:
 *   - If the title includes 'Same budget, same channels', it constructs `dataToGroup`
 *     by iterating over the budgets within `same_budget_same_channels`.
 *   - Otherwise, it updates the values in `updatedGroups` based on `budgets`(updated values by user).
 *
 * After grouping, iterates through each object in the `dataToGroup` array. For each object,
 * it formats the date, checks if `formatByMonth` has an entry for that date, and if so,
 * pushes the formatted object into the corresponding array in `formatByMonth`.
 *
 * @param data Can be an array of `GroupType` objects or a `HorizonScenario` object.
 * @returns void
 */
  function groupDataByDate(data?: HorizonData): void {
    if (data === undefined) return;

    let dataToGroup: GroupType[] = [];
    const updatedGroups: GroupType[] = [];

    if (Array.isArray(data)) {
      dataToGroup = data;
    } else {
      const horizonScenario: HorizonScenario = data as HorizonScenario;
      if (title.includes('Same budget, same channels')) {
        if (dataToGroup.length === 0) {
          horizonScenario.same_budget_same_channels.forEach((item: any) => {
            processDataForSameBudgetSameChannels(item, dataToGroup);
          });
        }
      } else {
        horizonScenario.same_budget_same_channels?.forEach((item: any) => {
          updateDataFromSameBudgetSameChannels(item, updatedGroups);
        });
        updateNewDataFromBudgets(horizonScenario, updatedGroups);
        dataToGroup = updatedGroups;
      }
    }
    dataToGroup?.forEach((entry: GroupType) => {
      formatDataByMonth(entry, formatByMonth);
    });
  }

  groupDataByDate(data);

  // Create the Channel header and funnel header rows
  const channelHeaders: string[] = [''];
  const secondHeaders: string[] = ['Date'];
  for (const channel of channels) {
    for (let i = 0; i < funnels.length; i++) {
      if (i === 0) {
        channelHeaders.push(channel);
      } else {
        channelHeaders.push('');
      }
      secondHeaders.push(funnels[i]);
    }
  }
  // Parse each array into a string with items separated by commas
  const parsedHeaders: string = channelHeaders?.map((item: string) => `${item}`).join(',');
  const parsedSecondRow: string = secondHeaders?.map((item: string) => `${item}`).join(',');
  outputRows.push(`${parsedHeaders}`);
  outputRows.push(`${parsedSecondRow}`);


  /**
 * Processes grouped data and creates historical overview data rows.
 *
 * This function takes in a `formatByMonthData` map containing data grouped by month
 * and a `channelFunnelMetrics` representing a dynamic structure of channels and funnels.
 * It iterates over each entry in the `formatByMonthData` map and constructs historical
 * overview data rows based on the provided `channelFunnelMetrics`. For each entry in the map:
 *   - It extracts the date and initializes an output array.
 *   - It iterates over each channel in the `channelFunnelMetrics`.
 *   - For each channel, it iterates over each funnel and checks if the funnel status exists
 *     in the `channelFunnelMetrics`. It then finds the corresponding entry in the data array and
 *     updates the output array with the value if found, otherwise, it adds '0.00'.
 *   - It updates the funnel status and total in the `channelFunnelMetrics` based on the presence
 *     of the entry.
 *   - At the end, it formats the row and pushes it to the `outputRows` array.
 *
 * @param formatByMonthData A map containing data grouped by month.
 * @param channelFunnelMetrics A dynamic structure representing channels and funnels.
 * @returns void
 */
  function processGroupedData(formatByMonthData: Map<string, any[]>, channelFunnelMetrics: { [key: string]: { [key: string]: { status: boolean, total: number } } }): void {

    // Iterate over each key-value pair
    formatByMonthData.forEach((value, key) => {
      const date = key;
      const output: string[] = [];

      // Add the date to the output array
      output.push(date);

      // Iterate over each channel
      Object.keys(channelFunnelMetrics).forEach(channel => {
        // Iterate over each funnel for the current channel
        Object.keys(channelFunnelMetrics[channel]).forEach(funnel => {
          // Check if the funnel status exists in the channelFunnelMetrics
          const funnelTotal = channelFunnelMetrics[channel][funnel].total;

          // Find the corresponding entry in the value array
          const entry = value.find(entry => entry.channel.name === channel && entry.funnel.name === funnel);

          // If the entry exists, push its value to the output array, otherwise push '0.00'
          if (entry) {
            output.push(entry.value.toFixed(2));
          } else {
            output.push('0.00');
          }

          // Update the funnel status and total
          channelFunnelMetrics[channel][funnel].status = entry ? true : false;
          channelFunnelMetrics[channel][funnel].total = entry ? funnelTotal + entry.value : funnelTotal;
        });
      });

      // Push the formatted row to the outputRows array
      outputRows.push(output.join(','));
    });
  }

  processGroupedData(formatByMonth, channelFunnelHasValue);

  // Create the Channel total rows at the end
  const totals: string[] = ['Totals'];
  for (const channel of channels) {
    for (let i = 0; i < funnels.length; i++) {
      const funnel = funnels[i];
      totals.push(channelFunnelHasValue[channel][funnel].total.toFixed(2));
    }
  }
  const parsedTotalsRow: string = totals?.map((item: string) => `${item}`).join(',');
  outputRows.push(`${parsedTotalsRow}`);

  return outputRows;
};
