/**
* Get analyst survey question group by URL param
 */

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  GetAnalystQuestionGroupResult,
  useGetAnalystQuestionGroupQuery,
  GET_ANALYST_QUESTION_GROUP_ENDPOINT_KEY,
  util
} from 'api/crudGraphQL/analyst_surveys/getAnalystQuestionGroup';
import { AnalystQuestionGroup } from 'api/crudGraphQL/analyst_surveys/types';
import { Projection } from 'api/entityGraphQL';
import { questionGroupProjection } from '../constants';
import { useGetAnalystSurveyDepartmentByUrlParam } from './useGetAnalystSurveyDepartmentByUrlParam';

export type UseGetAnalystSurveyQuestionGroupByUrlParam = (args?: {
  refetchOnMountOrArgChange?: boolean;
}) => {
  // Utils
  updateQuestionGroupCache: (questionGroup: Partial<AnalystQuestionGroup>) => void;
  questionGroupId: number;
  questionGroupArguments: {
    id: number;
    projection: Projection;
  }

  // RTK Query
  isLoading: boolean;
  isFetching: boolean;
  data?: GetAnalystQuestionGroupResult;
}

export const useGetAnalystSurveyQuestionGroupByUrlParam: UseGetAnalystSurveyQuestionGroupByUrlParam = ({ refetchOnMountOrArgChange } = {}) => {
  const dispatch = useDispatch();
  const { data: department } = useGetAnalystSurveyDepartmentByUrlParam();

  const questionGroupId = Number( useParams<{ [x: string]: string }>().question_group_id ?? 0);
  const questionGroupArguments = {
    id: questionGroupId,
    projection: questionGroupProjection,
    revision: department?.is_edit_mode === 'yes' ? null : undefined,
  };

  //  Util: Update the question group cache in redux for this request
  const updateQuestionGroupCache = (data: Partial<AnalystQuestionGroup>) => dispatch(
    util.updateQueryData(GET_ANALYST_QUESTION_GROUP_ENDPOINT_KEY, {
      ...questionGroupArguments,
      id: data.id ?? questionGroupArguments.id, // Allow updating question group based on the passed ID instead of the URL param ID
    }, (draft) => {
      Object.assign(draft, data);
      return draft;
    })
  );

  return {
    questionGroupId,
    questionGroupArguments,
    updateQuestionGroupCache,

    // RTK Query
    ...useGetAnalystQuestionGroupQuery(questionGroupArguments, {
      skip: !questionGroupId,
      refetchOnMountOrArgChange
    })
  };
};
