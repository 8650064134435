import { baseApi } from 'api/crudGraphQL';
import { Projection } from 'api/entityGraphQL';
import tagTypes from './tagTypes';
import { PricingType } from './types';

export type GetPricingTypesQuery = {
  projection?: Projection;
};

export type GetPricingTypesResult = PricingType[];

export const GET_PRICING_TYPES_ENDPOINT = 'getPricingTypes';

export const { useGetPricingTypesQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_PRICING_TYPES_ENDPOINT]: build.query<GetPricingTypesResult, GetPricingTypesQuery>({
      transformResponse: (response: { pricing_types: GetPricingTypesResult }) => response.pricing_types,
      providesTags: (pricingTypes = []) => [
        tagTypes.PRICING_TYPES,
        ...pricingTypes.map(pricingType => ({
          id: pricingType.id,
          type: tagTypes.PRICING_TYPE
        }))
      ],
      query: ({
        projection = { id: true, name: true }
      }) => {
        return {
          body: {
            query: {
              pricing_types: {
                ...projection
              }
            }
          }
        };
      }
    })
  })
});
