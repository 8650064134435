/**
 * Library -> Analyst Surveys -> Constants
 */

// Routing
export const BASE_PATH = '/library/analyst-surveys';
const DEPARTMENTS_BASE_PATH = `${BASE_PATH}/departments/:department_id`;
const QUESTION_GROUPS_BASE_PATH = `${DEPARTMENTS_BASE_PATH}/question-groups/:question_group_id`;

export const routes = {
  DEPARTMENTS: BASE_PATH,
  QUESTION_GROUPS_EDIT: `${QUESTION_GROUPS_BASE_PATH}/edit`,
  QUESTION_GROUPS_NEW: `${DEPARTMENTS_BASE_PATH}/new`,
  QUESTION_GROUPS: DEPARTMENTS_BASE_PATH,
  QUESTIONS_EDIT: `${QUESTION_GROUPS_BASE_PATH}/questions/:question_id`,
  QUESTIONS_NEW: `${QUESTION_GROUPS_BASE_PATH}/questions/new`,
  QUESTIONS: `${QUESTION_GROUPS_BASE_PATH}`,
};

/**
  * Re-used projections
  * Note: This is useful for effectively re-using the same projection 
  * for fetching basic data using RTK Query
 */

export const departmentProjection = {
  name: true,
  id: true,
  revision: true,
  is_edit_mode: true,
};

export const questionGroupsListProjection = {
  id: true,
  name: true,
  max: true,
  status: true,
  business_type: {
    id: true,
    name: true,
  },
  services: {
    id: true,
    name: true,
  },
  department: departmentProjection
};

export const questionGroupProjection = {
  ...questionGroupsListProjection,
  questions: {
    name: true,
    id: true,
    status: true,
  }
};

export const questionProjection = {
  id: true,
  name: true,
  max: true,
  status: true,
  question_group: questionGroupProjection,
  support_automation: true,
  criteria: {
    score: true,
    name: true
  }
};
