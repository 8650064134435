/**
 * Audits -> DiscoveryForm -> SalesforceOpportunityStage (in sidebar)
 */

import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { AppDispatch } from 'app/store';
import classNames from 'classnames';
import moment from 'moment';
import { Audit, UpdateSalesforceStageFormValues, updateAudit } from 'features/entitiesRedux/models/audit';
import { Button, Divider, notification, Tooltip } from 'components';
import { UpdateSalesforceStageModal } from './components';
import { StageOption } from './components/UpdateSalesforceStageModal/components/StageSelection/components';
import css from './SalesforceOpportunityStage.module.scss';

type Props = {
  audit: Pick<Audit, 'id' | 'salesforce_opportunity_id' | 'salesforce_opportunity'>;
  onRefresh: (bool: boolean) => void;
}

const SalesforceOpportunityStage: FC<Props> = ({ audit, onRefresh }) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const opportunityLinked = !!audit?.salesforce_opportunity?.id;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isUpdatingStage, setIsUpdatingStage] = useState<boolean>(false);
  const toggleUpdateStageModal = () => setModalOpen(!modalOpen);

  /** onClick handler for the "Update" button in the discovery sidebar */
  const handleClickUpdate = () => {
    if (!opportunityLinked) {
      // if an opportunity is not selected, scroll to that field in the form
      const element = document.getElementById('salesforce-account');
      element?.scrollIntoView({ behavior: 'smooth' });
      history.push(`/appraisals/${audit?.id}/discovery#pre-call`);
    } else {
      toggleUpdateStageModal();
    }
  };

  /** modal form submission handler */
  const handleSubmit = async (values: UpdateSalesforceStageFormValues) => {
    setIsUpdatingStage(true);

    // opportunity stages eligible for setting salesforce_close_date: 'disqualified', 'closed-lost' or 'nurture'
    const closeDateStageIds = [1009, 1010, 1011];
    // only include argument for salesforce_close_date if selected stage matches one of the 3 above
    const shouldSaveCloseDate = values?.salesforce_stage_id && closeDateStageIds.indexOf(values?.salesforce_stage_id) > -1;

    try {
      const updateAction = await dispatch(updateAudit({
        id: audit?.id,
        salesforce_opportunity_id: audit?.salesforce_opportunity_id,
        ...values,
        salesforce_meeting_date: values?.salesforce_meeting_date
          ? moment(values?.salesforce_meeting_date).format('YYYY-MM-DD')
          : undefined,
        salesforce_close_date: values?.salesforce_close_date && shouldSaveCloseDate
          ? moment(values?.salesforce_close_date).format('YYYY-MM-DD')
          : undefined,
      }));

      if (updateAudit.fulfilled.match(updateAction)) {
        notification.success({
          message: 'Salesforce opportunity stage updated',
        });
        toggleUpdateStageModal();
        if (onRefresh) onRefresh(true);
      }

      if (updateAudit.rejected.match(updateAction)) {
        notification.error({
          message: 'An error occurred while updating the Salesforce opportunity stage. Please try again.',
        });
      }
    } catch (e) {
      console.error('Error updating audit', e);
    }
    setIsUpdatingStage(false);
  };

  /** content displayed in the discovery sidebar */
  const renderInfo = () => {
    if (opportunityLinked && audit?.salesforce_opportunity?.stage) {
      return (
        <StageOption
          className={classNames(css.stageTag, audit?.salesforce_opportunity?.stage?.slug)}
          option={audit?.salesforce_opportunity?.stage}
          radio={false}
        />
      );
    }

    return (
      <span className={css.missingInfo}>
        {
          !opportunityLinked ? 'Missing Salesforce Account Information'
            : opportunityLinked && !audit?.salesforce_opportunity?.stage?.name ? 'No stage set'
              : null
        }
      </span>
    );
  };

  /** "Update" button in the discovery sidebar */
  const renderCta = () => {
    return (
      <Button
        className={classNames(css.button, { [css.active]: opportunityLinked })}
        type="text"
        onClick={handleClickUpdate}
      >
        Update
      </Button>
    );
  };

  return (
    <div className={css.root}>
      <span className={css.title}>Salesforce Opportunity Stage</span>
      <Divider height={1} />
      <div className={css.content}>
        <div className={css.info}>
          {renderInfo()}
        </div>
        <div className={css.cta}>
          {!opportunityLinked
            ? <Tooltip title="Please link this appraisal to a Salesforce Opportunity">{renderCta()}</Tooltip>
            : renderCta()
          }
        </div>
      </div>
      <UpdateSalesforceStageModal
        audit={audit}
        onSubmit={handleSubmit}
        onToggle={toggleUpdateStageModal}
        open={modalOpen}
        updating={isUpdatingStage}
      />
    </div>
  );
};

export default SalesforceOpportunityStage;
