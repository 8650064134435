import loadable from "@loadable/component";

const LoadableComponent = loadable(
  () => import("./QuestionGroup")
  /* , {
  fallback: <div>Loading...</div>,
  } */
);

export { LoadableComponent as QuestionGroup };
