import { Projection } from 'api/entityGraphQL/types';
import { NovaAIFeedbackReasonsType } from 'features/entitiesRedux/models/nova_ai';
import { basePublicApi } from '../basePublicApi';

export type GetNovaAIReasonsPublicQuery = {
  projection?: Projection;
};

export type GetNovaAIReasonsPublicResult = NovaAIFeedbackReasonsType[];

export const GET_NOVA_AI_REASONS_PUBLIC_ENDPOINT_KEY = 'getNovaAIReasonsPublic';

export const { useGetNovaAIReasonsPublicQuery } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_NOVA_AI_REASONS_PUBLIC_ENDPOINT_KEY]: build.query<GetNovaAIReasonsPublicResult, GetNovaAIReasonsPublicQuery>({
      transformResponse: (response: { nova_ai_reasons: GetNovaAIReasonsPublicResult }) => response.nova_ai_reasons,
      query: ({
        projection = { id: true, name: true, slug: true },
      }) => ({
        body: {
          query: {
            nova_ai_reasons: {
              ...projection
            },
          }
        }
      })
    }),
  }),
});
