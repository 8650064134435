/**
 * Entities -> Audits -> Adapter
 */

import { createEntityAdapter } from '@reduxjs/toolkit';
import { compareField } from '../../utils/compareField';
import { Audit } from './audit';

/**
 * Redux toolkit entity adapter for storing/caching entities by id
 */
export const auditsAdapter = createEntityAdapter<Audit>({
  selectId: (audit) => audit.id,
  sortComparer: compareField('name'),
});
