import { Projection } from 'api/entityGraphQL/types';
import { NovaAIResponseType } from 'features/entitiesRedux/models/nova_ai';
import { basePublicApi } from '../basePublicApi';
import { cleanArguments } from 'api/crudGraphQL/utils';

export type GetNovaAIResponsePublicQuery = {
  prompt_guid: string;
  projection?: Projection;
  version?: string;
};

export type GetNovaAIResponsePublicResult = NovaAIResponseType;

export const GET_NOVA_AI_RESPONSE_PUBLIC_ENDPOINT_KEY = 'getNovaAIResponsePublic';

export const { useLazyGetNovaAIResponsePublicQuery } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_NOVA_AI_RESPONSE_PUBLIC_ENDPOINT_KEY]: build.query<GetNovaAIResponsePublicResult, GetNovaAIResponsePublicQuery>({
      transformResponse: (response: { nova_ai_response: GetNovaAIResponsePublicResult }) => response.nova_ai_response,
      query: ({
        projection = { response: true },
        ...args
      }) => ({
        body: {
          query: {
            nova_ai_response: {
              __args: cleanArguments(args),
              ...projection
            },
          }
        }
      })
    }),
  }),
});
