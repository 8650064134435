import { PricingTier } from 'api/crudGraphQL/strategies/types';
import { pick } from 'lodash';
import { PricingTierValueName, PricingTypeEnum } from '../constants';

/**
 * Format pricing tier object based on the pricing type.
 * Not all key/value pairs are needed for all pricing types.
 *
 * @param pricingTypeSlug Slug of the pricing type
 * @param pricingTier Pricing tier array
 * @returns {Partial<PricingTier>} The formatted pricing tier object with the only the necessary key/value pairs
 */
export const formatInitialPackageStrategySinglePricingTier = (pricingTypeSlug: string, pricingTier: PricingTier[]): Partial<PricingTier> => {
  let formattedPricingTier = {};
  switch (pricingTypeSlug) {
    case PricingTypeEnum.Fixed:
      formattedPricingTier = pick(pricingTier[0], [PricingTierValueName.PRICE, PricingTierValueName.SNIPPET]);
      break;
    case PricingTypeEnum.AddOnFixed:
      formattedPricingTier = pick(pricingTier[0], [PricingTierValueName.PRICE, PricingTierValueName.SNIPPET]);
      break;
    case PricingTypeEnum.AddOnMultiplier:
      formattedPricingTier = pick(pricingTier[0], [PricingTierValueName.DELIVERABLE, PricingTierValueName.PRICE, PricingTierValueName.SNIPPET]);
      break;
    case PricingTypeEnum.Custom:
      formattedPricingTier = pick(pricingTier[0], [PricingTierValueName.SNIPPET]);
      break;
    case PricingTypeEnum.Multiplier:
      formattedPricingTier = pick(pricingTier[0], [PricingTierValueName.DELIVERABLE, PricingTierValueName.PRICE, PricingTierValueName.SNIPPET]);
      break;
  }
  return formattedPricingTier;
};

/**
 * Format pricing tiers objects based on the pricing type.
 * Not all key/value pairs are needed for all pricing types.
 *
 * @param pricingTypeSlug Slug of the pricing type
 * @param pricingTier Pricing tier array
 * @returns {Partial<PricingTier>} The formatted pricing tier array of objects with the only the necessary key/value pairs
 */
export const formatInitialPackageStrategyMultiplePricingTiers = (pricingTypeSlug: string, pricingTier: PricingTier[]): Partial<PricingTier>[] => {
  let formattedPricingTier: Partial<PricingTier>[] = [];
  switch (pricingTypeSlug) {
    case PricingTypeEnum.Spend:
      formattedPricingTier = pricingTier.map((pricing_tier: PricingTier) => {
        return pick(pricing_tier, [PricingTierValueName.NAME, PricingTierValueName.SPEND_MIN, PricingTierValueName.SPEND_MAX, PricingTierValueName.PRICE, PricingTierValueName.SNIPPET]);
      });
      break;
    case PricingTypeEnum.Quantity:
      formattedPricingTier = pricingTier.map((pricing_tier: PricingTier) => {
        return pick(pricing_tier, [PricingTierValueName.NAME, PricingTierValueName.DELIVERABLE, PricingTierValueName.PRICE, PricingTierValueName.SNIPPET]);
      });
      break;
  }
  return formattedPricingTier;
};
