/**
 * SectionBuilder -> Fields -> NumberField
 */

import React, { FC } from 'react';
import { FormItem, InputNumber } from 'components';
import { formatCurrencyInput, formatPercentInput } from '../../../../../../utils';
import { FieldProps } from '../../types';
import css from './NumberField.module.scss';

const NumberField: FC<FieldProps> = ({ name, format, placeholder }) => {

  const getPrecision = (format: string) => {
    let precision;
    switch (format) {
      case 'currency':
      case 'float':
      case 'percent':
        precision = 2;
        break;
      case 'years':
        precision = 1;
        break;
      default:
        precision = 0;
    }
    return precision;
  };

  const getFormatter = (format: string, val: string | number | undefined) => {
    const sanitized = `${val}`.replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const sanitizedWithDecimal = `${val}`.replace(/[^0-9.]/g, '');
    let formatter;
    switch (format) {
      case 'float':
      case 'years':
        formatter = sanitizedWithDecimal;
        break;
      case 'currency':
        formatter = formatCurrencyInput(val);
        break;
      case 'percent':
        formatter = formatPercentInput(val);
        break;
      default:
        formatter = sanitized;
    }
    return formatter;
  };

  return (
    <FormItem name={name}>
      <InputNumber
        className={css.root}
        type={format}
        max={format === 'percent' ? 100 : Number.MAX_SAFE_INTEGER}
        precision={getPrecision(format)}
        formatter={(val) => getFormatter(format, val)}
        placeholder={placeholder}
      />
    </FormItem>
  );
};

export default NumberField;
