import React, { memo, useMemo } from 'react';
import { Typography } from '@sprnova/nebula';
import { NovaAIResponseType } from 'features/entitiesRedux/models/nova_ai';

type LoadTimeTextProps = {
  activeResponse: NovaAIResponseType;
}

const LoadTimeText = ({ activeResponse }: LoadTimeTextProps): JSX.Element => {
  const stopDateTime = new Date(activeResponse.displayed_at ?? activeResponse.ended_at ?? 0).getTime();
  const startDateTime = new Date(activeResponse.created_at ?? 0).getTime();
  /*
  * Variable: apiResponseLoadTime
  * Description: Calculated by subtracting the ended time it took for the answer to load and the time it started to get the response time of the novaGPT frontend load time.
  * Then we divide by 1000 to get the time in seconds.
  */
  const apiResponseLoadTime = useMemo(
    () =>
      Math.trunc((stopDateTime - startDateTime) / 1000),
    [stopDateTime, startDateTime]);

  return (
    <>
      {!(apiResponseLoadTime <= 0) ?
        <Typography
          sx={{
            textAlign: 'right',
            fontSize: '11px',
            right: 0,
            position: 'relative'
          }}>
    Answer Time: {apiResponseLoadTime} seconds
        </Typography>
        : undefined}
    </>

  );
};

export default memo(LoadTimeText);
