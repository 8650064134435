import { useRef, useEffect } from 'react';

/**
 * A hook to be used to hold onto a value from a previous render. A common
 * use case for such a hook is when you want to remember the previous value
 * of a prop or state variable.
 *
 * @param value The current value that is to be stored and retrieved on next render.
 * @returns The value from the previous render.
 */
const usePrevious = <TValue>(value: TValue): TValue | undefined => {
  const ref = useRef<TValue>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export default usePrevious;
