import { FieldErrors } from 'react-hook-form';
import { CreatePackageBlueprintFormType } from 'features/strategies/CreatePackageBasedBlueprint/CreatePackageBasedBlueprintFormDataType';

/**
 * Method triggered when the form from react-hook-form is submitted and error is found
 * @param errors Error object from react-hook-form. e.g:
 * client_id: {
 *  "type": "required",
 *  "message": "Please select a client",
 *  "ref": {}
 * }
 */
export const scrollToFormFirstError = (errors: FieldErrors<CreatePackageBlueprintFormType>): void => {
  const firstErrorField = Object.keys(errors)[0];
  if (firstErrorField) {
    const element = document.getElementById(firstErrorField);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
};
