import { createSlice } from '@reduxjs/toolkit';
import { fetchSubordinatesPaginated, fetchSurveysPaginated, updateTalentSurvey, updateTalentSurveyBucket, fetchTalentSurveyById } from 'features/talent/actions';
import { surveysAdapter } from 'features/talent/adapter';
import {
  fetchClientsPaginated,
  fetchClientById,
  updateClient,
  createClient,
  deleteClient,
  updateClientAccount,
  fetchContactsPaginated,
  fetchContactById,
  fetchAuditsPaginated,
  fetchAuditById,
  fetchUsersPaginated,
  fetchUserById,
  deleteClientUser,
  createClientUser,
  fetchStrategiesPaginated,
  fetchStrategyById,
  fetchRuleById,
  organizationsAdapter,
  fetchForecastById,
} from './models';
import {
  createAlert,
  createAlertRecipients,
  deleteAlert,
  fetchAlertById,
  fetchAlerts,
  fetchAlertsPaginated,
  fetchTotalClients,
  alertsAdapter
} from './models/alert';
import {
  auditsAdapter,
  updateAudit,
  deleteAudit,
  createAudit,
  updateDiscoveryBucket,
  updateDiscoveryPage,
  updateDiscovery,
} from './models/audit';
import { clientsAdapter, createClientAccount, createClientIntegrationRequest, deleteClientAccount, updateClientIntegrationRequest } from './models/client';
import {
  contactsAdapter,
  updateContact,
  createContact,
  deleteContact,
} from './models/contact';
import {
  fetchDashboardsPaginated,
  fetchDashboards,
  dashboardsAdapter,
  fetchDashboardById,
  createDashboard,
  updateDashboard
} from './models/dashboard';
import {
  getAvailableDataSources,
  datasourcesAdapter,
} from './models/data_source';
import {
  fetchDepartments,
  fetchDepartmentById,
  createDepartment,
  updateDepartment,
  deleteDepartment,
  departmentsAdapter,
  departmentReducers,
} from './models/department';
import { externalClientsAdapter, fetchExternalClientById } from './models/external_client';
import {
  fetchChurns,
  fetchRapports,
  fetchPaces,
  createChurn,
  updateChurn,
  deleteChurn,
  createRapport,
  updateRapport,
  deleteRapport,
  createPace,
  updatePace,
  deletePace,
  fieldReducers,
  fieldsAdapter
} from './models/field';
import {
  forecastsAdapter,
  forecastReducers,
  updateForecast,
  updateForecastChannel,
  updateForecastMonth,
  createNeedleMovers,
  deleteNeedleMovers
} from './models/forecast';
import {
  fetchPartnersPaginated,
  fetchPartnerById,
  fetchExternalPartnerById,
  createPartner,
  updatePartner,
  deletePartner,
  createPartnerUser,
  partnersAdapter,
  partnerReducers,
} from './models/partner';
import {
  reportsAdapter,
  fetchReportsPaginated,
  fetchReportById,
  fetchReports,
  updateReport,
  createReportWidget,
  createReportWidgetData,
  updateReportWidgetData,
  saveReportWidgetData,
  createReportStatus,
  updateReportStatus,
  updateReportSection,
  deletePacingGoal,
  createPacingGoal,
  updatePacingGoal,
  // fetchSections
} from './models/report';
import {
  createRule,
  deleteRule,
  fetchRules,
  ruleReducers,
  rulesAdapter,
  updateRule,
} from './models/rule';
import {
  fetchServices,
  fetchServiceById,
  createService,
  updateService,
  deleteService,
  servicesAdapter,
  serviceReducers,
} from './models/service';
import {
  createStrategy,
  createStrategyCost,
  createStrategyDuplicate,
  createStrategyProposal,
  createStrategyTask,
  deleteStrategy,
  deleteStrategyCost,
  deleteStrategyTask,
  strategiesAdapter,
  updateStrategy,
  updateStrategyCost,
  updateStrategyCostMonth,
  updateStrategyTask,
  createStrategyTasks,
  updateStrategyTaskMonth,
  updateStrategyTaskOrder,
} from './models/strategy';
import {
  fetchTasks,
  fetchTaskById,
  createTask,
  updateTask,
  deleteTask,
  tasksAdapter,
  taskReducers,
} from './models/task';
import { usersAdapter } from './models/user';
import { EntitiesState } from './types';
import { connectEntityActionReducers } from './utils';

/**
 * State
 */
export const entitiesInitialState: EntitiesState = {
  data: {
    audits: auditsAdapter.getInitialState(),
    external_clients: externalClientsAdapter.getInitialState(),
    clients: clientsAdapter.getInitialState(),
    contacts: contactsAdapter.getInitialState(),
    datasources: datasourcesAdapter.getInitialState(),
    departments: departmentsAdapter.getInitialState(),
    forecasts: forecastsAdapter.getInitialState(),
    organizations: organizationsAdapter.getInitialState(),
    partners: partnersAdapter.getInitialState(),
    reports: reportsAdapter.getInitialState(),
    rules: rulesAdapter.getInitialState(),
    services: servicesAdapter.getInitialState(),
    strategies: strategiesAdapter.getInitialState(),
    tasks: tasksAdapter.getInitialState(),
    users: usersAdapter.getInitialState(),
    surveys: surveysAdapter.getInitialState(),
    alerts: alertsAdapter.getInitialState(),
    dashboards: dashboardsAdapter.getInitialState(),
    dashboard_churns: fieldsAdapter.getInitialState(),
    dashboard_rapports: fieldsAdapter.getInitialState(),
    dashboard_paces: fieldsAdapter.getInitialState(),
  },
  isLoading: false,
  error: null,
};

/**
 * Reducer
 */
export const entitiesSlice = createSlice({
  name: 'entities',
  initialState: entitiesInitialState,
  reducers: {
    /**
     * Synchronous reducers
     */
    ...departmentReducers,
    ...partnerReducers,
    ...ruleReducers,
    ...serviceReducers,
    ...taskReducers,
    ...forecastReducers,
    ...fieldReducers,
  },
  extraReducers: (builder) => {
    /** External Clients */
    connectEntityActionReducers(builder, fetchExternalClientById);
    /** Clients */
    connectEntityActionReducers(builder, fetchClientsPaginated);
    connectEntityActionReducers(builder, fetchClientById);
    connectEntityActionReducers(builder, updateClient);
    connectEntityActionReducers(builder, createClient);
    connectEntityActionReducers(builder, deleteClient);
    connectEntityActionReducers(builder, deleteClientUser);
    connectEntityActionReducers(builder, createClientUser);
    connectEntityActionReducers(builder, updateClientAccount);
    connectEntityActionReducers(builder, createClientAccount);
    connectEntityActionReducers(builder, deleteClientAccount);
    connectEntityActionReducers(builder, updateClientIntegrationRequest);
    connectEntityActionReducers(builder, createClientIntegrationRequest);
    /** Contacts */
    connectEntityActionReducers(builder, fetchContactsPaginated);
    connectEntityActionReducers(builder, fetchContactById);
    connectEntityActionReducers(builder, updateContact);
    connectEntityActionReducers(builder, createContact);
    connectEntityActionReducers(builder, deleteContact);
    /* Data Sources */
    connectEntityActionReducers(builder, getAvailableDataSources);
    /** Departments */
    connectEntityActionReducers(builder, fetchDepartments);
    connectEntityActionReducers(builder, fetchDepartmentById);
    connectEntityActionReducers(builder, createDepartment);
    connectEntityActionReducers(builder, deleteDepartment);
    connectEntityActionReducers(builder, updateDepartment);
    /** Services */
    connectEntityActionReducers(builder, fetchServices);
    connectEntityActionReducers(builder, fetchServiceById);
    connectEntityActionReducers(builder, createService);
    connectEntityActionReducers(builder, deleteService);
    connectEntityActionReducers(builder, updateService);
    /** Tasks */
    connectEntityActionReducers(builder, fetchTasks);
    connectEntityActionReducers(builder, fetchTaskById);
    connectEntityActionReducers(builder, createTask);
    connectEntityActionReducers(builder, deleteTask);
    connectEntityActionReducers(builder, updateTask);
    /** Audits */
    connectEntityActionReducers(builder, fetchAuditsPaginated);
    connectEntityActionReducers(builder, fetchAuditById);
    connectEntityActionReducers(builder, updateAudit);
    connectEntityActionReducers(builder, createAudit);
    connectEntityActionReducers(builder, deleteAudit);
    connectEntityActionReducers(builder, updateDiscoveryBucket);
    connectEntityActionReducers(builder, updateDiscoveryPage);
    connectEntityActionReducers(builder, updateDiscovery);
    /** Users */
    connectEntityActionReducers(builder, fetchUsersPaginated);
    connectEntityActionReducers(builder, fetchUserById);
    /** Partners */
    connectEntityActionReducers(builder, fetchPartnersPaginated);
    connectEntityActionReducers(builder, fetchPartnerById);
    connectEntityActionReducers(builder, fetchExternalPartnerById);
    connectEntityActionReducers(builder, createPartner);
    connectEntityActionReducers(builder, deletePartner);
    connectEntityActionReducers(builder, createPartnerUser);
    connectEntityActionReducers(builder, updatePartner);
    /** Strategies */
    connectEntityActionReducers(builder, fetchStrategiesPaginated);
    connectEntityActionReducers(builder, createStrategyCost);
    connectEntityActionReducers(builder, deleteStrategyCost);
    connectEntityActionReducers(builder, updateStrategyCost);
    connectEntityActionReducers(builder, updateStrategyCostMonth);
    connectEntityActionReducers(builder, updateStrategyTaskMonth);
    connectEntityActionReducers(builder, deleteStrategyTask);
    connectEntityActionReducers(builder, createStrategyTask);
    connectEntityActionReducers(builder, createStrategyTasks);
    connectEntityActionReducers(builder, updateStrategyTask);
    connectEntityActionReducers(builder, updateStrategyTaskOrder);
    connectEntityActionReducers(builder, createStrategy);
    connectEntityActionReducers(builder, createStrategyDuplicate);
    connectEntityActionReducers(builder, updateStrategy);
    connectEntityActionReducers(builder, deleteStrategy);
    connectEntityActionReducers(builder, fetchStrategyById);
    connectEntityActionReducers(builder, createStrategyProposal);
    /** Reports */
    connectEntityActionReducers(builder, fetchReportsPaginated);
    connectEntityActionReducers(builder, fetchReportById);
    connectEntityActionReducers(builder, fetchReports);
    connectEntityActionReducers(builder, updateReport);
    connectEntityActionReducers(builder, createReportWidget);
    connectEntityActionReducers(builder, createReportWidgetData);
    connectEntityActionReducers(builder, updateReportWidgetData);
    connectEntityActionReducers(builder, saveReportWidgetData);
    connectEntityActionReducers(builder, createReportStatus);
    connectEntityActionReducers(builder, updateReportStatus);
    connectEntityActionReducers(builder, updateReportSection);
    connectEntityActionReducers(builder, createPacingGoal);
    connectEntityActionReducers(builder, deletePacingGoal);
    connectEntityActionReducers(builder, updatePacingGoal);
    // connectEntityActionReducers(builder, deleteReportStatus);
    /** Surveys */
    connectEntityActionReducers(builder, fetchSubordinatesPaginated);
    connectEntityActionReducers(builder, fetchSurveysPaginated);
    connectEntityActionReducers(builder, updateTalentSurvey);
    connectEntityActionReducers(builder, updateTalentSurveyBucket);
    connectEntityActionReducers(builder, fetchTalentSurveyById);
    /** Rules */
    connectEntityActionReducers(builder, fetchRules);
    connectEntityActionReducers(builder, fetchRuleById);
    connectEntityActionReducers(builder, createRule);
    connectEntityActionReducers(builder, deleteRule);
    connectEntityActionReducers(builder, updateRule);
    /** Forecasts */
    connectEntityActionReducers(builder, fetchForecastById);
    connectEntityActionReducers(builder, updateForecastChannel);
    connectEntityActionReducers(builder, updateForecastMonth);
    connectEntityActionReducers(builder, updateForecast);
    connectEntityActionReducers(builder, deleteNeedleMovers);
    connectEntityActionReducers(builder, createNeedleMovers);
    /** Alerts */
    connectEntityActionReducers(builder, fetchAlertsPaginated);
    connectEntityActionReducers(builder, fetchTotalClients);
    connectEntityActionReducers(builder, fetchAlerts);
    connectEntityActionReducers(builder, fetchAlertById);
    connectEntityActionReducers(builder, deleteAlert);
    connectEntityActionReducers(builder, createAlert);
    connectEntityActionReducers(builder, createAlertRecipients);
    /** Dashboards */
    connectEntityActionReducers(builder, fetchDashboardsPaginated);
    connectEntityActionReducers(builder, fetchDashboards);
    connectEntityActionReducers(builder, fetchDashboardById);
    connectEntityActionReducers(builder, createDashboard);
    connectEntityActionReducers(builder, updateDashboard);
    /** Fields */
    connectEntityActionReducers(builder, fetchChurns);
    connectEntityActionReducers(builder, fetchRapports);
    connectEntityActionReducers(builder, fetchPaces);
    connectEntityActionReducers(builder, createChurn);
    connectEntityActionReducers(builder, updateChurn);
    connectEntityActionReducers(builder, deleteChurn);
    connectEntityActionReducers(builder, createRapport);
    connectEntityActionReducers(builder, updateRapport);
    connectEntityActionReducers(builder, deleteRapport);
    connectEntityActionReducers(builder, createPace);
    connectEntityActionReducers(builder, updatePace);
    connectEntityActionReducers(builder, deletePace);
  },
});

/**
 * Export
 */
export default entitiesSlice.reducer;
