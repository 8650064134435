import React from 'react';
import { Skeleton } from '@sprnova/nebula';
import { Audit } from 'features/entitiesRedux/models/audit/audit';

/**
 * Format the title of an audit
 * @param { Audit } audit - The audit object
 * @param { boolean } isLoading - Whether the audit is still loading
 * @returns Audit title
 */
export const formatAuditTitle = ({
  audit,
  isLoading,
}: {
    audit?: Audit;
    isLoading: boolean;
  }): string | JSX.Element => {
  if (!audit && isLoading) {
    return <Skeleton width={200} />;  
  }

  if (audit) {
    if (audit.name) {
      return `${audit.name}`;
    } // Try the name field
    if (audit?.client?.name) {
      return `${audit.client.name} Appraisal`;
    } // Try the client field

    return `Appraisal #${audit.id}`;
  }  

  return '...';
};