import { createAction, createSlice } from '@reduxjs/toolkit';
import { selectApiClient } from 'features/global';
import { getClient } from '../../api/entityGraphQL/utils';
import { RootState } from '../../app/store';
import {
  BusinessType,
  Department,
  Industry,
  LeadSource,
  Pillar,
  Service,
} from '../entitiesRedux/models';

/**
 * Types
 */
interface AuditsState {
  discoveryPage: {
    activeTab: string | null;
    activeDepartment: {
      id: number | null;
      name: string | null;
      slug: string | null;
      services: Service[];
      notes: string | null;
    };
    activeServices: Service[];
    enableSaveButton: boolean;
    availableTabs: Topic[];
    businessInfo: {
      business_types: BusinessType[];
      industries: Industry[];
      lead_sources: LeadSource[];
      pillars: Pillar[];
    };
    budgetTypes: BudgetType[];
    channelTypes: ChannelType[];
    goalTypes: GoalType[];
    topics: Topic[];
    departments: Department[];
    services: Service[];
    questions: Question[];
    presentationTemplates: Template[];
    version: string | null;
    businessTypeId: number | null;
    isLoading: boolean;
    isCreating: boolean;
    isUpdating: boolean;
    error: string | null;
  };
}

export type Topic = {
  id: number;
  name: string;
  slug: string;
  order: number;
  notes: string;
  questions: Question[];
  count: number;
  total: number;
};

export type Question = {
  id: number;
  question: string;
  type: string;
  business_type: BusinessType;
  topic: Topic;
  is_active: string;
  is_required: string;
  is_public: string;
  group_name: string;
  answer: string;
  fields: string[];
  options: string[];
  format: string;
  placeholder: string;
  subtext: string;
};

export type ChannelType = {
  id: number;
  name: string;
}

export type GoalType = {
  id: number;
  name: string;
  business_type: {
    id: number;
  }
}

export enum ANALYST_SURVEY_STATUS_TYPES {
  COMPLETE = 'complete',
  PARTIAL = 'partial',
  PENDING = 'pending',
}

export type BudgetType = {
  id: number;
  name: string;
}

export type Template = {
  id: number;
  name: string;
  slug: string;
  links: {
    id: number;
    name: string;
    slug: string;
  }
  department: Department;
  data_source: {
    id: number;
    name: string;
    slug: string;
  }
}

export type PresentationTemplateVariable = {
  id: number,
  value: string
}

export type IntegrationInput = {
  name?: string;
  slug?: string;
  id: number | string;
  period: number;
  currency: string;
  start_date: string;
  end_date: string;
}

/**
 * State
 */
export const auditsInitialState: AuditsState = {
  discoveryPage: {
    activeTab: null,
    activeDepartment: {
      id: null,
      name: null,
      slug: null,
      services: [],
      notes: null,
    },
    activeServices: [],
    enableSaveButton: false,
    availableTabs: [],
    businessInfo: {
      business_types: [],
      industries: [],
      lead_sources: [],
      pillars: [],
    },
    budgetTypes: [],
    channelTypes: [],
    goalTypes: [],
    topics: [],
    departments: [],
    services: [],
    questions: [],
    presentationTemplates: [],
    version: null,
    businessTypeId: null,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    error: null,
  },
};

/**
 * Actions
 */
export const changeTab = createAction('audits/changeTab', function prepare(
  slug
) {
  return {
    payload: { slug },
  };
});

export const changeDepartment = createAction(
  'audits/changeDepartment',
  function prepare(department) {
    return {
      payload: { department },
    };
  }
);

export const setDiscoveryVersion = createAction(
  'audits/setDiscoveryVersion',
  function prepare(version) {
    return {
      payload: { version },
    };
  }
);

export const setDiscoveryPageBusinessTypeId = createAction(
  'audits/setDiscoveryPageBusinessTypeId',
  function prepare(businessTypeId) {
    return {
      payload: { businessTypeId },
    };
  }
);

/**
 * Async Actions
 */
export const fetchBusinessInfo = () => async (
  dispatch: (fetchBusinessInfoPending1: any) => void,
  getState: any
) => {
  const clientApi = selectApiClient(getState() as RootState);
  const query = `query {
                      business_types {
                        id
                        name
                        slug
                      }
                      industries {
                        id
                        name
                        slug
                      }
                      ${
  clientApi !== 'public'
    ? `
                      lead_sources {
                        id
                        name
                        slug
                      }
                      pillars {
                        id
                        name
                        slug
                      }`
    : ''
}
                    }`;

  try {
    dispatch(fetchBusinessInfoPending());
    const response = await getClient(clientApi)
      .sendRequest(query)
      .catch((err: Error) => console.log(err.message));

    dispatch(fetchBusinessInfoFulfilled({ businessInfo: response }));
  } catch (err) {
    dispatch(
      fetchBusinessInfoRejected({ err: 'error fetching business units' })
    );
  }
};

export const fetchTopics = ({
  business_type_id
}: {
  business_type_id: number;
}) => async (
  dispatch: (fetchTopicsPending1: any) => void,
  getState: any
) => {
  const query = `query {
                  topics(business_type_id: ${business_type_id}) {
                    id
                    slug
                    name
                    order
                    count
                    total
                    questions {
                      prospect_question
                      type
                      question
                      id
                      fields
                      options
                      placeholder
                      format
                      group_name
                      answer
                      business_type {
                        id
                        name
                      }
                    }
                  }
                }`;

  try {
    dispatch(fetchTopicsPending());

    const response = await getClient(selectApiClient(getState() as RootState))
      .sendRequest(query)
      .catch((err: Error) => console.log(err.message));

    dispatch(fetchTopicsFulfilled({ topics: response.topics }));
  } catch (err) {
    dispatch(fetchTopicsRejected({ err: 'error fetching topics' }));
  }
};

export const fetchDepartments = () => async (
  dispatch: (fetchDepartmentsPending: any) => void,
  getState: any
) => {
  const query = `query {
                      departments {
                        id
                        name
                        slug
                        services(module_id: 1001) {
                          id
                          name
                          department {
                            id
                          }
                        }
                      }
                    }`;

  try {
    dispatch(fetchDepartmentsPending());

    const response = await getClient(selectApiClient(getState() as RootState))
      .sendRequest(query)
      .catch((err: Error) => console.log(err.message));

    dispatch(fetchDepartmentsFulfilled({ departments: response.departments }));
  } catch (err) {
    dispatch(fetchDepartmentsRejected({ err: 'error fetching departments' }));
  }
};

export const fetchServices = () => async (
  dispatch: (fetchServicesPending: any) => void,
  getState: any
) => {
  const query = `query {
                      services(module: "appraisal") {
                          id
                          name
                          department {
                            id
                          }
                        }
                    }`;

  try {
    dispatch(fetchServicesPending());

    const response = await getClient(selectApiClient(getState() as RootState))
      .sendRequest(query)
      .catch((err: Error) => console.log(err.message));

    dispatch(fetchServicesFulfilled({ services: response.services }));
  } catch (err) {
    dispatch(fetchServicesRejected({ err: 'error fetching services' }));
  }
};

export const fetchQuestions = () => async (
  dispatch: (fetchQuestionsPending: any) => void,
  getState: any
) => {
  const query = `query {
                      questions {
                        id
                        question
                        is_public
                        is_required
                        group_name
                        topic {
                          id
                        }
                        business_type {
                          id
                        }
                      }
                    }`;

  try {
    dispatch(fetchQuestionsPending());

    const response = await getClient(selectApiClient(getState() as RootState))
      .sendRequest(query)
      .catch((err: Error) => console.log(err.message));

    dispatch(fetchQuestionsFulfilled({ questions: response.questions }));
  } catch (err) {
    dispatch(fetchQuestionsRejected({ err: 'error fetching questions' }));
  }
};

export const fetchBudgetTypes = () => async (
  dispatch: (fetchBudgetTypesPending: any) => void,
  getState: any
) => {
  const query = `query {
                      budgets {
                        id
                        name
                      }
                    }`;

  try {
    dispatch(fetchBudgetTypesPending());

    const response = await getClient(selectApiClient(getState() as RootState))
      .sendRequest(query)
      .catch((err: Error) => console.log(err.message));

    dispatch(fetchBudgetTypesFulfilled({ budgetTypes: response.budgets }));
  } catch (err) {
    dispatch(fetchBudgetTypesRejected({ err: 'error fetching budget types' }));
  }
};

export const fetchChannelTypes = () => async (
  dispatch: (fetchChannelTypesPending: any) => void,
  getState: any
) => {
  const query = `query {
                      channels {
                        id
                        name
                      }
                    }`;

  try {
    dispatch(fetchChannelTypesPending());

    const response = await getClient(selectApiClient(getState() as RootState))
      .sendRequest(query)
      .catch((err: Error) => console.log(err.message));

    dispatch(fetchChannelTypesFulfilled({ channelTypes: response.channels }));
  } catch (err) {
    dispatch(fetchChannelTypesRejected({ err: 'error fetching channel types' }));
  }
};

export const fetchGoalTypes = () => async (
  dispatch: (fetchGoalTypesPending: any) => void,
  getState: any
) => {
  const query = `query {
                      goals {
                        id
                        name
                        business_type {
                          id
                        }
                      }
                    }`;

  try {
    dispatch(fetchGoalTypesPending());

    const response = await getClient(selectApiClient(getState() as RootState))
      .sendRequest(query)
      .catch((err: Error) => console.log(err.message));

    dispatch(fetchGoalTypesFulfilled({ goalTypes: response.goals }));
  } catch (err) {
    dispatch(fetchGoalTypesRejected({ err: 'error fetching goal types' }));
  }
};

export const fetchActiveServices = (auditId: number | undefined) => async (
  dispatch: (fetchActiveServicesPending: any) => void,
  getState: any
) => {
  const query = `query {
                      audit(id: ${auditId}) {
                        id
                        discovery {
                          services {
                            id
                            name
                            is_active
                            in_survey
                            department {
                              id
                            }
                            templates {
                              id
                              name
                            }
                          }
                        }
                      }
                    }`;

  try {
    dispatch(fetchActiveServicesPending());

    const response = await getClient(selectApiClient(getState() as RootState))
      .sendRequest(query)
      .catch((err: Error) => console.log(err.message));

    dispatch(
      fetchActiveServicesFulfilled({
        activeServices: response.audit.discovery.services,
      })
    );
  } catch (err) {
    dispatch(
      fetchActiveServicesRejected({ err: 'error fetching discovery services' })
    );
  }
};

export const fetchPresentationTemplates = () => async (
  dispatch: (fetchPresentationTemplatesPending: any) => void,
  getState: any
) => {
  const query = `query {
                      templates {
                        id
                        name
                        slug
                        department {
                          id
                          name
                        }
                        data_source {
                          id
                          name
                          slug
                        }
                        links {
                          id
                          name
                          slug
                        }
                        services {
                          id
                          name
                        }
                      }
                    }`;

  try {
    dispatch(fetchPresentationTemplatesPending());

    const response = await getClient(selectApiClient(getState() as RootState))
      .sendRequest(query)
      .catch((err: Error) => console.log(err.message));

    dispatch(fetchPresentationTemplatesFulfilled({ templates: response.templates }));
  } catch (err) {
    dispatch(fetchPresentationTemplatesRejected({ err: 'error fetching presentation templates' }));
  }
};

/**
 * Reducer
 */
export const auditsSlice = createSlice({
  name: 'audits',
  initialState: auditsInitialState,
  reducers: {
    /** Changing the topic tab */
    changeTab: (state, action) => {
      state.discoveryPage.activeTab = action.payload.slug;
    },

    /** Changing the department on Marketing Strategy page of Discovery Call */
    changeDepartment: (state, action) => {
      state.discoveryPage.activeDepartment = action.payload.department;
    },

    /** Tracks whether the current discovery is public or internal  */
    setDiscoveryVersion: (state, action) => {
      state.discoveryPage.version = action.payload.version;
    },

    /** Set the business type globally */
    setDiscoveryPageBusinessTypeId: (state, action) => {
      state.discoveryPage.businessTypeId = action.payload.businessTypeId;
    },

    /** Fetch all business types, industries and lead sources **/
    fetchBusinessInfoPending: (state) => {
      state.discoveryPage.isLoading = true;
      state.discoveryPage.error = null;
    },
    fetchBusinessInfoFulfilled: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = null;
      state.discoveryPage.businessInfo = action.payload.businessInfo;
    },
    fetchBusinessInfoRejected: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = action.payload.err;
    },

    /** Fetch all topics **/
    fetchTopicsPending: (state) => {
      state.discoveryPage.isLoading = true;
      state.discoveryPage.error = null;
    },
    fetchTopicsFulfilled: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = null;
      state.discoveryPage.topics = action.payload.topics;
    },
    fetchTopicsRejected: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = action.payload.err;
    },

    /** Fetch all departments **/
    fetchDepartmentsPending: (state) => {
      state.discoveryPage.isLoading = true;
      state.discoveryPage.error = null;
    },
    fetchDepartmentsFulfilled: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = null;
      state.discoveryPage.departments = action.payload.departments;
    },
    fetchDepartmentsRejected: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = action.payload.err;
    },

    /** Fetch all services **/
    fetchServicesPending: (state) => {
      state.discoveryPage.isLoading = true;
      state.discoveryPage.error = null;
    },
    fetchServicesFulfilled: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = null;
      state.discoveryPage.services = action.payload.services;
    },
    fetchServicesRejected: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = action.payload.err;
    },

    /** Fetch all questions **/
    fetchQuestionsPending: (state) => {
      state.discoveryPage.isLoading = true;
      state.discoveryPage.error = null;
    },
    fetchQuestionsFulfilled: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = null;
      state.discoveryPage.questions = action.payload.questions;
    },
    fetchQuestionsRejected: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = action.payload.err;
    },

    /** Fetch all budget types **/
    fetchBudgetTypesPending: (state) => {
      state.discoveryPage.isLoading = true;
      state.discoveryPage.error = null;
    },
    fetchBudgetTypesFulfilled: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = null;
      state.discoveryPage.budgetTypes = action.payload.budgetTypes;
    },
    fetchBudgetTypesRejected: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = action.payload.err;
    },

    /** Fetch all channel types **/
    fetchChannelTypesPending: (state) => {
      state.discoveryPage.isLoading = true;
      state.discoveryPage.error = null;
    },
    fetchChannelTypesFulfilled: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = null;
      state.discoveryPage.channelTypes = action.payload.channelTypes;
    },
    fetchChannelTypesRejected: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = action.payload.err;
    },

    /** Fetch all goal types **/
    fetchGoalTypesPending: (state) => {
      state.discoveryPage.isLoading = true;
      state.discoveryPage.error = null;
    },
    fetchGoalTypesFulfilled: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = null;
      state.discoveryPage.goalTypes = action.payload.goalTypes;
    },
    fetchGoalTypesRejected: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = action.payload.err;
    },

    /** Fetch active discovery services **/
    fetchActiveServicesPending: (state) => {
      state.discoveryPage.isLoading = true;
      state.discoveryPage.error = null;
    },
    fetchActiveServicesFulfilled: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = null;
      state.discoveryPage.activeServices = action.payload.activeServices.filter(
        (service: Service) => {
          return service?.is_active === 'yes';
        }
      );
    },
    fetchActiveServicesRejected: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = action.payload.err;
    },

    /** Fetch all presentation templates **/
    fetchPresentationTemplatesPending: (state) => {
      state.discoveryPage.isLoading = true;
      state.discoveryPage.error = null;
    },
    fetchPresentationTemplatesFulfilled: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = null;
      state.discoveryPage.presentationTemplates = action.payload.templates;
    },
    fetchPresentationTemplatesRejected: (state, action) => {
      state.discoveryPage.isLoading = false;
      state.discoveryPage.error = action.payload.err;
    },
  },
});

const {
  fetchBusinessInfoPending,
  fetchBusinessInfoFulfilled,
  fetchBusinessInfoRejected,
  fetchTopicsPending,
  fetchTopicsFulfilled,
  fetchTopicsRejected,
  fetchDepartmentsPending,
  fetchDepartmentsFulfilled,
  fetchDepartmentsRejected,
  fetchServicesPending,
  fetchServicesFulfilled,
  fetchServicesRejected,
  fetchActiveServicesPending,
  fetchActiveServicesFulfilled,
  fetchActiveServicesRejected,
  fetchQuestionsPending,
  fetchQuestionsFulfilled,
  fetchQuestionsRejected,
  fetchBudgetTypesPending,
  fetchBudgetTypesFulfilled,
  fetchBudgetTypesRejected,
  fetchChannelTypesPending,
  fetchChannelTypesFulfilled,
  fetchChannelTypesRejected,
  fetchGoalTypesPending,
  fetchGoalTypesFulfilled,
  fetchGoalTypesRejected,
  fetchPresentationTemplatesPending,
  fetchPresentationTemplatesFulfilled,
  fetchPresentationTemplatesRejected
} = auditsSlice.actions;

/**
 * Selectors
 */
export const selectDiscoveryPage = (state: RootState) => {
  return state.audits.discoveryPage;
};

export const selectQuestionsByBusinessType = (
  businessTypeId: number | null
) => (state: RootState) => {
  const questions = state.audits.discoveryPage.questions;
  return questions?.filter(
    (question) =>
      question?.business_type?.id === businessTypeId || !question?.business_type
  );
};

export const selectQuestionByQuestionId = (questionId: number) => (
  state: RootState
) => {
  const questions = state.audits.discoveryPage.questions;
  return questions?.filter((question) => question?.id === questionId);
};

/**
 * Export
 */
export default auditsSlice.reducer;
