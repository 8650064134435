/**
 * Blueprints -> Review List -> Row
 */

import React, { FC, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import moment from 'moment';
import pluralize from 'pluralize';
import { formatCurrency } from 'utils/format';
import { Resource, Strategy } from 'features/entitiesRedux';
import listCss from 'features/strategies/StrategiesListPage/components/StrategiesList/components/Row/Row.module.scss';
import { Button, BlockList, Avatar, AccessControl } from 'components';
import { Action } from '../../../../../../../api/accessControl';
import css from './Row.module.scss';

type Types = {
  strategy: Strategy;
  reviewerType: 'Finance' | 'Department';
};

const BlueprintsReviewListItem: FC<Types> = ({ strategy, reviewerType }) => {
  const createdAt = useMemo(() => moment(strategy?.created_at).format('M/D/YYYY'), [strategy]);
  const history = useHistory();
  const renderButtons = () => {
    const hashRoute = reviewerType === 'Department' ? '#team-commissions' : '';
    const pageRoute = () =>{
      history.push(`/blueprints/${strategy?.id}${hashRoute}`, {from: 'internal'});
    };
    return (
      <AccessControl
        action={[Action.update]}
        resource={Resource.strategy}
        showWarning={false}
      >
        <Button  onClick={pageRoute} type="primary">
            Review Blueprint <RightOutlined/>
        </Button>
      </AccessControl>
    );
  };

  return (
    <BlockList.Row
      className={classnames(listCss.column, listCss.root)}
      to={`/blueprints/${strategy?.id}`}
      aria-label="Go to blueprint"
    >
      <BlockList.Column
        alignLeft
        className={classnames(listCss.column, listCss.column__client)}
        avatar={
          <Avatar src={strategy?.client?.logo}>
            {strategy?.client?.name?.charAt(0) || '-'}
          </Avatar>
        }
        value={<Link to={`/power-view/accounts/${strategy?.client?.id}`}>{strategy?.client?.name}</Link>}
        description={<span className={listCss.__subtitle}>
          {strategy?.name}</span>}
        ellipsis
      />
      <BlockList.Column
        className={classnames(listCss.column, listCss.column__createdAt)}
        value={createdAt}
      />
      <BlockList.Column
        className={classnames(listCss.column, listCss.column__avggp)}
        value={formatCurrency(strategy?.monthly_gross_profit || 0)}
      />
      <BlockList.Column
        className={classnames(listCss.column, listCss.column__length)}
        value={
          strategy?.type === 'project'
            ? 'N/A'
            : pluralize('month', strategy?.length, true)
        }
      />
      <BlockList.Column
        className={classnames(listCss.column, css.column__buttons)}
        value={renderButtons()}
      />
    </BlockList.Row>
  );
};

export default BlueprintsReviewListItem;
