import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Box } from '@sprnova/nebula';
import { Strategy } from 'features/entitiesRedux';
import css from './BlueprintName.module.scss';

type BlueprintNameProps = {
  blueprint: Strategy;
};

const BlueprintName = ({ blueprint }: BlueprintNameProps): JSX.Element => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    event.stopPropagation();
  };

  return (
    <Box sx={{
      alignItems: 'center',
      display: 'flex',
      gap: '12px',
      wordBreak: 'break-word',
    }}>
      <Avatar title={blueprint?.client?.name} src={blueprint?.client?.logo} alt="Client avatar">
        {blueprint?.client?.name?.charAt(0) || '-'}
      </Avatar>
      <div>
        <div><b>{blueprint?.client?.name}</b></div>
        <div><b>{blueprint?.pillar?.name}</b></div>
        <Link to={`/power-view/accounts/${blueprint?.client?.id}`} target="_blank" rel="noopener noreferrer" className={css.client__link} onClick={(event): void => handleClick(event)}>Client ID: {blueprint?.client?.id}</Link>
      </div>
    </Box>
  );
};

export default memo(BlueprintName);
