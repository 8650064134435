import React from 'react';
import classNames from 'classnames';
import css from './StatusIndicator.module.scss';

interface StatusIndicatorDotProps {
  className?: string;
  style?: React.CSSProperties;
  status?: string;
}

export const StatusIndicatorDot = ({
  className,
  style,
  status,
}: StatusIndicatorDotProps) => {
  const getClass = () => {
    let statusClass;
    switch (status) {
      case 'pending':
        statusClass = css.pending;
        break;
      case 'partial':
        statusClass = css.partial;
        break;
      case 'complete':
        statusClass = css.complete;
        break;
      default:
        statusClass = css.pending;
    }
    return statusClass;
  };

  return (
    <div className={classNames(className, css.dot)} style={style}>
      <span className={getClass()}></span>
    </div>
  );
};

export default StatusIndicatorDot;
