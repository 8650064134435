/**
 * SurveyQuestion -> QuestionSwitch
 */

import React, { FC, useState, useEffect } from 'react';
import { TalentSurveyBucket } from 'features/talent/types';
import { Switch } from '../../../Switch';

export type QuestionSwitchProps = {
  question: Partial<TalentSurveyBucket> | undefined;
  onChange: (value: number) => void;
  unauthorized: boolean;
}

export const QuestionSwitch: FC<QuestionSwitchProps> = ({ question, onChange, unauthorized  }) => {
  const [disabled, setDisabled] = useState(false);
  const [checked, setChecked] = useState(question?.score === 1);

  const handleChange = () => {
    setChecked(curr => {
      const nextState = !curr;
      // Disable switch while updating value
      setDisabled(true);

      // Update score
      onChange(nextState ? 1 : 0);

      return nextState;
    });
  };

  // Remove disabled state when the score updates
  useEffect(() => {
    if (!!question?.score === checked && disabled) {
      setDisabled(false);
    }
  }, [checked, disabled, question?.score]);

  return (
    <Switch
      onChange={handleChange}
      disabled={disabled || unauthorized}
      value={checked}
    />
  );
};
