/**
 * SelectEcommercePlatform
 */

import React, { forwardRef } from 'react';
import { EcommercePlatform } from '../../features/entitiesRedux';
import { useEcommercePlatforms } from '../../utils';
import Select, { Props as SelectProps } from '../Select/Select';

type Props = SelectProps & {
  valueKey?: keyof EcommercePlatform;
}

const SelectEcommercePlatform = forwardRef(({ skeleton, valueKey = 'id', ...props }: Props, ref: any) => {
  const { ecommercePlatforms, loading } = useEcommercePlatforms();

  return (
    <Select
      showSearch
      optionFilterProp="children"
      skeleton={loading || skeleton}
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      {...props}
    >
      {ecommercePlatforms.map((ecommercePlatform: EcommercePlatform) => (
        <Select.Option key={ecommercePlatform.id} value={ecommercePlatform[valueKey as keyof EcommercePlatform]}>
          {ecommercePlatform.name}
        </Select.Option>
      ))}
    </Select>
  );
});

SelectEcommercePlatform.displayName = 'SelectEcommercePlatform';
export default SelectEcommercePlatform;
