import React from 'react';
import css from './NonPercentageValue.module.scss';

export interface NonPercentageValueType {
  value: number,
  valueType?: string,
}
export const NonPercentageValue = ({
  value,
  valueType,
}: NonPercentageValueType): JSX.Element => {

  return (
    <div className={css.parent}>
      {valueType === '#' ? <div className={css.dollarValue}>{value} yrs</div> : <div className={css.dollarValue}>{valueType}{value}</div>}
    </div>
  )
}
