import React from 'react';
import { scoreToLabel } from 'api/utils';
import classNames from 'classnames';
import { AppraisalScoreStatusTag } from 'features/audits/components';
import { getDepartmentStatus } from 'features/audits/utils';
import { AuditsSurveyStatus, Department } from 'features/entitiesRedux';
import { Popover } from 'components';
import { ANALYST_SURVEY_STATUS_TYPES } from '../../../../auditsSlice';
import { Placement } from '../ScoreBreakdownPopover';
import css from '../ScoreBreakdownPopover.module.scss';
interface AnalystScoreProps {
  departments?: Department[];
  analystScore?: number;
  analystScoreMax?: number;
  children?: React.ReactNode;
  placement: Placement;
  surveyStatus?: AuditsSurveyStatus | ANALYST_SURVEY_STATUS_TYPES;
}

const AnalystScore = ({
  departments,
  analystScore,
  analystScoreMax,
  children,
  placement = 'bottom',
  surveyStatus,
}: AnalystScoreProps): JSX.Element => {
  const departmentScores = departments?.map((dept: Department) => dept.score);
  const appraisalSurveyStatus = surveyStatus || ANALYST_SURVEY_STATUS_TYPES.PENDING;
  return (
    <Popover
      title={
        <div className={css.scoreDept}>
          <strong className={css.scoreDeptName} style={{ verticalAlign: 'middle' }}>
            Analyst Score
          </strong>
          <AppraisalScoreStatusTag type="analyst-score" departments={departments} surveyStatus={appraisalSurveyStatus} />
        </div>
      }
      content={
        <div className={css.scoreDept}>
          <ul className={css.scoreList}>
            {departments?.length ?
              departments?.map((dept: Department, index: number) => {
                const departmentStatus = getDepartmentStatus(dept) || 'pending';
                return (
                  <li
                    className={css.scoreListItem}
                    key={`score-${dept?.name}-${index}`}
                  >
                    <span className={css.scoreName}>{dept?.name}</span>
                    <span className={css.scoreValue}>
                      <span className={classNames(css.scoreValue_score, { [css[departmentStatus]]: true })}>
                        {departmentStatus === 'complete' ? dept?.score : ''}
                      </span>
                      <span className={classNames(css.scoreValue_max, { [css[departmentStatus]]: true })}>
                        /{dept?.max}
                      </span>
                    </span>
                  </li>
                );
              }) : <span className={css.emptyText}>Analyst survey does not have any channels yet</span>}
          </ul>
          {departmentScores?.length ?
            <div className={css.scoreFooter}>
              <span className={css.left}>Score:</span>
              <span className={classNames(css.right, { [css[appraisalSurveyStatus]]: true })}>
                <span className={classNames(
                  css.score,
                  scoreToLabel({ score: analystScore, max: analystScoreMax })
                )}>
                  {analystScore || ''}</span>
                <span className={css.total}>/{analystScoreMax}</span>
              </span>
            </div>
            : <></>
          }
        </div>
      }
      trigger={'hover'}
      placement={placement}
      arrowPointAtCenter
    >
      {children}
    </Popover>
  );
};

export default AnalystScore;
