/**
 * Update task
 */

import { Projection } from 'api/entityGraphQL';
import { Strategy } from 'features/entitiesRedux';
import {baseApi} from '../baseApi';
import {UpdateStrategyArgs} from '../strategies/strategyArgs';
import {cleanArguments} from '../utils';
import tagTypes from './tagTypes';


export type UpdateTaskMutation = {
  projection?: Projection;
} & UpdateStrategyArgs;

export type UpdateTaskResult = Strategy;

export const UPDATE_TASK_ENDPOINT_KEY = 'updateTask';

export const { useUpdateTaskMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [UPDATE_TASK_ENDPOINT_KEY]: build.mutation<UpdateTaskResult, UpdateTaskMutation>({
      transformResponse: (response: { updateTask: Strategy }) => response.updateTask,
      invalidatesTags: result => [
        result?.id ? { id: result.id, type: tagTypes.TASK } : tagTypes.TASK,
        tagTypes.TASKS,
      ],
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<UpdateTaskMutation> = {
          ...values
        };
        return {
          body: {
            mutation: {
              updateTask: {
                __args: cleanArguments(__args),
                ...projection,
                id: true
              }
            }
          }
        };
      }
    })
  })
});
