/**
* Get business types
*/

import { Projection } from 'api/entityGraphQL/types';
import { BusinessType } from 'features/entitiesRedux/models/types';
import { baseApi } from '../';
import tagTypes from './tagTypes';

export type GetBusinessTypesQuery = {
  projection: Projection;
};

export type GetBusinessTypesResult = BusinessType[];

export const GET_BUSINESS_TYPES_ENDPOINT_KEY = 'getBusinessTypes';

export const { useGetBusinessTypesQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_BUSINESS_TYPES_ENDPOINT_KEY]: build.query<GetBusinessTypesResult, GetBusinessTypesQuery>({
      keepUnusedDataFor: 60 * 60 * 24,
      transformResponse: (response: { business_types: GetBusinessTypesResult }) => response.business_types,
      providesTags: (business_types = []) => [
        tagTypes.BUSINESS_TYPE_LIST,
        ...business_types.map(businessType => ({
          id: businessType.id,
          type: tagTypes.BUSINESS_TYPE
        }))
      ],
      query: ({
        projection = { id: true, name: true },
      }) => ({
        body: {
          query: {
            business_types: {
              ...projection
            },
          }
        }
      })
    }),
  }),
});
