import React, { memo } from 'react';
import { EmptyState } from '@sprnova/nebula';

/**
 * ExternalClientMissingDataRequests component displays an empty state
 * message and provides a button to contact Customer Success via email.
 *
 * @returns {JSX.Element} A React component that renders an empty state message
 * with a contact button.
 */
const ExternalClientMissingDataRequests = () => {
  const handleClick = () => {
    window.location.href = 'mailto:novacs@powerdigital.com';
  };

  return (
    <>
      <EmptyState
        size='large'
        title="You don't have any access requests"
        description='This is where the Customer Success team will request your help integrating your data platforms. If you have any questions, reach out to Customer Success.'
        buttonProps={{
          children: 'Contact Customer Success',
          onClick: handleClick,
          style: { fontSize: '0.82rem', padding: '0 13px', height: '35px'}
        }}
      />
    </>
  );
};

export default memo(ExternalClientMissingDataRequests);
