import React, { forwardRef } from 'react';
import { Radio as AntdRadio } from 'antd';
import { RadioProps } from 'antd/lib/radio';
import classNames from 'classnames';
import { SkeletonInput } from '../SkeletonInput';
import css from './Radio.module.scss';

interface Props extends RadioProps {
  className?: string;
  skeleton?: boolean;
}

const Radio = forwardRef(
  ({ className, skeleton = false, ...props }: Props, ref: any) => {
    // Show skeleton when loading is true
    if (skeleton) return <SkeletonInput loading={false} active={true} />;

    return (
      <AntdRadio
        {...props}
        className={classNames(className, css.root)}
        ref={ref}
      />
    );
  }
);

Radio.displayName = 'Radio';

export default Radio;
