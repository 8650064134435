import React, {FC} from 'react';

interface SaveIconProps {
  height?: string;
  width?: string;
}

const SaveIcon: FC<SaveIconProps> = ({ height = '1em', width = '1em' }) => (
  <svg
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="M6.3 19.4992C5.8 19.4992 5.375 19.3242 5.025 18.9742C4.675 18.6242 4.5 18.1992 4.5 17.6992V14.9992H6V17.6992C6 17.7659 6.03333 17.8326 6.1 17.8992C6.16667 17.9659 6.23333 17.9992 6.3 17.9992H17.7C17.7667 17.9992 17.8333 17.9659 17.9 17.8992C17.9667 17.8326 18 17.7659 18 17.6992V14.9992H19.5V17.6992C19.5 18.1992 19.325 18.6242 18.975 18.9742C18.625 19.3242 18.2 19.4992 17.7 19.4992H6.3ZM12 15.6242L7.725 11.3492L8.775 10.2492L11.25 12.7242V4.32422H12.75V12.7242L15.225 10.2492L16.275 11.3492L12 15.6242Z" fill="#4A1FFF"/>
    </g>
  </svg>
);

export default SaveIcon;
