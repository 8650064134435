/**
 * SelectChannel
 */

import React, { forwardRef, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { uniq } from 'lodash';
import { Divider, Input } from 'components';
import Select, { Props as SelectProps } from '../../../../../../../../../../components/Select/Select';
import { useChannels } from '../../../../../../../../../../utils';
import { Channel } from '../../../../../../../../../entitiesRedux';

interface Props extends SelectProps {
  discoveryChannels: Channel[];
}

let index = 1;

const SelectChannel = forwardRef(({ skeleton, discoveryChannels, ...props }: Props, ref: any) => {
  const { channels, loading } = useChannels();

  // get the default channel options from the channels table
  const defaultChannelNames = channels?.map(channel => channel?.name);
  // get the discovery_channel names for this discovery
  const discoveryChannelNames = discoveryChannels?.map(channel => channel?.name);
  // combine both arrays and de-dupe to get our final list for the dropdown
  const channelNameOptions = uniq([...defaultChannelNames, ...discoveryChannelNames]);

  // local state variables for custom input and tracking the dropdown options
  const [customInputName, setCustomInputName] = useState<string>();
  const [items, setItems] = useState<Array<string | null>>([]);

  useEffect(() => {
    if (!loading) setItems(channelNameOptions);
  }, [loading]);

  const addItem = () => {
    setItems([...items, customInputName || `Other channel ${index++}`]);
    setCustomInputName('');
  };

  return (
    <Select
      allowClear
      showArrow
      skeleton={loading || skeleton}
      optionFilterProp="children"
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      dropdownRender={menu => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
            <Input
              placeholder="Enter channel name"
              style={{ flex: 'auto' }}
              value={customInputName}
              onChange={(e) => setCustomInputName(e.target.value)}
              onPressEnter={addItem}
              onKeyDown={e => e.stopPropagation()} // prevents removing selection when pressing delete key
            />
            <a
              style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
              onClick={addItem}
            >
              <PlusOutlined /> Add channel
            </a>
          </div>
        </div>
      )}
      {...props}
    >
      {items?.map(item => {
        if (item !== null) {
          return (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          );
        }
      })}
    </Select>
  );
});

SelectChannel.displayName = 'SelectChannel';
export default SelectChannel;
