import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  persistReducer,
  persistStore,
  createMigrate,
  PERSIST,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import storageSession from 'redux-persist/lib/storage/session';
import dashboardsReducer from 'features/am-dashboard/dashboardsSlice';
import auditsReducer from 'features/audits/auditsSlice';
import entitiesReducer from 'features/entitiesRedux/entitiesSlice';
import globalReducer from 'features/global/globalSlice';
import reportsReducer from 'features/reports/reportsSlice';
import strategiesReducer from 'features/strategies/strategiesSlice';
import { version } from '../../package.json';
import { baseApi } from '../api/crudGraphQL/baseApi';
import { basePublicApi } from '../api/crudGraphQL/public';
import { migrations } from './migrations';
const persistVersion = Number(version.replace(/\./g,''));

const isDev = process.env.NODE_ENV === 'development';

const reducers = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  [basePublicApi.reducerPath]: basePublicApi.reducer,
  entities: persistReducer(
    {
      key: 'entities',
      storage,
      blacklist: ['isLoading', 'error'],
      migrate: createMigrate(migrations.entities),
      debug: isDev,
      version: persistVersion, // Increment to run migration (also add version to migrations.ts)
    },
    entitiesReducer
  ),
  global: persistReducer(
    {
      key: 'global',
      blacklist: ['account'],
      storage,
    },
    globalReducer
  ),
  audits: persistReducer(
    {
      key: 'audits',
      storage,
    },
    auditsReducer
  ),
  reports: persistReducer(
    {
      key: 'reports',
      storage,
    },
    reportsReducer
  ),
  strategies: persistReducer(
    {
      key: 'strategies',
      storage,
    },
    strategiesReducer
  ),
  dashboards: persistReducer(
    {
      key: 'dashboards',
      storage,
    },
    dashboardsReducer
  ),
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: {
        warnAfter: 100,
      },
      serializableCheck: {
        ignoredActions: [PERSIST],
      },
    }).concat(baseApi.middleware)
      .concat(basePublicApi.middleware),
});

// Enables refetchOnFocus/refetchOnReconnect behaviors for RTK queries
setupListeners(store.dispatch);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;
