/**
* Save department revision
* 
* Saves and publishes a department revision (and increments the revision number)
*/

import { baseApi } from 'api/crudGraphQL';
import { Projection } from 'api/entityGraphQL/types';
import { Department } from 'features/entitiesRedux/models/department/department';
import { updateGetDepartmentCache } from './getDepartment';

export type SaveDepartmentRevisionMutation = {
  id: number;
  projection: Projection;
}

export type SaveDepartmentRevisionResult = Department;

export const { useSaveDepartmentRevisionMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    saveDepartmentRevision: build.mutation<SaveDepartmentRevisionResult, SaveDepartmentRevisionMutation>({
      transformResponse: (response: { saveDepartmentRevision: Department }) =>  response.saveDepartmentRevision,
      query: ({ projection = {}, id }) => (
        {
          body: {
            mutation: {
              saveDepartmentRevision: {
                __args: {
                  department_id: id
                },
                ...projection,
                id: true
              }
            }
          }
        }
      ),
      // Update any getDepartment query cache that matches the arguments of this mutation (id and projection)
      onQueryStarted: updateGetDepartmentCache
    }),
  }),
});
