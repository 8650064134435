import React from 'react';
import loadable from '@loadable/component';
import { Skeleton } from 'antd';
import { useGetPlaybookAdsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookAds';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';

const CreativePlaybookAds = loadable(
  () => import('./CreativePlaybookAds'),
  {
    fallback: <PageSkeleton />
  }
);

const Title = (): JSX.Element => {
  const { data: playbookAdsData, isLoading } = useGetPlaybookAdsQuery({
    projection: {id: true}
  }, {refetchOnMountOrArgChange: true});

  if (!playbookAdsData || isLoading) {
    return <Skeleton title={{ width: 200 }} paragraph={false} active />;
  }

  return <>Ads</>;

};

Title.displayName = 'CreativePlaybookAdsTitle';

export default Object.assign(CreativePlaybookAds, { Title });
