import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Projection,
  Pagination,
  createClientMutation,
  updateClientMutation,
  deleteClientMutation,
  novaGraphQLClient,
  createClientAccountMutation,
  createClientIntegrationReminderMutation,
  createClientIntegrationRequestMutation,
  deleteClientAccountMutation,
  updateClientAccountMutation,
  updateClientIntegrationRequestMutation,
  fetchClientsQuery,
  fetchClientByIdQuery,
} from 'api/entityGraphQL';
import { normalizeEntities } from '../../utils/normalizeEntities';
import {
  ActionFetchManyPaginatedConfig,
  ActionFetchPaginatedResponse,
  ActionFetchOneConfig,
} from '../types';
import { User } from '../user/user';
import { Client, CreateClientIntegrationApiParameters, CreateClientIntegrationReminderApiParameters, CreateClientIntegrationRequestApiParameters, CreateClientUserApiParameters, DeleteClientUserApiParameters, UpdateClientIntegrationApiParameters, UpdateClientIntegrationRequestApiParameters } from './client';
import { selectClientById } from './selectors';

/** Fetch many */
export type FetchClientsFilter = {
  id?: number;
  client_ids?: number[];
  name?: string;
  author_id?: number;
  account_manager_id?: number;
  executive_sponsor_id?: number;
  business_type_id?: number;
  business_type_ids?: number[];
  timezone_id?: number;
  industry_ids?: number[];
  salesforce_client_id?: number;
  salesforce_client_name?: string;
  tier_id?: number;
  status?: string;
};
type FetchClientsPaginatedConfig = ActionFetchManyPaginatedConfig & {
  filter?: FetchClientsFilter;
  projection?: Projection;
  pagination?: Pagination;
};
export const fetchClientsPaginated = createAsyncThunk(
  'entities/fetchClientsPaginated',
  async ({
    filter: {
      id,
      client_ids,
      name,
      author_id,
      account_manager_id,
      executive_sponsor_id,
      business_type_id,
      business_type_ids,
      timezone_id,
      industry_ids,
      salesforce_client_id,
      salesforce_client_name,
      tier_id,
      status
    } = {},
    projection,
    pagination,
  }: FetchClientsPaginatedConfig): Promise<ActionFetchPaginatedResponse> => {
    const { clients, ...rest } = await fetchClientsQuery({
      args: {
        id,
        client_ids,
        name: name ? `*${String(name).replace(/\s+/g, '*')}*` : undefined,
        author_id,
        account_manager_id,
        executive_sponsor_id,
        business_type_id,
        business_type_ids,
        timezone_id,
        industry_ids,
        salesforce_client_id,
        salesforce_client_name,
        tier_id,
        status
      },
      projection,
      pagination,
    });
    return {
      ...normalizeEntities({ clients }),
      pagination: rest,
    };
  }
);

/** Fetch one */
export const fetchClientById = createAsyncThunk(
  'entities/fetchClientById',
  async ({ id, projection }: ActionFetchOneConfig) => {
    const { clients } = await fetchClientByIdQuery(id, {
      projection,
    });
    return { ...normalizeEntities({ clients }) };
  }
);

/** Update */
export const updateClient = createAsyncThunk(
  'entities/updateClient',
  async (client: Partial<Client> & { id: number }) => {
    const response = await updateClientMutation({
      id: client.id,
      name: client.name,
      legal_name: client.legal_name,
      website: client.website,
      status: client.status,
      business_type_id: client?.business_type_id,
      account_manager_id: client?.account_manager_id,
      executive_sponsor_id: client?.executive_sponsor_id,
      organization_id: client?.organization_id,
      industry_id: client?.industry_id,
      lead_source_id: client?.lead_source?.id,
      salesforce_client_id: client?.salesforce_client_id,
      netsuite_id: client?.netsuite_id,
      tier_id: client?.tier_id,
    });
    return { ...normalizeEntities(response) };
  }
);

/** Create */
export const createClient = createAsyncThunk(
  'entities/createClient',
  async (client: Partial<Client> & { name: string }) => {
    const response = await createClientMutation({
      name: client.name,
      legal_name: client.legal_name,
      website: client.website,
      logo: client.logo,
      account_manager_id: client.account_manager_id,
      executive_sponsor_id: client.executive_sponsor_id,
      organization_id: client?.organization_id,
      status: client.status,
      business_type_id: client?.business_type_id,
      industry_id: client?.industry_id,
      lead_source_id: client?.lead_source?.id,
      salesforce_client_id: client?.salesforce_client_id,
      netsuite_id: client?.netsuite_id,
      tier_id: client?.tier_id,
    });
    return { ...normalizeEntities(response) };
  }
);

/** Delete */
export const deleteClient = createAsyncThunk(
  'entities/deleteClient',
  async (id: number) => {
    const response = await deleteClientMutation(id);
    return { ...normalizeEntities(response) };
  }
);

/** Delete client user */
export const deleteClientUser = createAsyncThunk(
  'entities/deleteClientUser',
  async ({
    client_id,
    user_id
  }: DeleteClientUserApiParameters,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { getState }: { getState: any }
  ) => {
    await novaGraphQLClient.deleteClientUser({
      client_id,
      user_id
    });

    const client = selectClientById(client_id)(getState());

    const updatedClient = {
      id: client_id,
      users: client.users.filter((user: User) => user.id !== user_id)
    };

    return { ...normalizeEntities({ clients: [updatedClient] }) };
  }
);

/** Create client user */
export const createClientUser = createAsyncThunk(
  'entities/createClientUser',
  async ({
    type_of_email,
    client_id,
    email,
    name,
    is_pdm_employee,
    data_source_ids
  }: CreateClientUserApiParameters,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { getState }: { getState: any }
  ) => {
    const user = await novaGraphQLClient.createClientUser({
      type_of_email,
      client_id,
      email,
      name,
      is_pdm_employee,
      data_source_ids
    });

    const client = selectClientById(client_id)(getState());
    const updatedClient = {
      id: client_id,
      users: client?.users?.concat(user as User)
    };

    normalizeEntities({ users: [user] });

    return { ...normalizeEntities({ clients: [updatedClient] }) };
  }
);

/** Create client integration request reminder */
export const createClientIntegrationReminder = createAsyncThunk(
  'entities/createClientIntegrationReminder',
  async ({
    client_id,
  }: CreateClientIntegrationReminderApiParameters
  ) => {
    const apiCallStatus = await createClientIntegrationReminderMutation({
      client_id,
    });

    return apiCallStatus;
  }
);

/** Create client integration request */
export const createClientIntegrationRequest = createAsyncThunk(
  'entities/createClientIntegrationRequest',
  async ({
    client_id,
    data_source_id,
    requested_user_id
  }: CreateClientIntegrationRequestApiParameters
  ) => {
    const client = await createClientIntegrationRequestMutation({
      client_id,
      data_source_id,
      requested_user_id
    });

    return { ...normalizeEntities({ clients: [client] }) };
  }
);

/** Update client integration request */
export const updateClientIntegrationRequest = createAsyncThunk(
  'entities/updateClientIntegrationRequest',
  async ({
    id,
    status,
    completed_by
  }: UpdateClientIntegrationRequestApiParameters
  ) => {
    const client = await updateClientIntegrationRequestMutation({
      id,
      status,
      completed_by
    });

    normalizeEntities({ external_clients: [client] });
    return { ...normalizeEntities({ clients: [client] }) };
  }
);


/**
 * Client Account mutations. (Client Integrations)
 */

export const createClientAccount = createAsyncThunk(
  'entities/createClientAccount',
  async ({
    client_id,
    account_id,
    url,
    value2,
    auth_method,
    data_source_account_id,
    data_q_data,
    is_created_by_client,
    created_by
  }: CreateClientIntegrationApiParameters,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { getState }: { getState: any }
  ) => {
    const clientIntegration = await createClientAccountMutation({
      client_id,
      account_id,
      data_source_account_id,
      url,
      value2,
      auth_method,
      data_q_data,
      is_created_by_client,
      created_by,
    });

    const client = selectClientById(client_id)(getState());
    const updatedClient = {
      id: client_id,
      accounts: client?.accounts?.concat(clientIntegration)
    };

    normalizeEntities({ clients: [updatedClient] });

    return clientIntegration;
  }
);

export const updateClientAccount = createAsyncThunk(
  'entities/updateClientAccount',
  async ({
    client_id,
    id,
    url,
    data_source_account_id,
    value2,
    grant_pdm_access,
    page_access_token,
    meta_page_id
  }: UpdateClientIntegrationApiParameters,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { getState }: { getState: any }
  ) => {
    const clientIntegration = await updateClientAccountMutation({
      id,
      url,
      data_source_account_id,
      value2,
      grant_pdm_access,
      page_access_token,
      meta_page_id
    });

    const client = selectClientById(client_id as number)(getState());
    const updatedClient = {
      id: client_id,
      accounts: client?.accounts.filter((integration) => integration?.id !== clientIntegration?.id).concat(clientIntegration)
    };

    normalizeEntities({ clients: [updatedClient] });

    return clientIntegration;
  }
);

export const deleteClientAccount = createAsyncThunk(
  'entities/deleteClientAccount',
  async ({
    id,
    client_id
  }: {
    id: number;
    client_id: number;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { getState }: { getState: any }
  ) => {
    const response = await deleteClientAccountMutation({
      id,
    });

    const client = selectClientById(client_id as number)(getState());
    const updatedClient = {
      id: client_id,
      accounts: client?.accounts.filter((integration) => integration?.id !== id)
    };

    normalizeEntities({ clients: [updatedClient] });

    return response;
  }
);
