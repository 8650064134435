import isEqual from 'lodash/isEqual';

/**
   * Handle if a cell should update
   *
   * Ant design table is slow due to rerendering on every change
   * even if the cell didn't change
   *
   * By passing shouldCellUpdate, we can control when a cell should update
   * and improve performance
   *
   * We can compare the current record with the previous one
   * using lodash isEqual to perform a deep compare on the objects
   * and their nested properties
   *
   * If they have changed, then update the cell
   * docstring
   * @param curr {} current record
   * @param prev {} previous record
   * @returns boolean if should update the cell
   */
const shouldCellUpdate = (curr: any, prev: any) => {
  // only re-render if the new record is different from the previous one
  // do a deep compare here for nested objects using lodash isEqual
  return isEqual(curr, prev) === false;
};

export default shouldCellUpdate;
