import React, { forwardRef, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import { GetClientsQuery, useGetClientsQuery } from 'api/crudGraphQL/clients/getClients';
import { Projection } from 'api/entityGraphQL/types';
import classNames from 'classnames';
import { Button } from 'components/Button';
import { Client } from 'features/entitiesRedux/models/client/client';
import Select, { Props as SelectProps } from '../Select/Select';
import css from './SelectClient.module.scss';

export interface SelectClientProps extends SelectProps {
  clientData?: Client[];
  onSelectClient?: (client: Partial<Client>) => void;
  notFoundText?: {
    description: React.ReactNode;
    buttonText: string;
  }
  projection?: Projection;
  args?: Partial<GetClientsQuery>;
}

type EmptyClientProps = {
  name: string;
  notFoundText?: {
    description: React.ReactNode;
    buttonText: string;
  }
};

const EmptyClient = ({ name, notFoundText }: EmptyClientProps) => {
  const location = useLocation();
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={notFoundText?.description ? notFoundText.description : <span>No company found with that name</span>}
    >
      <Link to={`/power-view/accounts/new?name=${name}&redirect=${location?.pathname}`}>
        <Button type="primary">{notFoundText?.buttonText ? notFoundText.buttonText : 'Create Client'}</Button>
      </Link>
    </Empty>
  );
};

const SelectClient = forwardRef(({
  skeleton,
  className,
  clientData,
  onSelectClient,
  notFoundText,
  projection,
  args,
  ...props
}: SelectClientProps, ref: any) => {
  /**
   * RTK fetch client
   * Skips if clientsData is passed as prop
   */
  const { data: clientsRequest, isLoading, error } = useGetClientsQuery({
    limit: 9999,
    projection: { id: true, name: true },
    /** Override default limit and projection with passed args */
    ...(args ?? {}),
  });

  const clients = useMemo(() => {
    if (clientsRequest?.data) {
      return clientsRequest.data;
    }
    return [];
  }, [clientsRequest]);

  const [name, setName] = useState('');

  const clientsValues = clientData ? clientData : clients;

  function onSelect(value: any | undefined) {
    const client = clients.find((client) => {
      return client.id === Number.parseInt(
        props.labelInValue ? value.value : value,
        10
      );
    });
    if (onSelectClient && client !== undefined) {
      return onSelectClient(client);
    }
  }

  return (
    <Select
      suffixIcon={<SearchOutlined />}
      showSearch
      optionFilterProp='children'
      skeleton={isLoading || skeleton}
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props}
      ref={ref}
      onSelect={onSelect}
      className={classNames(css.root, className)}
      notFoundContent={<EmptyClient name={name} notFoundText={notFoundText} />}
      onSearch={(value) => setName(value)}
    >
      {(clientsValues as (Partial<Client> & { id: number })[])
        .filter((client) => client.id !== undefined)
        .map((client) => (
          <Select.Option key={client.id} value={client.id}>
            {client.name}
          </Select.Option>
        ))
      }
    </Select>
  );
}
);

SelectClient.displayName = 'SelectClient';

export default SelectClient;
