import React, { memo } from 'react';
import { Box, Typography } from '@sprnova/nebula';

export type GoalTitleProps = {
  title: string;
  date: string;
};
const GoalTitle = ({ title, date }: GoalTitleProps): JSX.Element => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
    >
      <Typography variant='h3'>
        {title}
      </Typography>
      <Typography variant='caption'>
        {date}
      </Typography>
    </Box>
  );
};

export default memo(GoalTitle);
