/**
 * Strategies -> Proposal Preview Page
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  FileTextOutlined,
  FileSyncOutlined,
  FileAddOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { Container } from '@sprnova/nebula';
import { Typography } from 'antd';
import classNames from 'classnames';
import { format } from 'date-fns';
import { PageHero } from 'layouts/components';
import useSalesforceOpportunities from 'utils/hooks/useSalesforceOpportunities';
import {
  useStrategyByParamId,
  selectIsLoading,
  Strategy,
  Department,
  Service,
} from 'features/entitiesRedux';
import { useAccount } from 'features/global';
import {
  Button,
  Skeleton,
  NoResultsFound,
  Collapse,
  CollapsePanel,
} from 'components';
import { GenerateProposalButton, SalesforceStageSyncModal } from './components';
import { getSalesforceOpportunityStage } from '../utils';
import css from './StrategyProposalPage.module.scss';

export const formatMonths = (months?: Strategy['months']): JSX.Element | string => {
  if (!months) return '';
  const monthsString = months
    .filter((month) => month?.hours && month.hours > 0)
    .map((month) =>
      month?.date ? format(new Date(month?.date), 'MMM') : undefined
    )
    .filter((month) => !!month)
    .join(', ');
  return monthsString;
};

export const validOpportunityStages = [
  'Meeting Set',
  'Qualified/Access',
  'Appraisal',
];

const StrategyProposalPage = () => {
  const history = useHistory();
  const isLoading = useSelector(selectIsLoading);
  const strategy = useStrategyByParamId();
  const editUrl = strategy?.proposal_url;
  const hasProposalDocument = !!editUrl;
  const { account } = useAccount();

  /** If blueprint is linked to a salesforce opportunity then get that opportunity's stage */
  const { salesforce_opportunities } = useSalesforceOpportunities(strategy?.client?.salesforce_client_id);
  const opportunityStage = getSalesforceOpportunityStage(salesforce_opportunities, strategy?.salesforce_opportunity_id);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  /**
   * Show the Salesforce modal if ALL of the following conditions are true:
   - 1. Blueprint is linked to a Salesforce opportunity
   - 2. Blueprint is set as primary deal for the Salesforce opportunity
   - 3. Blueprint's status is either "Open Forecasted" or "Open Not Forecasted"
   - 4. Current Salesforce Opportunity stage is found in the validOpportunityStages array
   */
  const showSalesforceModal =
    typeof strategy.salesforce_opportunity_id === 'string' &&
    strategy.salesforce_opportunity_id.length > 0 &&
    strategy.salesforce_is_primary &&
    (strategy.status === 'open' || strategy.status === 'not-forecasted') &&
    !!opportunityStage && validOpportunityStages.indexOf(opportunityStage) > -1;

  const [salesforceModalVisible, setSalesforceModalVisible] = useState<boolean>(showSalesforceModal);

  useEffect(() => {
    if (showSalesforceModal) {
      setSalesforceModalVisible(true);
    } else {
      setSalesforceModalVisible(false);
    }
  }, [showSalesforceModal]);

  if (isLoading && !strategy) {
    return <Skeleton />;
  }

  type TasksListProps = { serviceId: number };
  const TasksList = ({ serviceId }: TasksListProps) => (
    <>
      <Collapse ghost>
        {strategy?.tasks
          ?.filter(
            (task: Strategy['tasks'][0]) => task?.service?.id === serviceId
          )
          .map((task: Strategy['tasks'][0], i: number) => {
            return (
              <CollapsePanel
                header={
                  <div>
                    <h4 className={css.taskTitle}>{task.name}</h4>
                  </div>
                }
                key={`task-panel-${i}`}
              >
                <Typography.Text className={css.collapseSubtitle}>
                  {formatMonths(task?.months)}
                </Typography.Text>
                <div className={css.snippetWrapper}>
                  <div className={'dangerouslySetInnerHTML'} dangerouslySetInnerHTML={{ __html: task.snippet }} />
                </div>
              </CollapsePanel>
            );
          })}
      </Collapse>
    </>
  );

  type ServiceListProps = { departmentId: number };
  const ServiceList = ({ departmentId }: ServiceListProps) => (
    <Collapse ghost>
      {strategy?.service_summary
        ?.filter((service: Service) => service?.department?.id === departmentId)
        .map((service: Service, i: number) => {
          return (
            <CollapsePanel
              header={
                <>
                  <h3 className={css.serviceTitle}>{service?.name}</h3>
                </>
              }
              key={`service-panel-${i}`}
            >
              <div>
                <Typography.Text
                  className={classNames(
                    css.collapseSubtitle,
                    css.serviceSubtitle
                  )}
                >
                  {formatMonths(service?.months)}
                </Typography.Text>
                <TasksList serviceId={service?.id} />
              </div>
            </CollapsePanel>
          );
        })}
    </Collapse>
  );

  const DepartmentsList = () => (
    <>
      {strategy?.department_summary?.filter?.((department: Department) => department?.name !== 'AM' && department?.name !== 'ES')
        .map((department: Department) => {
          return (
            <div
              className={css.departmentItem}
              key={`department-${department?.id}`}
            >
              <h2 className={css.departmentTitle}>{department?.name}</h2>
              <Typography.Text
                className={classNames(
                  css.collapseSubtitle,
                  css.departmentSubtitle
                )}
              >
                {formatMonths(department?.months)}
              </Typography.Text>
              <div>
                <ServiceList departmentId={department.id} />
              </div>
            </div>
          );
        })}
    </>
  );

  return (
    <>
      <PageHero
        title="Proposal Preview"
        description={strategy?.name}
        end={
          hasProposalDocument
            ? [
              <GenerateProposalButton
                key="regenerate-proposal"
                type="text"
                header
                icon={<FileSyncOutlined />}
                strategyId={strategy.id}
                account={account}
                isButtonDisabled={isButtonDisabled}
                setIsButtonDisabled={setIsButtonDisabled}
                disabled={isButtonDisabled}
              >
                  Regenerate
              </GenerateProposalButton>,

              <Button
                type="text"
                header
                icon={<SendOutlined />}
                disabled
                key={'share'}
              >
                  Share
              </Button>,
              <a
                key="edit-proposal"
                target="_blank"
                rel="noopener noreferrer"
                href={editUrl}
              >
                <Button type="text" header icon={<FileTextOutlined />}>
                    Open Document
                </Button>
              </a>,
            ]
            : []
        }
      />
      <Container hasVerticalPadding>
        {hasProposalDocument ? (
          <DepartmentsList />
        ) : (
          <NoResultsFound
            title="No proposal document generated"
            renderButton={ <GenerateProposalButton
              type="primary"
              size="large"
              icon={<FileAddOutlined />}
              strategyId={strategy.id}
              onComplete={() => history.push(`/blueprints/${strategy.id}/proposal?newProposal=true`)}
              account={account}
              isButtonDisabled={isButtonDisabled}
              setIsButtonDisabled={setIsButtonDisabled}
              disabled={isButtonDisabled}
            >
              Generate
            </GenerateProposalButton>}
          />
        )}
        {showSalesforceModal ?
          <SalesforceStageSyncModal
            strategyId={strategy?.id}
            visible={salesforceModalVisible}
            setVisible={setSalesforceModalVisible}
            onCancel={() => setSalesforceModalVisible(false)}
            opportunityName={strategy?.salesforce_opportunity_name}
          /> : null
        }
      </Container>
    </>
  );
};

const Title = () => <>Proposal</>;

Title.displayName = 'StrategyProposalPageTitle';

export default Object.assign(StrategyProposalPage, { Title });
