/**
 * SelectPillar
 */

import React, { forwardRef } from 'react';
import { Pillar } from 'features/entitiesRedux';
import Select, { Props as SelectProps } from '../Select/Select';
import { GetPillarsQuery, useGetPillarsQuery } from 'api/crudGraphQL/pillars/getPillars';

interface SelectPillarProps extends SelectProps {
  args?: Partial<GetPillarsQuery>;
}

const SelectPillar = forwardRef(({ skeleton, args, ...props }: SelectPillarProps, ref: any) => {
  /**
   * RTK fetch pillars
   */
  const { data: pillars = [], isLoading } = useGetPillarsQuery({
    projection: { id: true, name: true },
    /** Override default projection with passed args */
    ...(args ?? {}),
  });

  return (
    <Select
      showSearch
      optionFilterProp="children"
      skeleton={isLoading || skeleton}
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      {...props}
    >
      {pillars.map((pillar: Pillar) => (
        <Select.Option key={pillar.id} value={pillar.id}>
          {pillar.name}
        </Select.Option>
      ))}
    </Select>
  );
});

SelectPillar.displayName = 'SelectPillar';

export default SelectPillar;
