/**
 * Get data sources
 */
import { Projection } from 'api/entityGraphQL';
import { basePublicApi } from '..';
import tagTypes from './tagTypes';
import { cleanArguments } from 'api/crudGraphQL/utils';
import { DataSource } from 'features/entitiesRedux';

export type GetDataSourcesPublicQuery = {
  is_visible?: boolean;
  projection?: Projection;
};

export type GetDataSourcesPublicResult = DataSource[];

export const GET_DATA_SOURCES_ENDPOINT_KEY = 'getDataSourcesPublic';

export const { useGetDataSourcesPublicQuery } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_DATA_SOURCES_ENDPOINT_KEY]: build.query<GetDataSourcesPublicResult, GetDataSourcesPublicQuery>({
      transformResponse: (response: { accounts: GetDataSourcesPublicResult }) => response.accounts,
      providesTags: (result) => {
        return [tagTypes.DATA_SOURCES];
      },
      query: ({
        is_visible = true,
        projection = { id: true },
        ...args
      }) => {
        const __args: Partial<GetDataSourcesPublicQuery> = args;

        return {
          body: {
            query: {
              accounts: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        };
      }
    })
  })
});
