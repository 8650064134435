import React from 'react';
import { Grid, Typography } from '@mui/material';
import NoDataIcon from 'components/Icon/svg/nebula/NoDataIcon';

export type NoDataForTimePeriodProps = {
  label: string
}

export const NoDataForTimePeriod = ({label}: NoDataForTimePeriodProps ):JSX.Element => {
  return (
    <Grid container justifyContent='space-evenly' style={{padding: '3% 0'}}>
      <Grid container component='div' display='flex' direction='column' justifyContent='center' style={{borderRadius: '50%', backgroundColor: '#EFEFEF', width: '361px', height: '361px'}}>
        <Grid container direction='column' alignContent='center' alignItems='center' style={{overflow: 'visible', overflowWrap: 'normal'}}>
          <NoDataIcon />
          <Typography component='h3' style={{font: 'Inter', fontWeight: 600, fontSize: '20px', whiteSpace: 'nowrap', paddingTop: '20px'}}>{`Sorry, we couldn’t find any ${label} results that matched your search.`}</Typography>
          <Typography component='p' style={{font: 'Inter', fontWeight: 400, fontSize: '14px', whiteSpace: 'nowrap', paddingTop: '20px'}}>Please try selecting a different time period.</Typography>
        </Grid>
      </Grid>
    </Grid>

  );
};
