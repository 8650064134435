import React, { useState, FC, memo, useMemo } from 'react';
import { Box, CaretOutlinedDownIcon, CaretOutlinedUpIcon, Collapse, IconButton, Stepper, Typography } from '@sprnova/nebula';
import { GetNovaChallengesResult } from 'api/crudGraphQL/nova_ai/getNovaChallenges';
import { NovaAiChallengeType } from 'features/entitiesRedux/models/nova_ai/novaAI';
import ChallengeMessage from '../ChallengeMessage';
import css from './UsageStepper.module.scss';

interface UsageStepperProps {
    steps: GetNovaChallengesResult;
    currentStep?: NovaAiChallengeType;
}

const UsageStepper: FC<UsageStepperProps> = ({ steps, currentStep }) => {
  const [stepperCollapsed, setStepperCollapsed] = useState(false);
  const handleCollapse = (): void => {
    setStepperCollapsed((prev) => !prev);
  };
  const stepLimits = useMemo(() => steps.map((step) => <>+ {step.question_limit}</>), [steps]);
  const stepLabels = useMemo(() => [<>Unlocked novaGPT</>, ...stepLimits], [stepLimits]);
  const challengeProgress = useMemo(
    () => currentStep ?? (steps.length > 0 ? steps[steps.length - 1] : undefined),
    [currentStep, steps]
  );
  const activeStepIndex = useMemo(
    () => challengeProgress ? (currentStep ? steps.indexOf(challengeProgress) + 1 : steps.length + 1) : 0,
    [challengeProgress, currentStep, steps]
  );
  return (
    <Box className={css.root} sx={{ backgroundColor: '#e6f4fa', padding: '20px' }}>
      {challengeProgress &&
      <>
        <Box sx={{ textAlign: 'center' }}>
          {challengeProgress.question_count >= challengeProgress.question_trigger ?
            <Typography sx={{ marginRight: '10px', display: 'inline' }}>
            You have used {challengeProgress.question_count}/{challengeProgress.question_limit} questions. {challengeProgress.question_count >= challengeProgress.question_trigger && <ChallengeMessage message={challengeProgress.message} htmlResponse={challengeProgress.action} useOuterHtml />}
            </Typography>
            :
            <Typography sx={{ marginRight: '10px', display: 'inline' }}>
            Welcome to the Insights AI Beta! You have {challengeProgress.question_count}/{challengeProgress.question_limit} questions.
            </Typography>
          }
          <IconButton size="xs" onClick={handleCollapse}>
            {stepperCollapsed ? <CaretOutlinedDownIcon /> : <CaretOutlinedUpIcon />}
          </IconButton>
        </Box>
        <Box pt={stepperCollapsed ? 0 : 2}>
          <Collapse in={!stepperCollapsed}>
            {!stepperCollapsed && challengeProgress ? <Stepper className={css.stepper} steps={stepLabels} activeStep={activeStepIndex} /> : <></>}
          </Collapse>
        </Box>
      </>
      }
    </Box>
  );
};

UsageStepper.displayName = 'UsageStepper';

export default memo(UsageStepper);
