import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFieldArrayReturn } from 'react-hook-form';
import { SelectChangeEvent } from '@mui/material';
import { Button, Controller, Grid, IconButton, PlusIcon, Select, TextField, TrashIcon, useFieldArray, useForm, Typography } from '@sprnova/nebula';
import { isEqual } from 'lodash';
import { InputDataItem, OutputDataItem } from '../FiltersDrawer/types';

type MetricItem = {
  id: string;
  label: string;
}

type FieldItemType = {
  id: string;
  metric?: string;
};

const MetricsFilter = ({
  onSubmit,
}: {
  onSubmit: (values: Record<string, any>) => void,
}): React.ReactElement => {
  const [operators, setOperators] = useState<string[]>([]);
  const sessionStorageName = 'intelligence:creative-affinity:metric-filters';

  const defaultObject = useMemo(() => ({
    metric: '',
    operator: '',
    value1: null,
    value2: null,
  }), []);

  const defaultValues = useMemo(() => ({
    metrics: [defaultObject],
    lastUpdated: Date.now(),  // add this field to trigger a dummy form update
  }), [defaultObject]);

  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues
  });

  const savedFormValues = useMemo(() => ({
    'metrics': JSON.parse(sessionStorage.getItem(sessionStorageName) || '[]'),
  }), []);

  const { append: appendMetricsFilter, remove: removeMetricsFilter, fields: metricsFields, update: updateMetricsFilter } = useFieldArray({
    control,
    name: 'metrics',
  });

  const metricItems: MetricItem[] = useMemo(() => [
    { id: 'spend', label: 'Spend' },
    { id: 'revenue', label: 'Revenue' },
    { id: 'conversions', label: 'Conversions' },
    { id: 'roas', label: 'ROAS' },
    { id: 'ltv', label: 'LTV' },
    { id: 'score', label: 'Affinity Score' },
    { id: 'cpa', label: 'CPA' },
  ], []);

  const operatorItems: MetricItem[] = useMemo(() => [
    { id: '>', label: 'Greater Than' },
    { id: '<', label: 'Below Than' },
    { id: 'between', label: 'In Between' },
  ], []);

  /**
 * Tries to save the filter to session storage.
 *
 * The function attempts to save a specified filter object to the session storage.
 * If an error occurs during the save operation, it clears the session storage and tries to save again.
 * If an error occurs again after clearing the session storage, the error is logged to the console.
 *
 * @param {Array<any>} keyValue An array containing the key and value to be stored.
 * @returns {void} This function does not return a value.
 */
  const saveFilterTryCatch = useCallback(([name, value]: any): void => {
    try {
      sessionStorage.setItem(name, value);
    } catch (error) {
      sessionStorage.clear();
      try {
        sessionStorage.setItem(name, value);
      } catch (error) {
        console.error('Error saving filter to session storage', error);
      }
    }
  }, []);

  const handleSaveFilter = useCallback((values: Record<string, any>) => {
    Array<string[]>(
      [sessionStorageName, JSON.stringify(values.metrics)],
    ).map(saveFilterTryCatch);
  }, [saveFilterTryCatch]);

  const convertToDecimal = useCallback((value: number): number => {
    return value / 10;
  }, []);

  const convertValuesToNumbers = useMemo(() => (data?: InputDataItem[]): OutputDataItem[] => {
    return (data ?? []).map(item => {
      if (item.metric === "score") {
        return {
          ...item,
          value1: item.value1 !== null ? convertToDecimal(Number(item.value1)) : null,
          value2: item.value2 !== null ? convertToDecimal(Number(item.value2)) : null
        };
      } else {
        return {
          ...item,
          value1: item.value1 !== null ? Number(item.value1) : null,
          value2: item.value2 !== null ? Number(item.value2) : null
        };
      }
    });
  }, [convertToDecimal]);

  useEffect(() => {
    /**
     * Watch for changes in the form, submit and save to local storage
     */
    const subscription = watch((value) => {
      const metrics = value.metrics;
      const newOperators = metrics?.map((item: any) => item.operator);
      setOperators(newOperators ?? []);
      handleSaveFilter(value);
      const convertedMetrics = convertValuesToNumbers(metrics as InputDataItem[] || []);

      const nonEmptyMetrics = convertedMetrics.filter((item: OutputDataItem) => !isEqual(item, defaultObject));

      onSubmit({
        general_metric_filters: nonEmptyMetrics,
      });
    });
    return () => subscription.unsubscribe();
  }, [defaultObject, defaultValues, onSubmit, watch, operators, handleSaveFilter]);

  const handleAdd = useCallback(() => {
    appendMetricsFilter(defaultObject);
  }, [appendMetricsFilter, defaultObject]);

  const handleRemove = useCallback((index: number) => {
    removeMetricsFilter(index);
    setOperators(operators =>
      operators.filter((_, idx) => idx !== index)
    );

    // Trigger a dummy form update to re-evaluate watch
    setValue('lastUpdated', new Date().getTime());
  }, [removeMetricsFilter, setValue]);

  const handleSetInitialValues = useCallback(() => {
    const formValues = savedFormValues['metrics'];

    if (formValues && Array.isArray(formValues) && formValues.length > 0) {
      // set from local storage if available
      setValue('metrics', formValues);
    } else {
      // otherwise set the default values
      setValue('metrics', defaultValues['metrics']);
    }
  }, [defaultValues, savedFormValues, setValue]);

  useEffect(() => {
    (async (): Promise<void> => {
      // form must be cleared before changing
      await removeMetricsFilter();
      handleSetInitialValues();
    })();
  }, [defaultValues, handleSetInitialValues, removeMetricsFilter, savedFormValues, setValue]);

  const renderOneTextField = useCallback(({ control, index }) => {
    return (
      <Grid item xs={6} sx={{ marginLeft: '24px' }}>
        <Controller
          control={control}
          name={`metrics.${index}.value1`}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Quantity"
              id={`value1-${field.name}`}
              type="number"
              error={!!error}
              helperText={error ? error.message : ''}
            />
          )}
        />
      </Grid>
    );
  }, []);

  const renderTwoTextField = useCallback(({ control, index }) => {
    return (
      <>
        <Grid item xs={4} sx={{ marginLeft: '24px' }}>
          <Controller
            control={control}
            name={`metrics.${index}.value1`}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Quantity"
                id={`value1-${field.name}`}
                type="number"
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sx={{ marginLeft: '24px' }}>
          <Controller
            control={control}
            name={`metrics.${index}.value2`}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Quantity"
                id={`value2-${field.name}`}
                type="number"
              />
            )}
          />
        </Grid>
      </>
    );
  }, []);

  const isItemSelectable = (item: { id: string, label: string }, metricFieldId: string, fields: FieldItemType[]): boolean => {
    // Check if the current field's metric matches the item.id or if it's empty.
    const currentField = fields.find(field => field.id === metricFieldId);
    const isSelectedInCurrentField = currentField?.metric === item.id;

    // Check if the item is not selected in any other field except the current field
    const isNotSelectedInOtherFields = fields.every(field =>
      field.id === metricFieldId ||
      field.metric !== item.id ||
      !field.metric
    );

    return isSelectedInCurrentField || isNotSelectedInOtherFields;
  };

  const renderFields = useCallback((fields: UseFieldArrayReturn['fields']) => {
    return (  // Explicit return statement
      <>
        {fields.map((field, index) => {
          const metricFieldId = field.id as string;
          return (
            <Grid container key={metricFieldId} spacing={2} sx={{ paddingBottom: '24px' }}>
              <Grid item xs={11} sx={{ padding: '24px 0' }}>
                <Controller
                  control={control}
                  name={`metrics.${index}.metric`}
                  render={({ field, fieldState: { error } }) => (
                    <Select
                      {...field}
                      id={`metric-${field.name}`}
                      label="Metric"
                      onChange={(event: SelectChangeEvent<unknown>) => {
                        if (typeof event.target.value === 'string') {
                          setValue(`metrics.${index}.metric`, event.target.value as never);
                          updateMetricsFilter(index, {
                            metric: event.target.value,
                            operator: getValues(`metrics.${index}.operator`),
                            value1: getValues(`metrics.${index}.value1`),
                            value2: getValues(`metrics.${index}.value2`),
                          });
                        }
                      }}
                    >
                      {metricItems
                        .filter(item => isItemSelectable(item, metricFieldId, fields))
                        .map(item => (
                          <Select.Item key={`metricItem_${item.id}`} value={item.id}>
                            {item.label}
                          </Select.Item>
                        ))}
                    </Select>
                  )}
                />
              </Grid>
              <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '24px 0' }}>
                <IconButton
                  size="md"
                  onClick={() => {
                    handleRemove(index);
                  }}
                >
                  <TrashIcon />
                </IconButton>
              </Grid>
              <Grid
                spacing={2}
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderLeft: '1px solid #6D6D6D',
                  padding: '16px 0 16px 32px',
                  marginLeft: '20px'
                }}>
                <Grid
                  item
                  xs={operators[index] === 'between' ? 4 : 6}
                  sx={{ paddingLeft: '0 !important' }}
                >
                  <Controller
                    control={control}
                    name={`metrics.${index}.operator`}
                    render={({ field, fieldState: { error } }) => (
                      <Select
                        {...field}
                        id={`operator-${field.name}`}
                        label="Rule"
                        onChange={(event: SelectChangeEvent<unknown>) => {
                          if (typeof event.target.value === 'string') {
                            setValue(`metrics.${index}.operator`, event.target.value as never);
                            setValue(`metrics.${index}.value1`, null);
                            setValue(`metrics.${index}.value2`, null);
                            updateMetricsFilter(index, {
                              metric: getValues(`metrics.${index}.metric`),
                              operator: event.target.value,
                              value1: getValues(`metrics.${index}.value1`),
                              value2: getValues(`metrics.${index}.value2`),
                            });
                          }
                        }}
                      >
                        {operatorItems.map((item) => (
                          <Select.Item key={`operatorItem_${item.id}`} value={item.id}>
                            {item.label}
                          </Select.Item>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
                {operators[index] !== 'between' && renderOneTextField({ control, index })}
                {operators[index] === 'between' && renderTwoTextField({ control, index })}
              </Grid>
            </Grid>
          );
        })}
      </>
    );
  }, [operators, control, renderOneTextField, renderTwoTextField, metricItems, setValue, handleRemove, operatorItems]);


  return (
    <div>
      <Typography variant='h4' margin='24px 0' component='div'>
        Metric Filter
      </Typography>
      {renderFields(metricsFields)}
      <Button
        fullWidth
        size="large"
        type="button"
        variant="secondary"
        startIcon={<PlusIcon />}
        onClick={handleAdd}
        disabled={getValues('metrics').length === 7}
      >
        Add new metric
      </Button>
    </div>
  );
};

export default MetricsFilter;
