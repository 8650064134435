/**
 * AddTasksModal -> ConfirmSelection
 */

import React, { FC, ReactNode } from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { NoResultsFound } from 'components/NoResultsFound';
import { Table } from 'components/Table';
import upperFirst from 'lodash/upperFirst';
import { Task } from 'features/entitiesRedux';
import css from './ConfirmSelection.module.scss';

type Props = {
  hasEmptyUnits?: boolean;
  loading?: boolean;
  tasks: Task[];
  onRemove: (task: Task) => void;
  onUpdate: (task: Task, units: number | string) => void;
};

const ConfirmSelection: FC<Props> = ({
  loading,
  tasks,
  onRemove,
  onUpdate,
  hasEmptyUnits,
}) => {
  if (!tasks.length) {
    return <NoResultsFound title="No tasks selected" />;
  }

  const renderUnitsField = (units: number, task: Task): ReactNode => {
    const isConfigurable = task.is_configurable === 'yes';
    return (
      <div className={css.units}>
        {isConfigurable ? (
          <Input
            type="number"
            className={css.units__field}
            disabled={loading}
            value={units}
            onChange={(e): void =>
              onUpdate(task, e.target.value ? parseInt(e.target.value, 10) : '')
            }
            addonAfter={upperFirst(task.unit_type || 'Hours')}
          />
        ) : (
          <span className={css.units__notConfigurable}>Not configurable</span>
        )}
      </div>
    );
  };

  const renderActions = (x: any, task: Task): ReactNode => (
    <Button
      className={css.removeButton}
      aria-label="Remove task"
      type="link"
      danger
      icon={<MinusCircleOutlined />}
      onClick={(): void => onRemove(task)}
      disabled={loading}
    />
  );

  const columns = [
    {
      title: 'Task',
      key: 'name',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: 'Hours',
      key: 'units',
      dataIndex: 'units',
      render: renderUnitsField,
    },
    {
      title: '',
      key: 'actions',
      dataIndex: 'actions',
      width: 60,
      render: renderActions,
    },
  ];

  return (
    <div className={css.root}>
      {hasEmptyUnits && (
        <Alert
          className={css.alert}
          message="Update units for configurable tasks to continue"
          type="info"
        />
      )}
      <Table
        rowKey="id"
        showHeader={false}
        expandable={{
          expandIcon: () => null,
        }}
        pagination={false}
        dataSource={tasks}
        columns={columns}
        className={css.table}
      />
    </div>
  );
};

export default ConfirmSelection;
