import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Card, CardContent, CardHeader, Autocomplete } from '@sprnova/nebula';
import { useGetPricingTypesQuery } from 'api/crudGraphQL/pricing_types/getPricingTypes';
import { PricingType } from 'api/crudGraphQL/pricing_types/types';
import { Task } from '../../../entitiesRedux';
import { PricingTypeEnum } from '../constants';
import { ActionType, FormDataType } from '../packageStrategyTypes';import AddOnMultiplierContractDetails from './AddOnMultiplierContractDetails';
import AddOnPricingContractDetails from './AddOnPricingContractDetails';
import CustomPricingContractDetails from './CustomPricingContractDetails';
import FixedPricingContractDetails from './FixedPricingContractDetails';
import MultiplierPricingContractDetails from './MultiplierPricingContractDetails';
import QuantityPricingContractDetails from './QuantityPricingContractDetails';
import SpendContractDetails from './SpendContractDetails';
import css from '../package-strategies.module.scss';

interface PricingTypeViewToMapInterface {
  [key: string]: JSX.Element
}

type ContractDetailsProps = {
  isSubmitted: boolean;
  dispatch: React.Dispatch<any>;
  formData: Partial<FormDataType>;
  formDataCached: Partial<FormDataType>;
  task?: Task;
}

const loadingNameOptions = 'Loading...';

const EditContractDetails = ({ isSubmitted, dispatch, formData, formDataCached, task }: ContractDetailsProps): JSX.Element => {
  /**
   * Object to map pricing type to a view.
   */
  const pricingTypeToViewMap: PricingTypeViewToMapInterface = useMemo(() => {
    return ({
      'fixed': <FixedPricingContractDetails isSubmitted={isSubmitted} dispatch={dispatch} formData={formData} formDataCached={formDataCached} />,
      'add-on-fixed': <AddOnPricingContractDetails isSubmitted={isSubmitted} dispatch={dispatch} formData={formData} formDataCached={formDataCached} />,
      'add-on-multiplier': <AddOnMultiplierContractDetails isSubmitted={isSubmitted} dispatch={dispatch} formData={formData} formDataCached={formDataCached} />,
      'custom': <CustomPricingContractDetails isSubmitted={isSubmitted} dispatch={dispatch} formData={formData} formDataCached={formDataCached} />,
      'multiplier': <MultiplierPricingContractDetails isSubmitted={isSubmitted} dispatch={dispatch} formData={formData} formDataCached={formDataCached} />,
      'quantity': <QuantityPricingContractDetails isCurrentData={task?.pricing_type?.name === 'Quantity'} task={task} isSubmitted={isSubmitted} dispatch={dispatch} formData={formData} />,
      'spend': <SpendContractDetails isCurrentData={task?.pricing_type?.slug === PricingTypeEnum.Spend} task={task} isSubmitted={isSubmitted} dispatch={dispatch} formData={formData} />,
    });
  }, [dispatch, formData, formDataCached, isSubmitted, task]);

  const { data: pricingTypes, isLoading: isLoadingPricingTypes } = useGetPricingTypesQuery({
    projection: {
      id: true,
      description: true,
      name: true,
      slug: true,
    }
  });

  /**
   * Default pricing type from async call
   * - If no pricing type is found, default to the following object: { id: 0, name: '', description: '', slug: '' }
   */
  const defaultPricingType = useMemo(() => {
    if (task && task.pricing_type) {
      return [{ id: task.pricing_type.id, name: task.pricing_type.name, description: task.pricing_type.description, slug: task.pricing_type.slug }];
    }
    return [];
  }, [task]);

  const [pricingTypeOptions, setPricingTypeOptions] = useState<PricingType[] | []>(defaultPricingType);

  useEffect(() => {
    if (pricingTypes) {
      setPricingTypeOptions(pricingTypes.map((pricingType: PricingType) => {
        const clonedPricingType = {...pricingType};
        Object.assign(clonedPricingType, { displayName: (<span><b>{pricingType.name}</b> {` — ${pricingType.description}`}</span>) });
        return clonedPricingType;
      }));
    }
  }, [pricingTypes, task?.pricing_type?.name]);

  const handleSelectPricingType = useCallback((event: any, pricingType: PricingType) => {
    dispatch({ type: ActionType.ON_CHANGE_PRICING_TYPE, payload: { pricing_type_id: pricingType.id, pricing_type_slug: pricingType.slug }});
  }, [dispatch]);

  /**
   * Render card content: Pricing type form.
   */
  const cardContent = useCallback(() => {
    return (
      <>
        <Autocomplete
          id='priceTypes'
          className={css.autocomplete}
          defaultValue={pricingTypeOptions[0]}
          disableClearable
          error={!formData?.pricing_type_id && isSubmitted}
          getOptionLabel={(option): string => option?.name || ''}
          getOptionDisabled={(option): boolean => option?.name === loadingNameOptions}
          helperText='This determines the criteria for the strategy'
          isOptionEqualToValue={(option, value): boolean => option.id === value.id}
          key='priceTypes'
          label='Pricing Type'
          loading={isLoadingPricingTypes}
          options={isLoadingPricingTypes ? [] : pricingTypeOptions}
          onChange={(event, newValue): void => {
            handleSelectPricingType(event, newValue);
          }}
          // needed as we have to render the options with a custom ReactNode
          renderOption={(props, option): JSX.Element => (
            <Box
              component='li'
              {...props}
            >
              {option.displayName}
            </Box>
          )}
        />
        <div>
          {pricingTypeToViewMap?.[formData.pricing_type_slug as keyof typeof pricingTypeToViewMap]}
        </div>
      </>
    );
  }, [formData?.pricing_type_id, formData.pricing_type_slug, handleSelectPricingType, isLoadingPricingTypes, isSubmitted, pricingTypeOptions, pricingTypeToViewMap]);

  const renderContractDetailsCard = useMemo(() => {
    return (
      <Card
        disablePadding
        header={
          <CardHeader title="Contract Details" />
        }
      >
        <CardContent>
          {cardContent()}
        </CardContent>
      </Card>
    );
  }, [cardContent]);

  return (
    <>
      {renderContractDetailsCard}
    </>
  );
};

export default memo(EditContractDetails);
