/**
* Talent layout sidebar menu
 */

import React, { ReactNode, useEffect } from 'react';
import {
  AppNavItemProps,
  NavigationCalendarFilledIcon,
  NavigationCalendarIcon,
  NavigationChartFilledIcon,
  NavigationChartIcon,
  NavigationPlantFilledIcon,
  NavigationPlantIcon,
  NavigationStairsFilledIcon,
  NavigationStairsIcon,
  NavigationTableChartFilledIcon,
  NavigationTableChartIcon,
  NavigationUserFolderFilledIcon,
  NavigationUserFolderIcon,
} from '@sprnova/nebula';
import { Role } from 'api/accessControl/Role';
import { useGetFeatureFlagsQuery } from 'api/crudGraphQL/feature_flags/getFeatureFlags';
import { useAccount } from 'features/global/hooks/useAccount';
import { TalentMenuItems } from 'features/talent/constants';

type TalentSidebarMenuSection = {
    label: ReactNode;
    items: ({
        label: ReactNode,
        path: string,
        disabled?: boolean,
     } & Pick<AppNavItemProps, 'icon' | 'iconFilled'>)[]
}



export const useTalentSidebarMenu = (): {
    sections: TalentSidebarMenuSection[];
    isLoading: boolean;
} => {
  const { isLoading, account } = useAccount();
  const isTalentSuperAdmin = account?.roles?.length && account?.roles?.includes('talent-super-admin' as Role);

  const {
    data: featureFlags,
    error: errorLoadingFeatureFlags,
    isSuccess: isSuccessFetchingFeatureFlags
  } = useGetFeatureFlagsQuery({
    name: 'pulse',
    projection: {
      name: true,
      is_enabled: true
    }
  });

  useEffect(() => {
    if(errorLoadingFeatureFlags) {
      console.log('Error loading feature flags. ' + errorLoadingFeatureFlags);
    }
    if(featureFlags && isSuccessFetchingFeatureFlags)  {
      const pulseFeatureFlag = featureFlags[0];
      if(pulseFeatureFlag && !pulseFeatureFlag.is_enabled) {
        console.log('Pulse feature flag not enabled');
      }
    }
  }, [errorLoadingFeatureFlags, isSuccessFetchingFeatureFlags, featureFlags]);

  const sections: TalentSidebarMenuSection[] = [
    {
      label: 'Reporting',
      items: [
        {
          label: TalentMenuItems.ReviewDashboard,
          path: '/talent/reporting/review',
          icon: <NavigationChartIcon />,
          iconFilled: <NavigationChartFilledIcon />
        },
      ]
    },
    {
      label: 'My Hub',
      items: [
        {
          label: TalentMenuItems.MyReviewCycle,
          path: '/talent/reviews',
          icon: <NavigationUserFolderIcon />,
          iconFilled: <NavigationUserFolderFilledIcon />
        },
        {
          label: TalentMenuItems.HedgeHog,
          path: '/talent/hedgehog',
          icon: <NavigationPlantIcon />,
          iconFilled: <NavigationPlantFilledIcon />
        },
        {
          label: TalentMenuItems.Vital5,
          path: '/talent/vital-5',
          icon: <NavigationStairsIcon />,
          iconFilled: <NavigationStairsFilledIcon />
        },
        {
          label: TalentMenuItems.ReviewLog,
          path: '/talent/review/log',
          icon: <NavigationTableChartIcon />,
          iconFilled: <NavigationTableChartFilledIcon />
        },
      ]
    }
  ];

  if (isTalentSuperAdmin) {
    sections.push({
      label: 'Cycles',
      items: [
        {
          label: TalentMenuItems.AllCycles,
          path: '/talent/cycles',
          icon: <NavigationCalendarIcon />,
          iconFilled: <NavigationCalendarFilledIcon />
        }
      ]
    });
  }

  const addPulseToMenu = featureFlags && isSuccessFetchingFeatureFlags && featureFlags.find((flag) => flag.name === 'pulse')?.is_enabled;

  if(addPulseToMenu) {
    sections[1].items.push({
      label: 'Pulse',
      path: '/talent/pulse',
      icon: <NavigationStairsIcon />,
      iconFilled: <NavigationStairsFilledIcon />
    });
  }

  return {
    sections,
    isLoading,
  };
};
