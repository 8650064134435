import React, { FC, useCallback, useMemo } from 'react';
import { AppFooter } from '@sprnova/nebula';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import PrivacyPolicyLink from 'components/PrivacyPolicyLink/PrivacyPolicyLink';
import { useAccount } from 'features/global/hooks/useAccount';
import { version } from '../../../../package.json';

export const Footer: FC = () => {
  const { account } = useAccount();
  const mixpanel = useMixpanel();

  /** Mixpanel Analytics */
  const handleTrackMixpanelEvent = useCallback((buttonClicked: string): void => {
    try {
      const options = {
        accountId: account?.id,
        accountName: account?.name,
        accountEmail: account?.email,
        buttonClicked
      };
      if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: Internal View Footer ${buttonClicked} Button Clicked`, options);
      if (mixpanel?.track) {
        mixpanel.track(`Internal View Footer ${buttonClicked} Button Clicked`, options);
      }
    } catch (error) {
      console.error('Mixpanel error', error);
    }
  },[account?.id, account?.name, account?.email, mixpanel]);

  const links = useMemo(() => [
    { label: 'Privacy Policy', component: PrivacyPolicyLink, onClick: (): void => {handleTrackMixpanelEvent('Privacy Policy'); }},
  ], [handleTrackMixpanelEvent]);

  const content = useMemo(() =>
    `Copyright ©${new Date().getFullYear()} – nova v${version} – Power Digital Marketing`
  , []);

  return <AppFooter links={links} content={content} />;
};
