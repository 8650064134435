import React, {forwardRef} from 'react';
import { TreeSelect as AntdTreeSelect } from 'antd';
import { TreeSelectProps } from 'antd/lib/tree-select';
import classNames from 'classnames';
import { SkeletonInput } from '../SkeletonInput';
import css from './TreeSelect.module.scss';

export interface Props extends TreeSelectProps<any> {
  className?: string;
  block?: boolean;
  skeleton?: boolean;
}

const TreeSelect = forwardRef(({ className, block, skeleton = false, ...props }: Props, ref: any) => {
  // Show skeleton when loading is true
  if (skeleton) return <SkeletonInput className={css.skeleton} loading={false} active={true} />;

  return (
    <AntdTreeSelect {...props} className={classNames(className, css.root, {[css.block]: block })} ref={ref} />
  );
});

TreeSelect.displayName = 'TreeSelect';

export default TreeSelect;
