import React, { useState } from "react";
import * as Sentry from "@sentry/react";

export const TestError = () => {
  let val: any = "val";
  val = { error: "Error" };
  const [time, setTime] = useState(3);
  const [content, setContent] = useState(`Error`);
  setTimeout(function () {
    setContent(val);
    Sentry.captureMessage("This is a sample error to test the error boundary.");
    throw new Error("This is a sample error to test the error boundary.");
  }, 3000);
  setInterval(() => setTime(time - 1), 1000);
  return (
    <div>
      <h2>Error in {time} seconds</h2>
      <div>{content}</div>
    </div>
  );
};

export const ErrorWrapper = () => {
  return (
    <div>
      <h1>Error Page</h1>
      <TestError />
    </div>
  );
};
