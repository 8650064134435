/**
 * hasFeatureAccess
 *
 * Check if current external user has access to a particular nova feature
 * Access is determined on a per-client basis (whichever client the user currently has selected)
 *
 * @param allFeatures - array of nova features each containing their level of access
 * @param featureSlug - the route or feature that access is being checked for
 * @returns boolean
 */
import { NovaFeature } from 'features/entitiesRedux/models/nova_features';

export const hasFeatureAccess = (allFeatures: NovaFeature[], featureSlug: string): boolean => {
  return allFeatures?.find(feature => feature?.slug === featureSlug)?.is_active === true;
};
