import React from 'react';
import { useHistory } from 'react-router';
import { Alert, Button, AlertTitle, ArrowRightIcon } from '@sprnova/nebula';

/**
 * ALERT_TEXT contains the static text used in the NoIntegrationsAlertBanner component,
 * including the title, description, contact email, support chat text, and button label.
 */
const ALERT_TEXT = {
  title: 'No Integrations connected',
  description: 'Scoreboards are powered by data integrations. Please make sure you have completed all Data Access Requests in order to unlock this functionality. If you have any questions, contact ',
  email: 'novacs@powerdigital.com',
  support: ' or use nova’s Intercom support chat.',
  buttonLabel: 'Data Integrations',
};

type NoIntegrationsAlertBannerProps = {
  clientId: number;
  isExternalUser?: boolean;
}

/**
 * NoIntegrationsAlertBanner component displays a warning alert if no integrations are connected.
 * It provides a button that redirects the user to the Data Integrations page for the given client.
 *
 * @param {number} clientId - The unique identifier for the client.
 * @param {boolean} [isExternalUser] - Optional. Indicates if the user is an external user.
 *
 * @returns {JSX.Element} The rendered alert banner component.
 */
const NoIntegrationsAlertBanner = ({ clientId, isExternalUser }: NoIntegrationsAlertBannerProps): JSX.Element => {
  const history = useHistory();
  const redirectPath = `/${isExternalUser ? 'c/' : 'power-view/accounts/'}${clientId}/integrations/`;

  return (
    <Alert
      severity="warning"
      action={
        <Button
          variant="tertiary"
          endIcon={<ArrowRightIcon />}
          size="large"
          onClick={() => history.push(redirectPath)}
          key="NoIntegrationsAlertBanner-integrations-button"
        >
          {ALERT_TEXT.buttonLabel}
        </Button>
      }
      sx={{marginBottom: '1.5rem'}}
    >
      <AlertTitle>{ALERT_TEXT.title}</AlertTitle>
      {ALERT_TEXT.description}
      <a href={`mailto:${ALERT_TEXT.email}`}>{ALERT_TEXT.email}</a>
      {ALERT_TEXT.support}
    </Alert>
  );
};

export default NoIntegrationsAlertBanner;
