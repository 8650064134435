/**
* Delete department revision
*/

import { baseApi } from 'api/crudGraphQL';
import { Projection } from 'api/entityGraphQL/types';
import { Department } from 'features/entitiesRedux/models/department/department';
import { updateGetDepartmentCache } from './getDepartment';

export type DeleteDepartmentRevisionMutation = {
  projection: Projection;
  id: number;
}

export type DeleteDepartmentRevisionResult = Department;

export const { useDeleteDepartmentRevisionMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    deleteDepartmentRevision: build.mutation<DeleteDepartmentRevisionResult, DeleteDepartmentRevisionMutation>({
      transformResponse: (response: { deleteDepartmentRevision: Department }) =>  response.deleteDepartmentRevision,
      query: ({ id, projection = {} }) => ({
        body: {
          mutation: {
            deleteDepartmentRevision: {
              __args: {
                department_id: id
              },
              ...projection,
              id: true
            }
          }
        }
      }),
      // Update any getDepartment query cache that matches the arguments of this mutation (id and projection)
      onQueryStarted: updateGetDepartmentCache
    }),
  }),
});
