import React, { memo, useCallback, useMemo } from 'react';
import { Dialog, DialogActions } from '@sprnova/nebula';
import css from './ContractLengthChangeModal.module.scss';

type ContractLengthChangeModalProps = {
  onAccept: () => void;
  onCancel: () => void;
  isOpen: boolean;
}
const ContractLengthChangeModal = ({ onAccept, onCancel, isOpen }: ContractLengthChangeModalProps): JSX.Element => {
  const renderDialogContent = useMemo(() => {
    return (
      <div className={css.list}>
        <span>By changing the length of contract this changes the:</span>
        <br />
        <ul>
          <li>Strategy&apos;s Frequency</li>
          <li>Outsource Costs</li>
          <li>Monthly Retainer</li>
          <li>End Date</li>
        </ul>
        <span>This change will impact overall pricing, please update Strategies & Pricing and Outsource Costs to reflect this change.</span>
      </div>
    );
  }, []);

  const handleCancelContractLengthChange = useCallback((): void => {
    onCancel();
  }, [onCancel]);

  const handleApproveContractLengthChange = useCallback((): void => {
    onAccept();
  }, [onAccept]);

  return (
    <Dialog
      PaperProps={{ className: css.dialog }}
      open={isOpen}
      title='Changing The Length of Contract'
      footer={
        <DialogActions
          secondaryButtonProps={{ onClick: handleCancelContractLengthChange, children: 'Cancel', variant: 'text', size: 'small' }}
          primaryButtonProps={{ onClick: handleApproveContractLengthChange, children: 'Accept', size: 'small' }}
        />
      }
    >
      {renderDialogContent}
    </Dialog>
  );
};

export default memo(ContractLengthChangeModal);
