import React from 'react';
import loadable from '@loadable/component';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';

const LoadableComponent = loadable(
  () => import('./StrategiesListPage'),
  {
    fallback: <PageSkeleton />
  }
);

const Title = () => <>Blueprints (Hourly)</>;

export default Object.assign(LoadableComponent, { Title });
