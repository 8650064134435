import React, { FC, memo } from 'react';
import { Box, InfoTooltip, Tag, Typography } from '@sprnova/nebula';
import { getClientStageDefinition } from 'utils/clients/getClientStageDefinition';
import { ClientAccountStage } from 'features/entitiesRedux';

interface Props {
  stage: ClientAccountStage;
  tooltip?: boolean;
  tooltipIconSize?: 'small' | 'inherit' | 'large' | 'medium';
}

const ClientStageTag: FC<Props> = ({ stage, tooltip, tooltipIconSize = 'small' }) => {
  const color = stage && stage.color ? stage.color : 'default';
  const stageName = stage && stage.name ? stage.name : 'N/A';
  const tooltipElement = tooltip ? <InfoTooltip
    content={getClientStageDefinition(stage.slug)}
    iconSize={tooltipIconSize}
    placement='right'
    variant='plain'
    buttonProps={{
      sx: {
        verticalAlign: 'bottom',
        '&:hover': {
          backgroundColor: 'unset !important', // needed because there is a default background color on hover
        },
        marginLeft: '10px',
      },
    }}
  /> : <></>;

  if (stageName === 'N/A' || !stage) {
    return (
      <Box sx={{ width: '100%', maxWidth:'158px' }}>
        <Typography variant="body1" align={'center'}  display="block" >
          {stageName}
        </Typography>
      </Box>
    );
  } else {
    return (
      <Tag
        sx={{textTransform: 'uppercase'}}
        color={color}
      >
        {stageName}{tooltipElement}
      </Tag>
    );
  }
};

export default memo(ClientStageTag);
