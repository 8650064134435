import React, { FC, useMemo, memo } from 'react';
import { Skeleton, Typography } from '@sprnova/nebula';

type Props = {
  rowCount?: number;
}

const SessionListSkeleton: FC<Props> = ({ rowCount = 10 }) => {

  const rows = useMemo(() => {
    const rows: JSX.Element[] = [];

    for (let i = 0; i < rowCount; i++) {
      rows.push(
        <React.Fragment key={`skeleton-row-${i}`}>
          {/* Session name */}
          <Typography variant="h1" width="100%">
            <Skeleton />
          </Typography>
          {/* Session date */}
          <Typography variant="h3" width="50%">
            <Skeleton />
          </Typography>
        </React.Fragment>
      );
    }

    return rows;
  }, [rowCount]);

  return (
    <div style={{
      height: '100%',
      overflow: 'hidden',
      backgroundColor: '#FAFAFA',
      padding: '20px 0',
    }}>
      <div style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '0 24px',
      }}>
        {rows}
      </div>
    </div>
  );
};

export default memo(SessionListSkeleton);
