import React, { memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetNovaFeaturesPublicQuery } from 'api/crudGraphQL/public/nova_features/getNovaFeaturesPublic';
import { Warning } from 'components/AccessControl/Warning';
import Scoreboard from './Scoreboard';

const ExternalScoreboard = (): JSX.Element => {
  const { clientId } = useParams<{ [x: string]: string }>();

  const { data: nova_features = [], isLoading: isLoadingFeatures } = useGetNovaFeaturesPublicQuery({
    client_id: parseInt(clientId),
    projection: {
      id: true,
      slug: true,
      is_active: true,
    } }, { skip: !clientId });

  const hasAccess = useMemo(() => {
    return nova_features.some(feature => feature.slug === 'scoreboards');
  }, [nova_features]);

  if (!hasAccess && !isLoadingFeatures) {
    return <Warning container />;
  }

  return (
    <Scoreboard isClient />
  );
};

export default memo(ExternalScoreboard);
