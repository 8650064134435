import React, { memo, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CalendarIcon, DatePicker, Grid, InputAdornment } from '@sprnova/nebula';
import { useGetIndicatorEarlyDateQuery } from 'api/crudGraphQL/scoreboards/indicators/getIndicatorEarlyDate';
import { format } from 'date-fns';

const slotProps = {
  textField: {
    InputProps: {
      endAdornment: (
        <InputAdornment
          sx={{
            color: '#979797',
          }}
          position="end"
        >
          <CalendarIcon />
        </InputAdornment>
      ),
    },
  },
  clearable: true,
};
type DateRangeProps = {
  setValue: UseFormSetValue<any>;
};
/**
 * Remove this component when Nebula RangePicker is available
 */
const DateRange = ({ setValue }: DateRangeProps): JSX.Element => {
  const { id } = useParams<{ [x: string]: string }>();
  const [from, setFrom] = useState<Date | null>();

  const { data: date, isLoading } = useGetIndicatorEarlyDateQuery({ report_id: parseInt(id) }, { skip: !id });

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <DatePicker
          id='DateRange-from'
          label="From"
          onChange={(value: Date | null): void => {
            setFrom(value);
            setValue('from', value ? format(new Date(value), 'yyyy-MM-dd') : '');
          }}
          slotProps={slotProps}
          minDate={date && new Date(date) || undefined}
          disabled={isLoading}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id='DateRange-to'
          label="To"
          onChange={(value: Date | null): void => {
            setValue('to', value ? format(new Date(value), 'yyyy-MM-dd') : '');
          }}
          slotProps={slotProps}
          minDate={from || undefined}
          disabled={isLoading}
        />
      </Grid>
    </Grid>
  );
};

export default memo(DateRange);
