import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@sprnova/nebula';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';
import { FieldArrayName } from './types';

export const CustomContainer = ({ name, onClear, children }: { name: FieldArrayName, onClear: (name: FieldArrayName) => void, children: any }): JSX.Element => {
  return (
    <Container sx={{border: '1px solid #D9D9D9', borderRadius: '8px', padding: '24px 8px', marginBottom: '20px'}}>
      <Box sx={{ mb: 2 }}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            <Typography variant='h4'>
              {`For ${capitalize(pluralize(name))}`}
            </Typography>
          </Grid>
          <Grid item>
            <Button size='small' onClick={(): void => onClear(name)}>
                Clear
            </Button>
          </Grid>
        </Grid>
        {children}
      </Box>
      <Typography variant='h5' sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        {`Maximum 4 ${capitalize(name)} filters`}
      </Typography>
    </Container>
  );
};
