import React, { useState, useMemo, useEffect, useCallback, useReducer } from 'react';
import { Collapse, Container, Grid, Button, Box, Skeleton, Select, Typography, Alert } from '@sprnova/nebula';
import { useGetClientsQuery } from 'api/crudGraphQL/clients/getClients';
import { useGetPlaybookAdsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookAds';
import { useGetPlaybookAdsetsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookAdsets';
import { useGetPlaybookCampaignsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookCampaigns';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import FormV2 from 'components/nebula/Form/FormV2';
import { capitalize, isNumber, pickBy, sortBy } from 'lodash';
import { plural, singular } from 'pluralize';
import {
  Bar,
  BarChart,
  BarData,
  CartesianGrid,
  Label,
  LabelList,
  Legend,
  LegendProps,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
import { StringParam } from 'serialize-query-params';
import { useQueryParams } from 'use-query-params';
import { PlaybookAd, PlaybookAdset, PlaybookCampaign } from 'features/entitiesRedux/models/creative_playbook';
import { useAccount } from 'features/global';
import { useFilterContext } from '../../components/Filters/Filters';
import { adsComparisonProjection, adsetComparisonProjection, campaignComparisonProjection } from '../../ViewCreativePlaybook/components/PlaybookTable/components/PlaybookTableBody/components/CollapsibleData/projections';
import { getRatingAndColor } from '../../ViewCreativePlaybook/components/PlaybookTable/components/PlaybookTableBody/components/TableBodyData/components/AffinityScore';
import { PlaybookDataType } from '../../ViewCreativePlaybook/constants';
import { extractClientIdsFromCreativeAffinityData, hasSalesforceCommerceCloudConnector } from '../../ViewCreativePlaybook/utils';
import type { CompareValue } from '../CreativeAffinityOverviewPage';
import { TabNames, CreativeAffinityKeys, CreativeAffinityValueTypes } from '../utils/constants';
import { formatValuesBasedOnType } from '../utils/formatValuesBasedOnType';
import { CampaignHeader, ColumnsTable } from './components';
import { ComparisonRepeater } from './components/ComparisonRepeater';

interface CreativeAffinityComparisonProps {
  activeTab?: string | number | null;
  compareTab?: string | number | null;
  compareValues?: CompareValue[];
}

type DataType = (PlaybookCampaign | PlaybookAdset | PlaybookAd) | undefined;

export const CreativeAffinityComparison = ({
  activeTab,
  compareTab,
  compareValues,
}: CreativeAffinityComparisonProps): JSX.Element => {
  const [campaignIds, setCampaignIds] = useState<number[]>([]);
  const [adsetIds, setAdsetIds] = useState<number[]>([]);
  const [adIds, setAdIds] = useState<number[]>([]);
  const [collapsed, toggle] = useReducer((state: boolean) => !state, false);
  const { filter } = useFilterContext();

  const [_, setQueryParams] = useQueryParams({
    left: StringParam,
    right: StringParam
  });
  const [left, setLeft] = useState<string>('ctr');
  const [right, setRight] = useState<string>('cpc');

  useEffect(() => {
    /**
     * This sets the default query params for the graph
     * and fixes a visual bug where the select values aren't set on load
     */
    if (compareValues?.length) {
      setQueryParams({
        left,
        right
      });
    }
  }, []);


  /**
   * Mixpanel event tracking
   */
  const mixpanel = useMixpanel();
  const { account } = useAccount();

  const trackGraphValuesCompared = useCallback(() => {
    try {
      if (!mixpanel) return;
      const mixpanelTitle = `Creative Affinity - ${typeof activeTab === 'string' ? capitalize(activeTab) : ''} Graph Values Compared`;
      const options = {
        userId: account?.id,
        userName: account?.name,
        leftYAxis: left,
        rightYAxis: right,
      };
      mixpanel.track(mixpanelTitle, options);
      if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
    } catch (error) {
      console.error('Track Mixpanel error', error);
    }
  }, [account, activeTab, left, mixpanel, right]);

  /**
   * Scroll functionality to offset the
   */
  let transform = 0;
  let anchorlinkTop = 0;

  const navbar = useMemo(() => {
    return document.querySelector('header > div.MuiContainer-root');
  }, []);

  const handleScroll = useCallback(() => {
    const scrolledDistance = window.scrollY;
    const viewportHeight = window.innerHeight;
    const totalDocumentHeight = document.documentElement.scrollHeight;
    if (navbar !== null) {
      const computedHeight = parseInt(getComputedStyle(navbar).height);
      const computedTransform = getComputedStyle(navbar).transform;

      if (computedTransform !== null) {
        const matrixValues = computedTransform.match(/matrix\((.+)\)/);

        if (matrixValues !== null) {
          const values = matrixValues[1].split(', ').map(parseFloat);

          if (values.length >= 6) {
            transform = values[5];
            anchorlinkTop = computedHeight - Math.abs(transform);
          }
        }
      }
      //When scroll to the bottom of the page, navbar shows, place anchorlinks below navbar
      if (scrolledDistance + viewportHeight >= totalDocumentHeight - 1) {
        anchorlinkTop = computedHeight;
      }
      //apply the simultaneous top value to the anchorlink div
      const anchorlinkDiv = document.getElementById('anchorlinkDiv');
      if (anchorlinkDiv) {
        anchorlinkDiv.style.top = `${anchorlinkTop-1}px`;
      }
    }
  }, [navbar]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const { limit, page, sort, name, ...restFilter } = filter;

  const { data: playbookCampaignsData, isLoading: campaignsLoading } = useGetPlaybookCampaignsQuery({
    ids: campaignIds,
    ...restFilter,
    projection: campaignComparisonProjection
  },
  { skip: !campaignIds?.length || compareTab !== TabNames.campaigns }
  );
  const { data: playbookAdsetsData, isLoading: adsetsLoading } = useGetPlaybookAdsetsQuery({
    ids: adsetIds,
    ...restFilter,
    projection: adsetComparisonProjection
  },
  { skip: !adsetIds?.length || compareTab !== TabNames.adsets }
  );
  const { data: playbookAdsData, isLoading: adsLoading } = useGetPlaybookAdsQuery({
    ids: adIds,
    ...restFilter,
    projection: adsComparisonProjection
  },
  { skip: !adIds?.length || compareTab !== TabNames.ads }
  );

  // Extract client ids from CA data
  const clientsIds = (): number[] => {
    const clientIdsCampaign = playbookCampaignsData?.data
      ? extractClientIdsFromCreativeAffinityData(playbookCampaignsData.data, PlaybookDataType.Campaign)
      : [];

    const clientIdsAdSet = playbookAdsetsData?.data
      ? extractClientIdsFromCreativeAffinityData(playbookAdsetsData.data, PlaybookDataType.AdSet)
      : [];

    const clientIdsAd = playbookAdsData?.data
      ? extractClientIdsFromCreativeAffinityData(playbookAdsData.data, PlaybookDataType.Ad)
      : [];

    return [...clientIdsCampaign, ...clientIdsAdSet, ...clientIdsAd];
  };

  /**
   * Query client data to determine if client has SFCC
   */
  const { data: clientsData } = useGetClientsQuery({
    client_ids: clientsIds(),
    limit: -1,
    projection: {
      id: true,
      name: true,
      fivetran_connectors: {
        data_source: {
          slug: true
        },
        data_feed_created: true
      }
    }
  });
  const clientHasSFCC = clientsData?.data ? hasSalesforceCommerceCloudConnector(clientsData?.data) : false;

  /**
     * We want to disable if there are no values for any
     * of the compared values for the selected key
     */
  const isNoValue = (data: Partial<DataType>[] | undefined, key: string) => {
    return data?.filter((item) => item && (item[key as keyof DataType] !== undefined && item[key as keyof DataType] !== 0)).length === 0;
  };

  const handleGraphChangeSubmit = useCallback(({ left, right }: Record<string, string>) => {
    if (left) setLeft(left);
    if (right) setRight(right);
    trackGraphValuesCompared();
  }, [trackGraphValuesCompared]);

  const renderGraph = useMemo(() => {
    let data: Partial<DataType>[] | undefined;
    let length = 0;
    /**
     * We only want the numeric values for the graph.
     */
    const mapData = (data: Partial<DataType>[]): Partial<DataType>[] => {
      return data?.map((item) => {
        const values = pickBy(item, isNumber);
        length = Object.keys(values).length;
        return values || [];
      });
    };
    const order = compareValues?.map((item: any) => item?.id);
    switch (activeTab) {
      case TabNames.campaigns:
        data = mapData(sortBy(playbookCampaignsData?.data, (item) => order?.indexOf(item.id)) || []);
        break;
      case TabNames.adsets:
        data = mapData(sortBy(playbookAdsetsData?.data, (item) => order?.indexOf(item.id)) || []);
        break;
      case TabNames.ads:
        data = mapData(sortBy(playbookAdsData?.data, (item) => order?.indexOf(item.id)) || []);
        break;
      default:
        data = [];
        break;
    }
    if (campaignsLoading || adsetsLoading || adsLoading) return <></>;

    const keys = Object.keys((data as DataType[])?.find((item) => item && Object.keys(item).length === length) || []).filter((key: string) => key !== 'id') || [];

    if (!left && keys.length > 0) setLeft(keys[0]);
    if (!right) setRight(keys[1]);
    const labelContent = (props: BarData, type: string) => {
      const { x, y, value, width } = props;

      return (
        <g>
          <text
            x={x + width / 2}
            y={y - 8}
            style={{
              textAnchor: 'middle',
              fontSize: '12px',
              fill: '#2C2C2C'
            }}
          >
            {
              type === 'score' && typeof value === 'number'
                ? `${(value * 10).toFixed(2)}% (${getRatingAndColor(value).rating})`
                : formatValuesBasedOnType({[type]:value}, CreativeAffinityValueTypes, { maximumFractionDigits: 4 })[type]
            }
          </text>
        </g>
      );
    };

    const legend = (props: LegendProps) => {
      const { payload } = props;
      return (
        <ul
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {
            payload?.map((entry: any, index: number) => (
              <li
                key={`item-${index}`}
                style={{
                  listStyleType: 'none', marginRight: index === 0 ? '20px' : '0px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    width: '16px',
                    height: '16px',
                    borderRadius: '2px',
                    marginRight: '8px',
                    background: entry.color
                  }}
                />
                <Typography variant="caption">
                  {CreativeAffinityKeys[entry.value as keyof typeof CreativeAffinityKeys]}
                </Typography>
              </li>
            ))
          }
        </ul>
      );
    };

    const formatYAxis = (value: number, type: string | number | any) => {
      if (type === 'ltv' || type === 'cac' || type === 'spend' || type === 'roas' || type === 'cpa' || type === 'cpc' || type === 'cpm') {
        if (value >= 1000) {
          return `${(value / 1000).toFixed(0)}k`;
        }
        return value;
      } else if (type === 'stop_rate' || type === 'hold_rate' || type === 'ctr' || type === 'cvr') {
        return `${(value * 100).toFixed(2)}%`;
      } else {
        return value;
      }
    };

    return (
      <Box>
        <FormV2
          submitOnChange
          onSubmit={handleGraphChangeSubmit}
          withQueryParams
          button={false}
          gridItemBreakpoints={{ xs: 2 }}
          justifyContent='space-between'
          style={{
            zIndex: 1,
            position: 'relative'
          }}
          items={[
            {
              component: Select,
              name: 'left',
              props: {
                id: 'left',
                label: 'Left Y-Axis Metric',
                defaultValue: left,
                children: keys.map((key: string) => (
                  <Select.Item
                    key={`left_${key}`}
                    value={key}
                    disabled={
                      key === right
                      /**
                       * We want to disable if there are no values for any
                       * of the compared values for the selected key
                       */
                        || data?.filter((item: any) => item[key] !== undefined && item[key] !== 0).length === 0
                    }
                  >
                    {CreativeAffinityKeys[key as keyof typeof CreativeAffinityKeys]}
                  </Select.Item>
                ))
              },
              paramType: StringParam,
              grid: {
                xs: 3
              },
            },
            {
              component: Select,
              name: 'right',
              props: {
                id: 'right',
                label: 'Right Y-Axis Metric',
                defaultValue: right,
                children: keys.map((key: string) => (
                  <Select.Item
                    key={`right_${key}`}
                    value={key}
                    disabled={
                      key === left || isNoValue(data, key)
                    }
                  >
                    {CreativeAffinityKeys[key as keyof typeof CreativeAffinityKeys]}
                  </Select.Item>
                ))
              },
              paramType: StringParam,
              grid: {
                xs: 3
              },
            }
          ]}
        />
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            width={500}
            height={300}
            data={data as readonly object[]}
            margin={{
              top: 10,
              right: 30,
              left: 30,
              bottom: 30,
            }}
            barGap={8}
          >
            <CartesianGrid
              strokeDasharray="0"
              vertical={false}
              // horizontal={true}
            />
            <XAxis tick={false} />
            <YAxis
              yAxisId='left'
              orientation='left'
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) => formatYAxis(value, left)}
            >
              <Label
                value={CreativeAffinityKeys[left as keyof typeof CreativeAffinityKeys] || left}
                position="insideRight"
                angle={-90}
                offset={80}
                style={{
                  textAnchor: 'middle',
                  fontSize: '12px',
                  fontWeight: 500,
                  text: '#6D6D6D !important',
                }}
              />
            </YAxis>
            <YAxis
              yAxisId='right'
              orientation='right'
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) => formatYAxis(value, right)}
            >
              <Label
                value={CreativeAffinityKeys[right as keyof typeof CreativeAffinityKeys] || right}
                position="insideRight"
                angle={90}
                offset={-20}
                style={{
                  textAnchor: 'middle',
                  fontSize: '12px',
                  fontWeight: 500,
                  color: '#6D6D6D !important',
                }}
              />
            </YAxis>
            <Legend verticalAlign='top' wrapperStyle={{ top: '-30px', position: 'absolute' }} content={legend} />
            <Bar yAxisId='left' dataKey={left} fill="#6051A1" radius={[10, 10, 0, 0]}>
              <LabelList
                dataKey={left}
                position='centerTop'
                content={(props: any) => labelContent(props, left)}
              />
            </Bar>
            <Bar yAxisId='right' dataKey={right} fill="#E65957" radius={[10, 10, 0, 0]}>
              <LabelList
                dataKey={right}
                position='centerTop'
                content={(props: any) => labelContent(props, right)}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  }, [activeTab, playbookCampaignsData?.data, playbookAdsetsData?.data, playbookAdsData?.data, campaignsLoading, adsetsLoading, adsLoading, left, right, handleGraphChangeSubmit]);

  const renderCampaignHeader = useMemo(() => {
    let data;
    const order = compareValues?.map((item: any) => item?.id);
    const skeleton = (
      <Skeleton
        animation="wave"
        variant="rectangular"
        height='100px'
        sx={{
          marginTop: '40px'
        }}
      />
    );
    if (activeTab === TabNames.campaigns) data = sortBy(playbookCampaignsData?.data, (item) => order?.indexOf(item.id));
    if (activeTab === TabNames.adsets) data = sortBy(playbookAdsetsData?.data, (item) => order?.indexOf(item.id));
    if (activeTab === TabNames.ads) data = sortBy(playbookAdsData?.data, (item) => order?.indexOf(item.id));
    if (campaignsLoading || adsetsLoading || adsLoading) return skeleton;

    return <CampaignHeader data={data} /*handleRemove={handleRemove}*/ activeTab={activeTab as string} />;
  }, [activeTab, adsLoading, adsetsLoading, campaignsLoading, compareValues, playbookAdsData?.data, playbookAdsetsData?.data, playbookCampaignsData?.data]);

  const renderLoadingComponents = useMemo(() => {
    return (
      <Grid container spacing={2}>
        {
          compareValues?.map((_: any, i: number) => (
            <Grid
              key={`skeletonContainer_${i}`}
              item
              xs={12 / compareValues?.length}
            >
              {
                Array.from(new Array(3)).map((_, i) => (
                  <Skeleton
                    key={`skeleton_${i}`}
                    animation="wave"
                    variant="rectangular"
                    height='30px'
                    style={{ marginBottom: '10px' }}
                  />
                ))
              }
            </Grid>
          ))
        }
      </Grid>
    );
  }, [compareValues]);

  const renderColumnsTable = useMemo(() => {
    let data;
    const order = compareValues?.map((item: any) => item?.id);
    if (activeTab === TabNames.campaigns) data = sortBy(playbookCampaignsData?.data, (item) => order?.indexOf(item.id));
    if (activeTab === TabNames.adsets) data = sortBy(playbookAdsetsData?.data, (item) => order?.indexOf(item.id));
    if (activeTab === TabNames.ads) data = sortBy(playbookAdsData?.data, (item) => order?.indexOf(item.id));
    if (campaignsLoading || adsetsLoading || adsLoading) return renderLoadingComponents;
    return (
      <ColumnsTable data={data} activeTab={activeTab as string} />
    );
  }, [compareValues, activeTab, playbookCampaignsData?.data, playbookAdsetsData?.data, playbookAdsData?.data, campaignsLoading, adsetsLoading, adsLoading, renderLoadingComponents]);

  const renderEditButton = useMemo(() => {
    if (collapsed || campaignsLoading || adsetsLoading || adsLoading) return <></>;
    return (
      <Box sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20px'
      }}>
        <Button onClick={toggle}>Edit {capitalize(plural(activeTab as 'string'))}</Button>
      </Box>
    );
  }, [activeTab, adsLoading, adsetsLoading, campaignsLoading, collapsed]);

  const renderTitle = useMemo(() => {
    if (typeof activeTab === 'string') {
      if (campaignsLoading || adsetsLoading || adsLoading) return <></>;
      return (
        <Typography
          variant="h2"
          gutterBottom
          component='div'
          sx={{
            marginBottom: '30px',
            textTransform: 'capitalize'
          }}
        >
          {plural(TabNames[`${singular(activeTab)}Title` as keyof typeof TabNames])} Comparison
        </Typography>
      );
    }
  }, [activeTab, campaignsLoading, adsetsLoading, adsLoading]);

  const handleSettingCorrectIds = useCallback(() => {
    switch (activeTab) {
      case TabNames.campaigns: {
        const ids = compareValues?.map((item: any) => item?.id);
        if (ids?.length && ids?.length >= 2) {
          setCampaignIds(ids);
        }
        break;
      }
      case TabNames.adsets: {
        const ids = compareValues?.map((item: any) => item?.id);
        if (ids?.length && ids?.length >= 2) {
          setAdsetIds(ids);
        }
        break;
      }
      case TabNames.ads: {
        const ids = compareValues?.map((item: any) => item?.id);
        if (ids?.length && ids?.length >= 2) {
          setAdIds(ids);
        }
        break;
      }
      default:
        return;
    }
  }, [activeTab, compareValues]);

  useEffect(() => {
    handleSettingCorrectIds();
  }, []);


  const renderComparisonRepeater = useMemo(() => {
    let data;
    if (activeTab === TabNames.campaigns) data = playbookCampaignsData?.data;
    if (activeTab === TabNames.adsets) data = playbookAdsetsData?.data;
    if (activeTab === TabNames.ads) data = playbookAdsData?.data;
    if (!data || !compareValues?.length) return <></>;
    return (
      <ComparisonRepeater
        activeTab={activeTab}
        compareValues={compareValues}
        setIds={{
          setCampaignIds,
          setAdsetIds,
          setAdIds
        }}
        toggle={toggle}
      />
    );
  }, [activeTab, compareValues, playbookAdsData?.data, playbookAdsetsData?.data, playbookCampaignsData?.data]);

  return (
    <Container hasVerticalPadding>
      {clientHasSFCC && (
        <Alert severity="warning" sx={{marginBottom: '10px'}}>
          Salesforce Commerce Cloud refunds data is currently missing. LTV values may not be 100% accurate. We are working on this
        </Alert>
      )}
      {renderTitle}
      {renderGraph}
      <Grid container spacing={2}>
        <Box width='100%' sx={{ margin: '0px 80px' }}>
          <Box
            id="anchorlinkDiv"
            style={{
              background: '#fff',
              width: '100%',
              position: 'sticky',
              zIndex: 1,
              marginBottom: '20px',
              paddingTop: '24px',
            }}
          >
            {renderCampaignHeader}
            {renderEditButton}
            <Collapse in={collapsed} sx={{ marginTop: '20px' }}>
              {renderComparisonRepeater}
            </Collapse>
          </Box>
          <div>
            {renderColumnsTable}
          </div>
        </Box>

      </Grid>
    </Container>
  );
};
