/**
 * Components -> ScrollForm -> Context
 */

import { createContext } from 'react';
import noop from 'lodash/noop';
import { IScrollFormSection } from './types';

export type ScrollFormSectionRefCollection = {
  root: React.RefObject<HTMLDivElement>,
  header: React.RefObject<HTMLDivElement>,
  sticky_sentinel_top: React.RefObject<HTMLDivElement>,
  sticky_sentinel_bottom: React.RefObject<HTMLDivElement>,
};
export interface ScrollFormContextInterface {
  registerSection: (args: IScrollFormSection & { refs: ScrollFormSectionRefCollection }) => void;
  setInViewId: (idOrCallback: string | ((prevState: string) => string)) => void;
  updateSection: (args: Pick<IScrollFormSection, 'progress' | 'id'>) => void;
}

export default createContext<ScrollFormContextInterface>({
  registerSection: noop,
  setInViewId: noop,
  updateSection: noop,
});
