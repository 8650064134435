/**
 * Get Salesforce Opportunity Reasons (reasons for moving an opportunity to a particular stage)
 * requires a 'type' argument
 */
import { Projection } from 'api/entityGraphQL';
import { OpportunityReason } from 'features/entitiesRedux/models/types';
import { baseApi } from '../baseApi';
import { cleanArguments } from '../utils';
import tagTypes from './tagTypes';

export type GetOpportunityReasonsQuery = {
  id?: number;
  type?: string;
  projection: Projection;
}

export type GetOpportunityReasonsResult = OpportunityReason[];

export const { useGetOpportunityReasonsQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOpportunityReasons: build.query<GetOpportunityReasonsResult, GetOpportunityReasonsQuery>({
      transformResponse: (response: { opportunity_reasons: GetOpportunityReasonsResult }) => response.opportunity_reasons,
      providesTags: (opportunity_reasons = []) => {
        return [
          tagTypes.OPPORTUNITY_REASONS,
          ...opportunity_reasons.map(opportunity_reason => ({
            id: opportunity_reason.id,
            type: tagTypes.OPPORTUNITY_REASON
          }))
        ];
      },
      query: ({
        projection = { id: true, name: true },
        ...args
      }) => {
        const __args: Partial<GetOpportunityReasonsQuery> = args;

        return {
          body: {
            query: {
              opportunity_reasons: {
                __args: cleanArguments(__args),
                ...projection
              }
            }
          }
        };
      }
    })
  })
});
