export const valuePresentStyles = {
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #000000', borderRadius: 1
  },
  '&fieldset.notchedOutline': {
    border: '1px solid #000000 !important',
  },
  '&:hover fieldset': {
    border: '2px solid #0288D1 !important',
  },
  'label': {
    color: 'black',
  },
  '&:hover label': {
    color: '#0288D1',
  },
  '&:focus-within fieldset, &:focus-visible fieldset': {
    border: '2px solid #0288D1 !important',
  },
};
