import React, { useCallback } from 'react';
import { AlertRibbon } from 'features/intelligence/components/library-components';
import css from './InactiveIntegrations.module.scss';
import moment from 'moment';
import { AD_NETWORKS, ECOMMERCE } from '../../constants';

type InactiveIntegrationsProps = {
  clientId: number;
  inactiveIntegrations: string[];
}

const InactiveIntegrations = ({ clientId, inactiveIntegrations }: InactiveIntegrationsProps): JSX.Element => {

  /**
   * Assigns appropriate display text to each inactive integration
   * @param StringParam
   * @returns {string}
   */

const displayInactiveIntegrations = useCallback((name: string) => {
  switch (name) {
    case ECOMMERCE:
      return 'eCommerce store (BigCommerce, Magento, or Shopify)';
    case AD_NETWORKS:
      return 'Ad Networks integration (Facebook, Google Ads, or Funnel.io)';
    default:
      return name;
  }
}, []);

  return (
    <AlertRibbon
      className={css.root}
      type="warning"
      title="Inactive Integrations"
      content={
        <div>
          <p className={css.description}>
            Your client's integrations must be active in order for our team to receive data.
            The integrations below need to be refreshed.
            To refresh your client's integrations please{' '}
            <a href="https://form.asana.com/?k=rB9mmmX8qE-zGV5Dcn55qg&d=22908445599079" target="_blank" rel="noopener noreferrer">
              submit a ticket{' '}
            </a>
            to the engineering team.
          </p>
          <ol className={css.statusList}>
            {inactiveIntegrations.map((integration: string, index: number) => (
              <li key={integration}>
                {`${index + 1}. `}
                {displayInactiveIntegrations(integration)}
              </li>
            ))}
          </ol>
        </div>
      }
    />
  );
};

export default InactiveIntegrations;
