import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { setEntities } from './setEntities';
import { setPending } from './setPending';
import { setRejected } from './setRejected';

/**
 * Hook up the redux toolkit extraReducers builder callback with 3-step thunk action types
 *
 * @param builder
 * @param action
 */
export const connectEntityActionReducers = (
  builder: ActionReducerMapBuilder<any>,
  action: any
) => {
  builder.addCase(action.pending, setPending);
  builder.addCase(action.fulfilled, setEntities);
  builder.addCase(action.rejected, setRejected);
};
