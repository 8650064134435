/**
 * Components -> SelectSalesforceAccount
 */

import React, { FC, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Empty } from 'antd';
import { novaGraphQLClient } from 'api/entityGraphQL';
import { sortBy } from 'lodash';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import { SalesforceAccount } from 'features/entitiesRedux/models/salesforce/salesforce';
import Select, { Props as SelectProps } from '../Select/Select';
import { Spin } from '../Spin';
import css from './SelectSalesforceAccount.module.scss';

const FETCH_LIMIT = 50;

interface SelectSalesforceAccountProps extends SelectProps {
  accounts?: Partial<SalesforceAccount>[]
  onConnectionError?: (error: string) => void;
  disableFetchOnSearch?: boolean,
}

const SelectSalesforceAccount: FC<SelectSalesforceAccountProps> = forwardRef(function SelectSalesforceAccount({
  className,
  skeleton: skeletonProp,
  accounts: accountsProp,
  onConnectionError,
  disableFetchOnSearch = false,
  value,
  ...props
}, ref: any) {
  const initialAccounts = accountsProp || [];
  const initialAccountId = useRef<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [skeleton, setSkeleton] = useState<boolean>(false);
  const [cachedInitialAccounts, setCachedInitialAccounts] = useState<Partial<SalesforceAccount>[]>(initialAccounts);
  const [allOptions, setOptions] = useState<Partial<SalesforceAccount>[]>(initialAccounts);

  // Filter out undefined options and remove potential duplicates
  const options = useMemo(() =>
    sortBy(uniqBy(allOptions.filter((option) => option?.id !== undefined), 'id'), 'name')
  , [allOptions]);

  // Enable async search if it's not explicitly disabled
  const enableAsyncSearch = disableFetchOnSearch === false;

  // Fetch initial salesforce account if we have a value on mount
  useEffect(() => {
    let isCancelled = false;
    const fetchInitialAccount = async () => {
      setSkeleton(true);
      try {
        const { salesforce_accounts: [initialAccount] } = await novaGraphQLClient.fetchSalesforceAccounts({
          filter: { salesforce_account_id: value },
        });
        if (initialAccount && !isCancelled) {
          setCachedInitialAccounts(curr => [...curr, initialAccount]);
          setOptions(curr => [...curr, initialAccount]);
        }
        setSkeleton(false);
      } catch (error) {
        if (!isCancelled) {
          setSkeleton(false);
          onConnectionError?.('There was an error connecting to Salesforce. Please reload the page and try again');
        }
      }
    };

    // We want to fetch the initial account on mount
    // or if the initial value/id changes and we don't already have that account data
    if (
      value &&
      initialAccountId.current !== value &&
      !cachedInitialAccounts.find(a => a.id === value) &&
      !options.find(a => a.id === value)) {
      fetchInitialAccount();
    }

    initialAccountId.current = value;

    return () => {
      isCancelled = true;
    };
  }, [value]);

  // Fetching accounts manually
  const fetchAccounts: (name?: string | undefined) => Promise<Partial<SalesforceAccount>[] | undefined> = async (name?: string) => {
    // We don't want to re-fetch the initial accounts
    // if the search field is cleared and we have cached initial results
    if (!name && cachedInitialAccounts.length) {
      setOptions(cachedInitialAccounts);
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const response: { salesforce_accounts: Partial<SalesforceAccount>[] } = await novaGraphQLClient.fetchSalesforceAccounts({
        filter: { search_name: name ? name : '' },
        limit: FETCH_LIMIT,
      });

      // When search query is empty: Merge initial accounts (if passed down via. the accounts-prop)
      // with the accounts we get from the response (this would typically be the latest accounts)
      // so we have some initial results for the user to choose from
      const nextAccounts: Partial<SalesforceAccount>[] = !name ? [...initialAccounts, ...response.salesforce_accounts] : response.salesforce_accounts;

      setOptions(nextAccounts);
      setLoading(false);

      return nextAccounts;
    } catch (error) {
      setLoading(false);
      onConnectionError?.('There was an error connecting to Salesforce. Please reload the page and try again');
    }
  };

  // Fetch the initial accounts on load if needed
  useEffect(() => {
    let isCancelled = false;
    if (enableAsyncSearch) {
      (async () => {
        const initialResults = await fetchAccounts();

        if (!isCancelled && Array.isArray(initialResults)) {
          setOptions(initialResults);

          // Cache initial results so we don't need to fetch them again if the user clears the search field
          setCachedInitialAccounts(curr => [...curr, ...initialResults]);
        }
      })();
    }

    return () => {
      isCancelled = true;
    };
  }, [disableFetchOnSearch]);

  // Asynchronously fetch accounts when the user types in the search field if disableFetchOnSearch != true
  const handleSearch = debounce(fetchAccounts, 200);

  // Nothing found or loading content
  const renderContent = (menu: any) => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
    if (loading) {
      return (
        <Empty
          image={<Spin />}
          description='Loading...'
        />
      );
    }

    if (!options.length) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description='No results found'
        />
      );
    }

    return menu;
  };

  const renderOptions = useMemo(() => {
    return (options as (Partial<SalesforceAccount> & { id: number })[])
      .map((option) => (
        <Select.Option key={option?.id} value={option?.id}>
          {option?.name}
        </Select.Option>
      ));
  }, [options]);

  return (
    <Select
      className={className}
      dropdownClassName={css.salesforceDropdown}
      value={value}
      loading={loading}
      skeleton={skeletonProp || skeleton}
      dropdownRender={renderContent}
      onSearch={enableAsyncSearch ? handleSearch : undefined}
      optionFilterProp="name"
      filterOption={disableFetchOnSearch ? (input, option) => option?.name?.toLowerCase().includes(input.toLowerCase()) : false}
      ref={ref}
      placeholder="Select Salesforce Account"
      showSearch
      {...props}
    >
      {renderOptions}
    </Select>
  );
});

export default SelectSalesforceAccount;
