
import React, { memo, useMemo } from 'react';
import { Card, CardHeader, CardContent, Grid, KeyValue } from '@sprnova/nebula';
import { Task } from 'features/entitiesRedux/models/task';

type ViewStrategyOverviewProps = {
  task?: Task;
}

const ViewStrategyOverview = ({
  task
}: ViewStrategyOverviewProps): JSX.Element => {
  const strategyOverviewCardContent = useMemo(() => {
    return (
      <>
        <Grid container
          justifyContent={'space-around'}
          spacing={3}
        >
          <>
            <Grid item xs={12}>
              <KeyValue
                label='Strategy Name'
                value={task?.name}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyValue
                label='Service'
                value={task?.service?.name}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyValue
                label='Strategy Frequency'
                value={task?.strategy_frequency?.name}
              />
            </Grid>
          </>
        </Grid>
      </>
    );
  }, [task?.name, task?.service?.name, task?.strategy_frequency?.name]);

  return (
    <Card
      id={'strategy-overview-navigation'}
      disablePadding
      sx={{ marginBottom: '24px' }}
      header={
        <CardHeader title="Strategy Overview" />
      }
    >
      <CardContent>
        {strategyOverviewCardContent}
      </CardContent>
    </Card>
  );
};

/**
 * Strategy Overview card in Read mode
 */
export default memo(ViewStrategyOverview);
