/**
 * Get Scoreboard (report) indicator earliest date
 */
import { baseApi, cleanArguments } from '../..';
import tagTypes from '../tagTypes';

export type GetIndicatorEarlyDateQuery = {
  report_id: number;
  indicator_id?: number;
}

export type GetIndicatorEarlyDateResult = string;

export const GET_INDICATOR_EARLY_DATE_ENDPOINT_KEY = 'getIndicatorEarlyDate';

export const { useGetIndicatorEarlyDateQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_INDICATOR_EARLY_DATE_ENDPOINT_KEY]: build.query<GetIndicatorEarlyDateResult, GetIndicatorEarlyDateQuery>({
      transformResponse: (response: { indicator_early_date: GetIndicatorEarlyDateResult}) => response.indicator_early_date,
      providesTags: (report) => {
        return report ? [
          { id: report, type: tagTypes.INDICATOR_EARLY_DATE }
        ] : [tagTypes.INDICATOR_EARLY_DATE];
      },
      query: ({
        ...args
      }) => {

        return {
          body: {
            query: {
              indicator_early_date: {
                __args: cleanArguments(args),
              }
            }
          }
        };
      }
    }),
  }),
});
