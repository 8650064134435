import React from 'react';
import { AlertRibbon } from 'features/intelligence/components/library-components';
import css from './TrainingMessage.module.scss';

const TrainingMessage = (): JSX.Element => {
  return (
    <AlertRibbon
      sx={{margin: '0 45px'}}
      className={css.root}
      title="Forecast Training Required"
      content={
        <>
          <div>
            <p className={css.description}>
            nova has to complete a model training for every client’s first forecast.
            You will receive an email with instructions on how to create a forecast using
            your client’s new model after the training process is successfully completed.
            </p>
          </div>
        </>
      }
      type="info"
    />
  );
};

export default TrainingMessage;
