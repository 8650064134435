import React, { memo, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Button, Grid } from '@sprnova/nebula';
import { LoadingIndicator } from 'features/clients/ClientIntegrationsPage/components/ManageIntegrationsTab/components/StyledComponents';

type CreateScoreboardFormActionsProps = {
  isCreating?: boolean;
  disabled?: boolean;
  redirectPath: string;
  isEdit?: boolean;
}

/**
 * Renders form action buttons for creating a scoreboard, including submit and cancel buttons.
 *
 * @param {CreateScoreboardFormActionsProps} props - The component props
 * @returns {JSX.Element} The rendered component
 */
const CreateScoreboardFormActions: React.FC<CreateScoreboardFormActionsProps> = ({isCreating, redirectPath, disabled, isEdit}) => {
  const history = useHistory();

  // Redirect if create scoreboards canceled
  const handleCancelButtonClick = (): void => {
    history.push(redirectPath);
  };

  const buttonText = useMemo(() => {
    if (isEdit) {
      return isCreating ? 'Saving...' : 'Save Changes';
    } else {
      return isCreating ? 'Creating...' : 'Create Scoreboard';
    }
  }, [isCreating, isEdit]);

  return (
    <Grid container justifyContent="flex-end" spacing={2}>
      {/* Button to cancel and redirect */}
      <Grid item>
        <Button size="large" onClick={handleCancelButtonClick} disabled={isCreating} >
          Cancel
        </Button>
      </Grid>

      {/* Button to submit the form */}
      <Grid item>
        <Button variant="primary" size="large" type="submit" disabled={isCreating || disabled} >
          {isCreating && <LoadingIndicator />}
          {buttonText}
        </Button>
      </Grid>
    </Grid>
  );
};

export default memo(CreateScoreboardFormActions);
