import React from 'react';
import classNames from 'classnames';
import { Service } from 'features/entitiesRedux';
import { Popover } from 'components';
import { StatusIndicatorDot } from './StatusIndicatorDot';
import css from './StatusIndicator.module.scss';

interface StatusIndicatorProps {
  activeServices?: any[];
  allServices?: Service[];
  className?: string;
  style?: React.CSSProperties;
}

export const StatusIndicator = ({
  activeServices,
  allServices,
  className,
  style,
}: StatusIndicatorProps) => {
  const status = `${activeServices?.length || 0}/${allServices?.length} selected`;
  const statusClass = activeServices?.length ? 'complete' : 'pending';

  return (
    <>
      {statusClass !== 'pending' ?
        <Popover content={status}>
          <div className={classNames(className, css.root)} style={style}>
            <div className={css.wrapper}>
              <StatusIndicatorDot status={statusClass} />
            </div>
          </div>
        </Popover> : <></>
      }
    </>
  );
};
