import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import { Box, IconButton, useTheme} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import TableRow from '@mui/material/TableRow';
import {
  Container,
  EditIcon,
  Grid,
  Paper,
  TextField,
  Skeleton,
  PlusIcon,
  Button,
  IconButton as IconButtonNebula,
  TrashIcon,
  Tooltip
} from '@sprnova/nebula';
import { Action, Resource } from 'api/accessControl';
import versionConfig from 'api/utils/versionConfig';
import { AccessControl } from 'components/AccessControl';
import { isEqual } from 'lodash';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import useMixpanelTrack from 'utils/hooks/useMixpanelTrack';
import EntityPropertyType from 'utils/sort/EntityPropertyType';
import Order from 'utils/sort/Order';
import { compareByName, getComparator, stableSort } from 'utils/sort/sortingUtil';
import { Department, User , StrategyOutsourceCostGuideItem, Service } from 'features/entitiesRedux';
import { useAccessControl, useAccount } from 'features/global';
import { fieldBaseStyles } from 'features/intelligence/components/library-components/Dropdown/constants';
import { Dropdown } from 'features/intelligence/components/library-components/Dropdown/Dropdown';
import LibraryPageHero from 'features/library/components/LibraryPageHero';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import allOutsourceGuideItemsQueryString from './allOutsourceGuideItemsQueryString';
import EnhancedTableHead from './EnhancedTableHead';
import HeadCellType from './HeadCellType';
import Item from './Item';
import css from './OutsourceCostGuidePage.module.scss';

const OutsourceCostGuidePage = (): JSX.Element => {
  const history = useHistory();
  /** Event Tracking */
  const mixpanel = useMixpanelTrack();
  // used for reverting back to original data set after filtering is cancelled out
  const [originalOutsourceCostGuideItems, setOriginalOutsourceCostGuideItems] = useState([]);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<EntityPropertyType>({ entity: 'department', property: 'name' });
  const [departments, setDepartments] = useState<Partial<Department>[]>([]);
  const [services, setServices] = useState([]);
  const [internalContacts, setInternalContacts] = useState<Partial<User>[]>([]);
  const [departmentSelected, setDepartmentSelected] = useState<Partial<Department>>();
  const [serviceSelected, setServiceSelected] = useState<string>('');
  const [internalContactSelected, setInternalContactSelected] = useState<Partial<User>>();
  const [loadedData, setLoadedData] = useState<boolean>(false);

  const { account } = useAccount();
  const { can } = useAccessControl();
  const canDelete = can(Action.delete, Resource.libraryOutsourceCost, account?.id);
  const canEdit = can(Action.update, Resource.libraryOutsourceCost, account?.id);

  const [pricingVersionQueryParam] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );
  const canReadLibraryOutsourceCostPackage = can(Action.read, Resource.libraryOutsourceCost);
  const isPackageVersion = pricingVersionQueryParam === PricingVersion.PACKAGE;

  /**
   * Use ref to trigger useEffect only once
   */
  const didSendPageViewEventRef = useRef(false);

  // Track mixpanel event when page is visited for package version only
  useEffect(() => {
    if (!didSendPageViewEventRef.current && account && isPackageVersion) {
      didSendPageViewEventRef.current = true;
      mixpanel('Outsource Cost Guide Page Viewed', {
        title: account?.name,
        userId: account?.id,
        userName: account?.name,
        department: account?.department?.name,
        departments: JSON.stringify(account?.departments?.map((department: Department) => department.name))
      });
    }
  }, [account, isPackageVersion, mixpanel]);

  const TablePaginationActions = (props: TablePaginationActionsProps) : JSX.Element => {
    const { count, page, rowsPerPage, onPageChange } = props;

    const theme = useTheme();

    const handleBackButtonClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
    }, [onPageChange, page]);

    const handleNextButtonClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
    }, [onPageChange, page]);

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
      </Box>
    );
  };

  const handleRequestSort = useCallback((
    event: React.MouseEvent<unknown>,
    sortOrderObject: EntityPropertyType
  ) => {
    const isAsc = isEqual(orderBy, sortOrderObject) && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(sortOrderObject);
  }, [order, orderBy]);

  /**
   * Return unique department names for dropdown
   * @param itemsArray
   * @returns {Partial<Department>[]} unique departments
   */
  const getUniqueDepartments = useCallback((itemsArray: any): Partial<Department>[] => {
    const uniqueDepartments: Partial<Department>[] = [];
    const departmentsAdded: { [key: string]: boolean } = {};
    itemsArray.forEach((item: any) => {
      if (!departmentsAdded[item.department.name as keyof typeof departmentsAdded]) {
        uniqueDepartments.push(item.department);
        departmentsAdded[item.department.name] = true;
      }
    });
    return uniqueDepartments;
  }, []);

  /**
   * Return unique internal contacts for dropdown
   * @param itemsArray
   * @returns {Partial<User>[]} unique internal contacts
   */
  const getUniqueInternalContacts = useCallback((itemsArray: any): Partial<User> [] => {
    const uniqueInternalContacts: Partial<User>[] = [];
    const internalContactsAdded: { [key: string]: boolean } = {};
    itemsArray.forEach((item: any) => {
      if (!internalContactsAdded[item.internal_contact.name as keyof typeof internalContactsAdded]) {
        uniqueInternalContacts.push(item.internal_contact);
        internalContactsAdded[item.internal_contact.name] = true;
      }
    });
    return uniqueInternalContacts;
  }, []);

  const fetchAllOutsourceGuideItems = useCallback(() => {
    fetch(`${import.meta.env.REACT_APP_PLATFORM_API_HOST}`, {
      method: 'POST',
      headers: versionConfig[import.meta.env.REACT_APP_PLATFORM_API_VERSION || 'v1'],
      body: JSON.stringify({
        query: allOutsourceGuideItemsQueryString,
        variables: {
          pricing_version: pricingVersionQueryParam
        }
      }),
    }).then((response) => {
      response.json().then((data) => {
        if (data && data.data && data.data.outsource_costs_guide_items) {
          setOriginalOutsourceCostGuideItems(data.data.outsource_costs_guide_items);
          setDepartments(getUniqueDepartments(data.data.outsource_costs_guide_items).sort((departmentA: Partial<Department>, departmentB: Partial<Department>) => compareByName(departmentA, departmentB)));
          // only grabbing unique values for services because items have the same service name
          setServices(data.data.outsource_costs_guide_items.map((item: any) => item.service)
            .filter((value: string, index: number, array: Array<string>) => array.indexOf(value) === index)
            .sort());
          setInternalContacts(getUniqueInternalContacts(data.data.outsource_costs_guide_items)
            .sort((internalContactA: Partial<User>, internalContactB: Partial<User>) => compareByName(internalContactA, internalContactB)));
        }
      })
        .catch((err) => {console.log(err);})
        .finally(() => {setLoadedData(true);});
    }).catch((err) => { console.log(err); });
  }, [getUniqueDepartments, getUniqueInternalContacts, pricingVersionQueryParam]);

  useEffect(() => {
    fetchAllOutsourceGuideItems();
  }, [fetchAllOutsourceGuideItems]);

  const shouldClearAllFilters = useCallback(() => {
    if (!departmentSelected && !serviceSelected && !internalContactSelected) {
      return true;
    }
    return false;
  }, [departmentSelected, internalContactSelected, serviceSelected]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const resetPage = () => setPage(0);

  /**
     * Apply filters in combination with each other
     * If none are selected, revert to the original data set
     * @returns [] new filtered outsource cost guide items
     */
  const getFilteredOutsourceCostGuideItems = useCallback(() => {
    if (shouldClearAllFilters()) {
      return originalOutsourceCostGuideItems;
    } else {
      let newOutsourceCostGuideItems = [...originalOutsourceCostGuideItems];

      if (departmentSelected) {
        newOutsourceCostGuideItems = newOutsourceCostGuideItems.filter((item: any) => item.department.name.toLowerCase().includes(departmentSelected.name ? departmentSelected.name.toLowerCase() : ''));
      }

      if (serviceSelected) {
        newOutsourceCostGuideItems = newOutsourceCostGuideItems.filter((item: any) => item.service.toLowerCase().includes(serviceSelected.toLowerCase()));
      }

      if (internalContactSelected) {
        newOutsourceCostGuideItems = newOutsourceCostGuideItems.filter((item: any) => item.internal_contact.name.toLowerCase().includes(internalContactSelected.name ? internalContactSelected.name.toLowerCase() : ''));
      }
      resetPage();
      return newOutsourceCostGuideItems;
    }

  }, [departmentSelected, internalContactSelected, originalOutsourceCostGuideItems, serviceSelected, shouldClearAllFilters]);

  const outsourceCostGuideItems = useMemo(() => originalOutsourceCostGuideItems ? getFilteredOutsourceCostGuideItems() : [], [getFilteredOutsourceCostGuideItems, originalOutsourceCostGuideItems]);

  const visibleRows = React.useMemo(
    () =>
      stableSort(outsourceCostGuideItems, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, outsourceCostGuideItems, page, rowsPerPage],
  );

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = useMemo(() => page > 0 ? Math.max(0, (1 + page) * rowsPerPage - outsourceCostGuideItems.length) : 0, [outsourceCostGuideItems.length, page, rowsPerPage]);

  const handleChangePage = useCallback((
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleOnClickEdit = useCallback((outsourceCostGuideItem: StrategyOutsourceCostGuideItem) => {
    const pathname = `/library/outsource-cost-guide/${outsourceCostGuideItem.id}/edit`;
    history.push({ pathname, search: `?pricingVersion=${pricingVersionQueryParam}`, state: outsourceCostGuideItem });
  }, [history, pricingVersionQueryParam]);

  const headCells: Partial<HeadCellType>[] = useMemo(() => [
    {
      id: 'department',
      label: 'Department',
      disablePadding: false,
      isNumeric: false,
      entity: 'department',
      property: 'name'
    },
    {
      id: 'service',
      label: 'Service',
      disablePadding: false,
      isNumeric: false,

    },
    {
      id: 'internalContact',
      label: 'Internal Contact',
      disablePadding: false,
      isNumeric: false

    },
    {
      id: 'amount',
      label: 'Amount',
      disablePadding: false,
      isNumeric: true
    },
    {
      id: 'frequencyOfCost',
      label: 'Frequency of Cost',
      disablePadding: false,
      isNumeric: false,
    },
    {
      id: 'notes',
      label: 'Notes',
      disablePadding: false,
      isNumeric: false,
    },
    // This is the column for the edit and delete icons that doesn't need a label or sorting
    {
      id: 'actions',
      label: '',
      disablePadding: false,
      isNumeric: false
    }
  ], []);

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [selectedDeleteRowId, setSelectedDeleteRowId] = useState(null);
  const handleDeleteDialogClickOpen = useCallback((row: any) => {
    setOpenDeleteDialog(true);
    setSelectedDeleteRowId(row.id);
  }, []);
  const handleDeleteDialogClose = useCallback(() => {
    setOpenDeleteDialog(false);
    setSelectedDeleteRowId(null);
  }, []);

  const renderEditButton = useCallback((row: any) => {
    return (
      <Tooltip content="Edit outsource cost" placement="bottom">
        <IconButtonNebula size="md" onClick={() => handleOnClickEdit(row)} disabled={!canEdit}>
          <EditIcon />
        </IconButtonNebula>
      </Tooltip>
    );
  }, [canEdit, handleOnClickEdit]);

  const renderDeleteButton = useCallback((row: any) => {
    return (
      <Tooltip content="Delete outsource cost" placement="bottom">
        <IconButtonNebula size="md" onClick={() => handleDeleteDialogClickOpen(row)} disabled={!canDelete}>
          <TrashIcon />
        </IconButtonNebula>
      </Tooltip>
    );
  }, [canDelete, handleDeleteDialogClickOpen]);

  /**
   * Render the action buttons container
   * If pricing version is Package, use Library Package Access Control.
   * @param row The individual row
   * @returns {JSX.Element} The action buttons container: Edit and Delete buttons
   */
  const renderActionButtonsContainer = useCallback((row: any): JSX.Element => {
    return (
      <div>
        {renderEditButton(row)}
        {renderDeleteButton(row)}
      </div>
    );
  }, [renderDeleteButton, renderEditButton]);

  const tableContainer = useMemo(() => {
    return (
      <TableContainer component={Paper} className={css.table__container}>
        <Table aria-label="custom pagination table">
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={outsourceCostGuideItems.length}
            className={css.table__header}
          />
          <TableBody className={css.table__body}>
            {visibleRows.map((row: any) => (
              <TableRow key={row.id}>
                <TableCell style={{ width: 160 }}>
                  {row.department.name}
                </TableCell>
                <TableCell style={{ width: 160 }}>
                  {row.service}
                </TableCell>
                <TableCell style={{ width: 160 }}>
                  {row.internal_contact.name}
                </TableCell>
                <TableCell style={{ width: 160 }}>
                  {row.amount}
                </TableCell>
                <TableCell style={{ width: 160 }}>
                  {row.frequency_of_cost}
                </TableCell>
                <TableCell style={{ width: 250 }}>
                  {row.notes_or_description}
                </TableCell>
                <TableCell style={{ width: 110 }} className={css.cell__action}>
                  {renderActionButtonsContainer(row)}
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [emptyRows, handleRequestSort, headCells, order, orderBy, outsourceCostGuideItems.length, renderActionButtonsContainer, visibleRows]);

  const tablePagination = useMemo(() => {
    return (
      <div className={css.table__pagination}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          colSpan={3}
          count={outsourceCostGuideItems.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={memo(TablePaginationActions)}
        />
      </div>
    );
  },[handleChangePage, handleChangeRowsPerPage, outsourceCostGuideItems.length, page, rowsPerPage]);

  const tablePackageAccessControl = useMemo(() => {
    return (
      <AccessControl
        action={[Action.read]}
        resource={Resource.libraryOutsourceCost}
      >
        <>
          {tableContainer}
          {tablePagination}
        </>
      </AccessControl>
    );
  }, [tableContainer, tablePagination]);

  const handleDeleteOutsourceCostGuideItem = useCallback(() => {
    if (selectedDeleteRowId) {
      fetch(`${import.meta.env.REACT_APP_PLATFORM_API_HOST}`, {
        method: 'POST',
        headers: versionConfig[import.meta.env.REACT_APP_PLATFORM_API_VERSION || 'v1'],
        body:
          JSON.stringify({
            query:
              `mutation { deleteOutsourceCostsGuideItem (id: ${selectedDeleteRowId}) { id } }`,
          }),
      }).then((response) => {
        response.json().then((data) => {
          const deletedOutsourceCostGuideItem : any = originalOutsourceCostGuideItems.filter((item: any) => item.id === selectedDeleteRowId);

          setOriginalOutsourceCostGuideItems(originalOutsourceCostGuideItems.filter((item: any) => item.id !== selectedDeleteRowId));

          let checkIfExistDepartmentSelected = false;
          let checkIfExistServiceSelected = false;
          let checkIfExistInternalContactSelected = false;

          originalOutsourceCostGuideItems.filter((item: any) => {
            if(item.id !== selectedDeleteRowId) {
              if (departmentSelected && deletedOutsourceCostGuideItem[0].department.name && item.department.name.toLowerCase().includes(deletedOutsourceCostGuideItem[0].department.name.toLowerCase())) {
                checkIfExistDepartmentSelected = true;
              }
              if (serviceSelected && deletedOutsourceCostGuideItem[0].service && item.service.toLowerCase().includes(deletedOutsourceCostGuideItem[0].service.toLowerCase())) {
                checkIfExistServiceSelected = true;
              }

              if (internalContactSelected && deletedOutsourceCostGuideItem[0].internal_contact.name && item.internal_contact.name.toLowerCase().includes(deletedOutsourceCostGuideItem[0].internal_contact.name.toLowerCase())) {
                checkIfExistInternalContactSelected = true;
              }
            }
          });

          if (!checkIfExistDepartmentSelected) {
            setDepartmentSelected(undefined);
          }
          if (!checkIfExistServiceSelected) {
            setServiceSelected('');
          }
          if (!checkIfExistInternalContactSelected) {
            setInternalContactSelected(undefined);
          }

          setOpenDeleteDialog(false);
          setSelectedDeleteRowId(null);

          const mixpanelOptions =  {
            outsourceCostGuideItemId: deletedOutsourceCostGuideItem[0].id,
            outsourceCostGuideItem: deletedOutsourceCostGuideItem[0].name,
            outsourceCostGuideItemAmount: deletedOutsourceCostGuideItem[0].amount,
            outsourceCostGuideItemDepartmentId: deletedOutsourceCostGuideItem[0].department.id,
            outsourceCostGuideItemDepartmentName: deletedOutsourceCostGuideItem[0].department.name,
            outsourceCostGuideItemFrequencyOfCost: deletedOutsourceCostGuideItem[0].frequency_of_cost,
            outsourceCostGuideItemInternalContactId: deletedOutsourceCostGuideItem[0].internal_contact.id,
            outsourceCostGuideItemInternalContactName: deletedOutsourceCostGuideItem[0].internal_contact.name,
            outsourceCostGuideItemNotesOrDescription: deletedOutsourceCostGuideItem[0].notes_or_description,
            outsourceCostGuideItemService: deletedOutsourceCostGuideItem[0].service,
            pricingVersion: pricingVersionQueryParam
          };

          mixpanel('Library, deleted an outsource cost guide item', mixpanelOptions);
        }).catch((err) => {
          console.log(err);
        });
      }).catch((err) => {
        console.log(err);
      });
    }
  }, [selectedDeleteRowId, originalOutsourceCostGuideItems, pricingVersionQueryParam, mixpanel, departmentSelected, serviceSelected, internalContactSelected]);

  const renderAddButton = useCallback((): JSX.Element => {
    return (
      <Button
        variant="primary"
        size="large"
        to={`/library/outsource-cost-guide/new?pricingVersion=${pricingVersionQueryParam}`}
        component={Link}
        startIcon={<PlusIcon />}
      >
        New Outsource Cost
      </Button>
    );
  }, [pricingVersionQueryParam]);

  const renderHeroButtonContainer = useCallback((): JSX.Element => {
    const resource = isPackageVersion ? Resource.libraryOutsourceCost : Resource.strategy;
    return (
      <AccessControl
        action={[Action.create]}
        resource={resource}
        showWarning={false}
      >
        {renderAddButton()}
      </AccessControl>
    );
  }, [isPackageVersion, renderAddButton]);

  const renderTable = useCallback(() => {
    if (pricingVersionQueryParam === PricingVersion.PACKAGE) {
      return (
        <>
          {tablePackageAccessControl}
        </>
      );
    } else {
      return (
        <>
          {tableContainer}
          {tablePagination}
        </>
      );
    }
  }, [pricingVersionQueryParam, tableContainer, tablePackageAccessControl, tablePagination]);

  const dropdownStyle = (value: Partial<Department> | string | Partial<User> | undefined): Record<string, Record<string, any> | string | number> => {
    if (value) {
      return { ...fieldBaseStyles, margin: '20px' };
    } else {
      return { margin: '20px', '& .MuiOutlinedInput-notchedOutline': { borderColor: '#6D6D6D' } };
    }
  };

  return (
    <>
      <LibraryPageHero
        title={`Outsource Cost Guide ${isPackageVersion ? '(Package)' : ''}`}
        end={renderHeroButtonContainer()}
      />

      <div>
        <Dialog
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Delete this outsource cost guide item?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this outsource cost guide item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose}>No</Button>
            <Button onClick={handleDeleteOutsourceCostGuideItem}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Container hasVerticalPadding>
        {isPackageVersion && !canReadLibraryOutsourceCostPackage ? <></> :
          <Grid container sx={{ marginBottom: '20px', boxShadow: 0, border: '1px solid #D9D9D9', borderRadius: '8px', overflow: 'hidden' }}>
            <Grid xs={4} sx={{ borderRadius: 1 }}>
              <Item sx={{ boxShadow: 'none', borderRadius: '0px' }}>
                <Dropdown
                  sx={dropdownStyle(departmentSelected)}
                  id='departments'
                  keyLabel={'name'}
                  noOptionsText={'Loading options...'}
                  options={departments}
                  type='autocomplete'
                  handleSelect={(event, department) => {
                    setDepartmentSelected(department);
                    resetPage();
                  }}
                  value={departmentSelected}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={departmentSelected ? 'Department' : 'Search Department'}
                    />
                  )}
                />
              </Item>
            </Grid>
            <Grid xs={4}>
              <Item sx={{ boxShadow: 'none', borderRadius: '0px' }}>
                <Dropdown
                  id='services'
                  sx={dropdownStyle(serviceSelected)}
                  keyLabel={'name'}
                  noOptionsText={'Loading options...'}
                  options={services}
                  type='autocomplete'
                  handleSelect={(event, serviceName) => {
                    setServiceSelected(serviceName);
                    resetPage();
                  }}
                  value={serviceSelected}
                  renderInput={(params): JSX.Element => (
                    <TextField
                      {...params}
                      label={serviceSelected ? 'Service' : 'Search Service'}
                    />
                  )}
                />
              </Item>
            </Grid>
            <Grid xs={4} sx={{ borderRadius: 1 }}>
              <Item sx={{ boxShadow: 'none', borderRadius: '0px' }}>
                <Dropdown
                  id='internal_contacts'
                  sx={dropdownStyle(internalContactSelected)}
                  keyLabel={'name'}
                  noOptionsText={'Loading options...'}
                  options={internalContacts}
                  type='autocomplete'
                  handleSelect={(event, internalContact) => {
                    setInternalContactSelected(internalContact);
                    resetPage();
                  }}
                  value={internalContactSelected}
                  renderInput={(params): JSX.Element => (
                    <TextField
                      {...params}
                      label={internalContactSelected ? 'Internal Contact' : 'Search Internal Contact'}
                    />
                  )}
                />
              </Item>
            </Grid>
          </Grid>
        }
        {outsourceCostGuideItems.length === 0 && !loadedData ?
          <Skeleton variant='rectangular'>
            {isPackageVersion ? tablePackageAccessControl : tableContainer}
          </Skeleton>
          :
          <>
            {renderTable()}
          </>
        }
      </Container>
    </>
  );
};

export default memo(OutsourceCostGuidePage);
