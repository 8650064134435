import React, { forwardRef } from 'react';
import { Input as AntdInput } from 'antd';
import { TextAreaProps as AntTextAreaProps } from 'antd/lib/input';
import classNames from 'classnames';
import { SkeletonInput } from '../SkeletonInput';
import css from './TextArea.module.scss';

export interface TextAreaProps extends AntTextAreaProps {
  className?: string;
  skeleton?: boolean;
}

const TextArea = forwardRef(
  ({ className, skeleton = false, ...props }: TextAreaProps, ref: any) => {
    // Show skeleton when loading is true
    if (skeleton) return <SkeletonInput loading={false} active={true} />;

    return (
      <AntdInput.TextArea
        {...props}
        className={classNames(className, css.root)}
        ref={ref}
      />
    );
  }
);

TextArea.displayName = 'TextArea';

export default TextArea;
