/**
 * Entities -> Organizations -> Actions
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { novaGraphQLClient, Projection } from 'api/entityGraphQL';
import { normalizeEntities } from 'features/entitiesRedux/utils';
import { FetchOrganizationsFilter } from './organization';

/** Fetch Many Paginated */
export const fetchOrganizations = createAsyncThunk(
  'entities/fetchOrganizations',
  async (config: {
    projection?: Projection;
    filter?: FetchOrganizationsFilter;
  }): Promise<any> => {
    const { organizations } = await novaGraphQLClient.fetchOrganizations(
      config
    );
    return { ...normalizeEntities({ organizations }) };
  }
);

/** Fetch one */
export const fetchOrganizationById = createAsyncThunk(
  'entities/fetchOrganizationById',
  async ({ id, ...config }: { id: number; projection?: Projection }) => {
    const { organizations } = await novaGraphQLClient.fetchOrganizationById(
      id,
      {
        ...config,
      }
    );
    return { ...normalizeEntities({ organizations }) };
  }
);

/** Create */
// export const createOrganization = createAsyncThunk(
//   'entities/createOrganization',
//   async (values: Partial<Organization>) => {
//     const response = await novaGraphQLClient.createOrganization(values);
//     return { ...normalizeEntities(response) };
//   }
// );

/* Update */
// export const updateOrganization = createAsyncThunk(
//   'entities/updateOrganization',
//   async (values: Partial<Organization>) => {
//     const response = await novaGraphQLClient.updateOrganization(values);
//     return { ...normalizeEntities(response) };
//   }
// );

/** Delete */
// export const deleteOrganization = createAsyncThunk(
//   'entities/deleteOrganization',
//   async (id: number) => {
//     try {
//       await novaGraphQLClient.deleteOrganization(id);
//       return id;
//     } catch (error) {
//       console.error('Error deleting organization', error);
//       return { ...normalizeEntities({}) };
//     }
//   }
// );
