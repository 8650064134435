import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, Grid, InfoIcon, InfoTooltip, Tooltip, Typography } from '@sprnova/nebula';
import { useGetScoreboardDataSourcesPublicQuery } from 'api/crudGraphQL/public/scoreboards/getScoreboardDataSourcesPublic';
import { useGetScoreboardDataSourcesQuery } from 'api/crudGraphQL/scoreboards/getScoreboardDataSources';
import { Indicator } from 'api/crudGraphQL/scoreboards/types';
import pluralize from 'pluralize';
import { useFilterContext } from 'features/scoreboards/utils/Filters';
import EditGoalFilters from './EditGoalFilters';
import PercentageTag from '../../components/PercentageTag';
import Platforms from '../../components/Platforms';
import { formatValue } from '../../components/utils';

type ModifiedPlatform = (Indicator['platform'] & { last_refresh_status: string }) | undefined;
export interface FormattedDataType extends Indicator {
  formattedPlatform: ModifiedPlatform[] | undefined;
}

type ScorecardProps = {
  data: Indicator;
  isClient?: boolean;
  fixedHeight?: boolean;
  homepageView?: boolean;
  defaultComparisonRange?: string;
};

export const Scorecard: React.FC<ScorecardProps> = ({ data, isClient = false, fixedHeight = false, homepageView = false, defaultComparisonRange }) => {
  const { filter } = useFilterContext();
  const { id } = useParams<{ [x: string]: string }>();

  const { data: dataSourcesQuery } = (isClient ? useGetScoreboardDataSourcesPublicQuery : useGetScoreboardDataSourcesQuery)({
    report_id: parseInt(id),
    projection: {
      report_id: true,
      client_id: true,
      data_source_id: true,
      data_source_name: true,
      data_source_slug: true,
      warehouse_source: true,
      last_refresh_status: true,
    }
  });

  const formattedData: FormattedDataType | undefined | any = useMemo(() => {
    if (!dataSourcesQuery) return data;
    return {
      ...data,
      formattedPlatform: data.platform
        ?.map((item) => {
          return ({
            ...item,
            last_refresh_status: dataSourcesQuery.filter(source => {
              return source.data_source_slug === item.platform;
            })?.[0]?.last_refresh_status
          });
        })
    };
  }, [data, dataSourcesQuery]);

  const renderComparedToSection = useMemo(() => {
    if (!homepageView && !filter?.compared) {
      /**
       * If it's not homepageView and compared is not selected, we don't need to show this section
       */
      return <></>;
    }
    if (data.compared_value) {
      return (
        <Grid item display={'flex'} alignItems={'center'}>
          <PercentageTag comparedValues={data.compared_value} />
          <Typography variant='caption' sx={{paddingLeft: '8px'}}>
            {'vs. '}
            {defaultComparisonRange ? defaultComparisonRange : `${filter?.range.replace(/_/g, ' ')}`}
            {` (${formatValue(data.compared_value[1], data.format, true)})`}
          </Typography>
        </Grid>
      );
    }
    return null;
  }, [data.compared_value, data.format, defaultComparisonRange, filter?.compared, filter?.range, homepageView]);

  const renderDataFromIntegratedPlatforms = useMemo(() => {
    if (formattedData?.platform) {
      const length = formattedData.platform.length;
      return (
        <Tooltip
          variant='rich'
          placement={homepageView ? 'bottom-start' : 'right-start'}
          heading='Platforms'
          content={
            <Grid container style={{width: '250px'}}>
              <Platforms data={formattedData?.formattedPlatform} required_categories={formattedData?.required_platform_category}/>
            </Grid>
          }
        >
          <Typography variant='caption'>
            <Box display='flex' justifyContent='flex-start' alignItems='center'>
              Data from {length} integrated {pluralize('platforms', length)} <InfoIcon sx={{ fontSize: '12px', marginLeft: '4px' }} />
            </Box>
          </Typography>
        </Tooltip>
      );
    }
  }, [formattedData?.platform, formattedData?.formattedPlatform, formattedData?.required_platform_category, homepageView]);

  if (!data) {
    return <></>;
  }

  return (
    <Card
      textAlign='left'
      sx={{
        boxShadow: 'none',
        '& .default-text': {
          color: '#4A1FFF',
        },
        padding: '0 0 24px 0',
      }}
    >
      <Box>
        <Grid container display={'flex'} justifyContent={'space-between'}>
          <Grid item display={'flex'} alignItems={'center'}>
            <Typography
              variant='h5'
              className='default-text'
              sx={{
                margin: 0,
                paddingRight: '8px'
              }}>
              {data.name}
            </Typography>
            <InfoTooltip content={data.tooltip} />
          </Grid>
          {
            formattedData?.formattedPlatform
              && <EditGoalFilters isClient={isClient} info={data.tooltip} />
          }
        </Grid>
        <Grid item padding='8px 0'>
          <Typography variant='h3' className='default-text'>{formatValue(data.value, data.format)}</Typography>
        </Grid>
        {renderComparedToSection}
        <Grid item paddingTop='8px' height={fixedHeight ? '28px' : ''}>
          <Typography variant='caption'
            sx={{
              fontSize: '12px',
              fontWeight: 500,
              color: '#838383',
            }}
          >
            {renderDataFromIntegratedPlatforms}
          </Typography>
        </Grid>
      </Box>
    </Card>
  );
};
