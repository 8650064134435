/**
 * SectionBuilder -> Fields -> CompetitorsField
 *
 * Dynamic FormList for adding multiple competitors
 */

import React, { FC } from 'react';
import { MinusCircleOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';
import {
  Button,
  FormItem,
  FormList,
  Input, Tooltip,
} from 'components';
import { FieldProps } from '../../types';
import css from '../fieldgroup_shared.module.scss';

const CompetitorsField: FC<FieldProps> = ({ name }) => {
  return (
    <div className={css.root}>
      <FormList name={name}>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field) => (
                <Row key={field.key} align="top" justify="space-between">
                  <Col xs={23} className={css.column_fieldset}>
                    <Row gutter={[12, 0]}>
                      <Col xs={24} lg={12}>
                        <FormItem
                          name={[field.name, 'name']}
                          label="Name"
                          fieldKey={field.fieldKey}
                        >
                          <Input placeholder="Enter a competitor name" />
                        </FormItem>
                      </Col>
                      <Col xs={24} lg={12}>
                        <FormItem
                          name={[field.name, 'website']}
                          label="Website"
                          fieldKey={field.fieldKey}
                        >
                          <Input />
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={1} className={css.column_delete}>
                    <Tooltip title="Delete">
                      <MinusCircleOutlined
                        className={css.delete}
                        onClick={() => remove(field.name)}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              ))}
              <FormItem>
                <Button type="text" header icon={<PlusCircleFilled />} onClick={() => add()}>
                  Add competitor
                </Button>
              </FormItem>
            </div>
          );
        }}
      </FormList>
    </div>
  );
};

export default CompetitorsField;
