import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Grid, Tooltip, Typography, TextField, TrashIcon, IconButton, Button, PlusIcon, MonetaryField, Textarea } from '@sprnova/nebula';
import classNames from 'classnames';
import { TextEditor } from 'components/TextEditor';
import { valuePresentStyles as dropdownStyles } from 'features/users/UserOverviewPage/components/constants';
import { maxCharactersSnippetSummary } from '../constants';
import { DeleteTierModal } from '../DeleteTierModal';
import { ActionType, FormDataType } from '../packageStrategyTypes';
import { duplicateTierNameErrorHelperText, spendMaxTierHelperText, spendMinTierHelperText, spendTierMaximumErrorHelperText, spendTierMinimumErrorHelperText } from '../packageStrategyUtil';
import css from './../package-strategies.module.scss';
import cssContractDetails from './ContractDetails.module.scss';

type SpendDataObject = {
  name: string;
  spend_min: number;
  spend_max: number;
  price: number;
}

type SpendContractDetailsProps = {
  isSubmitted: boolean;
  dispatch: React.Dispatch<any>;
  formData: Partial<FormDataType>;
}

const SpendContractDetails = ({ isSubmitted, dispatch, formData }: SpendContractDetailsProps): JSX.Element => {
  const defaultSpendDataObjectsCount = useMemo(() => 3, []);
  const [selectedDeleteTierIndex, setSelectedDeleteTierIndex] = useState<number | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  /**
   * Returns an array of SpendDataObject with default values
   *
   * @returns {Array<SpendDataObject>} An array of SpendDataObject with default values
   */
  const getInitialSpendDataObjects = useCallback(() => {
    const initSpendDataObjects = [];
    for (let i = 0; i < defaultSpendDataObjectsCount; i++) {
      initSpendDataObjects.push({
        name: '',
        spend_min: 0,
        spend_max: 0,
        price: 0
      });
    }
    return initSpendDataObjects;
  }, [defaultSpendDataObjectsCount]);

  const [spendData, setSpendData] = useState<Array<SpendDataObject>>(getInitialSpendDataObjects());

  // initialize form data with default spend values
  useEffect(() => {
    if (spendData && spendData.length > 0) {
      dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS, payload: spendData});
    }
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_DATA, payload: { snippet_summary: '' }});
  }, [dispatch, spendData]);

  /**
   * Handles the change of the tier name
   * @param {React.ChangeEvent<HTMLInputElement>} event The event object
   * @param {number} index The index of the spend object
   * @returns {void}
   */
  const handleSpendNameChange = useCallback((event, index: number) => {
    const newSpendData = spendData.map((spendDataObject, spendDataObjectIndex) => {
      if (spendDataObjectIndex === index) {
        return {
          ...spendDataObject,
          name: event.target.value
        };
      }
      return spendDataObject;
    });
    setSpendData(newSpendData);
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS, payload: newSpendData});
  }, [dispatch, spendData]);

  /**
   * Handles the change of the spend range minimum
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} event The event object
   * @param {number} index The index of the spend object
   * @returns {void}
   */
  const handleSpendRangeMinimumChange = useCallback((event, index: number) => {
    const newSpendData = spendData.map((spendDataObject, spendDataObjectIndex) => {
      if (spendDataObjectIndex === index) {
        return {
          ...spendDataObject,
          spend_min: event.target.value
        };
      }
      return spendDataObject;
    });
    setSpendData(newSpendData);
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS, payload: newSpendData});
  }, [dispatch, spendData]);

  /**
   * Handles the change of the spend range maximum
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} event The event object
   * @param {number} index The index of the spend object
   * @returns {void}
   */
  const handleSpendRangeMaximumChange = useCallback((event, index: number) => {
    const newSpendData = spendData.map((spendDataObject, spendDataObjectIndex) => {
      if (spendDataObjectIndex === index) {
        return {
          ...spendDataObject,
          spend_max: event.target.value
        };
      }
      return spendDataObject;
    });
    setSpendData(newSpendData);
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS, payload: newSpendData});
  }, [dispatch, spendData]);

  /**
   * Handles the change of the quantity price
   * @param {React.ChangeEvent<HTMLInputElement>} event The event object
   * @param {number} index The index of the spend object
   * @returns {void}
   */
  const handleTierPriceChange = useCallback((event, index: number) => {
    const newSpendData = spendData.map((spendDataObject, spendDataObjectIndex) => {
      if (spendDataObjectIndex === index) {
        return {
          ...spendDataObject,
          price: Number.parseFloat(event.target.value)
        };
      }
      return spendDataObject;
    });
    setSpendData(newSpendData);
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS, payload: newSpendData});
  }, [dispatch, spendData]);

  /**
   * Handles the deletion of a tier
   * @param {React.MouseEvent<HTMLButtonElement>} event The event object
   * @returns {void}
   */
  const handleDeleteTier = useCallback(() => {
    const newSpendData = spendData.filter((_, index:number) => index !== selectedDeleteTierIndex);
    setSpendData(newSpendData);
    setOpenDeleteDialog(false);
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS, payload: newSpendData});
  }, [spendData, dispatch, selectedDeleteTierIndex]);

  /**
   * Handles the opening of the delete dialog
   * Sets the selected tier index to be deleted
   * @param {number} spendDataObjectIndex The index of the spend object
   * @returns {void}
   */
  const handleDeleteDialogClickOpen = useCallback((spendDataObjectIndex: number) => {
    setOpenDeleteDialog(true);
    setSelectedDeleteTierIndex(spendDataObjectIndex);
  }, []);

  /**
   * Handles the closing of the delete dialog
   * Sets the selected tier index to null
   * @returns {void}
   */
  const handleDeleteDialogClose = useCallback(() => {
    setOpenDeleteDialog(false);
    setSelectedDeleteTierIndex(null);
  }, []);

  /**
   * Handles the addition of a new tier
   * @returns {void}
   */
  const handleAddNewTier = useCallback(() => {
    setSpendData([...spendData, {
      name: '',
      spend_min: 0,
      spend_max: 0,
      price: 0
    }]);
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS, payload: [...spendData, {
      name: '',
      spend_min: 0,
      spend_max: 0,
      price: 0
    }]});
  }, [dispatch, spendData]);

  /**
   * Handles the setting of the proposal snippet
   * @param {string} snippetHtml The html of the snippet
   * @param {number} index The index of the spend object
   */
  const handleSetProposalSnippet = useCallback((snippetHtml: string, index: number) => {
    const newSpendData = spendData.map((spendDataObject, spendDataObjectIndex) => {
      if (spendDataObjectIndex === index) {
        return {
          ...spendDataObject,
          snippet: snippetHtml
        };
      }
      return spendDataObject;
    });
    setSpendData(newSpendData);
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS, payload: newSpendData});
  }, [dispatch, spendData]);

  const tierCards = useMemo(() => spendData.map((spendDataObject, index) => {
    const isDuplicateTierName = formData?.pricing_tiers?.filter((_, idx) => idx !== index)
      .map(({ name }) => name.trim().toLowerCase())
      .includes(spendDataObject.name?.trim().toLowerCase()) && spendDataObject.name?.trim() !== '';
    return (
      <Card
        sx={{ paddingTop: 0, marginBottom: '24px' }}
        key={`spend-tier-${index}`}
      >
        <div>
          <div className={css.two__fields__container}>
            <TextField
              id={'tierNameInput' + index}
              label="Tier Name"
              value={spendDataObject.name}
              error={(!formData?.pricing_tiers?.[index]?.name || isDuplicateTierName) && isSubmitted}
              onChange={(event): void => handleSpendNameChange(event, index)}
              sx={spendDataObject.name ? dropdownStyles : undefined}
              helperText={isDuplicateTierName && duplicateTierNameErrorHelperText}
            />
            <Tooltip content="Delete tier" variant="plain">
              <IconButton
                sx={{ height: '100%' }}
                size="xl"
                onClick={(): void => handleDeleteDialogClickOpen(index)}
                disabled={spendData.length === 1}
              >
                <TrashIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classNames(css.two__fields__container, cssContractDetails.tier__quantity__container)}>
            <MonetaryField
              id={'spendRangeMinimum' + index}
              label="Spend Range Minimum"
              error={(!formData?.pricing_tiers?.[index]?.spend_min || Number(formData?.pricing_tiers?.[index]?.spend_min) > Number(formData?.pricing_tiers?.[index]?.spend_max)) && isSubmitted}
              helperText={ Number(formData?.pricing_tiers?.[index]?.spend_min) > Number(formData?.pricing_tiers?.[index]?.spend_max) ? spendTierMinimumErrorHelperText : spendMinTierHelperText }
              value={spendDataObject.spend_min}
              onChange={(event): void => handleSpendRangeMinimumChange(event, index)}
            />
            <MonetaryField
              id={'spendRangeMaximum' + index}
              label="Spend Range Maximum"
              error={(!formData?.pricing_tiers?.[index]?.spend_max || Number(formData?.pricing_tiers?.[index]?.spend_min) > Number(formData?.pricing_tiers?.[index]?.spend_max)) && isSubmitted}
              helperText={ Number(formData?.pricing_tiers?.[index]?.spend_min) > Number(formData?.pricing_tiers?.[index]?.spend_max) ? spendTierMaximumErrorHelperText : spendMaxTierHelperText }
              value={spendDataObject.spend_max}
              onChange={(event): void => handleSpendRangeMaximumChange(event, index)}
            />
          </div>
          <MonetaryField
            id={'tierPrice' + index}
            error={!formData?.pricing_tiers?.[index]?.price && isSubmitted}
            helperText='Price of this tier'
            label="Tier Price"
            value={spendDataObject.price}
            onChange={(event): void => handleTierPriceChange(event, index)}
          />
          <Typography sx={{ marginTop: '24px', fontWeight: 600 }} display="block" gutterBottom>
            Proposal Snippet
          </Typography>
          <TextEditor
            className={classNames(css.text_editor, !formData?.pricing_tiers?.[index]?.snippet && isSubmitted ? css.snippet_error : undefined)}
            onChange={(snippetHtml): void => {
              handleSetProposalSnippet(snippetHtml, index);
            }}
          />
        </div>
      </Card>
    );
  }
  ), [formData?.pricing_tiers, handleDeleteDialogClickOpen, handleSetProposalSnippet, handleSpendNameChange, handleSpendRangeMaximumChange, handleSpendRangeMinimumChange, handleTierPriceChange, isSubmitted, spendData]);

  return (
    <>
      <Textarea
        id="snippetSummaryField"
        error={formData?.snippet_summary && formData?.snippet_summary.length > maxCharactersSnippetSummary && isSubmitted}
        placeholder="This will appear in a tooltip when a user is adding this strategy to a Blueprint"
        label="Snippet summary"
        maxCharacters={maxCharactersSnippetSummary}
        minRows={2}
        resizeable
        sx={{ marginBottom: '24px', marginTop: '24px' }}
        value={formData?.snippet_summary}
        onChange={(event): void => dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_DATA, payload: { snippet_summary: event.target.value } })}
      />
      {tierCards}
      <Button
        sx={{ width: '100%' }}
        variant="tertiary"
        size="large"
        startIcon={<PlusIcon />}
        onClick={handleAddNewTier}
      >
        Add New Tier
      </Button>
      <DeleteTierModal
        openDialog={openDeleteDialog}
        handleCloseDialog={handleDeleteDialogClose}
        handleDeleteTier={handleDeleteTier}
      />
    </>
  );
};

export default memo(SpendContractDetails);
