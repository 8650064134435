import React, { memo, useEffect, useMemo } from 'react';
import { AppBanner, Typography } from '@sprnova/nebula';
import ChallengeMessage from 'features/clients/AiDashboard/components/NovaGpt/components/ChallengeMessage';
import { NovaAiChallengeType } from 'features/entitiesRedux/models/nova_ai';

interface Props {
  challengeData: NovaAiChallengeType[];
  refetch: () => void;
}

const ClientBanner = ({ challengeData, refetch }: Props): JSX.Element => {
  // Get the current challenge with a status of in_progress
  const currentChallenge = useMemo(
    () => challengeData?.find((challenge) => challenge.status === 'in_progress'),
    [challengeData]
  );
  const lastChallenge = useMemo(() => challengeData.length ? challengeData[challengeData.length - 1] : undefined, [challengeData]);
  const questionCount = useMemo(() => currentChallenge ? currentChallenge.question_count : lastChallenge ? lastChallenge.question_count : undefined, [currentChallenge, lastChallenge]);
  const questionLimit = useMemo(() => currentChallenge ? currentChallenge.question_limit : lastChallenge ? lastChallenge.question_limit : undefined, [currentChallenge, lastChallenge]);

  // Conditionally set up polling behavior based on currentChallenge
  const shouldTriggerPolling = currentChallenge && currentChallenge?.question_count >= currentChallenge?.question_trigger;
  useEffect(() => {
    if (shouldTriggerPolling) {
      const interval = setInterval(() => {
        refetch(); // manually trigger refetch
      }, 5000); // Polling interval set to 5000ms

      // Clean up interval on component unmount or when condition changes
      return (): void => clearInterval(interval);
    }
  }, [currentChallenge, refetch, shouldTriggerPolling]);

  return (
    <AppBanner>
      {shouldTriggerPolling ?
        <Typography>
            You have used {questionCount}/{questionLimit} questions. {shouldTriggerPolling && <ChallengeMessage message={currentChallenge.message} htmlResponse={currentChallenge.action} useOuterHtml/>}
        </Typography>
        :
        <Typography>
            Welcome to the Insights AI Beta! You have {questionCount}/{questionLimit} questions.
        </Typography>
      }
    </AppBanner>
  );
};

export default memo(ClientBanner);
