/**
 * SurveyQuestion -> QuestionTextarea
 */

import React, { FC } from 'react';
import { TalentSurveyBucket } from 'features/talent/types';
import DebounceField from '../../../DebouncedField';
import { TextArea } from '../../../TextArea';

export type QuestionTextareaProps = {
  question: Partial<TalentSurveyBucket> | undefined;
  onChange: (value: string) => void;
  disabled?: boolean;
}

export const QuestionTextarea: FC<QuestionTextareaProps> = ({ question, onChange, disabled  }) => {
  return (
    <DebounceField
      component={TextArea}
      onUpdate={onChange}
      rows={4}
      syncValue={false}
      value={question?.notes}
      disabled={disabled}
    />
  );
};
