import { Projection } from 'api/entityGraphQL/types';

export const salesforceOpportunityProjection: Projection = {
  id: true,
  name: true,
  stage: true,
  display_value: true,
};

export const strategiesProjection: Projection = {
  id: true,
  salesforce_opportunity_id: true,
};
