import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AppHeaderUserButton,
  Dropdown,
  ActionMenu,
  PositionerAnchorProps,
  PositionerContentProps,
  PositionerMeta,
} from '@sprnova/nebula';
import { AppDispatch } from 'app/store';
import Cookies from 'js-cookie';
import { useExternalAccount } from 'utils';
import { ExternalUser, defaultExternalClientReportProjection, refreshExternalClientById } from 'features/entitiesRedux';

export type ClientUserDropdownProps = {
  clientId?: number;
  accountName?: string | null;
};

export const ClientUserDropdown: FC<ClientUserDropdownProps> = ({ accountName, clientId }) => {
  const { logout } = useAuth0();

  const [isLoggingOut, setLoggingOut] = useState(false);
  const initial = accountName?.charAt(0) || '';
  const externalUser: ExternalUser = useExternalAccount();
  const dispatch: AppDispatch = useDispatch();

  /**
   * Grab client id from url and refresh client query
   */
  const { clientId: id } = useParams<{ [x: string]: string }>();
  useEffect(() => {
    if (id) {
      const clientId = parseInt(id);
      refreshExternalClientById(clientId, dispatch, { projection: defaultExternalClientReportProjection });
    }
  }, [dispatch, id]);

  const handleLogout = (): void => {
    setLoggingOut(true);

    Cookies.remove('BEARER-TOKEN');
    Cookies.remove('auth0_user_type');

    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    });
  };

  const removeSessionItems = (): void => {
    sessionStorage.removeItem('clientLogo');
    sessionStorage.removeItem('clientName');
  };

  const renderAnchor = (props: PositionerAnchorProps, { isOpen }: PositionerMeta): ReactNode => (
    <AppHeaderUserButton
      image={externalUser?.avatar}
      initial={initial}
      {...props}
      open={isOpen}
      disabled={!accountName}
    />
  );

  const renderContent = ({ onClose }: PositionerContentProps): ReactNode => (
    <ActionMenu onItemClick={onClose}>
      { clientId && (
        <ActionMenu.Item
          onClick={removeSessionItems}
          component={Link}
          to={`/c/${clientId}/profile/`}
        >
          My Profile
        </ActionMenu.Item>
      )}
      <ActionMenu.Item
        disabled={isLoggingOut}
        onClick={handleLogout}
      >
        {isLoggingOut ? 'Logging out...' : 'Log out'}
      </ActionMenu.Item>
    </ActionMenu>
  );

  return (
    <Dropdown placement="bottom-start" anchor={renderAnchor}>
      {renderContent}
    </Dropdown>
  );
};
