import { Projection } from 'api/entityGraphQL';

export const defaultDataSourceProjection: Projection = {
  id: true,
  name: true,
  slug: true,
  description: true,
  disclaimer: true,
  group: true,
  is_refreshable: true,
  max_refresh: true,
  is_manual_only: true,
  is_requestable: true,
  is_visible: true,
  is_available: true,
  auth_url: true,
  redirect_uri: true,
  client_id: true,
  fivetran_status: true,
  nova_cloud_client_id: true,
  nova_cloud_redirect_uri: true,
};
