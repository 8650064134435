export enum CLIENT_STATUS_ENUM {
  Active = 'active',
  Prospect = 'prospect',
  Churned = 'churned',
}

export enum CLIENT_STAGE_ENUM {
  Acquired = 'acquired',
  ContractEnded = 'contract-ended',
  Inactive = 'inactive',
  PendingActive = 'pending-active',
  PendingChurn = 'pending-churn',
  PendingRenewal = 'pending-renewal',
  PotentialClient = 'potential-client',
  WithinContract = 'within-contract',
}

export enum LookerReportTypeEnum {
  Base = 'base',
  Standard = 'standard',
  Custom = 'custom',
}

export enum ClientStatusIdTypeEnum {
  Active = 1003,
  Prospect = 1001,
}
