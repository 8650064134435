export const cardHeaderStyles = {
  backgroundColor: '#2C2C2C',
  color: '#FFFFFF',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '24px',
  padding: '24px',
};

export const cardContentStyles = {
  backgroundColor: '#2C2C2C',
  color: '#FFFFFF',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '22px',
  padding: '0 24px 24px 24px',
  maxHeight: '236px',
  overflow: 'scroll'
};

export const cardContainerStyles = {
  minHeight: '338px',
};
