import React from 'react';

const NoDataIcon = (): JSX.Element => (
  <svg
    x="0px"
    y="0px"
    width="58"
    height="51"
    viewBox="0 0 60 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.03125 52.5016C4.51458 52.5016 3.25575 52.0011 2.25475 51.0001C1.25158 49.9969 0.75 48.737 0.75 47.2203V35.0328C0.75 33.5161 1.25158 32.2562 2.25475 31.2531C3.25575 30.2521 4.51458 29.7516 6.03125 29.7516H17.7313C18.4354 32.6224 19.925 34.9646 22.2 36.7781C24.475 38.5937 27.075 39.5016 30 39.5016C32.9792 39.5016 35.6063 38.5937 37.8813 36.7781C40.1562 34.9646 41.6187 32.6224 42.2687 29.7516H53.9688C55.4854 29.7516 56.7453 30.2521 57.7485 31.2531C58.7495 32.2562 59.25 33.5161 59.25 35.0328V47.2203C59.25 48.737 58.7495 49.9969 57.7485 51.0001C56.7453 52.0011 55.4854 52.5016 53.9688 52.5016H6.03125ZM6.03125 49.2516H53.9688C54.5104 49.2516 54.9838 49.0479 55.389 48.6406C55.7963 48.2354 56 47.762 56 47.2203V35.0328C56 34.4911 55.7963 34.0177 55.389 33.6126C54.9838 33.2052 54.5104 33.0016 53.9688 33.0016H44.95C43.5417 35.9807 41.5104 38.3511 38.8563 40.1126C36.2021 41.8719 33.25 42.7516 30 42.7516C26.75 42.7516 23.7979 41.8719 21.1437 40.1126C18.4896 38.3511 16.4583 35.9807 15.05 33.0016H6.03125C5.48958 33.0016 5.01617 33.2052 4.611 33.6126C4.20367 34.0177 4 34.4911 4 35.0328V47.2203C4 47.762 4.20367 48.2354 4.611 48.6406C5.01617 49.0479 5.48958 49.2516 6.03125 49.2516ZM47.55 22.1953L45.275 19.9203L54.5375 10.6578L56.8937 12.9328L47.55 22.1953ZM12.45 22.1953L3.10625 12.9328L5.4625 10.6578L14.725 19.9203L12.45 22.1953ZM28.375 13.6641V0.664062H31.625V13.6641H28.375ZM6.03125 49.2516H4H56H53.9688H6.03125Z" fill="black"/>
  </svg>
);

export default NoDataIcon;
