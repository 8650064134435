

/**
 * Reducer
 */

import { createSlice } from '@reduxjs/toolkit';
import { getClient } from 'api/entityGraphQL/utils';
import { RootState } from 'app/store';
import { selectApiClient } from 'features/global';

export const dashboardsInitialState: any = {
  isLoading: false,
  error: null
};

export const fetchDashboards = () => async (
  dispatch: (handlePending: any) => void,
  getState: any,
) => {
  const clientApi = selectApiClient(getState() as RootState);
  const query = `
    dashboards {
      data {
        id
        client {
          id
          name
          account_manager {
            id
            name
            avatar
          }
          executive_sponsor {
            id
            name
            avatar
          }
        }
        is_active
        pace {
          id
          name
          slug
          color
          is_active
        }
        rapport {
          id
          name
          slug
          color
          is_active
        }
        churn {
          id
          name
          slug
          color
          is_active
        }
        escalation
        contract
        notice_date
        start_date
        end_date
        retainer
        referrals
        renewal_terms
        monthly_goal_forecast
        organization {
          id
          name
          slug
        }
        notes {
          id
          date
          title
          text
        }
        created_at
        updated_at
      }
    }
  `;
  try {
    dispatch(handlePending());
    const response = await getClient(clientApi)
      .sendRequest(query)
      .catch((err: Error) => console.log(err.message));
    dispatch(fetchDashboardsFulfilled({ dashboards: response }));
  } catch (error) {
    dispatch(handleRejected({ err: 'error fetching dashboards' }));
  }
};

export const dashboardsSlice = createSlice({
  name: 'dashboards',
  initialState: dashboardsInitialState,
  reducers: {
    fetchDashboardsFulfilled: (state, action) => {
      state.dashboards.isLoading = false;
      state.dashboards.error = 'error';
      state.dashboards.dashboards = action.payload.dashboards;
    },
    handlePending: (state?) => {
      state.dashboards.isLoading = true;
      state.dashboards.error = null;
    },
    handleRejected: (state, action) => {
      state.dashboards.isLoading = false;
      state.dashboards.error = action.payload.err;
    },
  }
});

export const selectDashboardPage = (state: RootState) => {
  return state.dashboards;
};

const { fetchDashboardsFulfilled, handlePending, handleRejected } = dashboardsSlice.actions;

export default dashboardsSlice.reducer;
