/**
 * Table Expand Button
 */

import React, { FC } from 'react';
import { PlusSquareTwoTone, MinusSquareTwoTone } from '@ant-design/icons';
import { Tooltip } from 'components/Tooltip';
import css from './ExpandButton.module.scss';

type Props = { 
  collapseLabel?: string;
  expanded: boolean;
  expandLabel?: string;
  onExpand: any;
  record: any;
}

const ExpandButton: FC<Props> = ({ expanded, onExpand, record, collapseLabel, expandLabel }) => {
  if (!record?.children?.length) {
    return null;
  }

  const content = expanded ? (
    <MinusSquareTwoTone className={css.root} onClick={e => onExpand(record, e)} />
  ) : (
    <PlusSquareTwoTone className={css.root} onClick={e => onExpand(record, e)} />
  );

  if (collapseLabel && expandLabel) {
    return (
      <Tooltip
        trigger="hover"
        title={expanded ? collapseLabel : expandLabel}
        destroyTooltipOnHide
      >
        {content}
      </Tooltip>
    );
  }

  return content;
};

export default ExpandButton;