import React from "react";
import loadable from "@loadable/component";
import { useGetUserQuery } from "api/crudGraphQL/users/getUser";
import { useParams } from "react-router-dom";
import { Skeleton } from "components";

const EditUserPage = loadable(
  () => import("./EditUserPage")
);

export const Title = () => {
  const { id } = useParams<{ [x: string]: string }>();
  const { data: user, isLoading } = useGetUserQuery({
    id: Number(id),
    projection: { id: true, name: true }
  });
  if (!user || isLoading) {
    return <Skeleton title={{ width: 200 }} paragraph={false} active />;
  }
  return <>{user?.name}</>;
};

Title.displayName = 'EditUserPageTitle';

export default Object.assign(EditUserPage, { Title });
