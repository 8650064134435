/**
 * Get Scoreboard (report) selector indicators
 * Returns a list of all indicators and their active status
 * for a given scoreboard (public)
 */
import { Projection } from 'api/entityGraphQL';
import { YesNo } from 'features/entitiesRedux';
import { cleanArguments } from "api/crudGraphQL/utils";
import { basePublicApi } from "../../basePublicApi";
import tagTypes from '../tagTypes';

export type GetScoreboardSelectorIndicatorsPublicQuery = {
  report_id: number;
  category?: string;
  projection?: Projection;
}

export type ScoreboardSelectorIndicator = {
  category: string;
  equation: string;
  format: string;
  formula: string;
  indicator_id: number;
  is_active: YesNo;
  name: string;
  order_display: number;
  settings: string;
  slug: string;
  tooltip: string;
  required_group: string;
};

export type GetScoreboardSelectorIndicatorsPublicResult = ScoreboardSelectorIndicator[];

export const GET_SCOREBOARD_SELECTOR_INDICATORS_PUBLIC_ENDPOINT_KEY = 'getScoreboardSelectorIndicatorsPublic';

export const { useGetScoreboardSelectorIndicatorsPublicQuery, util } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_SCOREBOARD_SELECTOR_INDICATORS_PUBLIC_ENDPOINT_KEY]: build.query<GetScoreboardSelectorIndicatorsPublicResult, GetScoreboardSelectorIndicatorsPublicQuery>({
      transformResponse: (response: { report_selector_indicators: GetScoreboardSelectorIndicatorsPublicResult}) => response.report_selector_indicators,
      providesTags: (indicators = []) => {
        return [
          tagTypes.SELECTOR_INDICATORS,
          ...indicators?.map((indicator: ScoreboardSelectorIndicator) => ({
            id: indicator.indicator_id,
            type: tagTypes.SELECTOR_INDICATOR
          }))
        ];
      },
      query: ({
        report_id,
        category,
        projection = { id: true },
      }) => {

        return {
          body: {
            query: {
              report_selector_indicators: {
                __args: cleanArguments({
                  report_id,
                  category,
                }),
                ...projection
              }
            }
          }
        };
      }
    }),
  }),
});
