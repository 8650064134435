import { Projection } from 'api/entityGraphQL/types';
import { EmployeeSize } from 'features/entitiesRedux';
import { basePublicApi } from '../basePublicApi';
import { cleanArguments } from 'api/crudGraphQL/utils';

export type GetEmployeeSizesPublicQuery = {
  projection?: Projection;
};

export type GetEmployeeSizesPublicResult = EmployeeSize[];

export const GET_EMPLOYEE_SIZES_PUBLIC_ENDPOINT_KEY = 'getEmployeeSizesPublic';

export const { useGetEmployeeSizesPublicQuery } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_EMPLOYEE_SIZES_PUBLIC_ENDPOINT_KEY]: build.query<GetEmployeeSizesPublicResult, GetEmployeeSizesPublicQuery>({
      transformResponse: (response: { client_employee_sizes: GetEmployeeSizesPublicResult }) => response.client_employee_sizes,
      query: (query) => ({
        body: {
          query: {
            client_employee_sizes: {
              ...(cleanArguments(query?.projection) ?? {
                id: true,
                name: true,
              })
            },
          }
        }
      })
    }),
  }),
});
