/**
 * Skeleton -> Page
 */

import React, { FC } from 'react';
import { Container, HeroSkeleton, Skeleton } from '@sprnova/nebula';

export const PageSkeleton: FC = ({ children }) => (
  <>
    <HeroSkeleton />
    <Container hasVerticalPadding>
      {children || (
        <>
          <Skeleton width={400} sx={{ mb: 0.5 }} />
          <Skeleton width={300} sx={{ mb: 0.5 }}  />
          <Skeleton width={350} sx={{ mb: 0.5 }}  />
        </>
      )}
    </Container>
  </>
);