import React, { Fragment, memo, useMemo } from 'react';
import { Box, Grid, Typography, theme } from '@sprnova/nebula';
import KPIPlatforms from './KPIPlatforms';
import { IntegrationType } from '../types';


const RequiredPlatformsLabelColor = theme.variables?.colors.primary.charcoal[200];
const BreakLineColor = theme.variables?.colors.neutrals.ghost[400];

type ListTooltipContentProps = {
  label: string;
  tooltip: string;
  formula: string;
  platform: Record<string, IntegrationType[]>;
  requiredPlatformCategory: string;
};

const ListTooltipContent: React.FC<ListTooltipContentProps> = ({
  label,
  tooltip,
  formula,
  platform,
  requiredPlatformCategory,
}) => {
  const formattedCategoryList = useMemo(() => {
    return requiredPlatformCategory.split(',').map(category => category.trim());
  }, [requiredPlatformCategory]);

  return (
    <Grid>
      <Typography variant='body1' sx={{paddingBottom: formula ? '0' : '12px'}}>{tooltip}</Typography>
      {
        formula &&
        <div>
          <br />
          <Typography
            variant='body1'
            sx={{
              fontWeight: 'bold',
              paddingBottom: '12px',
            }}
          >
            {label} = {formula?.replace('/', ' / ')}
          </Typography>
        </div>
      }
      {
        formattedCategoryList && formattedCategoryList.length && platform &&
        <Box>
          <div style={{paddingBottom: '12px'}}>
            <Typography variant='body1'
              sx={{
                fontWeight: 'bold',
                padding: '12px 0',
                borderTop: '1px solid',
                borderTopColor: BreakLineColor
              }}
            >
              Required platforms
            </Typography>
            <Grid display='flex' flexDirection='column'>
              {formattedCategoryList.map((category, index) => (
                <Fragment key={`require_category_${category}`}>
                  <Typography
                    variant='caption'
                    color={RequiredPlatformsLabelColor}
                    sx={{ textDecoration: 'underline', paddingTop: index>0 ? '12px' : '0'}}>
                    {category}
                  </Typography>
                  <KPIPlatforms data={platform[category]} category={category} statusOnly />
                </Fragment>
              ))}
            </Grid>

          </div>
        </Box>
      }
    </Grid>
  );
};

export default memo(ListTooltipContent);
