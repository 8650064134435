/**
 * Components -> SelectAppraisal
 *
 * This component supports asynchronously searching for appraisals
 * or simply using a fixed list of appraisals passed via. the "appraisals"-prop.
 *
 * The latest appraisals will be fetched used as the initial options if disableFetchOnSearch != true.
 * When fetching is enabled, the "appraisals"-prop values will be merged with the fetched initial appraisals.
 *
 * If a "clientId"-prop is supplied, the component will automatically fetch the appraisals for that client.
 *
 * If an "appraisalId"-prop is supplied, the component will exclude that appraisal from the list of options.
 *
 * The component will automatically try to fetch the initial audit if an inital value is specified
 * to ensure that that the actual label will render on mount and not the ID of the appraisal.
 */

import React, { ForwardRefExoticComponent, useMemo, forwardRef, useState, useEffect, useRef } from 'react';
import { fetchClientByIdQuery, novaGraphQLClient } from 'api/entityGraphQL';
import classnames from 'classnames';
import { sortBy } from 'lodash';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import { Audit } from 'features/entitiesRedux/models/audit';
import { formatDate } from '../../features/audits/utils';
import Select, { Props as SelectProps } from '../Select/Select';
import { Spin } from '../Spin';
import css from './SelectAppraisal.module.scss';

const FETCH_LIMIT = 30;
const PROJECTION: { [x: string]: boolean; id: true; } = {
  id: true,
  name: true,
  created_at: true,
};

export type Props = SelectProps & {
   appraisals?: Partial<Audit>[];
   appraisalId?: number;
   clientId?: number;
   disableFetchOnSearch?: boolean;
   isInternalClientReview?: boolean;
   disableFetchInitialAuditOnMount?: boolean;
   onSelectAppraisal?: (appraisal:Partial<Audit> | undefined) => void
 };

const SelectAppraisal: ForwardRefExoticComponent<Props> = forwardRef(function SelectAppraisal({
  appraisalId,
  appraisals: appraisalsProp,
  className,
  clientId,
  disableFetchOnSearch = false,
  disableFetchInitialAuditOnMount = false,
  isInternalClientReview = false,
  skeleton: skeletonProp,
  value,
  onSelectAppraisal,
  ...props
}, ref: React.Ref<HTMLInputElement>) {
  const initialAppraisals = appraisalsProp || [];
  const initialAppraisalId = useRef<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [skeleton, setSkeleton] = useState<boolean>(false);
  const [cachedInitialAppraisals, setCachedInitialAppraisals] = useState<Partial<Audit>[]>(initialAppraisals);
  const [allOptions, setOptions] = useState<Partial<Audit>[]>(initialAppraisals);
  const options = useMemo(() =>
    sortBy(uniqBy(allOptions.filter((option) => option?.id !== undefined && option?.id !== appraisalId), 'id'), 'name')
  , [allOptions]);

  /**
   * When an appraisal is selected, use that appraisal's ID to find the whole appraisal object
   * and call the onSelectAppraisal with that appraisal as a param
   * @param appraisal_id - ID of an appraisal
   */
  const handleSelect = (appraisal_id:number | undefined) => {
    const appraisal = options.find(option => option.id === appraisal_id);
    if (onSelectAppraisal) {
      onSelectAppraisal(appraisal);
    }
  };

  // Enable async search if it's not explicitly disabled or if we only want to fetch options for a given client
  const enableAsyncSearch = disableFetchOnSearch === false || !clientId;

  // Fetch appraisals/options for a given client ID
  useEffect(() => {
    let isCancelled = false;
    const fetchOptionsByClientId = async (id: number) => {
      setSkeleton(true);
      const { clients } = await fetchClientByIdQuery(id, {
        projection: {
          id: true,
          audits: {
            name: true,
            id: true,
            salesforce_opportunity_id: true,
            created_at: true,
          }
        }});
      const appraisals = clients[0]?.audits || [];

      if (!isCancelled) {
        setOptions(appraisals);
        setSkeleton(false);
      }
    };

    if (clientId) {
      fetchOptionsByClientId(clientId);
    }

    return () => {
      isCancelled = true;
    };
  }, [clientId]);

  // Fetch initial appraisal if we have a value on mount
  useEffect(() => {
    let isCancelled = false;
    const fetchInitialAudit = async () => {
      setSkeleton(true);
      const { audits: [initialAppraisal] } = await novaGraphQLClient.fetchAuditById(value, { projection: PROJECTION });
      if (initialAppraisal && !isCancelled) {
        setCachedInitialAppraisals(curr => [...curr, initialAppraisal]);
        setOptions(curr => [...curr, initialAppraisal]);
      }
      setSkeleton(false);
    };

    // We want to fetch the initial appraisal on mount
    // or if the initial value/id changes and we don't already have that appraisal data
    if (
      !disableFetchInitialAuditOnMount &&
      value &&
      initialAppraisalId.current !== value &&
      !cachedInitialAppraisals.find(a => a.id === value) &&
      !options.find(a => a.id === value)) {
      fetchInitialAudit();
    }

    initialAppraisalId.current = value;

    return () => {
      isCancelled = true;
    };
  }, [value]);

  // Sync internal appraisals state if appraisals prop changes
  useEffect(() => {
    if (Array.isArray(appraisalsProp)) {
      setOptions(curr => [...curr, ...appraisalsProp]);
      setCachedInitialAppraisals(curr => [...curr, ...appraisalsProp]);
    }
  }, [appraisalsProp]);

  // Fetching appraisals manually outside the redux state
  // because we want to avoid triggering the global loading state
  // which can cause some issues in some parts of the application currently
  const fetchAppraisals: (name?: string | undefined) => Promise<Partial<Audit>[] | undefined> = async (name?: string) => {
    // We don't want to re-fetch the initial appraisals
    // if the search field is cleared and we have cached initial results
    if (!name && cachedInitialAppraisals.length) {
      setOptions(cachedInitialAppraisals);
      setLoading(false);
      return;
    }

    setLoading(true);

    const response: { audits: Partial<Audit>[] } = await novaGraphQLClient.fetchAudits({
      args: {
        // Fuzzy search by name
        name: name ? `*${String(name).replace(/\s+/g, '*')}*` : undefined,
        sort: ['-created_at'],
      },
      pagination: {
        limit: FETCH_LIMIT,
        page: 1,
      },
      projection: PROJECTION,
    });

    // When search query is empty: Merge initial appraisals (if passed down via. the appraisals-prop)
    // with the appraisals we get from the response (this would typically be the latest appraisals)
    // so we have some initial results for the user to choose from
    const nextAppraisals: Partial<Audit>[] = !name ? [...initialAppraisals, ...response.audits] : response.audits;

    setOptions(nextAppraisals);
    setLoading(false);

    return nextAppraisals;
  };

  // Fetch the initial audits on load if needed
  useEffect(() => {
    let isCancelled = false;
    if (enableAsyncSearch) {
      (async () => {
        const initialResults = await fetchAppraisals();

        if (!isCancelled && Array.isArray(initialResults)) {
          setOptions(initialResults);

          // Cache initial results so we don't need to fetch them again if the user clears the search field
          setCachedInitialAppraisals(curr => [...curr, ...initialResults]);
        }
      })();
    }

    return () => {
      isCancelled = true;
    };
  }, [disableFetchOnSearch]);

  // Asynchronously fetch audits when the user types in the search field if disableFetchOnSearch != true
  const handleSearch = debounce(fetchAppraisals, 200);

  // Nothing found or loading content
  const renderContent = (menu: any) => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
    if (loading) {
      return <Spin size="small" />;
    }

    if (!options.length && !isInternalClientReview) {
      return <div className={css.nothingFound}>No appraisals found</div>;
    }

    return menu;
  };

  const renderOptions = useMemo(() => {
    return (options as (Partial<Audit> & { id: number })[])
      .map((option) => (
        <Select.Option key={option?.id} value={option?.id}>
          {option?.name ? option?.name : 'Unnamed Appraisal'}
          <small className={css.muted}>
            {formatDate(option?.created_at)}
          </small>
        </Select.Option>
      ));
  }, [options]);

  return (
    <Select
      className={classnames(css.root, className)}
      block
      value={value}
      loading={loading}
      skeleton={skeletonProp || skeleton}
      dropdownRender={renderContent}
      onSearch={enableAsyncSearch ? handleSearch : undefined}
      optionFilterProp="name"
      filterOption={disableFetchOnSearch ? (input, option) => option?.name?.toLowerCase().includes(input.toLowerCase()) : false}
      ref={ref}
      showSearch
      placeholder="Select appraisal"
      onSelect={handleSelect}
      {...props}
    >
      <>
        {isInternalClientReview && !options?.length ? <Select.Option key={'none'} value={0}>None</Select.Option> : <></>}
        {renderOptions}
      </>
    </Select>
  );
});

export default SelectAppraisal;
