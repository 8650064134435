export const taskProjection =
{
  id: true,
  control_type: true,
  children: {
    name: true,
    id: true,
    hours: true,
    service: {
      id: true,
      name: true,
      department: {
        id: true,
        name: true,
      },
    },
  },
  executive_summary: true,
  hours: true,
  hours_per_unit: true,
  is_sub_task: true,
  is_configurable: true,
  maximum_hours: true,
  minimum_hours: true,
  multiplier: {
    name: true,
    slug: true,
    id: true,
  },
  name: true,
  next_revision: {
    id: true,
  },
  outsource_budget: true,
  projected_hours: true,
  pricing_version: {
    name: true,
    slug: true,
  },
  pricing_type: {
    id: true,
    name: true,
    slug: true,
  },
  pricing_tiers: {
    id: true,
    name: true,
    deliverable: true,
    snippet: true,
    price: true,
    quantity: true,
    quantity_price: true,
    spend_min: true,
    spend_max: true,
  },
  recommended_outsource_budget: true,
  recommended_hours: true,
  service: {
    id: true,
    name: true,
    department: {
      id: true,
      name: true,
    },
  },
  snippet: true,
  snippet_summary: true,
  strategy_frequency: {
    id: true,
    name: true,
    slug: true,
  },
  unit_type: true,
};
