import { Department } from 'features/entitiesRedux';

export const getOpportunityScoreStatus = (departments?: Department[]): string => {
  if (departments?.length) {
    const statuses = departments?.map(dept => dept?.opportunity);
    if (statuses?.every((i) => i === null)) {
      return 'pending';
    } else if (statuses?.every((i) => typeof i === 'string' && i.length)) {
      return 'complete';
    } else {
      return 'partial';
    }
  } else {
    return 'pending';
  }
};
