import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogActions, Spinner } from '@sprnova/nebula';
import { useDeleteTaskMutation } from 'api/crudGraphQL/tasks/deleteTask';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { notification } from 'components/notification';
import { Task } from 'features/entitiesRedux/models/task';
import { useAccount } from 'features/global';

type DeleteTaskModalProps = {
  open: boolean;
  pathToRedirectOnDelete?: string;
  refetchTasks?: () => void;
  task?: Task;
  toggleModal: () => void;
}

/**
 * Blueprint Approve Modal component
 * @returns JSX.Element
 */
const DeleteTaskModal = ({
  open,
  pathToRedirectOnDelete,
  refetchTasks,
  task,
  toggleModal,
}: DeleteTaskModalProps): JSX.Element => {
  const history = useHistory();
  const [deleteTaskMutation, { isLoading: isDeletingTask }] = useDeleteTaskMutation();
  const mixpanel = useMixpanel();
  const { account } = useAccount();

  const handleMixpanel = useCallback(() => {
    if (mixpanel) {
      const mixpanelTitle = 'Strategy deleted';
      const options = {
        userId: account?.id,
        userName: account?.name,
        strategyName: task?.name,
        strategyId: task?.id,
      };
      mixpanel.track(mixpanelTitle, options);
    }
  }, [account?.id, account?.name, mixpanel, task?.id, task?.name]);

  const handleDelete = useCallback(async (): Promise<void> => {
    try {
      if (!task || !task.id) {
        throw new Error('Strategy not found');
      }
      await deleteTaskMutation({ id: task.id }).unwrap();
      notification.success({
        message: 'Strategy deleted',
      });
      handleMixpanel();

      refetchTasks?.();
      if (pathToRedirectOnDelete) history.replace(pathToRedirectOnDelete);

    } catch (error) {
      notification.error({
        message: 'Failed to delete strategy, please try again',
      });
      console.error('Error deleting strategy', error);
    }
  }, [deleteTaskMutation, handleMixpanel, history, pathToRedirectOnDelete, refetchTasks, task]);

  return (
    <Dialog
      open={open}
      onClose={isDeletingTask ? undefined : (): void => toggleModal()}
      title='Delete Strategy'
      maxWidth={400}
      footer={
        <DialogActions
          secondaryButtonProps={{ onClick: () => toggleModal(), children: 'Cancel', variant: 'text', disabled: isDeletingTask }}
          primaryButtonProps={{ onClick: handleDelete, children: 'Delete', sx: { padding: '11.5px 24px', height: '40px' }, startIcon: isDeletingTask && <Spinner />, disabled: isDeletingTask }}
        />
      }
    >
      Doing this can affect Appraisals, Blueprints, Scoreboards, and Clients that have this strategy in use. Are you sure you want to delete this strategy?
    </Dialog>
  );
};

export default memo(DeleteTaskModal);
