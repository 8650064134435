import { getClient } from 'api/entityGraphQL/utils';
import { QuestionHandler } from '../actions';

export const userScoreHandler: QuestionHandler = (id, key, value, additionalInfo) => {
  const apiClient = getClient();
  return apiClient.updateDiscovery({
    id: additionalInfo?.discoveryId,
    user_score: value as any,
  });
};
