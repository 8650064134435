import { Projection } from 'api/entityGraphQL/types';
import { NovaAIResponseType } from 'features/entitiesRedux/models/nova_ai';
import { baseApi, cleanArguments } from '..';

export type UpdateNovaAIChatMutation = {
  projection?: Omit<Projection, 'id'>;
  prompt_guid: string;
  version?: number;
  feedback?: number;
  feedback_reason_id?: number;
  feedback_additional?: string;
  support_requested?: boolean;
  displayed_at?: string;
}

export type UpdateNovaAIChatResult = NovaAIResponseType;

export const UPDATE_NOVA_AI_CHAT_ENDPOINT_KEY = 'updateNovaAIChat';

export const { useUpdateNovaAIChatMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [UPDATE_NOVA_AI_CHAT_ENDPOINT_KEY]: build.mutation<UpdateNovaAIChatResult, UpdateNovaAIChatMutation>({
      transformResponse: (response: { updateNovaAIChat: UpdateNovaAIChatResult }) =>  response.updateNovaAIChat,
      query: ({ projection = {response: true}, ...values }) => {
        const __args: Partial<UpdateNovaAIChatMutation> = values;
        return ({
          body: {
            mutation: {
              updateNovaAIChat: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        }
        );
      }
    }),
  }),
});
