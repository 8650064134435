import { MixpanelContextType } from 'components/MixpanelProvider/MixpanelContext';

type FilterProps = {
  mixpanel?: MixpanelContextType;
  type?: 'filter' | 'tab' | 'external' | 'module' | 'view-scoreboard' | 'create-scoreboard'| 'edit-scoreboard' | 'delete-scoreboard' | 'edit-kpis' | 'customize-widgets' | 'new-pacing-goal' | 'edit-pacing-goal' | 'delete-pacing-goal' | 'edit-goal-filters';
  options: Record<string, string | number | boolean | Record<string, any> | undefined>;
};

export const track = ({ mixpanel, type, options }: FilterProps): void => {
  try {
    if (!mixpanel) return;
    switch (type) {
      case 'filter':
        trackFilterChange(mixpanel, options);
        break;
      case 'tab':
        trackTabClick(mixpanel, options);
        break;
      case 'external':
        trackExternal(mixpanel, options);
        break;
      case 'module':
        trackModuleClick(mixpanel, options);
        break;
      case 'view-scoreboard':
        trackViewScoreboard(mixpanel, options);
        break;
      case 'create-scoreboard':
        trackCreateScoreboard(mixpanel, options);
        break;
      case 'edit-scoreboard':
        trackEditScoreboard(mixpanel, options);
        break;
      case 'delete-scoreboard':
        trackDeleteScoreboard(mixpanel, options);
        break;
      case 'edit-kpis':
        trackEditKPIs(mixpanel, options);
        break;
      case 'customize-widgets':
        trackCustomizeWidgets(mixpanel, options);
        break;
      case 'new-pacing-goal':
        trackNewPacingGoal(mixpanel, options);
        break;
      case 'edit-pacing-goal':
        trackEditPacingGoal(mixpanel, options);
        break;
      case 'delete-pacing-goal':
        trackDeletePacingGoal(mixpanel, options);
        break;
      case 'edit-goal-filters':
        trackEditGoalFilters(mixpanel, options);
        break;
      default:
        break;
    }
  } catch (error) {
    console.error(error);
  }
};

export const trackFilterChange = (mixpanel: MixpanelContextType, options: FilterProps['options']): void => {
  try {
    if (!mixpanel) return;
    const mixpanelTitle = 'Scoreboards - Filter Change';
    mixpanel.track(mixpanelTitle, options);
    if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
  } catch (error) {
    console.error(error);
  }
};

export const trackTabClick = (mixpanel: MixpanelContextType, options: FilterProps['options']): void => {
  try {
    if (!mixpanel) return;
    const { tab, ...rest } = options;
    const mixpanelTitle = `Scoreboards - ${tab} Clicked`;
    mixpanel.track(mixpanelTitle, rest);
    if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, rest);
  } catch (error) {
    console.error(error);
  }
};

export const trackExternal = (mixpanel: MixpanelContextType, options: FilterProps['options']): void => {
  try {
    if (!mixpanel) return;
    const mixpanelTitle = 'External Scoreboards List Viewed';
    mixpanel.track(mixpanelTitle, options);
    if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
  } catch (error) {
    console.error(error);
  }
};

export const trackModuleClick = (mixpanel: MixpanelContextType, options: FilterProps['options']): void => {
  try {
    if (!mixpanel) return;
    const { is_external } = options;
    const { module, ...rest } = options;
    const mixpanelTitle = is_external ? `External Scoreboards - ${module} Clicked` : `Scoreboards - ${module} Clicked`;
    mixpanel.track(mixpanelTitle, rest);
    if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, rest);
  } catch (error) {
    console.error(error);
  }
};

export const trackViewScoreboard = (mixpanel: MixpanelContextType, options: FilterProps['options']): void => {
  try {
    if (!mixpanel) return;
    const { is_external } = options;
    const mixpanelTitle = is_external ? 'External Scoreboards - View Scoreboard' : 'Scoreboards - View Scoreboard';
    mixpanel.track(mixpanelTitle, options);
    if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
  } catch (error) {
    console.error(error);
  }
};

export const trackCreateScoreboard = (mixpanel: MixpanelContextType, options: FilterProps['options']): void => {
  try {
    if (!mixpanel) return;
    const mixpanelTitle = 'Scoreboards - Create Scoreboard';
    mixpanel.track(mixpanelTitle, options);
    if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
  } catch (error) {
    console.error(error);
  }
};

export const trackEditScoreboard = (mixpanel: MixpanelContextType, options: FilterProps['options']): void => {
  try {
    if (!mixpanel) return;
    const mixpanelTitle = 'Scoreboards - Edit Scoreboard';
    mixpanel.track(mixpanelTitle, options);
    if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
  } catch (error) {
    console.error(error);
  }
};

export const trackDeleteScoreboard = (mixpanel: MixpanelContextType, options: FilterProps['options']): void => {
  try {
    if (!mixpanel) return;
    const mixpanelTitle = 'Scoreboards - Delete Scoreboard';
    mixpanel.track(mixpanelTitle, options);
    if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
  } catch (error) {
    console.error(error);
  }
};

export const trackEditKPIs = (mixpanel: MixpanelContextType, options: FilterProps['options']): void => {
  try {
    if (!mixpanel) return;
    const mixpanelTitle = 'Scoreboards - Edit KPIs Submitted';
    mixpanel.track(mixpanelTitle, options);
    if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
  } catch (error) {
    console.error(error);
  }
};

export const trackCustomizeWidgets = (mixpanel: MixpanelContextType, options: FilterProps['options']): void => {
  try {
    if (!mixpanel) return;
    const mixpanelTitle = 'Scoreboards - Customize Widgets Submitted';
    mixpanel.track(mixpanelTitle, options);
    if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
  } catch (error) {
    console.error(error);
  }
};

export const trackNewPacingGoal = (mixpanel: MixpanelContextType, options: FilterProps['options']): void => {
  try {
    if (!mixpanel) return;
    const mixpanelTitle = 'Scoreboards - New Pacing Goal Created';
    mixpanel.track(mixpanelTitle, options);
    if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
  } catch (error) {
    console.error(error);
  }
};

export const trackEditPacingGoal = (mixpanel: MixpanelContextType, options: FilterProps['options']): void => {
  try {
    if (!mixpanel) return;
    const mixpanelTitle = 'Scoreboards - Pacing Goal Edited';
    mixpanel.track(mixpanelTitle, options);
    if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
  } catch (error) {
    console.error(error);
  }
};

export const trackDeletePacingGoal = (mixpanel: MixpanelContextType, options: FilterProps['options']): void => {
  try {
    if (!mixpanel) return;
    const mixpanelTitle = 'Scoreboards - Pacing Goal Deleted';
    mixpanel.track(mixpanelTitle, options);
    if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
  } catch (error) {
    console.error(error);
  }
};

export const trackEditGoalFilters = (mixpanel: MixpanelContextType, options: FilterProps['options']): void => {
  try {
    if (!mixpanel) return;
    const mixpanelTitle = 'Scoreboards - Edit Goal Filters Submitted';
    mixpanel.track(mixpanelTitle, options);
    if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
  } catch (error) {
    console.error(error);
  }
};
