import { MixpanelContextType } from 'components/MixpanelProvider/MixpanelContext';

type TrackExportProps = {
  title: string;
  type: 'CSV' | 'PDF';
  mixpanel: MixpanelContextType;
  options?: Record<string, unknown>;
};

const trackExport = ({
  title,
  type,
  mixpanel,
  options,
}: TrackExportProps): void => {
  try {
    if (!mixpanel) return;
    mixpanel.track(title, options);
    if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${title} ${type}`, { options });
  } catch (error) {
    console.error('Track Mixpanel error', error);
  }
};

export default trackExport;
