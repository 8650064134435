/**
 * Get widgets
 */
import { Projection } from 'api/entityGraphQL';
import { baseApi, cleanArguments } from '..';
import tagTypes from './tagTypes';
import { Widget } from './types';

export type GetWidgetsQuery = {
  id?: number;
  name?: string;
  projection?: Projection;
}

export type GetWidgetsResult = Widget[];

export const GET_WIDGETS_ENDPOINT_KEY = 'getWidgets';

export const { useGetWidgetsQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_WIDGETS_ENDPOINT_KEY]: build.query<GetWidgetsResult, GetWidgetsQuery>({
      transformResponse: (response: { widgets: GetWidgetsResult}) => response.widgets,
      providesTags: () => {
        return [tagTypes.WIDGETS];
      },
      query: ({
        id,
        projection = { id: true },
      }) => {
        return {
          body: {
            query: {
              widgets: {
                __args: cleanArguments({ id }),
                ...projection
              }
            }
          }
        };
      }
    }),
  }),
});
