import { notification } from 'components';
/**
 * Display Success Notification
 */
export const handleSuccess = (
  name: string,
  onComplete?: () => void,
): void => {
  notification.success({
    message: `${name} was generated successfully`,
  });
  onComplete?.();
};
