import { format } from "date-fns";

export const formatDate = (date: string | Date | undefined) => {
  const noDate = "-";
  try {
    const formattedDate = date ? format(new Date(date), "M/d/yyyy") : noDate;
    return formattedDate;
  } catch (e) {
    console.warn("⚠️ invalid date", date);
  }
  return noDate;
};
