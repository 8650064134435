/**
 * LostModal -> Format initial values
 */

import moment, { Moment } from 'moment';
import {LostModalFormValues} from '../types';

const formatInitialValues: (values: LostModalFormValues) => Omit<LostModalFormValues, 'follow_up_date'> & { follow_up_date: Moment } = (values) => {
  const { 
    lost_reason_type,
    detailed_reason,
    follow_up_date,
    status
  } = values || {};
  
  return {
    status,
    lost_reason_type,
    detailed_reason,
    follow_up_date: follow_up_date ? moment(follow_up_date) : moment(new Date()).utc().add(90, 'd') // Default to 90 days from today
  };
};

export default formatInitialValues;