/**
 * Strategies -> Create strategy page
 */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from '@sprnova/nebula';
import { useGetPillarsQuery } from 'api/crudGraphQL/pillars/getPillars';
import { useCreateStrategyMutation } from 'api/crudGraphQL/strategies/createStrategy';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { PageHero } from 'layouts/components';
import { useQueryParam } from 'use-query-params';
import { Client, createKickoffDeck, Pillar, StrategyFormValues } from 'features/entitiesRedux';
import { useAccount } from 'features/global';
import { PricingVersion } from 'features/library/constants';
import { notification } from 'components';
import { novaGraphQLClient } from '../../../api/entityGraphQL';
import { StrategyForm } from '../components';

const CreateStrategyPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const mixpanel = useMixpanel();
  const { account } = useAccount();
  const [defaultAuditId] = useQueryParam<string>('appraisal_id');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  /**
   * RTK fetch pillars
   */
  const { data: pillars = [], isLoading } = useGetPillarsQuery({
    projection: { id: true, name: true, slug: true },
  });

  const [createStrategyRequest] = useCreateStrategyMutation();

  const handleSubmit = async (
    values: StrategyFormValues,
    client?: Partial<Client>
  ) => {
    setIsSubmitting(true);

    const {
      client_name,
      commission_flat_fee,
      is_reviewed,
      salesforce_close_date,
      salesforce_reason,
      salesforce_explanation,
      presentation,
      review_type,
      deny_reason,
      review_department_id,
      ...rest
    } = values;

    try {
      if (rest.client_id) {
        const strategy = await createStrategyRequest({
          ...rest,
          client_id: rest.client_id,
          departments: values?.departments ?? [],
          status: 'open',
          pricing_version: PricingVersion.HOURLY
        }).unwrap();

        /**
         * Only create new kickoff deck if user has authorized Google account
         */
        if (account?.accounts?.filter((account) => account?.type === 'google')?.length) {
          try {
            const createKickoffAction: any = await dispatch(createKickoffDeck({ strategy_id: strategy.id }));
            if (createKickoffDeck.fulfilled.match(createKickoffAction)) {
              notification.success({ message: 'Created Kickoff Deck' });
            }
            if (createKickoffDeck.rejected.match(createKickoffAction)) {
              const needsReauth = createKickoffAction?.error?.message?.includes('Google Account is missing');
              console.log('Error creating kickoff deck');
              notification.error({
                message: `An error occurred while creating Kickoff Deck${needsReauth ? '. Please reauthenticate your Google account.' : ''}`
              });
            }
          } catch (error) {
            console.error('Error creating kickoff deck', error);
          }
        }

        // if audit_id is present, then update audit with strategy id so they are linked
        if (values?.audit_id && strategy.id) {
          await novaGraphQLClient.updateAudit({
            id: values?.audit_id,
            strategy_id: strategy.id
          });
        }

        // track "Blueprint created" event
        try {
          const pillar = pillars?.find((pillar: Pillar) => pillar.id === values?.pillar_id);
          const options = {
            title: values?.name,
            blueprintId: strategy.id,
            // blueprint: values?.name,
            clientId: values?.client_id || values?.client?.id,
            clientName: values?.client_name,
            businessType: client?.business_type?.name,
            businessTypeId: client?.business_type?.id,
            businessTypeSlug: client?.business_type?.slug,
            appraisalId: defaultAuditId ? Number(defaultAuditId) : null,
            recommendedServices: values?.tasks?.map(task => task.name),
            pillar: pillar?.name
          };
          console.log('🛤 Track: Blueprint created', {
            options,
            // values,
          });
          if (mixpanel?.track) {
            mixpanel.track('Blueprint created', options);
          }
        } catch (error) {
          console.error('Mixpanel error', error);
        }

        history.push(`/blueprints/${strategy.id}`, {from: 'internal'});
      }

    } catch (error) {
      console.error('Error creating Blueprint', error);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <PageHero title="Create Blueprint" />
      <Container hasVerticalPadding>
        <StrategyForm onSubmit={handleSubmit} isFetchingStrategy={isSubmitting} />
      </Container>
    </>
  );
};

const Title = () => <>Create Blueprint</>;
Title.displayName = 'CreateStrategyPageTitle';
export default Object.assign(CreateStrategyPage, { Title });
