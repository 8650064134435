/**
 * SectionBuilder -> Fields -> RevenueGoalPace
 */

import React, { FC, useEffect } from 'react';
import { FormItem, Input } from 'components';
import { MagicOutlined } from '../../../../../../../../../components/Icon';
import { DiscoveryGoal } from '../../../../../../../../entitiesRedux';
import { FieldProps } from '../../../types';
import css from '../../outputs_shared.module.scss';

const RevenueGoalPace: FC<FieldProps> = ({ name, form }) => {
  // get form values for current year revenue and current year revenue goal
  const currentYearRevenue = form.getFieldValue('question_current_year_revenue');
  const currentYearRevenueGoal = form.getFieldValue('goals')?.filter((goal: DiscoveryGoal) => {
    return goal?.type === 'Current Year Revenue Goal';
  })[0]?.metric;

  const calculatePacing = () => {
    // check if both values are truthy
    if (!!currentYearRevenue && !!currentYearRevenueGoal) {
      // remove all non-numeric characters and cast as numbers
      const currentRevenue = typeof currentYearRevenue === 'number'
        ? currentYearRevenue
        : parseInt(currentYearRevenue?.replace(/[^0-9]+/g, ''));
      const revenueGoal = typeof currentYearRevenueGoal === 'number'
        ? currentYearRevenueGoal
        : parseInt(currentYearRevenueGoal?.replace(/[^0-9]+/g, ''));
      // calculate pacing percentage
      const output = ((currentRevenue / revenueGoal) * 100).toFixed(0)+'%';
      return form.setFieldsValue({[name]: output});
    }
  };

  useEffect(() => {
    calculatePacing();
  }, [form, currentYearRevenue, currentYearRevenueGoal]);

  return (
    <FormItem
      name={name}
      className={css.root}
      extra={
        <div className={css.extra}>Goal Pace = Current Year Revenue / Current Year Revenue Goal</div>
      }
    >
      <Input
        addonAfter={<span role="img" className="anticon">
          <MagicOutlined />
        </span>}
        readOnly
      />
    </FormItem>
  );
};

export default RevenueGoalPace;
