/**
 * Entities -> Departments -> Hooks
 */

import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'app/store';
import { useEntityByParamId } from '../_entity/hooks';
import { fetchDepartments, fetchDepartmentById } from './actions';
import { Department, FetchDepartmentsFilter } from './department';
import { selectDepartmentById, selectAllDepartments } from './selectors';

/**
 * Get paginated departments
 */
export const useDepartments = ({
  projection = undefined,
}: {
  filter?: FetchDepartmentsFilter,
  projection?: any
} = {}): {
  departments: Department[];
  loading: boolean;
  error: string | undefined;
  refresh: () => void;
} => {
  const departments = useSelector(selectAllDepartments);
  const dispatch: AppDispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>('');

  const getDepartments = useCallback(async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(fetchDepartments({ projection }));
      if (fetchDepartments.fulfilled.match(resultAction)) {
        setError('');
        setLoading(false);
      }
      if (fetchDepartments.rejected.match(resultAction)) {
        setLoading(false);
        setError(resultAction?.error?.message);
      }
    } catch {
      setError('Failed to fetch departments');
    }
  }, [dispatch, projection]);

  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  const refresh = () => {
    dispatch(fetchDepartments({ projection }));
  };

  return {
    departments,
    error,
    loading,
    refresh
  };
};

/**
 * Get departments by param ID
 */
export const useDepartmentByParamId = () => useEntityByParamId(selectDepartmentById, fetchDepartmentById);
