import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  novaGraphQLClient, novaGraphQLPublicClient,
} from 'api/entityGraphQL';
import {
  Projection,
  FetchAuditsFilter,
} from 'api/entityGraphQL/types';
import { getClient } from 'api/entityGraphQL/utils';
import { RootState } from 'app/store';
import Cookies from 'js-cookie';
import { flatten } from 'lodash';
import { IntegrationInput, Topic, PresentationTemplateVariable } from 'features/audits/auditsSlice';
import { UpdateDiscoveryArgs, UpdateDiscoveryScoreArgs, UpdateDiscoveryPageArgs } from 'features/entitiesRedux/models/discovery';
import { selectApiClient } from 'features/global';
import {
  AuditFormArgs,
  CreateAuditActionArgs
} from './audit';
import {
  auditNameHandler,
  businessTypeHandler,
  channelsHandler,
  clientNameHandler,
  clientWebsiteHandler,
  consideredServicesHandler,
  contactsHandler,
  dateQuestionHandler,
  departmentNotesHandler,
  goalsHandler,
  industryHandler,
  leadChannelsHandler,
  leadSourceHandler,
  numberQuestionHandler,
  pillarHandler,
  preferredChannelsHandler,
  salesforceOpportunityHandler,
  servicesHandler,
  textareaQuestionHandler,
  textQuestionHandler,
  userScoreHandler
} from './questionHandlers';
import {
  ActionFetchManyPaginatedConfig,
  ActionFetchPaginatedResponse,
  AutomatedScore,
  Contact,
  Department,
  Prospect,
  selectAuditById,
  YesNo
} from '..';
import { normalizeEntities } from '../../utils';

/** Fetch many */
export const fetchAuditsPaginated = createAsyncThunk(
  'entities/fetchAuditsPaginated',
  async ({
    filter,
    projection,
    pagination,
  }: ActionFetchManyPaginatedConfig & {
    filter?: FetchAuditsFilter;
  }): Promise<ActionFetchPaginatedResponse> => {
    const {
      audits,
      ...paginationResponse
    } = await novaGraphQLClient.fetchAudits({
      args: {
        ...filter,
        name: filter?.name
          ? `*${String(filter?.name).replace(/\s+/g, '*')}*`
          : undefined,
        sort: filter?.sort,
      },
      projection,
      pagination,
    });
    return { ...normalizeEntities({ audits }), pagination: paginationResponse };
  }
);

/** Fetch one */
export const fetchAuditById = createAsyncThunk(
  'entities/fetchAuditById',
  async (
    { id, ...config }: { id: number; projection?: Projection },
    { getState }
  ) => {
    const apiClient = getClient(selectApiClient(getState() as RootState));
    const { audits } = await apiClient.fetchAuditById(id, {
      ...config,
    });
    return { ...normalizeEntities({ audits }) };
  }
);

/** Update */
export const updateAudit = createAsyncThunk(
  'entities/updateAudit',
  async (args: AuditFormArgs) => {
    const response = await novaGraphQLClient.updateAudit(args);
    return { ...normalizeEntities(response) };
  }
);

/** Update Discovery Bucket */
export const updateDiscoveryBucket = createAsyncThunk(
  'entities/updateDiscoveryBucket',
  async (
    {
      discovery_id,
      bucket_id,
      score,
      audit_projection,
      audit_id,
    }: {
      discovery_id: number;
      bucket_id: number;
      score?: number;
      audit_projection?: Projection;
      audit_id?: number;
    },
    { dispatch }
  ) => {
    await novaGraphQLClient.updateDiscoveryBucket({
      discovery_id: discovery_id,
      bucket_id: bucket_id,
      score: score,
    });
    if (audit_id) {
      dispatch(fetchAuditById({ id: audit_id, projection: audit_projection }));
    }
    return { ...normalizeEntities({}) }; // normalizr schema shape expected by entitiesSlice reducer
  }
);

/** Update Discovery */
export const updateDiscovery = createAsyncThunk(
  'entities/updateDiscovery',
  async (
    {
      projection,
      auditProjection,
      ...args
    }: {
      projection?: Projection;
      auditProjection?: Projection;
    } & UpdateDiscoveryArgs,
    { dispatch }
  ) => {
    const response = await novaGraphQLClient.updateDiscovery(args, {
      projection,
    });

    const audit_id = response?.discoveries?.[0]?.audit?.id;
    if (audit_id !== undefined) {
      dispatch(
        fetchAuditById({
          id: audit_id,
          projection: auditProjection || {
            ...novaGraphQLClient.defaultAuditProjection,
            id: true,
          },
        })
      );
    }
    return { ...normalizeEntities({}) }; // normalizr schema shape expected by entitiesSlice reducer
  }
);

/** Update Discovery Score */
export const updateDiscoveryScore = createAsyncThunk(
  'entities/updateDiscoveryScore',
  async (
    {
      projection,
      auditProjection,
      ...args
    }: {
      projection?: Projection;
      auditProjection?: Projection;
    } & UpdateDiscoveryScoreArgs,
    { dispatch }
  ) => {
    const response = await novaGraphQLClient.updateDiscoveryScore(args, {
      projection,
    });
    const audit_id = response?.discoveries?.[0]?.audit?.id;
    if (audit_id !== undefined) {
      dispatch(
        fetchAuditById({
          id: audit_id,
          projection: auditProjection || {
            ...novaGraphQLClient.defaultAuditProjection,
            id: true,
          },
        })
      );
    }
    return { ...normalizeEntities({}) }; // normalizr schema shape expected by entitiesSlice reducer
  }
);

/** Update Discovery Department (Opportunity Slider in Analyst Survey) */
export const updateDiscoveryDepartment = createAsyncThunk(
  'entities/updateDiscoveryDepartment',
  async (
    {
      discovery_id,
      department_id,
      opportunity,
      analyst_notes,
      audit_projection,
      audit_id,
      update_revision,
    }: {
      discovery_id: number;
      department_id: number;
      opportunity?: string;
      analyst_notes?: string;
      audit_projection?: Projection;
      audit_id?: number;
      update_revision?: YesNo;
    },
    { dispatch }
  ) => {
    await novaGraphQLClient.updateDiscoveryDepartment({
      discovery_id: discovery_id,
      department_id: department_id,
      opportunity: opportunity,
      analyst_notes: analyst_notes,
      update_revision: update_revision,
    });
    if (audit_id) {
      dispatch(fetchAuditById({ id: audit_id, projection: audit_projection }));
    }
    return { ...normalizeEntities({}) };
  }
);

/** Discovery 2.0 -> Type definitions */
export type QuestionHandler = (
  id: number | null | undefined,
  key: string | null,
  value: string | number | Date | Array<any> | Record<string, unknown> | Projection | undefined,
  additionalInfo?: {
    auditId?: number,
    auditName?: string,
    auditProjection?: Projection,
    businessTypeId?: string | number,
    clientId?: number,
    clientName?: string,
    clientWebsite?: string,
    discoveryId: number,
    industryId?: string | number,
    leadSourceId?: string | number,
    pillarId?: string | number,
    clientContacts?: Contact[],
    salesforceOpportunityId?: string,
  },
  topics?: Topic[],
  topicId?: number,
) => Promise<any>;

type QuestionHandlerMapType = {
  [questionKey: string]: QuestionHandler
}

/** Discovery 2.0 -> Get a single question object from all questions in the topics array */
const getQuestionFromTopicsByKey = (key: string | null, topics?: Topic[]) => {
  // get a single array of all questions from all topics
  const allQuestions = flatten(topics?.map((topic: Topic) => topic.questions));
  // return a single question object matching the provided field name (group_name column in database)
  return allQuestions.filter(question => question.group_name === key)[0];
};

/**
 * Discovery 2.0 -> Mapping field names to API calls for special cases
 *  special cases = fields that call something other than updateDiscoveryQuestion
 *  i.e. updateClient, updateAudit, updateDiscoveryGoals, etc.
 */
export const questionHandlers: QuestionHandlerMapType = {
  audit_name: auditNameHandler,
  business_type_id: businessTypeHandler,
  client_name: clientNameHandler,
  client_website: clientWebsiteHandler,
  //competitors: competitorsHandler,
  considered_services: consideredServicesHandler,
  contacts: contactsHandler,
  //decision_maker_ids: decisionMakersHandler,
  departments: departmentNotesHandler,
  goals: goalsHandler,
  industry_id: industryHandler,
  lead_channels: leadChannelsHandler,
  lead_source_id: leadSourceHandler,
  pillar_id: pillarHandler,
  question_preferred_sales_channels: preferredChannelsHandler,
  sales_channels: channelsHandler,
  salesforce_opportunity_id: salesforceOpportunityHandler,
  services: servicesHandler,
  user_score: userScoreHandler,
};

/**
 * Discovery 2.0 -> Handle all fields that call updateDiscoveryQuestion
 *  typecasting for different possible field values (text, numbers, dates, etc)
 * */
export const defaultQuestionHandler: QuestionHandler = (id, key, value, additionalInfo, topics) => {
  const question = getQuestionFromTopicsByKey(key, topics);
  if (question.type === 'text') {
    return textQuestionHandler(id, key, value, additionalInfo);
  }
  if (question.type === 'textarea') {
    return textareaQuestionHandler(id, key, value, additionalInfo, undefined, question?.topic?.id);
  }
  if (question.type === 'number') {
    return numberQuestionHandler(id, key, value, additionalInfo);
  }
  if (question.type === 'date') {
    return dateQuestionHandler(id, key, value, additionalInfo);
  }
  // default to textQuestionHandler
  return textQuestionHandler(id, key, value, additionalInfo);
};

/** Update Discovery Call (combined actions) */
export const updateDiscoveryPage = createAsyncThunk(
  'entities/updateDiscoveryPage',
  async (
    {
      id,
      clientId,
      auditId,
      projection,
      auditProjection,
      topics,
      ...args
    }: UpdateDiscoveryPageArgs,
    { dispatch, getState }
  ) => {
    const requests: any[] = [];

    const apiClient = getClient(selectApiClient(getState() as RootState));

    // store common values often required for API calls
    const additionalInfo = {
      auditId: auditId,
      auditName: args.audit_name,
      auditProjection: auditProjection,
      businessTypeId: args.business_type_id,
      clientId: clientId,
      clientName: args.client_name,
      clientWebsite: args.client_website,
      discoveryId: id,
      industryId: args.industry_id,
      leadSourceId: args.lead_source_id,
      pillarId: args.pillar_id,
      clientContacts: args.contacts,
      salesforceOpportunityId: args.salesforce_opportunity_id,
    };

    // get an array of question names coming from args object
    const questionKeys = Object.keys(args);

    // loop over the question names and pass them to their relevant API endpoints
    const results = questionKeys.map(questionKey => {
      const question = getQuestionFromTopicsByKey(questionKey, topics);
      if (questionHandlers[questionKey]) {
        // any questions that are special cases (other API calls like updateClient, updateAudit, etc.)
        return questionHandlers[questionKey](question?.id, questionKey, args[questionKey], additionalInfo);
      } else {
        // any questions that call updateDiscoveryQuestion mutation
        return defaultQuestionHandler(question.id, questionKey, args[questionKey], additionalInfo, topics);
      }
    });

    /** PreCall */
    // if (args.client_name) {
    //   requests.push(
    //     apiClient.updateClient({
    //       id: clientId,
    //       name: args.client_name,
    //     })
    //   );
    // }
    //
    // if (args.client_website) {
    //   requests.push(
    //     apiClient.updateClient({
    //       id: clientId,
    //       website: args.client_website,
    //     })
    //   );
    // }
    //
    // if (args.business_type_id) {
    //   requests.push(
    //     apiClient.updateDiscovery({
    //       id: id,
    //       business_type_id: args.business_type_id,
    //     })
    //   );
    // }
    //
    // if (args.industry_id) {
    //   requests.push(
    //     apiClient.updateClient({
    //       id: clientId,
    //       industry_id: args.industry_id,
    //     })
    //   );
    // }
    //
    // if (args.lead_source_id) {
    //   requests.push(
    //     apiClient.updateClient({
    //       id: clientId,
    //       lead_source_id: args.lead_source_id,
    //     })
    //   );
    // }
    //
    // if (args.pillar_id) {
    //   requests.push(
    //     apiClient.updateAudit({
    //       id: auditId,
    //       pillar_id: args.pillar_id,
    //     })
    //   );
    // }
    //
    // if (args.audit_name) {
    //   requests.push(
    //     apiClient.updateAudit({
    //       id: auditId,
    //       name: args.audit_name,
    //     }, {projection: auditProjection})
    //   );
    // }

    // if (args.contacts) {
    //   // filter out any empty field sets the user may have accidentally added
    //   let validContacts = args.contacts?.filter((contact: Contact) => {
    //     if (contact === undefined) {
    //       return false;
    //     }
    //     if (
    //       contact.name === '' &&
    //       contact.email === '' &&
    //       contact.title === '' &&
    //       contact.notes === ''
    //     ) {
    //       return false;
    //     }
    //     const { id, ...rest } = contact;
    //     return !Object.values(rest).every(
    //       (el) => el === undefined || el === null || isBlankRegex(el)
    //     );
    //   });
    //
    //   /** handle undefined/null values, convert to empty strings instead */
    //   validContacts?.forEach((contact: Contact) => {
    //     if (contact.notes === undefined) {
    //       contact.notes = null;
    //     }
    //     if (contact.email === undefined || contact.email === null) {
    //       contact.email = '';
    //     }
    //     if (contact.name === undefined) {
    //       contact.name = null;
    //     }
    //     if (contact.title === undefined) {
    //       contact.title = null;
    //     }
    //   });
    //
    //   /** More elegant solution but can't figure out typescript definition */
    //   // validContacts.forEach(contact => {
    //   //   Object.keys(contact).forEach(key => {
    //   //     if (contact[key] === undefined || contact[key] === null) {
    //   //       contact[key] = "";
    //   //     }
    //   //   });
    //   // });
    //
    //   // if any ids match what's in decision_maker_ids, then update is_decision_maker value for those contacts
    //   if (args.decision_maker_ids) {
    //     validContacts = validContacts?.map((contact: Contact) => ({
    //       ...contact,
    //       is_decision_maker: args.decision_maker_ids?.includes(contact.id)
    //         ? 'yes'
    //         : 'no',
    //     }));
    //   }
    //   requests.push(
    //     apiClient.saveContacts({
    //       client_id: clientId,
    //       contacts: validContacts,
    //       delete_missing: 'yes',
    //     })
    //   );
    // }

    // if (validateStringField(args.precall_opening_draft)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1045,
    //       answer: args.precall_opening_draft,
    //     })
    //   );
    // }
    //
    // if (validateStringField(args.precall_business_model)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1046,
    //       answer: args.precall_business_model,
    //     })
    //   );
    // }
    //
    // if (validateStringField(args.precall_similar_clients)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1047,
    //       answer: args.precall_similar_clients,
    //     })
    //   );
    // }
    //
    // if (validateStringField(args.precall_opportunities)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1003,
    //       answer: args.precall_opportunities,
    //     })
    //   );
    // }

    // if (validateStringField(args.precall_topic_notes)) {
    //   requests.push(
    //     apiClient.updateDiscoveryTopic({
    //       discovery_id: id,
    //       topic_id: 1001,
    //       notes: args.precall_topic_notes,
    //     })
    //   );
    // }

    /** Products & Revenue */
    // if (args.products) {
    //   // filter out any empty field sets the user may have accidentally added
    //   const validProducts = args.products
    //     ?.filter((product) => {
    //       if (product === undefined) {
    //         return false;
    //       }
    //       if (
    //         product.name === '' &&
    //         product.cost === null &&
    //         product.margin === null
    //       ) {
    //         return false;
    //       }
    //       return !Object.values(product).every((el) => el === undefined);
    //     })
    //     .map<Product>(
    //       (product: Product): Product => {
    //         return {
    //           ...product,
    //           cost: Number.parseFloat(
    //             formatCurrencyInput(product?.cost || undefined)
    //           ),
    //           margin: Number.parseFloat(
    //             formatCurrencyInput(product?.margin || undefined)
    //           ),
    //         };
    //       }
    //     );
    //
    //   // handle undefined/null values
    //   validProducts?.forEach((product) => {
    //     if (product.name === undefined || product.name === null) {
    //       product.name = '';
    //     }
    //     if (product.cost === undefined) {
    //       product.cost = null;
    //     }
    //     if (product.margin === undefined) {
    //       product.margin = null;
    //     }
    //   });
    //
    //   requests.push(
    //     apiClient.saveDiscoveryProducts({
    //       discovery_id: id,
    //       products: validProducts,
    //       delete_missing: 'yes',
    //     })
    //   );
    // }

    // if (validateStringField(args.question_aov)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1010,
    //       answer: args?.question_aov?.toString(),
    //     })
    //   );
    // }
    //
    // if (validateStringField(args.question_ltv)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1011,
    //       answer: args?.question_ltv?.toString(),
    //     })
    //   );
    // }
    //
    // if (validateStringField(args.question_customers)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1012,
    //       answer: args.question_customers,
    //     })
    //   );
    // }
    //
    // if (validateStringField(args.question_selling_proposition)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1048,
    //       answer: args.question_selling_proposition,
    //     })
    //   );
    // }

    // if (validateStringField(args.products_topic_notes)) {
    //   requests.push(
    //     apiClient.updateDiscoveryTopic({
    //       discovery_id: id,
    //       topic_id: 1004,
    //       notes: args.products_topic_notes,
    //     })
    //   );
    // }

    /** Sales Channels */
    // if (args.channels) {
    //   // filter out any empty field sets the user may have accidentally added
    //   const validChannels = args.channels
    //     ?.filter((channel) => {
    //       if (channel === undefined) {
    //         return false;
    //       }
    //       if (!channel.name && !channel.percent && channel.notes === '') {
    //         return false;
    //       }
    //       return !Object.values(channel).every((el) => el === undefined);
    //     })
    //     .map<Channel>(
    //       (channel: Channel): Channel => {
    //         return {
    //           ...channel,
    //           percent: Number.parseFloat(
    //             formatCurrencyInput(channel?.percent || undefined)
    //           ),
    //         };
    //       }
    //     );
    //
    //   // handle undefined/null values
    //   validChannels?.forEach((channel) => {
    //     if (!channel.name) {
    //       channel.name = null;
    //     }
    //     if (channel.percent === undefined) {
    //       channel.percent = null;
    //     }
    //     if (channel.notes === undefined || channel.notes === null) {
    //       channel.notes = '';
    //     }
    //   });
    //
    //   requests.push(
    //     apiClient.saveDiscoveryChannels({
    //       discovery_id: id,
    //       channels: validChannels,
    //       delete_missing: 'yes',
    //     })
    //   );
    // }

    // if (validateStringField(args.question_sales_process)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1025,
    //       answer: args.question_sales_process,
    //     })
    //   );
    // }
    //
    // if (validateStringField(args.question_cycle_length)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1027,
    //       answer: args.question_cycle_length,
    //     })
    //   );
    // }
    //
    // if (validateStringField(args.question_sqls)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1029,
    //       answer: args.question_sqls,
    //     })
    //   );
    // }
    //
    // if (validateStringField(args.question_close_ratio)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1031,
    //       answer: args.question_close_ratio,
    //     })
    //   );
    // }
    //
    // if (validateStringField(args.sales_channels_topic_notes)) {
    //   requests.push(
    //     apiClient.updateDiscoveryTopic({
    //       discovery_id: id,
    //       topic_id: 1003,
    //       notes: args.sales_channels_topic_notes,
    //     })
    //   );
    // }

    /** Marketing Strategy */
    // if (args.services) {
    //   // filter down to services that have values for both radios (is_current, producer)
    //   let validServices = args.services
    //     ?.filter((service: Service & { modified?: boolean }) => {
    //       if (service.is_current || service.producer || service.modified) {
    //         return true;
    //       }
    //       return false;
    //     })
    //     .map((service: Service & { modified?: boolean }) => {
    //       const newService = {
    //         ...service,
    //         is_active: service.modified ? 'no' : 'yes',
    //       };
    //
    //       // handle missing/undefined values
    //       if (!service.is_current) {
    //         newService['is_current'] = null;
    //       }
    //       if (!service.producer) {
    //         newService['producer'] = null;
    //       }
    //       if (!service.in_survey) {
    //         newService['in_survey'] = 'no';
    //       }
    //       if (!service.is_priority) {
    //         newService['is_priority'] = 'no';
    //       }
    //
    //       delete newService.modified;
    //       //delete newService.department.name;
    //       return newService;
    //     });
    //
    //   // if any ids match what's in in_survey_ids, then update in_survey value for those services
    //   if (args.in_survey_ids !== undefined) {
    //     //console.log('args.in_survey_ids', args.in_survey_ids);
    //     validServices = validServices?.map((service) => ({
    //       ...service,
    //       in_survey: args.in_survey_ids?.includes(service.id) ? 'yes' : 'no',
    //     }));
    //   }
    //
    //   // console.log('validServices passed to mutation', validServices);
    //
    //   requests.push(
    //     apiClient.saveDiscoveryServices({
    //       discovery_id: id,
    //       services: validServices,
    //       delete_missing: 'no',
    //     })
    //   );
    // }

    if (args.departments) {
      const _departments = Object.values(args.departments)
        .map((department: Department) => {
          return {
            id: department.id,
            notes: department.notes || null,
            analyst_notes: department.analyst_notes || null,
            // in_survey: department.in_survey || "no",
          };
        })
        .filter((department) => department.id !== undefined);

      requests.push(
        apiClient.saveDiscoveryDepartments({
          discovery_id: id,
          departments: _departments,
          delete_missing: 'no',
        })
      );
    }

    // if (args.customers) {
    //   // filter out any empty field sets the user may have accidentally added
    //   const validCustomers = args.customers?.filter((customer) => {
    //     if (customer === undefined) {
    //       return false;
    //     }
    //     if (customer.name === '' && customer.industry === '') {
    //       return false;
    //     }
    //     return !Object.values(customer).every((el) => el === undefined);
    //   });
    //
    //   // handle undefined/null values
    //   validCustomers?.forEach((customer) => {
    //     if (customer.name === undefined || customer.name === null) {
    //       customer.name = '';
    //     }
    //     if (customer.industry === undefined || customer.industry === null) {
    //       customer.industry = '';
    //     }
    //   });
    //
    //   requests.push(
    //     apiClient.saveDiscoveryCustomers({
    //       discovery_id: id,
    //       customers: validCustomers,
    //       delete_missing: 'yes',
    //     })
    //   );
    // }

    // if (args.competitors) {
    //   // filter out any empty field sets the user may have accidentally added
    //   const validCompetitors = args.competitors?.filter((competitor) => {
    //     if (competitor === undefined) {
    //       return false;
    //     }
    //     if (competitor.name === '' && competitor.website === '') {
    //       return false;
    //     }
    //     return !Object.values(competitor).every((el) => el === undefined);
    //   });
    //
    //   // handle undefined/null values
    //   validCompetitors?.forEach((competitor) => {
    //     if (competitor.name === undefined || competitor.name === null) {
    //       competitor.name = '';
    //     }
    //     if (competitor.website === undefined || competitor.website === null) {
    //       competitor.website = '';
    //     }
    //   });
    //
    //   requests.push(
    //     apiClient.saveDiscoveryCompetitors({
    //       discovery_id: id,
    //       competitors: validCompetitors,
    //       delete_missing: 'yes',
    //     })
    //   );
    // }

    // if (validateStringField(args.question_crm)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1005,
    //       answer: args.question_crm,
    //     })
    //   );
    // }
    //
    // if (validateStringField(args.question_email_platform)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1023,
    //       answer: args.question_email_platform,
    //     })
    //   );
    // }
    //
    // if (validateStringField(args.question_email_list_size)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1041,
    //       answer: args.question_email_list_size,
    //     })
    //   );
    // }
    //
    // if (validateStringField(args.marketing_strategy_topic_notes)) {
    //   requests.push(
    //     apiClient.updateDiscoveryTopic({
    //       discovery_id: id,
    //       topic_id: 1002,
    //       notes: args.marketing_strategy_topic_notes,
    //     })
    //   );
    // }

    /** KPIs */
    // if (args.goals) {
    //   // filter out any empty field sets the user may have accidentally added
    //   const validGoals = args.goals?.filter((goal) => {
    //     if (goal === undefined) {
    //       return false;
    //     }
    //     if (!goal.type && !goal.metric) {
    //       return false;
    //     }
    //     return !Object.values(goal).every((el) => el === undefined);
    //   });
    //
    //   // handle undefined/null values
    //   validGoals?.forEach((goal) => {
    //     if (!goal.type) {
    //       goal.type = null;
    //     }
    //     if (!goal.metric) {
    //       goal.metric = null;
    //     }
    //   });
    //
    //   requests.push(
    //     apiClient.saveDiscoveryGoals({
    //       discovery_id: id,
    //       goals: validGoals,
    //       delete_missing: 'yes',
    //     })
    //   );
    // }

    // if (args.growths) {
    //   // filter out any empty field sets the user may have accidentally added
    //   const validGrowths = args.growths?.filter((growth) => {
    //     if (growth === undefined) {
    //       return false;
    //     }
    //     if (growth.goal === '') {
    //       return false;
    //     }
    //     return !Object.values(growth).every((el) => el === undefined);
    //   });
    //
    //   requests.push(
    //     apiClient.saveDiscoveryGrowths({
    //       discovery_id: id,
    //       growths: validGrowths,
    //       delete_missing: 'yes',
    //     })
    //   );
    // }

    // if (validateStringField(args.kpis_topic_notes)) {
    //   requests.push(
    //     apiClient.updateDiscoveryTopic({
    //       discovery_id: id,
    //       topic_id: 1005,
    //       notes: args.kpis_topic_notes,
    //     })
    //   );
    // }

    /** Budget */
    // if (args.budgets) {
    //   // filter out any empty field sets the user may have accidentally added
    //   const validBudgets = args.budgets
    //     ?.filter((budget) => {
    //       if (budget === undefined) {
    //         return false;
    //       }
    //       if (
    //         budget.budget === null &&
    //         budget.ad_spend === null &&
    //         budget.notes === ''
    //       ) {
    //         return false;
    //       }
    //       return !Object.values(budget).every((el) => el === undefined);
    //     })
    //     .map<Budget>(
    //       (budget: Budget): Budget => {
    //         return {
    //           ...budget,
    //           budget: Number.parseFloat(
    //             formatCurrencyInput(budget?.budget || undefined)
    //           ),
    //           ad_spend: Number.parseFloat(
    //             formatCurrencyInput(budget?.ad_spend || undefined)
    //           ),
    //         };
    //       }
    //     );
    //
    //   // handle undefined/null values
    //   validBudgets?.forEach((budget) => {
    //     if (budget.budget === undefined) {
    //       budget.budget = null;
    //     }
    //     if (budget.ad_spend === undefined) {
    //       budget.ad_spend = null;
    //     }
    //     if (budget.notes === undefined || budget.notes === null) {
    //       budget.notes = '';
    //     }
    //   });
    //
    //   requests.push(
    //     apiClient.saveDiscoveryBudgets({
    //       discovery_id: id,
    //       budgets: validBudgets,
    //       delete_missing: 'yes',
    //     })
    //   );
    // }

    // if (validateStringField(args.budget_topic_notes)) {
    //   requests.push(
    //     apiClient.updateDiscoveryTopic({
    //       discovery_id: id,
    //       topic_id: 1006,
    //       notes: args.budget_topic_notes,
    //     })
    //   );
    // }

    /** Buying Criteria */
    // if (args.question_new_partner_timeline !== undefined) {
    //   if (
    //     args.question_new_partner_timeline === null ||
    //     isBlankRegex(args.question_new_partner_timeline)
    //   ) {
    //     requests.push(
    //       apiClient.deleteDiscoveryQuestion({
    //         discovery_id: id,
    //         question_id: 1016,
    //       })
    //     );
    //   } else {
    //     requests.push(
    //       apiClient.updateDiscoveryQuestion({
    //         discovery_id: id,
    //         question_id: 1016,
    //         answer: moment(args.question_new_partner_timeline).format(
    //           'MM/DD/YYYY'
    //         ),
    //       })
    //     );
    //   }
    // }

    // if (args.question_strategy_launch_date !== undefined) {
    //   if (
    //     args.question_strategy_launch_date === null ||
    //     isBlankRegex(args.question_strategy_launch_date)
    //   ) {
    //     requests.push(
    //       apiClient.deleteDiscoveryQuestion({
    //         discovery_id: id,
    //         question_id: 1017,
    //       })
    //     );
    //   } else {
    //     requests.push(
    //       apiClient.updateDiscoveryQuestion({
    //         discovery_id: id,
    //         question_id: 1017,
    //         answer: moment(args.question_strategy_launch_date).format(
    //           'MM/DD/YYYY'
    //         ),
    //       })
    //     );
    //   }
    // }

    // if (validateStringField(args.question_decision_basis)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1018,
    //       answer: args.question_decision_basis,
    //     })
    //   );
    // }
    //
    // if (validateStringField(args.buying_criteria_topic_notes)) {
    //   requests.push(
    //     apiClient.updateDiscoveryTopic({
    //       discovery_id: id,
    //       topic_id: 1007,
    //       notes: args.buying_criteria_topic_notes,
    //     })
    //   );
    // }
    //
    // /** Audit Framing */
    // if (validateStringField(args.question_access_request)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1021,
    //       answer: args.question_access_request,
    //     })
    //   );
    // }
    //
    // if (args.question_audit_presentation_date !== undefined) {
    //   if (
    //     args.question_audit_presentation_date === null ||
    //     isBlankRegex(args.question_audit_presentation_date)
    //   ) {
    //     requests.push(
    //       apiClient.deleteDiscoveryQuestion({
    //         discovery_id: id,
    //         question_id: 1022,
    //       })
    //     );
    //   } else {
    //     requests.push(
    //       apiClient.updateDiscoveryQuestion({
    //         discovery_id: id,
    //         question_id: 1022,
    //         answer: moment(args.question_audit_presentation_date).format(
    //           'MM/DD/YYYY'
    //         ),
    //       })
    //     );
    //   }
    // }
    //
    // if (validateStringField(args.question_pains_challenges)) {
    //   requests.push(
    //     apiClient.updateDiscoveryQuestion({
    //       discovery_id: id,
    //       question_id: 1049,
    //       answer: args.question_pains_challenges,
    //     })
    //   );
    // }
    //
    // if (validateStringField(args.audit_framing_topic_notes)) {
    //   requests.push(
    //     apiClient.updateDiscoveryTopic({
    //       discovery_id: id,
    //       topic_id: 1008,
    //       notes: args.audit_framing_topic_notes,
    //     })
    //   );
    // }

    if (args.user_score) {
      requests.push(
        apiClient.updateDiscovery({
          id: id,
          user_score: args.user_score,
        })
      );
    }

    // call all requests for discovery page
    //await Promise.all(requests);
    await Promise.all(results);


    const defaultAuditProjection = auditProjection || novaGraphQLClient.defaultAuditProjection;
    let refreshAuditProjection = null;

    /**
    * Refresh the audit in the redux store and update the initial values if:
    */

    // 1. There's no audit ID (audit not created prior to this aciton being run)
    if (!auditId) {
      refreshAuditProjection = defaultAuditProjection;
    }

    // 2. The user added contacts (newly created contacts needs an ID) or updated existing contacts
    if (Array.isArray(args?.contacts)) {
      refreshAuditProjection = { client: defaultAuditProjection.client };
    }

    if (refreshAuditProjection) {
      await dispatch(
        fetchAuditById({
          id: auditId,
          projection: {
            ...refreshAuditProjection,
            id: true,
          },
        })
      );
    }

    return { ...normalizeEntities({}) }; // this is where we want to add our side effect
  }
);

/** Create Prospect */
export const createProspect = createAsyncThunk(
  'entities/createProspect',
  async (
    prospect: Partial<Prospect> & {
      company: string;
      business_type_id: number;
      contact: string;
      email: string;
      website: string;
      recaptcha: string;
    },
    { getState }
  ) => {
    const response = await novaGraphQLPublicClient.createProspect({
      company: prospect.company,
      business_type_id: parseInt(String(prospect.business_type_id), 10),
      contact: prospect.contact,
      email: prospect.email,
      website: prospect.website,
      recaptcha: prospect.recaptcha,
    });

    Cookies.set('entityId', String(response?.prospects?.[0]?.id));
    Cookies.set('signature', String(response?.prospects?.[0]?.signature));

    getClient(selectApiClient(getState() as RootState)).resetClient();
    return { ...normalizeEntities(response) };
  }
);

/** Create Presentation */
export type CreatePresentationDTO = {
  audit_id?: number;
  client_id?: number;
  name?: string;
  status?: string;
  deck_url?: string;
  templates: number[];
  variables: PresentationTemplateVariable[];
  client_name: string;
  website?: string;
  logo_path?: string;
  notes?: string;
  business_type_id: number;
  integrations?: IntegrationInput[];
};

export const createPresentation = createAsyncThunk(
  'entities/createPresentation',
  async (
    presentation: CreatePresentationDTO
  ) => {
    const response = await novaGraphQLClient.createPresentation({
      audit_id: parseInt(String(presentation.audit_id), 10),
      client_id: parseInt(String(presentation.client_id), 10),
      name: presentation.name,
      status: presentation.status,
      deck_url: presentation.deck_url,
      templates: presentation.templates,
      variables: presentation.variables,
      client_name: presentation.client_name,
      website: presentation.website,
      logo_path: presentation.logo_path,
      notes: presentation.notes,
      business_type_id: parseInt(String(presentation.business_type_id), 10),
      integrations: presentation.integrations,
    });
    return { ...normalizeEntities(response) };
  }
);

/** Update Presentation */
export const updatePresentation = createAsyncThunk(
  'entities/updatePresentation',
  async (
    presentation: {
      id?: number;
      name?: string;
      status?: string;
      deck_url?: string;
      percent?: number;
      logo_path?: string;
      notes?: string;
      templates: Array<number>;
      variables: PresentationTemplateVariable[];
      integrations?: IntegrationInput[];
      regenerate?:boolean;
    }
  ) => {
    const response = await novaGraphQLClient.updatePresentation({
      id: parseInt(String(presentation.id), 10),
      name: presentation.name,
      status: presentation.status,
      deck_url: presentation.deck_url,
      logo_path: presentation.logo_path,
      notes: presentation.notes,
      percent: parseInt(String(presentation.percent), 10),
      templates: presentation.templates,
      variables: presentation.variables,
      integrations: presentation.integrations,
      regenerate: presentation.regenerate,
    });
    return { ...normalizeEntities(response) };
  }
);

/** Create Audit */
export const createAudit = createAsyncThunk(
  'entities/createAudit',
  async (
    audit: CreateAuditActionArgs
  ) => {
    const response = await novaGraphQLClient.createAudit({
      audit_ids: audit.audit_ids,
      audit_id: audit.audit_id === 0 ? undefined : audit.audit_id, // handles special case for re-appraisals
      client_id: audit.client_id,
      client_name: audit.client_name,
      pillar_id: parseInt(String(audit.pillar_id), 10),
      tier_id: parseInt(String(audit.tier_id), 10),
      name: audit.name ? audit.name : `${audit.client_name} Appraisal`,
      create_discovery: audit.create_discovery || 'yes',
      website: audit?.website,
    });
    return { ...normalizeEntities(response) };
  }
);

/** Delete */
export const deleteAudit = createAsyncThunk(
  'entities/deleteAudit',
  async (id: number) => {
    const response = await novaGraphQLClient.deleteAudit(id);
    return { ...normalizeEntities(response) };
  }
);

/** Analyst Survey - Update or Refresh Automated Scores */
export const updateAutomatedScores = createAsyncThunk(
  'entities/updateAutomatedScores',
  async (
    {
      audit_id,
      data_source_slug,
      id,
      ids,
      integration_id,
      status,
    }: {
      audit_id: number;
      data_source_slug?: string;
      id?: number;
      ids?: number[];
      integration_id?: number;
      status?: string;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { getState }: { getState: any }
  ) => {
    const response = await novaGraphQLClient.updateAutomatedScores({
      audit_id,
      data_source_slug,
      id,
      ids,
      integration_id,
      status
    });
    const audit = selectAuditById(audit_id)(getState());
    const automatedScoreIds = response?.map(i => i.id);
    const automatedScores = audit?.automated_scores?.filter((score: AutomatedScore) => {
      return !automatedScoreIds.includes(score?.id);
    });
    const updatedAudit = {
      id: audit_id,
      automated_scores: automatedScores.concat(response)
    };
    return { ...normalizeEntities({ audits: [updatedAudit] }) };
  }
);
