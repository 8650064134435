import React, { createContext, useContext, useReducer } from 'react';
import { GetPlaybookAdsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookAds';
import { GetPlaybookAdsetsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookAdsets';
import { GetPlaybookCampaignsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookCampaigns';
import { isEqual } from 'lodash';

type Query = Omit<GetPlaybookCampaignsQuery | GetPlaybookAdsetsQuery | GetPlaybookAdsQuery, 'Projection'>;
type ReducerType = { type: 'add' | 'remove' | 'reset'; value?: Partial<Query> };
export type FilterReturnType = {
  filter: Partial<Query>;
  setFilter: React.Dispatch<ReducerType>;
};

export const Filters = (initialState?: Partial<Query>): FilterReturnType => {
  const [state, dispatch] = useReducer((state: Partial<Query>, action: ReducerType): Partial<Query> => {
    switch (action.type) {
      case 'add': {
        const newState: Partial<Query> = { ...state };
        if (action.value) {
          Object.keys(action.value).forEach((key) => {
            // Check if the key already exists in the state and if the value is different
            if (action.value && !isEqual(newState[key as keyof Query], action.value[key as keyof Query])) {
              newState[key as keyof Query] = action.value[key as keyof Query] as undefined; // Typecast the value as undefined
            }
          });
        }
        return newState;
      }
      case 'reset': {
        return {};
      }
      default:
        return state;
    }
  }, { ...initialState } as Partial<Query>);

  return {
    filter: state,
    setFilter: dispatch,
  };
};

export const FilterContext = createContext<FilterReturnType | undefined>(undefined);

export const FilterProvider = (props: { children: React.ReactNode, filter: FilterReturnType }): JSX.Element => (
  <FilterContext.Provider value={props.filter}>
    {props.children}
  </FilterContext.Provider>
);

export const useFilterContext: () => FilterReturnType | Record<string, any> = () => useContext(FilterContext) || {};
