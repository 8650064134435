/**
* Get intelligence products
*/

import { Projection } from 'api/entityGraphQL/types';
import { YesNo } from 'features/entitiesRedux';
import tagTypes from './tagTypes';
import { baseApi } from '../..';

export type GetIntelligenceProductsQuery = { projection: Projection; };

export type GetIntelligenceProductsResult = { id: number; name: string; slug: string; is_authorized: YesNo }[];

export const GET_INTELLIGENCE_PRODUCTS_ENDPOINT_KEY = 'getIntelligenceProducts';

export const { useGetIntelligenceProductsQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_INTELLIGENCE_PRODUCTS_ENDPOINT_KEY]: build.query<GetIntelligenceProductsResult, GetIntelligenceProductsQuery>({
      // Don't refetch this endpoint until a full page reload
      keepUnusedDataFor: Number.MAX_SAFE_INTEGER,
      transformResponse: (response: { intelligence_products: GetIntelligenceProductsResult }) => response.intelligence_products,
      providesTags: (products = []) => [
        tagTypes.INTELLIGENCE_PRODUCTS,
        ...products.map(product => ({
          id: product.id,
          type: tagTypes.INTELLIGENCE_PRODUCT
        }))
      ],
      query: ({
        projection = { id: true, name: true },
      }) => {

        return {
          body: {
            query: {
              intelligence_products: {
                ...projection
              },
            }
          }
        };
      },
    }),
  }),
});
