/**
 * Get strategies
 */

import { baseApi, cleanArguments, paginationProjection } from 'api/crudGraphQL';
import { Pagination, Projection } from 'api/entityGraphQL';
import { Strategy } from 'features/entitiesRedux';
import tagTypes from './tagTypes';

export type GetStrategiesQuery = {
  id?: number;
  author_id?: number;
  department_id?: number;
  department_ids?: number[];
  industry_id?: number;
  is_department_pending_review?: string;
  is_finance_reviewed?: boolean;
  is_department_heads_reviewed?: boolean;
  name?: string;
  own?: boolean;
  pillar_ids?: number[];
  pricing_version?: string;
  salesforce_opportunity_id?: string;
  salesforce_opportunity_ids?: string[];
  salesforce_opportunity_name?: string;
  status?: string;
  statuses?: string[];
  user_ids?: number[];
  page?: number;
  limit?: number;
  sort?: string[];
  projection?: Projection;
};

export type GetStrategiesResult = Pagination & {
  data: Strategy[]
};

export const GET_STRATEGIES_ENDPOINT_KEY = 'getStrategies';

export const { useGetStrategiesQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_STRATEGIES_ENDPOINT_KEY]: build.query<GetStrategiesResult, GetStrategiesQuery>({
      transformResponse: (response: { strategies: GetStrategiesResult }) => response.strategies,
      providesTags: (result) => {
        const { data } = result ?? { data: [] };

        return [
          tagTypes.STRATEGIES,
          ...data.map((strategy: Strategy) => ({
            id: strategy.id,
            type: tagTypes.STRATEGY
          }))
        ];
      },
      query: ({
        projection = { id: true, name: true },
        page = 1,
        limit = 10,
        name,
        ...args
      }) => {
        const __args: Partial<GetStrategiesQuery> = {
          page,
          limit,
          ...args,
        };

        if (name) {
          __args.name = `*${name}*`;
        }

        return {
          body: {
            query: {
              strategies: {
                __args: cleanArguments(__args),
                data: projection,
                ...paginationProjection
              }
            }
          }
        };
      }
    })
  })
});
