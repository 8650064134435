import React, { FC, memo } from 'react';
import { InfoTooltip } from '@sprnova/nebula';

interface BlueprintStageTooltipProps {
  iconSize?: 'small' | 'inherit' | 'large' | 'medium';
  height?: number | string;
  width?: number | string;
}

const BlueprintStageTooltip: FC<BlueprintStageTooltipProps> = ({ iconSize = 'small', height = '20px', width = '20px' }): JSX.Element => {
  return (
    <InfoTooltip
      content='This defines where the Blueprint is in the Salesforce process'
      iconProps={{ style: { color: '#575757', height, width } }}
      iconSize={iconSize}
      placement='right'
      sx={{ marginLeft: '8px' }}
      maxWidth={350}
      variant='plain'
    />
  );
};

/**
 * Tooltip with definition of blueprint stage
 * @param iconSize Size of the tooltip icon: small | inherit | large | medium
 * @param height Height of the tooltip icon
 * @param width Width of the tooltip icon
 */
export default memo(BlueprintStageTooltip);
