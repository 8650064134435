/**
 * SectionBuilder -> Fields -> YesNoField
 */

import React, { FC } from 'react';
import { FormItem, RadioButton, RadioGroup } from 'components';
import { FieldProps } from '../../types';
import css from './YesNoField.module.scss';

const YesNoField: FC<FieldProps> = ({ name }) => {
  return (
    <FormItem name={name} className={css.root}>
      <RadioGroup buttonStyle="solid">
        <RadioButton value="yes">Yes</RadioButton>
        <RadioButton value="no">No</RadioButton>
      </RadioGroup>
    </FormItem>
  );
};

export default YesNoField;
