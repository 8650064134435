/**
 * Library -> Services
 */

import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, PlusIcon, Skeleton } from '@sprnova/nebula';
import { Action } from 'api/accessControl';
import { Resource } from 'api/accessControl/Resource';
import { useDeleteServiceMutation } from 'api/crudGraphQL/services/deleteService';
import { useGetServicesQuery } from 'api/crudGraphQL/services/getServices';
import omitBy from 'lodash/omitBy';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { useMixpanelTrack } from 'utils';
import { Department } from 'features/entitiesRedux';
import { Service, FetchServicesFilter } from 'features/entitiesRedux/models/service/service';
import { useAccount } from 'features/global';
import LibraryPageHero from 'features/library/components/LibraryPageHero/LibraryPageHero';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import {
  AccessControl,
  notification,
  NoResultsFound,
} from 'components';
import { List, Filters } from './components';
import { initialValues as initialFilterValues} from './components/Filters';

const Services = (): JSX.Element => {
  const [filters, setFilters] = useState({});
  const [pricingVersionQueryParam,] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );
  const isPackageVersion = pricingVersionQueryParam === PricingVersion.PACKAGE;
  const mixpanel = useMixpanelTrack();

  /**
   * Use ref to trigger useEffect only once
   */
  const didSendPageViewEventRef = useRef(false);

  const { account } = useAccount();

  // Track mixpanel event when page is visited for package version only
  useEffect(() => {
    if (!didSendPageViewEventRef.current && account && isPackageVersion) {
      didSendPageViewEventRef.current = true;
      mixpanel('Services Page Viewed', {
        title: account?.name,
        userId: account?.id,
        userName: account?.name,
        department: account?.department?.name,
        departments: JSON.stringify(account?.departments?.map((department: Department) => department.name))
      });
    }
  }, [account, isPackageVersion, mixpanel]);
  /**
   * Fetch services
   */
  const { data: services = [], isLoading, isFetching } = useGetServicesQuery({
    pricing_version: pricingVersionQueryParam,
    projection: {
      id: true,
      name: true,
      department: {
        id: true,
        name: true,
      },
      business_types: {
        id: true,
        name: true
      },
      pricing_version: {
        name: true,
        slug: true
      }
    },
  });

  /**
   * Delete service
   */
  const [deleteServiceRequest, { isLoading: isDeletingService }] = useDeleteServiceMutation();

  const mixpanelTracking = (service: Partial<Service> & Pick<Service, 'id'>): void => {
    const options = {
      businessTypes: service?.business_types?.map((business_type) => business_type),
      department: service?.department,
      serviceId: service?.id,
      serviceName: service?.name,
      pricingVersion: service?.pricing_version?.name,
    };
    const eventTitle = 'Library Service deleted';
    mixpanel(eventTitle, options);
  };

  const handleDelete = async (service: Partial<Service> & Pick<Service, 'id'>): Promise<void> => {
    try {
      await deleteServiceRequest({ id: service.id });

      mixpanelTracking(service);
      notification.success({
        message: 'Service deleted',
      });
    } catch (error) {
      notification.error({
        message: 'Failed to delete service',
      });
      console.error('Error deleting service', error);
    }
  };

  const handleFiltersChange = useCallback((filterValues) => {
    setFilters(filterValues);
  }, []);

  const handleResetFilters = useCallback(() => {
    setFilters(initialFilterValues);
  }, []);

  const hasFilters = useMemo(() => !!Object.keys(omitBy(filters, val => !val)).length, [filters]);
  const serviceFilterFunc = useCallback((service: Service) => {
    const result = [];
    const { name, business_types, department } = service;
    const {
      business_type_id:
      businessTypeFilter,
      department_id: departmentFilter,
      name: nameFilter
    }: FetchServicesFilter = filters;

    // Filter by name
    if (nameFilter && typeof name === 'string') {
      result.push(name.toLowerCase().includes(nameFilter.toLowerCase()));
    }

    // Filter by business type
    if (businessTypeFilter && business_types) {
      const businessTypeIds = business_types.map(type => type.id);
      result.push(businessTypeIds.includes(businessTypeFilter));
    }

    // Filter by department
    if (departmentFilter && department) {
      result.push(department.id === departmentFilter);
    }

    // Only return services if all filters match
    return !result.filter(x => x === false).length;
  }, [filters]);

  const filteredServices = useMemo(() => hasFilters ? services.filter(serviceFilterFunc) : services, [hasFilters, services, serviceFilterFunc]);

  /**
   * Render the hero button
   * @returns JSX.Element - The hero button
   */
  const renderHeroButton = useCallback((): JSX.Element => {
    return (
      <Button
        variant="primary"
        size="large"
        to={`/library/services/new?${pricingVersionString}=${pricingVersionQueryParam}`}
        component={Link}
        startIcon={<PlusIcon />}
      >
          New Service
      </Button>
    );
  }, [pricingVersionQueryParam]);

  /**
   * If data are loading, display a skeleton
   * else display the hero button:
   * - If pricing version is Package, use Library Package Access Control.
   * @returns JSX.Element - The hero button
   */
  const renderHeroButtonContainer = useCallback((): JSX.Element => {
    if (isLoading) {
      return <Skeleton width={200} height={50} />;
    } else {
      const resource = isPackageVersion ? Resource.libraryService : Resource.service;
      return (
        <AccessControl
          key="create-service"
          action={[Action.create]}
          resource={resource}
          showWarning={false}
        >
          {renderHeroButton()}
        </AccessControl>
      );
    }
  }, [isLoading, isPackageVersion, renderHeroButton]);

  /**
   * If no services in the library exist, display a no results found message
   * else display list of library services.
   * @returns JSX.Element - The library Services content
   */
  const renderContent = (): JSX.Element => {
    if(!services.length && !hasFilters && !isLoading) {
      return <NoResultsFound title="No services created" />;
    } else {
      return (
        <>
          <Filters
            onValuesChange={handleFiltersChange}
            values={filters}
            onReset={handleResetFilters}
          />
          { (hasFilters && !filteredServices.length) ? (
            <NoResultsFound
              title="No services matched the applied filters"
              buttonProps={{
                children: 'Reset filters',
                type: 'primary',
                onClick: handleResetFilters,
              }}
            />
          ) : (
            <List
              onDelete={handleDelete}
              loading={isDeletingService || isFetching || isLoading}
              services={filteredServices}
            />
          )}
        </>
      );
    }
  };

  /**
   * If pricing version is Package, use Library Package Access Control.
   * @returns JSX.Element - The library Services content container
   */
  const renderContentContainer = (): JSX.Element => {
    const resource = isPackageVersion ? Resource.libraryService : Resource.service;
    return (
      <AccessControl action={[Action.read]} resource={resource}>
        {renderContent()}
      </AccessControl>
    );
  };

  return (
    <>
      <LibraryPageHero
        title={`Services ${isPackageVersion ? '(Package)' : ''}`}
        end={renderHeroButtonContainer()}
      />
      <Container hasVerticalPadding>
        {isLoading ? <Skeleton height={200} /> : renderContentContainer()}
      </Container>
    </>
  );
};

export default Services;
