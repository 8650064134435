import { EntityAdapter, EntityState, EntityId } from '@reduxjs/toolkit';

/**
 * Workaround to prevent redux from storing previously cached tasks
 *
 * @param adapter
 * @param state
 * @param data
 */
export const adapterSetAll = (
  adapter: EntityAdapter<any>,
  state: EntityState<any>,
  data: any[] | Record<EntityId, any>
) => {
  if (!data) return;

  adapter.setAll(state, data);
};