import loadable from '@loadable/component';

const ListItemStandard = loadable(
  () => import('./ListItemStandard')
);

const ListItemStandardMeta = loadable(
  () => import('./ListItemStandardMeta')
);

export { ListItemStandard, ListItemStandardMeta };