/**
 * Get service
 */

import { Projection } from 'api/entityGraphQL';
import { Service } from 'features/entitiesRedux';
import { baseApi } from '../baseApi';
import { GetServicesResult } from './getServices';
import tagTypes from './tagTypes';

export type GetServiceQuery = {
  id: number;
  projection: Projection;
}

export type GetServiceResult = Service;

export const { useGetServiceQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getService: build.query<GetServiceResult, GetServiceQuery>({
      transformResponse: (response: { services: GetServicesResult }) => response.services[0],
      providesTags: (service) => [
        service?.id ? { id: service.id, type: tagTypes.SERVICE } : tagTypes.SERVICE,
      ],
      query: ({
        projection = { id: true },
        id,
      }) => ({
        body: {
          query: {
            services: {
              __args: {
                id
              },
              ...projection
            },
          }
        }
      }),
    })
  })
});
