import { Projection } from 'api/entityGraphQL';

export const defaultContactProjection: Projection = {
  id: true,
  name: true,
  title: true,
  email: true,
  phone: true,
  is_decision_maker: true,
  notes: true,
  client: {
    id: true,
    name: true,
  },
  accounts: {
    id: true,
    account: {
      id: true,
      name: true,
    },
  },
  created_at: true,
  updated_at: true,
};
