import React, { useCallback, useMemo, memo } from 'react';
import { GridCellParams, GridPaginationModel, GridSortItem } from '@mui/x-data-grid-premium';
import { Box, Button, DataGrid, GridColDef } from '@sprnova/nebula';
import { useFilterContext } from 'features/scoreboards/utils/Filters';

type ScoreboardsListProps = {
  rows: Array<object>;
  columns: GridColDef[];
  total: number;
  isLoading: boolean;
  hideFooter?: boolean;
  isMyAccounts?: boolean;
  isExternal?: boolean;
  clientId?: number;
};

const ScoreboardsList = ({
  rows,
  columns,
  total,
  isLoading,
  hideFooter,
  isMyAccounts,
  isExternal,
  clientId,
}: ScoreboardsListProps): JSX.Element => {
  const { setFilter } = useFilterContext();

  const handleOpenIntercom = useCallback(() => {
    if ((window['Intercom'])) {
      window['Intercom']('show');
    }
  }, []);

  const emptyStateProps = useMemo(() => {
    if (isMyAccounts) {
      if (isExternal) {
        return {
          title: 'You don\'t have any scoreboards (yet)!',
          description: <Box>
            Scoreboards are dashboards set up by your account team to paint a picture of your marketing performance.
            <Box sx={{ marginTop: '20px' }}>
              <Button variant='text' size='large'>Learn more</Button>
            </Box>
          </Box>,
        };
      } else {
        return {
          title: 'You aren\'t assigned to any scoreboards',
          description: <>Need assistance or have questions? Please reach out to nova support through our <Button variant='text'onClick={handleOpenIntercom}>chat platform</Button>.</>,
        };
      }
    }
    return undefined;
  }, [isMyAccounts, handleOpenIntercom, isExternal]);

  const handleSort = useCallback((model: GridSortItem[]): void => {
    const { field, sort } = model?.[0] ?? {};
    const sortValue = sort === 'desc' ? '-' + field : field;
    setFilter?.({ type: 'add', value: { sort: sortValue } });
  }, [setFilter]);

  const handlePagination = useCallback((model: GridPaginationModel): void => {
    setFilter?.({ type: 'add', value: { page: model.page + 1, limit: model.pageSize } });
  }, [setFilter]);

  const handleCellClick = useCallback((params: GridCellParams): void => {
    const { id, field } = params;
    if (field !== 'extra' && id) {
      const link = isExternal ? `/c/${clientId}/scoreboards/${id}` : `/scoreboards/${id}`;
      window.open(link, '_blank', 'noopener,noreferrer');
    }
  }, [clientId, isExternal]);

  const renderTable = useMemo(() => {
    /**
     * Show skeleton table on initial load
     */
    if (isLoading && !rows.length) {
      return <DataGrid rows={[]} columns={columns} skeleton skeletonRows={3} />;
    }
    return (
      <DataGrid
        rows={rows}
        columns={columns}
        rowHeight={88}
        sortingMode='server'
        paginationMode='server'
        onSortModelChange={handleSort}
        onPaginationModelChange={handlePagination}
        onCellClick={handleCellClick}
        pagination
        rowCount={total}
        loading={isLoading}
        hideFooter={hideFooter}
        emptyStateProps={emptyStateProps}
        sx={{ 'cursor': 'pointer' }}
      />
    );
  }, [columns, isLoading, rows, handleSort, handlePagination, handleCellClick, total, hideFooter, emptyStateProps]);

  return renderTable;
};

export default memo(ScoreboardsList);
