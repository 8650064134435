/**
 * Client -> ClientSalesforceSettings
 */

import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'app/store';
import { Alert } from 'components/Alert/index';
import { Button } from 'components/Button/index';
import { FormItem } from 'components/FormItem/index';
import { message } from 'components/message/index';
import { SelectSalesforceAccount } from 'components/SelectSalesforceAccount/index';
import formatErrorToHumanReadable from 'utils/format/formatErrorToHumanReadable';
import { Audit } from 'features/entitiesRedux/models/audit/audit';
import { Client, updateClient } from 'features/entitiesRedux/models/client';
import {useUpdateAuditMutation} from '../../../../../api/crudGraphQL/audits/updateAudit';
import css from './ClientSalesforceSettings.module.scss';

type Props = {
  buttonLabel?: string;
  client?: Partial<Client>;
  customLabel?: string;
  isDisabled?: boolean;
  onSelectAccount?: (val: string | null | undefined) => void;
  onUnselectAccount?: () => void;
  suffixIcon?: React.ReactNode
  audit?: Audit;
}

const ClientSalesforceSettings: FC<Props> = ({ audit, buttonLabel, client, customLabel, isDisabled, onSelectAccount, onUnselectAccount, suffixIcon }) => {
  const isLinked = !!client?.salesforce_client_id;
  const dispatch: AppDispatch = useDispatch();
  const [salesforceClientId, setSalesforceClientId] = useState<string | null | undefined>(client?.salesforce_client_id);
  const [loading, setLoading] = useState<boolean>(false);
  const [removing, setRemoving] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [connectionError, setConnectionError] = useState<string>('');
  const [updateAuditMutation] = useUpdateAuditMutation();

  useEffect(() => {
    if (salesforceClientId !== client?.salesforce_client_id) {
      setSalesforceClientId(client?.salesforce_client_id);
    }
  }, [client?.salesforce_client_id]);

  const handleUpdate = async (
    { salesforce_client_id, salesforce_client_name }: Pick<Client, 'salesforce_client_id' | 'salesforce_client_name'>,
    onSuccess?: () => void
  ) => {
    if (client?.id) {
      const isRemove = salesforce_client_id === null;
      setLoading(true);

      const action = await dispatch(
        updateClient({
          id: client.id,
          salesforce_client_id,
        })
      );

      if (updateClient.fulfilled.match(action)) {
        setSalesforceClientId(salesforce_client_id);
        setError('');
        message.success(isRemove ?
          `${client.name} was unlinked from ${client.salesforce_client_name ? client.salesforce_client_name : 'Salesforce'} successfully` :
          `${client.name} was linked to ${salesforce_client_name ? salesforce_client_name : 'Salesforce'} successfully`);

        onSuccess?.();
      }

      if (updateClient.rejected.match(action)) {
        // Slice out the actual error message from the stringified error response
        const error = typeof action?.error?.message === 'string'
          ? formatErrorToHumanReadable(action.error.message)
          : 'An unknown error occoured. Please try again.';

        setSalesforceClientId(null);
        setError(error);
      }

      setLoading(false);
      setRemoving(false);
    }
  };

  const handleSelectAccount = async (_: string, data: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    setError('');

    await handleUpdate({
      salesforce_client_id: data.value,
      salesforce_client_name: data.children,
    }, () => {
      onSelectAccount?.(data.value);
    });
  };

  const handleRemoveLink = async () => {
    setRemoving(true);

    await handleUpdate({
      salesforce_client_id: null,
      salesforce_client_name: null,
    }, onUnselectAccount);

    setRemoving(false);
  };

  const handleConnectionError = (error: string) => {
    if (error) setConnectionError(error);
  };

  return (
    <div className={css.root} id="salesforce-account">
      <div className={css.row}>
        <div className={css.col_select}>
          <FormItem label={customLabel ? customLabel : 'Link to Salesforce Account'}>
            {!connectionError ?
              <SelectSalesforceAccount
                loading={loading}
                disabled={loading || isLinked || isDisabled}
                value={salesforceClientId || null}
                onSelect={handleSelectAccount}
                onConnectionError={handleConnectionError}
                suffixIcon={suffixIcon}
              /> :
              <Alert
                type="error"
                showIcon
                message={connectionError}
              />
            }
          </FormItem>

          {error && <Alert showIcon className={css.error} type="error" message={error} />}
        </div>
        <div className={css.col_button}>
          {(isLinked && !connectionError) ? (
            <Button
              danger
              disabled={loading || removing}
              onClick={handleRemoveLink}
            >
              {removing ? 'Removing...' : buttonLabel ? buttonLabel : 'Remove link'}
            </Button>
          ) : <></>}
        </div>
      </div>

    </div>
  );
};

export default ClientSalesforceSettings;
