/**
 * Get scoreboard (reports) public
 */
import { Projection } from 'api/entityGraphQL';
import { cleanArguments } from "api/crudGraphQL/utils";
import { basePublicApi } from "../basePublicApi";
import tagTypes from './tagTypes';
import { StatusArgs, IndicatorArgs, GoalPacingArgs } from '../../scoreboards/getScoreboard';
import { Scoreboard } from 'api/crudGraphQL/scoreboards/types';

export type GetScoreboardPublicQuery = {
  id: number;
  statusArgs?: StatusArgs;
  indicatorArgs?: IndicatorArgs;
  goalPacingArgs?: GoalPacingArgs;
  projection?: Projection;
}
export type GetScoreboardPublicResult = Scoreboard;

export const GET_SCOREBOARD_PUBLIC_ENDPOINT_KEY = 'getScoreboardPublic';

export const { useGetScoreboardPublicQuery, util } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_SCOREBOARD_PUBLIC_ENDPOINT_KEY]: build.query<GetScoreboardPublicResult, GetScoreboardPublicQuery>({
      transformResponse: (response: { report: GetScoreboardPublicResult}) => response.report,
      providesTags: (report) => {
        return report ? [
          { id: report.id, type: tagTypes.SCOREBOARD }
        ] : [tagTypes.SCOREBOARDS];
      },
      query: ({
        id,
        statusArgs,
        indicatorArgs,
        goalPacingArgs,
        projection = { id: true },
      }) => {
        if (id === 0) {
          id = 1;
        }

        if (statusArgs) {
          /**
           * Add status args to projection
           */
          projection = {
            ...projection,
            statuses: {
              __args: cleanArguments(statusArgs),
              id: true,
              ...projection.statuses,
            }
          };
        }

        if (indicatorArgs) {
          /**
           * Add indicator args to projection
           */
          projection = {
            ...projection,
            indicators: {
              __args: cleanArguments(indicatorArgs),
              id: true,
              ...projection.indicators,
            }
          };
        }

        if (goalPacingArgs) {
          /**
           * Add goal pacing args to projection
           */
          projection = {
            ...projection,
            pacing_goals: {
              __args: cleanArguments(goalPacingArgs),
              id: true,
              ...projection.pacing_goals,
            }
          };
        }

        return {
          body: {
            query: {
              report: {
                __args: cleanArguments({ id }),
                ...projection
              }
            }
          }
        };
      }
    }),
  }),
});
