/**
 * ScrollForm.Section
 */

import React, { FC, ReactElement, ReactNode, useEffect, useContext, memo, useRef } from 'react';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import ScrollFormContext from '../../Context';
import { ScrollFormBlockProps } from '../Block';
import css from './Section.module.scss';

export type Props = {
  label: string;
  id: string;
  progress?: [number, number]
  children: ReactNode | ReactElement<ScrollFormBlockProps> | Array<ReactElement<ScrollFormBlockProps>>;
};

const Section: FC<Props> = ({ children, id, label, progress }) => {
  const isRegistred = useRef(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const sentinelTopRef = useRef<HTMLDivElement>(null);
  const sentinelBottomRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const previousProgress = useRef(progress);
  const { registerSection, updateSection } = useContext(ScrollFormContext);

  // Register the section so we can show it in the sticky sidebar
  useEffect(() => {
    registerSection({
      id,
      label,
      progress,
      refs: {
        root: rootRef,
        header: headerRef,
        sticky_sentinel_top: sentinelTopRef,
        sticky_sentinel_bottom: sentinelBottomRef,
      }
    });

    isRegistred.current = true;
  }, []);

  // Report some changes back to ScrollForm
  useEffect(() => {
    if (isRegistred.current && !isEqual(previousProgress.current, progress)) {
      updateSection({
        id,
        progress
      });
      
      previousProgress.current = progress;
    }
  }, [progress]);

  return (
    <div  ref={rootRef}  className={css.root} id={id}>
      <div className={classnames(css.stickySentinel, css.stickySentinel__top)} ref={sentinelTopRef} />
      <h2 ref={headerRef} className={css.label}>{label}</h2>
      {children}
      <div className={classnames(css.stickySentinel, css.stickySentinel__bottom, 'js-sentinel-bottom')} ref={sentinelBottomRef} />
    </div>
  );
};

export default memo(Section);
