import { saveContactsMutation } from 'api/entityGraphQL';
import { isBlankRegex } from 'features/audits/utils';
import { QuestionHandler } from '../actions';

export const contactsHandler: QuestionHandler = (id, key, value, additionalInfo): any => {
  if (Array.isArray(value)) {
    // filter out any empty field sets the user may have accidentally added
    const validContacts = value?.filter((contact) => {
      if (contact === undefined) return false;
      if (
        contact.name === '' &&
        contact.email === '' &&
        contact.linkedin === '' &&
        contact.title === '' &&
        contact.notes === ''
      ) return false;

      const { id, ...rest } = contact;
      return !Object.values(rest).every(
        (el) => el === undefined || el === null || typeof el === 'string' && isBlankRegex(el)
      );
    });

    /** handle undefined/null values, convert to empty strings instead */
    validContacts?.forEach((contact) => {
      if (contact.notes === undefined) {
        contact.notes = null;
      }
      if (contact.email === undefined || contact.email === null) {
        contact.email = '';
      }
      if (contact.name === undefined) {
        contact.name = null;
      }
      if (contact.title === undefined) {
        contact.title = null;
      }
      if (contact.linkedin === undefined) {
        contact.linkedin = null;
      }
    });

    return saveContactsMutation({
      client_id: additionalInfo?.clientId as number,
      contacts: validContacts,
      delete_missing: 'yes',
    });
  }
};
