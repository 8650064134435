export const FIELD_NAMES = {
  name: 'name',
  department_ids: 'department_ids',
  author_id: 'author_id',
};

export const BLUEPRINT_STAGES_ARRAY = [
  'Draft',
  'Appraisal',
  'Blueprint',
  'Legal',
  'Won',
  'Nurture',
  'Lost'
];
