/**
 * SectionBuilder -> Fields -> ConsideredServicesField
 */

import React, { FC } from 'react';
import { Empty } from 'antd';
import { FormItem, TreeSelect } from 'components';
import { Department, Service, useDepartments } from '../../../../../../../entitiesRedux';
import { FieldProps } from '../../types';
import css from './ConsideredServicesField.module.scss';

const projection = {
  id: true,
  name: true,
  services: {
    id: true,
    name: true
  }
};

const ConsideredServicesField: FC<FieldProps> = ({ name, placeholder }) => {
  const { departments, loading } = useDepartments({ projection });
  const skeleton = loading && !departments?.length;

  const treeData = departments?.filter(department => department?.services?.length)
    .map((department: Partial<Department>, index) => {
      return {
        title: department?.name,
        selectable: false,
        key: `department-${department?.name}-${index}`,
        children: department?.services?.map((service: Partial<Service>) => {
          return {
            title: service?.name,
            value: service?.id,
            key: service?.id,
            searchTitle: service?.name,
          };
        })
      };
    });

  return (
    <FormItem name={name}>
      <TreeSelect
        showSearch
        // allowClear
        treeCheckable
        placeholder={placeholder}
        treeNodeFilterProp="searchTitle"
        filterTreeNode={(input, option) =>
          typeof option?.searchTitle === 'string' ? option?.searchTitle?.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
        }
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeData={treeData}
        className={css.root}
        dropdownClassName={css.consideredServicesDropdown}
        skeleton={skeleton}
        notFoundContent={
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description='No results found'
          />
        }
        virtual={false}
      />
    </FormItem>
  );
};

export default ConsideredServicesField;
