export const formatCurrencyInput = (value: number | string | undefined): string => {
  return `${value}`
    // only allow numbers or a decimal point
    .replace(/[^0-9.]/g, '')
    // don't allow more than one decimal point
    .replace(/(\..*)\./g, '$1')
    // don't allow more than 2 digits after decimal
    .replace(/(\.[\d]{2})./g, '$1')
    // add thousands separators
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};