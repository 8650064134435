import { getClient } from 'api/entityGraphQL/utils';
import { QuestionHandler } from '../actions';

export const salesforceOpportunityHandler: QuestionHandler = (id, key, value, additionalInfo) => {
  const apiClient = getClient();
  return apiClient.updateAudit({
    id: additionalInfo?.auditId,
    salesforce_opportunity_id: additionalInfo?.salesforceOpportunityId || '',
  }, {projection: additionalInfo?.auditProjection});
};
