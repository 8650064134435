import React, { useState, useMemo, useReducer, useCallback, useEffect, memo } from 'react';
import { ArrowLeftIcon, Box, Button, Dialog, DialogActions, Link, Select, Spinner, useSnackbar } from '@sprnova/nebula';
import { useLazyGetSalesforceOpportunitiesQuery } from 'api/crudGraphQL/salesforce_opportunities/getSalesforceOpportunities';
import { getBlueprintPath } from 'utils/blueprints/getBlueprintPath';
import { Opportunity } from 'features/entitiesRedux/models/opportunity';
import { SalesforceOpportunity } from 'features/entitiesRedux/models/salesforce';
import { salesforceOpportunityIsClosed } from 'features/strategies/utils/salesforceOpportunityIsClosed';
import { SelectSalesforceOpportunity } from 'features/strategies/ViewBlueprintPage/components/Overview/components/ContractDetails/components';
import { SalesforceValuesObjectType } from 'features/strategies/ViewBlueprintPage/components/Overview/components/ContractDetails/components/ContractDetailsEdit/ContractDetailsEdit';
import RemoveSalesforceOpportunityConfirmationModal from '../RemoveSalesforceOpportunityConfirmationModal';

interface SalesforceOpportunityLinkModalProps {
  alreadyLinkedOpportunity: Opportunity | undefined;
  open: boolean;
  toggleSalesforceOpportunityLinkModal: () => void;
  setSalesforceValuesObject: React.Dispatch<React.SetStateAction<SalesforceValuesObjectType>>;
  salesforceValuesObject: SalesforceValuesObjectType;
  salesforceClientId: string | null | undefined;
}

const SalesforceOpportunityLinkModal = (
  {
    alreadyLinkedOpportunity,
    open,
    toggleSalesforceOpportunityLinkModal,
    setSalesforceValuesObject,
    salesforceValuesObject,
    salesforceClientId,
  }: SalesforceOpportunityLinkModalProps): JSX.Element => {
  const [removeSalesforceOpportunityConfirmationModalIsOpen, toggleRemoveSalesforceOpportunityConfirmationModal] = useReducer((open) => !open, false);
  const [availableOpportunities, setAvailableOpportunities] = useState<SalesforceOpportunity[] | null>(null);
  const [fetchSalesforceOpportunities, { data: allSalesforceOpportunities, isFetching: isFetchingSalesforceOpportunities }] = useLazyGetSalesforceOpportunitiesQuery();
  const { addSnackbar } = useSnackbar();

  const filterOutLostOrWonOpportunities = useCallback(() => {
    if (allSalesforceOpportunities && allSalesforceOpportunities.length > 0) {
      setAvailableOpportunities(allSalesforceOpportunities.filter((opportunity: SalesforceOpportunity) => !salesforceOpportunityIsClosed(opportunity)));
    }
  }, [allSalesforceOpportunities]);

  /**
   * Fetch the salesforce opportunities that are not linked to any other BPs yet
   * @param salesforceAccountId - The salesforce account id
   * @returns void
   */
  const getSalesforceOpportunities = useCallback(async (salesforceAccountId: string) => {
    try {
      await fetchSalesforceOpportunities({
        account_id: salesforceAccountId,
        with_linked: false,
        projection: {
          account_id: true,
          id: true,
          name: true,
          stage: true,
          display_value: true,
        }
      }).unwrap();
      filterOutLostOrWonOpportunities();
    } catch (error) {
      const errorMessage = 'Error fetching salesforce opportunities.';
      addSnackbar({
        variant: 'error',
        message: errorMessage,
      });
      console.error(errorMessage, error);
    }
  }, [addSnackbar, fetchSalesforceOpportunities, filterOutLostOrWonOpportunities]);

  useEffect(() => {
    if (open && salesforceClientId) {
      getSalesforceOpportunities(salesforceClientId);
    }
  }, [salesforceClientId, getSalesforceOpportunities, open]);

  const handleOnClose = useCallback(() => {
    setSalesforceValuesObject((previousValues) => ({
      ...previousValues, salesforce_opportunity_id: null, salesforce_opportunity_name: null,
    }));
    toggleSalesforceOpportunityLinkModal();
  }, [setSalesforceValuesObject, toggleSalesforceOpportunityLinkModal]);

  const renderExplanationText = useMemo(() => (
    <Box sx={{ marginBottom: 2.6 }}>
      <Box sx={{ marginBottom: 1.6 }}>
        Option 1: <b>Remove the Salesforce Opportunity from that blueprint and use this Salesforce Opportunity for this Blueprint</b>
      </Box>
      <Box>
        Option 2: <b>Select a different Salesforce Opportunity to connect to this Blueprint</b>
      </Box>
    </Box>
  ), []);

  const renderSelectSalesforceAndButton = useMemo(() => {
    return (
      <>
        <Box sx={{ display: 'flex', gap: '24px' }}>
          <Select
            id="id-salesforce-opportunity"
            defaultValue={alreadyLinkedOpportunity?.salesforce_id}
            disabled={true}
            label='Salesforce Opportunity'
            sx={{ flex: 0.4 }}
          >
            <Select.Item value={alreadyLinkedOpportunity?.salesforce_id}>{alreadyLinkedOpportunity?.salesforce_name}</Select.Item>
          </Select>
          <Button
            disabled={salesforceValuesObject.salesforce_opportunity_id}
            onClick={(): void => toggleRemoveSalesforceOpportunityConfirmationModal()}
            size="large"
            sx={{ flex: 0.6 }}
            variant="secondary"
          >
            Remove from other Blueprint and replace
          </Button>
        </Box>
      </>
    );
  }, [alreadyLinkedOpportunity?.salesforce_id, alreadyLinkedOpportunity?.salesforce_name, salesforceValuesObject.salesforce_opportunity_id, setSalesforceValuesObject]);

  const renderOrText = useMemo(() => {
    if (availableOpportunities && availableOpportunities.length === 0) {
      return <></>;
    }
    return <div style={{ marginTop: '24px', marginBottom: '24px', fontWeight: 600 }}>OR</div>;
  }, [availableOpportunities]);

  const renderSelectSalesforce = useMemo(() => {
    if (availableOpportunities && availableOpportunities.length === 0) {
      return <></>;
    }
    return (<SelectSalesforceOpportunity
      opportunities={availableOpportunities}
      autoCompleteProps={{
        helperText: 'Choose different Salesforce Opportunity to use for this Blueprint'
      }}
      withLinkedOpportunity={false}
      setSalesforceValuesObject={setSalesforceValuesObject}
      salesforceValuesObject={salesforceValuesObject}
      salesforceClientId={salesforceClientId}
    />);
  }, [availableOpportunities, salesforceClientId, salesforceValuesObject, setSalesforceValuesObject]);

  const renderDialogContent = useMemo(() => {
    return (
      <>
        {renderExplanationText}
        {renderSelectSalesforceAndButton}
        {renderOrText}
        {renderSelectSalesforce}
        {alreadyLinkedOpportunity?.strategy && (
          <RemoveSalesforceOpportunityConfirmationModal
            alreadyLinkedOpportunity={alreadyLinkedOpportunity}
            extraToggle={toggleSalesforceOpportunityLinkModal}
            open={removeSalesforceOpportunityConfirmationModalIsOpen}
            toggle={toggleRemoveSalesforceOpportunityConfirmationModal}
            setSalesforceValuesObject={setSalesforceValuesObject}
          />
        )}
      </>
    );
  }, [alreadyLinkedOpportunity?.strategy, availableOpportunities, removeSalesforceOpportunityConfirmationModalIsOpen, renderExplanationText, renderOrText, renderSelectSalesforce, renderSelectSalesforceAndButton, setSalesforceValuesObject, toggleSalesforceOpportunityLinkModal]);

  const dialogDescription = useMemo(() => {
    const linkToAlreadyLinkedBlueprint = alreadyLinkedOpportunity?.strategy ? <Link href={getBlueprintPath(alreadyLinkedOpportunity?.strategy)} target='_blank' rel="noreferrer noopener">{alreadyLinkedOpportunity.strategy?.name}</Link> : alreadyLinkedOpportunity?.strategy?.name;
    return <>This Salesforce Opportunity is used in {linkToAlreadyLinkedBlueprint} Blueprint.</>;
  }, [alreadyLinkedOpportunity?.strategy]);

  return (
    <Dialog
      maxWidth={1106}
      open={open}
      onClose={(): void => handleOnClose()}
      title='Salesforce Opportunity'
      description={dialogDescription}
      footer={
        <DialogActions
          secondaryButtonProps={{ onClick: () => handleOnClose(), children: 'Cancel', variant: 'text' }}
          primaryButtonProps={{
            children: 'Confirm',
            hidden: availableOpportunities && availableOpportunities.length === 0 ? true : false,
            disabled: isFetchingSalesforceOpportunities,
            onClick: () => toggleSalesforceOpportunityLinkModal(),
          }}
          tertiaryButtonProps={{
            children: 'Back',
            hidden: !salesforceValuesObject.salesforce_opportunity_id,
            onClick: () => setSalesforceValuesObject(previousValue => ({ ...previousValue, salesforce_opportunity_id: null, salesforce_opportunity_name: null })),
            startIcon: <ArrowLeftIcon />,
          }}
        />
      }
    >
      {isFetchingSalesforceOpportunities ? <Spinner size='small' /> : renderDialogContent}
    </Dialog>
  );
};

export default memo(SalesforceOpportunityLinkModal);
