import React, { memo, useCallback, useMemo, useState } from 'react';
import { InfoIcon, Positioner, Typography, theme } from '@sprnova/nebula';

type ColumnInfoType = {
  info?: string;
};

const ColumnInfoPopper = ({ info }: ColumnInfoType) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const id = useMemo(() => (open ? 'info-popover' : undefined), [open]);

  const { colors, typography } = {
    colors: theme.variables.colors,
    typography: theme.typography,
  };

  return (
    <Positioner
      placement="bottom-start"
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchor={(): JSX.Element =>
        <div
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          style={{
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            marginLeft: '12px',
            color: colors.primary.charcoal[400]
          }}
        >
          <InfoIcon width='20px' height='20px' />
        </div>
      }
      sx={{
        zIndex: 90,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        borderRadius: '3px',
        padding: '8px 16px'
      }}
    >
      <Typography variant="subtitle2" component="div"
        style={{
          fontWeight: 400,
          lineHeight: '22px',
          maxWidth: '720px',
        }}
      >
        {info ? info : 'Creative Affinity column head info popper content.'}
      </Typography>
    </Positioner>
  );
};

export default memo(ColumnInfoPopper);
