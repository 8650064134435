import { LookerReportTypeEnum } from 'features/clients/constants';
import { LookerCategory, LookerStandardReport } from 'features/entitiesRedux';
import { FavoritedReport, LookerStandardReportWithReportType } from './LookerReportSelect';

export type LookerCategoryWithCombinedReports = LookerCategory & {
  reports: ({ type: LookerReportTypeEnum } & LookerStandardReport)[];
};

/**
 * Checks if a Looker report is favorited by the user.
 *
 * @param report - The Looker report.
 * @param favoritedReports - The user's favorited Looker reports.
 * @returns True if the user favorited the given Looker report; false otherwise.
 */
export const isFavorited = (report: LookerStandardReportWithReportType, favoritedReports: FavoritedReport[] = []): boolean => {
  return favoritedReports.find((favoritedReport) =>
    report.id === favoritedReport.id
    && report.type === favoritedReport.type) !== undefined;
};

/**
 * For each category in the provided categories array, combines all reports (Standard, Base, Custom)
 * into a single array, which can then be accessed via the `reports` property. Additionally, for each
 * report, a `type` property is added that indicates the type of report.
 *
 * @param categories The categories.
 * @returns An array of categoies with an additional property, `reports`, which contains all reports
 * (Standard, Base, Custom) for that category.
 */
const combineReports = (categories: LookerCategory[]): LookerCategoryWithCombinedReports[] => {
  const result = categories.map((category) => {
    const reports = [
      ...category?.looker_base_reports?.map((report => ({ ...report, type: LookerReportTypeEnum.Base }))) ?? [],
      ...category?.looker_standard_reports?.map((report => ({ ...report, type: LookerReportTypeEnum.Standard }))) ?? [],
      ...category?.looker_custom_reports?.map((report => ({ ...report, type: LookerReportTypeEnum.Custom }))) ?? [],
    ];

    return {
      ...category,
      reports
    };
  });

  return result;
};

/**
 * For each category in the provided categories array, combines all reports (Standard, Base, Custom)
 * into a single array, which can then be accessed via the `reports` property. Additionally, reports
 * and categories can be filtered via the provided filter arguments. If a category has zero reports,
 * then it will be excluded from the result.
 *
 * @param categories The categories.
 * @param filterReport The optional report filter.
 * @param filterCategory The optional category filter.
 * @returns An array of filtered categories, each of which has at least one report, and all reports
 * are combined into a `reports` property.
 */
export const getCategoriesWithCombinedReports = (
  categories: LookerCategory[] = [],
  filterReport?: (report: LookerStandardReport) => boolean,
  filterCategory?: (category: LookerCategory) => boolean
): LookerCategoryWithCombinedReports[] => {

  let result = combineReports(categories);

  // Apply optional category filter.
  if (filterCategory) {
    result = result.filter((category) => filterCategory(category));
  }

  // Apply optional report filter.
  if (filterReport) {
    result = result.map((category) => {
      category.reports = category.reports.filter(filterReport);
      return category;
    });
  }

  // Remove categories with zero reports.
  result = result.filter((category) => category.reports.length > 0);

  return result;
};
