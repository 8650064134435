import React from 'react';
import { SkeletonInput } from '../../../../../../../../components';

interface Props {
  adjustedPercent?: number;
  isLoading?: boolean;
}

const AdjustedCommission = ({ adjustedPercent, isLoading }: Props): JSX.Element => {
  if (isLoading) return <SkeletonInput />;
  
  return (
    <>
      <span style={{paddingLeft: 8}}>
        {adjustedPercent
          ? `${adjustedPercent}%`
          : 'N/A'
        }
      </span>
    </>
  );
};

export default AdjustedCommission;