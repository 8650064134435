import React, { FC, memo } from 'react';
import { IconButton, Tag, TimesIcon, Typography, UnlockIcon } from '@sprnova/nebula';
import css from './NovaGPTDialogHeader.module.scss';

type NovaGPTDialogHeaderProps = {
  onToggle: () => void;
}

const NovaGPTDialogHeader: FC<NovaGPTDialogHeaderProps> = ({ onToggle }) => {
  return (
    <div className={css.root}>
      <div className={css.start}>
        <Typography variant="h2">Ask novaGPT</Typography>
      </div>
      <div className={css.end}>
        <Tag className={css.tag} color="grey">
          <UnlockIcon sx={{ width: '16px', height: '16px' }} />
          <Typography variant="h6">UNLOCK WITH NOVA INSIGHTS AI</Typography>
        </Tag>
        <IconButton
          variant="plain"
          size="lg"
          onClick={onToggle}
        >
          <TimesIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default memo(NovaGPTDialogHeader);
