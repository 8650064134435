import React from 'react';
import { PageHero } from 'layouts/components';
import CreateScoreboardForm from './CreateScoreboardPage/CreateScoreboardForm/CreateScoreboardForm';

/**
 * Renders the page for creating a new scoreboard, including a header and form.
 *
 * @returns {JSX.Element} The rendered component
 */
const EditScoreboardPage = (): JSX.Element => {

  return (
    <>
      {/* Page header */}
      <PageHero title='Edit Scoreboard' />
      {/* Create new Scoreboard form */}
      <CreateScoreboardForm isEdit />
    </>
  );
};

export default EditScoreboardPage;
