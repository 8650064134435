import cloneDeep from 'lodash/cloneDeep';
import { NovaAIResponseType } from 'features/entitiesRedux/models/nova_ai';

const defaults = {
  response: 'There was no data found that matches your question. Please try a different question.',
};

/**
 * Transforms a NovaAIResponseType.
 *
 * Performs the following transformations:
 *   - If the `response` property exists and has a non-empty string value, it is trimmed.
 *     Otherwise, the `response` property is set to a default value.
 *
 * @param response The original response.
 * @returns A transformed, deep copy of the response.
 */
const transformResponse = (response: NovaAIResponseType): NovaAIResponseType => {
  const responseCopy = cloneDeep(response);
  responseCopy.response = response?.response && response.response.trim().length > 0
    ? response.response.trim()
    : defaults.response;
  responseCopy.json_response = response?.json_response
    ? response.json_response
    : undefined;

  return responseCopy;
};

export default transformResponse;
