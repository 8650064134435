/**
 * Util function to add months to a date
 * @param date Initial date
 * @param months Number of months to be added to the initial date
 * @returns Date
 */
export const addMonthsToDate = (date: Date, months: number): Date => {
  date.setUTCMonth(date.getUTCMonth() + months);
  return date;
};

/**
 * Util function to subtract months to a date
 * @param date Initial date
 * @param months Number of months to be subtracted to the initial date
 * @returns Date
 */
export const subtractMonthsToDate = (date: Date, months: number): Date => {
  date.setUTCMonth(date.getUTCMonth() - months);
  return date;
};

/**
 * Util function to add days to a date
 * @param date Initial date
 * @param days Number of days to be added to the initial date
 * @returns Date
 */
export const addDaysToDate = (date: Date, days: number): Date => {
  date.setUTCDate(date.getUTCDate() + days);
  return date;
};

/**
 * Util function to subtract days to a date
 * @param date Initial date
 * @param days Number of days to be subtracted to the initial date
 * @returns Date
 */
export const subtractDaysToDate = (date: Date, days: number): Date => {
  date.setUTCDate(date.getUTCDate() - days);
  return date;
};
