/**
 * Get job titles
 */

import { baseApi, cleanArguments, paginationProjection } from 'api/crudGraphQL';
import { Pagination, Projection } from 'api/entityGraphQL/types';
import { JobTitle } from 'features/entitiesRedux/models/types';
import tagTypes from './tagTypes';

export type GetJobTitlesQuery = {
  id?: number;
  name?: string;
  limit?: number;
  page?: number;
  sort?: string[];
  projection: Projection;
}

export type GetJobTitlesResult = Pagination & {
  data: JobTitle[]
};

export const GET_JOB_TITLES_ENDPOINT_KEY = 'getJobTitles';

export const { useGetJobTitlesQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_JOB_TITLES_ENDPOINT_KEY]: build.query<GetJobTitlesResult, GetJobTitlesQuery>({
      transformResponse: (response: { job_titles: GetJobTitlesResult }) => response.job_titles,
      providesTags: (result) => {
        const { data } = result ?? { data: [] };

        return [
          tagTypes.JOB_TITLE_LIST,
          ...data.map(jobTitle => ({
            id: jobTitle.id,
            type: tagTypes.JOB_TITLE
          }))
        ];
      },
      query: ({
        projection = { id: true, name: true },
        page = 1,
        limit = 10,
        sort,
        name,
        ...args
      }) => {
        const __args: Partial<GetJobTitlesQuery> = {
          page,
          limit,
          sort,
          name,
          ...args
        };

        if (name) {
          __args.name = `*${name}*`;
        }

        return {
          body: {
            query: {
              job_titles: {
                __args: cleanArguments(__args),
                data: projection,
                ...paginationProjection
              },
            }
          }
        };
      }
    })
  })
});
