import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Drawer, DrawerView, DrawerFooter, Grid, Autocomplete, Typography, Box, Controller } from '@sprnova/nebula';
import { useGetBusinessTypesQuery } from 'api/crudGraphQL/business_types/getBusinessTypes';
import { GetPlaybookAdsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookAds';
import { GetPlaybookAdsetsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookAdsets';
import { GetPlaybookCampaignsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookCampaigns';
import { useFormWithQueryParams } from 'components/nebula/Form/useFormWithQueryParams';
import { capitalize, identity, pickBy } from 'lodash';
import { BooleanParam, JsonParam, NumberParam, useQueryParams } from 'use-query-params';
import { CustomFilter } from './CustomFilter';
import { CustomFilterItemType, MetricsFilterType, OutputDataItem } from './types';
import { useFilterContext } from '../Filters/Filters';
import MetricsFilter from '../MetricsFilter/MetricsFilter';

interface Props {
  open: boolean;
  toggle: () => void;
  trackFilterApplied: (filter: Partial<GetPlaybookCampaignsQuery | GetPlaybookAdsetsQuery | GetPlaybookAdsQuery>) => void;
  activeTab?: string;
}
type AutocompleteType = { id: number, label: string };
type DrawerFiltersType = {
  status?: AutocompleteType | string;
  asset_format_types?: AutocompleteType[];
  business_type_ids?: AutocompleteType[];
  funnel_ids?: AutocompleteType[];
  general_metric_filters?: OutputDataItem[];
  campaign_custom_filters?: CustomFilterItemType[];
  adset_custom_filters?: CustomFilterItemType[];
  ad_custom_filters?: CustomFilterItemType[];
  match_name_in_all_models?: number;
  format?: string;
};
const FiltersDrawer = ({ open, toggle, trackFilterApplied, activeTab }: Props): JSX.Element => {
  const [drawerFilters, setDrawerFilters] = useState<DrawerFiltersType>({});
  const [needsToUpdate, setNeedsToUpdate] = useState<boolean>(true);
  const [metricFilters, setMetricFilters] = useState<Record<string, MetricsFilterType[]>>({ general_metric_filters: [] });
  const [customFilters, setCustomFilters] = useState<Partial<Record<string, CustomFilterItemType>>>({});
  const { filter, setFilter } = useFilterContext();

  const [queryParams] = useQueryParams({
    limit: NumberParam,
    exclude_without_ltv: BooleanParam,
  });

  const { control, getValues, watch } = useFormWithQueryParams({
    defaultValues: {
      status: undefined,
      asset_format_types: undefined,
      business_type_ids: undefined,
      funnel_ids: undefined,
    },
    queryParamsConfig: {
      status: JsonParam,
      asset_format_types: JsonParam,
      business_type_ids: JsonParam,
      funnel_ids: JsonParam,
    }
  });

  const { data: businessTypeRequest, } = useGetBusinessTypesQuery({
    projection: { id: true, name: true },
  });

  const businessTypes = useMemo(() => {
    if (businessTypeRequest) {
      return businessTypeRequest.map(option => ({ id: option.id, label: option.name, value: option.id }));
    }
    return [];
  }, [businessTypeRequest]);

  const handleUpdate = useCallback((values: Record<string, DrawerFiltersType>) => {
    const entries = Object.entries(values) ?? [];
    const formattedValues: DrawerFiltersType = {};
    entries.map(([key, value]) => {
      if (
        Array.isArray(value) &&
        key === 'business_type_ids' ||
        key === 'asset_format_types' ||
        key === 'funnel_ids'
      ) {
        if (Array.isArray(value)) {
          formattedValues[key] = value.map((item) => item.id);
        }
      }
      if (key === 'status') formattedValues[key] = (value as AutocompleteType)?.id?.toString();
    });

    if (needsToUpdate) {
      /**
       * This function will run on mount, which will trigger a filter update.
       * We want this to happen only once to account for the values coming from
       * the query params.
       */
      setDrawerFilters(pickBy(formattedValues, identity));
      setNeedsToUpdate(false);
    } else {
      setDrawerFilters({ ...drawerFilters, ...formattedValues });
    }
  }, [drawerFilters, needsToUpdate]);

  useEffect(() => {
    const subscription = watch(() => {
      handleUpdate(getValues());
    });
    return (): void => subscription.unsubscribe();
  }, [getValues, handleUpdate, watch]);

  useEffect(() => {
    if (needsToUpdate) {
      handleUpdate(getValues());
    }
  }, [getValues, handleUpdate, needsToUpdate]);

  const handleApply = useCallback(() => {
    /**
     * Runs on "Apply" button click
    */
    // pop off custom filters
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      general_metric_filters, campaign_custom_filters, adset_custom_filters, ad_custom_filters, match_name_in_all_models,
      ...rest
    } = drawerFilters;
    const combinedFilters = {
      ...filter,
      ...rest,
      ...drawerFilters,
      ...customFilters,
      page: 1,
      limit: queryParams.limit || 10,
    };
    setFilter?.({ type: 'add', value: { ...rest, ...drawerFilters, ...customFilters, ...metricFilters } });
    trackFilterApplied(combinedFilters);
    toggle();
  }, [drawerFilters, filter, customFilters, queryParams.limit, setFilter, metricFilters, trackFilterApplied, toggle]);

  const handleCustomFilterSubmit = useCallback((values: Record<string, CustomFilterItemType>) => {
    const { campaign_custom_filters, adset_custom_filters, ad_custom_filters } = values ?? {};
    /**
     * Pass undefined filters to remove them from the query
     */
    setCustomFilters({ campaign_custom_filters, adset_custom_filters, ad_custom_filters, ...values });
  }, []);

  const handleMetricFilterSubmit = useCallback((values: Record<string, MetricsFilterType[]>) => {
    const { general_metric_filters } = values ?? {};
    /**
     * Pass undefined filters to remove them from the query
     */
    setMetricFilters({ general_metric_filters });
  }, []);

  const renderForm = useMemo(() => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name='status'
            render={({ field: { onChange, ...field } }) => (
              <Autocomplete
                id={field.name}
                {...field}
                label={`${capitalize(activeTab)} Status`}
                options={[
                  { id: 'active', label: 'Active' },
                  { id: 'inactive', label: 'Inactive' },
                  { id: 'pending', label: 'Pending' },
                ]}
                onChange={(event, newValue) => onChange(newValue)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name='asset_format_types'
            render={({ field: { onChange, ...field } }) => (
              <Autocomplete
                {...field}
                id={field.name}
                label='Ad Format'
                disableCloseOnSelect
                multiple
                options={[
                  { id: 'image', label: 'Image' },
                  { id: 'video', label: 'Video' },
                  { id: 'carousel', label: 'Carousel' },
                ]}
                onChange={(event, newValue) => onChange(newValue)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name='business_type_ids'
            render={({ field: { onChange, ...field } }) => (
              <Autocomplete
                {...field}
                id={field.name}
                label='Business Unit'
                disableCloseOnSelect
                multiple
                options={businessTypes}
                onChange={(event, newValue) => onChange(newValue)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name='funnel_ids'
            render={({ field: { onChange, ...field } }) => (
              <Autocomplete
                {...field}
                id={field.name}
                label='Funnel Stage'
                disableCloseOnSelect
                multiple
                options={[
                  { id: 1001, label: 'Top' },
                  { id: 1002, label: 'Middle' },
                  { id: 1003, label: 'Bottom' }
                ]}
                onChange={(event, newValue) => onChange(newValue)}
              />
            )}
          />
        </Grid>
      </Grid>
    );
  }, [activeTab, businessTypes, control]);

  return (
    <Drawer
      open={open}
      onClose={toggle}
      keepMounted // to load filters on mount
    >
      <DrawerView
        title='Filters'
        onClose={toggle}
        footer={
          <DrawerFooter
            primaryButtonProps={{ children: 'Apply', onClick: handleApply }}
            secondaryButtonProps={{ children: 'Cancel', onClick: toggle }}
          />
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='h3' marginBottom='20px' component='div'>
              General Filter
            </Typography>
            {renderForm}
            <MetricsFilter
              onSubmit={handleMetricFilterSubmit}
            />
            <Box sx={{ mb: 2 }}>
              <CustomFilter
                activeTab={activeTab}
                onSubmit={handleCustomFilterSubmit}
              />
            </Box>
          </Grid>
        </Grid>
      </DrawerView>
    </Drawer>
  );
};

export default FiltersDrawer;
