import React, { useState, useEffect, useRef, RefObject, useCallback, CSSProperties }  from 'react';
import { LoadableComponent } from '@loadable/component';
import { Grid, Tab, styled } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import { scrollToRef } from 'features/audits/utils';

export type Tab = {
  title: string;
  id: string;
  disabled?: boolean;
  ref?: RefObject<HTMLDivElement> | string;
}

export interface SidebarProps {
  type?: 'default' | 'filter' | 'navigation';
  title?: JSX.Element | string;
  tabs?: Tab[];
  extra?: JSX.Element;
  sticky?: boolean;
  offsetTop?: number;
  offsetBottom?: number;
  style?: CSSProperties;
}


export const Sidebar = ({
  type = 'default',
  title,
  tabs,
  extra,
  offsetTop = 100,
  offsetBottom = 42,
  sticky = false,
  style
}: SidebarProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  const tabsRef = useRef<any>(null);
  const createClassName = (title?: string): string => title?.replace(/\s+/g, '-').toLowerCase() || '';

  const changeSelectedTab = useCallback(() => {
    if (tabs?.length) {
      const activeTab = tabs.findIndex(tab => {
        if (typeof tab?.ref !== 'string') {
          const { top, bottom } = tab?.ref?.current?.getBoundingClientRect() ?? {};
          if (top && bottom) {
            return top >= offsetTop || bottom >= offsetBottom;
          }
        }
      });
      (activeTab > -1) && setSelectedTab(activeTab);
    }
  }, [tabs, offsetTop, offsetBottom]);

  // Commented out because the last Widget(s) not highlighted when scrolling to the bottom.
  // useEffect(()=> {
  //   window.addEventListener('scroll', changeSelectedTab);
  //   return () => window.removeEventListener('scroll', changeSelectedTab);
  // }, [tabs]);

  const StyledGrid = styled(Grid)(() => ({
    '& .MuiGrid-root': {
      position: sticky ? 'sticky' : 'initial',
      top: sticky ? 0 : 'initial',
      zIndex: sticky ? '99' : 'initial',
      background: '#F4F4F4',
      padding: 0,
    }
  }));

  const StyledTabs = styled(Tabs)(() => ({
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-root': {
      color: '#4A1FFF',
      fontSize: '14px',
      fontFamily: 'Inter',
      fontWeight: '600',
      lineHeight: '17px',
      textTransform: 'capitalize',
      alignItems: 'flex-start',
      padding: '0 8px',
      '&:hover': {
        background: '#F4F4F4',
        boxShadow: 'none',
        borderRadius: '4px',
      },
      '&:active': {
        background: '#F4F4F4',
      },
      '&.Mui-selected': {
        color: '#4124BE',
        background: 'none',
        boxShadow: 'none',
      },
      '&.Mui-disabled': {
      }
    },
  }));

  switch(type) {
    case 'default':
      return (
        <div>
          <div>{title}</div>
        </div>
      );
    case 'filter':
      return (
        <div>
          <div>{title}</div>
        </div>
      );
    case 'navigation':
      return (
        <StyledGrid container direction='column'>
          {tabs &&
          <StyledTabs
            value={selectedTab}
            onChange={handleChange}
            aria-label={String(title)}
            ref={tabsRef}
            variant='scrollable'
            scrollButtons='auto'
            orientation="vertical"
            style={{ position: 'sticky', top: 0, zIndex: 1 }}
          >
            {tabs?.map(({title, id, disabled, ref}, index) => {
              return (
                <Tab
                  key={`anchor-tab-${index}`}
                  label={title}
                  href={`#${id}`}
                  disabled={disabled}
                  onClick={(e) => {
                    e.preventDefault();
                    if (ref && typeof ref !== 'string') scrollToRef(ref, 0);
                  }}
                  sx={{ borderRadius: '4px' }}
                />
              );
            })}
          </StyledTabs>
          }
          {
            extra &&
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {extra}
          </div>
          }
        </StyledGrid>
      );
  }
};
