/**
 * ScrollForm.Block
 */

import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import css from './Block.module.scss';

export type Props = {
  label?: string;
  subtext?: string;
  groupName?: string;
  children?: ReactNode;
};

const Block: FC<Props> = ({ label, subtext, groupName, children }) => {
  return (
    <div className={
      classNames(css.root, {
        [css.question_aquire_customers]: groupName === 'question_acquire_customers',
        [css.question_acquire_leads]: groupName === 'question_acquire_leads',
        [css.services]: groupName === 'services'
      })}
    >
      {groupName === 'services' ? <></> : <h3 className={css.label}>{label}</h3>}
      {subtext ? <p className={css.subtext}>{subtext}</p> : <></>}
      {children}
    </div>
  );
};

export default Block;
