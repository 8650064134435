/**
 * useTiers
 * Fetches all tiers
 */

import { useState, useEffect } from 'react';
import { novaGraphQLClient } from 'api/entityGraphQL';
import { Tier } from 'features/entitiesRedux';

type Type = {
  loading: boolean;
  error: string;
  tiers: Tier[];
}

export const useTiers: () => Type = () => {
  const [tiers, setTiers] = useState<Tier[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      setLoading(true);
      try {
        const { tiers } = await novaGraphQLClient.fetchTiers({});
        if (!isCancelled) {
          setLoading(false);
          setTiers(tiers);
          setError('');
        }
      } catch(error) {
        if (!isCancelled) {
          setLoading(false);
          setError(error?.response?.errors?.[0]?.message || 'Unknown error');
        }
      }
    })();

    return () => {
      isCancelled = true;
    };

  }, []);

  return { tiers, loading, error };
};

export default useTiers;
