export default {
  SCOREBOARDS: 'SCOREBOARDS',
  SCOREBOARD: 'SCOREBOARD',
  DATA_SOURCES: 'DATA_SOURCES',
  DATA_SOURCE: 'DATA_SOURCE',
  SELECTOR_INDICATORS: 'SELECTOR_INDICATORS',
  SELECTOR_INDICATOR: 'SELECTOR_INDICATOR',
  INDICATOR_EARLY_DATE: 'INDICATOR_EARLY_DATE',
  WIDGETS: 'WIDGETS',
  SCOREBOARD_INTEGRATION: 'SCOREBOARD_INTEGRATION',
};
