/**
 * Export tag types for each API service
 */

import acquisitionTagTypes from './acquisitions/tagTypes';
import amDashboardNotesTagTypes from './am_dashboards/notes/tagTypes';
import amDashboardsTagTypes from './am_dashboards/tagTypes';
import analystSurveysTagTypes from './analyst_surveys/tagTypes';
import auditTagTypes from './audits/tagTypes';
import businessEntityTagTypes from './business_entities/tagTypes';
import businessTypesTagTypes from './business_types/tagTypes';
import facebookPagesTagTypes from './classic_integrations/facebook_ads/tagTypes';
import clientAccountStagesTagTypes from './client_account_stages/tagTypes';
import clientAccountStatusUpdateSectionTagTypes from './client_account_status_update_section/tagTypes';
import clientAccountStatusesTagTypes from './client_account_statuses/tagTypes';
import clientDocumentSectionsTagTypes from './client_document_sections/tagTypes';
import clientIntegrationRequestTagTypes from './client_integration_request/tagTypes';
import clientSentimentTagTypes from './client_sentiments/tagTypes';
import clientUserDepartmentsTagTypes from './client_user_departments/tagTypes';
import clientsTagTypes from './clients/tagTypes';
import creativePlaybookTagTypes from './creative_playbook/tagTypes';
import customerInsightsTagTypes from './customer_insights/tagTypes';
import departmentsTagTypes from './departments/tagTypes';
import discoveriesTagTypes from './discoveries/tagTypes';
import featureFlagsTagTypes from './feature_flags/tagTypes';
import fivetranConnectorCardTagTypes from './fivetran/tagTypes';
import forecastsTagTypes from './forecasts/tagTypes';
import funnelConnectorTagTypes from './funnel/tagTypes';
import horizonsTagTypes from './horizons/tagTypes';
import categoriesTagTypes from './indicators/tagTypes';
import industriesTagTypes from './industries/tagTypes';
import caCohortAnalysis from './intelligence/cohort_analysis/tagTypes';
import cohortAnalysisDateRanges from './intelligence/cohorts/date_ranges/tagTypes';
import playbookPlatformCalculatedMetricsTagTypes from './intelligence/creative_affinity/tagTypes';
import customerLifecycleAnalysisTagTypes from './intelligence/customer_life_cycle_analysis/tagTypes';
import horizonRequirementTagTypes from './intelligence/forecast/tagTypes';
import intelligenceTagTypes from './intelligence/products/tagTypes';
import cacThresholdTagTypes from './intelligence/summary/cac_threshold/tagTypes';
import ltvTagTypes from './intelligence/summary/ltv/tagTypes';
import jobTitlesTagTypes from './job_titles/tagTypes';
import leadSourcesTagTypes from './lead_sources/tagTypes';
import dashboardChurnsTagTypes from './library/dashboard_churns/tagTypes';
import dashboardPacesTagTypes from './library/dashboard_paces/tagTypes';
import dashboardRapportTagTypes from './library/dashboard_rapports/tagTypes';
import lookerTagTypes from './looker/tagTypes';
import maxRevenueCalculationVersionTagTypes from './max_revenue_calculation_version/tagTypes';
import meTagTypes from './me/tagTypes';
import notificationsTagTypes from './notifications/tagTypes';
import novaAITagTypes from './nova_ai/tagTypes';
import googleAnalyticsTagTypes from './nova_cloud_integrations/google_analytics/tagTypes';
import googleAnalytics4TagTypes from './nova_cloud_integrations/google_analytics_4/tagTypes';
import googleSearchConsoleTagTypes from './nova_cloud_integrations/google_search_console/tagTypes';
import organizationsTagTypes from './organizations/tagTypes';
import partnersTagTypes from './partners/tagTypes';
import pillarsTagTypes from './pillars/tagTypes';
import retoolAppsTagTypes from './retool/retool_apps/tagTypes';
import retoolAttributesTagTypes from './retool/retool_attributes/tagTypes';
import salesforceOpportunitiesTagTypes from './salesforce_opportunities/tagTypes';
import sectionsTagTypes from './scoreboards/statuses/sections/tagTypes';
import scoreboardsTagTypes from './scoreboards/tagTypes';
import servicesTagTypes from './services/tagTypes';
import strategyCostsTagTypes from './strategies/additional/cost/tagTypes';
import strategyCostMonthsTagTypes from './strategies/additional/cost_month/tagTypes';
import strategyInformationTagTypes from './strategies/additional/information/tagTypes';
import strategyMonthsTagTypes from './strategies/additional/month/tagTypes';
import strategyTaskTagTypes from './strategies/additional/task/tagTypes';
import strategyTasksMonthTagTypes from './strategies/additional/task_month/tagTypes';
import strategyTasksTagTypes from './strategies/additional/tasks/tagTypes';
import strategiesTagTypes from './strategies/tagTypes';
import talentSurveysTagTypes from './surveys/tagTypes';
import developmentStatusesTagTypes from './talent_report/development_statuses/tagTypes';
import usersReportsTagTypes from './talent_report/tagTypes';
import tasksTagTypes from './tasks/tagTypes';
import tiersTagTypes from './tiers/tagTypes';
import timezonesTagTypes from './timezones/tagTypes';
import usersTagTypes from './users/tagTypes';
import vaboTypesTagTypes from './vabo_types/tagTypes';

export const tagTypes = {
  ...acquisitionTagTypes,
  ...amDashboardNotesTagTypes,
  ...amDashboardsTagTypes,
  ...analystSurveysTagTypes,
  ...businessEntityTagTypes,
  ...businessTypesTagTypes,
  ...clientsTagTypes,
  ...clientUserDepartmentsTagTypes,
  ...creativePlaybookTagTypes,
  ...dashboardChurnsTagTypes,
  ...dashboardPacesTagTypes,
  ...dashboardRapportTagTypes,
  ...departmentsTagTypes,
  ...developmentStatusesTagTypes,
  ...horizonsTagTypes,
  ...salesforceOpportunitiesTagTypes,
  ...forecastsTagTypes,
  ...jobTitlesTagTypes,
  ...servicesTagTypes,
  ...talentSurveysTagTypes,
  ...usersTagTypes,
  ...usersReportsTagTypes,
  ...intelligenceTagTypes,
  ...ltvTagTypes,
  ...cacThresholdTagTypes,
  ...notificationsTagTypes,
  ...customerLifecycleAnalysisTagTypes,
  ...pillarsTagTypes,
  ...leadSourcesTagTypes,
  ...partnersTagTypes,
  ...strategiesTagTypes,
  ...timezonesTagTypes,
  ...caCohortAnalysis,
  ...retoolAppsTagTypes,
  ...retoolAttributesTagTypes,
  ...strategyMonthsTagTypes,
  ...strategyCostsTagTypes,
  ...strategyCostMonthsTagTypes,
  ...strategyTaskTagTypes,
  ...strategyTasksTagTypes,
  ...strategyTasksMonthTagTypes,
  ...strategyInformationTagTypes,
  ...tasksTagTypes,
  ...maxRevenueCalculationVersionTagTypes,
  ...cohortAnalysisDateRanges,
  ...customerInsightsTagTypes,
  ...horizonRequirementTagTypes,
  ...fivetranConnectorCardTagTypes,
  ...clientIntegrationRequestTagTypes,
  ...scoreboardsTagTypes,
  ...meTagTypes,
  ...tiersTagTypes,
  ...industriesTagTypes,
  ...clientAccountStatusesTagTypes,
  ...clientAccountStatusUpdateSectionTagTypes,
  ...clientAccountStagesTagTypes,
  ...organizationsTagTypes,
  ...lookerTagTypes,
  ...auditTagTypes,
  ...discoveriesTagTypes,
  ...facebookPagesTagTypes,
  ...clientDocumentSectionsTagTypes,
  ...googleAnalytics4TagTypes,
  ...googleAnalyticsTagTypes,
  ...googleSearchConsoleTagTypes,
  ...featureFlagsTagTypes,
  ...vaboTypesTagTypes,
  ...clientSentimentTagTypes,
  ...sectionsTagTypes,
  ...categoriesTagTypes,
  ...novaAITagTypes,
  ...playbookPlatformCalculatedMetricsTagTypes,
  ...funnelConnectorTagTypes
};
