import React, { FC, memo, useCallback, useMemo } from 'react';
import { Box, Button, GridColDef, DataGrid, DataGridProps, Skeleton } from '@sprnova/nebula';
import { EntitiesListProps } from 'components/List/List';
import omitBy from 'lodash/omitBy';
import { getBlueprintEndDate } from 'utils/blueprints/getBlueprintEndDate';
import { capitalize, formatCurrency } from 'utils';
import { Strategy } from 'features/entitiesRedux';
import BlueprintName from 'features/strategies/components/BlueprintName';
import BlueprintStageTableHeader from 'features/strategies/components/BlueprintStageTableHeader/BlueprintStageTableHeader';
import BlueprintStatus from 'features/strategies/components/BlueprintStatus';
import { BLUEPRINT_REVIEWER_TYPE } from 'features/strategies/constants';
import { Alert } from 'components';
import { DEFAULT_PAGE_SIZE } from '../../BlueprintsPackageReviewPage';
import css from './BlueprintsPackageReviewListTable.module.scss';

type BlueprintsPackageReviewListTableTypes = EntitiesListProps & {
  error?: any;
  filter: Record<string, unknown>;
  handlePaginationChange: ({ page, pageSize, }: {
    page: number;
    pageSize: number;
  }) => void;
  isLoading?: boolean;
  strategies: Strategy[];
  strategiesTotal: number;
  reviewerType: string;
};

export type KeyStrategy = keyof Strategy;

/**
 * Component to render the table list of blueprints package that need to be reviewed
 */
const BlueprintsPackageReviewListTable: FC<BlueprintsPackageReviewListTableTypes> = ({
  error,
  filter,
  isLoading,
  handlePaginationChange,
  strategies,
  strategiesTotal,
  reviewerType,
}) => {
  const hasFilters = !!Object.keys(omitBy(filter, (val) => !val)).length;
  const loading = isLoading || (strategies?.length === 0 && !hasFilters);
  const hashRoute = reviewerType === BLUEPRINT_REVIEWER_TYPE.Department ? '#team-commissions' : '';

  const redirectToBlueprint = useCallback((id: number): void => {
    window.open(`/blueprints/package/${id}${hashRoute}`, '_blank', 'noopener,noreferrer');
  }, [hashRoute]);

  const dateOptions: Intl.DateTimeFormatOptions = useMemo(() => {
    return (
      {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'UTC'
      }
    );
  }, []);
  const dateFormatter = useMemo(() => new Intl.DateTimeFormat('en-US', dateOptions), [dateOptions]);

  const tableColumns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'Blueprint Name',
        minWidth: 210,
        sortable: true,
        flex: 1,
        renderCell: (params): JSX.Element => {
          return <BlueprintName blueprint={params?.row} />;
        }
      },
      {
        field: 'start-date',
        flex: 1,
        headerName: 'Start Date',
        minWidth: 120,
        sortable: false,
        renderCell: (params): JSX.Element => {
          return <>{dateFormatter.format(new Date(params.row.date))}</>;
        }
      },
      {
        field: 'end-date',
        flex: 1,
        headerName: 'End Date',
        minWidth: 120,
        sortable: false,
        renderCell: (params): JSX.Element => {
          return <>{getBlueprintEndDate(params.row)}</>;
        }
      },
      {
        field: 'blueprint-stage',
        renderHeader: (): JSX.Element => {
          if (isLoading) return <Skeleton sx={{ minWidth: 170 }} />;
          return <BlueprintStageTableHeader />;
        },
        minWidth: 170,
        sortable: false,
        flex: 1,
        renderCell: (params): JSX.Element => {
          return <><BlueprintStatus className={css.table__body__cell__status} status={params.row.status} tooltip /></>;
        }
      },
      {
        field: 'average-gp',
        flex: 1,
        headerName: 'Avg. GP',
        minWidth: 145,
        sortable: false,
        renderCell: (params): string => {
          const blueprint = params.row;
          return blueprint.monthly_gross_profit ? formatCurrency(blueprint.monthly_gross_profit) : '-';
        }
      },
      {
        field: 'total-revenue',
        headerName: 'Total Revenue',
        minWidth: 145,
        sortable: false,
        renderCell: (params): string => {
          const blueprint = params.row;
          return blueprint.total_retainer ? formatCurrency(blueprint.total_retainer) : '-';
        }
      },
      {
        field: 'type',
        flex: 1,
        headerName: 'Type',
        minWidth: 100,
        sortable: false,
        renderCell: (params): string => {
          return capitalize(params.row.type);
        }
      },
      {
        field: 'length-of-contract',
        flex: 1,
        headerName: 'Length of Contract',
        minWidth: 180,
        sortable: false,
        renderCell: (params): string => {
          const blueprint = params.row;
          return `${blueprint.length} Month${blueprint.length > 1 ? 's' : ''}`;
        }
      },
      {
        id: 'action',
        field: 'action',
        flex: 1,
        headerName: 'Action',
        type: 'actions',
        minWidth: 150,
        getActions: ((params): JSX.Element[] => {
          return ([
            <Button
              key={params.row.id}
              className={css.link}
              disableRipple
              onClick={() => redirectToBlueprint(params?.row.id)}
              size="small"
            >
              Review Blueprint
            </Button>
          ]);
        }
        ),
        sortable: false,
      },
    ];
  },[dateFormatter, isLoading, redirectToBlueprint]);

  const onRowClick: DataGridProps['onRowClick'] = useCallback((params, event) => {
    event.preventDefault();
    redirectToBlueprint(params?.row.id);
  }, [redirectToBlueprint]);

  const renderTable = useCallback(() => {
    return (
      <Box sx={{
        '& .name-col': {
          whiteSpace: 'unset !important',
          padding: '0 10px !important',
        },
        '& .notes-col': {
          whiteSpace: 'unset !important',
        }
      }}>
        <DataGrid
          skeleton={loading}
          loading={isLoading}
          columns={tableColumns}
          disableRowSelectionOnClick
          initialState={{
            pagination: { paginationModel: { pageSize: DEFAULT_PAGE_SIZE } },
          }}
          onRowClick={onRowClick}
          pagination
          rows={strategies || []}
          rowHeight={100}
          paginationMode="server"
          onPaginationModelChange={handlePaginationChange}
          rowCount={strategiesTotal}
          sx={{
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            },
          }}
          getCellClassName={(params): string => {
            if (params.field === 'name') {
              return 'name-col';
            }
            if (params.field === 'notes') {
              return 'notes-col';
            }
            return '';
          }}
        />
      </Box>
    );
  }, [handlePaginationChange, isLoading, loading, onRowClick, strategies, strategiesTotal, tableColumns]);

  /**
   * Render the blueprint table list
   * @returns JSX.Element
   */
  const renderItem = (): JSX.Element => {
    return (
      <div>
        {renderTable()}
      </div>
    );
  };

  if (error) {
    return <Alert message={error} type="error" />;
  }

  return (
    <>
      {renderItem()}
    </>
  );
};

export default memo(BlueprintsPackageReviewListTable);
