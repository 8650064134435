import { useEffect } from 'react';
import LogRocket from 'logrocket';
import { useUser } from './useUser';

/**
 * Configures LogRocket for the current internal or external user.
 */
export const useLogRocket = (): void => {
  const { internalUser, externalUser } = useUser();
  const account = internalUser || externalUser;

  useEffect(() => {
    if (account?.id && account?.name && account?.email) {
      try {
        // Initialize LogRocket
        LogRocket.init('power-digital/poc-test', {
          mergeIframes: true,
          // Indicate the domains of all iframes -- cross- AND same-origin -- included in your main application code
          childDomains: [
            'https://powerdigital-dev.cloud.looker.com',
            'https://powerdigitalnonprod.cloud.looker.com',
            'https://powerdigitalnonprod1.cloud.looker.com',
            'https://retool.novapower.io',
            'https://powerdigital.retool.com',
            'https://app.milanote.com',
            'https://calendly.com',
            'https://docs.google.com'
          ],
        });

        // Identify user based on account details
        LogRocket.identify(String(account.id), {
          name: account.name,
          email: account.email,
        });
      } catch (e) {
        console.error('Error initializing LogRocket', e);
      }
    }
  }, [account]);
};
