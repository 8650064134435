import React, { memo } from 'react';
import { Box } from '@sprnova/nebula';
import BlueprintStageTooltip from '../BlueprintStageTooltip';

const BlueprintStageTableHeader = (): JSX.Element => {
  return (
    <>
      <Box sx={{ fontWeight: 500 }}>
      Stage
      </Box>
      <BlueprintStageTooltip />
    </>
  );
};

export default memo(BlueprintStageTableHeader);
