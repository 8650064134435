import React from 'react';

export const PerformanceIcon = () => (
  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="56" cy="56" r="47" fill="#D9D9D9"/>
    <path d="M9 83.5C9 81.2909 10.7909 79.5 13 79.5H32.5V99C32.5 101.209 30.7091 103 28.5 103H13C10.7909 103 9 101.209 9 99V83.5Z" fill="#4A1FFF"/>
    <path d="M32.5 60C32.5 57.7909 34.2909 56 36.5 56H56V75.5C56 77.7091 54.2091 79.5 52 79.5H32.5V60Z" fill="#A94882"/>
    <path d="M56 36.5C56 34.2909 57.7909 32.5 60 32.5H79.5V52C79.5 54.2091 77.7091 56 75.5 56H56V36.5Z" fill="#E65957"/>
    <path d="M79.5 13C79.5 10.7909 81.2909 9 83.5 9H99C101.209 9 103 10.7909 103 13V28.5C103 30.7091 101.209 32.5 99 32.5H79.5V13Z" fill="#FFA600"/>
  </svg>

);
