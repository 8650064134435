import React from 'react';
import loadable from '@loadable/component';
import { Skeleton } from 'antd';
import { useGetHorizonByUrlParam } from '../hooks/useGetHorizonByUrlParam';

const HorizonRoot = loadable(
  () => import('./HorizonRoot')
);

const Title = (): JSX.Element => {
  const { data: horizon, isLoading } = useGetHorizonByUrlParam({ refetchOnMountOrArgChange: true });

  if (!horizon || isLoading) {
    return <Skeleton title={{ width: 200 }} paragraph={false} active />;
  }

  return <>{horizon?.name}</>;

};

Title.displayName = 'HorizonRootTitle';

export default Object.assign(HorizonRoot, { Title });
