import loadable from '@loadable/component';

const LoadableComponent = loadable(
  () => import('./Icon')
);

export { LoadableComponent as Icon };

// Solo Icons
export * from './svg/KeyIcon';
export * from './svg/ExternalLinkIcon';
export * from './svg/NotificationIcon';
export * from './svg/HomeIcon';
export * from './svg/nebula/NebulaHomeIcon';
export * from './svg/nebula/NebulaHelpIcon';
export * from './svg/nebula/NebulaNotificationIcon';
export * from './svg/nebula/ExportIcon';
export * from './svg/ActiveContextualIcon';
export * from './svg/ArrowDownIcon';
export * from './svg/ArrowUpIcon';
export * from './svg/ArrowLeftIcon';
export * from './svg/ArrowRightIcon';
export * from './svg/BoxIcon';
export * from './svg/BoxStackIcon';
export * from './svg/BulletPointLShape';
export * from './svg/CheckmarkIcon';
export * from './svg/DataQIcon';
export * from './svg/ExclamationMarkIcon';
export * from './svg/CloseIcon';
export * from './svg/ContextualIcon';
export * from './svg/DropDownArrowDownIcon';
export * from './svg/DropDownArrowRightIcon';
export * from './svg/EditIcon';
export * from './svg/EmptyIcon';
export * from './svg/GoogleAds';
export * from './svg/GoogleDataStudioIcon';
export * from './svg/HorizonIcon';
export * from './svg/NetsuiteIcon';
export * from './svg/PinterestIcon';
export * from './svg/RakutenIcon';
export * from './svg/FacebookMessengerIcon';
export * from './svg/InstagramIcon';
export * from './svg/Intelligence';
export * from './svg/TikTokIcon';
export * from './svg/MetaIcon';
export * from './svg/BiggestWins';
export * from './svg/Strategies';
export * from './svg/Challenges';
export * from './svg/Solutions';
export * from './svg/TextBubble';
export * from './svg/Magic';
export * from './svg/MagicOutlined';
export * from './svg/MenuDescending';
export * from './svg/Comment';
export * from './svg/NovaScaleIcon';
export * from './svg/NovaLogo';
export * from './svg/PlayIcon';
export * from './svg/SetSquareIcon';
export * from './svg/StarIcon';
export * from './svg/CohortAnalysisIcon';
export * from './svg/LightbulbIcon';
export * from './svg/LightbulbFilledIcon';
export * from './svg/WarningIcon';
export * from './svg/ErrorIcon';
export * from './svg/InfoIcon';
export * from './svg/JoinRightIcon';
export * from './svg/NovaIntelligenceIcon';
export * from './svg/LockIcon';

//  Status Icons
export * from './svg/Status/Feedback';
export * from './svg/Status/Good';
export * from './svg/Status/Great';
export * from './svg/Status/Excellent';
export * from './svg/Status/Warning';

// Electric Icons
export * from './svg/AddNoteIcon';
export * from './svg/AddReportIcon';
export * from './svg/AddRowIcon';
export * from './svg/CreateNewIcon';
export * from './svg/DuplicateIcon';
export * from './svg/EditCircleIcon';
export * from './svg/PitchIcon';
export * from './svg/SaveIcon';
export * from './svg/ScoreCardIcon';
export * from './svg/ToTopIcon';

// Pure CSS Icons
export * from './LoadingIcon/LoadingIcon';

// Intelligence
export * from './svg/IntelligenceTitle';
export * from './svg/IntelligenceCohortsTitle';
export * from './svg/IntelligenceForecastsTitle';
export * from './svg/IntelligenceCreativeAffinityTitle';
export * from './svg/IntelligenceBasketAnalysisTitle';
export * from './svg/IntelligenceCustomerInsightsTitle';
