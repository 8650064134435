import { Client, ExternalClient, LookerStandardReport } from 'features/entitiesRedux';

/**
 * Given a client, indicated whether the client's business type is equal to that of the provided Looker report.
 *
 * @param client The client.
 * @param report The report.
 * @returns True if the client's business type is equal to that of the provided report or if the provided report
 * is not associated to any business type; false otherwise.
 */
const isClientBusinessTypeLookerReport = (client: Client | ExternalClient, report: LookerStandardReport): boolean => {
  const clientBusinessType = client?.business_type?.slug;
  const reportBusinessType = report?.business_type?.slug;

  if (!clientBusinessType) {
    throw new Error('Unknown business type of client.');
  }

  // Report is associated with client.
  const isClientBusinessTypeReport = reportBusinessType === clientBusinessType;

  // Report is associated with any client.
  const isAnyBusinessTypeReport = report.business_type === null || !report.business_type;

  return isClientBusinessTypeReport || isAnyBusinessTypeReport;
};

export default isClientBusinessTypeLookerReport;
