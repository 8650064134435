import { Projection } from 'api/entityGraphQL/types';
import { NovaAIChatType } from 'features/entitiesRedux/models/nova_ai';
import { baseApi, cleanArguments } from '..';

export type GetNovaAIChatHistoryQuery = {
  session_guid: string;
  projection?: Projection;
};

export type GetNovaAIChatHistoryResult = NovaAIChatType[];

export const GET_NOVA_AI_CHAT_HISTORY_ENDPOINT_KEY = 'getNovaAIChatHistory';

export const { useLazyGetNovaAIChatHistoryQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_NOVA_AI_CHAT_HISTORY_ENDPOINT_KEY]: build.query<GetNovaAIChatHistoryResult, GetNovaAIChatHistoryQuery>({
      transformResponse: (response: { nova_ai_chat_history: GetNovaAIChatHistoryResult }) => response.nova_ai_chat_history,
      query: ({
        projection = {
          responses: {
            response: true,
          }
        },
        ...args
      }) => ({
        body: {
          query: {
            nova_ai_chat_history: {
              __args: cleanArguments(args),
              ...projection
            },
          }
        }
      })
    }),
  }),
});
