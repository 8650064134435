import React, { forwardRef } from 'react';
import { Popover as AntdPopover } from 'antd';
import {RenderFunction} from 'antd/lib/_util/getRenderPropValue';
import {PopoverProps} from 'antd/lib/popover';
import {TooltipPlacement} from 'antd/lib/tooltip';
import classNames from 'classnames';
import css from './Popover.module.scss';

interface Props extends PopoverProps {
    title?: React.ReactNode | RenderFunction;
    content?: React.ReactNode | RenderFunction;
    placement?: TooltipPlacement;
    trigger?: string | string[];
    arrowPointAtCenter?: boolean;
}

const Popover = forwardRef(({ className, ...props }: Props, ref: any) => {
  return <AntdPopover {...props} overlayClassName={classNames(css.root, className)} ref={ref} />;
});

export default Popover;
