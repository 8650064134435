import { accessControl as ac, Action, Resource, Role } from 'api/accessControl';
import { paramCase } from 'change-case';
import { useUser } from 'utils/hooks/useUser';

const can = (accountId: number, roles: Role[]) => (
  action: Action,
  resource: Resource,
  authorId?: number
) => {
  const grants: boolean[] = roles.map((role) => {
    const roleParam = paramCase(String(role).toLowerCase());
    const query = ac.can(roleParam);

    /** First test for _Any permission */
    try {
      const ability = `${action}Any`;
      if ((query as any)[ability](resource).granted)
        // .attributes
        return true;
    } catch {}

    /** Second test for _Own permission */
    try {
      const ability = `${action}Own`;
      const ownEntity = accountId === authorId || action === Action.create;
      const granted = (query as any)[ability](resource).granted; // .attributes
      if (ownEntity && granted) return true;
    } catch {}

    return false;
  });

  return grants.some((grant) => grant === true);
};

export const useAccessControl = () => {
  const { internalUser, externalUser, isLoading } = useUser();
  const account = internalUser || externalUser;
  const { id: accountId, roles } = account || { id: 0, roles: [] };

  return {
    can: can(accountId, roles),
    isLoading
  };
};
