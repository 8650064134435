/**
 * Breadcrumbs are used to trace page navigation history.
 * Clicking on a page name in the string of breadcrumbs will result in navigation back to that page.
 */

import React, { useMemo } from 'react';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs as MuiBreadcrumbs, createTheme, ThemeProvider } from '@mui/material';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { HomeIcon } from 'components/Icon';
import { Pathname } from 'history';
import { RouteConfig, routes as defaultRoutes } from 'routes';

export interface BreadcrumbsType {
  routes?: any[];
  params?: any;
  pathname?: string | Pathname;
  icon?: JSX.Element;
}

const breadcrumbsTheme = createTheme({
  components: {
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
        },
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 12
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: '#4124BE',
          },
          '&:focus': {
            color: '#4124BE',
          }
        }
      }
    }
  },
});

export const Breadcrumbs = ({
  routes,
  params,
  pathname,
  icon = <HomeIcon />
}: BreadcrumbsType) => {
  /**
   * Expose routes, params, and pathname for testability/story
   */
  routes = routes ?? defaultRoutes;
  params = params ?? useParams<{ [x: string]: string }>();
  const { location } = useHistory() ?? {};
  pathname = pathname ?? location.pathname;
  const rootRoute = pathname.split('/')[1];

  const crumbs = useMemo(() => {
    const crumbs: RouteConfig[] = [];

    // Get all routes that contain the current one.
    const relatedCrumbs = routes?.filter((route) => `${route.path}/`.includes(`${rootRoute}/`))
    // Swap out any dynamic routes with their param values.
    // E.g. "/users/:id" will become "/id/1"
      .map(({ path, ...rest }) => ({
        path: Object.keys(params).length
          ? Object.keys(params).reduce((path, param) => {
            return path.replace(`:${param}`, params[param] ?? '');
          }, path)
          : path,
        ...rest,
      })) ?? [];

    pathname?.split('/').reduce(
      (acc, cur) => {
        const total = acc + `${acc !== '/' ? '/' : ''}` + cur;
        const filtered = relatedCrumbs.filter(crumb => crumb.path === total);
        const isRoot = cur === '/' || cur === '';
        // Root link (Dashboard) is set below, so we ignore it here
        if (!isRoot && filtered.length)
          crumbs.push(relatedCrumbs.filter(crumb => crumb.path === total)[0]);
        return total;
      },
      ''
    );

    return crumbs;
  }, [routes]);


  return (
    <ThemeProvider theme={breadcrumbsTheme}>
      <MuiBreadcrumbs aria-label='breadcrumb' separator={<NavigateNextIcon sx={{ fontSize: 12 }} />} >
        <Link underline='none' color='inherit' href='/'>
          {icon} Dashboard
        </Link>
        {
          rootRoute &&
          crumbs
            .filter((crumb) => crumb.path.length > 1)
            .sort((a, b) => a.path.length - b.path.length)
            .map(({ name, path: crumbPath, TitleComponent }, i, arr) => {
              const last = arr.length === i + 1;
              const renderTitle = () => {
                return TitleComponent ? <TitleComponent /> : name;
              };
              return (
                <div key={crumbPath}>
                  {
                    !last ? (
                      <RouterLink to={crumbPath}>
                        <Link underline='none' color='inherit' component='div'>{renderTitle()}</Link>
                      </RouterLink>
                    ) : (
                      <Typography color='text.primary'>{renderTitle()}</Typography>
                    )
                  }
                </div>
              );
            })
        }
      </MuiBreadcrumbs>
    </ThemeProvider>
  );
};

