import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'features/entitiesRedux';
import { selectAccount, selectAccountMeta, fetchMe } from '../globalSlice';
import { selectorComparator } from '../selectorComparator';

/**
 * Hooks
 */
export type UseAccountReturn = {
  account: User | null;
  isLoading: boolean;
  error?: string | null;
  xsrfToken?: string;
};

/**
 * @deprecated Use `useGetMeQuery` instead.
 */
export const useAccount = (): UseAccountReturn => {
  const dispatch = useDispatch();
  const account = useSelector(selectAccount, selectorComparator);
  const [isLoading, error = null] = useSelector(selectAccountMeta, selectorComparator);
  const [cookies] = useCookies(['XSRF-TOKEN']);
  const xsrfToken = cookies['XSRF-TOKEN'];

  useEffect(() => {
    if (!isLoading && !account) {
      try {
        dispatch(fetchMe());
      } catch (err) {
        console.error(err);
      }
    }
    /* include xsrfToken in dependencies to trigger refetch on login */
  }, [account, dispatch, isLoading, xsrfToken]);

  return {
    account,
    isLoading: !!isLoading,
    error: typeof error !== 'boolean' ? error : null,
    xsrfToken,
  };
};
