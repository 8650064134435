import React, { useCallback, useMemo, memo } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { Select, SelectProps, StarButton } from '@sprnova/nebula';
import { LookerReportTypeEnum } from 'features/clients/constants';
import { LookerStandardReport } from 'features/entitiesRedux';
import { isFavorited, LookerCategoryWithCombinedReports } from './utils';

export type FavoritedReport = {
  id: number;
  type: LookerReportTypeEnum;
};

export type LookerStandardReportWithReportType = {
  type: LookerReportTypeEnum;
} & LookerStandardReport;

type Props = {
  categories: LookerCategoryWithCombinedReports[];
  noReportsText?: string;
  favoritedReports?: FavoritedReport[];
  onChange?: ((event: SelectChangeEvent<string>) => void);
  onFavorite?: (favorite: FavoritedReport) => void;
  onUnfavorite?: (favorite: FavoritedReport) => void;
} & SelectProps;

const LookerReportSelect: React.FC<Props> = ({
  categories = [],
  noReportsText = 'No reports available',
  favoritedReports,
  onFavorite,
  onUnfavorite,
  ...rest
}) => {
  const renderItem = useCallback((report: LookerStandardReportWithReportType, isFavorited: boolean) => {
    const { looker_name, display_name, id, type, looker_category } = report;

    const favorite = {
      id,
      type,
    };

    const end = favoritedReports !== undefined ? (
      <StarButton
        active={isFavorited}
        onClick={(): void => isFavorited ? onUnfavorite?.(favorite) : onFavorite?.(favorite)}
      />
    ) : null;

    return (
      <Select.Item
        key={`${looker_category?.name}_${id}_${type}`}
        value={looker_name}
        end={end}
      >
        {display_name}
      </Select.Item>
    );
  }, [favoritedReports, onFavorite, onUnfavorite]);

  // Combine, or flatten, reports from all categories into an array.
  const allReports = useMemo(() =>
    categories.map(({reports}) => reports).flat(),
  [categories]);

  const allFavoritedReports = useMemo(() =>
    allReports.filter((report) => isFavorited(report, favoritedReports)),
  [allReports, favoritedReports]);

  const renderSelectOptions = useMemo(() => {

    const favoritedSelectItems = allFavoritedReports.map((report) => renderItem(report, true));

    const unfavoritedSelectItems = [...categories.reduce<React.ReactNode[]>((acc, category) => {

      const unfavoritedReports = category.reports.filter((report) => !isFavorited(report, favoritedReports));

      if (unfavoritedReports.length > 0) {
        return [
          ...acc,
          <Select.Category label={category.name} key={category.name} />,
          ...unfavoritedReports.map((report) => renderItem(report, false)),
        ];
      } else {
        return acc;
      }
    }, [])];

    if (favoritedSelectItems.length > 0 && unfavoritedSelectItems.length > 0) {
      // There are favorited reports and unfavorited reports.
      return [
        <Select.Category label="Favorites" key="favorites-category" />,
        ...favoritedSelectItems,
        <Select.Divider key="favorites-divider" />,
        ...unfavoritedSelectItems,
      ];
    } else if (favoritedSelectItems.length > 0) {
      // There are just favorited reports.
      return [
        <Select.Category label="Favorites" key="favorites-category" />,
        ...favoritedSelectItems,
      ];
    } else if (unfavoritedSelectItems.length > 0) {
      // There are just unfavorited reports.
      return unfavoritedSelectItems;
    } else {
      // There are no reports.
      return [
        <Select.Item disabled value="0" key="no-reports">
          {noReportsText}
        </Select.Item>
      ];
    }
  }, [allFavoritedReports, categories, renderItem, favoritedReports, noReportsText]);

  return (
    <Select
      {...rest}
    >
      {renderSelectOptions}
    </Select>
  );
};

export default memo(LookerReportSelect);
