import React, { useMemo, isValidElement, memo } from 'react';
import { Tabs as AntdTabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
import classNames from 'classnames';
import css from './Tabs.module.scss';

interface Props extends TabsProps {
    className?: string;
    tabBarStyle?: React.CSSProperties;
    style?: React.CSSProperties;
    children?: React.ReactNode;
}

const Tabs = ({ className, children, ...props }: Props): JSX.Element => {
  const createClassName = (props?: any): string => {
    const { tab } = props?.props ?? '';
    if (typeof tab === 'string') {
      return tab?.replace(/\s+/g, '-').toLowerCase();
    }
    return '';
  };

  const modifiedChildren = useMemo(() => {
    if (children && Array.isArray(children)) {
      return children.map((child) => {
        /**
         * If the child is a JSX tag or an object returned by createElement,
         * then return a new React element and join a new class name to it.
         */
        if (isValidElement(child)) {
          const el = child as React.ReactElement<any>;
          const props = {
            className: classNames(el?.props?.className, createClassName(child))
          };
          return React.cloneElement(el, props);
        }
        // The child is a non-element React node (e.g., string, number, etc.).
        return child;
      });
    } else if (children && isValidElement(children)) {
      /**
       * The child is a JSX tag or an object returned by createElement,
       * so return a new React element and join a new class name to it.
       */
      const el = children as React.ReactElement<any>;
      const props = {
        className: classNames(el.props?.className, createClassName(children))
      };
      return React.cloneElement(el, props);
    }
    // There is no child or the child is a non-element React node (e.g., string, number, etc.).
    return children;
  }, [children]);

  return (
    <AntdTabs {...props} className={classNames(className, css.root)} tabBarStyle={{marginBottom: 0}}>
      {modifiedChildren}
    </AntdTabs>
  );
};

export default memo(Tabs);
