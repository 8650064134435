import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { AppLayout } from '@sprnova/nebula';
import ProtectedBaseLayout from 'layouts/ProtectedBaseLayout/ProtectedBaseLayout';
import { ClientHeader, PrivacyPopUpModal, Sidebar } from './components';
import ClientBanner from './components/ClientBanner';
import { useNovaChallengesBanner } from './components/ClientBanner/hooks/useNovaChallengesBanner';
import ClientFooter from './components/ClientFooter';
import { Routes } from '../components';

export const ClientLayout: FC = () =>{
  const history = useHistory();
  const { isAuthenticated } = useAuth0();
  console.log('isAuthenticated', isAuthenticated);
  const shouldRenderPrivacyPopUp = history.location.pathname.includes('integrations') && !history.location.pathname.includes('oauthError');
  const { show, challengeData, refetch } = useNovaChallengesBanner();

  return (
    <ProtectedBaseLayout>
      <AppLayout
        banner={show ? <ClientBanner challengeData={challengeData} refetch={refetch} /> : null}
        header={<ClientHeader />}
        sidebar={<Sidebar />}
        footer={!window.location.href.includes('insights-ai') ? <ClientFooter /> : null}
      >
        <Routes />
      </AppLayout>
      {shouldRenderPrivacyPopUp ? <PrivacyPopUpModal /> : null}
    </ProtectedBaseLayout>
  );
};
