/**
 * SelectDevelopmentStatus select dropdown
 */

import React, { forwardRef } from 'react';
import useDevelopmentStatuses from 'utils/hooks/useDevelopmentStatuses';
import Select, { Props as SelectProps } from '../Select/Select';

interface SelectDevelopmentStatusProps extends SelectProps {
  className?: string;
}

const SelectDevelopmentStatus = forwardRef(({ className, skeleton: skeletonProp, ...props }: SelectDevelopmentStatusProps, ref: any) => {
  const { development_statuses: allDevelopmentStatuses, loading } = useDevelopmentStatuses();
  // Show skeleton when loading is true
  const skeleton = loading && !allDevelopmentStatuses.length;

  return (
    <Select
      showSearch
      optionFilterProp="children"
      skeleton={skeleton || skeletonProp}
      ref={ref}
      className={className}
      dropdownStyle={{ minWidth: 'fit-content' }} // the dropdown list width will grow depending on the list content text length
      {...props}
    >
      {allDevelopmentStatuses.map((value, index) => {
        return (
          <Select.Option key={`${index}-${value.name}`} value={value.id}>
            {value.name}
          </Select.Option>
        );})}
    </Select>
  );
});

SelectDevelopmentStatus.displayName = 'SelectDevelopmentStatus';

export default SelectDevelopmentStatus;
