import { CLIENT_STAGE_ENUM } from 'features/clients/constants';

/**
 * Get the Client stage definition
 * @param clientStage The client stage
 * @returns string The definition of the client stage
*/
export const getClientStageDefinition = (clientStage: string): string => {
  switch (clientStage) {
    case CLIENT_STAGE_ENUM.Acquired:
      return 'This Client has been acquired and does not have a Blueprint';
    case CLIENT_STAGE_ENUM.ContractEnded:
      return 'This Client\'s contract has ended. Either mark as churned or create a new Blueprint';
    case CLIENT_STAGE_ENUM.Inactive:
      return 'This Client\'s contract has ended and has been marked as churned';
    case CLIENT_STAGE_ENUM.PendingActive:
      return 'This Client has a start date within 15 days';
    case CLIENT_STAGE_ENUM.PendingChurn:
      return 'This Client has been marked as churned and the churn end date is not in effect';
    case CLIENT_STAGE_ENUM.PendingRenewal:
      return 'This Client has an active Blueprint and an end date within 90 days';
    case CLIENT_STAGE_ENUM.PotentialClient:
      return 'This Client is a lead';
    case CLIENT_STAGE_ENUM.WithinContract:
      return 'This Client is within their contract dates';
    default:
      return 'N/A';
  }
};
