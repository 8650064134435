/**
* Get the breadcrumbs for the current page
 * Returns an array of breadcrumb items that can be used to render a Breadcrumbs component
 */

import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { BreadcrumbsItemProps } from '@sprnova/nebula';
import { RouteConfig, routes } from 'routes';

const getRouteWithParams = (originalPath: string, params: Record<string, string>): string => {
  let path = originalPath;
  for (const [key, value] of Object.entries(params)) {
    path = path.replace(`:${key}`, value);
  }

  return path;
};

const isRoutePathMatch = (routePath: string, targetPath: string): boolean => {
  const routeSegments = routePath.split('/').filter(Boolean);
  const targetSegments = targetPath.split('/').filter(Boolean);

  if (routeSegments.length !== targetSegments.length) {
    return false;
  }

  for (let i = 0; i < routeSegments.length; i++) {
    if (routeSegments[i].startsWith(':')) {
      continue;
    }

    if (routeSegments[i] !== targetSegments[i]) {
      return false;
    }
  }

  return true;
};

export const useBreadcrumbs = (): BreadcrumbsItemProps[] => {
  const location = useLocation();
  const params = useParams();
  const pathnames = location.pathname.split('/').filter(path => path);

  return useMemo(() => {
    let accumulatedPath = '';

    return pathnames.map((pathSegment) => {
      accumulatedPath += `/${pathSegment}`;
      const route: (RouteConfig | undefined) = routes.find(route => isRoutePathMatch(route.path, accumulatedPath));

      if (!route) {
        return undefined;
      }
      
      const { path, name, TitleComponent } = route;

      return {
        name: TitleComponent ? <TitleComponent /> : name,
        path: getRouteWithParams(path ?? '', params),
      };
    }).filter(Boolean) as BreadcrumbsItemProps[];
  }, [pathnames, params]);
};