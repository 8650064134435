import React, { FC, memo, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Checkbox } from '@sprnova/nebula';
import { Service, Task } from 'features/entitiesRedux';
import { PricingTypeEnum } from 'features/library/package-strategies/constants';
import TaskItem from '../TaskItem';
import TasksListItem from '../TasksListItem';
import css from './ServiceItem.module.scss';

type Props = {
  service: Service;
  onSelect: (tasks: Task[]) => void;
  onUnselect: (tasks: Task[]) => void;
  selectedIds: number[];
  pricingVersion: string;
  currentId?: number;
};

const ServiceItem: FC<Props> = ({
  service,
  onSelect,
  onUnselect,
  selectedIds,
  pricingVersion,
  currentId,
}) => {
  const [collapsedServices, setCollapsedServices] = useReducer((bool) => !bool, true);

  const tasks = useMemo(() => service?.tasks.filter((task: Task) => {
    return task.pricing_version?.slug === pricingVersion
    && task?.next_revision === null
    && task.id !== currentId;
  }), [currentId, pricingVersion, service?.tasks]);

  return (
    <Checkbox.Group
      id="checkboxGroupServices"
      collapsed={collapsedServices}
      label={service.name}
      onCollapseChange={setCollapsedServices}
      sx={{ marginLeft: 0 }}
      className={css.checkbox__group__services}
    >
      <TasksListItem
        tasks={tasks}
        onSelect={onSelect}
        onUnselect={onUnselect}
        selectedIds={selectedIds}
      />
    </Checkbox.Group>
  );
};

export default memo(ServiceItem);
