import React from 'react';
import { Slider as NebulaSlider, SliderProps } from '@sprnova/nebula';
import classNames from 'classnames';
import { useTheme } from '../../utils';
import css from './Slider.module.scss';

const Slider = ({ className, min, max, ...props }: SliderProps): JSX.Element => {
  const { isTalent } = useTheme();
  const sliderRef = React.useRef<HTMLInputElement>(null); // Add this line to create a ref of type HTMLInputElement
  return (
    <NebulaSlider
      {...props}
      className={classNames(
        css.root,
        { [css.talent]: isTalent },
        className,
      )}
      min={min}
      max={max}
      ref={sliderRef} // Add this line to assign the ref to the NebulaSlider component
    />
  );
};

export default Slider;
