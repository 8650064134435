import React from 'react';
import loadable from '@loadable/component';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';

const CreativePlaybookRoot = loadable(
  () => import('./CreativePlaybookRoot'),
  {
    fallback: <PageSkeleton />
  }
);

const Title = (): JSX.Element => {
  return <>Creative Affinity</>;
};

Title.displayName = 'CreativePlaybookRootTitle';

export default Object.assign(CreativePlaybookRoot, { Title });
