import React, { memo, useCallback, useMemo, useState } from 'react';
import { SxProps } from '@mui/system';
import { Button } from '@sprnova/nebula';
import { config } from 'config';
import useMixpanelTrack from 'utils/hooks/useMixpanelTrack';
import { useAccount } from 'features/global/hooks/useAccount';
import { useDataSources } from '../../../../../../../../entitiesRedux';

interface googleAuthRedirectUrlProps {
  redirect: string,
  buttonLabel: string,
  endIcon?: React.ReactNode,
  startIcon?: React.ReactNode,
  sx?: SxProps,
}

/**
 * Return Google Redirect Url
 */
const GoogleAuthRedirectUrl = ({ redirect, buttonLabel, endIcon, startIcon, sx }: googleAuthRedirectUrlProps): JSX.Element => {
  const datasources = useDataSources();
  const dataSourceType = 'google-proposal';
  const filteredDataSource = datasources?.filter(datasource => datasource.slug === dataSourceType);
  const mixpanel = useMixpanelTrack();

  /**
   * Get the current domain from config file to render redirect urls.
   * Find a redirect path and set the current user type.
   */
  const host = config?.host;
  /**
   * Local use state variables. Reduce rendering.
   */
  const scope = 'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/documents https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/userinfo.email';
  const accessType = 'offline';

  /**
   * For local development api is running on different port.
   * const redirectUrl = 'http://localhost:8888/authorize/google-auth/callback';
   */
  const redirectUrl = host?.concat(filteredDataSource[0]?.redirect_uri);

  /** Parameters to pass to auth/callback. */
  const googleClientId = filteredDataSource[0]?.client_id;
  const state = `redirect;${redirect}`;

  const { account } = useAccount();

  /** Build Google Ads auth link. */
  const authLink = `${filteredDataSource[0]?.auth_url}?prompt=consent&hd=powerdigitalmarketinginc.com&response_type=code&client_id=${googleClientId}&scope=${scope}&access_type=${accessType}&redirect_uri=${redirectUrl}&state=${state}&flowName=GeneralOAuthFlow`;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const trackGoogleAuthClick = useCallback(() => {
    if (account) {
      setIsButtonDisabled(true);
      try {
        const options = {
          usersName: account.name,
          usersEmail: account.email,
          usersId: account.id,
        };
        const eventTitle = 'Clicked Google Authorization Button';
        mixpanel(eventTitle, options);
      } catch (error) {
        console.error('Mixpanel error', error);
      }
    }
  }, [account, mixpanel, setIsButtonDisabled]);

  const renderButton = useMemo(() => {
    return ( <Button onClick={trackGoogleAuthClick}
      href={`${authLink}`}
      rel='noopener noreferrer'
      target="_blank"
      size="small"
      variant="tertiary"
      endIcon={endIcon}
      startIcon={startIcon}
      sx={sx}
      disabled={isButtonDisabled}
    >
      {buttonLabel}
    </Button>);
  }, [authLink, buttonLabel, endIcon, startIcon, sx, trackGoogleAuthClick, isButtonDisabled]);

  /*disable button for 5 sec after click to prevent multiple clicks*/
  return (renderButton);
};
export default memo(GoogleAuthRedirectUrl);
