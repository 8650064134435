import React, {CSSProperties, MouseEventHandler} from 'react';
import {Button as MuiButton, ButtonProps, createTheme, ThemeProvider} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';
import classNames from 'classnames';


/**
 * Buttons are used for any call to action.
 * The hierarchy of CTA importance from most important to least important is primary, secondary, tertiary, text, then icon.
 */

export interface ButtonCustomProps extends ButtonProps {
  children?: JSX.Element | string;
  icon?: JSX.Element;
  icon_position?: 'left' | 'right';
  size?: 'small' | 'medium' | 'large';
  href?: string;
  htmlType?: 'button' | 'submit' | 'reset' | undefined;
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'text';
  disabled?: boolean;
  width?: string;
  onClick?:  MouseEventHandler<HTMLButtonElement>,
  style?: CSSProperties | undefined;
}

const primaryButtonTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          font: '16px',
          fontWeight: '600',
          boxShadow: 'none',
          background: '#4A1FFF',
          color: '#FFFFFF',
          textTransform: 'none',
          '&:hover': {
            background: '#4124BE',
            boxShadow: 'none',
          },
          '&:active': {
            background: '#4124BE',
          },
          '&.Mui-disabled': {
            color: '#AEAEAE',
            background: '#EFEFEF',
            borderColor: '#AEAEAE'
          }
        }
      }
    }
  }
});

const secondaryButtonTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          font: '16px',
          fontWeight: '600',
          color: '#4A1FFF',
          borderColor: '#4A1FFF',
          background: '#FFFFFF',
          textTransform: 'none',
          borderWidth: '2px',
          '&:hover': {
            background: '#E1DAFF',
            borderColor: '#4124BE',
            borderWidth: '2px',
          },
          '&:active': {
            background: '#E1DAFF',
          },
          '&.Mui-disabled': {
            borderWidth: '1px',
            color: '#AEAEAE',
            background: '#EFEFEF',
            borderColor: '#AEAEAE'
          }
        }
      }
    }
  }
});

const tertiaryButtonTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          font: '16px',
          fontWeight: '600',
          color: '#4A1FFF',
          borderColor: '#D9D9D9',
          background: '#FFFFFF',
          textTransform: 'none',
          '&:hover': {
            background: '#E1DAFF',
            borderColor: '#4124BE',
          },
          '&:active': {
            background: '#E1DAFF',
          },
          '&.Mui-disabled': {
            color: '#AEAEAE',
            background: '#EFEFEF',
            borderColor: '#AEAEAE',
          }
        }
      }
    }
  }
});

const textButtonTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          font: '16px',
          fontWeight: '600',
          color: '#4A1FFF',
          textTransform: 'none',
          '&:hover': {
            color: '#4124BE',
            background: 'none',
            text: 'underline',
          },
          '&:active': {
            color: '#4124BE',
          },
          '&.Mui-disabled': {
            color: '#AEAEAE',
          }
        },

      }
    }
  }
});

export const NebulaButton = ({
  children,
  icon,
  icon_position = 'left',
  size = 'large',
  sx,
  href,
  htmlType,
  onClick,
  buttonType = 'primary',
  disabled,
  width,
  style,
  ...props
}: ButtonCustomProps): JSX.Element => {
  const fontFamily = 'Inter';

  const createClassName = (): string => {
    if (typeof children === 'string') return children?.replace(/\s+/g, '-').toLowerCase();
    return '';
  };

  switch (buttonType) {
    case 'primary' :
      return <ThemeProvider theme={primaryButtonTheme}>
        <MuiButton
          variant="contained"
          size={size}
          disabled={disabled}
          style={style}
          href={href}
          type={htmlType}
          startIcon={icon && icon_position==='left' ? icon : <></>}
          endIcon={icon && icon_position==='right' ? icon : <></>}
          className={createClassName()}
          onClick={onClick}
          sx={{ width, fontFamily }}
          {...props}
        >
          {children}
        </MuiButton>
      </ThemeProvider>;
    case 'secondary':
      return <ThemeProvider theme={secondaryButtonTheme}>
        <MuiButton
          variant="outlined"
          size={size}
          disabled={disabled}
          style={style}
          href={href}
          type={htmlType}
          startIcon={icon && icon_position==='left' ? icon : <></>}
          endIcon={icon && icon_position==='right' ? icon : <></>}
          className={createClassName()}
          onClick={onClick}
          sx={{ width, fontFamily }}
          {...props}
        >
          {children}
        </MuiButton>
      </ThemeProvider>;
    case 'tertiary':
      return <ThemeProvider theme={tertiaryButtonTheme}>
        <MuiButton
          variant="outlined"
          size={size}
          disabled={disabled}
          style={style}
          href={href}
          type={htmlType}
          startIcon={icon && icon_position==='left' ? icon : <></>}
          endIcon={icon && icon_position==='right' ? icon : <></>}
          className={createClassName()}
          onClick={onClick}
          sx={{ width, fontFamily }}
          {...props}

        >
          {children}
        </MuiButton>
      </ThemeProvider>;
    case 'text':
      return <ThemeProvider theme={textButtonTheme}>
        <MuiButton
          variant="text"
          size={size}
          disabled={disabled}
          style={style}
          href={href}
          type={htmlType}
          startIcon={icon && icon_position==='left' ? icon : <></>}
          endIcon={icon && icon_position==='right' ? icon : <></>}
          className={createClassName()}
          onClick={onClick}
          sx={{ width, fontFamily }}
          {...props}

        >
          {children}
        </MuiButton>
      </ThemeProvider>;
  }
};
