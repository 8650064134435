import { createAsyncThunk } from '@reduxjs/toolkit';
import { FetchAlertsFilter, novaGraphQLClient, Pagination, Projection } from 'api/entityGraphQL';
import { normalizeEntities } from 'features/entitiesRedux/utils';
import { YesNo, ActionFetchManyPaginatedConfig, ActionFetchPaginatedResponse } from '../types';
/**
 * Fetch total selected clients
 */
export type ActionFetchTotalClients = {
  business_type_ids?: number[];
  industry_ids?: number[];
  client_ids?: number[];
}
export const fetchTotalClients = createAsyncThunk(
  'entities/fetchTotalClients',
  async ({
    business_type_ids,
    industry_ids,
    client_ids,
  }: ActionFetchTotalClients): Promise<any> => {
    const { clients } = await novaGraphQLClient.fetchTotalClients({
      args: {
        business_type_ids,
        industry_ids,
        client_ids,
      },
      projection: { total: true }
    });
    return {
      ...normalizeEntities({ clients })
    };
  }
);

/**
 * Fetch alerts
 */
export const fetchAlerts = createAsyncThunk(
  'entities/fetchAlerts',
  async ({ is_active, pagination, projection }: { is_active?: YesNo; pagination?: Pagination; projection?: Projection; }): Promise<any> => {
    const { alerts, ...rest } = await novaGraphQLClient.fetchAlerts({ args: { is_active }, pagination, projection });
    return {
      ...normalizeEntities({ alerts }),
      pagination: rest
    };
  }
);

/**
 * Fetch alerts paginated
 */
export const fetchAlertsPaginated = createAsyncThunk(
  'entities/fetchAlertsPaginated',
  async ({
    filter,
    pagination,
    projection
  }: ActionFetchManyPaginatedConfig & {
    filter?: FetchAlertsFilter;
  }): Promise<ActionFetchPaginatedResponse> => {
    const { alerts, ...paginatedResponse } = await novaGraphQLClient.fetchAlerts({
      args: {
        ...filter,
        title: filter?.title ?
          `*${String(filter?.title).replace(/\s+/g, '*')}*` :
          undefined,
        business_type_id: filter?.business_type_id,
        industry_id: filter?.industry_id,
        sort: filter?.sort,
        is_active: filter?.is_active
      },
      pagination,
      projection
    });
    return {
      ...normalizeEntities({ alerts }),
      pagination: paginatedResponse,
    };
  }
);

/**
 * Fetch alert by ID
 */
export const fetchAlertById = createAsyncThunk(
  'entities/fetchAlertById',
  async ({ id, projection }: { id: number; projection?: Projection; }): Promise<any> => {
    const { alerts } = await novaGraphQLClient.fetchAlertById(
      id,
      // projection
    );
    return {
      ...normalizeEntities({ alerts })
    };
  }
);

/**
 * Delete alert
 */
export const deleteAlert = createAsyncThunk(
  'entities/deleteAlert',
  async ({ id, projection }: { id: number; projection?: Projection; }): Promise<any> => {
    const { alerts } = await novaGraphQLClient.deleteAlert(
      id,
      // projection
    );
    return {
      ...normalizeEntities({ alerts })
    };
  }
);

/**
 * Create new alert
 */
export const createAlert = createAsyncThunk(
  'entities/createAlert',
  async ({ type, style, is_active, start_at, end_at, title, text, show_button }: any): Promise<any> => {
    const response = await novaGraphQLClient.createAlert(
      { type, style, is_active, start_at, end_at, title, text, show_button }
    );
    return { ...normalizeEntities(response) };
  }
);

/**
 * Update alert
 */
export const updateAlert = createAsyncThunk(
  'entities/updateAlert',
  async ({ id, type, style, is_active, start_at, end_at, title, text, show_button }: any): Promise<any> => {
    const response = await novaGraphQLClient.updateAlert(
      { id, type, style, is_active, start_at, end_at, title, text, show_button }
    );
    return { ...normalizeEntities(response) };
  }
);

/**
 * Create alert recipients
 */
export const createAlertRecipients = createAsyncThunk(
  'entities/createAlertRecipients',
  async ({ alert_id, entity_ids, entity_type }: any): Promise<any> => {
    const response = await novaGraphQLClient.createAlertRecipients(
      { alert_id, entity_ids, entity_type }
    );
    return { ...normalizeEntities(response) };
  }
);

/**
 * Save alert recipients
 */
export const saveAlertRecipients = createAsyncThunk(
  'entities/saveAlertRecipients',
  async ({ alert_id, recipients, delete_missing }: any): Promise<any> => {
    const response = await novaGraphQLClient.saveAlertRecipients(
      { alert_id, recipients, delete_missing }
    );
    return { ...normalizeEntities(response) };
  }
);

/**
 * Create alert client
 */
export const createAlertClient = createAsyncThunk(
  'entities/createAlertClient',
  async ({ alert_id, client_id, is_active }: any): Promise<any> => {
    const response = await novaGraphQLClient.createAlertClient(
      { alert_id, client_id, is_active }
    );
  }
);
