/**
 * View strategy -> Hourly Breakdown -> Table -> HourField
 */

import React, { FC, MutableRefObject, memo, useCallback, useEffect, useState } from 'react';
import { StopOutlined, CloseOutlined, WarningOutlined } from '@ant-design/icons';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { Popover } from 'antd';
import { useUpdateStrategyTaskMonthMutation } from 'api/crudGraphQL/strategies/additional/task_month/updateStrategyTaskMonth';
import classNames from 'classnames';
import { StrategyMonth, StrategyTask } from 'features/entitiesRedux';
import cssViewStrategyPage from 'features/strategies/ViewStrategyPage/ViewStrategyPage.module.scss';
import { InputNumber, DebouncedField, notification } from 'components';
import css from './HourField.module.scss';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';

type Types = {
  month: StrategyMonth
  task: StrategyTask;
  strategyId: number;
  isLocked: boolean;
  className?: string
  handleHourFieldUpdate: (value: number, strategy_id: number, month_id: number, task_id: number) => void;
  handlePasteTaskHourForRow: (monthValue: number, taskId: number, monthId: number, strategyId: number) => void;
  isDraggingHoursRef: MutableRefObject<boolean>;
  taskMonthIdToTaskAndMonthObjectMapRef: MutableRefObject<Record<string, unknown>>;
};

const HourField: FC<Types> = ({
  month,
  task,
  strategyId: strategy_id,
  isLocked = false,
  className,
  handleHourFieldUpdate,
  handlePasteTaskHourForRow,
  isDraggingHoursRef,
  taskMonthIdToTaskAndMonthObjectMapRef,
}) => {

  const [value, setValue] = useState(month?.value ?? 0);
  const [hourIsSelectedOnDragToUpdate, setHourIsSelectedOnDragToUpdate] = useState<boolean>(false);
  const [draggedCounter, setDragCounter] = useState<number>(0);

  useEffect(() => {
    setValue(month?.value);
  }, [month?.value]);

  if (hourIsSelectedOnDragToUpdate && !isDraggingHoursRef.current) {
    setHourIsSelectedOnDragToUpdate(false);
  }

  const onDragLeave = useCallback((event: any) => {
    event.preventDefault();
    setDragCounter(0);
  }, [setDragCounter]);

  const onDragOver = useCallback((event: any) => {
    // allow this to be a drop target by preventing default event
    event.preventDefault();
    /**
       * Only toggle the hourIsSelectedOnDragToUpdate first drag event over the element each time
       * Otherwise while dragging it will keep calling state calls unnecessarily
       */

    if (isDraggingHoursRef.current && draggedCounter === 0) {

      /**
         * If the month and/or task is not already in the list of months to update with the dragged hours value
         * and it is not already selected to be updated with the dragged hours value
         * then add it to the respective list to update it
         *
         * If the month and/or task has already been selected to be updated with the dragged hours
         * value and we are hovering over it again (on the way back)
         * then remove it from the list of months to update with the dragged hours value to toggle it off
         */

      const taskMonthMapKey = `${task?.id}-${month?.id}`;
      const taskId = task?.id;
      const monthId = month?.id;

      if (!hourIsSelectedOnDragToUpdate && !Object.hasOwn(taskMonthIdToTaskAndMonthObjectMapRef.current, taskMonthMapKey)) {
        taskMonthIdToTaskAndMonthObjectMapRef.current = { ...taskMonthIdToTaskAndMonthObjectMapRef.current, [taskMonthMapKey]: { taskId, monthId } };
      }

      else if (hourIsSelectedOnDragToUpdate && Object.hasOwn(taskMonthIdToTaskAndMonthObjectMapRef.current, taskMonthMapKey)) {
        delete taskMonthIdToTaskAndMonthObjectMapRef.current[taskMonthMapKey as keyof typeof taskMonthIdToTaskAndMonthObjectMapRef.current];
        taskMonthIdToTaskAndMonthObjectMapRef.current = { ...taskMonthIdToTaskAndMonthObjectMapRef.current };
      }

      setHourIsSelectedOnDragToUpdate(hourIsSelectedOnDragToUpdate => !hourIsSelectedOnDragToUpdate);
      setDragCounter(draggedCounter => draggedCounter + 1);
    }
  }, [isDraggingHoursRef, draggedCounter, task?.id, month?.id, hourIsSelectedOnDragToUpdate, taskMonthIdToTaskAndMonthObjectMapRef]);

  const handleHourFieldChange = useCallback((newHourValue: string | number | null) => {
    if (typeof newHourValue !== 'string') {
      // default empty to 0
      if (newHourValue === null) {
        newHourValue = 0;
      }

      setValue(newHourValue);
      handleHourFieldUpdate(newHourValue, task?.id, month?.id, strategy_id);

    }

  }, [handleHourFieldUpdate, month?.id, strategy_id, task?.id]);

  const handlePasteCallback = useCallback(() => {
    return handlePasteTaskHourForRow(value, task?.id, month?.id, strategy_id);
  }, [handlePasteTaskHourForRow, month?.id, strategy_id, task?.id, value]);

  const inputField = <InputNumber
    onChange={handleHourFieldChange}
    className={classNames(css.root, { [cssViewStrategyPage.editingDisabled]: isLocked, [cssViewStrategyPage.hourIsSelectedOnDragToUpdate]: hourIsSelectedOnDragToUpdate}, className)}
    value={value}
    disabled={isLocked}
    onDragOver={onDragOver}
    onDragLeave={onDragLeave}
    min={0}
    keyboard={false}
  />;
  // we are storing the month number in the name field and we can use that to determine the first month
  return (
    <>
      {inputField}
      {month?.name === 1 && !isLocked &&
        <Popover content={'Apply value to entire row'}>
          <ArrowCircleRightOutlinedIcon sx={{cursor: 'pointer',position: 'relative',top:'5px',left: '6px',fontSize: '21px'}} onClick={handlePasteCallback} />
        </Popover>
      }
    </>
  );
};

export default memo(HourField);
