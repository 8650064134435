/**
 * StrategyForm -> RecommendedServices -> SelectAnalystSurveyDepartment
 */

import React, { forwardRef } from 'react';
import Select, { Props as SelectProps } from '../../../../../../../../components/Select/Select';
import { Department } from '../../../../../../../entitiesRedux';
import css from './SelectAnalystSurveyDepartment.module.scss';

interface SelectAnalystSurveyDepartmentProps extends SelectProps {
  departments: Department[];
  onDepartmentChange: (departmentId: number) => void;
  onDepartmentClear: () => void;
  defaultDepartmentId?: number | null;
}

const SelectAnalystSurveyDepartment = forwardRef(({
  skeleton: skeletonProp,
  departments,
  onDepartmentChange,
  onDepartmentClear,
  defaultDepartmentId,
  ...props
}: SelectAnalystSurveyDepartmentProps, ref: any) => {

  return (
    <Select
      className={css.root}
      allowClear
      showSearch
      optionFilterProp="children"
      skeleton={skeletonProp}
      defaultValue={defaultDepartmentId}
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      placeholder="All Channels"
      ref={ref}
      onSelect={onDepartmentChange}
      onClear={onDepartmentClear}
      {...props}
    >
      {departments.map((department: Partial<Department>) => (
        <Select.Option key={department.id} value={department.id || ''}>
          {department.name}
        </Select.Option>
      ))}
    </Select>
  );
});

SelectAnalystSurveyDepartment.displayName = 'SelectAnalystSurveyDepartment';

export default SelectAnalystSurveyDepartment;
