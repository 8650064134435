import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs as NebulaBreadcrumbs,
  BreadcrumbsProps as NebulaBreadcrumbProps,
  styled,
  BreadcrumbsItemProps
} from '@sprnova/nebula';
import {StringParam, useQueryParam, withDefault} from 'use-query-params';
import { useBreadcrumbs } from './useBreadcrumbs';

const StyledBreadcrumbs = styled(NebulaBreadcrumbs)(() => ({
  // Todo: remove this later (Tiny tweak to remove margin fro ant design skeleton)
  '.ant-skeleton-title': {
    margin: '0 !important'
  }

}));

export interface BreadcrumbsProps extends NebulaBreadcrumbProps {
  hideHome?: boolean;
  mapFunc?: (item: BreadcrumbsItemProps, index: number) => BreadcrumbsItemProps;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  hideHome = false,
  mapFunc = (item: BreadcrumbsItemProps): BreadcrumbsItemProps => item
}) => {
  const breadcrumbs = useBreadcrumbs();
  const [pricingVersionQueryParam, setPricingVersionQueryParam] = useQueryParam<string>(
    'pricingVersion',
    useMemo(() => withDefault(StringParam, 'hourly' as string), [])
  );

  const items = useMemo(() => {
    const crumbsPricingVersionBase = breadcrumbs[0].path;
    const crumbsBasedOnRoute = breadcrumbs.map(({ name, path }) => {
      if(pricingVersionQueryParam && crumbsPricingVersionBase === '/library') {
        path = path + '?pricingVersion=' + pricingVersionQueryParam;
      }
      return (
        {
          name,
          path,
          component: Link,
          to: path
        });
    });

    let crumbs: BreadcrumbsProps['items'] = crumbsBasedOnRoute;

    if (!hideHome) {
      crumbs = [
        {
          name: 'Home',
          path: '/'
        },
        ...crumbsBasedOnRoute
      ];
    }

    if (mapFunc) {
      return crumbs.map(mapFunc);
    }

    return crumbs;
  }, [breadcrumbs, hideHome, mapFunc]);

  return (
    <StyledBreadcrumbs items={items} />
  );

};
