import loadable from "@loadable/component";

const LoadableComponent = loadable(
  () => import("./QuestionScore")
  /* , {
  fallback: <div>Loading...</div>,
  } */
);

export { LoadableComponent as QuestionScore };
