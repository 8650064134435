/**
 * Entities -> Partners -> Reducers
 */

import { EntitiesState } from '../../types';
import { partnersAdapter } from './adapter';

export default {
  'deletePartner/fulfilled'(state: EntitiesState, action: { payload:  number }) {
    state.isLoading = false;
    partnersAdapter.removeOne(state.data.partners, action.payload);
  }
};
