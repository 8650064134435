import React, { memo, useEffect } from 'react';
import { Textarea, Typography } from '@sprnova/nebula';
import classNames from 'classnames';
import { TextEditor } from 'components';
import { maxCharactersSnippetSummary } from '../constants';
import { ActionType, FormDataType } from '../packageStrategyTypes';
import css from '../package-strategies.module.scss';

type CustomPricingContractDetailsProps = {
  isSubmitted: boolean;
  dispatch: React.Dispatch<any>;
  formData: Partial<FormDataType>;
}

const CustomPricingContractDetails = ({ isSubmitted, dispatch, formData }: CustomPricingContractDetailsProps): JSX.Element => {
  // initialize form data with default custom pricing values
  useEffect(() => {
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_SINGLE_TIER, payload: {
      snippet: '',
    }});
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_DATA, payload: { snippet_summary: '' }});
  }, [dispatch]);

  return (
    <>
      <Textarea
        id="snippetSummaryField"
        error={formData?.snippet_summary && formData?.snippet_summary.length > maxCharactersSnippetSummary && isSubmitted}
        placeholder="This will appear in a tooltip when a user is adding this strategy to a Blueprint"
        label="Snippet summary"
        maxCharacters={maxCharactersSnippetSummary}
        minRows={2}
        resizeable
        sx={{ marginBottom: '24px', marginTop: '24px' }}
        value={formData?.snippet_summary}
        onChange={(event): void => dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_DATA, payload: { snippet_summary: event.target.value } })}
      />
      <Typography sx={{ fontWeight: 600 }} display="block" gutterBottom>
        Proposal Snippet
      </Typography>
      <TextEditor
        className={classNames(css.text_editor, !formData?.pricing_tiers?.[0]?.snippet && isSubmitted ? css.snippet_error : undefined)}
        onChange={(snippetHtml): void => {
          dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_SINGLE_TIER, payload: { snippet: snippetHtml }});
        }}
      />
    </>
  );
};

export default memo(CustomPricingContractDetails);
