export default {
  CLIENTS: 'CLIENTS',
  CLIENT: 'CLIENT',
  CLIENT_ADDRESS: 'CLIENT_ADDRESS',
  CLIENT_DOCUMENT_SECTION: 'CLIENT_DOCUMENT_SECTION',
  CLIENT_DOCUMENT_SECTIONS: 'CLIENT_DOCUMENT_SECTIONS',
  CLIENT_DOCUMENT_FILE: 'CLIENT_DOCUMENT_FILE',
  CLIENT_DOCUMENT_FILES: 'CLIENT_DOCUMENT_FILES',
  CLIENT_STATUS_UPDATE: 'CLIENT_STATUS_UPDATE',
  CLIENT_STATUS_UPDATES: 'CLIENT_STATUS_UPDATES',
  CLIENT_STATUS_UPDATES_SECTION_TYPE: 'CLIENT_STATUS_UPDATE_SECTION_TYPE',
  CLIENT_STATUS_UPDATES_SECTION_TYPES: 'CLIENT_STATUS_UPDATES_SECTION_TYPES',
  NETSUITE: 'NETSUITE',
};
