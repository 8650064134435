export default {
  SALESFORCE_OPPORTUNITIES: 'SALESFORCE_OPPORTUNITIES',
  SALESFORCE_OPPORTUNITY: 'SALESFORCE_OPPORTUNITY',
  SALESFORCE_ACCOUNTS: 'SALESFORCE_ACCOUNTS',
  SALESFORCE_ACCOUNT: 'SALESFORCE_ACCOUNT',
  OPPORTUNITY_REASONS: 'OPPORTUNITY_REASONS',
  OPPORTUNITY_REASON: 'OPPORTUNITY_REASON',
  OPPORTUNITY_STAGES: 'OPPORTUNITY_STAGES',
  OPPORTUNITY_STAGE: 'OPPORTUNITY_STAGE'
};
