import React, { useEffect, useState } from 'react';
import { FormInstance } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment, { Moment } from 'moment';
import { HorizonModelClient } from 'features/entitiesRedux/models/horizon';
import { DatePicker, FormItem } from 'components';
import css from './DateRange.module.scss';

type DateRangeProps = {
  setLength: (length: number | undefined) => void;
  models?: HorizonModelClient[];
  form: FormInstance;
  isEdit?: boolean
}

const DateRange = ({ setLength, form, models, isEdit }: DateRangeProps): JSX.Element => {
  const [startDate, setStartDate] = useState<Moment>();
  const [startDatedSelected, setStartDateSelected] = useState<boolean>(false);
  const [modelCreationDate, setModelCreationDate] = useState<string>();

  useEffect(() => {
    if (isEdit) {
      setStartDate(form.getFieldValue('start_date'));
    }
  }, [form.getFieldValue('start_date')]);

  useEffect(() => {
    setStartDateSelected(false);
    const modelFilter = models?.filter((model) => model?.id === form.getFieldValue('model_client_id'));
    setModelCreationDate(modelFilter?.[0]?.created_at);
  }, [!!form.getFieldValue('model_client_id')]);

  /**
   * Disables dates more than 14 months beyond model creation date
   * Disables dates that are not at least a month ahead
   * @param current
   * @returns {boolean}
   */
  const disabledEndDate: RangePickerProps['disabledDate'] = (current: Moment) => {
    // selection must be at least 1 month ahead of current month and no more than 14 months ahead of model creation date
    if (startDate) {
      return current?.diff(modelCreationDate,'months', true) > 15 || current?.diff(startDate,'months') < 1;
    }
    return false;
  };

  const currentDate = moment().toString();

  /**
   * Disables dates more than 14 months beyond model creation date
   * Disables dates that are not at least a month ahead
   * @param current
   * @returns {boolean}
   */
  const disabledStartDate: RangePickerProps['disabledDate'] = (current: Moment) => {
    // selection must be at least 1 month ahead of current month and no more than 14 months ahead of model creation date
    if (currentDate) {
      return current?.diff(currentDate,'months', true) < 1 || current?.diff(modelCreationDate,'months', true) > 14;
    }
    return false;
  };

  /**
     * Sets end date to undefined
     */
  const resetEndDate = () => {
    form.setFieldsValue({end_date: undefined});
    setLength(undefined);
  };

  /**
     * Stores selected date and resets end date each time start date is changed
     * @param date
     */
  const handleStartDateChange = (date: Moment) => {
    setStartDate(date);
    setStartDateSelected(!!date);
    resetEndDate();
  };

  /**
     * Updates length of the forecast
     * @param date
     */
  const handleEndDateChange = (date: Moment) => {
    setLength(date?.diff(startDate,'months') + 1);
  };

  return (
    <>
      <div className={css.dateRangeContainer}>
        <div className={css.dateRange}>
          <div className={css.date}>
            <FormItem name='start_date' label='Start Date' help='Select a start month' >
              <DatePicker
                disabled={!modelCreationDate}
                picker='month'
                disabledDate={disabledStartDate}
                onChange={(e:Moment) => handleStartDateChange(e)}
                format='MMMM YYYY'
              />
            </FormItem>
          </div>
          <div className={css.date}>
            <FormItem name='end_date' label='End Date' help='Select an end month' >
              <DatePicker
                disabled={!startDatedSelected && !isEdit}
                picker='month'
                disabledDate={disabledEndDate}
                onChange={(e:Moment) => handleEndDateChange(e)}
                format='MMMM YYYY'
              />
            </FormItem>
          </div>
        </div>
      </div>
    </>
  );
};

export default DateRange;
