import React, { memo } from 'react';
import { Carousel as NebulaCarousel, CarouselProps as NebulaCarouselProps } from '@sprnova/nebula';

const Carousel = ({ slides, ...props }: NebulaCarouselProps): JSX.Element => {
  const controls = slides.length > 1;
  return (
    <NebulaCarousel
      width='auto'
      sx={{
        flex: 1,
        '& > .slick-slider': {
          display: 'flex',
          alignItems: 'flex-end',
        },
        '& .slick-dots': {
          width: 'auto'
        },
        '& .slick-slide > div > .MuiBox-root > .MuiBox-root > div': {
          padding: 0,
        }
      }}
      height='fit-content'
      slides={slides}
      // hide arrows and dots if there's only one carousel
      arrows={controls}
      dots={controls}
      {...props}
    />
  );
};

export default memo(Carousel);
