/**
 * SelectService
 */

import React, { forwardRef } from 'react';
import { Projection } from 'api/entityGraphQL';
import { useQueryParam } from 'use-query-params';
import { Service, useServices } from 'features/entitiesRedux';
import { pricingVersionString } from 'features/library/constants';
import Select, { Props as SelectProps } from '../Select/Select';
import css from './SelectService.module.scss';

type SelectServiceProps = SelectProps & {
  showDepartment?: boolean;
  departmentId?: number;
  pricing_version?: string;
  projection?: Projection
};
const SelectService = forwardRef(
  (
    {
      skeleton,
      showDepartment = false,
      departmentId,
      pricing_version = 'hourly',// default query param to hourly
      projection,
      ...props
    }: SelectServiceProps,
    ref: any
  ) => {
    const [pricingVersionQueryParam, setPricingVersionQueryParam] = useQueryParam<string>(pricingVersionString);
    if(!pricingVersionQueryParam) {
      setPricingVersionQueryParam('hourly'); // default query param to hourly
    }
    const { services, loading: fetching } = useServices({
      department_id: departmentId,
      pricing_version: pricingVersionQueryParam || pricing_version,
      projection: projection || {
        name: true,
        id: true,
        department: {
          id: true,
          name: true
        }
      }
    });
    const loading = fetching && !services.length;

    return (
      <div className={css.root}>
        <Select
          showSearch
          optionFilterProp="children"
          skeleton={loading || skeleton}
          filterOption={(input, option) =>
            option?.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          ref={ref}
          {...props}
        >
          {services?.map((service: Partial<Service>) => (
            <Select.Option
              key={service.id}
              value={service.id || ''}
              className={css.selectServiceOption}
            >
              {service.name}
              {showDepartment && service?.department && (
                <>
                  {' '}
                  <small className={css.muted}>
                    {service.department?.name}
                  </small>
                </>
              )}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  }
);
SelectService.displayName = 'SelectService';

export default SelectService;
