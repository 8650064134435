/**
 * Function to export data as a CSV file.
 * @param {string | string[]} data - The data to be exported. Can be a string or an array of strings.
 * @param {string} moduleName - The name of the module from which the data is being exported.
 * @param {string} [clientName] - The name of the client for whom the data is being exported. Optional.
 * @param {string} [saveTitle] - The name of the exported file. Default exists. Optional.
 * @returns {void}
 */
type ExportCSVProps = {
  data: (string | string[])[];
  moduleName: string;
  clientName?: string;
  saveTitle?: string;
};

const exportCSV = async ({
  data,
  moduleName,
  clientName,
  saveTitle,
}: ExportCSVProps): Promise<void> => {
  try {

    // Create a new anchor element
    const element = document.createElement('a');
    const rows = Array.isArray(data) ? data.join('\n') : data;
    const encodedUri = encodeURIComponent(rows);
    const date = `${new Date().toLocaleDateString('en-us', {})}`;

    // Set the href of the anchor element to the encoded URI, specifying that the data is in CSV format and UTF-8 character set
    element.href = `data:text/csv;charset=utf-8,${encodedUri}`;

    // Set the download attribute of the anchor element to the desired filename, which includes the client name (if provided), module name, and date
    element.download = saveTitle ? `${saveTitle}.csv` : `${clientName ? `${clientName}'s ${moduleName}` : `${moduleName}`} ${date}.csv`;

    // Append the anchor element to the body of the document
    document.body.appendChild(element);

    // Simulate a click on the anchor element to start the download
    element.click();

    // Remove the anchor element from the body of the document after the download has started
    document.body.removeChild(element);

  } catch (error) {
    console.error('error exporting csv: ', error);
  }
};

export default exportCSV;
