/**
 * Blueprints -> Review List -> Header
 */

import React from 'react';
import classnames from 'classnames';
import listCss from 'features/strategies/StrategiesListPage/components/StrategiesList/components/Row/Row.module.scss';
import { BlockList } from 'components';
import css from '../Row/Row.module.scss';

const BlueprintsReviewListHeader = (): JSX.Element => {
  const items = [
    {
      label: 'Company',
      className: classnames(listCss.column, listCss.column__client),
      alignLeft: true,
    },
    {
      label: 'Date Created',
      className: classnames(listCss.column, listCss.column__createdAt),
    },
    {
      label: 'Avg. GP',
      className: classnames(listCss.column, listCss.column__avggp),
    },
    {
      label: 'Length',
      className: classnames(listCss.column, listCss.column__length),
    },
    {
      label: '',
      className: classnames(listCss.column, css.column__buttons),
    },
  ];

  return <BlockList.Header items={items} />;
};

export default BlueprintsReviewListHeader;