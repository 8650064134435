/**
* Get Playbook platform calculated metrics
*/

import { baseApi } from 'api/crudGraphQL/baseApi';
import { Projection } from 'api/entityGraphQL';
import tagTypes from './tagTypes';

export type GetPlaybookPlatformCalculatedMetricsQuery = {
  name?: string;
  formula?: string;
  platform_slug?: string;
  projection?: Projection;
};

export type GetPlaybookPlatformCalculatedMetricsResult = {
  name: string;
  formula: string;
  platform_slug: string;
}[];

export const GET_PLAYBOOK_PLATFORM_CALCULATED_METRICS = 'getPlaybookPlatformCalculatedMetrics';

export const { useGetPlaybookPlatformCalculatedMetricsQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_PLAYBOOK_PLATFORM_CALCULATED_METRICS]: build.query<GetPlaybookPlatformCalculatedMetricsResult, GetPlaybookPlatformCalculatedMetricsQuery>({
      transformResponse: (response: { playbook_platform_calculated_metrics: GetPlaybookPlatformCalculatedMetricsResult }) => response.playbook_platform_calculated_metrics,
      providesTags: (result) => {
        return [tagTypes.PLAYBOOK_PLATFORM_CALCULATED_METRICS];
      },
      query: ({
        projection = {
          id: false,
          name: true,
          formula: true,
          platform_slug: true
        },
      }) => {

        return {
          body: {
            query: {
              playbook_platform_calculated_metrics: {
                ...projection
              }
            }
          }
        };
      }
    }),
  }),
});
