import React, { FC } from 'react';
import { Hero, HeroProps } from '@sprnova/nebula';
import { Breadcrumbs } from '../Breadcrumbs';

export interface PageHeroProps extends HeroProps {
  hideBreadcrumbs?: boolean;
  hideHome?: boolean;
}

export const PageHero: FC<PageHeroProps> = ({
  hideBreadcrumbs = false,
  hideHome = false,
  ...heroProps
}) => {
  const breadcrumbs = !hideBreadcrumbs ? <Breadcrumbs hideHome={hideHome} /> : null;

  return (
    <Hero breadcrumbs={breadcrumbs} {...heroProps} />
  );
};
