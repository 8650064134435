import React from 'react';
import loadable from '@loadable/component';
import { Skeleton } from 'antd';
import { useGetPlaybookCampaignsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookCampaigns';

const CreativePlaybookCampaigns = loadable(
  () => import('./CreativePlaybookCampaigns')
);

const Title = (): JSX.Element => {
  const { data: playbookCampaignsData, isLoading } = useGetPlaybookCampaignsQuery({
    projection: {id: true}
  }, {refetchOnMountOrArgChange: true});

  if (!playbookCampaignsData || isLoading) {
    return <Skeleton title={{ width: 200 }} paragraph={false} active />;
  }

  return <>Campaigns</>;

};

Title.displayName = 'CreativePlaybookCampaignsTitle';

export default Object.assign(CreativePlaybookCampaigns, { Title });
