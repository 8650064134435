/**
 * Get parnters
 */

import { baseApi, cleanArguments, paginationProjection } from 'api/crudGraphQL';
import { Pagination, Projection } from 'api/entityGraphQL';
import { Partner } from 'features/entitiesRedux';
import tagTypes from './tagTypes';

export type GetPartnersQuery = {
  id?: number;
  search?: string;
  company?: string;
  status?: string;
  name?: string;
  page?: number;
  limit?: number;
  sort?: string[];
  projection?: Projection;
};

export type GetPartnersResult = Pagination & {
  data: Partner[]
};

export const GET_PARTNERS_ENDPOINT_KEY = 'getPartners';

export const { useGetPartnersQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_PARTNERS_ENDPOINT_KEY]: build.query<GetPartnersResult, GetPartnersQuery>({
      transformResponse: (response: { partners: GetPartnersResult }) => response.partners,
      providesTags: (result) => {
        const { data } = result ?? { data: [] };

        return [
          tagTypes.PARTNERS,
          ...data.map((partner: Partner) => ({
            id: partner.id,
            type: tagTypes.PARTNER
          }))
        ];
      },
      query: ({
        projection = { id: true, name: true },
        page = 1,
        limit = 10,
        name,
        ...args
      }) => {
        const __args: Partial<GetPartnersQuery> = {
          page,
          limit,
          ...args,
        };

        if (name) {
          __args.name = `*${name}*`;
        }

        return {
          body: {
            query: {
              partners: {
                __args: cleanArguments(__args),
                data: projection,
                ...paginationProjection
              }
            }
          }
        };
      }
    })
  })
});
