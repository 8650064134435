import React from 'react';

interface CheckmarkIconProps {
  className?: string;
}

export const CheckmarkIcon = ({
  className
}: CheckmarkIconProps): JSX.Element => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 13 12"
    className={className}
  >
    <g id="Group_3597" transform="translate(-775 -405)">
      <g id="Group_3529" transform="translate(718.943 405)">
        <path
          stroke="currentColor"
          style={{ fill: 'none', strokeWidth: 2 }}
          d="M57.7,6.1l3.2,3.1l6.5-6.4"
        />
      </g>
    </g>
  </svg>
);
