import React from 'react';
import loadable from '@loadable/component';

const LoadableComponent: any = loadable(
  () => import('./EditOutsourceCostGuidePage')
);

const Title = (): JSX.Element => {
  return <>Edit Outsource Cost Guide</>;
};

LoadableComponent.Title = Title;

export { LoadableComponent as EditOutsourceCostGuidePage };
