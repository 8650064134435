import loadable from "@loadable/component";

const LoadableComponent = loadable(
  () => import("./ViewPresentationPage")
  /* , {
  fallback: <div>Loading...</div>,
  } */
);

export { LoadableComponent as ViewPresentationPage };
