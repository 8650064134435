import React, { forwardRef } from 'react';
import { Switch as AntdSwitch } from 'antd';
import classNames from 'classnames';
import { YesNo } from '../../features/entitiesRedux/models';
import { SkeletonInput } from '../SkeletonInput';
import css from './YesNoSwitch.module.scss';

interface Props {
  value?: YesNo;
  onChange?: (value: YesNo) => any;
  className?: string;
  skeleton?: boolean;
  checked?: boolean;
  disabled?: boolean;
}

const YesNoSwitch = forwardRef(
  ({ value = 'no', onChange, className, checked, skeleton = false, ...props }: Props, ref: any) => {
    // Show skeleton when loading is true
    if (skeleton) return <SkeletonInput loading={false} active={true} />;

    const handleOnChange = (value:boolean) => {
      if (onChange === undefined) { return; }
      if (value) {
        onChange('yes');
      }
      if (!value) {
        onChange('no');
      }
    };

    return (
      <>
        <AntdSwitch
          {...props}
          className={classNames(className, css.root)}
          onChange={handleOnChange}
          checked={value === 'yes'}
          ref={ref}
        />
      </>
    );
  }
);

YesNoSwitch.displayName = 'YesNoSwitch';

export default YesNoSwitch;
