export const chatHistoryProjection = {
  prompt: {
    created_at: true,
    guid: true,
    text: true,
  },
  responses: {
    created_at: true,
    ended_at: true,
    displayed_at: true,
    error: true,
    feedback: true,
    json_response: true,
    prompt_guid: true,
    prompt_text: true,
    response: true,
    session_guid: true,
    status: true,
    version: true,
  }
};