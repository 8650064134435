/**
 * This hook is responsible for creating the sidebar menu for the Nebula layout
 */

import React, { ReactNode, useMemo } from 'react';
import {
  NavigationBoltFilledIcon,
  NavigationBoltIcon,
  NavigationBookFilledIcon,
  NavigationBookIcon,
  NavigationChartFilledIcon,
  NavigationChartIcon,
  NavigationContactBookFilledIcon,
  NavigationContactBookIcon,
  NavigationHandshakeFilledIcon,
  NavigationHandshakeIcon,
  NavigationLightbulbFilledIcon,
  NavigationLightbulbIcon,
  NavigationMapFilledIcon,
  NavigationMapIcon,
  NavigationTableChartFilledIcon,
  NavigationTableChartIcon,
  NavigationUserGroupFilledIcon,
  NavigationUserGroupIcon,
  NavigationPlantIcon,
  NavigationPlantFilledIcon,
} from '@sprnova/nebula';
import { useGetIntelligenceProductsQuery } from 'api/crudGraphQL/intelligence/products/getIntelligenceProducts';
import { useTalentSidebarMenu } from 'layouts/TalentLayout/components/Sidebar/useTalentSidebarMenu';
import { useHasAccessContractAiAndReporting } from 'utils/hooks/blueprints/useHasAccessContractAiAndReporting';
import { usePowerViewMenuItems } from 'features/ClientCentral/usePowerViewMenuItems';
import { useAccount } from 'features/global/hooks/useAccount';

export const useNovaSidebarMenu = (): {
  sections: {
    legend: string;
    items: {
      label: string;
      icon: ReactNode;
      iconFilled: ReactNode;
      path: string;
      dropdownItems?: {
        label: ReactNode;
        path: string;
        disabled?: boolean;
        hidden?: boolean;
      }[];
    }[];
  }[];
  isLoading: boolean;
} => {
  const { isLoading: isLoadingAccount } = useAccount();

  const hasAccessContractAiAndReporting = useHasAccessContractAiAndReporting();

  /**
   * Pull in navigation items from other parts of the app
   * to populate the nav item dropdowns
   */

  // Talent
  const { sections: talentSections, isLoading: isLoadingTalentItems} = useTalentSidebarMenu();
  const talentMenuItems = useMemo(() => talentSections.reduce<{
    label: ReactNode,
    path: string,
    disabled?: boolean;
  }[]
  >((acc, { items = [] }) => acc.concat(items), []), [talentSections]);

  // Intelligence
  const {
    data: intelligenceItems = [],
    isLoading: isLoadingIntelligenceItems,
  } = useGetIntelligenceProductsQuery({
    projection : {
      id: true,
      name: true,
      slug: true,
      is_authorized: true,
    }
  });

  const intelligenceMenuItems = useMemo(() => intelligenceItems.map(({ name, slug, is_authorized }) => ({
    label: name,
    path: `/intelligence/${slug}`,
    disabled: is_authorized === 'no',
  })), [intelligenceItems]);

  // Power View
  const powerViewItems = usePowerViewMenuItems();
  const powerViewMenuItems = useMemo(() => powerViewItems.map(({ name, path, is_authorized }) => ({
    label: name,
    path,
    disabled: !is_authorized,
  })), [powerViewItems]);

  /**
   * Nav sections
   */
  const sections = useMemo(() => {
    const navSections = [
      {
        legend: 'Analysis',
        items: [
          {
            label: 'Appraisals',
            icon: <NavigationChartIcon />,
            iconFilled: <NavigationChartFilledIcon />,
            path: '/appraisals',
          },
          {
            label: 'Intelligence',
            icon: <NavigationLightbulbIcon />,
            iconFilled: <NavigationLightbulbFilledIcon />,
            path: '/intelligence',
            dropdownItems: intelligenceMenuItems,
          },
        ]
      },
      {
        legend: 'Strategy',
        items: [
          {
            label: 'Blueprints',
            icon: <NavigationMapIcon />,
            iconFilled: <NavigationMapFilledIcon />,
            path: '/blueprints',
            dropdownItems: [
              {
                label: 'Package Pricing',
                path: '/blueprints/package'
              },
              {
                label: 'Hourly Pricing',
                path: '/blueprints/hourly'
              },
              {
                label: 'Contract AI',
                path: '/blueprints/contract-ai',
                hidden: !hasAccessContractAiAndReporting,
              },
              {
                label: 'Blueprint Reporting',
                path: '/blueprints/reporting',
                target: '_blank',
                url: true,
                hidden: !hasAccessContractAiAndReporting,
              },
            ],
          },
          {
            label: 'Library',
            icon: <NavigationBookIcon />,
            iconFilled: <NavigationBookFilledIcon />,
            path: '/library',
          },
        ]
      },
      {
        legend: 'Client Central',
        items: [
          {
            label: 'Scoreboards',
            icon: <NavigationTableChartIcon />,
            iconFilled: <NavigationTableChartFilledIcon />,
            path: '/scoreboards',
          },
          {
            label: 'Power View',
            icon: <NavigationBoltIcon />,
            iconFilled:  <NavigationBoltFilledIcon/>,
            path: '/power-view',
            dropdownItems: powerViewMenuItems,
          },
        ]
      },
      {
        legend: 'People',
        items: [
          {
            label: 'Our Team',
            icon: <NavigationUserGroupIcon />,
            iconFilled: <NavigationUserGroupFilledIcon />,
            path: '/users',
          },
          {
            label: 'Talent',
            icon: <NavigationPlantIcon />,
            iconFilled: <NavigationPlantFilledIcon />,
            path: '/talent',
            dropdownItems: talentMenuItems,
          },
          {
            label: 'Partners',
            icon: <NavigationHandshakeIcon />,
            iconFilled: <NavigationHandshakeFilledIcon />,
            path: '/partners',
          },
          {
            label: 'Contacts',
            icon: <NavigationContactBookIcon />,
            iconFilled: <NavigationContactBookFilledIcon />,
            path: '/contacts',
          },
        ]
      },
    ];

    return navSections;
  }, [intelligenceMenuItems, hasAccessContractAiAndReporting, powerViewMenuItems, talentMenuItems]);

  /**
  * So we can show skeleton until everyting is loaded and ready
  */
  const isLoading = isLoadingAccount || isLoadingTalentItems || isLoadingIntelligenceItems;

  return {
    sections,
    isLoading
  };
};
