import React from 'react';
import { useParams } from 'react-router-dom';
import loadable from '@loadable/component';
import { Skeleton } from 'antd';
import { useGetHorizonQuery } from 'api/crudGraphQL/horizons/getHorizon';

const HistoricalOverviewPage = loadable(
  () => import('./HistoricalOverviewPage')
);

const Title = (): JSX.Element => {
  const { id } = useParams<{ [x: string]: string }>();
  const { data: horizon, isLoading } = useGetHorizonQuery({
    id: Number(id),
    projection: { id: true, name: true  }
  });

  if (!horizon || isLoading) {
    return <Skeleton title={{ width: 200 }} paragraph={false} active />;
  }

  return <>Historical Overview</>;

};

Title.displayName = 'HistoricalOverviewPageTitle';

export default Object.assign(HistoricalOverviewPage, { Title });
