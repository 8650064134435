/**
 * useRoles
 * Fetches all roles
 */

import { useState, useEffect } from 'react';
import { novaGraphQLClient, Projection } from 'api/entityGraphQL';
import { RoleType } from 'features/entitiesRedux';

type Type = {
  loading: boolean;
  error: string;
  role_types: RoleType[];
}
type Props = {
  type?: string;
  types?: string[];
  slugs?: string[];
  projection?: Projection;
}

export const useRoles: ({ type, types, slugs, projection }: Props) => Type = ({ type, types, slugs, projection }: Props) => {
  const [role_types, setRoleTypes] = useState<RoleType[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { roles } = await novaGraphQLClient.fetchRoleTypes({
          type,
          types,
          slugs,
          projection
        });
        setError('');
        setRoleTypes(roles);
      } catch (error) {
        setError(error?.response?.errors[0]?.message || 'Unknown error');
      }
      finally {
        setLoading(false);
      }
    })();
  }, []);

  return { role_types, loading, error };
};

export default useRoles;
