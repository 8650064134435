import { getClient } from 'api/entityGraphQL/utils';
import { formatCurrencyInput } from 'features/audits/utils';
import { LeadChannel } from '../../types';
import { QuestionHandler } from '../actions';

export const leadChannelsHandler: QuestionHandler = (id, key, value, additionalInfo): any => {
  const apiClient = getClient();
  if (Array.isArray(value)) {
    // filter out any empty field sets the user may have accidentally added
    const validChannels = value?.filter((channel) => {
      if (channel === undefined) return false;
      if (!channel.name && !channel.percent) return false;
      return !Object.values(channel).every((el) => el === undefined);
    }).map<LeadChannel>(
      (channel: LeadChannel): LeadChannel => {
        return {
          ...channel,
          percent: Number.parseFloat(
            formatCurrencyInput(channel?.percent || undefined)
          ),
        };
      }
    );

    // handle undefined/null values
    validChannels?.forEach((channel) => {
      if (!channel.name) channel.name = null;
      if (channel.percent === undefined) channel.percent = null;
    });

    return apiClient.saveDiscoveryLeadChannels({
      discovery_id: additionalInfo?.discoveryId as number,
      lead_channels: validChannels,
      delete_missing: 'yes',
    });
  }
};
