/**
* Update horizon
*/

import { baseApi, cleanArguments } from 'api/crudGraphQL';
import { Projection } from 'api/entityGraphQL/types';
import { Horizon } from 'features/entitiesRedux/models/horizon';
import tagTypes from './tagTypes';

export type UpdateHorizonMutation = {
  id: number;
  projection?: Projection;
  start_date: string;
  length: number;
  name?: string;
}

export type UpdateHorizonResult = Horizon;

export const UPDATE_HORIZON_ENDPOINT_KEY = 'updateHorizon';

export const { useUpdateHorizonMutation } = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    [UPDATE_HORIZON_ENDPOINT_KEY]: builder.mutation<UpdateHorizonResult, UpdateHorizonMutation>({
      transformResponse: (response: { updateHorizon: Horizon }) =>  response.updateHorizon,
      invalidatesTags: result => [
        result?.id ? { id: result.id, type: tagTypes.HORIZON } : tagTypes.HORIZON,
        tagTypes.HORIZONS
      ],
      query: ({ projection = {start_date: true, length: true}, ...values }) => (
        {
          body: {
            mutation: {
              updateHorizon: {
                __args: cleanArguments(values),
                ...projection,
                id: true
              }
            }
          }
        }
      )
    }),
  }),
});
