import React from 'react';

export const TalentReviewDashboardLightIcon = () => (
  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="51.5" y="37.5" width="39" height="23" rx="5" transform="rotate(-180 51.5 37.5)" fill="#D9D9D9"/>
    <rect x="98.5" y="37.5" width="39" height="23" rx="5" transform="rotate(-180 98.5 37.5)" fill="#E65957"/>
    <rect x="51.5" y="97.5" width="39" height="23" rx="5" transform="rotate(-180 51.5 97.5)" fill="#D9D9D9"/>
    <rect x="52.5" y="66.5" width="40" height="23" rx="5" transform="rotate(-180 52.5 66.5)" fill="#A94882"/>
    <rect x="99.5" y="97.5" width="40" height="23" rx="5" transform="rotate(-180 99.5 97.5)" fill="#FFA600"/>
    <rect x="98.5" y="66.5" width="39" height="23" rx="5" transform="rotate(-180 98.5 66.5)" fill="#D9D9D9"/>
    <circle cx="55.9761" cy="69.9761" r="6" transform="rotate(-47.6683 55.9761 69.9761)" fill="#4A1FFF"/>
    <circle cx="55.9761" cy="39.9761" r="6" transform="rotate(-47.6683 55.9761 39.9761)" fill="#4A1FFF"/>
  </svg>
);
