import { Typography, styled } from '@mui/material';

export const DataAccessRequestSubheader = styled(Typography)(() => ({
  'fontFamily': 'Inter',
  'fontSize': '14px',
  'fontWeight': 500,
}));

export const DatasourceSubheading = styled(Typography)(() => ({
  'fontFamily': 'Inter',
  'fontSize': '12px',
  'fontWeight': 400,
  'color': '#838383',
}));


export const DatasourceTitle = styled(Typography)(() => ({
  'fontFamily': 'Inter',
  'fontSize': '20px',
  'fontWeight': 600,
  'whiteSpace': 'nowrap'
}));

export const DataAccessRequestMenuItemText = styled(Typography)(() => ({
  'fontFamily': 'Inter',
  'fontSize': '14px',
  'fontWeight': 600,
  'color': '#4A1FFF'
}));

export const MenuItemLabel = styled(Typography)(() => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 600,
  color: '#4A1FFF',
}));
