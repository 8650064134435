import React, { FC, useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppNav, AppSidebar } from '@sprnova/nebula';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { SidebarSkeleton } from 'layouts/components';
import { AppsDropdown } from 'layouts/components/AppsDropdown';
import { useAccount } from 'features/global';
import { AppItemSubMenu } from './components';
import { useNovaSidebarMenu } from './useNovaSidebarMenu';

export const Sidebar: FC = () => {
  const { account } = useAccount();
  const { pathname: currentPath } = useLocation();
  const { sections, isLoading } = useNovaSidebarMenu();

  /**
   * Mixpanel event tracking
   */
  const mixpanel = useMixpanel();
  const trackSidebarLinkClick = useCallback((name: string) => {
    try {
      if (!mixpanel) return;
      const mixpanelTitle = `Sidebar - ${name} Clicked`;
      const options = {
        userId: account?.id,
        userName: account?.name,
      };
      mixpanel.track(mixpanelTitle, options);
      if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
    } catch (error) {
      console.error('Track Mixpanel error', error);
    }
  }, [mixpanel, account?.id, account?.name]);

  const renderNavSections = useMemo(() =>  sections.map(({ legend, items }) => (
    <AppNav.Section label={legend} key={legend}>
      {items.map(({ dropdownItems, label, icon, iconFilled, path }) => {
        /**
         * App item with dropdown items
         */
        if (dropdownItems?.length) {
          return (
            <AppItemSubMenu
              key={path}
              icon={icon}
              iconFilled={iconFilled}
              items={dropdownItems}
              component={Link}
              path={path}
              onClick={(): void => trackSidebarLinkClick(label)}
            >
              {label}
            </AppItemSubMenu>
          );
        }

        return (
          <AppNav.Item
            key={path}
            component={Link}
            to={path}
            icon={icon}
            iconFilled={iconFilled}
            isActive={currentPath.includes(path)}
            onClick={(): void => trackSidebarLinkClick(label)}
          >
            {label}
          </AppNav.Item>
        );
      }
      )}
    </AppNav.Section>
  )), [currentPath, sections, trackSidebarLinkClick]);

  return (
    <AppSidebar
      logo={
        <AppSidebar.Logo component={Link} to="/dashboard" title="Go home" />
      }
      renderTopEnd={
        <AppsDropdown />
      }
    >
      {
        isLoading ? (
          <SidebarSkeleton
            sections={
              [
                [80, [80, 80]],
                [80, [105, 55]],
                [120, [70, 140, 120]],
                [80, [90, 80, 90]],
              ]
            }
          />
        ) : (
          <AppNav>
            {renderNavSections}
          </AppNav>
        )
      }
    </AppSidebar>
  );
};
