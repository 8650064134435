import React from 'react';

export const PackagePricingIcon = () => (
  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="104.389" y="89.4385" width="16" height="116.715" rx="8" transform="rotate(132.578 104.389 89.4385)"
          fill="#D9D9D9"/>
    <rect x="103.182" y="58.5674" width="16" height="70.7602" rx="8" transform="rotate(132.578 103.182 58.5674)"
          fill="#D9D9D9"/>
    <rect x="71.2661" y="90.7773" width="16" height="63.4417" rx="8" transform="rotate(132.578 71.2661 90.7773)"
          fill="#D9D9D9"/>
    <rect x="26.2725" y="86.2715" width="60" height="60" rx="10" transform="rotate(-90.2601 26.2725 86.2715)"
          fill="#FFA600"/>
    <rect x="41.1362" y="71.1357" width="30" height="30" rx="10" transform="rotate(-90.2601 41.1362 71.1357)"
          fill="#A94882"/>
    <circle cx="92" cy="23" r="8" transform="rotate(-90 92 23)" fill="#4A1FFF"/>
    <circle cx="20" cy="91" r="8" transform="rotate(-90 20 91)" fill="#4A1FFF"/>
    <circle cx="19" cy="22" r="8" transform="rotate(-180 19 22)" fill="#4A1FFF"/>
    <circle cx="93" cy="90" r="8" transform="rotate(180 93 90)" fill="#4A1FFF"/>
  </svg>

);
