export enum Role {
  // Auth0 roles
  internal = 'internal',
  external = 'external',
  // Legacy roles
  accountDirector = 'account-director',
  csAccountDirector = 'cs-account-director',
  amSpecialist = 'am-specialist',
  accountDirectors = 'account-directors',
  accounting = 'accounting',
  accountManagers = 'account-managers',
  csAccountManager = 'cs-account-manager',
  accountMeasurement = 'account-measurement',
  analystSurveyAdmin = 'analyst-survey-admin',
  analystSurveyEditor = 'analyst-survey-editor',
  anonymous = 'anonymous',
  baseReportingExternal = 'base-reporting-external',
  blueprintsAdmin = 'blueprints-admin',
  blueprintsOverride = 'blueprints-override', // secondary role
  blueprintsReview = 'blueprints-review',
  cLevel = 'c-level',
  cohortsAdmin = 'cohorts-admin',
  contractor = 'contractor',
  client = 'client',
  clientServices = 'client-success', // is named "client-services" now in the database and in the UI but we are keeping the same slug
  customerInsightsAdmin = 'customer-insights-admin',
  customReportingExternal = 'custom-reporting-external',
  dataAnalytics = 'data-analytics',
  dataIntelligenceAdmin = 'data-intelligence-admin',
  departmentDirectors = 'department-directors',
  forecastsAdmin = 'forecasts-admin',
  groupDirector = 'group-director',
  csGroupDirector = 'cs-group-director',
  humanResources = 'human-resources',
  novaAiExternal = 'nova-ai-external',
  novaAiInternal = 'nova-ai-internal',
  novaAiSuperAdmin = 'nova-ai-super-admin',
  newHire = 'new-hire',
  operationsAdmin = 'operations-admin',
  partner = 'partner',
  retoolUser = 'retool-user',
  salesPersons = 'sales-persons',
  standardReportingExternal = 'standard-reporting-external',
  strategists = 'strategists',
  superAdmin = 'super-admin',
  talentCLevel = 'talent-c-level',
  talentDepartmentDirector = 'talent-department-director',
  talentSuperAdmin = 'talent-super-admin',
  talentVpLevel = 'talent-vp-level',
  vpLevel = 'vp-level',
  externalProviderVerification = 'external-provider-verification',
}
