if (!import.meta.env.REACT_APP_NODE_ENV) {
  throw new Error('.env configuration not defined');
}

export const config = {
  nodeEnv: import.meta.env.REACT_APP_NODE_ENV,
  host: import.meta.env.REACT_APP_HOST,
  platform: {
    api: {
      version: import.meta.env.REACT_APP_PLATFORM_API_VERSION || 'v1',
      host: import.meta.env.REACT_APP_PLATFORM_API_HOST || '',
      token: import.meta.env.REACT_APP_PLATFORM_API_TOKEN || '',
    },
    sentry: {
      dsn: import.meta.env.REACT_APP_SENTRY_DSN,
    },
  },
  prospect: {
    api: {
      version: import.meta.env.REACT_APP_PROSPECT_API_VERSION || 'v1',
      host: import.meta.env.REACT_APP_PROSPECT_API_HOST || '',
    }
  },
  blackbox: {
    url: import.meta.env.REACT_APP_BLACKBOX_URL,
    presentation_url: import.meta.env.REACT_APP_BLACKBOX_PRESENTATION_URL,
  },
  auth0: {
    domain: import.meta.env.REACT_APP_AUTH0_DOMAIN || '',
    // domain: import.meta.env.REACT_APP_NODE_ENV === 'development.local' ? import.meta.env.REACT_APP_AUTH0_DOMAIN as string : import.meta.env.REACT_APP_HOST as string,
    clientId: import.meta.env.REACT_APP_AUTH0_CLIENT_ID || '',
    audience: import.meta.env.REACT_APP_AUTH0_AUDIENCE || '',
    namespace: import.meta.env.REACT_APP_AUTH0_NAMESPACE || '',
  }
};

// Attach to global window to debug deployments
(window as any).NOVA_CONFIG = config;

// console.log("⚙️ config", config);
