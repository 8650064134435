import { getClient } from 'api/entityGraphQL/utils';
import { QuestionHandler } from '../actions';

export const consideredServicesHandler: QuestionHandler = (id, key, value, additionalInfo): any => {
  if (Array.isArray(value)) {
    return getClient().updateDiscoveryConsideredServices({
      discovery_id: additionalInfo?.discoveryId as number,
      considered_services: value,
    });
  }
};
