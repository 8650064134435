/**
 * Get categories (public)
 */
import { basePublicApi } from '../basePublicApi';
import tagTypes from './tagTypes';

export type Category = {
  category: string;
};

export type GetCategoriesPublicQuery = Record<string, never>;

export type GetCategoriesPublicResult = Category[]

export const GET_CATEGORIES_PUBLIC_ENDPOINT_KEY = 'getCategoriesPublic';

export const { useGetCategoriesPublicQuery } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_CATEGORIES_PUBLIC_ENDPOINT_KEY]: build.query<GetCategoriesPublicResult, GetCategoriesPublicQuery>({
      transformResponse: (response: { indicator_categories: GetCategoriesPublicResult }) => response.indicator_categories,
      providesTags: (result) => {
        return [tagTypes.CATEGORIES];
      },
      query: ({
        projection = { id: false, category: true },
      }) => {

        return {
          body: {
            query: {
              indicator_categories: {
                ...projection,
              }
            }
          }
        };
      }
    })
  })
});
