import React, { memo, useReducer } from 'react';
import { Controller, ControllerProps, FormGroup, InfoTooltip, SortableList, Switch, Typography, useFieldArray } from '@sprnova/nebula';
import ListTooltipContent from './ListTooltipContent';
import { IntegrationType } from '../types';

type ListProps = {
  control: ControllerProps['control'];
  name: string;
  platformData?: Record<string, IntegrationType[]>;
};
const List = ({ control, name, platformData }: ListProps): JSX.Element => {
  const { fields: items, move } = useFieldArray({
    control,
    name,
  });
  const [collapsed, toggleCollapsed] = useReducer((bool) => !bool, !items.some((item: Record<string, unknown>) => item.is_active));

  return (
    <FormGroup
      id={`${name}-collapsible`}
      collapsed={collapsed}
      onCollapseChange={toggleCollapsed}
      label={
        <Typography variant='h3'>
          {name}
        </Typography>
      }
      sx={{
        '> div': {
          display: 'flex',
          flexDirection: 'row-reverse',
          '> div:nth-of-type(1)': {
            paddingTop: '10px'
          },
          '> div:nth-of-type(2)': {
            width: '100%'
          }
        },
      }}
    >
      <SortableList
        id='List-sortable-list'
        items={items}
        onSortEnd={(oldIndex: number, newIndex: number): void => move(oldIndex, newIndex)}
      >
        {items.map((item: Record<string, string>, index: number) => (
          <SortableList.Item
            key={item.id}
            id={item.id}
            end={
              <Controller
                name={`${name}.${index}.is_active`}
                control={control}
                render={({ field }): JSX.Element => <Switch checked={field.value} {...field} />}
              />
            }
          >
            {item.label}
            &nbsp;
            <InfoTooltip
              heading={item.label}
              content={
                <ListTooltipContent
                  label={item.label}
                  tooltip={item.tooltip}
                  formula={item.equation}
                  platform={platformData || {}}
                  requiredPlatformCategory={item.required_platform_category}
                />
              }
            />
          </SortableList.Item>
        ))}
      </SortableList>
    </FormGroup>
  );
};

export default memo(List);
