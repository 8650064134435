interface Args {
  input: string;
  limit: number;
  ellipsis?: boolean;
}

const limitChars = ({ input, limit, ellipsis = true }: Args): string => {
  const end = ellipsis ? '...' : '';
  if (input.length > limit) {
    return input.substring(0, limit).trim() + end;
  } else {
    return input;
  }
};

export default limitChars;