/**
 * Entities -> Strategies -> Actions
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { novaGraphQLClient, Projection } from 'api/entityGraphQL';
import { normalizeEntities } from 'features/entitiesRedux/utils';
import { TaskInput } from '../task';
import {
  ActionFetchManyPaginatedConfig,
  ActionFetchPaginatedResponse,
} from '../types';
import { User } from '../user';
import { selectStrategyById } from './selectors';
import {
  Strategy,
  StrategyMonth,
  StrategyCost,
  StrategyTask,
  FetchStrategiesFilter,
  StrategyFormValues,
  StrategyDuplicateValues,
} from './strategy';

/**
 * Helpers
 */

// Returns a strategy with an updated or removed array sub-value (e.g. costs or tasks)
// This is used to update a strategy in the store via. the entitiesSlice when a sub-value updates
// Alternatively, this could (and should probably) be done in the reducer, but this works with the existing pattern
const getStrategyWithUpdatedSubValue: (
  strategy_id: number,
  id: any,
  key: string,
  update: object | null,
  getState: Function
) => Partial<Strategy> | null = (strategyId, id, key, update, getState) => {
  const strategy = selectStrategyById(strategyId)(getState());
  if (strategy) {
    const index = strategy[key].findIndex(
      (data: { id: number }) => data.id === id
    );
    let updated = [...strategy[key]];
    if (update === null) {
      updated = updated.filter((data: { id: number }) => data.id !== id);
    } else {
      updated[index] = { ...updated[index], ...update };
    }
    return { id: strategyId, [key]: updated };
  }

  return null;
};

/** Fetch Many */
export const fetchStrategiesPaginated = createAsyncThunk(
  'entities/fetchStrategiesPaginated',
  async ({
    ...config
  }: ActionFetchManyPaginatedConfig & {
    filter?: FetchStrategiesFilter;
  }): Promise<ActionFetchPaginatedResponse> => {
    const {
      strategies,
      ...pagination
    } = await novaGraphQLClient.fetchStrategies(config);
    return { ...normalizeEntities({ strategies }), pagination };
  }
);

/** Fetch one */
export const fetchStrategyById = createAsyncThunk(
  'entities/fetchStrategyById',
  async ({ id, ...config }: { id: number; projection?: Projection }) => {
    const { strategies } = await novaGraphQLClient.fetchStrategyById(id, {
      ...config,
    });
    return { ...normalizeEntities({ strategies }) };
  }
);

/** Create */
export const createStrategy = createAsyncThunk(
  'entities/createStrategy',
  async (values: StrategyFormValues) => {
    const response = await novaGraphQLClient.createStrategy(values);
    return { ...normalizeEntities(response) };
  }
);

/** Duplicate */
export const createStrategyDuplicate = createAsyncThunk(
  'entities/createStrategyDuplicate',
  async (values: StrategyDuplicateValues) => {
    const strategy = await novaGraphQLClient.createStrategyDuplicate(values);
    return { ...normalizeEntities({ strategies: [strategy] }) };
  }
);

/** Delete strategy tasks */
export const deleteStrategyTasks = createAsyncThunk(
  'entities/strategies/deleteStrategyTasks',
  async (
    {
      task_ids,
      strategy_id,
    }: {
      task_ids: number[];
      strategy_id: number;
    },
    {
      getState,
    }: {
      getState: any;
    }
  ) => {
    try {
      await novaGraphQLClient.deleteStrategyTasks({
        task_ids,
        strategy_id,
      });

      // Delete the task(s) from the strategy
      const updatedStrategy = getStrategyWithUpdatedSubValue(
        strategy_id,
        task_ids,
        'tasks',
        null,
        getState
      );
      if (updatedStrategy) {
        return { ...normalizeEntities({ strategies: [updatedStrategy] }) };
      }

      return { ...normalizeEntities({}) };
    } catch (error) {
      console.log('Error deleting strategy task(s)', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/* sync data */
export const salesforceDataToSync = createAsyncThunk(
  'entities/salesforceDataToSync',
  async (id: string) => {
    const response = await novaGraphQLClient.salesforceDataToSync(id);
    return { ...normalizeEntities(response) };
  }
);
/* sync */
export const syncNovaToSalesforce = createAsyncThunk(
  'entities/syncNovaToSalesforce',
  async (id: number) => {
    const response = await novaGraphQLClient.syncNovaToSalesforce(id);
    return { ...normalizeEntities(response) };
  }
);

/* Update */
export const updateStrategy = createAsyncThunk(
  'entities/updateStrategy',
  async (values: StrategyFormValues) => {
    const response = await novaGraphQLClient.updateStrategy(values);
    return { ...normalizeEntities(response) };
  }
);

/** Delete */
export const deleteStrategy = createAsyncThunk(
  'entities/deleteStrategy',
  async (id: number) => {
    const response = await novaGraphQLClient.deleteStrategy(id);
    return { ...normalizeEntities(response) };
  }
);

/** Update strategy month */
export const updateStrategyMonth = createAsyncThunk(
  'entities/strategies/updateStrategyMonth',
  async (values: Partial<StrategyMonth> & { id: number }, projection: any) => {
    const response = await novaGraphQLClient.updateStrategyMonth(
      values,
      projection
    );
    return { ...normalizeEntities(response) };
  }
);

/** Create strategy cost */
export const createStrategyCost = createAsyncThunk(
  'entities/strategies/createStrategyCost',
  async (
    values: Partial<StrategyCost> & {
      strategy_id: number;
      department_id: number;
    },
    {
      getState,
    }: {
      getState: any;
    }
  ) => {
    try {
      const strategyCost = await novaGraphQLClient.createStrategyCost(values);
      const strategy = selectStrategyById(values.strategy_id)(getState());
      const updatedStrategy = {
        ...strategy,
        costs: strategy.costs.concat(strategyCost),
      };
      return { ...normalizeEntities({ strategies: [updatedStrategy] }) };
    } catch (error) {
      console.log('Error creating strategy cost', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/** Update strategy cost */
export const updateStrategyCost = createAsyncThunk(
  'entities/strategies/updateStrategyCost',
  async (
    args: Partial<StrategyCost> & {
      id: number;
      strategy_id?: number;
    },
    {
      getState,
    }: {
      getState: any;
    }
  ) => {
    const { strategy_id, id, ...values } = args;

    try {
      const response = await novaGraphQLClient.updateStrategyCost({
        id,
        ...values,
      });

      // Update cost in state if strategy ID is provided
      if (strategy_id) {
        const updatedStrategy = getStrategyWithUpdatedSubValue(
          strategy_id,
          id,
          'costs',
          response,
          getState
        );
        if (updatedStrategy) {
          return { ...normalizeEntities({ strategies: [updatedStrategy] }) };
        }
      }

      return { ...normalizeEntities({}) };
    } catch (error) {
      console.log('Error updating strategy cost', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/** Update strategy cost month */
export const updateStrategyCostMonth = createAsyncThunk(
  'entities/strategies/updateStrategyCostMonth',
  async (
    {
      id,
      month_id,
      cost,
      strategy_id,
    }: {
      id: number;
      month_id: number;
      cost: number;
      strategy_id?: number;
    },
    {
      getState,
    }: {
      getState: any;
    }
  ) => {
    try {
      const response = await novaGraphQLClient.updateStrategyCostMonth({
        id,
        month_id,
        cost,
      });

      // Update cost in state if strategy ID is provided
      if (strategy_id) {
        const updatedStrategy = getStrategyWithUpdatedSubValue(
          strategy_id,
          id,
          'costs',
          response,
          getState
        );
        if (updatedStrategy) {
          return { ...normalizeEntities({ strategies: [updatedStrategy] }) };
        }
      }

      return { ...normalizeEntities({}) };
    } catch (error) {
      console.log('Error updating strategy cost month', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/** Create strategy task */
export const createStrategyTask = createAsyncThunk(
  'entities/strategies/createStrategyTask',
  async (
    values: Partial<StrategyTask> & {
      strategy_id: number;
      task_id: number;
      order: number;
    },
    {
      getState,
    }: {
      getState: any;
    }
  ) => {
    try {
      const strategy = await novaGraphQLClient.createStrategyTask(values);
      return { ...normalizeEntities({ strategies: [strategy] }) };
    } catch (error) {
      console.log('Error creating strategy task', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/* Create many strategy tasks */
export const createStrategyTasks = createAsyncThunk(
  'entities/strategies/createStrategyTasks',
  async (values: {
    id: number;
    tasks: Partial<TaskInput> & { id: number }[];
  }) => {
    try {
      const strategy = await novaGraphQLClient.createStrategyTasks(values);
      return { ...normalizeEntities({ strategies: [strategy] }) };
    } catch (error) {
      console.log('Error creating strategy tasks', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/** Delete strategy cost */
export const deleteStrategyCost = createAsyncThunk(
  'entities/strategies/deleteStrategyCost',
  async (
    {
      id,
      strategy_id,
    }: {
      id: number;
      strategy_id?: number;
    },
    {
      getState,
    }: {
      getState: any;
    }
  ) => {
    try {
      await novaGraphQLClient.deleteStrategyCost(id);

      // Remove cost from strategy if strategy ID is provided
      if (strategy_id) {
        const updatedStrategy = getStrategyWithUpdatedSubValue(
          strategy_id,
          id,
          'costs',
          null,
          getState
        );
        if (updatedStrategy) {
          return { ...normalizeEntities({ strategies: [updatedStrategy] }) };
        }
      }

      return { ...normalizeEntities({}) };
    } catch (error) {
      console.log('Error deleting strategy cost', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/** Update strategy task */
export const updateStrategyTask = createAsyncThunk(
  'entities/strategies/updateStrategyTask',
  async (
    args: Partial<StrategyTask> & {
      task_id: number;
      strategy_id: number;
    },
    {
      getState,
    }: {
      getState: any;
    }
  ) => {
    const { strategy_id, task_id, ...values } = args;

    try {
      const strategy = await novaGraphQLClient.updateStrategyTask({
        task_id,
        strategy_id,
        ...values,
      });

      return { ...normalizeEntities({ strategies: [strategy] }) };
    } catch (error) {
      console.log('Error updating strategy task', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/** Update strategy task month */
export const updateStrategyTaskMonth = createAsyncThunk(
  'entities/strategies/updateStrategyTaskMonth',
  async (
    {
      hours,
      month_id,
      strategy_id,
      task_id,
    }: {
      task_id: number;
      month_id: number;
      hours: number;
      strategy_id: number;
    },
    {
      getState,
    }: {
      getState: any;
    }
  ) => {
    const task = await novaGraphQLClient.updateStrategyTaskMonth({
      hours: Number(hours), //  Math.floor(hours),
      month_id,
      strategy_id,
      task_id,
    });

    const updatedStrategy = getStrategyWithUpdatedSubValue(
      strategy_id,
      task_id,
      'tasks',
      task,
      getState
    );

    if (updatedStrategy) {
      return { ...normalizeEntities({ strategies: [updatedStrategy] }) };
    }

    return { ...normalizeEntities({}) };
  }
);

/** Delete strategy task */
export const deleteStrategyTask = createAsyncThunk(
  'entities/strategies/deleteStrategyTask',
  async (
    {
      task_id,
      strategy_id,
    }: {
      task_id: number;
      strategy_id: number;
    },
    {
      getState,
    }: {
      getState: any;
    }
  ) => {
    try {
      await novaGraphQLClient.deleteStrategyTask({
        task_id,
        strategy_id,
      });

      // Delete the task from the strategy
      const updatedStrategy = getStrategyWithUpdatedSubValue(
        strategy_id,
        task_id,
        'tasks',
        null,
        getState
      );
      if (updatedStrategy) {
        return { ...normalizeEntities({ strategies: [updatedStrategy] }) };
      }

      return { ...normalizeEntities({}) };
    } catch (error) {
      console.log('Error deleting strategy task', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/** Delete strategy task */
export const updateStrategyTaskOrder = createAsyncThunk(
  'entities/strategies/updateStrategyTaskOrder',
  async (
    {
      strategy_id,
      tasks,
    }: {
      strategy_id: number;
      tasks: number[];
    },
    {
      getState,
    }: {
      getState: any;
    }
  ) => {
    try {
      const strategy = await novaGraphQLClient.updateStrategyTaskOrder({
        strategy_id,
        tasks,
      });

      return { ...normalizeEntities({ strategies: [strategy] }) };
    } catch (error) {
      console.log('Error updating strategy tasks order', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/** Create Strategy Proposal */
export const createStrategyProposal = createAsyncThunk(
  'entities/createStrategyProposal',
  async (
    args: {
      id: number;
      update_salesforce_stage?: number;
    }
  ) => {
    const strategy = await novaGraphQLClient.createStrategyProposal(args);
    return { ...normalizeEntities({ strategies: [strategy] }) };
  }
);

/** Sync Blueprint to Salesforce */
export const syncSalesforceToBlueprint = createAsyncThunk(
  'entities/syncSalesforceToBlueprint',
  async (id: number) => {
    const strategy = await novaGraphQLClient.syncSalesforceToBlueprint(id);
    return { ...normalizeEntities({ strategies: [strategy] }) };
  }
);

/** Create strategy user */
export const createStrategyUser = createAsyncThunk(
  'entities/strategies/createStrategyUser',
  async (
    args: {
      strategy_id: number;
      user_id: number;
      commission_percent: number;
    }, { dispatch }
  ) => {
    const response = await novaGraphQLClient.createStrategyUser(args);
    const strategyId = response?.strategies?.[0]?.id;
    if (strategyId !== undefined) {
      dispatch(fetchStrategyById({ id: strategyId }));
    }
    return { ...normalizeEntities({}) }; // normalizr schema shape expected by entitiesSlice reducer
  }
);

/** Update strategy user */
export const updateStrategyUser = createAsyncThunk(
  'entities/strategies/updateStrategyUser',
  async (
    args: Partial<User> & {
      strategy_id: number;
      user_id: number;
      commission_percent: number;
    }, { dispatch }
  ) => {
    const response = await novaGraphQLClient.updateStrategyUser(args);
    const strategyId = response?.strategies?.[0]?.id;
    if (strategyId !== undefined) {
      dispatch(fetchStrategyById({ id: strategyId }));
    }
    return { ...normalizeEntities({}) }; // normalizr schema shape expected by entitiesSlice reducer
  }
);

/** Delete strategy user */
export const deleteStrategyUser = createAsyncThunk(
  'entities/strategies/deleteStrategyUser',
  async (
    {
      strategy_id,
      user_id,
    }: {
      strategy_id: number;
      user_id: number;
    },
    {
      getState,
    }: {
      getState: any;
    }
  ) => {
    try {
      await novaGraphQLClient.deleteStrategyUser({ strategy_id, user_id });

      // Remove user from strategy if strategy ID is provided
      if (strategy_id) {
        const updatedStrategy = getStrategyWithUpdatedSubValue(
          strategy_id,
          user_id,
          'users',
          null,
          getState
        );
        if (updatedStrategy) {
          return { ...normalizeEntities({ strategies: [updatedStrategy] }) };
        }
      }

      return { ...normalizeEntities({}) };
    } catch (error) {
      console.log('Error deleting strategy user', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/** Create kickoff deck */
export const createKickoffDeck = createAsyncThunk(
  'entities/createKickoffDeck',
  async ({ strategy_id, integrations, users }:
    { strategy_id: number; integrations?: [number] | []; users?: number[] }) => {
    const response = await novaGraphQLClient.createKickoffDeck({ strategy_id, integrations, users });
    return { ...normalizeEntities(response) };
  }
);
