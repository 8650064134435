import React, { FC, memo } from 'react';
import { Tag } from '@sprnova/nebula';
import { InviteStatus } from '../../TeamOverview';

type InviteStatusCellProps = {
  status?: string;
};

const InviteStatusCell: FC<InviteStatusCellProps> = ({ status }) => {

  const statusToColorMap: Record<InviteStatus, 'green' | 'yellow'> = {
    [InviteStatus.Accepted]: 'green',
    [InviteStatus.Invited]: 'yellow',
  };

  return status ? (
    <Tag color={statusToColorMap[status as keyof typeof statusToColorMap]}>
      {status.toUpperCase()}
    </Tag>
  ) : <span>{'-'}</span>;
};

export default memo(InviteStatusCell);
