import React, {memo, useEffect, useMemo, useState} from 'react';
import { useParams } from 'react-router';
import { Button, Card, CardHeader, IconButton, PlusIcon, TrashIcon, TextField, Skeleton, Typography } from '@sprnova/nebula';
import { useGetTasksQuery } from 'api/crudGraphQL/tasks/getTasks';
import { AddAdditionalStrategyModal } from 'components/AddAdditionalStrategyModal';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { Task } from 'features/entitiesRedux';
import { Dropdown } from 'features/intelligence/components/library-components';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import { valuePresentStyles } from 'features/users/UserOverviewPage/components/constants';
import { tasksProjection } from './projection';
import { ActionType, FormDataType } from '../packageStrategyTypes';import css from './EditAdditionalStrategy.module.scss';

type TaskAtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>; // combine Partial and Pick to make some properties required
export type TaskIdRequiredType = Task & TaskAtLeast<Task, 'id'>;

interface EditAdditionalStrategyProps {
  task?: Task;
  loadingEditData?: boolean;
  isSubmitted: boolean;
  setIsSubmitted?: React.Dispatch<React.SetStateAction<boolean>>;
  dispatch: React.Dispatch<any>;
  formData: Partial<FormDataType>;
}

const EditAdditionalStrategy = ({ task, loadingEditData, isSubmitted, setIsSubmitted, dispatch, formData }: EditAdditionalStrategyProps): JSX.Element => {
  const [additionalStrategiesList, setAdditionalStrategiesList] = useState<TaskIdRequiredType[]>(task?.children ?? []);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [pricingVersionQueryParam,] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );
  const { data: tasks, isFetching } = useGetTasksQuery({
    projection: tasksProjection,
    pricing_version: pricingVersionQueryParam,
  });
  const { id } = useParams<{ [x: string]: string }>();

  /**
   * Filter tasks that are not already selected in the additional strategy list
   */
  const filteredTasks = useMemo(() => {
    return tasks?.filter(task => !additionalStrategiesList.map(item => item.id).includes(task.id) && task?.pricing_version?.slug === pricingVersionQueryParam && task.id !== Number(id));
  }, [additionalStrategiesList, id, pricingVersionQueryParam, tasks]);

  const handleAddAdditionalStrategy = (): void => { setModalOpen(true); };

  const handleAddStrategy = (data: TaskIdRequiredType[]): void => {
    setAdditionalStrategiesList(data);
    setModalOpen(false);
    dispatch({ type: ActionType.ON_CHANGE_ADDITIONAL_STRATEGY_DATA, payload: additionalStrategiesList });
  };

  const handleRemoveAdditionalStrategy = (item: TaskIdRequiredType): void => {
    const newAdditionalStrategiesList = additionalStrategiesList.filter((task: TaskIdRequiredType) => task.id !== item.id);
    setAdditionalStrategiesList(newAdditionalStrategiesList);
    dispatch({ type: ActionType.ON_CHANGE_ADDITIONAL_STRATEGY_DATA, payload: newAdditionalStrategiesList });  };

  const handleSelectAdditionalStrategy = (value: TaskIdRequiredType, index: number): void => {
    const newAdditionalStrategiesList = additionalStrategiesList.map((task: TaskIdRequiredType, taskIndex: number) => taskIndex === index ? value : task);
    setAdditionalStrategiesList(newAdditionalStrategiesList);
    dispatch({ type: ActionType.ON_CHANGE_ADDITIONAL_STRATEGY_DATA, payload: newAdditionalStrategiesList });
  };

  useEffect(() => {
    if (loadingEditData && task?.children && task?.children?.length > 0 && additionalStrategiesList.length === 0) {
      task?.children?.map(item => {
        if (item.id) {
          setAdditionalStrategiesList(prevState => prevState.concat(
            {id: item.id, name: item.name, pricing_version: item.pricing_version} as TaskIdRequiredType));
        }
      });
    }
  } , [additionalStrategiesList, loadingEditData, task]);

  // dispatch the initial additional strategies list
  useEffect(() => {
    if (additionalStrategiesList && additionalStrategiesList.length > 0) {
      dispatch({ type: ActionType.ON_CHANGE_ADDITIONAL_STRATEGY_DATA, payload: additionalStrategiesList });
    }
  }, [additionalStrategiesList, dispatch]);

  const renderSelectAdditionalStrategyList = (): JSX.Element[] => {
    return additionalStrategiesList.map((item: TaskIdRequiredType, index: number) => {
      return (
        <div key={`additional-strategy-${index}`} className={css.row__additional_strategy}>
          <Dropdown
            id='tasks'
            disableClearable={true}
            isOptionEqualToValue={(option: TaskIdRequiredType, value: TaskIdRequiredType): boolean => option.id === value.id}
            keyLabel={'name'}
            loading={!tasks || tasks?.length === 0 }
            options={filteredTasks}
            type='autocomplete'
            handleSelect={(_, selectedValue): void => handleSelectAdditionalStrategy(selectedValue, index)}
            value={additionalStrategiesList[index]}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                label='Strategy'
                className={css.inputField}
                sx={additionalStrategiesList[index] ? valuePresentStyles : undefined}
              />
            )}
          />
          <IconButton size="xl" onClick={(): void => handleRemoveAdditionalStrategy(item)}><TrashIcon /></IconButton>
        </div>
      );
    });
  };


  const renderAddButton = (): JSX.Element => {
    return (
      <Button
        size="large"
        startIcon={<PlusIcon />}
        variant="tertiary"
        sx={{ width: '100%' }}
        onClick={handleAddAdditionalStrategy}
      >
        Add Combined Strategy
      </Button>
    );
  };

  return (
    <Card
      header={
        <CardHeader
          title="Combined Strategies"
          description="Add strategies that are deployed with this strategy when it gets added to a Blueprint"
        />
      }
    >
      {isFetching ? <div style={{ padding: '0px 24px 0px 24px'}}><Skeleton height={70} /></div> :
        <div>
          {renderSelectAdditionalStrategyList()}
          {modalIsLoading ? <Skeleton height={70} /> : renderAddButton()}
          <AddAdditionalStrategyModal
            open={modalOpen}
            onToggle={(): void => setModalOpen(prevModalOpen => !prevModalOpen)}
            onFinish={handleAddStrategy}
            selectedTasks={additionalStrategiesList}
            pricingVersion={pricingVersionQueryParam}
            setModalIsLoading={setModalIsLoading}
          />
        </div>
      }
    </Card>
  );
};

export default memo(EditAdditionalStrategy);
