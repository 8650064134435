import React from 'react';

export const WarningIcon = () => (
  <svg
    x="0px"
    y="0px"
    width="22"
    height="22"
    fill="currentColor"
    viewBox="0 0 22 18"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M0.875 17.5L11 0L21.125 17.5H0.875ZM3.45 16H18.55L11 3L3.45 16ZM11 14.8C11.2333 14.8 11.425 14.725 11.575 14.575C11.725 14.425 11.8 14.2333 11.8 14C11.8 13.7667 11.725 13.575 11.575 13.425C11.425 13.275 11.2333 13.2 11 13.2C10.7667 13.2 10.575 13.275 10.425 13.425C10.275 13.575 10.2 13.7667 10.2 14C10.2 14.2333 10.275 14.425 10.425 14.575C10.575 14.725 10.7667 14.8 11 14.8ZM10.25 12.2H11.75V7.2H10.25V12.2Z" fill="#D1A508"/>
  </svg>
);
