import React, {memo, useCallback } from 'react';
import { Card, CardHeader, Grid, Link } from '@sprnova/nebula';
import { Task } from 'features/entitiesRedux/models/task';
import { PricingVersion, pricingVersionString } from 'features/library/constants';

type ViewAdditionalStrategyProps = {
  task?: Task;
}

const ViewAdditionalStrategy = ({ task }: ViewAdditionalStrategyProps): JSX.Element => {
  const renderTaskChildren = useCallback(() => {
    return (
      <Grid
        container
        justifyContent={'space-around'}
        spacing={2.4}
      >
        {task && task.children ? task.children.map((taskChildren: Task) => {
          return (
            <Grid item xs={12} key={`combined-strategy-${taskChildren.id}`}>
              <Link
                href={`/library/package-strategies/${taskChildren.id}?${pricingVersionString}=${PricingVersion.PACKAGE}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {taskChildren.name}
              </Link>
            </Grid>
          );
        }) : <>N/A</>}
      </Grid>
    );
  }, [task]);

  return (
    <Card
      header={
        <CardHeader title="Combined Strategies" />
      }
    >
      {task?.children && task?.children.length > 0 ? renderTaskChildren() : 'N/A'}
    </Card>
  );
};

/**
 * Combined Strategy card in Read mode
 */
export default memo(ViewAdditionalStrategy);
