import React from 'react';

export const MagicOutlined = () => (
  <svg
    version="1.1"
    focusable="false"
    width="1em"
    height="1em"
    fill="currentColor"
    x="0px"
    y="0px"
    viewBox="0 0 12 12"
  >
    <polygon points="5.3,0 5.7,0.7 6.3,1 5.7,1.3 5.3,2 5,1.3 4.3,1 5,0.7 "/>
    <polygon points="10.1,6.7 10.7,8.1 12,8.7 10.7,9.3 10.1,10.6 9.5,9.3 8.2,8.7 9.5,8.1 "/>
    <polygon points="2,0 2.6,1.4 4,2 2.6,2.7 2,4.1 1.4,2.7 0,2 1.4,1.4 "/>
    <path d="M11.9,2.1l-2-2C9.7-0.1,9.3,0,9.1,0.2L0.2,9.1C0,9.3-0.1,9.7,0.1,9.9l2,2c0.2,0.2,0.6,0.2,0.8,0l8.9-8.9
	C12,2.7,12.1,2.3,11.9,2.1z M2.5,10.7L1.3,9.5L6.8,4L8,5.2L2.5,10.7z M7.5,3.3l2-2l1.2,1.2l-2,2L7.5,3.3z"/>
  </svg>
);