/**
 * Entities -> Departments -> Adapter
 */

import { createEntityAdapter } from '@reduxjs/toolkit';
import { compareField } from '../../utils/compareField';
import { Department } from './department';

/**
 * Redux toolkit entity adapter for storing/caching entities by id
 */
export const departmentsAdapter = createEntityAdapter<Department>({
  selectId: (department) => department.id,
  sortComparer: compareField('name'),
});
