/**
 * Get industries
 */

import { Projection } from 'api/entityGraphQL/types';
import { Industry, YesNo } from 'features/entitiesRedux';
import { baseApi, cleanArguments } from '..';
import tagTypes from './tagTypes';

export type GetIndustriesQuery = {
  id?: number;
  name?: string;
  flattened?: YesNo;
  projection: Projection;
};

export type GetIndustriesResult = Industry[];

export const GET_INDUSTRIES_ENDPOINT_KEY = 'getIndustries';

export const { useGetIndustriesQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getIndustries: build.query<GetIndustriesResult, GetIndustriesQuery>({
      transformResponse: (response: { industries: GetIndustriesResult }) => response.industries,
      providesTags: (result) => {
        return [tagTypes.INDUSTRIES];
      },
      query: ({
        projection = { id: true, name: true, slug: true },
        name,
        ...args
      }) => {
        const __args: Partial<GetIndustriesQuery> = {
          ...args,
        };

        if (name) {
          __args.name = `*${name}*`;
        }

        return {
          body: {
            query: {
              industries: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        };
      }
    })
  })
});
