/**
 * SectionBuilder -> Fields -> ServicesField
 *
 * UI component for selecting services by department (aka Channel)
 * the Appraisal's Analyst Survey gets populated based on the selections made here
 */

import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import useAppraisalById from 'utils/hooks/useAppraisalById';
import { fetchDepartments, fetchServices, selectDiscoveryPage } from 'features/audits/auditsSlice';
import { Audit } from 'features/entitiesRedux/models/audit/audit';
import { useServices } from 'features/entitiesRedux/models/service/hooks';
import { Service } from 'features/entitiesRedux/models/service/service';
import {
  Collapse,
  CollapsePanel,
  FormItem,
  FormList,
  Input, Spin,
  YesNoSwitch,
} from 'components';
import { FieldProps } from '../../types';
import { StatusIndicator } from './components';
import css from './ServicesField.module.scss';

const ServicesField: FC<FieldProps> = ({ name, form }) => {
  const dispatch = useDispatch();
  const discoveryPage = useSelector(selectDiscoveryPage);

  /** Fetch all departments */
  useEffect(() => {
    dispatch(fetchServices());
    dispatch(fetchDepartments());
  }, [dispatch]);

  const urlParts = window.location.pathname.split('/');
  const auditIdIndex = urlParts?.indexOf('appraisals') + 1;
  const auditId = urlParts[auditIdIndex];
  const audit: Partial<Audit> | undefined = useAppraisalById(Number(auditId)).appraisal;

  /** get all services available for selection */
  const { services } = useServices({
    projection: {
      id: true,
      name: true,
      modules: {
        id: true,
      },
      department: {
        id: true,
      },
    }
  });
  const appraisalServices = services?.filter(s => {
    return s?.modules?.filter(module => module?.id === 1001)?.length > 0;
  });

  /** get services with in_survey = "yes" */
  const selectedServices = audit?.discovery?.services?.filter((service) => service?.in_survey === 'yes') || [];

  /** combine appraisalServices with selectedServices so we can set the form value */
  const mergedServices: { [key: string]: Service }  = {};
  for (const service of appraisalServices) {
    mergedServices[`${service.id}`] = service;
  }
  for (const selectedService of selectedServices) {
    const tempService = mergedServices[`${selectedService.id}`];
    mergedServices[`${selectedService.id}`] = { ...tempService, ...selectedService };
  }
  const finalServices = Object.values(mergedServices).sort((a, b) => (a.id > b.id ? 1 : -1));

  /** now we set the form value for 'services' field */
  const departments = discoveryPage?.departments;
  const allServices = form?.getFieldValue('services');
  useEffect(() => {
    if (departments?.length) {
      form.setFieldsValue({ services: finalServices.map(svc => {
        return {
          ...svc,
          modules: undefined
        };
      }) });
    }
  }, [departments?.length, allServices?.length, finalServices?.length, selectedServices?.length]);

  /** Get panel keys for departments with selected services so we can expand them by default */
  const activeKeys = finalServices
    ?.filter((s: Service) => s.in_survey === 'yes')
    ?.map((s: Service) => `dept-panel-${s?.department?.id}`) || undefined;
  const activeDepartmentKeys = activeKeys?.length ? activeKeys : undefined;

  /** boolean to help control rendering of department panels / services */
  const discoveryExistsAndServicesAreEmpty =
    audit?.discovery?.id &&
    Object.hasOwnProperty.call(audit?.discovery, 'services') &&
    Array.isArray(audit?.discovery?.services) &&
    selectedServices?.length === 0;

  /** show loading indicator while department panels are rendering */
  if (!departments?.length) return (
    <div className={css.loadingWrapper}>
      <Spin size="large" />
    </div>
  );

  return (
    <FormList name={name}>
      {(fields) => {
        return (
          <div className={css.root}>
            {discoveryPage?.departments?.filter(dept => dept?.services?.length > 0)?.map((dept) => {

              if (!activeDepartmentKeys && !discoveryExistsAndServicesAreEmpty) return null;

              return (
                <Collapse
                  key={`dept-${dept?.id}`}
                  className={css.departmentCollapse}
                  striped={false}
                  defaultActiveKey={activeDepartmentKeys}
                >
                  <CollapsePanel
                    key={`dept-panel-${dept?.id}`}
                    header={
                      <>
                        <div className={css.headerLabelGrow}>
                          {dept?.name}
                          <StatusIndicator
                            activeServices={
                              form?.getFieldValue('services')?.filter(
                                (s: Service) => s?.department?.id === dept?.id && s?.in_survey === 'yes'
                              )
                            }
                            allServices={appraisalServices?.filter((s: Service) => s?.department?.id === dept?.id)}
                          />
                        </div>
                      </>
                    }
                    className={css.departmentCollapsePanel}
                  >
                    {fields
                      .filter((field) => {
                        const service = form?.getFieldValue(['services', field.name]);
                        return service?.department?.id === dept?.id;
                      })
                      .map((field) => {
                        return (
                          <Row
                            key={field.key}
                            align={'top'}
                            justify="space-between"
                            className={css.serviceRow}
                          >
                            {/** hidden form item for is_active field */}
                            <FormItem
                              name={[field.name, 'is_active']}
                              style={{ display: 'none' }}
                            >
                              <Input />
                            </FormItem>

                            <Col md={8}>
                              <FormItem
                                name={[field.name, 'name']}
                                style={{ marginBottom: 0 }}
                              >
                                <span>
                                  {form?.getFieldValue(['services', field.name, 'name',])}
                                </span>
                              </FormItem>
                            </Col>
                            <Col md={2}>
                              <FormItem
                                name={[field.name, 'in_survey']}
                                fieldKey={field.fieldKey}
                                style={{ marginBottom: 0 }}
                                className="form-item-service"
                              >
                                <YesNoSwitch />
                              </FormItem>
                            </Col>
                          </Row>
                        );
                      })}
                  </CollapsePanel>
                </Collapse>
              );
            })}
          </div>
        );
      }}
    </FormList>
  );
};

export default ServicesField;
