
import { Projection } from 'api/entityGraphQL';
import { SalesforceOpportunity } from 'features/entitiesRedux';
import { baseApi } from '../baseApi';
import { cleanArguments } from '../utils';

export type GetSalesforceOpportunitiesQuery = {
  account_id: string;
  opportunity_id?: string;
  with_linked?: boolean;
  projection?: Projection;
}

export type GetSalesforceOpportunitiesResult = SalesforceOpportunity[];
export const GET_SALESFORCE_OPPORTUNITIES_ENDPOINT_KEY = 'getSalesforceOpportunities';

export const { useGetSalesforceOpportunitiesQuery, useLazyGetSalesforceOpportunitiesQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_SALESFORCE_OPPORTUNITIES_ENDPOINT_KEY]: build.query<GetSalesforceOpportunitiesResult, GetSalesforceOpportunitiesQuery>({
      transformResponse: (response: { salesforce_opportunities: GetSalesforceOpportunitiesResult }) => response.salesforce_opportunities,
      query: ({
        projection = { id: true, name: true },
        ...args
      }) => {
        const __args: Partial<GetSalesforceOpportunitiesQuery> = args;

        return {
          body: {
            query: {
              salesforce_opportunities: {
                __args: cleanArguments(__args),
                ...projection
              }
            }
          }
        };
      }
    })
  })
});




