export const indicatorsProjection = {
  id: true,
  refreshed_at: true,
  indicators: {
    id: true,
    name: true,
    slug: true,
    category: true,
    format: true,
    tooltip: true,
    equation: true,
    value: true,
    compared_value: true,
    required_platform_category: true,
    platform: {
      title: true,
      value: true,
      platform: true,
      compared_value: true,
    },
    data: {
      date: true,
      value: true,
      compared_date: true,
      compared_value: true,
      platform: {
        value: true,
        title: true,
        platform: true,
      },
      compared_platform: {
        value: true,
        title: true,
        platform: true,
      }
    }
  }
};

export const dataSourcesProjection = {
  report_id: true,
  client_id: true,
  data_source_id: true,
  data_source_name: true,
  data_source_slug: true,
  warehouse_source: true,
  last_refresh_status: true,
};

export const selectorIndicatorsProjection = {
  indicator_id: true,
  name: true,
  slug: true,
  category: true,
  is_active: true,
  order_display: true,
  format: true,
  formula: true,
  tooltip: true,
  equation: true,
  settings: true,
  required_group: true,
};

export const scoreboardIntegrationProjection = {
  client_id: true,
  client_name: true,
  data_source_id: true,
  data_source_name: true,
  category: true,
  last_refresh_status: true,
  warehouse_source: true,
};

export const pacingGoalsProjection = {
  id: true,
  type: true,
  refreshed_at: true,
  refresh_status: true,
  term: true,
  start: true,
  metric: true,
  metric_name: true,
  value: true,
  current_value: true,
  prediction: true,
  goal_value: true,
  percent_on_pace: true,
  percent_of_goal: true,
  goal_channel_filter: true,
  goal_source_filter: true,
  is_filterable: true,
  indicator: {
    format: true,
  },
  percent_of_goal_platform: {
    platform: true,
    title: true,
    value: true,
  },
  current_platform: {
    platform: true,
    title: true,
    value: true,
  },
  percent_on_pace_platform: {
    platform: true,
    title: true,
    value: true,
  },
  prediction_platform: {
    platform: true,
    title: true,
    value: true,
  },
  data: {
    date: true,
    actual: true,
    goal: true,
    pace: true,
  },
};
