export enum PricingTypeEnum {
  AddOnFixed = 'add-on-fixed',
  AddOnMultiplier = 'add-on-multiplier',
  Custom = 'custom',
  Fixed = 'fixed',
  Multiplier = 'multiplier',
  Quantity = 'quantity',
  Spend = 'spend',
}

export enum PricingTierValueName {
  DELIVERABLE = 'deliverable',
  NAME = 'name',
  PRICE = 'price',
  QUANTITY = 'quantity',
  QUANTITY_PRICE = 'quantity_price',
  SNIPPET = 'snippet',
  SPEND_MAX = 'spend_max',
  SPEND_MIN = 'spend_min',
}

export const maxCharactersSnippetSummary = 500;
