export enum PlaybookDataType {
  Ad = 'ad',
  AdSet = 'adset',
  Campaign = 'campaign',
}
export enum SortType {
  ltv = 'ltv',
  score = 'score',
  spend = 'spend',
  roas = 'roas',
  revenue = 'revenue',
}
