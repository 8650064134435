/**
 * SelectCriteriaEntity
 */

import React, { forwardRef, useEffect } from 'react';
import { Empty } from 'antd';
import { TreeSelect, TreeSelectProps } from 'components/TreeSelect';
import { Entity } from 'features/entitiesRedux';
import { sortByName, useCriteriaEntities } from '../../utils';
import formatOptionTitle from './formatOptionTitle';
import css from './SelectCriteriaEntity.module.scss';

interface SelectCriteriaEntityProps extends TreeSelectProps {
  value?: string;
  onChange?: (value: string) => void;
  onLoadData: (value: boolean) => void;
}

const SelectCriteriaEntity = forwardRef(({ skeleton: skeletonProp, onChange, onLoadData, value, ...props }: SelectCriteriaEntityProps, ref: any) => {
  const { entities, loading } = useCriteriaEntities();
  const skeleton = loading && !entities?.length;

  /** Let parent component know if data is still loading */
  useEffect(() => {
    if (loading) {
      onLoadData(true);
    } else {
      onLoadData(false);
    }
  }, [loading]);

  // const businessTypeEntities = entities.filter(e => e.entity_type === 'BusinessType').sort(sortByName);
  const departmentEntities = entities.filter(e => e.entity_type === 'Department').sort(sortByName);
  const questionGroupEntities = entities.filter(e => e.entity_type === 'Score').sort(sortByName);
  const questionEntities = entities.filter(e => e.entity_type === 'Bucket').sort(sortByName);

  const treeData = [
    // {
    //   title: 'Business Type',
    //   selectable: false,
    //   key: 'business-type',
    //   children: businessTypeEntities.map((entity: Partial<Entity>) => {
    //     return {
    //       title: entity.name,
    //       value: entity.value,
    //       key: entity.value,
    //       searchTitle: entity.name,
    //     };
    //   })
    // },
    {
      title: 'Department Score',
      selectable: false,
      key: 'department',
      children: departmentEntities.map((entity: Partial<Entity>) => {
        return {
          title: entity.name,
          value: entity.value,
          key: entity.value,
          searchTitle: entity.name,
        };
      })
    },
    {
      title: 'Analyst Question Group',
      selectable: false,
      key: 'analyst-question-group',
      children: questionGroupEntities.map((entity: Partial<Entity>) => {
        return {
          title: formatOptionTitle(entity.name, `${entity.business_type?.name}, ${entity.parent?.name}`),
          value: entity.value,
          key: entity.value,
          searchTitle: [entity.name, entity.business_type?.name, entity.parent?.name].join(' '),
        };
      })
    },
    {
      title: 'Analyst Question',
      selectable: false,
      key: 'analyst-question',
      children: questionEntities.map((entity: Partial<Entity>) => {
        return {
          title: formatOptionTitle(entity.name, `${entity.business_type?.name}, ${entity.parent?.name}`),
          value: entity.value,
          key: entity.value,
          searchTitle: [entity.name, entity.business_type?.name, entity.parent?.name].join(' '),
        };
      })
    },
  ];

  const handleSelect = (val: string) => {
    if (onChange) {
      onChange(val);
    }
  };

  return (
    <TreeSelect
      showSearch
      placeholder="Select entity"
      treeNodeFilterProp="searchTitle"
      filterTreeNode={(input, option) =>
        typeof option?.searchTitle === 'string' ? option?.searchTitle?.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
      }
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeData={treeData}
      ref={ref}
      className={css.root}
      skeleton={skeleton || skeletonProp}
      allowClear
      notFoundContent={
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description='No entities matched your search'
        />
      }
      {...props}
      onChange={handleSelect}
      value={value}
    />
  );
});

export default SelectCriteriaEntity;
