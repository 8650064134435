import React, { memo, useState, useEffect, useRef, RefObject, useCallback, useMemo } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { LoadableComponent } from '@loadable/component';
import { Box, Grid, Popper, Typography } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon, Container, Card, Button, PageSpinner, Autocomplete , Alert } from '@sprnova/nebula';
import { Action } from 'api/accessControl/Action';
import { Resource } from 'api/accessControl/Resource';
import { useGetClientQuery } from 'api/crudGraphQL/clients/getClient';
import { useGetClientsQuery } from 'api/crudGraphQL/clients/getClients';
import { CustomerInsightsDefaultProjection } from 'api/crudGraphQL/customer_insights/customerInsightDefaultProjection';
import { useGetCustomerInsightDateRangesQuery } from 'api/crudGraphQL/customer_insights/getCustomerInsightDateRanges';
import { useGetCustomerInsightsQuery } from 'api/crudGraphQL/customer_insights/getCustomerInsights';
import { CustomerInsightType } from 'api/crudGraphQL/customer_insights/types';
import { AccessControl } from 'components/AccessControl';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import FormV2 from 'components/nebula/Form/FormV2';
import Info from 'components/nebula/icons/Info';
import { PageHero } from 'layouts/components';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import { useQueryParams, StringParam, JsonParam } from 'use-query-params';
import exportCSV from 'utils/actions/exportCSV';
import { exportPDF } from 'utils/actions/exportPDF';
import { Client } from 'features/entitiesRedux';
import { useAccount } from 'features/global';
import { ClientIndustryLink } from './components/ClientIndustryLink';
import { ExportDropdownButton } from './components/ExportDropdownButton';
import { NoDataForTimePeriod } from './components/NoDataForTimePeriod';
import WidgetCard from './components/WidgetCard';
import { CustomerInsightsWidgets } from './constants';
import { AboutThisTool } from '../components/AboutThisTool/AboutThisTool';
import { NebulaButton } from '../components/library-components';
import type { Tab as AnchorlinkGroupTab } from '../components/library-components/AnchorlinkGroup/AnchorlinkGroup';
import { Sidebar } from '../components/library-components/Sidebar/Sidebar';
import { timeRangeValues } from '../constants';
import css from './CustomerInsightsPage.module.scss';

type Tab = {
  ref: RefObject<HTMLDivElement>;
  infoTab?: string | JSX.Element;
  component?: LoadableComponent<any>,
} & AnchorlinkGroupTab;

export const CustomerInsightsPage = () => {
  const [clientId, setClientId] = useState<number>();
  const [client, setClient] = useState<Client>();
  const [clientName, setClientName] = useState<string | undefined>();
  const [clientIndustry, setClientIndustry] = useState<string | undefined>();
  const [customerInsightId, setCustomerInsightId] = useState<string>();
  const [customerInsight, setCustomerInsight] = useState<CustomerInsightType>();
  const [dateRangeId, setDateRangeId] = useState<Record<string, any>>();
  const [ltv, setLtv] = useState({
    id: 1004,
    label: 'States',
  });
  const [customerInsightsTitle, setCustomerInsightsTitle] = useState<string>('Customer Insights');
  const [scrollToList, setScrollToList] = useState<boolean>(false);
  const topRef = useRef<HTMLDivElement>(null);
  const printRef: React.Ref<HTMLDivElement> = useRef(null);
  const [creatingPDF, setCreatingPDF] = useState<boolean>(false);
  const [hasData, setHasData] = useState<boolean>(false);
  // info icon popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [ranges, setRanges] = useState([]);
  const [exporting, setExporting] = useState<boolean>(false);

  const { account } = useAccount();

  // const { id: client_id } = useParams<{ [x: string]: string }>();

  const [queryParams, setQueryParams] = useQueryParams({
    client: JsonParam,
    customer_insight_id: StringParam,
    date_range: JsonParam,
    ltv: JsonParam,
  });

  const location = useLocation();
  const history = useHistory();

  /**
   * Pass in client data with has_cohort_data so we can make a single query
   * and always know if a client has data or not
   */
  const { data: clientsRequest, isLoading: clientIsLoading, isFetching: clientIsFetching } = useGetClientsQuery({
    limit: 9999,
    projection: {
      id: true,
      name: true,
      industry: {
        name: true
      },
      fivetran_connectors: {
        data_source: {
          slug: true
        },
        data_feed_created: true
      }
    }
  });

  /**
   * RTK fetch customer insight date ranges
   */
  const { data: customerInsightsDateRanges, isLoading: dateRangesIsLoading } = useGetCustomerInsightDateRangesQuery({
    projection: { id: true, range: true },
  });

  const dateRanges = useMemo(() => {
    if (customerInsightsDateRanges) {
      return customerInsightsDateRanges.map((range) => ({
        id: range.id,
        label: range.range,
      }));
    }
    /** Otherwise returns empty array */
    return [];
  }, [customerInsightsDateRanges]);

  const skip = useMemo(() =>
    !(clientId && queryParams.client) || clientIsLoading || clientIsFetching || !(dateRangeId && queryParams.date_range),
  [clientId, queryParams.client, clientIsLoading, clientIsFetching, dateRangeId, queryParams.date_range]);

  const skipLtv = useMemo(() => skip || !ltv.id, [skip, ltv]);

  const { consumer_insight_ltvs, ...rest } = CustomerInsightsDefaultProjection;

  const { data: customerInsightsLtv, isLoading: ltvLoading, isFetching: ltvFetching } = useGetCustomerInsightsQuery({
    client_id: queryParams.client?.id ?? clientId,
    date_range_id: queryParams.date_range?.id ?? dateRangeId,
    group_id: queryParams?.ltv?.id ?? ltv.id,
    projection: {
      id: false,
      consumer_insight_ltvs
    }
  }, { skip: skip || skipLtv });

  const { data: customerInsights, isLoading, isFetching } = useGetCustomerInsightsQuery({
    client_id: queryParams.client?.id ?? clientId,
    date_range_id: queryParams.date_range?.id ?? dateRangeId,
    projection: rest
  }, { skip });

  const insights: Record<string, any> = useMemo(() => {
    let data = {};
    if (customerInsightsLtv) {
      data = { ...data, ...customerInsightsLtv?.data?.[0] };
    }
    if (customerInsights) {
      data = { ...data, ...customerInsights?.data?.[0] };
    }
    return data;
  }, [customerInsightsLtv, customerInsights]);

  /**
   * Mixpanel event tracking
   */
  const mixpanel = useMixpanel();

  const handleMixpanelEvents = useCallback((type?: 'PDF' | 'CSV' | WidgetNameType) => {
    try {
      let options: Record<string, any> = {
        client: clientName,
        clientId: clientId,
        userName: account?.name,
        userId: account?.id,
        dateRangeId: dateRangeId?.id,
        dateRange: dateRanges?.filter((range) => range.id === dateRangeId?.id)?.[0]?.label,
      };
      let trackTitle: string;
      if (!type) {
        trackTitle = 'Run Customer Insights';
      } else {
        if (type !== 'PDF' && type !== 'CSV') {
          trackTitle = 'Customer Insights Click View More';
          options = { ...options, widget: type };
        } else {
          trackTitle = `Export Customer Insights ${type}`;
        }
      }
      if (options.clientId && options.dateRange) {
        console.log(`🛤 Track: ${trackTitle}`, options);
        if (mixpanel?.track) {
          mixpanel.track(trackTitle, { options });
        }
      }
    } catch (error) {
      console.error('Error sending mixpanel event', error);
    }
  }, [clientName, clientId, account?.name, account?.id, dateRangeId, dateRanges, mixpanel]);

  useEffect(() => {
    if (clientId && dateRangeId) {
      handleMixpanelEvents();
    }
  }, [client, clientId, dateRangeId, clientName, ranges, handleMixpanelEvents]);

  const isInsightsDataLoading = useMemo((): Record<string, boolean> => {
    const ltv = ltvLoading || ltvFetching;
    const rest = isLoading || isFetching;
    return {
      ltv,
      rest,
      both: ltv || rest
    };
  }, [ltvLoading, ltvFetching, isLoading, isFetching]);

  const handlePopoverOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const id = useMemo(() => (open ? 'info-popover' : undefined), [open]);

  const hasNoData: boolean = useMemo(() => {
    if (isEmpty(insights)) return true;
    return isEmpty([
      ...insights?.consumer_insight_ltvs?.insights ?? [],
      ...insights?.consumer_insight_family_behaviors?.insights ?? [],
      ...insights?.consumer_insight_finance_wealths?.insights ?? [],
      ...insights?.consumer_insight_interests?.insights ?? [],
      ...insights?.consumer_insight_real_states?.insights ?? [],
      ...insights?.consumer_insight_life_stages?.insights ?? []
    ]);
  }, [insights]);

  //useEffect to determine is data is returned or not
  useEffect(() => {
    if (insights && clientId && dateRangeId) {
      if (hasNoData) {
        setHasData(false);
      } else {
        setHasData(true);
      }
    }
  }, [clientId, insights, dateRangeId, hasNoData]);

  //useEffect for client data
  useEffect(() => {
    if (clientsRequest && !clientIsLoading && !clientIsFetching) {
      if (queryParams.client) {
        setClientId(queryParams.client.id);
        setClient(clientsRequest.data.filter(item => item.id === queryParams.client.id)?.[0]);
        setClientName(clientsRequest.data.filter(item => item.id === queryParams.client.id)?.[0]?.name);
        setClientIndustry(clientsRequest.data.filter(item => item.id === queryParams.client.id)?.[0]?.industry?.name);
      }
    }
  }, [clientIsFetching, clientIsLoading, clientsRequest, queryParams.client]);

  //useEffect to set dateRangeId from value in query param
  useEffect(() => {
    if (queryParams.date_range) {
      setDateRangeId(queryParams.date_range);
    }
  }, [queryParams]);

  //useEffect to set ltv from value in query param
  useEffect(() => {
    if (queryParams.ltv) {
      setLtv(queryParams.ltv);
    }
  }, [queryParams]);

  //format title for customer insight page
  const displayConsumerInsightsHeaderTitle = useCallback((name: string | undefined): string => {
    return name ? `${name}'s Customer Insights` : 'Customer Insights';
  }, []);

  //useEffect to change url query params
  useEffect(() => {
    if (clientId && dateRangeId) {
      setCustomerInsightsTitle(displayConsumerInsightsHeaderTitle(client?.name));
    }
  }, [clientId, dateRangeId, customerInsightId, displayConsumerInsightsHeaderTitle, client?.name, history, ltv]);

  /**
   * This array controls both the tabs (links) and their respective widget;
   */
  // useRef can't be used in a useMemo
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const widgets: Tab[] = ([
    {
      title: CustomerInsightsWidgets.ltvTitle,
      id: CustomerInsightsWidgets.ltvId,
      ref: useRef(null),
    },
    {
      title: CustomerInsightsWidgets.familyBehaviorsTitle,
      id: CustomerInsightsWidgets.familyBehaviorsId,
      ref: useRef(null),
    },
    {
      title: CustomerInsightsWidgets.interestsTitle,
      id: CustomerInsightsWidgets.interestsId,
      ref: useRef(null),
      infoTab: `Top interests are calculated by participants answering a survey on whether or not
      they are interested in a subject. In cases where a participant chooses
      not to respond to a specific question, it is interpreted as indicating
      a lack of interest in that particular subject and reflected in the percentage below.`
    },
    {
      title: CustomerInsightsWidgets.realEstateTitle,
      id: CustomerInsightsWidgets.realEstateId,
      ref: useRef(null),
    },
    {
      title: CustomerInsightsWidgets.lifeStagesTitle,
      id: CustomerInsightsWidgets.lifeStagesId,
      ref: useRef(null),
    },
    {
      title: CustomerInsightsWidgets.financeWealthsTitle,
      id: CustomerInsightsWidgets.financeWealthsId,
      ref: useRef(null),
    },
  ]);

  type WidgetNameType = typeof widgets[number]['id'];

  //the functions for the view more button on customerinsights overview page (Button functionalitytaht triggers routing)
  const goToDetailedPage = useCallback((id: string): void => {
    const { pathname, search } = location;
    const newSearch = `${search}&customer_insight_id=${id}`;
    handleMixpanelEvents(id);
    history.push(`${pathname}${newSearch}`);
  }, [location, handleMixpanelEvents, history]);

  //autoscroll to top when come back from detailed page
  const goToTopRef = (): void => {
    if (printRef.current) {
      printRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const backToOverview = useCallback(() => {
    setQueryParams({ customer_insight_id: undefined, date_range: dateRangeId, ltv });
    goToTopRef();
  }, [setQueryParams, dateRangeId, ltv]);

  const viewMoreButton = useCallback((count: number, id: string) => {
    const handleViewMoreClick = () => {
      // Set the scrollToList value to true
      setScrollToList(true);
      // Call the goToDetailedPage function
      goToDetailedPage(id);
    };

    return (
      <div style={{ paddingTop: '24px' }}>
        <NebulaButton buttonType='tertiary' onClick={handleViewMoreClick} width='100%'>
          {`View ${count - 5} more results `}
        </NebulaButton>
      </div>
    );
  }, [goToDetailedPage]);

  const seeMoreButton = useCallback((id: string) => {
    const handleSeeMoreClick = () => {
      // Set the scrollToList value to true
      setScrollToList(false);
      // Call the goToDetailedPage function
      goToDetailedPage(id);
    };

    return (
      <NebulaButton
        buttonType='text'
        onClick={handleSeeMoreClick}
        width='100%'
        icon={<ArrowRightIcon />}
        icon_position='right'
        style={{ padding: '0' }}
      >
        See More
      </NebulaButton>
    );
  }, [goToDetailedPage]);

  const formatArray = useCallback((array: any[]) => {
    return array.map((item: any) => [
      `"${item.display || item.label}"`,
      `"${item.ltv
        ? `${item.format}${item.ltv}`
        : item.format === '#'
          ? `${item.percentage}yrs`
          : `${item.percentage}${item.format}`}"`,
      `"${item.ltv_benchmark
        ? `${item.format}${item.ltv_benchmark}`
        : item.format === '#'
          ? `${item.benchmark_percentage}yrs`
          : `${item.benchmark_percentage}${item.format}`}"`
    ]).join('\n');
  }, []);

  const exportToCsv = useCallback(async () => {
    await setExporting(true);
    handleMixpanelEvents('CSV');
    let csvData = [];
    const index2 = ['title', 'value', 'compared to'];
    if (customerInsightId) {
      let title = widgets.filter(widget => widget.id === customerInsightId)[0].title;
      if (title === 'Lifetime Value by') {
        title = `${title} ${ltv.label}`;
      }
      csvData = [title, index2, formatArray(insights[customerInsightId]?.insights)];
    } else {
      csvData = widgets.map((widget, i) => {
        let title = widget.title;
        if (title === 'Lifetime Value by') {
          title = `${title} ${ltv.label}`;
        }
        return [
          i > 0 ? `\n\n"${title}"` : `"${title}"`,
          index2,
          formatArray(insights[widget.id]?.insights)
        ].join('\n');
      });
    }

    await exportCSV({
      data: csvData,
      moduleName: 'Customer Insights',
      clientName: client?.name,
    }).finally(() => setExporting(false));
  }, [handleMixpanelEvents, customerInsightId, client?.name, widgets, formatArray, insights, ltv.label]);

  const renderPDFTitle = useCallback(() => (
    <div
      style={{
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal'
      }}
    >
      {
        clientName
          ? <div
            style={{
              fontSize: '48px',
            }}
          >
            {clientName} Customer Insights
          </div>
          : <></>
      }
      <div style={{ fontSize: '24px', paddingLeft: '8px' }}>
        {widgets.filter(widget => widget.id === customerInsightId)?.[0]?.title || 'Overview'} from {dateRangeId ? timeRangeValues[dateRangeId?.id] : 'all time'}
      </div>
    </div>
  ), [clientName, widgets, customerInsightId, dateRangeId]);

  const handleExportToPDF = useCallback(async () => {
    const element = printRef.current?.cloneNode(true) as HTMLElement;
    element.style.cssText = `
      max-width: 100% !important;
      margin: 0 auto !important;
    `;
    const firstDiv = element.getElementsByTagName('div')[0];
    firstDiv.style.margin = '0px';
    firstDiv.style.maxWidth = '100%';

    const date = `${new Date().toLocaleDateString('en-us', {})}`;
    setCreatingPDF(true);
    exportPDF(
      element,
      {
        title: renderPDFTitle(),
        saveFileName: `"${clientName ? `${clientName}'s Customer Insights` : 'Customer Insights'} ${date}"`,
      }
    ).then(() => {
      setCreatingPDF(false);
      element.remove();
    });
  }, [clientName, printRef, renderPDFTitle]);

  //render widgets on the overviewpage
  const renderWidgets = useCallback((): any => {
    if (clientIsLoading || isInsightsDataLoading.both && hasNoData) return <PageSpinner size='large' label='Loading customer insights' />;
    return widgets.map((widget, index) => {
      const { ref, ...rest } = widget;
      const componentData = insights[rest.id] ? { title: rest.title, values: insights[rest.id] } : undefined;
      return (
        <Box key={index} sx={{ marginTop: index === 0 ? '0' : '24px' }} ref={ref}>
          <WidgetCard
            data={componentData?.values}
            type='brief'
            title={componentData?.title}
            info={rest.infoTab}
            extra={componentData?.values?.insights?.length > 5 ? viewMoreButton(componentData?.values?.insights?.length, rest.id) : <></>}
            seeMore={seeMoreButton(rest.id)}
            isLoading={componentData?.title === CustomerInsightsWidgets.ltvTitle && isInsightsDataLoading.ltv}
          />
        </Box>
      );
    });
  }, [clientIsLoading, isInsightsDataLoading, widgets, insights, viewMoreButton, seeMoreButton, hasNoData]);

  //render a specific customer insight widget
  const renderWidget = useCallback((): any => {
    if (clientIsLoading || isInsightsDataLoading.both && hasNoData) return <PageSpinner size='large' label='Loading customer insights' />;
    const title = widgets.filter(widget => widget.id === customerInsightId)[0].title;
    return (
      <>
        <Box sx={{ marginTop: '0' }}>
          {customerInsight &&
            <WidgetCard
              data={customerInsight}
              type='detailed'
              title={title}
              clientName={clientName}
              scrollToList={scrollToList}
              isLoading={title === CustomerInsightsWidgets.ltvTitle && isInsightsDataLoading.ltv}
            />
          }
        </Box>
      </>
    );
  }, [clientIsLoading, isInsightsDataLoading, widgets, customerInsight, clientName, scrollToList, customerInsightId, hasNoData]);

  //useEffects for a specific customer insight widget
  useEffect(() => {
    if (queryParams.customer_insight_id) {
      setCustomerInsightId(queryParams.customer_insight_id);
    } else {
      setCustomerInsightId(undefined);
      setCustomerInsight(undefined);
    }
  }, [queryParams.customer_insight_id]);

  useEffect(() => {
    if (queryParams.date_range) {
      setDateRangeId(queryParams.date_range);
    } else {
      setDateRangeId(undefined);
    }
  }, [queryParams.date_range]);

  useEffect(() => {
    if (customerInsightId && insights[customerInsightId]) {
      setCustomerInsight(insights[customerInsightId]);
    }
    if (!customerInsightId || customerInsightId === 'undefined') {
      renderWidgets();
    }

  }, [customerInsightId, insights, renderWidgets]);

  const renderCategoriesCardContent = useMemo((): JSX.Element => {
    const tabs = widgets.map((widget) => {
      if (widget.title === CustomerInsightsWidgets.ltvTitle) {
        return {
          id: widget.id,
          title: 'Lifetime Value',
          ref: widget.ref,
        };
      }
      return widget;
    });

    return (
      <Sidebar
        type='navigation'
        title='Categories'
        tabs={tabs}
        sticky
        extra={
          <div style={{ width: '100%', marginTop: '16px' }}>
            <ExportDropdownButton links={[
              { name: 'CSV', handler: exportToCsv },
              { name: 'PDF', handler: handleExportToPDF }
            ]} />
          </div>
        }
      />
    );
  }, [widgets, exportToCsv, handleExportToPDF]);

  //format updated_at time stamp
  const displayUpdatedTimestamp = useMemo(() => {
    if (insights && insights?.updated_at) {
      return `Data last updated ${moment(insights?.updated_at).tz('America/Los_Angeles').format('MMMM Do YYYY, h:mm:ss a')}`;
    }
    else {
      return 'Not Updated Yet';
    }
  }, [insights]);

  const renderBackToOverview = useCallback(() => {
    const title = widgets.filter(widget => widget.id === customerInsightId)[0].title;
    return (
      <Sidebar
        type='navigation'
        sticky
        extra={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'flex-start'
            }}
          >
            <h3
              style={{
                fontFamily: 'Inter',
                fontSize: '20px',
                fontWeight: 600,
                lineHeight: '24px',
                paddingBottom: '6px'
              }}
            >
              {title === CustomerInsightsWidgets.ltvTitle ? 'Lifetime Value' : title}
            </h3>
            <ExportDropdownButton links={[
              { name: 'CSV', handler: exportToCsv },
              { name: 'PDF', handler: handleExportToPDF }
            ]} />
          </div>
        }
      />
    );
  }, [widgets, exportToCsv, handleExportToPDF, customerInsightId]);

  const { data: availableClients, isLoading: clientsLoading } = useGetClientsQuery(
    {
      page: 1,
      limit: 9999,
      has_consumer_insights: 1,
      projection: {
        id: true,
        name: true,
      }
    }
  );

  const clients = useMemo(() => {
    if (Array.isArray(availableClients?.data) && availableClients?.data?.length) {
      return availableClients.data.map((client: any) => {
        const { id, name } = client;
        return { id, label: name };
      });
    }
    return [];
  }, [availableClients]);

  const handleClear = useCallback((type: 'client' | 'date') => {
    switch (type) {
      case 'client':
        setClient(undefined);
        setClientId(undefined);
        setClientName(undefined);
        setClientIndustry(undefined);
        break;
      case 'date':
        setDateRangeId(undefined);
        break;
    }
    setCustomerInsightId(undefined);
    setCustomerInsight(undefined);
  }, []);

  const handleSubmit = useCallback((values: Record<string, any>) => {
    const { client, date_range } = values;
    if (client) setClientId(client.id);
    if (date_range) setDateRangeId(date_range);
  }, []);

  const formItems = useMemo(() => {
    const grid = { xs: 6 };
    return [
      {
        component: Autocomplete,
        name: 'client',
        props: {
          label: clientId ? 'Client' : 'Select a client',
          onClear: (): void => handleClear('client'),
          options: clients,
          disabled: clientsLoading,
          disablePortal: true,
        },
        paramType: JsonParam,
        grid,
      },
      {
        component: Autocomplete,
        name: 'date_range',
        props: {
          label: dateRangeId ? 'Time period' : 'Select a time period',
          onClear: (): void => handleClear('date'),
          options: dateRanges,
          disabled: dateRangesIsLoading,
        },
        paramType: JsonParam,
        grid,
      },
    ];
  }, [clientId, clients, clientsLoading, dateRangeId, dateRanges, dateRangesIsLoading, handleClear]);

  //render hero component w/ date filter
  const renderCustomerInsightsHero = useMemo((): JSX.Element => (
    <div className={css.cardContentWrapper} style={{ marginBottom: clientId ? '10px' : '0' }}>
      <Grid component='div' direction='column' display='flex' container>
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <div className={css.cardContentWrapper_title}>
            Gather Customer Insights
          </div>
          <>
            <div
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              style={{
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
                marginBottom: '20px',
                marginLeft: '16px'
              }}
            >
              {<Info width='24px' height='24px' />}
            </div>
            <Popper
              placement='top-start'
              id={id}
              open={open}
              anchorEl={anchorEl}
              nonce={undefined}
              sx={{
                zIndex: 90,
                backgroundColor: '#FFFFFF',
                boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                borderRadius: '3px',
                padding: '8px 16px'
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                  maxWidth: '720px',
                  padding: '8px 16px'
                }}
              >
                In order to populate data please select both a client and a time period. The benchmark data is based on the client&apos;s assigned industry, if the industry gets changed in the client&apos;s profile please wait 24 hours for the data to be reflected.
              </Typography>
            </Popper>
          </>
        </div>
        <FormV2
          withQueryParams
          submitOnChange
          onSubmit={handleSubmit}
          button={false}
          items={formItems}
          debounceTime={0}
        />
      </Grid>
    </div>
  ), [clientId, handlePopoverOpen, handlePopoverClose, id, open, anchorEl, handleSubmit, formItems]);

  const renderSidebarContent = useCallback((): JSX.Element => {
    if (clientId && dateRangeId && !customerInsightId) {
      return (
        <Grid sx={{ position: 'sticky', top: 0, paddingTop: '32px' }}>
          <Card
            title={
              <Typography variant='h4'>
                Categories
              </Typography>
            }
          >
            {renderCategoriesCardContent}
          </Card>
        </Grid>
      );
    } else if (clientId && dateRangeId && customerInsightId) {
      return (
        <Grid sx={{ position: 'sticky', top: 0, paddingTop: '32px' }}>
          <Card
            title={
              <Button
                disableRipple
                size='small'
                startIcon={<ArrowLeftIcon />}
                onClick={backToOverview}
              >
                Back to overview
              </Button>
            }
          >
            {renderBackToOverview()}
          </Card>
        </Grid>
      );
    } else {
      return (
        <div></div>
      );
    }
  }, [clientId, dateRangeId, customerInsightId, renderCategoriesCardContent, backToOverview, renderBackToOverview]);

  const renderWidgetsContent = useCallback((): JSX.Element => {
    const styles = isInsightsDataLoading.both && hasNoData ? {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      marginLeft: '24px',
    } : { marginLeft: '24px' };

    if (clientId && dateRangeId && !customerInsightId) {
      return (
        <Grid sx={styles}>
          {renderWidgets()}
        </Grid>
      );
    } else if (clientId && dateRangeId && customerInsightId) {
      return (
        <Grid sx={styles}>
          {renderWidget()}
        </Grid>
      );
    } else {
      return (
        <div></div>
      );
    }
  }, [isInsightsDataLoading, clientId, dateRangeId, customerInsightId, renderWidgets, renderWidget, hasNoData]);

  const renderAboutThisTool = useMemo(() => (
    <AboutThisTool
      cards={[
        {
          title: 'What is nova Intelligence Customer Insights?',
          content: (
            <div>
              The nova Intelligence Customer Insights tool, powered by Data Axle, unlocks a plethora of customer data for you that can be utilized to accurately show your clients who their customers are, what they do, trends, and much more.
            </div>
          )
        },
        {
          title: 'What are the benefits of using Customer Insights?',
          content: (
            <div>
              <p>Benefits include, but aren’t limited to:</p>
              <ul>
                <li>Clients being able to identify, predict and address the factors that influence their sales and profits.</li>
                <li>Gathering accurate data that can be used to develop strategies around client campaigns.</li>
                <li>Identifying opportunities in customer categories that you didn’t know existed.</li>
              </ul>
            </div>
          )
        },
        {
          title: 'How is Customer Insights data being pulled?',
          content: (
            <div>
              The nova Intelligence Customer Insights tool is powered by Data Axle. Data Axle empowers us with a database of over 280 million customer records that can be utilized to develop strategies, campaigns, and ultimately affect the bottom line of your clients.
            </div>
          )
        }
      ]}
    />
  ), []);

  // Checking if any of the ids have Salesforce Commerce Cloud connectors
  const playbookAdsDataHasSFCC = clientsRequest?.data.some(client =>
    client.id === queryParams.client?.id && client?.fivetran_connectors?.some(connector => connector?.data_source?.slug === 'salesforce-commerce-cloud' && connector?.data_feed_created === true)
  );

  return (
    <AccessControl action={[Action.read]} resource={Resource.customerInsights}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: `calc(${window.innerHeight}px * .1)`,
        }}
        ref={topRef}
      >
        {/* pass empty anchorlinks to remove unecessary bottom padding after hero header */}
        <PageHero title={customerInsightsTitle} anchorLinks={<></>}>
          <>
            {clientId && <ClientIndustryLink industry={clientIndustry} client_id={clientId} />}
            {/* TODO: Uncomment when we have the correct "updated at" timestamp */}
            {/* <UpdatedAtTimestamp timestamp={displayUpdatedTimestamp}/> */}
            <Card paddingTop='0px !important'>
              {renderCustomerInsightsHero}
              {playbookAdsDataHasSFCC && (
                <Alert severity="warning" sx={{marginTop: '20px'}}>
                Salesforce Commerce Cloud refunds data is currently missing. LTV values may not be 100% accurate. We are working on this.
                </Alert>
              )}
            </Card>
          </>
        </PageHero>

        <Container hasVerticalPadding>
          {(!(clientId && dateRangeId) && !customerInsightId) &&
            <Grid container direction='row' sx={{ marginTop: '32px' }}>
              <Box>{renderAboutThisTool}</Box>
            </Grid>
          }

          {(!hasData && !isInsightsDataLoading.both && dateRangeId && clientId) ? <NoDataForTimePeriod label='customer insights' /> :
            <Grid container direction='row'>
              <Grid item xs={3} sx={{ position: 'sticky', top: 0 }}>
                {renderSidebarContent()}
              </Grid>
              <Grid item container direction="column" xs={9} ref={printRef}
                sx={{
                  paddingTop: '32px'
                }}
              >
                {renderWidgetsContent()}
              </Grid>
            </Grid>
          }
        </Container>
      </div>
    </AccessControl>
  );
};

export const FormattedTitle = memo((): JSX.Element => {
  const { id } = useParams<{ [x: string]: string }>();

  const { data: client, isLoading, error } = useGetClientQuery({
    id: Number(id),
    projection: {
      id: true,
      name: true,
    }
  });
  if (error) return <>{client?.id}</>;
  return !isLoading ? <>{client?.name + '\'s Customer Insights'}</> : <>...</>;
});
