import React, { FC } from 'react';
import loadable from '@loadable/component';
import { OverviewCards } from 'components/OverviewCards';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';
import { useBlueprintMenuItems } from './useBlueprintMenuItems';

const Fallback: FC = () => {
  const items = useBlueprintMenuItems();

  return (
    <PageSkeleton>
      <OverviewCards
        skeleton
        skeletonCount={items.length}
      />
    </PageSkeleton>
  );
};

const LoadableComponent = loadable(
  () => import('./BlueprintCentralOverviewPage'),
  {
    fallback: <Fallback />
  }
);

export { LoadableComponent as BlueprintCentralOverviewPage };
