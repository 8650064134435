/**
 * BlockList -> Header
 */

import React, { FC } from 'react';
import classnames from 'classnames';
import optionsColumnCss from '../ActionsColumn/ActionsColumn.module.scss';
import css from './Header.module.scss';

type Props = {
  className?: string;
  hasOptions?: boolean;
  hasCustomActions?: boolean;
  items: {
    alignLeft?: boolean;
    label: React.ReactNode;
    className?: string;
  }[];
}

const BlockListHeader: FC<Props> = ({ items = [], hasOptions, hasCustomActions, className }) => (
  <div className={classnames(css.root, className)}>
    {items.map((item, index) => (
      <div className={classnames(css.column, {[css.alignLeft]: item.alignLeft }, item.className)} key={index}>
        {item.label}
      </div>
    ))}
    {hasCustomActions && <div className={classnames(css.column, css.customActions)} />}
    {hasOptions && <div className={classnames(css.column, optionsColumnCss.control)} />}
  </div>
);

export default BlockListHeader;