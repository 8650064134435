import { EntityAdapter, EntityState, EntityId } from '@reduxjs/toolkit';

/**
 * Workaround for bug related to using
 * upsertMany before state is set
 *
 * @param adapter
 * @param state
 * @param data
 */
export const adapterUpsertOrSetAll = (
  adapter: EntityAdapter<any>,
  state: EntityState<any>,
  data: any[] | Record<EntityId, any>
) => {
  if (!data) return;

  // console.error("🌶 adapterUpsertOrSetAll", state, data);
  try {
    adapter.upsertMany(state, data);
  } catch {
    adapter.setAll(state, data);
  }
};
