import React from 'react';

export interface DonutValueType {
  value: number,
  color?: string,
}
export const DonutValue = ({
  value,
  color,
}: DonutValueType): JSX.Element => {
  return (
    <div
      style={{
        background: `
          radial-gradient(closest-side, white 79%, transparent 80% 100%),
          conic-gradient(${color} ${value}%, #D9D9D9 0)
        `,
        width: '64px',
        height: '64px',
        borderRadius: '50%'
      }}
    >
      <div
        style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          color: '#000000',
        }}
      >
        {value}%
      </div>
    </div>
  )
}
