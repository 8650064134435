/**
 * Replace common non-ascii characters similar ascii values
 * @param text any string that may contain non-ascii characters
 * @returns formatted text with non-ascii characters removed/replaced
 */
const convertToAscii = (text: string): string => {
  //replace non-ascii single quotations
  let filteredText = text.normalize('NFD').replace(/[\u2018-\u201A]/g, '\'');

  //replace non-ascii double quotations
  filteredText = filteredText.normalize('NFD').replace(/[\u201C-\u201E]/g, '"');

  //replace non-ascii dashes
  filteredText = filteredText.normalize('NFD').replace(/[\u2013-\u2014]/g, '-');

  //replace non-ascii ellipsis
  filteredText = filteredText.normalize('NFD').replace(/[\u2026]/g, '...');

  //remove accents marks from characters
  filteredText = filteredText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  //remove any other non-ascii characters that were unable to be replaced
  return filteredText.replace(/[^\x20-\x7E]+/g, '');
};


export default convertToAscii;
