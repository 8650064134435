/**
 * Library -> Analyst Surveys -> Components -> Select Question Group
 */

import React, { forwardRef, FC, useMemo, useState } from 'react';
import { useGetAnalystQuestionGroupsQuery } from 'api/crudGraphQL/analyst_surveys/getAnalystQuestionGroups';
import { AnalystQuestionGroup } from 'api/crudGraphQL/analyst_surveys/types';
import { Projection } from 'api/entityGraphQL';
import Select, { Props as SelectProps } from 'components/Select/Select';
import { BusinessType } from 'features/entitiesRedux';
import { getBusinessTypeLabel } from '../../utils';
import { Status } from '../Status';
import css from './SelectAnalystQuestionGroup.module.scss';

interface SelectAnalystQuestionGroupProjection extends Projection {
  id: true;
  name: true;
  status: true;
  business_type: BusinessType;
}

interface SelectAnalystQuestionGroupProps extends SelectProps {
  projection?: SelectAnalystQuestionGroupProjection;
  departmentId?: number;
  revision?: number | null;
}

export const SelectAnalystQuestionGroup: FC<SelectAnalystQuestionGroupProps> = forwardRef(({
  skeleton,
  departmentId,
  revision,
  projection = {
    id: true,
    name: true,
    status: true,
    business_type: {
      name: true,
      id: true,
    }
  },
  ...props
}, ref: React.Ref<HTMLInputElement>) => {
  const [filterValue, setFilterValue] = useState('');

  const {
    data: result,
    isLoading,
  } = useGetAnalystQuestionGroupsQuery({
    projection,
    page: 1,
    limit: 9999,
    ...(departmentId ? { department_id: departmentId } : {}),
    ...(revision !== undefined ? { revision } : {})
  }, {
    refetchOnMountOrArgChange: true
  });

  const { data: questionGroupsData } = result || { data: [] };

  // Group option groups by business type
  const questionGroupsByBusinessType = useMemo(
    () => questionGroupsData.reduce<{ [x: string]: AnalystQuestionGroup[] }>((businessTypes, questionGroup) => {
      const businessTypeName = getBusinessTypeLabel(questionGroup?.business_type?.id);
      const questionGroups: AnalystQuestionGroup[] = businessTypes[businessTypeName] ?? [];

      return {
        ...businessTypes,
        [businessTypeName]: [...questionGroups, questionGroup]
      };
    }, {}), [questionGroupsData, filterValue]);

  // Custom filtering because the Select component doesn't support filtering within Select.OptionGroup's
  const filteredQuestionGroupsByBusinessType = useMemo(() => Object.entries(questionGroupsByBusinessType).reduce<{ [x: string]: AnalystQuestionGroup[] }>((businessTypesObj, [businessTypeName, questionGroups]) => {
    if (filterValue) {
      const filteredQuestionGroups = questionGroups.filter(({ name }) => name.toLowerCase().includes(filterValue.toLowerCase()));

      if (!filteredQuestionGroups.length) {
        return businessTypesObj;
      }

      return {
        ...businessTypesObj,
        [businessTypeName]: filteredQuestionGroups
      };
    }

    return {
      ...businessTypesObj,
      [businessTypeName]: questionGroups
    };
  }, {}), [questionGroupsByBusinessType, filterValue]);


  const render = useMemo(() => Object.entries(filteredQuestionGroupsByBusinessType).map(([businessTypeLabel, questionGroups]) => (
    <Select.Group label={businessTypeLabel} key={businessTypeLabel}>
      {questionGroups.map((questionGroup: AnalystQuestionGroup) => (
        <Select.Option
          key={questionGroup.id}
          value={questionGroup.id || ''}
          disabled={questionGroup.status === 'deleted'}
        >
          <div className={css.inner}>
            <span className={css.label}>
              {questionGroup.name}
              <Status status={questionGroup.status} circle hideLabel />
            </span>
            <small className={css.small}>
              {getBusinessTypeLabel(questionGroup?.business_type?.id)}
            </small>
          </div>
        </Select.Option>
      ))}
    </Select.Group>
  )), [filteredQuestionGroupsByBusinessType]);

  return (
    <Select
      className={css.root}
      autoFocus
      showSearch
      skeleton={!questionGroupsData.length || isLoading || skeleton}
      ref={ref}
      {...props}
      searchValue={filterValue}
      filterOption={false}
      onSearch={setFilterValue}
      notFoundContent={`No question groups found for "${filterValue}"`}
    >
      {render}
    </Select>
  );
});

SelectAnalystQuestionGroup.displayName = 'SelectAnalystQuestionGroup';
