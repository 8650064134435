import { RootState } from 'app/store';
import { selectEntitiesDenormalized } from '../_entity/selectors';

export const selectContactsByIds = (contactIds: number[]) => (
  state: RootState
) => {
  const input = { contacts: contactIds };
  return selectEntitiesDenormalized(state, input).contacts;
};
export const selectContactById = (contactId: number) => (state: RootState) => {
  return selectContactsByIds([contactId])(state)[0];
};
