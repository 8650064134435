import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Box, VideoIcon, theme } from '@sprnova/nebula';
import css  from './VideoPlayer.module.scss';

interface VideoPlayerProps {
  videoSource?: string;
  currentSlide?: number;
  index?: number;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoSource, currentSlide, index }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    if (video && videoSource) {
      video.src = videoSource;
    }
  }, [videoSource]);

  const handlePlay = useCallback((action?: 'play' | 'pause') => {
    const video = videoRef.current;
    if (action) {
      if (action === 'play') {
        video?.play();
      } else {
        video?.pause();
      }
      return setPlaying(action === 'play');
    }
    if (video) {
      if (playing) {
        video.pause();
      } else {
        video.play();
      }
      setPlaying(!playing);
    }
  }, [playing]);

  useEffect(() => {
    if (currentSlide !== index) {
      handlePlay('pause');
    }
  }, [currentSlide, handlePlay, index]);

  if (!videoSource) return (
    <Box
      height={400}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      sx={{
        borderRadius: '24px',
        color: theme.variables.colors.primary.charcoal[400],
        backgroundColor: theme.variables.colors.neutrals.ghost[300],

        ...theme.variables.typography.h3,
      }}
    >
      <VideoIcon
        sx={{
          color: theme.variables.colors.primary.charcoal[300],
          width: '56px',
          height: '56px'
        }}
      />
      <span style={{ fontSize: '1vw' }}>
        No video available
      </span>
    </Box>
  );
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <video ref={videoRef} controls width='250' className={css.video}>
        Your browser does not support the video tag.
      </video>
    </Box>
  );
};

export default VideoPlayer;
