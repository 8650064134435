/**
 * SelectDiscoveryLeadSource
 */

import React, { forwardRef } from 'react';
import { EcommercePlatform } from '../../features/entitiesRedux';
import { convertToSlug } from '../../utils';
import Select, { Props as SelectProps } from '../Select/Select';

type DiscoveryLeadSource = {
  id: number,
  name: string,
  slug: string
}

interface Props extends SelectProps {
  fieldOptions: string[];
  valueKey?: keyof EcommercePlatform;
}

const SelectDiscoveryLeadSource = forwardRef(({ skeleton, fieldOptions, valueKey = 'id', ...props }: Props, ref: any) => {
  const discoveryLeadSources = fieldOptions?.map((opt, i) => {
    return {
      id: 1001 + i,
      name: opt,
      slug: convertToSlug(opt)
    };
  });
  return (
    <Select
      showSearch
      optionFilterProp="children"
      skeleton={skeleton}
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      {...props}
    >
      {discoveryLeadSources.map((discoveryLeadSource: DiscoveryLeadSource) => (
        <Select.Option key={discoveryLeadSource?.id} value={discoveryLeadSource[valueKey as keyof DiscoveryLeadSource]}>
          {discoveryLeadSource?.name}
        </Select.Option>
      ))}
    </Select>
  );
});

SelectDiscoveryLeadSource.displayName = 'SelectDiscoveryLeadSource';
export default SelectDiscoveryLeadSource;
