/**
 * SectionBuilder -> Fields -> MultiSelectField
 */

import React, { FC, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Divider,
  FormItem,
  Input,
  Select,
} from 'components';
import { FieldProps } from '../../types';
import PreferredChannelsField from '../PreferredChannelsField';
import css from './MultiSelectField.module.scss';

let index = 1;

const MultiSelectField: FC<FieldProps> = ({
  name,
  fields,
  type,
  form,
  format,
  options,
  placeholder,
  onValuesChange
}) => {

  // handle special case fields
  if (name === 'question_preferred_sales_channels') {
    return (
      <PreferredChannelsField
        name={name}
        fields={fields}
        type={type}
        form={form}
        format={format}
        options={options}
        placeholder={placeholder}
        onValuesChange={onValuesChange}
      />
    );
  }

  // local state variables for custom input and tracking the dropdown options
  const [customInputName, setCustomInputName] = useState<string>();
  const [items, setItems] = useState<Array<string | null>>([]);

  useEffect(() => {
    if (options) setItems(options);
  }, [options]);

  const addItem = () => {
    setItems([...items, customInputName || `Other channel ${index++}`]);
    setCustomInputName('');
  };
  
  return (
    <FormItem name={name} className={css.root}>
      <Select
        mode="multiple"
        allowClear
        showArrow
        placeholder={placeholder}
        dropdownRender={menu => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
              <Input
                placeholder="Enter custom option"
                style={{ flex: 'auto' }}
                value={customInputName}
                onChange={(e) => setCustomInputName(e.target.value)}
                onPressEnter={addItem}
                onKeyDown={e => e.stopPropagation()} // prevents removing selections when pressing delete key
              />
              <a
                style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                onClick={addItem}
              >
                <PlusOutlined /> Add channel
              </a>
            </div>
          </div>
        )}
      >
        {items?.map(item => {
          if (item !== null) {
            return (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            );
          }
        })}
      </Select>
    </FormItem>

  );
};

export default MultiSelectField;
