/**
 * Intelligence -> Horizons -> View Horizon -> Historical Overview
 */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, DownloadIcon, Grid } from '@sprnova/nebula';
import { Action } from 'api/accessControl/Action';
import { Resource } from 'api/accessControl/Resource';
import { useGetHorizonFunnelsQuery } from 'api/crudGraphQL/horizons/getHorizonFunnels';
import classNames from 'classnames';
import { AccessControl } from 'components/AccessControl';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import Card, { CardContent, CardHeader } from 'components/nebula/Card';
import { uniq, uniqBy } from 'lodash';
import moment from 'moment';
import exportCSV from 'utils/actions/exportCSV';
import trackExport from 'utils/actions/trackExport';
import { useAccount } from 'features/global';
import { LineChart, HorizonScatterChart } from 'features/intelligence/horizon/components';
import { BudgetTable } from 'features/intelligence/horizon/components/BudgetTable';
import { LineChartDataType } from 'features/intelligence/horizon/components/LineChart/types';
import { useGetHorizonByUrlParam } from 'features/intelligence/horizon/hooks/useGetHorizonByUrlParam';
import { HorizonLayout } from 'features/intelligence/horizon/HorizonLayout';
import { formatHorizonDataForCSVExport } from 'features/intelligence/horizon/utils/formatHorizonDataForCSVExport';
import { Spin } from 'components';
import HorizonSkeleton from '../BudgetScenarios/HorizonSkeleton';
import css from './HistoricalOverview.module.scss';

type HistoricalOverviewProps = {
  showTabs?: boolean;
}

const HistoricalOverview: FC<HistoricalOverviewProps> = ({ showTabs = true }) => {
  const [pollingInterval, setPollingInterval] = useState<number>(0);
  /**
   * Fetch horizon
   */
  const {
    data: horizon,
    isLoading: isLoadingHorizon,
    isFetching: isFetchingHorizon,
  } = useGetHorizonByUrlParam({
    pollingInterval,
    refetchOnMountOrArgChange: true,
  });

  const historicalActualsChannels = horizon?.historical_actuals?.map((actual) => actual?.channel);
  const channelNames = uniq(historicalActualsChannels?.map((i) => i?.name));
  const historicalActuals = horizon?.historical_actuals;
  const performanceActuals = horizon?.performances_actuals;

  const [exporting, setExporting] = useState<boolean>(false);
  const {
    data: funnels = [],
    isLoading: funnelsLoading,
  } = useGetHorizonFunnelsQuery({
    projection: { name: true, id: true }
  });

  /**
   * Mixpanel event tracking props
   */
  const mixpanel = useMixpanel();
  const { account } = useAccount();
  const options = useMemo(() => {
    return {
      userId: account?.id,
      userName: account?.name,
    };
  }, [account]);
  const mixpanelTitle = 'nova Forecast - Export Historical Overview';


  /**
   * If data is not present on load, refresh query every 5 seconds to check for new data
   */
  useEffect(() => {
    if (!horizon?.actuals?.length || !historicalActuals?.length || !performanceActuals?.length) {
      setPollingInterval(5000);
    } else {
      setPollingInterval(0);
    }
  }, [historicalActuals, horizon, performanceActuals]);

  /**
  * Create array containing all months in the horizon
  */
  const horizonActuals = horizon?.actuals && [...horizon?.actuals];
  const orderedDates = horizonActuals?.sort(function(a, b) {
    return Date.parse(a.date) - Date.parse(b.date);
  });

  const firstDate = orderedDates ? orderedDates?.[0]?.date : '';
  const length = horizonActuals ? horizonActuals?.length / 2 : 0;
  const months = useMemo(() => {
    return Array.from(Array(length), (_, i) => {
      return moment(firstDate)?.add(i, 'month')?.format('MMM YYYY');
    });
  }, [firstDate, length]);

  /**
 * Filter by actual type
 */
  const netSalesFilter = horizonActuals?.filter(actual => actual?.channel?.name === 'Net Sales');
  const adSpendFilter = horizonActuals?.filter(actual => actual?.channel?.name === 'Ad Spend');

  /**
  * Format data for currently selected scenario so it will work with the <LineChart> component
  */
  const formattedChartData = months?.map((month, i) => {
    if (netSalesFilter?.length && adSpendFilter?.length) {
      return {
        month: month,
        actuals_net_sales: netSalesFilter?.[i]?.value,
        actuals_ad_spend: adSpendFilter?.[i]?.value,
      };
    }
  });

  /**
   * Assemble the LineChart data object
   */
  const lineChartData: LineChartDataType = {
    data: formattedChartData,
    title: `${horizon?.model_client?.client?.name} | Actuals`,
    xAxisDataKey: 'month',
    lines: [
      { key: 'actuals_net_sales', dataKey: 'actuals_net_sales', name: 'Net Sales', stroke: '#FFA600' },
      { key: 'actuals_ad_spend', dataKey: 'actuals_ad_spend', name: 'Total Ad Spend', stroke: '#A94882' },
    ],
  };

  const handleExportCSV = useCallback(async () => {
    await setExporting(true);

    const title = 'Historical Overview';

    const formattedData = formatHorizonDataForCSVExport({
      title: `${horizon?.model_client?.client?.name} | Historical Spend`,
      moduleName: title,
      data: historicalActuals,
      channels: channelNames ? channelNames.map(channel => channel) : [],
      funnels: Array.from(funnels).map(funnel => funnel.name),
      firstDate: firstDate,
      length: length,
    });

    await exportCSV({
      data: formattedData ? formattedData : [],
      moduleName: title,
    }).finally(() => {
      setExporting(false);
      trackExport({ title: mixpanelTitle, type: 'CSV', mixpanel, options });
    });
  }, [horizon?.model_client?.client?.name, historicalActuals, channelNames, funnels, firstDate, length, mixpanelTitle, mixpanel, options]);

  const renderExportCSVButton = useMemo(() => {
    return (
      <Grid item>
        <Button
          onClick={handleExportCSV}
          size="large"
          startIcon={<DownloadIcon />}
          variant="secondary"
          disabled={exporting || funnelsLoading}
        >
          Export
        </Button>
      </Grid>
    );
  }, [handleExportCSV, exporting, funnelsLoading]);

  return (
    <HorizonLayout horizon={horizon} isLoading={isLoadingHorizon} showTabs={showTabs}>
      <AccessControl action={[Action.read]} resource={Resource.forecast}>
        {isLoadingHorizon ? <HorizonSkeleton title='Historical Overview'/>
          :
          <div className={css.root}>
            <div className={css.title}>
              Historical Overview
              <div className={css.subTitle}>
                An analysis of what happened in the past based on historical data
              </div>
            </div>
            <Card className={css.card}>
              {performanceActuals?.length ?
                <HorizonScatterChart
                  performances={horizon?.performances_actuals}
                  title={`${horizon?.model_client?.client?.name}: Media Channel - Tactic Performance`}
                  subtitle="Scoring Media Channel - Tactics Performance against Historical Cost & ROAS"
                />
                :
                <>
                  <CardHeader
                    title={`${horizon?.model_client?.client?.name}: Media Channel - Tactic Performance`}
                    sub="Scoring Media Channel - Tactics Performance against Historical Cost & ROAS"
                    className={css.cardHeader}
                  />
                  <Spin className={css.spin} loading fontSize={100} />
                </>
              }

            </Card>
            <Card className={css.card}>
              {horizonActuals?.length ?
                <LineChart
                  data={lineChartData}
                  isBiaxial
                />
                :
                <>
                  <CardHeader
                    title={`${horizon?.model_client?.client?.name} | Actuals`}
                    className={css.cardHeader}
                  />
                  <Spin className={css.spin} loading fontSize={100} />
                </>
              }
            </Card>
            {historicalActualsChannels && historicalActuals ?
              <Card className={classNames(css.card, css.tableContainer)}>
                <CardHeader
                  title={`${horizon?.model_client?.client?.name} | Historical Spend`}
                  sub={uniq(historicalActualsChannels?.map((channel) => channel?.name))?.join(', ')}
                  renderEnd={renderExportCSVButton}
                  className={css.cardHeader}
                />
                {horizonActuals?.length && performanceActuals?.length ?
                  <CardContent className={css.table}>
                    <BudgetTable
                      channels={uniqBy(historicalActualsChannels, 'id')}
                      data={historicalActuals}
                      funnels={funnels}
                      length={length}
                      startDate={firstDate}
                      historical
                    />
                  </CardContent>
                  :
                  <Spin className={css.spin} loading fontSize={100} />
                }

              </Card>
              :
              <></>
            }
          </div>
        }
      </AccessControl>
    </HorizonLayout>
  );
};

export default HistoricalOverview;
