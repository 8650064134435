import React, { memo, useEffect, useMemo, useRef } from 'react';
import { theme, Grid, Card, CardHeader, CardActions, EmptyState, InfoTooltip } from '@sprnova/nebula';
import { uniqBy } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { CustomerInsightsWidgets } from '../constants';
import { BulletChart } from './BulletChart';
import InsightList from './InsightList';
import { SelectLTV } from './SelectLTV';

const StyledTotalInsightsDiv = styled('div')(({ theme }) => ({
  whiteSpace: 'nowrap',
  paddingRight: '10px',
  textTransform: 'capitalize',
  color: theme?.variables?.colors?.primary.charcoal[300], //colors?.primary.charcoal[300]
}));

type WidgetCardProps = {
  data?: any;
  type?: 'brief' | 'detailed';
  title?: string;
  info?: string | JSX.Element;
  clientName?: string;
  extra?: JSX.Element;
  seeMore?: JSX.Element;
  scrollToList?: boolean;
  isLoading?: boolean;
}

const WidgetCard = ({
  data=[],
  type = 'brief',
  title,
  info,
  clientName,
  extra,
  seeMore,
  scrollToList,
  isLoading,
}: WidgetCardProps): JSX.Element => {

  //sorting function
  const sortedData = useMemo(() => {
    if (Array.isArray(data?.insights)) {
      /**
       * Remove duplicates and sort by percentage;
       * if there are duplicate labels, the graph will break
       */
      return uniqBy([...data?.insights].sort((a, b) => {
        const percentageA = a.percentage ? Number(a.percentage) : Number(a.ltv);
        const percentageB = b.percentage ? Number(b.percentage) : Number(b.ltv);
        return percentageB - percentageA;
      }), 'label');
    }
  }, [data]);

  //format the date range
  const dates = useMemo(() => {
    const { start_date, end_date } = data?.dates ?? {};
    if (!start_date || !end_date) return {start: undefined, end: undefined};
    return {
      start: moment(start_date)?.format('MMMM D, YYYY'),
      end: moment(end_date)?.format('MMMM D, YYYY')
    };
  }, [data]);

  //Ref for the graph and full list
  const graphRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const { colors } = useMemo(() => {
    return {
      colors: theme.variables.colors,
    };
  }, []);

  useEffect(() => {
    if (type == 'detailed' && scrollToList == true && listRef.current) {
      listRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (type == 'detailed' && scrollToList == false && graphRef.current) {
      graphRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToList]);

  // Calculate the height based on the number of bars and space between them in the bulletChart
  const bulletChartData = useMemo(() => sortedData?.slice(0, 10) || [], [sortedData]);
  const numberOfBars = useMemo(() => bulletChartData?.length || 1, [bulletChartData]);
  const spaceBetweenBars = 16;
  const barSize = 30;
  const chartHeight = useMemo(() => (barSize + spaceBetweenBars) * numberOfBars + 100, [numberOfBars]);
  const renderCardContent = useMemo(() => {
    if (data?.insights?.length === 0) {
      return (
        <EmptyState
          size="medium"
          title="No results found"
        />
      );
    }
    return (
      <div ref={graphRef}>
        {type === 'detailed' &&
        <>
          <div style={{height: `${chartHeight}px`, width: '100%', margin: '8px 0'}}>
            <BulletChart data={bulletChartData}/>
          </div>
          <div
            ref={listRef}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '80px 0 8px 0'
            }}
          >
            <div
              style={{
                color: '#2C2C2C',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '20px'
              }}
            >
              Full List
            </div>
            <div
              style={{
                color: '#838383',
                fontFamily: 'Inter',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '14px'
              }}
            >
              {`${data?.insights?.length} total insights`}
            </div>
          </div>
        </>
        }
        {type === 'brief' && <InsightList data={sortedData?.slice(0, 5)}/>}
        {type === 'detailed' && <InsightList data={sortedData}/>}
        {extra}
      </div>
    );
  }, [type, chartHeight, bulletChartData, data, sortedData, extra]);

  const renderSelectLTV = useMemo(() => {
    if (title !== CustomerInsightsWidgets.ltvTitle) return;

    return (
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{ margin: '20px 0px 20px 10px' }}
      >
        <SelectLTV onSubmit={undefined} isLoading={isLoading} />
      </Grid>
    );
  }, [isLoading, title]);

  return (
    <Card
      header={
        <CardHeader
          title={
            <Grid container alignItems='center' sx={{ marginBottom: title === CustomerInsightsWidgets.ltvTitle ? '20px' : '' }}>
              <Grid item>
                {type === 'detailed' ? `${clientName}'s Customers' ${title}` : title}
              </Grid>
              {
                info &&
                <div>
                  <InfoTooltip
                    iconSize='medium'
                    placement='top-start'
                    heading={title}
                    content={info}
                    sx={{
                      marginLeft: '16px',
                    }}
                  />
                </div>
              }
              <Grid item>
                {renderSelectLTV}
              </Grid>
            </Grid>
          }
          description={
            (!dates?.start) || (!dates?.end)  ?
              <></>
              :
              `${dates?.start} - ${dates?.end}`
          }
          end={
            <CardActions
              actions={[
                <div key={'action'} style={{display: 'flex', alignItems: 'center'}}>
                  <StyledTotalInsightsDiv theme={{ colors }}>
                    {`${data?.insights?.length} total insights`}
                  </StyledTotalInsightsDiv>
                  {seeMore}
                </div>
              ]}
            />
          }
        />
      }
    >
      {renderCardContent}
    </Card>
  );
};

export default memo(WidgetCard);
