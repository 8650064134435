import { paginationProjection } from 'api/crudGraphQL';
import { Contact, YesNo } from 'features/entitiesRedux';
import { defaultContactProjection } from 'features/entitiesRedux/models/contact/projections';
import { PaginatedResponse, Pagination, Projection } from '../types';
import { filterUndefinedNullEmpty, getClient, toGraphQL } from '../utils';

export const fetchContactsQuery = async ({
  args: { id, name, client_id } = {},
  pagination: { page, limit } = { page: 1, limit: 10, sort: ['id'] },
  projection,
}: {
  args?: { id?: number; name?: string; client_id?: number };
  pagination?: Pagination;
  projection?: Projection;
} = {}): Promise<PaginatedResponse & { contacts: Partial<Contact>[] }> => {
  // GraphQL client.
  const graphQlClient = getClient();

  const __args = { page, limit, id, name, client_id};
  const query = toGraphQL({
    query: {
      contacts: {
        __args: filterUndefinedNullEmpty(__args),
        data: projection || defaultContactProjection,
        ...paginationProjection,
      },
    },
  });

  const response = await graphQlClient.sendRequest(query);
  const {
    contacts: { data: contacts, ...meta },
  } = response;
  return {
    contacts,
    ...meta,
  };
};

export const fetchContactByIdQuery = async (
  id: number,
  { projection }: { projection?: Projection } = {}
)=>  {
  const { contacts } = await fetchContactsQuery({
    args: { id },
    pagination: { page: 1, limit: 1 },
    projection,
  });
  return { contacts };
};

export const updateContactMutation = async (
  {
    id,
    name,
    title,
    email,
    phone,
    is_decision_maker,
    notes,
  }:
    {
      id: number;
      name?: string | null;
      title?: string | null;
      email?: string;
      phone?: string;
      is_decision_maker?: YesNo;
      notes?: string | null;
    },
  { projection }: { projection?: Projection } = {}
)=>  {
  // GraphQL client.
  const graphQlClient = getClient();

  const __args = { id, name, title, email, phone, is_decision_maker, notes };
  const query = toGraphQL({
    mutation: {
      updateContact: {
        __args: filterUndefinedNullEmpty(__args),
        ...(projection || defaultContactProjection),
      },
    },
  });

  const response = await graphQlClient.sendRequest(query);
  return { contacts: [response.updateContact] };
};

export const createContactMutation = async (
  {
    client_id,
    name,
    title,
    email,
    phone,
    is_decision_maker,
    notes,
  }:
    {
      client_id: number;
      name?: string | null;
      title?: string | null;
      email?: string;
      phone?: string;
      is_decision_maker?: YesNo;
      notes?: string | null;
    },
  { projection }: { projection?: Projection } = {}
)=>  {
  const __args = {
    client_id,
    name,
    title,
    email,
    phone,
    is_decision_maker,
    notes,
  };
  // GraphQL client.
  const graphQlClient = getClient();

  const query = toGraphQL({
    mutation: {
      createContact: {
        __args: filterUndefinedNullEmpty(__args),
        ...(projection || defaultContactProjection),
      },
    },
  });
  const response = await graphQlClient.sendRequest(query);
  return { contacts: [response.createContact] };
};

export const deleteContactMutation = async (id: number)=>  {
  // GraphQL client.
  const graphQlClient = getClient();

  const query = toGraphQL({
    mutation: {
      deleteContact: {
        __args: { id },
        id: true,
      },
    },
  });
  const response = await graphQlClient.sendRequest(query);
  return { contacts: [response.deleteContact] };
};

export const saveContactsMutation = async ({
  client_id,
  contacts,
  delete_missing,
}: {
  client_id: number;
  contacts: Contact[];
  delete_missing: string;
}): Promise<void> => {
  // GraphQL client.
  const graphQlClient = getClient();

  const __args = {
    client_id,
    contacts,
    delete_missing,
  };

  const query = toGraphQL({
    mutation: {
      saveContacts: {
        __args: filterUndefinedNullEmpty(__args),
        id: true,
      },
    },
  });
  await graphQlClient.sendRequest(query);
};
