import { Projection } from 'api/entityGraphQL/types';
import { NovaAIChatType } from 'features/entitiesRedux/models/nova_ai';
import { basePublicApi } from '../basePublicApi';
import { cleanArguments } from 'api/crudGraphQL/utils';

export type GetNovaAIChatHistoryPublicQuery = {
  session_guid: string;
  projection?: Projection;
};

export type GetNovaAIChatHistoryPublicResult = NovaAIChatType[];

export const GET_NOVA_AI_CHAT_HISTORY_PUBLIC_ENDPOINT_KEY = 'getNovaAIChatHistoryPublic';

export const { useLazyGetNovaAIChatHistoryPublicQuery } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_NOVA_AI_CHAT_HISTORY_PUBLIC_ENDPOINT_KEY]: build.query<GetNovaAIChatHistoryPublicResult, GetNovaAIChatHistoryPublicQuery>({
      transformResponse: (response: { nova_ai_chat_history: GetNovaAIChatHistoryPublicResult }) => response.nova_ai_chat_history,
      query: ({
        projection = {
          responses: {
            response: true,
          }
        },
        ...args
      }) => ({
        body: {
          query: {
            nova_ai_chat_history: {
              __args: cleanArguments(args),
              ...projection
            },
          }
        }
      })
    }),
  }),
});
