import React, { memo, useCallback, useMemo } from 'react';
import { Grid, Tag, Typography } from '@sprnova/nebula';
import { PacingGoal } from 'api/crudGraphQL/scoreboards/types';
import NebulaDatasourceLogo from 'components/NebulaDatasourceLogo/NebulaDatasourceLogo';
import { formatValue } from './utils';
import NoPlatformTooltip from '../KPIs/components/NoPlatformTooltip';
import { FormattedDataType } from '../KPIs/components/Scorecard';

const platformStatusFailed: { [key: string]: boolean } = {
  broken: true,
  failed: true,
  error: true,
  auth: true,
  active: false,
  completed: false,
  synced: false,
  in_progress: false,
  paused: true,
};

type PlatformsProps = {
  data?: FormattedDataType['formattedPlatform'][] | PacingGoal[];
  statusOnly?: boolean;
  format?: string | null;
  required_categories?: string[];
};

const Platforms: React.FC<PlatformsProps> = ({ data = [], statusOnly = false, format, required_categories }) => {
  const renderValue = useCallback(({ value, last_refresh_status }) => {
    if (!platformStatusFailed[last_refresh_status] && !statusOnly) {
      return formatValue(value, format);
    }
    return <Tag height='24px' color={platformStatusFailed[last_refresh_status] ? 'red' : 'green'}>{last_refresh_status.toUpperCase()}</Tag>;
  }, [format, statusOnly]);

  const renderPlatforms = useMemo(() => {
    if (!data || data.length === 0) {
      return <NoPlatformTooltip required_categories={required_categories}/>;
    }

    // Return Formatted platform title
    const getPlatformTitle = (title: string): string => {
      switch (title) {
        case 'ga4':
          return 'Google Analytics 4';
        case 'Facebook Marketing':
          return 'Meta Ads';
        case 'Microsoft Bing Ads':
          return 'Microsoft Ads';
        case 'pinterest':
          return 'Pinterest Ads';
        default:
          return title ?? 'Uncategorized';
      }
    };

    return data.map((d: any, index) => {
      const title = getPlatformTitle(d.title);
      return (
        <Grid
          container
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          key={`Platforms-${index}`}
          sx={{
            width: '100%',
            paddingBottom: '8px',
          }}
        >
          <Grid item>
            <Grid item display={'flex'} alignItems='center' justifyContent='center'>
              <NebulaDatasourceLogo minWidth='0' minHeight='0' slug={d.platform} />
              <Typography variant='caption' sx={{ padding: `${d.platform ? '0 8px' : 0 }` }}>
                {title}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant='caption' sx={{ paddingRight: '8px' }} fontWeight='500'>
              {renderValue(d)}
            </Typography>
          </Grid>
        </Grid>
      );
    });
  }, [data, renderValue, required_categories]);

  return (
    <Grid
      item
      sx={{ width: '100%' }}
    >
      {renderPlatforms}
    </Grid>
  );
};

export default memo(Platforms);
