import React, { forwardRef } from 'react';
import { Radio as AntdRadio } from 'antd';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';
import classNames from 'classnames';
import { SkeletonInput } from '../SkeletonInput';
import css from './RadioButton.module.scss';

interface Props extends RadioButtonProps {
  className?: string;
  skeleton?: boolean;
}

const RadioButton = forwardRef(
  ({ className, skeleton = false, ...props }: Props, ref: any) => {
    // Show skeleton when loading is true
    if (skeleton) return <SkeletonInput loading={false} active={true} />;

    return (
      <AntdRadio.Button
        {...props}
        className={classNames(className, css.root)}
        ref={ref}
      />
    );
  }
);

RadioButton.displayName = 'RadioButton';

export default RadioButton;
