import React from 'react';
import { PageHero } from 'layouts/components';
import CreateScoreboardForm from './CreateScoreboardForm/CreateScoreboardForm';

/**
 * Renders the page for creating a new scoreboard, including a header and form.
 *
 * @returns {JSX.Element} The rendered component
 */
const CreateScoreboardPage = (): JSX.Element => {
  return (
    <>
      {/* Page header */}
      <PageHero
        title={'Create a New Scoreboard'}
      />

      {/* Create new Scoreboard form */}
      <CreateScoreboardForm />
    </>
  );
};

export default CreateScoreboardPage;
