import { inherits } from 'util';
import React, { forwardRef, ReactNode, useCallback, useMemo } from 'react';
import { Autocomplete, AutocompleteOwnerState, AutocompleteProps, AutocompleteRenderGetTagProps, createTheme, TextField, ThemeProvider } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import classNames from 'classnames';

/**
 * Dropdowns should be used when there is an exhaustive list of options to choose from.
 * Functionalities of these dropdowns can include searchable (combobox) fields and multi-select.
 */

export type Option = {
  name: string;
  value: string | number;
}

export type DropdownType = SelectProps & Partial<AutocompleteProps<any, any, any, any>> & {
  type?: 'select' | 'autocomplete'
  label?: string;
  error?: boolean;
  errorText?: string;
  required?: boolean;
  placeholder?: string;
  formControlFullWidth?: boolean;
  value?: any;
  help?: ReactNode;
  options: Record<string, unknown>[] | any[] | any;
  loading?: boolean;
  handleChange?: (event: SelectChangeEvent<any>, child: ReactNode) => void
  handleSelect?: (event: React.SyntheticEvent, value: any | Array<any>) => void;
  containerClassName?: string;
  filterSelectedOptions?: boolean;
  keyName?: string; // key of the values that will be used to be displayed in the MenuItem
  keyValue?: string; // key of the values that will be used for the value of the MenuItem
  /**
   * By default, the component accepts the following options structures:
   * Default option structure:
   * interface AutocompleteOption {
      label: string;
     }
   * However, you can use different structures by providing a getOptionLabel prop.
   * So if you want another key than 'label', that's what keyLabel prop is for.
   */
  keyLabel?: string;
}

export const Dropdown = ({
  type,
  label,
  placeholder,
  value,
  help,
  options,
  loading,
  error,
  errorText,
  required=false,
  handleChange,
  handleSelect,
  containerClassName,
  formControlFullWidth = true,
  keyName = 'name',
  keyValue = 'value',
  keyLabel = 'label',
  ...props }: DropdownType): JSX.Element => {
  const optionsSingleValues = typeof options?.[0] === 'string' || options?.[0] === 'boolean' || options?.[0] === 'number';

  const dropdownStyles = useMemo(() => ({
    background: '#F4F4F4',
    scrollbarColor: '#01579B transparent',
    '::-webkit-scrollbar': {
      backgroundColor: '#F4F4F4',
      width: '4px',
      height: '0px',
      borderBottomRightRadius: '4px',
      borderTopRightRadius: '4px'
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#01579B',
      borderRadius: '10px'
    }
  }), []);

  const dropdownTheme = useMemo(() => createTheme({
    components: {
      MuiPopover: {
        styleOverrides: {
          paper: {
            ...dropdownStyles
          }
        }
      },
      MuiList: {
        styleOverrides: {
          root: {
            maxHeight: '230px'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&:hover': {
              background: '#E1DAFF',
            },
            '&:active, &.Mui-selected': {
              background: '#4A1FFF',
              color: 'white',
            },
          },
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          listbox: {
            ...dropdownStyles,
            maxHeight: '230px'
          },
          option: {
            '&:hover': {
              backgroundColor: '#E1DAFF !important',
            },
            '&[aria-selected=true]': {
              backgroundColor: '#4A1FFF !important',
              color: 'white',
            }
          },
          root: {
            '& .MuiAutocomplete-popupIndicator': {
              color: 'black',
            },
            'button.MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator': {
              color: '#868686'
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              borderColor: error ? '#C62828' : inherits,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: error ? '#C62828' : '#0288D1'
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: error ? '#C62828' : '#0288D1'
            },
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: error ? '#C62828' : undefined,
            '&.Mui-focused': {
              color: error ? '#C62828' : undefined
            }
          }
        },
      }
    }
  }), [dropdownStyles, error]);

  /**
   * This function is used to render the options of the dropdown depending on the type of options.
   * If the options are single values, then it will render the options as it is.
   * If the options are objects, then it will render the options using the keyName and keyValue props.
   */
  const renderOptions = useCallback(() => {
    if (optionsSingleValues) {
      return options?.map((option: any, index: number) => <MenuItem value={option} key={`dropdown-option-${index}`}>{option}</MenuItem>);
    } else {
      return options?.map((option: any, index: number) => {
        if (typeof option[keyValue] === 'string' || typeof option[keyValue] === 'number') {
          return <MenuItem value={option[keyValue]} key={`dropdown-option-${index}`}>{option[keyName]}</MenuItem>;
        } else {
          return option;
        }
      });
    }
  }, [keyName, keyValue, options, optionsSingleValues]);

  const createClassName = useCallback((): string => {
    if (typeof label === 'string') {
      return label?.replace(/\s+/g, '-').toLowerCase();
    }
    return '';
  }, [label]);

  const renderInput = useCallback((params: any) => <TextField {...params} label={label} placeholder={placeholder} />, [label, placeholder]);
  const getOptionLabel = useCallback((option: any) => option[keyLabel] ?? option, [keyLabel]);

  const autocomplete = useMemo(() => <Autocomplete
    loadingText='Loading...'
    defaultValue={props.defaultValue || null}
    value={value || ''}
    options={options}
    renderInput={renderInput}
    onChange={handleSelect}
    className={classNames(props.className, createClassName())}
    getOptionLabel={getOptionLabel}
    {...props}
  />
  , [createClassName, getOptionLabel, handleSelect, options, props, renderInput, value]);

  const select = useMemo(() => <Select
    value={value || ''}
    label={label}
    onChange={handleChange}
    className={classNames(props.className, createClassName())}
    {...props}
  >
    {renderOptions()}
  </Select>, [createClassName, handleChange, label, props, renderOptions, value]);

  const renderComponent = useCallback((): JSX.Element => {

    switch (type) {
      case 'autocomplete':
        return (
          autocomplete
        );
      case 'select':
      default:
        return (
          select
        );
    }
  }, [autocomplete, select, type]);

  let errorTextToShow = errorText;

  if(required && error && !errorText) {
    errorTextToShow = 'This is a required field';
  }

  return (
    <ThemeProvider theme={dropdownTheme}>
      <FormControl fullWidth={formControlFullWidth} className={containerClassName}>
        {
          type !== 'autocomplete'
            ? <InputLabel>{label}</InputLabel>
            : <></>
        }
        {renderComponent()}
        <FormHelperText error={error}>{errorTextToShow}</FormHelperText>
        <FormHelperText>{help}</FormHelperText>
      </FormControl>
    </ThemeProvider>
  );
};
