import { novaGraphQLClient, Projection } from 'api/entityGraphQL';

const defaultProjection = novaGraphQLClient.defaultAuditProjection;

export const projection: Projection = {
  ...defaultProjection,
  id: true,
  name: true,
  notes: true,
  status: true,
  client: {
    ...defaultProjection.client,
    accounts: {
      ...defaultProjection.client.accounts,
      value2: true,
      progress: true,
      type: true,
      slug: true,
    },
    integration_requests: {
      requests: {
        data_source_id: true,
        access_request_status: true,
        manual_access_request_status: true,
      }
    },
  },
  discovery: {
    ...defaultProjection.discovery,
    id: true,
    name: true,
    status: true,
    score: true,
    notes: true,
    client: {
      id: true,
      name: true,
      website: true,
      logo: true,
      business_type: { name: true },
      industry: { name: true },
    },
    author: { id: true, name: true },
    business_type: { id: true, name: true },
    questions: { id: true, answer: true },
    analyst_score: true,
    analyst_score_max: true,
    opportunity_score: true,
    services: {
      ...defaultProjection.discovery.services,
      id: true,
      name: true,
      is_active: true,
      in_survey: true,
      department: {
        id: true,
      },
    },
    departments: {
      ...defaultProjection.discovery.departments,
      id: true,
      name: true,
      score: true, // error when missing
      max: true, // error when missing
      opportunity: true,
      in_survey: true,
      revision: true,
      revisions: true,
      services: {
        id: true,
        name: true,
        department: { id: true },
      },
      scores: {
        ...defaultProjection.discovery.departments.scores,
        id: true,
        name: true,
        score: true, // error when missing
        max: true,
        is_current: true,
        services: {
          id: true,
          name: true,
          data_sources: { id: true, name: true, slug: true },
        },
        buckets: {
          ...defaultProjection.discovery.departments.scores.buckets,
          id: true,
          name: true,
          score: true, // error when missing
          max: true,
          criteria: {
            id: true,
            name: true,
            score: true,
          },
        },
      },
    },
  },
  audit: {
    id: true,
    name: true,
    discovery: {
      analyst_score: true,
      analyst_score_max: true,
      departments: {
        id: true,
        name: true,
        score: true,
        max: true,
        in_survey: true,
        scores: {
          id: true,
          name: true,
          score: true,
          max: true,
          buckets: {
            id: true,
            name: true,
            score: true,
            max: true,
          },
        },
      },
    },
  },
  automated_scores: {
    id: true,
    status: true,
    integration_id: true,
    integration: {
      slug: true,
    },
  }
};
