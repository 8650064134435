import React from 'react';
import loadable from '@loadable/component';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';

const LoadableComponent = loadable(
  () => import('./ClientHomePage'),
  {
    fallback: <PageSkeleton />
  }
);

export { LoadableComponent as ClientHomePage };
