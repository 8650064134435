import { Box } from '@sprnova/nebula';
import React, { memo } from 'react';

type Props = {
    variant?: 'dark' | 'light'
}

const ChatBubble: React.FC<React.PropsWithChildren<Props>> = ({ variant, children }) => {

    const defaultVariant = 'light';

    const variantToStyleMap = {
      dark: {
        borderColor: '#4A1FFE',
        backgroundColor: '#D4C9EF',
      },
      light: {
        borderColor: '#D9D9D9',
        backgroundColor: '#FFFFFF',
      },
    };

    const variantStyle = variantToStyleMap[variant ?? defaultVariant];

    return (
        <Box sx={{
            p: 1.5,
            mb: 0.5,
            borderRadius: '8px',
            borderWidth: '1px',
            borderStyle: 'solid',
            ...variantStyle,
        }}>
        {children}
    </Box>
    );
}

export default memo(ChatBubble);
