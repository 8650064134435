import React, { FC } from 'react';
import loadable from '@loadable/component';
import { OverviewCards } from 'components/OverviewCards';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';
import { useTalentMenuItems } from './useTalentMenuItems';

const Fallback: FC = () => {
  const items = useTalentMenuItems();

  return (
    <PageSkeleton>
      <OverviewCards
        skeleton
        skeletonCount={items.length}
      />
    </PageSkeleton>
  );
};

const LoadableComponent = loadable(
  () => import('./TalentCentralOverviewPage'),
  {
    fallback: <Fallback />
  }
);

export { LoadableComponent as TalentCentralOverviewPage };
