import React, { useCallback, useEffect, useMemo, useState, memo } from 'react';
import { createFilterOptions } from '@mui/material';
import { Autocomplete, Box, useSnackbar } from '@sprnova/nebula';
import { useLazyGetSalesforceOpportunitiesQuery } from 'api/crudGraphQL/salesforce_opportunities/getSalesforceOpportunities';
import { SalesforceOpportunity } from 'features/entitiesRedux/models/salesforce';
import { salesforceOpportunityIsClosed } from 'features/strategies/utils/salesforceOpportunityIsClosed';
import { SalesforceOpportunityOptionDisplayName } from './components';
import { salesforceOpportunityProjection } from './projection';
import { ContractDetailsFieldNames } from '../constants';
import { AutoCompleteFormOption, SalesforceValuesObjectType } from '../ContractDetailsEdit/ContractDetailsEdit';

export type SelectSalesforceOpportunityProps = {
   salesforceClientId?: string | null;
   className?: string;
   isSubmitting?: boolean;
   isLoading?: boolean;
   setSalesforceValuesObject: React.Dispatch<React.SetStateAction<SalesforceValuesObjectType>>;
   salesforceValuesObject: SalesforceValuesObjectType;
   salesforceAccountLinkUpdateInProgress?: boolean;
   /**
    * Flag to know whether or not we need to query all opportunities or only the ones that are not already linked to other Blueprints
    */
   withLinkedOpportunity?: boolean;
   autoCompleteProps?: Record<string, any>;
   opportunities?: SalesforceOpportunity[] | null;
 };

const SelectSalesforceOpportunity = ({
  salesforceClientId,
  className,
  isSubmitting = false,
  isLoading = false,
  setSalesforceValuesObject,
  salesforceValuesObject,
  salesforceAccountLinkUpdateInProgress = false,
  withLinkedOpportunity = true,
  autoCompleteProps,
  opportunities,
}: SelectSalesforceOpportunityProps): JSX.Element => {
  const [salesforceOpportunities, setSalesforceOpportunities] = useState<SalesforceOpportunity[]>([]);
  const [fetchSalesforceOpportunities, { isFetching: isFetchingSalesforceOpportunities }] = useLazyGetSalesforceOpportunitiesQuery();
  const { addSnackbar } = useSnackbar();

  const getSalesforceOpportunities = useCallback(async (accountId: string) => {
    await fetchSalesforceOpportunities({
      account_id: accountId,
      with_linked: withLinkedOpportunity,
      projection: salesforceOpportunityProjection
    }).unwrap()
      .then(async (salesforceOpportunitiesData: SalesforceOpportunity[]) => {
        setSalesforceOpportunities(salesforceOpportunitiesData);
      }).catch((error: any) => {
        const errorMessage = 'Error fetching salesforce opportunities.';
        addSnackbar({
          variant: 'error',
          message: errorMessage,
        });
        console.error(errorMessage, error);
      });
  }, [addSnackbar, fetchSalesforceOpportunities, withLinkedOpportunity]);

  /**
   * Used to limit the results of the AutoComplete dropdowns that don't have virtualization setup
   * This prevents the UI from freezing with large dropdown option lists
   */
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    limit: 200
  });

  /**
   * Salesforce opportunity options: Filter out all closed opp
   */
  const salesforceOpportunityOptions = useMemo(() => {
    return salesforceOpportunities ? salesforceOpportunities.reduce((salesforceOpportunitiesOptions: AutoCompleteFormOption[] | [], salesforceOpportunity) => {
      if (salesforceOpportunity && salesforceOpportunity.name && !salesforceOpportunityIsClosed(salesforceOpportunity)) {
        return [
          ...salesforceOpportunitiesOptions,
          { id: salesforceOpportunity.id, value: salesforceOpportunity.name, label: salesforceOpportunity.name, display_value: <SalesforceOpportunityOptionDisplayName salesforceOpportunity={salesforceOpportunity} />, salesforce_stage: salesforceOpportunity.stage }
        ];
      }
      return salesforceOpportunitiesOptions;
    }, []) : [];
  }, [salesforceOpportunities]);

  /**
   * Fetch salesforce opportunities when salesforce client is present
   */
  useEffect(() => {
    if (salesforceClientId && !opportunities) {
      getSalesforceOpportunities(salesforceClientId);
    }
  }, [fetchSalesforceOpportunities, getSalesforceOpportunities, opportunities, salesforceClientId]);

  useEffect(() => {
    if (opportunities) {
      setSalesforceOpportunities(opportunities);
    }
  }, [opportunities]);

  const handleSelectSalesForceOpportunity = useCallback((salesforceOpportunityObject: AutoCompleteFormOption) => {
    if (salesforceOpportunityObject === null) {
      setSalesforceValuesObject((prevValue: SalesforceValuesObjectType) => ({ ...prevValue, salesforce_opportunity_id: null, salesforce_opportunity_name: null, salesforce_stage: null }));
    } else {
      if (salesforceOpportunities && salesforceOpportunities.length > 0) {
        let salesforceOpportunity: SalesforceOpportunity | undefined = undefined;
        if (salesforceOpportunityObject) {
          salesforceOpportunity = salesforceOpportunities.find((salesforceOpportunity: SalesforceOpportunity) => salesforceOpportunity.name === salesforceOpportunityObject.value) ?? undefined;
        }
        if (salesforceOpportunity) {
          setSalesforceValuesObject((prevValue: SalesforceValuesObjectType) => ({ ...prevValue, salesforce_opportunity_name: salesforceOpportunity?.name, salesforce_opportunity_id: salesforceOpportunity?.id, salesforce_stage: salesforceOpportunity?.stage }));
        }
      }
      setSalesforceValuesObject((prevValue: SalesforceValuesObjectType) => ({ ...prevValue, salesforce_opportunity_name: salesforceOpportunityObject?.label, salesforce_opportunity_id: salesforceOpportunityObject?.id, salesforce_stage: salesforceOpportunityObject?.salesforce_stage }));
    }
  }, [salesforceOpportunities, setSalesforceValuesObject]);

  const disableOpportunity = salesforceAccountLinkUpdateInProgress || isSubmitting || !salesforceClientId || (salesforceOpportunityOptions.length === 0 && !isFetchingSalesforceOpportunities);

  /**
   * Variable to determine if the dropdown is loading.
   * Order of priority:
   * - isLoading from props
   * - opportunities from props
   * - if fetching salesforce opportunities within this component is in progress
   */
  const dropdownIsLoading = useMemo(() => {
    if (isLoading) return true;
    if (opportunities) return false;
    if (isFetchingSalesforceOpportunities) return true;
  }, [isFetchingSalesforceOpportunities, isLoading, opportunities]);

  return (
    <Autocomplete
      id="salesforce-opportunity-id"
      className={className}
      // only enable when salesforce account is selected
      disabled={disableOpportunity || autoCompleteProps?.disabled}
      defaultValue={salesforceValuesObject.salesforce_opportunity_id}
      filterOptions={filterOptions}
      isOptionEqualToValue={(option, value): boolean => option?.value === value}
      label={ContractDetailsFieldNames.SalesforceOpportunity}
      loading={dropdownIsLoading}
      options={salesforceOpportunityOptions}
      onChange={(event, salesforceOpportunityObject): void => handleSelectSalesForceOpportunity(salesforceOpportunityObject)}
      // needed as we have to render the options with a custom ReactNode
      value={salesforceValuesObject.salesforce_opportunity_name}
      renderOption={(props, option): JSX.Element => (
        <Box
          component='li'
          {...props}
        >
          {option.display_value}
        </Box>
      )}
      {...autoCompleteProps}
    />
  );
};

SelectSalesforceOpportunity.displayName = 'SelectSalesforceOpportunity';
export default memo(SelectSalesforceOpportunity);
