import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'app/store';
import { getAvailableDataSources, } from './actions';
import { DataSource } from './data_source';
import { sortByRequestable, sortByStatus } from './helpers';
import { selectAllDatasources } from './selectors';

export const useDataSources = (filter?: 'external'): DataSource[] => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getAvailableDataSources());
  },[]);

  const datasources = useSelector(selectAllDatasources);

  const filteredDatasources = filter ? sortByRequestable(datasources) : sortByStatus(datasources);

  return filteredDatasources;
};
