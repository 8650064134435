/**
 * SelectLeadSource
 */

import React, { forwardRef } from 'react';
import { LeadSource } from 'features/entitiesRedux';
import Select, { Props as SelectProps } from '../Select/Select';
import { GetLeadSourcesQuery, useGetLeadSourcesQuery } from 'api/crudGraphQL/lead_sources/getLeadSources';

interface SelectLeadSourceProps extends SelectProps {
  excludeSlugs?: string[]; // exclude certain lead sources by slug
  args?: Partial<GetLeadSourcesQuery>;
}

const SelectLeadSource = forwardRef(({ excludeSlugs, skeleton, args, ...props }: SelectLeadSourceProps, ref: any) => {
  /**
   * RTK fetch Lead Source
   */
    const { data: lead_sources = [], isLoading } = useGetLeadSourcesQuery({
      projection: { id: true, name: true },
      /** Override default projection with passed args */
      ...(args ?? {}),
    });
  const options = lead_sources?.filter(source => !excludeSlugs?.includes(source?.slug));

  return (
    <Select
      showSearch
      optionFilterProp="children"
      skeleton={isLoading || skeleton}
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      {...props}
    >
      {options.map((lead_source: LeadSource) => (
        <Select.Option key={lead_source.id} value={lead_source.id}>
          {lead_source.name}
        </Select.Option>
      ))}
    </Select>
  );
});

SelectLeadSource.displayName = 'SelectLeadSource';
export default SelectLeadSource;
