import React, { memo } from 'react';
import { Tag, TagColor } from '@sprnova/nebula';
import { SalesforceOpportunity } from 'features/entitiesRedux';
import { SALESFORCE_OPPORTUNITY_STAGE } from 'features/strategies/constants';
import css from './SalesforceOpportunityOptionDisplayValue.module.scss';

export type SalesforceOpportunityOptionDisplayValueProps = {
  salesforceOpportunity: Partial<SalesforceOpportunity>
};

const SalesforceOpportunityOptionDisplayValue = ({
  salesforceOpportunity,
}: SalesforceOpportunityOptionDisplayValueProps): JSX.Element => {

  /**
   * Function to determine the color of the stage tag based on the salesforce stage of the salesforce opportunity
   * @param stage Salesforce opportunity stage
   */
  const stageColor = (stage: string | undefined): TagColor =>{
    // Grey Closed
    if(stage === SALESFORCE_OPPORTUNITY_STAGE.ClosedWon || stage === SALESFORCE_OPPORTUNITY_STAGE.ClosedLost || stage === SALESFORCE_OPPORTUNITY_STAGE.Disqualified) return 'grey';
    // Green Open
    return 'green';
  };

  /**
   * Function to determine the text of the stage tag based on the salesforce stage of the salesforce opportunity
   * @param stage Salesforce opportunity stage
   * @returns
   */
  const stageText = (stage: string | undefined): string =>{
    // Closed
    if(stage === SALESFORCE_OPPORTUNITY_STAGE.ClosedLost || stage === SALESFORCE_OPPORTUNITY_STAGE.Disqualified) return 'Closed/Lost';
    else if(stage === SALESFORCE_OPPORTUNITY_STAGE.ClosedWon) return 'Closed/Won';
    // Open
    return 'Open';
  };

  return (
    <div className={css.salesforceOpportunityOptionDisplayValue}>
      <span>{salesforceOpportunity.display_value}</span>
      <Tag color={stageColor(salesforceOpportunity.stage)} sx={{ float: 'right'}}>{stageText(salesforceOpportunity.stage)}</Tag>
    </div>
  );
};

SalesforceOpportunityOptionDisplayValue.displayName = 'SalesforceOpportunityOptionDisplayValue';
export default memo(SalesforceOpportunityOptionDisplayValue);
