/**
* Get horizon funnels
*/

import {  HorizonFunnel } from 'features/entitiesRedux/models/horizon';
import { baseApi, cleanArguments } from '..';

export type GetHorizonFunnelsProjection = {
  id: true;
  name?: true;
  created_at?: true;
  updated_at?: true;
  slug?: true;
}

export type GetHorizonFunnelsQuery = {
  name?: string;
  id?: string;
  projection: GetHorizonFunnelsProjection;
};

export type GetHorizonFunnelsResult = HorizonFunnel[];

export const GET_HORIZONS_ENDPOINT_KEY = 'getHorizonFunnels';

export const { useGetHorizonFunnelsQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_HORIZONS_ENDPOINT_KEY]: build.query<GetHorizonFunnelsResult, GetHorizonFunnelsQuery>({
      transformResponse: (response: { horizon_funnels: GetHorizonFunnelsResult }) => 
      // Manually filtering out the "total" funnel for now until it's removed in the API
        (response.horizon_funnels || []).filter(funnel => funnel.name?.toLowerCase() !== 'total'), 
      query: ({
        projection = { id: true, name: true },
        name,
        id,
      }) => ({
        body: {
          query: {
            horizon_funnels: {
              __args: cleanArguments({
                id,
                name: name ? `*${name}*` : undefined,
              }),
              ...projection
            }
          }
        }
      })
    }),
  }),
});
