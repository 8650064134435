import React, { memo, useCallback, useEffect, useState } from 'react';
import { Box, Breadcrumbs, Container, HeroTabs, Button } from '@sprnova/nebula';
import { Action } from 'api/accessControl';
import { useGetScoreboardsQuery } from 'api/crudGraphQL/scoreboards/getScoreboards';
import { AccessControl } from 'components/AccessControl';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import Plus from 'components/nebula/icons/Plus';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';
import { PageHero } from 'layouts/components';
import { Resource } from 'features/entitiesRedux';
import { useAccount } from 'features/global/hooks/useAccount';
import AllAccounts from './components/AllAccounts';
import { track } from './components/mixpanelEvents';
import MyAccounts from './components/MyAccounts';
import { FilterProvider, Filters } from './utils/Filters';

const tabsArray = [
  {
    label: 'My Scoreboards',
    value: 0,
    component: <MyAccounts />
  },
  {
    label: 'All Scoreboards',
    value: 1,
    component: <AllAccounts />
  },
];

const ScoreboardsListPage = (): JSX.Element => {
  const mixpanel = useMixpanel();
  const { account, isLoading } = useAccount();
  const { filter, setFilter } = Filters({
    page: 1,
    limit: 10,
  });
  const [currentTab, setCurrentTab] = useState<number>(0);

  const { data: scoreboardsData } = useGetScoreboardsQuery({
    projection: {
      id: true,
    },
    ...{
      my_scoreboards: 1,
      /**
       * We only care if the user has ANY scoreboards
       */
      limit: 1
    }
  });

  useEffect(() => {
    /**
     * If user has no scoreboards, set current tab to All Scoreboards
     */
    if (scoreboardsData && scoreboardsData?.data?.length === 0) {
      setCurrentTab(1);
    }
  }, [scoreboardsData]);

  const handleTabChange = useCallback((e: React.SyntheticEvent<Element, Event>, value: number) => {
    setFilter({ type: 'reset' });
    setCurrentTab(value);
    const { innerHTML } = e?.target as Element ?? {};
    const { id, name } = account ?? {};
    track({ mixpanel, type: 'tab', options: { tab: innerHTML, user_id: id, user_name: name } });
  }, [setCurrentTab, setFilter, mixpanel, account]);

  const renderTabs = useCallback((): JSX.Element => {
    return (
      <HeroTabs
        items={tabsArray.map(({ component, ...rest }) => rest)}
        onChange={handleTabChange}
        value={currentTab}
      />
    );
  }, [handleTabChange, currentTab]);

  const renderContent = useCallback((): JSX.Element => {
    return (
      <div>
        {tabsArray.find((tab) => tab.value === currentTab)?.component}
      </div>
    );
  }, [currentTab]);

  if (isLoading) {
    return <PageSkeleton />;
  }

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <PageHero
        title='Scoreboards'
        breadcrumbs={<Breadcrumbs items={[
          {name: 'Home', path: '/'},
          {name: 'Scoreboards', path: '/scoreboards'},
        ]}
        />}
        tabs={renderTabs()}
        end={
          <Button
            component="a"
            href="/scoreboards/new"
            size="small"
            variant="primary"
            startIcon={<Plus />}
          >
            Create New Scoreboard
          </Button>
        }
      />
      <Container hasVerticalPadding>
        <AccessControl action={[Action.read]} resource={Resource.report}>
          <Box sx={{ height: 'auto' }}>
            <FilterProvider filter={{ filter, setFilter }}>
              {renderContent()}
            </FilterProvider>
          </Box>
        </AccessControl>
      </Container>
    </Box>
  );
};

export default memo(ScoreboardsListPage);
