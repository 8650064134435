/**
 * useStickyHighlighting
 *
 * Custom hook for determning when a section should be highlighted in the sidebar
 */

import { useEffect, useCallback, useRef, useState } from 'react';
import { ScrollFormSectionRefCollection } from '../Context';

enum ScrollSectionRefNames {
  STICKY_SENTINEL_TOP = 'sticky_sentinel_top',
  STICKY_SENTINEL_BOTTOM = 'sticky_sentinel_bottom',
  HEADER = 'header',
  ROOT = 'root',
}

type StickyHighlightingArgs = {
  onBeforeInit?: () => void;
  sectionRefs: {
    [id: string]: ScrollFormSectionRefCollection
  };
}

/**
 * Get array of elements from a section ref collection
 * @param refs section ref collection
 * @param refName the specfic ref element to return for each collection
 * @returns 
 */
const getElementsByRefName: (sectionRefs: StickyHighlightingArgs['sectionRefs'], name: ScrollSectionRefNames) => (HTMLElement | null)[] = (refs, refName) => Object.entries(refs)
  .reduce(
    (acc, entry: [string, ScrollFormSectionRefCollection]) => acc.concat(entry[1][refName].current),
      [] as (HTMLElement | null)[]
  );

const useStickyHighlighting: (args: StickyHighlightingArgs) => { 
  inViewId: string, 
  setInViewId: (idOrCallback: string | ((prevState: string) => string)) => void 
} = ({ 
  sectionRefs, 
  onBeforeInit 
}) => {
  const initialized = useRef<boolean>(false);
  const recentManuallyClicked = useRef<string>('');
  const [inViewId, setInViewId] = useState<string>('');
  const sentinelBottomObserverRef = useRef<IntersectionObserver | null>(null);
  const sentinelTopObserverRef = useRef<IntersectionObserver | null>(null);

  const handleStickyChange: (data: { 
    id: string;
    stuck: boolean;
    position: 'top' | 'bottom';
   }) => void = (data) => {
     const { id: sectionId, stuck } = data;

     if (stuck && inViewId !== sectionId) {
       // Prevent setting another section ID until we have scrolled to the manually clicked section
       if (recentManuallyClicked.current && sectionId !== recentManuallyClicked.current) {
         recentManuallyClicked.current = '';
       } else {
         setInViewId(sectionId);
       }
     }
   };

  const handleSetInView = (stateOrCallback: any) => {
    recentManuallyClicked.current = stateOrCallback;
    setInViewId(stateOrCallback);
  };

  /**
  * Sets up an intersection observer to notify when the top sentinel element
  * of a section becomes visible/invisible at the top of the container.
  */
  const observeTopSentinels: (refs: StickyHighlightingArgs['sectionRefs']) => void = refs => {
    const callback = (records: IntersectionObserverEntry[]) => {
      for (const record of records) {
        if (record?.target?.parentElement?.id && record?.rootBounds) {
          const sectionId = record.target.parentElement.id;
          const targetInfo = record.boundingClientRect;
          const rootBoundsInfo = record?.rootBounds;
  
          // Started sticking
          if (targetInfo.bottom < rootBoundsInfo.top) {
            handleStickyChange({
              id: sectionId,
              stuck: true,
              position: 'top',
            });
          }
  
          // Stopped sticking
          if (targetInfo.bottom >= rootBoundsInfo.top &&
            targetInfo.bottom < rootBoundsInfo.bottom) {

            handleStickyChange({
              id: sectionId,
              stuck: false,
              position: 'top',
            });
          }
        }
      }
    };

    sentinelTopObserverRef.current = new IntersectionObserver(callback, { threshold: [0] });

    // Attach observers to the top sentinels of each section
    const sentinels = getElementsByRefName(refs, ScrollSectionRefNames.STICKY_SENTINEL_TOP);
    sentinels.forEach((el: (HTMLElement | null)) => {
      if (el) {
        sentinelTopObserverRef?.current?.observe(el);
      }
    });
  };
  
  /**
  * Sets up an intersection observer to notify when the bottom sentinel element
  * of a section becomes visible/invisible at the bottom of the container.
  */
  const observeBottomSentinels: (refs: StickyHighlightingArgs['sectionRefs']) => void = refs => {
    const callback = (records: IntersectionObserverEntry[]) => {
      for (const record of records) {
        if (record?.target?.parentElement?.id && record?.rootBounds) {
          const sectionId = record.target.parentElement.id;
          const targetInfo = record.boundingClientRect;
          const rootBoundsInfo = record.rootBounds;
          const ratio = record.intersectionRatio;

          // Started sticking
          if (targetInfo.bottom > rootBoundsInfo.top && ratio === 1) {

            handleStickyChange({
              id: sectionId,
              stuck: true,
              position: 'bottom',
            });
          }

          // Stopped sticking
          if (targetInfo.top < rootBoundsInfo.top &&
          targetInfo.bottom < rootBoundsInfo.bottom) {

            handleStickyChange({
              id: sectionId,
              stuck: false,
              position: 'bottom',
            });
          }
        }
      }
    };

    sentinelBottomObserverRef.current = new IntersectionObserver(callback, { threshold: [1] });

    // Attach observers to the bottom sentinels of each section
    const sentinels = getElementsByRefName(refs, ScrollSectionRefNames.STICKY_SENTINEL_BOTTOM);

    sentinels.forEach((el: (HTMLElement | null)) => {
      if (el) {
        sentinelBottomObserverRef?.current?.observe(el);
      }
    });
  };

  /**
   * Setup observers etc.
   */
  const initialize = useCallback(async () => {
    if (typeof onBeforeInit === 'function') {
      await onBeforeInit();
    }

    observeTopSentinels(sectionRefs);
    observeBottomSentinels(sectionRefs);
  
    initialized.current = true;
  }, [sectionRefs]);

  /**
   * Unobserve on unmount
   */
  const cleanup = useCallback(() => {
    sentinelTopObserverRef.current?.disconnect();
    sentinelBottomObserverRef.current?.disconnect();
  }, []);

  useEffect(() => {
    // Wait until sections has been registered before initializing
    if (
      !initialized.current && 
      typeof sectionRefs === 'object' && 
      Object.keys(sectionRefs).length
    ) {
      initialize();
    }

    return () => {
      if (initialized.current) {
        cleanup();
      }
    };
  }, [sectionRefs, cleanup, initialize]);

  return {
    inViewId,
    setInViewId: handleSetInView
  };
};

export default useStickyHighlighting;