import React, { useCallback, useEffect, useMemo, useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { createFilterOptions } from '@mui/material';
import { Autocomplete } from '@sprnova/nebula';
import { useGetSalesforceAccountsQuery } from 'api/crudGraphQL/salesforce_opportunities/getSalesforceAccounts';
import { AppDispatch } from 'app/store';
import { formatErrorToHumanReadable } from 'utils';
import { Client, SalesforceAccount, updateClient } from 'features/entitiesRedux';
import { notification } from 'components';
import { AutoCompleteFormOption, SalesforceValuesObjectType } from '../ContractDetailsEdit/ContractDetailsEdit';

export type SelectSalesforceAccountProps = {
   className?: string;
   client?: Partial<Client>;
   refetchClients: () => void;
   setSalesforceAccountLinkUpdateInProgress?: React.Dispatch<React.SetStateAction<boolean>>;
   salesforceAccountLinkUpdateInProgress?: boolean;
   isSalesforceAccountRemoved: boolean;
   isSubmitting?: boolean;
   setSalesforceValuesObject: React.Dispatch<React.SetStateAction<SalesforceValuesObjectType>>;
   salesforceValuesObject: SalesforceValuesObjectType;
};

const SelectSalesforceAccount = ({
  className,
  client,
  refetchClients,
  setSalesforceAccountLinkUpdateInProgress,
  salesforceAccountLinkUpdateInProgress = false,
  isSalesforceAccountRemoved,
  isSubmitting = false,
  setSalesforceValuesObject,
  salesforceValuesObject,
}: SelectSalesforceAccountProps): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();

  const [salesforceAccounts, setSalesforceAccounts] = useState<Partial<SalesforceAccount>[]>([]);

  /**
   * Used to limit the results of the AutoComplete dropdowns that don't have virtualization setup
   * This prevents the UI from freezing with large dropdown option lists
   */
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    limit: 200
  });
  /**
   * RTK fetch salesforce accounts
   */
  const { data: salesforceAccountsData, isFetching: isFetchingSalesforceAccounts, refetch: refetchSalesforceAccount } = useGetSalesforceAccountsQuery({
    limit: 9999,
    projection: {
      description: true,
      id: true,
      lead_status: true,
      name: true,
    }
  });

  useEffect(() => {
    if (salesforceAccountsData) {
      setSalesforceAccounts(salesforceAccountsData);
    }
  }, [salesforceAccountsData]);

  /**
   * If the salesforce account is removed from the client,
   * refetch the salesforce accounts to get the latest data
   */
  useEffect(() => {
    if (isSalesforceAccountRemoved) {
      refetchSalesforceAccount();
    }
  }, [isSalesforceAccountRemoved, refetchSalesforceAccount]);

  const salesforceAccountOptions: AutoCompleteFormOption[] = useMemo(() =>
    salesforceAccounts ? salesforceAccounts.map((salesforceAccount: Partial<SalesforceAccount>) => {
      return salesforceAccount && salesforceAccount.name ?
        { value: salesforceAccount.name, label: `${salesforceAccount.name} - ${salesforceAccount.id}` } : { value: '', label: '' };
    }) : [], [salesforceAccounts]);

  const clearSalesforceValues = useCallback(() => {
    setSalesforceValuesObject({ salesforce_opportunity_id: '', salesforce_client_id: '' });
  }, [setSalesforceValuesObject]);

  const updateSalesforceFormData = useCallback((unlinking: boolean, salesforceAccountId: string | null, salesforceAccountName: string | null): void => {
    if (unlinking) {
      clearSalesforceValues();
    } else {
      setSalesforceValuesObject((prevValue: SalesforceValuesObjectType) => ({ ...prevValue, salesforce_client_id: salesforceAccountId }));
    }
  }, [clearSalesforceValues, setSalesforceValuesObject]);

  const updateSalesforceAccountLink = useCallback(async (unlinking: boolean, salesforceAccount: Partial<SalesforceAccount> | undefined = undefined) => {
    if (client && client.id) {
      setSalesforceAccountLinkUpdateInProgress?.(true);
      const salesforceAccountId = salesforceAccount?.id ?? null;
      const salesforceAccountName = salesforceAccount?.name ?? null;

      const action = await dispatch(
        updateClient({
          id: client.id,
          // if unlinking, clear the salesforce client information, otherwise set it
          salesforce_client_id: unlinking ? null : salesforceAccountId,
          salesforce_client_name: unlinking ? null : salesforceAccountName
        })
      );
      setSalesforceAccountLinkUpdateInProgress?.(false);

      const successMessageVerb = unlinking ? 'unlinked' : 'linked';
      const toOrFrom = unlinking ? 'from' : 'to';

      if (updateClient.fulfilled.match(action)) {
        const oldSalesForceAccountNameToDisplay = client.salesforce_client_name;
        const salesForceAccountNameToDisplay = unlinking ? oldSalesForceAccountNameToDisplay : salesforceAccountName;
        notification.success({
          message:
            `${client.name} was ${successMessageVerb} ${toOrFrom} Salesforce Account: '${salesForceAccountNameToDisplay}' successfully`
        });
        // if unlinking, clear the salesforce account, opportunity, and client salesforce information from the form data. Otherwise, set it
        updateSalesforceFormData(unlinking, salesforceAccountId, salesforceAccountName);
      }

      if (updateClient.rejected.match(action)) {
        // Slice out the actual error message from the stringified error response
        const errorMessage = typeof action?.error?.message === 'string'
          ? formatErrorToHumanReadable(action.error.message)
          : 'An unknown error occurred. Please try again.';

        notification.error({
          message: errorMessage
        });
        /*
          clear the form data of the salesforce account, opportunity, and client salesforce information
          because the update failed
        */
        clearSalesforceValues();
      }
      /**
       * Refetch the clients data to get the latest salesforce client information per client
       */
      refetchClients();
    }
  }, [clearSalesforceValues, client, dispatch, refetchClients, setSalesforceAccountLinkUpdateInProgress, updateSalesforceFormData]);

  const handleSelectSalesForceAccount = useCallback((salesforceAccountObject: AutoCompleteFormOption): void => {
    if (salesforceAccounts && salesforceAccounts.length > 0) {
      let salesforceAccount: Partial<SalesforceAccount> | undefined = undefined;
      if(salesforceAccountObject) {
        salesforceAccount = salesforceAccounts.find((salesforceAccount: Partial<SalesforceAccount>) => salesforceAccount.name === salesforceAccountObject.value) ?? undefined;
      }
      /**
       * Only if there is a salesforce account found, update the salesforce account link
      *
      * This also handles the case where the user clicks the clear button on the dropdown
      * and the salesforce account is set to undefined. This way we don't try to update the salesforce account link
      * with a null value
      */
      if (salesforceAccount) {
        setSalesforceValuesObject((prevValue: SalesforceValuesObjectType) => ({ ...prevValue, salesforce_client_id: salesforceAccount?.id }));
        updateSalesforceAccountLink(false, salesforceAccount);
      }
    }
  }, [salesforceAccounts, setSalesforceValuesObject, updateSalesforceAccountLink]);

  /**
   * We send null for the salesforce client id if the client is not linked to a salesforce account
   * However, when we pull the data for the account after unlinking it, the salesforce client id is an empty string
   * So we need to check for both null and empty string
   */
  const shouldDisableSalesforceAccountDropdown = useMemo(() => {
    return !client || !salesforceAccounts || !!salesforceValuesObject?.salesforce_client_id;
  }, [client, salesforceAccounts, salesforceValuesObject?.salesforce_client_id]);

  return (
    <Autocomplete
      key={salesforceValuesObject.salesforce_client_id}
      id="salesforce-account-id"
      className={className}
      disableClearable
      options={salesforceAccountOptions}
      loading={isFetchingSalesforceAccounts}
      label="Salesforce Account"
      onChange={(event, salesforceAccountObject): void => handleSelectSalesForceAccount(salesforceAccountObject)}
      // If a client has a salesforce account linked, they have to remove the account before they can change the salesforce account on the client
      disabled={shouldDisableSalesforceAccountDropdown || isSubmitting || salesforceAccountLinkUpdateInProgress}
      value={salesforceValuesObject?.salesforce_client_id}
      filterOptions={filterOptions}
      sx={{ width: '100%' }}
    />
  );
};

SelectSalesforceAccount.displayName = 'SelectSalesforceAccount';
export default memo(SelectSalesforceAccount);
