import React from 'react';

export const DataQIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    x="0px"
    y="0px"
    viewBox="0 0 12 12"
  >
    <g>
      <path d="M6,9.3l1.5,0.9L6,11.1l-1.5-0.9L6,9.3 M6,8.4c0,0-0.1,0-0.1,0l-2.8,1.6c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0.1,0.2L5.9,12
		c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0l2.7-1.6c0,0,0,0,0.1-0.1c0-0.1,0.1-0.2,0-0.2L6,8.4C6.1,8.5,6.1,8.4,6,8.4L6,8.4z"/>
    </g>
    <g>
      <path d="M7.1,1.3l1.5,0.9v1.7L7.1,3V1.3 M6.5,0C6.4,0,6.3,0,6.3,0.1v3.1v0.2c0,0,0,0.1,0,0.1l0.2,0.1l2.7,1.5c0,0,0.1,0,0.1,0
		c0.1,0,0.1,0,0.1-0.1V1.8v0c0,0,0-0.1,0-0.1l0,0L6.6,0C6.6,0,6.6,0,6.5,0L6.5,0z"/>
    </g>
    <g>
      <path d="M7.1,5.6l1.5,0.9v1.7L7.1,7.4V5.6 M6.5,4.4c-0.1,0-0.2,0-0.2,0.1v3.1v0.2c0,0,0,0.1,0,0.1L6.5,8l2.7,1.5c0,0,0.1,0,0.1,0
		c0.1,0,0.1,0,0.1-0.1V6.2v0c0,0,0-0.1,0-0.1l0,0L6.6,4.4C6.6,4.4,6.6,4.4,6.5,4.4L6.5,4.4z"/>
    </g>
    <g>
      <path d="M4.9,5.6v1.7L3.4,8.3V6.6L4.9,5.6 M5.5,4.4c-0.1,0-0.1,0-0.1,0L2.6,6.1l0,0c0,0,0,0,0,0.1v0v3.2c0,0.1,0.1,0.1,0.1,0.1
		c0.1,0,0.1,0,0.1,0L5.5,8l0.2-0.1c0,0,0,0,0-0.1V7.6V4.5C5.7,4.4,5.6,4.4,5.5,4.4L5.5,4.4z"/>
    </g>
  </svg>
);