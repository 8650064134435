import React, { FC } from 'react';
import { Skeleton } from 'antd';
import Card, { CardContent, CardHeader } from 'components/nebula/Card';
import css from './BudgetScenarios.module.scss';
import cardCss from './components/Card/Card.module.scss';

type SkeletonProps = {
  title?: string
}

const HorizonSkeleton: FC<SkeletonProps> = ({title = 'Budget Scenarios'}) => {
  const horizonSkeleton = (
    <>
      <h3 className={css.label}>
        <Skeleton.Input active style={{ height: 15, width: 160 }} />
      </h3>
      <Card className={cardCss.root}>
        <CardHeader
          noBorder
          size="default"
          title={<Skeleton.Input active style={{ height: 15, width: 140 }} />}
          sub={<Skeleton.Input active style={{ height: 12, width: 200 }} />}
        />
      </Card>
      <Card className={cardCss.root}>
        <CardHeader
          noBorder
          size="default"
          title={<Skeleton.Input active style={{ height: 15, width: 140 }} />}
          sub={<Skeleton.Input active style={{ height: 12, width: 200 }} />}
        />
      </Card>
    </>
  );

  return (
    <Card hasShadow>
      <CardHeader
        size="large"
        title={title}
      />
      <CardContent>
        {horizonSkeleton}
        {horizonSkeleton}
      </CardContent>
    </Card>
  );};

export default HorizonSkeleton;
