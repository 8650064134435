/**
 * Components -> SelectSalesforceOpportunity
 */

import React, { FC, forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import { Tag } from 'components/Tag';
import { useSalesforceOpportunities } from 'utils/hooks/useSalesforceOpportunities';
import { SalesforceOpportunity } from 'features/entitiesRedux/models/salesforce/salesforce';
import { salesforceOpportunityIsClosed } from 'features/strategies/utils/salesforceOpportunityIsClosed';
import Select, { Props as SelectProps } from '../Select/Select';
import css from './SelectSalesforceOpportunity.module.scss';


type Props = SelectProps & {
  opportunities?: SalesforceOpportunity[];
  salesforceClientId?: string | null;
}

const SelectSalesforceOpportunity: FC<Props> = forwardRef(function SelectSalesforceOpportunity({
  opportunities: opportunitiesProp,
  className,
  mode,
  skeleton,
  salesforceClientId,
  ...props
}, ref: any) {
  // Only fetch salesforce opportunties if an ID is provided
  // and no opportunties has been passed as a prop
  const fetchId = (salesforceClientId && !opportunitiesProp?.length) ? salesforceClientId : null;

  const {
    salesforce_opportunities,
    loading: loadingOpportunities
  } = useSalesforceOpportunities(fetchId);

  const filteredOptions = useMemo(() => {
    const options = fetchId ? salesforce_opportunities : opportunitiesProp;
    return options?.filter((option) => option?.id !== undefined && !salesforceOpportunityIsClosed(option));
  }, [fetchId, opportunitiesProp, salesforce_opportunities]);

  const stageColor = (stage: string | undefined) =>{
    // Grey Closed
    if(stage === 'Closed Won' || stage === 'Closed Lost' || stage === 'Disqualified') return '#B9BCB7';
    // Green Open
    return '#B7E2C4';
  };

  const stageText = (stage: string | undefined) =>{
    // Closed
    if(stage === 'Closed Lost' || stage === 'Disqualified') return 'Closed/Lost';
    else if(stage === 'Closed Won') return 'Closed/Won';
    //  Open
    return 'Open';
  };

  return (
    <Select
      className={classNames(css.root, className)}
      mode={mode}
      showSearch
      skeleton={loadingOpportunities || skeleton}
      optionFilterProp="name"
      filterOption={(input, option) => option?.name?.toLowerCase().includes(input?.toLowerCase())}
      ref={ref}
      placeholder="Select Salesforce Opportunity"
      dropdownRender={menu => (
        <div className={css.dropdown}>
          {menu}
        </div>
      )}
      {...props}
    >
      {(filteredOptions as (Partial<SalesforceOpportunity>)[])?.map((option) => (
        <Select.Option key={option?.id} value={option?.id || ''}>{option?.display_value}<Tag color={stageColor(option?.stage)}>{stageText(option?.stage)}</Tag></Select.Option>
      ))}
    </Select>
  );
});

export default SelectSalesforceOpportunity;
