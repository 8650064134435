import { createContext } from "react";

export type AddNotesMode = "preview" | "form";

interface AddNotesContextProps {
  mode: AddNotesMode;
  notes?: string;
  hasNotes: boolean;
  onSave: (notes: string) => void;
  onCancel: () => void;
  onEdit: () => void;
  submitting: boolean;
  loading: boolean;
  error: string | null;
}
export const AddNotesContext = createContext<AddNotesContextProps>({
  mode: "preview",
  hasNotes: false,
  onCancel: () => {},
  onEdit: () => {},
  onSave: () => {},
  submitting: false,
  loading: false,
  error: null,
});
