/**
 * StrategyForm -> Departments
 */

import React, { FC } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Form,
  Button,
} from 'antd';
import classnames from 'classnames';
import {
  FormItem,
  SelectDepartment
} from 'components';
import css from './Departments.module.scss';

type Type = {
  isEdit?: boolean;
  skeleton?: boolean;
  departments_ids?: (undefined | number)[]
}

const Departments: FC<Type> = ({ isEdit, skeleton, departments_ids }) => {
  return (
    <Form.List initialValue={departments_ids} name="departments">
      {(fields, { add, remove }) => {
        return (
          <div className={classnames(css.root, { [css.marginTop]: !fields.length })}>
            {fields.map((field, i) => (
              <div key={field.key} className={css.row}>
                <FormItem
                  {...field}
                  label={`Department ${i + 1}`}
                  className={css.item}
                  name={field.name}
                  rules={[{ required: true, message: 'Please choose a department' }]}
                >
                  <SelectDepartment autoFocus={!isEdit} excludedDepartmentNames={['AM', 'ES', 'AD', 'Consulting']} skeleton={skeleton} />
                </FormItem>
                <Button
                  className={css.removeButton}
                  aria-label="Remove department"
                  type="link"
                  icon={<MinusCircleOutlined />}
                  onClick={() => remove(field.name)}
                />
              </div>
            ))}
            <FormItem>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
                className={css.addFirstButton}
              >
                Add department
              </Button>
            </FormItem>
          </div>
        );
      }}
    </Form.List>
  );
};

export default Departments;
