/**
 * Components -> Tooltip
 */

import React, { FC, memo } from 'react';
import { Tooltip as AntTooltip } from 'antd';
import { TooltipProps as DefaultTooltipProps } from 'antd/lib/tooltip';
import css from './Tooltip.module.scss';

export type TooltipProps = DefaultTooltipProps & {
  sub?: React.ReactNode;
  disabled?: boolean;
}

export const Tooltip: FC<TooltipProps> = memo(function Tootltip({ title, sub, disabled, children, ...rest }) {
  const content = (title && sub) ? (
    <span className={css.content}>
      <span className={css.title}>{title}</span>
      <span className={css.sub}>{sub}</span>
    </span>
  ) : <span className={css.title}>{title}</span>;

  if (disabled) {
    return <>{children}</>;
  }

  return (
    <AntTooltip
      title={content}
      {...rest}
    >
      {children}
    </AntTooltip>
  );
});
