/**
 * formatErrorToHumanReadable
 * 
 * Convert the default error response to a human readable error message
 */

const formatErrorToHumanReadable: (message: string) => string = (message) => {
  try {
    // Find the index where we want to slice out the remaining error message
    const endIndex = message.indexOf(': {"'); // "This is a message: {"response":{"errors":[{"me....""

    if (endIndex > 0) {
      return message.slice(0, endIndex);
    }
    
    return message;
  } catch {
    return message;
  }
};

export default formatErrorToHumanReadable;