import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { LocalizationProvider, AdapterDateFns , novaTheme, ThemeProvider } from '@sprnova/nebula';
import './index.css';
import 'nebula-global-styles';
import { config } from 'config';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { store, persistor } from './app/store';

Sentry.init({
  dsn: config.platform.sentry.dsn,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  environment: config.nodeEnv,
});

const render = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={novaTheme} withGlobalStyles>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <App />
        </LocalizationProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

ReactDOM.render(render, document.getElementById('root'));

if (import.meta.hot) {
  import.meta.hot.accept();
}