import loadable from "@loadable/component";

const LoadableComponent = loadable(
    () => import("./ViewSurveyPage")
    /* , {
    fallback: <div>Loading...</div>,
    } */
);

export { LoadableComponent as ViewSurveyPage };
