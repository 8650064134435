import { Projection } from 'api/entityGraphQL/types';

export const tasksProjection: Projection = {
  id: true,
  name: true,
  pricing_version: {
    name: true,
    slug: true,
  },
  pricing_type: {
    name: true,
  },
  service: {
    id: true,
    name: true,
    department: {
      id: true,
      name: true,
    },
  },
};
