import React, { FC, memo, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useParams } from 'react-router';
import { AnchorLinks, Box, Breadcrumbs, Button, Container, EmptyState, Grid, HeroSkeleton, LoadingBar, RefreshIcon, Spinner, useScrollSpy } from '@sprnova/nebula';
import { Action } from 'api/accessControl';
import { useGetMeQuery } from 'api/crudGraphQL/me/getMe';
import { useGetMePublicQuery } from 'api/crudGraphQL/public/me/getMePublic';
import { useGetScoreboardDataSourcesPublicQuery } from 'api/crudGraphQL/public/scoreboards/getScoreboardDataSourcesPublic';
import { useGetScoreboardPublicQuery } from 'api/crudGraphQL/public/scoreboards/getScoreboardPublic';
import { useGetScoreboardQuery } from 'api/crudGraphQL/scoreboards/getScoreboard';
import { useGetScoreboardDataSourcesQuery } from 'api/crudGraphQL/scoreboards/getScoreboardDataSources';
import { Widget } from 'api/crudGraphQL/scoreboards/types';
import { useUpdateScoreboardMutation } from 'api/crudGraphQL/scoreboards/updateScoreboard';
import { Projection } from 'api/entityGraphQL';
import { AccessControl } from 'components/AccessControl';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { PageHero } from 'layouts/components';
import { Resource } from 'features/entitiesRedux';
import CustomizeWidgetsDrawer from 'features/scoreboards/components/CustomizeWidgetsDrawer';
import FunctionsMenu from './components/FunctionsMenu';
import { track } from './components/mixpanelEvents';
import { scoreboardsProjection } from './components/projections';
import { GoalPacing } from './widgets/GoalPacing/GoalPacing';
import EditGoalFilters from './widgets/KPIs/components/EditGoalFilters';
import { KPIs } from './widgets/KPIs/KPIs';

interface ExtendedWidgets extends Widget {
  Component: JSX.Element;
}
type ScoreboardProps = {
  isClient?: boolean;
  projection?: Projection;
};
export const SCROLL_IDS = {
  KPIS: 'primary-kpis',
  GOAL_PACING: 'goal-pacing',
};
const Scoreboard: FC<ScoreboardProps> = ({ isClient = false, projection = scoreboardsProjection }) => {
  const { clientId, id } = useParams<{ [x: string]: string }>();
  const [pollingInterval, setPollingInterval] = useState<number>();
  const [refreshing, setRefreshing] = useState(false);
  const mixpanel = useMixpanel();
  const [tracked, setTracked] = useState(false);
  // for use in FunctionsMenu
  const [openCustomizeWidgets, toggleCustomizeWidgets] = useReducer((state) => !state, false);
  const [openGoalFilter, toggleGoalFilter] = useReducer((state) => !state, false);

  const [updateScoreboard] = useUpdateScoreboardMutation();

  const { data: account, isLoading: isLoadingMe } = (isClient ? useGetMePublicQuery : useGetMeQuery)({
    projection: {
      id: true,
      name: true,
      email: true,
    }
  });

  const { data, isLoading, isFetching } = (isClient ? useGetScoreboardPublicQuery : useGetScoreboardQuery)({
    id: parseInt(id),
    projection,
  }, { skip: !id, pollingInterval });

  const { data: scoreboardDataSources } = (isClient ? useGetScoreboardDataSourcesPublicQuery : useGetScoreboardDataSourcesQuery)({
    report_id: parseInt(id),
    projection: {
      report_id: true,
      client_id: true,
      data_source_id: true,
      data_source_name: true,
      data_source_slug: true,
      warehouse_source: true,
      last_refresh_status: true,
    }
  });

  useEffect(() => {
    if (data && data.refresh_status === 'complete') {
      setPollingInterval(undefined);
    }
  }, [data, pollingInterval]);

  // View Scoreboard Mixpanel event
  useEffect(() => {
    if (account && data && !tracked) {
      const { id, name, email } = account;
      track({
        mixpanel,
        type: 'view-scoreboard',
        options: {
          user_id: id,
          user_name: name,
          email,
          is_external: isClient,
          client_id: data.client?.id,
          client_name: data.client?.name,
          scoreboard_id: data.id,
          scoreboard_name: data.name,
          scoreboard_data_sources: scoreboardDataSources
        }
      });
      setTracked(true);
    }
  }, [account, clientId, data, isClient, mixpanel, scoreboardDataSources, tracked]);

  // KPIs and Goal Pacing click Mixpanel event
  const handleTrackModuleClick = useCallback((moduleID: string): void => {
    if (account) {
      const { id, name, email } = account;
      track({
        mixpanel,
        type: 'module',
        options: {
          module: moduleID,
          user_id: id,
          user_name: name,
          email,
          is_external: isClient,
          client_id: data?.client?.id,
          client_name: data?.client?.name,
          scoreboard_id: data?.id,
          scoreboard_name: data?.name
        },
      });
    }
  }, [account, data?.client?.id, data?.client?.name, data?.id, data?.name, isClient, mixpanel]);

  const { currentInView, scrollToElement } = useScrollSpy({
    // Required
    ids: Object.values(SCROLL_IDS),

    // Optional
    offset: 20, // Sets a global scroll offset for all elements
    updateUrlHashOnScroll: true, // Updates the URL hash as you scroll or when clicking on an anchor link
    // scrollToElementOnMount: true, // Scrolls to the element on mount if the URL hash matches an element ID
  });

  const handleScrollToElement = useCallback(
    (id: string) => (e: React.SyntheticEvent): void => {
      e.preventDefault();
      scrollToElement(id);
      handleTrackModuleClick(id);
    },
    [handleTrackModuleClick, scrollToElement],
  );

  const handleRefreshData = useCallback(async () => {
    try {
      setRefreshing(true);
      await updateScoreboard({ id: parseInt(id), refreshed_at: null, projection: { refresh_status: true } }).unwrap();
      setPollingInterval(10000);
    } catch (error) {
      console.error(error);
    } finally {
      setRefreshing(false);
    }
  }, [id, updateScoreboard]);

  useEffect(() => {
    if (data?.refreshed_at === null) {
      handleRefreshData();
    }
  }, [data?.refreshed_at, handleRefreshData]);

  const Widgets: Record<string, JSX.Element> = useMemo(() => ({
    [SCROLL_IDS.KPIS]: <KPIs isClient={isClient} />,
    [SCROLL_IDS.GOAL_PACING]: <GoalPacing isClient={isClient} />,
  }), [isClient]);

  const widgets: ExtendedWidgets[] = useMemo(() => {
    if (!data) return [];
    return data.widgets.map((widget: Widget) => {
      return {
        ...widget,
        Component: Widgets[widget.slug as string],
      };
    });
  }, [Widgets, data]);

  const renderAnchorLinks = useMemo(() => {
    if (!data) return [];
    const filteredWidgets = isClient ? widgets.filter(widget => widget.is_active === 'yes') : widgets;
    return (
      <AnchorLinks
        items={
          filteredWidgets
            ?.map((widget: Widget) => ({
              href: `#${widget.slug}`,
              label: widget.name,
              value: widget.slug,
              onClick: handleScrollToElement(widget.slug),
            }))
        }
        value={currentInView || SCROLL_IDS.KPIS}
        sx={{
          paddingBottom: '16px',
        }}
      />
    );
  }, [data, isClient, widgets, currentInView, handleScrollToElement]);

  const renderBreadcrumbs = useMemo(() => {
    if (isClient) {
      return (
        <Breadcrumbs items={[
          {name: 'Home', path: '/'},
          {name: 'Scoreboards', path: `/c/${clientId}/scoreboards`},
          {name: data?.name || 'Scoreboard', path: `/c/${clientId}/scoreboards/${id}`},
        ]}
        />
      );
    }
    return (
      <Breadcrumbs items={[
        {name: 'Home', path: '/'},
        {name: 'Scoreboards', path: '/scoreboards'},
        {name: data?.name, path: `/scoreboards/${id}`},
      ]}
      />
    );
  }, [isClient, data?.name, id, clientId]);

  const renderHero = useMemo(() => {
    if (isLoading) return <HeroSkeleton />;

    return (
      <PageHero
        title={data?.name ? `${data?.name} Scoreboard` : 'Scoreboard'}
        breadcrumbs={renderBreadcrumbs}
        anchorLinks={renderAnchorLinks}
        end={
          !isClient &&
          <AccessControl action={[Action.create]} resource={Resource.report}>
            <Grid container alignItems='center' justifyContent='center' spacing={2}>
              <Grid item>
                <Button
                  startIcon={
                    data?.refresh_status
                      && data?.refresh_status !== 'complete'
                      ? <Spinner size='small' />
                      : <RefreshIcon />
                  }
                  variant='text'
                  size='large'
                  onClick={handleRefreshData}
                  disabled={refreshing || isLoading || isFetching || data?.refresh_status === 'in_progress'}
                >
                  Refresh Data
                </Button>
              </Grid>
              <Grid item>
                <FunctionsMenu
                  scoreboardId={id}
                  clientId={data?.client?.id}
                  toggleCustomize={toggleCustomizeWidgets}
                  toggleGoalFilter={toggleGoalFilter}
                />
                <CustomizeWidgetsDrawer open={openCustomizeWidgets} toggle={toggleCustomizeWidgets} />
                <EditGoalFilters
                  isClient={isClient}
                  info={null}
                  open={openGoalFilter}
                  toggle={toggleGoalFilter}
                  hideIconButton
                />
              </Grid>
            </Grid>
          </AccessControl>
        }
      />
    );
  }, [isLoading, data?.name, data?.refresh_status, data?.client?.id, renderBreadcrumbs, renderAnchorLinks, isClient, handleRefreshData, refreshing, isFetching, id, openCustomizeWidgets, openGoalFilter]);

  const renderWidgets = useMemo(() => {
    const filteredWidgets = isClient ? widgets.filter(widget => widget.is_active === 'yes') : widgets;
    if (!filteredWidgets.length && !isLoading) {
      return (
        <EmptyState
          title='Nothing to see here (yet)!'
          description='Your account team is working on setting up your scoreboard. If you have any questions, reach out to your account team.'
          size='large'
        />
      );
    };

    if (data && data.refresh_status !== 'complete') {
      return (
        <div style={{paddingTop: '200px'}}>
          <LoadingBar
            description="Please hold! Loading data from integrations. This should take less than a minute."
          />
        </div>
      );
    }

    return filteredWidgets.map((widget: ExtendedWidgets) => {
      return widget.Component;
    });
  }, [data, isClient, isLoading, widgets]);

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      {renderHero}
      <Container hasVerticalPadding>
        <AccessControl action={[Action.read]} resource={Resource.report}>
          <Box sx={{ height: 'auto' }}>
            {renderWidgets}
            {/* TODO: Add back in when widget is defined */}
            {/* {
              isClient &&
              <ScheduleACallWidget />
            } */}
          </Box>
        </AccessControl>
      </Container>
    </Box>
  );
};

export const Title = (): JSX.Element => <>Scoreboard</>;

export default memo(Scoreboard);
