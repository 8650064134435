import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { UseFieldArrayReturn } from 'react-hook-form';
import { SelectChangeEvent } from '@mui/material';
import { Box, Button, Controller, Grid, IconButton, InfoTooltip, PlusIcon, Radio, Select, Switch, TextField, TrashIcon, Typography, useFieldArray, useForm } from '@sprnova/nebula';
import { isEqual } from 'lodash';
import { TabNames } from 'features/intelligence/creative-playbook/CreativeAffinity/utils/constants';
import { CustomContainer } from './CustomContainer';
import { FieldArrayName } from './types';

export const CustomFilter = ({
  activeTab,
  onSubmit,
}: {
  onSubmit: (values: Record<string, any>) => void,
  activeTab?: string,
}): React.ReactElement => {
  const [operator, setOperator] = useState('');
  const sessionStoragePrefix = 'intelligence:creative-affinity:';
  const sessionStorageMatch = `${sessionStoragePrefix}match`;
  const savedMatchValue = useMemo(() => sessionStorage.getItem(sessionStorageMatch) || undefined, [sessionStorageMatch]);
  const defaultObject = useMemo(() => ({
    operator: '',
    rule: '',
    keyword: '',
  }), []);
  const defaultValues = useMemo(() => ({
    match: true,
    [TabNames.campaign]: [defaultObject],
    [TabNames.adset]: [defaultObject],
    [TabNames.ad]: [defaultObject],
  }), [defaultObject]);

  const operatorItems = useMemo(() => [
    { id: 'and', label: 'And' },
    { id: 'or', label: 'Or' },
  ], []);

  const ruleItems = useMemo(() => [
    { id: 'contains', label: 'Contains' },
    { id: 'does_not_contain', label: 'Does not contain' },
    { id: 'equals', label: 'Equals' },
    { id: 'does_not_equal', label: 'Does not equal' },
    { id: 'starts_with', label: 'Starts with' },
    { id: 'ends_with', label: 'Ends with' },
  ], []);

  const sessionStorageFormName = useCallback((name: Partial<TabNames>) => {
    switch (name) {
      case TabNames.campaign:
        return `${sessionStoragePrefix}${TabNames.campaign}`;
      case TabNames.adset:
        return `${sessionStoragePrefix}${TabNames.adset}`;
      case TabNames.ad:
        return `${sessionStoragePrefix}${TabNames.ad}`;
      default:
        return '';
    }
  }, [sessionStoragePrefix]);

  const savedFormValues = useMemo(() => ({
    [TabNames.campaign]: JSON.parse(sessionStorage.getItem(sessionStorageFormName(TabNames.campaign)) || '[]'),
    [TabNames.adset]: JSON.parse(sessionStorage.getItem(sessionStorageFormName(TabNames.adset)) || '[]'),
    [TabNames.ad]: JSON.parse(sessionStorage.getItem(sessionStorageFormName(TabNames.ad)) || '[]'),
  }), [sessionStorageFormName]);

  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues
  });

  const { append: appendCampaign, remove: removeCampaign, fields: campaignFields } = useFieldArray({
    control,
    name: TabNames.campaign,
  });
  const { append: appendAdset, remove: removeAdset, fields: adsetFields } = useFieldArray({
    control,
    name: TabNames.adset,
  });
  const { append: appendAd, remove: removeAd, fields: adFields } = useFieldArray({
    control,
    name: TabNames.ad,
  });

  const saveFilterTryCatch = useCallback(([name, value]: any): void => {
    /**
     * Try to save the filter to session storage
     * If an error occurs, clear the session storage and try again
     * If an error occurs again, log the error
     */
    try {
      sessionStorage.setItem(name, value);
    } catch (error) {
      sessionStorage.clear();
      try {
        sessionStorage.setItem(name, value);
      } catch (error) {
        console.error('Error saving filter to session storage', error);
      }
    }
  }, []);

  const handleSaveFilter = useCallback((values: Record<string, any>) => {
    Array<string[]>(
      [sessionStorageFormName(TabNames.campaign), JSON.stringify(values[TabNames.campaign])],
      [sessionStorageFormName(TabNames.adset), JSON.stringify(values[TabNames.adset])],
      [sessionStorageFormName(TabNames.ad), JSON.stringify(values[TabNames.ad])],
      [sessionStorageMatch, JSON.stringify(values.match)],
    ).map(saveFilterTryCatch);
  }, [sessionStorageFormName, sessionStorageMatch, saveFilterTryCatch]);

  const deleteEqualToKey = useCallback((obj: Record<string, any>, comparedTo: any): Record<string, any> => {
    /**
     * Create a deep copy of the object to avoid modifying read-only properties
     *
     * Using JSON.parse(JSON.stringify(obj)) creates a deep copy of the object
     * by serializing it into a JSON string and then parsing that string back into a new object.
     * This process effectively creates a new object with the same data
     * but without any references to the original object,
     * ensuring that modifications to the copy do not affect the original.
     */
    const newObj = JSON.parse(JSON.stringify(obj));
    const keys = Object.keys(newObj);
    keys.forEach((key: string) => {
      // delete if equal to comparedTo or empty array
      if(
        isEqual(newObj[key as any], comparedTo)
          || Array.isArray(newObj[key as any])
          && newObj[key as any].length === 0
      ) {
        delete newObj[key as any];
        return;
      }
    });
    return newObj;
  }, []);

  useEffect(() => {
    /**
     * Watch for changes in the form, submit and save to local storage
     */
    const subscription = watch((value) => {
      const campaigns = value[TabNames.campaign];
      const adsets = value[TabNames.adset];
      const ads = value[TabNames.ad];
      // check if the values are equal to the default values and return if true
      if (
        isEqual(defaultValues[TabNames.campaign], campaigns)
          && isEqual(defaultValues[TabNames.adset], adsets)
          && isEqual(defaultValues[TabNames.ad], ads)
          && isEqual(savedMatchValue, value.match)
      ) return;

      onSubmit({
        ...deleteEqualToKey(
          {
            [`${TabNames.campaign}_custom_filters`]: campaigns,
            [`${TabNames.adset}_custom_filters`]: adsets,
            [`${TabNames.ad}_custom_filters`]: ads,
          },
          [defaultObject]
        ),
        match_name_in_all_models: value.match ? 1 : 0,
      });
      handleSaveFilter(value);
    }
    );
    return () => subscription.unsubscribe();
  }, [defaultObject, defaultValues, deleteEqualToKey, handleSaveFilter, onSubmit, savedMatchValue, watch]);

  const handleAdd = useCallback((name: FieldArrayName) => {
    switch (name) {
      case TabNames.campaign:
        appendCampaign(defaultObject);
        break;
      case TabNames.adset:
        appendAdset(defaultObject);
        break;
      case TabNames.ad:
        appendAd(defaultObject);
        break;
    }
  }, [appendAd, appendAdset, appendCampaign, defaultObject]);

  const handleRemove = useCallback((name: FieldArrayName, index: number) => {
    switch (name) {
      case TabNames.campaign:
        removeCampaign(index);
        break;
      case TabNames.adset:
        removeAdset(index);
        break;
      case TabNames.ad:
        removeAd(index);
        break;
    }
    setOperator('');
  }, [removeAd, removeAdset, removeCampaign]);

  const handleClear = useCallback((name: FieldArrayName) => {
    switch (name) {
      case TabNames.campaign:
        removeCampaign();
        break;
      case TabNames.adset:
        removeAdset();
        break;
      case TabNames.ad:
        removeAd();
        break;
    }
    // clear local storage
    sessionStorage.setItem(sessionStorageFormName(name), '');
    setOperator('');
  }, [sessionStorageFormName, removeAd, removeAdset, removeCampaign]);

  const handleToggle = useCallback((_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setValue('match', checked);
  }, [setValue]);

  const handleSetInitialValues = useCallback((name: FieldArrayName) => {
    const formValues = savedFormValues[name];
    const matchedValue = savedMatchValue && JSON.parse(savedMatchValue);

    if (formValues && formValues !== undefined && Array.isArray(formValues) && formValues.length > 0) {
      // set from local storage if available
      setValue(name, formValues);
    } else {
      // otherwise set the default values
      setValue(name, defaultValues[name]);
    }
    if (matchedValue !== undefined) {
      // set from local storage if available
      setValue('match', matchedValue);
    } else {
      // otherwise set the default value
      setValue('match', defaultValues.match);
    }
  }, [defaultValues, savedFormValues, savedMatchValue, setValue]);

  useEffect(() => {
    (async (): Promise<void> => {
      // form must be cleared before changing
      await removeCampaign();
      await removeAdset();
      await removeAd();
      handleSetInitialValues(TabNames.campaign);
      handleSetInitialValues(TabNames.adset);
      handleSetInitialValues(TabNames.ad);
    })();
  }, [defaultValues, handleClear, handleSetInitialValues, removeAd, removeAdset, removeCampaign, savedFormValues, savedMatchValue, setValue]);

  const renderFields = useCallback((fields: UseFieldArrayReturn['fields'], name: FieldArrayName) => (
    fields.map((field, index) => (
      <Box key={field.id}>
        <Grid item xs={6}>
          {index > 0 &&
            <Controller
              control={control}
              name={`${name}.${index}.operator`}
              render={({ field, fieldState: { error } }): React.ReactElement => (
                <Radio.Group
                  {...field}
                  id={`operator-${field.name}`}
                  name={`${name}.${index}.operator`}
                  error={error?.message}
                  onChange={(event: SelectChangeEvent<unknown>): void => {
                    if (typeof event.target.value === 'string') {
                      setValue(`${name}.${index}.operator`, event.target.value as never);
                      setOperator(`${name}.${index}.operator, ${event.target.value}`);
                    }
                  }}
                  sx={{padding: '16px 0'}}
                >
                  {
                    operatorItems.map((item) => (
                      <Radio key={`operatorItem_${item.id}`} value={item.id} label={item.label} />
                    ))
                  }
                </Radio.Group>
              )}
            />
          }
        </Grid>
        <Grid container spacing={1} sx={{display:'flex', alignItems: 'center'}}>
          <Grid item xs={5.5}>
            <Controller
              control={control}
              name={`${name}.${index}.rule`}
              render={({ field, fieldState: { error } }): React.ReactElement => (
                <Select
                  {...field}
                  label='Rule'
                  id={`rule-${field.name}`}
                  error={error?.message}
                  onChange={(event: SelectChangeEvent<unknown>): void => {
                    if (typeof event.target.value === 'string')
                      setValue(`${name}.${index}.rule`, event.target.value as never);
                  }}
                  disabled={index !== 0 && !getValues(name)[index].operator}
                >
                  {
                    ruleItems.map((item) => (
                      <Select.Item key={`operatorItem_${item.id}`} value={item.id}>
                        {item.label}
                      </Select.Item>
                    ))
                  }
                </Select>
              )}
            />
          </Grid>
          <Grid item xs={5.5}>
            <Controller
              control={control}
              name={`${name}.${index}.keyword`}
              render={({ field, fieldState: { error } }): React.ReactElement => (
                <TextField
                  {...field}
                  error={error?.message}
                  label="Keyword"
                  id={`keyword-field-${field.name}`}
                  disabled={index !== 0 && !getValues(name)[index].operator}
                />
              )}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              size="md"
              onClick={(): void => {
                handleRemove(name, index);
              }}
            >
              <TrashIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    ))
    // operator used to rerender for disabled fields check
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [control, getValues, handleRemove, ruleItems, setValue, operator]);

  const renderError = useCallback((name: FieldArrayName) => (
    <Grid container alignItems='center' justifyContent='center' sx={{ marginTop: '10px' }}>
      {
        errors?.[name]?.message && <Typography variant='body2' color='error'>{errors?.[name]?.message}</Typography>
      }
    </Grid>
  ), [errors]);

  const renderAddConditionButton = useCallback((name: FieldArrayName) => (
    <div style={{paddingTop: '6px'}}>
      <Button
        fullWidth
        size='large'
        type='button'
        variant='secondary'
        startIcon={<PlusIcon />}
        onClick={(): void => handleAdd(name)}
        disabled={getValues(name).length === 4}
        marginTop='10px'
      >
      Add Condition
      </Button>
    </div>
  ), [getValues, handleAdd]);

  return (
    <>
      <div>
        <Typography variant='h3' sx={{ mb: 2, mt: 4 }}>
        Advanced Filter
        </Typography>
        <Controller
          control={control}
          name='match'
          render={({ field, fieldState: { error } }): React.ReactElement => (
            <Switch
              id='match_name_in_all_models'
              labelRight='Apply filters to all levels'
              name='match_name_in_all_models'
              onChange={handleToggle}
              sx={{
                fontSize: '12px'
              }}
              checked={field.value}
              error={error}
            />
          )}
        />
        <InfoTooltip
          content={
            <div style={{width: '250px'}}>
            By selecting the toggle the advanced filters will apply to the Campaign, Ad Sets, and Ads on the current tab.
            </div>
          }
          placement="right"
          variant='plain'
          sx={{marginLeft: '8px', marginBottom: '15px'}}
        />
      </div>

      {
        (activeTab === TabNames.campaigns || getValues('match')) && (
          <CustomContainer name={TabNames.campaign} onClear={handleClear}>
            {renderFields(campaignFields, TabNames.campaign)}
            {renderError(TabNames.campaign)}
            {renderAddConditionButton(TabNames.campaign)}
          </CustomContainer>
        )
      }
      {
        (activeTab === TabNames.adsets || getValues('match')) && (
          <CustomContainer name={TabNames.adset} onClear={handleClear}>
            {renderFields(adsetFields, TabNames.adset)}
            {renderError(TabNames.adset)}
            {renderAddConditionButton(TabNames.adset)}
          </CustomContainer>
        )
      }
      {
        (activeTab === TabNames.ads || getValues('match')) && (
          <CustomContainer name={TabNames.ad} onClear={handleClear}>
            {renderFields(adFields, TabNames.ad)}
            {renderError(TabNames.ad)}
            {renderAddConditionButton(TabNames.ad)}
          </CustomContainer>
        )
      }
    </>
  );
};
