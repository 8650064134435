import React, { memo, useCallback, useMemo, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, ButtonProps, Drawer, DrawerFooter, DrawerView, PlusIcon, useForm, useSnackbar } from '@sprnova/nebula';
import { useCreatePacingGoalMutation } from 'api/crudGraphQL/scoreboards/pacing_goals/createPacingGoal';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { useAccount } from 'features/global';
import { track } from 'features/scoreboards/components/mixpanelEvents';
import { useFilterContext } from 'features/scoreboards/utils/Filters';
import MonthlyGoalForm from './MonthlyGoalForm';

type CreateMonthlyGoalDrawerProps = {
  isLoading?: boolean;
  buttonProps?: ButtonProps;
  title?: string;
};
const CreateMonthlyGoalDrawer = ({ isLoading, buttonProps, title = 'New Monthly Goal' }: CreateMonthlyGoalDrawerProps): JSX.Element => {
  const { addSnackbar } = useSnackbar();
  const { id } = useParams<{ [x: string]: string }>();
  const { setFilter } = useFilterContext();
  const [open, toggle] = useReducer((state) => !state, false);
  const [submitting, setSubmitting] = useState(false);
  const mixpanel = useMixpanel();
  const { account } = useAccount();

  const [createPacingGoal] = useCreatePacingGoalMutation();

  const {
    control,
    reset,
    trigger,
    getValues,
    formState: { isValid },
  } = useForm<any>({
    defaultValues: {
      metric: '',
      goal: undefined,
    },
  });

  const handleSubmit = useCallback(async (e): Promise<void> => {
    e?.preventDefault();
    trigger();
    try {
      setSubmitting(true);
      const { metric, goal } = getValues();
      const result = await createPacingGoal({
        report_id: parseInt(id),
        type: 'monthly',
        metric: metric.id,
        value: parseInt(goal),
      }).unwrap();
      addSnackbar({
        variant: 'success',
        message: 'Monthly goal created',
        persist: false,
      });
      setFilter({ type: 'add', value: { monthly: { id: result.id, type: 'create' } } });
      reset();
      toggle();
      const { id: accountId, name } = account ?? {};
      track({ mixpanel, type: 'new-pacing-goal', options: {
        monthly_pacing_metric: metric.id,
        monthly_pacing_id: result.id,
        scoreboard_id: id,
        user_id: accountId,
        user_name: name,
      } });
    } catch (error) {
      addSnackbar({
        variant: 'error',
        message: 'Error creating monthly goal',
        persist: false,
      });
      console.error('Error creating goal', error);
    } finally {
      setSubmitting(false);
    }
  }, [account, addSnackbar, createPacingGoal, getValues, id, mixpanel, reset, setFilter, trigger]);

  const renderForm = useMemo(() => (
    <DrawerView
      component="form"
      onSubmit={handleSubmit}
      title='Create Monthly Goal'
      onClose={toggle}
      footer={
        <DrawerFooter
          primaryButtonProps={{
            children: 'Save',
            type: 'submit',
            disabled: isLoading || submitting || !isValid,
          }}
          secondaryButtonProps={{
            children: 'Cancel',
            onClick: (): void => {
              reset();
              toggle();
            },
            disabled: isLoading || submitting
          }}
        />
      }
    >
      <MonthlyGoalForm control={control} trigger={trigger} />
    </DrawerView>
  ), [handleSubmit, isLoading, submitting, isValid, control, trigger, reset]);

  return (
    <>
      <Button
        variant='tertiary'
        size='large'
        startIcon={<PlusIcon />}
        onClick={toggle}
        {...buttonProps}
      >
        {title}
      </Button>
      <Drawer open={open} onClose={toggle} isBlank>
        {renderForm}
      </Drawer>
    </>
  );
};

export default memo(CreateMonthlyGoalDrawer);
