/**
 * Entities -> Service Expansions -> Adapter
 */

import { createEntityAdapter } from '@reduxjs/toolkit';
import { compareField } from '../../utils/compareField';
import { Alert } from './alert';

/**
 * Redux toolkit entity adapter for storing/caching entities by id
 */
export const alertsAdapter = createEntityAdapter<Alert>({
  selectId: (service) => service.id,
  sortComparer: compareField('name'),
});
