import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider, AppState } from '@auth0/auth0-react';
import { config } from 'config';

const Auth0ProviderWithHistory = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const history = useHistory();

  const onRedirectCallback = (appState?: AppState): void => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      cacheLocation="localstorage"
      authorizationParams={
        {
          redirect_uri: window.location.origin,
          ...(config.auth0.audience ? { audience: config.auth0.audience } : null)
        }
      }
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
