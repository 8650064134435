import React, { useMemo, memo } from 'react';
import {
  Alert,
  EmptyState,
  EmptyStateProps,
  Link,
  NavigationAddChartIcon,
  NavigationBarChartFilledIcon,
  NavigationInsightsFilledIcon,
  NewWindowIcon,
  Spinner,
} from '@sprnova/nebula';
import { LookerReportTypeEnum } from 'features/clients/constants';
import css from './LookerReportPageContent.module.scss';

type LookerReportPageContentProps = {
  dashboardUrl?: string;
  documentationUrl?: string;
  error?: string;
  hasReports?: boolean;
  isExternal?: boolean;
  isLoading?: boolean;
  reportType?: LookerReportTypeEnum;
}

export const LookerReportPageContent: React.FC<LookerReportPageContentProps> = ({
  dashboardUrl,
  documentationUrl,
  error,
  hasReports = false,
  isExternal = false,
  isLoading = false,
  reportType,
}) => {

  const noReportsEmptyStateProps: Partial<EmptyStateProps> = useMemo(() => {
    let resultProps: Partial<EmptyStateProps> = {};

    const defaultProps: Partial<EmptyStateProps> = {
      size: 'large',
      title: 'No reports available',
    };

    if (isExternal) {
      switch (reportType) {
        case LookerReportTypeEnum.Base:
          resultProps = {
            size: 'large',
            icon: <NavigationBarChartFilledIcon />,
            title: 'Your account team hasn\'t set up any reports (yet)!',
            description: 'Please reach out to your account team with any questions.',
          };
          break;
        case LookerReportTypeEnum.Custom:
          resultProps = {
            size: 'large',
            icon: <NavigationAddChartIcon />,
            title: 'Your account team hasn\'t set up any Custom Reports (yet)!',
            description: 'Please reach out to your account team with any questions about your custom reports or requesting new ones.',
          };
          break;
        case LookerReportTypeEnum.Standard:
          resultProps = {
            size: 'large',
            icon: <NavigationInsightsFilledIcon />,
            title: 'Your account team hasn\'t set up any Insights AI Reports (yet)!',
            description: 'Please reach out to your account team with any questions.',
          };
          break;
        default:
          resultProps = defaultProps;
      }
    } else {
      resultProps = defaultProps;
    }

    return resultProps;
  }, [reportType, isExternal]);

  let content;

  if (dashboardUrl) {
    content = (
      <>
        {documentationUrl ? (
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Link
              href={documentationUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={css.documentationLink}
              mb={2}
            >
              View documentation
              <NewWindowIcon sx={{
                width: '20px',
                height: '20px',
                verticalAlign: 'middle',
                marginLeft: '5px'
              }} />
            </Link>
          </div>
        ) : null}
        <iframe
          style={{paddingBottom: '10px'}}
          key={dashboardUrl}
          src={dashboardUrl}
        />
      </>
    );
  } else if (isLoading) {
    content = <Spinner style={{display: 'block', margin: '0 auto'}} />;
  } else if (error) {
    content = <Alert severity="error" sx={{marginBottom: '20px'}}>{error}</Alert>;
  } else if (hasReports) {
    content = (
      <EmptyState
        size="large"
        title="No report selected"
        description="Select a report in the dropdown above to view."
      />
    );
  } else {
    content = <EmptyState {...noReportsEmptyStateProps} />;
  }

  return content;
};

export default memo(LookerReportPageContent);
