import React, { useReducer } from 'react';
import { isEqual } from 'lodash'; // Assuming isEqual is imported from lodash

type Action<T> = {
  type: string;
  value?: T;
};

type FilterReturnType<T> = {
  filter: Partial<T>;
  setFilter: React.Dispatch<Action<T>>;
};

type FilterContextType<T> = T | undefined;
type FilterProviderProps<T> = {
  children: React.ReactNode;
  filter: FilterContextType<T>; // Allow filter to be undefined
};

type Identity = Record<string, any>;
export const Filters = (initialState?: Partial<Identity>): FilterReturnType<Identity> => {
  const [state, dispatch] = useReducer((state: Partial<Identity>, action: Record<string, any>): any => {
    switch (action.type) {
      case 'add': {
        const newState: Partial<Identity> = { ...state };
        if (action.value) {
          Object.keys(action.value).forEach((key) => {
            // Check if the key already exists in the state and if the value is different
            if (action.value && !isEqual(newState[key as keyof Identity], action.value[key as keyof Identity])) {
              newState[key as keyof Identity] = action.value[key as keyof Identity] as undefined; // Typecast the value as undefined
            }
          });
        }
        return newState;
      }
      case 'remove': {
        const newState: Partial<Identity> = { ...state };
        if (action.value) {
          delete newState[action.value as keyof Identity];
        }
        return newState;
      }
      case 'reset': {
        return {};
      }
      default:
        return state;
    }
  }, { ...initialState } as Partial<Identity>);

  return {
    filter: state,
    setFilter: dispatch,
  };
};

export const useFilterContext = (): FilterContextType<any> => {
  const context = React.useContext(FilterContext);
  return context !== undefined ? context : {};
};

export const FilterProvider = ({ children, filter }: FilterProviderProps<Identity>): React.ReactElement => {
  if (filter) {
    return (
      <FilterContext.Provider value={filter}>
        {children}
      </FilterContext.Provider>
    );
  }
  return <>{children}</>;
};

export const FilterContext = React.createContext<FilterContextType<any>>(undefined);
