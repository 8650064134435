import React, {memo, useMemo, useState} from 'react';
import {Box, Grid, Card, Link, CardHeader, Typography} from '@sprnova/nebula';
import css from './NavigationBox.module.scss';

const NavigationBox = (): JSX.Element => {
  const [linkStatus, setLinkStatus] = useState<string>('StrategyOverviewNavigation');

  const cardContent = useMemo ( () => {
    return (
      <>
    <Box className={css.formLinkNav} sx={{width: '100%'}}>
      <Link href="#strategy-overview-navigation" underline="hover" className={ linkStatus === 'StrategyOverviewNavigation' ? css.active : '' } onClick={() => setLinkStatus('StrategyOverviewNavigation')}>Strategy Overview</Link>
      <Link href="#contract-details-navigation" underline="hover" className={ linkStatus === 'ContractDetailsNavigation' ? css.active : '' } onClick={() => setLinkStatus('ContractDetailsNavigation')}>Contract Details</Link>
      <Link href="#combined-strategies-navigation" underline="hover" className={ linkStatus === 'CombinedStrategiesNavigation' ? css.active : '' } onClick={() => setLinkStatus('CombinedStrategiesNavigation')}>Combined Strategies</Link>
    </Box>
      </>
    );
  }, [linkStatus]);

  return (
    <Card
      header={
        <CardHeader
          title= {
            <Typography variant="h4" component="div" gutterBottom>
              Navigation
            </Typography>
          }
        />}
      className={css.card}
    >
      {cardContent}
    </Card>
  );
};

export default memo(NavigationBox);
