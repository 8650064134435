/**
 * Get scoreboards (reports)
 */
import { Pagination, Projection } from 'api/entityGraphQL/types';
import { StatusArgs, IndicatorArgs } from './getScoreboard';
import tagTypes from './tagTypes';
import { Scoreboard } from './types';
import { baseApi, cleanArguments, paginationProjection } from '..';

export type GetScoreboardsQuery = {
  limit?: number;
  page?: number;
  sort?: string[];
  id?: number;
  name?: string;
  business_type_id?: number;
  account_manager_id?: number;
  executive_sponsor_id?: number;
  goal_set?: string;
  my_accounts?: number;
  my_scoreboards?: number;
  statusArgs?: StatusArgs;
  indicatorArgs?: IndicatorArgs;
  projection?: Projection;
};

export type GetScoreboardsResult = Pagination & {
  data: Scoreboard[]
};

export const GET_SCOREBOARDS_ENDPOINT_KEY = 'getScoreboards';

export const { useGetScoreboardsQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_SCOREBOARDS_ENDPOINT_KEY]: build.query<GetScoreboardsResult, GetScoreboardsQuery>({
      transformResponse: (response: { reports: GetScoreboardsResult }) => response.reports,
      providesTags: (result) => {
        const { data } = result ?? { data: [] };

        return [
          tagTypes.SCOREBOARDS,
          ...data.map((scoreboard) => ({
            id: scoreboard.id,
            type: tagTypes.SCOREBOARD
          }))
        ];
      },
      query: ({
        projection = { id: true },
        page = 1,
        limit = 10,
        name,
        statusArgs,
        indicatorArgs,
        ...args
      }) => {
        const __args: Partial<GetScoreboardsQuery> = {
          page,
          limit,
          ...args
        };
        if (name) {
          __args.name = `*${name}*`;
        }

        if (statusArgs) {
          /**
           * Add status args to projection
           */
          projection = {
            ...projection,
            statuses: {
              __args: cleanArguments(statusArgs),
              id: true,
              ...projection.statuses,
            }
          };
        }

        if (indicatorArgs) {
          /**
           * Add indicator args to projection
           */
          projection = {
            ...projection,
            indicators: {
              __args: cleanArguments(indicatorArgs),
              id: true,
              ...projection.indicators,
            }
          };
        }

        return {
          body: {
            query: {
              reports: {
                __args: cleanArguments(__args),
                data: projection,
                ...paginationProjection
              }
            }
          }
        };
      }
    })
  })
});
