import React, { FC, memo, useCallback } from 'react';
import { Checkbox, InfoTooltip, Tooltip } from '@sprnova/nebula';
import { Task } from 'features/entitiesRedux';
import { MultiplierSlug } from 'features/strategies/ViewStrategyPage/components/HourlyBreakdown/components/HourlyBreakdownTable/HourlyBreakdownTable';
import css from './TaskItem.module.scss';

type Props = {
  task: Task;
  checked: boolean;
  onToggle: (task: Task, checked: boolean) => void;
};

const TaskItem: FC<Props> = ({ task, checked, onToggle }) => {
  const subTasks = task.children;
  const hasChildren = Array.isArray(subTasks) && !!subTasks.length;
  const handleToggle = useCallback((): void => onToggle(task, checked), [checked, onToggle, task]);

  const renderTaskName = useCallback(() => (
    <>
      {`${task.name}`}
      {task.multiplier ? (
        <>
          {' '}
          <MultiplierSlug {...task.multiplier} />
        </>
      ) : (
        ''
      )}
    </>
  ), [task.multiplier, task.name]);

  /**
   * Renders the number, with a tooltip, of subtasks that a task has. Only renders if it has at least 1.
   */
  const renderTaskLabel = useCallback(() => {
    const tooltipContent = (
      <ol className={css.tooltip__content__list}>
        {subTasks.map(({ name }) => (
          <li key={name}>{name}</li>
        ))}
      </ol>
    );
    const tooltipHeading = (
      <>Combined Strategies</>
    );
    if (hasChildren) {
      return (
        <>
          <Tooltip heading={tooltipHeading} content={tooltipContent} variant="plain">
            <span className={css.subtask}>{ `[+ ${subTasks.length}]`}</span>
          </Tooltip>
        </>
      );
    }
    return <></>;
  }, [hasChildren, subTasks]);

  const renderTooltipSnippetSummary = useCallback(() => {
    if (task.snippet_summary) {
      return (
        <InfoTooltip
          id={`tooltipSnippetSummary-${task.id}`}
          content={task.snippet_summary}
          placement='right'
          variant='plain'
        />
      );
    }
    return null;
  }, [task.id, task.snippet_summary]);

  return (
    <>
      <Checkbox
        label={<div className={css.checkbox__label}>{renderTaskName()} {renderTaskLabel()} {renderTooltipSnippetSummary()}</div>}
        checked={checked}
        onChange={handleToggle}
      />
    </>
  );
};

export default memo(TaskItem);
