import { difference, keys, omitBy, union } from 'lodash';

export const countUniqueFilters = (defaultValues: Record<string, unknown>, filters: Record<string, unknown>): number => {
  // Get all unique keys from both objects
  const allKeys = union(keys(defaultValues),
    // remove empty arrays from filters
    keys(omitBy(filters, (value: unknown) => Array.isArray(value) && value.length === 0)));

  // Filter out keys that exist in defaultValues
  const uniqueFilterKeys = difference(allKeys, keys(defaultValues))
    .filter(item => !['sort', 'limit', 'page', 'match_name_in_all_models'].includes(item));

  // Return the count of unique filter keys
  return uniqueFilterKeys.length;
};
