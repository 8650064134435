import React, { FC, memo } from 'react';
import { MailIcon } from '@sprnova/nebula';

type ContactInfoCellProps = {
  email?: string;
};

const ContactInfoCell: FC<ContactInfoCellProps> = ({ email }) => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
    }}>
      {email ? (
        <>
          <MailIcon />
          <a
            style={{
              color: '#0277B7',
              margin: '0 0 0 4px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            href={`mailto:${email}`}
          >
            {email}
          </a>
        </>
      ) : '-'}
    </div>
  );
};

export default memo(ContactInfoCell);
