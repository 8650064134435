/**
 * AddTasksModal
 */

import React, { FC, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { Button } from 'components/Button';
import { Skeleton } from 'components/Skeleton';
import difference from 'lodash/difference';
import shouldTrackReUnlockedBlueprint from 'utils/blueprints/blueprintMixpanelUtil';
import { useMixpanelTrack } from 'utils';
import { Strategy } from 'features/entitiesRedux';
import { useDepartments, Department } from 'features/entitiesRedux/models/department';
import { Task } from 'features/entitiesRedux/models/task';
import { SelectionList, ConfirmSelection } from './components';
import css from './AddTasksModal.module.scss';

const projection = {
  name: true,
  id: true,
  services: {
    id: true,
    name: true,
    pricing_version: {
      name: true,
      slug: true,
    },
    tasks: {
      id: true,
      name: true,
      hours: true,
      hours_per_unit: true,
      projected_hours: true,
      is_configurable: true,
      unit_type: true,
      children: {
        id: true,
        name: true,
      },
      multiplier: {
        name: true,
        slug: true,
        id: true,
      },
      pricing_version: {
        name: true,
        slug: true,
      },
    },
  },
};

enum STEPS {
  SELECTION = 'selection',
  CONFIRM_SELECTION = 'confirm-selection',
}

type Props = {
  open: boolean;
  creating?: boolean;
  continueLabel?: string;
  onToggle: (open: boolean) => void;
  onFinish?: (tasks: Task[] | any) => void;
  onContinue?: (tasks: Task[]) => void;
  excludedTaskIds?: number[];
  strategy?: Strategy;
};

const AddTasksModal: FC<Props> = ({
  continueLabel,
  creating,
  excludedTaskIds,
  onContinue,
  onFinish,
  onToggle,
  open,
  strategy,
}) => {
  const { id } = useParams<{ [x: string]: string }>();
  const [selected, setSelected] = useState<Task[]>([]);
  const [currentStep, setCurrentStep] = useState(STEPS.SELECTION);
  const { departments: allDepartments, loading } = useDepartments({ projection });
  const selectedIds = selected.map((task) => task.id);

  /**
   * Mixpanel event tracking
   */
  const mixpanel = useMixpanelTrack();
  const trackAddTask = () => {
    try {
      const options = {
        tasks: selected?.map((task: Task) => {
          return {
            task: task?.name,
            isConfigurable: task?.is_configurable,
            projectedHours: task?.projected_hours,
          };
        }),
        blueprintId: id,
        taskIds: selectedIds,
      };
      if (process.env.NODE_ENV !== 'production') console.log('🛤 Track: Blueprint tasks created', { options });

      let eventTitle = 'Blueprint tasks created';
      if (strategy && shouldTrackReUnlockedBlueprint(strategy)) {
        eventTitle = 'Blueprint Re-Unlocked, edited blueprint: tasks created';
      }
      mixpanel(eventTitle, options);
    } catch (error) {
      console.error('Mixpanel error', error);
    }
  };

  const departments = useMemo(() => {
    // Filter our excluded task IDs if needed
    if (Array.isArray(excludedTaskIds) && excludedTaskIds.length) {
      const exludedIds = new Set(excludedTaskIds);
      return allDepartments?.filter(dept => dept.name !== 'AM' && dept.name !== 'ES')?.reduce((acc: Department[], department: Department) => acc.concat({
        ...department,
        services: department?.services?.map(service => ({
          ...service,
          tasks: service?.tasks?.filter((task: Task) => !exludedIds.has(task.id)),
        }))
      }), []);
    }
    return allDepartments;
  }, [allDepartments, excludedTaskIds]);

  // Only allow submitting if all configurable tasks has units
  const hasEmptyUnitFields = !!selected.filter(
    (t: any) => typeof t.units !== 'number' && t.is_configurable === 'yes'
  ).length;

  const disableSubmit = creating || !selected.length || hasEmptyUnitFields;

  const handleSelect = (tasks: Task[]) => {
    const update = selected.concat(difference(tasks, selected));
    setSelected(update);
  };

  const handleUnselect = (tasks: Task[]) => {
    const ids = new Set(tasks.map((task) => task.id));
    setSelected(selected.filter((task) => !ids.has(task.id)));
  };

  const handleContinue = () => {
    // Skips the confirm step
    if (typeof onContinue === 'function') {
      onContinue(selected);
    } else {
      setCurrentStep(STEPS.CONFIRM_SELECTION);
    }
  };

  const handleGoBack = () => {
    setCurrentStep(STEPS.SELECTION);
  };

  const handleCancel = () => {
    onToggle(false);
  };

  const handleReset = () => {
    setSelected([]);
    setCurrentStep(STEPS.SELECTION);
  };

  const handleAddTasks = () => {
    trackAddTask();
    if (typeof onFinish === 'function') {
      onFinish(selected);
    }
  };

  // Update selected task units
  const handleUpdateTask = (task: Task, units: number | string) => {
    const updated = selected.map((t: Task) =>
      t.id === task.id ? { ...task, units } : t
    );
    setSelected(updated);
  };

  // Reset on close
  useEffect(() => {
    if (!open) {
      handleReset();
    }
  }, [open]);

  const renderContent = () => {
    if (loading) {
      return <Skeleton />;
    }

    // Select tasks
    if (currentStep === STEPS.SELECTION) {
      return (
        <SelectionList
          departments={departments}
          onSelect={handleSelect}
          onUnselect={handleUnselect}
          selectedIds={selectedIds}
          currentId={Number(id)}
        />
      );
    }

    // Confirm selection and customize hours (if possible) before adding tasks
    if (currentStep === STEPS.CONFIRM_SELECTION) {
      return (
        <ConfirmSelection
          loading={creating}
          tasks={selected}
          onRemove={(task) => handleUnselect([task])}
          onUpdate={handleUpdateTask}
          hasEmptyUnits={hasEmptyUnitFields}
        />
      );
    }

    return null;
  };

  const renderCancelButton = (
    <Button
      type="text"
      htmlType="button"
      onClick={handleCancel}
      disabled={creating}
    >
      Cancel
    </Button>
  );

  const renderSubmitButton = (
    <Button
      size="large"
      type="primary"
      htmlType="button"
      onClick={handleAddTasks}
      disabled={disableSubmit}
    >
      {creating ? 'Please wait...' : 'Add strategies'}
    </Button>
  );

  const footer =
    currentStep === STEPS.SELECTION
      ? [
        <div className={css.footerSummary} key="summary">
          {`${selected.length} tasks selected`}
        </div>,
        <div className={css.footerButtons} key="buttons">
          {renderCancelButton}
          <Button
            size="large"
            type="primary"
            disabled={!selected.length}
            htmlType="button"
            onClick={handleContinue}
          >
            {continueLabel || 'Continue'}
          </Button>
        </div>,
      ]
      : [
        <Button
          key="backButton"
          icon={<ArrowLeftOutlined />}
          type="text"
          htmlType="button"
          onClick={handleGoBack}
          className={css.backButton}
          disabled={creating}
        >
            Back
        </Button>,
        <div className={css.footerButtons} key="buttons">
          {renderCancelButton}
          {renderSubmitButton}
        </div>,
      ];

  if (loading) {
    return null;
  }

  return (
    <Modal
      className={css.root}
      width={1000}
      title={
        currentStep === STEPS.SELECTION ? 'Select strategies' : 'Confirm selection'
      }
      visible={open}
      footer={footer}
      onCancel={!creating ? handleCancel : undefined}
      destroyOnClose
      maskClosable={false}
    >
      {renderContent()}
    </Modal>
  );
};

export default AddTasksModal;
