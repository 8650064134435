/**
 * SelectMultiplier
 */

import React, { forwardRef } from 'react';
import { useMultipliers } from 'utils/hooks';
import { Multiplier } from 'features/entitiesRedux';
import Select, { Props as SelectProps } from '../Select/Select';

const SelectMultiplier = forwardRef(({ skeleton, ...props }: SelectProps, ref: any) => {
  const { multipliers, loading } = useMultipliers();

  return (
    <Select
      showSearch
      optionFilterProp="children"
      skeleton={loading || skeleton}
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      {...props}
    >
      {multipliers.map((multiplier: Multiplier) => (
        <Select.Option key={multiplier.id} value={multiplier.id}>
          {`${multiplier.name} (${multiplier.value}x normal cost)`}
        </Select.Option>
      ))}
    </Select>
  );
});

export default SelectMultiplier;
