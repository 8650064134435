import React, { FC, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AppHeaderUserButton,
  Dropdown,
  ActionMenu,
  PositionerAnchorProps,
  PositionerContentProps,
  PositionerMeta,
} from '@sprnova/nebula';
import Cookies from 'js-cookie';
import { useAccount } from 'features/global/hooks/useAccount';

export const UserDropdown: FC = () => {
  const { logout } = useAuth0();

  const { account } = useAccount();
  const [isLoggingOut, setLoggingOut] = useState(false);
  const initial = account?.name?.charAt(0) || '';

  const handleLogout = (): void => {
    setLoggingOut(true);

    Cookies.remove('BEARER-TOKEN');
    Cookies.remove('auth0_user_type');

    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    });
  };

  const renderUserDropdown = (props: PositionerAnchorProps, { isOpen }: PositionerMeta): ReactNode => (
    <AppHeaderUserButton
      image={account?.avatar}
      initial={initial}
      {...props}
      open={isOpen}
      disabled={!account}
    />
  );

  const renderDropdownContent = ({ onClose }: PositionerContentProps): ReactNode => (
    <ActionMenu onItemClick={onClose}>
      <ActionMenu.Item
        component={Link}
        to={`/users/${account?.id}`}
      >
        My Profile
      </ActionMenu.Item>
      <ActionMenu.Item
        disabled={isLoggingOut}
        onClick={handleLogout}
      >
        {isLoggingOut ? 'Logging out...' : 'Log out'}
      </ActionMenu.Item>
    </ActionMenu>
  );

  return (
    <Dropdown placement="bottom-start" anchor={renderUserDropdown}>
      {renderDropdownContent}
    </Dropdown>
  );
};
