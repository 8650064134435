/**
 * SelectRole
 */

import React, { forwardRef } from 'react';
import { useRoles } from 'utils/hooks';
import { RoleType } from 'features/entitiesRedux';
import Select, { Props as SelectProps } from '../Select/Select';

type Props = SelectProps & {
  type?: string;
  types?: string[];
}

const SelectRole = forwardRef(({ skeleton: skeletonProp, type, types, ...props }: Props, ref: any) => {
  const { role_types, loading } = useRoles({ type, types });
  const skeleton = loading && !role_types.length;

  return (
    <Select
      showSearch
      skeleton={skeleton || skeletonProp}
      optionFilterProp='children'
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      {...props}
    >
      {role_types?.map((role_type: RoleType) => (
        <Select.Option
          key={role_type.id}
          value={role_type.slug}
          disabled={
            props.mode === 'multiple' &&
            role_type.slug.includes('talent') &&
            !props?.value?.find((x: Partial<RoleType['slug']>[]) => x.includes(role_type.slug)) &&
            !!props?.value?.find((x: Partial<RoleType['slug']>[]) => x.includes('talent'))
          }
        >
          {role_type.name}
        </Select.Option>
      ))}
    </Select>
  );
});

SelectRole.displayName = 'SelectRole';

export default SelectRole;
