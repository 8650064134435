import { baseApi } from 'api/crudGraphQL';
import { StrategyFrequency } from 'api/crudGraphQL/strategies/types';
import { Projection } from 'api/entityGraphQL';
import tagTypes from './tagTypes';

export type GetStrategyFrequenciesQuery = {
  projection?: Projection;
};

export type GetStrategyFrequenciesResult = StrategyFrequency[];

export const GET_STRATEGY_FREQUENCIES_ENDPOINT = 'getStrategyFrequencies';

export const { useGetStrategyFrequenciesQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_STRATEGY_FREQUENCIES_ENDPOINT]: build.query<GetStrategyFrequenciesResult, GetStrategyFrequenciesQuery>({
      transformResponse: (response: { strategy_frequencies: GetStrategyFrequenciesResult }) => response.strategy_frequencies,
      providesTags: (strategyFrequencies = []) => [
        tagTypes.STRATEGY_FREQUENCIES,
        ...strategyFrequencies.map(strategyFrequency => ({
          id: strategyFrequency.id,
          type: tagTypes.STRATEGY_FREQUENCY
        }))
      ],
      query: ({
        projection = { id: true, name: true }
      }) => {
        return {
          body: {
            query: {
              strategy_frequencies: {
                ...projection
              }
            }
          }
        };
      }
    })
  })
});
