import React from "react";
import { Menu as AntdMenu } from "antd";
import { MenuProps } from "antd/lib/menu";
import classNames from "classnames";
import css from "./Menu.module.scss";

interface Props extends MenuProps {
  className?: string;
}

const Menu = ({ className, ...props }: Props) => {
  return <AntdMenu {...props} className={classNames(className, css.root)} />;
};

export default Object.assign(Menu, {
  Divider: AntdMenu.Divider,
  Item: AntdMenu.Item,
  SubMenu: AntdMenu.SubMenu,
  ItemGroup: AntdMenu.ItemGroup,
});
