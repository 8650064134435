/**
 * Card -> Header
 */

import React, { FC } from 'react';
import classNames from 'classnames';
import css from './CardHeader.module.scss';

export interface CardHeaderProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  className?: string;
  size?: 'default' | 'large';
  sub?: React.ReactNode;
  title?: React.ReactNode;
  renderEnd?: React.ReactNode;
  noBorder?: boolean;
}

const SIZES: Record<string, {
  title: {
    element: keyof JSX.IntrinsicElements;
  },
  sub: {
    element: keyof JSX.IntrinsicElements;
  }
}> = {
  default: {
    title: {
      element: 'h3',
    },
    sub: {
      element: 'h4',
    }
  },
  large: {
    title: {
      element: 'h2',
    },
    sub: {
      element: 'h3',
    }
  }
};

export const CardHeader: FC<CardHeaderProps> = ({
  title,
  sub,
  size = 'default',
  className,
  renderEnd,
  noBorder,
  ...props
}) => {
  const Title: keyof JSX.IntrinsicElements = SIZES[size].title.element;
  const Sub: keyof JSX.IntrinsicElements = SIZES[size].sub.element;

  return (
    <div
      className={classNames(
        css.root,
        css[`size--${size}`],
        { [css.noBorder]: noBorder },
        className
      )}
      {...props}
    >
      <div className={css.content}>
        {title ? <Title className={css.title} style={{ fontWeight: 600 }}>{title}</Title> : null}
        {sub ? <Sub className={css.sub}>{sub}</Sub> : null}
      </div>
      {renderEnd ? <div className={css.end}>{renderEnd}</div> : null}
    </div>
  );
};
