import React, { useEffect, useCallback, ReactElement, useMemo, BaseSyntheticEvent, memo } from 'react';
import { useForm } from 'react-hook-form';
import { Controller, Grid, GridProps } from '@sprnova/nebula';
import { isValid } from 'date-fns';
import { DebounceSettings, debounce } from 'lodash';
import { DateParam, JsonParam, NumberParam, NumericObjectParam, ObjectParam, StringParam, useQueryParams } from 'use-query-params';

export type ItemsType = {
  component: ReactElement | JSX.Element | React.FC | any;
  name: string;
  props?: Record<string, any>;
  paramType?:
  typeof StringParam
  | typeof NumberParam
  | typeof DateParam
  | typeof ObjectParam
  | typeof NumericObjectParam
  | typeof StringParam[]
  | typeof NumberParam[]
  | typeof DateParam[]
  | typeof ObjectParam[]
  | typeof NumericObjectParam[];
  grid?: GridProps;
}
interface FormProps {
  onSubmit?: (data: any) => void;
  submitOnChange?: boolean;
  button?: React.ReactElement | false;
  withQueryParams?: boolean;
  gridSpacing?: number;
  rowSpacing?: number;
  columnSpacing?: Record<string, number> | Record<string, number>[];
  gridItemBreakpoints?: Record<string, number> | Record<string, number>[];
  justifyContent?: GridProps['justifyContent'];
  items?: ItemsType[];
  debounceTime?: number;
  debounceOptions?: DebounceSettings;
  style?: React.CSSProperties;
}

/**
 *
 * @param onSubmit - function to call when the form is submitted
 * @param children - the form fields
 * @param submitOnChange - whether to submit the form on change
 * @param button - the button to submit the form; if false, no button will be rendered
 * @param withQueryParams - whether to use query params; if true, values will be set from the query params on load and the query params will be updated on change
 * @param gridSpacing - the spacing between the form fields
 * @param gridItemBreakpoints - the breakpoints for the form fields
 * @returns a form
 */
const FormV2: React.FC<FormProps> = ({
  onSubmit,
  submitOnChange,
  button,
  withQueryParams = false,
  gridSpacing = 2,
  rowSpacing,
  justifyContent,
  items,
  debounceTime = 500,
  debounceOptions = { leading: false, trailing: true, maxWait: 2000 },
  style,
}) => {
  const { handleSubmit, control, trigger, getValues, setValue, resetField } = useForm();

  const [queryParams, setQueryParams] = useQueryParams<any>(
    items?.reduce((acc: any, item: any) => {
      if (item.paramType) {
        return {
          ...acc,
          [item.name]: item.paramType,
        };
      }

      return acc;
    }, {})
    || {}
  );


  useEffect(() => {
    // Create a new record to store only non-null, non-undefined query parameters
    const validQueryParams: Record<string, any> = {};

    if (withQueryParams) {
      /**
       * Set the values from the query params on load
       */
      if (items) {
        items?.map(item => {
          const value = queryParams[item.name] || undefined;
          /**
           * Throws a "Maximum update depth exceeded" error if we try to set a date value
           */
          if (isValid(value)) {
            /**
             * https://github.com/Hacker0x01/react-datepicker/issues/1565#issuecomment-1405228874
             * This appears to be a bug with MUI DatePicker and date-fns;
             * It's possible that updating to the latest version of MUI will fix this and/or date-fns will fix this
             * but that will likely require updating our node version and many other packages.
             *
             * For now, MUI DatePicker will not update from query params on load
             */
            // setValue(item.name, format(new Date(value), 'MM/dd/yyyy'));
          } else {
            setValue(item.name, value);
          }
          /**
           * Unclear if the bug applies to all date selectors or just MUI DatePicker, so we'll leave this here for now
           */
          // if (!(value instanceof Date)) setValue(item.name, value);

          if (value !== undefined && value !== null) {
            validQueryParams[item.name] = value;
          }
        });
      }
    }
    /**
     * Submit the form on load
     */
    if (submitOnChange && onSubmit) {
      if (withQueryParams) {
        handleSubmit(() => onSubmit(validQueryParams))();
      } else {
        handleSubmit(onSubmit)();
      }
    }
  // if you fix the deps here it will cause an infinite loop
  // 1+ dependencies is constantly changing as a result of this effect, triggering this effect infinitely when that dep changes
  // until we can fix that, we need to leave out the deps
  }, []);

  const isAnyItemInQueryParams = useCallback((itemNames: string[]): boolean => {
    let atLeastOneItemPresent = false;
    for (const itemName of itemNames) {
      if (queryParams[itemName]) {
        atLeastOneItemPresent = true;
        break;
      }
    }
    return atLeastOneItemPresent;
  }, [queryParams]);

  /**
   * Clears the specified query parameter from the URL.
   *
   * @param {string} paramName - The name of the query parameter to be cleared.
   * @returns {void} - This function does not return anything.
   */
  const clearQueryParam = useCallback((paramName: string): void => {
    const paramToClear: Record<string, undefined> = { [paramName]: undefined };
    setQueryParams(paramToClear, 'replaceIn');
  }, [setQueryParams]);

  /**
   * Formats the label to be used as a control name for React Hook Form.
   *
   * @param {string} [label] - The label to be formatted.
   * @returns {string | undefined} - The formatted label with spaces replaced by underscores and converted to lowercase, or undefined if no label is provided.
   */
  const formatLabel = useCallback((label?: string) => {
    return label?.replace(/ /g, '_').toLowerCase();
  }
  , []);

  const handleClickSubmit = useCallback(async (event: any) => {
    event.preventDefault();
    const values = getValues();

    if (withQueryParams) {
      setQueryParams(values);
    }
    if (onSubmit) {
      handleSubmit(onSubmit)();
    }
  }, [getValues, handleSubmit, onSubmit, setQueryParams, withQueryParams]);

  /**
   * Update the query params and submit the form on change if submitOnChange is true.
   *
   * @param {BaseSyntheticEvent} event - The event object triggered by the form change.
   * @returns {Promise<void>} - A Promise that resolves once the debounced asynchronous function completes.
   */
  const handleChangeForm = useCallback(debounce(async (event: BaseSyntheticEvent) => {
    const { name, id, label, value, checked } = event.target || {};

    if (withQueryParams && submitOnChange) {
      const key = name || id || formatLabel(label);
      // TextField also has boolean type checked value, so we need another indicator to check if it's a switch component.
      const isCheckbox = event.target.type === 'checkbox';
      if (typeof checked === 'boolean' && isCheckbox) {
        /**
         * Handle Switch components
         */
        setQueryParams({ [key]: checked });
      } else {
        const isEmptyValue = value === '' || value === null || value === undefined;
        if (isEmptyValue) {
          clearQueryParam(key);
        } else {
          const { paramType } = items?.filter(item => item.name === id)[0] ?? {};
          if (paramType !== JsonParam) {
            /**
             * Do not update query param if the value is a string input
             */
            setQueryParams({ [key]: value });
          }
        }
      }
    }
    if (submitOnChange && onSubmit) {
      await trigger();
      handleSubmit(onSubmit)();
    }
  }, debounceTime, debounceOptions),
  [withQueryParams, submitOnChange, resetField, onSubmit, setQueryParams, formatLabel, trigger, handleSubmit]);

  const handleClear = useCallback((name: string, onClear: any) => {
    // Clear the form value and query param value.
    resetField(name);
    if (withQueryParams) {
      clearQueryParam(name);
    }
    if (onClear) {
      // Invoke the onClear callback function.
      onClear();
    }
  }, [resetField, withQueryParams, clearQueryParam]);

  /**
   * This functions is called when a user made a change on the filter UI
   * It updates the form value and query param value
   *
   * @param {React.SyntheticEvent | any} e - The event object or value associated with the change.
   * @param {any} value - The new value of the item.
   * @param {string} name - The name of the item.
   * @param {any} item - The item being changed.
   * @returns {void} - This function does not return anything.
   */
  const handleItemChange = useCallback(debounce((e: React.SyntheticEvent | any, value: any, name: string, item: any) => {
    /**
     * Not all components have a value prop, so we need to handle them differently
     */
    const { props: { onClear, clearItemsOnUpdate }, component: { displayName } } = item;
    value = displayName === 'DatePicker'
      ? e
      : displayName === 'Autocomplete'
        ? value
        : e.target?.value;
    if (Array.isArray(value) && value.length === 0) {
      value = undefined;
    }
    const isEmptyValue = value === '' || value === null || value === undefined;
    // Update form value.
    if (isEmptyValue) {
      handleClear(name, onClear);
    } else {
      setValue(name, value);
    }

    // Update query param value.
    if (withQueryParams && !isEmptyValue) {
      // If the value is valid, update query param.
      setQueryParams({ [name]: value });
    }

    if (clearItemsOnUpdate) {
      // Reset these other fields when the current field is set.
      clearItemsOnUpdate.forEach((otherField: string) => {
        if (otherField === name) return;
        resetField(otherField);
        if (withQueryParams) {
          clearQueryParam(otherField);
        }
      });
    }

    if (submitOnChange && onSubmit) {
      handleSubmit(() => onSubmit({ [name]: value }))();
    }
  }, debounceTime, debounceOptions),
  [setQueryParams, withQueryParams, resetField, onSubmit, handleClear]);

  /**
   * This function gets values either from query params or from the form to display on UI.
   *
   * @param {ItemsType} item - The item from which to retrieve the value.
   * @returns {any} - The value of the specified item.
   */
  const getItemValue = useCallback((item: ItemsType): any => {

    const componentTypeName = item.component.displayName;
    if (componentTypeName === 'TextField') {
      // Use the form value for TextField components.
      return getValues(item.name);
    }
    // Get the value from the query params if withQueryParams is true.
    if (withQueryParams) {
      const queryParamValue = queryParams[item.name];
      if (queryParamValue !== undefined && queryParamValue !== null) {
        return queryParamValue;
      }
    } else {
      // Get the value from the form if withQueryParams is false.
      const formValue = getValues(item.name);
      if (formValue !== undefined && formValue !== null) {
        return formValue;
      }
    }

    if (item.name === 'timePeriod' && item.props?.defaultValue && item.props?.clearItemsOnUpdate && !isAnyItemInQueryParams(item.props.clearItemsOnUpdate)) {
      // A temporary solution to set default value in CreativeAffinityOverviewPage on load. Using setQueryParams in useEffect causes infinite loop.
      return item.props.defaultValue;
    }

    // If none of the above conditions are met, return clear value.
    if (componentTypeName === 'Select') {
      // Set to an empty string '' to reset Select value.
      // See https://mui.com/material-ui/api/select/.
      return '';
    } else if (componentTypeName === 'DatePicker') {
      // Set to null to reset DatePicker value.
      // See https://mui.com/x/api/date-pickers/date-picker/.
      return null;
    } else {
      return undefined;
    }
  }, [getValues, isAnyItemInQueryParams, queryParams, withQueryParams]);

  const getItemDefaultValue = useCallback((item: ItemsType): any => {
    const componentTypeName = item.component.displayName;
    if (!withQueryParams || componentTypeName === 'DatePicker' || componentTypeName === 'Select' || componentTypeName === 'TextField') {
      // If the component is a DatePicker we don't set default value.
      // If the componnet is a Select or TextField, its default value is handled in getItemValue.
      return undefined;
    }
    return queryParams[item.name];
  }, [queryParams, withQueryParams]);

  const renderChildren = useMemo(() => (
    items?.map((item: ItemsType, index: number) => {
      return (
        <Controller
          key={`${item.name}${index}`}
          name={item.name}
          control={control}
          render={({ field }): ReactElement => {
            return (
              <Grid item {...item.grid}>
                <item.component
                  {...item.props}
                  {...field}
                  onChange={
                    item.component.displayName === 'Autocomplete' || item.component.displayName === 'Select' || item.component.displayName === 'DatePicker'
                      ? (e: React.SyntheticEvent, value: any): void => {
                        handleItemChange(e, value, item.name, item);
                      }
                      : field.onChange
                  }
                  value={getItemValue(item)}
                  defaultValue={getItemDefaultValue(item)}
                  onClear={
                    item.props?.onClear
                      ? (_: unknown): void => {
                        handleClear(item.name, item.props?.onClear);
                      }
                      : undefined
                  }
                />
              </Grid>
            );
          }
          }
        />
      );
    })
  ), [items, control, getItemValue, getItemDefaultValue, handleItemChange, handleClear]);

  return (
    <form
      onSubmit={handleClickSubmit}
      onChange={handleChangeForm}
      style={{ width: '100%', ...style }}
    >
      <Grid container spacing={gridSpacing} rowSpacing={rowSpacing} justifyContent={justifyContent}>
        {renderChildren}
        {
          button !== false &&
          <Grid item>
            {
              button
                ? React.cloneElement(button, { type: 'submit' })
                : <input type="submit" />
            }
          </Grid>
        }
      </Grid>
    </form>
  );
};

export default memo(FormV2);
