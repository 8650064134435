import { baseApi, cleanArguments } from 'api/crudGraphQL';
import { Projection } from 'api/entityGraphQL/types';
import { NovaAIResponseType } from 'features/entitiesRedux/models/nova_ai';

export type GetNovaAIResponseQuery = {
  prompt_guid: string;
  projection?: Projection;
  version?: string;
};

export type GetNovaAIResponseResult = NovaAIResponseType;

export const GET_NOVA_AI_RESPONSE_ENDPOINT_KEY = 'getNovaAIResponse';

export const { useLazyGetNovaAIResponseQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_NOVA_AI_RESPONSE_ENDPOINT_KEY]: build.query<GetNovaAIResponseResult, GetNovaAIResponseQuery>({
      transformResponse: (response: { nova_ai_response: GetNovaAIResponseResult }) => response.nova_ai_response,
      query: ({
        projection = { response: true },
        ...args
      }) => ({
        body: {
          query: {
            nova_ai_response: {
              __args: cleanArguments(args),
              ...projection
            },
          }
        }
      })
    }),
  }),
});
