import React, { memo, useCallback, useMemo, useReducer } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import { Alert as AlertRibbon, Box, Container, Skeleton, Grid, TrashIcon, EditIcon, ButtonBar } from '@sprnova/nebula';
import { Action } from 'api/accessControl/Action';
import { useGetTasksQuery } from 'api/crudGraphQL/tasks/getTasks';
import { taskProjection } from 'api/crudGraphQL/tasks/taskProjection';
import { AccessControl } from 'components/AccessControl';
import { Alert } from 'components/Alert';
import { Spin } from 'components/Spin';
import { PageHero } from 'layouts/components';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import DeleteTaskModal from 'features/library/tasks/DeleteTaskModal/DeleteTaskModal';
import { Resource } from '../../../entitiesRedux';
import {DEFINE_SCROLL_MARGIN_TOP} from '../../../strategies/constants';
import NavigationBox from '../NavigationBox/NavigationBox';
import { ViewAdditionalStrategy } from '../ViewAdditionalStrategy';
import { ViewContractDetails } from '../ViewContractDetails';
import { ViewStrategyOverview } from '../ViewStrategyOverview';

const ViewPackageStrategyPage = (): JSX.Element => {
  const { id } = useParams<{ [x: string]: string }>();
  const history = useHistory();
  const [deleteTaskModalOpen, toggleDeleteTaskModalOpen] = useReducer((deleteTaskModalOpen) => !deleteTaskModalOpen, false);

  const { data: tasks, isFetching, isLoading } = useGetTasksQuery({
    id: Number(id),
    latest_version: false, // To be able to query old versions
    projection: taskProjection,
    pricing_version: PricingVersion.PACKAGE,
  });
  // we need to return the first task because the query returns an array of tasks and we don't have a singular query yet.
  const task = useMemo(() => {
    if (tasks) {
      return tasks[0];
    }
  }, [tasks]);

  /**
   * Check if the task is an old version.
   * Is an old version if the task has been updated and now has a next_revision value.
   */
  const taskIsAnOldVersion = useMemo(() => {
    return !!task?.next_revision;
  } , [task]);

  /**
   * Render the content of the page
   */
  const renderContent = useCallback((): JSX.Element => {
    if (!task && !isFetching) {
      return (
        <Alert type="error" message="Task could not be found" showIcon />
      );
    }
    if (isLoading) {
      return (
        <Skeleton height={120} />
      );
    }
    return (
      <>
        <Grid container spacing={2.4} columns={15}>
          <Grid item xs={15} lg={3}>
            <Box sx={{position: 'sticky', top: '96px'}}>
              <NavigationBox />
            </Box>
          </Grid>
          <Grid item xs={15} lg={12}>
            <Box id={'strategy-overview-navigation'} sx={DEFINE_SCROLL_MARGIN_TOP}>
              <ViewStrategyOverview task={task} />
            </Box>
            <Box id={'contract-details-navigation'} sx={DEFINE_SCROLL_MARGIN_TOP} >
              <ViewContractDetails task={task} />
            </Box>
            <Box id={'combined-strategies-navigation'} sx={DEFINE_SCROLL_MARGIN_TOP} >
              <ViewAdditionalStrategy task={task} />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }, [isFetching, isLoading, task]);

  const pageTitle = task?.name;

  const isFetchingSpin = useMemo(() => {
    if (isFetching) return <Spin loading={isFetching} />;
  }, [isFetching]);

  const content = useMemo(() => {
    return (
      <AccessControl
        action={[Action.read]}
        resource={Resource.libraryStrategy}
        warning='You are not authorized to view strategies.'
      >
        {renderContent()}
      </AccessControl>
    );
  }, [renderContent]);

  const handleClickEdit = useCallback(() => {
    history.push(`/library/package-strategies/${task?.id}/edit?${pricingVersionString}=${PricingVersion.PACKAGE}`);
  }, [history, task?.id]);

  const handleClickDelete = useCallback((): void => {
    toggleDeleteTaskModalOpen();
  }, []);

  const renderPageHeroDescription = useCallback(() => {
    return (
      <AlertRibbon severity="info">
        You are viewing an old version because updates have been made to this strategy in the library since you added it to the Blueprint, therefore you cannot edit/delete this strategy.
        If this strategy gets deleted from the Blueprint then you will no longer have access to this strategy.
      </AlertRibbon>
    );
  }, []);

  const renderPageHeroEndActionButtons = useCallback(() => {
    return (
      <ButtonBar
        primaryButtonProps={{
          variant: 'secondary',
          children: 'Delete',
          startIcon: <TrashIcon />,
          onClick: () => handleClickDelete(),
        }}
        secondaryButtonProps={{
          variant: 'primary',
          children: 'Edit',
          onClick: () => handleClickEdit(),
          startIcon: <EditIcon />
        }}
        size="large"
      />
    );
  }, [handleClickDelete, handleClickEdit]);

  const renderPageHeroEnd = useCallback((): JSX.Element => {
    if (!task && !isFetching) {
      return <></>;
    }
    if (isLoading) {
      return (
        <Skeleton height={50} width={350} />
      );
    }
    return taskIsAnOldVersion ? <></> : renderPageHeroEndActionButtons();
  }, [isFetching, isLoading, renderPageHeroEndActionButtons, task, taskIsAnOldVersion]);

  return (
    <>
      <Helmet>
        <title>
          Strategy Package
        </title>
      </Helmet>
      <PageHero
        title={pageTitle}
        end={renderPageHeroEnd()}
        description={taskIsAnOldVersion ? renderPageHeroDescription() : <></>}
      />
      {isFetchingSpin}
      <Container hasVerticalPadding>
        {content}
      </Container>
      <DeleteTaskModal
        open={deleteTaskModalOpen}
        pathToRedirectOnDelete={`/library/tasks?${pricingVersionString}=${PricingVersion.PACKAGE}`}
        task={task}
        toggleModal={toggleDeleteTaskModalOpen}
      />
    </>
  );
};

/**
 * View component of a library strategy package
 */
export default memo(ViewPackageStrategyPage);
