/**
 * Departments -> List
 */

import React, { FC, memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Action } from 'api/accessControl';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { Resource } from 'features/entitiesRedux';
import { Department } from 'features/entitiesRedux/models/department';
import { useAccessControl } from 'features/global/hooks/useAccessControl';
import { useAccount } from 'features/global/hooks/useAccount';
import DeleteButton from 'features/library/components/DeleteButton';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import { Button, Table } from 'components';
import css from './List.module.scss';

type Props = {
  departments: Department[];
  loading: boolean;
  onDelete: (department: Pick<Department, 'id'>) => void;
};

const DepartmentsList: FC<Props> = ({
  departments,
  loading,
  onDelete
}) => {
  const { account } = useAccount();
  const { can } = useAccessControl();
  const canDelete = can(Action.delete, Resource.libraryDepartment, account?.id);
  const canEdit = can(Action.update, Resource.libraryDepartment, account?.id);

  const [pricingVersionQueryParam] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );
  const renderActions = (value: string, department: Department) => (
    <div className={css.actions}>
      <DeleteButton
        model={department}
        onDelete={onDelete}
        type='department'
        disabled={!canDelete}
      />
      <Link to={`/library/departments/${department.id}/edit?pricingVersion=${pricingVersionQueryParam}`}>
        <Button type="text" icon={<EditOutlined />} disabled={!canEdit} />
      </Link>
    </div>
  );

  const renderName = (name: string, row: Department) => <Link to={`/library/services?department_id=${row.id}&pricingVersion=${pricingVersionQueryParam}`}>{name}</Link>;

  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: renderName,
      sorter: (a: any, b: any) => (`${a.name}`).localeCompare(b.name),
    },
    {
      title: '',
      key: 'actions',
      dataIndex: 'actions',
      width: 130,
      render: renderActions
    },
  ];

  return (
    <Table
      loading={loading}
      rowKey="id"
      columns={columns}
      dataSource={departments}
      pagination={false}
    />
  );
};

export default memo(DepartmentsList);
