import React, { FC, memo } from 'react';
import { Button, Dialog, Grid, Typography } from '@sprnova/nebula';
import NovaGPTDialogHeader from './components/NovaGPTDialogHeader';
import css from './NovaGPTDialog.module.scss';

type NovaGPTDialogProps = {
  open: boolean;
  toggle: () => void;
}

const NovaGPTDialog: FC<NovaGPTDialogProps> = ({ open, toggle }) => {
  return (
    <Dialog
      open={open}
      onClose={toggle}
      title={<NovaGPTDialogHeader onToggle={toggle} />}
      size="xl"
    >
      <Grid container>
        <Grid item xs={12} sm={6}>
          <div className={css.left}>
            <img src="/png/nova-gpt/ask-nova-gpt-dialog.png" alt="Ask novaGPT" />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={css.right}>
            <div className={css.inner}>
              <Typography variant="h3">Empower your data with AI ✨</Typography>
              <Typography variant="body1">
                nova Insights AI combines market leading analytics and report with an industry specific GPT to instantly make your data AI ready and provide real time discovery into performance by simply asking a question.
              </Typography>
              <Button variant="primary" size="large">Book a Demo</Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default memo(NovaGPTDialog);
