/**
* Get me
*/

import { baseApi } from 'api/crudGraphQL';
import { Projection } from 'api/entityGraphQL';
import { User } from 'features/entitiesRedux';
import tagTypes from './tagTypes';

export type GetMeQuery = {
  projection: Projection;
};

export type GetMeResult = User;

export const { useGetMeQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getMe: build.query<GetMeResult, GetMeQuery>({
      transformResponse: (response: { me: GetMeResult }) => response.me,
      providesTags: (me) => {
        return me ? [
          { id: me.id, type: tagTypes.ME },
        ] : [tagTypes.ME];
      },
      query: ({
        projection = { id: true, name: true },
      }) => ({
        body: {
          query: {
            me: {
              ...projection,
            }
          }
        }
      })
    })
  })
});
