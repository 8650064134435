import React, { forwardRef, useCallback, useState, useEffect, useMemo, ReactNode } from 'react';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { Projection } from 'api/entityGraphQL';
import { Select } from 'components/Select';
import { Skeleton } from 'components/Skeleton';
import { Spin } from 'components/Spin';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import pluralize from 'pluralize';
import { Props as SelectProps } from '../Select/Select';
import css from './LazySelect.module.scss';

type ValueType = Record<string, any>;

type Props = SelectProps & {
  disableSkeleton?: boolean;
  query: UseQuery<QueryDefinition<any, any, string, any, string>>;
  projection?: Projection;
  searchKey?: string;
  values?: ValueType[];
  scrollOffset?: number;
  debounceTime?: number;
  additionalFilters?: ValueType;
  sort?: string[];
  limit?: number;
}

const LazySelect = forwardRef(({
  disableSkeleton = false,
  className,
  values,
  query,
  projection = {
    id: true,
    name: true,
  },
  searchKey = 'name',
  scrollOffset = 80,
  debounceTime = 500,
  additionalFilters,
  sort,
  limit = 20,
  ...props
}: Props, ref: any) => {
  const [page, setPage] = useState<number>(1);
  const [searchPage, setSearchPage] = useState<number>(1);
  const [filter, setFilter] = useState<ValueType>({ page, limit, sort, ...additionalFilters });
  const [propsFilter, setPropsFilter] = useState<ValueType>({});
  const [initialPropsValues, setInitialPropsValues] = useState<ValueType[]>([]);
  const [combinedPaginatedValues, setCombinedPaginatedValues] = useState<ValueType[]>([]);
  const [combinedSearchPaginatedValues, setCombinedSearchPaginatedValues] = useState<ValueType[]>([]);
  const [searching, setSearching] = useState<boolean>(false);

  const handleSearch = useCallback((filterValue?: string) => {
    if (!filterValue) {
      setFilter({ page: 1, limit, sort, ...additionalFilters });
    } else {
      setSearching(true);
      setFilter({ [searchKey]: filterValue, page: 1, limit, sort, ...additionalFilters });
    }
  }, []);
  /**
   * Fetch
   */
  const { data, isLoading, isFetching } = query({ ...filter, projection });
  /**
   * For fetching default values
   */
  const { data: propsData, isLoading: propsLoading } = query({ ...propsFilter, projection });

  const fetchedData = useMemo(() => {
    setSearching(false);
    if (values) return values;
    // filter by specified key; cleared on blur
    if (data) {
      if (filter[searchKey]) {
        if (
          combinedSearchPaginatedValues?.length &&
          searchPage === 1 &&
          isEqual(combinedSearchPaginatedValues, data.data)
        ) {
          return combinedSearchPaginatedValues;
        } else if (searchPage > 1) {
          return combinedSearchPaginatedValues;
        } else {
          setCombinedSearchPaginatedValues(data.data);
          return data.data;
        }
      }
      if (combinedPaginatedValues.length) {
        return combinedPaginatedValues;
      }
      setCombinedPaginatedValues(data.data);
      return data.data;
    }
    return [];
  }, [values, data, combinedPaginatedValues, combinedSearchPaginatedValues]);

  const handleOnScroll = (e: any) => {
    const { offsetHeight, scrollTop, scrollHeight } = e.target || {};
    const shouldFetch = !isLoading && offsetHeight + scrollTop >= scrollHeight - scrollOffset && data.last_page > page || !data.has_more_pages;
    if (isFetching) return;
    if (shouldFetch) {
      if (filter[searchKey]) {
        setSearchPage(searchPage + 1);
        setFilter({ ...filter, page: searchPage + 1, sort, ...additionalFilters  });
      } else {
        setPage(page + 1);
        setFilter({ ...filter, page: page + 1, sort, ...additionalFilters  });
      }
    }
  };

  /**
   * On search
   */
  const handleValuesChange = debounce( (str?: string) => {
    if (values) {
      /**
       * If values are passed in as a prop, no need to query;
       * filtering is done with filteringOption prop
       */
      return;
    } else {
      handleSearch(str);
    }
  }, debounceTime);

  useEffect(() => {
    if (data) {
      if (filter[searchKey]) {
        setCombinedSearchPaginatedValues(uniqBy([...combinedSearchPaginatedValues, ...data.data], 'id'));
      } else {
        setCombinedPaginatedValues(uniqBy([...combinedPaginatedValues, ...data.data], 'id'));
      }
    }
  }, [data?.current_page]);

  useEffect(() => {
    /**
     * This is used to prevent ID flashing
     */
    if (propsData && (propsFilter?.id || propsFilter?.ids)) {
      setInitialPropsValues(propsData.data);
    }
  }, [propsData]);

  useEffect(() => {
    /**
     * Sets initial values (added to the top of Select dropdown)
     */
    if (props.value) {
      if (props.value.length) {
        setPropsFilter({ ...propsFilter, ids: props.value });
      } else if (typeof props.value === 'number') {
        setPropsFilter({ ...propsFilter, id: props.value });
      }
    }
  }, [props.value]);

  const renderDropdown = (menu: ReactNode) => {
    if (!isLoading && !values && !fetchedData.length) {
      return <div className={css.nothingFound}>No {pluralize(searchKey)} found</div>;
    }
    if (values) return <>{menu}</>;
    return <div className={css.root}>
      {
        searching && (isLoading || isFetching) ?
          <div className={css.skeleton}>
            <Skeleton active />
          </div> :
          <div>{menu}</div>
      }
    </div>;
  };

  return (
    <Select
      showSearch
      className={className}
      onPopupScroll={handleOnScroll}
      onSearch={(str: string) => {
        setCombinedSearchPaginatedValues([]);
        handleValuesChange(str);
      }}
      filterOption={values ? (input, option) =>  typeof option?.children === 'string' ? option.children.toLowerCase().includes(input.toLowerCase()) : false : false}
      skeleton={!disableSkeleton && (isLoading || propsLoading || props?.skeleton)}
      loading={searching || isLoading || isFetching}
      onBlur={() => {
        setSearchPage(1);
        setCombinedSearchPaginatedValues([]);
        handleValuesChange();
      }}
      dropdownRender={renderDropdown}
      ref={ref}
      {...props}
    >
      {
        (uniqBy([...initialPropsValues, ...fetchedData], 'id') as (Partial<ValueType>[]))
          ?.filter((value) => value?.id !== undefined)
          ?.map((value: { [key: string]: any }, i: number) => (<Select.Option key={`${value.id}-${i}`} value={value.id} label={value.name}>{value.name}</Select.Option>)
          )
      }
      {
        !values ?
          <div style={{ display: isFetching ? 'initial' : 'none' }}><Spin size='small' /></div> :
          <></>
      }
    </Select>
  );
});

export default LazySelect;
