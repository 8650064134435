/**
 * Utility to format Horizon uncertainty range areas for the <LineChart> component
 * @param {string} field - The chart key or dataKey you are generating the range area for
 * @return {array}
 */
import { AreaType } from 'features/intelligence/horizon/components/LineChart/types';


const getHorizonUncertaintyRangeAreas = (field: string): AreaType[] => {
  return [
    {
      key: `${field}_10_percent_above`,
      dataKey: `${field}_10_percent_above`,
      stroke: '#a6d7a8',
      fill: '#a6d7a8',
      fillOpacity: undefined,
      name: '+/- 10% model uncertainty',
    },
    {
      key: `${field}_10_percent_below`,
      dataKey: `${field}_10_percent_below`,
      stroke: '#a6d7a8',
      fill: '#ffffff',
      fillOpacity: '1',
      name: undefined,
    },
    {
      key: `${field}_20_percent_above`,
      dataKey: `${field}_20_percent_above`,
      stroke: '#dbefdc',
      fill: '#dbefdc',
      fillOpacity: undefined,
      name: '+/- 20% model uncertainty',
    },
    {
      key: `${field}_20_percent_below`,
      dataKey: `${field}_20_percent_below`,
      stroke: '#dbefdc',
      fill: '#ffffff',
      fillOpacity: '1',
      name: undefined,
    }
  ];
};

export default getHorizonUncertaintyRangeAreas;
