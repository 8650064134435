import { Projection } from 'api/entityGraphQL/types';
import { NovaAITierType } from 'features/entitiesRedux/models/nova_ai';
import tagTypes from './tagTypes';
import { baseApi, cleanArguments } from '..';

export type GetNovaAITierQuery = {
  client_id: number;
  projection?: Projection;
};

export type GetNovaAITierResult = NovaAITierType;

export const GET_NOVA_AI_TIER_ENDPOINT_KEY = 'getNovaAITier';

export const { useGetNovaAITierQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_NOVA_AI_TIER_ENDPOINT_KEY]: build.query<GetNovaAITierResult, GetNovaAITierQuery>({
      transformResponse: (response: { nova_ai_tier: GetNovaAITierResult }) => response.nova_ai_tier,
      providesTags: tier => [
        tier?.id ? { id: tier.id, type: tagTypes.AI_TIER } : tagTypes.AI_TIER,
        tagTypes.AI_TIERS
      ],
      query: ({
        projection = {
          id: true,
          name: true,
          slug: true,
        },
        ...values
      }) => {
        const __args: Partial<GetNovaAITierQuery> = values;
        return {
          body: {
            query: {
              nova_ai_tier: {
                __args: cleanArguments(__args),
                ...projection,
              },
            }
          }
        };
      }
    }),
  }),
});
