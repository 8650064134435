import { isEqual } from 'lodash';

/**
 * Checks if a value exists
 * Returns true if the value is not undefined, not null, not empty string, not empty array, not empty object
 *
 * @param value {any | undefined} - value to check
 * @returns boolean - true if value exists, false if not
 */
export const valueExists = (value: any | undefined): boolean => {
  return value !== undefined && value !== '' && value !== null && !isEqual(value, []) && !isEqual(value, {});
};
