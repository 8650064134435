import loadable from "@loadable/component";

const ListItem = loadable(
  () => import("./ListItem")
  /* , {
  fallback: <div>Loading...</div>,
  } */
);

const ListItemMeta = loadable(
  () => import("./ListItemMeta")
  /* , {
  fallback: <div>Loading...</div>,
  } */
);

export { ListItem, ListItemMeta };
