import React, { memo, useReducer } from 'react';
import { useHistory } from 'react-router';
import { DropdownIconButton, EllipsesIcon, ActionMenu, EditIcon, TrashIcon } from '@sprnova/nebula';
import DeleteScoreboardModal from './DeleteScoreboardModal';

type DropdownActionMenuProps = {
  scoreboardId: number;
  scoreboardName: string;
  clientId: number;
  clientName: string;
};

const DropdownActionMenu = ({ scoreboardId, scoreboardName, clientId, clientName }: DropdownActionMenuProps): JSX.Element => {
  const [openDeleteModal, toggleDeleteModal] = useReducer((state) => !state, false);
  const history = useHistory();
  const handleClick = (path: string): void => history.push(path);

  if (!scoreboardId) return <></>;
  return (
    <>
      <DropdownIconButton
        icon={<EllipsesIcon />}
        placement="bottom-start"
        size="md"
      >
        <ActionMenu>
          <ActionMenu.Item
            onClick={(): void => handleClick(`/scoreboards/${scoreboardId}/edit`)}
            startIcon={<EditIcon />}
          >
            Edit Scoreboard
          </ActionMenu.Item>
          <ActionMenu.Item
            onClick={toggleDeleteModal}
            startIcon={<TrashIcon />}
          >
            Delete Scoreboard
          </ActionMenu.Item>
        </ActionMenu>
      </DropdownIconButton>
      <DeleteScoreboardModal
        scoreboardId={scoreboardId}
        scoreboardName={scoreboardName}
        clientId={clientId}
        clientName={clientName}
        open={openDeleteModal}
        toggle={toggleDeleteModal}
      />
    </>
  );
};

export default memo(DropdownActionMenu);
