import React, { memo, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Skeleton } from '@mui/material';
import { Grid, Typography, IconButton, EditIcon, Drawer, DrawerFooter, Box, DrawerView, useForm, Select, Radio, theme,  Legend as NebulaLegend } from '@sprnova/nebula';
import { Indicator } from 'api/crudGraphQL/scoreboards/types';
import { useFilterContext } from 'features/scoreboards/utils/Filters';
import DateRange from './DateRange';
import { formatRangeLabel, formatTitle } from '../../components/utils';

enum Range {
  LastWeek = 'last_week',
  LastMonth = 'last_month',
  Last7Days = 'last_7_days',
  Last30Days = 'last_30_days',
  Last90Days = 'last_90_days',
  MonthToDate = 'month_to_date',
  Last365Days = 'last_365_days',
  Custom = 'custom',
}
const indentedBorderStyles = {
  display: 'flex',
  alignItems: 'center',
  borderLeft: '1px solid #6D6D6D',
  padding: '16px 0 16px 32px',
  marginLeft: '20px'
};

const ComparisonTimeframeColor = theme.variables?.colors.data_visualization.sequential[90];
const PreviousPeriodColor = theme.variables?.colors.data_visualization.sequential[50];

export const defaultKPIValues = {
  range: Range.Last30Days,
  compared: 'previous_period',
  type: 'day',
  from: undefined,
  to: undefined,
};
const ComparisonTimeframe = ({ data = [], loading }: { data: Indicator[]; loading: boolean }): JSX.Element => {
  const [open, toggle] = useReducer((state) => !state, false);
  const [range, setRange] = useState<Range>(defaultKPIValues.range);
  const [compareTimePeriod, setCompareTimePeriod] = useState<string>('yes');
  const { filter, setFilter } = useFilterContext();

  const { register, handleSubmit, getValues, setValue } = useForm({
    defaultValues: defaultKPIValues
  });

  useEffect(() => {
    // Set default values on load
    setFilter?.({ type: 'add', value: { ...getValues() } });
    setCompareTimePeriod(getValues().compared ? 'yes' : 'no');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = useCallback((e) => {
    e.preventDefault();
    handleSubmit(handleFormSubmit);
    setFilter?.({ type: 'add', value: getValues() });
    toggle();
  }, [getValues, handleSubmit, setFilter]);

  const renderTopLegend = useMemo(() => {
    return (
      <Grid container alignItems='center' justifyContent='center'>
        <Typography variant='h4' sx={{ marginRight: '20px' }}>Comparison Timeframe:</Typography>
        <NebulaLegend
          variant="line"
          color={ComparisonTimeframeColor}
          label={formatRangeLabel(filter?.range, filter?.from, filter?.to)}
        />
        {
          filter?.compared && (
            <>
              <Typography
                variant='caption'
                sx={{
                  marginRight: '10px',
                  fontStyle: 'italic',
                  color: theme.variables?.colors.disabled[400]
                }}
              >
                compared to
              </Typography>
              <NebulaLegend
                variant="line"
                color={PreviousPeriodColor}
                label={formatTitle(filter?.compared)}
              />
            </>
          )
        }
        <IconButton onClick={toggle} size='sm'>
          <EditIcon />
        </IconButton>
      </Grid>
    );
  }, [filter?.compared, filter?.from, filter?.range, filter?.to]);

  const renderRange = useMemo(() => {
    return (
      <>
        <Grid item xs={12}>
          <Select
            {...register('range')}
            id='ComparisonTimeframe-range'
            label='Range'
            defaultValue={filter?.range || defaultKPIValues.range}
            value={range}
            onChange={(e): void => setRange(e.target.value as Range)}
          >
            <Select.Item value={Range.LastWeek}>Last week</Select.Item>
            <Select.Item value={Range.LastMonth}>Last month</Select.Item>
            <Select.Item value={Range.Last7Days}>Last 7 days</Select.Item>
            <Select.Item value={Range.Last30Days}>Last 30 days</Select.Item>
            <Select.Item value={Range.Last90Days}>Last 90 days</Select.Item>
            <Select.Item value={Range.MonthToDate}>Month to date</Select.Item>
            <Select.Item value={Range.Last365Days}>Last 365 days</Select.Item>
            <Select.Item value={Range.Custom}>Custom date range</Select.Item>
          </Select>
        </Grid>
        {
          range === 'custom' && (
            <Grid item xs={12}>
              <DateRange setValue={setValue} />
            </Grid>
          )
        }
      </>
    );
  }, [filter?.range, range, register, setValue]);

  const renderCompareTimePeriod = useMemo(() => {
    return (
      <>
        <Grid item xs={12}>
          <Radio.Group
            label='Compare to another time period'
            id='ComparisonTimeframe-compare-time-period'
            defaultValue={filter.compared ? 'yes' : 'no'}
            value={compareTimePeriod}
            onChange={(e): void => {
              if (e.target.value === 'no') {
                setValue('compared', '');
              } else {
                setValue('compared', defaultKPIValues.compared);
              }
              setCompareTimePeriod(e.target.value);
            }}
          >
            <Radio label='Yes' value='yes' />
            <Radio label='No' value='no' />
          </Radio.Group>
        </Grid>
        {
          compareTimePeriod === 'yes' && (
            <Grid
              item
              xs={12}
              sx={indentedBorderStyles}
            >
              <Select
                {...register('compared')}
                id='ComparisonTimeframe-compare-to'
                label='Compare to:'
                defaultValue={defaultKPIValues.compared}
              >
                <Select.Item value='previous_period'>Previous period</Select.Item>
                <Select.Item value='previous_year'>Previous year</Select.Item>
              </Select>
            </Grid>
          )
        }
      </>
    );
  }, [compareTimePeriod, filter.compared, register, setValue]);

  const handleCancel = useCallback(() => {
    setValue('range', filter?.range);
    setRange(filter?.range);
    setValue('compared', filter?.compared);
    setCompareTimePeriod(filter?.compared ? 'yes' : 'no');
    setValue('from', filter?.from);
    setValue('to', filter?.to);
    toggle();
  }, [filter?.compared, filter?.from, filter?.range, filter?.to, setValue, toggle]);

  const renderDrawer = useMemo(() => {
    if (loading) {
      return (
        <Grid container alignItems='center' justifyContent='center'>
          <Skeleton variant='rectangular' height={24} width='50%' />
        </Grid>
      );
    }
    return <>
      { (data && data?.length > 0) && renderTopLegend}
      <Drawer
        open={open}
        onClose={toggle}
        variant='persistent'
      >
        <DrawerView
          title='Edit Comparison Timeline'
          onClose={toggle}
          onSubmit={handleFormSubmit}
          component='form'
          footer={
            <DrawerFooter
              primaryButtonProps={{ children: 'Save', type: 'submit' }}
              secondaryButtonProps={{ children: 'Cancel', onClick: handleCancel }}
            />
          }
        >
          <Grid container spacing={2}>
            {renderRange}
            {renderCompareTimePeriod}
            <Grid item xs={12}>
              <Select
                {...register('type')}
                id='ComparisonTimeframe-group-by'
                label='Group by:'
                defaultValue={filter?.type || defaultKPIValues.type}
              >
                <Select.Item value='day'>Day</Select.Item>
                <Select.Item value='month'>Month</Select.Item>
                <Select.Item value='year'>Year</Select.Item>
              </Select>
            </Grid>
          </Grid>
        </DrawerView>
      </Drawer>
    </>;
  }, [loading, data, renderTopLegend, open, handleFormSubmit, handleCancel, renderRange, renderCompareTimePeriod, register, filter?.type]);

  return (
    <Box id='comparison-timeframe'>
      {renderDrawer}
    </Box>
  );
};

export default memo(ComparisonTimeframe);
