/**
 * Entities -> Tasks -> Selectors
 */

import { EntityId } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { selectEntitiesDenormalized } from '../_entity/selectors';
import { tasksAdapter } from './adapter';

export const taskSelectors = tasksAdapter.getSelectors((state: RootState) => state.entities.data.tasks);

export const selectTasksByIds = (taskIds: EntityId[]) => (state: RootState) => {
  const input = { tasks: taskIds };
  return selectEntitiesDenormalized(state, input).tasks;
};

export const selectAllTasks = (state: RootState) => {
  const taskIds = taskSelectors.selectIds(state);
  return selectTasksByIds(taskIds)(state);
};

export const selectTaskById = (taskId: number) => (state: RootState) => {
  return selectTasksByIds([taskId])(state)[0];
};
