/**
 * Components -> Table
 */

import React from 'react';
import { Table as AntTable } from 'antd';
import { TableProps } from 'antd/lib/table';
import classnames from 'classnames';
import css from './Table.module.scss';

type ExtraTableProps  = {
  transparentHeader?: boolean;
}

const Table = <RecordType extends object = any>({ className, transparentHeader, ...rest }: TableProps<RecordType> & ExtraTableProps) => (
  <AntTable
    {...rest}
    className={classnames(css.root, {[css.transparentHeader]: transparentHeader}, className)}
  />
);

export default Object.assign(Table, {
  Column: AntTable.Column,
  ColumnGroup: AntTable.ColumnGroup,
  Summary: AntTable.Summary,
});
