/**
 * Projections for CollapsibleData and AdPreviewModalCard
 */

export const adProjection = {
  id: true,
  name: true,
  formats: true,
  funnel: true,
  type: true,
  adset: {
    name: true,
    funnel: true,
    campaign: {
      name: true,
      funnel: true,
      last_refreshed: true,
      client: {
        id: true,
        name: true,
        logo: true,
        business_type: {
          name: true,
        },
        industry: {
          name: true
        },
        playbook_platform_match_rates: {
          platform: {
            id: true,
            name: true,
            slug: true,
          },
          match_rate: true,
        },
      }
    },
  },
  platform: {
    id: true,
    name: true,
    slug: true,
    image_url: true,
  },
  images: {
    id: true,
    url: true,
    thumbnail: true,
    type: true,
    domain_url: true,
  },
  videos: {
    id: true,
    url: true,
    thumbnail: true,
    type: true,
    domain_url: true,
  },
  carousels: {
    id: true,
    type: true,
    identifier: true,
    assets: {
      id: true,
      parent: true,
      url: true,
      thumbnail: true,
      type: true,
      domain_url: true,
    },
  },
  first_asset: {
    domain_url: true,
    url: true,
    thumbnail: true,
  },
  title: true,
  status: true,
  body: true,
  spend: true,
  roas: true,
  revenue: true,
  // results: true,
  stop_rate: true,
  hold_rate: true,
  retention_rate: true,
  score: true,
  ctr: true,
  cpc: true,
  cpm: true,
  cpa: true,
  cvr: true,
  clicks: true,
  impressions: true,
  conversions: true,
  slug: true,
  updated_at: true,
  created_at: true,
  ecomm_matched_metric: {
    id: true,
    ltv: true,
    cac: true,
  },
  last_refreshed: true,
};

export const adsetProjection = {
  id: true,
  funnel: true,
  ads: {
    id: true,
    name: true,
    formats: true,
    funnel: true,
    spend: true,
    roas: true,
    revenue: true,
    stop_rate: true,
    retention_rate: true,
    hold_rate: true,
    ctr: true,
    cpc: true,
    cpm: true,
    cpa: true,
    cvr: true,
    clicks: true,
    impressions: true,
    conversions: true,
    score: true,
    last_refreshed: true,
    first_asset: {
      domain_url: true,
      url: true,
      thumbnail: true,
    },
    platform: {
      id: true,
      name: true
    },
    images: {
      id: true,
      url: true,
      thumbnail: true,
      type: true,
      domain_url: true,
    },
    videos: {
      id: true,
      url: true,
      thumbnail: true,
      type: true,
      domain_url: true,
    },
    carousels: {
      id: true,
      type: true,
      identifier: true,
      assets: {
        id: true,
        parent: true,
        url: true,
        thumbnail: true,
        type: true,
        domain_url: true,
      },
    },
    ecomm_matched_metric: {
      id: true,
      ltv: true,
      cac: true,
    }
  },
};

export const campaignProjection = {
  id: true,
  funnel: true,
  adsets: {
    id: true,
    name: true,
    funnel: true,
    ads: {
      id: true,
      name: true,
      formats: true,
      funnel: true,
      spend: true,
      roas: true,
      revenue: true,
      stop_rate: true,
      retention_rate: true,
      hold_rate: true,
      score: true,
      ctr: true,
      cpc: true,
      cpm: true,
      cpa: true,
      cvr: true,
      clicks: true,
      impressions: true,
      conversions: true,
      last_refreshed: true,
      first_asset: {
        domain_url: true,
        url: true,
        thumbnail: true,
      },
      platform: {
        id: true,
        name: true
      },
      ecomm_matched_metric: {
        id: true,
        ltv: true,
        cac: true,
      }
    },
    first_asset:{
      domain_url: true,
      url: true,
      thumbnail: true,
    },
    score: true,
    ecomm_matched_metric: {
      id: true,
      ltv: true,
      cac: true,
    }
  },
};

export const adsComparisonProjection = {
  ...adProjection,
  adset: {
    id: true,
    name: true,
    last_refreshed: true,
    campaign: {
      id: true,
      name: true,
      last_refreshed: true,
      client: {
        id: true,
        name: true,
        logo: true,
        industry: {
          id: true,
          name: true
        },
        business_type: {
          id: true,
          name: true
        },
        playbook_platform_match_rates: {
          platform: {
            id: true,
            name: true,
            slug: true,
          },
          match_rate: true,
        },
      },
      objective: true,
    }
  },
};

export const adsetComparisonProjection = {
  ...adsetProjection,
  name: true,
  platform: {
    id: true,
    name: true,
    slug: true,
  },
  ads: {
    ...adsetProjection.ads,
    adset: {
      name: true,
      id: true,
      last_refreshed: true,
      campaign: {
        name: true,
        id: true,
        last_refreshed: true,
        client: {
          id: true,
          name: true,
          logo: true,
          business_type: {
            id: true,
            name: true,
          },
          industry: {
            id: true,
            name: true
          },
          playbook_platform_match_rates: {
            platform: {
              id: true,
              name: true,
              slug: true,
            },
            match_rate: true,
          },
        }
      }
    },
  },
  campaign: {
    id: true,
    name: true,
    last_refreshed: true,
    client: {
      id: true,
      name: true,
      logo: true,
      industry: {
        id: true,
        name: true
      },
      business_type: {
        id: true,
        name: true
      },
      playbook_platform_match_rates: {
        platform: {
          id: true,
          name: true,
          slug: true,
        },
        match_rate: true,
      },
    },
    objective: true,
  },
  first_asset:{
    domain_url: true,
    url: true,
    thumbnail: true,
  },
  status: true,
  spend: true,
  roas: true,
  stop_rate: true,
  retention_rate: true,
  hold_rate: true,
  score: true,
  ctr: true,
  cpc: true,
  cpm: true,
  cpa: true,
  cvr: true,
  clicks: true,
  impressions: true,
  conversions: true,
  slug: true,
  updated_at: true,
  ecomm_matched_metric: {
    id: true,
    ltv: true,
    cac: true,
  },
  last_refreshed: true,
};

export const campaignComparisonProjection = {
  ...campaignProjection,
  name: true,
  platform: {
    id: true,
    name: true,
    slug: true,
  },
  adsets: {
    ...campaignProjection.adsets,
    ads: {
      ...campaignProjection.adsets.ads,
      adset: {
        name: true,
        id: true,
        last_refreshed: true,
        campaign: {
          name: true,
          id: true,
          last_refreshed: true,
          client: {
            id: true,
            name: true,
            logo: true,
            business_type: {
              id: true,
              name: true,
            },
            industry: {
              id: true,
              name: true
            },
          }
        },
      },
    },
    first_asset:{
      domain_url: true,
      url: true,
      thumbnail: true,
    },
    score: true,
    ecomm_matched_metric: {
      id: true,
      ltv: true,
      cac: true,
    }
  },
  client: {
    id: true,
    name: true,
    logo: true,
    industry: {
      id: true,
      name: true
    },
    business_type: {
      id: true,
      name: true
    },
    playbook_platform_match_rates: {
      platform: {
        id: true,
        name: true,
        slug: true,
      },
      match_rate: true,
    },
  },
  first_asset:{
    domain_url: true,
    url: true,
    thumbnail: true,
  },
  objective: true,
  status: true,
  spend: true,
  roas: true,
  stop_rate: true,
  retention_rate: true,
  hold_rate: true,
  score: true,
  ctr: true,
  cpc: true,
  cpm: true,
  cpa: true,
  cvr: true,
  clicks: true,
  impressions: true,
  conversions: true,
  slug: true,
  updated_at: true,
  created_at: true,
  last_refreshed: true,
  ecomm_matched_metric: {
    id: true,
    ltv: true,
    cac: true,
  }
};
