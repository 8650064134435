/**
 * SelectDepartment
 */

import React, { forwardRef } from 'react';
import { useDepartments } from 'utils/hooks';
import { Department } from 'features/entitiesRedux';
import Select, { Props as SelectProps } from '../Select/Select';

interface Props extends SelectProps {
  excludedDepartmentNames?: string[];
}

// eslint-disable-next-line react/display-name
const SelectDepartment = forwardRef(({ skeleton: skeletonProp, excludedDepartmentNames, ...props }: Props, ref: any) => {
  const { departments: allDepartments, loading } = useDepartments();
  const departments = excludedDepartmentNames?.length ? allDepartments.filter(value => {
    return !excludedDepartmentNames?.includes(value?.name);
  }) : allDepartments;
  const skeleton = loading && !departments.length;

  return (
    <Select
      showSearch
      optionFilterProp="children"
      skeleton={skeleton || skeletonProp}
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      {...props}
    >
      {departments.map((department: Partial<Department>) => (
        <Select.Option key={department.id} value={department.id || ''}>
          {department.name}
        </Select.Option>
      ))}
    </Select>
  );
});

export default SelectDepartment;
