/**
 * SectionBuilder -> Fields -> PitchLink
 */

import React, { FC } from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import { Button, FormItem } from 'components';
import { FieldProps } from '../../types';
import css from './PitchLink.module.scss';

const PitchLink: FC<FieldProps> = ({ name }) => (
  <FormItem name={name} className={css.root}>
    <a
      href="https://docs.google.com/document/d/1rIhtE3TQQQDHupesMuOONqoB8vjprBIF1a3Hz7jey5A/edit?ts=607f402e"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button type="default" icon={<FileTextOutlined />} header menuItem>
        View Power Pitch
      </Button>
    </a>
  </FormItem>
);

export default PitchLink;