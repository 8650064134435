/**
 * Get scoreboards (reports) public
 */
import { Pagination, Projection } from 'api/entityGraphQL/types';
import { paginationProjection } from '../..';
import { cleanArguments } from "api/crudGraphQL/utils";
import { basePublicApi } from "../basePublicApi";
import { StatusArgs, IndicatorArgs } from '../../scoreboards/getScoreboard';
import tagTypes from './tagTypes';
import { Scoreboard } from '../../scoreboards/types';

export type GetScoreboardsPublicQuery = {
  limit?: number;
  page?: number;
  sort?: string[];
  id?: number;
  name?: string;
  business_type_id?: number;
  account_manager_id?: number;
  executive_sponsor_id?: number;
  goal_set?: string;
  my_accounts?: number;
  statusArgs?: StatusArgs;
  indicatorArgs?: IndicatorArgs;
  projection?: Projection;
};

export type GetScoreboardsPublicResult = Pagination & {
  data: Scoreboard[]
};

export const GET_SCOREBOARDS_PUBLIC_ENDPOINT_KEY = 'getScoreboardsPublic';

export const { useGetScoreboardsPublicQuery } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_SCOREBOARDS_PUBLIC_ENDPOINT_KEY]: build.query<GetScoreboardsPublicResult, GetScoreboardsPublicQuery>({
      transformResponse: (response: { reports: GetScoreboardsPublicResult }) => response.reports,
      providesTags: (result) => {
        const { data } = result ?? { data: [] };

        return [
          tagTypes.SCOREBOARDS,
          ...data.map((scoreboard) => ({
            id: scoreboard.id,
            type: tagTypes.SCOREBOARD
          }))
        ];
      },
      query: ({
        projection = { id: true },
        page = 1,
        limit = 10,
        name,
        statusArgs,
        indicatorArgs,
        ...args
      }) => {
        const __args: Partial<GetScoreboardsPublicQuery> = {
          page,
          limit,
          ...args
        };
        if (name) {
          __args.name = `*${name}*`;
        }

        if (statusArgs) {
          /**
           * Add status args to projection
           */
          projection = {
            ...projection,
            statuses: {
              __args: cleanArguments(statusArgs),
              id: true,
              ...projection.statuses,
            }
          };
        }

        if (indicatorArgs) {
          /**
           * Add indicator args to projection
           */
          projection = {
            ...projection,
            indicators: {
              __args: cleanArguments(indicatorArgs),
              id: true,
              ...projection.indicators,
            }
          };
        }

        return {
          body: {
            query: {
              reports: {
                __args: cleanArguments(__args),
                data: projection,
                ...paginationProjection
              }
            }
          }
        }
      }
    })
  })
});
