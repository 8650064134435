import React from 'react';
import moment from 'moment';
import { Skeleton } from '../../../../components';
import { Forecast } from '../../../entitiesRedux';

const formatForecastTitle = ({
  forecast,
  isLoading,
}: {
  forecast?: Partial<Forecast>;
  isLoading: boolean;
}): string | JSX.Element => {
  return (!forecast?.id && isLoading) ? (
    <Skeleton title={{ width: 200 }} paragraph={false} active />
  ) : (forecast) ? (
    (() => {
      if (forecast?.model?.id === 1003 && forecast?.name) {
        return forecast?.name;
      }
      if (forecast?.audit?.client?.name && forecast?.created_at) {
        return `${forecast?.audit?.client?.name} ${moment(forecast?.created_at).format('MMM YYYY')} Forecast`;
      }
      if (forecast?.name) {
        return `${forecast?.name} Forecast`;
      }
      return `Forecast #${forecast?.id}`;
    })()
  ) : (
    '...'
  );
};

export default formatForecastTitle;
