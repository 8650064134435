/**
 * Intelligence -> Horizons -> View Horizon -> Client Details
 */

import React, { FC, useEffect, useState } from 'react';
import { Form as AntdForm } from 'antd';
import { Action } from 'api/accessControl/Action';
import { Resource } from 'api/accessControl/Resource';
import { useUpdateHorizonMutation } from 'api/crudGraphQL/horizons/updateHorizon';
import { AccessControl } from 'components/AccessControl';
import moment, { Moment } from 'moment';
import { HorizonForm } from 'features/intelligence/horizon/components';
import { useGetHorizonByUrlParam } from 'features/intelligence/horizon/hooks/useGetHorizonByUrlParam';
import { HorizonLayout } from '../../../HorizonLayout';

type FormattedFormValues = {
  start_date?: string | Moment;
}

const ClientDetails: FC = () => {
  const [length, setLength] = useState<number>();
  const [startDate, setStartDate] = useState<string>('');
  const [updateHorizonRequest, { isLoading }] = useUpdateHorizonMutation();
  const [form] = AntdForm.useForm();

  /**
   * Fetch horizon
   */
  const {
    data: horizon,
    isLoading: isLoadingHorizon,
    isFetching: isFetchingHorizon,
  } = useGetHorizonByUrlParam({
    refetchOnMountOrArgChange: true
  });

  /**
   * Auto populate form fields when horizon data loads
   */
  useEffect(() => {
    form.setFieldsValue({
      client_id: horizon?.model_client?.client?.id,
      start_date: moment(horizon?.start_date),
      end_date: horizon ? moment(horizon?.start_date).add(horizon?.length - 1, 'months') : moment(),
      model_client_id: horizon?.model_client?.id
    });
    const formattedStartDate = moment(form.getFieldValue('start_date')).startOf('month').format('YYYY-MM-DD');
    setStartDate(formattedStartDate);
    setLength(horizon?.length);
  }, [form, horizon]);

  useEffect(() => {
    const formattedStartDate = moment(form.getFieldValue('start_date')).startOf('month').format('YYYY-MM-DD');
    setStartDate(formattedStartDate);
  },[form.getFieldValue('start_date')]);

  const onSubmit = async (formValues: FormattedFormValues) => {
    if (horizon && length) {
      const updateHorizon = await updateHorizonRequest({
        id: horizon?.id,
        start_date: moment(formValues?.start_date).startOf('month').format('YYYY-MM-DD'),
        length: length
      });

    }
  };

  const submitDisabled = (startDate === horizon?.start_date && length === horizon?.length ||
    !form.getFieldValue('start_date') || !form.getFieldValue('end_date')
  );

  const initialValues = {
    client_id: horizon?.model_client?.client?.id,
    start_date: moment(horizon?.start_date),
    end_date: horizon ? moment(horizon?.start_date).add(horizon?.length - 1, 'months') : moment(),
    model_client_id: horizon?.model_client?.id
  };

  return (
    <>
      <HorizonLayout horizon={horizon} isLoading={isLoadingHorizon}>
        <AccessControl action={[Action.read]} resource={Resource.forecast}>
          <HorizonForm
            updateLoading={isLoading}
            onSubmit={onSubmit}
            clientData={horizon?.model_client?.client}
            form={form}
            submitDisabled={submitDisabled}
            isEdit
            setLength={setLength}
            initialValues={initialValues}
          />
        </AccessControl>
      </HorizonLayout>

    </>
  );
};

export default ClientDetails;
