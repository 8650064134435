/**
 * useCriteriaEntities
 * Fetches all possible criteria entities used in Library -> Rules
 */

import { useState, useEffect } from 'react';
import { novaGraphQLClient } from 'api/entityGraphQL';
import { Entity } from 'features/entitiesRedux';

type ReturnType = {
  loading: boolean;
  error: string;
  entities: Entity[];
};

export const useCriteriaEntities: () => ReturnType = () => {
  const [entities, setEntities] = useState<Entity[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { entities } = await novaGraphQLClient.fetchCriteriaEntities({});
        setError('');
        setEntities(entities);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error?.response?.errors?.[0]?.message || 'Unknown error');
      }
    })();
  }, []);

  return { entities, loading, error };
};

export default useCriteriaEntities;
