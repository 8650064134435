import loadable from '@loadable/component';

const LoadableComponentSearch = loadable(
  () => import('./AmazonAdsSearchIntegrationForm')
);

const LoadableComponentDsp = loadable(
  () => import('./AmazonAdsDspIntegrationForm')
);


export { LoadableComponentSearch as AmazonAdsSearchIntegrationForm };
export { LoadableComponentDsp as AmazonAdsDspIntegrationForm };