export enum CustomerInsightsWidgets {
  ltvTitle = 'Lifetime Value by',
  ltvId = 'consumer_insight_ltvs',
  familyBehaviorsTitle = 'Shopping + Entertainment',
  familyBehaviorsId = 'consumer_insight_family_behaviors',
  interestsTitle = 'Top Interests',
  interestsId = 'consumer_insight_interests',
  realEstateTitle = 'Home + Real Estate',
  realEstateId = 'consumer_insight_real_states', // BE typo
  lifeStagesTitle = 'Life Stage',
  lifeStagesId = 'consumer_insight_life_stages',
  financeWealthsTitle = 'Finance + Wealth',
  financeWealthsId = 'consumer_insight_finance_wealths'
}
