import React, { useState, useCallback } from 'react';
import { Dialog, DialogActions, Spinner, useSnackbar } from '@sprnova/nebula';
import { useUpdateStrategyMutation } from 'api/crudGraphQL/strategies/updateStrategy';
import { Opportunity } from 'features/entitiesRedux/models/opportunity/opportunity';
import { SalesforceValuesObjectType } from 'features/strategies/ViewBlueprintPage/components/Overview/components/ContractDetails/components/ContractDetailsEdit/ContractDetailsEdit';

interface RemoveSalesforceOpportunityConfirmationModalProps {
  alreadyLinkedOpportunity: Opportunity;
  extraToggle?: () => void;
  open: boolean;
  setSalesforceValuesObject: React.Dispatch<React.SetStateAction<SalesforceValuesObjectType>>;
  toggle: () => void;
}

const RemoveSalesforceOpportunityConfirmationModal = (
  {
    alreadyLinkedOpportunity,
    extraToggle,
    open,
    setSalesforceValuesObject,
    toggle,
  }: RemoveSalesforceOpportunityConfirmationModalProps): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [updateStrategyMutation] = useUpdateStrategyMutation();
  const { addSnackbar } = useSnackbar();

  const handleOnClickRemove = useCallback(async () => {
    try {
      setSubmitting(true);
      await updateStrategyMutation({
        id: alreadyLinkedOpportunity.strategy.id,
        salesforce_opportunity_id: null
      }).unwrap();
      addSnackbar({
        variant: 'success',
        message: 'Salesforce Opportunity removed successfully from the other Blueprint',
      });
      setSalesforceValuesObject((previousValues) => ({ ...previousValues, salesforce_opportunity_id: alreadyLinkedOpportunity.salesforce_id, salesforce_opportunity_name: alreadyLinkedOpportunity.salesforce_name}));
      toggle();
      extraToggle?.();
    } catch (error) {
      const errorMessage = 'Error removing the Salesforce Opportunity from the other Blueprint, please try again';
      console.error(errorMessage, error);
      addSnackbar({
        variant: 'error',
        message: errorMessage,
      });
    }
  }, [addSnackbar, alreadyLinkedOpportunity.salesforce_id, alreadyLinkedOpportunity.salesforce_name, alreadyLinkedOpportunity.strategy.id, extraToggle, setSalesforceValuesObject, toggle, updateStrategyMutation]);

  const handleOnClickCancel = useCallback(() => {
    toggle();
  }, [toggle]);

  return (
    <Dialog
      maxWidth={500}
      open={open}
      onClose={submitting ? undefined : (): void => toggle()}
      title={'Remove Salesforce Opportunity'}
      description='Are you sure you want to remove this Salesforce Opportunity from the other Blueprint? This will replace this Salesforce
                  Opportunity from the other Blueprint to this Blueprint. This change cannot be reverted'
      footer={
        <DialogActions
          primaryButtonProps={{ startIcon: submitting ? <Spinner /> : <></>, onClick: () => handleOnClickRemove(), children: submitting ? 'Removing...' : 'Remove', disabled: submitting }}
          secondaryButtonProps={{ onClick: () => handleOnClickCancel(), children: 'Cancel', variant: 'text' }}
        />
      }
    />
  );
};

export default RemoveSalesforceOpportunityConfirmationModal;
