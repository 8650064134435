import React, { memo } from 'react';
import { EmptyState } from '@sprnova/nebula';
import { formatPlatformCategory } from '../utils';

type NoPlatformTooltipProps = {
  required_categories?: string[];
};
const NoPlatformTooltip: React.FC<NoPlatformTooltipProps> = ({ required_categories }) => {
  const description = required_categories && required_categories.length > 0 ?
    `Integrate at least ${required_categories.map((category, index) => {
      if (index === required_categories.length - 1 && index !== 0) {
        return `and one ${formatPlatformCategory(category)} platform`;
      }
      return `one ${formatPlatformCategory(category)} platform`;
    }).join(', ')} to get accurate data.`
    :
    'Please ensure that all data access requests are complete and integrated.';

  return (
    <EmptyState
      size='medium'
      title='No available integrations'
      description={description}
    />
  );
};

export default memo(NoPlatformTooltip);
