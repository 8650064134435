import React from 'react';
import { BlockList } from 'components';

const AddIntegrationListSkeleton = (): JSX.Element => {
  return (
    <BlockList.Skeleton rows={8} />
  );
};

export { AddIntegrationListSkeleton };
