/**
 * SelectSalesforceReason
 */

import React, { forwardRef } from 'react';
import { useGetOpportunityReasonsQuery } from 'api/crudGraphQL/salesforce_opportunities/getOpportunityReasons';
import Select, { Props as SelectProps } from '../Select/Select';

interface Props extends SelectProps {
  type?: string;
}

const SelectSalesforceReason = forwardRef(({ type, skeleton, ...props }: Props, ref: React.Ref<HTMLInputElement>) => {
  const { data: reasons = [], isLoading, isFetching } = useGetOpportunityReasonsQuery({
    type: type === 'closed-lost' ? 'lost' : type,
    projection: {
      id: true,
      name: true,
      slug: true,
    },
  });

  return (
    <Select
      showSearch
      optionFilterProp="children"
      skeleton={skeleton || isLoading || isFetching}
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      {...props}
    >
      {reasons.map((reason) => (
        <Select.Option key={reason?.name} value={reason?.name}>
          {reason?.name}
        </Select.Option>
      ))}
    </Select>
  );
});

SelectSalesforceReason.displayName = 'SelectSalesforceReason';
export default SelectSalesforceReason;
