import { createEntityAdapter } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { denormalize } from 'normalizr';
import { entitiesSchema } from './entity';

const selectEntitiesData = (state: RootState) => state.entities.data;

type AdapterProperty = [string, ReturnType<typeof createEntityAdapter>];

export const selectEntitiesDenormalized = (state: RootState, input: object) => {
  const schema = entitiesSchema;

  const entities = Object.entries(selectEntitiesData(state)).reduce(
    (accum: object, [key, adapter]) => ({
      ...accum,
      [key]: Object.prototype.hasOwnProperty.call(adapter, 'entities')
        ? (adapter as { entities: any }).entities
        : null,
    }),
    {}
  );
  const denormalized = denormalize(input, schema, entities);
  return denormalized;
};
