import { Strategy } from 'features/entitiesRedux';
import { PricingVersion } from 'features/library/constants';

/**
 * Get the blueprint path based on the BP pricing version
 * @param blueprint Strategy
 * @returns The path to the blueprint
*/
export const getBlueprintPath = (blueprint: Strategy): string => {
  if (blueprint.pricing_version?.slug === PricingVersion.PACKAGE) {
    return `/blueprints/package/${blueprint.id}`;
  } else {
    return `/blueprints/${blueprint.id}`;
  }
};
