import React, { forwardRef } from 'react';
import { Tag as AntdTag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import classNames from 'classnames';
import css from './Tag.module.scss';

interface Props extends TagProps {
  className?: string;
}

const Tag = forwardRef(({ className, ...props }: Props, ref: any) => {
  return (
    <AntdTag {...props} className={classNames(className, css.root)} ref={ref} />
  );
});

Tag.displayName = 'Tag';

export default Tag;
