/**
* Get Playbook Campaigns
*/

import { Pagination, Projection } from 'api/entityGraphQL/types';
import { getArrayOrUndefined } from 'utils/helpers/getArrayOrUndefined';
import { PlaybookCampaign } from 'features/entitiesRedux/models/creative_playbook';
import { CustomFilterItemType, MetricFilterItemType } from './CreativePlaybookUtilTypes';
import tagTypes from './tagTypes';
import { baseApi, cleanArguments, paginationProjection } from '..';

export type GetPlaybookCampaignsQuery = {
  id?: number;
  ids?: number[];
  client_id?: number;
  client_ids?: number[];
  industry_ids?: number[];
  business_type_ids?: number[];
  asset_format_types?: ('image' | 'video' | 'carousel')[];
  funnel_ids?: number[];
  client_name?: string;
  campaign_id?: number;
  campaign_name?: string;
  platform_id?: number;
  platform_ids?: number[];
  start_date?: string;
  end_date?: string;
  time_period?: string;
  name?: string;
  general_metric_filters?: MetricFilterItemType[];
  campaign_custom_filters?: CustomFilterItemType[];
  adset_custom_filters?: CustomFilterItemType[];
  ad_custom_filters?: CustomFilterItemType[];
  match_name_in_all_models?: number;
  exclude_without_ltv?: number;
  status?: string;
  page?: number;
  limit?: number;
  sort?: string;
  projection: Projection;
};

export type GetPlaybookCampaignsResult = Pagination & {
  data: PlaybookCampaign[]
};

export const GET_PLAYBOOK_CAMPAIGNS_ENDPOINT_KEY = 'getPlaybookCampaigns';

export const { useGetPlaybookCampaignsQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_PLAYBOOK_CAMPAIGNS_ENDPOINT_KEY]: build.query<GetPlaybookCampaignsResult, GetPlaybookCampaignsQuery>({
      transformResponse: (response: { playbook_campaigns: GetPlaybookCampaignsResult }) => response.playbook_campaigns,
      providesTags: (result) => {
        const { data } = result ?? { data: [] };

        return [
          tagTypes.PLAYBOOK_CAMPAIGNS,
          ...data.map((playbookCampaign: PlaybookCampaign) => ({
            id: playbookCampaign.id,
            type: tagTypes.PLAYBOOK_CAMPAIGN
          }))
        ];
      },
      query: ({
        projection = { id: true, name: true },
        id,
        client_id,
        client_ids,
        industry_ids,
        business_type_ids,
        asset_format_types,
        funnel_ids,
        platform_ids,
        name,
        page = 1,
        limit = 10,
        sort,
        ...args
      }) => {
        const __args: Partial<GetPlaybookCampaignsQuery> = {
          id,
          client_id,
          client_ids,
          industry_ids,
          business_type_ids,
          asset_format_types,
          funnel_ids,
          platform_ids,
          name,
          page,
          limit,
          sort,
          ...args,
        };

        if (name && name !== '') {
          __args.name = `*${name}*`;
        } else {
          __args.name = undefined;
        }

        if (sort && sort === '' || sort === 'none') {
          __args.sort = undefined;
        }

        if (client_ids) __args.client_ids = getArrayOrUndefined(client_ids);
        if (industry_ids) __args.industry_ids = getArrayOrUndefined(industry_ids);
        if (business_type_ids) __args.business_type_ids = getArrayOrUndefined(business_type_ids);
        if (asset_format_types) __args.asset_format_types = getArrayOrUndefined(asset_format_types);
        if (funnel_ids) __args.funnel_ids = getArrayOrUndefined(funnel_ids);
        if (platform_ids) __args.platform_ids = getArrayOrUndefined(platform_ids);

        return {
          body: {
            query: {
              playbook_campaigns: {
                __args: cleanArguments(__args),
                data: projection,
                ...paginationProjection
              }
            }
          }
        };
      }
    }),
  }),
});
