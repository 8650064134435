/**
 * Strategy Form -> Commission Fields
 *
 * includes fields for:
 * --- Sales Rep. Commission
 * --- Default Commission
 * --- Commission Adjustment
 * --- Adjusted Commission
 *
 */

import React from 'react';
import { FormItem, InputNumber } from '../../../../../../components';
import { formatPercentInput } from '../../../../../audits/utils';
import { Strategy } from '../../../../../entitiesRedux';
import { AdjustedCommission, DefaultCommission, SalesRepCommission } from './components';

interface Props {
  strategy?: Partial<Strategy>;
  isEdit?: boolean;
  isLoading?: boolean;
}

const CommissionFields = ({
  strategy,
  isEdit,
  isLoading
}: Props): JSX.Element => {
  return (
    <>
      {isEdit && strategy?.pillar?.id === 1003 ?
        <FormItem label="Sales Rep. Commission">
          <SalesRepCommission user={strategy?.author} isLoading={isLoading} />
        </FormItem>
        : <></>
      }
      {isEdit ?
        <FormItem label="Default Commission">
          <DefaultCommission
            defaultCommission={strategy?.default_base_commission}
            disabled={strategy?.pillar?.id === 1003 && !!strategy?.author?.commission_override}
            isLoading={isLoading}
          />
        </FormItem>
        : <></>
      }
      <FormItem
        label="Commission Adjustment"
        name="commission_percent_adjustment"
      >
        <InputNumber
          skeleton={isLoading}
          placeholder="Enter a percentage"
          formatter={formatPercentInput}
          precision={2}
          max={100}
        />
      </FormItem>
      {isEdit ?
        <FormItem label="Adjusted Commission">
          <AdjustedCommission adjustedPercent={strategy?.adjusted_commission_percent} isLoading={isLoading} />
        </FormItem>
        : <></>
      }
    </>
  );
};

export default CommissionFields;
