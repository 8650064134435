import { Projection } from 'api/entityGraphQL/types';
import { AnnualRevenue } from 'features/entitiesRedux';
import { basePublicApi } from '../basePublicApi';
import { cleanArguments } from 'api/crudGraphQL/utils';

export type GetAnnualRevenuesPublicQuery = {
  projection?: Projection;
};

export type GetAnnualRevenuesPublicResult = AnnualRevenue[];

export const GET_ANNUAL_REVENUES_ENDPOINT_KEY = 'getAnnualRevenuesPublic';

export const { useGetAnnualRevenuesPublicQuery } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_ANNUAL_REVENUES_ENDPOINT_KEY]: build.query<GetAnnualRevenuesPublicResult, GetAnnualRevenuesPublicQuery>({
      transformResponse: (response: { client_annual_revenues: GetAnnualRevenuesPublicResult }) => response.client_annual_revenues,
      query: (query) => ({
        body: {
          query: {
            client_annual_revenues: {
              ...(cleanArguments(query?.projection) ?? {
                id: true,
                name: true,
              })
            }
          }
        }
      })
    }),
  }),
});
