import React from 'react';
import { Alert as MuiAlert, AlertTitle, Box, Typography } from '@mui/material';
import type { AlertProps } from '@mui/material';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import { ErrorIcon, InfoIcon, WarningIcon } from 'components/Icon';

const warningPrimaryColor = '#D1A508';
const warningSecondaryColor = '#FFFAE6';

const infoPrimaryColor = '#01579B';
const infoSecondaryColor = '#E6F3FB';

const errorPrimaryColor = '#C62828';
const errorSecondaryColor = '#F8E0E0';
/**
 * Alert ribbons are used to convey informational, error, or warning messages,
 * and the type of content and attention level they pertain to are classified
 * in their placement: Global, Sectional, or In-line.
 * These alerts disappear when dimissed or when action is taken to resolve them.
 */

export interface AlertRibbonProps extends AlertProps {
  ribbon_type?: 'global'| 'in-line' | 'sectional';
  type?: 'warning' | 'info' | 'error';
  hasIcon?: boolean;
  iconPosition?: 'top' | 'middle';
  extra?: JSX.Element;
  extraPosition?: 'top' | 'middle';
  title?: string;
  content?: JSX.Element | string;
}

export const AlertRibbon = ({
  ribbon_type = 'global',
  type = 'warning',
  hasIcon = true,
  iconPosition = 'top',
  extra,
  extraPosition = 'middle',
  title,
  content,
  ...props
}: AlertRibbonProps): JSX.Element => {

  const AlertRibbonTheme = createTheme({
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            border: 'solid',
            borderWidth: '2px',
            padding: '16px',
            color: 'black',
            background: type === 'error' ? errorSecondaryColor : type === 'info' ? infoSecondaryColor : warningSecondaryColor,
            borderColor: type === 'error' ? errorPrimaryColor : type === 'info' ? infoPrimaryColor : warningPrimaryColor,
          },
          message: {
            padding: '0',
            display: 'flex',
            justifyContent: 'space-between',
          },
          icon: {
            padding: '2px 10px',
            alignItems: iconPosition === 'top' ? 'flex-start' : 'center',
          },
          action: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: extraPosition === 'top' ? 'flex-start' : 'center',
            padding: '2px 10px',
          }
        }
      },
      MuiAlertTitle: {
        styleOverrides: {
          root: {
            margin: '0',
            fontSize: '1rem',
            fontWeight: '700',
            padding: '0',
          },
        }
      }
    }
  });

  return (
    <ThemeProvider theme={AlertRibbonTheme}>
      <MuiAlert
        {...props}
        severity={type}
        icon={hasIcon && (type === 'error' ? <ErrorIcon/> : type === 'info' ? <InfoIcon/> : <WarningIcon/>)}
        action={extra}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          component: 'div',
          className: 'title-container'
        }}
        >
          {title && <AlertTitle> {title} </AlertTitle>}
          <Typography 
            sx={{
              fontSize: '1rem',
              fontWeight: '400',
            }}
            variant='body2'
            variantMapping={{ body2: 'div' }}
          >
            {content}
          </Typography>
        </Box>
      </MuiAlert>
    </ThemeProvider>
  );
};
