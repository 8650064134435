export default {
  SURVEY: 'SURVEY',
  SURVEYS: 'SURVEYS',
  SURVEY_CYCLE: 'SURVEY_CYCLE',
  SURVEY_CYCLES: 'SURVEY_CYCLES',
  SURVEY_CYCLE_EXPERIENCE: 'SURVEY_CYCLE_EXPERIENCE',
  SURVEY_CYCLE_EXPERIENCES: 'SURVEY_CYCLE_EXPERIENCE',
  SURVEY_TYPE: 'SURVEY_TYPE',
  SURVEY_TYPES: 'SURVEY_TYPES',
};
