/**
 * BlockList -> ActionsColumn
 */

import React, { FC } from 'react';
import classnames from 'classnames';
import ActionsDropdown, { Props as ActionsDropdownProps } from 'components/ActionsDropdown';
import Column, { Props as ColumnProps } from '../Column';
import css from './ActionsColumn.module.scss';

interface Props extends ColumnProps {
  actions: ActionsDropdownProps['actions'];
  menuItemClassName?: string;
  dropdownClassName?: string;
}

const ActionsColumn: FC<Props> = ({ className, dropdownClassName, actions = [], menuItemClassName }) => (
  <Column className={classnames(css.control, className)} ellipsis={false} onTop>
    {Array.isArray(actions) && actions.length ? (<ActionsDropdown actions={actions} className={dropdownClassName} menuItemClassName={menuItemClassName} />) : null}
  </Column>
);

export default ActionsColumn;
