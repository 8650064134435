/**
 * Icon -> ArrowRight
 */

import React, { FC } from 'react';

const ArrowRight: FC = (props) => (
  <span role="img" className="anticon anticon-user" {...props}>
    <svg
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="m12 19.625-1.075-1.075 5.825-5.8H4.375v-1.5H16.75l-5.825-5.8L12 4.375 19.625 12z" />
    </svg>
  </span>
);

export default ArrowRight;
