/**
 * Library -> Tasks
 */

import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Container, PlusIcon, Skeleton } from '@sprnova/nebula';
import { Action } from 'api/accessControl';
import { useGetTasksQuery } from 'api/crudGraphQL/tasks/getTasks';
import { AppDispatch } from 'app/store';
import omitBy from 'lodash/omitBy';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import useMixpanelTrack from 'utils/hooks/useMixpanelTrack';
import {
  FetchTasksFilter,
  Resource,
  Task,
  Department,
  deleteTask
} from 'features/entitiesRedux';
import { useAccount } from 'features/global';
import LibraryPageHero from 'features/library/components/LibraryPageHero';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import {
  AccessControl,
  NoResultsFound,
  Spin,
  notification,
} from 'components';
import { Filters, List } from './components';
import { initialValues as initialFilterValues } from './components/Filters';
import { tasksProjection } from './projection';

const Tasks = (): JSX.Element => {
  const [pricingVersionQueryParam] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );

  const getButtonLink = useMemo(
    () => (pricingVersionQueryParam === PricingVersion.PACKAGE ? '/library/package-strategies/new?pricingVersion=' + pricingVersionQueryParam : '/library/tasks/new?pricingVersion=' + pricingVersionQueryParam),
    [pricingVersionQueryParam]
  );
  const mixpanel = useMixpanelTrack();
  /**
   * Use ref to trigger useEffect only once
   */
  const didSendPageViewEventRef = useRef(false);

  const { account } = useAccount();
  const isPackageVersion = pricingVersionQueryParam === PricingVersion.PACKAGE;

  // Track mixpanel event when page is visited for package version only
  useEffect(() => {
    if (!didSendPageViewEventRef.current && account && isPackageVersion) {
      didSendPageViewEventRef.current = true;
      mixpanel('Strategies Page Viewed', {
        title: account?.name,
        userId: account?.id,
        userName: account?.name,
        department: account?.department?.name,
        departments: JSON.stringify(account?.departments?.map((department: Department) => department.name))
      });
    }
  }, [account, isPackageVersion, mixpanel]);

  const dispatch: AppDispatch = useDispatch();
  const [allTasks, setAllTasks] = useState<Task[]>([]);
  const [filter, setFilter] = useState<FetchTasksFilter>({});

  const { data: tasks, isFetching, isLoading: loading, refetch } = useGetTasksQuery({
    projection: tasksProjection,
    pricing_version: pricingVersionQueryParam,
  }, {refetchOnMountOrArgChange: true});

  useEffect(()=> {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (tasks) {
      setAllTasks(tasks);
    }
  }, [tasks]);

  const handleSetFilter = useCallback((filterValues) => {
    setFilter(filterValues);
  }, []);

  const handleDelete = useCallback(async (task: Partial<Task>) => {
    try {
      if (!task || !task.id || !task.name) {
        throw new Error('Strategy id or name is missing. Cannot delete task');
      }
      const updateAction = await dispatch(deleteTask(task.id));

      if (deleteTask.fulfilled.match(updateAction)) {
        notification.success({
          message: 'Strategy deleted',
        });
        mixpanel('Strategy deleted', { taskId: task.id, taskName: task.name });
        // Remove the deleted task from the list of all tasks
        setAllTasks(allTasks.filter((currentTask) => currentTask.id !== task.id));
      }
    } catch (error) {
      console.error('Error deleting task', error);
    }
  }, [allTasks, dispatch, mixpanel]);

  const hasFilters = !!Object.keys(omitBy(filter, (val) => !val)).length;

  const taskFilterFunc = useCallback(
    (task: Task) => {
      const result = [];
      const { name, service } = task;
      const {
        name: nameFilter,
        service_id: serviceFacetId,
        department_id: departmentFacetId,
      }: FetchTasksFilter = filter;
      // Hotfix
      if (service === null) {
        result.push(false);
      }

      // Filter by task name
      if (nameFilter && typeof name === 'string') {
        result.push(name.toLowerCase().includes(nameFilter.toLowerCase()));
      }

      // Filter by service
      if (serviceFacetId && service) {
        result.push(service.id === serviceFacetId);
      }

      // Filter by department
      const department = service?.department;
      if (departmentFacetId && department) {
        result.push(department.id === departmentFacetId);
      }

      // Only return tasks if all filters/facets match
      return !result.includes(false);
    },
    [filter]
  );

  const filteredTasks = useMemo(
    () => (hasFilters ? allTasks.filter(taskFilterFunc) : allTasks),
    [hasFilters, allTasks, taskFilterFunc]
  );

  const containerContents = useMemo(() => !allTasks.length && !hasFilters && !loading ? (
    <NoResultsFound title="No strategies created" />
  ) : (
    <>
      <Filters
        filter={filter}
        hasFilters={hasFilters}
        setFilter={handleSetFilter}
      />
      {hasFilters && !filteredTasks.length ? (
        <NoResultsFound
          title="No strategies matched the applied filters"
          buttonProps={{
            children: 'Reset filters',
            type: 'primary',
            onClick: () => {
              handleSetFilter(initialFilterValues);
            },
          }}
        />
      ) : (
        <List
          onDelete={handleDelete}
          loading={loading}
          tasks={filteredTasks}
        />
      )}
    </>
  ), [allTasks, filteredTasks, filter, hasFilters, handleDelete, handleSetFilter, loading]);

  const isFetchingSpin = useMemo(() => {
    if (isFetching) return <Spin loading={isFetching} />;
  }, [isFetching]);

  const content = useMemo(() => {
    const resource = isPackageVersion ? Resource.libraryStrategy : Resource.task;
    return (
      <AccessControl action={[Action.read]} resource={resource}>
        <>
          {isFetchingSpin}
          {containerContents}
        </>
      </AccessControl>
    );
  }, [containerContents, isFetchingSpin, isPackageVersion]);

  /**
   * Render the hero button
   * @returns JSX.Element - The hero button
   */
  const renderHeroButton = useCallback((): JSX.Element => {
    return (
      <Button
        variant="primary"
        size="large"
        to={getButtonLink}
        component={Link}
        startIcon={<PlusIcon />}
      >
        New Strategy
      </Button>
    );
  }, [getButtonLink]);

  /**
   * If data are loading, display a skeleton
   * else display the hero button:
   * - If pricing version is Package, use Library Package Access Control.
   * @returns JSX.Element - The hero button
   */
  const renderHeroButtonContainer = useCallback((): JSX.Element => {
    if (loading) {
      return <Skeleton width={200} height={50} />;
    } else {
      const resource = isPackageVersion ? Resource.libraryStrategy : Resource.task;
      return (
        <AccessControl
          key="create-task"
          action={[Action.create]}
          resource={resource}
          showWarning={false}
        >
          {renderHeroButton()}
        </AccessControl>
      );
    }
  }, [loading, isPackageVersion, renderHeroButton]);

  return (
    <>
      <LibraryPageHero
        title={`Strategies ${isPackageVersion ? '(Package)' : ''}`}
        end={renderHeroButtonContainer()}
      />
      <Container hasVerticalPadding>
        {content}
      </Container>
    </>
  );
};

export default memo(Tasks);
