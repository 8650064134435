
import { Projection } from 'api/entityGraphQL';
import { SalesforceAccount } from 'features/entitiesRedux';
import { baseApi } from '../baseApi';
import { cleanArguments } from '../utils';

export type GetSalesforceAccountsQuery = {
  search_name?: string;
  salesforce_account_id?: string;
  limit?: number;
  projection?: Projection;
}

export type GetSalesforceAccountsResult = SalesforceAccount[];
export const GET_SALESFORCE_ACCOUNTS_ENDPOINT_KEY = 'getSalesforceAccounts';

export const { useGetSalesforceAccountsQuery, useLazyGetSalesforceAccountsQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_SALESFORCE_ACCOUNTS_ENDPOINT_KEY]: build.query<GetSalesforceAccountsResult, GetSalesforceAccountsQuery>({
      transformResponse: (response: { salesforce_accounts: GetSalesforceAccountsResult }) => response.salesforce_accounts,
      query: ({
        projection = { id: true, name: true },
        ...args
      }) => {
        const __args: Partial<GetSalesforceAccountsQuery> = args;

        return {
          body: {
            query: {
              salesforce_accounts: {
                __args: cleanArguments(__args),
                ...projection
              }
            }
          }
        };
      }
    })
  })
});




