/**
 * SectionBuilder -> Fields -> ClientNameField
 */

import React, { FC } from 'react';
import { FormItem, Input } from '../../../../../../../../components';
import { FieldProps } from '../../types';

const ClientNameField: FC<FieldProps> = ({ name }) => {
  return (
    <FormItem name={name}>
      <Input />
    </FormItem>
  );
};

export default ClientNameField;
