/**
 * Entities -> Departments -> Actions
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { novaGraphQLClient, Projection } from 'api/entityGraphQL';
import { normalizeEntities } from 'features/entitiesRedux/utils';
import { FetchDepartmentsFilter, Department } from './department';

/** Fetch Many Paginated */
export const fetchDepartments = createAsyncThunk(
  'entities/fetchDepartments',
  async (config: {
    projection?: Projection,
    filter?: FetchDepartmentsFilter;
  }): Promise<any> => {
    const { departments } = await novaGraphQLClient.fetchDepartments(config);
    return { ...normalizeEntities({ departments }) };
  }
);

/** Fetch one */
export const fetchDepartmentById = createAsyncThunk(
  'entities/fetchDepartmentById',
  async ({ id, ...config }: { id: number; projection?: Projection }) => {
    const { departments } = await novaGraphQLClient.fetchDepartmentById(id, {
      ...config,
    });
    return { ...normalizeEntities({ departments }) };
  }
);

/** Create */
export const createDepartment = createAsyncThunk(
  'entities/createDepartment',
  async (values: Partial<Department>) => {
    const response = await novaGraphQLClient.createDepartment(values);
    return { ...normalizeEntities(response) };
  }
);

/* Update */
export const updateDepartment = createAsyncThunk(
  'entities/updateDepartment',
  async (values: Partial<Department>) => {
    const response = await novaGraphQLClient.updateDepartment(values);
    return { ...normalizeEntities(response) };
  }
);

/** Delete */
export const deleteDepartment = createAsyncThunk(
  'entities/deleteDepartment',
  async (id: number) => {
    try {
      await novaGraphQLClient.deleteDepartment(id);
      return id;
    } catch (error) {
      console.error('Error deleting department', error);
      return { ...normalizeEntities({}) };
    }
  }
);
