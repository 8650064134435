import { Projection } from 'api/entityGraphQL';
import { baseApi } from '..';
import tagTypes from './tagTypes';
import { Task } from './types';

export type DeleteTaskMutation = {
  id: number;
  projection?: Projection;
}

export type DeleteTaskResult = Task;

export const DELETE_TASK_ENDPOINT_KEY = 'deleteTask';

export const { useDeleteTaskMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [DELETE_TASK_ENDPOINT_KEY]: build.mutation<DeleteTaskResult, DeleteTaskMutation>({
      transformResponse: (response: { deleteTask: Task }) => response.deleteTask,
      invalidatesTags: [tagTypes.TASKS],
      query: ({ id, projection = {} }) => ({
        body: {
          mutation: {
            deleteTask: {
              __args: {
                id
              },
              ...projection,
              id: true
            }
          }
        }
      })
    })
  })
});
