/**
 * Tasks -> Filters
 */

import React, {useEffect, useMemo} from 'react';
import { Form as AntdForm } from 'antd';
import classnames from 'classnames';
import omitBy from 'lodash/omitBy';
import {NumberParam, StringParam, useQueryParams, withDefault, useQueryParam } from 'use-query-params';
import { FetchTasksFilter } from 'features/entitiesRedux';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import {
  Form,
  Input,
  FormItem,
  Button,
  SelectDepartment,
  SelectService,
} from 'components';
import initialValues from './initialValues';
import css from './Filters.module.scss';

interface Props {
  setFilter: (x: object) => void;
  filter: FetchTasksFilter;
  isLoading?: boolean;
  hasFilters: boolean;
}

const TasksFilters = ({
  filter = {},
  setFilter,
  hasFilters,
  isLoading,
}: Props) => {
  const [form] = AntdForm.useForm();
  const [queryParams, setQueryParams] = useQueryParams({
    department_id: NumberParam,
    service_id: NumberParam,
    name: StringParam,
  });

  const [pricingVersionQueryParam, setPricingVersionQueryParam] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );

  const handleUpdateFilters = (filterValues: FetchTasksFilter) => {
    const values = omitBy(filterValues, (val) => !val);
    setFilter(values);
    setQueryParams(values);
  };

  const handleValuesChange = (
    change: object,
    filterValues: FetchTasksFilter
  ) => {
    handleUpdateFilters(filterValues);
  };

  // Update form if filters updates externally
  useEffect(() => {
    setQueryParams(omitBy(filter, (val) => !val));
    form.setFieldsValue(filter);
  }, [filter, form, setQueryParams]);

  // Set filters based on query params on mount
  // or use filters stored in session (if any)
  useEffect(() => {
    if (Object.keys(omitBy(queryParams, (val) => !val)).length) {
      handleUpdateFilters(queryParams);
    }
  }, []); /* eslint-disable-line */

  return (
    <div className={css.root}>
      <Form
        initialValues={{ ...initialValues, ...filter }}
        form={form}
        className={css.form}
        layout="vertical"
        onValuesChange={handleValuesChange}
      >
        <div className={css.columns}>
          <div className={classnames(css.column, css.column__department)}>
            <FormItem
              smallLabel
              label="Department"
              name="department_id"
              className={css.formItem}
            >
              <SelectDepartment
                loading={isLoading}
                allowClear
                placeholder="Filter by Department"
              />
            </FormItem>
          </div>
          <div className={classnames(css.column, css.column__service)}>
            <FormItem
              smallLabel
              label="Service"
              name="service_id"
              className={css.formItem}
            >
              <SelectService
                loading={isLoading}
                allowClear
                placeholder="Filter by Service"
                departmentId={filter?.department_id || undefined}
                pricing_version={pricingVersionQueryParam}
              />
            </FormItem>
          </div>
          <div className={classnames(css.column, css.column__name)}>
            <FormItem
              smallLabel
              label="Name"
              name="name"
              className={css.formItem}
            >
              <Input
                skeleton={isLoading}
                allowClear
                placeholder="Filter by Name"
              />
            </FormItem>
          </div>
          <div
            className={classnames(css.column, css.column__reset, {
              [css.show]: hasFilters,
            })}
          >
            <Button
              className={css.resetButton}
              type="text"
              onClick={() => {
                handleUpdateFilters(initialValues);
                form.setFieldsValue(initialValues);
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default TasksFilters;
