import React, { forwardRef, memo } from 'react';
import { InputNumber as AntdInputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import classNames from 'classnames';
import { SkeletonInput } from '../SkeletonInput';
import css from './InputNumber.module.scss';

interface Props extends InputNumberProps {
  className?: string;
  skeleton?: boolean;
  type?: string;
}

const formatter = (value?: number | string): string => {
  if (!value) {
    return '';
  }
  const lastChar = String(value).slice(-1);
  const tail = lastChar === '.' ? lastChar : '';
  const number = Number(value);
  const formatted = number.toString() + tail;
  return formatted;
};

const InputNumber = forwardRef(
  ({ className, skeleton = false, type, ...props }: Props, ref: React.Ref<HTMLInputElement> | undefined) => {
    // Show skeleton when loading is true
    if (skeleton) return <SkeletonInput loading={false} active={true} />;

    return (
      <AntdInputNumber
        formatter={formatter}
        {...props}
        className={classNames(className, type, css.root)}
        ref={ref}
      />
    );
  }
);

InputNumber.displayName = 'InputNumber';

export default memo(InputNumber);
