import React from 'react';
import { Box, Typography } from '@sprnova/nebula';
import AdPreviewModalCard from 'features/intelligence/creative-playbook/components/AdPreviewModalCard/AdPreviewModalCard';
import { getPlatformIcon } from 'features/intelligence/creative-playbook/CreativeAffinityUtils';
import { PlaybookDataType } from 'features/intelligence/creative-playbook/ViewCreativePlaybook/constants';
import { columnSpacing } from '../../utils/columnSpacing';

export const Campaign = ({ values }: any): JSX.Element => {
  return (
    <Box sx={{ marginBottom: '10px' }}>
      <div style={{
        maxHeight: '500px',
        overflow: 'hidden',
        overflowY: 'scroll',}}
      >
        {
          values?.map((value: any, i: number) => {
            return (
              <div key={`adset_${i}`} style={{ padding: `0 ${columnSpacing}`, marginBottom: '30px' }}>
                <Typography
                  variant='h5'
                  style={{
                    fontWeight: 600,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginBottom: '20px',
                    maxWidth: 'fit-content',
                  }}
                >
                  {value?.name}
                </Typography>
                {
                  value.ads?.map((ad: any, i: number) => {
                    return (
                      <div key={`ads_${i}`} style={{ display: 'flex', marginBottom: '8px' }}>
                        <AdPreviewModalCard adId={ad.id} adPreviewModalData={ad} dataType={PlaybookDataType.Ad} />
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: '70%',
                          }}
                        >
                          <Typography
                            variant='h5'
                            style={{
                              fontWeight: 500,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: '80%',
                              marginBottom: '4px'
                            }}
                          >
                            {ad?.name}
                          </Typography>
                          <div>
                            {getPlatformIcon(ad?.platform?.name)}
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            );
          })
        }
      </div>
    </Box>
  );
};

export const Adset = ({ values }: any) => {
  return (
    <Box sx={{
      maxHeight: '500px',
      overflow: 'scroll',
      marginBottom: '10px',
      maxWidth: '100%',
      display: 'flex !important',
      alignItems: 'flex-start !important',
    }}>
      <div style={{ maxWidth: '100%' }}>
        {
          values?.map((ad: any, i: number) => {
            return (
              <div
                key={`ads_${i}`}
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  marginBottom: '8px',
                }}
              >
                <AdPreviewModalCard adId={ad.id} adPreviewModalData={ad} dataType={PlaybookDataType.Ad} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '60%',
                  }}
                >
                  <Typography
                    variant='h5'
                    style={{
                      fontWeight: 500,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '100%',
                      marginBottom: '4px'
                    }}
                  >
                    {ad?.name}
                  </Typography>
                  <div>
                    {getPlatformIcon(ad?.platform?.name)}
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    </Box>
  );
};

export const Ad = ({ value }: any): JSX.Element => {
  return (
    <AdPreviewModalCard
      adId={value?.id}
      adPreviewModalData={value}
      height='216px'
      width='216px'
      radius='16px'
      imageStyles={{ marginRight: '0px' }}
      maxWidth={null}
      minWidth={null}
      sx={{
        marginRight: 0
      }}
      missingImageSize='large'
      dataType={PlaybookDataType.Ad}
    />
  );
};

type AffinityProps = {
  value: number;
  score?: string;
}
export const Affinity = ({ value, score = 'High' }: AffinityProps): JSX.Element => {
  return (
    <Typography
      variant='h3'
      gutterBottom
      component='div'
      sx={{
        color: '#2E7D32'
      }}
    >
      {value}%
      <span style={{ color: '#000000' }}>
        &nbsp;({score})
      </span>
    </Typography>
  );
};
