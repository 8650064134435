import { FunnelConnector } from 'features/entitiesRedux';
import tagTypes from './tagTypes';
import { baseApi } from '..';

export type CreateFunnelConnectorMutation = {
  connectors: Partial<FunnelConnector>[];
};

export type CreateFunnelConnectorResult = string[];

/**
 * Custom hook for creating a new Funnel connector.
 * This mutation is integrated into the baseApi's endpoints using RTK Mutation.
 *
 * @param {CreateFunnelConnectorMutation} args - The arguments for creating a Funnel connector.
 * - `group_id` (string | undefined): The ID of the group where the connector will be created.
 * - `service` (string | undefined): The type of service for the connector.
 * - `is_created_by_client` (boolean | undefined): Flag indicating if the connector is created by the client.
 * - `attributions` (string | undefined): Additional attributions or notes for the connector.
 *
 * @returns A RTK Mutation mutation hook (`useCreateFunnelConnectorMutation`) that provides
 * the mutate function and the mutation result (data, error, isLoading).
 *
 * The mutation:
 * - Invalidates FIVETRAN_CONNECTOR tag upon a successful creation.
 * - Transforms the GraphQL response to return only the createFunnelConnector data.
 *
 * @example
 * const [createFunnelConnector, { data, error, isLoading }] = useCreateFunnelConnectorMutation();
 * createFunnelConnector({ connectors: connectors });
 */
export const { useCreateFunnelConnectorMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createFunnelConnector: build.mutation<CreateFunnelConnectorResult, CreateFunnelConnectorMutation>({
      transformResponse: (response: { createFunnelConnector: CreateFunnelConnectorResult }) => response.createFunnelConnector ?? [],
      invalidatesTags: [tagTypes.FUNNEL_CONNECTOR],
      query: ({
        connectors
      }) => ({
        body: {
          mutation: {
            createFunnelConnector: {
              __args: { connectors },
            }
          }
        }
      })
    })
  })
});
