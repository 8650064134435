/**
 * SelectSurveyType
 */

import React, { forwardRef } from 'react';
import { useGetSurveyTypeQuery } from 'api/crudGraphQL/surveys/getSurveyTypes';
import { SurveyType } from 'features/entitiesRedux';
import Select, { Props as SelectProps } from '../Select/Select';

interface SelectSurveyTypeProps extends SelectProps {
  multiple?: boolean;
  unique?: boolean;
}

const SelectSurveyType = forwardRef(({ skeleton, multiple, unique = false, ...props }: SelectSurveyTypeProps, ref: React.Ref<HTMLInputElement>) => {
  const { data: survey_types = [], isLoading } = useGetSurveyTypeQuery({
    is_active: true,
    sort: ['survey_types.name'],
    unique,
    projection: {
      id: true,
      name: true,
    }
  });

  return (
    <Select
      showSearch
      skeleton={isLoading || skeleton}
      optionFilterProp='children'
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      mode= { multiple ? 'multiple' : undefined}
      {...props}
    >
      {survey_types.map((survey_type: SurveyType) => (
        <Select.Option key={survey_type.id} value={survey_type.id}>
          {survey_type.name}
        </Select.Option>
      ))}
    </Select>
  );
});

SelectSurveyType.displayName = 'SelectSurveyType';

export default SelectSurveyType;
