/**
 * Talent Surveys -> useSurvey
 *
 * Takes a surveyId and returns the survey
 * The survey ID from the URL will be used if no survey ID is passed,
 */

import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Projection } from 'api/entityGraphQL';
import { formatErrorToHumanReadable } from 'utils';
import { notification } from 'components';
import { fetchTalentSurveyById } from '../actions';
import { selectSurveyById } from '../selectors';
import { TalentSurvey } from '../types';

const useSurvey: (surveyId?: number, projection?: Projection) => ({
  loading: boolean;
  error: string;
  survey: TalentSurvey;
  setLoading: (state: boolean) => void;
  refetch: () => void;
}) = (surveyId, projection) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ [x: string]: any }>();
  const surveyParamId = Number.parseInt(id);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const survey = useSelector(selectSurveyById(surveyParamId));

  const fetchSurvey = useCallback(async () => {
    setLoading(true);

    try {
      const fetchSurveyAction: any = await dispatch(fetchTalentSurveyById({ id: surveyParamId, projection }));

      if (fetchTalentSurveyById.fulfilled.match(fetchSurveyAction)) {
        setError('');
        setLoading(false);
      }

      if (fetchTalentSurveyById.rejected.match(fetchSurveyAction)) {
        const error = fetchSurveyAction?.error?.message || '';
        setError(formatErrorToHumanReadable(error));
        setLoading(false);
      }
    } catch (e) {
      console.error('Loading survey error', e);
      setError(formatErrorToHumanReadable(e));
      setLoading(false);
      notification.error({ message: 'Error loading survey' });
    }
  }, [dispatch, projection, surveyParamId]);

  const refetch = useCallback(() => {
    fetchSurvey();
  }, [fetchSurvey]);

  useEffect(() => {
    if (surveyId && surveyParamId) {
      fetchSurvey();
    }
  }, [fetchSurvey, surveyId, surveyParamId]);

  return {
    error,
    loading,
    survey: loading ? null : survey,
    setLoading,
    refetch
  };
};

export default useSurvey;
