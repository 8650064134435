/**
* Get analyst question
*/

import { baseApi } from 'api/crudGraphQL';
import { Projection } from 'api/entityGraphQL/types';
import { GetAnalystQuestionsResult } from './getAnalystQuestions';
import tagTypes from './tagTypes';
import { AnalystQuestion } from './types';

export const GET_ANALYST_QUESTION_ENDPOINT_KEY = 'getAnalystQuestion';

export type GetAnalystQuestionQuery = {
  projection?: Projection;
  id: number;
}

export type GetAnalystQuestionResult = AnalystQuestion;

export const { useGetAnalystQuestionQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_ANALYST_QUESTION_ENDPOINT_KEY]: build.query<GetAnalystQuestionResult, GetAnalystQuestionQuery>({
      transformResponse: (response: { analyst_questions: GetAnalystQuestionsResult }) => response.analyst_questions?.data?.[0],
      providesTags: (analystQuestion) => {
        return analystQuestion ? [
          {
            id: analystQuestion.id,
            type: tagTypes.ANALYST_SURVEY_QUESTION
          }
        ] : [tagTypes.ANALYST_SURVEY_QUESTION];
      },
      query: ({
        projection = { id: true, name: true },
        id
      }) => {
        return {
          body: {
            query: {
              analyst_questions: {
                __args: {
                  id,
                  limit: 1,
                  page: 1,
                },
                data: projection,
              },
            }
          }
        };
      },
    }),
  }),
});
