/**
 * Tasks -> List
 */

import React, {FC, memo, useCallback, useMemo} from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Action } from 'api/accessControl';
import {StringParam, useQueryParam, withDefault} from 'use-query-params';
import { Task, Service , Resource } from 'features/entitiesRedux';
import { useAccessControl } from 'features/global/hooks/useAccessControl';
import { useAccount } from 'features/global/hooks/useAccount';
import DeleteButton from 'features/library/components/DeleteButton';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import { Table, Button, ExpandButton } from 'components';
import css from './List.module.scss';

type Props = {
  tasks: Task[];
  loading: boolean;
  onDelete: (task: Pick<Task, 'id'>) => void;
};

const TasksList: FC<Props> = ({
  tasks,
  loading,
  onDelete
}) => {
  const [pricingVersionQueryParam] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );

  const { account } = useAccount();
  const { can } = useAccessControl();
  const canDelete = can(Action.delete, Resource.libraryStrategy, account?.id);
  const canEdit = can(Action.update, Resource.libraryStrategy, account?.id);

  const getLink = useCallback((task: Task) => {
    return (pricingVersionQueryParam === PricingVersion.PACKAGE ? `/library/package-strategies/${task?.id}?pricingVersion=${pricingVersionQueryParam}` : `/library/tasks/${task?.id}/edit?pricingVersion=${pricingVersionQueryParam}`);
  }, [pricingVersionQueryParam]);

  const getLinkEdit = useCallback((task: Task) => {
    return (pricingVersionQueryParam === PricingVersion.PACKAGE ? `/library/package-strategies/${task?.id}/edit?pricingVersion=${pricingVersionQueryParam}` : `/library/tasks/${task?.id}/edit?pricingVersion=${pricingVersionQueryParam}`);
  }, [pricingVersionQueryParam]);

  /**
   * Render the delete button
   * @param task The individual task
   * @returns JSX.Element
   */
  const renderDelete = useCallback((task: Task): JSX.Element => {
    return (
      <DeleteButton
        model={task}
        onDelete={onDelete}
        type='strategy'
        disabled={!canDelete}
      />
    );
  }, [canDelete, onDelete]);

  /**
   * Render Delete container
   * If pricing version is Package, use Library Package Access Control.
   * @param task The individual task
   * @returns
   */
  const renderDeleteContainer = useCallback((task: Task): JSX.Element => {
    return renderDelete(task);
  }, [renderDelete]);

  /**
   * Render the edit button
   * @param task The individual task
   * @returns JSX.Element
   */
  const renderEditButton = useCallback((task: Task): JSX.Element => {
    return (
      <Link to={getLinkEdit(task)}>
        <Button
          type="text"
          icon={<EditOutlined />}
          disabled={!canEdit}
        />
      </Link>
    );
  }, [canEdit, getLinkEdit]);

  /**
   * Render edit container
   * If pricing version is Package, use Library Package Access Control.
   * @param task The individual task
   * @returns JSX.Element
   */
  const renderEditContainer = useCallback((task: Task): JSX.Element => {
    return renderEditButton(task);
  }, [renderEditButton]);

  const renderActions = useCallback((_, task: Task): JSX.Element => (
    <div className={css.actions}>
      {renderDeleteContainer(task)}
      {renderEditContainer(task)}
    </div>
  ), [renderDeleteContainer, renderEditContainer]);

  const nameSorter = (a: any, b: any): number => (`${a.name}`).localeCompare(b.name);
  const renderName = (name: string, task: Task): JSX.Element => (<Link to={getLink( task )} target='_blank' rel="noreferrer noopener">{name}</Link>);

  const pricingTypeSorter = (a: Task, b: Task): number => (`${a?.pricing_type?.name}`).localeCompare(b?.pricing_type?.name ?? '');

  const serviceSorter = (a: Task, b: Task): number => (`${a?.service?.name}`).localeCompare(b?.service?.name);
  const renderService = (service: Service): JSX.Element | null => service ? <Link to={`/library/services/${service.id}/edit?pricingVersion=${pricingVersionQueryParam}`} target='_blank' rel="noreferrer noopener">{service.name}</Link> : null;

  const departmentSorter = (a: Task, b: Task): number => (`${a?.service?.department?.name}`).localeCompare(b?.service?.department?.name);
  const renderDepartment = (value: any, { service }: { service: Service }): JSX.Element | null => {
    const department = service?.department;
    if (!department)
      return null;

    return <Link to={`/library/departments/${department.id}/edit?pricingVersion=${pricingVersionQueryParam}`} target='_blank' rel="noreferrer noopener">{department.name}</Link>;
  };

  let columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: renderName,
      width: 500,
      ellipsis: true,
      sorter: nameSorter,
    },
    {
      title: 'Pricing Type',
      key: 'pricing_type',
      dataIndex: ['pricing_type', 'name'], // access nested property using an array of keys. This gets the name of the pricing type object
      width: 200,
      ellipsis: true,
      sorter: pricingTypeSorter,
    },
    {
      title: 'Service',
      key: 'service',
      dataIndex: 'service',
      render: renderService,
      width: 200,
      ellipsis: true,
      sorter: serviceSorter,
    },
    {
      title: 'Department',
      key: 'department',
      dataIndex: 'department',
      width: 150,
      ellipsis: true,
      render: renderDepartment,
      sorter: departmentSorter,
    },
    {
      title: '',
      key: 'actions',
      dataIndex: 'actions',
      width: 130,
      render: renderActions
    },
  ];

  /**
   * Only show the pricing type column if the pricing version is package because there are no pricing types for hourly
   */
  if (pricingVersionQueryParam === PricingVersion.HOURLY) {
    columns = columns.filter((column) => column.key !== 'pricing_type');
  }

  const expandable = {
    expandIcon: ({ expanded, onExpand, record }: { expanded: boolean; onExpand: any; record: any; }) => (
      <ExpandButton
        expandLabel="Expand sub-tasks"
        collapseLabel="Collapse sub-tasks"
        expanded={expanded}
        onExpand={onExpand}
        record={record}
      />
    ),
    indentSize: 24
  };

  return (
    <Table
      expandable={expandable}
      loading={loading}
      rowKey="id"
      columns={columns}
      dataSource={tasks}
      scroll={{ x: 1100 }}
    />
  );
};

export default memo(TasksList);
