/**
 * SelectIndustry
 */

import React, { forwardRef } from 'react';
import { Industry } from 'features/entitiesRedux';
import { useIndustries } from '../../utils';
import Select, { Props as SelectProps } from '../Select/Select';

const SelectIndustry = forwardRef(({ skeleton, className, ...props }: SelectProps, ref: any) => {
  const { industries, loading } = useIndustries();

  return (
    <Select
      showSearch
      optionFilterProp="children"
      skeleton={loading || skeleton}
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      className={className}
      {...props}
    >
      {industries.map((industry: Industry) => (
        <Select.Option key={industry.id} value={industry.id}>
          {industry.name}
        </Select.Option>
      ))}
    </Select>
  );
});

SelectIndustry.displayName = 'SelectIndustry';
export default SelectIndustry;
