/**
 * SelectForecastChannels
 */

import React, { forwardRef, useMemo } from 'react';
import { forecast, ForecastChannel } from 'features/entitiesRedux';
import { useForecastChannels } from '../../utils';
import Select, { Props as SelectProps } from '../Select/Select';

type SelectForecastChannelsProps = SelectProps & {
  excludeIds?: number[];
};

const SelectForecastChannels = forwardRef(({ skeleton, excludeIds, ...props }: SelectForecastChannelsProps, ref: any) => {
  const { forecastChannels, loading } = useForecastChannels({
    projection: {
      id: true,
      name: true,
    }
  });

  const forecastChannelOptions = useMemo(() => {
    if (excludeIds) {
      const excludeSet = new Set(excludeIds);
      return forecastChannels.filter((c) => !excludeSet.has(c.id));
    }

    return forecastChannels;
  }, [forecastChannels, excludeIds]);

  return (
    <Select
      mode="multiple"
      allowClear
      showArrow
      placeholder="Select one or more channels"
      showSearch
      optionFilterProp="children"
      skeleton={loading || skeleton}
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      {...props}
    >
      {forecastChannelOptions?.map((forecastChannel: ForecastChannel) => {
        return (
          <Select.Option key={forecastChannel?.id} value={forecastChannel?.id}>
            {forecastChannel?.name}
          </Select.Option>
        );
      })}
    </Select>
  );
});

SelectForecastChannels.displayName = 'SelectForecastChannels';

export default SelectForecastChannels;
