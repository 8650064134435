import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { SearchIcon, Typography } from '@sprnova/nebula';
import { Department, Service, Task } from 'features/entitiesRedux';
import { DebouncedField, Input } from 'components';
import { ServiceItem, TasksListItem } from './components';
import css from './StrategySelectionList.module.scss';

type StrategySelectionListProps = {
  departments: Department[],
  onSelect: (tasks: Task[]) => void;
  onUnselect: (tasks: Task[]) => void;
  selectedIds: number[];
  pricingVersion: string;
  currentId?: number;
};

const StrategySelectionList = ({
  departments,
  onSelect,
  onUnselect,
  selectedIds,
  pricingVersion,
  currentId,
}: StrategySelectionListProps): JSX.Element => {
  const [search, setSearch] = useState<string>('');
  // Only show departments that have services.
  const departmentsWithServices = useMemo(() => departments?.filter((department) => department.services && department.services.length > 0), [departments]);
  const [filteredDepartments, setFilteredDepartments] = useState<Department[]>(departmentsWithServices);
  const [filteredTasks, setFilteredTasks] = useState<Task[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [departmentsCache, setDepartmentsCache] = useState<{[q: string]: Department[]}>({});
  const [tasksCache, setTasksCache] = useState<{[q: string]: Task[]}>({});

  const setAllTasks = useCallback(() => {
    const allTasks = departmentsWithServices?.reduce((acc, department: Department) => acc.concat(department?.services?.reduce((a: Task[], b) => a.concat(b.tasks), []), []), [] as Task[]);

    setTasks(allTasks);
  }, [departmentsWithServices]);

  useEffect(() => {
    setAllTasks();
  }, [setAllTasks]);

  /**
   * Function that handle logic for search filter:
   * - Filter the departments and tasks that match the search value
   *
   * @param searchValue Search value being typed in the search field
   */
  const onSearch = (searchValue: string) => {
    const departs = departmentsCache[searchValue] || departmentsWithServices;
    const tsks = tasksCache[searchValue] || tasks;
    const formattedSearchValue = searchValue.trim().toLowerCase();
    setSearch(formattedSearchValue);

    const filteredDepartments = departs?.reduce((acc: Department[], department: Department) => {
      const filteredServices = department?.services?.filter((service: Service) => service.name.toLowerCase().includes(formattedSearchValue) && service.pricing_version?.slug === pricingVersion);
      const nameMatches = department?.name?.toLowerCase().includes(formattedSearchValue);

      if (!nameMatches && !filteredServices?.length) {
        return acc;
      } else {
        return acc.concat({ ...department, services: (nameMatches && !filteredServices?.length) ? department.services: filteredServices });
      }
    }, []);
    setDepartmentsCache(prevState => ({ ...prevState, [searchValue]: filteredDepartments }));

    const filteredTasks = tsks?.filter(
      (task: Task) => task?.name?.toLowerCase()?.includes(formattedSearchValue)
      /**
       * Filter out tasks with the same ID and whose children have the same ID
       * to prevent infinite looping
       */
      && task?.id !== currentId
      && task?.pricing_version?.slug === pricingVersion
      && task?.next_revision === null
      && task?.children?.filter((childrenId: any) => childrenId.id === currentId).length === 0
    );
    setTasksCache(prevState => ({ ...prevState, [searchValue]: filteredTasks }));
    setFilteredDepartments(filteredDepartments);
    setFilteredTasks(filteredTasks);
  };

  const renderDepartment = useCallback((department: Department): JSX.Element => {
    return (
      <>
        <Typography variant='h5' sx={{ mb: 1.8, mt: 2.4, color: '#575757' }}>{department.name}</Typography>
        {Array.isArray(department?.services) && department?.services?.filter((s: Service) => s?.tasks?.length && s?.pricing_version?.slug === pricingVersion)?.map((service: Service) => {
          if (service.pricing_version?.slug === pricingVersion) {
            return (
              <ServiceItem
                currentId={Number(currentId)}
                key={service.id}
                service={service}
                onSelect={onSelect}
                onUnselect={onUnselect}
                selectedIds={selectedIds}
                pricingVersion={pricingVersion}
              />
            );
          }
        })}
      </>
    );
  }, [currentId, onSelect, onUnselect, pricingVersion, selectedIds]);

  const renderFilteredTasks = (): null | JSX.Element => {
    if (!search || !filteredTasks?.length) {
      return null;
    }

    return (
      <>
        <Typography variant='h5' sx={{ mb: 1.8, mt: 2.4, color: '#575757' }}>Tasks search results</Typography>
        <TasksListItem
          className={css.tasks__list__item}
          tasks={filteredTasks}
          onSelect={onSelect}
          onUnselect={onUnselect}
          selectedIds={selectedIds}
        />
      </>
    );
  };

  const renderContent = (): JSX.Element => {
    const depts = search ? filteredDepartments : departmentsWithServices;
    const noResults = search && !filteredDepartments?.length && !filteredTasks?.length;

    let content;

    if (noResults) {
      content = (
        <div className={css.noResults}>
          {`No departments, services or strategies matching "${search}" were found.`}
        </div>
      );
    } else if (departmentsWithServices?.length === 0) {
      content = (
        <div className={css.noResults}>
          No strategies
        </div>
      );
    } else {
      content = (
        <ul className={css.list}>
          {renderFilteredTasks()}
          {depts.map(renderDepartment)}
        </ul>
      );
    }

    return content;
  };

  return (
    <div className={css.root}>
      <div className={css.filters}>
        <DebouncedField
          placeholder='Search'
          onUpdate={search => {
            onSearch(search);
          }}
          name='search'
          prefix={<SearchIcon />}
          component={Input}
          value={search}
          isDisabled={departmentsWithServices.length === 0}
          allowClear
        />
      </div>
      <div className={css.content}>
        {renderContent()}
      </div>
    </div>
  );
};

export default memo(StrategySelectionList);
