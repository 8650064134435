import React from 'react';
import { IntegrationMetaIcon, IntegrationTikTok2Icon } from '@sprnova/nebula';


export function getPlatformIcon(platformName: string | undefined): JSX.Element {
  switch (platformName) {
    case 'TikTok':
    case 'tiktok-ads':
      return <IntegrationTikTok2Icon />;
    case 'Meta':
    case 'meta-ads':
      return <IntegrationMetaIcon />;
    default:
      return <></>;
  }
}

export function getAdFormatName(adFormats: string[]): string {
  return adFormats.map(format => format.charAt(0).toUpperCase() + format.slice(1)).join(', ');
}

export function getSearchbarText(dataType: string): string {
  switch (dataType) {
    case 'campaign':
      return 'Campaign';
    case 'adset':
      return 'Ad Set';
    case 'ad':
      return 'Ad';
    default:
      return 'Unknown';
  }
}

export function formatUpdatedTimestamp(timestamp: string | undefined): string {
  if (!timestamp) return 'Not updated yet';
  const [year, month, day] = timestamp.split('-').map(String);
  return `${month}/${day}/${year}`;
}
