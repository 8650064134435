/**
* Get paginated analyst question groups
*/

import { baseApi, paginationProjection, cleanArguments } from 'api/crudGraphQL';
import { Pagination, Projection } from 'api/entityGraphQL/types';
import tagTypes from './tagTypes';
import { AnalystQuestionGroup } from './types';

export const GET_ANALYST_QUESTION_GROUPS_ENDPOINT_KEY = 'getAnalystQuestionGroups';

export type GetAnalystQuestionGroupsQuery = {
  business_type_id?: number | string | null;
  department_id?: number;
  limit?: number;
  name?: string;
  page?: number;
  projection: Projection;
  service_id?: number;
  revision?: number | null;
}

export type GetAnalystQuestionGroupsResult = Pagination & {
  data: AnalystQuestionGroup[]
}

export const {
  useGetAnalystQuestionGroupsQuery,
  useLazyGetAnalystQuestionGroupsQuery,
  util,
} = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_ANALYST_QUESTION_GROUPS_ENDPOINT_KEY]: build.query<GetAnalystQuestionGroupsResult, GetAnalystQuestionGroupsQuery>({
      transformResponse: (response: { analyst_question_groups: GetAnalystQuestionGroupsResult }) => response.analyst_question_groups,
      providesTags: (result) => {
        const { data } = result ?? { data: [] };

        return [
          tagTypes.ANALYST_SURVEY_QUESTION_GROUPS,
          ...data.map(questionGroup => ({
            id: questionGroup.id,
            type: tagTypes.ANALYST_SURVEY_QUESTION_GROUP
          }))
        ];
      },
      query: ({
        projection = { id: true, name: true },
        page = 1,
        limit = 10,
        name,
        ...args
      }) => {
        const __args: Partial<GetAnalystQuestionGroupsQuery> = {
          page,
          limit,
          ...args
        };

        if (name) {
          __args.name = `*${name}*`;
        }

        if (args.business_type_id === 'null') {
          __args.business_type_id = null;
        }

        return {
          body: {
            query: {
              analyst_question_groups: {
                __args: cleanArguments(__args),
                data: projection,
                ...paginationProjection
              },
            }
          }
        };
      },
    }),
  }),
});
