/**
 * Components -> SelectBlueprint
 *
 * This component supports asynchronously searching for blueprints
 * or simply using a fixed list of blueprints passed via. the "blueprints"-prop.
 *
 * The latest blueprints will be fetched used as the initial options if disableFetchOnSearch != true.
 * When fetching is enabled, the "blueprints"-prop values will be merged with the fetched initial blueprints.
 *
 * If a "clientId"-prop is supplied, the component will automatically fetch the blueprints for that client.
 *
 * The component will automatically try to fetch the initial strategy if an inital value is specified
 * to ensure that that the actual label will render on mount and not the ID of the blueprint.
 */

import React, { ForwardRefExoticComponent, useMemo, forwardRef, useState, useEffect, useRef } from 'react';
import { fetchClientByIdQuery, novaGraphQLClient } from 'api/entityGraphQL';
import classnames from 'classnames';
import { sortBy } from 'lodash';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import { Strategy } from 'features/entitiesRedux/models/strategy';
import { formatDate } from '../../features/audits/utils';
import Select, { Props as SelectProps } from '../Select/Select';
import { Spin } from '../Spin';
import css from './SelectBlueprint.module.scss';

const FETCH_LIMIT = 30;
const PROJECTION: { [x: string]: boolean; id: true; } = {
  id: true,
  name: true,
  created_at: true,
};

  type Props = SelectProps & {
    blueprints?: Partial<Strategy>[]
    disableFetchOnSearch?: boolean,
    clientId?: number;
  };

const SelectBlueprint: ForwardRefExoticComponent<Props> = forwardRef(function SelectBlueprint({
  blueprints: blueprintsProp,
  className,
  disableFetchOnSearch = false,
  clientId,
  skeleton: skeletonProp,
  value,
  ...props
}, ref: React.Ref<HTMLInputElement>) {
  const initialBlueprints = blueprintsProp || [];
  const initialBlueprintId = useRef<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [skeleton, setSkeleton] = useState<boolean>(false);
  const [cachedInitialBlueprints, setCachedInitialBlueprints] = useState<Partial<Strategy>[]>(initialBlueprints);
  const [allOptions, setOptions] = useState<Partial<Strategy>[]>(initialBlueprints);

  // Filter out undefined options and remove potential duplicates
  const options = useMemo(() =>
    sortBy(uniqBy(allOptions.filter((option) => option?.id !== undefined), 'id'), 'name')
  , [allOptions]);

  // Enable async search if it's not explicitly disabled or if we only want to fetch options for a given client
  const enableAsyncSearch = disableFetchOnSearch === false || !clientId;

  // Fetch blueprint options for a given client ID
  useEffect(() => {
    let isCancelled = false;
    const fetchOptionsByClientId = async (id: number) => {
      setSkeleton(true);
      const { clients } = await fetchClientByIdQuery(id, {
        projection: {
          id: true,
          strategies: {
            name: true,
            id: true,
            monthly_gross_profit: true,
            created_at: true,
          }
        }});
      const blueprints = clients[0]?.strategies || [];

      if (!isCancelled) {
        setOptions(blueprints);
        setSkeleton(false);
      }
    };

    if (clientId) {
      fetchOptionsByClientId(clientId);
    }

    return () => {
      isCancelled = true;
    };
  }, [clientId]);

  // Fetch initial blueprint if we have a value on mount
  useEffect(() => {
    let isCancelled = false;
    const fetchInitialBlueprint = async () => {
      setSkeleton(true);
      const { strategies: [initialBlueprint] } = await novaGraphQLClient.fetchStrategyById(value, { projection: PROJECTION });
      if (initialBlueprint && !isCancelled) {
        setCachedInitialBlueprints(curr => [...curr, initialBlueprint]);
        setOptions(curr => [...curr, initialBlueprint]);
      }
      setSkeleton(false);
    };

    // We want to fetch the initial blueprint on mount
    // or if the initial value/id changes and we don't already have that blueprint data
    if (
      value &&
       initialBlueprintId.current !== value &&
       !cachedInitialBlueprints.find(a => a.id === value) &&
       !options.find(a => a.id === value)) {
      fetchInitialBlueprint();
    }

    initialBlueprintId.current = value;

    return () => {
      isCancelled = true;
    };
  }, [value]);

  // Sync internal blueprints state if blueprints prop changes
  useEffect(() => {
    if (Array.isArray(blueprintsProp)) {
      setOptions(curr => [...curr, ...blueprintsProp]);
      setCachedInitialBlueprints(curr => [...curr, ...blueprintsProp]);
    }
  }, [blueprintsProp]);

  // Fetching blueprints manually outside the redux state
  // because we want to avoid triggering the global loading state
  // which can cause some issues in some parts of the application currently
  const fetchBlueprints: (name?: string | undefined) => Promise<Partial<Strategy>[] | undefined> = async (name?: string) => {
    // We don't want to re-fetch the initial blueprints
    // if the search field is cleared and we have cached initial results
    if (!name && cachedInitialBlueprints.length) {
      setOptions(cachedInitialBlueprints);
      setLoading(false);
      return;
    }

    setLoading(true);

    const response: { strategies: Partial<Strategy>[] } = await novaGraphQLClient.fetchStrategies({
      filter: {
        // Fuzzy search by name
        name: name ? `*${String(name).replace(/\s+/g, '*')}*` : undefined,
      },
      pagination: {
        limit: FETCH_LIMIT,
        page: 1,
      },
      projection: PROJECTION,
    });

    // When search query is empty: Merge initial blueprints (if passed down via. the blueprints-prop)
    // with the blueprints we get from the response (this would typically be the latest blueprints)
    // so we have some initial results for the user to choose from
    const nextBlueprints: Partial<Strategy>[] = !name ? [...initialBlueprints, ...response.strategies] : response.strategies;

    setOptions(nextBlueprints);
    setLoading(false);

    return nextBlueprints;
  };

  // Fetch the initial strategies on load if needed
  useEffect(() => {
    let isCancelled = false;
    if (enableAsyncSearch) {
      (async () => {
        const initialResults = await fetchBlueprints();

        if (!isCancelled && Array.isArray(initialResults)) {
          setOptions(initialResults);

          // Cache initial results so we don't need to fetch them again if the user clears the search field
          setCachedInitialBlueprints(curr => [...curr, ...initialResults]);
        }
      })();
    }

    return () => {
      isCancelled = true;
    };
  }, [disableFetchOnSearch]);

  // Asynchronously fetch strategies when the user types in the search field if disableFetchOnSearch != true
  const handleSearch = debounce(fetchBlueprints, 200);

  // Nothing found or loading content
  const renderContent = (menu: any) => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
    if (loading) {
      return <Spin size="small" />;
    }

    if (!options.length) {
      return <div className={css.nothingFound}>No blueprints found</div>;
    }

    return menu;
  };

  const renderOptions = useMemo(() => {
    return (options as (Partial<Strategy> & { id: number })[])
      .map((option) => (
        <Select.Option key={option?.id} value={option?.id}>
          {option?.name}
          <small className={css.muted}>
            {`$${option?.monthly_gross_profit}, ${formatDate(option?.created_at)}`}
          </small>
        </Select.Option>
      ));
  }, [options]);

  return (
    <Select
      className={classnames(css.root, className)}
      block
      value={value}
      loading={loading}
      skeleton={skeletonProp || skeleton}
      dropdownRender={renderContent}
      onSearch={enableAsyncSearch ? handleSearch : undefined}
      optionFilterProp="name"
      filterOption={disableFetchOnSearch ? (input, option) => option?.name?.toLowerCase().includes(input.toLowerCase()) : false}
      ref={ref}
      placeholder="Select blueprint"
      showSearch
      {...props}
    >
      {renderOptions}
    </Select>
  );
});

export default SelectBlueprint;
