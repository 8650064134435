import React, { memo, useCallback, useEffect } from 'react';
import { Typography, MonetaryField, Textarea } from '@sprnova/nebula';
import classNames from 'classnames';
import { TextEditor } from 'components/TextEditor';
import { maxCharactersSnippetSummary } from '../constants';
import { ActionType, FormDataType } from '../packageStrategyTypes';
import css from '../package-strategies.module.scss';

type FixedPricingContractDetailsProps = {
  isSubmitted: boolean;
  dispatch: React.Dispatch<any>;
  formData: Partial<FormDataType>;
}

const FixedPricingContractDetails = ({ isSubmitted, dispatch, formData }: FixedPricingContractDetailsProps): JSX.Element => {
  const [fixedPrice, setFixedPrice] = React.useState<number>();

  // initialize form data with default fixed pricing values
  useEffect(() => {
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_SINGLE_TIER, payload: {
      price: undefined,
      snippet: '',
    }});
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_DATA, payload: { snippet_summary: '' }});
  }, [dispatch]);

  const handleFixedPriceChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFixedPrice(event.target.value as unknown as number);
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_SINGLE_TIER, payload: {
      price: Number.parseFloat(event.target.value)
    }});
  }, [dispatch]);

  return (
    <>
      <MonetaryField
        id="monetaryFieldFixedPrice"
        helperText="Price of this fixed strategy"
        label="Price"
        error={!formData?.pricing_tiers?.[0]?.price && isSubmitted}
        sx={{ marginBottom: '24px', marginTop: '24px' }}
        value={fixedPrice}
        onChange={(event): void => handleFixedPriceChange(event)}
      />
      <Textarea
        id="snippetSummaryField"
        error={formData?.snippet_summary && formData?.snippet_summary.length > maxCharactersSnippetSummary && isSubmitted}
        placeholder="This will appear in a tooltip when a user is adding this strategy to a Blueprint"
        label="Snippet summary"
        maxCharacters={maxCharactersSnippetSummary}
        minRows={2}
        resizeable
        sx={{ marginBottom: '24px' }}
        value={formData?.snippet_summary}
        onChange={(event): void => dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_DATA, payload: { snippet_summary: event.target.value } })}
      />
      <Typography sx={{ fontWeight: 600 }} display="block" gutterBottom>
        Proposal Snippet
      </Typography>
      <TextEditor
        className={classNames(css.text_editor, !formData?.pricing_tiers?.[0]?.snippet && isSubmitted ? css.snippet_error : undefined)}
        onChange={(snippetHtml): void => {
          dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_SINGLE_TIER, payload: { snippet: snippetHtml }});
        }}
      />
    </>
  );
};

export default memo(FixedPricingContractDetails);
