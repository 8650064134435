import { NovaAISessionType } from 'features/entitiesRedux/models/nova_ai';

const filterSession = (sessions: NovaAISessionType[], currentSession: NovaAISessionType): NovaAISessionType[] => {
  // Filter out session passed to function from session list.
  const filteredSessions = sessions.filter((session) => session.guid !== currentSession.guid);

  return filteredSessions;
};

export default filterSession;
