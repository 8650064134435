import React, { FC } from 'react';
import { Props } from 'components/Select/Select';
import moment from 'moment';
import { HorizonModelClient } from 'features/entitiesRedux/models/horizon';
import { Select } from 'components';

type SelectHorizonModelProps = Props & {
  models: HorizonModelClient[] | undefined;
  isLoading?: boolean;
}

const SelectHorizonModels: FC<SelectHorizonModelProps> = ({models, isLoading, ...props}): JSX.Element => {

  const formattedValues = models?.map((modelClient: HorizonModelClient) => {
    return {
      id: modelClient?.id,
      label: `${modelClient?.version ? `V${modelClient?.version}` : 'V0'}
      ${modelClient?.created_at ? `- Released ${moment(modelClient?.created_at).format('M/D/YYYY')}` : '' }`,
      value: modelClient?.id
    };
  });

  return (
    <Select {...props} options={formattedValues} skeleton={isLoading} allowClear />
  );
};

export default SelectHorizonModels;
