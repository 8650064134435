import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { notification, Table } from '../../../../../components';
import { SalesforceIcon } from '../../../../../components/Icon/svg/SalesforceIcon';
import { createStrategyProposal } from '../../../../entitiesRedux';
import css from './SalesforceStageSyncModal.module.scss';

interface Props {
  strategyId: number;
  visible: boolean;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel: () => void;
  opportunityName: string;
}

const SalesforceStageSyncModal = ({
  strategyId,
  visible,
  setVisible,
  onCancel,
  opportunityName,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleUpdateSalesforce = async () => {
    setIsSubmitting(true);
    try {
      const resultAction: any = await dispatch(createStrategyProposal(
        { id: strategyId, update_salesforce_stage: 1 }
      ));

      if (createStrategyProposal.fulfilled.match(resultAction)) {
        notification.success({ message: 'Salesforce opportunity stage changed to "Blueprint"' });
        setVisible && setVisible(false);
      }
      if (createStrategyProposal.rejected.match(resultAction)) {
        console.error('Error updating Salesforce opportunity');
        notification.error({ message: 'Error updating Salesforce opportunity stage' });
      }
    } catch(error) {
      console.error('Error updating Salesforce opportunity', error);
    }
    setIsSubmitting(false);
  };

  const dataSource = [
    {
      key: '1',
      field: 'Stage',
      from: 'Appraisal',
      to: 'Blueprint',
    },
  ];

  const columns = [
    { title: 'Field', dataIndex: 'field', key: 'field' },
    { title: 'From', dataIndex: 'from', key: 'from' },
    { title: 'To', dataIndex: 'to', key: 'to' },
  ];

  return (
    <Modal
      title={
        <div className={css.modalHeader}>
          <div className={css.icon}>
            <SalesforceIcon />
          </div>
          <div className={css.titleContainer}>
            <div className={css.title}>Update Salesforce Opportunity Stage</div>
            <div className={css.subtitle}>Opportunity name: {opportunityName}</div>
          </div>
        </div>
      }
      visible={visible}
      onOk={handleUpdateSalesforce}
      okText={isSubmitting ? 'Please wait...' : 'Confirm and sync'}
      okButtonProps={{ disabled: isSubmitting }}
      onCancel={onCancel}
      cancelButtonProps={{ disabled: isSubmitting }}
      width={800}
      className={css.modal}
      maskClosable={false}
    >
      <div>
        <p className={css.modalInfo}>
          Generating a new proposal automatically changes the Salesforce Opportunity stage to <strong>Blueprint</strong>:
        </p>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </div>
    </Modal>
  );
};

export default SalesforceStageSyncModal;
