import { createAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

/**
 * Types
 */
interface StrategiesState {
  strategyPage: {
    keepSalesforceOpportunityOriginalVersion: boolean;
    blueprintNewVersionNotes: string | undefined;
    isLoading: boolean;
    isCreating: boolean;
    isUpdating: boolean;
    error: string | undefined;
  };
}
/**
 * Initial Strategies State
 */
export const strategiesInitialState: StrategiesState = {
  strategyPage: {
    blueprintNewVersionNotes: undefined,
    error: undefined,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    keepSalesforceOpportunityOriginalVersion: false,
  },
};

/**
 * Actions
 */

export const setBlueprintNewVersionNotes = createAction(
  'strategies/setBlueprintNewVersionNotes',
  function prepare(notes) {
    return {
      payload: { notes },
    };
  }
);

export const setKeepSalesforceOpportunityOriginalVersion = createAction(
  'strategies/setKeepSalesforceOpportunityOriginalVersion',
  function prepare(keepSalesforceOpportunityOriginalVersion) {
    return {
      payload: { keepSalesforceOpportunityOriginalVersion },
    };
  }
);

/**
 * Async Actions
 */

//

/**
 * Reducer
 */
export const strategiesSlice = createSlice({
  name: 'strategies',
  initialState: strategiesInitialState,
  reducers: {
    /** Set the notes for the blueprint version  */
    setBlueprintNewVersionNotes: (state, action) => {
      state.strategyPage.blueprintNewVersionNotes = action.payload.notes;
    },

    /** Set whether or not to keep the salesforce opportunity linked to the original BP version */
    setKeepSalesforceOpportunityOriginalVersion: (state, action) => {
      state.strategyPage.keepSalesforceOpportunityOriginalVersion = action.payload.keepSalesforceOpportunityOriginalVersion;
    },
  },
});

/**
 * Selectors
 */
export const selectBlueprintNewVersionNotes = (state: RootState) => {
  return state.strategies.strategyPage.blueprintNewVersionNotes;
};

export const selectKeepSalesforceOpportunityOriginalVersion = (state: RootState) => {
  return state.strategies.strategyPage.keepSalesforceOpportunityOriginalVersion;
};


/**
 * Export
 */
export default strategiesSlice.reducer;
