import React, { Fragment, memo, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { ArrowRightIcon, Autocomplete, Box, Button, Card, CardActions, CardHeader, Controller, DatePicker, Divider, FilterIcon, Radio, Select, Stack, Switch, Typography } from '@sprnova/nebula';
import { useGetClientsQuery } from 'api/crudGraphQL/clients/getClients';
import { useGetPlaybookPlatformsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookPlatforms';
import { useGetIndustriesQuery } from 'api/crudGraphQL/industries/getIndustries';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { useFormWithQueryParams } from 'components/nebula/Form/useFormWithQueryParams';
import VirtualizedListBox from 'components/VirtualizedAutocomplete/VirtualizedListBox';
import { format } from 'date-fns';
import { isEmpty, isNil, omitBy } from 'lodash';
import pluralize from 'pluralize';
import { BooleanParam, DateParam, JsonParam, StringParam, useQueryParams } from 'use-query-params';
import { Client, Role } from 'features/entitiesRedux';
import { PlaybookPlatform } from 'features/entitiesRedux/models/creative_playbook';
import { useAccount } from 'features/global';
import { countUniqueFilters } from '../../CreativeAffinity/utils/countUniqueFilters';
import { useFilterContext } from '../Filters/Filters';
import FiltersDrawer from '../FiltersDrawer/FiltersDrawer';

interface Platform {
  id: number;
  label: string;
}

interface FilterProps {
  activeTab?: number | null;
  activeTabName?: string;
  setActiveTab?: React.Dispatch<React.SetStateAction<number | undefined | null>>;
  error?: JSX.Element;
}
const Filter = ({ activeTab, setActiveTab, activeTabName, error }: FilterProps): JSX.Element => {
  const [queryParams] = useQueryParams({
    type: StringParam,
    period: StringParam,
  });
  const [type, setType] = useState<string>(queryParams.type || 'clients');
  const [period, setPeriod] = useState<string>(queryParams.period || 'time');
  const [drawerOpen, toggleDrawer] = useReducer((state: boolean) => !state, false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const { filter, setFilter } = useFilterContext();
  const boxStyles = {
    justifyContent: 'space-between',
    flexGrow: 1,
    sx: {
      width: '33%',
      // remove width on smaller screens
      '@media (max-width: 1199px)': {
        width: '100%',
      },
    }
  };
  /**
   * Mixpanel event tracking
   */
  const mixpanel = useMixpanel();
  const { account } = useAccount();

  const defaultValues = useMemo(() => {
    let platformIds = [
      {
        id: 1001,
        label: 'TikTok'
      },
      {
        id: 1002,
        label: 'Meta',
      },
    ];
    if (account?.roles.includes('external-provider-verification' as Role)) {
      // The default platform for external-provider-verification users should only be Meta
      platformIds = platformIds.filter((platform) => platform.label === 'Meta');
    }
    return {
      type,
      client_ids: undefined,
      industry_id: undefined,
      period,
      time_period: 'Last 7 days',
      start_date: undefined,
      end_date: undefined,
      platform_ids: platformIds,
      exclude_without_ltv: true,
    };
  }, [account?.roles, period, type]);

  const numberOfFilters = useMemo(() => {
    return countUniqueFilters(defaultValues, filter);
  }, [defaultValues, filter]);

  const { control, getValues, watch, formState: { errors } } = useFormWithQueryParams({
    defaultValues,
    queryParamsConfig: {
      type: StringParam,
      client_ids: JsonParam,
      industry_id: JsonParam,
      period: JsonParam,
      time_period: StringParam,
      start_date: DateParam,
      end_date: DateParam,
      platform_ids: JsonParam,
      exclude_without_ltv: BooleanParam,
    }
  });

  const startDate = getValues('start_date');
  const endDate = getValues('end_date');

  const { data: getClientsData, isLoading: clientsIsLoading } = useGetClientsQuery({
    account_status_ids: [
      1001, // Prospect
      1003  // Active
    ],
    // Only show clients with playbook campaigns
    has_playbook_campaigns: 1,
    limit: -1,
    projection: {
      id: true,
      name: true,
    }
  });

  const { data: industriesRequest } = useGetIndustriesQuery({
    projection: { id: true, name: true },
  });

  const { data: platformsRequest, isLoading: platformsIsLoading } = useGetPlaybookPlatformsQuery({
    projection: { id: true, name: true },
  });

  const clients = useMemo(() => (
    getClientsData?.data?.map((client) => ({
      label: client.name,
      id: client.id,
      value: client.id,
    })) || []
  ), [getClientsData?.data]);

  const industries = useMemo(() => {
    if (industriesRequest) {
      return industriesRequest.map(option => ({ id: option.id, label: option.name, value: option.id }));
    }
    return [];
  }, [industriesRequest]);

  const platforms = useMemo(() => {
    if (platformsRequest) {
      return platformsRequest.map((platform: PlaybookPlatform) => ({ id: platform.id, label: platform.name }));
    }
    return [];
  }, [platformsRequest]);

  const disabledCheck = useCallback((values?: Record<string, unknown>) => {
    const { type, period, client_ids, industry_id, time_period, start_date, end_date, platform_ids } = values || getValues() || {};
    // Check if at least one client or industry is selected
    const hasClientOrIndustry = type === 'clients' && client_ids?.length > 0
      || type === 'industry' && industry_id > 0;
    // Check if time period or start/end dates are set
    const hasTimePeriodOrDates = period === 'time' && !!time_period
      || period === 'range' && (!!start_date || !!end_date);
    // Check if at least one platform is selected
    const hasPlatform = platform_ids?.length > 0;
    setDisabled(!hasClientOrIndustry || !hasTimePeriodOrDates || !hasPlatform);
  }, [getValues]);

  useEffect(() => {
    // initial disabled check
    disabledCheck();
    // set type and period from query params
    const { type, period } = getValues();
    setType(type);
    setPeriod(period);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // watch for changes in type and period
    // useFormWithQueryParams should also handle the radios so the query params are synced
    const subscription = watch((values) => {
      const { period, type } = values;
      setPeriod(period);
      setType(type);
      // handle disabled check
      disabledCheck(values);
    });
    return (): void => subscription.unsubscribe();
  }, [disabledCheck, watch]);

  const trackFilterApplied = useCallback((filters: Record<string, unknown>) => {
    try {
      if (!mixpanel || isEmpty(filter)) return;
      const mixpanelTitle = 'Creative Affinity - Filter Applied';

      const options = omitBy({
        userId: account?.id,
        userName: account?.name,
        ...filter,
        ...filters,
        platform_names: (filters.platform_ids as Platform[])?.map((platform: Platform) => platform.label)
      }, isNil);

      mixpanel.track(mixpanelTitle, options);
      if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
    } catch (error) {
      console.error('Track Mixpanel error', error);
    }
  }, [account, filter, mixpanel]);

  const handleSubmit = useCallback(() => {
    const { client_ids, industry_id, time_period, start_date, end_date, platform_ids, exclude_without_ltv, sort } = getValues();
    const clients = client_ids?.map((client: Client) => client.id);
    const formatStr = 'yyyy-MM-dd';
    setFilter?.({
      type: 'add',
      value: {
        start_date: period === 'range' && start_date
          ? format(start_date, formatStr)
          : undefined,
        end_date: period === 'range' && end_date
          ? format(end_date, formatStr)
          : undefined,
        time_period: period === 'time' ? time_period : undefined,
        client_ids: type === 'clients' ? clients : undefined,
        industry_id: type === 'industry' ? industry_id : undefined,
        platform_ids: platform_ids.map((platform: PlaybookPlatform) => platform.id),
        exclude_without_ltv: exclude_without_ltv ? 1 : 0,
        sort: sort ?? '-ltv',
      }
    });
    if (!activeTab) setActiveTab?.(3);
    trackFilterApplied(getValues());
  }, [activeTab, getValues, period, setActiveTab, setFilter, trackFilterApplied, type]);

  const renderType = useMemo(() => {
    if (type === 'clients') {
      return (
        <Controller
          control={control}
          name='client_ids'
          render={({ field: { onChange, ...field } }) => (
            <Autocomplete
              {...field}
              disableCloseOnSelect
              multiple
              id='client_ids-select'
              label='Client(s)'
              limitTags={2}
              onChange={(event, newValue) => {
                if (newValue.length > 5) {
                  newValue.pop();
                }
                onChange(newValue);
              }}
              options={clients}
              skeleton={clientsIsLoading}
              ListboxComponent={VirtualizedListBox}
              helperText='Select up to 5'
              error={errors?.['client_ids']?.message}
            />
          )}
        />
      );
    }
    if (type === 'industry') {
      return (
        <Controller
          control={control}
          name='industry_id'
          render={({ field }) => (
            <Select
              {...field}
              id='industry_id-select'
              label='Industry'
              error={errors?.['industry_id']?.message}
            >
              {industries.map((option) => (
                <Select.Item value={option.value} key={`creative-affinity-filter-${option.value}`}>{option.label}</Select.Item>
              ))}
            </Select>
          )}
        />
      );
    }
  }, [clients, clientsIsLoading, control, errors, industries, type]);

  const renderPeriod = useMemo(() => {
    if (period === 'time') {
      return (
        <Controller
          control={control}
          name='time_period'
          render={({ field }) => (
            <Select
              {...field}
              id='time_period-select'
              label='Time Period'
              error={errors?.['time_period']?.message}
            >
              {[
                'Last 7 days',
                'Last 14 days',
                'Last 30 days',
                'Last Quarter',
                'Last Year',
                'All data'
              ].map((option) => (
                <Select.Item value={option} key={`creative-affinity-filter-${option}`}>{option}</Select.Item>
              ))}
            </Select>
          )}
        />
      );
    }
    if (period === 'range') {
      return (
        <Stack direction='row' spacing={2} flexGrow={1}>
          <Controller
            control={control}
            name='start_date'
            render={({ field }) => (
              <DatePicker
                {...field}
                id='start-date-select'
                label='Start Date'
                disableFuture
                maxDate={endDate}
                slotProps={{
                  actionBar: {
                    actions: ['clear']
                  }
                }}
              />
            )}
          />
          <Controller
            control={control}
            name='end_date'
            render={({ field }) => (
              <DatePicker
                {...field}
                id='end-date-select'
                label='End Date'
                minDate={startDate}
                slotProps={{
                  actionBar: {
                    actions: ['clear']
                  }
                }}
              />
            )}
          />
        </Stack>
      );
    }
  }, [period, control, errors, endDate, startDate]);

  const renderSubmitButton = useMemo(() => {
    return (
      <Button
        variant='primary'
        onClick={handleSubmit}
        endIcon={!activeTab && <ArrowRightIcon />}
        disabled={disabled}
        size='large'
      >
        {
          activeTab
            ? 'Apply Changes'
            : 'Get Started'
        }
      </Button>
    );
  }, [activeTab, handleSubmit, disabled]);

  return (
    <Card
      header={
        <CardHeader
          title='Filter by'
          end={
            <CardActions
              actions={[
                <Typography key='additional' sx={{ fontStyle: 'italic' }}>
                  {
                    activeTab && (
                      <div>
                        {
                          numberOfFilters > 0 && `${numberOfFilters} additional ${pluralize('filters', numberOfFilters)} applied`
                        }
                      </div>
                    )
                  }
                </Typography>,
                <Fragment key='filter-action-1'>
                  {
                    !activeTab
                      ? (
                        <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
                          All fields below are required.
                        </Typography>
                      )
                      : (
                        <Fragment key='filter-action-container'>
                          {
                            typeof activeTabName === 'string' && (
                              <FiltersDrawer
                                activeTab={activeTabName}
                                open={drawerOpen}
                                toggle={toggleDrawer}
                                trackFilterApplied={trackFilterApplied}
                              />
                            )
                          }
                          <Button
                            startIcon={<FilterIcon />}
                            variant='tertiary'
                            onClick={toggleDrawer}
                          >
                            More Filters
                          </Button>
                        </Fragment>
                      )
                  }
                </Fragment>
              ]}
            />
          }
        />
      }
    >
      <Stack
        direction={{ xs: 'column', lg: 'row'}}
        divider={<Divider orientation="vertical" flexItem />}
        justifyContent='space-between'
        spacing={2}
        useFlexGap
        alignItems='stretch'
      >
        <Box {...boxStyles}>
          <Controller
            control={control}
            name='type'
            render={({ field: { onChange, ...field } }) => (
              <Radio.Group
                row
                id='type-radio'
                {...field}
                sx={{ mb: '20px' }}
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
              >
                <Radio label='Client(s)' value='clients' />
                <Radio label='Industry' value='industry' />
              </Radio.Group>
            )}
          />
          {renderType}
        </Box>
        <Box {...boxStyles}>
          <Stack>
            <Controller
              control={control}
              name='period'
              render={({ field: { onChange, ...field } }) => (
                <Radio.Group
                  {...field}
                  row
                  id='type-radio'
                  sx={{ mb: '20px' }}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                >
                  <Radio label='Time Period' value='time' />
                  <Radio label='Custom Range' value='range' />
                </Radio.Group>
              )}
            />
            {renderPeriod}
          </Stack>
        </Box>
        <Box display='flex' alignItems='flex-end' {...boxStyles}>
          <Controller
            control={control}
            name='platform_ids'
            render={({ field: { onChange, ...field } }) => (
              <Autocomplete
                {...field}
                disableCloseOnSelect
                multiple
                id='platform_ids-select'
                label='Ad Platform'
                limitTags={2}
                onChange={(event, newValue) => onChange(newValue)}
                options={platforms}
                skeleton={platformsIsLoading}
                sx={{
                  width: '-webkit-fill-available',
                }}
                helperText={
                  type === 'clients'
                    && <Box sx={{ visibility: 'hidden' }}>hidden</Box>
                }
                error={errors?.['platform_ids']?.message}
              />
            )}
          />
        </Box>
      </Stack>
      <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ mt: '30px' }}>
        <Box>
          <Controller
            control={control}
            name='exclude_without_ltv'
            render={({ field: { value, ...field } }) => (
              <Switch
                {...field}
                id='exclude-without-ltv-switch'
                labelLeft='Exclude Campaigns, Ad Sets, and Ads with unavailable LTVs'
                checked={value}
              />
            )}
          />
        </Box>
        <Box>
          {renderSubmitButton}
        </Box>
      </Stack>
      {
        error && (
          <Box>
            {error}
          </Box>
        )
      }
    </Card>
  );
};

export default memo(Filter);
