/**
 * useTasks
 * Fetches all tasks
 */

import { useState, useEffect, useMemo } from 'react';
import { Projection, novaGraphQLClient } from 'api/entityGraphQL';
import {FetchTasksFilter, Task} from 'features/entitiesRedux';

type ReturnType = {
  loading: boolean;
  error: string;
  tasks: Task[];
};

export const useTasks: ({pricingVersion}: { pricingVersion?: string, projection?: Projection}) => ReturnType = ( data  ) => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const defaultProjection = useMemo(() => {
    return {
      name: true,
      id: true,
      hours: true,
      service: {
        id: true,
        name: true,
        department: { id: true, name: true },
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { tasks } = await novaGraphQLClient.fetchTasks(  {
          pricing_version: data.pricingVersion || 'hourly',
          projection: data.projection ? data.projection : defaultProjection
        });
        setError('');
        setTasks(tasks);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error?.response?.errors[0]?.message || 'Unknown error');
      }
    })();
  }, [data.pricingVersion, data.projection, defaultProjection]);

  return { tasks, loading, error };
};

export default useTasks;
