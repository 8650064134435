/**
 * SelectTier
 */

import React, { forwardRef } from 'react';
import { useTiers } from 'utils';
import { Tier } from 'features/entitiesRedux';
import Select, { Props as SelectProps } from '../Select/Select';

const SelectTier = forwardRef(({ skeleton, ...props }: SelectProps, ref: any) => {
  const { tiers, loading } = useTiers();

  return (
    <Select
      showSearch
      optionFilterProp='children'
      skeleton={loading || skeleton}
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      {...props}
    >
      {tiers.map((tier: Tier) => (
        <Select.Option key={tier.id} value={tier.id}>
          {tier.name}
        </Select.Option>
      ))}
    </Select>
  );
});

SelectTier.displayName = 'SelectTier';
export default SelectTier;
