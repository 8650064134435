/**
* Get analyst survey question by URL param
* and expose useful utility methods
 */

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { 
  GetAnalystQuestionResult, 
  useGetAnalystQuestionQuery, 
  GET_ANALYST_QUESTION_ENDPOINT_KEY, 
  util 
} from 'api/crudGraphQL/analyst_surveys/getAnalystQuestion';
import { AnalystQuestion } from 'api/crudGraphQL/analyst_surveys/types';
import { Projection } from 'api/entityGraphQL';
import { questionProjection } from '../constants';
import { useGetAnalystSurveyQuestionGroupByUrlParam } from './useGetAnalystSurveyQuestionGroupByUrlParam';

export type UseGetAnalystSurveyQuestionByUrlParam = (args?: {
  refetchOnMountOrArgChange?: boolean;
}) => {
  // Utils
  updateQuestionCache: (analystQuestion: Partial<AnalystQuestion>) => void;
  questionId: number;
  questionProjection: Projection;
  questionArguments: {
    id: number;
    projection: Projection;
  }

  // RTK Query
  isLoading: boolean;
  isFetching: boolean;
  data?: GetAnalystQuestionResult;
}

export const useGetAnalystSurveyQuestionByUrlParam: UseGetAnalystSurveyQuestionByUrlParam = ({ refetchOnMountOrArgChange } = {}) => {
  const dispatch = useDispatch();
  const { questionGroupId } = useGetAnalystSurveyQuestionGroupByUrlParam();

  const questionId = Number( useParams<{ [x: string]: string }>().question_id ?? 0);
  const questionArguments = {
    id: questionId,
    projection: questionProjection,
  };

  //  Util: Update the question cache in redux for this request
  const updateQuestionCache = (data: Partial<AnalystQuestion>) => dispatch(
    util.updateQueryData(GET_ANALYST_QUESTION_ENDPOINT_KEY, {
      ...questionArguments,
      id: data.id ?? questionArguments.id, // Allow updating question based on the passed ID instead of the URL param ID
    }, (draft) => {
      Object.assign(draft, data);
      return draft;
    })
  );

  return {
    questionId,
    questionArguments,
    questionProjection,
    updateQuestionCache,

    // RTK Query
    ...useGetAnalystQuestionQuery(questionArguments, {
      skip: !questionGroupId || !questionId,
      refetchOnMountOrArgChange,
    })
  };
};
