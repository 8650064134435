/**
 * Services -> Filters
 */

import React from 'react';
import { Form } from 'antd';
import { NumberParam, StringParam } from 'use-query-params';
import { LABELS_TYPES } from 'utils';
import { FetchServicesFilter } from 'features/entitiesRedux/models/service/service';
import {
  Input,
  SelectDepartment,
  SelectBusinessType,
  Filters,
} from 'components';

type ServicesFiltersProps = {
  values: FetchServicesFilter;
  onValuesChange: (values: FetchServicesFilter) => void;
  onReset: () => void;
}

const ServicesFilters = ({
  onValuesChange,
  onReset,
  values
}: ServicesFiltersProps) => {
  const [form] = Form.useForm();

  const fields = [
    {
      label: 'Name',
      name: 'name',
      urlParameterType: StringParam,
      render: (
        <Input
          allowClear
          placeholder="Filter by name"
        />
      ),
    },
    {
      label: 'Department',
      name: 'department_id',
      urlParameterType: NumberParam,
      render: (
        <SelectDepartment
          allowClear
          placeholder="Filter by department"
        />
      ),
    },
    {
      label: LABELS_TYPES.BUSINESS_UNIT_FIRST_LETTERS_CAPITALIZED,
      name: 'business_type_id',
      urlParameterType: NumberParam,
      render: (
        <SelectBusinessType
          allowClear
          placeholder={`Filter by ${LABELS_TYPES.BUSINESS_UNIT}`}
        />
      ),
    },
  ];

  return (
    <Filters
      fields={fields}
      form={form}
      onValuesChange={onValuesChange}
      onReset={onReset}
      values={values}
      hasMargin
    />
  );
};

export default ServicesFilters;
