import React, { forwardRef, memo, MouseEventHandler } from 'react';

interface PrivacyPolicyLinkProps {
  text?: string; // Optional prop to allow custom text
  className?: string; // Optional prop to allow custom CSS classes
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const PrivacyPolicyLink: React.FC<PrivacyPolicyLinkProps> = forwardRef<
	HTMLAnchorElement,
	PrivacyPolicyLinkProps
>(({ text, className, onClick }, ref) => {
  const url =
		'https://trust-center.powerdigitalmarketing.com/doc/trust?rid=6671a6a7daa79be4542dcf89&r=ggd678n3cmyxdjwrnhbos7';
  const defaultText = 'Privacy Policy';

  return (
    <a
      onClick={onClick}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      ref={ref}
    >
      {text || defaultText}
    </a>
  );
});

PrivacyPolicyLink.displayName = 'PrivacyPolicyLink';

export default memo(PrivacyPolicyLink);
