import React, { useCallback, useMemo, memo } from 'react';
import { Select } from '@sprnova/nebula';
import { sortBy } from 'lodash';
import uniqBy from 'lodash/uniqBy';
import { formatDate } from 'features/audits/utils';
import { Audit } from 'features/entitiesRedux/models/audit';
import { Spin } from 'components';
import { ContractDetailsFieldNames, ContractDetailsKeys } from '../constants';
import css from './SelectAppraisal.module.scss';

export type SelectAppraisalProps = {
   appraisals?: Partial<Audit>[]; // all appraisals of the client
   appraisal?: Partial<Audit>; // current appraisal of the client
   error?: string;
   className?: string;
   isLoading: boolean;
   isSubmitting?: boolean;
   formControls: any;
 };

const SelectAppraisal = ({
  appraisals, // all appraisals of the client
  appraisal, // current appraisal of the client
  error,
  className,
  isLoading,
  isSubmitting = false,
  formControls,
}: SelectAppraisalProps): JSX.Element => {
  const options = useMemo(() =>
    sortBy(uniqBy(appraisals, 'id'), 'name')
  , [appraisals]);

  const renderSingleOption = useCallback((value: string | null, label: string, disabled: boolean)  => {
    return (
      [{ value, label, disabled }]
    );
  }, []);

  const renderOptions = useMemo(() => {
    if (isLoading) return renderSingleOption('loading', 'Loading...', true);
    if (options?.length === 0) return renderSingleOption('not-found', 'No appraisal found', true);
    return options.map((option) => {
      return ({
        value: option?.id,
        label: (
          <div key={option?.id}>
            {option?.name ? option?.name : 'Unnamed Appraisal'}
            <small className={css.date}>
              {formatDate(option?.created_at)}
            </small>
          </div>
        ),
      });
    });
  }, [isLoading, options, renderSingleOption]);

  return (
    <Select
      {...formControls.register(ContractDetailsKeys.AuditId)}
      id="appraisal-id"
      className={className}
      defaultValue={formControls.getValues(ContractDetailsKeys.AuditId)}
      disabled={isSubmitting}
      error={error}
      label={ContractDetailsFieldNames.Appraisal}
      options={renderOptions}
      renderValue={(value): string | undefined => {
        if (isLoading) return appraisal?.name;
        return (
          appraisals?.find((appraisal) => appraisal?.id === value)?.name
        );}}
      startAdornment={isLoading && !appraisal ? <Spin /> : null} // loading icon for UX
      value={formControls.getValues(ContractDetailsKeys.AuditId)}
    />
  );
};

SelectAppraisal.displayName = 'SelectAppraisal';
export default memo(SelectAppraisal);
