import { Projection } from 'api/entityGraphQL/types';
import { NovaAIResponseType } from 'features/entitiesRedux/models/nova_ai';
import { basePublicApi } from '../basePublicApi';
import { cleanArguments } from 'api/crudGraphQL/utils';

export type UpdateNovaAIChatPublicMutation = {
  projection?: Omit<Projection, 'id'>;
  prompt_guid: string;
  version?: number;
  feedback?: number;
  feedback_reason_id?: number;
  feedback_additional?: string;
  support_requested?: boolean;
  displayed_at?: string;
}

export type UpdateNovaAIChatPublicResult = NovaAIResponseType;

export const UPDATE_NOVA_AI_CHAT_PUBLIC_ENDPOINT_KEY = 'updateNovaAIChatPublic';

export const { useUpdateNovaAIChatPublicMutation } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [UPDATE_NOVA_AI_CHAT_PUBLIC_ENDPOINT_KEY]: build.mutation<UpdateNovaAIChatPublicResult, UpdateNovaAIChatPublicMutation>({
      transformResponse: (response: { updateNovaAIChat: UpdateNovaAIChatPublicResult }) =>  response.updateNovaAIChat,
      query: ({ projection = {response: true}, ...values }) => {
        const __args: Partial<UpdateNovaAIChatPublicMutation> = values;
        return ({
          body: {
            mutation: {
              updateNovaAIChat: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        }
        );
      }
    }),
  }),
});
