import React, { memo, useCallback, useMemo, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { Button, Container, Grid, Box } from '@sprnova/nebula';
import { Action, Resource } from 'api/accessControl';
import { PricingTier } from 'api/crudGraphQL/strategies/types';
import { useCreateTaskMutation } from 'api/crudGraphQL/tasks/createTask';
import { AccessControl } from 'components/AccessControl/AccessControl';
import { notification } from 'components/notification';
import { PageHero } from 'layouts/components';
import omit from 'lodash/omit';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import convertToAscii from 'utils/format/convertToAscii';
import useMixpanelTrack from 'utils/hooks/useMixpanelTrack';
import { useAccessControl } from 'features/global';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import { DEFINE_SCROLL_MARGIN_TOP } from 'features/strategies/constants';
import {convertSpecifiedValuesToBase64} from '../../../../api/utils';
import { AdditionalStrategy } from '../AdditionalStrategy';
import { TaskIdRequiredType } from '../AdditionalStrategy/AdditionalStrategy';
import ContractDetails from '../ContractDetails/ContractDetails';
import NavigationBox from '../NavigationBox/NavigationBox';
import { ActionType, FormDataType } from '../packageStrategyTypes';
import { getPricingTiersWithFloatValues, validateFormData } from '../packageStrategyUtil';
import StrategyOverview from '../StrategyOverview/StrategyOverview';
import css from '../package-strategies.module.scss';

const CreateStrategyPackagePage = (): JSX.Element => {
  const { can } = useAccessControl();
  const canAddLibraryStrategyPackage = can(Action.create, Resource.libraryStrategy);
  const [pricingVersionQueryParam,] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );
  const history = useHistory();
  const [formData, setFormData] = useState<Partial<FormDataType>>({
    name: '',
    service_id: undefined,
    strategy_frequency_id: undefined,
    pricing_type_id: undefined,
    pricing_tiers: [],
  });
  const mixpanel = useMixpanelTrack();

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [createPackageStrategyMutation, { isLoading: isCreatingTask }] = useCreateTaskMutation();

  const reducer = (state: any, action: any): Partial<FormDataType> => {
    switch (action.type) {
      case ActionType.ON_CHANGE_STRATEGY_OVERVIEW_DATA: {
        const newValue = action.payload;
        setFormData((previousValue) => ({ ...previousValue, ...newValue }));
        return newValue;
      }
      case ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS: {
        const newValue = action.payload;
        setFormData((previousValue) => ({ ...previousValue, pricing_tiers: newValue }));
        return newValue;
      }
      case ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_SINGLE_TIER: {
        const newValue = action.payload;
        setFormData((previousValue) => ({ ...previousValue, pricing_tiers: [{ ...previousValue?.pricing_tiers?.[0], ...newValue }] }));
        return newValue;
      }
      case ActionType.ON_CHANGE_CONTRACT_DETAILS_DATA: {
        const newValue = action.payload;
        setFormData((previousValue) => ({ ...previousValue, ...newValue }));
        return newValue;
      }
      case ActionType.ON_CHANGE_PRICING_TYPE: {
        const newValue = action.payload;
        setFormData((previousValue) => ({ ...previousValue, ...newValue, pricing_tiers: [] }));
        return newValue;
      }
      case ActionType.ON_CHANGE_ADDITIONAL_STRATEGY_DATA: {
        const newValue = action.payload;
        const childrenTasks = action.payload.map((task: TaskIdRequiredType) => {
          return (
            { id: task.id }
          );
        });
        setFormData((previousValue) => ({ ...previousValue, children: childrenTasks }));
        return newValue;
      }
      default: {
        return state;
      }
    }

  };
  const [, dispatch] = useReducer(reducer, {});

  const mixpanelTracking = useCallback((): void => {
    const options = {
      name: formData.name,
      service_id: formData.service_id,
      strategy_frequency_id: formData.strategy_frequency_id,
      pricing_tiers: formData.pricing_tiers,
      pricing_type_id: formData.pricing_type_id,
      children: formData?.children, // required when there are additional strategies to create Parent / Children task relations
      pricing_version: pricingVersionQueryParam,
    };
    const eventTitle = 'Library Strategy created';
    mixpanel(eventTitle, options);
  }, [formData?.children, formData.name, formData.pricing_tiers, formData.pricing_type_id, formData.service_id, formData.strategy_frequency_id, mixpanel, pricingVersionQueryParam]);

  const handleSubmitForm = useCallback(async () => {
    setIsSubmitted(true);
    // Remove non-required fields from the form data
    const formDataWithoutNonRequired = { ...omit(formData, 'snippet_summary')};
    const formErrorMessage = validateFormData(formDataWithoutNonRequired);
    if (formData.name && formData.service_id && formErrorMessage == null) {
      try {
        if(formData?.pricing_tiers !== undefined && formData?.pricing_tiers?.length > 0) {
          formData?.pricing_tiers.map((pricingTierItem: PricingTier) => {
            if (pricingTierItem.snippet) {
              const formattedSnippet = convertToAscii(pricingTierItem.snippet);
              if (pricingTierItem.snippet !== formattedSnippet) {
                pricingTierItem.snippet = formattedSnippet;
              }
              convertSpecifiedValuesToBase64(pricingTierItem, ['snippet']);
            }
          });
        }

        await createPackageStrategyMutation({
          name: formData.name,
          service_id: formData.service_id,
          snippet_summary: formData.snippet_summary,
          strategy_frequency_id: formData.strategy_frequency_id,
          pricing_tiers: formData.pricing_tiers ? getPricingTiersWithFloatValues(formData.pricing_tiers) : [],
          pricing_type_id: formData.pricing_type_id,
          children: formData?.children, // required when there are additional strategies to create Parent / Children task relations
          pricing_version: pricingVersionQueryParam,
          projection: {
            id: true,
            name: true,
          }
        }).unwrap();

        mixpanelTracking();
        notification.success({
          message: 'Package strategy created successfully',
        });
        setIsSubmitted(false);

        history.replace('/library/tasks?pricingVersion=package');

      } catch (e) {
        console.error('Error creating package strategy', e);
        notification.error({
          message: 'Error creating package strategy',
        });
      }
    } else {
      notification.error({
        message: formErrorMessage
      });
    }
  }, [createPackageStrategyMutation, formData, history, mixpanelTracking, pricingVersionQueryParam]);

  /**
   * Render 3 different cards:
   * - Strategy Overview
   * - Contract Details
   * - Combined Strategy
   * @returns JSX.Element Return the different cards for the create package strategy page
   */
  const renderCards = (): JSX.Element => {
    return (
      <>
        <Box id={'strategy-overview-navigation'} sx={DEFINE_SCROLL_MARGIN_TOP} >
          <StrategyOverview
            isSubmitted={isSubmitted}
            dispatch={dispatch}
            formData={formData}
          />
        </Box>
        <Box id={'contract-details-navigation'} sx={DEFINE_SCROLL_MARGIN_TOP} >
          <ContractDetails
            isSubmitted={isSubmitted}
            dispatch={dispatch}
            formData={formData}
          />
        </Box>
        <Box id={'combined-strategies-navigation'} sx={DEFINE_SCROLL_MARGIN_TOP} >
          <AdditionalStrategy
            dispatch={dispatch}
          />
        </Box>
      </>
    );
  };

  const renderActionButtons = (): JSX.Element => {
    return (
      <>
        <Grid mt={3.2} sx={{ textAlign: 'end' }}>
          <Button
            onClick={() => history.replace(`/library/tasks?pricingVersion=${pricingVersionQueryParam}`)}
            variant="secondary"
            sx={{ mr: '16px' }}
            size="large"
            className={css.button}
            disabled={isCreatingTask}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmitForm()}
            variant="primary"
            size="large"
            className={css.button}
            disabled={isCreatingTask}
          >
            Save
          </Button>
        </Grid>
      </>
    );
  };

  const pageTitle = useMemo(() => {
    if (canAddLibraryStrategyPackage) return 'Create Strategy Package';
  }, [canAddLibraryStrategyPackage]);

  return (
    <>
      <Helmet>
        <title>
          Create Strategy Package
        </title>
      </Helmet>
      <PageHero
        title={pageTitle}
      />
      <Container hasVerticalPadding>
        <AccessControl
          action={[Action.create]}
          resource={Resource.libraryStrategy}
          warning='You are not authorized to create strategies.'
        >
          <Grid container spacing={2.4} columns={15}>
            <Grid item xs={15} lg={3}>
              <Box sx={{position: 'sticky', top: '96px'}}>
                <NavigationBox />
              </Box>
            </Grid>
            <Grid item xs={15} lg={12}>
              {renderCards()}
              {renderActionButtons()}
            </Grid>
          </Grid>
        </AccessControl>
      </Container>
    </>
  );
};

export default memo(CreateStrategyPackagePage);
