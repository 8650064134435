/**
 * useAppraisalById
 * Fetches a single appraisal
 */

import { useState, useEffect } from 'react';
import { novaGraphQLClient } from 'api/entityGraphQL';
import { Audit } from 'features/entitiesRedux';

type ReturnType = {
  loading: boolean;
  error: string;
  appraisal: Partial<Audit>;
}

export const useAppraisalById = (appraisalId: number | undefined): ReturnType => {
  const [appraisal, setAppraisal] = useState<Partial<Audit>>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (appraisalId) {
          const { audits } = await novaGraphQLClient.fetchAuditById(parseInt(String(appraisalId)));
          setError('');
          setAppraisal(audits[0]);
          setLoading(false);
        }
      } catch(error) {
        setLoading(false);
        setError(error?.response?.errors[0]?.message || 'Unknown error');
      }
    })();
  }, [appraisalId]);

  return { appraisal, loading, error };
};

export default useAppraisalById;
