import { GetNovaAITierQuery, useGetNovaAITierQuery } from 'api/crudGraphQL/nova_ai/getNovaAITier';
import { GetNovaAITierPublicQuery, useGetNovaAITierPublicQuery } from 'api/crudGraphQL/public/nova_ai/getNovaAITierPublic';
import isExternalUser from 'utils/helpers/isExternalUser';
import { NovaAITierType } from 'features/entitiesRedux/models/nova_ai';

type UseNovaAITierResult = {
  aiTier?: NovaAITierType;
  isLoading: boolean;
  isFetching: boolean;
}

/**
 * Calls the appropriate endpoint to fetch a client's AI tier based on the user type.
 *
 * @param query - The query object for fetching the client's AI tier.
 * @param skip - Optional boolean to skip the query. Defaults to `false`.
 * @returns An object containing the AI Tier data.
 *
 * @example
 * const { aiTier } = useNovaAITier({client_id: 1234});
 */
export const useNovaAITier = (query: GetNovaAITierQuery | GetNovaAITierPublicQuery, skip = false): UseNovaAITierResult => {
  const isExternal = isExternalUser();

  const {
    data: aiTierInternal,
    isLoading: isLoadingInternal,
    isFetching: isFetchingInternal,
  } = useGetNovaAITierQuery(query, { skip: skip || isExternal });

  const {
    data: aiTierPublic,
    isLoading: isLoadingPublic,
    isFetching: isFetchingPublic,
  } = useGetNovaAITierPublicQuery(query, { skip: skip || !isExternal });

  const aiTier = aiTierInternal || aiTierPublic;
  const isLoading = isLoadingInternal || isLoadingPublic;
  const isFetching = isFetchingInternal || isFetchingPublic;

  return {
    aiTier,
    isLoading,
    isFetching
  };
};
