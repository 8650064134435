/**
 * SelectCrm
 */

import React, { forwardRef } from 'react';
import { CRM } from '../../features/entitiesRedux';
import { useCrms } from '../../utils';
import Select, { Props as SelectProps } from '../Select/Select';

type Props = SelectProps & {
  valueKey?: keyof CRM
}

const SelectCrm = forwardRef(({ skeleton, valueKey = 'id', ...props }: Props, ref: any) => {
  const { crms, loading } = useCrms();

  return (
    <Select
      showSearch
      allowClear
      optionFilterProp="children"
      skeleton={loading || skeleton}
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      {...props}
    >
      {crms.map((crm: CRM) => (
        <Select.Option key={crm.id} value={crm[valueKey as keyof CRM]}>
          {crm.name}
        </Select.Option>
      ))}
    </Select>
  );
});

SelectCrm.displayName = 'SelectCrm';
export default SelectCrm;
