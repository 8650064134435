import React, { FC, ReactNode, useReducer } from 'react';
import { useHistory } from 'react-router';
import {
  AppHeader,
  Dropdown,
  ActionMenu,
  HelpOutlinedIcon,
  NewWindowIcon,
  PositionerAnchorProps,
  PositionerContentProps,
} from '@sprnova/nebula';
import { FAQ_URL, HELP_ARTICLES_URL } from 'layouts/constants';
import isExternalUser from 'utils/helpers/isExternalUser';

const tutorials: [string, string, number][] = [
  ['Onboarding', '/', 191695],
  ['Appraisals', '/appraisals/1929', 192120],
  ['Blueprints', '/blueprints/', 192130],
  ['Scoreboards Overview', '/scoreboards', 194402],
  ['Scoreboard Setup', '/scoreboards/1025?product_tour_id=194413', 194413],
  ['Client Overview & Integrations', '/power-view/accounts/1563?product_tour_id=194806', 194806]
];

export const HelpDropdown: FC = () => {
  const history = useHistory();
  const [subMenuOpen, toggleSubMenu] = useReducer((state) => !state, false);

  const isClient = isExternalUser();

  const handleStartProductTour = (
    route: string,
    tourId: number,
  ): void=> {
    history.push(route);

    if ((window['Intercom'])) {
      window['Intercom']('startTour', tourId);
    }
  };

  const handleOpenIntercom = (): void => {
    if ((window['Intercom'])) {
      window['Intercom']('show');
    }
  };

  const renderAnchor = (props: PositionerAnchorProps): ReactNode => (
    <AppHeader.Button
      icon={<HelpOutlinedIcon />}
      label="Help"
      {...props}
    />
  );

  const renderContent = ({ onClose }: PositionerContentProps): ReactNode => (
    <ActionMenu onItemClick={onClose}>
      <ActionMenu.Item
        endIcon={<NewWindowIcon />}
        component="a"
        href={FAQ_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        FAQ
      </ActionMenu.Item>
      <ActionMenu.Item
        endIcon={<NewWindowIcon />}
        component="a"
        href={HELP_ARTICLES_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
				Help Articles
      </ActionMenu.Item>
      <ActionMenu.Item onClick={handleOpenIntercom}>
        Support Chat
      </ActionMenu.Item>
      {/* Commenting out for now as we may bring it back per @Alex Gordy */}
      {/* <ActionMenu.Item
        component="a"
        href="https://form.asana.com/?k=Y0Uh0wpSRgfi9S8TIfvZzA&d=22908445599079"
        target="_blank"
        rel="noopener noreferrer"
        endIcon={<NewWindowIcon />}
      >
				Submit a Support Ticket
      </ActionMenu.Item> */}
      {!isClient && (
        <>
          <ActionMenu.Item
            component="a"
            href="https://form.asana.com/?k=M3npspX0VLnvhAPrtLpt8Q&d=22908445599079"
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<NewWindowIcon />}
          >
            Submit a Feature Request
          </ActionMenu.Item>
          <ActionMenu.SubMenu
            label="Tutorials"
            open={subMenuOpen}
            onToggle={toggleSubMenu}
          >
            {tutorials.map(([name, route, tourId]) => (
              <ActionMenu.Item
                key={name}
                onClick={(): void =>  handleStartProductTour(route, tourId)}
              >
                {name}
              </ActionMenu.Item>
            ))}
          </ActionMenu.SubMenu>
          <ActionMenu.Item
            endIcon={<NewWindowIcon />}
            component="a"
            href="https://help.novapower.io/en/collections/6176284-feature-releases-internal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Feature Updates
          </ActionMenu.Item>
        </>
      )}
    </ActionMenu>
  );

  return (
    <Dropdown placement="bottom-end" anchor={renderAnchor}>
      {renderContent}
    </Dropdown>
  );
};
