import { singular } from 'pluralize';
import { PlaybookAd, PlaybookAdset, PlaybookCampaign } from 'features/entitiesRedux/models/creative_playbook';
import { CreativeAffinityValueTypes, TabNames } from './constants';
import { formatValuesBasedOnType, percentageFormatter } from './formatValuesBasedOnType';

/**
 * This function formats the data for CSV export.
 *
 * @param {PlaybookCampaign[] | PlaybookAdset[] | PlaybookAd[]} data - The data to be formatted. It can be of type PlaybookCampaign, PlaybookAdset, or PlaybookAd.
 * @param {number} activeTab - The active tab number. It can be 1 for 'Campaign', 2 for 'Adset', or 3 for 'Ad'.
 *
 * @returns {string[]} - The formatted data as an array of strings. The first element is a string of headers joined by commas. The rest of the elements are the data rows, each represented as a string joined by commas.
 */

export const formatDataForCSVExport = (data: PlaybookCampaign[] | PlaybookAdset[] | PlaybookAd[], activeTab: number, label: string) => {
  // All possible headers
  const type = singular(label);
  let headers = [
    type,
    type === 'Ad' && 'Ad Set',
    (type === 'Ad' || type === 'Ad Set') && 'Campaign',
    type === 'Ad' && 'Ad Format',
    'Client',
    'Status',
    'Industry',
    'Affinity Score',
    'LTV',
    'Ad Spend',
    'ROAS',
    'CPA',
    'Clicks',
    'Impressions',
    'Conversions',
    'Stop Rate',
    'Hold Rate',
    'CTR',
    'CVR',
    'CPC',
    'CPM',
    'Last Updated',
  ].filter(header => header);

  const rows = data.map((item) => {
    const formattedData = formatValuesBasedOnType(item, CreativeAffinityValueTypes);

    // All possible values. Columns that do not relate to all 3 possible data sets will populate as undefined to be removed later
    const values = [
      `"${item?.name}"`,
      type === 'Ad' && `"${TabNames.adset in formattedData ? formattedData?.adset?.name : undefined}"`,
      (type === 'Ad' || type === 'Ad Set') && `"${TabNames.adset in formattedData ? formattedData?.adset?.campaign?.name : undefined}"`,
      type === 'Ad' && `"${TabNames.ad in formattedData ? formattedData?.formats : undefined}"`,
      `"${'client' in formattedData ? formattedData?.client?.name : 'campaign' in formattedData ? formattedData?.campaign?.client?.name : formattedData?.adset?.campaign?.client?.name}"`,
      `"${formattedData?.status}"`,
      `"${'client' in formattedData ? formattedData?.client?.industry?.name : 'campaign' in formattedData ? formattedData?.campaign?.client?.industry?.name : formattedData?.adset?.campaign?.client?.industry?.name}"`,
      formattedData?.score ? `"${percentageFormatter(formattedData?.score * 1000)}"` : '"undefined"',
      `"${formattedData?.ltv}"`,
      `"${formattedData?.spend}"`,
      `"${formattedData?.roas}"`,
      `"${formattedData?.cpa}"`,
      `"${formattedData?.clicks}"`,
      `"${formattedData?.impressions}"`,
      `"${formattedData?.conversions}"`,
      `"${formattedData?.stop_rate}"`,
      `"${formattedData?.hold_rate}"`,
      `"${formattedData?.ctr}"`,
      `"${formattedData?.cvr}"`,
      `"${formattedData?.cpc}"`,
      `"${formattedData?.cpm}"`,
      `"${formattedData?.last_refreshed || 'undefined'}"`
    ].filter(value => value);

    return values;
  });

  // Identify columns that are undefined for all rows
  const undefinedIndices = rows[0].map((_, index) => rows.every(row => row[index] === '"undefined"'));

  // Remove columns that are undefined for all rows from headers and all rows
  headers = headers.filter((_, index) => !undefinedIndices[index]);
  const filteredRows = rows.map(row => row.filter((_, index) => !undefinedIndices[index]).join(','));

  return [headers.join(','), ...filteredRows];
};
