import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AuditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Action, Resource } from 'api/accessControl';
import { Dropdown } from 'components/Dropdown';
import { Audit } from 'features/entitiesRedux';
import { AccessControl, Button } from 'components';
import { AuditContextMenu } from '../AuditContextMenu';
import css from './AuditPageHeaderExtra.module.scss';

/**
 *
 * TODO: Refactor into global <PageHeaderExtraMenu /> component
 *
 */

interface AuditPageHeaderExtraProps {
  audit?: Audit;
  exclude?: ('overview' | 'edit')[];
  hideButtons?: ('discovery')[];
}
const AuditPageHeaderExtra = ({
  audit,
  exclude = ['edit'],
  hideButtons,
  ...props
}: AuditPageHeaderExtraProps) => {

  /** Hide dropdown overlay when a menu item is clicked */
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const handleOverlayMenuClick = () => {
    setIsDropdownVisible(false);
  };
  const handleVisibleChange = (flag: boolean) => {
    setIsDropdownVisible(flag);
  };

  return (
    <>
      {!exclude?.includes('overview') && (
        <AccessControl
          action={Action.read}
          resource={Resource.audit}
          showWarning={false}
        >
          <Link to={`/appraisals/${audit?.id}`}>
            <Button type="text" icon={<AuditOutlined />} header>
              Appraisal Overview
            </Button>
          </Link>
        </AccessControl>
      )}
      {!exclude?.includes('edit') && (
        <AccessControl
          action={Action.update}
          resource={Resource.audit}
          showWarning={false}
        >
          <Link to={`/appraisals/${audit?.id}/edit`}>
            <Button type="text" icon={<AuditOutlined />} header>
              Edit Appraisal
            </Button>
          </Link>
        </AccessControl>
      )}

      <Dropdown
        overlay={
          <AuditContextMenu
            audit={audit}
            className={css.overlay}
            hideButtons={hideButtons}
            onMenuClick={handleOverlayMenuClick}
          />
        }
        placement="bottomRight"
        arrow
        overlayClassName={css.overlayMenu}
        onVisibleChange={handleVisibleChange}
        visible={isDropdownVisible}
      >
        <Button
          type="text"
          icon={<EllipsisOutlined />}
          header
          iconOnly
        />
      </Dropdown>
    </>
  );
};

export default AuditPageHeaderExtra;
