import { RootState } from 'app/store';
import { selectEntitiesDenormalized } from '../../entitiesRedux/models/_entity/selectors';

export const selectSurveysByIds = (surveyIds: number[]) => (state: RootState) => {
  const input = { surveys: surveyIds };
  return selectEntitiesDenormalized(state, input).surveys;
};

export const selectSurveyById = (surveyId: number) => (state: RootState) => {
  return selectSurveysByIds([surveyId])(state)[0];
};
