import React, { FC, isValidElement, useMemo } from 'react';
import { Form as AntdForm } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import classNames from 'classnames';
import css from './FormItem.module.scss';

interface Props extends FormItemProps {
  className?: string;
  smallLabel?: boolean;
  boldLabel?: boolean;
  marginBottomNone?: boolean;
  hideLabel?: boolean;
  description?: React.ReactNode;
}

const FormItem: FC<Props> = ({
  children,
  className,
  description,
  help,
  hideLabel,
  marginBottomNone,
  name,
  smallLabel,
  boldLabel,
  ...props
}) => {
  const createClassName = (): string => {
    const { label } = props ?? '';
    if (typeof label === 'string') {
      return label?.replace(/\s+/g, '-').toLowerCase();
    }
    return '';
  };

  const modifiedChildren = useMemo(() => {
    if (children && Array.isArray(children)) {
      return children.map((child) => {
        /**
         * If the child is a JSX tag or an object returned by createElement,
         * then return a new React element and join a new class name to it.
         */
        if (isValidElement(child)) {
          const el = child as React.ReactElement<any>;
          const props = {
            className: classNames(el?.props?.className, createClassName())
          };
          return React.cloneElement(el, props);
        }
        // The child is a non-element React node (e.g., string, number, etc.).
        return child;
      });
    } else if (children && isValidElement(children)) {
      /**
       * The child is a JSX tag or an object returned by createElement,
       * so return a new React element and join a new class name to it.
       */
      const el = children as React.ReactElement<any>;
      const props = {
        className: classNames(el.props?.className, createClassName())
      };
      return React.cloneElement(el, props);
    }

    // There is no child or the child is a non-element React node (e.g., string, number, etc.).
    return children;
  }, [children]);

  return (
    <AntdForm.Item
      /* https://ant.design/components/form/#components-form-demo-complex-form-control */
      name={description ? undefined : name}
      help={help}
      className={classNames(
        css.root,
        { [css.smallLabel]: smallLabel },
        { [css.boldLabel]: boldLabel },
        { [css.marginBottomNone]: marginBottomNone },
        { [css.hideLabel]: hideLabel },
        className,
      )}
      {...props}
    >
      {description ? (
        <div className={css.inner}>
          <div className={css.description}>
            {description}
          </div>
          <AntdForm.Item noStyle name={name} {...props}>
            {modifiedChildren}
          </AntdForm.Item>
        </div>
      ) : modifiedChildren}
    </AntdForm.Item>
  );
};

export default FormItem;
