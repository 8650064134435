/**
 * Create strategy tasks
 */

import { Projection } from 'api/entityGraphQL';
import { Strategy, TaskInput } from 'features/entitiesRedux';
import { baseApi, cleanArguments } from '../../..';

export type CreateStrategyTasksMutation = {
  id: number;
  tasks?: Pick<TaskInput, 'id' | 'units'>[];
  projection?: Projection;
};

export type CreateStrategyTasksResult = Strategy;

export const CREATE_STRATEGY_TASKS_ENDPOINT_KEY = 'createStrategyTasks';

export const { useCreateStrategyTasksMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [CREATE_STRATEGY_TASKS_ENDPOINT_KEY]: build.mutation<CreateStrategyTasksResult, CreateStrategyTasksMutation>({
      transformResponse: (response: { createStrategyTasks: Strategy }) => response.createStrategyTasks,
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<CreateStrategyTasksMutation> = {
          ...values
        };
        return {
          body: {
            mutation: {
              createStrategyTasks: {
                __args: cleanArguments(__args),
                id: true
              }
            }
          }
        };
      }
    })
  })
});
