import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { pickBy } from 'lodash';
import { QueryParamConfigMap, useQueryParams } from 'use-query-params';

type FormValues = Record<string, unknown>;
type UseFormWithQueryParamsType = {
  defaultValues?: Partial<FormValues>;
  options?: Omit<typeof useForm, 'defaultValues'>,
  queryParamsConfig: QueryParamConfigMap;
};
/**
 * A custom hook that combines React Hook Form with query parameters handling.
 *
 * @param {Partial<Record<string, unknown>>} [props.defaultValues] - Initial values for the form.
 * @param {Object} [props.options] - Options to pass to react-hook-form's useForm.
 * @param {Object} props.queryParamsConfig - Configuration for handling query parameters.
 *
 * @returns {ReturnType<typeof useForm>} - An object containing all methods provided by react-hook-form.
 *
 * @description
 * This hook enhances the functionality of react-hook-form by automatically managing query parameters.
 * It sets up the form with initial values and handles query parameters throughout the component lifecycle.
 *
 * Key Features:
 * - Combines react-hook-form with query parameter management.
 * - Automatically loads query parameters into form values.
 * - Updates query parameters based on form changes.
 * - Handles both initial values and query parameters.
 *
 * Usage example:
 * ```
 *  const { control } = useFormWithQueryParams({
      defaultValues: {
        name: 'Name TEST'
      },
      queryParamsConfig: {
        name: StringParam,
      }
    });
    return (
      <Controller
        control={control}
        name="name"
        render={({ field }) => {
          return (
            <TextField
              id={field.name}
              {...field}
              label="Name"
              variant="outlined"
            />
          );
        }}
      />
    )
 * ```
*/
export function useFormWithQueryParams({
  defaultValues,
  options,
  queryParamsConfig,
}: UseFormWithQueryParamsType): ReturnType<typeof useForm> {
  const [queryParams, setQueryParams] = useQueryParams(queryParamsConfig);
  const formMethods = useForm({
    // Overwrite defaultValues with non-undefined query parameters
    defaultValues: { ...defaultValues, ...pickBy(queryParams) },
    ...options,
  });

  // Set query parameters from initial values if they are not already set
  useEffect(() => {
    if (
      defaultValues
      && Object.entries(queryParams).every(([_, value]) => value === undefined)
    ) {
      Object.keys(defaultValues).forEach((key) => {
        if (
          queryParamsConfig[key] // Check if key is a valid query parameter
            && !queryParams[key] // Check if query parameter is not already set
            && defaultValues[key] // Check if default value is not undefined
        ) {
          setQueryParams({ [key]: defaultValues[key] });
        }
      });
    }
  }, [defaultValues, queryParams, queryParamsConfig, setQueryParams]);

  // Load query parameters into form values
  useEffect(() => {
    Object.keys(queryParams).forEach(key => {
      if (formMethods.register(key)) {
        formMethods.setValue(key, queryParams[key]);
      }
    });
  }, [formMethods, queryParams]);

  // Set query params on form change
  useEffect(() => {
    const subscription = formMethods.watch((value, { name }) => {
      const formattedValue = value[name as string] === '' ? undefined : value[name as string];
      setQueryParams({ [name as string]: formattedValue });
    });
    return (): void => subscription.unsubscribe();
  }, [formMethods, formMethods.watch, setQueryParams]);

  return formMethods;
}
