/**
 * Entities -> Services -> Selectors
 */

import { EntityId } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { selectEntitiesDenormalized } from '../_entity/selectors';
import { servicesAdapter } from './adapter';

export const serviceSelectors = servicesAdapter.getSelectors((state: RootState) => state.entities.data.services);

export const selectServicesByIds = (serviceIds: EntityId[]) => (state: RootState) => {
  const input = { services: serviceIds };
  return selectEntitiesDenormalized(state, input).services;
};

export const selectAllServices = (state: RootState) => {
  const serviceIds = serviceSelectors.selectIds(state);
  return selectServicesByIds(serviceIds)(state);
};

export const selectServiceById = (serviceId: number) => (state: RootState) => {
  return selectServicesByIds([serviceId])(state)[0];
};
