/**
* Get strategy tasks
*/

import { baseApi, cleanArguments } from 'api/crudGraphQL';
import { Projection } from 'api/entityGraphQL';
import { Service, Task } from 'features/entitiesRedux';
import tagTypes from './tagTypes';
import { taskProjection } from './taskProjection';

export type GetTasksQuery = {
  id?: number;
  latest_version?: boolean;
  name?: string;
  service?: Service;
  pricing_version: string;
  sort?: string[];
  projection: Projection;
};

export type GetTasksResult = Task[];

export const GET_TASKS_ENDPOINT_KEY = 'getTasks';

export const { useGetTasksQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_TASKS_ENDPOINT_KEY]: build.query<GetTasksResult, GetTasksQuery>({
      transformResponse: (response: { tasks: GetTasksResult }) => response.tasks,
      providesTags: (tasks = []) => [
        tagTypes.TASKS,
        ...tasks.map(task => ({
          id: task.id,
          type: tagTypes.TASK
        }))
      ],
      query: ({
        projection = taskProjection,
        name,
        ...args
      }) => {
        const __args: Partial<GetTasksQuery> = args;

        if (name) {
          __args.name = `*${name}*`;
        }

        return {
          body: {
            query: {
              tasks: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        };
      }
    })
  })
});
