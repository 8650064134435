/**
 * NotesButton
 */

import React, { FC, useState, memo, useEffect, useCallback, useRef } from 'react';
import { FormOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { createNoteMutation } from 'api/entityGraphQL';
import classnames from 'classnames';
import { Button } from 'components/Button';
import { message } from 'components/message';
import { Popover } from 'components/Popover';
import { TextArea } from 'components/TextArea';
import { Tooltip } from 'components/Tooltip';
import { truncateString } from 'utils';
import { NoteConfig } from 'features/entitiesRedux/models/notes/notes';
import css from './NotesButton.module.scss';

export type NotesButtonProps = {
  className?: string;
  noteConfig?: Omit<NoteConfig, 'value'>;
  onUpdate?: (value: string) => void,
  value?: string;
  isLocked?: boolean;
}

export const NotesButton: FC<NotesButtonProps> = ({
  noteConfig,
  className,
  onUpdate,
  value: initialValue = '',
  isLocked = false,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [saving, setSaving] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [textareaValue, setTextareaValue] = useState(value || '');
  const hasNote = !!value;
  const handleTogglePopover = (visible: boolean) => setShowPopover(visible);
  const handleToggleTooltip = (visible: boolean) => setShowTooltip(visible);
  const handleChange: (e: any) => void = e => setTextareaValue(e.target.value);
  const handleCancel: () => void = () => {
    setShowPopover(false);
    setTextareaValue(value);
  };

  /**
   * Save note using the global notes API
   */
  const handleSaveNote = useCallback(async () => {
    if (!noteConfig) {
      return;
    }

    await createNoteMutation({
      ...noteConfig,
      value: textareaValue,
    });
  }, [noteConfig, textareaValue]);

  const handleSubmit: (e: any) => void = async e => {
    e.preventDefault();
    setSaving(true);

    try {
      if (noteConfig) {
        await handleSaveNote();
        message.success('Note saved');
      } else {
        await onUpdate?.(textareaValue);
      }
      setValue(textareaValue);
      setSaving(false);
      setShowPopover(false);
    } catch (e) {
      setSaving(false);
      message.error('Failed to save note');
    }
  };

  // Sync internal state when external value changes
  useEffect(() => {
    if (typeof value === 'string' && value !== textareaValue) {
      setTextareaValue(value);
    }
  }, [value]);

  // Focus textarea delayed on open to prevent odd popover bug
  // where it would sometimes scroll the window incorrectly
  useEffect(() => {
    if (showPopover) {
      setTimeout(() => {
        textareaRef.current?.focus();
      }, 50);
    }
  }, [showPopover]);

  // The cursor is placed in the beginning by default. This moves it to the end.
  const moveCaretAtEnd = (e: React.FocusEvent<HTMLTextAreaElement>): void => {
    const temp = e.target.value;
    e.target.value = '';
    e.target.value = temp;
  };

  const popover = (
    <form className={css.popover} onSubmit={handleSubmit}>
      <TextArea
        className={css.popover__textarea}
        onChange={handleChange}
        onFocus={moveCaretAtEnd}
        value={textareaValue}
        rows={4}
        ref={textareaRef}
      />
      <Button
        disabled={textareaValue === value || isLocked}
        htmlType="submit"
        className={css.popover__saveButton}
        type="primary"
        block
      >
        {saving ? 'Saving...' : 'Save'}
      </Button>
      <Button
        className={css.popover__cancelButton}
        onClick={handleCancel}
        type="text"
        block
        disabled={saving}
      >
        Cancel
      </Button>
    </form>
  );

  return (
    <Tooltip
      visible={showTooltip && !showPopover}
      onVisibleChange={handleToggleTooltip}
      trigger="hover"
      title={hasNote ? truncateString(value, 300) : 'Add note'}
      destroyTooltipOnHide
    >
      <Popover
        title="Notes"
        autoAdjustOverflow={false}
        trigger="click"
        content={popover}
        visible={showPopover}
        destroyTooltipOnHide
        onVisibleChange={handleTogglePopover}
      >
        <Button
          className={classnames(css.iconButton, className)}
          type="text"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          aria-label={hasNote ? 'Edit note' : 'Add note'}
          icon={hasNote ? <FormOutlined /> : <PlusCircleOutlined />}
        />
      </Popover>
    </Tooltip>
  );
};