/**
 * Update strategy
 */

import { Projection } from 'api/entityGraphQL';
import { Strategy } from 'features/entitiesRedux';
import { UpdateStrategyArgs } from './strategyArgs';
import { baseApi, cleanArguments } from '..';

export type UpdateStrategyMutation = {
  projection?: Projection;
} & UpdateStrategyArgs;

export type UpdateStrategyResult = Strategy;

export const UPDATE_STRATEGY_ENDPOINT_KEY = 'updateStrategy';

export const { useUpdateStrategyMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [UPDATE_STRATEGY_ENDPOINT_KEY]: build.mutation<UpdateStrategyResult, UpdateStrategyMutation>({
      transformResponse: (response: { updateStrategy: Strategy }) => response.updateStrategy,
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<UpdateStrategyMutation> = {
          ...values
        };
        return {
          body: {
            mutation: {
              updateStrategy: {
                __args: cleanArguments(__args),
                ...projection,
                id: true
              }
            }
          }
        };
      }
    })
  })
});
