import React, { forwardRef } from 'react';
import { useGetUsersQuery } from 'api/crudGraphQL/users/getUsers';
import classnames from 'classnames';
import { LazySelect } from 'components/LazySelect';
import { User } from 'features/entitiesRedux';
import { Props as SelectProps } from '../Select/Select';
import css from './SelectUser.module.scss';

type SelectUserProps = SelectProps & {
  disableSkeleton?: boolean;
  users?: Partial<User>[];
  filters?: Record<string, any>;
  limit?: number;
  sort?: string[];
}

const SelectUser = forwardRef(({
  className,
  mode,
  disableSkeleton = false,
  users,
  filters,
  limit,
  sort,
  ...props
}: SelectUserProps, ref: any) => {

  return (
    <LazySelect
      query={useGetUsersQuery}
      className={classnames(css.root, className)}
      values={users}
      ref={ref}
      mode={mode}
      limit={limit}
      sort={sort}
      additionalFilters={filters}
      {...props}
    />
  );
});

SelectUser.displayName = 'SelectUser';
export default SelectUser;

