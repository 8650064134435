import { Projection } from 'api/entityGraphQL/types';

export const externalClientProjection: Projection = {
  id: true,
  name: true,
  logo: true,
  is_accepted: true,
  annual_revenue: {
    id: true,
    name: true,
    slug: true
  },
  business_type: {
    id: true,
    name: true,
    slug: true
  },
  employee_size : {
    id: true,
    name: true,
    slug: true
  },
  website: true,
  industry: {
    id: true,
    name: true,
    slug: true
  },
  users: {
    avatar: true,
    email: true,
    id: true,
    is_accepted: true,
    name: true,
    title: true,
  }
};
