/**
 * useChannels
 * Fetches all channel types
 */

import { useState, useEffect } from 'react';
import { novaGraphQLClient, Projection } from 'api/entityGraphQL';
import { Channel } from '../../features/entitiesRedux';

type Type = {
  loading: boolean;
  error: string;
  channels: Channel[];
};

type UseChannelsArgs = { projection?: Projection };
export const useChannels = ({
  projection: defaultProjection,
}: UseChannelsArgs = {}): Type => {
  const [channels, setChannels] = useState<Channel[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [projection /* , setProjection */] = useState(defaultProjection);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      setLoading(true);
      try {
        const { channels } = await novaGraphQLClient.fetchChannels({ projection });
        if (!isCancelled) {
          setError('');
          setChannels(channels);
          setLoading(false);
        }
      } catch (error) {
        if (!isCancelled) {
          setLoading(false);
          setError(error?.response?.errors?.[0]?.message || 'Unknown error');
        }
      }
    })();

    return () => {
      isCancelled = true;
    };

  }, [projection]);

  return { channels, loading, error };
};

export default useChannels;
