import { Projection } from 'api/entityGraphQL';

export const horizonBudgetProjection: Projection = {
  id: true,
  value: true,
  date: true,
  funnel: {
    id: true,
    name: true
  },
  channel: {
    id: true,
    name: true,
  },
  scenario: {
    id: true,
    name: true,
  }
};

export const sameBudgetSameChannelsProjection: Omit<Projection, 'id'> = {
  month_date: true,
  budgets: {
    value: true,
    channel: {
      id: true,
      name: true,
    },
    funnel: {
      id: true,
      name: true,
    }
  }
};

export const horizonBudgetScenarioProjection: Projection = {
  id: true,
  name: true,
  is_primary: true,
  status: true,
  created_at: true,
  channels: {
    name: true,
    id: true,
  },
  model_predictions: {
    id: true,
    date: true,
    value: true,
  },
  predicted_outputs: {
    id: true,
    date: true,
    value: true,
    funnel: {
      id: true,
      name: true,
    },
    channel: {
      id: true,
      name: true,
    },
    scenario: {
      id: true,
      name: true,
    }
  },
  performances_actuals: {
    channel: {
      id: true,
      name: true,
    },
    funnel: {
      id: true,
      name: true,
    },
    roas_pct_rank: true,
    cost_pct_rank: true,
    roas: true,
    cost: true,
  },
  performances_predictions: {
    channel: {
      id: true,
      name: true,
    },
    funnel: {
      id: true,
      name: true,
    },
    cost: true,
    cost_pct_rank: true,
    roas: true,
    roas_pct_rank: true,
  },
  budgets: horizonBudgetProjection,
  same_budget_same_channels: sameBudgetSameChannelsProjection,
};

export const horizonsProjection: Projection = {
  id: true,
  name: true,
  start_date: true,
  length: true,
  updated_at: true,
  client: {
    id: true,
    name: true,
    horizon_channels: {
      id: true,
      name: true,
      type: true,
    },
    fivetran_connectors: {
      id: true,
      client_id: true,
      data_source_id: true,
      progress: true,
      data_source: {
        slug: true,
      },
      data_feed_created: true,
    },
  },
  model: {
    name: true
  },
  model_client: {
    id: true,
    client: {
      id: true,
      name: true,
      horizon_channels: {
        id: true,
        name: true,
        type: true,
      },
      horizon_models: {
        id: true,
        created_at: true,
        version: true,
        status: true,
        model: {
          created_at: true,
          name: true
        }
      }
    },
    current_channels: {
      id: true,
      name: true,
      type: true,
    }
  },
  actuals: {
    date: true,
    value: true,
    channel: {
      id: true,
      name: true}
  },
  historical_actuals: {
    channel: {
      id: true,
      name: true
    },
    funnel: {
      id: true,
      name: true
    },
    value: true,
    date: true
  },
  performances_actuals: {
    roas: true,
    cost: true,
    cost_pct_rank: true,
    roas_pct_rank: true,
    funnel: {
      id: true,
      name: true,
    },
    channel: {
      id: true,
      name: true,
    }
  },
  scenarios: horizonBudgetScenarioProjection,
};
