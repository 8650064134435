import React, { memo } from 'react';
import {
  IntegrationMetaIcon,
  IntegrationFacebookIcon,
  IntegrationLinkedinIcon,
  IntegrationTwitterIcon,
  IntegrationTikTok1Icon,
  IntegrationAmazonIcon,
  IntegrationInstagramIcon,
  IntegrationShopifyIcon,
  IntegrationMailChampIcon,
  IntegrationKlaviyoIcon,
  IntegrationPinterestIcon,
  IntegrationSalesforceIcon,
  IntegrationHubspotIcon,
  IntegrationBigCommerceIcon,
  IntegrationMicrosoftBingAdsIcon,
  IntegrationCriteoIcon,
  IntegrationGoogleAnalyticsUAIcon,
  IntegrationGoogleAnalyticsGAIcon,
  IntegrationGoogleSearchConsoleIcon,
  IntegrationGoogleAdsIcon,
  IntegrationDataQIcon,
  IntegrationMagentoIcon,
  IntegrationSemrushIcon,
  IntegrationTradeDeskIcon,
  IntegrationImpactIcon,
  IntegrationGoogleMyBusinessIcon,
  IntegrationWooCommerceIcon,
  IntegrationAscendIcon,
  IntegrationGoogleCampaignManagerIcon,
  IntegrationCJAffiliateIcon,
  IntegrationAppleIcon,
  IntegrationRakutenIcon,
  IntegrationShareASaleIcon,
  IntegrationAdelphicIcon,
  IntegrationStackAdaptIcon,
  IntegrationXIcon,
  IntegrationSnapchatIcon,
  IntegrationAdobeAnalyticsIcon,
} from '@sprnova/nebula';

interface NebulaDatasourceLogoProps {
  slug: string;
  width?: string | number;
  height?: string | number;
  minWidth?: string | number;
  minHeight?: string | number;
}

const NebulaDatasourceLogo: React.FC<NebulaDatasourceLogoProps> = ({
  slug,
  width,
  height,
  minWidth = '50px',
  minHeight = '59px',
}): JSX.Element => {
  // Define dynamic icon style based on props
  const iconStyle: React.CSSProperties = {
    width,
    height,
    minWidth,
    minHeight,
  };

  // Icon mapping object inside the component function
  const iconMap: Record<string, JSX.Element> = {
    'adelphic': <IntegrationAdelphicIcon style={iconStyle} />,
    'adobe-analytics': <IntegrationAdobeAnalyticsIcon style={iconStyle} />,
    'amazon-ads-dsp': <IntegrationAmazonIcon style={iconStyle} />,
    'amazon-ads-search': <IntegrationAmazonIcon style={iconStyle} />,
    'amazon-seller-central': <IntegrationAmazonIcon style={iconStyle} />,
    'apple-search-ads': <IntegrationAppleIcon style={iconStyle} />,
    'ascend-by-partnerize': <IntegrationAscendIcon style={iconStyle} />,
    'bigcommerce': <IntegrationBigCommerceIcon style={iconStyle} />,
    'campaign-manager-360': <IntegrationGoogleCampaignManagerIcon style={iconStyle} />,
    'cj-affiliate': <IntegrationCJAffiliateIcon style={iconStyle} />,
    'criteo': <IntegrationCriteoIcon style={iconStyle} />,
    'dataq': <IntegrationDataQIcon style={iconStyle} />,
    'facebook': <IntegrationFacebookIcon style={iconStyle} />,
    'facebook-marketing': <IntegrationMetaIcon style={iconStyle} />,
    'facebook-pages': <IntegrationFacebookIcon style={iconStyle} />,
    'ga4': <IntegrationGoogleAnalyticsGAIcon style={iconStyle}/>,
    'google-ads': <IntegrationGoogleAdsIcon style={iconStyle} />,
    'google-analytics': <IntegrationGoogleAnalyticsUAIcon style={iconStyle} />,
    'google-analytics-4': <IntegrationGoogleAnalyticsGAIcon style={iconStyle} />,
    'google-my-business': <IntegrationGoogleMyBusinessIcon style={iconStyle} />,
    'google-search-console': <IntegrationGoogleSearchConsoleIcon style={iconStyle} />,
    'hubspot-crm': <IntegrationHubspotIcon style={iconStyle} />,
    'impact-radius': <IntegrationImpactIcon style={iconStyle} />,
    'instagram-business': <IntegrationInstagramIcon style={iconStyle} />,
    'instagram': <IntegrationInstagramIcon style={iconStyle} />,
    'klaviyo': <IntegrationKlaviyoIcon style={iconStyle} />,
    'linkedin': <IntegrationLinkedinIcon style={iconStyle} />,
    'linkedin-ads': <IntegrationLinkedinIcon style={iconStyle} />,
    'magento' : <IntegrationMagentoIcon style={iconStyle} />,
    'mailchimp': <IntegrationMailChampIcon style={iconStyle} />,
    'microsoft-advertising': <IntegrationMicrosoftBingAdsIcon style={iconStyle} />,
    'microsoft-bing-ads': <IntegrationMicrosoftBingAdsIcon style={iconStyle} />,
    'paid-pinterest': <IntegrationPinterestIcon style={iconStyle} />,
    'pinterest': <IntegrationPinterestIcon style={iconStyle} />,
    'rakuten-affiliate-network': <IntegrationRakutenIcon style={iconStyle} />,
    'salesforce-commerce-cloud': <IntegrationSalesforceIcon style={iconStyle} />,
    'salesforce-leads': <IntegrationSalesforceIcon style={iconStyle} />,
    'salesforce-opportunities': <IntegrationSalesforceIcon style={iconStyle} />,
    'salesforce-organization': <IntegrationSalesforceIcon style={iconStyle} />,
    'salesforce-reports': <IntegrationSalesforceIcon style={iconStyle} />,
    'semrush': <IntegrationSemrushIcon style={iconStyle} />,
    'shareasale': <IntegrationShareASaleIcon style={iconStyle} />,
    'shopify': <IntegrationShopifyIcon style={iconStyle} />,
    'snapchat-ads': <IntegrationSnapchatIcon style={iconStyle} />,
    'stackadapt': <IntegrationStackAdaptIcon style={iconStyle} />,
    'the-trade-desk': <IntegrationTradeDeskIcon style={iconStyle} />,
    'tiktok-ads': <IntegrationTikTok1Icon style={iconStyle} />,
    'twitter': <IntegrationTwitterIcon style={iconStyle} />,
    'woocommerce': <IntegrationWooCommerceIcon style={iconStyle} />,
    'x-ads': <IntegrationXIcon style={iconStyle} />,

  };

  // Return nebula icon from map if found, otherwise return image
  return iconMap[slug] ?? (
    <img
      src={`/svg/logos/integrations/${slug}.svg`}
      alt={`${slug}-img`}
      style={{ maxWidth: width, maxHeight: height, minWidth, minHeight }}
    />
  ) ?? (
    <></>
  );
};

export default memo(NebulaDatasourceLogo);
