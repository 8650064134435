import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  AppHeader,
  AppHeaderProps,
  Box,
  NovaLogo,
  Typography,
  UserIcon,
  styled
} from '@sprnova/nebula';
import { novaGraphQLPublicClient } from 'api/entityGraphQL';
import { HelpDropdown } from 'layouts/components/Header/components';
import { ExternalClient, ExternalUser, selectExternalClientById } from 'features/entitiesRedux';
import { ClientUserDropdown } from './components';

const Logo = styled(Link)(() => ({
  display: 'flex'
}));

const ClientLogo: FC<{ client?: Pick<ExternalClient, 'name' | 'logo'>}> = ({ client }) => {
  let render = null;

  if (!client) {
    return null;
  }

  if (client.name) {
    render = (
      <Typography variant="h3" gutterBottom component="span" sx={{ margin: 0, lineHeight: 1 }}>
        {client.name}
      </Typography>
    );
  }

  if (client.logo) {
    render  = (
      <Box
        component="img"
        src={client.logo}
        sx={{ height: '40px'}}
      />
    );
  }

  return (
    <Box component="span" display="flex" alignItems="center">
      <Box sx={{ fontSize: '18px' }} component="span" px={1}>+</Box>
      {render}
    </Box>
  );
};


export const ClientHeader: FC<AppHeaderProps> = (props) => {
  const { clientId: id } = useParams<{ [x: string]: string }>();
  const client = useSelector(selectExternalClientById(parseInt(id)));

  const [account, setAccount] = useState<ExternalUser>();
  const [isLoadingAccount, setIsLoadingAccount] = useState<boolean>(false);
  const [accountName, setAccountName] = useState<string | null>(sessionStorage.getItem('clientUserName'));

  useEffect(() => {
    if (accountName === null) {
      (async (): Promise<void> => {
        setIsLoadingAccount(true);
        try {
          const me = await novaGraphQLPublicClient.getUser();
          setAccountName(me?.name);
          sessionStorage.setItem('clientUserName', me?.name);
          setAccount(me);
        } catch (error) {
          console.error(error as string);
        }
        setIsLoadingAccount(false);
      })();
    }
  }, [accountName]);

  /**
   * Sub menu information.
   */
  const [hasClients, setHasClients] = useState<boolean>(false);

  /**
   * Check if external user has more than one accepted client
   */
  useEffect(() => {
    const acceptedClients = account?.clients?.filter((client) => client.is_accepted === 'yes');
    if (acceptedClients) {
      const hasClientsBool = !!(acceptedClients?.length && (acceptedClients?.length > 1));
      setHasClients(hasClientsBool);
    }
  }, [account, hasClients]);

  const renderLogo = useMemo(() => (
    <Box display="flex" alignItems="center">
      <Logo to="/">
        <NovaLogo color="dark" />
      </Logo>
      <ClientLogo client={client} />
    </Box>
  ), [client]);

  const renderEnd = useMemo(() => {
    if (accountName) {
      return (
        <>
          <HelpDropdown />
          <ClientUserDropdown
            accountName={accountName}
            clientId={client?.id}
          />
        </>
      );
    }

    if (!isLoadingAccount) {
      return (
        <AppHeader.Button
          icon={<UserIcon />}
          component="a"
          href="/"
          label="Login"
        />
      );
    }

    return null;
  }, [accountName, isLoadingAccount, client]);

  return (
    <AppHeader end={renderEnd} {...props}/>
  );
};


