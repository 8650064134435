import React from 'react';

export const TalentVital5LightIcon = () => (
  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="34.5985" cy="74.9481" r="10" transform="rotate(-47.6683 34.5985 74.9481)" fill="#FFA600"/>
    <ellipse cx="45.5393" cy="64.8831" rx="9.91215" ry="10" transform="rotate(-47.6683 45.5393 64.8831)"
             fill="#E65957"/>
    <circle cx="56.5985" cy="54.9481" r="10" transform="rotate(-47.6683 56.5985 54.9481)" fill="#A94882"/>
    <circle cx="67.5985" cy="44.9481" r="10" transform="rotate(-47.6683 67.5985 44.9481)" fill="#6051A1"/>
    <circle cx="76.5985" cy="35.9481" r="10" transform="rotate(-47.6683 76.5985 35.9481)" fill="#203E4A"/>
    <rect x="111.503" y="105.821" width="16" height="100" rx="8" transform="rotate(179.797 111.503 105.821)"
          fill="#D9D9D9"/>
    <rect x="111.471" y="89.8213" width="16" height="83.5671" rx="8" transform="rotate(89.7969 111.471 89.8213)"
          fill="#D9D9D9"/>
    <circle cx="103.472" cy="97.9932" r="8" transform="rotate(-90 103.472 97.9932)" fill="#4A1FFF"/>
    <rect x="0.472168" y="6.02246" width="16" height="100.155" rx="8" transform="rotate(-0.108622 0.472168 6.02246)"
          fill="#D9D9D9"/>
    <rect x="0.501953" y="22.0107" width="16" height="83.57" rx="8" transform="rotate(-90.1086 0.501953 22.0107)"
          fill="#D9D9D9"/>
    <circle cx="8.47168" cy="13.9932" r="8" transform="rotate(-90 8.47168 13.9932)" fill="#4A1FFF"/>
  </svg>
);
