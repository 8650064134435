import React from 'react';

export const ErrorIcon = () => (
  <svg
    x="0px"
    y="0px"
    width="22"
    height="22"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M10 14.725C10.2333 14.725 10.425 14.65 10.575 14.5C10.725 14.35 10.8 14.1583 10.8 13.925C10.8 13.6917 10.725 13.5 10.575 13.35C10.425 13.2 10.2333 13.125 10 13.125C9.76667 13.125 9.575 13.2 9.425 13.35C9.275 13.5 9.2 13.6917 9.2 13.925C9.2 14.1583 9.275 14.35 9.425 14.5C9.575 14.65 9.76667 14.725 10 14.725ZM9.25 11.075H10.75V5.075H9.25V11.075ZM10 19.5C8.68333 19.5 7.446 19.25 6.288 18.75C5.12933 18.25 4.125 17.575 3.275 16.725C2.425 15.875 1.75 14.8707 1.25 13.712C0.75 12.554 0.5 11.3167 0.5 10C0.5 8.68333 0.75 7.44567 1.25 6.287C1.75 5.129 2.425 4.125 3.275 3.275C4.125 2.425 5.12933 1.75 6.288 1.25C7.446 0.75 8.68333 0.5 10 0.5C11.3167 0.5 12.5543 0.75 13.713 1.25C14.871 1.75 15.875 2.425 16.725 3.275C17.575 4.125 18.25 5.129 18.75 6.287C19.25 7.44567 19.5 8.68333 19.5 10C19.5 11.3167 19.25 12.554 18.75 13.712C18.25 14.8707 17.575 15.875 16.725 16.725C15.875 17.575 14.871 18.25 13.713 18.75C12.5543 19.25 11.3167 19.5 10 19.5ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z" fill="#C62828"/>
  </svg>
);
