import React, { FC, memo, useMemo } from 'react';
import { Box, Grid, Avatar, Typography, theme } from '@sprnova/nebula';
import { Scoreboard } from 'api/crudGraphQL/scoreboards/types';
import { format } from 'date-fns';
import { Client } from 'features/entitiesRedux';

type AccountCellProps = {
  data: Partial<Client | Scoreboard>;
  isExternal?: boolean;
}

const AccountCell: FC<AccountCellProps> = memo(({ data, isExternal = false }: AccountCellProps) => {
  /** Some logos do not include host */
  const storage = `${process.env.REACT_APP_HOST}/storage`;

  const renderAccount = useMemo(() => {
    const bold = { fontWeight: 'bold' };
    const italic = { fontStyle: 'italic', color: theme.variables.colors.primary.charcoal[200] };
    if (isExternal) {
      const { name, updated_at, client: { logo } } = data as Scoreboard ?? {};
      const fomattedLogo = logo ? logo.includes(storage) ? logo : `${storage}${logo}` : undefined;
      return (
        <>
          <Grid item xs={4} sx={{ marginRight: '12px' }}>
            <Avatar title={name} src={fomattedLogo}>{name?.[0]}</Avatar>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='h5' sx={{ ...bold, paddingBottom: '6px' }}>{name}</Typography>
            <Typography variant='body1' sx={italic}>Last updated: {format(new Date(updated_at), 'PPpp')}</Typography>
          </Grid>
        </>
      );
    }
    const { name: scoreboardName, client } = data as Scoreboard ?? {};
    const { logo, name, id } = client ?? {};
    const fomattedLogo = logo ? logo.includes(storage) ? logo : `${storage}${logo}` : undefined;
    return (
      <>
        <Grid item xs={4} sx={{ marginRight: '12px' }}>
          <Avatar title={name} src={fomattedLogo}>{name?.[0]}</Avatar>
        </Grid>
        <Grid item xs={8}>
          <Typography variant='h5' sx={bold}>{scoreboardName}</Typography>
          <Typography variant='body1'>{name}</Typography>
          <Typography
            variant='caption'
            sx={italic}
          >
              Client ID: {id}
          </Typography>
        </Grid>
      </>
    );
  }, [data, isExternal, storage]);

  return (
    <Box sx={{ width: '100%' }}>
      <Grid columns={2} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
        {renderAccount}
      </Grid>
    </Box>
  );
});

AccountCell.displayName = 'AccountCell';

export default AccountCell;
