import React, { FC, memo } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Department, Rule, Service, Task } from 'features/entitiesRedux';
import { Button, Popconfirm, Tooltip } from 'components';
import css from './DeleteButton.module.scss';

type DeleteButtonProps = {
  model: Service | Department | Task | Rule;
  type: string
  onDelete?: (model: Service | Department | Task | Rule) => void;
  disabled: boolean;
}

const DeleteButton: FC<DeleteButtonProps> = ({ model, onDelete, type, disabled}) => {

  const intercomShow = () => {
    if ((window as Window).Intercom) {
      (window as Window).Intercom('show');
    }
  };

  const renderDeleteButton = (
    <Button
      disabled={disabled}
      type="text"
      icon={<DeleteOutlined />}
      aria-label={`Delete ${model?.name}`}
      style={disabled ? {pointerEvents: 'none'} : {}}
    />
  );

  const popconfirmTitle = (
    <div className={css.popconfirmTitle}>
      Doing this can affect Appraisals, Blueprints, Scoreboards, and Clients that have this {type} in use. Are you sure you want to delete this {type}?
    </div>
  );

  const tooltipTitle = (
    <div className={css.tooltipTitle}>
      Please <a onClick={() => intercomShow()}>contact</a> a nova super admin to delete a {type}.
    </div>
  );

  return (
    <>
      {!disabled ? (onDelete && (
        <Popconfirm
          title={popconfirmTitle}
          placement='topRight'
          onConfirm={() => onDelete(model)}
          okText="Confirm"
          cancelText="Cancel"
        >
          {renderDeleteButton}
        </Popconfirm>
      )) : (
        <Tooltip
          title={tooltipTitle}
          placement='topRight'
        >
          <span>
            {renderDeleteButton}
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default memo(DeleteButton);
