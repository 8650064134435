/**
 * @param title 
 * Add label/title changes here to change them throughout the app
 * ex: https://app.asana.com/0/1198982315261693/1199169523480917
 */
export const titleChange = (title: string): any => {
  switch(title?.toLowerCase()) {
    case 'leads':
      return 'Goal Completions';
    case 'roas':
      return 'Blended ROAS';
    case 'cost per lead':
      return 'Cost Per Conversion';
    case 'spend':
      return 'Total Spend';
    case 'total revenue':
    case 'total-revenue':
      return 'Gross Revenue';
    case 'total sales':
    case 'total-sales':
      return 'E-commerce Revenue';
    case 'lost revenue':
    case 'lost-revenue':
      return 'Abandoned Cart Revenue';
    case 'eCommerce Revenue':
      return 'E-commerce Revenue';
    case 'e-commerce roas':
      return 'Total Website ROAS';
    case 'revenue':
      return 'Revenue';
    case 'sessions':
      return 'Sessions';
    case 'transactions':
      return 'Transactions';
    case 'ga conversion value':
    case 'ga-conversion-value':
      return 'Conversion Value';
    case 'ga new user rate':
    case 'ga new users rate':
    case 'ga-new-users-rate':
      return 'New Users Rate';
    case 'ga returning users rate':
    case 'ga-returning-users-rate':
      return 'Returning Users Rate';
    case 'ga new users':
    case 'ga-new-users':
      return 'New Users';
    case 'ga returning users':
    case 'ga-returning-users':
      return 'Returning Users';
    case 'ga users':
    case 'ga total users':
    case 'ga-users':
      return 'Total Users';
    case 'amz-sales':
      return 'Amazon Ads Sales';
    default:
      return title;
  }
};