import { Strategy } from 'features/entitiesRedux';

/**
 * Given a strategy param, determine if we should track an event
 * based on the strategy's status and close_won_at.
 * If the status exists, status is not won, and close_won_at exists, then we should track,
 * because it indicates the strategy has been unlocked and being edited again
 * @param blueprint Strategy
 * @returns boolean
 */
const shouldTrackReUnlockedBlueprint = (blueprint: Strategy): boolean => {
  return blueprint?.close_won_at && blueprint.status && blueprint?.status !== 'won';
};

export default shouldTrackReUnlockedBlueprint;
