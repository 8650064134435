/**
 * AppNav.Item with dropdown sub-menu
 */

import React, { FC, ReactNode, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { AppNav, AppNavItemProps } from '@sprnova/nebula';

export interface AppItemSubMenuProps extends AppNavItemProps {
    path: string;
    items: {
        label: ReactNode;
        path: string;
        disabled?: boolean;
        hidden?: boolean;
    }[];
}

export const AppItemSubMenu: FC<AppItemSubMenuProps> = ({
  items,
  path,
  ...rest
}) => {
  const { pathname } = useLocation();
  const isActive = useMemo(() =>
    pathname === path ||
    items.some(({ path }) => pathname.includes(path)
    ), [items, pathname, path]);

  const dropdownItems = useMemo(
    () => items.map(({ label, path, disabled = false, hidden = false }) => (
      {
        label,
        to: path,
        component: NavLink,
        isActive: pathname === path,
        disabled,
        hidden,
      }
    )), [items, pathname]);


  return (
    <AppNav.Item
      isActive={isActive}
      component={NavLink}
      to={path}
      dropdownItems={dropdownItems}
      {...rest}
    />
  );
};
