/**
 * Entities -> Partners -> Selectors
 */

import { RootState } from 'app/store';
import { selectEntitiesDenormalized } from '../_entity/selectors';
import { Partner } from './partner';

export const selectPartnersByIds = (partnerIds: number[]) => (state: RootState) => {
  const input = { partners: partnerIds };
  return selectEntitiesDenormalized(state, input).partners.filter((partner: Partner) => partner);
};

export const selectPartnerById = (partnerId: number) => (state: RootState) => {
  return selectPartnersByIds([partnerId])(state)[0];
};
