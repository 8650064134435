export const openIntercom = (): void => {
  if ((window['Intercom'])) {
    window['Intercom']('show');
  }
};
export const hideIntercomButton = (): void => {
  if ((window as Window).Intercom) {
    (window as Window).Intercom('update', {
      'hide_default_launcher': true
    });
  }
};