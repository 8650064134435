/**
 * Entities -> Strategies -> Types
 */

import { PricingVersion } from 'api/crudGraphQL/strategies/types';
import { Moment } from 'moment-timezone';
import {Task, VaboType} from 'features/entitiesRedux';
import { BlueprintStage } from 'features/strategies/types';
import { Audit, ControlType, Department, Service } from '..';
import { Client } from '../client/client';
import { Partner } from '../partner/partner';
import {
  BusinessType,
  Commission,
  LeadSource,
  LeadSourceEmployee,
  Multiplier,
  Pillar,
} from '../types';
import { User } from '../user/user';
import { StrategyChurnReason } from './strategyChurnReason';
import { StrategyPackage } from './strategyPackage';

export type RequiredInformation = {
  id: number;
  name: string;
  slug: string;
  description: string;
  is_needed: boolean;
  is_manual: boolean;
  created_at: Date;
  updated_at: Date;
}

/**
 * Strategy
 */
export type StrategyMonth = {
  cost: number;
  costs: number;
  value: number;
  date: string;
  hour: number;
  hours: number;
  management_hours: number;
  suggested_hourly_rate: number;
  id: number;
  name: number; // the name in this case actually holds the month number relative to the contract length (i.e if contract length is 24 months, month 13 is January of the next year so name would be 13)
  profit: number;
  retainer: number; // final price for the month
  hourly_rate: number;
  suggestion: number;
  services: number; // total number of services for the month
  recommended: number; // recommended price for the month
};

export type StrategyPackageMonth = {
  id?: number;
  package_id?: number;
  month_id?: string;
  value?: number;
  name?: string;
}

export type StrategyCost = {
  costs: number;
  created_at: Date;
  department: Department;
  id: number;
  months: StrategyMonth[];
  name: string;
  notes: string;
  updated_at: Date;
};

export type StrategyTask = {
  slug?: string,
  created_at: Date;
  service: Service;
  hours: number;
  projected_hours: string;
  is_configurable: string;
  units: number;
  hours_per_unit: number;
  unit_type: string;
  id: number;
  months: StrategyMonth[];
  name: string;
  notes: string;
  order: number;
  snippet: string;
  updated_at: Date;
  multiplier: Multiplier;
  control_type: ControlType;
  minimum_hours: number;
  recommended_hours: number;
};

export type StrategySummarySheet = {
  id: number;
  name: string;
  slug: string;
  calculation_notes: string[],
  months: StrategyMonth[];
}[];

export type StrategyGrade = {
  name: string;
  id: number;
  hourly_rate: number;
};

export type StrategyUser = (Pick<User, 'id'> & Partial<User>) & {
  commission_percent?: number,
  strategy_commission?: number,
};

export type TaskMonth = {
  id: number;
  name: string;
  notes?: string;
  order?: number;
  hours?: number;
  units?: number;
  months?: StrategyMonth[];
  children?: Task[];
  parents?: Task[];
  service?: Service;
  multiplier?: Multiplier;
  snippet?: string;
  snippet_url?: string;
  executive_summary?: string;
  projected_hours?: string;
  outsource_budget?: string;
  is_sub_task?: string;
  is_configurable?: string;
  hours_per_unit?: number;
  unit_type?: string;
  control_type?: string;
  hours_type?: string;
  recommended_hours?: number;
  recommended_outsource_budget?: string;
  minimum_hours?: number;
  maximum_hours?: number;
  slug?: string;
}

export type Strategy = {
  account_manager: User;
  actual_gross_profit: number;
  ad_percent: number;
  ad_spend?: 'yes' | 'no';
  adjusted_commission_percent: number;
  aging: number;
  audit: Audit;
  author: Partial<User>;
  auto_added_strategy_packages: StrategyPackage[];
  billing_name?: string;
  billing_phone?: string;
  billing_email?: string;
  billing_address?: string;
  billing_city?: string;
  billing_state?: string;
  billing_zip?: string;
  billing_country?: string;
  // this is the status of the blueprint
  blueprint_status: string;
  business_type: BusinessType;
  churn_reason: StrategyChurnReason;
  client: Client;
  close_won_at: Date;
  commission_flat_fee: number;
  commission_percent_adjustment: number;
  commission_percent: number;
  commission_version: number;
  commission: Partial<Commission>;
  costs: StrategyCost[];
  created_at: Date;
  date: string;
  default_base_commission: number;
  deliverable?: {
    id: number;
    status: string;
    link: string;
    message: string;
    updated_at: Date;
  };
  deny_reason?: string | null
  department_id?: number | null;
  department_summary: StrategySummarySheet;
  departments: Partial<Department>[];
  departments_across_all_blueprint_entities: Partial<Department>[];
  detailed_reason?: string;
  early_churn_date: string;
  embed_url: null | string;
  executive_sponsor: Partial<User>;
  finance_reviewed_at: Date;
  finance_reviewer: { id: string, name: string };
  finance_reviewer_status: string;
  finance_summary: {
    department_name: string;
    service_name: string;
    type: 'revenue' | 'outsource';
    months: StrategyMonth[];
  }[];
  follow_up_date?: string;
  grade: StrategyGrade;
  guaranteed_term: number;
  hourly_rate: number;
  id: number;
  is_commission_needed?: boolean;
  is_department_heads_reviewed?: boolean | null;
  is_finance_reviewed?: boolean | null;
  is_outsource_needed: boolean;
  is_package_needed: boolean;
  is_vabo?: boolean;
  lead_source: LeadSource;
  lead_source_employee: LeadSourceEmployee;
  length: number;
  lost_reason_type?: string;
  monthly_gross_profit: number;
  months: StrategyMonth[];
  multipliers: Multiplier[];
  name: string;
  notes: string;
  nova_is_primary: boolean;
  parent: {
    id: number;
    name: string;
  };
  partner: Partial<Partner>;
  packages: StrategyPackage[];
  pillar: Pillar;
  presentation: {
    deck_url: string;
  };
  pricing_version: PricingVersion;
  proposal_url?: string;
  required_information?: RequiredInformation[];
  revenue_calculation_version?: number | null;
  reviewed_at: string;
  reviewer: User;
  reviewer_status: string,
  salesforce_opportunity: {
    close_date?: string;
    explanation?: string;
    reason?: string;
    id: number;
    salesforce_id: number;
    salesforce_name: string;
    stage: {
      id: number;
      name: string;
    };
    type: string;
    meeting_date: string;
  };
  salesforce_connection_status: string,
  salesforce_is_primary: boolean;
  salesforce_opportunity_id: string | null | undefined;
  salesforce_opportunity_link: string;
  salesforce_opportunity_name: string | null | undefined;
  service_summary: StrategySummarySheet;
  // this is the stage of the blueprint
  status: BlueprintStage;
  sync_to_salesforce: boolean;
  target_hourly_rate: number;
  tasks: StrategyTask[];
  total_commission: number;
  total_commission_expense: number;
  total_costs: number;
  total_executive: number;
  total_executive_expense: number;
  total_expenses: number;
  total_hours: number;
  total_gross_profit: number;
  total_minimum: number;
  total_partner: number;
  total_partner_expense: number;
  total_profit: number;
  total_real_profit: number;
  total_retainer: number;
  type: 'retainer' | 'project';
  updated_at: Date;
  users: User[];
  vabo_type?: Partial<VaboType>;
  vabo_type_id?: number;
  versions: Strategy[];
  version_name: string;
  version_count: number; // includes deleted versions
};

/**
 * Duplicate values
 */
export type StrategyDuplicateValues = Pick<
  Strategy,
  'id' | 'type' | 'length' | 'type'
> & {
  client_id: number;
  date: Moment | undefined | string;
  length?: number;
  notes?: string;
  status?: string;
  type?: string;
  pillar_id?: number;
};

/**
 * Strategy form values
 */
export type StrategyFormValues = Partial<
  Omit<Strategy, 'departments' | 'users'>
  > & {
  account_manager_id?: number | null;
  ad_spend?: 'yes' | 'no';
  audit_id?: number;
  author_id?: number;
  billing_name?: string;
  billing_phone?: string;
  billing_email?: string;
  billing_address?: string;
  billing_city?: string;
  billing_state?: string;
  billing_zip?: string;
  billing_country?: string;
  business_type_id?: number;
  client_id?: number;
  client_name?: string;
  commission_flat_fee?: number;
  commission_percent_adjustment?: number;
  status?: string;
  date?: string;
  departments?: (number | undefined)[];
  detailed_reason?: string;
  executive_sponsor_id?: number | null;
  follow_up_date?: string;
  is_reviewed?: boolean;
  is_outsource_needed?: boolean;
  lead_source_id?: number;
  lead_source_employee_id?: number;
  lost_reason_type?: string;
  partner_id?: number;
  pillar_id?: number;
  tasks?: { id: number }[];
  users?: number[];
  salesforce_opportunity_id?: string | null;
  salesforce_is_primary?: boolean;
  salesforce_close_date?: Date;
  salesforce_reason?: string;
  salesforce_explanation?: string;
  guaranteed_term?: number;
  presentation?: {
    deck_url: string;
  }
  review_type?: 'Finance' | 'Department';
  deny_reason?: string | null;
  review_department_id?: number | null;
  is_vabo?: boolean;
  vaboTypeId?: number | undefined;
};

/**
 * Strategies filter
 */
export type FetchStrategiesFilter = {
  id?: number;
  name?: string;
  author_id?: number;
  audit_id?: number;
  is_reviewed?: boolean;
  is_finance_reviewed?: boolean | null;
  is_department_heads_reviewed?: boolean | null;
  department_id?: number | null;
  status?: string;
  sort?: string[];
};

export type StrategyOutsourceCostGuideItem = {
  id: number;
  internal_contact: Partial<User>;
  department: Partial<Department>;
  service: string;
  amount: any;
  frequency_of_cost: string;
  notes_or_description: string;
  pricing_version: Partial<PricingVersion>;
};

export const StrategyVaboTypes = { id: true, name: true, slug: true, description: true };
