/**
 * Update strategy task order
 */

import { Projection } from 'api/entityGraphQL';
import { Strategy } from 'features/entitiesRedux';
import { baseApi, cleanArguments } from '../../..';
import tagTypes from '../../tagTypes';

export type UpdateStrategyTaskOrderMutation = {
  strategy_id: number;
  tasks: number[];
  projection?: Projection;
};

export type UpdateStrategyTaskOrderResult = Strategy;

export const UPDATE_STRATEGY_TASK_ORDER_ENDPOINT_KEY = 'updateStrategyTaskOrder';

export const { useUpdateStrategyTaskOrderMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [UPDATE_STRATEGY_TASK_ORDER_ENDPOINT_KEY]: build.mutation<UpdateStrategyTaskOrderResult, UpdateStrategyTaskOrderMutation>({
      transformResponse: (response: { updateStrategyTaskOrder: Strategy }) => response.updateStrategyTaskOrder,
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<UpdateStrategyTaskOrderMutation> = {
          ...values
        };
        return {
          body: {
            mutation: {
              updateStrategyTaskOrder: {
                __args: cleanArguments(__args),
                id: true
              }
            }
          }
        };
      }
    })
  })
});
