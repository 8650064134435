import { Resource } from '../Resource';
import { Role } from '../Role';
import { RoleConfig } from '../types';

const attrs = ['*'];
export const newHire: RoleConfig = {
  [Role.newHire]: {
    [Resource.analystSurvey]: {
      'read:any': attrs,
    },
    [Resource.audit]: {
      'read:any': attrs,
    },
    [Resource.client]: {
      'read:any': attrs,
    },
    [Resource.contact]: {
      'read:any': attrs,
    },
    [Resource.cohorts]: {
      'read:any': attrs,
    },
    [Resource.creativeAffinity]: {
      'read:any': attrs,
    },
    [Resource.customerInsights]: {
      'read:any': attrs,
    },
    [Resource.department]: {
      'read:any': attrs,
    },
    [Resource.discoveryCall]: {
      'read:any': attrs,
    },
    [Resource.forecast]: {
      'read:any': attrs,
    },
    [Resource.integration]: {
      'read:any': attrs,
    },
    [Resource.libraryDepartment]: {
      'read:any': attrs,
    },
    [Resource.libraryService]: {
      'read:any': attrs,
    },
    [Resource.libraryStrategy]: {
      'read:any': attrs,
    },
    [Resource.libraryOutsourceCost]: {
      'read:any': attrs,
    },
    [Resource.packageStrategy]: {
      'read:any': attrs,
    },
    [Resource.partner]: {
      'read:any': attrs,
    },
    [Resource.recommendedServices]: {
      'read:any': attrs,
    },
    [Resource.report]: {
      'read:any': attrs,
    },
    [Resource.rule]: {
      'read:any': attrs,
    },
    [Resource.service]: {
      'read:any': attrs,
    },
    [Resource.strategy]: {
      'read:any': attrs,
    },
    [Resource.talent]: {
      'read:any': attrs,
    },
    [Resource.talentSurvey]: {
      'read:own': attrs,
    },
    [Resource.task]: {
      'read:any': attrs,
    },
    [Resource.user]: {
      'read:any': attrs,
      'update:own': attrs,
    },
  },
};
