import { PricingTier } from 'api/crudGraphQL/strategies/types';
import { TaskChildren } from 'api/crudGraphQL/tasks/types';

export type FormDataType = StrategyOverviewDataType & ContractDetailsDataType & AdditionalStrategyDataType;

export type StrategyOverviewDataType = {
  name: string;
  service_id: number;
  strategy_frequency_id: number;
}

export type ContractDetailsDataType = {
  pricing_type_id: number;
  pricing_type_slug: string;
  pricing_tiers: PricingTier[];
  snippet_summary: string;
}

export type AdditionalStrategyDataType = {
  children: TaskChildren[];
}

export enum ActionTypeContractDetails {
  ON_CHANGE_PRICING_TYPE = 'on_change_pricing_type',
}

export enum ActionType {
  /**
   * Action type used for any changes in dropdowns in strategy overview card
   */
  ON_CHANGE_STRATEGY_OVERVIEW_DATA = 'on_change_strategy_overview_data',
  /**
   * Action type used for any changes in pricing type dropdown in contract details card
   */
  ON_CHANGE_PRICING_TYPE = 'on_change_pricing_type',
  /**
   * Action type used for any changes in dropdowns in contract details card
   */
  ON_CHANGE_CONTRACT_DETAILS_DATA = 'on_change_contract_details_data',
  /**
   * Action type used for any changes in the additional dropdowns in contract details card for multiple tiers pricing
   */
  ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS = 'on_change_contract_details_pricing_type_data_multiple_tiers',
  /**
   * Action type used for any changes in the additional dropdowns in contract details card for single tier pricing
   */
  ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_SINGLE_TIER = 'on_change_contract_details_pricing_type_data_single_tier',
  /**
   * Action type used for any changes in additional strategy data
   */
  ON_CHANGE_ADDITIONAL_STRATEGY_DATA = 'on_change_additional_strategy_data',
}
