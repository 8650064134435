import React, { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Button, Col, Row } from 'antd';
import { useGetNovaFeaturesPublicQuery } from 'api/crudGraphQL/public/nova_features/getNovaFeaturesPublic';
import classNames from 'classnames';
import { Warning } from 'components/AccessControl/Warning';
import { Spin } from 'components/Spin';
import isExternalUser from 'utils/helpers/isExternalUser';
import { hasFeatureAccess } from 'features/clients/External/utils/hasFeatureAccess';
import { useClientRefreshById } from 'features/entitiesRedux/models/client/hooks';
import { defaultClientIntegrationProjection } from 'features/entitiesRedux/models/client/projections';
import { selectDataSourcesById } from 'features/entitiesRedux/models/data_source/selectors';
import { useExternalClientRefreshById } from 'features/entitiesRedux/models/external_client/hooks';
import { defaultExternalClientIntegrationProjection } from 'features/entitiesRedux/models/external_client/projections';
import { selectIsLoading } from 'features/entitiesRedux/selectors';
import css from 'features/errors/ErrorBoundary.module.scss';
import { ErrorPage } from 'features/errors/ErrorPage';
import { novaFeaturesProjection } from '../External/novaFeaturesProjection';

const ClientIntegrationOauthErrorPage = (): JSX.Element => {
  /**
   * Page is used for both internal and client-facing.
   */
  const isClient = isExternalUser();
  const history = useHistory();
  const redirectPath = isClient ? 'c' : 'clients';

  /**
   * Obtain client and data source.
   */
  const { clientId: clientIdUnParsed, datasourceId: datasourceIdUnParsed } = useParams<{ [x: string]: string }>();
  const clientId = parseInt(clientIdUnParsed) || undefined;
  const datasourceId = parseInt(datasourceIdUnParsed);

  const { client } = isClient && clientId ?
    useExternalClientRefreshById(clientId, { projection: defaultExternalClientIntegrationProjection }) : clientId ?
      useClientRefreshById(clientId, { projection: defaultClientIntegrationProjection }) : { client: undefined};

  const dataSource = useSelector(selectDataSourcesById(datasourceId));

  /**
   * Keep track of loading.
   */
  const isLoading = useSelector(selectIsLoading);

  /**
   * Feature access control (external users)
   */
  const { data: nova_features = [], isLoading: isLoadingFeatures } = useGetNovaFeaturesPublicQuery({ client_id: parseInt(clientIdUnParsed), projection: novaFeaturesProjection }, { skip: !parseInt(clientIdUnParsed) || typeof parseInt(clientIdUnParsed) !== 'number'});
  const hasAccess = useMemo(() => {
    return hasFeatureAccess(nova_features, 'integrations');
  }, [nova_features]);

  // show alert to external users if currently selected client does not have access to this feature
  if (isClient && !hasAccess && !isLoadingFeatures) {
    return <Warning container />;
  }

  return (
    <div className={classNames(css.root, { [css.root_mobile]: isMobile })}>
      { isLoading || isLoadingFeatures ? <Spin fontSize={45}/> :
        <div className={css.errorWrapper}>
          <Alert
            message={<ErrorPage />}
            description={
              <>
                <span className={css.title}>{`While trying to authenticate for ${dataSource?.name}, something went wrong...`}</span>

                <div className={css.subtitle}>Please contact us through Intercom or our support form.</div>

                <Row justify='start'>
                  <Col style={{ 'marginRight': '30px' }}>
                    <Button onClick={() => history.push(`/${redirectPath}/${client?.id}/integrations`)}>
                    Integrations Overview
                    </Button>
                  </Col>

                  <Col>
                    <a href='https://form.asana.com/?k=Y0Uh0wpSRgfi9S8TIfvZzA&d=22908445599079' target="_blank" rel="noreferrer">
                      <Button>
                      Support Form
                      </Button>
                    </a>
                  </Col>
                </Row>
              </>
            }
            type="error"
          />
        </div>
      }
    </div>
  );
};

export const FormattedTitleClientIntegrationOauthErrorPage = (): JSX.Element => {
  /**
   * Obtain client and integration ID from path.
   */
  const { datasourceId: datasourceIdUnParsed } = useParams<{ [x: string]: string }>();
  const datasourceId = parseInt(datasourceIdUnParsed);

  /**
   * Find the integration name.
   */
  const dataSource = useSelector(selectDataSourcesById(datasourceId));

  return <span>{dataSource?.name ? `${dataSource?.name} Error` : 'Loading...'}</span>;
};

export default ClientIntegrationOauthErrorPage;
