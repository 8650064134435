/**
 * Services -> List
 */

import React, {FC, memo, useMemo} from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Action } from 'api/accessControl/Action';
import { Resource } from 'api/accessControl/Resource';
import {StringParam, useQueryParam, withDefault} from 'use-query-params';
import { LABELS_TYPES } from 'utils';
import { Service } from 'features/entitiesRedux/models/service/service';
import { BusinessType } from 'features/entitiesRedux/models/types';
import { useAccessControl } from 'features/global/hooks/useAccessControl';
import { useAccount } from 'features/global/hooks/useAccount';
import DeleteButton from 'features/library/components/DeleteButton';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import {
  AccessControl,
  Button,
  Table,
} from 'components';
import css from './List.module.scss';

type ServicesListProps = {
  services: Service[];
  loading: boolean;
  onDelete: (service: Partial<Service> & Pick<Service, 'id'>) => void;
};

const ServicesList: FC<ServicesListProps> = ({ services, loading, onDelete }) => {
  const [pricingVersionQueryParam] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );
  const convertBusinessTypesToString: (
    business_types: BusinessType[] | undefined
  ) => string = (business_types) =>
    business_types?.map((type) => type.name).join(', ') || '';

  const { account } = useAccount();
  const { can } = useAccessControl();
  const canDelete = can(Action.delete, Resource.libraryService, account?.id);
  const canEdit = can(Action.update, Resource.libraryService, account?.id);

  const renderActions = (value: string, service: Service): JSX.Element => (
    <div className={css.actions}>
      <DeleteButton
        model={service}
        onDelete={onDelete}
        type='service'
        disabled={!canDelete}
      />
      <Link to={`/library/services/${service.id}/edit?${pricingVersionString}=${pricingVersionQueryParam}`}>
        <Button type="text" icon={<EditOutlined />} disabled={!canEdit}/>
      </Link>
    </div>
  );

  const renderName = (name: string, row: Service): JSX.Element => (
    <Link to={`/library/tasks?service_id=${row.id}&${pricingVersionString}=${pricingVersionQueryParam}`}>{name}</Link>
  );
  const renderDepartment = (name: string, { department }: Service): JSX.Element | null =>
    department ? (
      <Link to={`/library/departments/${department.id}/edit?${pricingVersionString}=${pricingVersionQueryParam}`}>
        {department.name}
      </Link>
    ) : null;
  const renderBusinessTypes = (business_types: BusinessType[]) =>
    business_types && business_types.length
      ? convertBusinessTypesToString(business_types)
      : null;

  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: renderName,
      defaultSortOrder: 'ascend' as const,
      sorter: (a: any, b: any) => `${a.name}`.localeCompare(b.name),
      width: 300,
    },
    {
      title: 'Department',
      key: 'department',
      dataIndex: 'department',
      render: renderDepartment,
      sorter: (a: any, b: any) => `${a.name}`.localeCompare(b.name),
      width: 200,
    },
    {
      title: `${LABELS_TYPES.BUSINESS_UNIT_FIRST_LETTERS_CAPITALIZED}s`,
      key: 'business_types',
      dataIndex: 'business_types',
      render: renderBusinessTypes,
      sorter: (a: Service, b: Service) =>
        `${convertBusinessTypesToString(a.business_types)}`.localeCompare(
          convertBusinessTypesToString(b.business_types)
        ),
      width: 250,
    },
    {
      title: '',
      key: 'actions',
      dataIndex: 'actions',
      width: 130,
      render: renderActions,
    },
  ];

  return (
    <Table
      loading={loading}
      rowKey="id"
      columns={columns}
      dataSource={services}
      scroll={{ x: 1100 }}
    />
  );
};

export default memo(ServicesList);
