import React from 'react';
import loadable from '@loadable/component';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';
import { Skeleton } from 'components';
import { useSurvey } from '../../hooks';

const LoadableComponent: any = loadable(
  () => import('./ViewTalentSurveyPage'),   {
    fallback: <PageSkeleton />
  }
);

const Title = () => {
  const projection: any = {
    id: true,
    user: {
      id: true,
      name: true,
    }
  };

  const { survey, error } = useSurvey(
    undefined, // The ID is grapped from the URL by default
    projection,
  );


  if (error) {
    return 'Error';
  }

  if (!survey) {
    return <Skeleton title={{ width: 150 }} paragraph={false} active />;
  }

  return `${survey?.user?.name} Review`;
};

LoadableComponent.Title = Title;

export { LoadableComponent as ViewTalentSurveyPage };
