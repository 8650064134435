import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import loadable from '@loadable/component';
import { Action, Resource } from 'api/accessControl';
import { useGetServiceQuery } from 'api/crudGraphQL/services/getService';
import { useAccessControl } from 'features/global';
import { PricingVersion } from 'features/library/constants';
import { Skeleton } from 'components';
import projection from './projection';

const EditServicePage = loadable(
  () => import('./EditServicePage')
);

const Title: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { can } = useAccessControl();
  const canEditLibraryServicePackage = can(Action.update, Resource.libraryService);
  const { data: service, isLoading } = useGetServiceQuery({
    id: Number(id),
    projection
  });

  if (isLoading) {
    return <Skeleton title={{ width: 150 }} paragraph={false} active />;
  }

  if (!service || (service && service.pricing_version?.slug === PricingVersion.PACKAGE && !canEditLibraryServicePackage)) {
    return null;
  }

  return <>{service.name}</>;
};

Title.displayName = 'EditServicePageTitle';

export default Object.assign(EditServicePage, { Title });
