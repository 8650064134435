import { normalize } from 'normalizr';
import { TalentSurvey } from 'features/talent/types';
import { entitiesSchema } from '../models';
import { Alert } from '../models/alert/alert';
import { Audit, Presentation, Prospect } from '../models/audit/audit';
import { Client } from '../models/client/client';
import { Contact } from '../models/contact/contact';
import { Dashboard } from '../models/dashboard';
import { DataSource } from '../models/data_source';
import { Department } from '../models/department/department';
import { ExternalClient } from '../models/external_client/external_client';
import { Churn, Rapport, Pace } from '../models/field';
import { Forecast } from '../models/forecast/forecast';
import { Organization } from '../models/organization/organization';
import { Partner } from '../models/partner/partner';
import { Report } from '../models/report/report';
import { Widget } from '../models/report/widget';
import { Rule } from '../models/rule/rule';
import { Service } from '../models/service/service';
import { Strategy } from '../models/strategy/strategy';
import { Task } from '../models/task/task';
import { User } from '../models/user/user';

export const normalizeEntities = (data: {
  audits?: Partial<Audit>[];
  clients?: Partial<Client>[];
  external_clients?: Partial<ExternalClient>[];
  contacts?: Partial<Contact>[];
  datasources?: Partial<DataSource>[];
  departments?: Partial<Department>[];
  forecasts?: Partial<Forecast>[];
  organizations?: Partial<Organization>[];
  partners?: Partial<Partner>[];
  presentations?: Partial<Presentation>[];
  prospects?: Partial<Prospect>[];
  reports?: Partial<Report>[];
  rules?: Partial<Rule>[];
  services?: Partial<Service>[];
  strategies?: Partial<Strategy>[];
  surveys?: Partial<TalentSurvey>[];
  tasks?: Partial<Task>[];
  users?: Partial<User>[];
  widgets?: Partial<Widget>[];
  alerts?: Partial<Alert>[];
  dashboards?: Partial<Dashboard>[];
  dashboard_churns?: Partial<Churn>[];
  dashboard_rapports?: Partial<Rapport>[];
  dashboard_paces?: Partial<Pace>[];
}) => {
  const schema = entitiesSchema;

  try {
    const normalized = normalize<
      any,
      {
        audits: { [key: string]: Audit };
        clients: { [key: string]: Client };
        external_clients: { [key: string]: ExternalClient };
        contacts: { [key: string]: Contact };
        dashboards: { [key: string]: Dashboard };
        dashboard_churns: { [key: string]: Churn[] };
        dashboard_rapports: { [key: string]: Rapport[] };
        dashboard_paces: { [key: string]: Pace[] };
        datasources: { [key: string]: DataSource };
        departments: { [key: string]: Department };
        forecasts: { [key: string]: Forecast };
        organizations: { [key: string]: Organization };
        partners: { [key: string]: Partner };
        presentations: { [key: string]: Presentation };
        prospects: { [key: string]: Prospect };
        reports: { [key: string]: Report };
        rules: { [key: string]: Rule };
        services: { [key: string]: Service };
        strategies: { [key: string]: Strategy };
        surveys: { [key: string]: TalentSurvey };
        tasks: { [key: string]: Task };
        users: { [key: string]: User };
        widgets: { [key: string]: Widget };
      }
    >(data, schema);

    return { ...normalized };
  } catch (error) {
    console.error('🚨 normalizedEntities.error', error);
    throw error;
  }
};
