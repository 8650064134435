import React from 'react';

export const ClientsIcon = () =>(
  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M84.7143 84.7143C100.726 78.1341 112 62.3837 112 44C112 19.6995 92.3005 0 68 0C49.6163 0 33.8659 11.2743 27.2857 27.2857C11.2743 33.8659 0 49.6163 0 68C0 92.3005 19.6995 112 44 112C62.3837 112 78.1341 100.726 84.7143 84.7143ZM68 88C73.9168 88 79.5609 86.8321 84.7143 84.7143C86.8321 79.5609 88 73.9168 88 68C88 43.6995 68.3005 24 44 24C38.0832 24 32.4391 25.1679 27.2857 27.2857C25.1679 32.4391 24 38.0832 24 44C24 68.3005 43.6995 88 68 88Z" fill="#D9D9D9"/>
    <path d="M43.3399 47.2656V72.9276H69.0019C69.0019 72.9276 69.0019 72.9276 69.0019 72.9276C69.0019 58.7549 57.5125 47.2656 43.3399 47.2656C43.3399 47.2656 43.3398 47.2656 43.3399 47.2656Z" fill="#A94882"/>
    <ellipse cx="11.3586" cy="11.3586" rx="11.3586" ry="11.3586" transform="matrix(-1 0 0 1 66.0566 36)" fill="#FFA600"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M62.3812 55.7237C57.6852 50.5294 50.8937 47.2656 43.3398 47.2656V47.4594C43.3944 53.6858 48.4587 58.7165 54.698 58.7165C57.6609 58.7165 60.3589 57.582 62.3812 55.7237Z" fill="#E65957"/>
  </svg>
);
