interface WidgetSibCardStylesProps {
  index?: number;
}

export const listItemStyles = ({ index = 1 }: WidgetSibCardStylesProps) => ({
  bgcolor: '#F4F4F4',
  marginTop: index === 1 ? '0' : '8px',
  height: '100%',
  padding: '8px 0'
});

export const listItemAvatarIndexStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  color: '#6D6D6D',
};

export const listItemAvatarValueStyles = {
  display: 'flex',
  justifyContent: 'center',
  padding: '0 24px',
  borderLeft: '1px solid lightgrey',
};
