import React, { FC, memo } from 'react';
import { Avatar } from '@sprnova/nebula';
import css from './TeammateCell.module.scss';

type TeammateCellProps = {
  name?: string;
  avatar?: string;
  title?: string;
  isAuthenticatedUser?: boolean;
};

const TeammateCell: FC<TeammateCellProps> = ({ avatar, name, title, isAuthenticatedUser }) => {
  return (
    <>
      <Avatar src={avatar} title={name}>{name?.charAt(0)}</Avatar>
      <div className={css.nameContainer}>
        {name && (
          <span className={css.name}>
            {name}
            {isAuthenticatedUser && <span className={css.authenticatedUserLabel}>(You)</span>}
          </span>
        )}
        {title && <span className={css.title}>{title}</span>}
      </div>
    </>
  );
};

export default memo(TeammateCell);
