import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Projection } from 'api/entityGraphQL';
import { AppDispatch } from 'app/store';
import { fetchExternalClientById } from './actions';
import { ExternalClient } from './external_client';
import { selectExternalClientById } from './selectors';

export const useExternalClientRefreshById = (
  clientId: number,
  config: { projection?: Projection } = {}
): { client: ExternalClient, refresh: (config?: { projection?: Projection }) => void } => {
  const dispatch = useDispatch();

  const refresh = (config?: { projection?: Projection }) => {
    dispatch(fetchExternalClientById({ id: clientId, ...config }));
  };

  useEffect(() => {
    dispatch(fetchExternalClientById({ id: clientId, ...config }));
  }, [clientId]);

  return {
    client: useSelector(selectExternalClientById(clientId)),
    refresh,
  };
};

export const refreshExternalClientById = (
  clientId: number,
  dispatch: AppDispatch,
  config: { projection?: Projection } = {}
): void => {
  dispatch(fetchExternalClientById({ id: clientId, ...config }));
};
