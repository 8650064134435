/**
* Get horizon channels
*/

import {  HorizonChannel } from 'features/entitiesRedux/models/horizon';
import { baseApi, cleanArguments } from '..';

export type GetHorizonChannelsProjection = {
  created_at?: true;
  id: true;
  is_active?: true;
  name?: true;
  slug?: true;
  type?: true;
  updated_at?: true;
}

export type GetHorizonChannelsQuery = {
  name?: string;
  id?: string;
  projection: GetHorizonChannelsProjection;
};

export type GetHorizonChannelsResult = HorizonChannel[];

export const GET_HORIZONS_ENDPOINT_KEY = 'getHorizonChannels';

export const { useGetHorizonChannelsQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_HORIZONS_ENDPOINT_KEY]: build.query<GetHorizonChannelsResult, GetHorizonChannelsQuery>({
      transformResponse: (response: { horizon_channels: GetHorizonChannelsResult }) => response.horizon_channels,
      query: ({
        projection = { id: true },
        name,
        id,
      }) => ({
        body: {
          query: {
            horizon_channels: {
              __args: cleanArguments({
                id,
                name: name ? `*${name}*` : undefined,
              }),
              ...projection
            }
          }
        }
      })
    }),
  }),
});
