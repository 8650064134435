/* eslint-disable react/display-name */
/* eslint-disable no-sparse-arrays */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * SelectIndicators
 */

import React, { forwardRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Table } from 'antd';
import { Button } from 'components/Button';
import { Popover } from 'components/Popover';
import { Skeleton } from 'components/Skeleton';
import { difference, uniq } from 'lodash';
import isExternalUser from 'utils/helpers/isExternalUser';
import { useIndicators } from 'utils/hooks';
import { createReportIndicator, deleteReportIndicator, Report } from 'features/entitiesRedux';
import { checkRefreshedIndicators } from 'features/reports/reportsSlice';
import { titleChange } from 'features/reports/ReportTabs/components/utils/titleChange';
import css from './SelectIndicators.module.scss';

type Indicator = {
  id: number;
  name: string;
  slug: string;
}
interface SelectIndicatorsProps {
  reportIndicators?: Indicator[];
  report?: Report;
  className?: string;
  updateKpiValues?: () => void;
  triggerLoading?: () => void;
  ids?: number[];
  disabled?: boolean;
}

export const SelectIndicators = forwardRef(({ report, ids, triggerLoading, updateKpiValues, disabled }: SelectIndicatorsProps, ref: any) => {
  const isClient = isExternalUser();
  const dispatch = useDispatch();
  const { indicators, loading } = useIndicators({});
  const [skeleton, setSkeleton] = useState<boolean>(false);
  const [sortedIndicators, setSortedIndicators] = useState<any>();
  const [selectedMetrics, setSelectedMetrics] = useState<any>([]);
  const [initialMetrics, setInitialMetrics] = useState<any>([]);

  const [visible, setVisible] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const createMarkup = (value: string) => {
    return {__html: value};
  };

  useEffect(() => {
    if (indicators) {
      const keys = uniq(indicators?.map((indicator: any) => indicator?.category));
      const sorted = keys?.map((key: string) => {
        const filtered = indicators?.filter((indicator: any) => indicator?.category === key);
        return filtered;
      });
      const sortedWithDescription = sorted?.map((item: any) => {
        return item?.map((val: any) => {
          return {
            ...val,
            description: <Popover placement='right' overlayClassName={css.popover} content={
              <>
                <div dangerouslySetInnerHTML={createMarkup(val?.tooltip)} />
                {handleEquationFormat(
                  (val?.slug === 'conversion-rate' && report?.business_type?.slug === 'ecomm')
                    ? 'Transactions/Sessions'
                    : val?.equation
                )}
              </>
            }><InfoCircleOutlined /></Popover>
          };
        });
      });
      setSortedIndicators(sortedWithDescription);
    }
  }, [indicators]);

  const showModal = () => setVisible(true);

  const handleOk = async () => {
    const indicatorsToDelete = difference(initialMetrics, selectedMetrics);
    const indicatorsToAdd = difference(selectedMetrics, initialMetrics);
    setSubmitting(true);
    if (report) {
      const { id } = report;
      if (indicatorsToDelete?.length) {
        await indicatorsToDelete?.forEach((value: any) => dispatch(deleteReportIndicator({ report_id: id, indicator_id: value })));
      }
      if (indicatorsToAdd?.length) {
        await indicatorsToAdd?.forEach((value: any) => dispatch(createReportIndicator({ report_id: id, indicator_id: value })));
      }
    }
    await setSubmitting(false);
    await setVisible(false);
    updateKpiValues && await updateKpiValues();
    if (report) await dispatch(checkRefreshedIndicators({ reportId: report.id }));
    triggerLoading && await triggerLoading();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (ids) {
      setInitialMetrics(ids);
      setSelectedMetrics(ids);
    }
  }, [ids]);

  useEffect(() => {
    if (indicators?.length) {
      setSkeleton(false);
    } else {
      setSkeleton(true);
    }
  }, [indicators]);

  return (
    <div ref={ref}>
      { !isClient ?
        <Button
          disabled={disabled}
          onClick={showModal}
          type="text"
          header
          icon={<EditOutlined />}
        >
          KPI Metrics
        </Button> :
        <></>
      }
      <Modal
        title="Select KPI Metrics"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        okButtonProps={{
          disabled: submitting || loading
        }}
        cancelButtonProps={{
          disabled: submitting
        }}
        width={600}
        className={css.modal}
      >
        {
          skeleton ?
            <>
              <Skeleton loading={skeleton} active />
              <Skeleton loading={skeleton} active />
              <Skeleton loading={skeleton} active />
            </> :
            sortedIndicators?.map((indicator: any, index: number) => {

              return (
                <Table
                  className={css.table}
                  key={`${indicator?.map((item: any) => item?.category)[0]}-${index}`}
                  size="small"
                  rowSelection={{
                    type: 'checkbox',
                    hideSelectAll: true,
                    onSelect: (record, selected) => {
                      if (selected) {
                        setSelectedMetrics([...selectedMetrics, record?.id]);
                      }
                      if (!selected) {
                        const updated = selectedMetrics.filter((key: number) => key !== record?.id);
                        setSelectedMetrics(updated);
                      }
                    },
                    getCheckboxProps: (metric: any) => {
                      if (report?.google_analytics_auth_method !== 'oauth') {
                        return ({
                        /**
                         * TODO: Remove this when these metrics become available
                         */
                          disabled:
                            metric?.slug === 'customers' ||
                            metric?.slug === 'opportunities' ||
                            metric?.slug === 'sqls' ||
                            metric?.slug === 'ga-users' ||
                            metric?.slug === 'ga-returning-users' ||
                            metric?.slug === 'ga-new-users-rate' ||
                            metric?.slug === 'ga-returning-users-rate'
                        });
                      } else {
                        return ({
                        /**
                         * TODO: Remove this when these metrics become available
                         */
                          disabled:
                            metric?.slug === 'customers' ||
                            metric?.slug === 'opportunities' ||
                            metric?.slug === 'sqls'
                        });
                      }
                    },
                    selectedRowKeys: selectedMetrics
                  }}
                  pagination={false}
                  columns={[
                    {
                      title: indicator?.map((item: any) => item?.category)[0],
                      dataIndex: 'name',
                      width: '90%',
                      className: css.label,
                    },
                    {
                      title: <>&nbsp;</>,
                      dataIndex: 'description',
                      className: css.description
                    }
                  ]}
                  onRow={(metric) => ({
                    onClick: () => {
                      if (report?.google_analytics_auth_method !== 'oauth') {
                        switch (metric?.slug) {
                          /**
                           * TODO: Remove this when these metrics become available
                           */
                          case 'customers':
                          case 'opportunities':
                          case 'sqls':
                          case 'ga-users':
                          case 'ga-returning-users':
                          case 'ga-new-users-rate':
                          case 'ga-returning-users-rate':
                            break;
                          default:
                            if (selectedMetrics?.includes(metric?.key)) {
                              const newValues = selectedMetrics.filter((key: number) => key !== metric?.id);
                              setSelectedMetrics(newValues);
                            } else {
                              const newValues = [...selectedMetrics, metric?.id];
                              setSelectedMetrics(newValues);
                            }
                        }
                      } else {
                        switch (metric?.slug) {
                          /**
                           * TODO: Remove this when these metrics become available
                           */
                          case 'customers':
                          case 'opportunities':
                          case 'sqls':
                            break;
                          default:
                            if (selectedMetrics?.includes(metric?.key)) {
                              const newValues = selectedMetrics.filter((key: number) => key !== metric?.id);
                              setSelectedMetrics(newValues);
                            } else {
                              const newValues = [...selectedMetrics, metric?.id];
                              setSelectedMetrics(newValues);
                            }
                        }
                      }
                    },
                  })}
                  dataSource={indicator.map((item: any) => {
                    return {
                      key: item?.id,
                      ...item,
                      name: titleChange(item?.name),
                    };
                  })}
                />
              );
            })
        }
      </Modal>
    </div>
  );
});

const handleEquationFormat = (format: string) => {
  const arr = format?.split('/');
  return arr ? (
    <pre>
      = {arr[0]}
      <br />
      / {arr[1]}
    </pre>
  ) :
    <></>;
};
