import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Form, FormInstance } from 'antd';
import { useGetClientsQuery } from 'api/crudGraphQL/clients/getClients';
import { useGetHorizonChannelsQuery } from 'api/crudGraphQL/horizons/getHorizonChannels';
import { useGetHorizonRequirementsQuery } from 'api/crudGraphQL/intelligence/forecast/getHorizon';
import { HorizonRequirementType, HorizonType } from 'api/crudGraphQL/intelligence/forecast/types';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { Client, ClientIntegration } from 'features/entitiesRedux';
import { Button, FormItem, SelectClient } from 'components';
import { horizonChannelFilter } from '../../utils/horizonChannelFilter';
import { ChannelOverview } from '../ChannelOverview';
import { DateRange, SelectHorizonModels, TrainingMessage } from './components';
import InactiveIntegrations from './components/InactiveIntegrations/InactiveIntegrations';
import InsufficientData from './components/InsufficientData';
import MissingIntegrations from './components/MissingIntegrations';
import { GOOGLE_ANALYTICS, GOOGLE_ANALYTICS_4, ECOMMERCE, AD_NETWORKS } from './constants';
import css from './HorizonForm.module.scss';
import { LABELS_TYPES } from "utils";

export type formattedHorizonFormData = {
  client_id?: number;
  start_date?: string | Moment;
  end_date?: string | Moment;
  model_client_id?: number;
}

type HorizonFormProps = {
  initialValues?: formattedHorizonFormData;
  clientData?: Client;
  forecastFetching?: boolean;
  forecastData?: HorizonType;
  isEdit?: boolean;
  onSubmit: (values: formattedHorizonFormData) => Promise<void>;
  handleSelectClient?: (clientId: number) => void;
  handleModelSelect?: (modelId: number) => void;
  setLength: (length: number | undefined) => void
  form: FormInstance;
  submitDisabled: boolean;
  clientLoading?: boolean;
  updateLoading?: boolean;
  isCreating?: boolean;
}

const HorizonForm = ({
  initialValues,
  clientData,
  clientLoading,
  forecastFetching,
  forecastData,
  isEdit,
  onSubmit,
  handleSelectClient,
  handleModelSelect,
  form,
  submitDisabled,
  setLength,
  updateLoading,
  isCreating
}: HorizonFormProps): JSX.Element => {

  const { data: horizonChannels } = useGetHorizonChannelsQuery({
    projection: {
      id: true,
      name: true,
      type: true,
    }
  });

  const { data: clientsData, isLoading: clientsLoading } = useGetClientsQuery({
    available_for_horizon_forecast: 1,
    limit: 999999,
    projection: {
      id: true,
      name: true,
    }
  }, {refetchOnMountOrArgChange: true});

  const requirementChangeDate = useMemo(() => moment('2024-07-01'), []);
  const currentDate = useMemo(() => moment(), []);
  const showGA = useMemo(() => currentDate < requirementChangeDate, [currentDate, requirementChangeDate]);
  //the array takes all integrations that are missing
  //keep this array in global scope so that it can be accessed by all the functions
  const missingIntegrations: string[] = [];


  /**
   * If client has Horizon model 0 with a status of 'failed' then show Insufficient Data alert
   */
  const horizonModelZero = clientData?.horizon_models?.filter(model => model?.version === 0)?.[0];
  const hasInsufficientData = !!horizonModelZero && horizonModelZero?.status === 'failed';

  /**
    * Checks if user has integrations required to create a forecast
    */
  const eCommerceIntegrations = useMemo(
    () => !!forecastData?.ecommerce?.some((item) => ['bigcommerce', 'magento', 'shopify'].includes(item.name)),
    [forecastData?.ecommerce]
  );

  const adNetworksIntegrations = useMemo(
    () =>
      !!forecastData?.adnetworks?.some((item) =>
        ['Ad Networks (Combined) via Funnel.io', 'Google Ads', 'Facebook Marketing'].includes(item.name)
      ),
    [forecastData?.adnetworks]
  );

  // if current date is before January 1, 2024, then both Google Analytics UA and GA4 are required.
  // if current date is on or after January 1, 2024, then only GA4 is required
  const analyticsIntegrations = useMemo(
    () =>
      currentDate < requirementChangeDate
        ? ['Google Analytics', 'Google Analytics 4'].every((name) =>
          forecastData?.analytics?.some((item) => item.name === name)
        )
        : !!forecastData?.analytics?.some((item) => ['Google Analytics 4'].includes(item.name)),
    [currentDate, requirementChangeDate, forecastData?.analytics]
  );

  const hasRequiredIntegrations = useMemo(
    () => !!forecastData && eCommerceIntegrations && adNetworksIntegrations && analyticsIntegrations,
    [forecastData, eCommerceIntegrations, adNetworksIntegrations, analyticsIntegrations]
  );

  const statusCompleted = 'completed';

  /**
   * If client is missing integrations render alert with what to do next
   * @returns {JSX.Element}
   */
  const checkMissingAdnetworksIntegration = useCallback((missingIntegrations: string[]) => {
    if (!forecastData?.adnetworks || forecastData?.adnetworks.length === 0) {
      missingIntegrations.push(AD_NETWORKS);
    }
  }, [forecastData?.adnetworks]);

  const checkMissingEcommerceIntegration = useCallback((missingIntegrations: string[]) => {
    if (!forecastData?.ecommerce || forecastData?.ecommerce.length === 0) {
      missingIntegrations.push(ECOMMERCE);
    }
  }, [forecastData?.ecommerce]);

  const checkMissingAnalyticsIntegration = useCallback((missingIntegrations: string[]) => {
    let hasGA = false;
    let hasGA4 = false;
    forecastData && forecastData?.analytics?.forEach((item) => {
      if (item.name === GOOGLE_ANALYTICS) {
        hasGA = true;
      }
      if (item.name === GOOGLE_ANALYTICS_4) {
        hasGA4 = true;
      }
    });
    if (!hasGA) {
      missingIntegrations.push(GOOGLE_ANALYTICS);
    }
    if (!hasGA4) {
      missingIntegrations.push(GOOGLE_ANALYTICS_4);
    }
  }, [forecastData]);

  const renderMissingIntegrationsAlert = useCallback(() => {
    //check if there is any missing adnetworks integration
    checkMissingAdnetworksIntegration(missingIntegrations);

    //check if there is any missing ecommerce integration
    checkMissingEcommerceIntegration(missingIntegrations);

    //check if there is any missing google analytics integration
    checkMissingAnalyticsIntegration(missingIntegrations);

    if (!hasRequiredIntegrations && clientData && forecastData && !clientLoading) {
      return <MissingIntegrations missingIntegrations={missingIntegrations} clientId={clientData?.id} showGA={showGA}/>;
    }
  }, [checkMissingAdnetworksIntegration, missingIntegrations, checkMissingEcommerceIntegration, checkMissingAnalyticsIntegration, hasRequiredIntegrations, clientData, forecastData, clientLoading, showGA]);


  /**
   * If client has inactive integrations render alert with what to do next
   * @returns {JSX.Element}
   */

  const checkInactiveAdnetworksIntegration = useCallback((
    inactiveIntegrations: string[],
    funnelAdnetworks?: HorizonRequirementType[],
    nonFunnelAdnetworks?: HorizonRequirementType[],
  ): void => {
    if (funnelAdnetworks?.length) {
      if (funnelAdnetworks?.[0]?.active === 0 || funnelAdnetworks?.[0]?.status !== statusCompleted) {
        inactiveIntegrations.push(AD_NETWORKS);
      }
    }
    if (nonFunnelAdnetworks?.length) {
      nonFunnelAdnetworks.forEach((item) => {
        if ((item.active === 0 || item.status !== statusCompleted) && !inactiveIntegrations?.includes(AD_NETWORKS)) {
          inactiveIntegrations.push(AD_NETWORKS);
        }
      });
    }
  }, []);

  const checkInactiveEcommerceIntegration = useCallback((inactiveIntegrations: string[]) => {
    forecastData?.ecommerce?.forEach((item) => {
      if (item.active === 0 || item.status !== statusCompleted) {
        inactiveIntegrations.push(ECOMMERCE);
      }
    });
  }, [forecastData?.ecommerce, statusCompleted]);

  const handleAnalyticsIntegration = useCallback(
    (inactiveIntegrations: string[], integrationName: string, shouldCheckActive: boolean): void => {
      let hasIntegration = false;
      let activeIntegration = false;

      forecastData && forecastData?.analytics?.forEach((item) => {
        if (item.name === integrationName) {
          hasIntegration = true;
          if (shouldCheckActive && item.active === 1 && item.status === statusCompleted) {
            activeIntegration = true;
          }
        }
      });

      if (hasIntegration && !activeIntegration) {
        inactiveIntegrations.push(integrationName);
      }
    },
    [forecastData]
  );

  const checkInactiveAnalyticsIntegration = useCallback(
    (inactiveIntegrations): void => {
      if (showGA) {
        handleAnalyticsIntegration(inactiveIntegrations, GOOGLE_ANALYTICS_4, true);
        if (missingIntegrations.includes(GOOGLE_ANALYTICS_4) || inactiveIntegrations.includes(GOOGLE_ANALYTICS_4)) {
          handleAnalyticsIntegration(inactiveIntegrations, GOOGLE_ANALYTICS, true);
        }
      } else {
        handleAnalyticsIntegration(inactiveIntegrations, GOOGLE_ANALYTICS_4, true);
      }
    },
    [handleAnalyticsIntegration, missingIntegrations, showGA]
  );

  const renderInactiveIntegrationsAlert = useCallback(() => {
    //the array takes all integrations that are inactive, then pass the array to the InactiveIntegrations component
    const inactiveIntegrations: string[] = [];

    //check if there is any inactive google analytics integration
    checkInactiveAnalyticsIntegration(inactiveIntegrations);

    //check if there is any inactive ecommerce integration
    checkInactiveEcommerceIntegration(inactiveIntegrations);

    //check if there is any inactive adnetworks integration
    const funnelAdnetworks = forecastData?.adnetworks?.filter(item => item.name.includes('via Funnel.io'));
    const nonFunnelAdnetworks = forecastData?.adnetworks?.filter(item => !item.name.includes(' via Funnel.io'));
    checkInactiveAdnetworksIntegration(inactiveIntegrations, funnelAdnetworks, nonFunnelAdnetworks);

    if (clientData && forecastData && !clientLoading && inactiveIntegrations?.length) {
      return <InactiveIntegrations inactiveIntegrations={inactiveIntegrations} clientId={clientData?.id}/>;
    }
  }, [clientData, clientLoading, forecastData, currentDate, requirementChangeDate, hasRequiredIntegrations]);

  const renderInsufficientDataAlert = useCallback(() => {
    if (clientData?.id && hasRequiredIntegrations && hasInsufficientData && !clientLoading) {
      return <InsufficientData clientId={clientData?.id} />;
    }
  }, [clientData?.id, hasRequiredIntegrations, hasInsufficientData, clientLoading]);

  const trainedModels = clientData?.horizon_models?.filter((model) => model?.version !== 0 && model?.status === 'completed');
  const clientIsTrainable = clientData?.id && hasRequiredIntegrations && !hasInsufficientData;
  const clientNotYetTrained = clientIsTrainable && !trainedModels?.length;
  const clientAlreadyTrained = clientIsTrainable && trainedModels?.length;

  const filteredAvailableChannels = horizonChannels?.filter(horizonChannelFilter);
  const filteredCurrentChannels = clientData?.horizon_channels?.filter(horizonChannelFilter);

  return (
    <>
      {!forecastFetching && (
        <>
          {renderMissingIntegrationsAlert()}
          {renderInactiveIntegrationsAlert()}
          {renderInsufficientDataAlert()}
        </>
      )}
      <div className={classNames(css.root, { [css.isEdit]: isEdit })}>
        <div className={css.title}>
          {isEdit ? `Edit ${LABELS_TYPES.CAPITALIZE_CLIENT} Details` : 'Create Forecast'}
        </div>
        {clientNotYetTrained ? <TrainingMessage/> : <></>}
        <Form
          layout='vertical'
          form={form}
          onFinish={onSubmit}
          initialValues={initialValues}
        >
          <div className={css.clientContainer}>
            <div className={css.client}>
              <FormItem name="client_id" label={LABELS_TYPES.CAPITALIZE_CLIENT} >
                <SelectClient
                  onChange={handleSelectClient}
                  skeleton={clientLoading || clientsLoading}
                  clientData={clientsData?.data}
                  disabled={isEdit}/>
              </FormItem>
            </div>
          </div>
          {clientData && (clientAlreadyTrained || isEdit) ?
            <>
              <div className={css.modelsContainer}>
                <div className={css.models}>
                  <FormItem name="model_client_id" label='Forecast Model' help='Select a model to create a forecast from' >
                    <SelectHorizonModels models={trainedModels} onChange={handleModelSelect} disabled={isEdit}/>
                  </FormItem>
                </div>
              </div>
              <DateRange form={form} setLength={setLength} models={clientData?.horizon_models} isEdit={isEdit}/>
              <div className={css.channelOverview}>
                <ChannelOverview currentChannels={filteredCurrentChannels} availableChannels={filteredAvailableChannels} />
              </div>
            </>
            :
            <></>
          }
          <div className={css.buttonsContainer}>
            <div className={classNames(css.createButton, {[css.disabled]: submitDisabled  && !clientNotYetTrained})}>
              <Button htmlType='submit' disabled={submitDisabled && !clientNotYetTrained} loading={updateLoading || isCreating}>
                {isEdit ? 'Save Changes' : clientNotYetTrained ? 'Start Model Training' : 'Create Forecast'}
              </Button>
            </div>
            {isEdit ?
              <></>
              :
              <div className={css.cancelButton}>
                <Link to='/intelligence/forecasts'>
                  <Button>
                  Cancel
                  </Button>
                </Link>
              </div>
            }
          </div>
        </Form>
        <div>
        </div>
      </div>
    </>
  );
};

export default HorizonForm;
