/**
* Update Notifications
*/

import { Pagination, Projection } from 'api/entityGraphQL/types';
import { Notification } from 'features/entitiesRedux/models/notification';
import tagTypes from './tagTypes';
import { baseApi, cleanArguments, paginationProjection } from '..';

export type UpdateNotificationsMutation = {
  is_new?: boolean;
  page?: string;
  limit?: number
  projection?: Projection;
};

export type UpdateNotificationsResult = Pagination & {
  data: Notification[]
};

export const UPDATE_NOTIFICATIONS_ENDPOINT_KEY = 'updateNotifications';

export const { useUpdateNotificationsMutation, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [UPDATE_NOTIFICATIONS_ENDPOINT_KEY]: build.mutation<UpdateNotificationsResult, UpdateNotificationsMutation>({
      transformResponse: (response: { notifications: UpdateNotificationsResult }) => response.notifications,
      query: ({ projection = { id: true }, ...values }) => {
        const __args: Partial<UpdateNotificationsMutation> = {
          ...values,
        };
        return {
          body: {
            mutation: {
              updateNotifications: {
                __args: cleanArguments(__args),
                data: projection,
                ...paginationProjection
              }
            }
          }
        };
      }
    }),
  }),
});
