/**
 * Entities -> Forecast -> Selectors
 */

import { EntityId } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { selectEntitiesDenormalized } from '../_entity/selectors';
import { forecastsAdapter } from './adapter';

export const forecastsSelectors = forecastsAdapter.getSelectors(
  (state: RootState) => state.entities.data.forecasts
);

export const selectAllForecasts = (state: RootState) => {
  const forecastIds = forecastsSelectors.selectIds(state);
  return selectForecastsByIds(forecastIds)(state);
};

export const selectForecastsByIds = (forecastIds: EntityId[]) => (
  state: RootState
) => {
  const input = { forecasts: forecastIds };
  return selectEntitiesDenormalized(state, input).forecasts;
};

export const selectForecastById = (forecastId: number) => (
  state: RootState
) => {
  return selectForecastsByIds([forecastId])(state)[0];
};