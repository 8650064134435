import { EntityId } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { selectEntitiesDenormalized } from '../_entity/selectors';
import { dashboardsAdapter } from './adapter';

export const selectDashboardsByIds = (dashboardIds: EntityId[]) => (state: RootState) => {
  const input = { dashboards: dashboardIds };
  return selectEntitiesDenormalized(state, input).dashboards;
};

export const selectDashboardById = (dashboardId: number) => (state: RootState) => {
  return selectDashboardsByIds([dashboardId])(state)[0];
};

export const selectDashboardNotesByIds = (dashboardNoteIds: number[]) => (state: RootState) => {
  const input = { dashboard: dashboardNoteIds };
  return selectEntitiesDenormalized(state, input).dashboard;
};

export const selectDashboardNoteById = (dashboardNoteId: number) => (state: RootState) => {
  return selectDashboardNotesByIds([dashboardNoteId])(state)[0];
};

export const dashboardSelectors = dashboardsAdapter.getSelectors(
  (state: RootState) => state.entities.data.dashboards
);

export const selectAllDashboards = (state: RootState) => {
  const dashboardIds = dashboardSelectors.selectIds(state);
  return selectDashboardsByIds(dashboardIds)(state);
};
