/**
 * SectionBuilder -> Fields -> SalesforceOpportunity
 */

import React, { FC } from 'react';
import { Projection } from 'api/entityGraphQL';
import { Audit, useAuditByParamId } from 'features/entitiesRedux/models/audit';
import { FormItem, SelectSalesforceOpportunity } from 'components';
import { FieldProps } from '../../types';

export const projection: Projection = {
  id: true,
  salesforce_opportunity_id: true,
  client: {
    id: true,
    salesforce_client_id: true,
  }
};

const SalesforceOpportunity: FC<FieldProps> = ({ name }) => {
  const audit: Audit = useAuditByParamId({ projection });
  return (
    <FormItem name={name}>
      <SelectSalesforceOpportunity
        salesforceClientId={audit?.client?.salesforce_client_id}
        allowClear
        disabled={!audit?.client?.salesforce_client_id}
      />
    </FormItem>
  );
};

export default SalesforceOpportunity;
