export enum YesNo {
  Yes = 'yes',
  No = 'no',
}

export enum SortDirectionToSymbol {
  DESCEND = '-', // Descending
  ASCEND = '+', // Ascending
}

export enum AscendDescend {
  ASCEND = 'ascend',
  DESCEND = 'descend',
}

export enum ViewMode {
  Read = 'read',
  Edit = 'edit',
}

export enum DraftPublished {
  Draft = 'draft',
  Published = 'published',
}

export const PROMISE_FULFILLED_RESULT_STATUS = 'fulfilled';

/**
 * Max number for a 32-bit integer
 */
export const MAX_NUMBER = 2147483647;
