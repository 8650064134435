/**
* Delete department
*/

import { baseApi } from 'api/crudGraphQL';
import { Projection } from 'api/entityGraphQL/types';
import { Department } from 'features/entitiesRedux/models/department/department';
import tagTypes from './tagTypes';

export type DeleteDepartmentMutation = {
  projection?: Projection;
  id: number;
}

export type DeleteDepartmentResult = Department;

export const { useDeleteDepartmentMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    deleteDepartment: build.mutation<DeleteDepartmentResult, DeleteDepartmentMutation>({
      transformResponse: (response: { deleteDepartment: Department }) =>  response.deleteDepartment,
      invalidatesTags: [tagTypes.DEPARTMENTS],
      query: ({ id, projection = {} }) => ({
        body: {
          mutation: {
            deleteDepartment: {
              __args: {
                id
              },
              ...projection,
              id: true
            }
          }
        }
      })
    }),
  }),
});
