import * as React from 'react';
import { styled, tableCellClasses, TableCellProps, TableCell as MuiTableCell} from '@mui/material';

const StyledTableCell = styled(MuiTableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderRight: 'solid 1px #d9d9d9',
    '&:last-child': {
      borderRight: 0,
    },
  },
}));

export const TableCell = ({
  children,
  ...props
}: TableCellProps): JSX.Element => {
  return (
    <StyledTableCell {...props}>
      {children}
    </StyledTableCell>
  );
};
