/**
 * SectionBuilder -> Fields -> BreakEvenAcquisitionCost
 */

import React, { FC, useEffect } from 'react';
import { FormItem, Input } from 'components';
import { MagicOutlined } from '../../../../../../../../../components/Icon';
import { formatCurrencyInput } from '../../../../../../../utils';
import { FieldProps } from '../../../types';
import css from '../../outputs_shared.module.scss';

const BreakEvenAcquisitionCost: FC<FieldProps> = ({ name, form }) => {
  // get form values for calculation
  const calculatedLTV = form.getFieldValue('output_ltv');
  const blendedProfitMargin = form.getFieldValue('question_blended_margin_ecomm');

  const calculateOutput = () => {
    // check if both values are truthy
    if (!!calculatedLTV && !!blendedProfitMargin) {
      // remove all non-numeric characters and cast as a number
      const ltv = typeof calculatedLTV === 'number'
        ? calculatedLTV
        : parseInt(calculatedLTV?.replace(/[^0-9]+/g, ''));
      // calculate output
      const output = '$ ' + formatCurrencyInput(ltv * (blendedProfitMargin / 100));
      return form.setFieldsValue({[name]: output});
    }
  };

  useEffect(() => {
    calculateOutput();
  }, [form, calculatedLTV, blendedProfitMargin]);

  return (
    <FormItem
      name={name}
      className={css.root}
      extra={
        <div className={css.extra}>Break-Even Acquisition Cost = LTV * Blended profit margin</div>
      }
    >
      <Input 
        addonAfter={
          <span role="img" className="anticon">
            <MagicOutlined />
          </span>
        }
        readOnly
      />
    </FormItem>
  );
};

export default BreakEvenAcquisitionCost;
