import * as React from 'react';
import { styled, TableRow as MuiTableRow} from '@mui/material';
import { tableRowClasses, TableRowProps } from '@mui/material/TableRow';

const StyledTableRow = styled(MuiTableRow)(({ theme }) => ({
  [`&.${tableRowClasses.head}`]: {
    backgroundColor: '#F4F4F4',
  },
}));

export const TableRow = ({
  children,
  ...props
}: TableRowProps): JSX.Element => {
  return (
    <StyledTableRow {...props}>
      {children}
    </StyledTableRow>
  );
};
