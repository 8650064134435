import React, { forwardRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { AppstoreAddOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Row, Form, Table, message, Checkbox, Radio } from 'antd';
// import arrayMove from 'array-move';
import classNames from 'classnames';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { Popover } from 'components/Popover';
import { Select } from 'components/Select';
import { uniq } from 'lodash';
import { useWidgets, useIndicators } from 'utils/hooks';
import useMixpanelTrack from 'utils/hooks/useMixpanelTrack';
import { Report } from 'features/entitiesRedux';
import { createWidget, selectReportPage } from 'features/reports/reportsSlice';
import { titleChange } from 'features/reports/ReportTabs/components/utils/titleChange';
import { rangeValues } from 'features/reports/Widgets/PrimaryKPIs/components';
import { handleJsonSettings } from 'features/reports/Widgets/SecondaryKPIs/utils';
import { getDescription, equationValues, equation } from '../TooltipPopover/KPITerms';
import css from './AddWidgetToReport.module.scss';

type AddWidgetToReportProps = {
  report?: Report;
  reportId?: number;
  refresh?: () => void;
  disabled?: boolean;
}
export const AddWidgetToReport = ({ report, reportId = 0, refresh, disabled }: AddWidgetToReportProps): JSX.Element => {
  const track = useMixpanelTrack();
  const dispatch = useDispatch();
  const { indicators } = useIndicators({});
  const reportsPage = useSelector(selectReportPage);
  const newlyCreatedWidgetId = reportsPage[reportId]?.newlyCreatedWidgetId;

  const { widgets, loading } = useWidgets();
  const [widget, setWidget] = useState<{ id: number, name: string } | null>();
  const [visible, setVisible] = useState<boolean>(false);
  const [activateScroll, setActiveateScroll] = useState<boolean>(false);

  const handleSelectWidget = (value: number) => {
    setWidget(widgets.filter((widget: any) => widget.id === value)?.[0]);
  };

  useEffect(() => {
    if (widgets) {
      handleSelectWidget(1006);
    }
  }, [widgets]);

  useEffect(() => {
    /** Scroll to newly-created widget */
    if (activateScroll && newlyCreatedWidgetId) {
      scrollTo(widgetIdName(widget?.id, newlyCreatedWidgetId));
      setActiveateScroll(false);
    }
  }, [newlyCreatedWidgetId, activateScroll]);

  const showModal = () => setVisible(true);
  const handleCancel = () => {
    setVisible(false);
    // setWidget(null);
  };
  const handleSubmit = async (values: any) => {
    if (reportId) {
      const isComparedTo = !!values?.compare;
      const { range, compare, type } = values;
      const kpis: any[] = [];
      const settings = handleJsonSettings({ metrics: { range, period: compare, type, isComparedTo } });
      setVisible(false);
      try {
        await dispatch(createWidget({
          reportId,
          widgetId: values.widgetId,
          title: values.title,
          position: 3, // Always set new widget after Status Update
          indicator_ids: values.otherValues,
          settings
        }));

        if (values.otherValues) {
          values.otherValues.map((x: number) => {
            const kpi = titleChange(indicators.filter(y => y.id === x)[0].name);
            kpis.push(kpi);
          });
        }
        // Track mixpanel "Create New Secondary KPI Widget Created"
        if (report) {
          track(`Create Scoreboard Widget: ${widget?.name}`, {
            title: report?.name,
            clientName: report?.client?.name,
            ...{
              scoreboardId: report?.id,
              kpis: kpis,
            }

          });
        }
        message.success('Widget added').then(() => setActiveateScroll(true));
      } catch (err) {
        message.error('An error occurred while adding widget. Please try again.');
        console.error('Error adding widget', err);
      }
    }
  };

  /**
   * TODO: Uncomment when more widgets are available
   */
  // useEffect(() => {
  //   if (!visible) setWidget(null);
  // }, [visible]);

  return (
    <>
      <Button
        type="text"
        size='large'
        header
        onClick={showModal}
        icon={<AppstoreAddOutlined />}
        disabled={disabled}
      >
          Add Widget
      </Button>
      <Modal
        title="Add Widget"
        visible={visible}
        destroyOnClose
        onCancel={handleCancel}
        closable
        footer={null}
        className={css.modal}
      >
        <Row justify='center'>
          <SelectWidget defaultValue={widget?.id} onSelectWidget={handleSelectWidget} />
        </Row>
        <Row className={css.widget}>
          {
            widget ? <CustomizeWidget widget={widget} onSubmit={handleSubmit} setVisible={setVisible} /> : <></>
          }
        </Row>
      </Modal>
    </>
  );
};

type SelectWidgetProps = {
  onSelectWidget?: (widget: any) => void;
  defaultValue?: number;
  className?: any;
}
export const SelectWidget = forwardRef(({ className, onSelectWidget, defaultValue, ...props}: SelectWidgetProps, ref: any) => {
  const { widgets, loading } = useWidgets();
  const [filteredWidgets, setFilteredWidgets] = useState<any>();
  const [widget, setWidget] = useState<any>();
  const handleSelect = (value: any) => {
    setWidget(value);
    if (onSelectWidget) {
      return onSelectWidget(value);
    }
  };

  useEffect(() => {
    setFilteredWidgets(widgets?.filter(widget => widget?.id === 1006));
  }, [widgets]);

  return (
    <Select
      // skeleton={loading || skeleton}
      ref={ref}
      onSelect={handleSelect}
      className={classNames(className)}
      placeholder="Select a widget"
      value={widget}
      defaultValue={defaultValue}
      disabled={!!defaultValue}
      {...props}
    >
      {filteredWidgets?.map((widget: any) => {
        return (
          <Select.Option key={widget.id} value={widget.id}>
            {widget.name}
          </Select.Option>
        );
      })}
    </Select>
  );
});

type CustomizeWidgetProps = {
  widget?: {
    id: number;
    name: string;
  };
  onSubmit?: (values: any) => void;
  setVisible?: any;
}
const CustomizeWidget = ({ widget, report, onSubmit, setVisible }: CustomizeWidgetProps & AddWidgetToReportProps) => {
  const maxLength = 40;
  const [form] = Form.useForm();
  const [otherValues, setOtherValues] = useState<any>();
  const [range, setRange] = useState<string>('last_30_days');
  const [compare, setCompare] = useState<string | null | undefined>('previous_period');
  const [type, setType] = useState<string>('day');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [items, setItems] = useState<any>();
  const [widgetTitle, setWidgetTitle] = useState<string>('Secondary KPIs');
  useEffect(() => {
    if (otherValues) {
      form.setFieldsValue({ otherValues: otherValues });
    }
    if (range) {
      form.setFieldsValue({ range: range });
    }
    if (compare) {
      form.setFieldsValue({ compare: compare });
    }
    if (type) {
      form.setFieldsValue({ type: type });
    }
  }, [otherValues, range, type]);
  const handleOnFinish = (values: { [x: string]: any }) => {
    if (onSubmit) {
      setIsLoading(true);
      onSubmit({ ...values });
    }
  };

  return (
    <>
      <Form
        form={form}
        layout='horizontal'
        onFinish={handleOnFinish}
        name="addWidgetToReport"
        autoComplete="off"
        className={css.addWidgetToReport}
      >
        <Form.Item hidden name='widgetId' initialValue={widget?.id} />
        <Form.Item
          name='title'
          label='Title'
          validateStatus="validating"
          help={widgetTitle.length >= maxLength ? `Max of ${maxLength} characters` : ''}
        >
          <Input
            placeholder='Secondary KPIs'
            maxLength={maxLength}
            onChange={(e) => setWidgetTitle(e.target.value)}
          />
        </Form.Item>
        {
          /**
           * Use this for other KPI widgets
           */
          metricsWidget({ name: widget?.name, setOtherValues, setRange, setCompare, setType, range, type, compare })
        }
        <Form.Item>
          <Button htmlType="submit" type="primary" disabled={otherValues?.length === 0 || isLoading}>
            Add Widget
          </Button>
          <Button onClick={() => setVisible(false)} type="text" disabled={isLoading}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

type metricsWidgetProps = {
  name?: string;
  setOtherValues?: React.Dispatch<React.SetStateAction<number[]>>;
  setRange?: React.Dispatch<React.SetStateAction<string>>;
  setCompare?: React.Dispatch<React.SetStateAction<string | undefined | null>>;
  setType?: React.Dispatch<React.SetStateAction<string>>;
  range?: string;
  type?: string;
  compare?: string | undefined | null;
}
const metricsWidget = ({ name, ...props }: metricsWidgetProps): Element | React.ReactFragment => {
  const { Group } = Radio;
  const { compare, range, setType, setRange, setCompare } = props;
  const { Option } = Select;
  const [isComparedTo, setIsComparedTo] = useState<boolean>(true);
  name = name?.toLowerCase();
  switch (name) {
    case 'secondary kpis':
      return (
        <>
          <Form.Item name='range' label='Range:'>
            <Select defaultValue={range} onChange={(e) => setRange && setRange(e)}>
              {
                rangeValues?.filter((value: any) => value.value !== 'custom').map((range: any) => <Option key={range.value} value={range.value}>{range.name}</Option>)
              }
            </Select>
          </Form.Item>
          <Form.Item name='compare' label='Compared to:'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                style={{ marginRight: '10px' }}
                checked={isComparedTo}
                onChange={(e: any) => {
                  if (!e.target.checked && setCompare) {
                    setCompare(null);
                  }
                  if (e.target.checked && setCompare) {
                    setCompare('previous_period');
                  }
                  setIsComparedTo(e.target.checked);
                }} />
              <Select value={isComparedTo && compare} onChange={(e) => setCompare && setCompare(e)} disabled={!isComparedTo}>
                <Option value='previous_period'>Previous Period</Option>
                <Option value='previous_year'>Previous Year</Option>
              </Select>
            </div>
          </Form.Item>
          <Form.Item name='type' label='Group by:'>
            <Group
              options={[
                {label: 'Day', value: 'day'},
                {label: 'Week', value: 'week'},
                {label: 'Month', value: 'month'},
                {label: 'Year', value: 'year'},
              ]}
              onChange={(e: any) => setType && setType(e.target.value)}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          <Form.Item name='otherValues'>
            <SelectIndicators setOtherValues={props.setOtherValues} maxNumberOfSelections={4} />
          </Form.Item>
        </>
      );
    default:
      return <></>;
  }
};

type Indicator = {
  id: number;
  name: string;
  slug: string;
}
const SelectIndicators = forwardRef(({ setOtherValues, maxNumberOfSelections }: { setOtherValues: any, maxNumberOfSelections: number }, ref: any) => {
  const { indicators, loading } = useIndicators({});
  const [sortedIndicators, setSortedIndicators] = useState<any>();
  const [selectedMetrics, setSelectedMetrics] = useState<any>([]);
  const isDisabled = selectedMetrics?.length >= maxNumberOfSelections;

  useEffect(() => {
    if (indicators) {
      const keys = uniq(indicators?.map((indicator: any) => indicator?.category));
      const sorted = keys?.map((key: string) => {
        const filtered = indicators?.filter((indicator: any) => indicator?.category === key);
        return filtered;
      });
      const sortedWithDescription = sorted?.map((item: any) => {
        return item?.map((val: any) => {
          return {
            ...val,
            description: <Popover placement="right" overlayClassName={css.popover} content={
              <>
                {getDescription(val?.slug)}
                {equation(equationValues(val?.slug))}
              </>
            }><InfoCircleOutlined /></Popover>
          };
        });
      });
      setSortedIndicators(sortedWithDescription);
    }
  }, [indicators]);

  useEffect(() => {
    setOtherValues(selectedMetrics);
  }, [selectedMetrics]);
  return (
    <>
      {isDisabled && <Alert message={<>A maximum of {maxNumberOfSelections} metrics may be selected</>} type="warning" />}
      {
        sortedIndicators?.map((indicator: any, index: number) => {
          return (
            <Table
              className={classNames({[css.maxNumReached]: isDisabled})}
              key={`${indicator?.map((item: any) => item?.category)[0]}-${index}`}
              size='small'
              pagination={false}
              rowSelection={{
                type: 'checkbox',
                hideSelectAll: true,
                getCheckboxProps: (metric: any) => ({
                  /**
                   * Won't allow for > 4 checks while still allowing deselection
                   */
                  disabled: (isDisabled && !selectedMetrics?.includes(metric?.key)) ||
                  metric?.slug === 'customers' || metric?.slug === 'opportunities' || metric?.slug === 'sqls'
                }),
                onSelect: (record, selected) => {
                  if (selected) {
                    setSelectedMetrics([...selectedMetrics, record?.id]);
                  }
                  /**
                   * Handles deselection
                   */
                  if (!selected) {
                    const updated = selectedMetrics.filter((key: number) => key !== record?.id);
                    setSelectedMetrics(updated);
                  }
                },
                selectedRowKeys: selectedMetrics
              }}
              onRow={(metric) => ({
                /**
                 * Allows for clicking on the row as well as the checkbox
                 */
                onClick: () => {
                  switch (metric?.slug) {
                  /**
                  * TODO: Remove this when these metrics become available
                  */
                    case 'customers':
                    case 'opportunities':
                    case 'sqls':
                      break;
                    default:
                      if (selectedMetrics.includes(metric?.key)) {
                        const newValues = selectedMetrics.filter((key: number) => key !== metric?.id);
                        setSelectedMetrics(newValues);
                      } else if (selectedMetrics?.length < maxNumberOfSelections) {
                        const newValues = [...selectedMetrics, metric?.id];
                        setSelectedMetrics(newValues);
                      }
                  }
                }
              })}
              columns={[
                {
                  title: indicator?.map((item: any) => item?.category)[0],
                  dataIndex: 'name',
                  width: '90%',
                  className: css.label
                },
                {
                  title: <>&nbsp;</>,
                  dataIndex: 'description',
                  className: css.description
                }
              ]}
              dataSource={indicator?.map((item: any) => {
                return {
                  key: item?.id,
                  ...item,
                  name: titleChange(item?.name),
                };
              })}
            />
          );
        })
      }
    </>
  );
});

// const SortableItem = SortableElement(({value}: any) => {
//   const title = value?.slug === 'secondary-kpis' ? value?.data[0]?.title : value?.name;
//   return (
//     <li className={css.sortableElement} tabIndex={0}>{title}</li>
//   );
// });


// const SortableList = SortableContainer(({items}: any) => {
//   return (
//     <ul className={css.sortableContainer}>
//       {items?.map((value: any, index: number) => (
//         <SortableItem key={`item-${value.id}`} index={index} value={value} />
//       ))}
//     </ul>
//   );
// });

// const SortableComponent = ({items, setItems}: any) => {
//   const onSortEnd = ({oldIndex, newIndex}: any) => {
//     if (setItems) {
//       setItems(arrayMove(items, oldIndex, newIndex));
//     }
//   };
//   return (
//     <SortableList items={items} onSortEnd={onSortEnd} />
//   );
// };

const widgetIdName = (id?: number, widgetId?: number) => {
  switch (id) {
    case 1006:
    default:
      return `#secondaryKpis-${widgetId}`;
  }
};

const scrollTo = (id: string) => {
  const section = document.querySelector(id);
  section?.scrollIntoView( { behavior: 'smooth' } );
};
