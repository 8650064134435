import tagTypes from './tagTypes';
import { basePublicApi } from '..';
import { cleanArguments } from 'api/crudGraphQL/utils';
import { Projection } from 'api/entityGraphQL';

export type CreateFivetranConnectorPublicMutation = {
  group_id: string;
  service: string;
  is_created_by_client?: boolean;
  attributions?: string;
  auth_email?: string;
  customer_id?: string;
  account_id?: string;
  property_id?: string;
  site_url?: string;
  projection?: Projection;
};

export type CreateFivetranConnectorPublicResult = {
  connector_id: string;
  connector_card_uri: string;
  schema_name: string;
};

/**
 * Custom hook for creating a new Fivetran connector.
 * This mutation is integrated into the basePublicApi's endpoints using RTK Query.
 */
export const { useCreateFivetranConnectorPublicMutation } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    createFivetranConnectorPublic: build.mutation<CreateFivetranConnectorPublicResult, CreateFivetranConnectorPublicMutation>({
      transformResponse: (response: { createFivetranConnector: CreateFivetranConnectorPublicResult }) => response.createFivetranConnector,
      invalidatesTags: [tagTypes.FIVETRAN_CONNECTOR],
      query: ({
        projection = { connector_id: true, connector_card_uri: true, schema_name: true },
        attributions = '',
        ...args
      }) => {
        const __args: Partial<CreateFivetranConnectorPublicMutation> = args;

        return {
          body: {
            mutation: {
              createFivetranConnector: {
                __args: cleanArguments(__args),
                ...projection,
              },
            },
          },
        };
      },
    }),
  }),
});
