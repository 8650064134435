import React, { memo } from 'react';
import { SxProps } from '@mui/material';
import { Grid, Typography, TypographyProps } from '@sprnova/nebula';

export const getRatingAndColor = (score: number): { rating: string; color: string } => {
  let rating = '';
  let color = '';
  if (score >= 7) {
    rating = 'High';
    color = '#2E7D32';
  } else if (score >= 4) {
    rating = 'Medium';
    color = '#ED6C02';
  } else if (score >= 0) {
    rating = 'Low';
    color = '#D32F2F';
  }
  return { rating, color };
};

type AffinityScoreProps = {
  score?: number | string;
  sx?: SxProps;
  gridSx?: SxProps;
  variant?: TypographyProps['variant'];
};

const AffinityScore = ({ score, sx, gridSx, variant = 'body1' }: AffinityScoreProps): JSX.Element => {
  if (!score) return <></>;
  const value = getRatingAndColor(Number(score));
  return (
    <Grid container flexDirection='row' sx={gridSx}>
      <Typography
        variant={variant}
        sx={{
          fontWeight: 700,
          color: `${value.color}!important`,
          marginBottom: '0!important',
          ...sx
        }}
      >
        {(Number(score) * 10).toFixed(2)}%&nbsp;
      </Typography>
      <Typography
        variant={variant}
        sx={{
          fontStyle: 'italic',
          marginBottom: '0!important',
          ...sx
        }}
      >
        ({value.rating})
      </Typography>
    </Grid>
  );
};

export default memo(AffinityScore);
