import { LookerCategory, LookerStandardReport } from 'features/entitiesRedux';

/**
 * Find a Looker report by name within an array of Looker categories.
 *
 * @param categories An array of Looker categories, each of which may have zero or more Base, Standard, or Custom reports.
 * @param name The Looker name of the report that is to be found within the categories.
 * @returns The reference to the found Looker report whose Looker name is equal to the given name; `undefined` otherwise.
 */
export const getReportByName = (categories: LookerCategory[], name: string): LookerStandardReport | undefined => {
  const reports = categories.map((category) => [
    ...category?.looker_base_reports ?? [],
    ...category?.looker_standard_reports ?? [],
    ...category?.looker_custom_reports ?? [],
  ]).flat();

  return reports.find((report) => report.looker_name === name);
};
