import { Projection } from 'api/entityGraphQL/types';

export const salesforceOpportunityProjection: Projection = {
  id: true,
  name: true,
  stage: true,
  display_value: true,
};

export const getNovaOpportunitiesProjection: Projection = {
  id: true,
  client: {
    id: true,
  },
  salesforce_id: true,
  salesforce_name: true,
  strategy: {
    id: true,
    client: {
      id: true,
      name: true,
    },
    date: true,
    pillar: {
      id: true,
      name: true
    },
    pricing_version: {
      id: true,
      slug: true,
    },
    name: true,
  }
};

