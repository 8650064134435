import React, { forwardRef } from 'react';
import { UpOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { scrollTop } from '../../features/audits/utils';
import css from './ScrollTopButton.module.scss';

interface ScrollTopButtonProps {
  className?: string;
}

const ScrollTopButton = forwardRef(({ className, ...props }: ScrollTopButtonProps, ref: any) => {
  return (
    <div className={classNames(css.root, className)}>
      <button
        className={css.scrollTopButton}
        ref={ref}
        onClick={() => scrollTop()}
        {...props}
      >
        <UpOutlined />
      </button>
    </div>
  );
});

ScrollTopButton.displayName = 'ScrollTopButton';
export default ScrollTopButton;
