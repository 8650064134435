import React, { memo } from 'react';
import { Card, CardHeader, theme } from '@sprnova/nebula';
import { PacingGoal } from 'api/crudGraphQL/scoreboards/types';
import Platforms from '../../components/Platforms';

const PlatformCardHeaderBGColor = theme.variables?.colors.neutrals.ghost[300];

interface PlatformCardProps {
  platforms?: PacingGoal[];
  format?: string | null;
}

const PlatformCard = ({ platforms, format }: PlatformCardProps): JSX.Element => {
  return (
    <Card
      container
      width='22.75%'
      header={
        <CardHeader
          title="Platforms"
          bgcolor={PlatformCardHeaderBGColor}
          borderRadius="8px 8px 0 0"
          padding="12px 16px !important"
          textAlign="left"
        />
      }
      sx={{
        position: 'absolute',
        mt: 1,
        backgroundColor: 'white',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 2,
        paddingBottom: '16px',
      }}
    >
      <Platforms data={platforms} format={format} />
    </Card>
  );
};

export default memo(PlatformCard);
