/**
 * Get feature flags
 */

import { Projection } from 'api/entityGraphQL/types';
import { FeatureFlag } from 'features/entitiesRedux/models/feature_flag';
import tagTypes from './tagTypes';
import { baseApi, cleanArguments } from '../';

export type GetFeatureFlagsQuery = {
  projection?: Projection;
  client_id?: number;
  name?: string;
};

export type GetFeatureFlagsResult = FeatureFlag[];

export const GET_FEATURE_FLAGS_ENDPOINT_KEY = 'getFeatureFlags';

export const { useGetFeatureFlagsQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_FEATURE_FLAGS_ENDPOINT_KEY]: build.query<GetFeatureFlagsResult, GetFeatureFlagsQuery>({
      transformResponse: (response: { feature_flags: GetFeatureFlagsResult }) => response.feature_flags,
      providesTags: (feature_flags = []) => [
        tagTypes.FEATURE_FLAGS,
        ...feature_flags.map(featureFlag => ({
          id: featureFlag.id,
          type: tagTypes.FEATURE_FLAG
        }))
      ],
      query: ({
        projection = { id: true, name: true },
        client_id,
        ...args
      }) => {
        const __args: Partial<GetFeatureFlagsQuery> = {
          client_id,
          ...args,
        };

        return {
          body: {
            query: {
              feature_flags: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        };
      }
    }),
  }),
});
