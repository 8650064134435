/**
 * Rules -> List
 */

import React, { FC, memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { Action } from 'api/accessControl';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { Resource, Rule } from 'features/entitiesRedux';
import { useAccessControl } from 'features/global/hooks/useAccessControl';
import { useAccount } from 'features/global/hooks/useAccount';
import DeleteButton from 'features/library/components/DeleteButton';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import { Button, Table, Tooltip } from 'components';
import { limitChars } from '../../../../../../utils';
import css from './List.module.scss';

type Props = {
  rules: Rule[];
  loading: boolean;
  onDelete?: (rule: Pick<Rule, 'id'>) => void;
  pagination?: false | TablePaginationConfig;
};

const RulesList: FC<Props> = ({ rules, loading, onDelete, pagination }) => {
  const [pricingVersionQueryParam] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );
  const { account } = useAccount();
  const { can } = useAccessControl();
  const canDelete = can(Action.delete, Resource.rule, account?.id);
  const canEdit = can(Action.update, Resource.rule, account?.id);

  const renderActions = (value: string, rule: Rule) => (
    <div className={css.actions}>
      <DeleteButton
        model={rule}
        onDelete={onDelete}
        type='rule'
        disabled={!canDelete}
      />

      <Link to={`/library/rules/${rule.id}/edit?pricingVersion=${pricingVersionQueryParam}`}>
        <Button type="text" icon={<EditOutlined />} disabled={!canEdit}/>
      </Link>
    </div>
  );

  const nameSorter = (a: any, b: any) => `${a.name}`.localeCompare(b.name);

  const renderName = (name: string, rule: Rule) => (
    <Link to={`/library/rules/${rule.id}/edit?pricingVersion=${pricingVersionQueryParam}`}>
      <Tooltip title={name?.length > 100 ? name : null}>
        {limitChars({input: name, limit: 100})}
      </Tooltip>
    </Link>
  );

  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: renderName,
      width: 500,
      ellipsis: true,
      sorter: nameSorter,
    },
    {
      title: '',
      key: 'actions',
      dataIndex: 'actions',
      width: 130,
      render: renderActions,
    },
  ];

  return (
    <Table
      loading={loading}
      rowKey="id"
      columns={columns}
      dataSource={rules}
      scroll={{ x: 1100 }}
      pagination={pagination}
    />
  );
};

export default memo(RulesList);
