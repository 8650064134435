import { Projection } from 'api/entityGraphQL/types';
import { NovaAIResponseType } from 'features/entitiesRedux/models/nova_ai';
import { basePublicApi } from '../basePublicApi';
import { cleanArguments } from 'api/crudGraphQL/utils';

export type StartNovaAIPromptPublicMutation = {
  client_id: number;
  question: string;
  debug?: string;
  projection?: Omit<Projection, 'id'>;
  prompt_guid?: string;
  session_guid?: string;
}

export type StartNovaAIPromptResult = NovaAIResponseType;

export const START_NOVA_AI_PROMPT_PUBLIC_ENDPOINT_KEY = 'startNovaAIPromptPublic';

export const { useStartNovaAIPromptPublicMutation } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [START_NOVA_AI_PROMPT_PUBLIC_ENDPOINT_KEY]: build.mutation<StartNovaAIPromptResult, StartNovaAIPromptPublicMutation>({
      transformResponse: (response: { startNovaAIPrompt: StartNovaAIPromptResult }) =>  response.startNovaAIPrompt,
      query: ({ projection = {response: true}, ...values }) => {
        const __args: Partial<StartNovaAIPromptPublicMutation> = values;
        return ({
          body: {
            mutation: {
              startNovaAIPrompt: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        });
      }
    }),
  }),
});
