/**
 * Syncs Salesforce data to Blueprint.
 */

import { Projection } from 'api/entityGraphQL';
import { Strategy } from 'features/entitiesRedux';
import { baseApi, cleanArguments } from '../../..';

export type SyncSalesforceToBlueprintMutation = {
  id: number;
  projection?: Projection;
};

export type SyncSalesforceToBlueprintResult = Strategy;

export const SYNC_SALESFORCE_TO_BLUEPRINT_ENDPOINT_KEY = 'syncSalesforceToBlueprint';

export const { useSyncSalesforceToBlueprintMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [SYNC_SALESFORCE_TO_BLUEPRINT_ENDPOINT_KEY]: build.mutation<SyncSalesforceToBlueprintResult, SyncSalesforceToBlueprintMutation>({
      transformResponse: (response: { syncSalesforceToBlueprint: Strategy }) => response.syncSalesforceToBlueprint,
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<SyncSalesforceToBlueprintMutation> = {
          ...values
        };
        return {
          body: {
            mutation: {
              syncSalesforceToBlueprint: {
                __args: cleanArguments(__args),
                id: true
              }
            }
          }
        };
      }
    })
  })
});
