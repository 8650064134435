import React, { FC } from 'react';
import loadable from '@loadable/component';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';
import { Skeleton } from 'components';
import { Status } from '../../components/Status';
import { useGetAnalystSurveyQuestionByUrlParam } from '../../hooks/useGetAnalystSurveyQuestionByUrlParam';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LoadableComponent: any = loadable(
  () => import('./EditAnalystQuestionPage'),
  {
    fallback: <PageSkeleton />
  }
);

const Title: FC = () => {
  const {
    data: analystQuestion,
    isLoading,
  } = useGetAnalystSurveyQuestionByUrlParam();

  if (!analystQuestion && isLoading) {
    return <Skeleton title={{ width: 150 }} paragraph={false} active />;
  }

  return <>{!analystQuestion ? 'Not found' : <>{analystQuestion.name}<Status status={analystQuestion.status} circle hideLabel /></>}</>;
};

LoadableComponent.Title = Title;

export { LoadableComponent as EditAnalystQuestionPage };
