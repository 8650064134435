import React, { FC, useCallback, useMemo } from 'react';
import { HorizonChannel, HorizonFunnel } from 'features/entitiesRedux/models/horizon';
import { Button, Modal } from 'components';
import { BudgetTable, BudgetTableProps, BudgetTableValue } from '../../BudgetTable';
import { getBudgetTableMatrix } from '../../utils';

export type CopyPastePreviewModalProps = {
  onClose: () => void;
  isSaving: boolean;
   channels: HorizonChannel[];
  funnels: HorizonFunnel[];
  dates: string[];
  length: number;
  startDate: string;
  currentData: BudgetTableProps['data'];
  onSave: (data: BudgetTableProps['data']) => void;
  pasteData: {
    xIndexOffset: number;     // xIndex of the cell where the paste was triggered
    yIndexOffset: number;     // yIndex of the cell where the paste was triggered
    parsedValues: number[][]; // Parsed pasted values
  } | null;
}

export const CopyPastePreviewModal: FC<CopyPastePreviewModalProps> = ({ 
  onClose,
  isSaving,
  pasteData, 
  startDate, 
  funnels, 
  channels, 
  dates, 
  length,
  currentData,
  onSave
}) => {
  // Match the value matrixes of the pasted data and the current data
  // to create an array of values that will be passed to the BudgetTable
  const newValues = useMemo(() => {
    if (!pasteData) {
      return [];
    } 

    // Note: The x- and y index of the cell the user inserted the data into
    const { xIndexOffset, yIndexOffset, parsedValues } = pasteData;
    const budgetTableMatrix = getBudgetTableMatrix(dates, channels, funnels);

    return parsedValues.reduce<BudgetTableValue[]>((acc, row, rowIndex) => [
      ...acc,
      ...row.reduce<BudgetTableValue[]>((accCells, value, cellIndex) => {
        const xIndex = xIndexOffset + cellIndex;
        const yIndex = yIndexOffset + rowIndex;
        const cell = budgetTableMatrix[yIndex]?.[xIndex];

        if (!cell) {
          return accCells;
        }

        const { date, funnel, channel } = cell;

        return [
          ...accCells,
          {
            date, 
            funnel, 
            channel,
            value,
            appearance: 'new' // Highlights the new values
          }
        ];
      }, [])
    ], []);
  }, [dates, funnels, channels, pasteData]);

  // Merge pasted values with existing values to create the full table
  const  mergedValues: BudgetTableValue[] = useMemo(() => {
    if (!newValues.length) {
      return [];
    }

    return [
      ...currentData.map((data): BudgetTableValue => ({...data, 
        appearance: 'faded' // Fade out the existing values
      })),
      ...newValues
    ];
  }, [currentData, newValues]);

  const handleSave = (): void => onSave(newValues);

  return (
    <Modal
      title="Please review the values you are about to paste"
      sub="New values are highlighted in green"
      visible={!!mergedValues.length}
      destroyOnClose
      onCancel={onClose}
      width={1350}
      closable
      footer={[
        <Button
          key="cancel"
          onClick={onClose}
          size="large"
          disabled={isSaving}
        >
        Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          size="large"
          disabled={isSaving}
          onClick={handleSave}
        >
          {isSaving ? 'Saving...' : 'Save'}
        </Button>,
      ]}
    >
      <BudgetTable 
        channels={channels}
        funnels={funnels}
        startDate={startDate}
        length={length}
        data={mergedValues}
      />
    </Modal>
  );
};