import React from 'react';

export const TalentAllCyclesLightIcon = () => (
  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="29.5" y="90.7988" width="16" height="48.5972" rx="8" transform="rotate(-180 29.5 90.7988)" fill="#D9D9D9"/>
    <rect x="13.5" y="37.2012" width="16" height="85" rx="8" transform="rotate(-90 13.5 37.2012)" fill="#4A1FFF"/>
    <rect x="52.5" y="90.7988" width="16" height="48.5972" rx="8" transform="rotate(-180 52.5 90.7988)" fill="#D9D9D9"/>
    <rect x="98.5" y="90.7988" width="16" height="48.5972" rx="8" transform="rotate(-180 98.5 90.7988)" fill="#D9D9D9"/>
    <rect x="75.5" y="90.7988" width="16" height="48.5972" rx="8" transform="rotate(-180 75.5 90.7988)" fill="#D9D9D9"/>
    <circle cx="67.4507" cy="50" r="8" transform="rotate(-90 67.4507 50)" fill="#E65957"/>
    <circle cx="90.5" cy="50" r="8" transform="rotate(-90 90.5 50)" fill="#6051A1"/>
    <circle cx="21.5015" cy="50" r="8" transform="rotate(-90 21.5015 50)" fill="#FFA600"/>
    <circle cx="44.5015" cy="50" r="8" transform="rotate(-90 44.5015 50)" fill="#A94882"/>
  </svg>
);
