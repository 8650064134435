/**
 * Entities -> TalentSurveys -> Adapter
 */

import { createEntityAdapter } from '@reduxjs/toolkit';
import { compareField } from 'features/entitiesRedux/utils/compareField';
import { TalentSurvey } from './types/talent_survey';

/**
 * Redux toolkit entity adapter for storing/caching entities by id
 */
export const surveysAdapter = createEntityAdapter<TalentSurvey>({
  selectId: (user) => user.id,
  sortComparer: compareField('name'),
});
