import React, { FC, useRef } from 'react';
import {
  AppHeader,
  AppHeaderProps,
} from '@sprnova/nebula';
import { HelpDropdown, UserDropdown } from './components';
import NotificationsDropdown from './components/NotificationsDropdown/NotificationsDropdown';

interface HeaderProps extends AppHeaderProps {
  hideNotifications?: boolean;
  hideHelp?: boolean;
}

export const Header: FC<HeaderProps> = ({ hideHelp, hideNotifications, ...props}) => {
  const ref = useRef<HTMLDivElement>(null);

  const renderEnd = <>
    {!hideNotifications ? <NotificationsDropdown /> : null}
    {!hideHelp ? <HelpDropdown /> : null}
    <UserDropdown />
  </>;

  return (
    <AppHeader
      end={renderEnd}
      ref={ref}
      sticky
      {...props}
    />
  );
};

