import React, { type ProviderProps, useMemo, FC } from 'react';
import { type Config, init } from 'mixpanel-browser';
import { MixpanelContext, MixpanelContextType } from './MixpanelContext';

export interface MixpanelProviderProps extends Omit<ProviderProps<MixpanelContextType>, 'value'> {
  config?: Partial<Config>;
  name?: string;
  token?: string;
}

export const MixpanelProvider: FC<MixpanelProviderProps> = ({
  children,
  config: _config,
  name: _name,
  token = import.meta.env.REACT_APP_MIXPANEL_TOKEN,
}) => {
  const name = useMemo(() => _name ?? 'react-mixpanel-browser', [_name]);

  const config = useMemo(
    () => ({
      track_pageview: false, // Rarely makes sense to track page views in React apps
      ..._config,
    }),
    [_config],
  );

  const context = useMemo(
    () => (token ? init(token, config, name) : undefined),
    [config, name, token],
  );

  return <MixpanelContext.Provider value={context}>{children}</MixpanelContext.Provider>;
};