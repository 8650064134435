import React from 'react';

export const KeyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 71.39 75.65"
    width="1em"
    height="1em"
    fill="currentColor"
    x="0px"
    y="0px"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M50.78,4C60,4,67.39,11.68,67.39,21.16S60,38.33,50.78,38.33a16.11,16.11,0,0,1-8.59-2.5L28,50.06s5.76,5.25,7,6.68a2.52,2.52,0,0,1,.26,3.15,2.78,2.78,0,0,1-2.45,1.45,3,3,0,0,1-1.89-.68L25.77,56l-6.34,6.34,4.28,4.39a3.35,3.35,0,0,1,.81,1.32A2.74,2.74,0,0,1,24,70.94a2.26,2.26,0,0,1-1.67.71,3.27,3.27,0,0,1-.79-.11,4,4,0,0,1-1.75-1.06L14,64.7,9.76,68.05A9.41,9.41,0,0,1,8.58,69a2.89,2.89,0,0,1-1.6.48,2.93,2.93,0,0,1-2.64-4.25,5,5,0,0,1,.72-1.06l32.7-32.32a17.42,17.42,0,0,1-3.6-10.65A16.89,16.89,0,0,1,50.78,4m0-4C39.41,0,30.16,9.49,30.16,21.16a21.27,21.27,0,0,0,2.49,10.07L2.25,61.28l-.11.11a8.91,8.91,0,0,0-1.35,2,6.78,6.78,0,0,0,.2,6.7,7.07,7.07,0,0,0,6,3.39,6.9,6.9,0,0,0,3.75-1.11,12.82,12.82,0,0,0,1.62-1.23l1.3-1,3.26,3.25a8,8,0,0,0,3.54,2.09,7.26,7.26,0,0,0,1.83.25,6.32,6.32,0,0,0,4.53-1.91,6.65,6.65,0,0,0,1.52-6.88A7.28,7.28,0,0,0,26.58,64l-1.53-1.57.86-.85,2.32,2.1a7,7,0,0,0,4.57,1.71,7.37,7.37,0,0,0,1.48-.16,6.86,6.86,0,0,0,4.43-3.28A6.53,6.53,0,0,0,38,54.09c-.73-.83-2.57-2.58-4.25-4.15L43,40.73a20.08,20.08,0,0,0,7.83,1.6c11.36,0,20.61-9.5,20.61-21.17S62.14,0,50.78,0Z"/>
        <path
          d="M50.89,33.46a12.22,12.22,0,0,1-12-12.36,12,12,0,1,1,24.06,0A12.22,12.22,0,0,1,50.89,33.46Zm0-20.72a8.21,8.21,0,0,0-8,8.36,8,8,0,1,0,16.06,0A8.21,8.21,0,0,0,50.89,12.74Z"/>
      </g>
    </g>
  </svg>
);