
import { DataSource } from 'features/entitiesRedux';
import { defaultDataSourceProjection } from 'features/entitiesRedux/models/data_source/projections';
import { getClient, toGraphQL } from '../utils';



export const getAvailableDataSourcesQuery = async (): Promise<{ datasources: DataSource[] }> => {
  // GraphQL client.
  const graphQlClient = getClient();

  const query = toGraphQL({
    query: {
      accounts: {
        __args: { is_visible: true },
        ...defaultDataSourceProjection,
      },
    },
  });

  const { accounts } = await graphQlClient.sendRequest(query);

  return {
    datasources: accounts,
  };
};
