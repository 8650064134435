/**
 * Get client
 */

import { Projection } from 'api/entityGraphQL/types';
import { Client } from 'features/entitiesRedux';
import { baseApi } from '..';
import { GetClientsResult } from './getClients';
import tagTypes from './tagTypes';

export type GetClientQuery = {
  id: number;
  projection: Projection;
  limit?: number;
  page?: number;
  external?: boolean;
}

export type GetClientResult = Client;

export const { useGetClientQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getClient: build.query<GetClientResult, GetClientQuery>({
      transformResponse: (response: { clients: GetClientsResult }) => response.clients?.data?.[0],
      providesTags: (client) => {
        return client ? [
          { id: client.id, type: tagTypes.CLIENT },
        ] : [tagTypes.CLIENT];
      },
      query: ({
        projection = { id: true },
        id,
        limit = 1,
        page = 1,
        external = false,
      }) => {
        const baseArgs: any = { id };

        // If not external, add limit and page
        if (!external) {
          baseArgs.limit = limit;
          baseArgs.page = page;
        }

        return {
          body: {
            query: {
              clients: {
                __args: baseArgs,
                data: projection,
              }
            }
          }
        };
      }
    })
  })
});
