import React, { Dispatch, SetStateAction, useMemo, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppSidebar, Button, LockIcon, NavigationInsightsFilledIcon, Spinner } from '@sprnova/nebula';
import { useGetNovaFeaturesPublicQuery } from 'api/crudGraphQL/public/nova_features/getNovaFeaturesPublic';
import classNames from 'classnames';
import NovaGptDrawer from 'features/clients/AiDashboard/components/NovaGptDrawer';
import NovaGPTDialog from 'features/clients/components/NovaGPTDialog';
import css from './NovaGptButton.module.scss';


type NovaGptButtonProps = {
  handleMixPanelEvent?: () => void;
  isInternal?: boolean;
  isSidebar?: boolean;
  size?: string;
  hideIcon?: boolean;
  variant?: 'plain' | 'gradient';
  bannerPrompt?: string;
  isFromBanner?: boolean;
  setIsFromBanner?: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
}

const NovaGptButton = ({isInternal, isSidebar, size, handleMixPanelEvent, hideIcon, variant = 'gradient', bannerPrompt = '', disabled = false, isFromBanner, setIsFromBanner}: NovaGptButtonProps): JSX.Element => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [novaGPTDialogOpen, toggleNovaGPTDialog] = useReducer((open) => !open, false);
  const { clientId: idRaw } = useParams<{ [x: string]: string }>();
  const clientId = parseInt(idRaw);

  const { data: nova_features = [], isLoading: isLoadingFeatures } = useGetNovaFeaturesPublicQuery({ client_id: clientId, projection: { id: true, name: true, slug: true, is_active: true, category: true } }, { skip: !clientId || isInternal });
  const isShowingNovaGPT = nova_features?.some(nova_feature => (nova_feature.slug === 'ask-novagpt'));
  const isActiveNovaGPT = nova_features?.some(nova_feature => (nova_feature.slug === 'ask-novagpt' && nova_feature.is_active === true));

  const handleClick = (): void | undefined => {
    const toggleButtonAction = isInternal ? (): void => setDrawerOpen(!drawerOpen) : !isActiveNovaGPT ? toggleNovaGPTDialog : (): void => setDrawerOpen(!drawerOpen);
    handleMixPanelEvent?.();
    toggleButtonAction();
    setIsFromBanner?.(true);
  };

  const isUnlocked = isInternal || isActiveNovaGPT;

  const icon = isUnlocked
    ? <NavigationInsightsFilledIcon />
    : <LockIcon />;

  const buttonClassNames = useMemo(() => classNames({
    [css.variantGradient]: variant === 'gradient',
    [css.variantPlain]: variant === 'plain',
    [css.variantDisabled]: disabled,
  }), [disabled, variant]);

  if (isLoadingFeatures) return <Spinner size="small" />;

  return isInternal || isShowingNovaGPT ? (
    <>
      {isSidebar ?
        <AppSidebar.Button
          size={size ?? undefined}
          icon={hideIcon ? <></> : icon}
          onClick={handleClick}
          className={isUnlocked ? buttonClassNames : undefined}
        >
      Ask nova
        </AppSidebar.Button> :
        <Button
          disabled={disabled}
          size={size ?? undefined}
          startIcon={hideIcon ? <></> : icon}
          style={{ padding: '14px 24px', color: 'white', whiteSpace: 'nowrap', textDecoration: 'none' }}
          onClick={!disabled ? handleClick : undefined}
          className={buttonClassNames}
        >
      Ask nova
        </Button>
      }
      <NovaGPTDialog open={novaGPTDialogOpen} toggle={toggleNovaGPTDialog} />
      <NovaGptDrawer isFromBanner={isFromBanner} setIsFromBanner={setIsFromBanner} bannerPrompt={bannerPrompt} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}/>
    </>
  )
    : <></>;
};
export default NovaGptButton;
