import React from "react";
import loadable from "@loadable/component";
import { Skeleton } from "antd";

const LoadableComponent = loadable(() => import("./SearchInput"), {
  fallback: (
    // eslint-disable-next-line react/react-in-jsx-scope
    <Skeleton.Input style={{ width: "189px" }} active size={"default"} />
  )
});

export { LoadableComponent as SearchInput };
