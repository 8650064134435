import React, { forwardRef } from 'react';
import { AutoComplete as AntdAutoComplete } from 'antd';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import classNames from 'classnames';
import { useClientsPaginated, Client } from 'features/entitiesRedux';
import css from './AutoCompleteClient.module.scss';

interface Props extends AutoCompleteProps {
  className?: string;
  skeleton?: boolean;
  onSelectClient?: (client: Partial<Client>) => void;
}

const AutoCompleteClient = forwardRef(
  ({ className, onSelectClient, ...props }: Props, ref: any) => {
    const { clients } = useClientsPaginated({
      page: 1,
      limit: 9999,
      trackHistory: false,
      projection: {
        id: true,
        name: true,
        website: true,
        business_type: {
          id: true,
          name: true,
        },
        industry: {
          id: true,
          name: true,
        },
        lead_source: {
          id: true,
          name: true,
        },
      },
    });

    function onSelect(value: any | undefined) {
      // console.log('AutoCompleteClient.onSelect', value);
      const client = clients.find((client) => {
        return client.id === Number.parseInt(value, 10);
      });
      // console.log('AutoCompleteClient.client', client);
      if (onSelectClient && client !== undefined) {
        return onSelectClient(client);
      }
    }

    return (
      <AntdAutoComplete
        className={classNames(className, css.root)}
        ref={ref}
        {...props}
        onSelect={onSelect}
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {(clients as (Partial<Client> & { id: number })[])
          .filter((client) => client.id !== undefined)
          .map((client) => (
            <AntdAutoComplete.Option
              key={client.name}
              value={String(client.id)}
            >
              {client.name}
            </AntdAutoComplete.Option>
          ))}
      </AntdAutoComplete>
    );
  }
);

export default AutoCompleteClient;
