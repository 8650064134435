import { getClient } from 'api/entityGraphQL/utils';
import { Service } from '../../service';
import { QuestionHandler } from '../actions';

export const servicesHandler: QuestionHandler = (id, key, value, additionalInfo): any => {
  const apiClient = getClient();
  if (Array.isArray(value)) {
    // filter down to services that have a value for in_survey
    const validServices = value
      ?.filter((service: Service & { modified?: boolean }) => {
        if (service.in_survey || service.modified) {
          return true;
        }
        return false;
      })
      .map((service: Service & { modified?: boolean }) => {
        const newService = {
          ...service,
          is_active: service.modified ? 'no' : 'yes',
        };
        if (!service.in_survey) {
          newService['in_survey'] = 'no';
        }
        delete newService.modified;

        return newService;
      }).map(svc => {
        // need to get rid of some keys so that the saveDiscoveryServices mutation accepts our argument
        const temp = {
          ...svc,
          modules: undefined,
          business_types: undefined,
          department: {
            id: svc.department.id,
            name: undefined,
            services: undefined
          },
        };
        delete temp.modules;
        delete temp.business_types;
        delete temp.department.name;
        delete temp.department.services;
        return temp;
      });

    return apiClient.saveDiscoveryServices({
      discovery_id: additionalInfo?.discoveryId as number,
      services: validServices as any,
      delete_missing: 'no',
    });
  }
};
