import { getClient } from 'api/entityGraphQL/utils';
import { QuestionHandler } from '../actions';

export const goalsHandler: QuestionHandler = (id, key, value, additionalInfo): any => {
  const apiClient = getClient();
  if (Array.isArray(value)) {
    // filter out any empty field sets
    const validGoals = value?.filter((goal) => {
      if (goal === undefined) return false;
      if (!goal.type && !goal.metric) return false;
      return !Object.values(goal).every((el) => el === undefined);
    });

    // handle undefined/null values
    validGoals?.forEach((goal) => {
      if (!goal.type) goal.type = null;
      if (!goal.metric) goal.metric = null;
      goal.metric = String(goal.metric);
    });

    return apiClient.saveDiscoveryGoals({
      discovery_id: additionalInfo?.discoveryId as number,
      goals: validGoals,
      delete_missing: 'yes',
    });
  }
};
