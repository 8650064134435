import React, { memo, useCallback, useState } from 'react';
import { Dialog, useSnackbar } from '@sprnova/nebula';
import { useDeleteScoreboardMutation } from 'api/crudGraphQL/scoreboards/deleteScoreboard';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { useAccount } from 'features/global';
import { track } from './mixpanelEvents';

type DeleteScoreboardModalProps = {
  open: boolean;
  toggle: React.DispatchWithoutAction;
  scoreboardId: number;
  scoreboardName: string;
  clientId: number;
  clientName: string;
};
const DeleteScoreboardModal = ({ open, toggle, scoreboardId, scoreboardName, clientId, clientName}: DeleteScoreboardModalProps): JSX.Element => {
  const [submitting, setSubmitting] = useState(false);
  const { addSnackbar } = useSnackbar();

  const [deleteScoreboard] = useDeleteScoreboardMutation();
  const mixpanel = useMixpanel();
  const { account } = useAccount();

  // Mixpanel events
  const handleDeleteScoreboardTrack = useCallback((): void => {
    if (account) {
      const { id, name, email } = account;
      track({
        mixpanel,
        type: 'delete-scoreboard',
        options: {
          user_id: id,
          user_name: name,
          email,
          scoreboard_id: scoreboardId,
          scoreboard_name: scoreboardName,
          client_id: clientId,
          client_name: clientName,
        },
      });
    }
  }, [account, clientId, clientName, mixpanel, scoreboardId, scoreboardName]);

  const handleDelete = async (): Promise<void> => {
    try {
      setSubmitting(true);
      await deleteScoreboard({ id: scoreboardId });
      addSnackbar({
        message: 'Scoreboard deleted',
        variant: 'success',
        persist: false,
      });
    } catch (error) {
      console.error(error);
      addSnackbar({
        message: 'Error deleting scoreboard',
        variant: 'error',
        persist: false,
      });
    } finally {
      setSubmitting(false);
      handleDeleteScoreboardTrack();
      toggle();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={toggle}
      title='Are you sure you want to delete this Scoreboard?'
      description='This action cannot be undone. It will also remove this scoreboard from the view of the client.'
      secondaryButtonProps={{
        onClick: toggle, children: 'Cancel', disabled: submitting
      }}
      primaryButtonProps={{
        onClick: handleDelete,
        children: 'Yes, I\'m sure',
        disabled: submitting,
      }}
      maxWidth={312}
    />
  );
};

export default memo(DeleteScoreboardModal);
