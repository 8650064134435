import React, { useState, memo, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Container, Divider, useForm, useSnackbar } from '@sprnova/nebula';
import { useCreateScoreboardMutation } from 'api/crudGraphQL/scoreboards/createScoreboard';
import { useGetScoreboardQuery } from 'api/crudGraphQL/scoreboards/getScoreboard';
import { useUpdateScoreboardMutation } from 'api/crudGraphQL/scoreboards/updateScoreboard';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { Client } from 'features/entitiesRedux';
import { useAccount } from 'features/global';
import { track } from 'features/scoreboards/components/mixpanelEvents';
import CreateScoreboardFormActions from './components/CreateScoreboardFormActions';
import CreateScoreboardNameTextfield from './components/CreateScoreboardNameTextfield';
import CreateScoreboardSelectClient from './components/CreateScoreboardSelectClient';
import CreateScoreboardSelectIntegrations from './components/CreateScoreboardSelectIntegrations';

export type CreateScoreboardFormType = {
  client?: { id: number, name: string };
  selected_data_source_ids?: number[];
  name?: string;
}

/**
 * Renders a form for creating a new scoreboard, including client selection,
 * scoreboard naming, and integration selection.
 *
 * @returns {JSX.Element} The rendered component
 */
const CreateScoreboardForm = ({ isEdit }: { isEdit?: boolean }): JSX.Element => {
  const { id } = useParams<{ [x: string]: string }>();
  // Hooks to create scoreboard and redirect
  const [createScoreboard, { isLoading: isCreateScoreboardLoading }] = useCreateScoreboardMutation();
  const [updateScoreboard, { isLoading: isUpdateScoreboardLoading }] = useUpdateScoreboardMutation();
  const history = useHistory();
  const redirectPath = '/scoreboards';
  const [excludedDatasourceIds, setExcludedDatasourceIds] = useState<number[]>([]);
  const [hasIntegrations, setHasIntegrations] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const mixpanel = useMixpanel();
  const { account } = useAccount();

  const { data } = useGetScoreboardQuery({
    id: parseInt(id),
    projection: {
      id: true,
      name: true,
      client: {
        id: true,
        name: true
      },
      excluded_data_sources: {
        id: true
      },
    },
  }, { skip: !id });

  // Snackbar for error message display
  const { addSnackbar } = useSnackbar();

  // Update excluded datasources state
  useEffect(() => {
    const initialExcludedDatasourceIds = isEdit ? data?.excluded_data_sources?.map(excludedDatasource => parseInt(excludedDatasource?.id)) ?? [] : [];
    setExcludedDatasourceIds(initialExcludedDatasourceIds);
  }, [data, isEdit]);

  // Selected client data
  const [selectedClient, setSelectedClient] = useState<Partial<Client> | undefined>(undefined);

  // Mixpanel events
  const handleCreateScoreboardTrack = useCallback((clientID?: number, clientName?: string, scoreboardName?: string, selected_data_source_ids?: number[]): void => {
    if (account) {
      const { id, name, email } = account;
      track({
        mixpanel,
        type: 'create-scoreboard',
        options: {
          user_id: id,
          user_name: name,
          email,
          client_id: clientID,
          client_name: clientName,
          scoreboard_name: scoreboardName,
          selected_data_source_ids,
        },
      });
    }
  }, [account, mixpanel]);

  const handleEditScoreboardTrack = useCallback((clientID?: number, scoreboardName?: string, selected_data_source_ids?: number[]): void => {
    if (account) {
      const { id, name, email } = account;
      track({
        mixpanel,
        type: 'edit-scoreboard',
        options: {
          user_id: id,
          user_name: name,
          email,
          client_id: clientID,
          client_name: data?.client?.name,
          scoreboard_id: data?.id,
          scoreboard_name: scoreboardName,
          selected_data_source_ids,
        },
      });
    }
  }, [account, data?.client?.name, data?.id, mixpanel]);

  // Handler for form submission
  const onSubmit = async (data: CreateScoreboardFormType): Promise<void> => {
    const { client, name, selected_data_source_ids } = data;
    const client_id = client?.id;
    const client_name = client?.name;

    if (client_id && name) {
      setIsSubmitting(true); // Disable buttons

      try {
        // Trigger the mutation with the args
        if (isEdit) {
          if (id) {
            await updateScoreboard({
              id: parseInt(id),
              name,
              excluded_data_source_ids: excludedDatasourceIds,
            });
          }
          handleEditScoreboardTrack(client_id, name, selected_data_source_ids);
        } else {
          await createScoreboard({
            client_id,
            excluded_data_source_ids: excludedDatasourceIds,
            name
          });
          handleCreateScoreboardTrack(client_id, client_name, name, selected_data_source_ids);
        }
        const message = isEdit ? 'Scoreboard successfully updated' : 'Scoreboard successfully created';
        addSnackbar({
          variant: 'success',
          message,
          persist: false,
        });

        // Wait for snackbar animation to complete before redirecting
        const snackbarDuration = 1000;
        setTimeout(() => {
          setIsSubmitting(false); // Enable buttons
          history.push(redirectPath);
        }, snackbarDuration);

      } catch (error) {
        const message = isEdit ? 'Failed to update Scoreboard' : 'Failed to create Scoreboard';
        addSnackbar({
          variant: 'error',
          message,
          persist: false,
        });
        console.error(`Failed to ${isEdit ? 'edit' : 'create'} scoreboard: `, error);
        setIsSubmitting(false); // Enable buttons even on error
      }
    }
  };

  // Initialize useForm hook with default values
  const { handleSubmit, control, setValue, getValues } = useForm<CreateScoreboardFormType>({
    defaultValues: {
      client: {id: undefined, name: undefined},
      selected_data_source_ids: undefined,
      name: undefined
    },
  });


  useEffect(() => {
    if (isEdit && data) {
      /**
       * If the form is in edit mode and the data has been fetched,
       * set the form values to the fetched data
       */
      setValue('client', {id: data.client.id, name: data.client.name});
      setValue('name', data.name || data.client.name);
      setSelectedClient(data.client);
    }
  }, [data, getValues, id, isEdit, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container hasVerticalPadding>
        {/* Select Client */}
        <CreateScoreboardSelectClient
          control={control}
          setSelectedClient={setSelectedClient}
          isEdit={isEdit}
        />

        {/* Input Scoreboard Name textfield */}
        <CreateScoreboardNameTextfield
          control={control}
          selectedClientName={selectedClient?.name}
          setValue={setValue}
          isEdit={isEdit}
        />

        {/* Select Integrations */}
        {selectedClient?.id && (
          <CreateScoreboardSelectIntegrations
            control={control}
            setValue={setValue}
            clientId={selectedClient.id}
            setExcludedDatasourceIds={setExcludedDatasourceIds}
            excludedDatasourceIds={excludedDatasourceIds}
            setHasIntegrations={setHasIntegrations}
            isEdit={isEdit}
          />
        )}

        <Divider variant='middle' sx={{margin: '2em 0'}}/>

        {/* Form actions */}
        <CreateScoreboardFormActions
          redirectPath={redirectPath}
          isCreating={isCreateScoreboardLoading || isUpdateScoreboardLoading}
          disabled={!hasIntegrations || isSubmitting}
          isEdit={isEdit}
        />
      </Container>

    </form>
  );
};

export default memo(CreateScoreboardForm);
