import React, { FC, memo, useCallback, useMemo, useReducer } from 'react';
import { Checkbox } from '@sprnova/nebula';
import { Task } from 'features/entitiesRedux';
import { PricingTypeEnum } from 'features/library/package-strategies/constants';
import TaskItem from '../TaskItem';

type Props = {
  className?: string;
  tasks: Task[];
  onSelect: (tasks: Task[]) => void;
  onUnselect: (tasks: Task[]) => void;
  selectedIds: number[];
};

const TasksListItem: FC<Props> = ({
  className,
  tasks,
  onSelect,
  onUnselect,
  selectedIds,
}) => {
  const tasksAddons = useMemo(() => {
    return tasks.filter((task: Task) => task.pricing_type?.slug === PricingTypeEnum.AddOnFixed || task.pricing_type?.slug === PricingTypeEnum.AddOnMultiplier);
  }, [tasks]);

  const tasksNotAddons = useMemo(() => {
    return tasks.filter((task: Task) => task.pricing_type?.slug !== PricingTypeEnum.AddOnFixed && task.pricing_type?.slug !== PricingTypeEnum.AddOnMultiplier);
  }, [tasks]);

  const [collapsedAddons, setCollapsedAddons] = useReducer((bool) => !bool, true);
  const [collapsedNotAddons, setCollapsedNotAddons] = useReducer((bool) => !bool, true);

  const handleToggle = useCallback((task: Task, checked: boolean) => {
    if (checked) {
      onUnselect([task]);
    } else {
      onSelect([task]);
    }
  }, [onSelect, onUnselect]);

  const renderTask = useCallback((task: Task) => {
    const checked = selectedIds.includes(task.id);

    return (
      <TaskItem
        key={task.id}
        task={task}
        onToggle={handleToggle}
        checked={checked}
      />
    );
  }, [handleToggle, selectedIds]);

  /**
   * Renders the list of tasks that are add-ons: AddOnFixed and AddOnMultiplier
   * - Only renders if the list is not empty.
   * - Renders the list only when the checkbox group is expanded. (For performance reasons)
   */
  const renderListTasksAddons = useCallback(() => {
    if (tasksAddons.length > 0) {
      return (
        <Checkbox.Group
          id="checkboxGroupAddons"
          collapsed={collapsedAddons}
          label={'Add-ons'}
          onCollapseChange={setCollapsedAddons}
          sx={{ marginLeft: 0 }}
          className={className}
        >
          {!collapsedAddons ? tasksAddons.map(renderTask) : null}
        </Checkbox.Group>
      );
    }
    return <></>;
  }, [className, collapsedAddons, renderTask, tasksAddons]);

  /**
   * Renders the list of tasks that are not add-ons: not AddOnFixed and not AddOnMultiplier
   * - Only renders if the list is not empty.
   * - Renders the list only when the checkbox group is expanded. (For performance reasons)
   */
  const renderListTasksNotAddons = useCallback(() => {
    if (tasksNotAddons.length > 0) {
      return (
        <Checkbox.Group
          id="checkboxGroupNotAddons"
          collapsed={collapsedNotAddons}
          label={'Main Strategies'}
          onCollapseChange={setCollapsedNotAddons}
          sx={{ marginLeft: 0 }}
          className={className}
        >
          {!collapsedNotAddons ? tasksNotAddons.map(renderTask) : null}
        </Checkbox.Group>
      );
    }
    return <></>;
  }, [className, collapsedNotAddons, renderTask, tasksNotAddons]);

  return (
    <>
      {renderListTasksAddons()}
      {renderListTasksNotAddons()}
    </>
  );
};

/**
 * Renders the list of tasks with checkboxes:
 * - 1 list for tasks that are add-ons: AddOnFixed and AddOnMultiplier
 * - 1 list for tasks that are not add-ons
 */
export default memo(TasksListItem);
