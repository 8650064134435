
import React, { memo, useEffect, useMemo, useState } from 'react';
import { Card, CardContent, CardHeader, Grid, Select, TextField } from '@sprnova/nebula';
import { useGetServicesQuery } from 'api/crudGraphQL/services/getServices';
import { useGetStrategyFrequenciesQuery } from 'api/crudGraphQL/strategies/additional/frequencies/getStrategyFrequencies';
import { StrategyFrequency } from 'api/crudGraphQL/strategies/types';
import {StringParam, useQueryParam, withDefault} from 'use-query-params';
import { Service } from 'features/entitiesRedux';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import { Task } from '../../../entitiesRedux';
import { ActionType, FormDataType } from '../packageStrategyTypes';
import css from './EditStrategyOverview.module.scss';

type StrategyOverviewProps = {
  isSubmitted: boolean;
  dispatch: React.Dispatch<any>;
  formData: Partial<FormDataType>;
  task?: Task
}

const EditStrategyOverview = ({
  isSubmitted,
  dispatch,
  formData,
  task
}: StrategyOverviewProps): JSX.Element => {
  const [pricingVersionQueryParam,] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );
  const [serviceOptions, setServiceOptions] = useState<Partial<Service[]>>([]);
  /**
   * Fetch services
   */
  const { data: services, isLoading: isLoadingServices } = useGetServicesQuery({
    pricing_version: pricingVersionQueryParam,
    projection: {
      name: true,
      id: true,
      department: {
        id: true,
        name: true,
      }
    },
  });

  /**
   * Fetch strategy frequencies
   */
  const { data: strategyFrequencies, isLoading: isLoadingStrategyFrequencies } = useGetStrategyFrequenciesQuery({
    projection: {
      id: true,
      name: true,
      slug: true,
    },
  });

  /**
   * Initialize service options and custom the name
   */
  useEffect(() => {
    if (services) {
      setServiceOptions(services.map((service: Service) => {
        const clonedService = {...service};
        clonedService.name = service.name + '   (' + service.department?.name + ')';
        return clonedService;
      }));
    }
  }, [services, task?.pricing_type?.name]);

  /**
   * Options for the service select
   */
  const renderServiceOptions = useMemo(() => {
    if (!services && isLoadingServices) {
      return <Select.Item disabled value={'loading'}>Loading...</Select.Item>;
    }
    return serviceOptions.map((service) => (
      <Select.Item key={`service-${service?.id}`} value={service?.id}>{service?.name}</Select.Item>
    ));
  }, [isLoadingServices, serviceOptions, services]);

  /**
   * Options for the strategy frequency select
   *
   * Select has no loading state so we need to handle it manually:
   * - If the strategy frequencies are not available and they are loading, show loading state option:
   *    - 1 disabled option with text 'Loading...'
   */
  const renderStrategyFrequencyOptions = useMemo(() => {
    if (!strategyFrequencies && isLoadingStrategyFrequencies) {
      return <Select.Item disabled value={'loading'}>Loading...</Select.Item>;
    } else {
      if (Array.isArray(strategyFrequencies) && strategyFrequencies.length > 0) {
        return strategyFrequencies.map((strategyFrequency: StrategyFrequency) => (
          <Select.Item key={`strategy-frequency-${strategyFrequency.id}`} value={strategyFrequency.id}>{strategyFrequency.name}</Select.Item>
        ));
      }
      return [];
    }
  }, [isLoadingStrategyFrequencies, strategyFrequencies]);

  const cardContent = <>
    <Grid container
      justifyContent={'space-around'}
      spacing={3}
    >
      <>
        <Grid item xs={12}>
          <TextField
            id='strategy-name-input'
            error={!formData.name && isSubmitted}
            helperText="Describe the strategy, this will be used in the contract"
            label='Strategy Name'
            name='strategy_name'
            onChange={(event): void => {
              dispatch({ type: ActionType.ON_CHANGE_STRATEGY_OVERVIEW_DATA, payload: { name: event.target.value} });
            }}
            required={true}
            value={formData.name}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            autoWidth
            id='service-select'
            defaultValue={task?.service.id}
            error={!formData?.service_id && isSubmitted}
            helperText="Service that relates to this strategy"
            label='Service'
            MenuProps={{ PaperProps: { className: css.dropdown } }}
            name='service'
            onChange={(event): void => {
              const { target: { value }} = event;
              dispatch({ type: ActionType.ON_CHANGE_STRATEGY_OVERVIEW_DATA, payload: { service_id: value }});
            }}
            renderValue={(value): string | undefined => {
              if (isLoadingServices) return `${task?.service.name} (${task?.service?.department?.name})`;
              return (
                `${services?.find((service) => service.id === value)?.name} (${task?.service?.department?.name})`
              );
            }}
            value={formData?.service_id}
          >
            {renderServiceOptions}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Select
            id='strategy-frequency-select'
            defaultValue={task?.strategy_frequency?.id}
            error={!formData?.strategy_frequency_id && isSubmitted}
            helperText="To be used to set how often this strategy will occur"
            label='Strategy Frequency'
            MenuProps={{ PaperProps: { className: css.dropdown } }}
            name='strategy-frequency'
            onChange={(event): void => {
              const { target: { value }} = event;
              dispatch({ type: ActionType.ON_CHANGE_STRATEGY_OVERVIEW_DATA, payload: { strategy_frequency_id: value }});
            }}
            renderValue={(value): string | undefined => {
              if (isLoadingStrategyFrequencies) return task?.strategy_frequency?.name;
              return (
                strategyFrequencies?.find((strategyFrequency: any) => strategyFrequency.id === value)?.name
              );
            }}
            value={formData?.strategy_frequency_id}
          >
            {renderStrategyFrequencyOptions}
          </Select>
        </Grid>
      </>
    </Grid>
  </>;

  return (
    <Card
      id={'strategy-overview-navigation'}
      disablePadding
      sx={{ marginBottom: '24px' }}
      header={
        <CardHeader title="Strategy Overview" />
      }
    >
      <CardContent>
        {cardContent}
      </CardContent>
    </Card>
  );
};

/**
 * Strategy overview card when editing an existing library strategy package
 */
export default memo(EditStrategyOverview);
