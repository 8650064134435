/**
 * Format currency helper
 */

const formatCurrency: (number?: number) => string = (number) => {
  if (typeof number !== 'number') {
    return ' - ';
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(number);
};

export default formatCurrency;
