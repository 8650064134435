/**
* Update Notification
*/

import { Projection } from 'api/entityGraphQL/types';
import { Notification } from 'features/entitiesRedux/models/notification';
import { baseApi, cleanArguments } from '..';
import tagTypes from './tagTypes';

export type UpdateNotificationMutation = {
  id: number;
  is_new?: boolean;
  projection?: Projection;
};

export type UpdateNotificationResult = Notification

export const UPDATE_NOTIFICATION_ENDPOINT_KEY = 'updateNotification';

export const { useUpdateNotificationMutation, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [UPDATE_NOTIFICATION_ENDPOINT_KEY]: build.mutation<UpdateNotificationResult, UpdateNotificationMutation>({
      transformResponse: (response: { updateNotification: Notification }) => response.updateNotification,
      invalidatesTags: result => [
        result?.id ? { id: result.id, type: tagTypes.NOTIFICATION } : tagTypes.NOTIFICATION,
        tagTypes.NOTIFICATIONS
      ],
      query: ({ projection = { id: true }, ...values }) => {
        const __args: Partial<UpdateNotificationMutation> = {
          ...values,
        };
        return {
          body: {
            mutation: {
              updateNotification: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        };
      }
    }),
  }),
});
