/**
 * Audits -> Forecasts -> View Forecast Page
 */

import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Container } from '@sprnova/nebula';
import { PageHero } from 'layouts/components';
import moment from 'moment';
import { ForecastTabs } from './components/ForecastTabs';
import { Projection } from '../../../../api/entityGraphQL';
import { Alert, Button } from '../../../../components';
import { selectError, selectIsLoading } from '../../../entitiesRedux';
import { useForecastById } from '../../../entitiesRedux/models/forecast/hooks';
import formatForecastTitle from '../utils/formatForecastTitle';
import css from './ViewForecastPage.module.scss';

const projection: Projection = {
  id: true,
  name: true,
  date: true,
  length: true,
  created_at: true,
  audit: {
    id: true,
    client: {
      id: true,
      name: true,
    },
  },
  model: { id: true },
};

const ViewForecastPage: FC = () => {
  /**
   * Redux State
   */
  const error = useSelector(selectError);
  const isLoading = useSelector(selectIsLoading);

  /**
   * Load variables from path
   */
  const { forecastId: idRaw = '' } = useParams<{ [x: string]: string | undefined }>();
  const forecastId = Number.parseInt(idRaw);
  const { forecast, loading: loadingForecast } = useForecastById(forecastId, projection);
  const title = formatForecastTitle({ forecast, isLoading: isLoading || loadingForecast });

  return (
    <div className={css.root}>
      <Helmet>
        <title>{forecast?.model?.id === 1003 && forecast?.name ? forecast?.name :
          forecast?.audit?.client?.name && forecast?.created_at ? `${forecast?.audit?.client?.name} ${moment(forecast?.created_at).format('MMM YYYY')} Forecast` : forecast?.name ? `${forecast?.name} Forecast` : 'Forecast Analysis'}</title>
      </Helmet>
      <PageHero
        title={title !== '...' ? <span>{title}</span> : 'Forecast'}
        description='Forecast analysis'
        end={[
          <Link to={`/appraisals/${forecast?.audit?.id}/forecasts/${forecast?.id}/edit`} key="edit-forecast">
            <Button type="text" icon={<EditOutlined />} header>
              Edit Forecast
            </Button>
          </Link>
        ]}
      />
      <Container hasVerticalPadding>
        {error && <Alert message={error} type="error" className={css.error} />}
        <ForecastTabs forecastDate={forecast?.date} forecastLength={forecast?.length} />
      </Container>
    </div>
  );
};

export const FormattedTitle: () => JSX.Element = () => {
  const { forecastId: idRaw = '' } = useParams<{ [x: string]: string | undefined }>();
  const forecastId = Number.parseInt(idRaw);
  const { forecast, loading: loadingForecast } = useForecastById(forecastId, projection);
  const isLoading = useSelector(selectIsLoading);
  return <span>{formatForecastTitle({ forecast, isLoading: isLoading || loadingForecast })}</span>;
};

export default ViewForecastPage;
