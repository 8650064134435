import { getClient } from 'api/entityGraphQL/utils';
import { QuestionHandler } from '../actions';

export const pillarHandler: QuestionHandler = (id, key, value, additionalInfo) => {
  const apiClient = getClient();
  return apiClient.updateAudit({
    id: additionalInfo?.auditId,
    pillar_id: additionalInfo?.pillarId as number
  });
};
