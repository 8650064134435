/**
 * Get talent survey type
 */

import { Projection } from 'api/entityGraphQL';
import { SurveyType } from 'features/entitiesRedux';
import tagTypes from './tagTypes';
import { baseApi , cleanArguments } from '..';

export type GetSurveyTypeQuery = {
  id?: number;
  is_active?: boolean;
  name?: string;
  show?: string;
  sort?: string[];
  unique?: boolean;
  projection?: Projection;
}

export type GetSurveyTypeResult = SurveyType[];

export const { useGetSurveyTypeQuery, useLazyGetSurveyTypeQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSurveyType: build.query<GetSurveyTypeResult, GetSurveyTypeQuery>({
      transformResponse: (response: { survey_types: GetSurveyTypeResult }) => response.survey_types,
      providesTags: (survey_types = []) => {
        return [
          tagTypes.SURVEY_TYPES,
          ...survey_types.map(type => ({
            id: type.id,
            type: tagTypes.SURVEY_TYPE
          }))
        ];
      },
      query: ({
        projection = { id: true, name: true },
        ...args
      }) => {
        const __args: Partial<GetSurveyTypeQuery> = args;

        return {
          body: {
            query: {
              survey_types: {
                __args: cleanArguments(__args),
                ...projection,
              },
            }
          }
        };
      },
    }),
  }),
});
