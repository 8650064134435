import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DataGrid, formatCurrency, GridColDef } from '@sprnova/nebula';
import { formatNumber } from 'features/audits/utils';
import DatasetBullets from './components/DatasetBullets';
import { ApiResponse } from '../../NovaGpt';

interface DataGridComponentProps {
  data: ApiResponse[];
}

const DataGridComponent: React.FC<DataGridComponentProps> = ({ data }) => {
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [displayAsBullets, setDisplayAsBullets] = useState(false);

  // Determine money-related fields once
  const moneyFields = useMemo(() => {
    const firstRow = data[0] ?? {}; // Use nullish coalescing to handle undefined
    return Object.keys(firstRow).filter(key =>
      /(?:REVENUE|SALES|INCOME|EARNINGS|PROFIT)/i.test(key)
    );
  }, [data]);

  const calculateMinWidth = useCallback((text: string) => {
    // Base width for padding and other elements
    const baseWidth = 100;
    // Width per character (adjust based on font and padding)
    const charWidth = 10;
    return baseWidth + text.length * charWidth;
  }, []);

  const formatData = useCallback((data: ApiResponse[]) => {
    return data.map(row => {
      const formattedRow: ApiResponse = {};

      Object.entries(row).forEach(([key, value]) => {
        if (typeof value === 'number' && !key.includes('ID')) {
          // Apply formatCurrency first if it's a money-related field
          if (moneyFields.includes(key)) {
            formattedRow[key] = formatCurrency(value);
          } else {
            formattedRow[key] = formatNumber(value);
          }
        } else {
          formattedRow[key] = value;
        }
      });

      return formattedRow;
    });
  }, [moneyFields]);

  const formattedData = useMemo(() => formatData(data), [data, formatData]);

  useEffect(() => {
    if (formattedData.length === 0) return;

    const firstRow = formattedData[0] ?? {}; // Use nullish coalescing to handle undefined

    if (formattedData.length <= 5) {
      // Simple data: display as bullet points
      setDisplayAsBullets(true);
    } else {
      // Generate columns for table view
      const dynamicColumns: GridColDef[] = Object.keys(firstRow).map((key) => {
        const headerName = key.replace(/_/g, ' ').toUpperCase(); // Replace all underscores with spaces and capitalize
        return {
          field: key,
          headerName,
          flex: 1,
          minWidth: calculateMinWidth(headerName),
        };
      });

      /**
       * Sort Columns
       * Ensures that ID column is always first, then title column, then the rest
       */
      const sortedColumns = dynamicColumns.sort((columnA, columnB) => {
        if (columnA.field.includes('ID') && !columnB.field.includes('ID')) return -1;
        if (!columnA.field.includes('ID') && columnB.field.includes('ID')) return 1;
        if (columnA.field.toLowerCase().includes('title') && !columnB.field.toLowerCase().includes('title')) return -1;
        if (!columnA.field.toLowerCase().includes('title') && columnB.field.toLowerCase().includes('title')) return 1;
        return 0;
      });

      setColumns(sortedColumns);
      setDisplayAsBullets(false);
    }
  }, [formattedData, calculateMinWidth]);

  const getIdField = (row: ApiResponse): string | number => {
    if (!row || typeof row !== 'object') {
      // Handle the case when row is invalid
      return 'invalid-row';
    }
    // Find a suitable key to use as the ID
    const keys = Object.keys(row);
    const idField = keys?.length > 0 ? JSON.stringify(row) : 'default-id'; // Safely access the first key's value

    // Return the idField if it is valid, otherwise return a default ID specific to the row
    return idField;
  };

  return (
    <div style={{ width: '100%', paddingTop: 20 }}>
      {displayAsBullets ? (
        <DatasetBullets data={formattedData} />
      ) : (
        <DataGrid
          rows={formattedData}
          columns={columns}
          getRowId={(row) => getIdField(row)}
        />
      )}
    </div>
  );
};

export default memo(DataGridComponent);
