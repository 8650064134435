import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, Button, EditIcon, Drawer, DrawerFooter, useSnackbar, Spinner } from '@sprnova/nebula';
import { useUpdateScoreboardIndicatorsMutation } from 'api/crudGraphQL/scoreboards/indicators/updateScoreboardIndicators';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { cloneDeep, isEqual, map } from 'lodash';
import { YesNo } from 'features/entitiesRedux';
import { useAccount } from 'features/global/hooks/useAccount';
import { track } from 'features/scoreboards/components/mixpanelEvents';
import List from './List';
import { IntegrationType } from '../types';


const transformKey = (key: string): string => {
  const transformedKey = key.toLowerCase()
    .replace(/-/g, '')
    .replace(/\s+/g, '_') + '_indicators';
  return transformedKey.replace('_store', '');
};

type TransformIndicatorType = Record<string, boolean | number | YesNo>;
const transformIndicator = (indicator: TransformIndicatorType): TransformIndicatorType => ({
  is_active: indicator.is_active ? 'yes' : 'no',
  indicator_id: indicator.indicator_id as number,
});

export interface Indicator {
  is_active: boolean;
  indicator_id: number;
  label?: string;
}

type EditKPIsDrawerProps = {
  id: number;
  data: any;
  platformData?: Record<string, IntegrationType[]>;
  open: boolean;
  toggleOpen: () => void;
};

const EditKPIsDrawer: React.FC<EditKPIsDrawerProps> = ({ id, data, platformData, open, toggleOpen }) => {
  const [submitting, setSubmitting] = useState(false);
  const { addSnackbar } = useSnackbar();
  const mixpanel = useMixpanel();
  const { account } = useAccount();
  const { control, handleSubmit, getValues, setValue } = useForm({
    defaultValues: data,
  });

  useEffect(() => {
    /**
     * data is getting updated with a metric's platform data from a separate query
     * so we need to update the form values when this data changes
     * (provided it's not the same as the current form values)
     */
    if (data && !isEqual(data, getValues())) {
      Object.keys(data).forEach((key: string) => {
        setValue(key, data[key]);
      });
    }
  }, [data, getValues, setValue]);

  const [updateScoreboardIndicatorsRequest] = useUpdateScoreboardIndicatorsMutation();

  const addLabelsToIndicators = useCallback((newValues: Record<string, Indicator[]>, oldValues: Record<string, Indicator[]>): Record<string, Indicator[]> => {
    try {
      // Create a deep copy of newValues to avoid direct modification to data
      const labeledKPIs = cloneDeep(newValues);

      Object.keys(labeledKPIs).forEach(category => {
        labeledKPIs[category] = labeledKPIs[category].map((item: Indicator) => {
          const correspondingItem: Indicator | undefined = oldValues[category].find(
            (oldValue: Indicator) => oldValue.indicator_id === item.indicator_id
          );
          return correspondingItem ? { ...item, label: correspondingItem.label } : item;
        });
      });

      return labeledKPIs;
    } catch (error) {
      console.error('An error occurred while adding labels to indicators:', error);
      return newValues; // Return the original newValues in case of an error
    }
  }, []);

  const onSubmit = useCallback(async(values: Record<string, Indicator[]>): Promise<void> => {
    try {
      setSubmitting(true);

      const transformed = Object.keys(values)
        // Transform the keys to match the format of the mutation
        // and map the values to the transformed format
        .map(key => ({ [transformKey(key)]: map(values[key], transformIndicator) }))
        .reduce((acc, curr) => {
          const key = Object.keys(curr)[0]; // Get the first (and only) key of the current object
          acc[key] = [...acc[key] || [], ...curr[key]]; // Merge the arrays
          return acc;
        }, {});

      await updateScoreboardIndicatorsRequest({ report_id: id, ...transformed });

      const { id: user_id, name } = account ?? {};
      const updated_kpis = addLabelsToIndicators(values, data);
      track({ mixpanel, type: 'edit-kpis', options: {
        updated_kpis: updated_kpis,
        user_id: user_id,
        user_name: name
      } });
      addSnackbar({
        message: 'Updated KPIs',
        variant: 'success',
        persist: false,
      });
    } catch (error) {
      console.error('Error updating KPIs:', error);
      addSnackbar({
        message: 'Error updating KPIs',
        variant: 'error',
        persist: false,
      });
    } finally {
      toggleOpen();
      setSubmitting(false);
    }
  }, [account, addLabelsToIndicators, addSnackbar, data, id, mixpanel, toggleOpen, updateScoreboardIndicatorsRequest]);

  const renderList = useMemo(() => {
    return (
      Object.keys(data)?.map((key: string) => {
        return (
          <List key={key} control={control} name={key} platformData={platformData}/>
        );
      })
    );
  }, [data, control, platformData]);

  return (
    <>
      <Button
        size='small'
        startIcon={<EditIcon />}
        variant='tertiary'
        onClick={toggleOpen}
      >
            Edit KPIs
      </Button>
      <Drawer
        open={open}
        onClose={toggleOpen}
        title="Settings"
        component="form"
        onSubmit={handleSubmit(onSubmit as any)}
        footer={
          <DrawerFooter
            primaryButtonProps={{
              type: 'submit',
              children: 'Apply',
              size: 'large',
              disabled: submitting,
              startIcon: submitting && <Spinner size='small' />
            }}
            secondaryButtonProps={{
              children: 'Cancel',
              onClick: toggleOpen,
              size: 'large',
              variant: 'text',
              disabled: submitting
            }}
          />
        }
      >
        {renderList}
      </Drawer>
    </>
  );
};

export default memo(EditKPIsDrawer);
