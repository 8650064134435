/**
* RTK Query -> base API setup
 */

import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from 'config';
import { ClientError, GraphQLClient } from 'graphql-request';
import Cookies from 'js-cookie';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { tagTypes } from './tagTypes';
import { ErrorReturn } from './types';

// Error handler
export const errorHandler = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: ClientError | Record<string, any>,
  body?: Record<string, unknown>
): ErrorReturn => {
  // Logging could be done here
  console.error('An error occurred while running request:', body, error);

  if (error instanceof ClientError) {
    return {
      message: 'An error occurred',
      debugMessage: undefined
    };
  }

  const firstError = error?.response?.errors[0] || {};
  const { message, debugMessage } = firstError;

  return {
    message: message ?? 'An error occurred',
    debugMessage: debugMessage,
  };
};

export const graphqlRequest = async <ReturnType>(body: Record<string, unknown>): Promise<ReturnType> => {
  // Get the correct headers based on the API version
  const headersByVersion: { [version: string]: Record<string, string> } = {
    v1: {
      authorization: `Bearer ${config.platform.api.token}`,
    },
    v2: {
      authorization: `Bearer ${Cookies.get('BEARER-TOKEN')}`,
    },
  };

  const client = new GraphQLClient(`${config.platform.api.host}/`, {
    headers: headersByVersion[config.platform.api.version]
  });

  return client.request(
    jsonToGraphQLQuery(body, { pretty: true})
  );
};

// A custom graphql base query that supports graphql
export const baseQuery = async ({ body }: { body: Record<string, unknown> }) => {
  try {
    const data = await graphqlRequest(body);

    return { data };
  } catch (errorData) {
    return {
      error: errorHandler(errorData, body)
    };
  }
};

// Initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  tagTypes: Object.values(tagTypes),
  reducerPath: 'api',
  baseQuery,
  endpoints: () => ({}),
  // https://redux-toolkit.js.org/rtk-query/usage/persistence-and-rehydration
  // extractRehydrationInfo(action, { reducerPath, ...rest }) {
  //   if (action.type === REHYDRATE) {
  //     return action.payload[reducerPath];
  //   }
  // },
});
