/**
 * useIndustries
 * Fetches all industries
 */

import { useState, useEffect } from 'react';
import { novaGraphQLClient } from 'api/entityGraphQL';
import { Industry } from 'features/entitiesRedux';

type Type = {
  loading: boolean;
  error: string;
  industries: Industry[];
}

export const useIndustries: () => Type = () => {
  const [industries, setIndustries] = useState<Industry[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      setLoading(true);
      try {
        const { industries } = await novaGraphQLClient.fetchIndustries({});
        if (!isCancelled) {
          setLoading(false);
          setIndustries(industries);
          setError('');
        }
      } catch(error) {
        if (!isCancelled) {
          setLoading(false);
          setError(error?.response?.errors?.[0]?.message || 'Unknown error');
        }
      }
    })();

    return () => {
      isCancelled = true;
    };

  }, []);

  return { industries, loading, error };
};

export default useIndustries;
