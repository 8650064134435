import React, { memo } from 'react';
import { Grid, KeyValue } from '@sprnova/nebula';
import { Task } from 'features/entitiesRedux/models/task';

type ViewAddOnPricingContractDetailsProps = {
  task?: Task;
}

const ViewAddOnPricingContractDetails = ({ task }: ViewAddOnPricingContractDetailsProps): JSX.Element => {
  return (
    <Grid
      container
      justifyContent={'space-around'}
      spacing={3}
    >
      <Grid item xs={6}>
        <KeyValue
          label='Pricing Type'
          value={task?.pricing_type?.name ?? 'N/A'}
        />
      </Grid>
      <Grid item xs={6}>
        <KeyValue
          label='Price'
          value={task?.pricing_tiers?.[0]?.price == null
            ? 'N/A'
            : `${task?.pricing_tiers?.[0]?.price}`}
        />
      </Grid>
      <Grid item xs={12}>
        <KeyValue
          label='Snippet Summary'
          value={task?.snippet_summary
            ? <div dangerouslySetInnerHTML={{ __html: task?.snippet_summary }} />
            : 'N/A'}
        />
      </Grid>
      <Grid item xs={12}>
        <KeyValue
          label='Proposal Snippet'
          value={task?.pricing_tiers?.[0]?.snippet
            ? <div dangerouslySetInnerHTML={{ __html: task?.pricing_tiers?.[0]?.snippet }} />
            : 'N/A'}
        />
      </Grid>
    </Grid>
  );
};

export default memo(ViewAddOnPricingContractDetails);
