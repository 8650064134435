/**
 * Delete pacing goal
 */

import { Projection } from 'api/entityGraphQL/types';
import { baseApi } from '../..';
import clientTagTypes from '../../clients/tagTypes';
import tagTypes from '../tagTypes';
import { Scoreboard } from '../types';

export type DeletePacingGoalMutation = {
  id: number;
  projection?: Projection;
}

export type DeletePacingGoalResult = Scoreboard;

export const DELETE_PACING_GOAL = 'deletePacingGoal';

export const { useDeletePacingGoalMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [DELETE_PACING_GOAL]: build.mutation<DeletePacingGoalResult, DeletePacingGoalMutation>({
      invalidatesTags: [
        tagTypes.SCOREBOARDS,
        tagTypes.SCOREBOARD,
        clientTagTypes.CLIENTS,
        clientTagTypes.CLIENT,
      ],
      query: ({ id, projection = { id: true } }) => ({
        body: {
          mutation: {
            deletePacingGoal: {
              __args: {
                id
              },
              ...projection,
              id: true
            }
          }
        }
      })
    })
  })
});
