/**
 * StrategyForm -> RecommendedServices -> RuleTooltip
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { TooltipPopover } from '../../../../../../../../components';
import { formatComparisonOperatorText } from '../../../../../../../../utils';
import { Audit, Rule, RuleCriteria } from '../../../../../../../entitiesRedux';
import css from './RuleTooltip.module.scss';

interface RuleTooltipProps {
  audit: Audit;
  taskId: number;
}

const RuleTooltip = ({
  audit,
  taskId,
}: RuleTooltipProps): JSX.Element => {

  const getAuditRulesFromTask = (taskId: number) => {
    return audit?.rules?.filter((rule: Rule) => {
      const filtered = rule?.tasks?.filter(task => task.id === taskId);
      return filtered?.length > 0;
    });
  };

  const getTooltipContent = (taskId: number) => {
    const rules = getAuditRulesFromTask(taskId);
    return rules?.map((rule: Rule, i: number) => {
      return (
        <div className={css.tooltip_rule} key={`${rule.id}=${i}`}>
          <div className={css.tooltip__ruleCriteria}>
            {rule?.criteria?.map((criterion: RuleCriteria, i: number) => {
              return (
                <div className={css.tooltip__ruleCriteriaItem} key={`${criterion?.id}-${i}`}>
                  <span>Criteria: </span>
                  <span>{typeof criterion?.entity !== 'string' ? criterion?.entity?.name : undefined}{' '}score is{' '}</span>
                  <span>{formatComparisonOperatorText(criterion?.operator)}{' '}</span>
                  <span>{criterion?.value}{' '}</span>
                </div>
              );
            })}
          </div>
          <Link className={css.tooltip_ruleEditBtn} to={`/library/rules/${rule.id}/edit`} target="_blank"><EditOutlined /></Link>
        </div>
      );
    });
  };

  return (
    <TooltipPopover
      content={getTooltipContent(taskId)}
      placement="topRight"
      arrowPointAtCenter
    />
  );
};

RuleTooltip.displayName = 'RuleTooltip';

export default RuleTooltip;
