/**
 * SectionBuilder -> Fields -> DecisionMakersField
 *
 * Multi-select dropdown containing all client contacts
 */

import React, { FC } from 'react';
import { every, isNull } from 'lodash';
import {
  Alert,
  FormItem,
  Select,
} from 'components';
import { Contact } from '../../../../../../../entitiesRedux';
import { FieldProps } from '../../types';
import css from './DecisionMakersField.module.scss';

const DecisionMakersField: FC<FieldProps> = ({ name, form, placeholder }) => {
  const contacts = form.getFieldValue('contacts').filter((contact: Contact) => {
    return !every(contact, val => val === '' || val === null || val === undefined);
  });

  return (
    <>
      {contacts?.length ?
        <>
          <FormItem name={name} className={css.root}>
            <Select
              mode="multiple"
              allowClear
              placeholder={placeholder}
            >
              {contacts?.map((contact: Contact, i: number) => (
                <Select.Option key={`${contact?.name}-${i}`} value={contact?.id}>
                  {contact?.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <Alert
            message={
              <>
              Manage contacts in the <a href="#pre-call">Pre-Call</a> section.
              </>
            }
            type="info"
            style={{ width: 'auto', margin: '10px 0px 30px 0px' }}
            showIcon
          />
        </> :
        <Alert
          message="No existing contacts"
          description={
            <p>
            No contacts exist for this client. You can add new contacts in the <a href="#pre-call">Pre-Call</a> section.
            </p>
          }
          type="warning"
          style={{ width: '100%' }}
          showIcon
        />
      }
    </>
  );
};

export default DecisionMakersField;
