/**
 * Entities -> Partners -> Adapter
 */

import { createEntityAdapter } from '@reduxjs/toolkit';
import { compareField } from '../../utils/compareField';
import { Partner } from './partner';

/**
 * Redux toolkit entity adapter for storing/caching entities by id
 */
export const partnersAdapter = createEntityAdapter<Partner>({
  selectId: (partner) => partner.id,
  sortComparer: compareField('name'),
});
