import React,{ FC, ReactNode, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppSidebar,
  AppsIcon,
  Dropdown,
  ActionMenu,
  NewWindowIcon,
  PositionerAnchorProps,
  PositionerContentProps
} from '@sprnova/nebula';
import { Projection, novaGraphQLClient } from 'api/entityGraphQL';

export const AppsDropdown: FC = () => {
  const { pathname } = useLocation();
  const [dataQLink, setDataQLink] = useState<string>();

  const projection: Projection = {
    id: true,
    dataq: true,
  };

  /** Request signed DataQ link for current user */
  const handleClickMenuTrigger = async (): Promise<void> => {
    try {
      const { me } = await novaGraphQLClient.fetchMe({ projection });
      if (me?.dataq) {
        setDataQLink(me?.dataq);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const appItems: {
    label: string;
    path?: string;
    url?: string;
    isActive?: boolean;
  }[] = [
    {
      label: 'nova',
      path: '/',
      isActive: pathname.split('/')[1] !== 'talent'
    },
    {
      label: 'nova Command',
      url: 'https://command.powerdigital.io/',
    },
    {
      label: 'nova Talent',
      path: '/talent',
      isActive: pathname.split('/')[1] === 'talent'
    },
    {
      label: 'nova Collab',
      url: 'https://collab.powerdigital.io/',
    },
    {
      label: 'nova Influence',
      url: 'https://dexter.powerdigital.io/',
    },
    {
      label: 'nova Keyword',
      url: 'https://keyword.powerdigital.io/',
    },
    {
      label: 'DataQ',
      url: dataQLink,
    },
  ];

  const renderAnchor = ({onClick, ...props}: PositionerAnchorProps): ReactNode => {

    const handleClick = (): void => {
      handleClickMenuTrigger();
      onClick?.();
    };

    return (
      <AppSidebar.IconButton onClick={handleClick} {...props} aria-label="Nova Apps" >
        <AppsIcon />
      </AppSidebar.IconButton>
    );
  };

  const renderContent = ({ onClose }: PositionerContentProps): ReactNode => (
    <ActionMenu onItemClick={onClose} sx={{minWidth: 250 }}>
      {appItems.map(({ label, path, url, isActive }) => {
        const props = path ?
          { to: path, component: Link } :
          { href: url, component: 'a', target: '_blank', rel: 'noopener noreferrer' };

        return (
          <ActionMenu.Item
            isActive={isActive}
            key={label}
            endIcon={<NewWindowIcon />}
            {...props}
          >
            {label}
          </ActionMenu.Item>
        );
      })}
    </ActionMenu>
  );

  return (
    <Dropdown anchor={renderAnchor} placement="bottom-start">
      {renderContent}
    </Dropdown>
  );
};
