/**
 * SectionBuilder -> Fields -> LTV
 */

import React, { FC, useEffect } from 'react';
import { FormItem, Input } from 'components';
import { MagicOutlined } from '../../../../../../../../../components/Icon';
import { formatCurrencyInput } from '../../../../../../../utils';
import { FieldProps } from '../../../types';
import css from '../../outputs_shared.module.scss';

const LTV: FC<FieldProps> = ({ name, form }) => {
  // get form values for calculation
  const numRepeatPurchases = form.getFieldValue('question_num_customer_repeat_purchases');
  const averageOrderValue = form.getFieldValue('question_aov');

  const calculateOutput = () => {
    // check if both values are truthy
    if (!!numRepeatPurchases && !!averageOrderValue) {
      // calculate output
      const output = '$ ' + formatCurrencyInput((numRepeatPurchases * averageOrderValue).toFixed(0));
      return form.setFieldsValue({[name]: output});
    }
  };

  useEffect(() => {
    calculateOutput();
  }, [form, numRepeatPurchases, averageOrderValue]);

  return (
    <FormItem
      name={name}
      className={css.root}
      extra={
        <div className={css.extra}>LTV = Times a customer purchases within their lifetime with you * AOV</div>
      }
    >
      <Input 
        addonAfter={
          <span role="img" className="anticon">
            <MagicOutlined />
          </span>
        }
        readOnly
      />
    </FormItem>
  );
};

export default LTV;
