/**
 * Delete strategy tasks
 */

import { Projection } from 'api/entityGraphQL/types';
import { Strategy } from 'features/entitiesRedux';
import { baseApi } from '../../..';

export type DeleteStrategyTasksMutation = {
  strategy_id: number;
  task_ids?: number[];
  projection?: Projection;
}

export type DeleteStrategyTasksResult = Strategy;

export const DELETE_STRATEGY_TASKS_ENDPOINT_KEY = 'deleteStrategyTasks';

export const { useDeleteStrategyTasksMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [DELETE_STRATEGY_TASKS_ENDPOINT_KEY]: build.mutation<DeleteStrategyTasksResult, DeleteStrategyTasksMutation>({
      query: ({ projection = {}, ...values }) => ({
        body: {
          mutation: {
            deleteStrategyTasks: {
              __args: {
                ...values
              },
              id: true
            }
          }
        }
      })
    })
  })
});
