import React, { FC } from 'react';
import loadable from '@loadable/component';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';
import { Skeleton } from 'components';
import { DepartmentStatus } from '../../components';
import { useGetAnalystSurveyDepartmentByUrlParam } from '../../hooks/useGetAnalystSurveyDepartmentByUrlParam';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LoadableComponent: any = loadable(
  () => import('./AnalystQuestionGroupsPage'),
  {
    fallback: <PageSkeleton />
  }
);

const Title: FC = () => {
  const { data: department, isLoading } = useGetAnalystSurveyDepartmentByUrlParam();

  if (!department && isLoading) {
    return <Skeleton title={{ width: 150 }} paragraph={false} active />;
  }

  return !department ? <>Not found</> : <>{department.name}<DepartmentStatus department={department} circle hideLabel /></>;
};

LoadableComponent.Title = Title;

export { LoadableComponent as AnalystQuestionGroupsPage };
