import React, { FC } from 'react';
import classNames from 'classnames';
import { Spin } from 'components/Spin';
import { BudgetTableValue } from '../../BudgetTable';
import tableCss from '../../BudgetTable.module.scss';
import css from './Cell.module.scss';

type CellProps = {
  fullWidth?: boolean;
  height?: 'small' | 'medium' | 'large';
  align?: 'top';
  color?: 'gray'
  isBold?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  prefix?: string;
  id?: string;
  appearance?: BudgetTableValue['appearance'];
}

export const Cell: FC<CellProps> = ({
  align,
  appearance,
  children,
  color,
  fullWidth = false,
  height = 'medium',
  id,
  isBold,
  isError,
  isLoading,
  prefix,
}) => (
  <div
    id={id}
    className={
      classNames(
        css.cell,
        tableCss.cellControl,
        {
          [css['cell--fullWidth']]: fullWidth,
          [css['cell--isLoading']]: isLoading,
          [css['cell--isError']]: isError,
          [css['cell--isBold']]: isBold,
          [css[`cell__height--${height}`]]: height,
          [css[`cell__color--${color}`]]: color,
          [css[`cell__align--${align}`]]: align,
          [css[`cell__appearance--${appearance}`]]: appearance,
        }
      )}
  >
    {prefix ? <div className={css.cell__prefix}>{prefix}</div> : null}
    {children}
    {isLoading ? <Spin className={css.cell__spinner} /> : null}
  </div>
);