/**
 * Components -> ScrollForm
 */

import React, { FC, ReactElement, useState, useRef, useMemo, useEffect } from 'react';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import { Button, ButtonProps } from 'components/Button';
import { Card } from 'components/Card';
import { Skeleton } from 'components/Skeleton';
import { Sidebar, Block, Section, ScrollFormSectionProps } from './components';
import Context, { ScrollFormContextInterface , ScrollFormSectionRefCollection } from './Context';
import useStickyHighlighting from './hooks/useStickyHighlighting';
import { IScrollFormSection } from './types';
import css from './ScrollForm.module.scss';

interface IScrollForm {
  skeleton?: boolean;
  id?: string;
  children?: ReactElement<ScrollFormSectionProps> | Array<ReactElement<ScrollFormSectionProps>> | null;
  onAutosaveToggle?: () => void;
  autosaveEnabled?: boolean;
  saving?: boolean;
  renderStart?: ReactElement;
  saveButtonProps?: ButtonProps;
  resetId?: number | string | undefined;
  extra?: ReactElement;
}

type SubComponents = {
  Block: typeof Block;
  Section: typeof Section;
}

const ScrollForm: FC<IScrollForm> & SubComponents = ({
  autosaveEnabled,
  children,
  id,
  onAutosaveToggle,
  renderStart,
  saveButtonProps,
  saving,
  skeleton,
  resetId,
  extra,
}) => {
  const rootRef = useRef(null);
  const scrollToHashTimeoutRef = useRef<number | null>(null);
  const currentResetId = useRef(resetId);
  const sectionRefs = useRef<{ [id: string]: ScrollFormSectionRefCollection }>({});
  const [sections, setSections] = useState<IScrollFormSection[]>([]);
  const sectionIds = useMemo(() => sections.map(s => s.id), [sections]);

  const scrollToSectionById = (sectionId: string) => {
    const el = document.getElementById(sectionId);

    if (el) {
      window.scrollTo({
        // Scroll section into the top of the viewport + a small offset to ensure that the header becomes sticky
        top: el.getBoundingClientRect().top + window.scrollY - 75,
      });
    }
  };

  // Scroll to section if needed before sticky highlighting is enabled
  const handleScrollToHash = async () => {
    const id = window.location?.hash?.replace('#', '');

    if (id) {
      // Prevent default browser logic when a hash ID is present
      window.scrollTo(0, 0);

      // Set the hash ID as the current section
      setInViewId(id);

      // Add a small timeout before scroling to section
      // to ensure that the sections has finished rendering
      scrollToHashTimeoutRef.current = window.setTimeout(() => {
        scrollToSectionById(id);
      }, 1000);
    }
  };

  // Track which section is in view and updates the sidebar highlighting
  const { inViewId, setInViewId } = useStickyHighlighting({
    sectionRefs: sectionRefs.current,
    onBeforeInit: handleScrollToHash
  });

  // Update or remove URL hash when the section inview ID changes
  useEffect(() => {
    if (inViewId) {
      history.pushState(null, '', `#${inViewId}`);
    }
  }, [inViewId]);

  // In some cases we want to reset and re-register the sections
  // We can trigger this externally by changing the "resetId"-prop
  const handleReset = () => {
    setSections([]);
    sectionRefs.current = {};
  };

  // Reset the scroll form when the reset ID changes – useful for triggering a reset externally
  useEffect(() => {
    if (currentResetId.current && currentResetId.current !== resetId) {
      handleReset();
      currentResetId.current = resetId;
    }
  }, [resetId]);

  // Register each section to our internal state
  const registerSection: ScrollFormContextInterface['registerSection'] = (data) => {
    // Prevent duplicated sections
    const ids = new Set(sectionIds);
    if (!ids.has(data.id)) {
      setSections(curr => curr.concat(data));
      sectionRefs.current = Object.assign(sectionRefs.current, { [data.id]: data.refs });
    }
  };

  // Update registred section data
  const updateSection = (data: Pick<IScrollFormSection, 'progress' | 'id'>) => {
    const section = sections.find(s => s.id === data.id);

    if (section) {
      const updatedSection = { ...section, ...data };
      setSections(curr => curr.map(s => s.id === data.id ? updatedSection : s));
    }
  };

  // Handle sidebar item click
  const handleItemClick = (id: string, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setInViewId(id);
    scrollToSectionById(id);
  };

  // Pass context down to each section
  const context = {
    registerSection,
    setInViewId,
    updateSection,
  };

  // Setup event listeners, clean-up, etc.
  useEffect(() => {
    // Clear highlighted section when the user scrolls to the top of the browser (or clicks go to top)
    const handleOnScroll = () => {
      if (window.scrollY === 0) {
        // Clear url hash
        history.pushState(null, '', `${window.location.pathname}${window.location.search}`);
        setInViewId('');
      }
    };

    document.addEventListener('scroll', handleOnScroll);

    return () => {
      document.removeEventListener('scroll', handleOnScroll);

      if (scrollToHashTimeoutRef.current) {
        clearTimeout(scrollToHashTimeoutRef.current);
      }
    };
  }, []);

  return (
    <Context.Provider value={context}>
      <div className={css.wrap}>
        {renderStart ? <div className={css.renderStart}>{renderStart}</div> : null}
        <div className={css.controls}>
          <div className={css.controls__autosave}>
            <span className={css.controls__autosave__label}>
              Autosave
            </span>
            <Switch
              checkedChildren={<CheckOutlined />}
              className={css.controls__autosave__switch}
              defaultChecked={autosaveEnabled}
              onChange={onAutosaveToggle}
              unCheckedChildren={<CloseOutlined />}
              disabled={skeleton}
            />
          </div>
          <Button
            className={css.controls__button}
            htmlType="submit"
            type="primary"
            size="large"
            {...saveButtonProps}
            disabled={saving || skeleton || saveButtonProps?.disabled}
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
        </div>
        <div className={css.root} id={id} ref={rootRef}>
          <Sidebar
            activeId={inViewId}
            items={sections}
            onItemClick={handleItemClick}
            skeleton={skeleton}
            renderStart={renderStart}
            showRenderStartBelow={true}
            extra={extra}
          />
          <Card className={css.content}>
            {skeleton ? (
              <div>
                <Skeleton
                  active
                  paragraph={{ rows: 6 }}
                />
              </div>
            ) : children}
          </Card>
        </div>
      </div>
    </Context.Provider>
  );
};

/* Define sub-components */
ScrollForm.Block = Block;
ScrollForm.Section = Section;

export default ScrollForm;
