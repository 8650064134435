import React, { FC, useState, useEffect, memo } from 'react';
import { ArrowLeftIcon, ArrowRightIcon, Button, ReloadIcon } from '@sprnova/nebula';
import { Modal, ModalProps } from 'antd';
import classNames from 'classnames';
import { range } from 'lodash';
import { usePrevious } from 'utils';
import css from './StepModal.module.scss';
import appCss from 'src/App.module.scss';

type Step = {
  title: React.ReactNode;
  render: () => React.ReactNode;
  canContinue?: boolean;
}

type Props = {
  onToggle: (open: boolean) => void;
  open: boolean;
  steps: Step[];
  step?: number;
  backButtonText?: string;
  cancelButtonText?: string;
  nextButtonText?: string;
  onStepChange?: (step: number) => void;
  onSubmit?: (resetHistory: () => void, close: () => void) => void;
  onClose?: () => void;
  submitButtonText?: string;
  canSubmit?: boolean;
  submitting: boolean;
  resetAdjustPricingForm: () => void;
};

const StepModal: FC<Props & ModalProps> = ({
  onToggle,
  open,
  steps,
  backButtonText,
  cancelButtonText,
  nextButtonText,
  onStepChange,
  onSubmit,
  submitButtonText,
  children,
  className,
  canSubmit,
  onClose,
  step,
  submitting,
  resetAdjustPricingForm,
  ...rest
}) => {
  const defaultInitialStep = 1;
  const initialHistory = [defaultInitialStep - 1];
  const [history, setHistory] = useState<number[]>(initialHistory);
  const currentStepIndex = history.at(-1) ?? 0;
  const currentStep = steps[currentStepIndex];
  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex === steps.length - 1;

  const shouldRenderCancelButton = true;
  const shouldRenderBackButton = !isFirstStep;
  const shouldRenderForwardButton = !isLastStep;
  const shouldRenderSubmitButton = isLastStep;

  const prevStep = usePrevious(step);
  const hasNewStep = prevStep && step && prevStep !== step;

  useEffect(() => {
    if (hasNewStep) {
      const newHistory = range(step);
      setHistory(newHistory);
    }
  }, [setHistory, hasNewStep, step]);

  const resetHistory = (): void => {
    setHistory(initialHistory);
  };

  const handleStepBackward = (): void => {
    // Pop off the most recent step from the history stack.
    setHistory(history => [...history.slice(0, history.length - 1)]);

    // Inform the parent of the updated step.
    onStepChange?.(currentStepIndex);
  };

  const handleStepForward = (): void => {
    // Push the next step onto the history stack.
    setHistory(history => [...history, currentStepIndex + 1]);

    // Inform the parent of the updated step.
    onStepChange?.(currentStepIndex);
  };

  const close = (): void => {
    onToggle(false);
    onClose?.();
  };

  const handleCancel = (): void => {
    resetAdjustPricingForm();
    resetHistory();
    close();
  };

  const handleSubmit = (): void => {
    onSubmit?.(resetHistory, close);
  };

  const footer = [
    <div className={css.footer} key="buttons">
      <div>
        {shouldRenderBackButton && (
          <Button
            startIcon={<ArrowLeftIcon />}
            variant="tertiary"
            onClick={handleStepBackward}
          >
            {backButtonText ?? 'Back'}
          </Button>
        )}
      </div>
      <div>
        {shouldRenderCancelButton && (
          <Button
            variant="secondary"
            onClick={handleCancel}
          >
            {cancelButtonText ?? 'Cancel'}
          </Button>
        )}
        {shouldRenderForwardButton && (
          <Button
            endIcon={<ArrowRightIcon />}
            variant="primary"
            onClick={handleStepForward}
            disabled={!currentStep.canContinue}
          >
            {nextButtonText ?? 'Next'}
          </Button>
        )}
        {shouldRenderSubmitButton && (
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={!canSubmit}
            startIcon={submitting ? <ReloadIcon className={appCss.icon_loading} htmlColor='#989898' /> : null}
          >
            { submitButtonText ?? 'Submit'}
          </Button>
        )}
      </div>
    </div>
  ];

  return (
    <Modal
      {...rest}
      className={classNames(css.root, className)}
      title={currentStep.title}
      onCancel={handleCancel}
      footer={footer}
      visible={open}
    >
      {children}
      {currentStep.render()}
    </Modal>
  );
};

export default memo(StepModal);
