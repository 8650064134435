/**
 * useCrms
 * Fetches all CRM types
 */

import { useState, useEffect } from 'react';
import { novaGraphQLClient, Projection } from 'api/entityGraphQL';
import { CRM } from '../../features/entitiesRedux';

type Type = {
  loading: boolean;
  error: string;
  crms: CRM[];
};

type UseCrmsArgs = { projection?: Projection };
export const useCrms = ({
  projection: defaultProjection,
}: UseCrmsArgs = {}): Type => {
  const [crms, setCrms] = useState<CRM[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [projection /* , setProjection */] = useState(defaultProjection);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { crms } = await novaGraphQLClient.fetchCrms({ projection });
        setError('');
        setCrms(crms);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error?.response?.errors?.[0]?.message || 'Unknown error');
      }
    })();
  }, [projection]);

  return { crms, loading, error };
};

export default useCrms;
