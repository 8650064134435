import React, { ReactNode } from 'react';
import { Alert as AntdAlert } from 'antd';
import { AlertProps as AntdAlertProps } from 'antd/lib/alert';
import classNames from 'classnames';
import { formatErrorToHumanReadable } from '../../utils';
import css from './Alert.module.scss';

export interface AlertProps extends AntdAlertProps {
  className?: string;
  message: ReactNode | string | { message: string };
}

const Alert = ({ className, message, ...props }: AlertProps): JSX.Element => {
  const messageString =
    typeof message === 'object' && (message as any)?.message
      ? formatErrorToHumanReadable((message as any)?.message)
      : message;

  return (
    <AntdAlert
      {...props}
      message={messageString}
      className={classNames(className, css.root)}
    />
  );
};

export default Alert;
