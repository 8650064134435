import React, { useCallback, useState } from 'react';
import { Autocomplete, Box, Button, Controller, Grid, Skeleton } from '@sprnova/nebula';
import { useGetClientsQuery } from 'api/crudGraphQL/clients/getClients';
import { useGetPlaybookAdsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookAds';
import { useGetPlaybookAdsetsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookAdsets';
import { useGetPlaybookCampaignsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookCampaigns';
import { uniqBy } from 'lodash';

type ControllerColumnProps = {
  control: any;
  field: any;
  setValue: any;
  index: number;
  remove: any;
  numberOfColumns: number;
};
export const ControllerColumn = ({ control, field, setValue, index, remove, numberOfColumns }: ControllerColumnProps): JSX.Element => {
  const [client, setClient] = useState<any>(field?.client_id || undefined);
  const [campaign, setCampaign] = useState<any>(field?.campaign_id || undefined);
  const [adset, setAdset] = useState<any>(field?.adset_id || undefined);
  const [ad, setAd] = useState<any>(field?.ad_id || undefined);
  const pagination = { page: 1, limit: 9999 };


  const { data: availableClients, isLoading: clientsLoading } = useGetClientsQuery(
    {
      has_playbook_campaigns: 1,
      ...pagination,
      projection: {
        id: true,
        name: true,
      }
    }
  );

  const { data: playbookCampaignsData, isLoading: campaignsLoading, isFetching: campaignsFetching } = useGetPlaybookCampaignsQuery({
    client_id: client?.id,
    ...pagination,
    projection: {
      id: true,
      name: true,
      adsets: {
        id: true,
        name: true
      }
    },
  }, { skip: !client?.id }
  );

  const { data: playbookAdsetsData, isLoading: adsetsLoading, isFetching: adsetsFetching } = useGetPlaybookAdsetsQuery({
    campaign_id: campaign?.id,
    ...pagination,
    projection: {
      id: true,
      name: true,
      ads: {
        id: true,
        name: true
      }
    }
  }, { skip: !campaign?.id }
  );

  const { data: playbookAdsData, isLoading: adsLoading, isFetching: adsFetching } = useGetPlaybookAdsQuery({
    adset_id: adset?.id,
    ...pagination,
    projection: {
      id: true,
      name: true,
    }
  }, { skip: !adset?.id }
  );

  const handleChange = useCallback((key: string, index: number, value?: any) => {
    setValue(`columns.${index}.${key}`, value);
    switch (key) {
      case 'client_id': {
        if (value === null) {
          setValue(`columns.${index}.campaign_id`, undefined);
          setValue(`columns.${index}.adset_id`, undefined);
          setValue(`columns.${index}.ad_id`, undefined);

          setClient(undefined);
          setCampaign(undefined);
          setAdset(undefined);
          setAd(undefined);
        }
        return setClient(value);
      }
      case 'campaign_id': {
        if (value === null) {
          setValue(`columns.${index}.adset_id`, undefined);
          setValue(`columns.${index}.ad_id`, undefined);

          setCampaign(undefined);
          setAdset(undefined);
          setAd(undefined);
        }
        return setCampaign(value);
      }
      case 'adset_id': {
        if (value === null) {
          setValue(`columns.${index}.ad_id`, undefined);

          setAdset(undefined);
          setAd(undefined);
        }
        return setAdset(value);
      }
      case 'ad_id': {
        if (value === null) {
          setValue(`columns.${index}.ad_id`, undefined);

          setAd(undefined);
        }
        return setAd(value);
      }
      default:
        return;
    }
  }, [setValue]);

  const handleLoading = useCallback((key: string) => {
    switch (key) {
      case 'client_id':
        return clientsLoading;
      case 'campaign_id':
        return campaignsLoading || campaignsFetching;
      case 'adset_id':
        return adsetsLoading || adsetsFetching;
      case 'ad_id':
        return adsLoading || adsFetching;
      default:
        return false;
    }
  }, [adsFetching, adsLoading, adsetsFetching, adsetsLoading, campaignsFetching, campaignsLoading, clientsLoading]);

  const handleDisabled = useCallback((key: string) => {
    switch (key) {
      case 'campaign_id':
        return !client?.id;
      case 'adset_id':
        return !campaign?.id;
      case 'ad_id':
        return !adset?.id;
      case 'client_id':
      default:
        return false;
    }
  }, [ad, adset, campaign, client, field]);

  const formatLabel = useCallback((key: string) => {
    switch (key) {
      case 'client_id':
        return 'Client';
      case 'campaign_id':
        return 'Campaign';
      case 'adset_id':
        return 'Adset';
      case 'ad_id':
        return 'Ad';
      default:
        return key;
    }
  }, []);

  const renderOptions = useCallback((key: string) => {
    switch (key) {
      case 'client_id': {
        if (clientsLoading) return [];
        return uniqBy(availableClients?.data?.map((client: any) => {
          const { id, name } = client;
          return { id, label: name };
        }), obj => obj?.id) || [];
      }
      case 'campaign_id': {
        if (!client?.id || campaignsLoading) return [];
        return playbookCampaignsData?.data?.map((campaign: any) => ({
          id: campaign?.id,
          label: campaign?.name
        }))  || [];
      }
      case 'adset_id': {
        if (!client?.id || !campaign?.id || adsetsLoading) return [];
        return playbookAdsetsData?.data?.map((adset: any) => ({
          id: adset?.id,
          label: adset?.name
        })) || [];
      }
      case 'ad_id': {
        if (!client?.id || !campaign?.id || !adset?.id || adsLoading) return [];
        return playbookAdsData?.data?.map((ad: any) => ({
          id: ad?.id,
          label: ad?.name
        })) || [];
      }
      default:
        return [];
    }
  }, [clientsLoading, availableClients?.data, client?.id, campaignsLoading, playbookCampaignsData?.data, campaign?.id, adsetsLoading, playbookAdsetsData?.data, adset?.id, adsLoading, playbookAdsData?.data]);

  const handleValues = useCallback((key: string) => {
    switch (key) {
      case 'client_id':
        return client;
      case 'campaign_id':
        return campaign;
      case 'adset_id':
        return adset;
      case 'ad_id':
        return ad;
      default:
        return undefined;
    }
  }, [ad, adset, campaign, client]);

  return (
    <Grid item xs={12 / numberOfColumns} key={index}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: '60px', marginBottom: '30px' }}>
        <Button onClick={() => remove?.(index)}>Remove</Button>
      </Box>
      {
        Object.entries(field).filter(([key]) => key !== 'id').map(([key]) => (
          <Controller
            key={`${key}_${index}`}
            control={control}
            name={`columns.${index}.${key}`}
            render={({ field: _field, fieldState: { error } }): React.ReactElement => (
              <>
                {
                  handleLoading(key)
                    ? <Skeleton
                      variant='rectangular'
                      height={48}
                      sx={{
                        width: 'calc(100% - 60px)',
                        marginBottom: '20px',
                        marginLeft: '60px',
                      }}
                    />
                    : <Autocomplete
                      {..._field}
                      value={handleValues(key)}
                      id={`${key}-field-${field.name}`}
                      error={error?.message}
                      label={formatLabel(key)}
                      options={renderOptions(key)}
                      disabled={handleLoading(key) || handleDisabled(key)}
                      onChange={(event, value) => {
                        handleChange(key, index, value);
                      }}
                      sx={{
                        marginBottom: '20px',
                        paddingLeft: '60px',
                      }}
                    />
                }
              </>
            )}
          />
        ))
      }
    </Grid>
  );
};
