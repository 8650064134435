import React, { memo, useCallback } from 'react';
import { Dialog, Spinner, useSnackbar } from '@sprnova/nebula';
import { useDeleteNovaAIChatMutation } from 'api/crudGraphQL/nova_ai/deleteNovaAIChat';
import { useDeleteNovaAIChatPublicMutation } from 'api/crudGraphQL/public/nova_ai/deleteNovaAIChatPublic';
import isExternalUser from 'utils/helpers/isExternalUser';
import { NovaAIResponseType, NovaAISessionType } from 'features/entitiesRedux/models/nova_ai/novaAI';
import filterSession from '../../utils/filterSession';

type DeleteSessionDialogProps = {
  open: boolean;
  toggle: () => void;
  session: NovaAISessionType;
  sessionSelected?: NovaAISessionType;
  sessionList: NovaAISessionType[];
  setSessionList: (sessions: NovaAISessionType[]) => void
  setSessionSelected: (session: NovaAISessionType) => void
  setAnswers: (answers: NovaAIResponseType[][]) => void;
  setQuestionsSubmitted: (questions: string[]) => void;
}

const DeleteSessionDialog = ({open, toggle, session, sessionList, setSessionList, setSessionSelected, setAnswers, sessionSelected, setQuestionsSubmitted}: DeleteSessionDialogProps): JSX.Element => {
  const isExternal = isExternalUser();

  const [deleteNovaAIChatRequest, {isLoading: isDeletingInternal}] = useDeleteNovaAIChatMutation();
  const [deleteNovaAIChatRequestPublic, {isLoading: isDeletingPublic}] = useDeleteNovaAIChatPublicMutation();

  const deleteNovaAIChat = isExternal
    ? deleteNovaAIChatRequestPublic
    : deleteNovaAIChatRequest;

  const isDeleting = isDeletingInternal || isDeletingPublic;

  const { addSnackbar } = useSnackbar();

  const handleDeleteSessionSession = useCallback(async () => {
    try {
      await deleteNovaAIChat({ session_guid: session.guid });
      const filteredSessions = filterSession(sessionList, session);
      setSessionList(filteredSessions);
      if (session === sessionSelected) {
        setSessionSelected(filteredSessions[0]);
        setAnswers([]);
      }
      if (filteredSessions?.length < 1) {
        setQuestionsSubmitted([]);
      }
      addSnackbar({
        variant: 'success',
        message: 'Session successfully deleted.',
      });
    } catch (e) {
      console.error('Error deleting session.', e);
      addSnackbar({
        variant: 'error',
        message: 'Error deleting session.',
      });
    }
    toggle();
  }, [addSnackbar, deleteNovaAIChat, session, sessionList, sessionSelected, setAnswers, setQuestionsSubmitted, setSessionList, setSessionSelected, toggle]);

  return (
    <Dialog
      size={'sm'}
      description="Are you sure you want to delete this chat session?"
      open={open}
      onClose={toggle}
      title="Confirm Delete Session"
      secondaryButtonProps={{ onClick: toggle, children: 'Cancel' }}
      primaryButtonProps={{ onClick: handleDeleteSessionSession, children: isDeleting ? <Spinner /> : 'Delete' }}
    >
    </Dialog>
  );
};

export default memo(DeleteSessionDialog);
