import { isValidElement } from 'react';
import jsPDF, { ImageCompression } from 'jspdf';
import { flatten } from 'lodash';
import rasterizeHTML, { RenderResult } from 'rasterizehtml';
import { renderToStaticMarkup } from 'react-dom/server';
import { version } from '../../../package.json';

type Options = {
  width?: number;
  height?: number;
  compression?: ImageCompression;
  elementStyles?: string;
  title?: JSX.Element | string;
  saveFileName?: string;
  pdfOptions?: {
    format?: 'PNG' | 'JPEG';
    x?: number;
    y?: number;
  };
  styleOverrides?: {
    [key: string]: Partial<CSSStyleDeclaration>;
  }
}

/**
 * Export a div element as a PDF
 * Recommended to clone the element before exporting
 * ex:
 * const element = printElement?.cloneNode(true) as HTMLElement;
 * exportPDF(element).then(() => element.remove());
 *
 * NOTE: You may need to do some additional processing on the element before passing it in (ex: removing margins)
 * NOTE: This function is not pure and will remove the element from the DOM
 *
 * @param el - The div element to export
 * @param options - Options for the PDF export
 * @param options.width - The width of the PDF
 * @param options.height - The height of the PDF
 * @param options.compression - The compression of the PDF
 * @param options.elementStyles - The styles to apply to the element
 * @param options.title - The title to display on the PDF
 * @param options.saveFileName - The name of the file to save (default: untitled-[DATE].pdf)
 * @param options.pdfOptions - The options for the PDF: format (PNG | JPEG), x, y (default: PNG, 0, 0)
 * @param options.styleOverrides - The styles to override on the document (ex: "#id-name" | ".class-name" { margin: 0 })
 */
export const exportPDF = async (el: HTMLDivElement | HTMLElement | null, options?: Options) => {
  const {
    /**
     * 1065 is a good width for a full page
     */
    width = 1065,
    height,
    compression='NONE',
    elementStyles,
    /**
     * Sits above the printRef element and below the header;
     * Good spot to describe the PDF
     */
    title,
    saveFileName,
    pdfOptions
  } = options ?? {};
  const { format='PNG', x=0, y=0 } = pdfOptions ?? {};

  const element = el?.cloneNode(true) as HTMLElement;
  const canvas = document.createElement('canvas');
  if (elementStyles) element.style.cssText = elementStyles;

  const container = document.createElement('div');
  container.style.cssText = `
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
    width: auto;
    position: absolute;
    z-index: 99;
  `;

  [...element.querySelectorAll('.no-image-available'), ...element.querySelectorAll('[style*="url"]')].forEach((el: Element): void => {
    if (el.classList.contains('no-image-available')) {
      /**
       * Hide the "No Image Available" component as well
       */
      (el as HTMLElement).style.display = 'none';
    }
    /**
     * Hide images that are not from the current domain
     * This is because the images will not render in the PDF
     * until we find a way to download the images and embed them in the PDF
     */
    const style = el.getAttribute('style');
    const urlMatch = style?.match(/url\(['"]?(.*?)['"]?\)/);
    if (urlMatch && urlMatch[1]) {
      const url = urlMatch[1];
      if (!url.includes(window.location.origin)) {
        (el as HTMLElement).style.display = 'none';
      }
    }
  });

  element.querySelectorAll('[role="combobox"]').forEach((el: Element): void => {
    /**
     * Center the text in the combobox
     * (vertical alignment is lost when exporting)
     */
    (el as HTMLElement).style.position = 'relative';
    const child = el.firstChild as HTMLElement;
    if (!child) return;
    child.style.position = 'absolute';
    child.style.top = '50%';
    child.style.transform = 'translateY(-50%)';
  });

  const header = document.createElement('div');
  const footer = document.createElement('div');

  if (title) {
    const titleDiv = document.createElement('div');
    titleDiv.style.cssText = `
      margin-top: 50px;
      margin-left: 40px;
    `;
    if (isValidElement(title)) {
      const staticMarkup = renderToStaticMarkup(title);
      titleDiv.innerHTML = staticMarkup;
    } else if (typeof title === 'string') {
      titleDiv.innerHTML = title;
    }
    container.appendChild(titleDiv);
  }

  header.innerHTML = `
    <div style="position: relative; width: 100%; display: flex; justify-content: space-between; align-items: flex-end; padding-left: 2em;">
      <div style=" margin-top: 50px; display: flex; flex-direction: column; align-items: flex-start; ">
        <img src="/nova-logo.png" style=" height: 0.75em; " />
        <div style=" display: flex; ">
          <div style=" height: 1px; width: 1.25em; margin-left: 16px; " />
              <svg
                width="166"
                height="32"
                x="0px"
                y="0px"
                viewBox="0 0 166 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style=" width: 10em; height: 2.5em; padding-top: 0.125em; "
              >
              <path d="M4.30899 1.14991V24.9379H0V1.14991H4.30899Z" fill="black"/>
              <path d="M11.6855 14.4842V24.9379H7.48103V7.09691H11.4996V10.1285H11.7087C12.1191 9.12958 12.7734 8.33588 13.6716 7.74737C14.5775 7.15886 15.6964 6.86461 17.0282 6.86461C18.2593 6.86461 19.3317 7.12789 20.2454 7.65445C21.1668 8.181 21.8792 8.94374 22.3825 9.94265C22.8935 10.9416 23.1451 12.1534 23.1374 13.5782V24.9379H18.9329V14.2287C18.9329 13.0362 18.6232 12.1031 18.0038 11.4294C17.3921 10.7557 16.5442 10.4189 15.4602 10.4189C14.7246 10.4189 14.0703 10.5815 13.4973 10.9067C12.9321 11.2242 12.4869 11.6849 12.1617 12.2889C11.8442 12.8929 11.6855 13.6247 11.6855 14.4842Z" fill="black"/>
              <path d="M35.0116 7.09691V10.3492H24.756V7.09691H35.0116ZM27.288 2.8225H31.4924V19.5717C31.4924 20.137 31.5776 20.5706 31.7479 20.8726C31.926 21.1668 32.1583 21.3682 32.4448 21.4766C32.7313 21.585 33.0488 21.6392 33.3972 21.6392C33.6605 21.6392 33.9005 21.6198 34.1173 21.5811C34.3418 21.5424 34.5122 21.5076 34.6283 21.4766L35.3368 24.7637C35.1123 24.8411 34.7909 24.9263 34.3728 25.0192C33.9624 25.1122 33.4591 25.1664 32.8629 25.1819C31.8099 25.2128 30.8614 25.0541 30.0174 24.7056C29.1734 24.3494 28.5036 23.7996 28.0081 23.0563C27.5202 22.3129 27.2802 21.3837 27.288 20.2686V2.8225Z" fill="black"/>
              <path d="M44.9259 25.2864C43.1372 25.2864 41.5925 24.9147 40.2917 24.1713C38.9986 23.4202 38.0036 22.3593 37.3068 20.9887C36.6099 19.6104 36.2614 17.9881 36.2614 16.122C36.2614 14.2868 36.6099 12.6761 37.3068 11.29C38.0114 9.89619 38.9947 8.8121 40.2568 8.03775C41.519 7.25566 43.0017 6.86461 44.7052 6.86461C45.8047 6.86461 46.8423 7.04271 47.8179 7.39891C48.8012 7.74737 49.6685 8.28941 50.4195 9.02505C51.1784 9.76068 51.7746 10.6976 52.2082 11.8359C52.6418 12.9665 52.8586 14.3139 52.8586 15.878V17.1673H38.2359V14.3332H48.8284C48.8206 13.5279 48.6464 12.8116 48.3057 12.1844C47.965 11.5494 47.4888 11.05 46.8771 10.686C46.2732 10.3221 45.5685 10.1401 44.7633 10.1401C43.9038 10.1401 43.1489 10.3492 42.4984 10.7673C41.848 11.1777 41.3409 11.7198 40.9769 12.3935C40.6208 13.0594 40.4388 13.7912 40.4311 14.5887V17.0628C40.4311 18.1004 40.6208 18.9909 41.0002 19.7343C41.3796 20.4699 41.91 21.0352 42.5914 21.4301C43.2727 21.8173 44.0703 22.0109 44.9839 22.0109C45.5956 22.0109 46.1493 21.9257 46.6448 21.7554C47.1404 21.5773 47.5701 21.3178 47.934 20.9771C48.298 20.6364 48.5728 20.2144 48.7587 19.7111L52.6844 20.1525C52.4366 21.1901 51.9643 22.0961 51.2674 22.8704C50.5783 23.637 49.6956 24.2333 48.6193 24.6592C47.543 25.0773 46.3119 25.2864 44.9259 25.2864Z" fill="black"/>
              <path d="M59.3117 1.14991V24.9379H55.1073V1.14991H59.3117Z" fill="black"/>
              <path d="M66.5314 1.14991V24.9379H62.327V1.14991H66.5314Z" fill="black"/>
              <path d="M69.5467 24.9379V7.09691H73.7511V24.9379H69.5467ZM71.6605 4.56479C70.9946 4.56479 70.4216 4.3441 69.9416 3.90272C69.4615 3.4536 69.2215 2.91543 69.2215 2.2882C69.2215 1.65324 69.4615 1.11506 69.9416 0.673683C70.4216 0.224561 70.9946 0 71.6605 0C72.3342 0 72.9071 0.224561 73.3795 0.673683C73.8595 1.11506 74.0996 1.65324 74.0996 2.2882C74.0996 2.91543 73.8595 3.4536 73.3795 3.90272C72.9071 4.3441 72.3342 4.56479 71.6605 4.56479Z" fill="black"/>
              <path d="M84.5249 32C83.015 32 81.718 31.7948 80.634 31.3844C79.55 30.9817 78.6789 30.4397 78.0207 29.7583C77.3626 29.0768 76.9057 28.3218 76.6502 27.4933L80.4365 26.5757C80.6069 26.9241 80.8547 27.2687 81.1799 27.6094C81.5051 27.9579 81.9426 28.2444 82.4923 28.469C83.0498 28.7013 83.7506 28.8174 84.5945 28.8174C85.787 28.8174 86.7742 28.527 87.5563 27.9463C88.3383 27.3733 88.7293 26.4286 88.7293 25.1122V21.7321H88.5203C88.3035 22.1658 87.986 22.611 87.5679 23.0679C87.1575 23.5247 86.6116 23.908 85.9302 24.2178C85.2566 24.5275 84.4087 24.6824 83.3866 24.6824C82.0161 24.6824 80.7734 24.361 79.6584 23.7183C78.5511 23.0679 77.6684 22.0999 77.0103 20.8145C76.3599 19.5214 76.0346 17.903 76.0346 15.9593C76.0346 14.0002 76.3599 12.347 77.0103 10.9996C77.6684 9.64453 78.555 8.61851 79.67 7.9216C80.785 7.21694 82.0277 6.86461 83.3983 6.86461C84.4436 6.86461 85.303 7.04271 85.9767 7.39891C86.6581 7.74737 87.2001 8.16939 87.6027 8.66497C88.0054 9.15281 88.3112 9.61355 88.5203 10.0472H88.7525V7.09691H92.8989V25.2283C92.8989 26.7538 92.535 28.016 91.8072 29.0149C91.0793 30.0138 90.0843 30.761 88.8222 31.2566C87.5601 31.7522 86.1277 32 84.5249 32ZM84.5597 21.3837C85.4502 21.3837 86.209 21.1668 86.8362 20.7332C87.4633 20.2996 87.9395 19.6762 88.2647 18.8632C88.5899 18.0501 88.7525 17.0744 88.7525 15.9361C88.7525 14.8133 88.5899 13.8299 88.2647 12.9858C87.9473 12.1418 87.4749 11.4875 86.8478 11.0229C86.2283 10.5505 85.4656 10.3143 84.5597 10.3143C83.6228 10.3143 82.8408 10.5583 82.2136 11.0461C81.5864 11.5339 81.1141 12.2038 80.7966 13.0555C80.4791 13.8996 80.3204 14.8598 80.3204 15.9361C80.3204 17.0279 80.4791 17.9843 80.7966 18.8051C81.1218 19.6181 81.598 20.2531 82.2252 20.71C82.8601 21.1591 83.6383 21.3837 84.5597 21.3837Z" fill="black"/>
              <path d="M103.798 25.2864C102.009 25.2864 100.464 24.9147 99.1633 24.1713C97.8702 23.4202 96.8753 22.3593 96.1784 20.9887C95.4815 19.6104 95.1331 17.9881 95.1331 16.122C95.1331 14.2868 95.4815 12.6761 96.1784 11.29C96.883 9.89619 97.8664 8.8121 99.1285 8.03775C100.391 7.25566 101.873 6.86461 103.577 6.86461C104.676 6.86461 105.714 7.04271 106.69 7.39891C107.673 7.74737 108.54 8.28941 109.291 9.02505C110.05 9.76068 110.646 10.6976 111.08 11.8359C111.513 12.9665 111.73 14.3139 111.73 15.878V17.1673H97.1076V14.3332H107.7C107.692 13.5279 107.518 12.8116 107.177 12.1844C106.837 11.5494 106.36 11.05 105.749 10.686C105.145 10.3221 104.44 10.1401 103.635 10.1401C102.775 10.1401 102.021 10.3492 101.37 10.7673C100.72 11.1777 100.213 11.7198 99.8486 12.3935C99.4924 13.0594 99.3105 13.7912 99.3027 14.5887V17.0628C99.3027 18.1004 99.4924 18.9909 99.8718 19.7343C100.251 20.4699 100.782 21.0352 101.463 21.4301C102.144 21.8173 102.942 22.0109 103.856 22.0109C104.467 22.0109 105.021 21.9257 105.516 21.7554C106.012 21.5773 106.442 21.3178 106.806 20.9771C107.17 20.6364 107.444 20.2144 107.63 19.7111L111.556 20.1525C111.308 21.1901 110.836 22.0961 110.139 22.8704C109.45 23.637 108.567 24.2333 107.491 24.6592C106.415 25.0773 105.184 25.2864 103.798 25.2864Z" fill="black"/>
              <path d="M118.183 14.4842V24.9379H113.979V7.09691H117.998V10.1285H118.207C118.617 9.12958 119.271 8.33588 120.169 7.74737C121.075 7.15886 122.194 6.86461 123.526 6.86461C124.757 6.86461 125.83 7.12789 126.743 7.65445C127.665 8.181 128.377 8.94374 128.88 9.94265C129.391 10.9416 129.643 12.1534 129.635 13.5782V24.9379H125.431V14.2287C125.431 13.0362 125.121 12.1031 124.502 11.4294C123.89 10.7557 123.042 10.4189 121.958 10.4189C121.223 10.4189 120.568 10.5815 119.995 10.9067C119.43 11.2242 118.985 11.6849 118.66 12.2889C118.342 12.8929 118.183 13.6247 118.183 14.4842Z" fill="black"/>
              <path d="M140.383 25.2864C138.602 25.2864 137.073 24.8953 135.795 24.1132C134.525 23.3312 133.546 22.2509 132.857 20.8726C132.175 19.4865 131.835 17.8914 131.835 16.0871C131.835 14.2751 132.183 12.6761 132.88 11.29C133.577 9.89619 134.56 8.8121 135.83 8.03775C137.108 7.25566 138.618 6.86461 140.36 6.86461C141.808 6.86461 143.089 7.13176 144.204 7.66606C145.327 8.19262 146.221 8.93987 146.887 9.9078C147.553 10.868 147.932 11.9908 148.025 13.2762H144.007C143.844 12.4167 143.457 11.7004 142.845 11.1274C142.241 10.5466 141.432 10.2563 140.418 10.2563C139.558 10.2563 138.803 10.4886 138.153 10.9532C137.502 11.41 136.995 12.0682 136.631 12.9278C136.275 13.7873 136.097 14.8172 136.097 16.0174C136.097 17.2332 136.275 18.2785 136.631 19.1535C136.988 20.0208 137.487 20.6906 138.13 21.163C138.78 21.6276 139.543 21.8599 140.418 21.8599C141.037 21.8599 141.591 21.7437 142.079 21.5114C142.574 21.2714 142.988 20.9268 143.321 20.4777C143.654 20.0286 143.883 19.4826 144.007 18.8399H148.025C147.925 20.1021 147.553 21.2211 146.91 22.1967C146.268 23.1647 145.393 23.9235 144.285 24.4733C143.178 25.0154 141.877 25.2864 140.383 25.2864Z" fill="black"/>
              <path d="M158.067 25.2864C156.279 25.2864 154.734 24.9147 153.433 24.1713C152.14 23.4202 151.145 22.3593 150.448 20.9887C149.751 19.6104 149.403 17.9881 149.403 16.122C149.403 14.2868 149.751 12.6761 150.448 11.29C151.153 9.89619 152.136 8.8121 153.398 8.03775C154.66 7.25566 156.143 6.86461 157.847 6.86461C158.946 6.86461 159.984 7.04271 160.959 7.39891C161.943 7.74737 162.81 8.28941 163.561 9.02505C164.32 9.76068 164.916 10.6976 165.35 11.8359C165.783 12.9665 166 14.3139 166 15.878V17.1673H151.377V14.3332H161.97C161.962 13.5279 161.788 12.8116 161.447 12.1844C161.106 11.5494 160.63 11.05 160.019 10.686C159.415 10.3221 158.71 10.1401 157.905 10.1401C157.045 10.1401 156.29 10.3492 155.64 10.7673C154.989 11.1777 154.482 11.7198 154.118 12.3935C153.762 13.0594 153.58 13.7912 153.572 14.5887V17.0628C153.572 18.1004 153.762 18.9909 154.142 19.7343C154.521 20.4699 155.051 21.0352 155.733 21.4301C156.414 21.8173 157.212 22.0109 158.125 22.0109C158.737 22.0109 159.291 21.9257 159.786 21.7554C160.282 21.5773 160.712 21.3178 161.075 20.9771C161.439 20.6364 161.714 20.2144 161.9 19.7111L165.826 20.1525C165.578 21.1901 165.106 22.0961 164.409 22.8704C163.72 23.637 162.837 24.2333 161.761 24.6592C160.684 25.0773 159.453 25.2864 158.067 25.2864Z" fill="black"/>
            </svg>
          </div>
        </div>
      <img src="/svg/logos/jump-menu/nova.svg" alt="nova logo" style=" height: 5em; width: 5em; position: absolute; right: 18px; top: 18px; " />
    </div>
  `;

  footer.innerHTML = `
    <div style=" position: relative; display: flex; align-items: center; flex-direction: column; font-size: 12px; margin-top: 60px; padding-bottom: 16px; ">
      <p>The content of this file is confidential and intended for the client specified only.</p>
      <p>Copyright © ${new Date().getFullYear()} – nova v${version} – Power Digital Marketing</p>
      <img src="/nova-logo.png" alt="nova logo" style=" height: 25px; position: absolute; bottom: 25px; right: 18px; " />
    </div>
  `;

  container.prepend(header);
  /**
   * Grabs all styles from the document and puts them in a single string;
   * then creates a style element and prepends it to the container.
   * (this addresses an issue on prod where the styles do not exist in the DOM, possibly due to the Emotion library)
   */

  const safelyExtractCssRules = (sheet: CSSStyleSheet) => {
    try {
      return [...sheet.cssRules].map(rule => rule.cssText);
    } catch (e) {
      console.warn('Could not extract cssRules from a stylesheet:', e);
      return [];
    }
  };

  const styleSheets = flatten([...document.styleSheets].map(sheet => flatten(safelyExtractCssRules(sheet)))).join('');

  const style = document.createElement('style');
  style.innerHTML = styleSheets;
  container.prepend(style);
  container.appendChild(element);

  /**
   * Sometimes if the browser is >/< 100% zoom,
   * the scrollbar gets included in the PDF.
   */
  const removeScrollbarStyles = document.createElement('style');
  removeScrollbarStyles.innerHTML = `
    * {
      scrollbar-width: none;
      -ms-overflow-style: none;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  `;
  container.prepend(removeScrollbarStyles);

  element.style.cssText = `
    background: white;
    min-width: 100%;
    padding: 40px 40px 0;
  `;
  container.appendChild(footer);

  if (options?.styleOverrides) {
    const keys = Object.keys(options.styleOverrides);
    keys.forEach(key => {
      const element = container.querySelector(key) as HTMLElement | any;
      if (element) {
        const styleKeys = Object.keys(options.styleOverrides?.[key] ?? {}) ?? [];
        if (Array.isArray(styleKeys)) {
          styleKeys.forEach((styleKey: any, index: number) => {
            element.style[styleKey.replace(/-./g, (x: any)=>x[1].toUpperCase())] = options.styleOverrides?.[key]?.[styleKey];
          });
        }
      }
    });
  }

  /**
   * Uncomment if you want to see the html in the browser for debugging
   */
  // document.body.appendChild(container)
  await rasterizeHTML.drawHTML(container?.innerHTML, canvas, { width }).then((renderResult: RenderResult) => {
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: [renderResult.image.height + 40, width],
    });

    const img = document.createElement('img') as HTMLImageElement;
    img.src = renderResult.image.src;

    const context = canvas.getContext('2d');
    canvas.width = renderResult.image.width;
    canvas.height = renderResult.image.height;
    context?.drawImage(img, 0, 0, renderResult.image.width, renderResult.image.height);
    const imgData = canvas.toDataURL(`image/${format.toLowerCase()}`);

    pdf.addImage(imgData, format, x, y, width, renderResult.image.height, undefined, compression);
    const date = `${new Date().toLocaleDateString('en-us', {})}`;
    pdf.save(`${saveFileName ? saveFileName : `untitled-${date}`}.pdf`);
  }).then(() => container.remove());
};
