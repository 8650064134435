import React, { useState } from 'react';
import { useHistory, useParams , Redirect } from 'react-router-dom';
import { Container } from '@sprnova/nebula';
import { Action } from 'api/accessControl';
import { useDeleteClientMutation } from 'api/crudGraphQL/clients/deleteClient';
import { useGetClientQuery } from 'api/crudGraphQL/clients/getClient';
import { useUpdateClientMutation } from 'api/crudGraphQL/clients/updateClient';
import { PageHero } from 'layouts/components';
import {
  Client,
  useClientByParamId,
  Resource,
} from 'features/entitiesRedux';
import {
  Card,
  Skeleton,
  notification,
  message,
  AccessControl,
  Alert,
} from 'components';
import { ClientForm } from '../ClientForm';
import { getClientProjection } from '../projections';
import css from './EditClientPage.module.scss';

type FormattedTitleProps = {
  formattedTitle?: string | number;
};

const formatClientTitle = ({
  client,
  isLoading,
}: {
  client?: Client;
  isLoading: boolean;
}) => {
  return !client && isLoading ? (
    <Skeleton title={{ width: 200 }} paragraph={false} active />
  ) : client ? (
    client.name
  ) : (
    '...'
  );
};

const EditClientPage: React.FC<FormattedTitleProps> = () => {
  const history = useHistory();
  const { clientId: idRaw = '' } = useParams<{ [x: string]: string }>();
  const clientId = Number.parseInt(idRaw);
  const [submitting, setSubmitting] = useState<boolean>(false);

  /**
   * RTK get client projection and query
   */
  const {data: client, isLoading, error } = useGetClientQuery({
    id: Number(clientId),
    projection: getClientProjection
  });
  const title = formatClientTitle({ client, isLoading });

  /**
   * RTK update & delete client mutations
   */
  const [updateClientRequest] = useUpdateClientMutation();
  const [deleteClientRequest] = useDeleteClientMutation();
  const handleSubmit = async (values: { [x: string]: any }) => {
    setSubmitting(true);
    if (values) {
      try {
        await updateClientRequest({id: clientId, ...values}).unwrap();
        message.success('Client saved');
      } catch (error) {
        console.error('Error updating client', error);
        message.error(`Error updating client ${error.message}`);
      }
    }
    setSubmitting(false);
  };

  const onDelete = async () => {
    if (client?.id) {
      setSubmitting(true);
      try {
        await deleteClientRequest({id: clientId}).unwrap();
        history.replace('/power-view/accounts');
        notification.success({
          message: `${client.name} deleted`,
        });
        setSubmitting(false);
      } catch (error) {
        console.error('Error deleting client', error);
        setSubmitting(false);
      }
    }
  };

  return <Redirect to={`/power-view/accounts/${clientId}`} />;

  // return (
  //   <>
  //     <PageHero
  //       title={title}
  //       description="Edit client"
  //     />
  //     <Container hasVerticalPadding>
  //       <Card className={css.root}>
  //         {error && (
  //           <Alert message={error} type="error" className={css.error} />
  //         )}
  //         <AccessControl
  //           action={[Action.update]}
  //           resource={Resource.client}
  //           authorId={client?.author?.id}
  //         >
  //           <>
  //             <ClientForm
  //               client={client || undefined}
  //               onSubmit={handleSubmit}
  //               onDelete={onDelete}
  //               isLoading={isLoading || submitting}
  //             />
  //           </>
  //         </AccessControl>
  //       </Card>
  //     </Container>
  //   </>
  // );
};

export const FormattedTitle = (): JSX.Element => {
  const client = useClientByParamId();
  const isLoading = !client;

  return <span>{formatClientTitle({ client, isLoading })}</span>;
};

export default EditClientPage;
