import React from 'react';
import loadable from '@loadable/component';
import { Skeleton } from 'antd';
import { useGetPlaybookAdsetsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookAdsets';

const CreativePlaybookAdsets = loadable(
  () => import('./CreativePlaybookAdSets')
);

const Title = (): JSX.Element => {
  const { data: playbookAdSetsData, isLoading } = useGetPlaybookAdsetsQuery({
    projection: {id: true}
  }, {refetchOnMountOrArgChange: true});

  if (!playbookAdSetsData || isLoading) {
    return <Skeleton title={{ width: 200 }} paragraph={false} active />;
  }

  return <>Ad Sets</>;

};

Title.displayName = 'CreativePlaybookAdsetsTitle';

export default Object.assign(CreativePlaybookAdsets, { Title });
