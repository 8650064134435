import React from 'react';
import { TooltipProps, Typography, Tooltip, styled, tooltipClasses, CircularProgress } from '@mui/material';
import { Dialog } from '@sprnova/nebula';

export const IntegrationsTableHeadLabel = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
}));

export const IntegrationsTableBodyLabel = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
}));

export const ConnectorStatusTooltipHeader = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '22px',
}));

export const FivetranIntegrationDialogue = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    maxWidth: 'none'
  },
  '& .MuiPaper-root': {
    maxWidth: '100%',
    width: 'auto',
  }
}));

export const IntegrationDialogueTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: '24px',
  fontWeight: 600,
}));

export const IntegrationDialogueContent = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  color: '#000000',
  padding: '10px 0px'
}));

export const FivetranStyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    maxWidth: '400px',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    overflowX: 'hidden',
  },
}));

export const IntegrationsTableHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 600,
}));

export const IntegrationsTableSubHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 500,
}));

export const LoadingIndicator = (): JSX.Element => (
  <CircularProgress size={19} thickness={7} sx={{color: '#0288D1'}} />
);

