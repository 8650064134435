import { find } from 'lodash';
import { DataSource } from '../../../entitiesRedux';

/**
 * Find name of of a DataSource using the slug.
 * @param dataSources - List of DataSources.
 * @param slug - DataSource slug to find in list.
 * @returns - DataSource name found in list from slug or undefined.
 */
export const getDataSourceName = (dataSources: DataSource[], slug: string): string => {
  const dataSource = find([...dataSources], ['slug', slug]);
  return dataSource ? dataSource['name'] : '';
};
