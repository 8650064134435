import React, { FC, useContext } from 'react';
import { EditOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { Blockquote } from 'components/Blockquote';
import { Button as ButtonComponent } from 'components/Button';
import { truncateString, useTheme } from 'utils';
import { AddNotesContext } from '../context';
import css from './Preview.module.scss';

interface PreviewProps {
  maxCharacters?: number;
  tag?: typeof React.Component | React.FunctionComponent;
  className?: string;
  truncate?: boolean;
}

const Preview: FC<PreviewProps> = ({
  maxCharacters = 125,
  tag: Wrapper = Blockquote,
  className,
  truncate = false,
  ...props
}) => {
  const { mode, notes, onEdit, loading } = useContext(AddNotesContext);
  const { isTalent } = useTheme();

  // Only render in form mode
  if (mode !== 'preview') {
    return null;
  }

  if (loading && !notes) {
    return <>...</>; // loading indicator
  }

  const truncated = notes ? truncateString(notes, maxCharacters) : undefined;
  return notes ? (
    <Wrapper className={classNames(className, css.wrapper)} {...props}>
      {truncate ? truncated : notes}
      <span style={{ marginLeft: 6 }} className={css.editButtonWrapper}>
        <ButtonComponent
          className={classNames(css.button, { [css.talent]: isTalent })}
          onClick={(): void => {
            onEdit();
          }}
          inline
          type="link"
        >
          <EditOutlined title="edit notes" />
        </ButtonComponent>
      </span>
    </Wrapper>
  ) : null;
};

export { Preview };
