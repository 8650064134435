/**
 * AddTasksModal -> SelectionList -> Item -> TaskItem
 */

import React, { FC, memo } from 'react';
import { SnippetsOutlined } from '@ant-design/icons';
import { Checkbox } from 'components/Checkbox';
import { Tooltip } from 'components/Tooltip';
import { Task } from 'features/entitiesRedux';
import { MultiplierSlug } from 'features/strategies/ViewStrategyPage/components/HourlyBreakdown/components/HourlyBreakdownTable/HourlyBreakdownTable';
import css from './TaskItem.module.scss';

type Props = {
  task: Task;
  checked: boolean;
  onToggle: () => void;
};

const TaskItem: FC<Props> = ({ task, checked, onToggle }) => {
  const subTasks = task.children;
  const hasChildren = Array.isArray(subTasks) && !!subTasks.length;
  const handleToggle = () => onToggle();
  const title = (
    <>
      {`${task.name}`}
      {task.multiplier ? (
        <>
          {' '}
          <MultiplierSlug {...task.multiplier} />
        </>
      ) : (
        ''
      )}
    </>
  );
  const renderSubTasksTooltip = hasChildren ? (
    <Tooltip
      title={
        <>
          <strong>Sub-tasks:</strong>
          {subTasks.map(({ name }) => (
            <div key={name}>{name}</div>
          ))}
        </>
      }
    >
      <SnippetsOutlined className={css.subTasksIcon} />
    </Tooltip>
  ) : null;

  return (
    <li key={task.id} className={css.root} title={task?.name || ''}>
      <Checkbox
        className={css.checkbox}
        onChange={handleToggle}
        checked={checked}
      >
        {title}
        {renderSubTasksTooltip}
      </Checkbox>
    </li>
  );
};

export default memo(TaskItem);
