import React, { useContext } from 'react';

export type ThemeConfig = 'default' | 'client' | 'talent';

export const ThemeContext = React.createContext<ThemeConfig>('default');

export const useTheme = () => {
  const themeConfig = useContext(ThemeContext);

  return {
    theme: themeConfig,
    isDefault: themeConfig === 'default' || themeConfig !== 'client',
    isClient: themeConfig === 'client',
    isTalent: themeConfig === 'talent',
  };
};
