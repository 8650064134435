/**
 * Library -> Analyst Surveys -> Components -> Department Status
 */

import React, { FC } from 'react';
import { Department } from 'features/entitiesRedux';
import { Status, StatusProps } from '../Status';

type DepartmentStatusProps = StatusProps & {
  department: Pick<Department, 'is_edit_mode'>
}

export const DepartmentStatus: FC<DepartmentStatusProps> = ({ department: { is_edit_mode }, ...rest }) => {
  if (is_edit_mode !== 'yes') {
    return null;
  }
  
  return <Status status="updated" {...rest}>Draft</Status>;
};
