import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from '@sprnova/nebula';
import {
  useGetNovaFeaturesPublicQuery
} from 'api/crudGraphQL/public/nova_features/getNovaFeaturesPublic';
import { AppDispatch } from 'app/store';
import { Warning } from 'components/AccessControl/Warning';
import { Card } from 'components/Card';
import { message } from 'components/message';
import { Spin } from 'components/Spin';
import { PageHero } from 'layouts/components/PageHero';
import isExternalUser from 'utils/helpers/isExternalUser';
import { novaFeaturesProjection } from 'features/clients/External/novaFeaturesProjection';
import { hasFeatureAccess } from 'features/clients/External/utils/hasFeatureAccess';
import { Client, ClientIntegration } from 'features/entitiesRedux/models/client';
import { deleteClientAccount } from 'features/entitiesRedux/models/client/actions';
import { useClientRefreshById } from 'features/entitiesRedux/models/client/hooks';
import { defaultClientIntegrationProgressProjection, defaultClientIntegrationProjection } from 'features/entitiesRedux/models/client/projections';
import { selectClientById } from 'features/entitiesRedux/models/client/selectors';
import { useExternalClientRefreshById } from 'features/entitiesRedux/models/external_client/hooks';
import { defaultExternalClientIntegrationProjection } from 'features/entitiesRedux/models/external_client/projections';
import { AlertRibbon } from 'features/intelligence/components/library-components';
import {
  AdNetworksIntegrationForm,
  AmazonAdsSearchIntegrationForm,
  AmazonAdsDspIntegrationForm,
  BigCommerceIntegrationForm,
  DataQIntegrationForm,
  FacebookMarketingIntegrationForm,
  MagentoIntegrationForm,
  SemRushIntegrationForm,
  ShopifyIntegrationForm,
  GoogleAdsIntegrationForm,
  GoogleAnalyticsIntegrationForm,
  GoogleAnalyticsFunnelIntegrationForm,
  UnrecognizedIntegration,
  TikTokIntegrationForm,
  MicrosoftBingIntegrationForm,
  HubSpotIntegrationForm,
  GoogleAnalytics4IntegrationForm
} from '../components';
import css from '../utils/CreateEditIntegrationPage.module.scss';

const EditIntegrationPage = (): JSX.Element => {
  /**
   * Page is used for both internal and client-facing.
   */
  const isClient = isExternalUser();
  const userType = isClient ? 'external' : 'internal';
  const redirectPath = isClient ? 'c' : 'clients';

  /**
   * Redux State.
   */
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  /**
   * UseState variables.
   */
  const [error, setError] = useState<string | JSX.Element | undefined>();
  const [refresh, setRefresh] = useState<boolean>(false);

  /**
   * Parse url params.
   */
  const { clientId: id, integrationId: idString } = useParams<{ [x: string]: string }>();
  const clientId = parseInt(id) || undefined;
  const integrationId = parseInt(idString) || undefined;

  /**
   * Load variables from path.
   */
  const { client, refresh: refreshClient } = isClient && clientId ?
    useExternalClientRefreshById(clientId, { projection: defaultExternalClientIntegrationProjection }) : clientId ?
      useClientRefreshById(clientId, { projection: defaultClientIntegrationProjection }) : { client: undefined, refresh: undefined};

  const currentClientIntegration: ClientIntegration | undefined = client?.accounts?.find((clientIntegration: ClientIntegration) => clientIntegration?.id === integrationId);
  const slug = currentClientIntegration?.account?.slug;
  const integrationName = currentClientIntegration?.account?.name;
  const isGaError = 'Please verify that you have the correct Google Analytics Integration selected (UA or GA4) by checking the integration title before creating or editing.';

  useEffect(() => {
    if (refresh) {
      refreshClient && refreshClient({ projection: defaultClientIntegrationProgressProjection });
      setRefresh(false);
    }
    else {
      setTimeout(() => setRefresh(true), 15000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  /**
   * Loading variable.
   */
  const isLoading = !(slug && currentClientIntegration?.id && integrationName && client);

  /**
   * Delete integration handler
   */
  const handleDelete = async (integrationId: number) => {
    setError(undefined);
    try {
      const updateAction = await dispatch(deleteClientAccount({
        id: integrationId,
        client_id: clientId || 0
      }));

      if (deleteClientAccount.fulfilled.match(updateAction)) {
        message.success('Integration deleted');

        history.push(`/${redirectPath}/${clientId}/integrations${isClient ? '#manage-integrations' : ''}`);
      }

      if (deleteClientAccount.rejected.match(updateAction)) {
        setError('While trying to delete your integration, something went wrong. Please try again or contact us through intercom!');
        console.error(`Error deleting ${integrationName} client integration: `, updateAction);
      }
    } catch (error) {
      setError('While trying to delete your integration, something went wrong. Please try again or contact us through intercom!');
      console.error(`Error deleting ${integrationName} client integration`, error);
    }
  };

  const renderForm = (): JSX.Element => {
    if (slug && client && userType) {
      switch (slug) {
        case 'ad-networks':
          return (
            <AdNetworksIntegrationForm
              client={client as Client}
              mode="edit"
              onDelete={handleDelete}
              refreshClient={refreshClient}
              integrationId={integrationId}
              setError={setError}
            />
          );
        case 'amazon-ads-search':
          return (
            <AmazonAdsSearchIntegrationForm
              client={client}
              onDelete={handleDelete}
              refreshClient={refreshClient}
              integrationId={integrationId}
              setError={setError}
              userType={userType}
            />
          );
        case 'amazon-ads-dsp':
          return (
            <AmazonAdsDspIntegrationForm
              client={client}
              onDelete={handleDelete}
              refreshClient={refreshClient}
              integrationId={integrationId}
              setError={setError}
              userType={userType}
            />
          );
        case 'bigcommerce':
          return (
            <BigCommerceIntegrationForm
              client={client}
              mode="edit"
              onDelete={handleDelete}
              integrationId={integrationId}
              setError={setError}
              userType={userType}
            />
          );
        case 'dataq':
          return (
            <DataQIntegrationForm
              client={client as Client}
              mode="edit"
              onDelete={handleDelete}
              refreshClient={refreshClient}
              integrationId={integrationId}
              setError={setError}
            />
          );
        case 'facebook-marketing':
          return (
            <FacebookMarketingIntegrationForm
              client={client}
              onDelete={handleDelete}
              refreshClient={refreshClient}
              integrationId={integrationId}
              setError={setError}
              userType={userType}
            />
          );
        case 'magento':
          return (
            <MagentoIntegrationForm
              client={client}
              mode="edit"
              onDelete={handleDelete}
              integrationId={integrationId}
              setError={setError}
              userType={userType}
            />
          );
        case 'shopify':
          return (
            <ShopifyIntegrationForm
              client={client}
              mode="edit"
              onDelete={handleDelete}
              integrationId={integrationId}
              setError={setError}
              userType={userType}
            />
          );
        case 'tiktok-ads':
          return (
            <TikTokIntegrationForm
              client={client}
              onDelete={handleDelete}
              refreshClient={refreshClient}
              integrationId={integrationId}
              setError={setError}
              userType={userType}
            />
          );
        case 'google-ads':
          return (
            <GoogleAdsIntegrationForm
              client={client}
              onDelete={handleDelete}
              refreshClient={refreshClient}
              integrationId={integrationId}
              setError={setError}
              userType={userType}
            />
          );
        case 'google-analytics':
          return (
            <GoogleAnalyticsIntegrationForm
              client={client}
              onDelete={handleDelete}
              refreshClient={refreshClient}
              integrationId={integrationId}
              setError={setError}
              userType={userType}
            />);
        case 'google-analytics-4':
          return (
            <GoogleAnalytics4IntegrationForm
              client={client}
              onDelete={handleDelete}
              refreshClient={refreshClient}
              integrationId={integrationId}
              setError={setError}
              userType={userType}
            />);
        case 'google-analytics-funnel':
          return (
            <GoogleAnalyticsFunnelIntegrationForm
              client={client as Client}
              mode="edit"
              onDelete={handleDelete}
              refreshClient={refreshClient}
              integrationId={integrationId}
              setError={setError}
            />
          );
        case 'microsoft-bing-ads':
          return (
            <MicrosoftBingIntegrationForm
              client={client}
              onDelete={handleDelete}
              refreshClient={refreshClient}
              integrationId={integrationId}
              setError={setError}
              userType={userType}
            />
          );
        case 'semrush':
          return (
            <SemRushIntegrationForm
              client={client as Client}
              mode="edit"
              onDelete={handleDelete}
              integrationId={integrationId}
              setError={setError}
            />
          );
        case 'hubspot-crm':
          return (
            <HubSpotIntegrationForm
              client={client}
              onDelete={handleDelete}
              refreshClient={refreshClient}
              integrationId={integrationId}
              userType={userType}
            />
          );
        default:
          return <UnrecognizedIntegration />;
      }
    } else {
      if (isLoading) {
        return <></>;
      }
      else {
        setError('There is data missing. Please refresh the page, if that does not solve the problem contact us through intercom.');
        return <></>;
      }
    }
  };

  /**
   * Feature access control (external users)
   */
  const { data: nova_features = [], isLoading: isLoadingFeatures } = useGetNovaFeaturesPublicQuery({ client_id: parseInt(id), projection: novaFeaturesProjection }, { skip: !parseInt(id) || typeof parseInt(id) !== 'number'});
  const hasAccess = useMemo(() => {
    return hasFeatureAccess(nova_features, 'integrations');
  }, [nova_features]);

  // show alert to external users if currently selected client does not have access to this feature
  if (isClient && !hasAccess && !isLoadingFeatures) {
    return <Warning container />;
  }

  return (
    <>
      <PageHero
        hideHome
        title={integrationName ? `Edit ${integrationName} Integration` : 'Loading...'}
      />
      <Container hasVerticalPadding>
        {
          (error && !isLoading) ?
            <div style={{marginBottom: '10px'}}><AlertRibbon type={error == isGaError ? 'info' : 'error'} content={error} /></div> : <></>
        }
        <Card className={css.root}>
          <h2 className={css.settingsLabel}>Settings</h2>
          { isLoading ? <Spin fontSize={45}/> : renderForm() }
        </Card>
      </Container>
    </>
  );
};

export const FormattedTitle = (): JSX.Element => {
  /**
   * Obtain client and integration ID from path.
   */
  const { clientId: id, integrationId: idString } = useParams<{ [x: string]: string }>();
  const clientId = parseInt(id) || undefined;
  const integrationId = parseInt(idString) || undefined;

  /**
   * Find the integration name.
   */
  const client: Client | undefined = (clientId && useSelector(selectClientById(clientId))) || undefined;
  const currentClientIntegrationName = client?.accounts?.find((clientIntegration: ClientIntegration) => clientIntegration?.id === integrationId)?.account?.name;

  return <span>{currentClientIntegrationName ? currentClientIntegrationName : 'Loading...'}</span>;
};

export default EditIntegrationPage;
