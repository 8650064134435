import React, { memo } from 'react';
import { Grid, theme } from '@sprnova/nebula';
import { PacingGoal } from 'api/crudGraphQL/scoreboards/types';
import GoalCard from './GoalCard';
import { hexToRgba } from '../../components/utils';


interface GoalCardsProps {
  isLoading?: boolean;
  metric?: string;
  metric_name?: string;
  format?: string;
  actual_value?: string;
  est_value?: string;
  goal_for_value?: string;
  goal_pace_value?: string;
  actual_platforms?: PacingGoal[];
  est_platforms?: PacingGoal[];
  goal_for_platforms?: PacingGoal[];
  goal_pace_platforms?: PacingGoal[];
}

const ActualColor = theme.variables?.colors.data_visualization.sequential[90];
const EstPaceColor= hexToRgba(ActualColor, 0.4);
const GoalColor = theme.variables?.colors.data_visualization.sequential[50];


const GoalCards = ({
  isLoading,
  metric_name,
  format,
  actual_value,
  est_value,
  goal_for_value,
  goal_pace_value,
  actual_platforms,
  est_platforms,
  goal_for_platforms,
  goal_pace_platforms,
}: GoalCardsProps): JSX.Element => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <GoalCard
          isLoading={isLoading}
          prefix='Actual'
          title={metric_name}
          value={actual_value}
          color={ActualColor}
          format={format}
          platforms={actual_platforms}
          infoIconText='Your current revenue'
        />
      </Grid>
      <Grid item xs={3}>
        <GoalCard
          isLoading={isLoading}
          prefix='Est. Pace'
          title={metric_name}
          value={est_value}
          color={EstPaceColor}
          format={format}
          platforms={est_platforms}
          infoIconText='The estimated total revenue based on current pace'
        />
      </Grid>
      <Grid item xs={3}>
        <GoalCard
          isLoading={isLoading}
          prefix='Goal for'
          title={metric_name}
          value={goal_for_value}
          color={GoalColor}
          format={format}
          platforms={goal_for_platforms}
          infoIconText='Your defined goal for revenue this term'
        />
      </Grid>
      <Grid item xs={3}>
        <GoalCard
          isLoading={isLoading}
          title='Goal Pace'
          value={goal_pace_value}
          format={format}
          platforms={goal_pace_platforms}
          infoIconText={<>Percent of your estimated pace versus your goal(<b>Actual revenue / Goal for revenue</b> x 100)</>}
        />
      </Grid>
    </Grid>
  );
};

export default memo(GoalCards);
