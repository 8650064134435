import React, { forwardRef, memo } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Select } from '@sprnova/nebula';
import css from './VirtualizedAutocomplete.module.scss';

type VirtualizedListBoxProps = React.HTMLAttributes<HTMLElement> & {
  children?: React.ReactNode;
  width?: number | string;
  itemHeight?: number;
};

/**
 * VirtualizedListBox component for efficiently rendering large lists.
 *
 * This component uses `react-window`'s FixedSizeList to render only the visible items in the list,
 * enhancing performance for large datasets.
 *
 * @returns {JSX.Element} The rendered list component.
 */
const VirtualizedListBox = forwardRef<HTMLDivElement, VirtualizedListBoxProps>(({ children, width = '100%', itemHeight = 45, ...rest }, ref) => {
  // Convert children to array
  const items = React.Children.toArray(children) as React.ReactElement[];

  // Count the number of items
  const itemCount = items.length;

  // Calculate the height of the FixedSizeList
  const height = Math.max(Math.min(itemCount * itemHeight, 300), itemHeight);

  // Function to render each row in the virtualized list
  const renderRow = ({ index, style }: ListChildComponentProps): JSX.Element => {
    return (
      <Select.Item
        style={{ ...style, paddingLeft: 0 }}
        key={index}
        value={items[index].props.value}
      >
        {items[index]}
      </Select.Item>
    );
  };

  return (
    <div ref={ref} {...rest}>
      <FixedSizeList
        height={height}
        width={width}
        itemCount={itemCount}
        itemSize={itemHeight}
        className={css.customScrollbar}
      >
        {renderRow}
      </FixedSizeList>
    </div>
  );
});

// Add a display name (because of forwardRef)
VirtualizedListBox.displayName = 'VirtualizedListBox';

export default memo(VirtualizedListBox);
