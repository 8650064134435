/**
 * Delete client
 */

import { Projection } from 'api/entityGraphQL/types';
import { Client } from 'features/entitiesRedux';
import { baseApi } from '..';
import noteTagTypes from '../am_dashboards/notes/tagTypes';
import dashboardTagTypes from '../am_dashboards/tagTypes';
import tagTypes from './tagTypes';

export type DeleteClientMutation = {
  id: number;
  projection?: Projection;
}

export type DeleteClientResult = Client;

export const { useDeleteClientMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    deleteClient: build.mutation<DeleteClientResult, DeleteClientMutation>({
      invalidatesTags: [
        tagTypes.CLIENTS,
        tagTypes.CLIENT,
        dashboardTagTypes.AM_DASHBOARD,
        dashboardTagTypes.AM_DASHBOARDS,
        noteTagTypes.AM_DASHBOARD_NOTE,
        noteTagTypes.AM_DASHBOARD_NOTES
      ],
      query: ({ id, projection = {} }) => ({
        body: {
          mutation: {
            deleteClient: {
              __args: {
                id
              },
              ...projection,
              id: true
            }
          }
        }
      })
    })
  })
});
