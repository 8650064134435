import React from 'react';

export const ContractAiIcon = () => (
  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="56" cy="63" r="8" fill="#E65957"/>
    <path d="M55 12.9997L11 97.9736" stroke="#4A1FFF" strokeWidth="16" strokeLinecap="round"/>
    <path d="M55 98H11" stroke="#FFA600" strokeWidth="16" strokeLinecap="round"/>
    <path d="M102 98L55 13" stroke="#D9D9D9" strokeWidth="16" strokeLinecap="round"/>
    <path d="M56 98L56 83" stroke="#958AE2" strokeWidth="16" strokeLinecap="round"/>
  </svg>

);
