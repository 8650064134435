/**
 * Get pillars
 */

import { Projection } from 'api/entityGraphQL/types';
import { Pillar } from 'features/entitiesRedux';
import { baseApi, cleanArguments } from '..';
import tagTypes from './tagTypes';

export type GetPillarsQuery = {
  id?: number;
  name?: string;
  projection: Projection;
};

export type GetPillarsResult = Pillar[];

export const GET_PILLARS_ENDPOINT_KEY = 'getPillars';

export const { useGetPillarsQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_PILLARS_ENDPOINT_KEY]: build.query<GetPillarsResult, GetPillarsQuery>({
      transformResponse: (response: { pillars: GetPillarsResult }) => response.pillars,
      providesTags: (result) => {
        const data = result ?? [];

        return [
          tagTypes.PILLARS,
          ...data?.map((pillar: Pillar) => ({
            id: pillar.id,
            type: tagTypes.PILLAR
          }))
        ];
      },
      query: ({
        projection = { id: true },
        name,
        ...args
      }) => {
        const __args: Partial<GetPillarsQuery> = args;

        if (name) {
          __args.name = `*${name}*`;
        }

        return {
          body: {
            query: {
              pillars: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        };
      }
    })
  })
});
