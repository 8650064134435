/**
 * SectionBuilder -> Fields -> TextField
 */

import React, { FC } from 'react';
import { FormItem, Input } from 'components';
import { FieldProps } from '../../types';
import css from './TextField.module.scss';

const TextField: FC<FieldProps> = ({ name, placeholder }) => (
  <FormItem
    name={name}
    rules={[{ max: 255, message: 'Cannot exceed 255 characters' }]}
  >
    <Input className={css.root} placeholder={placeholder} />
  </FormItem>
);

export default TextField;
