import { BLUEPRINT_LEAD_SOURCE } from 'features/strategies/constants';

/**
 * Get the BP flat fee commission:
 * - If the lead source is Channel Partner, return the flat fee commission. Otherwise, return 0 because flat fee commission only applies to that channel-partner lead source.
 *
 * @param {string|number} flatFeeCommission The flat fee commission: number or string in some cases
 * @param {string} leadSourceSlug The lead source slug to check if it is Channel Partner. If not passed, flat fee commission defaults to 0.
 * @returns The flat fee commission number
*/
export const getBlueprintFlatFeeCommission = (flatFeeCommission: string | number | undefined | null, leadSourceSlug?: string): number => {
  if (flatFeeCommission) {
    if (leadSourceSlug && leadSourceSlug === BLUEPRINT_LEAD_SOURCE.ChannelPartner) {
      return formatBlueprintFlatFeeCommission(flatFeeCommission);
    } else {
      return 0; // default flat fee commission to 0 if the lead source is not Channel Partner
    }
  } else {
    return 0; // default flat fee commission to 0 if not provided
  }
};

const formatBlueprintFlatFeeCommission = (flatFeeCommission: string | number): number => {
  if (typeof flatFeeCommission === 'string') { // flat fee commission can be a string in some cases: for instance when using <MonetaryField> component
    return parseFloat(flatFeeCommission);
  } else if (typeof flatFeeCommission === 'number') {
    return flatFeeCommission;
  } else {
    return 0;
  }
};
