import { getClient } from 'api/entityGraphQL/utils';
import moment from 'moment';
import { isBlankRegex } from 'features/audits/utils';
import { QuestionHandler } from '../actions';

export const dateQuestionHandler: QuestionHandler = (id, key, value, additionalInfo): any => {
  if (value !== undefined) {
    // if we don't have a date value then delete the question record
    if (value === null || (typeof value === 'string' && isBlankRegex(value))) {
      return getClient().deleteDiscoveryQuestion({
        discovery_id: additionalInfo?.discoveryId as number,
        question_id: id as number
      });
    }

    if (moment.isMoment(value)) {
      return getClient().updateDiscoveryQuestion({
        discovery_id: additionalInfo?.discoveryId as number,
        question_id: id as number,
        answer: moment(value).format('MM/DD/YYYY')
      });
    }

    return;
  }
};
