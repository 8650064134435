
import { Pagination, Projection } from 'api/entityGraphQL';
import { Opportunity } from 'features/entitiesRedux/models/opportunity';
import { baseApi } from '../baseApi';
import { paginationProjection } from '../projections';
import { cleanArguments } from '../utils';

export type GetNovaOpportunitiesQuery = {
  salesforce_id?: string;
  salesforce_ids?: string[];
  strategy_id?: number;
  page?: number;
  limit?: number;
  sort?: string[];
  projection?: Projection;
}

export type GetNovaOpportunitiesResult = Pagination & {
  data: Opportunity[]
};

export const GET_NOVA_OPPORTUNITIES_ENDPOINT_KEY = 'getNovaOpportunities';

export const { useGetNovaOpportunitiesQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_NOVA_OPPORTUNITIES_ENDPOINT_KEY]: build.query<GetNovaOpportunitiesResult, GetNovaOpportunitiesQuery>({
      transformResponse: (response: { opportunities: GetNovaOpportunitiesResult }) => response.opportunities,
      query: ({
        projection = { id: true, salesforce_id: true },
        ...args
      }) => {
        const __args: Partial<GetNovaOpportunitiesQuery> = args;

        return {
          body: {
            query: {
              opportunities: {
                __args: cleanArguments(__args),
                data: projection,
                ...paginationProjection
              }
            }
          }
        };
      }
    })
  })
});




