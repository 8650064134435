/**
 * useSalesforceOpportunities
 * Fetches all salesforce opportunities by salesforce account ID
 */

import { useState, useEffect } from 'react';
import { novaGraphQLClient } from 'api/entityGraphQL';
import { SalesforceOpportunity } from 'features/entitiesRedux/models/salesforce';

type Type = {
  loading: boolean;
  error: string;
  salesforce_opportunities: SalesforceOpportunity[];
}

export const useSalesforceOpportunities: (salesforceAccountId?: string | null) => Type = (salesforceAccountId) => {
  const [salesforce_opportunities, setSalesforceOpportunities] = useState<SalesforceOpportunity[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    let isCancelled = false;

    const fetchOpportunities = async () => {
      setLoading(true);
      try {
        const response = await novaGraphQLClient.fetchSalesforceOpportunities({
          account_id: salesforceAccountId || ''
        });

        if (!isCancelled) {
          setLoading(false);
          setSalesforceOpportunities(response.salesforce_opportunities);
          setError('');
        }
      } catch(error) {
        if (!isCancelled) {
          setLoading(false);
          setError(error?.response?.errors?.[0].message || 'Unknown error');
        }
      }
    };

    if (salesforceAccountId) {
      fetchOpportunities();
    } else {
      setSalesforceOpportunities([]);
      setLoading(false);
    }

    return () => {
      isCancelled = true;
    };

  }, [salesforceAccountId]);

  return { salesforce_opportunities, loading, error };
};

export default useSalesforceOpportunities;
