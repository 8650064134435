import React, { memo, useMemo, useState } from 'react';
import { Card, Grid, InfoTooltip, Typography, Tag, Skeleton } from '@sprnova/nebula';
import { PacingGoal } from 'api/crudGraphQL/scoreboards/types';
import PlatformCard from './PlatformCard';
import { formatValue } from '../../components/utils';

interface GoalCardProps {
  isLoading?: boolean;
  title?: string;
  value?: string | number;
  prefix?: string;
  color?: string;
  format?: string;
  infoIconText?: string | JSX.Element;
  platforms?: PacingGoal[];
}

const GoalCard = ({ isLoading, title, value, prefix = '', color, format, infoIconText, platforms }: GoalCardProps): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);
  const isValueLoading = !title || value !== 0 && !value;

  const renderHeader = useMemo(() => (
    <Grid item display='flex' alignItems='center' paddingBottom='12px'>
      {(isLoading || isValueLoading) ? (
        <>
          <Typography variant='h5' paddingRight='8px'>
            {prefix}
          </Typography>
          <Skeleton variant='text' width={100} height={20} />
        </>
      ) : (
        <>
          <Typography variant='h5' paddingRight='8px'>{`${prefix} ${title}`}</Typography>
          <InfoTooltip content={infoIconText} />
        </>
      )}

    </Grid>
  ), [isLoading, isValueLoading, prefix, title, infoIconText]);

  const renderValue = useMemo(() => {
    if (isLoading || isValueLoading) {
      return <Skeleton variant='text' width={100} height={20} />;
    } else if (title === 'Goal Pace') {
      return Number(value) > 0 ? (
        <Tag color='green'>{`${value}%`}</Tag>
      ) : (
        <Tag color='grey'>{`${value}%`}</Tag>
      );
    } else {
      return <Typography variant='h3'>{formatValue(Number(value), format)}</Typography>;
    }
  }, [isLoading, isValueLoading, title, value, format]);


  return (
    <div>
      <Card
        contentPadding='16px'
        textAlign='left'
        width='100%'
        height='89px'
        disablePadding
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          padding: '16px',
          position: 'relative',
          boxShadow: 'none',
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            width: '16px',
            height: '100%',
            backgroundColor: color ?? 'none',
            borderRadius: '0 8px 8px 0',
          },
        }}
      >
        {renderHeader}
        {renderValue}
      </Card>
      {(isHovered && platforms && platforms.length)
        ?
        <PlatformCard platforms={platforms} format={format} />
        :
        <></>
      }
    </div>
  );
};

export default memo(GoalCard);
