import { useState, useEffect } from 'react';
import { novaGraphQLPublicClient } from 'api/entityGraphQL/NovaGraphQLPublicClient';

/**
 * @deprecated Use `useGetMePublicQuery` instead.
 */
export const useExternalAccount = () => {
  const [me, setMe] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const me = await novaGraphQLPublicClient.getUser();
        setMe(me);
      }
      catch(e) {
        console.error(e as string);
      }
    })();
  }, []);

  return me;
};

export default useExternalAccount;
