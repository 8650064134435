/**
 * Components -> BlockList
 */

import React, { FC } from 'react';
import classnames from 'classnames';
import css from './BlockList.module.scss';

type BlockListProps = {
  children: React.ReactNode;
  className?: string;
}

export const BlockList: FC<BlockListProps> = ({ children, className, ...rest }) => (
  <ul className={classnames(css.root, className)} {...rest}>
    {children}
  </ul>
);

