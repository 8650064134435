import { Projection } from 'api/entityGraphQL';


export const getClientProjection: Projection = {
  id: true,
  asana_id: true,
  acquisition: {
    id: true,
    name: true,
    slug: true,
  },
  addresses: {
    id: true,
    alias: true,
    type: true,
    street: true,
    suite: true,
    city: true,
    state_code: true,
    postal_code: true,
    country_code: true,
    is_primary: true,
  },
  author: {
    id: true,
    name: true,
  },
  audits: {
    id: true,
    name: true,
    created_at: true,
    client: { id: true, name: true },
  },
  business_type: {
    id: true,
    name: true,
    slug: true,
  },
  contacts: {
    id: true,
    name: true,
    title: true,
    email: true,
    phone: true,
    is_decision_maker: true,
  },
  churn_date: true,
  churn_reasons: {
    id: true,
    churn_type: {
      id: true,
      name: true,
      slug: true,
    },
    churn_date: true,
    client_escalation_link: true,
    created_by: {
      id: true,
      name: true,
    },
    created_at: true,
    deleted_at: true,
    deleted_by: {
      id: true,
      name: true,
    },
    what_went_well: true,
    what_could_be_improved: true,
  },
  is_active: true,
  legal_name: true,
  logo: true,
  name: true,
  website: true,
  accounts: {
    id: true,
    auth_method: true,
    is_enabled: true,
    is_ingesting: true,
    data_source_account_id: true,
    url: true,
    value2: true,
    progress: true,
    reauthenticate_url: true,
    is_created_by_client: true,
    account: {
      id: true,
      slug: true,
      name: true,
      description: true,
      is_refreshable: true,
      group: true,
      disclaimer: true,
    },
  },
  account_manager: {
    id: true,
    name: true,
    avatar: true,
  },
  account_status: {
    id: true,
    slug: true,
    name: true,
    color: true
  },
  account_stage: {
    id: true,
    slug: true,
    name: true,
    color: true
  },
  all_churn_reasons: {
    id: true,
    churn_type: {
      id: true,
      name: true,
      slug: true,
    },
    churn_date: true,
    client_escalation_link: true,
    created_by: {
      id: true,
      name: true,
    },
    created_at: true,
    deleted_at: true,
    deleted_by: {
      id: true,
      name: true,
    },
    what_went_well: true,
    what_could_be_improved: true,
  },
  executive_sponsor: {
    id: true,
    name: true,
    avatar: true,
  },
  group_director: {
    id: true,
    name: true,
    avatar: true,
  },
  am_specialist: {
    id: true,
    name: true,
  },
  organization: {
    id: true,
    name: true,
  },
  industry: {
    id: true,
    name: true,
  },
  lead_source: {
    id: true,
    name: true,
  },
  status: true,
  reports: {
    id: true,
    name: true,
    logo: true,
    business_type: {
      name: true,
    },
    client: {
      id: true,
      name: true,
      account_manager: {
        name: true,
        avatar: true,
      },
      executive_sponsor: {
        name: true,
        avatar: true,
      },
    },
  },
  strategies: {
    id: true,
    audit: {
      id: true,
      name: true,
    },
    aging: true,
    author: {
      id: true,
      name: true,
    },
    blueprint_status: true,
    client: {
      id: true,
      name: true,
      business_type: {
        id: true,
        name: true,
        slug: true,
      },
      salesforce_client_id: true,
      salesforce_client_name: true,
    },
    date: true,
    detailed_reason: true,
    follow_up_date: true,
    hourly_rate: true,
    lead_source: {
      id: true,
      name: true,
      slug: true,
    },
    lead_source_employee: {
      id: true,
      name: true,
    },
    length: true,
    lost_reason_type: true,
    monthly_gross_profit: true,
    name: true,
    packages: {
      id: true,
      task: {
        name: true,
        slug: true,
        service: {
          name: true,
          slug: true,
        }
      }
    },
    pillar: {
      id: true,
      name: true,
      slug: true,
    },
    pricing_version: {
      slug: true,
    },
    revenue_calculation_version: true,
    reviewer_status: true,
    salesforce_is_primary: true,
    salesforce_opportunity_id: true,
    salesforce_opportunity_name: true,
    status: true,
    tasks: {
      name: true,
      slug: true,
      service: {
        id: true,
        name: true,
        slug: true,
      }
    },
    total_profit: true,
    total_retainer: true,
    type: true,
  },
  users: {
    name: true,
    id: true,
    avatar: true,
  },
  integration_requests: {
    reminded_at: true,
    requests: {
      data_source_id: true,
      access_request_id: true,
      access_request_status: true,
      access_request_created_at: true,
      manual_access_request_id: true,
      manual_access_request_status: true,
      manual_access_request_created_at: true,
    }
  },
  salesforce_client_id: true,
  salesforce_client_name: true,
  strategy_last_contract_to_end: {
    id: true,
    date: true,
    early_churn_date: true,
    guaranteed_term: true,
    length: true,
    pricing_version: {
      slug: true,
    }
  },
  netsuite_id: true,
  tier: {
    id: true,
    name: true,
    slug: true,
  },
  dashboard: {
    id: true,
    client: {
      name: true,
      account_manager: {
        id: true,
        name: true,
        avatar: true,
      },
      executive_sponsor: {
        id: true,
        name: true,
        avatar: true,
      },
      group_director: {
        id: true,
        name: true,
        avatar: true,
      },
      am_specialist: {
        id: true,
        name: true,
        avatar: true,
      },
    },
    churn: {
      name: true,
      color: true,
    },
    retainer: true,
    pace: {
      name: true,
      color: true,
    },
    rapport: {
      name: true,
      color: true
    },
    organization: {
      name: true,
    },
  },
};
