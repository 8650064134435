import React, { memo, useCallback, useEffect } from 'react';
import { Typography, TextField, MonetaryField, Textarea } from '@sprnova/nebula';
import classNames from 'classnames';
import { TextEditor } from 'components/TextEditor';
import { maxCharactersSnippetSummary } from '../constants';
import { ActionType, FormDataType } from '../packageStrategyTypes';import css from '../package-strategies.module.scss';

type MultiplierContractDetailsProps = {
  isSubmitted: boolean;
  dispatch: React.Dispatch<any>;
  formData: Partial<FormDataType>;
  formDataCached: Partial<FormDataType>;
}

const MultiplierContractDetails = ({ isSubmitted, dispatch, formData, formDataCached }: MultiplierContractDetailsProps): JSX.Element => {
  /**
   * Initialize form data with empty values otherwise the form keys won't exist
   * causing the form to be submit-able
   */
  const initializeEmptyForm = useCallback(() => {
    if (formDataCached.pricing_type_slug !== formData.pricing_type_slug) {
      dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_SINGLE_TIER, payload: {
        price: undefined,
        deliverable: undefined,
        snippet: '',
      }});
    }
  }, [dispatch, formData.pricing_type_slug, formDataCached.pricing_type_slug]);

  useEffect(() => {
    initializeEmptyForm();
  }, [initializeEmptyForm]);

  const handleMultiplierDeliverablePriceChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_SINGLE_TIER, payload: { price: Number.parseFloat(event.target.value) }});
  }, [dispatch]);

  const handleMultiplierDeliverableNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_SINGLE_TIER, payload: { deliverable: event.target.value }});
  }, [dispatch]);

  const handleAddOnMultiplierSnippetChange = useCallback((value: string) => {
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_SINGLE_TIER, payload: { snippet: value }});
  }, [dispatch]);

  return (
    <>
      <div className={classNames(css.two__fields__container, css.margin_top)}>
        <TextField
          id="multiplierDeliverableName"
          error={!formData?.pricing_tiers?.[0]?.deliverable && isSubmitted}
          label="Deliverable Name"
          onChange={handleMultiplierDeliverableNameChange}
          value={formData?.pricing_tiers?.[0]?.deliverable}
        />
        <MonetaryField
          id="multiplierDeliverablePrice"
          error={!formData?.pricing_tiers?.[0]?.price && isSubmitted}
          helperText="Price per 1 deliverable"
          label="Single Deliverable Price"
          onChange={(event): void => handleMultiplierDeliverablePriceChange(event)}
          value={formData?.pricing_tiers?.[0]?.price}
        />
      </div>
      <Textarea
        id="snippetSummaryField"
        error={formData?.snippet_summary && formData?.snippet_summary.length > maxCharactersSnippetSummary && isSubmitted}
        placeholder="This will appear in a tooltip when a user is adding this strategy to a Blueprint"
        label="Snippet summary"
        maxCharacters={maxCharactersSnippetSummary}
        minRows={2}
        resizeable
        sx={{ marginBottom: '24px', marginTop: '24px' }}
        onChange={(event): void => dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_DATA, payload: { snippet_summary: event.target.value } })}
        value={formData?.snippet_summary}
      />
      <Typography sx={{ fontWeight: 600, marginTop: '24px' }} display="block" gutterBottom>
        Proposal Snippet
      </Typography>
      <TextEditor
        value={formData?.pricing_tiers?.[0]?.snippet}
        className={classNames(css.text_editor, !formData?.pricing_tiers?.[0]?.snippet && isSubmitted ? css.snippet_error : undefined)}
        onChange={handleAddOnMultiplierSnippetChange}
      />
    </>
  );
};

export default memo(MultiplierContractDetails);
