import { formatRelative } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { utcToZonedTime } from 'date-fns-tz';
import { capitalize, isEmpty } from 'lodash';
import { PlaybookAd, PlaybookAdset, PlaybookCampaign } from 'features/entitiesRedux/models/creative_playbook';

export const formatCurrency = (num: number | string): string => {
  if (!num) return '-';
  if (typeof num === 'string') return num;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(num);
};

type OptionType = {
  divider?: number;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
}
enum Divider  {
  hold_rate = 100,
  stop_rate = 100,
  ctr = 100,
}
export const percentageFormatter = (num: number | string, options?: OptionType): string => {
  if (!num) return '-';
  if (typeof num === 'string') return num;

  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: options?.minimumFractionDigits || 2,
    maximumFractionDigits: options?.maximumFractionDigits || 2,
  }).format(num / (options?.divider || 10000)); // assuming the value is already a percentage (e.g. 7.66 = 7.66%); we may need another arg to control this if we discover that the value is not a percentage
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const dateFormatter = (date: string): Date | null => {
  if (!date) return null;
  const updatedAtDate: Date = utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
  if (Number.isNaN(updatedAtDate.valueOf())) {
    return null;
  }
  return updatedAtDate;
};

export const formatRelativeDate = (date?: string | Date | null): string => {
  if (!date) return 'Not updated yet';

  // Compute the relative date string, capitalizing the first letter
  let formattedDate = capitalizeFirstLetter(formatRelative(new Date(date), new Date(), { locale: enUS }));

  // Remove the time part (" at ...")
  formattedDate = formattedDate.replace(/ at .*/, '');

  return formattedDate;
};

export type Data = Partial<PlaybookCampaign | PlaybookAdset | PlaybookAd> & {
  [key: string]: string | number | any;
};
export const formatValuesBasedOnType = (data: Data, Enum: Record<string, any>, options?: OptionType): Data => {
  let values = {};
  for (const key in data) {
    switch (Enum[key]) {
      case 'currency':
        values = { ...values, [key]: formatCurrency(data[key] as number) };
        break;
      case 'percent': {
        if (isEmpty(options)) {
          values = {
            ...values,
            [key]: percentageFormatter(data[key] as number * 100,
              { divider: Divider[key as keyof typeof Divider] || undefined })
          };
        } else {
          values = { ...values, [key]: percentageFormatter(data[key] as number * 100, {
            ...options,
            divider: Divider[key as keyof typeof Divider] || undefined
          }) };
        }
        break;
      }
      case 'date':
        values = { ...values, [key]: dateFormatter(data[key] as string) };
        break;
      case 'array':
        if (Array.isArray(data[key])) {
          values = { ...values, [key]: data[key].length ? data[key].map((item: string) => capitalize(item)).join(', ') : '-' };
        } else {
          values = { ...values, [key]: data[key] || '-' };
        }
        break;
      case 'matched_metric': {
        const { ltv, cac } = data[key] ?? {};
        values = { ...values, ltv: formatCurrency(ltv), cac: formatCurrency(cac) };
        break;
      }
      case 'decimal':
        values = { ...values, [key]: typeof data[key] === 'string' ? data[key] : data[key]?.toFixed(2) };
        break;
      case 'number':
        values = { ...values, [key]: data[key]?.toLocaleString('en-US') };
        break;
      case 'string':
        values = { ...values, [key]: data[key] || '-' };
        break;
      default:
        values = { ...values, [key]: data[key] };
        break;
    }
  }
  return values;
};
