/**
 * View strategy -> HourlyRateBreakdownPopover
 */

import React, { FC, ReactNode } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { DescriptionList } from 'components/DescriptionList';
import { Popover} from 'components/Popover';
import { titleChange } from 'features/reports/ReportTabs/components/utils/titleChange';
import { kpiterms } from './KPITerms';
import { Term, terms } from './Terms';
import css from './TooltipPopover.module.scss';

type Props = {
  title?: ReactNode;
  subtitle?: ReactNode;
  content?: ReactNode;
  list?: [ReactNode, ReactNode][];
  Icon?: FC;
  term?: Term;
  kpiterm?: {
    title: any;
    content: any;
    equation: any;
  };
  extra?: ReactNode;
  placement?: TooltipPlacement;
  arrowPointAtCenter?: boolean;
};

const TooltipPopover: FC<Props> = ({
  title,
  subtitle,
  content,
  list,
  Icon = InfoCircleOutlined, // <InfoCircleOutlined className={css.icon} />,
  term,
  kpiterm,
  extra,
  placement,
  arrowPointAtCenter,
}) => {
  if (term) {
    title = terms[term].title;
    content = terms[term].content;
  }
  if (kpiterm) {
    try {
      title = kpiterm.title;
      content = (
        <>
          <div dangerouslySetInnerHTML={createMarkup(kpiterm.content)} style={{ marginBottom: '10px' }} />
          <div>{handleEquationFormat(kpiterm.equation)}</div>
          <div className={css.extra}>{extra}</div>
        </>
      );
    } catch (error) {
      console.log('Undefined KPI Metric =>', error);
      title = kpiterms['undefined-kpi-metric'].title;
      content = kpiterms['undefined-kpi-metric'].content;
    }
  }
  const Content = () => (
    <>
      {content}
      {list && (
        <DescriptionList className={css.list}>
          {list.map(([label, value], index) => (
            <DescriptionList.Item
              className={css.list__item}
              key={`list-item-${index}`}
              label={label}
              value={value}
            />
          ))}
        </DescriptionList>
      )}
    </>
  );

  return (
    <Popover
      content={<Content />}
      title={
        title || subtitle ? (
          <>
            {title && <div className={css.title}>{title}</div>}
            {subtitle && <div className={css.sub}>{subtitle}</div>}
          </>
        ) : undefined
      }
      trigger="hover"
      overlayClassName={css.overlay}
      placement={placement}
      arrowPointAtCenter={arrowPointAtCenter}
    >
      <Icon className={css.icon} />
    </Popover>
  );
};

export default TooltipPopover;

const createMarkup = (value: string) => {
  return {__html: value};
};

const handleEquationFormat = (format: string) => {
  const arr = format?.split('/');
  return arr ? (
    <pre>
      = {titleChange(arr[0])}
      <br />
      / {titleChange(arr[1])}
    </pre>
  ) :
    <></>;
};