import { EntityId } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { selectEntitiesDenormalized } from '../_entity/selectors';
import { usersAdapter } from './adapter';

export const userSelectors = usersAdapter.getSelectors(
  (state: RootState) => state.entities.data.users
);

export const selectUsersByIds = (userIds: EntityId[]) => (state: RootState) => {
  const input = { users: userIds };
  return selectEntitiesDenormalized(state, input).users;
};
export const selectUserById = (userId: number) => (state: RootState) => {
  return selectUsersByIds([userId])(state)[0];
};
export const selectAllUsers = () => (state: RootState) => {
  const userIds = userSelectors.selectIds(state);
  return selectUsersByIds(userIds)(state);
};