type Topic = {
  id: number,
  notes: string
}

export const getTopicNotes = (topics?: Topic[], topicId?: number) => {
  if (typeof topics !== 'undefined') {
    const filtered = topics.filter(topic => {
      return topic.id === topicId;
    });

    if (filtered.length) {
      return filtered[0].notes;
    }
  }
};