import { SalesforceOpportunity } from 'features/entitiesRedux/models/salesforce';

export const getSalesforceOpportunityStage = (
  salesforce_opportunities: SalesforceOpportunity[],
  opportunity_id?: string | null
): string | null => {
  const filtered = salesforce_opportunities?.filter(opp => opp.id === opportunity_id);
  if (filtered?.length) {
    return filtered?.[0]?.stage;
  }
  return null;
};
