import React from 'react';
import loadable from '@loadable/component';

const LoadableComponent: any = loadable(
  () => import('./AddOutsourceCostGuidePage')
);

const Title = (): JSX.Element => {
  return <>New Outsource Cost</>;
};

LoadableComponent.Title = Title;

export { LoadableComponent as AddOutsourceCostGuidePage };

