import { getClient } from 'api/entityGraphQL/utils';
import { QuestionHandler } from '../actions';

export const textQuestionHandler: QuestionHandler = (id, key, value, additionalInfo) => {
  if (typeof value === 'undefined') {
    return getClient().deleteDiscoveryQuestion({
      discovery_id: additionalInfo?.discoveryId as number,
      question_id: id as number,
    });
  } else {
    return getClient().updateDiscoveryQuestion({
      discovery_id: additionalInfo?.discoveryId as number,
      question_id: id as number,
      answer: value as any
    });
  }
};
