import React, { lazy, memo } from 'react';
import { useParams } from 'react-router';
import { Skeleton } from '@sprnova/nebula';
import { useGetStrategyQuery } from 'api/crudGraphQL/strategies/getStrategy';
import { strategyProjection } from '../strategyProjection';

export const ViewBlueprintPage = lazy(() => import('./ViewBlueprintPage'));

export const ViewBlueprintPageTitle = memo(() => {
  const { id } = useParams<{ [key: string]: string }>();
  const { data: strategy, isFetching } = useGetStrategyQuery({
    id: Number.parseInt(id),
    projection: strategyProjection
  });
  
  if (isFetching || !strategy) {
    return <Skeleton />;
  }
  
  return <>{strategy?.client?.name}</>;
});
  
ViewBlueprintPageTitle.displayName = 'ViewBlueprintPageTitle';
