import { DataSource } from './data_source';

/**
 * Data sources that are able to be connected too should come before the 'coming soon' data sources.
 *
 * @param dataSources
 * @returns - Sorted list of datasources, is available comes before 'Coming Soon'.
 *
 * NOTE: DataQ has a strange case. Hardcoded.
 */
export const sortByStatus = (
  dataSources: DataSource[],
): DataSource[] => {
  const availableNotRequestable = dataSources.filter((dataSource: DataSource) => dataSource?.is_available && !dataSource?.is_requestable);
  const available = dataSources.filter((dataSource: DataSource) => dataSource?.is_available && dataSource?.is_requestable);
  const manualOnly = dataSources.filter((dataSource: DataSource) => dataSource?.is_manual_only && dataSource?.slug !== 'dataq');
  const comingSoon = dataSources.filter((dataSource: DataSource) => !dataSource?.is_available && !dataSource?.is_manual_only);
  const orderedDataSources: DataSource[] = availableNotRequestable.concat(available).concat(manualOnly).concat(comingSoon);

  return orderedDataSources;
};

/**
 * Data sources that are able to be connected too should come before the 'coming soon' data sources.
 *
 * @param dataSources
 * @returns - Sorted list of datasources, is available comes before 'Coming Soon'.
 */
export const sortByRequestable = (
  dataSources: DataSource[],
): DataSource[] => {
  return dataSources?.filter((datasource: DataSource)=> {
    return datasource?.is_requestable;
  });
};