import React from 'react';
import { Skeleton as AntdSkeleton } from 'antd';
import { SkeletonProps } from 'antd/lib/skeleton';
import classNames from 'classnames';
import css from './SkeletonInput.module.scss';

interface Props extends SkeletonProps {
  className?: string;
}

const SkeletonInput = ({ className, ...props }: Props): JSX.Element => {
  return (
    <AntdSkeleton.Input
      {...props}
      className={classNames(className, css.root)}
    />
  );
};

export default SkeletonInput;
