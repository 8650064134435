/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useMemo, useReducer, useRef, useState } from 'react';
import {
  Alert,
  ArrowLeftIcon,
  Button,
  Card,
  Container,
  DownloadIcon,
  Grid,
  HeroTabs,
  TabsPanel,
  Typography,
} from '@sprnova/nebula';
import { Action } from 'api/accessControl/Action';
import { Resource } from 'api/accessControl/Resource';
import { useGetPlaybookAdsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookAds';
import { useGetPlaybookAdsetsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookAdsets';
import { useGetPlaybookCampaignsQuery } from 'api/crudGraphQL/creative_playbook/getPlaybookCampaigns';
import { AccessControl } from 'components/AccessControl';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { PageHero } from 'layouts/components';
import { capitalize, extend, isEmpty, isEqual, isNil, omit, omitBy, reduce, uniqBy } from 'lodash';
import { plural, singular } from 'pluralize';
import { DateParam, DelimitedNumericArrayParam, NumberParam, StringParam, useQueryParams } from 'use-query-params';
import exportCSV from 'utils/actions/exportCSV';
import { exportPDF } from 'utils/actions/exportPDF';
import { PlaybookAd, PlaybookAdset, PlaybookCampaign } from 'features/entitiesRedux/models/creative_playbook';
import { useAccount } from 'features/global';
import { AboutThisTool } from 'features/intelligence/components/AboutThisTool/AboutThisTool';
import { ComparisonModal } from './comparison/components/ComparisonModal';
import { CreativeAffinityComparison } from './comparison/CreativeAffinityComparison';
import { formatDataForCSVExport } from './utils';
import { TabNames } from './utils/constants';
import { formatImageUrl } from './utils/formatImageUrl';
import Filter from '../components/Filter/Filter';
import { FilterProvider, Filters } from '../components/Filters/Filters';
import CreativePlaybookAds from '../ViewCreativePlaybook/components/CreativePlaybookAds';
import { playbookAdsProjection } from '../ViewCreativePlaybook/components/CreativePlaybookAds/projection/projection';
import CreativePlaybookAdSets from '../ViewCreativePlaybook/components/CreativePlaybookAdSets';
import { playbookAdsetProjection } from '../ViewCreativePlaybook/components/CreativePlaybookAdSets/projection';
import CreativePlaybookCampaigns from '../ViewCreativePlaybook/components/CreativePlaybookCampaigns';
import { playbookCampaignProjection } from '../ViewCreativePlaybook/components/CreativePlaybookCampaigns/projection';
import { SortType } from '../ViewCreativePlaybook/constants';

export type CompareValue = (PlaybookCampaign | PlaybookAdset | PlaybookAd) & { id: number, type: string; };
export type ReducerType = { type: 'add' | 'remove' | 'reset'; value?: any };

export const CreativeAffinityOverviewPage = memo(function CreativeAffinityOverviewPage () {
  const [queryParams, setQueryParams] = useQueryParams({
    tab: NumberParam,
    compareValues: DelimitedNumericArrayParam,
    compareTab: StringParam,
    sort: StringParam,
    timePeriod: NumberParam,
    startDate: DateParam,
    endDate: DateParam,
    limit: NumberParam,
  });
  const [activeTab, setActiveTab] = useState<number | undefined | null>(queryParams.tab);
  const [compareValues, setCompareValues] = useReducer((state: CompareValue[], action: ReducerType): CompareValue[] => {
    let newState = state;
    switch (action.type) {
      case 'add': {
        if (!action.value) break;
        // Remove duplicates
        newState = uniqBy([...action.value], 'id');
        break;
      }
      case 'remove': {
        if (!action.value) break;
        newState = newState.filter(item => item.id !== action.value?.id);
        break;
      }
      case 'reset':
        newState = [];
        break;
      default:
        break;
    }
    return newState;
  }, []);

  const [compareTab, setCompareTab] = useState<string | undefined | null>(null);

  const defaultFilter = {
    sort: `-${SortType.ltv}`,
    limit: 10,
    page: 1,
  };

  const { filter, setFilter } = Filters(defaultFilter);

  const [exporting, setExporting] = useState<boolean>(false);

  const [creatingPDF, setCreatingPDF] = useState<boolean>(false);
  /**
   * Mixpanel event tracking
   */
  const mixpanel = useMixpanel();
  const { account } = useAccount();

  const trackTabClicked = useCallback((tab?: string) => {
    try {
      if (!mixpanel) return;
      const mixpanelTitle = `Creative Affinity - ${tab} Clicked`;
      const options = {
        userId: account?.id,
        userName: account?.name,
      };
      mixpanel.track(mixpanelTitle, options);
      if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
    } catch (error) {
      console.error('Track Mixpanel error', error);
    }
  }, [account, mixpanel]);

  const trackFilterApplied = useCallback((filters: Record<string, any>) => {
    try {
      if (!mixpanel || isEmpty(filter)) return;
      const mixpanelTitle = 'Creative Affinity - Filter Applied';
      const options = omitBy({
        userId: account?.id,
        userName: account?.name,
        ...filter,
        ...filters
      }, isNil);
      mixpanel.track(mixpanelTitle, options);
      if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
    } catch (error) {
      console.error('Track Mixpanel error', error);
    }
  }, [account, filter, mixpanel]);

  const printRef: React.Ref<HTMLDivElement> = useRef(null);

  const tabs = useMemo(() => {
    const props = {
      ids: compareValues,
      setIds: setCompareValues,
      trackFilterApplied,
    };
    return [
      {
        label: 'Show summary of:',
        disabled: true,
        disableRipple: true,
        disableFocusRipple: true,
        sx: {
          color: '#2C2C2C !important',
          paddingLeft: '0 !important',
        }
      },
      {
        label: plural(TabNames.campaignTitle),
        id: TabNames.campaigns,
        value: 1,
        component: <CreativePlaybookCampaigns {...props} />
      },
      {
        label: plural(TabNames.adsetTitle),
        id: TabNames.adsets,
        value: 2,
        component: <CreativePlaybookAdSets {...props} />
      },
      {
        label: plural(TabNames.adTitle),
        id: TabNames.ads,
        value: 3,
        component: <CreativePlaybookAds {...props} />
      },
    ];
  }, [compareValues, trackFilterApplied]);

  const trackRunComparison = useCallback(() => {
    try {
      if (!mixpanel) return;
      const mixpanelTitle = `Creative Affinity - Run ${activeTab ? tabs[activeTab].label : ''} Comparison`;
      const values: any = reduce(Object.values(compareValues).map((item, index) => ({
        [`id${index + 1}`]: item.id,
        [`name${index + 1}`]: item.name,
        [`clientName${index + 1}`]: (item as PlaybookCampaign)?.client?.name
          || (item as PlaybookAd)?.adset?.campaign?.client?.name
          || (item as PlaybookAdset).campaign?.client?.name,
        [`clientId${index + 1}`]: (item as PlaybookCampaign).client?.id
          || (item as PlaybookAd).adset?.campaign?.client?.id
          || (item as PlaybookAdset).campaign?.client?.id,
        [`platformName${index + 1}`]: item.platform.name
      })), extend);
      const options = {
        userId: account?.id,
        userName: account?.name,
        ...values,
      };
      mixpanel.track(mixpanelTitle, options);
      if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
    } catch (error) {
      console.error('Track Mixpanel error', error);
    }
  }, [account, activeTab, compareValues, mixpanel, tabs]);

  const trackExport = useCallback((type: 'CSV' | 'PDF') => {
    try {
      if (!mixpanel) return;
      const mixpanelTitle = `Creative Affinity - Export ${activeTab ? tabs[activeTab].label : ''} ${type}`;

      const options = {
        userId: account?.id,
        userName: account?.name,
      };
      mixpanel.track(mixpanelTitle, options);
      if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
    } catch (error) {
      console.error('Track Mixpanel error', error);
    }
  }, [account, activeTab, mixpanel, tabs]);

  const handleSetActiveTab = useCallback((_: React.SyntheticEvent | null, tab: number) => {
    setActiveTab(tab);
    trackTabClicked(tabs[tab].label);
    const limit = 10;
    // set the first page and limit when switching tabs but keep the other filters
    setFilter?.({ type: 'add', value: { page: 1, limit, sort: `-${SortType.ltv}`, } });
    /**
     * Clear compareValues when switching tabs
     */
    setQueryParams({
      tab,
      compareValues: undefined,
      compareTab: undefined,
      sort: `-${SortType.ltv}`,
      limit,
    });
    setCompareValues({ type: 'reset' });
    setCompareTab(null);
  }, [setFilter, setQueryParams, tabs, trackTabClicked]);
  /**
   * Queries for export functionality
   */
  const skip = useCallback((tab: TabNames) => {
    if (activeTab === undefined) return true;
    return !!(activeTab && tabs[activeTab].id !== tab);
  }, [activeTab, tabs]);

  const { data: campaigns, isLoading: campaignsLoading, error: campaignsError } = useGetPlaybookCampaignsQuery({
    ...filter,
    projection: playbookCampaignProjection,
  }, { skip: skip(TabNames.campaigns) });
  const { data: adSets, isLoading: adSetsLoading, error: adSetsError } = useGetPlaybookAdsetsQuery({
    ...filter,
    projection: playbookAdsetProjection,
  }, { skip: skip(TabNames.adsets) });
  const { data: ads, isLoading: adsLoading, error: adsError } = useGetPlaybookAdsQuery({
    ...filter,
    projection: playbookAdsProjection,
  }, { skip: skip(TabNames.ads) });

  const handleExportCSV = useCallback(async () => {
    await setExporting(true);
    if (typeof activeTab !== 'number') {
      // activeTab is not a number, skip the export
      return;
    }

    let title: string;
    let dataToExport;
    switch (activeTab) {
      case 1:
        title = 'Creative Affinity (Campaigns)';
        dataToExport = campaigns?.data;
        break;
      case 2:
        title = 'Creative Affinity (Ad Sets)';
        dataToExport = adSets?.data;
        break;
      case 3:
        title = 'Creative Affinity (Ads)';
        dataToExport = ads?.data;
        break;
      default:
        title = 'Creative Affinity';
    }

    if (!dataToExport) {
      // dataToExport is undefined, skip the export
      return;
    }

    const formattedData = formatDataForCSVExport(dataToExport, activeTab, tabs[activeTab].label);

    await exportCSV({
      data: formattedData,
      moduleName: title,
    }).finally(() => {
      setExporting(false);
      trackExport('CSV');
    });
  }, [activeTab, tabs, campaigns?.data, adSets?.data, ads?.data, trackExport]);

  const handleExportPDF = useCallback(async () => {
    const element = printRef.current?.cloneNode(true) as HTMLElement;
    const date = `${new Date().toLocaleDateString('en-us', {})}`;
    setCreatingPDF(true);
    exportPDF(
      element,
      {
        width: 1465,
        saveFileName: `Creative Affinity ${activeTab ? capitalize(tabs[activeTab].id) : ''} Comparison ${date}`,
      }
    ).then(() => {
      setCreatingPDF(false);
      trackExport('PDF');
      element.remove();
    });
  }, [activeTab, tabs, trackExport]);

  const renderAboutThisTool = useMemo(() => (
    <AboutThisTool
      cards={[
        {
          title: 'What is nova Intelligence Creative Affinity?',
          content: (
            <div>
              nova Intelligence Creative Affinity seamlessly merges first-party data with ad creative analysis, offering nuanced insights into customer lifetime value. Analyzing over 25 key KPIs for platforms like Facebook and Instagram, it benefits marketing teams, data analysts, and strategic planners. This tool refines ad strategies for teams, extracts detailed customer insights for analysts, and helps planners develop more effective campaigns. Crucially, it aligns creative strategies with customer data, enhancing marketing outcomes and building stronger relationships.
            </div>
          )
        },
        {
          title: 'What are the benefits of using Creative Affinity?',
          content: (
            <div>
              Benefits include, but aren&apos;t limited to:
              <br /><br />
              <ul>
                <li>
                  Holistic Performance Measurement: The module facilitates in-depth analysis crucial for platforms like Facebook and Instagram, allowing nuanced evaluations of creative performance and identifying the most effective elements in an advertising campaign.
                </li>
                <li>
                  Competitive Benchmarking: Creative Affinity provides a valuable tool for competitive benchmarking. Users can compare their ad creatives against industry standards, gaining insights into their content&apos;s standing in the competitive landscape.
                </li>
                <li>
                  Strategic Optimization: The comparison, coupled with detailed reporting, furnishes actionable insights that guide users in refining creative strategies. This ensures that the content is not only visually appealing but also strategically aligned with target audiences&apos; preferences.
                </li>
                <li>
                  Data-Informed Decision-Making: By leveraging these insights, marketing teams, data analysts, and strategic planners can make informed decisions to optimize advertising campaigns. This approach leads to improved customer acquisition, engagement, and retention, ultimately maximizing the return on investment in advertising endeavors.
                </li>
              </ul>
            </div>
          )
        },
        {
          title: 'How is Creative Affinity data being pulled?',
          content: (
            <div>
              <div>
                Creative Affinity data undergoes a sophisticated integration process, merging user-provided Facebook account information with eCommerce store data. This process, driven by a proprietary matching algorithm unique to the nova platform, accurately links first-party data with specific ad creatives.
              </div>
              <br />
              <div>
                When a user integrates their Facebook account, nova gains access to ad performance metrics and creative details. Simultaneously, integrating their eCommerce store provides access to detailed customer transaction data. The proprietary algorithm meticulously analyzes and correlates these data streams, identifying ad creatives associated with specific customer behaviors and transactions. This results in a precise mapping of ad creatives to real-world customer actions and value.
              </div>
            </div>
          )
        },
      ]}
      cardSx={{
        minHeight: '362px'
      }}
    />
  ), []);

  const renderEndButton = useMemo(() => {
    if (activeTab) {
      return (
        <Grid container spacing={2}>
          {
            compareValues.length > 0
            && compareTab
            && <Grid item>
              <Button
                onClick={() => {
                  setCompareValues({ type: 'reset' });
                  setCompareTab(null);
                }}
                size="large"
                startIcon={<ArrowLeftIcon />}
                variant="text"
              >
                Reset Comparison
              </Button>
            </Grid>
          }
          <Grid item>
            <Button
              onClick={
                compareTab
                  ? handleExportPDF
                  : handleExportCSV
              }
              size="large"
              startIcon={<DownloadIcon />}
              variant="secondary"
              disabled={exporting || campaignsLoading || adSetsLoading || adsLoading || creatingPDF}
            >
              Export
            </Button>
          </Grid>
        </Grid>
      );
    }
  }, [activeTab, compareValues.length, compareTab, handleExportPDF, handleExportCSV, exporting, campaignsLoading, adSetsLoading, adsLoading, creatingPDF]);

  const renderTabs = useMemo(() => {
    if (activeTab) {
      return (
        <HeroTabs
          value={activeTab}
          onChange={handleSetActiveTab}
          /**
           * Filter out the component prop from the tabs array
           * as it is not part of TabsItemProps
           */
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          items={tabs.map(({ component, ...rest }) => rest)}
        />
      );
    }
  }, [activeTab, handleSetActiveTab, tabs]);

  const renderError = useMemo(() => {
    const error = campaignsError || adSetsError || adsError;
    if (error) {
      console.error('Query Error:', error.message);
      return (
        <Alert severity='error' sx={{ marginTop: '20px' }}>
          There was an error with your request: {error.message}
        </Alert>
      );
    }
  }, [adSetsError, adsError, campaignsError]);

  const renderHero = useMemo(() => (
    <PageHero
      title='Creative Affinity'
      end={renderEndButton}
      tabs={renderTabs}
    >
      {
        !compareTab
          ? (
            <Filter
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              activeTabName={activeTab ? tabs[activeTab].id as string : undefined}
              error={renderError}
            />
          )
          : <></>
      }
    </PageHero>
  ), [renderEndButton, renderTabs, compareTab, activeTab, tabs, renderError]);

  const renderTabPanels = useMemo(() => {
    if (activeTab) {
      return (
        tabs.map((tab, index) => (
          <TabsPanel value={activeTab} index={index} key={tab.label}>
            {tab.component}
          </TabsPanel>
        ))
      );
    }
  }, [activeTab, tabs]);

  const handleRunComparison = useCallback(() => {
    /**
     * Because the user can still change tabs on the compare page,
     * we must differentiate between the active tab and the compare tab
     * to preserve the initial comparison while still allowing other functionality
     * on the compare page to work
     *
     * TODO: the ability for a user to change tabs on the compare page
     * is currently disabled, but this functionality will be added in the future
     */
    const tabId = activeTab ? tabs[activeTab].id : null;
    setCompareTab(tabId);
    trackRunComparison();
  }, [activeTab, tabs, trackRunComparison]);

  const renderSelectedCampaignsCopy = useMemo(() => {
    if (activeTab) {
      const { label } = tabs[activeTab];
      switch (compareValues.length) {
        case 1:
          return `Please select at least 1 more ${singular(label)} to run a comparison.`;
        case 2:
          return `You have selected 2 ${label} and can select up to 2 more.`;
        case 3:
          return `You have selected 3 ${label} and can select up to 1 more.`;
        case 4:
          return `You have selected the maximum amount of ${label} to compare.`;
      }
    }
    return '';
  }, [activeTab, compareValues.length, tabs]);

  const renderSelectedCampaigns = useMemo(() => {
    const label = activeTab ? tabs[activeTab]?.label : '';
    return (
      <Card
        title={
          <Grid container>
            <Grid item xs={10}>
              <Grid>
                <Typography variant='h4'>
                  Compare {label}
                </Typography>
                <Typography variant='caption' component='div' sx={{ height: '15px' }}>
                  {renderSelectedCampaignsCopy}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid container flexDirection='row'>
                <Button
                  size='small'
                  variant='text'
                  onClick={(): void => setCompareValues({ type: 'reset' })}
                  sx={{ marginRight: '24px' }}
                >
                  Cancel
                </Button>
                <Button
                  size='small'
                  variant='primary'
                  onClick={handleRunComparison}
                  disabled={compareValues.length <= 1}
                >
                  Run Comparison
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
        sx={{
          '& > div:first-child > div': {
            width: '100%',
          }
        }}
      >
        <Grid container spacing={2}>
          {
            compareValues.map((value, i) => {
              return (
                <ComparisonModal
                  /**
                   * Take the first asset url from the first ad
                   * from the first adset in a campaign
                   * because only ads have assets
                   */
                  url={formatImageUrl(value)}
                  name={value.name}
                  label={label}
                  index={i}
                  key={`comparisonModal_${value.id}`}
                />
              );
            })
          }
        </Grid>
      </Card>
    );
  }, [activeTab, compareValues, handleRunComparison, renderSelectedCampaignsCopy, tabs]);

  return (
    <Container
      maxWidth={false}
      hasVerticalPadding={false}
      disableHorizontalPadding
    >
      <AccessControl
        action={[Action.read]}
        resource={Resource.creativeAffinity}
      >
        <FilterProvider filter={{ filter, setFilter }}>
          <div>
            {renderHero}
            {
              !compareTab
                ? <Container hasVerticalPadding style={{ position: 'relative' }}>
                  {
                    // Direct the user to apply the filters on load
                    !activeTab || isEqual(defaultFilter, omit(filter, 'tab'))
                      ? renderAboutThisTool
                      : renderTabPanels
                  }
                </Container>
                : <></>
            }
            {
              compareTab
                ?
                <div ref={printRef}>
                  <CreativeAffinityComparison
                    compareTab={compareTab}
                    activeTab={activeTab ? tabs[activeTab].id : null}
                    compareValues={compareValues}
                  />
                </div>
                : <></>
            }
          </div>
          {
            compareValues.length > 0 && !compareTab
              ? (
                <Container
                  hasVerticalPadding
                  sx={{
                    position: 'sticky',
                    bottom: '32px',
                    zIndex: 99,
                    padding: '0 20px !important',
                  }}
                >
                  {renderSelectedCampaigns}
                </Container>
              )
              : <></>
          }
        </FilterProvider>
      </AccessControl>
    </Container>
  );
});
