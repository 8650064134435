/**
 * DescriptionList
 */

import React, { FC } from 'react';
import classnames from 'classnames';
import css from './DescriptionList.module.scss';

type WrapType = {
  children: any;
  className?: string;
}

const DescriptionListWrap: FC<WrapType> = ({ children, className, ...rest }) => (
  <div className={classnames(css.wrap, className)} {...rest}>
    {children}
  </div>
);

type ItemType = {
  className?: string;
  label?: any;
  value?: any;
}

const DescriptionListItem: FC<ItemType> = ({ className, label, value }) => (
  <div className={classnames(css.item, className)}>
    {label && <dt className={css.item__label}>{label}</dt>}
    {value && <dd className={css.item__value}>{value}</dd>}
  </div>
);

type ListType = {
  children?: any;
  className?: string;
  label?: any;
  extra?: JSX.Element;
}

const DescriptionList: FC<ListType> = ({ children, label, className, extra, ...rest }) => (

  <div className={classnames(css.root, className)} {...rest}>
    <div className={css.labelContainer}>
      {label ? <h3 className={css.list__label}>{label}</h3> : <></>}
      {extra ? extra : <></>}
    </div>
    <dl className={css.list}>
      {children}
    </dl>
  </div>
);

export default Object.assign(DescriptionList, {
  Wrap: DescriptionListWrap,
  Item: DescriptionListItem,
});
