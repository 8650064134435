/**
 * Update strategy task month
 */

import { Projection } from 'api/entityGraphQL';
import { TaskMonth } from 'features/entitiesRedux';
import { baseApi, cleanArguments } from '../../..';
import strategyTagTypes from '../../tagTypes';
import infoTagTypes from '../information/tagTypes';
import tagTypes from './tagTypes';

export type UpdateStrategyTaskMonthMutation = {
  strategy_id: number;
  task_id: number;
  month_id: number;
  hours?: number;
  recalculate?: boolean;
  projection?: Projection;
};

export type UpdateStrategyTaskMonthResult = TaskMonth;

export const UPDATE_STRATEGY_TASK_MONTH_ENDPOINT_KEY = 'updateStrategyTaskMonth';

export const { useUpdateStrategyTaskMonthMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [UPDATE_STRATEGY_TASK_MONTH_ENDPOINT_KEY]: build.mutation<UpdateStrategyTaskMonthResult, UpdateStrategyTaskMonthMutation>({
      transformResponse: (response: { updateStrategyTaskMonth: TaskMonth }) => response.updateStrategyTaskMonth,
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<UpdateStrategyTaskMonthMutation> = {
          ...values
        };

        return {
          body: {
            mutation: {
              updateStrategyTaskMonth: {
                __args: cleanArguments(__args),
                id: true,
              }
            }
          }
        };
      }
    })
  })
});
