import React, { memo} from 'react';
import { ActionMenu, ArrowRightIcon, DropdownIconButton, EditIcon, EllipsesIcon, FilterIcon, NavigationConnectionIcon, SettingsIcon } from '@sprnova/nebula';

type FunctionsMenuProps = {
  scoreboardId?: string;
  clientId?: string | number;
  toggleCustomize: () => void;
  toggleGoalFilter: () => void;
};
const FunctionsMenu = ({ scoreboardId, clientId, toggleCustomize, toggleGoalFilter }: FunctionsMenuProps): JSX.Element => {
  return (
    <DropdownIconButton
      icon={<EllipsesIcon />}
      placement="bottom-start"
      size="md"
      id='functions-menu'
    >
      <ActionMenu>
        <ActionMenu.Item
          id='functions-menu_customize'
          startIcon={<SettingsIcon />}
          onClick={toggleCustomize}
        >
          Customize Widgets
        </ActionMenu.Item>
        <ActionMenu.Item
          id='functions-menu_edit'
          startIcon={<EditIcon />}
          href={`/scoreboards/${scoreboardId}/edit`}
        >
          Edit Scoreboard
        </ActionMenu.Item>
        <ActionMenu.Item
          id='functions-menu_goal-filter'
          startIcon={<FilterIcon />}
          onClick={toggleGoalFilter}
        >
          Edit Goal Filter
        </ActionMenu.Item>
        <ActionMenu.Item
          id='functions-menu_integrations'
          startIcon={<NavigationConnectionIcon />}
          endIcon={<ArrowRightIcon />}
          href={`/power-view/accounts/${clientId}/integrations`}
          target='_blank'
          rel='noopener noreferrer'
        >
          View Integrations
        </ActionMenu.Item>
      </ActionMenu>
    </DropdownIconButton>
  );
};

export default memo(FunctionsMenu);

