import { memo } from 'react';
import { Paper } from '@mui/material';
import styled from 'styled-components';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme?.palette?.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme?.typography?.body2,
  textAlign: 'center',
  color: theme?.palette?.text?.secondary,
}));

export default memo(Item);
