import { Projection } from 'api/entityGraphQL/types';
import { NovaAIFeedbackReasonsType } from 'features/entitiesRedux/models/nova_ai';
import { baseApi } from '..';

export type GetNovaAIReasonsQuery = {
  projection?: Projection;
};

export type GetNovaAIReasonsResult = NovaAIFeedbackReasonsType[];

export const GET_NOVA_AI_REASONS_ENDPOINT_KEY = 'getNovaAIReasons';

export const { useGetNovaAIReasonsQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_NOVA_AI_REASONS_ENDPOINT_KEY]: build.query<GetNovaAIReasonsResult, GetNovaAIReasonsQuery>({
      transformResponse: (response: { nova_ai_reasons: GetNovaAIReasonsResult }) => response.nova_ai_reasons,
      query: ({
        projection = { id: true, name: true, slug: true },
      }) => ({
        body: {
          query: {
            nova_ai_reasons: {
              ...projection
            },
          }
        }
      })
    }),
  }),
});
