import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { StringParam, useQueryParams } from 'use-query-params';

const SignUpPage = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0();
  const [queryParams] = useQueryParams({ login_hint: StringParam });

  loginWithRedirect({
    authorizationParams: {
      screen_hint: 'signup',
      login_hint: queryParams.login_hint ?? undefined,
    }
  });

  return <></>;
};

export default SignUpPage;
