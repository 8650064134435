import { createAction, createSlice } from '@reduxjs/toolkit';
import { novaGraphQLClient } from 'api/entityGraphQL';
import { getClient } from 'api/entityGraphQL/utils';
import { orderBy } from 'lodash';
import { LABELS_TYPES } from 'utils';
import { FusionIntegration } from 'features/entitiesRedux';
import { YesNo } from 'features/entitiesRedux/models/types';
import { RootState } from '../../app/store';

/**
 * Types
 */
type Data = {
  date: string;
  actual: number;
  goal: number;
  pace: number;
}
export type PacingGoals = {
  id: number;
  type: string;
  refreshed_at: string;
  refresh_status: 'Error' | 'done' | 'no data' | 'pending' | null;
  term: number;
  start: string;
  metric: string;
  value: number;
  current_value: number;
  prediction: number;
  goal_value: number;
  percent_on_pace: number;
  percent_of_goal: number;
  indicator: {
    format: string | null;
  },
  percent_of_goal_platform: {
    platform: true,
    title: true,
    value: true,
  },
  current_platform: {
    platform: true,
    title: true,
    value: true,
  },
  percent_on_pace_platform: {
    platform: true,
    title: true,
    value: true,
  },
  prediction_platform: {
    platform: true,
    title: true,
    value: true,
  },
  data: Data[];
};
type Goal = {
  id: number;
  name: string;
  pacing_goals: PacingGoals[];
}
type WidgetMetricData = {
  date: string;
  value: number;
}
type WidgetMetric = {
  widget: any;
  id: number;
  // widget_id: number;
  name: string;
  slug: string;
  is_active: YesNo;
  indicators: {
    id: number;
    value: number;
    data: WidgetMetricData[];
  }
}
interface ReportsState {
  // reportPage: {
    reportId: number | undefined;
    isPreview: boolean | undefined;
    mode: 'edit' | 'create' | 'view' | undefined;
    modes: {
      'primary-kpis': 'edit' | 'create' | 'view';
      'status-update': 'edit' | 'create' | 'view';
      'goal-pacing': 'edit' | 'create' | 'view';
      'data-studio': 'edit' | 'create' | 'view';
    };
    currentEditStatus: number;
    kpis: any[] | null;
    // kpisCompared: any[] | null;
    goals: Goal | any;
    kpiButtonValues: [] | null;
    kpiComparedButtonValues: [] | null;
    widgets: any | null;
    sections: any;
    business_types: any[];
    isClientView: boolean;
    enableSaveButton: boolean;
    isLoading: boolean;
    isCreating: boolean;
    isUpdating: boolean;
    error: string | null;
    widgetMetrics: any;
    refreshedCheck: any[];
    refreshedWidgetCheck: any;
    newlyCreatedWidgetId: number;
    // widgetMetrics: WidgetMetric[];
  // };
}

/**
 * State
 */

const defaultScoreboardValues = {
  reportId: undefined,
  mode: 'view',
  modes: {
    'primary-kpis': 'view',
    'status-update': 'view',
    'goal-pacing': 'view',
    'data-studio': 'view',
  },
  currentEditStatus: 0,
  isPreview: false,
  kpis: [],
  goals: [],
  kpiButtonValues: [],
  kpiComparedButtonValues: [],
  widgets: [],
  // statuses: [],
  sections: [],
  business_types: [],
  isClientView: false,
  enableSaveButton: false,
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  error: null,
  widgetMetrics: {},
  refreshedCheck: [],
  refreshedWidgetCheck: {},
  newlyCreatedWidgetId: 0,
};
export const reportsInitialState: any = {
  isClientView: false,
  isLoading: false,
  error: null,
  modal: false,
};

/**
 * Actions
 */
export const setModal = createAction(
  'reports/setModal',
  function prepare(isModal: boolean) {
    return {
      payload: { isModal }
    };
  }
);
export const setReportId = createAction(
  'reports/setReportId',
  function prepare(reportId: number) {
    return {
      payload: { reportId }
    };
  }
);
export const setUpdateTrigger = createAction(
  'reports/setUpdateTrigger',
  /**
   * Used for determining if KPIs should be updated in the store
   */
  function prepare({ reportId, update }: { reportId: number, update: boolean }) {
    return {
      payload: { reportId, update }
    };
  }
);
export const setAccounts = createAction(
  'reports/setAccounts',
  function prepare({ reportId, accounts }: { reportId: number, accounts: any }) {
    return {
      payload: { reportId, accounts }
    };
  }
);
export const setFusionIntegrations = createAction(
  'reports/setFusionIntegrations',
  function prepare({ reportId, fusionIntegrations }: { reportId: number, fusionIntegrations: FusionIntegration[] }) {
    return {
      payload: { reportId, fusionIntegrations }
    };
  }
);
export const triggerIntegrationChangeRefresh = createAction(
  'reports/triggerIntegrationChangeRefresh',
  function prepare({ reportId, integrationChange }: { reportId: number, integrationChange: boolean }) {
    return {
      payload: { reportId, integrationChange }
    };
  }
);
export const removeReportById = createAction(
  'reports/removeReportById',
  function prepare(reportId: number) {
    return {
      payload: { reportId }
    };
  }
);
export const setLastRefreshedTime = createAction(
  'reports/setLastRefreshedTime',
  function prepare(reportId: number) {
    return {
      payload: { reportId, lastRefreshedTime: new Date().toString() }
    };
  }
);
export const setDashboardUpdatedAt = createAction(
  'reports/setDashboardUpdatedAt',
  function prepare(reportId: number, time: string | undefined) {
    return {
      payload: { reportId, dashboardUpdatedAt: time ? new Date(time).toString(): undefined }
    };
  }
);
export const setClientView = createAction(
  'reports/setClientView',
  function prepare({ isClientView, reportId }: { isClientView: boolean, reportId: number }) {
    return {
      payload: { isClientView, reportId },
    };
  }
);
export const changeMode = createAction('reports/changeMode', function prepare(
  { changeMode, reportId }: { changeMode: string, reportId: number },
) {
  return {
    payload: { changeMode, reportId },
  };
});
export const changeCurrentEditStatus = createAction(
  'reports/changeCurrentEditStatus',
  function prepare({ changeCurrentEditStatus, reportId }: any) {
    return {
      payload: { changeCurrentEditStatus, reportId },
    };
  }
);
export const toggleIsPreview = createAction(
  'reports/toggleIsPreview',
  function prepare(isPreview: any) {
    return {
      payload: { isPreview },
    };
  }
);

/**
 *  Async Actions
 */

export const fetchGAAuthMethod = (reportId: number) => async (
  dispatch: (handlePending: any) => void
) => {
  const __args = {
    id: reportId
  };
  const query = novaGraphQLClient.toGraphQL({
    query: {
      report: {
        __args: novaGraphQLClient.filterUndefinedNullEmpty(__args),
        google_analytics_auth_method: true,
      },
    },
  });
  try {
    dispatch(handlePending({ reportId }));
    const response = await getClient()
      .sendRequest(query)
      .catch((err: { message: string | undefined }) => console.log(err.message));
    dispatch(fetchGAAuthMethodFulfilled({ authMethod: response.report, reportId }));
  } catch (err) {
    dispatch(handleRejected({ err: `error fetching report services: ${err}`, reportId }));
  }
};

export const setGoalFilter = ({ reportId, filter }: { reportId: number, filter?: string[] }) => async (
  dispatch: (handlePending: any) => void
) => {
  try {
    const __args = {
      id: reportId,
      goal_name_filter: filter
    };
    await novaGraphQLClient.updateReport({ ...__args });
    await dispatch(fetchGoalNameFilter(reportId));
    dispatch(handlePending({ reportId }));
  } catch (err) {
    dispatch(handleRejected({ err: 'error fetching goal filter', reportId }));
  }
};

export const fetchGoalNameFilter = (reportId: number) => async (
  dispatch: (handlePending: any) => void
) => {
  const __args = {
    id: reportId
  };
  const query = novaGraphQLClient.toGraphQL({
    query: {
      report: {
        __args: novaGraphQLClient.filterUndefinedNullEmpty(__args),
        goal_name_filter: true,
        goal_name_list: true,
      },
    },
  });
  try {
    dispatch(handlePending({ reportId }));
    const response = await getClient()
      .sendRequest(query)
      .catch((err: { message: string | undefined }) => console.log(err.message));
    dispatch(fetchGoalNameFilterFulfilled({ filter: response.report, reportId }));
  } catch (err) {
    dispatch(handleRejected({ err: `error fetching report services: ${err}`, reportId }));
  }
};

export const fetchBusinessTypes = ({ reportId }: { reportId: number }) => async (
  dispatch: (handlePending: any) => void
) => {
  const query = `
    query {
      business_types {
        id
        name
        slug
      }
    }
  `;
  try {
    dispatch(handlePending({ reportId }));
    const response = await getClient()
      .sendRequest(query)
      .catch((err: { message: string | undefined }) => console.log(err.message));
    dispatch(fetchBusinessTypesFulfilled({ business_types: response.business_types, reportId }));
  } catch (err) {
    dispatch(handleRejected({ err: `Error fetching ${LABELS_TYPES.BUSINESS_UNIT}s`, reportId }));
  }
};
export const fetchSections = ({ reportId }: { reportId: number }) => async (
  dispatch: (handlePending: any) => void
) => {
  const query = `
    query {
      sections {
        id
        name
        slug
      }
    }
    `;
  try {
    dispatch(handlePending({ reportId }));
    const response = await novaGraphQLClient
      .sendRequest(query)
      .catch((err) => console.log(err.message));
    dispatch(fetchSectionsFulfilled({ sections: response.sections, reportId }));
  } catch (err) {
    dispatch(handleRejected({ err: 'error fetching sections', reportId }));
  }
};
const defaultStatusProjection = (args?: any) => {
  return {
    statuses: {
      __args: {
        limit: 10,
        ...args
      },
      id: true,
      is_draft: true,
      publish_date: true,
      auto_save: true,
      author: {
        id: true,
        name: true,
        title: true,
        email: true,
        url: true,
        avatar: true,
      },
      name: true,
      date: true,
      headline: true,
      status: true,
      sections: {
        id: true,
        title: true,
        content: true,
        order: true,
        section: {
          id: true,
          name: true,
          slug: true,
          question: true,
          description: true,
        }
      }
    }
  };
};
const defaultGoalPacingProjection = {
  id: true,
  name: true,
  goal_channel_list: true,
  goal_source_list: true,
  goal_name_filter: true,
  pacing_goals: {
    id: true,
    type: true,
    refreshed_at: true,
    refresh_status: true,
    term: true,
    start: true,
    metric: true,
    metric_name: true,
    value: true,
    current_value: true,
    prediction: true,
    goal_value: true,
    percent_on_pace: true,
    percent_of_goal: true,
    goal_channel_filter: true,
    goal_source_filter: true,
    is_filterable: true,
    indicator: {
      format: true,
    },
    percent_of_goal_platform: {
      platform: true,
      title: true,
      value: true,
    },
    current_platform: {
      platform: true,
      title: true,
      value: true,
    },
    percent_on_pace_platform: {
      platform: true,
      title: true,
      value: true,
    },
    prediction_platform: {
      platform: true,
      title: true,
      value: true,
    },
    data: {
      date: true,
      actual: true,
      goal: true,
      pace: true,
    },
  },
};
const defaultIndicatorsProjection = {
  id: true,
  name: true,
  indicators: {
    id: true,
    name: true,
    slug: true,
    platform: {
      title: true,
      value: true,
      compared_value: true,
      platform: true
    },
    data: {
      date: true,
      value: true,
      compared_date: true,
      compared_value: true,
    },
  },
};

export const createWidget = ({
  reportId,
  widgetId,
  title,
  settings,
  position,
  indicator_ids
}: {
  reportId: number;
  widgetId: number;
  title?: string;
  settings?: string;
  position?: number;
  indicator_ids?: number[],
 }) => async (
  dispatch: (handlePending: any) => void
) => {
  const __args = {
    report_id: reportId,
    widget_id: widgetId,
    // is_active: 'yes',
    title,
    settings,
    position,
    indicator_ids
  };
  try {
    dispatch(handlePending({ reportId }));
    const response = await novaGraphQLClient.createReportWidget(__args);
    await dispatch(fetchWidgets({ reportId }));
    const widgetId = response.createReportWidget.id;
    dispatch(createWidgetFulfilled({ widgetId, reportId }));
  } catch (err) {
    dispatch(handleRejected({ err: `error fetching report services: ${err}`, reportId }));
  }
};

export const fetchWidgets = ({ reportId }: { reportId: number }) => async (
  dispatch: (handlePending: any) => void
) => {
  const __args = {
    id: reportId
  };
  const query = novaGraphQLClient.toGraphQL({
    query: {
      report: {
        __args: novaGraphQLClient.filterUndefinedNullEmpty(__args),
        id: true,
        widgets: {
          id: true,
          widget_id: true,
          name: true,
          title: true,
          settings: true,
          position: true,
          slug: true,
          is_active: true,
          row: true,
          data: {
            id: true,
            title: true,
            content: true,
          }
        }
      }
    }
  });
  try {
    dispatch(handlePending({ reportId }));
    const response = await getClient()
      .sendRequest(query)
      .catch((err: { message: string | undefined }) => console.log(err.message));
    const widgetsWithHref = response?.report?.widgets?.map((widget: any) => {
      return { ...widget, href: `${widget.slug.replace(/-[a-z]/g, (x: string) => x.toUpperCase()).replace(/-/g, '')}-${widget.id}` };
    });
    const orderedWidgets = orderBy(widgetsWithHref, ['position'], ['asc']);
    dispatch(fetchWidgetsFulfilled({ orderedWidgets, reportId }));
  } catch (err) {
    dispatch(handleRejected({ err: `error fetching report services: ${err}`, reportId }));
  }
};

export const fetchWidgetMetrics = ({
  widgetId,
  reportId,
  range,
  from,
  to,
  type,
  total,
  compared
}: {
  widgetId: number;
  reportId?: number;
  range?: string;
  from?: string;
  to?: string;
  type?: string;
  total?: string;
  compared?: string;
}) => async (dispatch: (handlePending: any) => void) => {
  const __args = {
    id: reportId
  };
  const isCompared = !!compared;
  const isRange = !!range;
  const platform = {
    platform: {
      title: true,
      value: true,
      platform: true,
      compared_value: isCompared,
    }
  };
  const data = {
    data: {
      date: true,
      value: true,
      compared_date: isCompared,
      compared_value: isCompared,
      platform: {
        value: true,
        compared_value: isCompared,
        title: true,
        platform: true,
      }
    }
  };
  const query = novaGraphQLClient.toGraphQL({
    query: {
      report: {
        __args: novaGraphQLClient.filterUndefinedNullEmpty(__args),
        indicators: {
          __args: novaGraphQLClient.filterUndefinedNullEmpty({
            report_widget_id: widgetId,
            range,
            from,
            to,
            compared,
            type,
            total
          }),
          id: true,
          name: true,
          slug: true,
          category: true,
          format: true,
          tooltip: true,
          equation: true,
          value: isRange,
          compared_value: isRange,
          ...(isRange ? platform : {}),
          ...(isRange ? data : {}),
        }
      }
    }
  });

  try {
    dispatch(handlePending({ reportId }));
    const response = await getClient()
      .sendRequest(query)
      .catch((err: { message: string | undefined }) => console.log(err.message));
    const metric = response?.report?.indicators;
    dispatch(fetchWidgetMetricsFulfilled({ reportId, widgetId, metric }));
  } catch (err) {
    dispatch(handleRejected({ err: `error fetching report services: ${err}`, reportId }));
  }
};

export const checkRefreshedIndicators = ({
  reportId,
  widgetId
}: {
  reportId: number;
  widgetId?: number;
}) => async (dispatch: (checkRefreshedIndicators: any ) => void) => {
  const isWidget = !!widgetId;
  const __args = {
    id: reportId
  };
  const __widgetArgs = {
    type: 'day',
    report_widget_id: widgetId
  };
  const query = novaGraphQLClient.toGraphQL({
    query: {
      report: {
        __args: novaGraphQLClient.filterUndefinedNullEmpty(__args),
        refreshed_at: true,
        refresh_status: true,
        indicators: {
          __args: novaGraphQLClient.filterUndefinedNullEmpty(__widgetArgs),
          id: true,
          name: true,
        }
      }
    }
  });
  if (isWidget && widgetId) {
    try {
      dispatch(handlePending({ reportId }));
      const response = await getClient()
        .sendRequest(query)
        .catch((err: { message: string | undefined }) => console.log(err.message));
      dispatch(checkWidgetRefreshedIndicatorsFulfilled({ refreshedWidgetCheck: { [widgetId]: response.report.indicators }, reportId }));
    } catch (err) {
      dispatch(handleRejected({ err: `error fetching report services: ${err}`, reportId }));
    }
  } else {
    try {
      dispatch(handlePending({ reportId }));
      const response = await getClient()
        .sendRequest(query)
        .catch((err: { message: string | undefined }) => console.log(err.message));
      dispatch(checkRefreshedIndicatorsFulfilled({ refreshedCheck: response.report.indicators, reportId }));
    } catch (err) {
      dispatch(handleRejected({ err: `error fetching report services: ${err}`, reportId }));
    }
  }
};
export const refreshIndicators = ({
  reportId
}: {
  reportId: number
}) => async (dispatch: (handlePending: any) => void) => {
  try {
    await novaGraphQLClient.updateReport({ id: reportId, refreshed_at: null });
    dispatch(handlePending({ reportId }));
    dispatch(refreshIndicatorsFulfilled({ reportId, isRefreshing: true }));
  } catch (err) {
    dispatch(handleRejected({ err: 'error fetching report services', reportId }));
  }
};
export const setRefreshing = ({
  reportId,
  isRefreshing
}: { reportId: number, isRefreshing: boolean }) => async (dispatch: (handlePending: any) => void) => {
  try {
    dispatch(handlePending({ reportId }));
    dispatch(refreshIndicatorsFulfilled({ reportId, isRefreshing }));
  } catch (err) {
    dispatch(handleRejected({ err: `error fetching report services: ${err}`, reportId }));
  }
};
export const deletePacingGoal = ({
  reportId,
  id
}: {
  reportId: number;
  id: number;
}) => async (dispatch: (handlePending: any) => void) => {
  try {
    dispatch(handlePending({ reportId }));
    const response = await novaGraphQLClient.deletePacingGoal({ id });
    await dispatch(fetchPacingGoals(reportId));
    dispatch(deletePacingGoalFulfilled({ reportId }));
  } catch (err) {
    dispatch(handleRejected({ err: `error fetching report services: ${err}`, reportId }));
  }
};
export const updatePacingGoal = ({
  reportId,
  id,
  type,
  start,
  term,
  metric,
  goal,
  months,
  isCustom,
  goal_channel_filter,
  goal_source_filter,
}: {
  reportId: number;
  id: number;
  type: string;
  start: string;
  term: number;
  metric: string;
  goal: number;
  months: any[];
  isCustom: boolean;
  goal_channel_filter: string[];
  goal_source_filter: string[];
}) => async (dispatch: (handlePending: any) => any) => {
  try {
    dispatch(handlePending({ reportId }));
    const __args = {
      id,
      type,
      metric,
      start,
      term,
      value: goal,
      // value: (isCustom === undefined || isCustom) ? null : value,
      months,
      goal_channel_filter,
      goal_source_filter,
    };
    const response = await novaGraphQLClient.updatePacingGoal(__args);
    await dispatch(fetchPacingGoals(reportId));
    dispatch(updatePacingGoalFulfilled({ reportId }));
  } catch (err) {
    dispatch(handleRejected({ err: `error fetching report services: ${err}`, reportId }));
  }
};
export const createPacingGoal = ({
  reportId,
  type,
  start,
  term,
  metric,
  goal,
  months,
  isCustom,
  goal_channel_filter,
  goal_source_filter,
}: {
  reportId: number;
  type: string;
  start: string;
  term: number;
  metric: string;
  goal: number;
  months: any[];
  isCustom: boolean;
  goal_channel_filter: string[];
  goal_source_filter: string[];
}) => async (dispatch: (handlePending: any) => void) => {
  try {
    dispatch(handlePending({ reportId }));
    const __args = {
      report_id: reportId,
      type,
      metric,
      start,
      term,
      value: goal,
      // value: (isCustom === undefined || isCustom) ? null : value,
      months,
      goal_channel_filter,
      goal_source_filter,
    };
    const response = await novaGraphQLClient.createPacingGoal(__args);
    await dispatch(fetchPacingGoals(reportId));
    dispatch(createPacingGoalFulfilled({ reportId }));
  } catch (err) {
    dispatch(handleRejected({ err: `error fetching report services: ${err}`, reportId }));
  }
};
export const fetchStatuses = ({ reportId, exclude_draft }: { reportId: number, exclude_draft?: boolean }) => async (dispatch: (handlePending: any) => void) => {
  try {
    const __args = {
      id: reportId,
    };
    const statusesProjection = exclude_draft ? defaultStatusProjection({ exclude_draft: true }) : defaultStatusProjection();
    const query = novaGraphQLClient.toGraphQL({
      query: {
        report: {
          __args: novaGraphQLClient.filterUndefinedNullEmpty(__args),
          ...statusesProjection
        },
      },
    });
    const response = await getClient()
      .sendRequest(query)
      .catch((err: { message: string | undefined }) => console.log(err.message));
    dispatch(fetchStatusesFulfilled({ statuses: response.report, reportId }));
  } catch (err) {
    dispatch(handleRejected({ err: `error fetching report services: ${err}`, reportId }));
  }
};
export const fetchReportKpis = ({
  reportId,
  range,
  type,
  from,
  to,
  total,
  compared,
}: {
  reportId?: number;
  range?: string;
  from?: string;
  to?: string;
  type?: string;
  total?: string;
  compared?: string;
}) => async (dispatch: (handlePending: any) => void) => {
  const isCompared = !!compared;
  const isRange = !!range;
  const platform = {
    platform: {
      title: true,
      value: true,
      platform: true,
      compared_value: isCompared,
    }
  };

  const data = {
    data: {
      date: true,
      value: true,
      compared_date: isCompared,
      compared_value: isCompared,
      platform: {
        value: true,
        title: true,
        platform: true,
      },
      compared_platform: {
        value: true,
        title: true,
        platform: true,
      }
    }
  };
  const { data: { compared_platform, ...rest } } = data;

  const query = novaGraphQLClient.toGraphQL({
    query: {
      report: {
        __args: novaGraphQLClient.filterUndefinedNullEmpty({
          id: reportId,
        }),
        indicators: {
          __args: novaGraphQLClient.filterUndefinedNullEmpty({
            range,
            from,
            to,
            compared,
            type,
            total
          }),
          id: true,
          name: true,
          slug: true,
          category: true,
          format: true,
          tooltip: true,
          equation: true,
          value: isRange,
          compared_value: isCompared,
          ...(isRange ? platform : {}),
          ...(isRange ? isCompared ? data : ({ data: rest }) : {}),
        }
      }
    }
  });
  try {
    dispatch(handlePending({ reportId }));
    const response = await getClient()
      .sendRequest(query)
      .catch((err: { message: string | undefined }) => console.log(err.message));
    dispatch(fetchReportKpisFulfilled({ kpis: response.report, reportId }));
  } catch (err) {
    dispatch(handleRejected({ err: `error fetching report services: ${err}`, reportId }));
  }
};
export const fetchPacingGoals = (reportId: number | undefined) => async (
  dispatch: (handlePending: any) => void
) => {
  const __args = {
    id: reportId,
  };
  const query = novaGraphQLClient.toGraphQL({
    query: {
      report: {
        __args: novaGraphQLClient.filterUndefinedNullEmpty(__args),
        ...defaultGoalPacingProjection,
      },
    },
  });
  try {
    dispatch(handlePending({ reportId }));
    const response = await getClient()
      .sendRequest(query)
      .catch((err: { message: string | undefined }) => console.log(err.message));
    dispatch(fetchPacingGoalsFulfilled({ goals: response.report, reportId }));
  } catch (err) {
    dispatch(handleRejected({ err: `error fetching report services: ${err}`, reportId }));
  }
};

/**
 * Reducer
 */
export const reportsSlice = createSlice({
  name: 'reports',
  initialState: reportsInitialState,
  reducers: {
    setModal: (state, action) => {
      state.modal = action.payload.isModal;
    },
    setReportId: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId],
        reportId: action.payload.reportId,
        kpis: state[action.payload.reportId]?.kpis || [],
        // widgetMetrics: state[action.payload.reportId]?.widgetsMetrics || {},
        sections: state[action.payload.reportId]?.sections || [],
        newlyCreatedWidgetId: 0,
        isLoading: false,
        error: null,
        update: true,
        integrationChange: state[action.payload.reportId]?.integrationChange || false
      };
    },
    setAuthMethod: (state, action) => {
      state[action.payload.reportId] = {
        ...state[action.payload.reportId],
        authMethod: action.payload.authMethod
      };
    },
    setAccounts: (state, action) => {
      state[action.payload.reportId] = {
        ...state[action.payload.reportId],
        accounts: action.payload.accounts
      };
    },
    setFusionIntegrations: (state, action) => {
      state[action.payload.reportId] = {
        ...state[action.payload.reportId],
        fusionIntegrations: action.payload.fusionIntegrations
      };
    },
    setGoalFilter: (state, action) => {
      state[action.payload.reportId] = {
        ...state[action.payload.reportId],
        goalFilter: action.payload.filter
      };
    },
    triggerIntegrationChangeRefresh: (state, action) => {
      state[action.payload.reportId] = {
        ...state[action.payload.reportId],
        integrationChange: action.payload.integrationChange
      };
    },
    setUpdateTrigger: (state, action) => {
      const { reportId, update } = action.payload;
      if (!state[reportId]) {
        state[reportId] = { update: false };
      }

      state[reportId].update = update;
    },
    removeReportById: (state, action) => {
      const removeId = action.payload.reportId;
      const newState = state;
      delete newState[removeId];
      state = newState;
    },
    setLastRefreshedTime: (state, action) => {
      state[action.payload.reportId] = {
        ...state[action.payload.reportId],
        lastRefreshedTime: action.payload.lastRefreshedTime
      };
    },
    setDashboardUpdatedAt: (state, action) => {
      state[action.payload.reportId] = {
        ...state[action.payload.reportId],
        dashboardUpdatedAt: action.payload.dashboardUpdatedAt
      };
    },
    changeMode: (state, action) => {
      state[action.payload.reportId].modes = action.payload.changeMode;
    },
    changeCurrentEditStatus: (state, action) => {
      state[action.payload.reportId].currentEditStatus = action.payload.changeCurrentEditStatus;
    },
    /** Toggling preview state */
    toggleIsPreview: (state, action) => {
      state[action.payload.reportId].isPreview = action.payload.isPreview;
    },
    setClientView: (state, action) => {
      state.isClientView = action.payload.isClientView;
    },
    fetchBusinessTypes: (state, action) => {
      state[action.payload.reportId].business_types = action.payload.business_types;
    },
    fetchSections: (state, action) => {
      state[action.payload.reportId].business_types = action.payload.business_types;
    },
    fetchBusinessTypesFulfilled: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: false };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: null };
      state[action.payload.reportId].business_types = action.payload.business_types;
    },
    handlePending: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: true };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: null };
    },
    fetchSectionsFulfilled: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: false };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: null };
      state[action.payload.reportId].sections = action.payload.sections;
    },
    fetchGAAuthMethodFulfilled: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: false };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: null };
      state[action.payload.reportId].authMethod = action.payload.authMethod.google_analytics_auth_method;
    },
    fetchGoalNameFilterFulfilled: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: false };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: null };
      state[action.payload.reportId].goalNameFilter = action.payload.filter.goal_name_filter;
      state[action.payload.reportId].goalNameList = action.payload.filter.goal_name_list;
    },
    fetchPacingGoalsFulfilled: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: false };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: null };
      state[action.payload.reportId].goals = action.payload.goals;
    },
    fetchStatusesFulfilled: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: false };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: null };
      state[action.payload.reportId].statuses = action.payload.statuses.statuses;
    },
    checkWidgetRefreshedIndicatorsFulfilled: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: false };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: null };
      state[action.payload.reportId].refreshedWidgetCheck = action.payload.refreshedWidgetCheck;
    },
    checkRefreshedIndicatorsFulfilled: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: false };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: null };
      state[action.payload.reportId].refreshedCheck = action.payload.refreshedCheck;
    },
    refreshIndicatorsFulfilled: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: false };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: null };
      state[action.payload.reportId] = { ...state[action.payload.reportId], isRefreshing: action.payload.isRefreshing };
    },
    handleRejected: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: false };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: action.payload.err };
    },
    deletePacingGoalFulfilled: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: false };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: null };
    },
    createPacingGoalFulfilled: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: false };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: null };
    },
    updatePacingGoalFulfilled: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: false };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: null };
    },
    fetchReportKpisFulfilled: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: false };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: null };
      state[action.payload.reportId] = { ...state[action.payload.reportId], kpis: action.payload.kpis.indicators };
    },
    createWidgetFulfilled: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: false };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: null };
      state[action.payload.reportId].newlyCreatedWidgetId = action.payload.widgetId;
    },
    fetchWidgetsFulfilled: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: false };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: null };
      state[action.payload.reportId].widgets = action.payload.orderedWidgets;
    },
    fetchWidgetMetricsFulfilled: (state, action) => {
      state[action.payload.reportId] = { ...state[action.payload.reportId], isLoading: false };
      state[action.payload.reportId] = { ...state[action.payload.reportId], error: null };
      const widgetMetrics = { ...state[action.payload.reportId].widgetMetrics, [action.payload.widgetId]: action.payload.metric };
      state[action.payload.reportId] = { ...state[action.payload.reportId], widgetMetrics };
    },
  },
});

const {
  checkWidgetRefreshedIndicatorsFulfilled,
  checkRefreshedIndicatorsFulfilled,
  createWidgetFulfilled,
  refreshIndicatorsFulfilled,
  deletePacingGoalFulfilled,
  createPacingGoalFulfilled,
  fetchReportKpisFulfilled,
  fetchWidgetsFulfilled,
  fetchWidgetMetricsFulfilled,
  fetchGAAuthMethodFulfilled,
  fetchGoalNameFilterFulfilled,
  fetchPacingGoalsFulfilled,
  fetchStatusesFulfilled,
  fetchBusinessTypesFulfilled,
  fetchSectionsFulfilled,
  updatePacingGoalFulfilled,
  handleRejected,
  handlePending
} = reportsSlice.actions;

/**
 * Selectors
 */
export const selectReportPage = (state: RootState) => {
  return state.reports;
};
/**
 * Export
 */
export default reportsSlice.reducer;
