/**
 * AddAdditionalStrategyModal
 */

import React, { FC, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from '@sprnova/nebula';
import { Modal } from 'antd';
import { Skeleton } from 'components/Skeleton';
import difference from 'lodash/difference';
import { useMixpanelTrack } from 'utils';
import { Strategy } from 'features/entitiesRedux';
import { useDepartments } from 'features/entitiesRedux/models/department';
import { Task } from 'features/entitiesRedux/models/task';
import { TaskIdRequiredType } from 'features/library/package-strategies/AdditionalStrategy/AdditionalStrategy';
import { StrategySelectionList } from 'features/strategies/components';
import { departmentsProjection } from './projection';
import css from './AddAdditionalStrategyModal.module.scss';

enum STEPS {
  SELECTION = 'selection',
}

type Props = {
  creating?: boolean,
  onFinish?: (tasks: Task[] | any) => void;
  onToggle: (open: boolean) => void;
  open: boolean;
  strategy?: Strategy;
  selectedTasks: TaskIdRequiredType[];
  pricingVersion: string;
  setModalIsLoading: (isLoading: boolean) => void;
};

const AddAdditionalStrategyModal: FC<Props> = ({
  creating,
  onToggle,
  onFinish,
  open,
  strategy,
  selectedTasks,
  pricingVersion,
  setModalIsLoading
}) => {
  const { id } = useParams<{ [x: string]: string }>();
  const [selected, setSelected] = useState<Task[]>(selectedTasks ?? []);
  const [currentStep, setCurrentStep] = useState(STEPS.SELECTION);
  const { departments: allDepartments, loading } = useDepartments({ projection: departmentsProjection });
  const selectedIds = selected.map((task) => task.id);
  const initialSelectedIds = selectedTasks.map((task: Task) => task.id);

  /**
   * Set loading state to know if Modal can be open or not
   */
  useEffect(() => {
    if (loading) {
      setModalIsLoading(true);
    } else {
      setModalIsLoading(false);
    }
  }, [loading, setModalIsLoading]);

  /**
   * Check if changes were made to the selected tasks.
   * No changes made if the selected tasks are the same as the initial selected tasks
   */
  const changesMade = useMemo(() => {
    if (selectedIds.length !== initialSelectedIds.length) {
      return true;
    } else {
      return !initialSelectedIds.every((id: number) => selectedIds.includes(id));
    }
  }, [initialSelectedIds, selectedIds]);

  useEffect(() => {
    if (open) {
      setSelected(selectedTasks ?? []);
    }
  }, [open, selectedTasks]);

  /**
   * Mixpanel event tracking
   */
  const mixpanel = useMixpanelTrack();
  const trackAddTask = () => {
    try {
      const options = {
        tasks: selected?.map((task: Task) => {
          return {
            task: task?.name,
            isConfigurable: task?.is_configurable,
            projectedHours: task?.projected_hours,
          };
        }),
        taskIds: selectedIds,
      };
      if (process.env.NODE_ENV !== 'production') console.log('🛤 Track: Library tasks created', { options });

      const eventTitle = 'Library tasks created';
      mixpanel(eventTitle, options);
    } catch (error) {
      console.error('Mixpanel error', error);
    }
  };

  const handleSelect = (tasks: Task[]) => {
    const update = selected.concat(difference(tasks, selected));
    setSelected(update);
  };

  const handleUnselect = (tasks: Task[]) => {
    const ids = new Set(tasks.map((task) => task.id));
    setSelected(selected.filter((task) => !ids.has(task.id)));
  };

  const handleGoBack = () => {
    setCurrentStep(STEPS.SELECTION);
  };

  const handleCancel = () => {
    onToggle(false);
  };

  const handleReset = () => {
    setCurrentStep(STEPS.SELECTION);
  };

  const handleAddTasks = () => {
    trackAddTask();
    if (typeof onFinish === 'function') {
      onFinish(selected);
    }
  };

  // Reset on close
  useEffect(() => {
    if (!open) {
      handleReset();
    }
  }, [open]);

  const renderContent = () => {
    if (loading) {
      return <Skeleton />;
    }

    // Select tasks
    if (currentStep === STEPS.SELECTION) {
      return (
        <StrategySelectionList
          departments={allDepartments}
          onSelect={handleSelect}
          onUnselect={handleUnselect}
          selectedIds={selectedIds}
          pricingVersion={pricingVersion}
          currentId={Number(id)}
        />
      );
    }
    return null;
  };

  const renderCancelButton = (
    <Button
      sx={{ marginRight: '24px' }}
      onClick={handleCancel}
      variant="secondary"
    >
      Cancel
    </Button>
  );

  const renderSubmitButton = (
    <Button
      onClick={handleAddTasks}
      disabled={!changesMade}
      variant="primary"
    >
      Add additional strategies
    </Button>
  );

  const footer =
    currentStep === STEPS.SELECTION
      ? [
        <div className={css.footerSummary} key="summary">
          {`${selected.length} strategies selected`}
        </div>,
        <div className={css.footerButtons} key="buttons">
          {renderCancelButton}
          {renderSubmitButton}
        </div>,
      ]
      : [
        <Button
          key="backButton"
          icon={<ArrowLeftOutlined />}
          type="text"
          htmlType="button"
          onClick={handleGoBack}
          className={css.backButton}
          disabled={creating}
        >
          Back
        </Button>,
        <div className={css.footerButtons} key="buttons">
          {renderCancelButton}
          {renderSubmitButton}
        </div>,
      ];

  if (loading) {
    return null;
  }

  return (
    <Modal
      className={css.root}
      width={1000}
      title={
        currentStep === STEPS.SELECTION ? 'Select additional strategies' : 'Confirm selection'
      }
      visible={open}
      footer={footer}
      onCancel={!creating ? handleCancel : undefined}
      destroyOnClose
      maskClosable={false}
    >
      {renderContent()}
    </Modal>
  );
};

export default AddAdditionalStrategyModal;
