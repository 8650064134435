import { Strategy } from 'features/entitiesRedux';
import { getGuaranteedTermAndContractLengthRatio } from 'features/strategies/utils/getGuaranteedTermAndContractLengthRatio';

const getGuaranteedTermCommission = (strategy: Partial<Strategy>): number | null => {
  if (strategy?.length && strategy?.guaranteed_term && strategy?.total_commission_expense) {
    return (getGuaranteedTermAndContractLengthRatio(strategy.guaranteed_term, strategy.length) * strategy.total_commission_expense);
  }
  return null;
};

export default getGuaranteedTermCommission;
