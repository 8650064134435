import React from 'react';

export const BlueprintReportinIcon = () => (
  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="64" y="94" width="16" height="55" rx="8" transform="rotate(-180 64 94)" fill="#D9D9D9"/>
    <rect x="96" y="94" width="16" height="76" rx="8" transform="rotate(-180 96 94)" fill="#4A1FFF"/>
    <circle cx="56" cy="47" r="8" fill="#A94882"/>
    <circle cx="24" cy="86" r="8" fill="#FFA600"/>
    <circle cx="88" cy="26" r="8" fill="#958AE2"/>
  </svg>

);
