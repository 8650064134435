import { addMonthsToDate, subtractDaysToDate } from 'utils/date/calculateNewDate';
import { Strategy } from 'features/entitiesRedux';

/**
 * Get the BP end date by adding the contract length to the start date and subtracting one day
 * @param blueprint Strategy
 * @returns string The date in the format 'MM/DD/YYYY'
*/
export const getBlueprintEndDate = (blueprint: Strategy): string => {
  const dateOptions: Intl.DateTimeFormatOptions =
      {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'UTC'
      };
  const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
  const addedMonthsToDate = addMonthsToDate(new Date(blueprint.date), blueprint.length);
  const addedMonthsToDateMinusOneDay = subtractDaysToDate(addedMonthsToDate, 1);
  return dateFormatter.format(addedMonthsToDateMinusOneDay);
};
