import React, { memo, useEffect, useMemo, useState } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { SelectChangeEvent } from '@mui/material';
import { Controller, Box, Select } from '@sprnova/nebula';
import { useGetClientFusionIntegrationsQuery } from 'api/crudGraphQL/clients/getClientsFusionIntegrations';
import { fusionIntegrationsProjection } from 'features/reports/EditReportPage/projection';
import NoIntegrationsAlertBanner from 'features/scoreboards/components/NoIntegrationsAlertBanner';
import { CreateScoreboardFormType } from '../CreateScoreboardForm';
import css from '../CreateScoreboardForm.module.scss';

type CreateScoreboardSelectIntegrationsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<CreateScoreboardFormType, any>;
  setValue: UseFormSetValue<CreateScoreboardFormType>;
  clientId: number;
  setExcludedDatasourceIds: React.Dispatch<React.SetStateAction<number[]>>;
  excludedDatasourceIds: number[];
  setHasIntegrations: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit?: boolean;
}

/**
 * Renders a multi-select dropdown for selecting client integrations.
 * Fetches integration data based on the provided clientId and updates the form state accordingly.
 *
 * @param {CreateScoreboardSelectIntegrationsProps} props - The component props
 * @returns {JSX.Element} The rendered component
 */
const CreateScoreboardSelectIntegrations: React.FC<CreateScoreboardSelectIntegrationsProps> = ({control, setValue, clientId, setExcludedDatasourceIds, setHasIntegrations, isEdit, excludedDatasourceIds}) => {
  const SELECTED_DATA_SOURCE_IDS = 'selected_data_source_ids';
  const [selectedDatasources, setSelectedDatasources] = useState<number[]>([]);

  // Client integrations data
  const { data: ClientFusionIntegrationsData, isLoading, isFetching } = useGetClientFusionIntegrationsQuery({ clientId, projection: fusionIntegrationsProjection }, {
    skip: !clientId, // Skip the query if clientId is falsy
  });

  // Convert to options array
  const options = useMemo(() => (
    ClientFusionIntegrationsData?.map(({ data_source }) => ({ label: data_source.name, value: data_source.id })) || []
  ), [ClientFusionIntegrationsData]);

  // Extract all data source IDs
  const allIds = useMemo(() => (
    ClientFusionIntegrationsData?.map(({ data_source }) => data_source.id) || []
  ), [ClientFusionIntegrationsData]);

  // Initialize selectedDatasources and selected_data_source_ids
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (ClientFusionIntegrationsData?.length) {
      setHasIntegrations(true);

      // Clear select states
      setSelectedDatasources([]);
      setValue(SELECTED_DATA_SOURCE_IDS, []);

      // Use setTimeout to delay the state update
      timeoutId = setTimeout(() => {
        if (isEdit) {
          const selectedDatasources = allIds.filter(id => !excludedDatasourceIds.includes(id));
          setSelectedDatasources(selectedDatasources);
          setValue(SELECTED_DATA_SOURCE_IDS, selectedDatasources);
        } else {
          const newSelectedDatasources = allIds.filter(id => id !== undefined); // Filter out undefined values
          setSelectedDatasources(newSelectedDatasources);
          setValue(SELECTED_DATA_SOURCE_IDS, newSelectedDatasources);
        }
      }, 10); // Minimal timeout needed to clear state but retain UX
    } else {
      setHasIntegrations(false);
      setSelectedDatasources([]);
      setValue(SELECTED_DATA_SOURCE_IDS, []);
    }

    // Cleanup timeout on component unmount or when dependencies change (to avoid memory leak)
    return (): void => {
      clearTimeout(timeoutId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ClientFusionIntegrationsData, allIds, isEdit, setHasIntegrations, setValue]); // Purposely omit excludedDatasourceIds as it conflicts with the handleChange functionality

  // Keep track of selected and unselected datsource ID's
  const handleChange = (event: SelectChangeEvent<unknown>): void => {
    const selectedIds = event.target.value as number[];
    setSelectedDatasources(selectedIds);

    const unselectedIds = allIds.filter(id => !selectedIds.includes(id));

    // Update form state
    setValue(SELECTED_DATA_SOURCE_IDS, selectedIds);
    setExcludedDatasourceIds(unselectedIds);
  };

  // Render select options list items
  const renderOptions = (options: { label: string; value: number }[]): JSX.Element | JSX.Element[] => {
    if (options.length === 0) { // No integrations found
      return (
        <Select.Item disabled value="">
          No Integrations Available
        </Select.Item>
      );
    }

    return options
      .filter(option => option.value) // Filter out any empty or undefined options
      .map((option) => (
        <Select.Item key={option.value} value={option.value}>
          {option.label}
        </Select.Item>
      ));
  };

  return (
    <Box sx={{ marginBottom: '1.5rem' }}>
      <Controller
        name={SELECTED_DATA_SOURCE_IDS}
        control={control}
        rules={{ required: 'At least one integration must be selected' }}
        render={({ field: {...field }, fieldState: { error } }) => (
          <Select
            {...field}
            multiple
            id="create_scoreboard_select_integrations"
            label='Select Integrations'
            value={selectedDatasources || []}
            onChange={(event) => handleChange(event)}
            helperText={error ? error.message : 'Please select an integration'}
            error={error && error.message}
            skeleton={isFetching}
            MenuProps={{
              PaperProps: {
                className: css.customScrollbar,
              },
            }}
          >
            {renderOptions(options)}
          </Select>
        )}
      />

      {/* Display alert banner if client has 0 active integrations */}
      { !ClientFusionIntegrationsData?.length && !isLoading && (
        <Box sx={{ marginTop: '1.5rem' }}>
          <NoIntegrationsAlertBanner clientId={clientId}/>
        </Box>
      )}
    </Box>
  );
};

export default memo(CreateScoreboardSelectIntegrations);
