import React, { useMemo } from 'react';
import { Box, Card, CardHeader, CardTabs } from '@sprnova/nebula';
import { SCROLL_IDS } from 'features/scoreboards/Scoreboard';
import LongTerm from './components/LongTerm/LongTerm';
import Monthly from './components/Monthly/Monthly';
import { Filters, FilterProvider } from '../../utils/Filters';


export type GoalPacingProps = {
  isClient: boolean;
};
export const GoalPacing = ({ isClient }: GoalPacingProps): JSX.Element => {
  const [activeTab, setActiveTab] = React.useState(0);
  const { filter, setFilter } = Filters({});

  const panels: Record<string, JSX.Element> = useMemo(() => ({
    0: <Monthly isClient={isClient} />,
    1: <LongTerm isClient={isClient} />,
  }), [isClient]);

  const renderPanels = useMemo(() => {
    return panels[activeTab];
  }, [activeTab, panels]);

  return (
    <Card
      header={
        <CardHeader
          title='Goal Pacing'
          tabs={
            <CardTabs
              onChange={(_, value): void => setActiveTab(value)}
              value={activeTab}
              items={[
                { label: 'Monthly', id: 'monthly' },
                { label: 'Long Term', id: 'long_term' },
              ]}
            />
          }
        />
      }
      id={SCROLL_IDS.GOAL_PACING}
    >
      <FilterProvider filter={{ filter, setFilter }}>
        <Box sx={{ textAlign: 'center' }}>
          {renderPanels}
        </Box>
      </FilterProvider>
    </Card>
  );
};
