/**
 * Rules -> Filters
 */

import React, { FC, useEffect } from 'react';
import { Form as AntdForm } from 'antd';
import classnames from 'classnames';
import omitBy from 'lodash/omitBy';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { FetchRulesFilter } from 'features/entitiesRedux';
import {
  Form,
  Input,
  FormItem,
  Button,
} from 'components';
import initialValues from './initialValues';
import css from './Filters.module.scss';

interface Props {
  setFilter: (values: FetchRulesFilter) => void;
  filter: FetchRulesFilter;
  isLoading?: boolean;
  hasFilters: boolean;
}

const RulesFilters: FC<Props> = ({
  filter = {},
  setFilter,
  hasFilters,
  isLoading,
}) => {
  const [form] = AntdForm.useForm();
  const [queryParams, setQueryParams] = useQueryParams({
    department_id: NumberParam,
    service_id: NumberParam,
    name: StringParam,
  });

  const handleUpdateFilters = (filterValues: FetchRulesFilter) => {
    const values = omitBy(filterValues, (val) => !val);
    setFilter(values);
    setQueryParams(values); // filterValues
  };

  const handleValuesChange = (
    change: FetchRulesFilter,
    filterValues: FetchRulesFilter
  ) => {
    handleUpdateFilters(filterValues);
  };

  const handleResetQueryParams = () => {
    setQueryParams({
      name: undefined,
      service_id: undefined,
      department_id: undefined
    });
  };

  // Update form if filters updates externally
  useEffect(() => {
    setQueryParams(omitBy(filter, (val) => !val));
    form.setFieldsValue(filter);
  }, [filter, form, setQueryParams]);

  // Set filters based on query params on mount
  // or use filters stored in session (if any)
  useEffect(() => {
    if (Object.keys(omitBy(queryParams, (val) => !val)).length) {
      handleUpdateFilters(queryParams);
    }
  }, []); /* eslint-disable-line */

  return (
    <div className={css.root}>
      <Form
        initialValues={{ ...initialValues, ...filter }}
        form={form}
        className={css.form}
        layout="vertical"
        onValuesChange={handleValuesChange}
      >
        <div className={css.columns}>
          {/*<div className={classnames(css.column, css.column__department)}>*/}
          {/*  <FormItem*/}
          {/*    smallLabel*/}
          {/*    label="Department"*/}
          {/*    name="department_id"*/}
          {/*    className={css.formItem}*/}
          {/*  >*/}
          {/*    <SelectDepartment*/}
          {/*      loading={isLoading}*/}
          {/*      allowClear*/}
          {/*      placeholder="Filter by Department"*/}
          {/*    />*/}
          {/*  </FormItem>*/}
          {/*</div>*/}
          {/*<div className={classnames(css.column, css.column__service)}>*/}
          {/*  <FormItem*/}
          {/*    smallLabel*/}
          {/*    label="Service"*/}
          {/*    name="service_id"*/}
          {/*    className={css.formItem}*/}
          {/*  >*/}
          {/*    <SelectService*/}
          {/*      loading={isLoading}*/}
          {/*      allowClear*/}
          {/*      placeholder="Filter by Service"*/}
          {/*      departmentId={filter?.department_id || undefined}*/}
          {/*    />*/}
          {/*  </FormItem>*/}
          {/*</div>*/}
          <div className={classnames(css.column, css.column__name)}>
            <FormItem
              smallLabel
              label="Name"
              name="name"
              className={css.formItem}
            >
              <Input
                skeleton={isLoading}
                allowClear
                placeholder="Filter by Name"
                onChange={(e) => {
                  // reset query params after clearing the input
                  if (e.target.value === '') {
                    handleResetQueryParams();
                  }
                }}
              />
            </FormItem>
          </div>
          <div
            className={classnames(css.column, css.column__reset, {
              [css.show]: hasFilters,
            })}
          >
            <Button
              className={css.resetButton}
              type="text"
              onClick={() => {
                handleUpdateFilters(initialValues);
                form.setFieldsValue(initialValues);
                handleResetQueryParams();
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RulesFilters;
