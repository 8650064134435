import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Alert, Container, PageLayout } from '@sprnova/nebula';
import { useGetNovaFeaturesPublicQuery } from 'api/crudGraphQL/public/nova_features/getNovaFeaturesPublic';
import { Warning } from 'components/AccessControl/Warning';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';
import { PageHero } from 'layouts/components/PageHero';
import isExternalUser from 'utils/helpers/isExternalUser';
import { useExternalAccount } from 'utils/hooks/useExternalAccount';
import { hasFeatureAccess } from 'features/clients/External/utils/hasFeatureAccess';
import { ExternalUser, useExternalClientRefreshById } from 'features/entitiesRedux';
import { CompanyOverview, TeamOverview } from './components';
import { ExternalUserProfileSidebar } from '../External/Components';
import { externalClientProjection } from '../External/externalClientProjection';
import { novaFeaturesProjection } from '../External/novaFeaturesProjection';

const MyCompanyPage: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState <string>();
  const { clientId: idRaw = '' } = useParams<{ [x: string]: string }>();
  const clientId = parseInt(idRaw);
  const { client, refresh } = useExternalClientRefreshById(clientId, { projection: externalClientProjection });

  /**
   * Feature access control
   */
  const { data: nova_features = [], isLoading: isLoadingFeatures } = useGetNovaFeaturesPublicQuery({ client_id: clientId, projection: novaFeaturesProjection }, { skip: !clientId });
  const hasAccess = useMemo(() => {
    return hasFeatureAccess(nova_features, 'company');
  }, [nova_features]);
  const isClientUser = isExternalUser();

  /**
   * Calls refresh data to update client.
   */
  const [trigger, setTrigger] = useState<boolean>(true);
  useEffect(() => {
    if (trigger) {
      refresh({projection: externalClientProjection});
      setTrigger(false);
    }
  }, [trigger]);

  const externalUser: ExternalUser = useExternalAccount();
  const acceptedClients = useMemo(() => externalUser?.clients?.filter((client) => client?.is_accepted === 'yes'),[externalUser?.clients]);
  const userClients = useMemo(() => acceptedClients?.map((client) => client.id),[acceptedClients]);

  useEffect (() => {
    if (userClients?.length > 0) {
      setLoading(false);
    }
  },[userClients]);

  const handleError = useCallback((error: string): void => {
    setError(error);
  }, [setError]);

  const handleTriggerClientRefresh = useCallback(() => {
    setTrigger(true);
  }, [setTrigger]);

  const sidebar = (
    <ExternalUserProfileSidebar
      client={client}
      externalUser={externalUser} route="company"
    />
  );

  if (loading) {
    return <PageSkeleton />;
  }

  /**
   * Show alert if current client does not have access to this feature
   */
  if (isClientUser && !hasAccess && !isLoadingFeatures) {
    return <Warning container />;
  }

  return (
    <>
      <PageHero title={'My Team'}/>
      <Container hasVerticalPadding>
        <PageLayout sidebar={sidebar}>
          {error && <Alert severity="error" sx={{marginBottom: '20px'}}>{error}</Alert>}
          <CompanyOverview
            client={client}
            onError={handleError}
            onTriggerClientRefresh={handleTriggerClientRefresh}
          />
          <TeamOverview
            isLoading={loading}
            authenticatedUserId={externalUser?.id}
            client={client}
            onError={handleError}
            onTriggerClientRefresh={handleTriggerClientRefresh}
          />
        </PageLayout>
      </Container>
    </>
  );
};
export default MyCompanyPage;
