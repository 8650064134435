import React from 'react';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link } from '@sprnova/nebula';

const CustomClientIndustryLabel = styled(Typography)({
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '20px',
  color: '#2C2C2C',
  marginBottom: '24px'
});

type UpdatedAtTimestampProps = {
  industry?: string;
  client_id?: number;
}

export const ClientIndustryLink = ({industry = 'Add Industry', client_id}: UpdatedAtTimestampProps): JSX.Element => {
  return (
    <>
      <CustomClientIndustryLabel>
        Client's Industry:
        <Link href={`/power-view/accounts/${client_id}`} target="_blank" rel="noopener noreferrer" sx={{marginLeft: '8px'}}>
          {industry}
        </Link>
      </CustomClientIndustryLabel>
    </>
  );
};
