/**
 * Entities -> Forecasts -> Adapter
 */

import { createEntityAdapter } from '@reduxjs/toolkit';
import { compareField } from '../../utils/compareField';
import { Forecast } from './forecast';

/**
 * Redux toolkit entity adapter for storing/caching entities by id
 */
export const forecastsAdapter = createEntityAdapter<Forecast>({
  selectId: (forecast) => forecast.id,
  sortComparer: compareField('name'),
});
