import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Progress } from 'components';
import css from './InferencePipelineProgressBar.module.scss';

const InferencePipelineProgressBar = (): JSX.Element => {
  const [percent, setPercent] = useState<number>(0);
  const fullProgress = percent + 10 > 100;

  /**
   * Every second, increase by 10 percent and then reset to 0 once we reach 100%
   */
  useEffect(() => {
    const interval = setInterval(() => {
      if (fullProgress) {
        setPercent(0);
      } else {
        setPercent((prevPercent) => prevPercent + 10);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [fullProgress]);

  return (
    <div className={css.root}>
      <div>
        <Progress
          percent={percent}
          showInfo={false}
          status="active"
          strokeWidth={20}
          className={classNames(css.progressBar, `percent-${percent}`)}
        />
        <div>
          <div className={css.loadingTitle}>
            Generating budget scenario output.
          </div>
          <div className={css.loadingInfo}>
            <p>This could take up to 5 minutes. We&apos;ll email you when this process is complete!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InferencePipelineProgressBar;
