import { Projection } from 'api/entityGraphQL';
import { basePublicApi } from '..';
import tagTypes from './tagTypes';
import { cleanArguments } from 'api/crudGraphQL/utils';

// Define the types for the query parameters and the result.
export type GetScoreboardIntegrationsPublicQuery = {
  report_id: number;
  client_id?: number;
  data_source_id?: number;
  projection?: Projection;
}

export type GetScoreboardIntegrationsPublicResult = {
  client_id: string;
  client_name: string;
  data_source_id: string;
  data_source_name: string;
  category: string;
  last_refresh_status: string;
  warehouse_source: string;
}[];

// Define a constant for the endpoint key
export const GET_SCOREBOARD_INTEGRATIONS_PUBLIC_ENDPOINT_KEY = 'getScoreboardIntegrationsPublic';

// Inject the new endpoint into the base API
export const { useGetScoreboardIntegrationsPublicQuery, util } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_SCOREBOARD_INTEGRATIONS_PUBLIC_ENDPOINT_KEY]: build.query<GetScoreboardIntegrationsPublicResult, GetScoreboardIntegrationsPublicQuery>({
      transformResponse: (response: { report_integration: GetScoreboardIntegrationsPublicResult }) => response.report_integration,
      providesTags: (result) => {
        return result ? [
          { id: result?.[0]?.client_id, type: tagTypes.SCOREBOARD_INTEGRATION }
        ] : [tagTypes.SCOREBOARD_INTEGRATION];
      },
      query: ({
        report_id,
        client_id,
        data_source_id,
        projection = { id: true },
      }) => {
        return {
          body: {
            query: {
              report_integration: {
                __args: cleanArguments({
                  report_id,
                  client_id,
                  data_source_id
                }),
                ...projection
              }
            }
          }
        };
      }
    }),
  }),
});
