export enum BLUEPRINT_STAGES_ENUM {
  Open = 'open', // open is same as draft
  Draft = 'draft', // draft is same as open
  Appraisal = 'appraisal',
  Blueprint = 'blueprint',
  Legal = 'legal',
  Won = 'won',
  Nurture = 'nurture',
  Lost = 'lost',
  Archive = 'archive',
}

export const BLUEPRINT_CONTRACT_TYPE = {
  retainer: 'retainer',
  project: 'project',
};

export enum BLUEPRINT_REVIEWER_TYPE {
  Finance = 'Finance',
  Department = 'Department',
}

export enum BLUEPRINT_REQUIRED_INFORMATION_SLUG {
  ReviewStrategiesContractLength = 'review-strategies-contract-length',
  OutsourceCosts = 'outsource-costs',
  ReviewOutsourceCostsContractLength = 'review-outsource-costs-contract-length',
  TeamCommissions = 'team-commissions',
  TeamCommissionsTotal = 'team-commissions-total',
  ReviewMonthlyRetainerContractLength = 'review-monthly-retainer-contract-length',
  ReviewTeamCommissionsContractLength = 'review-team-commissions-contract-length',
  FinalPriceRevertedBackToRecommended = 'final-price-reverted-back-to-recommended',
  StrategyPricing = 'strategies',
}

export enum BLUEPRINT_APPROVAL_STATUS {
  Pending = 'pending',
  Approved = 'approved',
  Denied = 'denied',
}

export enum BLUEPRINT_PILLAR {
  Addendum = 'addendum',
  NewBusiness = 'new-business',
  Resign = 'resign',
  Rollover = 'rollover',
  ServiceExpansion = 'service-expansion',
}

/**
 * Enum of the different lead sources slugs available
 */
export enum BLUEPRINT_LEAD_SOURCE {
  ChannelPartner = 'channel-partner',
  People = 'people',
}

export enum BLUEPRINT_MONTHLY_RETAINER_TAB_NAME {
  FinalPrice = 'final-price',
  Recommended = 'recommended',
}

export const PILLAR_SLUGS_TO_DEFAULT_LEAD_SOURCE_TO_NON_APPLICABLE = ['rollover', 'addendum'];

export const DEFINE_SCROLL_MARGIN_TOP = { mb: '1.5rem', scrollMarginTop: '95px' };

export enum SALESFORCE_OPPORTUNITY_STAGE {
  ClosedLost = 'Closed Lost',
  Disqualified = 'Disqualified',
  ClosedWon = 'Closed Won',
}

export const BLUEPRINT_PAUSE_TIME_UNTIL_NEXT_ACTION = 5000;
