import { Projection } from 'api/entityGraphQL/types';

export const blueprintsProjection: Projection = {
  id: true,
  author: {
    id: true,
    name: true,
  },
  client: {
    id: true,
    name: true,
    logo: true,
  },
  created_at: true,
  date: true,
  hourly_rate: true,
  length: true,
  monthly_gross_profit: true,
  name: true,
  pillar: {
    slug: true
  },
  revenue_calculation_version: true,
  reviewed_at: true,
  status: true,
  total_retainer: true,
  type: true,
  departments: {
    id: true,
    name: true,
  },
};
