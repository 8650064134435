import React, { memo, FC } from 'react';
import { InfoTooltip } from '@sprnova/nebula';
import { clientStageDefinition } from '../constants';

interface StageInfoTooltipProps {
  iconSize?: 'small' | 'inherit' | 'large' | 'medium';
  height?: number | string;
  width?: number | string;
}

const StageInfoTooltip: FC<StageInfoTooltipProps> = ({ iconSize = 'small', height = '20px', width = '20px' }): JSX.Element => {
  return (
    <InfoTooltip
      content={clientStageDefinition}
      iconProps={{style: {color: '#575757', height, width }}}
      iconSize={iconSize}
      placement='right'
      sx={{ marginLeft: '8px' }}
      maxWidth={500}
      variant='plain'
    />
  );
};

/**
 * Tooltip with definition of client stage
 * @param iconSize Size of the tooltip icon: small | inherit | large | medium
 * @param height Height of the tooltip icon
 * @param width Width of the tooltip icon
 */
export default memo(StageInfoTooltip);
