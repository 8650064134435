import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from '@sprnova/nebula';
import {
  useGetNovaFeaturesPublicQuery
} from 'api/crudGraphQL/public/nova_features/getNovaFeaturesPublic';
import { AppDispatch } from 'app/store';
import { Warning } from 'components/AccessControl/Warning';
import { Alert } from 'components/Alert';
import { Card } from 'components/Card';
import { message } from 'components/message';
import { Spin } from 'components/Spin';
import { PageHero } from 'layouts/components/PageHero';
import { parseResultActionError } from 'utils/helpers/humanReadableGraphqlError';
import isExternalUser from 'utils/helpers/isExternalUser';
import useMixpanelTrack from 'utils/hooks/useMixpanelTrack';
import { getDataSource, getDataSourceName } from 'features/clients/ClientIntegrationsPage/utils';
import { novaFeaturesProjection } from 'features/clients/External/novaFeaturesProjection';
import { hasFeatureAccess } from 'features/clients/External/utils/hasFeatureAccess';
import { Client, CreateClientIntegrationParameters } from 'features/entitiesRedux/models/client';
import { createClientAccount } from 'features/entitiesRedux/models/client/actions';
import { useClientRefreshById } from 'features/entitiesRedux/models/client/hooks';
import { defaultClientIntegrationProjection } from 'features/entitiesRedux/models/client/projections';
import { useDataSources } from 'features/entitiesRedux/models/data_source/hooks';
import { selectAllDatasources } from 'features/entitiesRedux/models/data_source/selectors';
import { useExternalClientRefreshById } from 'features/entitiesRedux/models/external_client/hooks';
import { defaultExternalClientIntegrationProjection } from 'features/entitiesRedux/models/external_client/projections';
import { selectIsLoading } from 'features/entitiesRedux/selectors';
import { useAccount } from 'features/global/hooks/useAccount';
import {
  AdNetworksIntegrationForm,
  BigCommerceIntegrationForm,
  DataQIntegrationForm,
  GoogleAnalyticsFunnelIntegrationForm,
  MagentoIntegrationForm,
  SemRushIntegrationForm,
  ShopifyIntegrationForm,
  UnrecognizedIntegration
} from '../components';
import { handleCreateIntegrationDataQ } from './utils/handleCreateIntegrationDataQ';
import css from '../utils/CreateEditIntegrationPage.module.scss';


const CreateIntegrationPage = (): JSX.Element => {
  /**
   * Page is used for both internal and client-facing.
   */
  const isClient = isExternalUser();
  const userType = isClient ? 'external' : 'internal';
  const redirectPath = isClient ? 'c' : 'clients';

  /**
   * Redux State
   */
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const track = useMixpanelTrack();

  /**
   * UseState variables.
   */
  const [error, setError] = useState<string | JSX.Element | undefined>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  /**
   * Obtain client and user entity.
   */
  const { clientId: id, slug } = useParams<{ [x: string]: string }>();
  const clientId = parseInt(id) || undefined;

  const { client, refresh: refreshClient } = isClient && clientId ?
    useExternalClientRefreshById(clientId, { projection: defaultExternalClientIntegrationProjection }) : clientId ?
      useClientRefreshById(clientId, { projection: defaultClientIntegrationProjection }) : { client: undefined, refresh: undefined};

  const { account } = useAccount();

  /**
   * Find current DataSource.
   */
  const datasources = useDataSources();
  const dataSource = getDataSource(datasources, slug);
  const dataSourceId = dataSource?.id;
  const dataSourceName = dataSource?.name;
  const dataSourceDescription = dataSource?.description;

  /**
   * Form handler
   */
  const handleSubmit = async ({
    url,
    value2,
    auth_method,
    data_source_account_id,
    data_q_data,
    default_report_id
  }: CreateClientIntegrationParameters) => {
    setError(undefined);

    if (dataSourceId && clientId) {
      setSubmitting(true);
      try {
        const resultAction = await dispatch(
          createClientAccount({
            client_id: clientId,
            account_id: dataSourceId,
            url,
            data_source_account_id,
            value2,
            auth_method,
            data_q_data,
            is_created_by_client: false,
            created_by: (account?.id && account?.id !== 0) ? account?.id : undefined,
          })
        );

        if (createClientAccount.fulfilled.match(resultAction)) {
          message.success(`New ${dataSourceName} integration created!`);

          /**
           * Mixpanel tracking for creating integration.
           */
          const integrationId: number | undefined = resultAction?.payload?.id;
          track(`Integration Added${userType == 'external' ? 'External Client' : '' }`, {
            title: dataSource?.name,
            client,
            ...{
              integrationId
            }
          });

          /**
           * DataQ Integration redirect logic.
           */
          if (slug === 'shopify' || slug === 'bigcommerce') {
            handleCreateIntegrationDataQ(slug, resultAction);
          }

          /**
           * Redirect to scoreboards or integrations overview depending on where the user came from.
           */
          if (default_report_id) {
            history.push(`/scoreboards/${default_report_id}#audienceInsights`);
          } else {
            history.push(`/${redirectPath}/${clientId}/integrations${isClient ? '#manage-integrations' : ''}`);
          }
        }

        if (createClientAccount.rejected.match(resultAction)) {
          setError(parseResultActionError(resultAction?.error?.message));
          console.error('Error creating new client integration: ', resultAction);
        }
      } catch (error) {
        setError('While trying to create your integration an error occurred. Please try again or contact us through intercom!');
        console.error(`Error creating ${dataSourceName} client integration`, error);
      }
    }
    setSubmitting(false);
  };

  /**
   * Return proper create integration form for current slug.
   */
  const renderForm = (): JSX.Element => {
    if (slug) {
      switch (slug) {
        case 'ad-networks':
          return (
            <AdNetworksIntegrationForm
              client={client as Client}
              mode="create"
              onSubmit={handleSubmit}
              refreshClient={refreshClient}
              setError={setError}
            />
          );
        case 'bigcommerce':
          return (
            <BigCommerceIntegrationForm
              client={client}
              mode="create"
              onSubmit={handleSubmit}
              disclaimer={dataSource?.disclaimer || ''}
              setError={setError}
              userType={userType}
            />
          );
        case 'dataq':
          return (
            <DataQIntegrationForm
              client={client as Client}
              mode="create"
              onSubmit={handleSubmit}
              setError={setError}
            />
          );
        case 'google-analytics-funnel':
          return (
            <GoogleAnalyticsFunnelIntegrationForm
              client={client as Client}
              mode="create"
              onSubmit={handleSubmit}
              refreshClient={refreshClient}
              setError={setError}
            />
          );
        case 'magento':
          return (
            <MagentoIntegrationForm
              client={client}
              mode="create"
              onSubmit={handleSubmit}
              disclaimer={dataSource?.disclaimer || ''}
              setError={setError}
              userType={userType}
            />
          );
        case 'shopify':
          return (
            <ShopifyIntegrationForm
              client={client}
              mode="create"
              onSubmit={handleSubmit}
              setError={setError}
              userType={userType}
            />
          );
        case 'semrush':
          return (
            <SemRushIntegrationForm
              client={client as Client}
              mode="create"
              onSubmit={handleSubmit}
              disclaimer={dataSource?.disclaimer || ''}
              setError={setError}
            />
          );
        default:
          return <UnrecognizedIntegration />;
      }
    } else {
      return <></>;
    }
  };

  const isLoading = useSelector(selectIsLoading);

  /**
   * Feature access control (external users)
   */
  const { data: nova_features = [], isLoading: isLoadingFeatures } = useGetNovaFeaturesPublicQuery({ client_id: parseInt(id), projection: novaFeaturesProjection }, { skip: !parseInt(id) || typeof parseInt(id) !== 'number'});
  const hasAccess = useMemo(() => {
    return hasFeatureAccess(nova_features, 'integrations');
  }, [nova_features]);

  // show alert to external users if currently selected client does not have access to this feature
  if (isClient && !hasAccess && !isLoadingFeatures) {
    return <Warning container />;
  }

  return (
    <>
      <PageHero
        hideHome
        title={dataSourceName ? `Create ${dataSourceName} Integration` : 'Loading...'}
        description={dataSourceDescription ? dataSourceDescription : ''}
      />
      <Container hasVerticalPadding>
        <Card className={css.root}>
          { (error && (!isLoading && !submitting)) ? <Alert showIcon message="Something went wrong..." description={error} type="error" banner className={css.error}/> : <></> }
          { isLoading || submitting || isLoadingFeatures ?
            <div className={css.spinContainer}>
              <Spin fontSize={45}/>
              {submitting && dataSource?.slug === 'shopify' ?
                <div className={css.shopifyLoading}>
                  <h2>Loading data from Shopify</h2>
                  <p>
                  Load time is based on your store&apos;s order, customer, product and activity data.<br />
                  The more you have, the longer load times can take.
                  </p>
                </div>
                : <></>
              }
            </div>
            : renderForm()
          }
        </Card>
      </Container>
    </>
  );
};

export const FormattedTitle = (): JSX.Element => {
  const { slug } = useParams<{ [x: string]: string }>();
  const datasources = useSelector(selectAllDatasources);
  const dataSourceName = getDataSourceName(datasources, slug);

  return <span>{dataSourceName ? dataSourceName : 'Loading...'}</span>;
};

export default CreateIntegrationPage;
