export type NovaAIResponseType = {
  challenge?: NovaAiChallengeType;
  created_at?: string;
  current_step?: number;
  database_name?: string;
  ended_at?: string;
  displayed_at?: string;
  error?: string;
  execution_guid?: string;
  feedback_additional?: string;
  feedback_reason?: NovaAIFeedbackReasonsType;
  feedback?: number;
  json_response?: string;
  prompt_guid?: string;
  prompt_text?: string;
  query?: string;
  response?: string;
  session_guid?: string;
  session_text?: string;
  sparql?: string;
  status?: NovaAIResponseStatus;
  total_steps?: number;
  version?: number;
}

export type NovaAiChallengeType = {
  id: number;
  action: string;
  message: string;
  question_count: number;
  question_limit: number;
  question_trigger: number;
  status: string;
  step: number;
}

export enum NovaAIResponseStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Failed = 'failed',
  InProgress = 'in_progress',
}

export type NovaAIFeedbackReasonsType = {
  id?: number;
  name?: string;
  slug?: string;
}

export type NovaAISessionType = {
  guid: string;
  latest_prompt: {guid?: string, text?: string, session_text?: string, created_at?: string};
  created_at: string
}

export type NovaAIChatType = {
  prompt: NovaAIQuestionType;
  responses: NovaAIResponseType[];
}

export type NovaAIQuestionType = {
  guid: string;
  text: string;
  created_at: string;
}

export type NovaAITierType = {
  created_at?: string;
  id: number;
  name: string;
  slug: string;
  updated_at: string;
  start_date: string;
}
