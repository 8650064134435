import React, { FC, useCallback, memo } from 'react';
import {
  ActionMenu,
  Dropdown,
  DropdownProps,
  EditIcon,
  EllipsesIcon,
  IconButton,
  TrashIcon
} from '@sprnova/nebula';

type ActionCellProps = {
  disabled?: boolean;
  onClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
};

const ActionCell: FC<ActionCellProps> = ({
  disabled,
  onClick,
  onEdit,
  onDelete,
}) => {

  const renderAnchor: DropdownProps['anchor'] = useCallback((props) => (
    <IconButton {...props} size="md" disabled={disabled}>
      <EllipsesIcon />
    </IconButton>
  ), [disabled]);

  const renderActionMenu: DropdownProps['children'] = useCallback((props) => (
    <ActionMenu onItemClick={props.onClose}>
      <ActionMenu.Item
        startIcon={<EditIcon />}
        onClick={onEdit}
      >
        Edit Teammate
      </ActionMenu.Item>
      <ActionMenu.Item
        startIcon={<TrashIcon />}
        onClick={onDelete}
      >
        Delete Teammate
      </ActionMenu.Item>
    </ActionMenu>
  ), [onEdit, onDelete]);

  return (
    <Dropdown
      onClick={onClick}
      placement="bottom-start"
      anchor={renderAnchor}
    >
      {renderActionMenu}
    </Dropdown>
  );
};

export default memo(ActionCell);
