/**
 * useForecastChannels
 * Fetches all records from the forecast_channels table
 */

import { useState, useEffect } from 'react';
import { novaGraphQLClient, Projection } from 'api/entityGraphQL';
import { ForecastChannel } from '../../features/entitiesRedux';

type Type = {
  loading: boolean;
  error: string;
  forecastChannels: ForecastChannel[];
};

type UseForecastChannelsArgs = { projection?: Projection };
export const useForecastChannels = ({
  projection: defaultProjection,
}: UseForecastChannelsArgs = {}): Type => {
  const [forecastChannels, setForecastChannels] = useState<ForecastChannel[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [projection /* , setProjection */] = useState(defaultProjection);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      setLoading(true);
      try {
        const { forecast_channels } = await novaGraphQLClient.fetchForecastChannels({ projection });
        if (!isCancelled) {
          setError('');
          setForecastChannels(forecast_channels);
          setLoading(false);
        }
      } catch (error) {
        if (!isCancelled) {
          setLoading(false);
          setError(error?.response?.errors[0]?.message || 'Unknown error');
        }
      }
    })();

    return () => {
      isCancelled = true;
    };

  }, [projection]);

  return { forecastChannels, loading, error };
};

export default useForecastChannels;
