export enum LABELS_TYPES {
  CLIENT = 'client',
  CAPITALIZE_CLIENT = 'Client',
  CLIENTS = 'clients',
  CAPITALIZE_CLIENTS = 'Clients',
  BUSINESS_UNIT = 'business unit', // used to be 'business type'
  BUSINESS_UNIT_CAPITALIZED = 'Business unit', // used to be 'Business type'
  BUSINESS_UNIT_FIRST_LETTERS_CAPITALIZED = 'Business Unit', // used to be 'Business Type'
  MONTH_TO_MONTH = 'Month to Month',
}
