/**
 * Components -> ActionsDropdown
 */

import React, { FC, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { AccessControl, AccessControlProps } from 'components/AccessControl';
import { Button } from 'components/Button';
import { Dropdown, DropdownProps } from 'components/Dropdown';
import { Menu } from 'components/Menu';
import { Popconfirm, PopconfirmProps } from 'components/Popconfirm';
import { Popover } from 'components/Popover';
import { useTheme } from '../../utils';
import css from './ActionsDropdown.module.scss';

export interface Props extends Omit<DropdownProps, 'overlay'> {
  children?: React.ReactNode;
  className?: string;
  menuItemClassName?: string;
  actions: {
    wrapper?: any;
    accessControl?: Pick<AccessControlProps, 'resource' | 'action' | 'authorId'>,
    disabled?: boolean;
    hoverLabel?: React.ReactNode;
    href?: string;
    icon?: React.ReactNode;
    label: React.ReactNode;
    onClick?: (event: MouseEvent) => void;
    popconfirmProps?: PopconfirmProps,
    rel?: string;
    target?: string;
    to?: string;
  }[]
}

const ActionsDropdown: FC<Props> = ({ children, className, menuItemClassName, actions, ...rest }) => {
  const { isTalent } = useTheme();
  const menu = (
    <Menu className={classnames(css.menu, { [css.talent]: isTalent }, className)}>
      {actions.map((option, index) => {
        const hasPopconfirm = !!option.popconfirmProps;
        const WrapperElement = option.wrapper;
        const InnerElement = option.to ? Link : 'a';
        const innerProps: any = {};

        if (option.to) {
          innerProps.to = option.to;
        }

        const innerElement =  <InnerElement
          {...innerProps}
          target={option.target}
          rel={option.rel}
          href={option.href}
          onClick={!hasPopconfirm ? option.onClick : undefined}
          disabled={option.disabled}
          className={css.item__inner}
        >
          {option.icon && (
            <span className={css.icon}>
              {option.icon}
            </span>
          )}
          {option.label}
        </InnerElement>;

        let menuItem = (
          <Menu.Item className={classnames(css.item, { [css.disabled]: option.disabled }, menuItemClassName)} key={index}>
            {hasPopconfirm ? (
              <Popconfirm
                hideArrow
                destroyTooltipOnHide
                title="Are you sure?"
                {...option.popconfirmProps}
              >
                {innerElement}
              </Popconfirm>
            ) : innerElement}
          </Menu.Item>
        );

        if (WrapperElement) {
          return (
            menuItem = (
              <WrapperElement key={index}>
                {menuItem}
              </WrapperElement>
            )
          );
        }

        if (option.hoverLabel) {
          return (
            menuItem = (
              <Popover className={css.popover} placement="left" content={option.hoverLabel} key={index}>
                {menuItem}
              </Popover>
            )
          );
        }

        if (option.accessControl) {
          return (
            <AccessControl
              key={index}
              action={option?.accessControl?.action}
              resource={option?.accessControl?.resource}
              showWarning={false}
            >
              {menuItem}
            </AccessControl>
          );
        }

        return menuItem;
      })}
    </Menu>
  );

  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      {...rest}
      overlay={menu}
    >
      {children || (
        <Button
          className={css.button}
          aria-label="actions"
          type="ghost"
          shape="round"
          icon={<MoreOutlined />}
          onClick={(e): void => {
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      )}
    </Dropdown>
  );
};

export default ActionsDropdown;
