import { Resource } from '../Resource';
import { Role } from '../Role';
import { RoleConfig } from '../types';

const attrs = ['*'];
export const client: RoleConfig = {
  [Role.client]: {
    [Resource.beta]: {},
    [Resource.strategy]: {},
    [Resource.partner]: {},
    [Resource.department]: {},
    [Resource.service]: {},
    [Resource.task]: {},
    [Resource.rule]: {},
    [Resource.client]: {
      'read:own': attrs,
      'update:own': attrs,
    },
    [Resource.contact]: {},
    [Resource.cohorts]: {},
    [Resource.creativeAffinity]: {},
    [Resource.customerInsights]: {},
    [Resource.audit]: {},
    [Resource.discoveryCall]: {},
    [Resource.analystSurvey]: {},
    [Resource.forecast]: {},
    [Resource.user]: {
      'read:own': attrs,
      'update:own': attrs,
    },
    [Resource.report]: {
      'read:any': attrs,
    },
    [Resource.integration]: {
      'read:own': attrs,
      'update:own': attrs,
    },
    [Resource.talent]: {},
    [Resource.talentSurvey]: {},
    [Resource.recommendedServices]: {},
  },
};
