import React, { memo } from 'react';
import { Box, Button, DataGrid, Dialog, GridColDef, NewWindowIcon, Typography } from '@sprnova/nebula';
import css from './HelpCenterModal.module.scss';

type HelpCenterModalProps = {
    modalOpen: boolean;
    toggleModal: () => void;
    trackHelpCenterMixpanel: (buttonClicked: string) => void;
}

const columns: GridColDef[] = [
  {
    field: 'category',
    headerName: 'Question Category',
    flex: 0.5
  },
  {
    field: 'data',
    headerName: 'Data Used',
    flex: 1,
  }
];

const rows = [
  {
    id: 1,
    category: 'Customer Acquisition & Behavior',
    data: 'Customer Data: customer acquisition dates, customer ID, marketing preferences, demographic details.',
  },
  {
    id: 2,
    category: 'Order Performance',
    data: 'Order Data: order date, order ID, order net sales, order total sales, order discounts, and gross margins.',
  },
  {
    id: 3,
    category: 'Product Sales & Performance',
    data: 'Product data: product type, product ID, pricing, order line data, (e.g., net quantity, gross margin), and return rates.',
  },
  {
    id: 4,
    category: 'Sales and Revenue',
    data: 'Order Data: order net sales, total sales, gross sales, and refunds.',
  },
  {
    id: 5,
    category: 'Customer Retention & Lifetime Value',
    data: 'Customer Data: customer start date, customer ID, linked with orders (for repeat purchases and CLV)',
  },
  {
    id: 6,
    category: 'Inventory Management',
    data: 'Inventory Data: product ID, inventory quantity , item cost, SKU, stock levels.',
  },
  {
    id: 7,
    category: 'Time-based Trends',
    data: 'Order & Customer Data: time-stamped order data, customer acquisition/engagement data for monthly, quarterly or yearly analysis.',
  },
  {
    id: 8,
    category: 'Discount Impact & Pricing Strategies',
    data: 'Order Line Data: discounts applied, AOV, return rates, gross margins per product.',
  },
  {
    id: 9,
    category: 'Marketing Channel Performance',
    data: 'Visits/Marketing Data: visit channel, visit source, ad spend, conversions (integration coming soon with paid media).',
  },
];

const links = [
  {
    href: 'https://help.novapower.io/en/articles/9736314-how-to-talk-to-your-data-nova-insights-ai',
    text: 'How to Talk to Your Data'
  },
  {
    href: 'https://help.novapower.io/en/articles/9400632-nova-gtp-prompt-examples',
    text: 'novaGPT Question Guide'
  },
  {
    href: 'https://help.novapower.io/en/articles/9376739-nova-insights-ai-faq',
    text: 'FAQs'
  },
];

const HelpCenterModal = ({ modalOpen, toggleModal, trackHelpCenterMixpanel }: HelpCenterModalProps): JSX.Element => {
    
  return (
    <Box>
      <Dialog
        open={modalOpen}
        onClose={toggleModal}
        title="novaGPT Help Center"
        primaryButtonProps={{ onClick: toggleModal, children: 'Close' }}
        size='xl'
        className={css.dialog}
      >
        <Typography mb={1}>
            Here is a table listing the categories of questions that nova Insights AI can answer today, along with the data it relies on:
        </Typography>
        <DataGrid columns={columns} rows={rows} hideFooter getRowHeight={() => 'auto'}/>
        <Typography mt={1.5} mb={2}>
            Still need assistance? Here are some resources that will help you get started:
        </Typography>
        <Box display={'flex'} justifyContent={'space-between'}>
          {links.map((link, key) => (
            <Button 
              key={key}
              endIcon={<NewWindowIcon />} 
              href={link.href}
              target='_blank' 
              rel='noreferrer'
              onClick={() => trackHelpCenterMixpanel(link.text)}
            >
              {link.text}
            </Button>
          ))}
        </Box>
      </Dialog>
    </Box>
  );
};

export default memo(HelpCenterModal);