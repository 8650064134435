/**
 * useWidgets
 * Fetches all widgets
 */

import { useState, useEffect } from 'react';
import { novaGraphQLClient } from 'api/entityGraphQL';

type Widget = {
  id: number;
  name: string;
}
type Type = {
  loading: boolean;
  error: string;
  widgets: Widget[];
};

export const useWidgets: () => Type = () => {
  const [widgets, setWidgets] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const widgets = await novaGraphQLClient.fetchWidgets();
        setError('');
        setWidgets(widgets);
      } catch (error) {
        setLoading(false);
        setError(error?.response?.errors[0]?.message || 'Unknown error');
      }
    })();
  }, []);

  return { widgets, loading, error };
};

export default useWidgets;
