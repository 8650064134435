import React, { forwardRef } from 'react';
import { Radio as AntdRadio } from 'antd';
import { RadioGroupProps } from 'antd/lib/radio';
import classNames from 'classnames';
import { SkeletonInput } from '../SkeletonInput';
import css from './RadioGroup.module.scss';

interface Props extends RadioGroupProps {
  className?: string;
  skeleton?: boolean;
  vertical?: boolean;
}

const RadioGroup = forwardRef(({ className, skeleton = false, children, vertical, ...props }: Props, ref: any) => {
  // Show skeleton when loading is true
  if (skeleton) return <SkeletonInput loading={false} active={true} />;

  return (
    <AntdRadio.Group {...props} className={classNames(className, css.root)} ref={ref}>
      {vertical ? <div className={css.vertical}>{children}</div> : children}
    </AntdRadio.Group>
  );
});

RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;
