import React, { FC, memo, useMemo } from 'react';
import { Alert, Box, GridColDef } from '@sprnova/nebula';
import { Action } from 'api/accessControl';
import { useGetScoreboardsQuery } from 'api/crudGraphQL/scoreboards/getScoreboards';
import { AccessControl } from 'components/AccessControl';
import ClientStageTag from 'features/clients/components/ClientStageTag/ClientStageTag';
import StageInfoTooltip from 'features/clients/components/Tooltip/StageInfoTooltip/StageInfoTooltip';
import { Resource } from 'features/entitiesRedux';
import { useFilterContext } from 'features/scoreboards/utils/Filters';
import AccountCell from './AccountCell';
import DropdownActionMenu from './DropdownActionMenu';
import FiltersForm from './FiltersForm';
import { allAccountsProjection } from './projections';
import ScoreboardsList from './ScoreboardsList';

const AllAccounts: FC = () => {
  const { filter } = useFilterContext();
  const { data: scoreboardsData, isLoading, isFetching, error } = useGetScoreboardsQuery({
    projection: allAccountsProjection,
    ...filter,
  });

  const columns: GridColDef[] = [
    {
      field: 'client_name',
      headerName: 'Account',
      renderCell: (params) => <AccountCell data={params.formattedValue} />,
      flex: 3,
    },
    {
      field: 'account_stage',
      headerName: 'Stage',
      renderHeader: () => <StageInfoTooltip />,
      renderCell: (params) => <ClientStageTag stage={params.row.account_stage} tooltip />,
      flex: 1,
    },
    {
      field: 'business_type',
      headerName: 'Business Unit',
      flex: 1,
    },
    {
      field: 'extra',
      headerName: '',
      renderCell: (params) => (
        <AccessControl action={[Action.read]} resource={Resource.report}>
          <DropdownActionMenu
            scoreboardId={params.row.id}
            scoreboardName={params.row.client_name.name}
            clientId={params.row.client_name.client.id}
            clientName={params.row.client_name.client.name}
          />
        </AccessControl>
      ),
      width: 65,
      sortable: false,
    },
  ];

  const rows = useMemo(() => {
    if (scoreboardsData) {
      return scoreboardsData.data.map((scoreboard) => {
        return {
          id: scoreboard.id,
          client_name: scoreboard,
          account_stage: scoreboard.client?.account_stage,
          business_type: scoreboard.client?.business_type?.name,
          extra: scoreboard.id,
        };
      });
    }
    return [];
  }, [scoreboardsData]);

  return (
    <Box>
      {
        error ? (
          <Alert severity="error">Error loading scoreboards{error.message && `: ${error.message}`}</Alert>
        ) : (
          <>
            <FiltersForm />
            <ScoreboardsList
              rows={rows}
              columns={columns}
              isLoading={isLoading || isFetching}
              total={scoreboardsData?.total ?? 0}
            />
          </>
        )
      }
    </Box>
  );
};

export default memo(AllAccounts);
