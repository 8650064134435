/**
 * SelectJobTitle
 */

import React, { forwardRef } from 'react';
import { useGetJobTitlesQuery } from 'api/crudGraphQL/job_titles/getJobTitles';
import { JobTitle } from 'features/entitiesRedux/models/types';
import Select, { Props as SelectProps } from '../Select/Select';

const SelectJobTitle = forwardRef(({ skeleton, ...props }: SelectProps, ref: React.Ref<HTMLInputElement>) => {
  const {
    data,
    isLoading,
    isFetching,
  } = useGetJobTitlesQuery({
    page: 1,
    limit: 9999,
    sort: ['name'],
    projection: { id: true, name: true },
  });

  const { data: jobTitles } = data ?? { data: [] };
  const loading = isLoading || isFetching;

  return (
    <Select
      showSearch
      skeleton={loading || skeleton}
      optionFilterProp="children"
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      {...props}
    >
      {jobTitles?.map((jobTitle: JobTitle) => (
        <Select.Option key={jobTitle.id} value={jobTitle.id}>
          {jobTitle.name}
        </Select.Option>
      ))}
    </Select>
  );
});

SelectJobTitle.displayName = 'SelectJobTitle';

export default SelectJobTitle;
