import React, { useMemo } from 'react';
import { IconProps, SvgIconOwnProps } from '@mui/material';
import { Box, BoxProps, Typography, styled, theme , ImageIcon } from '@sprnova/nebula';
import classNames from 'classnames';

type EmptyImageProps = React.ComponentPropsWithoutRef<'div'> & {
  size: 'xl' | 'large' | 'medium' | 'small' | 'xs';
  boxProps?: BoxProps;
  imgProps?: Omit<React.SVGProps<SVGSVGElement>, 'children'> & Partial<IconProps>;
  className?: string;
}

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${theme.variables.colors.secondary.ghost};
  color: ${theme.variables.colors.primary.charcoal[400]};
`;

export const EmptyImage: React.FC<EmptyImageProps> = ({ size, imgProps, boxProps, ...props }) => {
  const boxStyles = useMemo(() => {
    const commonStyles = {
      width: '100%',
      height: '100%',
      borderRadius: '4px',
    };
    let styles = {};
    if (size === 'xl') {
      styles = {
        minHeight: '400px',
        maxHeight: '400px',
        maxWidth: '400px',
      };
    }
    if (size === 'large') {
      styles = {
        minHeight: '200px',
        maxHeight: '200px',
        maxWidth: '200px',
      };
    }
    if (size === 'medium') {
      styles = {
        minHeight: '100px',
        minWidth: '100px',
        maxHeight: '100px',
        maxWidth: '100px',
      };
    }
    if (size === 'small') {
      styles = {
        minHeight: '72px',
        minWidth: '72px',
        maxHeight: '72px',
        maxWidth: '72px',
      };
    }
    if (size === 'xs') {
      styles = {
        minHeight: '48px',
        minWidth: '47px',
        maxHeight: '48px',
        maxWidth: '48px',
      };
    }
    return { ...commonStyles, ...styles };
  }, [size]);
  let fontSize = '';
  let iconSize = '';

  switch (size) {
    case 'xl':
      fontSize = '20px';
      iconSize = '4em';
      break;
    case 'large':
      fontSize = '16px';
      iconSize = '3em';
      break;
    case 'small':
      fontSize = '12px';
      iconSize = '1.6em';
      break;
    case 'xs':
      fontSize = '10px';
      iconSize = '1.2em';
      break;
    case 'medium':
    default:
      fontSize = '14px';
      iconSize = '2em';
      break;
  }

  return (
    <StyledBox {...boxStyles} {...props} {...boxProps} className={classNames(props.className, 'no-image-available')}>
      <ImageIcon sx={{ fontSize: iconSize }} {...(imgProps as SvgIconOwnProps)} />
      {
        size !== 'xs' && size !== 'small' &&
          <Typography
            variant="h3"
            sx={{
              color: 'gray-400',
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize,
              marginTop: '10px'
            }}
          >
            No image available
          </Typography>
      }
    </StyledBox>
  );
};
