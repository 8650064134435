
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, TextField, Card, CardHeader, CardContent, Select } from '@sprnova/nebula';
import { StrategyFrequency } from 'api/crudGraphQL/strategies/types';
import {StringParam, useQueryParam, withDefault} from 'use-query-params';
import { Service } from 'features/entitiesRedux';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import versionConfig from '../../../../api/utils/versionConfig';
import { FormDataType, ActionType } from '../packageStrategyTypes';
import allServicesQuery from './allServicesQuery';
import allStrategyFrequenciesQuery from './allStrategyFrequenciesQuery';
import css from './StrategyOverview.module.scss';

type StrategyOverviewProps = {
  isSubmitted: boolean;
  dispatch: React.Dispatch<any>;
  formData: Partial<FormDataType>;
}

type ServiceTypeFormatted = Partial<Service> & {
  displayName: string;
}

const StrategyOverview = ({
  isSubmitted,
  dispatch,
  formData
}: StrategyOverviewProps): JSX.Element => {
  const [allStrategyFrequency, setAllStrategyFrequency] = useState<StrategyFrequency[]>([]);
  const [allServices, setAllServices] = useState<ServiceTypeFormatted[]>([]);
  const [pricingVersionQueryParam,] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );

  const fetchAllStrategyFrequencies = useCallback(() => {
    fetch(`${import.meta.env.REACT_APP_PLATFORM_API_HOST}`, {
      method: 'POST',
      headers: versionConfig[import.meta.env.REACT_APP_PLATFORM_API_VERSION || 'v1'],
      body: JSON.stringify({
        query: allStrategyFrequenciesQuery,
      }),
    }).then((response) => {
      response.json().then((data) => {
        if (data && data.data && data.data.strategy_frequencies) {
          setAllStrategyFrequency(data.data.strategy_frequencies);
        }
      }).catch((err) => { console.log(err); });
    }).catch((err) => { console.log(err); });
  }, []);

  const fetchAllServices = useCallback(() => {
    fetch(`${import.meta.env.REACT_APP_PLATFORM_API_HOST}`, {
      method: 'POST',
      headers: versionConfig[import.meta.env.REACT_APP_PLATFORM_API_VERSION || 'v1'],
      body: JSON.stringify({
        query: allServicesQuery,
        variables: {
          pricing_version: pricingVersionQueryParam,
        },
      }),
    }).then((response) => {
      response.json().then((data) => {
        if (data && data.data && data.data.services) {
          setAllServices(data.data.services.map((service: ServiceTypeFormatted) => {
            service.displayName = service.name + '   (' + service.department?.name + ')';
            return service;
          }));
        }
      }).catch((err) => { console.log(err); });
    }).catch((err) => { console.log(err); });
  }, [pricingVersionQueryParam]);

  /* ------------------------- UseEffect --------------------------- */
  // Fetch all services and strategy frequencies
  useEffect(() => {
    fetchAllServices();
    fetchAllStrategyFrequencies();
  }, [fetchAllServices, fetchAllStrategyFrequencies]);

  /**
   * Options for the service select
   */
  const serviceOptions = useMemo(() => {
    return allServices.map((service) => (
      <Select.Item key={`service-${service.id}`} value={service.id}>{service.displayName}</Select.Item>
    ));
  }, [allServices]);

  /**
   * Options for the strategy frequency select
   */
  const strategyFrequencyOptions = useMemo(() => {
    return allStrategyFrequency.map((strategyFrequency) => (
      <Select.Item key={`strategy-frequency-${strategyFrequency.id}`} value={strategyFrequency.id}>{strategyFrequency.name}</Select.Item>
    ));
  }, [allStrategyFrequency]);

  const strategyOverviewCardContent = useMemo(() => {
    return (
      <>
        <Grid container
          justifyContent={'space-around'}
          spacing={3}
        >
          <>
            <Grid item xs={12}>
              <TextField
                id='strategy-name-input'
                name='strategy_name'
                helperText="Describe the strategy, this will be used in the contract"
                label={'Strategy Name'}
                required={true}
                error={!formData.name && isSubmitted}
                value={formData.name}
                onChange={(event): void => {
                  dispatch({ type: ActionType.ON_CHANGE_STRATEGY_OVERVIEW_DATA, payload: { name: event.target.value} });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                autoWidth
                id='service-select'
                error={!formData?.service_id && isSubmitted}
                helperText="Service that relates to this strategy"
                label='Service'
                MenuProps={{ PaperProps: { className: css.dropdown } }}
                name='service'
                onChange={(event): void => {
                  const { target: { value }} = event;
                  dispatch({ type: ActionType.ON_CHANGE_STRATEGY_OVERVIEW_DATA, payload: { service_id: value }});
                }}
              >
                {serviceOptions}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                id='strategy-frequency-select'
                error={!formData?.strategy_frequency_id && isSubmitted}
                helperText="To be used to set how often this strategy will occur"
                label='Strategy Frequency'
                MenuProps={{ PaperProps: { className: css.dropdown } }}
                name='strategy-frequency'
                onChange={(event): void => {
                  const { target: { value }} = event;
                  dispatch({ type: ActionType.ON_CHANGE_STRATEGY_OVERVIEW_DATA, payload: { strategy_frequency_id: value }});
                }}
              >
                {strategyFrequencyOptions}
              </Select>
            </Grid>
          </>
        </Grid>
      </>
    );
  }, [dispatch, formData.name, formData?.service_id, formData?.strategy_frequency_id, isSubmitted, serviceOptions, strategyFrequencyOptions]);

  return (
    <Card
      id={'strategy-overview-navigation'}
      disablePadding
      sx={{ marginBottom: '24px' }}
      header={
        <CardHeader title="Strategy Overview" />
      }
    >
      <CardContent>
        {strategyOverviewCardContent}
      </CardContent>
    </Card>
  );
};

/**
 * Strategy overview card when creating a new library strategy package
 */
export default memo(StrategyOverview);
