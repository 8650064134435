import { Bucket } from 'features/entitiesRedux';

export const getScoreStatus = ({ buckets }: { buckets: Bucket[] }) => {
  let status;

  /** filter allBuckets array down to any bucket scores that have a value (non-null) */
  const bucketScores = buckets?.filter(
    (i) => i !== undefined && i !== null && i.score !== null
  );

  /** 'pending' means all bucket scores are null */
  if (bucketScores?.length === 0) {
    status = 'pending';
  }

  /** 'partial' means we have some with values and some null */
  if (bucketScores?.length && bucketScores?.length < buckets?.length) {
    status = 'partial';
  }

  /** 'complete' means all bucket scores have a value (non-null) */
  if (bucketScores?.length === buckets?.length) {
    status = 'complete';
  }

  return status;
};
