import React, { memo } from 'react';
import { ArrowLeftIcon, ArrowRightIcon, IconButton } from '@sprnova/nebula';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import css from './AdPreviewModalCard.module.scss';

type ArrowProps = {
  'data-role'?: string;
  className?: string;
  style?: CSSStyleDeclaration;
  currentSlide?: number;
  slideCount?: number;
  onClick?: () => void;
};
export const NextArrow = (props: ArrowProps): JSX.Element => {
  const { className, style, onClick } = props;
  return (
    <IconButton
      size="md"
      className={className}
      onClick={onClick}
      sx={{
        ...style,
        color: '#4A1FFF !important',
        background: '#fff !important',
        border: '1px solid #D9D9D9 !important',
        top: 'calc(100%) !important',
        right: '25px !important',
        zIndex: 999,
        '&::before': {
          content: '""',
          display: 'none'
        }
      }}
    >
      <ArrowRightIcon />
    </IconButton>
  );
};

export const PrevArrow = (props: ArrowProps): JSX.Element => {
  const { className, style, onClick } = props;
  return (
    <IconButton
      size="md"
      className={className}
      onClick={onClick}
      sx={{
        ...style,
        color: '#4A1FFF !important',
        background: '#fff !important',
        border: '1px solid #D9D9D9 !important',
        top: 'calc(100%) !important',
        left: '25px !important',
        zIndex: 999,
        '&::before': {
          content: '""',
          display: 'none'
        },
      }}
    >
      <ArrowLeftIcon />
    </IconButton>
  );
};
type CarouselComponentProps = {
  children: React.ReactNode;
  initialSlide?: number;
  ref: React.RefObject<CarouselRef> | null;
  beforeChange?: (currentSlide: number, nextSlide: number) => void;
  dots?: boolean;
  dotPosition?: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
}
export const CarouselComponent = ({
  children,
  initialSlide = 1,
  ref,
  beforeChange,
  dots,
  dotPosition = 'bottom',
  className = css.smallCarousel
}: CarouselComponentProps): JSX.Element => {
  return (
    <Carousel
      dotPosition={dotPosition}
      arrows
      nextArrow={<NextArrow />}
      prevArrow={<PrevArrow />}
      className={className}
      initialSlide={initialSlide}
      ref={ref}
      beforeChange={beforeChange}
      dots={dots}
    >
      {children}
    </Carousel>
  );
};

export default memo(CarouselComponent);
