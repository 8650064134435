import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import loadable from '@loadable/component';
import { selectPartnerById } from 'features/entitiesRedux';
import { Skeleton } from 'components';

const ViewPartnerPage = loadable(
  () => import('./ViewPartnerPage'),
);

const Title = () => {
  const { id } = useParams<{ [x: string]: any }>();
  const partner = useSelector(selectPartnerById(Number.parseInt(id)));

  if (!partner) {
    return <Skeleton title={{ width: 200 }} paragraph={false} active />;
  }

  return <>{partner.company || partner.name}</>;
};

Title.displayName = 'ViewPartnerPageTitle';

export default Object.assign(ViewPartnerPage, { Title });
