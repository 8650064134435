import React, { memo, useMemo } from 'react';
import { Grid, GridProps, Legend as NebulaLegend, Typography, theme } from '@sprnova/nebula';
import { PacingData } from 'api/crudGraphQL/scoreboards/types';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Cell,
} from 'recharts';
import { formatScoreboardsDate, formatYAxisTick, hexToRgba } from '../../../components/utils';
import CustomToolTip from '../CustomToolTip';

const commonGridProps: GridProps = {
  display: 'flex',
  alignItems: 'center',
};

type LongTermChartProps = {
  longTermData: PacingData[];
  lastUpdated?: string | null;
  format?: string;
  metricName?: string;
};

const LongTermChart = ({ longTermData, lastUpdated, format, metricName }: LongTermChartProps): JSX.Element => {
  const ActualColor = theme.variables?.colors.data_visualization.sequential[90];
  const EstPaceColor= hexToRgba(ActualColor, 0.4);
  const GoalColor = theme.variables?.colors.data_visualization.sequential[50];
  const CartesianGridStroke  = theme.variables.colors.neutrals.ghost[400];
  const HeaderFooterColor = theme.variables?.colors.primary.charcoal[200];


  const formattedLongTermData = useMemo(() => {
    return longTermData?.map(item => ({
      ...item,
      hasPace: !!item.pace && !!item.actual && item.pace > item.actual,
      formattedActual: item.actual === 0 ? null : item.actual,
      formattedPace: item.actual && item.pace && item.pace > item.actual ? item.pace - item.actual : null,
      formattedDate: item.date ? formatScoreboardsDate(item.date, 'axis_value', 'LongTermChart') : 'no date'
    }));
  }, [longTermData]);

  const renderLegend = useMemo((): JSX.Element => {
    return (
      <Grid
        container
        display='flex'
        flexDirection='row'
        wrap='nowrap'
        justifyContent='end'
      >
        <NebulaLegend.Group direction="horizontal">
          <NebulaLegend
            variant="line"
            color={ActualColor}
            label="Actual"
          />
          <NebulaLegend
            variant="line"
            color={EstPaceColor}
            label="Pace"
          />
          <NebulaLegend
            variant="line"
            color={GoalColor}
            label="Goal"
          />
        </NebulaLegend.Group>
      </Grid>
    );
  }, [ActualColor, EstPaceColor, GoalColor]);

  const renderLongTermChartFooter = useMemo(() => {
    if (!lastUpdated) return null;
    return (
      <Grid display='flex' justifyContent='start' width='100%'>
        <Typography variant='caption' fontStyle='italic' color={HeaderFooterColor} paddingTop='40px'>
          Last updated: {formatScoreboardsDate(lastUpdated, 'timestamp')}
        </Typography>
      </Grid>
    );
  }, [HeaderFooterColor, lastUpdated]);

  return (
    <Grid direction='column' height='600px' width='100%' padding='24px 0' {...commonGridProps}>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart
          height={300}
          data={formattedLongTermData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barGap={0}
        >
          <CartesianGrid vertical={false} stroke={CartesianGridStroke} />
          <XAxis
            tickLine={false}
            axisLine={false}
            dataKey='formattedDate'
            interval={Math.floor(formattedLongTermData?.length / 16)}
          >
          </XAxis>
          <YAxis
            tickLine={false}
            axisLine={false}
            label={{ value: metricName, angle: -90, position: 'left', offset: 15 }}
            tickFormatter={formatYAxisTick}
          ></YAxis>
          <Tooltip content={<CustomToolTip metricName={metricName} format={format}/>} />
          <Legend
            verticalAlign='top'
            content={renderLegend}
            height={50}
            align='right'
          />
          <Bar
            dataKey="formattedActual"
            stackId="leftBar"
            fill={ActualColor}
            isAnimationActive={false}
          >
            {formattedLongTermData.map((entry, index) => (
              <Cell
                key={`LongTermChart-cell-${index}`}
                {...(entry.hasPace ? { radius: [0, 0, 0, 0] } : { radius: [8, 8, 0, 0] }) as any}
              />
            ))}
          </Bar>
          <Bar
            dataKey="formattedPace"
            stackId="leftBar"
            fill={EstPaceColor}
            radius={[8, 8, 0, 0]}
            isAnimationActive={false}
          />
          <Bar
            dataKey="goal"
            fill={GoalColor}
            radius={[8, 8, 0, 0]}
            isAnimationActive={false}
          />

        </BarChart>
      </ResponsiveContainer>
      {renderLongTermChartFooter}
    </Grid>
  );
};

export default memo(LongTermChart);

