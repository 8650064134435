/**
 * Entities -> Strategies -> Hooks
 */

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Projection } from 'api/entityGraphQL';
import { useEntitiesPaginated, useEntityByParamId, useEntityByParamIdRefresh } from '../_entity/hooks';
import { HookPaginatedConfig, HookPaginatedReturn } from '../types';
import { fetchStrategiesPaginated, fetchStrategyById } from './actions';
import { selectStrategiesByIds, selectStrategyById } from './selectors';
import { Strategy, FetchStrategiesFilter } from './strategy';

/**
 * Get paginated strategies
 */
export const useStrategiesPaginated = ({
  page: defaultPage,
  limit: defaultLimit,
  filter: defaultFilter,
  projection,
  trackHistory = true,
}: HookPaginatedConfig = {}): HookPaginatedReturn & {
  strategies: Strategy[];
  filter: FetchStrategiesFilter;
} => {
  const {
    entities: { strategies: strategyIds },
    isLoading,
    error,
    filter,
    setFilter,
    pagination,
  } = useEntitiesPaginated(fetchStrategiesPaginated, {
    page: defaultPage,
    limit: defaultLimit,
    filter: defaultFilter,
    projection,
    trackHistory,
  });
  const strategies = useSelector(selectStrategiesByIds(strategyIds));

  return { strategies, isLoading, error, filter, setFilter, pagination };
};

/**
 * Get strategies by param ID
 */
export const useStrategyByParamId = () =>
  useEntityByParamId(selectStrategyById, fetchStrategyById);

/**
 * Get strategies + refreshData function by param ID
 */
export const useStrategyByParamIdRefresh = (
  config: {
      projection?: Projection;
    } = {}
): { strategy: Strategy, refreshData: (extraProjection?: Projection) => void } => {
  const response = useEntityByParamIdRefresh(selectStrategyById, fetchStrategyById, config, 'id');
  return {
    strategy: response?.entity,
    refreshData: response?.refreshData
  };
};

/**
 * Get strategies + refreshData function by ID
 */
export const useStrategyRefreshById = (
  strategyId: number,
  config: { projection?: Projection } = {}
): { strategy: Strategy, refresh: (config?: { projection?: Projection }) => void } => {
  const dispatch = useDispatch();
  const refresh = (config?: { projection?: Projection }) => {
    dispatch(fetchStrategyById({ id: strategyId, ...config }));
  };
  useEffect(() => {
    dispatch(fetchStrategyById({ id: strategyId, ...config }));
  }, [strategyId]);
  return {
    strategy: useSelector(selectStrategyById(strategyId)),
    refresh,
  };
};
