import React, { memo } from 'react';
import { ClickIcon } from '@sprnova/nebula';

type NotificationIconProps = {
  slug: string
}

const NotificationIcon= ({slug}: NotificationIconProps) => {
  /**
   * Renders material UI icon based on slug value
   * @param slugName slug value in the dashboard_note_type table
   * @returns Material UI icon
   */
  const renderIconSwitch = (slugName: string) => {
    switch(slugName) {
      case 'traffic-mom-up':
      case 'traffic-daily-up':
      case 'traffic-mom-down':
      case 'traffic-daily-down':
        return (
          <span className="material-symbols-outlined">
              ads_click
          </span>
        );
      case 'repeat-cvr-mom-up':
      case 'repeat-cvr-daily-up':
        return (
          <span className="material-symbols-outlined">
              trending_up
          </span>
        );
      case 'repeat-cvr-mom-down':
      case 'repeat-cvr-daily-down':
        return (
          <span className="material-symbols-outlined">
              trending_down
          </span>
        );
      case 'repeat-rate-mom-up':
      case 'repeat-rate-daily-up':
        return (
          <span className="material-symbols-outlined">
              add_shopping_cart
          </span>
        );
      case 'repeat-rate-mom-down':
      case 'repeat-rate-daily-down':
        return (
          <span className="material-symbols-outlined">
              production_quantity_limits
          </span>
        );
      case 'revenue-mom-up':
      case 'revenue-mom-down':
      case 'revenue-daily-up':
        return (
          <span className="material-symbols-outlined">
              attach_money
          </span>
        );
      case 'revenue-daily-down':
        return (
          <span className="material-symbols-outlined">
              money_off
          </span>
        );
      case 'first-cvr-mom-up':
      case 'first-cvr-daily-up':
        return (
          <span className="material-symbols-outlined">
              trending_up
          </span>
        );
      case 'first-cvr-mom-down':
      case 'first-cvr-daily-down':
        return (
          <span className="material-symbols-outlined">
              trending_down
          </span>
        );
      case 'time-to-submit-your-pulse':
      case 'review-your-teams-pulses':
      case 'your-manager-reviewed-your-pulse':
        return (
          <ClickIcon />
        );

    }
  };

  return (
    <>
      {renderIconSwitch(slug)}
    </>
  );
};

export default memo(NotificationIcon);
