import React, { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, Dialog, Radio, Textarea, useForm } from '@sprnova/nebula';
import { useNovaAITier } from 'features/clients/hooks';

type Props = {
  isOpen: boolean;
  onSubmit: (data: OtherFeedbackFields) => void;
  onCancel?: () => void;
}

export type OtherFeedbackFields = {
  feedback_additional: string;
  support_requested: string;
}

const OtherFeedbackDialog: React.FC<Props> = ({ isOpen, onCancel, onSubmit }): JSX.Element => {
  const { handleSubmit, reset, control } = useForm<OtherFeedbackFields>();
  const { clientId: idRaw } = useParams<{ [clientId: string]: string }>();
  const clientId = parseInt(idRaw);

  const { aiTier } = useNovaAITier({ client_id: clientId }, !clientId);

  const handleCancel = useCallback(() => {
    onCancel?.();
    reset();
  }, [onCancel, reset]);

  const onFormSubmit = useCallback((validatedData: OtherFeedbackFields) => {
    onSubmit(validatedData);
    reset();
  }, [onSubmit, reset]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      title="Thank you for your feedback"
      description="It's our job to answer your marketing questions with speed and accuracy."
      secondaryButtonProps={{ onClick: handleCancel, children: 'Cancel' }}
      primaryButtonProps={{ onClick: handleSubmit(onFormSubmit), children: 'Submit Support Request' }}
    >

      <Controller
        control={control}
        defaultValue={aiTier?.slug !== 'essential' ? 'false' : 'null'}
        name="support_requested"
        render={({field}) => {
          return aiTier?.slug !== 'essential' ?  (
            <Radio.Group
              {...field}
              label="Would you like a nova analyst to review your question and contact you with the answer?"
              id="support-requested-input"
              sx={{mb: 4}}
            >
              <Radio value="true" label="Yes, ask an analyst" />
              <Radio value="false" label="No, thank you" />
            </Radio.Group>)
            :
            <></>;
        }}
      />
      <Controller
        control={control}
        defaultValue=""
        name="feedback_additional"
        render={({field}) => {
          return (
            <Textarea
              {...field}
              label="Additional details (optional)"
              id="other-feedback-input"
              helperText="In an effort to improve your experience, we'd like to learn more about the issue you're experiencing."
              minRows={5}
              maxRows={5}
            />
          );
        }}
      />
    </Dialog>
  );
};

export default memo(OtherFeedbackDialog);
