import { NovaAISessionType } from 'features/entitiesRedux/models/nova_ai';

const mergeSession = (sessions: NovaAISessionType[], session: NovaAISessionType): NovaAISessionType[] => {
  // Merge session with the list of sessions.
  const mergedSessions = [
    ...sessions.filter((s) => s.guid !== session.guid),
    session,
  ];

  // Sort sessions in place in descending order by creation date.
  mergedSessions.sort((sessionA, sessionB) => {
    const timeA = new Date(`${sessionA.created_at} UTC`).getTime();
    const timeB = new Date(`${sessionB.created_at} UTC`).getTime();
    const delta = timeB - timeA;
    return delta;
  });

  return mergedSessions;
};

export default mergeSession;
