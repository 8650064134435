import React, { FC } from 'react';
import classNames from 'classnames';
import css from './Blockquote.module.scss';

export type BlockquoteProps = {
  className?: string;
  children?: React.ReactNode;
};

export const Blockquote: FC<BlockquoteProps> = ({ className, children, ...props }) => {
  return (
    <blockquote className={classNames(className, css.blockquote)} {...props}>
      <span className={css.span}>{children}</span>
    </blockquote>
  );
};