import React from 'react';
import { SkeletonInput, Tooltip } from '../../../../../../../../components';
import css from './DefaultCommission.module.scss';

interface Props {
  defaultCommission?: number;
  disabled?: boolean;
  isLoading?: boolean;
}

const DefaultCommission = ({ defaultCommission, disabled, isLoading }: Props): JSX.Element => {
  if (isLoading) return <SkeletonInput />;

  const output = defaultCommission ? `${defaultCommission}%` : '-';
  
  return (
    <span className={css.root}>
      {disabled ?
        <Tooltip
          title="Default Commission overridden by Sales Rep. Commission"
          className={css.disabled}
          destroyTooltipOnHide
        >
          {output}
        </Tooltip>
        : output
      }
    </span>
  );
};

export default DefaultCommission;