/**
 * formatLargeNumber(800) => 800
 * formatLargeNumber(12345) => 12.3K
 * formatLargeNumber(2500000) => 2.5M
 *
 * @param {number} num
 * @param {string | undefined} unit
 * @param {number} precision
 * @return {string | null}
 */

export const formatLargeNumber = (
  num?: number,
  unit?: string | undefined,
  precision?: number,
): string | null => {

  if (num) {
    let output: string | null;

    // format the large number
    if ((num > 999 && num < 1000000) || (num < -999 && num > -1000000)) {
      output = (num / 1000).toFixed(precision) + 'K'; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1000000 || num <= -1000000) {
      output = (num / 1000000).toFixed(precision) + 'M'; // convert to M for number from > 1 million
    } else if (num < 1000 || num > -1000) {
      output = num.toString();
    } else {
      output = null;
    }

    // if output value has only a zero after the decimal place, then remove it
    if (precision === 1 && output?.indexOf('.0')) {
      output = output?.replace('.0', '');
    }

    // format output with provided argument for 'unit'
    if (unit === 'currency') output = `$${output}`;
    if (unit === 'percent') output = `${output}%`;

    return output;
  }

  return null;
};