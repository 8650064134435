import React, { memo, useCallback } from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import visuallyHidden from '@mui/utils/visuallyHidden';
import {Box} from '@sprnova/nebula';
import EntityPropertyType from 'utils/sort/EntityPropertyType';
import EnhancedTableHeadProps from './EnhancedTableHeadProps';
import HeadCellType from './HeadCellType';

function EnhancedTableHead(props: EnhancedTableHeadProps): JSX.Element {
  const { order, orderBy, onRequestSort, headCells, className} = props;
  const createSortHandler =
    useCallback((event: React.MouseEvent<unknown>, sortOrderObject: {
      entity: string;
      property?: string;
    }) => {
      onRequestSort(event, sortOrderObject);

    }, [onRequestSort]);

  return (
    <TableHead className={className}>
      <TableRow>
        {headCells.map((headCell: Partial<HeadCellType>) => {
          const orderByMatchesCell = orderBy.entity === headCell.entity;
          if (!headCell.hidden) {
            return <TableCell
              key={headCell.id}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderByMatchesCell ? order : false}
            >
              <TableSortLabel
                active={orderByMatchesCell}
                disabled={!orderByMatchesCell}
                direction={orderByMatchesCell ? order : 'asc'}
                onClick={(event) => {
                  if (headCell.entity && headCell.property) {
                    const sortOrderObject = { entity: headCell.entity, property: headCell.property };
                    createSortHandler(event, sortOrderObject);
                  }
                }}
              >
                {headCell.label}
                {orderByMatchesCell ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>;
          }})}
      </TableRow>
    </TableHead>


  );
}

export default memo(EnhancedTableHead);
