/**
 * Audits -> DiscoveryForm -> SalesforceOpportunityStage -> Modal -> StageSelection
 */

import React from 'react';
import { OpportunityStage } from 'features/entitiesRedux/models/types';
import { StageOption } from './components';
import css from './StageSelection.module.scss';

type Props = {
  onSelectStage: (option: string) => void;
  selectedStage?: string;
  stageOptions: Pick<OpportunityStage, 'id' | 'name' | 'slug'>[];
};

const StageSelection = ({ onSelectStage, selectedStage, stageOptions }: Props) => {
  return (
    <div className={css.root}>
      <div className={css.heading}>
        <span className={css.title}>Update Salesforce Opportunity Stage</span>
        <span className={css.subtitle}>Select the stage you&apos;d like reflected on the Salesforce Opportunity</span>
      </div>
      {stageOptions.map((option, i) => (
        <StageOption
          key={`${option.slug}-${i}`}
          option={option}
          onSelectStage={onSelectStage}
          selectedStage={selectedStage}
        />
      ))}
    </div>
  );
};

export default StageSelection;
