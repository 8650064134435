import React, { useEffect, useState, useRef, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from '@sprnova/nebula';
import { Row, Col } from 'antd';
import { Action, Resource } from 'api/accessControl';
import { convertToBase64 } from 'api/utils';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { PageHero } from 'layouts/components';
import { fetchAuditById, selectAuditById, Department, Contact } from 'features/entitiesRedux';
import {
  Audit,
  updateDiscoveryPage,
  useAuditByParamId,
} from 'features/entitiesRedux/models/audit';
import { selectError, selectIsLoading } from 'features/entitiesRedux/selectors';
import {
  Alert,
  AccessControl,
  Skeleton,
  PageHeaderScore,
  message,
  ScrollTopButton,
} from 'components';
import { ScoreBreakdownPopover } from '../AuditOverviewPage/components/ScoreBreakdownPopover';
import { projection } from '../AuditOverviewPage/projection';
import { AuditPageHeaderExtra } from '../AuditPageHeaderExtra';
import { setDiscoveryVersion } from '../auditsSlice';
import { DiscoveryForm } from '../components';
import { SalesforceOpportunityStage } from '../components/DiscoveryForm/components';
import css from './EditDiscoveryPage.module.scss';

type FormattedTitleProps = {
  formattedTitle?: string | number;
};

const formatDiscoveryTitle = ({
  audit,
  isLoading,
}: {
  audit?: Pick<Audit, 'name' | 'id' | 'client'>;
  isLoading: boolean;
}) => {
  return !audit && isLoading ? (
    <Skeleton title={{ width: 200 }} paragraph={false} active />
  ) : audit ? (
    (() => {
      if (audit.name) {
        return `${audit.name} Discovery`;
      } // Try the name field
      if (audit?.client?.name) {
        return `${audit?.client.name} Discovery`;
      } // Try the client field
      return `Discovery #${audit?.id}`;
    })()
  ) : (
    '...'
  );
};

const EditDiscoveryPage: React.FC<FormattedTitleProps> = () => {
  const currentBusinessTypeId = useRef<null | number>(null);
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const history = useHistory();
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const { id: idRaw = '' } = useParams<{ [x: string]: string | undefined }>();
  const auditId = Number.parseInt(idRaw);
  const audit = useSelector(selectAuditById(auditId));
  const title = formatDiscoveryTitle({ audit, isLoading });
  const businessType = audit?.discovery?.business_type;
  const opportunityScore = audit?.discovery?.opportunity_score;
  const discoveryDepartments = audit?.discovery?.departments?.filter(
    (d: Department) => d.in_survey === 'yes' && d.scores?.length > 0
  );

  // refresh the discovery after salesforce stage has been successfully updated
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);

  /**
   * Fetch or reload data when we have a business type ID
   */
  useEffect(() => {
    const businessTypeId = businessType?.id;

    const fetchOrReloadData = async () => {
      setLoading(true);
      currentBusinessTypeId.current = businessTypeId;

      await dispatch(fetchAuditById({
        id: auditId,
        projection
      }));

      await dispatch(setDiscoveryVersion('internal'));

      setLoading(false);
    };

    if (currentBusinessTypeId.current !== businessTypeId || shouldRefresh) {
      fetchOrReloadData();
    }

    setShouldRefresh(false);
  }, [businessType, shouldRefresh]);

  /**
   * Save data
   */
  const handleSave = async (values: any) => {
    const { business_type_id, contacts, ...remainingValues } = values;

    // We need to reload the form if the business type ID changes
    // because the fields will most likely change.
    // The reload happens when the useEffect above is triggered when the business type changes
    // but we set loading to true to start showing skeleton immediately.
    if (business_type_id && business_type_id !== currentBusinessTypeId.current) {
      setLoading(true);
    }

    setSaving(true);

    const result: any = await dispatch( /* eslint-disable-line @typescript-eslint/no-explicit-any */
      updateDiscoveryPage({
        auditId: audit?.id,
        id: audit?.discovery?.id,
        clientId: audit?.client?.id,
        auditProjection: { ...projection, id: true },
        topics: audit?.discovery?.topics,
        contacts: contacts?.map((contact: Contact) => ({
          ...contact,
          notes: contact.notes ? convertToBase64(contact.notes) : ''
        })),
        ...remainingValues,
      })
    );

    if (updateDiscoveryPage.rejected.match(result)) {
      setSaving(false);
      message.error('An error occurred. Please try again.');
    }

    if (updateDiscoveryPage.fulfilled.match(result)) {
      setSaving(false);
      message.success('Discovery saved');

      // if Salesforce Opportunity ID has been updated then refresh the discovery form
      if (values && Object.prototype.hasOwnProperty.call(values, 'salesforce_opportunity_id')) {
        if (values?.salesforce_opportunity_id !== audit?.salesforce_opportunity_id) {
          setShouldRefresh(true);
        }
      }

      // track "Discovery Call updated" event and include Discovery Score
      try {
        const options = {
          title: audit?.name,
          appraisalId: audit?.id,
          appraisal: audit?.name,
          discoveryId: audit?.discovery?.id,
          discoveryScore: audit?.discovery?.score,
          clientId: audit?.client?.id,
          clientName: audit?.client?.name,
          businessType: audit?.client?.business_type?.name,
          businessTypeId: audit?.client?.business_type?.id,
          businessTypeSlug: audit?.client?.business_type?.slug,
        };
        if (process.env.NODE_ENV !== 'production') console.log('🛤 Track: Appraisal created', { options });
        if (mixpanel?.track) {
          mixpanel.track('Discovery Call updated', options);
        }
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') console.error('Mixpanel error', error);
      }
    }

    setShouldRefresh(false);

    return result;
  };

  return (
    <>
      <PageHero
        title={title}
        description="Edit discovery call"
        end={<AuditPageHeaderExtra audit={audit} hideButtons={['discovery']} />}
      />
      <Container className={css.root} hasVerticalPadding>
        {error && <Alert message={error} type="error" className={css.error} />}
        <DiscoveryForm
          onSave={handleSave}
          saving={saving}
          loading={loading}
          businessTypeId={businessType?.id}
          audit={audit}
          renderStart={
            <Row className={css.discoveryScoreRow}>
              <Col span={24} className={css.discoveryScoreCol}>
                {audit?.pillar?.id === 1005 ? <></> :
                  <ScoreBreakdownPopover
                    isOpportunityScore
                    score={opportunityScore}
                    departments={discoveryDepartments}
                  >
                    <PageHeaderScore
                      title="Opportunity Score"
                      score={opportunityScore}
                      max={10}
                    />
                  </ScoreBreakdownPopover>
                }
                <PageHeaderScore
                  title="Discovery Score"
                  score={audit?.discovery?.discovery_score}
                  max={10}
                  fullwidth
                />
              </Col>
            </Row>
          }
          extra={
            <SalesforceOpportunityStage
              audit={audit}
              onRefresh={setShouldRefresh}
            />
          }
        />
        <ScrollTopButton />
      </Container>
    </>
  );
};

export const FormattedTitle: FC = () => {
  const audit: Audit | undefined = useAuditByParamId({
    projection: {
      id: true,
      name: true,
      author: {
        id: true,
      },
      client: {
        id: true,
        name: true,
      }
    },
  });
  const isLoading = !audit;
  return (
    <AccessControl
      action={[Action.read]}
      resource={Resource.client}
      authorId={audit?.author?.id}
      unauthorized={<span>...</span>}
    >
      <span>{formatDiscoveryTitle({ audit, isLoading })}</span>
    </AccessControl>
  );
};

export default EditDiscoveryPage;
