/**
* Get Client Account Stages
*/

import { Projection } from 'api/entityGraphQL/types';
import { ClientAccountStage } from 'features/entitiesRedux';
import { baseApi } from '../';
import tagTypes from './tagTypes';

export type GetAccountStagesQuery = {
  projection?: Projection;
};

export type GetAccountStagesResult = ClientAccountStage[];

export const GET_ACCOUNT_STAGES_ENDPOINT_KEY = 'getAccountStages';

export const { useGetAccountStagesQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_ACCOUNT_STAGES_ENDPOINT_KEY]: build.query<GetAccountStagesResult, GetAccountStagesQuery>({
      transformResponse: (response: { client_account_stages: GetAccountStagesResult }) => response.client_account_stages,
      providesTags: (client_account_stages = []) => [
        tagTypes.CLIENT_ACCOUNT_STAGE_LIST,
        ...client_account_stages.map(client_account_stage => ({
          id: client_account_stage.id,
          type: tagTypes.CLIENT_ACCOUNT_STAGE
        }))
      ],
      query: ({
        projection = { id: true, name: true },
      }) => ({
        body: {
          query: {
            client_account_stages: {
              ...projection
            },
          }
        }
      })
    }),
  }),
});
