/**
 * BudgetTable
 */

import React, { FC, useCallback, useMemo, useState } from 'react';
import { message } from 'antd';
import classNames from 'classnames';
import { addMonths, eachMonthOfInterval } from 'date-fns';
import { HorizonBudget, HorizonChannel, HorizonFunnel, HorizonScenarioSameBudgetSameChannels } from 'features/entitiesRedux/models/horizon';
import { Cell, CopyPastePreviewModal, CopyPastePreviewModalProps, Funnel, FunnelProps } from './components';
import { formatDateToFirstInMonth, getNormalizedDate, isValidPaste, parsePastedValue } from './utils';
import css from './BudgetTable.module.scss';

export type BudgetTableValue = {
  channel: Pick<HorizonChannel, 'id'>;
  date: string;
  funnel: Pick<HorizonFunnel, 'id'>;
  id?: number;
  value: number;
  appearance?: 'new' | 'faded';
}

export type BudgetTableProps = {
  channels: HorizonChannel[];
  data: BudgetTableValue[];
  previousData?: HorizonScenarioSameBudgetSameChannels;
  length: number;
  funnels: HorizonFunnel[];
  startDate: string;
  historical?: boolean;
  onSavePastedValues?: (data: BudgetTableValue[]) => Promise<void>;
  onUpdateCell?: ({ id, date, value }: {
    id?: number;
    date: string;
    value: number;
    funnelId: number;
    channelId: number;
   }) => Promise<HorizonBudget>
}

export type BudgetTableValuesMap = Record<string, Record<string, Record<string, {
  value: BudgetTableValue['value'];
  id?: BudgetTableValue['id'];
  appearance?: BudgetTableValue['appearance'];
}>>>;

type PasteCallback = (args: {
  date: string;
  event: React.ClipboardEvent<HTMLInputElement>;
  yIndex: number;
  xIndex: number;
}) => void;

export const BudgetTable: FC<BudgetTableProps> = ({
  startDate,
  data = [],
  funnels = [],
  channels = [],
  onUpdateCell,
  onSavePastedValues,
  length,
  previousData,
  historical,
}) => {
  /**
   * Value formatting
   */

  // Get the array of dates/months between the start and end date to display in the table
  const dates = useMemo(() => {
    if (!startDate || !length) {
      return [];
    }

    const start = getNormalizedDate(startDate);
    const end = addMonths(start, length - 1);

    return eachMonthOfInterval({
      start,
      end
    }).map(date => formatDateToFirstInMonth(date));
  }, [startDate, length]);

  // Format previous data to match the current data structure
  const formattedPreviousData = useMemo(() => {
    if (!previousData) {
      return [];
    }

    return (previousData || []).reduce<BudgetTableProps['data']>((acc, { month_date, budgets }) => [
      ...acc,
      ...budgets.map(({ channel, funnel, value }) => ({
        channel,
        date: month_date,
        funnel,
        value,
      }))
    ], []);
  }, [previousData]);

  // Merge the historical data with the current data
  // Note: Spreading the previous values first ensures that the current values will override the previous values
  const formattedData = useMemo(() => [...formattedPreviousData, ...data], [data, formattedPreviousData]);

  // Create a look-up map of the data values by key so we can populate the cells with current (and historical) values
  const initialValuesMap = useMemo(() => formattedData.reduce<BudgetTableValuesMap>((acc, { date, channel, funnel, value, id, appearance }) => {
    if (!acc[channel.id]) {
      acc[channel.id] = {};
    }

    if (!acc[channel.id][funnel.id]) {
      acc[channel.id][funnel.id] = {};
    }

    acc[channel.id][funnel.id] = {
      ...acc[channel.id][funnel.id],
      [`${formatDateToFirstInMonth(getNormalizedDate(date))}`]: { value, id, appearance }
    };

    return acc;
  }, {}), [formattedData]);

  /**
   * Copy/paste functionality
   */
  const [copyPasteData, setCopyPasteData] = useState<CopyPastePreviewModalProps['pasteData'] | null>(null);

  const handlePaste: PasteCallback = useCallback(({ yIndex, xIndex, event }) => {
    const value = event.clipboardData.getData('text');

    if (!isValidPaste(value)) {
      message.error('Invalid pasted value. Ensure that the value is a single number or multiple comma-separated numbers');
      event.preventDefault();
      return;
    }

    // Parsing pasted string to a matrix of values
    const parsedValues = parsePastedValue(value);

    // Only one value was pasted – don't do anything (the default behavior will take over)
    if (parsedValues.flat(2).length === 1) {
      return;
    }

    setCopyPasteData({
      parsedValues,
      yIndexOffset: yIndex,
      xIndexOffset: xIndex,
    });

    event.preventDefault();
  }, []);

  const [isSaving, setIsSaving] = useState(false);

  const handleSavePastedValues = async (values: BudgetTableProps['data']): Promise<void> => {
    if (!onSavePastedValues) {
      return;
    }

    try {
      setIsSaving(true);
      await onSavePastedValues(values);
      setCopyPasteData(null);
      setIsSaving(false);
    } catch {
      setIsSaving(false);
    }
  };

  /**
   * Render
   */
  const renderMonths = useMemo(() => (
    <div className={css.aside}>
      <div className={css.top}>
        <Cell fullWidth height="small" color="gray" />
      </div>
      <div className={css.stickyFunnel}>
        <Cell fullWidth  color="gray" align="top">
          Date
        </Cell>
      </div>
      {dates.map(date => (
        <Cell fullWidth key={date} color="gray">
          {getNormalizedDate(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}
        </Cell>
      ))}
      <Cell fullWidth color="gray" isBold>
        Totals
      </Cell>
    </div>
  ), [dates]);

  const renderChannels = useMemo(() => {
    // Get the correct table cell x index based on the channel and funnel index
    const getXIndex = (channelIndex: number, funnelIndex: number): number => channelIndex === 0 ? funnelIndex : funnelIndex + (funnels.length * channelIndex);

    return channels.map((channel, channelIndex) => {
      const handleUpdateCell: FunnelProps['onUpdateCell'] = onUpdateCell ? (args): Promise<HorizonBudget> => onUpdateCell?.({ ...args, channelId: channel.id }) : undefined;

      return (
        <div className={css.channel} key={channel.id}>
          <div className={css.top}>
            <Cell fullWidth color="gray" height="small">{channel.name}</Cell>
          </div>
          <div className={css.funnels}>
            {funnels.map(({ name, id }, funnelIndex) => {

              const onPaste = (args: {
                date: string;
                event: React.ClipboardEvent<HTMLInputElement>;
                yIndex: number;
              }): void => handlePaste({
                ...args,
                xIndex:getXIndex(channelIndex, funnelIndex)
              });

              return (
                <Funnel
                  id={id}
                  initialValues={initialValuesMap?.[channel.id]?.[id]}
                  key={id}
                  dates={dates}
                  name={name}
                  onUpdateCell={handleUpdateCell}
                  onPaste={onPaste}
                />
              );
            })}
          </div>
        </div>
      );
    });
  }, [channels, onUpdateCell, funnels, handlePaste, initialValuesMap, dates]);

  if (!channels.length || !funnels.length) {
    return (
      <div className={css.emptyResultContainer}>
        <div className={css.emptyResult}>
          <div className={css.iconContainer}>
            <span className={classNames('material-symbols-outlined', css.emptyResultIcon)}>
            upcoming
            </span>
          </div>
          <div className={css.emptyResultText}>
              There are no {historical && 'historical'} channels or funnels available for this client.
          </div>
        </div>
      </div>
    );
  }

  return (
    <>

      <div className={css.root}>
        <div className={css.scrollable}>
          <div className={css.table}>
            {renderMonths}
            {renderChannels}
          </div>
        </div>
      </div>
      <CopyPastePreviewModal
        channels={channels}
        currentData={formattedData}
        dates={dates}
        funnels={funnels}
        length={length}
        onClose={(): void => setCopyPasteData(null)}
        pasteData={copyPasteData}
        startDate={startDate}
        onSave={handleSavePastedValues}
        isSaving={isSaving}
      />
    </>
  );
};
