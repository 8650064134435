import React, { FC, useCallback, useMemo, useEffect, memo } from 'react';
import { Autocomplete, Grid, Select, TextField } from '@sprnova/nebula';
import { useGetBusinessTypesQuery } from 'api/crudGraphQL/business_types/getBusinessTypes';
import { useGetAccountStagesQuery } from 'api/crudGraphQL/client_account_stages/getClientAccountStages';
import { useGetScoreboardsQuery } from 'api/crudGraphQL/scoreboards/getScoreboards';
import { Scoreboard } from 'api/crudGraphQL/scoreboards/types';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { debounce, isNil, omitBy, uniqBy } from 'lodash';
import { ClientAccountStage } from 'features/entitiesRedux';
import { useAccount } from 'features/global/hooks/useAccount';
import { useFilterContext } from 'features/scoreboards/utils/Filters';
import { track } from './mixpanelEvents';

type FiltersFormProps = {
  onMyAccounts?: boolean;
};

const FiltersForm: FC<FiltersFormProps> = ({
  onMyAccounts = false,
}) => {
  const mixpanel = useMixpanel();
  const { account } = useAccount();
  const { filter, setFilter } = useFilterContext();

  /**
   * Only fetch clients from existing scoreboards
   */
  const { data: clientsRequest, isLoading: clientsIsLoading } = useGetScoreboardsQuery({
    my_scoreboards: onMyAccounts ? 1 : undefined,
    projection: { id: false, client: { id: true, name: true } },
    limit: 9999,
  });

  const { data: stagesRequest, isLoading: stagesIsLoading } = useGetAccountStagesQuery({
    projection: { id: true, name: true },
  });

  const { data: businessTypeRequest, isLoading: businessTypeIsLoading } = useGetBusinessTypesQuery({
    projection: { id: true, name: true },
  });

  const clients = useMemo(() => {
    /**
     * Remove duplicates
     */
    return uniqBy(clientsRequest?.data?.map(({ client }: Scoreboard) => ({
      value: client.id,
      label: client.name,
    })) ?? [], 'value');
  }, [clientsRequest]);

  const stages = useMemo(() => {
    return stagesRequest?.map((stage: ClientAccountStage) => ({
      value: stage.id,
      label: stage.name,
    })) ?? [];
  }, [stagesRequest]);

  const businessTypes = useMemo(() => {
    return businessTypeRequest?.map((businessType) => ({
      value: businessType.id,
      label: businessType.name,
    })) ?? [];
  }, [businessTypeRequest]);

  const handleChange = useCallback(debounce(async (e) => {
    await setFilter?.({ type: 'add', value: { [e.target.name]: e.target.value, page: 1 } });
  }, 500, { leading: false, trailing: true, maxWait: 2000 }), [setFilter]);

  useEffect(() => {
    const { id, name } = account ?? {};
    const options = omitBy({
      user_id: id,
      user_name: name,
      business_type: businessTypeRequest?.filter(({ id }) => id === filter?.business_type_id)[0]?.name,
      ...filter,
    }, isNil);
    track({ mixpanel, type: 'filter', options });
  }, [filter, account, mixpanel, businessTypeRequest]);

  const handleClear = useCallback((value: 'business_type_id' | 'account_stage_id' | 'client_id') => {
    setFilter?.({ type: 'remove', value });
  }, [setFilter]);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        marginBottom: '32px',
      }}
    >
      <Grid item xs={3}>
        <TextField
          id="Filter-company-name"
          name="name"
          label="Scoreboard name"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          id="Filter-client"
          label="Client"
          disablePortal
          options={clients}
          onChange={(_, option): void => {
            const value = option ? option.value : null;
            const isValueNull = value === null;
            setFilter?.(isValueNull ?
              { type: 'remove', value: 'client_id' }
              :
              { type: 'add', value: { client_id: value, page: 1 }}
            );
          }}
          skeleton={clientsIsLoading}
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          id="Filter-stage"
          name="account_stage_id"
          label="Stage"
          onChange={handleChange}
          onClear={(): void => handleClear('account_stage_id')}
          value={filter?.account_stage_id || ''}
          skeleton={stagesIsLoading}
        >
          {stages.map(({ label, value }) => <Select.Item key={`${label}-${value}`} value={value}>{label}</Select.Item>)}
        </Select>
      </Grid>
      <Grid item xs={3}>
        <Select
          id="Filter-business-type"
          name="business_type_id"
          label="Business Unit"
          onChange={handleChange}
          onClear={(): void => handleClear('business_type_id')}
          value={filter?.business_type_id || ''}
          skeleton={businessTypeIsLoading}
        >
          {businessTypes.map(({ label, value }) => <Select.Item key={`${label}-${value}`} value={value}>{label}</Select.Item>)}
        </Select>
      </Grid>
    </Grid>
  );
};

export default memo(FiltersForm);
