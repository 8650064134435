import React, { Suspense, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from '@sentry/react';
import { SnackbarProvider } from '@sprnova/nebula';
import Auth0ProviderWithHistory from 'auth/Auth0ProviderWithHistory';
import { MixpanelProvider } from 'components/MixpanelProvider';
import { QueryParamProvider } from 'use-query-params';
import { useIntercom } from 'utils/hooks/useIntercom';
import { useLogRocket } from 'utils/hooks/useLogRocket';
import { ThemeConfig, ThemeContext } from 'utils/hooks/useThemeContext';
import './App.module.scss';
import { PageNotFound } from './components/PageNotFound';
import { ErrorBoundary } from './features/errors';
import { routes } from './routes';

const App = (): JSX.Element => {
  /**
   * Set MUI X License Key
   */
  LicenseInfo.setLicenseKey('e95516719c85ebfaf25fd384008cdd05Tz04MTgxNyxFPTE3MzYzOTkxMDkwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

  /**
   * Initialize Sentry
   */
  const { user } = useAuth0();

  useEffect(() => {
    if (user?.name && user?.email) {
      Sentry.setUser({ username: user?.name, email: user?.email });
    }
  }, [user?.name, user?.email]);

  /**
   * Initialize LogRocket
   */
  useLogRocket();

  /**
   * Initialize Intercom
   */
  useIntercom();

  const renderRoutes = useMemo(() => routes.map((route, i) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Component: any = route.layout || route.component;
    const themeConfig: ThemeConfig = route.theme || 'default';
    return (
      <Route
        key={`route-${i}`}
        path={route?.path}
        render={(props): JSX.Element => (
          <ThemeContext.Provider value={themeConfig}>
            <Helmet>
              <title>{route?.name ? route?.name : 'nova'}</title>
            </Helmet>
            <Component {...props} {...route.layoutProps} />
          </ThemeContext.Provider>
        )}
        exact={route.exact || false}
      />
    );
  }), []);

  return (
    <ErrorBoundary>
      <Helmet
        titleTemplate="%s | nova"
        defaultTitle="nova"
      >
        <meta
          name="description"
          content="Experience the growth that enterprises dream of with the data-driven platform designed to eliminate the guesswork and uncertainty of digital marketing."
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/nova-favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/nova-favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/nova-favicons/favicon-16x16.png"
        />
        <link
          rel="mask-icon"
          href="/nova-favicons/safari-pinned-tab.svg"
          color="#4c1cfc"
        />
      </Helmet>
      <SnackbarProvider>
        <Router>
          <Auth0ProviderWithHistory>
            <MixpanelProvider>
              <QueryParamProvider ReactRouterRoute={Route}>
                <Suspense fallback={null}>
                  <Switch>
                    {renderRoutes}
                    <Route component={PageNotFound} />
                  </Switch>
                </Suspense>
              </QueryParamProvider>
            </MixpanelProvider>
          </Auth0ProviderWithHistory>
        </Router>
      </SnackbarProvider>
    </ErrorBoundary>
  );
};

export default App;
