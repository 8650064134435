import Cookies from 'js-cookie';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { NovaGraphQLClient, novaGraphQLClient } from './NovaGraphQLClient';
import { NovaGraphQLPublicClient, novaGraphQLPublicClient } from './NovaGraphQLPublicClient';
import { ClientType } from './types';

/**
 * Utility methods for API class.
 */
export const filterUndefinedNullEmpty = (
  obj: { [x: string]: any },
  alwaysInclude: string[] = [],
): Record<string, unknown> => Object.keys(obj).reduce(
  (accum, key) => ({
    ...accum,
    // Always include some fields to be able to e.g. reset a value
    ...(alwaysInclude.includes(key)
            || (obj[key] !== undefined // not undefined
              // obj[key] !== "" && // not empty string
              && !(Array.isArray(obj[key]) && obj[key].length === 0) // not an empty array
              && obj[key] !== null) // not null
      ? { [key]: obj[key] }
      : {}),
  }),
  {},
);

export const toGraphQL = (query: Record<string, unknown>, options: Record<string, unknown> = {}): string => jsonToGraphQLQuery(query, { pretty: true, ...options });

export const getClient = (clientType?: ClientType, loggingInfo?: string): NovaGraphQLPublicClient | NovaGraphQLClient => {
  const publicUserTypes = ['partner', 'client'];
  const urlParams = new URLSearchParams(window.location.search);
  const signature = urlParams.get('signature');
  const userTypeCookie = Cookies.get('auth0_user_type');

  //TODO: need a better way to determine if we're on a client-facing discovery
  const urlParts = window.location.pathname.split('/');
  const isPublicDiscovery = urlParts.indexOf('get-started') > -1;

  if (clientType === 'public' || signature || isPublicDiscovery || (userTypeCookie && publicUserTypes.includes(userTypeCookie))) {
    if (loggingInfo) console.log('using public client:', loggingInfo);
    return novaGraphQLPublicClient;
  }

  if (loggingInfo) console.log('using private client:', loggingInfo);
  return novaGraphQLClient;
};
