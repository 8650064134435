/**
 * Intelligence -> Horizon -> Line Chart
 */

import React from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  LegendProps,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import { formatLargeNumber, formatNumber } from 'features/audits/utils';
import { LineChartDataType } from './types';
import css from './LineChart.module.scss';

type Props = {
  data: LineChartDataType;
  isBiaxial?: boolean;
}

const LineChart = ({ data, isBiaxial }: Props): JSX.Element => {
  const renderLegend = (props: Pick<LegendProps, 'payload'>): JSX.Element => {
    const { payload } = props;
    return (
      <ul className={css.legend}>
        {payload?.map((entry, index) => {
          if (entry.value.indexOf('_') > -1) return; // skip if entry value is snake case
          return (
            <li key={`item-${index}`}>
              <span className={entry?.value?.indexOf('+/-') > -1 ? css.square : css.line} style={{ backgroundColor: entry?.color }} />
              <span>{entry.value}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderTooltip = (props: Pick<TooltipProps, 'active' | 'payload' | 'label'>): JSX.Element | null => {
    const { active, payload, label } = props;
    if (active && label && payload && payload.length) {
      return (
        <div className={css.tooltip}>
          <p className={css.tooltipDate}>{label}</p>
          <ul>
            {payload?.map((entry, index) => {
              if (entry.name.indexOf('_') > -1) return; // skip if entry value is snake case
              return (
                <li key={`item-${index}`}>
                  <span>{`${entry?.name}: $${formatNumber(Number(entry?.value))}`}</span>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={css.root}>
      <div className={css.chartTitle}>{data?.title}</div>
      <ResponsiveContainer
        width="100%"
        minHeight={500}
      >
        <ComposedChart
          data={data?.data}
          margin={{ top: 20, right: 40, bottom: 80, left: 40 }}
        >
          <XAxis
            dataKey={data?.xAxisDataKey}
            interval="preserveStartEnd"
            tickCount={data?.data?.length}
            axisLine={false}
            tickLine={false}
            angle={-60}
            dy={40}
          />
          <YAxis
            yAxisId={data?.lines?.[0]?.key}
            axisLine={false}
            tickLine={false}
            tickFormatter={(val): string | number | null => formatLargeNumber(val, undefined, 1) || 0}
            label={{
              value: data?.lines?.[0]?.name,
              angle: -90,
              position: 'left',
              style: { textAnchor: 'middle', fontSize: '14px', fill: data?.lines?.[0]?.stroke }
            }}
          />
          {isBiaxial && data?.lines?.length > 1 ?
            <YAxis
              yAxisId={data?.lines?.[1]?.key}
              orientation="right"
              axisLine={false}
              tickLine={false}
              tickFormatter={(val): string | number | null => formatLargeNumber(val, undefined, 1) || 0}
              label={{
                value: data?.lines?.[1]?.name,
                angle: -90,
                position: 'right',
                style: { textAnchor: 'middle', fontSize: '14px', fill: data?.lines?.[1]?.stroke }
              }}
            /> : <></>
          }
          <Tooltip content={renderTooltip} />
          <Legend
            align="left"
            verticalAlign="top"
            height={50}
            wrapperStyle={{ top: 0, left: 24 }}
            content={renderLegend}
          />
          {data?.areas?.map(area => {
            return (
              <Area
                name={area?.name}
                key={area?.key}
                dataKey={area?.dataKey}
                yAxisId={data?.lines?.[0]?.key}
                stroke={area?.stroke}
                fill={area?.fill}
                fillOpacity={area?.fillOpacity}
              />
            );
          })}
          <CartesianGrid
            vertical={false}
            offset={{ bottom: 20 }}
          />
          {data?.lines?.map((line, i) => {
            return (
              <Line
                name={line?.name}
                key={`${line?.key}-${i}`}
                dataKey={line?.dataKey}
                yAxisId={line?.key}
                stroke={line?.stroke}
                strokeWidth={4}
                dot={false}
              />
            );
          })}
        </ComposedChart>

      </ResponsiveContainer>
    </div>
  );
};

export default LineChart;
