import { Projection } from 'api/entityGraphQL/types';
import { Industry } from 'features/entitiesRedux';
import { basePublicApi } from '../basePublicApi';
import { cleanArguments } from 'api/crudGraphQL/utils';

export type GetIndustriesPublicQuery = {
  projection?: Projection;
};

export type GetIndustriesPublicResult = Industry[];

export const GET_INDUSTRIES_ENDPOINT_KEY = 'getIndustriesPublic';

export const { useGetIndustriesPublicQuery } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_INDUSTRIES_ENDPOINT_KEY]: build.query<GetIndustriesPublicResult, GetIndustriesPublicQuery>({
      transformResponse: (response: { industries: GetIndustriesPublicResult }) => response.industries,
      query: (query) => ({
        body: {
          query: {
            industries: {
              ...(cleanArguments(query?.projection) ?? {
                id: true,
                name: true,
                slug: true,
              })
            }
          }
        }
      })
    }),
  }),
});
