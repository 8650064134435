import React, { memo } from 'react';
import { Box, Grid, Stack } from '@sprnova/nebula';
import { GetPlaybookPlatformCalculatedMetricsResult } from 'api/crudGraphQL/intelligence/creative_affinity/getPlaybookPlatformCalculatedMetrics';
import { capitalCase } from 'change-case';
import { getPlatformIcon } from 'features/intelligence/creative-playbook/CreativeAffinityUtils';

const formatFormula = (value: string): string => {
  const parts = value.split('/');
  if (parts.length === 1) {
    return capitalCase(value);
  }
  return `${capitalCase(parts[0])} / ${capitalCase(parts[1])}`;
};

export type EquationTooltipProps = {
  description?: string;
  equations?: GetPlaybookPlatformCalculatedMetricsResult;
  multiplier?: number | string;
};
const EquationTooltip: React.FC<EquationTooltipProps> = ({ description, equations, multiplier }) => {
  return (
    <Stack spacing={1}>
      <Box>{description}</Box>
      {equations?.map((equation, index: number) => {
        const { name, formula, platform_slug } = equation;
        const formattedFormula = `${multiplier ? '(' : ''}${formatFormula(formula)}${multiplier ? `) * ${multiplier}` : ''}`;

        return (
          <Box key={`${name}_${index}`} display='flex'>
            <Grid container spacing={2}>
              <Grid item>{getPlatformIcon(platform_slug)}</Grid>
              <Grid item flex={2}>{formattedFormula}</Grid>
            </Grid>
          </Box>
        );
      })}
    </Stack>
  );
};

export default memo(EquationTooltip);
