import React from 'react';
import loadable from '@loadable/component';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';

export const PartnersPage = loadable(
  () => import('./PartnersPage'),
  {
    fallback: <PageSkeleton />
  }
);
