/**
 * Entities -> Forecasts -> Reducers
 */

import { EntitiesState } from '../../types';
import { forecastsAdapter } from './adapter';
import { ForecastChannel, ForecastMonth, ForecastNeedleMover } from './forecast';

// Helper function for updating a forecast channel
const updateChannel = ({
  state,
  forecast_id,
  channel_id,
  update,
}: {
  channel_id: number;
  forecast_id: number;
  state: EntitiesState;
  update: Partial<ForecastChannel>;
}) => {
  const listOfChannels = state.data.forecasts.entities[forecast_id];
  const channels = listOfChannels !== undefined ? [...listOfChannels?.channels] : [];

  forecastsAdapter.updateOne(state.data.forecasts, {
    id: forecast_id,
    changes: {
      channels: channels.map((c: ForecastChannel) => c.id === channel_id ? { ...c, ...update } : c)
    }
  });
};

// Helper function for updating the forecast month
const updateMonth = ({ state, forecast_id, forecast_channel_id, forecast_month_id, update }: {
  state: EntitiesState,
  forecast_id: number,
  forecast_month_id?: number,
  forecast_channel_id?: number | null,
  update: Partial<ForecastMonth>
}): void => {
  try {
    const monthId = forecast_month_id || update.id;
    const listOfChannels = state.data.forecasts.entities[forecast_id];
    const channels = listOfChannels !== undefined ? [...listOfChannels?.channels] : [];

    const listOfMonths = state.data.forecasts.entities[forecast_id];
    const months = listOfMonths !== undefined ? [...listOfMonths.months] : [];

    // Update forecast.channels.months if a forecast_channel_id is provided
    const changes = forecast_channel_id ? {
      channels: channels.map((channel: ForecastChannel) => channel.id === forecast_channel_id ?
        {
          ...channel,
          months: [...channel.months].map((month: ForecastMonth) => month.id === monthId ? { ...month, ...update } : month )
        }
        : channel)
    } :
    // If no forecast_channel_id is provided; update forecast.months
      {
        months: months.map((month: ForecastMonth) => month.id === monthId ? { ...month, ...update } : month )
      };

    forecastsAdapter.updateOne(state.data.forecasts, {
      id: forecast_id,
      changes
    });
  } catch (e) {
    console.error('Failed to update forecast month in state', e);
  }
};

// Update forecast.months or forecast.channel.months
const updateForecastChannelOrMonth = ({ state, forecast_id, forecast_channel_id, forecast_month_id, update }: {
  state: EntitiesState,
  forecast_id: number,
  forecast_month_id?: number,
  forecast_channel_id?: number | null,
  update: Partial<ForecastMonth> | Partial<ForecastChannel>,
}) => {
  // Update forecast.month or forecast.channel.month
  if (forecast_month_id) {
    updateMonth({
      state,
      forecast_id,
      forecast_channel_id,
      forecast_month_id,
      update
    });
  // Update forecast.channel
  } else if (forecast_channel_id) {
    updateChannel({
      channel_id: forecast_channel_id,
      forecast_id,
      state,
      update
    });
  }
};

// Helper function for getting the current needle movers from state
const getCurrentNeedleMovers: (state: EntitiesState, forecast_id: number, forecast_month_id?: number | null, forecast_channel_id?: number | null) => ForecastNeedleMover[] = (state, forecast_id, forecast_month_id, forecast_channel_id) => {
  const forecast = state.data.forecasts.entities[forecast_id];
  let needle_movers: any[] = [];

  // Forecast.channels.needle_movers
  if (forecast_channel_id && !forecast_month_id) {
    needle_movers = forecast?.channels?.find((channel: ForecastChannel) => channel.id === forecast_channel_id)?.needle_movers || [];
  // Forecast.months.needle_movers or Forecast.channels.months.needle_movers
  } else {
    const checking = !forecast_channel_id ?
      forecast?.months?.find((month: ForecastMonth) => month.id === forecast_month_id)?.needle_movers :
      forecast?.channels?.find((channel: ForecastChannel) => channel.id === forecast_channel_id)?.months?.find((month: ForecastMonth) => month.id === forecast_month_id)?.needle_movers;

    if (checking == undefined) needle_movers = [];
    else needle_movers = checking;
  }

  return [...needle_movers];
};


export default {
  /**
   * Create forecast channel
   */
  'createForecastChannel/fulfilled'(state: EntitiesState, action: { payload: ForecastChannel & { forecast_id: number } }): void {
    const { forecast_id, ...newChannel } = action.payload;

    const listOfChannels = state.data.forecasts.entities[forecast_id];
    const channels = listOfChannels !== undefined ? [...listOfChannels?.channels] : [];

    forecastsAdapter.updateOne(state.data.forecasts, {
      id: forecast_id,
      changes: {
        channels: [...channels, newChannel]
      }
    });

    state.isLoading = false;
  },

  /**
   * Update forecast channel
   */
  'updateForecastChannel/fulfilled'(state: EntitiesState, action: { payload: ForecastChannel & { forecast_id: number } }): void {
    const { forecast_id, ...update } = action.payload;

    if (forecast_id) {
      updateChannel({
        channel_id: update.id,
        forecast_id,
        state,
        update
      });
    }

    state.isLoading = false;
  },

  /**
   * Update forecast/channel needle movers
   */
  'updateForecastMonth/fulfilled'(state: EntitiesState, action: { payload: ForecastChannel & { forecast_id: number, forecast_channel_id?: number, month_id: number } }): void {
    const { forecast_id, forecast_channel_id, months } = action.payload;

    months.forEach(updatedMonth => {
      updateMonth({
        state,
        forecast_id,
        forecast_channel_id,
        forecast_month_id: updatedMonth.id,
        update: updatedMonth
      });
    });

    state.isLoading = false;
  },

  /**
  * Create Forecast Needle Movers
  */
  'createNeedleMovers/fulfilled'(state: EntitiesState, {
    payload: {
      needle_movers,
      forecast_id,
      forecast_month_id,
      forecast_channel_id
    }
  }: { payload: {
    needle_movers: ForecastNeedleMover[];
    forecast_id: number;
    forecast_month_id: number;
    forecast_channel_id?: number | null
  } }): void {
    try {
      const currentNeedleMovers = getCurrentNeedleMovers(state, forecast_id, forecast_month_id, forecast_channel_id);
      const updatedNeedleMovers = [...currentNeedleMovers, ...needle_movers];

      updateForecastChannelOrMonth({
        state,
        forecast_id,
        forecast_channel_id,
        forecast_month_id,
        update: {
          needle_movers: updatedNeedleMovers,
        }
      });
    } catch (e) {
      console.error('Failed to add forecast needle movers with payload:', {
        needle_movers,
        forecast_id,
        forecast_month_id,
        forecast_channel_id
      }, e);
    }
  },

  /**
  * Delete Forecast Needle Movers
  */
  'deleteNeedleMovers/fulfilled'(state: EntitiesState, {
    payload: {
      needle_movers,
      forecast_id,
      forecast_month_id,
      forecast_channel_id
    }
  }: { payload: {
    needle_movers: ForecastNeedleMover[],
    forecast_id: number;
    forecast_month_id: number;
    forecast_channel_id?: number | null
  } }): void {
    try {
      const currentNeedleMovers = getCurrentNeedleMovers(state, forecast_id, forecast_month_id, forecast_channel_id);
      const removeIds = new Set(needle_movers.map(({ bucket: { id: bucketId } }: ForecastNeedleMover) => bucketId));
      const updatedNeedleMovers = currentNeedleMovers.filter(({ bucket: { id: bucketId } }) => !removeIds.has(bucketId));

      updateForecastChannelOrMonth({
        state,
        forecast_id,
        forecast_channel_id,
        forecast_month_id,
        update: {
          needle_movers: updatedNeedleMovers,
        }
      });
    } catch (e) {
      console.error('Failed to remove forecast needle movers with payload: ', {
        needle_movers,
        forecast_id,
        forecast_month_id,
        forecast_channel_id
      }, e);
    }
  }
};
