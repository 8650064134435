import React, { useState } from 'react';
import { Container, PageLayout, Grid, EmptyState, EditIcon , theme, Typography } from '@sprnova/nebula';
import { Indicator } from 'api/crudGraphQL/scoreboards/types';
import KPIChart from './KPIChart';
import { Scorecard } from './Scorecard';

type ScorecardsTabsProps = {
  data: Indicator[];
  type: string;
  lastUpdated?: string | null;
  isClient: boolean;
  toggleEditKPIsDrawer?: () => void;
};

const scrollbarColor = theme.variables.colors.neutrals.ghost[600];

export const ScorecardsTabs = ({ data = [], type, lastUpdated, isClient = false, toggleEditKPIsDrawer }: ScorecardsTabsProps): JSX.Element => {
  const [activeContent, setActiveContent] = useState(0);

  const handleScorecardClick = (index: number): void => {
    setActiveContent(index);
  };

  if (!data || data?.length === 0) {
    const description = type === 'All KPIs'
      ? 'There are no KPIs for this client.'
      : `There are no ${type.toLowerCase()} KPIs for this client.`;
    return (
      <EmptyState
        size='large'
        title='Nothing to see here'
        description={description}
        buttonProps={isClient ? { sx: { display: 'none' } } : {
          children: <div
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography fontSize='14px' fontWeight='600'>Edit KPIs</Typography>
          </div>,
          startIcon: <EditIcon />,
          onClick: (): void => {
            toggleEditKPIsDrawer?.();
          }
        } }
        margin={'100px'}
      />
    );
  }

  return (
    <Container disableHorizontalPadding hasVerticalPadding>
      <PageLayout
        sidebar={
          <PageLayout.Sidebar
            sx={{
              border: 'none',
              boxShadow: 'none',
              width: 'auto',
              height: '560px',
              overflowY: 'scroll',
              overflowX: 'hidden',
              padding: '0 16px',
              zIndex: 1,
              '&::-webkit-scrollbar': {
                width: '6px',
                backgroundColor: scrollbarColor,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '10px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f5f5f5',
              },
            }}
          >
            {data.map((content, index) => (
              <Grid
                key={`ScorecardsTabs-${content.name}`}
                sx={{
                  padding: '6px 0',
                }}
                onClick={(): void => handleScorecardClick(index)}
                style={{ cursor: 'pointer' }}
              >
                <div
                  style={{
                    border: activeContent === index ? '2px solid #4A1FFF' : 'none',
                    borderRadius: '10px',
                  }}
                >
                  <Scorecard data={content} isClient={isClient} />
                </div>
              </Grid>
            ))}
          </PageLayout.Sidebar>
        }
      >
        <KPIChart indicatorData={data[activeContent]} lastUpdated={lastUpdated}/>
      </PageLayout>
    </Container>
  );
};
