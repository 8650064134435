import React, { memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { CustomerInsightType } from 'api/crudGraphQL/customer_insights/types';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Scatter,
  ResponsiveContainer,
  Cell,
  LabelList,
  TooltipProps,
  LabelProps
} from 'recharts';

type BulletChartProps = {
  data: any[];
}

type InputType = {
  id: number;
  label: string;
  format: string;
  definition: string;
  display: string;
  ltv:number;
  percentage: number;
  benchmark_percentage: number;
  ltv_benchmark: number;
  customer_insight: CustomerInsightType;
  created_at: string;
  updated_at: string;
}

type StatusTabType = {
  status: 'on track' | 'above' | 'below';
  color?: string;
}

type DataStructure = {
  name: string;
  value: number;
  format: string;
  xValue: string;
  benchmark: number;
  status: 'on track' | 'above' | 'below';
  color?: string;
}

export const BulletChart = ({ data }: BulletChartProps): JSX.Element => {
  //calculate and setup state and color for each insight statistics value
  const setStatus = useCallback((value: number, benchmark: number): StatusTabType => {
    if (Math.abs(value - benchmark) <= 5) {
      return {status: 'on track', color: '#9ACFED'};
    } else if (value - benchmark > 5) {
      return {status: 'above', color: '#A6D7A8'};
    } else {
      return {status: 'below', color: '#F8C49A'};
    }
  }, []);

  //convert data structure
  const convertDataStructure = useMemo(() => (data: Array<InputType>): Array<DataStructure> => {
    return data.map(item => {
      const value = item.percentage || item.ltv;
      const format = item.format;
      const xValue = item.percentage ? `${format} ${value}` : `${value} ${format}`;
      const benchmark = item.benchmark_percentage || item.ltv_benchmark;
      return {
        name: item.display || item.label,
        value: value,
        format: format || '',
        xValue: xValue,
        benchmark: benchmark,
        status: setStatus(value, benchmark).status,
        color: setStatus(value, benchmark).color,
      };
    });
  }, [setStatus]);

  const formattedData = useMemo(() => convertDataStructure(data), [data]);

  const Line = memo(({ ...props }: any) => {
      return (
        <rect
          fill='#402C2C2C'
          width={4}
          height={30}
          x={props.x}
          y={props.y - props.height - 3.9}
        />
      );
  });

  const formatNumber = useCallback((value?: number, sign?: string) => {
    switch (sign) {
      case '$':
        return `${sign}${value}`;
      case '#':
        return `${value} yrs`;
      case '%':
        return `${value}${sign}`;
      default:
        return undefined
    }
  }, []);

  const renderLabelContent = useCallback((props: LabelProps) => {
    const { x = 0, y = 0, value, width = 0 } = props ?? {};
    const { format, value: dataValue } = formattedData.find((item) => item.value === value) ?? {};
    const label = formatNumber(dataValue, format);
    const labelXPosition = x + width + 10;
    const barHeight = 30;

    return (
      <g>
        <text x={labelXPosition} y={y - barHeight / 2 - 3}
          style={{
            textAnchor: 'start',
            fontSize: '12px',
            fill: '#2C2C2C',
            alignmentBaseline: 'middle',
            fontWeight: 600
          }}
        >
          {label}
        </text>
      </g>
    );
  }, [formattedData]);

  const Legend = memo(() => {
    const legendItems = [
      { label: 'Above', color: '#A6D7A8' },
      { label: 'On Track', color: '#9ACFED' },
      { label: 'Below', color: '#F8C49A' },
      { label: 'Industry Benchmark', color: '#402C2C2C' },
    ];

    return (
      <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
        <span
          style={{
            textAlign: 'center',
            margin: '20px 0 12px 0',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '20px'
          }}
        >
        {data.length >= 10 ? <div>Top 10 Insights</div> : <>Top Insights</>}
        </span>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {legendItems.map((item, index) => (
            <div key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '0 8px'
              }}
            >
            <div
              style={{
                backgroundColor: item.color,
                height: index === 3 ? '4px' : '16px',
                width: '16px',
                borderRadius: '2px',
                marginRight: '8px'}}>
            </div>
            <span
              style={{
                fontFamily: 'Inter',
                fontSize: '12px',
                fontWeight: 600,
                lineHeight: '15px'
              }}
            >
              {item.label}
            </span>
          </div>
          ))}
        </div>
      </div>
    );
  });

  const TooltipContainer = styled.div`
    background-color: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  `;

  const TooltipLabel = styled.p`
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: #EFEFEF;
    padding: 8px 24px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  `;

  const TooltipValue = styled.p`
    font-size: 14px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 0px 24px;
  `;

  const TooltipBenchmark = styled.p`
    font-size: 14px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 0px 24px 16px 24px;
  `;

  const CustomTooltip = memo(({ active, payload, label }: TooltipProps) => {
    if (active && Array.isArray(payload) && payload.length) {
      const { value } = payload[0].payload ?? {};
      const { benchmark } = payload[1].payload ?? {};
      const { format } = payload[0].payload ?? {};
      const displayValue = formatNumber(value, format);
      const displayBenchmark = formatNumber(benchmark, format);
      return (
        <TooltipContainer>
          <TooltipLabel className="label">{`${label}`}</TooltipLabel>
          <TooltipValue className="value">{`Value: ${displayValue}`}</TooltipValue>
          <TooltipBenchmark className="benchmark">{`Benchmark: ${displayBenchmark}`}</TooltipBenchmark>
        </TooltipContainer>
      );
    }
    return null;
  });

  return (
    <>
      <Legend />
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          layout="vertical"
          width={500}
          height={400}
          data={formattedData}
          margin={{
            top: 20,
            right: 60,
            bottom: 20,
            left: 30,
          }}
        >
          <CartesianGrid stroke="#D9D9D9" horizontal={false} />
          <XAxis type="number" tickFormatter={(value) => formattedData?.[0]?.format === '%' ? `${value}${formattedData?.[0]?.format}` : `${formattedData?.[0]?.format}${value}`}
            tick={{
              style: {
                fontFamily: 'Inter',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '14px',
                fill: '#2C2C2C',
                paddingTop: '50px'
              },
            }}
            tickLine={false}
            axisLine={{
              stroke: 'none',
            }}
          />
          <YAxis
            dataKey="name"
            type="category"
            scale="band"
            tickLine={false}
            dx={-220}
            dy={25}
            width={200}
            tick={{
              style: {
                fontFamily: 'Inter',
                fontSize: '12px',
                fontWeight: 600,
                lineHeight: '14px',
                fill: '#2C2C2C',
                textAnchor: 'start'
              },
            }}
            axisLine={{
              stroke: '#D9D9D9',
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="value" barSize={30}>
            {
              formattedData?.map((data, index) => (
                <Cell key={index} fill={data.color} />
              ))
            }
          </Bar>
          <Scatter dataKey="benchmark" shape={<Line />}/>
          <Bar dataKey="value" barSize={0}>
            <LabelList dataKey="value" position="center" content={renderLabelContent} />
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

