import React, { useState, useEffect, useRef, FC } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import { Button } from 'components/Button';
import Cookies from 'js-cookie';
import css from './DragUpload.module.scss';

const { Dragger } = Upload;

type DragUploadProps = {
  id?: number;
  mode?: string;
  logo?: string;
  operations?: string;
  map?: string;
  version?: string;
  // customRequest?: Function;
};

export const DragUpload: FC<DragUploadProps> = ({
  // customRequest,
  logo,
  operations,
  map,
  version,
}) => {
  const [uploadedFile, setUploadedFile] = useState('');
  const [hasLogo, setHasLogo] = useState(!!logo);

  // console.log('DragUpload props.logo', logo);

  useEffect(() => {
    setHasLogo(!!logo);
  }, [logo]);

  const dragRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const div = dragRef.current;
    if (div) {
      div.addEventListener('dragover', () => {
        setHasLogo(false);
      });
      // div.addEventListener("dragexit", () => {
      //   setHasLogo(true);
      // });
      return (): void => {
        div.removeEventListener('dragover', () => {});
        // div.removeEventListener("dragexit", () => {});
      };
    }
  }, []);

  const handleChange = async (e: any): Promise<void> => {
    const { status } = e.file;
    // if (status !== "uploading") {
    //   console.log(e, e.file, e.fileList);
    // }
    if (status === 'done') {
      message.success(`${e.file.name} file uploaded successfully.`, e.file);
      if (e.file.response.data) {
        setUploadedFile(e.file.response.data.uploadClientLogo?.logo);
      }
      setHasLogo(true);
    } else if (status === 'error') {
      message.error(`${e.file.name} file upload failed.`);
    }
  };

  const versionConfig: { [x: string]: {} } = {
    v1: {
      Authorization: `Bearer ${import.meta.env.REACT_APP_PLATFORM_API_TOKEN || ''}`,
    },
    v2: {
      authorization: Cookies.get('BEARER-TOKEN') ? `Bearer ${Cookies.get('BEARER-TOKEN')}` : '',
    },
  };

  const urlParams = new URLSearchParams(window.location.search);
  const signatureQueryParam = urlParams.get('signature');
  const entityIdQueryParam = urlParams.get('entityId');
  const signatureCookie = Cookies.get('signature');
  const entityIdCookie = Cookies.get('entityId');

  let authorization;
  if (entityIdQueryParam && signatureQueryParam) {
    authorization = `Bearer ${entityIdQueryParam}-${signatureQueryParam}`;
  } else if (entityIdCookie && signatureCookie) {
    authorization = `Bearer ${entityIdCookie}-${signatureCookie}`;
  } else {
    authorization = undefined;
  }

  const xsrfToken = Cookies.get('XSRF-TOKEN');
  const publicAuth =
    authorization && xsrfToken
      ? {
        Authorization: authorization,
        'X-XSRF-TOKEN': xsrfToken,
      }
      : undefined;

  return (
    <div className={css.root}>
      <Button
        inline
        onClick={() => setHasLogo(!hasLogo)}
        className={css.button}
      >
        {hasLogo ? 'Upload logo' : 'Cancel'}
      </Button>
      <div className={css.dragArea} ref={dragRef}>
        {hasLogo ? (
          <div className={css.logo}>
            <img alt="" src={uploadedFile || logo} />
          </div>
        ) : (
          <Dragger
            name="file"
            multiple={false}
            showUploadList={false}
            accept=".png,.jpg,.jpeg,.gif,.svg"
            action={
              version === 'public'
                ? import.meta.env.REACT_APP_PROSPECT_API_HOST || ''
                : import.meta.env.REACT_APP_PLATFORM_API_HOST || ''
            }
            method="post"
            //import.meta.env.REACT_APP_PLATFORM_API_VERSION
            // customRequest={customRequest ? () => customRequest() : () => {}}
            onChange={(e) => handleChange(e)}
            headers={
              version === 'public'
                ? publicAuth
                : versionConfig[
                  import.meta.env.REACT_APP_PLATFORM_API_VERSION || 'v1'
                ]
            }
            data={{
              operations,
              map,
            }}
          >
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload a single image
            </p>
            <p className="ant-upload-hint">
              Support for .jpg, .jpeg, .png, .gif, and .svg
            </p>
          </Dragger>
        )}
      </div>
    </div>
  );
};

export const LogoUpload = ({ id, logo, mode, version }: DragUploadProps): JSX.Element => {
  const operations = `{"query":"mutation ($file: Upload!) { uploadClientLogo(client_id: ${id}, file: $file) { id, logo } }", "variables": { "file": null }}`;
  const map = '{"file": ["variables.file"]}';
  return (
    <DragUpload
      logo={logo}
      operations={operations}
      map={map}
      version={version}
      // customRequest={() => {}}
    />
  );
};
