import { getClient } from 'api/entityGraphQL/utils';
import { convertToBase64 } from 'api/utils';
import { QuestionHandler } from '../actions';

export const textareaQuestionHandler: QuestionHandler = (id, key, value, additionalInfo, topics = [], topicId) => {
  // React-Quill (TextEditor component) preserves empty html tags even when value is empty
  // if value is only HTML tags (with or without whitespace) and doesn't include an <img> tag, then pass an empty string
  let sanitized = value;
  if (typeof sanitized === 'string' && sanitized.indexOf('<img') === -1 && sanitized.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
    sanitized = '';
  }

  // special handling for question keys that begin with 'topic_notes'
  if (key?.indexOf('topic_notes') !== -1) {
    return getClient().updateDiscoveryTopic({
      discovery_id: additionalInfo?.discoveryId as number,
      topic_id: topicId as number,
      notes: (typeof sanitized === 'string' ? convertToBase64(sanitized) : sanitized) as any
    });
  } else if (key?.indexOf('topic_notes') === -1 && sanitized === '') {
    return getClient().deleteDiscoveryQuestion({
      discovery_id: additionalInfo?.discoveryId as number,
      question_id: id as number,
    });
  } else {
    return getClient().updateDiscoveryQuestion({
      discovery_id: additionalInfo?.discoveryId as number,
      question_id: id as number,
      answer: (typeof sanitized === 'string' ? convertToBase64(sanitized) : sanitized) as any
    });
  }

};
