/**
 * useGoals
 * Fetches all goal types
 */

import { useState, useEffect } from 'react';
import { novaGraphQLClient, Projection } from 'api/entityGraphQL';
import { Goal } from 'features/entitiesRedux';

type Type = {
  loading: boolean;
  error: string;
  goals: Goal[];
};

type UseGoalsArgs = { projection?: Projection };
export const useGoals = ({
  projection: defaultProjection,
}: UseGoalsArgs = {}): Type => {
  const [goals, setGoals] = useState<Goal[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [projection /* , setProjection */] = useState(defaultProjection);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      setLoading(true);
      try {
        const { goals } = await novaGraphQLClient.fetchGoals({ projection });
        if (!isCancelled) {
          setError('');
          setGoals(goals);
          setLoading(false);
        }
      } catch (error) {
        if (!isCancelled) {
          setLoading(false);
          setError(error?.response?.errors?.[0]?.message || 'Unknown error');
        }
      }
    })();

    return () => {
      isCancelled = true;
    };

  }, [projection]);

  return { goals, loading, error };
};

export default useGoals;
