/**
 * Entities -> Tasks -> Actions
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { novaGraphQLClient, Projection } from 'api/entityGraphQL';
import { normalizeEntities } from 'features/entitiesRedux/utils';
import { FetchTasksFilter, TaskFormValues } from './task';

/** Fetch Many */
export const fetchTasks = createAsyncThunk(
  'entities/fetchTasks',
  async (config: {
    projection?: Projection,
    pricing_version?: string,
    filter?: FetchTasksFilter;
  }): Promise<any> => {
    const { tasks } = await novaGraphQLClient.fetchTasks(config);
    return { ...normalizeEntities({ tasks }) };
  }
);

/** Fetch one */
export const fetchTaskById = createAsyncThunk(
  'entities/fetchTaskById',
  async ({ id, ...config }: { id: number; projection?: Projection }) => {
    const { tasks } = await novaGraphQLClient.fetchTaskById(id, {
      ...config,
    });
    return { ...normalizeEntities({ tasks }) };
  }
);

/** Create */
export const createTask = createAsyncThunk(
  'entities/createTask',
  async (values: Partial<TaskFormValues>) => {
    const response = await novaGraphQLClient.createTask(values);
    return { ...normalizeEntities(response) };
  }
);

/* Update */
export const updateTask = createAsyncThunk(
  'entities/updateTask',
  async (values: Partial<TaskFormValues>) => {
    const response = await novaGraphQLClient.updateTask(values);
    return { ...normalizeEntities(response) };
  }
);

/** Delete */
export const deleteTask = createAsyncThunk(
  'entities/deleteTask',
  async (id: number) => {
    try {
      await novaGraphQLClient.deleteTask(id);
      return id;
    } catch (error) {
      console.error('Error deleting task', error);
      return { ...normalizeEntities({}) };
    }
  }
);
