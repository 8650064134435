/**
 * StrategyForm -> RecommendedServices (audit rule tasks)
 */

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UnorderedListOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import classNames from 'classnames';
import { intersectionBy, uniqBy } from 'lodash';
import { Alert, Button, CheckboxGroup } from '../../../../../../components';
import { Props as CheckboxGroupProps } from '../../../../../../components/CheckboxGroup/CheckboxGroup';
import { getDepartmentStatus } from '../../../../../audits/utils';
import { Audit, Department, Task } from '../../../../../entitiesRedux';
import { RuleTooltip, SelectAnalystSurveyDepartment, TaskItem } from './components';
import css from './RecommendedServices.module.scss';

interface RecommendedServicesProps extends CheckboxGroupProps {
  value?: string[];
  onChange?: (checkedValue: CheckboxValueType[]) => void;
  recommendedServices: Task[];
  audit: Audit;
  showCheckboxes?: boolean;
  inModal?: boolean;
  onCloseModal?: () => void;
  defaultDepartmentId?: number | null;
  newBlueprint?: boolean;
}

const RecommendedServices = ({
  value,
  onChange,
  recommendedServices,
  audit,
  showCheckboxes = true,
  inModal = false,
  onCloseModal,
  defaultDepartmentId,
  newBlueprint,
  ...props
}: RecommendedServicesProps): JSX.Element => {
  const [activeDepartmentId, setActiveDepartmentId] = useState<number | null>(null);
  const existingTasks = intersectionBy(audit?.strategy?.tasks, recommendedServices, 'id');
  const existingTaskIds = existingTasks?.length ? existingTasks?.map((task: {id: number}) => task?.id) : [];

  /** If defaultDepartmentId exists then filter the recommended services */
  useEffect(() => {
    if (defaultDepartmentId) setActiveDepartmentId(defaultDepartmentId);
  }, [defaultDepartmentId]);

  const getDepartmentTasks = (tasks: Task[], departmentId: number) => {
    return tasks?.filter(task => task?.service?.department?.id === departmentId);
  };

  const renderHeader = () => {
    return (
      <div className={css.header}>
        <div className={classNames(css.column__heading, css.column__task)}>
          Task
        </div>
        <div className={classNames(css.column__heading, css.column__service)}>
          Service
        </div>
        <div className={classNames(css.column__heading, css.column__rule)}>
          Rules
        </div>
      </div>
    );
  };

  const renderDepartmentAlert = (departmentName: string) => {
    return (
      <Alert
        className={css.statusWarning}
        type='warning'
        showIcon
        message={
          <>
            <span>Please complete the {departmentName} section of the</span>
            <Link to={`/appraisals/${audit?.id}/survey`}>
              <Button type="link" onClick={() => inModal && onCloseModal ? onCloseModal() : null}>
                Analyst Survey
              </Button>
            </Link>
            <span>before adding tasks.</span>
          </>
        }
      />
    );
  };

  const departments = uniqBy(recommendedServices?.map(task => {
    return {
      id: task?.service?.department?.id,
      name: task?.service?.department?.name
    };
  }), 'id');

  const tasksByDepartment = departments?.map((department) => {
    const currentDepartment = audit?.discovery?.departments?.filter(dept => dept?.id === department?.id)?.[0];
    const currentDepartmentStatus = getDepartmentStatus(currentDepartment);
    return (
      <div className={css.departmentBlock} key={department?.id}>
        <div className={classNames(css.row, css.row__department)}>
          <div className={css.column__task}>
            <p className={css.departmentName}>{department?.name}</p>
            {currentDepartmentStatus !== 'complete' ?
              renderDepartmentAlert(department?.name)
              : <></>
            }
          </div>
        </div>
        {uniqBy(getDepartmentTasks(recommendedServices, department?.id), 'id').map((task, i) => {
          const existsInBlueprint = existingTaskIds?.indexOf(task?.id) > -1;
          return (
            <div className={css.row} key={`${task?.name}-row-${i}`}>
              <div className={css.column__task}>
                {showCheckboxes ?
                  <TaskItem
                    task={task}
                    departmentStatus={currentDepartmentStatus}
                    existsInBlueprint={existsInBlueprint}
                    newBlueprint={newBlueprint}
                  />
                  : task?.name
                }
              </div>
              <div className={css.column__service}>
                <span>{task?.service?.name}</span>
              </div>
              <div className={css.column__rule}>
                <RuleTooltip audit={audit} taskId={task?.id} />
              </div>
            </div>
          );
        })}
      </div>
    );
  });

  const handleValuesChange = (checkedValues: CheckboxValueType[]) => {
    if (onChange) {
      onChange(checkedValues);
    }
  };

  const handleDepartmentChange = (departmentId: number) => {
    setActiveDepartmentId(departmentId);
  };

  const activeDepartmentTasks = tasksByDepartment?.filter(dept => dept?.key === String(activeDepartmentId))?.[0];
  const renderServices = () => {
    // default to showing all recommended services for all departments
    if (!activeDepartmentId) {
      if (tasksByDepartment?.length) {
        return tasksByDepartment;
      } else {
        return (
          <Empty
            image={<UnorderedListOutlined />}
            description={<span>No recommended services found</span>}
          />
        );
      }
    }

    // if a department is selected...
    if (activeDepartmentId) {
      // if no recommended tasks exist, show empty state
      if (!activeDepartmentTasks) {
        return (
          <Empty
            image={<UnorderedListOutlined />}
            description={<span>No recommended services found for this channel</span>}
          />
        );
      } else {
        return activeDepartmentTasks;
      }
    }
  };

  const surveyDepartments = audit?.discovery?.departments?.filter((department: Department) => {
    return department?.in_survey === 'yes';
  });

  return (
    <div className={css.root}>
      {inModal ?
        <SelectAnalystSurveyDepartment
          departments={surveyDepartments}
          onDepartmentChange={handleDepartmentChange}
          onDepartmentClear={() => setActiveDepartmentId(null)}
          defaultDepartmentId={defaultDepartmentId}
        /> : <></>
      }
      <CheckboxGroup
        {...props}
        onChange={handleValuesChange}
        value={value}
      >
        {tasksByDepartment?.length ? renderHeader() : <></>}
        {renderServices()}
      </CheckboxGroup>
    </div>
  );
};

RecommendedServices.displayName = 'RecommendedServices';

export default RecommendedServices;
