/**
 * Format a number with thousands separators
 *
 * precision = desired number of decimal places
 * if no precision argument is provided then output will have 0 decimal places
 *
 * @param {number} input
 * @param {string} type
 * @param {number} precision
 * @param {boolean} noTrailingZeros
 * @return {string}
 */

export const formatNumber = (
  input?: number,
  type?: string,
  precision?: number,
  noTrailingZeros?: boolean,
  decimalToPercent?: boolean
): string => {
  // if not a number then provide a fallback
  if (typeof input !== 'number' || isNaN(input)) return '-';

  // convert from decimal to percent
  if (decimalToPercent) input = input * 100;

  // convert to a string with decimal places and thousands separators
  let output = input.toFixed(precision || 0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // remove trailing zeros
  if (noTrailingZeros) output = output.replace(/\.?0+$/, '');

  // format output based on provided argument for 'type'
  switch(type) {
    case 'percent':
      return `${output}%`;
    case 'currency':
      return `$${output}`;
    default:
      return output;
  }
};
