import { getClient } from 'api/entityGraphQL/utils';
import { Department } from '../../department';
import { QuestionHandler } from '../actions';

export const departmentNotesHandler: QuestionHandler = (id, key, value, additionalInfo): any => {
  const apiClient = getClient();
  if (value) {
    const _departments = Object.values(value)
      .map((department: Department) => {
        return {
          id: department.id,
          notes: department.notes || null,
          analyst_notes: department.analyst_notes || null,
        };
      })
      .filter((department) => department.id !== undefined);

    return apiClient.saveDiscoveryDepartments({
      discovery_id: additionalInfo?.discoveryId as number,
      departments: _departments,
      delete_missing: 'no',
    });
  }
};
