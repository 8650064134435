import { createAsyncThunk } from '@reduxjs/toolkit';
import { novaGraphQLClient, Projection } from 'api/entityGraphQL';
import { normalizeEntities } from 'features/entitiesRedux/utils';
import { YesNo } from '../types';
import { Churn, Field, Pace, Rapport } from '.';

type FieldArgs = {
  id?: number;
  name?: string;
  slug?: string;
  color?: string;
  is_active?: YesNo;
  sort?: string;
}
/**
 * Fetch churns
 */
export const fetchChurns = createAsyncThunk(
  'entities/fetchChurns',
  async (config: {
    projection?: Projection;
  }): Promise<any> => {
    const { dashboard_churns } = await novaGraphQLClient.fetchChurns(config);
    return { ...normalizeEntities({ dashboard_churns }) };
  }
);

/**
 * Fetch rapports
 */
export const fetchRapports = createAsyncThunk(
  'entities/fetchRapports',
  async (config: {
    projection?: Projection;
  }): Promise<any> => {
    const { dashboard_rapports } = await novaGraphQLClient.fetchRapports(config);
    return { ...normalizeEntities({ dashboard_rapports }) };
  }
);

/**
 * Fetch paces
 */
export const fetchPaces = createAsyncThunk(
  'entities/fetchPaces',
  async (config: {
    projection?: Projection;
  }): Promise<any> => {
    const { dashboard_paces } = await novaGraphQLClient.fetchPaces(config);
    return { ...normalizeEntities({ dashboard_paces }) };
  }
);

/**
 * Delete churn
 */
export const deleteChurn = createAsyncThunk(
  'entities/deleteChurn',
  async ({ id }: { id: number }) => {
    try {
      await novaGraphQLClient.deleteDashboardChurn({ id });
      return id;
    } catch (error) {
      console.error('Error deleting churn', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/**
 * Delete rapport
 */
export const deleteRapport = createAsyncThunk(
  'entities/deleteRapport',
  async ({ id }: { id: number }) => {
    try {
      await novaGraphQLClient.deleteDashboardRapport({ id });
      return id;
    } catch (error) {
      console.error('Error deleting rapport', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/**
 * Delete pace
 */
export const deletePace = createAsyncThunk(
  'entities/deletePace',
  async ({ id }: { id: number }) => {
    try {
      await novaGraphQLClient.deleteDashboardPace({ id });
      return id;
    } catch (error) {
      console.error('Error deleting pace', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/**
 * Delete lead sources
 */
// export const deleteLeadSource = createAsyncThunk(
//   'entities/deleteLeadSource',
//   async ({ id }: { id: number }) => {
//     try {
//       await novaGraphQLClient.deleteLeadSource({ id });
//       return id;
//     } catch (error) {
//       console.error('Error deleting pace', error);
//       return { ...normalizeEntities({}) };
//     }
//   }
// );

/**
 * Create churn
 */
export const createChurn = createAsyncThunk(
  'entities/createChurn',
  async ({ name, slug, color }: Partial<Field>) => {
    try {
      const { dashboard_churns } = await novaGraphQLClient.createDashboardChurn({  name, slug, color });
      return { ...normalizeEntities({ dashboard_churns }) };
    } catch (error) {
      console.error('Error deleting churn', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/**
 * Update churn
 */
export const updateChurn = createAsyncThunk(
  'entities/updateChurn',
  async ({ id, name, slug, color }: Partial<Field>) => {
    try {
      if (id) {
        const { dashboard_churns } = await novaGraphQLClient.updateDashboardChurn({ id, name, slug, color });
        return { ...normalizeEntities({ dashboard_churns }) };
      }
    } catch (error) {
      console.error('Error updating churn', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/**
 * Create rapport
 */
export const createRapport = createAsyncThunk(
  'entities/createRapport',
  async ({ name, slug, color }: Partial<Field>) => {
    try {
      const { dashboard_rapports } = await novaGraphQLClient.createDashboardRapport({ name, slug, color });
      return { ...normalizeEntities({ dashboard_rapports }) };
    } catch (error) {
      console.error('Error deleting rapport', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/**
 * Update rapport
 */
export const updateRapport = createAsyncThunk(
  'entities/updateRapport',
  async ({ id, name, slug, color }: Partial<Field>) => {
    try {
      if (id) {
        const { dashboard_rapports } = await novaGraphQLClient.updateDashboardRapport({ id, name, slug, color });
        return { ...normalizeEntities({ dashboard_rapports }) };
      }
    } catch (error) {
      console.error('Error updating rapport', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/**
 * Create pace
 */
export const createPace = createAsyncThunk(
  'entities/createPace',
  async ({ name, slug, color }: Partial<Field>) => {
    try {
      const { dashboard_paces } = await novaGraphQLClient.createDashboardPace({ name, slug, color });
      return { ...normalizeEntities({ dashboard_paces }) };
    } catch (error) {
      console.error('Error deleting pace', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/**
 * Update pace
 */
export const updatePace = createAsyncThunk(
  'entities/updatePace',
  async ({ id, name, slug, color }: Partial<Field>) => {
    try {
      if (id) {
        const { dashboard_paces } = await novaGraphQLClient.updateDashboardPace({ id, name, slug, color });
        return { ...normalizeEntities({ dashboard_paces }) };
      }
    } catch (error) {
      console.error('Error updating pace', error);
      return { ...normalizeEntities({}) };
    }
  }
);
