import { CompareValue } from '../CreativeAffinityOverviewPage';

export const formatImageUrl = (item: CompareValue): string | undefined => {
  const domain = item.first_asset?.domain_url;
  let url = item.first_asset?.url;
  const type = url?.split('.').pop()?.toLocaleLowerCase();
  /**
   * If the type is mp4 or single_video, we need to get the thumbnail
   * item.type is only available at the AD level
   */
  if (type === 'mp4' || item?.type === 'single_video') {
    url = item.first_asset?.thumbnail;
  } else {
    url = item.first_asset?.url;
  }
  if (!domain || !url) return undefined;
  return `${domain}/${url}`;
};
