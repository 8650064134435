
import { createEntityAdapter } from '@reduxjs/toolkit';
import { compareField } from '../../utils/compareField';
import { Rule} from './rule';

/**
 * Redux toolkit entity adapter for storing/caching entities by ID
 */
export const rulesAdapter = createEntityAdapter<Rule>({
  selectId: (rule) => rule.id,
  sortComparer: compareField('name'),
});
