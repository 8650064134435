import React, { memo, useState } from 'react';
import { EmptyImage } from 'components/nebula/EmptyImage/EmptyImage';
import { removeExtension } from '../../CreativeAffinity/utils/removeExtension';

interface ImageProps {
  url: string;
  skeletonWidth?: number;
  skeletonHeight?: number;
}

const ImageComponent: React.FC<ImageProps> = ({ url }) => {
  const [isError, setIsError] = useState(false);

  const handleError = (): void => {
    setIsError(true);
  };

  if (isError) {
    return <EmptyImage size='large' />;
  }
  // all images in s3 are jpg
  return <img src={`${removeExtension(url)}.jpg`} alt={`Image from ${url}`} width='100%' onError={handleError} />;
};

export default memo(ImageComponent);
