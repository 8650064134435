import React, { forwardRef } from 'react';
import { Switch as AntdSwitch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';
import classNames from 'classnames';
import { Spin } from '../Spin';
import css from './Switch.module.scss';

interface Props extends SwitchProps {
  className?: string;
  onChange?: (value: boolean) => void;
  value?: boolean;
  loading?: boolean;
}

const Switch = forwardRef(({ className, onChange, value, loading, ...props }: Props, ref: any) => {
  const handleOnChange = (value: boolean) => {
    if (onChange === undefined) { return; }
    if (value) {
      onChange(true);
    }
    if (!value) {
      onChange(false);
    }
  };

  // show animated spinner if loading === true
  if (loading) return <Spin loading={loading} />;

  return (
    <>
      <AntdSwitch
        {...props}
        onChange={handleOnChange}
        checked={value === true}
        className={classNames(className, css.root)}
        ref={ref}
      />
    </>
  );
});

Switch.displayName = 'Switch';

export default Switch;