import React from 'react';

export const AccountAssignmentIcon = () => (
  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="15" y="15" width="82" height="82" fill="#D9D9D9"/>
    <rect x="33" y="33" width="46" height="46" rx="8" fill="#E65957"/>
    <rect x="106.984" y="95.6719" width="16" height="129.643" rx="8" transform="rotate(135 106.984 95.6719)" fill="#4A1FFF"/>
    <circle cx="15" cy="15" r="8" fill="#958AE2"/>
    <circle cx="15" cy="97" r="8" fill="#FFA600"/>
    <circle cx="97" cy="15" r="8" fill="#FFA600"/>
    <circle cx="97" cy="97" r="8" fill="#958AE2"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M44.313 33L79 67.687V71C79 75.4183 75.4183 79 71 79H67.6856L33 44.3144V41C33 36.5817 36.5817 33 41 33H44.313Z" fill="#A94882"/>
  </svg>
);
