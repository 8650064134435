import React, { forwardRef } from "react";
import { Checkbox as AntdCheckbox } from "antd";
import { CheckboxProps } from "antd/lib/checkbox";
import classNames from "classnames";
import { SkeletonInput } from "../SkeletonInput";
import css from "./Checkbox.module.scss";

interface Props extends CheckboxProps {
  className?: string;
  skeleton?: boolean;
  checked?: boolean;
}

const Checkbox = forwardRef(
  ({ className, skeleton = false, ...props }: Props, ref: any) => {
    // Show skeleton when loading is true
    if (skeleton) return <SkeletonInput loading={false} active={true} />;

    return (
      <AntdCheckbox
        {...props}
        className={classNames(className, css.root)}
        ref={ref}
      />
    );
  }
);

export default Checkbox;
