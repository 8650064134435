import React, { FC, memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ActionMenu, PageLayout } from '@sprnova/nebula';
import { ExternalClient, ExternalUser } from 'features/entitiesRedux';
import Header from './components/Header';

export interface ExternalUserProfileSidebarProps {
  client: Pick<ExternalClient, 'id' | 'name' |'logo'>;
  route: string;
  externalUser: ExternalUser;
  setExternalUser?: (externalUser:ExternalUser)=> void;
}

const ExternalUserProfileSidebar: FC<ExternalUserProfileSidebarProps> = ({ client, route, externalUser }) => {
  const baseUrl = `/c/${client?.id}`;

  return (
    <PageLayout.Sidebar>
      <PageLayout.Sidebar.Card
        header={
          <Header 
            client={client} 
            externalUser={externalUser} 
            route={route}/>
        }
      >
        <ActionMenu>
          <ActionMenu.Item 
            isActive={route === 'profile'} 
            component={RouterLink} 
            to={`${baseUrl}/profile`}
          >
            My Profile
          </ActionMenu.Item>
          <ActionMenu.Item 
            isActive={route === 'company'} 
            component={RouterLink} 
            to={`${baseUrl}/company`}
          >
            Company Profile
          </ActionMenu.Item>
        </ActionMenu>
      </PageLayout.Sidebar.Card>
    </PageLayout.Sidebar>
  );
};

export default memo(ExternalUserProfileSidebar);



