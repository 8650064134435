/**
 * Intelligence -> Horizon -> Select Budget Scenario
 */

import React from 'react';
import Select, { Props as SelectProps } from 'components/Select/Select';
import { HorizonScenario } from 'features/entitiesRedux/models/horizon';
import css from './SelectBudgetScenario.module.scss';

interface Props extends SelectProps {
  scenarios?: HorizonScenario[];
  onSelect: (scenarioId: number) => void;
}

const SelectBudgetScenario = ({ scenarios, onSelect, ...props }: Props) => {
  return (
    <Select
      className={css.root}
      onSelect={onSelect}
      placeholder="Select a budget scenario"
      {...props}
    >
      {scenarios?.map(scenario => (
        <Select.Option value={scenario?.id} key={scenario?.id}>
          {scenario?.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectBudgetScenario;
