import React, { FC, useContext } from 'react';
import { PlusCircleOutlined, EditOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { Button as ButtonComponent, ButtonProps } from 'components/Button';
import { useTheme } from '../../../utils';
import { AddNotesContext } from '../context';
import css from './Button.module.scss';

type Props = ButtonProps & {
  iconAdd?: React.ReactNode;
  iconEdit?: React.ReactNode;
  showIcon?: boolean;
};

const Button: FC<Props> = ({
  children,
  iconAdd = <PlusCircleOutlined />,
  iconEdit = <EditOutlined />,
  ...props
}) => {
  const { hasNotes, onEdit } = useContext(AddNotesContext);

  const label = hasNotes ? 'Edit Notes' : 'Add Notes';
  const icon = hasNotes ? iconEdit : iconAdd;
  const { isTalent } = useTheme();
  return (
    <ButtonComponent
      type="primary"
      icon={icon}
      {...props}
      onClick={(): void => {
        onEdit();
      }}
      className={classNames(css.root, { [css.talent]: isTalent }, 'add-notes-button')}
    >
      {children || label}
    </ButtonComponent>
  );
};

export { Button };
