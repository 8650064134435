import React, { Dispatch, memo, SetStateAction, useCallback } from 'react';
import { Drawer } from '@sprnova/nebula';
import NovaGpt from '../NovaGpt/NovaGpt';
import css from './NovaGptDrawer.module.scss';

type NovaGptDrawerProps = {
    drawerOpen: boolean;
    setDrawerOpen: (open: boolean) => void;
    bannerPrompt?: string;
    setBannerPrompt?: Dispatch<SetStateAction<string>>;
    isFromBanner?: boolean;
    setIsFromBanner?: Dispatch<SetStateAction<boolean>>;
  }

const NovaGptDrawer = ({drawerOpen, setDrawerOpen, bannerPrompt, setBannerPrompt, isFromBanner, setIsFromBanner}: NovaGptDrawerProps): JSX.Element => {

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
    // Display intercom button again when drawer is closed
    if ((window as Window).Intercom) {
      (window as Window).Intercom('update', {
        'hide_default_launcher': false
      });
    }
  },[setDrawerOpen]);

  return (
    <Drawer
      id='novaGPT-drawer'
      className={css.root}
      open={drawerOpen}
      onClose={closeDrawer}
      keepMounted
      disablePadding
      minWidth={'65%'}
    >
      <NovaGpt bannerPrompt={bannerPrompt} setBannerPrompt={setBannerPrompt} isFromBanner={isFromBanner} setIsFromBanner={setIsFromBanner} isDrawer drawerOpen={drawerOpen} closeDrawer={closeDrawer}/>
    </Drawer>
  );
};

export default memo(NovaGptDrawer);
