/**
 * Polls a function `request` at a specified interval until a condition is met or canceled.
 *
 * @example
 *   const { promise, cancel } = shortPoll<T>(
 *     () => fetch('https://powerdigital.com/'),
 *     (response: T) => response.status !== 'in_progress',
 *     { interval: 1000 }
 *   );
 *
 *   promise.then((res) => doSomething(res));
 *
 * @template T The type of data expected from the `request` function.
 *
 * @param request - A function that returns a Promise resolving to the data of type `T`.
 * @param shouldStop - A function that determines whether to stop polling based on the response data of type `T`.
 *        Returns `true` to stop polling, `false` to continue polling.
 * @param options - An optional configuration object.
 * @param options.interval - The interval in milliseconds between polls. Defaults to 5000.
 *
 * @returns An object with two properties:
 *   - `promise`: A Promise that resolves to the data of type `T` when the `shouldStop` condition is met,
 *            or rejects with an error if polling is canceled or an error occurs during a request.
 *   - `cancel`: A function that can be called to cancel the polling process.
 */
const shortPoll = <T>(
  request: () => Promise<T>,
  shouldStop: (response: T) => boolean,
  options: {
    interval?: number;
  } = {},
): ShortPollingResult<T> => {
  const { interval } = { ...{ interval: 5000 }, ...options };

  let canceled = false;

  const cancel = (): void => {
    canceled = true;
  };

  const poll = async(
    resolve: (value: T | PromiseLike<T>) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reject: (reason?: any) => void,
  ): Promise<void> => {

    if (canceled) {
      reject(new Error('Short polling canceled.'));
      return;
    }

    try {
      const response = await request();
      const shouldStopPolling = shouldStop(response);

      if (shouldStopPolling) {
        resolve(response);
      } else {
        // Sleep for a duration of interval ms.
        let timeoutId: number;
        await new Promise((resolve) => {
          timeoutId = window.setTimeout(resolve, interval);
        }).then(() => {
          clearTimeout(timeoutId);
        });
        // Resume polling.
        poll(resolve, reject);
      }
    } catch (err) {
      reject(err);
    }
  };

  return {
    promise: new Promise<T>(poll),
    cancel,
  };
};


export type ShortPollingResult<T> = {
  promise: Promise<T>;
  cancel: () => void;
}

export default shortPoll;
