import React, { FC } from 'react';
import { AppNav, Skeleton as S, styled } from '@sprnova/nebula';

const Skeleton = styled(S)(() => ({
  backgroundColor: 'rgba(255,255,255, 0.3)'
}));

const IconSkeleton: FC = () => (
  <Skeleton 
    width={15} 
    height={15} 
    variant="circular" 
  />
);

export type SidebarSkeletonProps = {
  /**
   * [
   *  [labelWidth, [itemWidth1, itemWidth2, ...]],
   *  [labelWidth, [itemWidth1, ...]]
   * ]
   */
  sections: [number, number[]][]
}

export const SidebarSkeleton: FC<SidebarSkeletonProps> = ({ sections }) => (
  <AppNav>
    {
      sections.map(([labelWidth, itemWidths], i) => (
        <AppNav.Section key={i} label={<Skeleton width={labelWidth} />}>
          {itemWidths.map((width, j) => (
            <AppNav.Item key={j} disabled icon={<IconSkeleton />}>
              <Skeleton width={width} />
            </AppNav.Item>
          ))}
        </AppNav.Section>
      ))
    }
  </AppNav>
);