import React, { forwardRef } from 'react';
import { Input as AntdInput } from 'antd';
import { InputProps as AntInputProps } from 'antd/lib/input';
import classNames from 'classnames';
import { SkeletonInput } from '../SkeletonInput';
import css from './Input.module.scss';

export interface InputProps extends AntInputProps {
  className?: string;
  skeleton?: boolean;
}

const Input = forwardRef(({ className, skeleton = false, ...props }: InputProps, ref: any) => {
  // Show skeleton when loading is true
  if (skeleton) return <SkeletonInput loading={false} active={true} />;

  return <AntdInput {...props} className={classNames(className, css.root)} ref={ref} />;
});

Input.displayName = 'Input';

export default Object.assign(Input, {
  Search: AntdInput.Search,
  Password: AntdInput.Password,
});
