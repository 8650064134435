/**
 * Entities -> Dashboards -> Adapter
 */

import { createEntityAdapter } from '@reduxjs/toolkit';
import { compareField } from '../../utils/compareField';
import { Dashboard } from './dashboard';

/**
 * Redux toolkit entity adapter for storing/caching entities by id
 */
export const dashboardsAdapter = createEntityAdapter<Dashboard>({
  selectId: (service) => service.id,
  sortComparer: compareField('name'),
});
