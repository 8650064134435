/**
 * Blueprints -> Lost Reason Modal -> Form Fields
 */

import React, { FC } from 'react';
import { FormInstance } from 'antd/lib/form';
import { FormItem, Select, Option, TextArea, DatePicker } from 'components';

const reasonTypeOptions = [
  {
    value: 'Budget To Small',
    label: 'Budget Too Small'
  },
  {
    value: 'Status Quo',
    label: 'Status Quo'
  },
  {
    value: 'Lost To Another Agency',
    label: 'Lost To Another Agency'
  },
  {
    value: 'Other',
    label: 'Other'
  },
];

type Props = {
  className?: string;
  form: FormInstance;
  autoFocusFields?: boolean;
}

const LostReasonFormFields: FC<Props> = ({ className, form, autoFocusFields = false }) => {
  return (
    <div className={className}>
      <FormItem
        name="lost_reason_type"
        label="Lost Deal Reason"
        rules={[{ required: true, message: 'Please select a lost reason' }]}
      >
        <Select placeholder="Choose reason" autoFocus={autoFocusFields}>
          {reasonTypeOptions.map(option => (
            <Option value={option.value} key={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.lost_reason_type !== currentValues.lost_reason_type
        }
      >
        {() => form.getFieldValue('lost_reason_type') === 'Other' ? (
          <FormItem
            label="Other Reason"
            name="detailed_reason"
            rules={[{ required: true, message: 'Please enter a reason' }]}
          >
            <TextArea autoFocus={autoFocusFields} />
          </FormItem>
        ) : null
        }
      </FormItem>
      <FormItem
        name="follow_up_date"
        label="Follow-up date"
        rules={[{ required: true, message: 'Please select a follow-up date' }]}
        marginBottomNone
      >
        <DatePicker format="MM/DD/YYYY" />
      </FormItem>
    </div>
  );
};

export default LostReasonFormFields;
