import { Resource } from '../Resource';
import { Role } from '../Role';
import { RoleConfig } from '../types';

const attrs = ['*'];
export const externalProviderVerification: RoleConfig = {
  [Role.externalProviderVerification]: {
    [Resource.beta]: {},
    [Resource.strategy]: {},
    [Resource.partner]: {},
    [Resource.department]: {},
    [Resource.service]: {},
    [Resource.task]: {},
    [Resource.rule]: {},
    [Resource.client]: {},
    [Resource.contact]: {},
    [Resource.cohorts]: {},
    [Resource.creativeAffinity]: {
      'read:any': attrs,
    },
    [Resource.customerInsights]: {},
    [Resource.audit]: {},
    [Resource.discoveryCall]: {},
    [Resource.analystSurvey]: {},
    [Resource.forecast]: {},
    [Resource.user]: {},
    [Resource.report]: {},
    [Resource.integration]: {},
    [Resource.talent]: {},
    [Resource.talentSurvey]: {},
    [Resource.recommendedServices]: {},
  },
};
