import { convertToBase64 } from '.';

/**
   * Takes 2 arguments: Form values and an array of (Form.Item) names that use TextEditor;
   * 
   * The specified values are then converted to base64 so that the WAF doesn't throw any errors.
   * 
   * Returns values object with specified converted values.
   * 
   * Meant to sit at the top of the onFinish handler:
   * const handleOnFinish = async (values) => {
   *  values = convertSpecifiedValuesToBase64(values, ['text']);
   *  ...
   * }
 */
export const convertSpecifiedValuesToBase64 = (values?: { [x: string]: any }, names?: string[]): { [x: string]: any } => {
  if (values) {
    names?.map((name) => {
      values[name] = values[name] ? convertToBase64(values?.[name]) : values[name];
    });
    return values;
  }
  return {};
};