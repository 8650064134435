import React, { memo, useMemo } from 'react';
import { ArrowDownIcon, ArrowUpIcon, SubtractIcon, Tag, Typography } from '@sprnova/nebula';

const PercentageTag = ({ comparedValues }: { comparedValues: [number, number] }): JSX.Element => {
  const change = comparedValues[0] - comparedValues[1];
  const percentageChange = comparedValues[1] > 0 ? (change / comparedValues[1]) * 100 : 0;

  const renderIcon = useMemo(() => {
    switch(true) {
      case percentageChange > 0:
        return <ArrowUpIcon fontSize='small'/>;
      case percentageChange === 0:
        return <SubtractIcon fontSize='small'/>;
      case percentageChange < 0:
        return <ArrowDownIcon fontSize='small'/>;
    }
  }, [percentageChange]);

  const renderTag = useMemo(() => {
    const color = percentageChange > 0 ? 'green' : percentageChange < 0 ? 'red' : 'grey';
    return (
      <Tag height='24px' color={color}>
        {renderIcon}
        {/* Round to one decimal */}
        <Typography variant='h6'>{Math.round(percentageChange * 10) / 10}%</Typography>
      </Tag>
    );
  }, [percentageChange, renderIcon]);

  return renderTag;
};

export default memo(PercentageTag);
