import React, { memo, useEffect, useState } from 'react';
import css from './ChallengeMessage.module.scss';

type ChallengeMessageProps = {
  message: string;
  htmlResponse: string;
  useOuterHtml?: boolean; // New prop to control whether to use the full HTML element or just the inner HTML
}

const ChallengeMessage = ({ message, htmlResponse, useOuterHtml = false }: ChallengeMessageProps): JSX.Element => {
  const [finalMessage, setFinalMessage] = useState<string>('');

  useEffect(() => {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlResponse, 'text/html');
      if (doc.body.firstElementChild) {
        const replacement = useOuterHtml
          ? doc.body.firstElementChild.outerHTML // Use the full HTML element
          : doc.body.firstElementChild.innerHTML; // Use only the inner content

        const updatedMessage = message.replace('ACTION', replacement);
        setFinalMessage(updatedMessage);
      } else {
        console.error('No valid HTML content found');
        setFinalMessage(message); // Fallback to original message
      }
    } catch (error) {
      console.error('Error parsing HTML:', error);
      setFinalMessage(message); // Fallback to original message
    }
  }, [message, htmlResponse, useOuterHtml]);

  return (
    <>
      <span dangerouslySetInnerHTML={{ __html: finalMessage }} className={css.root}/>
    </>
  );
};

export default memo(ChallengeMessage);
