/**
 * SurveyQuestion -> QuestionSlider
 */

import React, { FC, useCallback, useMemo, useState } from 'react';
import { SliderProps } from '@sprnova/nebula';
import classnames from 'classnames';
import { TalentSurveyBucket } from 'features/talent/types';
import { Slider } from '../../../Slider';
import css from './QuestionSlider.module.scss';

export type QuestionSliderProps = {
  question: TalentSurveyBucket;
  onChange: (value: number) => void;
  disabled: boolean;
}

export const QuestionSlider: FC<QuestionSliderProps> = ({ question, onChange, disabled }) => {
  const [localValue, setLocalValue] = useState(question.score);
  const hasValue = localValue !== null;

  const marks: SliderProps['marks']  = useMemo(() => Array.from(
    Array(question?.max + 1), (_, markValue) => ({ value: markValue })
  ), [question]);

  const handleOnChange = useCallback((_: Event, value: number | number[]) => {
    if (Array.isArray(value)) {
      throw new Error('Question expects a single value');
    }

    setLocalValue(value);

    return onChange(value);
  }, [onChange]);

  const min = parseInt(Object.keys(marks)[0]);
  const max = parseInt(Object.keys(marks)[Object.keys(marks).length - 1]);

  const sliderProps = useMemo(() => (
    {
      min,
      max,
      marks,
      onChange: handleOnChange,
      defaultValue: localValue,
    }
  ), [min, max, marks, handleOnChange, localValue]);

  const classNames = classnames({ [css.noValueSelected]: !hasValue });

  return (
    <Slider
      id={`question-slider-${question.id}`}
      {...sliderProps}
      className={classNames}
      disabled={disabled}
    />
  );
};
