import React, { memo } from 'react';
import { Box, PageSpinner } from '@sprnova/nebula';
;

type NovaGptPageSpinnerProps = {
  isLoading: boolean
}

const NovaGptPageSpinner = ({isLoading}: NovaGptPageSpinnerProps): JSX.Element => {

  const questionArray = [
    'Sending question to AI and translating your data into human speak...'
  ];

  return isLoading ? (
    <Box paddingBottom='16px' textAlign='center' width='100%' justifyContent='center' display='flex'>
      <PageSpinner label={questionArray}/>
    </Box>
  ): <></>;

};
export default memo(NovaGptPageSpinner);
