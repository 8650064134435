/* eslint-disable @typescript-eslint/no-explicit-any */

import { Projection } from 'api/entityGraphQL';

/**
 * Entities -> External_Clients -> Projections
 */

/** Default external client integration projection to only use data that is needed. */
export const defaultExternalClientIntegrationProjection: Projection = {
  accounts: {
    auth_method: true,
    account: {
      description: true,
      disclaimer: true,
      group: true,
      id: true,
      is_refreshable: true,
      max_refresh: true,
      name: true,
      slug: true,
    },
    meta_page_id: true,
    data_source_account_id: true,
    id: true,
    is_created_by_client: true,
    is_enabled: true,
    is_ingesting: true,
    progress: true,
    reauthenticate_url: true,
    url: true,
    value2: true,
  },
  id: true,
  integration_requests: {
    reminded_at: true,
    requests: {
      data_source_id: true,
      access_request_id: true,
      access_request_status: true,
      access_request_created_at: true,
      manual_access_request_id: true,
      manual_access_request_status: true,
      manual_access_request_created_at: true,
    }
  },
  name: true,
  logo: true,
  status: true,
  fivetran_connectors: {
    id: true,
    client_id: true,
    data_source_id: true,
    connector_id: true,
    connector_card_auth: true,
    schema_name: true,
    created_by: true,
    progress: true,
    data_source: {
      slug: true,
      name: true
    }
  },
  group_id: true,
  group_destination: true,
  onboarded_at: true
};


/** Default external client report projection to only use data that is needed. */
export const defaultExternalClientReportProjection: { [x: string]: any, id: true } = {
  id: true,
  name: true,
  website: true,
  logo: true,
  reports: {
    id: true,
    name: true,
    website: true,
    logo: true,
    business_type: {
      id: true,
      name: true,
    },
    client: {
      id: true,
      name: true,
      business_type: {
        id: true,
        name: true,
      },
    },
  },
};
