/**
 * Entities -> Tasks -> Reducers
 */

import { EntitiesState } from '../../types';
import { tasksAdapter } from './adapter';

export default {
  'deleteTask/fulfilled'(state: EntitiesState, action: { payload:  number }) {
    state.isLoading = false;
    tasksAdapter.removeOne(state.data.tasks, action.payload);
  }
};
