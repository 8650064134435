/**
* RTK Query -> Re-usable projections
 */

export const paginationProjection = {
  current_page: true,
  from: true,
  has_more_pages: true,
  last_page: true,
  per_page: true,
  to: true,
  total: true
};
