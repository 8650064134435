export const allAccountsProjection = {
  id: true,
  name: true,
  client: {
    id: true,
    name: true,
    logo: true,
    account_stage: {
      name: true,
      color: true,
    },
    business_type: {
      id: true,
      name: true,
    },
  }
};

export const myAccountsProjection = {
  id: true,
  name: true,
  client: {
    id: true,
    name: true,
    logo: true,
    account_stage: {
      name: true,
      color: true,
    },
    business_type: {
      id: true,
      name: true,
    },
  },
  my_role: true,
};

export const myAccountsExternalProjection = {
  id: true,
  name: true,
  updated_at: true,
  client: {
    logo: true,
  },
};

export const scoreboardsProjection = {
  id: true,
  name: true,
  refreshed_at: true,
  refresh_status: true,
  client: {
    id: true,
    name: true,
  },
  widgets: {
    content: true,
    id: true,
    is_active: true,
    name: true,
    period: true,
    position: true,
    row: true,
    settings: true,
    size: true,
    slug: true,
    title: true,
    type: true,
    updated_at: true,
    widget_id: true,
  }
};

export const widgetsProjection = {
  id: true,
  name: true,
};
