import React, { forwardRef } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import type { CardProps } from '@mui/material';
import { Card as MuiCard, CardHeader, CardContent, CardActions } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import classNames from 'classnames';
import css from './Card.module.scss';

/**
 * Cards are containers which contain content or other cards.
 * They’re used to group related information.
 */

export interface CardType extends CardProps {
  type?: 'navigational' | 'content' | 'container' | 'feature';
  title?: string;
  subheader?: string | JSX.Element;
  action?: JSX.Element;
  actionPosition?: 'topRight' | 'middleRight' | 'bottomRight' | 'none';
  icon?: JSX.Element | boolean;
  content?: JSX.Element | boolean;
  contentStyles?: Record<string, unknown>;
  contentClassName?: string;
  border?: boolean;
  background?: string;
  backgroundColor?: string;
  headerStyles?: Record<string, any>;
  headerClassName?: string;
  boxShadow?: string;
  overflow?: 'hidden' | 'visible' | 'auto';
  height?: string,
  width?: string,
}

export const Card = forwardRef(({
  type = 'content',
  title,
  subheader,
  action,
  actionPosition = 'none',
  icon = <ChevronRightIcon />,
  content = false,
  contentStyles,
  contentClassName,
  border,
  background,
  backgroundColor = '#FFF',
  headerClassName,
  headerStyles = { background: 'white' },
  overflow,
  height,
  width,
  ...props
}: CardType, ref: any): JSX.Element => {
  const actionInsetPosition = (): Record<string, string> => {
    const styles = {
      position: 'absolute',
      padding: 'inherit',
    };
    switch (actionPosition) {
      case 'topRight':
        return { ...styles, inset: '0 0 auto auto' };
      case 'middleRight':
        return { ...styles, inset: 'auto 0 auto auto' };
      case 'bottomRight':
        return { ...styles, inset: 'auto 0 0 auto' };
      case 'none':
      default:
        return {};
    }
  };
  const cardTheme = createTheme({
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            border: border ? '1px solid #D9D9D9' : 'initial',
            backgroundImage: background ? `url("${background}")` : 'initial',
            backgroundColor: type === 'feature' ? '#2C2C2C' : backgroundColor ?? 'initial',
            height: height ?? 'initial',
            width: width ?? 'initial',
            boxShadow:
              (
                type === 'navigational' ? '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.2)'
                  : type === 'container' || type === 'content' ? '0px 0px 9px 2px rgba(0, 0, 0, 0.03), 0px 1px 9px 2px rgba(0, 0, 0, 0.08)'
                    : '0px 0px 9px 2px rgba(0, 0, 0, 0.03)'
              ),
            borderRadius: '8px',
            '&:hover': {
              boxShadow: type === 'navigational' && '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.2)',
            },
            '&:active': {
              boxShadow: type === 'navigational' && '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.2)',
            },
            overflow,
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            position: 'relative',
            padding: type === 'container' ? '12px 24px' : '24px 32px',
            borderBottom: type === 'container' ? 'solid 1px #D9D9D9' : 'initial',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          },
          title: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: type === 'content' ? '20px' : type === 'feature' ? '20px' : '24px',
            lineHeight: type === 'container' ? '41px' : type === 'content' ? '27px' : type === 'feature' ? '27px' : '33px',
            color: headerStyles?.color ? headerStyles.color : type === 'content' ? '#355E6F' : type === 'feature' ? '#FFFFFF' : '#2C2C2C',
          },
          subheader: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: type === 'container' ? 400 : type === 'content' ? 900 : 500,
            fontSize: type === 'container' ? '12px' : type === 'content' ? '16px' : '14px',
            lineHeight: type === 'container' ? '17px' : type === 'content' ? '22px' : '15px',
            color: type === 'container' ? '#838383' : type === 'content' ? '#565656' : type === 'feature' ? '#FFFFFF' : '#6D6D6D',
          },
          action: {
            flex: 'none',
            margin: 0,
            alignSelf: 'auto',
            ...actionInsetPosition()
          },
          content: {
            flex: 'inherit'
          }
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: '0px',
            fontSize: 12,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: content ? '24px' : '0px !important',
            background: 'white',
            borderRadius: '8px',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={cardTheme}>
      <MuiCard {...props} ref={ref}>

        {
          (title || subheader)
            ? <CardHeader className={classNames('card-header', headerClassName)} title={title} subheader={subheader} action={action} sx={headerStyles}/>
            : <></>
        }

        <CardActions>{type === 'navigational' && icon}</CardActions>
        <CardContent className={contentClassName} sx={contentStyles}>
          {content}
        </CardContent>
      </MuiCard>
    </ThemeProvider>
  );
});
