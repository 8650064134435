/**
 * RTK Query -> base public API setup
 */

import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from 'config';
import { GraphQLClient } from 'graphql-request';
import Cookies from "js-cookie";
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { errorHandler } from "../baseApi";
import { tagTypes } from './tagTypes';

export const graphqlPublicRequest = async <ReturnType>(body: Record<string, unknown>): Promise<ReturnType> => {
  const client = new GraphQLClient(`${config.prospect.api.host}/`, {
    headers: {
      authorization: `Bearer ${Cookies.get('BEARER-TOKEN')}` ?? '',
    }
  });

  return client.request(
    jsonToGraphQLQuery(body, { pretty: true })
  );
};

// A custom graphql base public query that supports graphql
export const basePublicQuery = async ({ body }: { body: Record<string, unknown> }) => {
  try {
    const data = await graphqlPublicRequest(body);

    return { data };
  } catch (errorData) {
    return {
      error: errorHandler(errorData, body)
    };
  }
};

// Initialize an empty public api service that we'll inject endpoints into later as needed
export const basePublicApi = createApi({
  tagTypes: Object.values(tagTypes),
  reducerPath: 'apiPublic',
  baseQuery: basePublicQuery,
  endpoints: () => ({}),
});
