
const allServices = `
    query
    (
        $pricing_version: String!
    )
    {
      services (pricing_version: $pricing_version) {
        name
        id
        department {
          id
          name
        }
      }
    }
`;

export default allServices;
