import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, CardActions, CardHeader, CardTabs, Skeleton, Spinner, TabsItemProps, Typography, useSnackbar } from '@sprnova/nebula';
import { useGetCategoriesQuery } from 'api/crudGraphQL/indicators/getCategories';
import { useGetCategoriesPublicQuery } from 'api/crudGraphQL/public/indicators/getCategoriesPublic';
import { useGetScoreboardIntegrationsPublicQuery } from 'api/crudGraphQL/public/scoreboards/getScoreboardIntegrationsPublic';
import { useGetScoreboardPublicQuery } from 'api/crudGraphQL/public/scoreboards/getScoreboardPublic';
import { useGetScoreboardSelectorIndicatorsPublicQuery } from 'api/crudGraphQL/public/scoreboards/indicators/getScoreboardSelectorIndicatorsPublic';
import { useGetScoreboardIntegrationsQuery } from 'api/crudGraphQL/scoreboards/getScorboardIntegrations';
import { useGetScoreboardQuery } from 'api/crudGraphQL/scoreboards/getScoreboard';
import { useGetScoreboardSelectorIndicatorsQuery } from 'api/crudGraphQL/scoreboards/indicators/getScoreboardSelectorIndicators';
import { Indicator } from 'api/crudGraphQL/scoreboards/types';
import lodashFilter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { SCROLL_IDS } from 'features/scoreboards/Scoreboard';
import { FilterProvider, Filters } from 'features/scoreboards/utils/Filters';
import ComparisonTimeframe, { defaultKPIValues } from './components/ComparisonTimeframe';
import EditKPIsDrawer from './components/EditKPIsDrawer';
import { ScorecardsTabs } from './components/ScorecardsTabs';
import { IntegrationType } from './types';
import { formatScoreboardsDate } from '../components/utils';
import { indicatorsProjection, selectorIndicatorsProjection, scoreboardIntegrationProjection } from '../projections';

export interface SortableListDataType {
  id: number;
  label: string;
  is_active: boolean;
  indicator_id: number;
  formula: string;
  tooltip: string;
  required_platform_category: string;
};
export type KPIsProps = {
  isClient?: boolean;
};
export const KPIs = memo(({
  isClient = false,
}: KPIsProps): JSX.Element => {
  const { id } = useParams<{ [x: string]: string }>();
  const ALL_KPIS = 'All KPIs';
  const [activeTab, setActiveTab] = React.useState(ALL_KPIS);
  const { addSnackbar } = useSnackbar();
  const [openEditKPIsDrawer, toggleEditKPIsDrawer] = React.useReducer((state) => !state, false);

  const { filter, setFilter } = Filters({});

  const { data, isLoading, isFetching, error } = (isClient ? useGetScoreboardPublicQuery : useGetScoreboardQuery)({
    id: parseInt(id),
    projection: indicatorsProjection,
    indicatorArgs: { ...filter, compared: filter?.compared === '' ? undefined : filter?.compared }
  }, { skip: !id || isEmpty(filter) });
  const lastUpdated = data?.refreshed_at;

  useEffect(() => {
    /**
     * Show error message if there is an error fetching data
     * Reset the filter to default values
     */
    if (error) {
      addSnackbar({
        variant: 'error',
        message: error.message,
        persist: false,
      });
      setFilter?.({ type: 'add', value: { ...defaultKPIValues } });
    }
  }, [addSnackbar, error, setFilter]);

  const { data: categoriesData } = (isClient ? useGetCategoriesPublicQuery : useGetCategoriesQuery)({});

  // Get the integrations (integrated platforms) for the scoreboard
  const { data: scoreboardIntegrationData } = (isClient ? useGetScoreboardIntegrationsPublicQuery : useGetScoreboardIntegrationsQuery)({
    report_id: parseInt(id),
    projection: scoreboardIntegrationProjection,
  }, { skip: !id });

  const formattedIntegrationData: Record<string, IntegrationType[]> = (scoreboardIntegrationData ?? []).reduce((acc: Record<string, any[]>, item) => {
    const categoryKey = item.category;

    if (!acc[categoryKey]) {
      acc[categoryKey] = [];
    }
    acc[categoryKey].push({
      client_id: item.client_id,
      client_name: item.client_name,
      data_source_id: item.data_source_id,
      data_source_name: item.data_source_name,
      last_refresh_status: item.last_refresh_status,
      warehouse_source: item.warehouse_source
    });

    return acc;
  }, {});

  const categories = useMemo(() => {
    if (!categoriesData) return [];
    return [ALL_KPIS, ...categoriesData.map(({ category }) => category)];
  }, [categoriesData]);

  const { data: selectorIndicator, isLoading: selectorIndicatorLoading } = (isClient ? useGetScoreboardSelectorIndicatorsPublicQuery : useGetScoreboardSelectorIndicatorsQuery)({
    report_id: parseInt(id),
    projection: selectorIndicatorsProjection,
  }, { skip: !id });

  const indicators: Record<string, Indicator[]> = useMemo(() => {
    if (!data) return {};
    /**
     * Group indicators by category
     */
    const values = orderBy(data?.indicators, [(data): string => data.category], ['desc'])
      .map((indicator) => indicator.category)
      .reduce((result: Record<string, unknown>, category) => {
        result[category] = lodashFilter(data?.indicators, { category });
        return result;
      }, {});
    /**
     * Include all KPIs
     */
    return { [ALL_KPIS]: data?.indicators, ...values };
  }, [data]);

  const refreshedAt = useMemo(() => {
    if (!data?.refreshed_at) return undefined;
    return data?.refreshed_at;
  }, [data]);

  const tabsArray = useMemo(() => {
    if (isLoading) {
      return Array.from({ length: 2 }, (_, i) => ({ label: <Skeleton variant="rectangular" width={100} />, value: i })) as TabsItemProps[];
    }
    if (categories) {
      return categories.map((indicator) => ({
        label: indicator,
        value: indicator,
      }));
    }
    return [];
  }, [isLoading, categories]);

  const formattedData: never[] | Record<string, SortableListDataType[]> = useMemo(() => {
    if (!selectorIndicator) return [];
    // Group indicators by category
    const grouped = groupBy(selectorIndicator, 'category');
    // Initialize an object to store the data
    const dataObj = Object.keys(grouped).reduce((acc: Record<string, SortableListDataType[]>, key: string) => {
      // For each category, map the indicators to the SortableListDataType
      // Some values (label, formula, tooltip, required_platform_category) are used in the sortable list component
      // They will be filtered out in the submit handler in EditKPIsDrawer
      acc[key] = grouped[key].map(item => ({
        id: item.indicator_id,
        label: item.name,
        is_active: item.is_active == 'yes',
        indicator_id: item.indicator_id,
        formula: item.formula,
        equation: item.equation,
        tooltip: item.tooltip,
        required_platform_category: item.required_group,
      }));
      return acc;
    }, {});

    return dataObj;
  }, [selectorIndicator]);

  const renderEditKPIsDrawer = useMemo(() => {
    if (isClient) return null;

    if (selectorIndicatorLoading) {
      return <Skeleton variant='rectangular' height={40} />;
    }

    return (
      <EditKPIsDrawer
        id={parseInt(id)}
        data={formattedData}
        platformData={formattedIntegrationData}
        open={openEditKPIsDrawer}
        toggleOpen={toggleEditKPIsDrawer}
      />
    );
  }, [isClient, selectorIndicatorLoading, id, formattedData, formattedIntegrationData, openEditKPIsDrawer]);

  const renderPanels = useMemo(() => {
    if (isLoading) {
      return (
        <Skeleton
          height={400}
          variant='rectangular'
        />
      );
    }
    /**
     * Vertically scrollable Sidebar with Scorecards will go here;
     * it will receive indicators[activeTab] as its data
     */
    return (
      <ScorecardsTabs
        data={indicators[activeTab]}
        type={activeTab}
        lastUpdated={lastUpdated}
        isClient={isClient}
        toggleEditKPIsDrawer={toggleEditKPIsDrawer}
      />
    );
  }, [activeTab, indicators, isClient, isLoading, lastUpdated]);

  return (
    <Card
      header={
        <CardHeader
          title={<>KPIs {isFetching && <Spinner size='small' />}</>}
          sx={{
            '> div:nth-of-type(3)': {
              width: '100%'
            }
          }}
          tabs={
            <CardTabs
              onChange={(_, value): void => setActiveTab(value)}
              items={tabsArray}
              value={activeTab}
              variant="scrollable"
              scrollButtons="auto"
            />
          }
          end={
            <CardActions
              actions={[
                <Typography
                  key='kpis-actions-refreshed-at'
                  variant='caption'
                  sx={{ fontStyle: 'italic' }}
                >
                  {refreshedAt && `Last Updated: ${formatScoreboardsDate(refreshedAt, 'timestamp')}`}
                </Typography>,
                renderEditKPIsDrawer,
              ]}
            />
          }
        />
      }
      id={SCROLL_IDS.KPIS}
    >
      <FilterProvider filter={{ filter, setFilter }}>
        <ComparisonTimeframe loading={isLoading} data={indicators[activeTab]} />
        <Box sx={{ marginTop: '20px' }}>
          {renderPanels}
        </Box>
      </FilterProvider>
    </Card>
  );
});

KPIs.displayName = 'KPIs';
