/**
 * NoResultsFound
 */

import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { Button, ButtonProps } from '../Button';
import css from './NoResultsFound.module.scss';

export type Types = {
  buttonProps?: Partial<ButtonProps>;
  className?: string;
  fill?: boolean;
  icon?: React.ReactNode;
  renderButton?: React.ReactNode | ((props: Partial<ButtonProps>) => React.ReactNode);
  sub?: React.ReactNode;
  title?: React.ReactNode;
}

const NoResultsFound: FC<Types> = ({
  buttonProps = {},
  className,
  fill,
  icon,
  renderButton,
  sub,
  title = 'No results found',
  ...props
}) => {
  const renderCallToAction = useMemo(() => {
    if(typeof renderButton === 'function') {
      return renderButton(buttonProps);
    }

    if (Object.keys(buttonProps).length) {
      return <Button type="default" {...buttonProps} />;
    }

    return renderButton;
  }, [renderButton, buttonProps]);

  return (
    <div className={classNames(css.root, { [css.fill]: fill }, className)} {...props}>
      {icon && <div className={css.icon}>{icon}</div>}
      {title && <h2 className={css.title}>{title}</h2>}
      {sub && <p className={css.sub}>{sub}</p>}
      {renderCallToAction ? (
        <div className={css.buttonWrap}>
          {renderCallToAction}
        </div>
      ) : null}
    </div>
  );
};

export default NoResultsFound;
