import { SalesforceOpportunity } from 'features/entitiesRedux/models/salesforce';
import { SALESFORCE_OPPORTUNITY_STAGE } from '../constants';

/**
 * Determine whether a salesforce opportunity is closed or not
 * @param opportunity Salesforce opportunity
 * @returns {boolean} True if Closed Lost, Disqualified or Closed Won, False otherwise
 */
export const salesforceOpportunityIsClosed = (opportunity: Partial<SalesforceOpportunity>): boolean => {
  const { stage: opportunityStage } = opportunity;
  if (opportunityStage == SALESFORCE_OPPORTUNITY_STAGE.ClosedLost ||
      opportunityStage == SALESFORCE_OPPORTUNITY_STAGE.ClosedWon ||
      opportunityStage == SALESFORCE_OPPORTUNITY_STAGE.Disqualified
  ) {
    return true;
  }
  return false;
};
