import React, { FC } from 'react';
import { Container, Alert } from '@sprnova/nebula';

type WarningProps = {
  message?: string;
  container?: boolean;
};

export const Warning: FC<WarningProps> = ({
  message = 'You are not authorized to view this content',
  container
}) => {
  const alert = <Alert severity="warning">{message}</Alert>;

  if (container) {
    return (
      <Container hasVerticalPadding>
        {alert}
      </Container>
    );
  }

  return alert;
};

