/**
 * SectionBuilder -> Fields -> PreferredChannelsField
 */

import React, { FC, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { uniq } from 'lodash';
import {
  Divider,
  FormItem,
  Input,
  Select,
} from 'components';
import { useChannels } from '../../../../../../../../utils';
import { FieldProps } from '../../types';
import css from './PreferredChannelsField.module.scss';

let index = 1;

const PreferredChannelsField: FC<FieldProps> = ({ name, placeholder, form }) => {
  const { channels, loading } = useChannels();
  // get the default channel options from the channels table
  const defaultChannelNames = channels?.map((channel) => channel.name);
  // get the preferred channels value for this discovery
  const discoveryChannelNames = form.getFieldValue([name]) || [];
  // combine both arrays and de-dupe to get our final list for the dropdown
  const channelNameOptions = uniq([...defaultChannelNames, ...discoveryChannelNames]);

  // local state variables for custom input and tracking the dropdown options
  const [customInputName, setCustomInputName] = useState<string>();
  const [items, setItems] = useState<Array<string | null>>([]);

  useEffect(() => {
    if (!loading) setItems(channelNameOptions);
  }, [loading]);

  const addItem = () => {
    setItems([...items, customInputName || `Other channel ${index++}`]);
    setCustomInputName('');
  };
  
  return (
    <FormItem name={name} className={css.root}>
      <Select
        mode="multiple"
        allowClear
        showArrow
        placeholder={placeholder}
        skeleton={loading}
        dropdownRender={menu => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
              <Input
                placeholder="Enter channel name"
                style={{ flex: 'auto' }}
                value={customInputName}
                onChange={(e) => setCustomInputName(e.target.value)}
                onPressEnter={addItem}
                onKeyDown={e => e.stopPropagation()} // prevents removing selections when pressing delete key
              />
              <a
                style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                onClick={addItem}
              >
                <PlusOutlined /> Add channel
              </a>
            </div>
          </div>
        )}
      >
        {items?.map(item => {
          if (item !== null) {
            return (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            );
          }
        })}
      </Select>
    </FormItem>

  );
};

export default PreferredChannelsField;
