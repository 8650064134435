import React from 'react';
import { Alert, Button } from 'components';

interface SalesforceSyncAlertProps {
  sync?: boolean;
  setOpenSyncModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const SalesforceSyncAlert = ({ sync, setOpenSyncModal }: SalesforceSyncAlertProps): JSX.Element => {
  return (
    <>
      {
        sync ? 
          <Alert 
            message='There is data that needs to be synced with Salesforce' 
            type='warning'
            showIcon
            style={{ marginRight: '20px', width: '100%' }}
            action={
              <Button size='small' onClick={() => setOpenSyncModal && setOpenSyncModal(true)}>
            Sync now
              </Button>
            }
          /> :
          <></>
      }
    </>
  );
};