import { Projection } from 'api/entityGraphQL';
import { FusionIntegration } from 'features/entitiesRedux';
import { baseApi } from '..';
import tagTypes from './tagTypes';

export type GetClientFusionIntegrationsQuery = {
  clientId: number;
  projection?: Projection;
};

export type GetClientFusionIntegrationsResult = FusionIntegration[];

/**
 * Hook to fetch client fusion integration details.
 *
 * This hook queries for integration details associated with a specific client ID.
 * It leverages the baseApi to execute a GraphQL query, fetching information such as
 * the classic integration ID, warehouse source, and data source details.
 *
 * The response is transformed to directly return the `nova_fusion_integrations` part
 * of the data, ensuring the hook returns the structure expected by the frontend.
 *
 * It also specifies cache tags for efficient invalidation and re-fetching strategies,
 * tagging the fetched data with the client's ID.
 *
 * @param {GetClientFusionIntegrationsQuery} query - The query parameters, including the client ID.
 * @returns A query result object containing `data`, `error`, `isLoading`, and other properties
 *          to manage the query state.
 */
export const { useGetClientFusionIntegrationsQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getClientFusionIntegrations: build.query<GetClientFusionIntegrationsResult, GetClientFusionIntegrationsQuery>({
      transformResponse: (response: { nova_fusion_integrations: GetClientFusionIntegrationsResult } ) => response?.nova_fusion_integrations ?? [],
      providesTags: (result) => result ? [{ type: tagTypes.CLIENT, id: result[0]?.client_id }] : [], //empty array when there's no result
      query: ({
        clientId,
        projection,
      }) => ({
        body: {
          query: {
            nova_fusion_integrations: {
              __args: {
                client_id: clientId,
              },
              ...projection
            }
          }
        }
      })
    }),
  }),
});
