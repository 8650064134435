/**
 * useRoleAccess
 *
 * A hook for checking if the current user role
 * matches one of the accepted user roles
 *
 * Usage:
 * const hasAccess = useRoleAccess(Role.client)
 *
 * Or with multiple roles:
 * const hasAccess = useRoleAccess([Role.client, Role.cLevel])
 */

import { useMemo } from 'react';
import { Role } from 'api/accessControl';
import { useAccount } from '..';

export const useRoleAccess = (
  roles: Role | Role[],
  config?: {
    talentOnly?: boolean
  }
): boolean => {
  const acceptedRoles = useMemo(()=> !Array.isArray(roles) ? [roles] : roles, [roles]);
  const { account } = useAccount();

  const hasAccess = useMemo(() => {
    if (account?.roles.length) {
      const accountRoles = new Set(account.roles);

      if (
        (accountRoles.has(Role.superAdmin) && !config?.talentOnly) || // Always allow the superAdmin role (unless talentOnly = true)
        acceptedRoles.some(r => accountRoles.has(r))
      ) {
        return true;
      }
    }

    return false;
  }, [acceptedRoles, account?.roles, config?.talentOnly]);

  return hasAccess;
};
