import { find } from 'lodash';
import { DataSource } from '../../../entitiesRedux';

/**
 * Find the correct data source object in a list of data sources from the slug.
 * @param dataSources - List of DataSources.
 * @param slug - DataSource slug to find in list.
 * @returns - DataSource found in list from slug or undefined.
 */
export const getDataSource = (dataSources: DataSource[], slug?: string): DataSource | undefined => {
  return find(dataSources, ['slug', slug]);
};
