/**
 * SelectComparisonOperator
 */

import React, { forwardRef } from 'react';
import { formatComparisonOperatorText } from '../../utils';
import Select, { Props as SelectProps } from '../Select/Select';

export const operators = [
  'equal',
  'not_equal',
  'less_than',
  'less_than_or_equal',
  'greater_than',
  'greater_than_or_equal',
];

const SelectComparisonOperator = forwardRef(({ ...props }: SelectProps, ref: any) => {
  return (
    <Select
      ref={ref}
      {...props}
    >
      {operators?.map(operator => {
        return <Select.Option key={operator} value={operator}>{formatComparisonOperatorText(operator)}</Select.Option>;
      })}
    </Select>
  );
});

SelectComparisonOperator.displayName = 'SelectComparisonOperator';

export default SelectComparisonOperator;