import React, { memo, useCallback, useMemo, useEffect, useState } from 'react';
import { Card, Tooltip, Typography, TextField, TrashIcon, IconButton, Button, PlusIcon, MonetaryField, Textarea } from '@sprnova/nebula';
import classNames from 'classnames';
import { TextEditor } from 'components/TextEditor';
import { valuePresentStyles as dropdownStyles } from 'features/users/UserOverviewPage/components/constants';
import { maxCharactersSnippetSummary } from '../constants';
import { DeleteTierModal } from '../DeleteTierModal';
import { ActionType, FormDataType } from '../packageStrategyTypes';
import { duplicateTierNameErrorHelperText } from '../packageStrategyUtil';
import css from './../package-strategies.module.scss';
import cssContractDetails from './ContractDetails.module.scss';

type QuantityDataObject = {
  name: string;
  deliverable: string;
  price: number;
}

type QuantityContractDetailsProps = {
  isSubmitted: boolean;
  dispatch: React.Dispatch<any>;
  formData: Partial<FormDataType>;
}

const QuantityContractDetails = ({ isSubmitted, dispatch, formData }: QuantityContractDetailsProps): JSX.Element => {
  const defaultQuantityDataObjectsCount = useMemo(() => 3, []);
  const [selectedDeleteTierIndex, setSelectedDeleteTierIndex] = useState<number | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  /**
   * Returns an array of QuantityDataObjects with default values
   *
   * @returns {Array<QuantityDataObject>} An array of QuantityDataObjects with default values
   */
  const getInitialQuantityDataObjects = useCallback(() => {
    const initQuantityDataObjects = [];
    for (let i = 0; i < defaultQuantityDataObjectsCount; i++) {
      initQuantityDataObjects.push({
        name: '',
        deliverable: '',
        price: 0
      });
    }
    return initQuantityDataObjects;
  }, [defaultQuantityDataObjectsCount]);

  const [quantityData, setQuantityData] = useState<Array<QuantityDataObject>>(getInitialQuantityDataObjects());

  // initialize form data with default quantity pricing values
  useEffect(() => {
    if (quantityData && quantityData.length > 0) {
      dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS, payload: quantityData});
    }
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_DATA, payload: { snippet_summary: '' }});
  }, [dispatch, quantityData]);

  /**
   * Handles the change of the tier name
   * @param {React.ChangeEvent<HTMLInputElement>} event The event object
   * @param {number} index The index of the quantity object
   */
  const handleQuantityNameChange = useCallback((event, index: number) => {
    const newQuantityData = quantityData.map((quantityDataObject, quantityDataObjectIndex) => {
      if (quantityDataObjectIndex === index) {
        return {
          ...quantityDataObject,
          name: event.target.value
        };
      }
      return quantityDataObject;
    });
    setQuantityData(newQuantityData);
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS, payload: newQuantityData});
  }, [dispatch, quantityData]);

  /**
   * Handles the change of the quantity number and deliverable
   * @param {React.ChangeEvent<HTMLInputElement>} event The event object
   * @param {number} index The index of the quantity object
   * @returns {void}
   */
  const handleQuantityNumberAndDeliverableChange = useCallback((event, index: number) => {
    const newQuantityData = quantityData.map((quantityDataObject, quantityDataObjectIndex) => {
      if (quantityDataObjectIndex === index) {
        return {
          ...quantityDataObject,
          deliverable: event.target.value
        };
      }
      return quantityDataObject;
    });
    setQuantityData(newQuantityData);
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS, payload: newQuantityData});
  }, [dispatch, quantityData]);

  /**
   * Handles the change of the quantity price
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} event The event object
   * @param {number} index The index of the quantity object
   * @returns {void}
   */
  const handleQuantityPriceChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const newQuantityData = quantityData.map((quantityDataObject, quantityDataObjectIndex) => {
      if (quantityDataObjectIndex === index) {
        return {
          ...quantityDataObject,
          price: Number.parseFloat(event.target.value)
        };
      }
      return quantityDataObject;
    });
    setQuantityData(newQuantityData);
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS, payload: newQuantityData});
  }, [dispatch, quantityData]);

  /**
   * Handles the deletion of a tier
   */
  const handleDeleteTier = useCallback(() => {
    const newQuantityData = quantityData.filter((quantityDataObject:QuantityDataObject, index:number) => index !== selectedDeleteTierIndex);
    setQuantityData(newQuantityData);
    setOpenDeleteDialog(false);
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS, payload: newQuantityData});
  }, [dispatch, quantityData, selectedDeleteTierIndex]);

  /**
   * Handles the opening of the delete dialog
   * Sets the selected tier index to be deleted
   * @param {number} quantityDataObjectIndex The index of the quantity object
   * @returns {void}
   */
  const handleDeleteDialogClickOpen = useCallback((quantityDataObjectIndex: number) => {
    setOpenDeleteDialog(true);
    setSelectedDeleteTierIndex(quantityDataObjectIndex);
  }, []);

  /**
   * Handles the closing of the delete dialog
   * Sets the selected tier index to null
   * @returns {void}
   */
  const handleDeleteDialogClose = useCallback(() => {
    setOpenDeleteDialog(false);
    setSelectedDeleteTierIndex(null);
  }, []);

  /**
   * Handles the addition of a new tier
   * @returns {void}
   */
  const handleAddNewTier = useCallback(() => {
    setQuantityData([...quantityData, {
      name: '',
      deliverable: '',
      price: 0
    }]);
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS, payload: [...quantityData, {
      name: '',
      deliverable: '',
      price: 0
    }]});
  }, [dispatch, quantityData]);

  /**
   * Handles the setting of the proposal snippet
   * @param {string} snippetHtml The html of the snippet
   * @param {number} index The index of the quantity object
   */
  const handleSetProposalSnippet = useCallback((snippetHtml: string, index: number) => {
    const newQuantityData = quantityData.map((quantityDataObject, quantityDataObjectIndex) => {
      if (quantityDataObjectIndex === index) {
        return {
          ...quantityDataObject,
          snippet: snippetHtml
        };
      }
      return quantityDataObject;
    });
    setQuantityData(newQuantityData);
    dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_PRICING_TYPE_DATA_MULTIPLE_TIERS, payload: newQuantityData});
  }, [dispatch, quantityData]);

  const tierCards = useMemo(() => quantityData.map((quantityDataObject, index) => {
    const isDuplicateTierName = formData?.pricing_tiers?.filter((_, idx) => idx !== index)
      .map(({ name }) => name.trim().toLowerCase())
      .includes(quantityDataObject.name?.trim().toLowerCase()) && quantityDataObject.name?.trim() !== '';
    return (
      <Card
        sx={{ paddingTop: 0, marginBottom: '24px' }}
        key={`quantity-pricing-tier-${index}`}
      >
        <div>
          <div className={css.two__fields__container}>
            <TextField
              id={'tierNameInput' + index}
              label="Tier Name"
              value={quantityDataObject.name}
              error={(!formData?.pricing_tiers?.[index]?.name || isDuplicateTierName) && isSubmitted}
              onChange={(event): void => handleQuantityNameChange(event, index)}
              sx={quantityDataObject.name ? dropdownStyles : undefined}
              helperText={isDuplicateTierName && duplicateTierNameErrorHelperText}
            />
            <Tooltip content="Delete tier" variant="plain">
              <IconButton
                sx={{ height: '100%' }}
                size="xl"
                onClick={(): void => handleDeleteDialogClickOpen(index)}
                disabled={quantityData.length === 1}
              >
                <TrashIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classNames(css.two__fields__container, cssContractDetails.tier__quantity__container)}>
            <TextField
              id={'quantityNumberAndDeliverable' + index}
              helperText="Ex: 5 emails, 1,000 pages"
              label="Quantity Number and Deliverable"
              value={quantityDataObject.deliverable}
              error={!formData?.pricing_tiers?.[index]?.deliverable && isSubmitted}
              onChange={(event): void => handleQuantityNumberAndDeliverableChange(event, index)}
              sx={quantityDataObject.deliverable ? dropdownStyles : undefined}
            />
            <MonetaryField
              id={'quantityPrice' + index}
              error={!formData?.pricing_tiers?.[index]?.price && isSubmitted}
              helperText="Price of tier"
              label="Quantity Price"
              value={quantityDataObject.price}
              onChange={(event): void => handleQuantityPriceChange(event, index)}
            />
          </div>
          <Typography sx={{ fontWeight: 600 }} display="block" gutterBottom>
            Proposal Snippet
          </Typography>
          <TextEditor
            className={classNames(css.text_editor, !formData?.pricing_tiers?.[index]?.snippet && isSubmitted ? css.snippet_error : undefined)}
            onChange={(snippetHtml): void => {
              handleSetProposalSnippet(snippetHtml, index);
            }}
          />
        </div>
      </Card>
    );
  }
  ), [formData?.pricing_tiers, handleDeleteDialogClickOpen, handleQuantityNameChange, handleQuantityNumberAndDeliverableChange, handleQuantityPriceChange, handleSetProposalSnippet, isSubmitted, quantityData]);

  return (
    <>
      <Textarea
        id="snippetSummaryField"
        error={formData?.snippet_summary && formData?.snippet_summary.length > maxCharactersSnippetSummary && isSubmitted}
        placeholder="This will appear in a tooltip when a user is adding this strategy to a Blueprint"
        label="Snippet summary"
        maxCharacters={maxCharactersSnippetSummary}
        minRows={2}
        resizeable
        sx={{ marginBottom: '24px', marginTop: '24px' }}
        value={formData?.snippet_summary}
        onChange={(event): void => dispatch({ type: ActionType.ON_CHANGE_CONTRACT_DETAILS_DATA, payload: { snippet_summary: event.target.value } })}
      />
      {tierCards}
      <Button
        sx={{ width: '100%' }}
        variant="tertiary"
        size="large"
        startIcon={<PlusIcon />}
        onClick={handleAddNewTier}
      >
        Add New Tier
      </Button>
      <DeleteTierModal
        openDialog={openDeleteDialog}
        handleCloseDialog={handleDeleteDialogClose}
        handleDeleteTier={handleDeleteTier}
      />
    </>
  );
};

export default memo(QuantityContractDetails);
