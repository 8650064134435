/**
* Get analyst survey department
*
* A re-usable hook for getting a department 
* with the correct arguments based on the department_id URL parameter
 */

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { 
  useGetDepartmentQuery,
  util,
  GET_DEPARTMENT_ENDPOINT_KEY,
  GetDepartmentResult 
} from 'api/crudGraphQL/departments/getDepartment';
import { Projection } from 'api/entityGraphQL';
import { Department } from 'features/entitiesRedux';
import { departmentProjection } from '../constants';

export type UseGetAnalystSurveyDepartmentByUrlParam = (rtkOptions?: {
  skip?: boolean;
}) => {
  updateDepartmentCache: (department: Partial<Department>) => void;
  departmentId: number;
  departmentProjection: Projection;
  departmentArguments: {
    id: number;
    projection: Projection;
  }

  // RTK Query
  isLoading: boolean;
  data?: GetDepartmentResult;
}

export const useGetAnalystSurveyDepartmentByUrlParam: UseGetAnalystSurveyDepartmentByUrlParam = ({ skip } = {}) => {
  const dispatch = useDispatch();
  const { department_id } = useParams<{ [x: string]: string }>();

  const departmentId = Number(department_id ?? 0);
  const departmentArguments = {
    id: departmentId,
    projection: departmentProjection
  };

  //  Util: Update the department cache in redux for this request
  const updateDepartmentCache = (data: Partial<Department>) => dispatch(
    util.updateQueryData(GET_DEPARTMENT_ENDPOINT_KEY, departmentArguments, (draft) => {
      Object.assign(draft, data);
      return draft;
    })
  );

  return {
    departmentId,
    departmentArguments,
    departmentProjection: departmentProjection,
    updateDepartmentCache,

    // RTK Query
    ...useGetDepartmentQuery(departmentArguments, {
      skip: !!skip || !departmentId,
    })
  };
};
