import React, { forwardRef, useState } from 'react';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Input as AntdInput } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import classNames from 'classnames';
import { SkeletonInput } from '../SkeletonInput';
import css from './TextAreaCollapsible.module.scss';

interface Props extends TextAreaProps {
  className?: string;
  skeleton?: boolean;
  style?: React.CSSProperties;
  displayToggle?: boolean;
}

const TextAreaCollapsible = forwardRef(
  ({
    className,
    skeleton = false,
    style,
    displayToggle = false,
    ...props
  }: Props, ref: any) => {

    // Show skeleton when loading is true
    if (skeleton) return <SkeletonInput loading={false} active={true} />;

    const renderButton = () => {
      if (showField) {
        return (
          <>
            <CaretDownOutlined className={css.icon}/><span className={css.toggleText}>Hide Notes</span>
          </>
        );
      } else {
        return (
          <>
            <CaretRightOutlined className={css.icon}/><span className={css.toggleText}>Show Notes</span>
          </>
        );
      }

    };

    // trigger for displayToggle
    const [showField, setShowField] = useState(false);
    const renderDisplayToggle = () => {
      if (displayToggle) {
        return (
          <div className={css.displayToggle} onClick={() => setShowField(!showField)}>
            {renderButton()}
          </div>
        );
      }
    };

    let displayStyle;
    if (displayToggle) {
      if (showField) {
        displayStyle = {display: 'block'};
      } else {
        displayStyle = {display: 'none'};
      }
    }

    return (
      <div className={classNames(className, css.root)}>
        {renderDisplayToggle()}
        <AntdInput.TextArea
          {...props}
          //className={classNames(className, css.root)}
          ref={ref}
          style={{...style, ...displayStyle}}
        />
      </div>
    );
  }
);

TextAreaCollapsible.displayName = 'TextAreaCollapsible';

export default TextAreaCollapsible;
