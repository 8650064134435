/**
 * useEcommercePlatforms
 * Fetches all E-Commerce Platform types
 */

import { useState, useEffect } from 'react';
import { novaGraphQLClient, Projection } from 'api/entityGraphQL';
import { EcommercePlatform } from '../../features/entitiesRedux';

type Type = {
  loading: boolean;
  error: string;
  ecommercePlatforms: EcommercePlatform[];
};

type UseEcommercePlatformsProps = { projection?: Projection };
export const useEcommercePlatforms = ({
  projection: defaultProjection,
}: UseEcommercePlatformsProps = {}): Type => {
  const [ecommercePlatforms, setEcommercePlatforms] = useState<EcommercePlatform[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [projection /* , setProjection */] = useState(defaultProjection);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      setLoading(true);
      try {
        const { ecommerce_platforms } = await novaGraphQLClient.fetchEcommercePlatforms({ projection });
        if (!isCancelled) {
          setError('');
          setEcommercePlatforms(ecommerce_platforms);
          setLoading(false);
        }
      } catch (error) {
        if (!isCancelled) {
          setLoading(false);
          setError(error?.response?.errors?.[0]?.message || 'Unknown error');
        }
      }
    })();

    return () => {
      isCancelled = true;
    };

  }, [projection]);

  return { ecommercePlatforms, loading, error };
};

export default useEcommercePlatforms;
