/**
 * SelectState
 */

import React, { forwardRef, memo } from 'react';
import { Select, SelectProps } from '@sprnova/nebula';
import { usStates } from './usStates';

export type StateType = {
  name: string;
  value: string;
}

const SelectState = forwardRef(({ ...props }: SelectProps, ref: React.Ref<HTMLInputElement>) => {
  return (
    <Select
      ref={ref}
      {...props}
    >
      {usStates.map((state: StateType) => (
        <Select.Item key={state.value} value={state.value}>
          {state.name}
        </Select.Item>
      ))}
    </Select>
  );
});

SelectState.displayName = 'SelectState';
export default memo(SelectState);
