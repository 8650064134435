/**
 Blueprints -> Review List Page
 */

import React, { useEffect } from 'react';
import { Container } from '@sprnova/nebula';
import { Action, Resource, Role } from 'api/accessControl';
import { Warning } from 'components/AccessControl/Warning';
import { PageHero } from 'layouts/components';
import { useStrategiesPaginated } from 'features/entitiesRedux';
import { useAccount } from 'features/global/hooks';
import { PricingVersion } from 'features/library/constants';
import { AccessControl, NoResultsFound } from 'components';
import { BlueprintsReviewList } from './components';

const BlueprintsReviewPage = (): JSX.Element => {
  const { account } = useAccount();

  const isFinanceReviewed = (account?.roles &&
    (account.roles.includes(Role.accounting) || account.roles.includes(Role.cLevel)||
    account.roles.includes(Role.vpLevel)) &&
    account.roles.includes(Role.blueprintsReview));

  const isSuperAdminBP = (account?.roles &&
    account.roles.includes(Role.superAdmin));

  const isDepartmentReviewed = (account?.roles && !isFinanceReviewed && !isSuperAdminBP &&
  (account.roles.includes(Role.departmentDirectors)
      || account.roles.includes(Role.blueprintsReview)));

  const {
    strategies,
    isLoading,
    error,
    pagination,
    setFilter,
    filter,
  } = useStrategiesPaginated({
    projection: {
      id: true,
      name: true,
      status: true,
      type: true,
      length: true,
      monthly_gross_profit: true,
      created_at: true,
      reviewed_at: true,
      client: {
        id: true,
        name: true,
        logo: true,
      },
    },
    filter: {
      pricing_version: PricingVersion.HOURLY,
      status: 'won',
      sort: ['-updated_at']
    }
  });
  const departmentIds = account?.departments?.map((department) => department.id);
  const showDepartmentPillars = [1001, 1002, 1004];
  // Have to reset the filter after the account loads
  useEffect(()=>{
    setFilter({
      ...filter,
      is_finance_reviewed: isFinanceReviewed ? false : isDepartmentReviewed ? true : undefined,
      is_department_heads_reviewed: isDepartmentReviewed ? false : undefined,
      department_ids: isDepartmentReviewed ?  departmentIds : undefined,
      is_department_pending_review: isDepartmentReviewed ? 'yes' : undefined,
      pillar_ids: isDepartmentReviewed ? showDepartmentPillars : undefined,

    });
  }, [account]);

  if (!isFinanceReviewed && !isDepartmentReviewed && !isSuperAdminBP && !isLoading) {
    return (
      <Warning container />
    );
  }

  const departmentNames = account?.departments?.map((department) => department.name).join(' and ');

  return (
    <>
      <PageHero
        title={isFinanceReviewed ? 'Blueprints Review Queue - Finance' : isDepartmentReviewed ? `Blueprints Review Queue - ${departmentNames} department` : 'Blueprints Review Queue'}
        description="All closed deals needing approval"
      />
      <Container hasVerticalPadding>
        <AccessControl action={[Action.update]} resource={Resource.strategy}>
          {!strategies.length && !isLoading
            ? <NoResultsFound title="No Blueprints to Review" />
            : <BlueprintsReviewList
              error={error}
              filter={filter}
              pagination={pagination}
              setFilter={setFilter}
              isLoading={isLoading && !strategies.length}
              strategies={strategies}
              reviewerType={isFinanceReviewed || isSuperAdminBP ? 'Finance' : 'Department'}
            />
          }
        </AccessControl>
      </Container>
    </>
  );};

const Title = () => <>Blueprints Review Queue</>;
Title.displayName = 'BlueprintsReviewPageTitle';
export default Object.assign(BlueprintsReviewPage, { Title });
