import * as Sentry from '@sentry/react';
import { Role } from 'api/accessControl';
import { config } from 'config';
import { GraphQLClient } from 'graphql-request';
import Cookies from 'js-cookie';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { isEmpty } from 'lodash';
import omit from 'lodash/omit';
import { TTLCounter } from 'ttl-counter';
import { TriggerDataPipelineApiParameters } from 'utils/actions/triggerDataPipeline';
import {
  YesNo,
  Audit,
  AuditFormArgs,
  Discovery,
  Product,
  Channel,
  LeadChannel,
  ServiceInput,
  Service,
  DiscoveryGoal,
  Growth,
  Budget,
  Customer,
  Department,
  Competitor,
  CreateAuditArgs,
  CreateForecastFormValues,
  Forecast,
  UpdateForecastArgs,
  UpdateForecastChannelArgs,
  ForecastChannel,
  UpdateForecastMonthArgs,
  ForecastMonth,
  ForecastModel,
  Backtest,
  CreateBacktestFormValues,
  CreateForecastNeedleMoversArgs,
  ForecastNeedleMover,
  DeleteClientUserApiParameters,
  CreateClientUserApiParameters,
  FetchTasksFilter,
  Task,
  TaskFormValues,
  FetchStrategiesFilter,
  Strategy,
  StrategyFormValues,
  StrategyDuplicateValues,
  StrategyTask,
  TaskInput,
  StrategyCost,
  StrategyMonth,
  Report,
  Accounts,
  Sections,
  FetchDepartmentsFilter,
  FetchOrganizationsFilter,
  Organization,
  FetchServicesFilter,
  ServiceFormValues,
  Pillar,
  LeadSource,
  Industry,
  Goal,
  CRM,
  EcommercePlatform,
  BusinessType,
  Indicators,
  Partner,
  CreatePartnerUserArgs,
  Multiplier,
  DataQClient,
  FacebookMarketingDataType,
  AmazonAdsDataType,
  GoogleAnalyticsDataType,
  GoogleAnalytics4DataType,
  GoogleAdsDataType,
  ClientDataSource,
  AudienceInsights,
  SurveyType,
  SkillsetGrade,
  RoleType,
  FetchRulesFilter,
  RuleFormValues,
  Entity,
  DataQDataSource,
  AutomatedScore,
  Churn,
  Rapport,
  Pace,
  Alert,
  Rule,
  User,
  AutomationService,
  TikTokDataType,
  Tier,
  Vertical,
  MicrosoftBingAdAccountType,
} from 'features/entitiesRedux';
import { FetchDashboardsFilter, Dashboard, FetchDashboardNotesFilter, Note, DashboardAndRevision, DashboardAdditional } from 'features/entitiesRedux/models/dashboard';
import { UpdateDiscoveryArgs, UpdateDiscoveryScoreArgs, UpdateUserArgs } from 'features/entitiesRedux/models/discovery/discovery';
import { defaultNotesProjection } from 'features/entitiesRedux/models/notes/projections';
import { SalesforceAccount, SalesforceOpportunity } from 'features/entitiesRedux/models/salesforce/salesforce';
import { TalentSurvey, UpdateTalentSurveyArgs, UpdateTalentSurveyBucketArgs } from 'features/talent/types';
import { returnStatusProjection } from './global_projections';
import {
  Projection,
  FetchAuditsFilter,
  FetchReportsFilter,
  Pagination,
  FetchAlertsFilter,
  ReturnStatusApi,
  PaginatedResponse,
  FetchManyPaginatedConfig,
} from './types';
import { IntegrationInput, PresentationTemplateVariable } from '../../features/audits/auditsSlice';


/**
 * Client for sending API requests to nova platform GraphQL server
 */
export class NovaGraphQLClient {
  version: string = config.platform.api.version;

  endpoint: string = config.platform.api.host + '/';

  token: string = config.platform.api.token;

  client: GraphQLClient;

  defaultPagination = { page: 1, limit: 10, sort: ['id'] };

  paginationProjection = {
    total: true,
    per_page: true,
    current_page: true,
    from: true,
    to: true,
    last_page: true,
    has_more_pages: true,
  };

  DEFAULT_PAGE = 1;
  DEFAULT_LIMIT = 10;

  paginationFields =
    `total
  per_page
  current_page
  from
  to
  last_page
  has_more_pages`;

  request_count = 0;

  ttlCounter: TTLCounter;

  constructor() {
    this.client = this.getClient();

    this.ttlCounter = new TTLCounter({
      ttl: 60 * 1000,
      countForCb: 240,

      cb(counterId: string) {
        const exception = Sentry.captureException(new Error('Request limit reached'));
        console.log('ttlCounter counterId', counterId);
        window.location.href = `/error.html?event_id=${exception}`;
        return false;
      }
    });
  }

  getClient(): GraphQLClient {
    const versionConfig: { [x: string]: Record<string, unknown> } = {
      v1: {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      },
      v2: {
        headers: {
          authorization: `Bearer ${Cookies.get('BEARER-TOKEN')}` ?? '',
        },
      },
    };

    return new GraphQLClient(this.endpoint, versionConfig[this.version]);
  }

  resetClient(): NovaGraphQLClient {
    this.client = this.getClient();

    return this;
  }

  /** REMOVE THESE AFTER REFACTOR */
  toGraphQL(query: Record<string, unknown>, options: Record<string, unknown> = {}): string { return jsonToGraphQLQuery(query, { pretty: true, ...options }); }

  filterUndefinedNullEmpty(
    obj: { [x: string]: any },
    alwaysInclude: string[] = [],
  ): Record<string, unknown> { return Object.keys(obj).reduce(
    (accum, key) => ({
      ...accum,
      // Always include some fields to be able to e.g. reset a value
      ...(alwaysInclude.includes(key)
              || (obj[key] !== undefined // not undefined
                // obj[key] !== "" && // not empty string
                && !(Array.isArray(obj[key]) && obj[key].length === 0) // not an empty array
                && obj[key] !== null) // not null
        ? { [key]: obj[key] }
        : {}),
    }),
    {},
  );
  }
  /** ^REMOVE THESE AFTER REFACTOR^ */

  /**
   * Global api call
   */
  async sendRequest(query: string): Promise<any> {
    this.request_count += 1;
    this.ttlCounter.count('query');

    const response: Response = await this.client.request(query);

    return response;
  }


  /**
   *
   *
   * Should start removing everything beneath this comment.
   *
   *
   */

  /**
   * Audits
   */
  defaultDiscoveryProjection = {
    id: true,
    audit: { id: true },
    client: {
      id: true,
      name: true,
      website: true,
      logo: true,
      business_type: { id: true, name: true },
      industry: { name: true },
      salesforce_client_id: true,
    },
    // is_completed: true,
    score: true,
    discovery_score: true,
    notes: true,
    analyst_notes: true,
    author: { id: true, name: true },
    business_type: { id: true, name: true },
    opportunity_score: true,
    opportunity_scores: true,
    analyst_score: true,
    analyst_score_max: true,
    user_score: true,
    status: true,
    departments: {
      id: true,
      name: true,
      analyst_notes: true,
      score: true,
      max: true,
      opportunity: true,
      in_survey: true,
      notes: true,
      scores: {
        id: true,
        name: true,
        score: true,
        max: true,
        notes: true,
        services: {
          id: true,
          name: true,
        },
        buckets: {
          id: true,
          name: true,
          score: true,
          automated_score: true,
          max: true,
          criteria: {
            id: true,
            name: true,
            score: true,
          },
        },
        buckets_support_automation: { id: true, name: true },
      },
    },
    services: {
      id: true,
      name: true,
      in_survey: true,
      is_active: true,
      // is_current: true,
      // producer: true,
      // is_priority: true,
      department: {
        id: true,
      },
    },
    considered_services: {
      id: true,
      name: true,
    },
    topics: { id: true, name: true, slug: true, notes: true },
    questions: { id: true, group_name: true, answer: true },
    channels: { id: true, name: true },
    lead_channels: { id: true, name: true },
    goals: { id: true, type: true, metric: true },
    products: { id: true, name: true },
    competitors: { id: true, name: true, website: true },
    customers: { id: true, name: true, industry: true },
    growths: { id: true, goal: true },
    scores: { id: true, name: true },
    buckets: { id: true, name: true, score: true, automated_score: true },
    budgets: { id: true, budget: true, ad_spend: true, notes: true },
    created_at: true,
    updated_at: true,
  };
  defaultPresentationProjection = {
    id: true,
    name: true,
    status: true,
    percent: true,
    deck_url: true,
    logo: true,
    audit: { id: true },
    author: { id: true },
    templates: { id: true },
    revisions: {
      id: true,
      author: {
        id: true,
        name: true,
      },
      deck_url: true,
      status: true,
      templates: {
        id: true,
        name: true,
      },
      created_at: true,
    },
    integrations: {
      id: true,
      url: true,
      type: true,
      account: { id: true, name: true, slug: true },
    },
    created_at: true,
    updated_at: true,
  };

  defaultForecastNeedleMoversProjection = {
    id: true,
    key: true,
    entity: {
      name: true,
      id: true,
    },
    bucket: {
      id: true,
      name: true,
      score: true,
      max: true,
      question_group: {
        name: true,
        id: true,
        department: {
          name: true,
          id: true,
        }
      },
    },
  };

  defaultForecastMonthsProjection = {
    needle_movers: this.defaultForecastNeedleMoversProjection,
    notes: defaultNotesProjection,
    ad_spend_lift: true,
    avg_mom_growth: true,
    created_at: true,
    date: true,
    efficiency_lift: true,
    first_time_aov_ad_spend_lift: true,
    first_time_aov_ad_spend: true,
    first_time_aov_delta: true,
    first_time_aov_efficiency_lift: true,
    first_time_aov_forecasted: true,
    first_time_aov_growth: true,
    first_time_aov_growth_override: true,
    first_time_aov: true,
    first_time_cvr_ad_spend_lift: true,
    first_time_cvr_ad_spend: true,
    first_time_cvr_delta: true,
    first_time_cvr_efficiency_lift: true,
    first_time_cvr_forecasted: true,
    first_time_cvr_growth: true,
    first_time_cvr_growth_override: true,
    first_time_cvr: true,
    first_time_purchases_ad_spend: true,
    first_time_purchases_delta: true,
    first_time_purchases_forecasted: true,
    first_time_purchases: true,
    first_time_revenue_ad_spend: true,
    first_time_revenue_delta: true,
    first_time_revenue_forecasted_growth: true,
    first_time_revenue_forecasted: true,
    first_time_revenue_growth: true,
    first_time_revenue: true,
    id: true,
    repeat_purchase_aov_ad_spend: true,
    repeat_purchase_aov_adjustment: true,
    repeat_purchase_aov_delta: true,
    repeat_purchase_aov_forecasted: true,
    repeat_purchase_aov_growth: true,
    repeat_purchase_aov_growth_override: true,
    repeat_purchase_aov: true,
    repeat_purchase_rate_ad_spend: true,
    repeat_purchase_rate_adjustment: true,
    repeat_purchase_rate_delta: true,
    repeat_purchase_rate_forecasted: true,
    repeat_purchase_rate_growth: true,
    repeat_purchase_rate: true,
    repeat_purchases_ad_spend: true,
    repeat_purchases_adjustment: true,
    repeat_purchases_delta: true,
    repeat_purchases_forecasted: true,
    repeat_purchases_growth: true,
    repeat_purchases_growth_override: true,
    repeat_purchases: true,
    repeat_revenue_ad_spend: true,
    repeat_revenue_delta: true,
    repeat_revenue_forecasted_growth: true,
    repeat_revenue_forecasted: true,
    repeat_revenue_growth: true,
    repeat_revenue_total: true,
    repeat_revenue: true,
    time_between_purchases_adjustment: true,
    time_between_purchases_growth: true,
    time_between_purchases_forecasted: true,
    total_purchases_ad_spend: true,
    total_purchases_delta: true,
    total_purchases_forecasted: true,
    total_purchases: true,
    total_revenue_ad_spend: true,
    total_revenue_delta: true,
    total_revenue_forecasted_growth: true,
    total_revenue_forecasted: true,
    total_revenue_growth: true,
    total_revenue: true,
    updated_at: true,
    users_ad_spend_lift: true,
    users_ad_spend: true,
    users_delta: true,
    users_efficiency_lift: true,
    users_forecasted_growth: true,
    users_forecasted: true,
    users_growth: true,
    users_growth_override: true,
    users_trending_ad_spend: true,
    users_trending: true,
    users: true,
  };
  defaultForecastProjection = {
    id: true,
    name: true,
    author: { id: true, name: true },
    audit: {
      id: true,
      name: true,
      client: {
        id: true,
        name: true,
      },
      discovery: {
        scores: {
          name: true,
          id: true,
          department: {
            name: true,
          },
          buckets: {
            name: true,
            id: true,
            score: true,
            max: true,
          }
        }
      }
    },
    model: { id: true },
    notes: defaultNotesProjection,
    status: true,
    is_active: true,
    date: true,
    length: true,
    goal: true,
    revenue_trending: true,
    revenue_forecasted: true,
    purchases_trending: true,
    purchases_forecasted: true,
    growth_trending: true,
    growth_forecasted: true,
    current_year_revenue: true,
    next_year_revenue: true,
    next_year_cac: true,
    users: true,
    first_time_purchases: true,
    first_time_cvr: true,
    first_time_revenue: true,
    first_time_aov: true,
    repeat_purchases: true,
    repeat_revenue: true,
    channels: {
      needle_movers: this.defaultForecastNeedleMoversProjection,
      notes: defaultNotesProjection,
      id: true,
      name: true,
      slug: true,
      is_active: true,
      services: {
        id: true,
        name: true,
      },
      previous_months: {
        date: true,
        id: true,
        users: true,
      },
      months: { ...this.defaultForecastMonthsProjection },
      users: true,
      users_ad_spend: true,
      users_forecasted: true,
      users_ad_spend_lift: true,
      users_efficiency_lift: true,
      created_at: true,
      updated_at: true,
    },
    months: { ...this.defaultForecastMonthsProjection },
    previous_months: {
      date: true,
      users: true,
      first_time_cvr: true,
      first_time_aov: true,
      first_time_purchases: true,
      first_time_revenue: true,
      repeat_purchase_aov: true,
      repeat_purchase_rate: true,
      time_between_purchases: true,
      repeat_purchases: true,
      repeat_revenue: true,
      total_purchases: true,
      total_revenue: true,
    },
    totals: {
      ...this.defaultForecastMonthsProjection,
      id: false,
    },
    previous_totals: {
      users: true,
      first_time_cvr: true,
      first_time_aov: true,
      first_time_purchases: true,
      first_time_revenue: true,
      repeat_purchase_aov: true,
      repeat_purchase_rate: true,
      repeat_purchases: true,
      repeat_revenue: true,
      total_purchases: true,
      total_revenue: true,
    },
    created_at: true,
    updated_at: true,
  };
  defaultAuditProjection = {
    id: true,
    name: true,
    notes: true,
    client: {
      id: true,
      name: true,
      website: true,
      business_type: { id: true },
      industry: { id: true },
      lead_source: { id: true },
      contacts: {
        id: true,
        name: true,
        email: true,
        title: true,
        linkedin: true,
        notes: true,
        is_decision_maker: true,
      },
      accounts: {
        id: true,
        url: true,
        value2: true,
        progress: true,
        account: {
          id: true,
          name: true,
          slug: true,
        },
      },
    },
    author: { id: true, name: true },
    pillar: { id: true, name: true },
    rules: {
      id: true,
      name: true,
      criteria: {
        entity: {
          id: true,
          name: true,
        },
        operator: true,
        value: true,
      },
      tasks: {
        id: true,
        name: true,
        service: {
          id: true,
          name: true,
          department: {
            id: true,
            name: true,
          },
        },
      },
    },
    status: true,
    discovery: this.defaultDiscoveryProjection,
    presentation: this.defaultPresentationProjection,
    forecasts: { id: true },
    audit: { id: true },
    audits: {
      id: true,
      name: true,
      discovery: {
        id: true,
        analyst_score: true,
        analyst_score_max: true,
        departments: {
          id: true,
          name: true,
          score: true,
          max: true,
          in_survey: true,
          created_at: true,
          scores: {
            id: true,
            name: true,
            score: true,
            max: true,
            services: {
              id: true,
              name: true,
            },
            buckets: {
              id: true,
              name: true,
              score: true,
              max: true,
            },
          },
        },
        services: {
          id: true,
          name: true,
          in_survey: true,
          department: {
            id: true,
            name: true
          }
        }
      }
    },
    created_at: true,
    updated_at: true,
    strategy: {
      id: true,
      name: true,
      pricing_version: {
        slug: true
      },
      tasks: {
        id: true,
        name: true,
      },
    },
    salesforce_opportunity: {
      id: true,
      salesforce_id: true,
      stage: {
        id: true,
        name: true,
        slug: true,
      },
      type: true,
      reason: true,
      explanation: true,
      close_date: true,
      meeting_date: true,
    }
  };

  async fetchAudits({
    args: {
      id,
      name,
      author_id,
      sort,
      status,
      status_in,
      survey_status,
      survey_status_in,
      ...args
    } = {},
    pagination: { page, limit } = this.defaultPagination,
    projection,
  }: FetchManyPaginatedConfig & {
    args?: FetchAuditsFilter;
  }): Promise<PaginatedResponse & { audits: Partial<Audit>[] }> {
    const __args = {
      page,
      limit,
      id,
      name,
      author_id,
      sort,
      status,
      status_in,
      survey_status,
      survey_status_in,
      ...args,
    };
    const query = this.toGraphQL({
      query: {
        audits: {
          __args: this.filterUndefinedNullEmpty(__args),
          data: projection || this.defaultAuditProjection,
          ...this.paginationProjection,
        },
      },
    });

    const response = await this.sendRequest(query);

    const {
      audits: { data: audits, ...meta },
    } = response;
    return {
      audits,
      ...meta,
    };
  }

  async fetchAuditById(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ audits: Partial<Audit>[] }> {
    const __args = { id };
    const query = this.toGraphQL({
      query: {
        audit: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultAuditProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    const audit = response.audit;

    return { audits: [audit] };
  }

  async updateAudit(
    args: AuditFormArgs,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ audits: Partial<Audit>[] }> {
    const query = this.toGraphQL({
      mutation: {
        updateAudit: {
          __args: this.filterUndefinedNullEmpty(args, ['strategy_id', 'audit_id', 'audit_ids', 'salesforce_opportunity_id']),
          ...(projection || this.defaultAuditProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { audits: [response.updateAudit] };
  }

  async updateDiscovery(
    {
      id,
      name,
      business_type_id,
      status,
      score,
      analyst_score,
      analyst_score_max,
      notes,
      analyst_notes,
      user_score,
    }: UpdateDiscoveryArgs,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ discoveries: Partial<Discovery>[] }> {
    const __args = {
      id,
      name,
      business_type_id,
      status,
      score,
      analyst_score,
      analyst_score_max,
      notes,
      analyst_notes,
      user_score,
    };
    const query = this.toGraphQL({
      mutation: {
        updateDiscovery: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultDiscoveryProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { discoveries: [response.updateDiscovery] };
  }

  async updateDiscoveryScore(
    { discovery_id, score_id, score, status, notes }: UpdateDiscoveryScoreArgs,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ discoveries: Partial<Discovery>[] }> {
    const __args = {
      discovery_id,
      score_id,
      score,
      status,
      notes,
    };
    const query = this.toGraphQL({
      mutation: {
        updateDiscoveryScore: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultDiscoveryProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { discoveries: [response.updateDiscoveryScore] };
  }

  async updateDiscoveryBucket({
    discovery_id,
    bucket_id,
    score,
  }: {
    discovery_id: number;
    bucket_id: number;
    score?: number;
  }): Promise<void> {
    const __args = {
      discovery_id,
      bucket_id,
      score,
    };
    const query = this.toGraphQL({
      mutation: {
        updateDiscoveryBucket: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
          //name: true,
          score: true,
        },
      },
    });
    await this.sendRequest(query);
  }

  async updateDiscoveryDepartment({
    discovery_id,
    department_id,
    opportunity,
    analyst_notes,
    update_revision,
  }: {
    discovery_id: number;
    department_id: number;
    opportunity?: string;
    analyst_notes?: string;
    update_revision?: YesNo;
  }): Promise<void> {
    const __args = {
      discovery_id,
      department_id,
      opportunity,
      analyst_notes,
      update_revision,
    };
    const query = this.toGraphQL({
      mutation: {
        updateDiscoveryDepartment: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
          opportunity: true,
        },
      },
    });
    await this.sendRequest(query);
  }

  async updateDiscoveryQuestion({
    discovery_id,
    question_id,
    answer,
  }: {
    discovery_id: number;
    question_id: number;
    answer?: string;
  }): Promise<void> {
    const __args = {
      discovery_id,
      question_id,
      answer,
    };
    const query = this.toGraphQL({
      mutation: {
        updateDiscoveryQuestion: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
        },
      },
    });
    await this.sendRequest(query);
  }

  async updateDiscoveryConsideredServices({
    discovery_id,
    considered_services,
  }: {
    discovery_id: number;
    considered_services: number[];
  }): Promise<void> {
    const __args = {
      discovery_id,
      considered_services,
    };
    const query = this.toGraphQL({
      mutation: {
        updateDiscoveryConsideredServices: {
          __args: __args,
          id: true,
        },
      },
    });
    await this.sendRequest(query);
  }

  async deleteDiscoveryQuestion({
    discovery_id,
    question_id,
  }: {
    discovery_id: number;
    question_id: number;
  }): Promise<void> {
    const __args = {
      discovery_id,
      question_id,
    };
    const query = this.toGraphQL({
      mutation: {
        deleteDiscoveryQuestion: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
        },
      },
    });
    await this.sendRequest(query);
  }

  async updateDiscoveryTopic({
    discovery_id,
    topic_id,
    notes,
  }: {
    discovery_id: number;
    topic_id: number;
    notes?: string;
  }): Promise<void> {
    const __args = {
      discovery_id,
      topic_id,
      notes,
    };
    const query = this.toGraphQL({
      mutation: {
        updateDiscoveryTopic: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
        },
      },
    });
    await this.sendRequest(query);
  }

  async saveDiscoveryProducts({
    discovery_id,
    products,
    delete_missing,
  }: {
    discovery_id: number;
    products: Product[];
    delete_missing: string;
  }): Promise<void> {
    const __args = {
      discovery_id,
      products,
      delete_missing,
    };

    const query = this.toGraphQL({
      mutation: {
        saveDiscoveryProducts: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
        },
      },
    });
    await this.sendRequest(query);
  }

  async saveDiscoveryChannels({
    discovery_id,
    channels,
    delete_missing,
  }: {
    discovery_id?: number;
    channels: Channel[];
    delete_missing: string;
  }): Promise<void> {
    const __args = {
      discovery_id,
      channels,
      delete_missing,
    };

    const query = this.toGraphQL({
      mutation: {
        saveDiscoveryChannels: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
        },
      },
    });
    await this.sendRequest(query);
  }

  async saveDiscoveryLeadChannels({
    discovery_id,
    lead_channels,
    delete_missing,
  }: {
    discovery_id: number;
    lead_channels: LeadChannel[];
    delete_missing: string;
  }): Promise<void> {
    const __args = {
      discovery_id,
      lead_channels,
      delete_missing,
    };

    const query = this.toGraphQL({
      mutation: {
        saveDiscoveryLeadChannels: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
        },
      },
    });
    await this.sendRequest(query);
  }

  async createDiscoveryServices({
    discovery_id,
    services,
    delete_missing,
  }: {
    discovery_id: number;
    services: ServiceInput[];
    delete_missing?: string;
  }): Promise<void> {
    const query = this.toGraphQL({
      mutation: {
        createDiscoveryServices: {
          __args: this.filterUndefinedNullEmpty(
            {
              discovery_id,
              services,
              delete_missing,
            }
          ),
          id: true,
        },
      },
    });

    const { createDiscoveryServices } = await this.sendRequest(query);

    return createDiscoveryServices;
  }

  async saveDiscoveryServices({
    discovery_id,
    services,
    delete_missing,
  }: {
    discovery_id: number;
    services: Service[];
    delete_missing: string;
  }): Promise<void> {
    const __args = {
      discovery_id,
      services,
      delete_missing,
    };

    const query = this.toGraphQL({
      mutation: {
        saveDiscoveryServices: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
        },
      },
    });
    await this.sendRequest(query);
  }

  async saveDiscoveryGoals({
    discovery_id,
    goals,
    delete_missing,
  }: {
    discovery_id: number;
    goals: DiscoveryGoal[];
    delete_missing: string;
  }): Promise<void> {
    const __args = {
      discovery_id,
      goals,
      delete_missing,
    };

    const query = this.toGraphQL({
      mutation: {
        saveDiscoveryGoals: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
        },
      },
    });
    await this.sendRequest(query);
  }

  async saveDiscoveryGrowths({
    discovery_id,
    growths,
    delete_missing,
  }: {
    discovery_id: number;
    growths: Growth[];
    delete_missing: string;
  }): Promise<void> {
    const __args = {
      discovery_id,
      growths,
      delete_missing,
    };

    const query = this.toGraphQL({
      mutation: {
        saveDiscoveryGrowths: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
        },
      },
    });
    await this.sendRequest(query);
  }

  async saveDiscoveryBudgets({
    discovery_id,
    budgets,
    delete_missing,
  }: {
    discovery_id: number;
    budgets: Budget[];
    delete_missing: string;
  }): Promise<void> {
    const __args = {
      discovery_id,
      budgets,
      delete_missing,
    };

    const query = this.toGraphQL({
      mutation: {
        saveDiscoveryBudgets: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
        },
      },
    });
    await this.sendRequest(query);
  }

  async saveDiscoveryCustomers({
    discovery_id,
    customers,
    delete_missing,
  }: {
    discovery_id: number;
    customers: Customer[];
    delete_missing: string;
  }): Promise<void> {
    const __args = {
      discovery_id,
      customers,
      delete_missing,
    };

    const query = this.toGraphQL({
      mutation: {
        saveDiscoveryCustomers: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
        },
      },
    });
    await this.sendRequest(query);
  }

  async saveDiscoveryDepartments({
    discovery_id,
    departments,
    delete_missing,
  }: {
    discovery_id: number;
    departments: Partial<Department>[];
    delete_missing: string;
  }): Promise<void> {
    const __args = {
      discovery_id,
      departments,
      delete_missing,
    };

    const query = this.toGraphQL({
      mutation: {
        saveDiscoveryDepartments: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
        },
      },
    });
    await this.sendRequest(query);
  }

  async saveDiscoveryCompetitors({
    discovery_id,
    competitors,
    delete_missing,
  }: {
    discovery_id: number;
    competitors: Competitor[];
    delete_missing: string;
  }): Promise<void> {
    const __args = {
      discovery_id,
      competitors,
      delete_missing,
    };

    const query = this.toGraphQL({
      mutation: {
        saveDiscoveryCompetitors: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
        },
      },
    });
    await this.sendRequest(query);
  }

  async createAudit(
    {
      audit_id,
      audit_ids,
      client_id,
      client_name,
      website,
      tier_id,
      business_type_id,
      industry_id,
      lead_source_id,
      pillar_id,
      name,
      create_discovery,
      status,
      notes,
    }: CreateAuditArgs,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ audits: Partial<Audit>[] }> {
    const __args = {
      audit_ids,
      audit_id,
      client_id,
      client_name,
      website,
      tier_id,
      business_type_id,
      industry_id,
      lead_source_id,
      pillar_id,
      name,
      create_discovery,
      status,
      notes,
    };
    const query = this.toGraphQL({
      mutation: {
        createAudit: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultAuditProjection),
        },
      },
    });
    const response = await this.sendRequest(query);
    return { audits: [response.createAudit] };
  }

  async deleteAudit(id: number) {
    const query = this.toGraphQL({
      mutation: {
        deleteAudit: {
          __args: { id },
          id: true,
        },
      },
    });
    const response = await this.sendRequest(query);
    return { audits: [response.deleteAudit] };
  }

  /**
   * Presentations
   */
  async createPresentation(
    {
      audit_id,
      client_id,
      name,
      status,
      deck_url,
      templates,
      variables,
      client_name,
      website,
      logo_path,
      notes,
      business_type_id,
      integrations,
    }: {
      audit_id?: number;
      client_id?: number;
      name?: string;
      status?: string;
      deck_url?: string;
      templates: Array<number>;
      variables: PresentationTemplateVariable[];
      client_name: string;
      website?: string;
      logo_path?: string;
      notes?: string;
      business_type_id: number;
      integrations?: IntegrationInput[];
    },
    { projection }: { projection?: Projection } = {}
  ) {
    const __args = {
      audit_id,
      client_id,
      name,
      status,
      deck_url,
      templates,
      variables,
      client_name,
      website,
      logo_path,
      notes,
      business_type_id,
      integrations,
    };
    const query = this.toGraphQL({
      mutation: {
        createPresentation: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultPresentationProjection),
        },
      },
    });
    const response = await this.sendRequest(query);
    return { presentations: [response.createPresentation] };
  }
  async updatePresentation(
    {
      id,
      name,
      status,
      deck_url,
      logo_path,
      notes,
      percent,
      templates,
      variables,
      integrations,
      regenerate
    }: {
      id?: number;
      name?: string;
      status?: string;
      deck_url?: string;
      logo_path?: string;
      notes?: string;
      percent?: number;
      templates: Array<number>;
      variables: PresentationTemplateVariable[];
      integrations?: IntegrationInput[];
      regenerate?: boolean;
    },
    { projection }: { projection?: Projection } = {}
  ) {
    const __args = {
      id,
      name,
      status,
      deck_url,
      logo_path,
      notes,
      percent,
      templates,
      variables,
      integrations,
      regenerate
    };
    const query = this.toGraphQL({
      mutation: {
        updatePresentation: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultPresentationProjection),
        },
      },
    });
    const response = await this.sendRequest(query);
    return { presentations: [response.updatePresentation] };
  }

  /**
   * Forecasts
   */
  async createForecast(
    {
      audit_id,
      name,
      length,
      date,
      channel_ids,
      ad_spend_lift,
      efficiency_lift,
      model_id,
    }: CreateForecastFormValues,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ forecasts: Partial<Forecast>[] }> {
    const __args = {
      audit_id,
      name,
      length,
      date,
      channel_ids,
      ad_spend_lift,
      efficiency_lift,
      model_id,
    };
    const query = this.toGraphQL({
      mutation: {
        createForecast: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultForecastProjection),
        },
      },
    });
    const response = await this.sendRequest(query);
    return { forecasts: [response.createForecast] };
  }

  async updateForecast(
    args: UpdateForecastArgs,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ forecasts: Partial<Forecast>[] }> {
    const query = this.toGraphQL({
      mutation: {
        updateForecast: {
          __args: this.filterUndefinedNullEmpty(args),
          ...(projection || this.defaultForecastProjection),
        },
      },
    });
    const response = await this.sendRequest(query);
    return { forecasts: [response.updateForecast] };
  }

  async createForecastChannel(
    args: UpdateForecastChannelArgs,
    { projection }: { projection?: Projection } = {}
  ): Promise<ForecastChannel> {
    const query = this.toGraphQL({
      mutation: {
        createForecastChannel: {
          __args: this.filterUndefinedNullEmpty(args),
          ...(projection || this.defaultForecastChannelsProjection),
        },
      },
    });
    const { createForecastChannel } = await this.sendRequest(query);
    return createForecastChannel;
  }

  async updateForecastChannel(
    { projection, ...args }: UpdateForecastChannelArgs & { projection?: Projection }
  ): Promise<ForecastChannel> {
    const query = this.toGraphQL({
      mutation: {
        updateForecastChannel: {
          __args: this.filterUndefinedNullEmpty(args),
          ...(projection || this.defaultForecastProjection['channels']),
        },
      },
    });
    const { updateForecastChannel } = await this.sendRequest(query);
    return updateForecastChannel;
  }

  async updateForecastMonth(
    args: UpdateForecastMonthArgs,
    { projection }: { projection?: Projection } = {}
  ): Promise<ForecastMonth[]> {
    const query = this.toGraphQL({
      mutation: {
        updateForecastMonth: {
          __args: this.filterUndefinedNullEmpty(args, [
            'first_time_aov_growth_override',
            'first_time_cvr_growth_override',
            'repeat_purchase_aov_growth_override',
            'repeat_purchases_growth_override',
            'users_growth_override',
          ]),
          ...(projection || this.defaultForecastProjection['months']),
        },
      },
    });
    const { updateForecastMonth: forecastMonth } = await this.sendRequest(query);
    return forecastMonth;
  }

  async fetchForecastById(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ forecasts: Partial<Forecast>[] }> {
    const __args = { id };
    const query = this.toGraphQL({
      query: {
        forecast: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultForecastProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { forecasts: [response.forecast] };
  }

  defaultForecastChannelsProjection = {
    id: true,
    name: true,
    is_active: true,
    ad_spend_lift: true,
    efficiency_lift: true,
    months: this.defaultForecastMonthsProjection,
    services: {
      id: true,
      name: true,
    },
  };

  async fetchForecastChannels({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { forecast_channels: ForecastChannel[] }> {
    const query = this.toGraphQL({
      query: {
        forecast_channels: projection || this.defaultForecastChannelsProjection,
      },
    });

    const response = await this.sendRequest(query);
    const { forecast_channels, ...meta } = response;

    return {
      forecast_channels,
      ...meta,
    };
  }

  defaultForecastModelsProjection = {
    id: true,
    name: true,
    status: true,
  };

  async fetchForecastModels({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { forecast_models: ForecastModel[] }> {
    const query = this.toGraphQL({
      query: {
        forecast_models: projection || this.defaultForecastModelsProjection,
      },
    });

    const response = await this.sendRequest(query);
    const { forecast_models, ...meta } = response;

    return {
      forecast_models,
      ...meta,
    };
  }

  defaultForecastBacktestsProjection = {
    id: true,
    name: true,
    slug: true,
    inputs: true,
    status: true,
    clients: true,
    forecasts: {
      id: true,
      name: true,
      audit: {
        id: true,
        client: {
          name: true,
        }
      },
      date: true,
      length: true
    },
    accuracy_by_month: true,
    users_accuracy_by_month: true,
    revenue_accuracy_by_month: true,
    accuracy_by_client: true,
    users_accuracy_by_client: true,
    revenue_accuracy_by_client: true,
    created_at: true,
  };

  async fetchForecastingBacktests({
    args: { id, name } = {},
    pagination: { page, limit } = this.defaultPagination,
    projection,
  }: FetchManyPaginatedConfig & {
    args?: { id?: number; name?: string };
  }): Promise<PaginatedResponse & { backtests: Backtest[] }> {
    const __args = { page, limit, id, name };
    const query = this.toGraphQL({
      query: {
        backtests: {
          __args: this.filterUndefinedNullEmpty(__args),
          data: projection || this.defaultForecastBacktestsProjection,
          ...this.paginationProjection,
        },
      },
    });

    const response = await this.sendRequest(query);
    const {
      backtests: { data: backtests, ...meta },
    } = response;
    return {
      backtests,
      ...meta,
    };
  }

  async createForecastingBacktest(
    { backtest_id, name, inputs }: CreateBacktestFormValues,
    { projection }: { projection?: Projection } = {}
  ): Promise<Backtest> {
    const __args = { backtest_id, name, inputs };
    const query = this.toGraphQL({
      mutation: {
        createBacktest: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultForecastBacktestsProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return response.createBacktest;
  }

  async updateForecastingBacktest(
    { id }: { id: number },
    { projection }: { projection?: Projection } = {}
  ): Promise<Backtest> {
    const __args = { id };
    const query = this.toGraphQL({
      mutation: {
        updateBacktest: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultForecastBacktestsProjection),
        },
      },
    });
    const response = await this.sendRequest(query);
    return response.updateBacktest;
  }

  async deleteForecastingBacktest(
    { id }: { id: number },
    { projection }: { projection?: Projection } = {}
  ): Promise<Backtest> {
    const __args = { id };
    const query = this.toGraphQL({
      mutation: {
        deleteBacktest: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultForecastBacktestsProjection),
        },
      },
    });
    const response = await this.sendRequest(query);
    return response.deleteBacktest;
  }

  async createForecastNeedleMovers(
    needleMovers: CreateForecastNeedleMoversArgs[],
    { projection }: { projection?: Projection } = {}
  ): Promise<ForecastNeedleMover[]> {
    const requests = needleMovers.map(args => this.sendRequest(
      this.toGraphQL({
        mutation: {
          createNeedleMover: {
            __args: this.filterUndefinedNullEmpty(args),
            ...(projection || this.defaultForecastNeedleMoversProjection),
          },
        },
      })
    ));

    const response = await Promise.all(requests);
    return response.map(({ createNeedleMover }) => createNeedleMover);
  }

  async deleteForecastNeedleMovers(
    needleMoverIds: number[],
    { projection }: { projection?: Projection } = {}
  ): Promise<ForecastNeedleMover[]> {
    const requests = needleMoverIds.map(id => this.sendRequest(
      this.toGraphQL({
        mutation: {
          deleteNeedleMover: {
            __args: {
              id
            },
            ...(projection || this.defaultForecastNeedleMoversProjection),
          },
        },
      })
    ));

    const response = await Promise.all(requests);
    return response.map(({ deleteNeedleMover }) => deleteNeedleMover);
  }

  /**
   * Users
   */
  defaultUserProjection = {
    id: true,
    name: true,
    email: true,
    avatar: true,
    permissions: false, // TODO: Confirm API field availability
    roles: true, // TODO: Confirm API field availability
    title: true,
    is_fulltime: true,
    manager: { id: true, name: true },
    department: { id: true, name: true, slug: true },
    departments: { id: true, name: true, slug: true },
    organization: { id: true, name: true, slug: true },
    survey_type: { id: true, name: true },
    commission_override: true,
    managees: {
      id: true,
      name: true,
    },
    managers: {
      id: true,
      name: true,
    },
    reviewees: {
      id: true,
      name: true,
    },
    surveys: {
      id: true,
      score: true,
      max: true,
      status: true,
      type: {
        name: true,
      },
      user: {
        id: true,
        name: true,
        avatar: true,
        roles: true,
      },
      author: {
        name: true,
      },
      updated_at: true,
    },
    created_at: true,
    updated_at: true,
  };
  async fetchUsers({
    args: { id, name, department_id, roles } = {},
    pagination: { page, limit, sort } = this.defaultPagination,
    projection,
  }: FetchManyPaginatedConfig & {
    args?: { id?: number; name?: string, department_id?: number, roles?: Role[]; };
  }): Promise<PaginatedResponse & { users: Partial<User>[] }> {
    const __args = { page, limit, id, name, department_id, roles, sort };
    const query = this.toGraphQL({
      query: {
        users: {
          __args: this.filterUndefinedNullEmpty(__args),
          data: projection || this.defaultUserProjection,
          ...this.paginationProjection,
        },
      },
    });

    const response = await this.sendRequest(query);
    const {
      users: { data: users, ...meta },
    } = response;
    return {
      users,
      ...meta,
    };
  }

  async fetchUserById(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ users: Partial<User>[] }> {
    const { users } = await this.fetchUsers({
      args: { id },
      pagination: { page: 1, limit: 1 },
      projection,
    });
    return { users };
  }

  async updateUser(
    {
      id,
      name,
      email,
      avatar,
      title,
      is_fulltime,
      roles,
      manager_id,
      managers,
      managees,
      reviewees,
      department_id,
      departments,
      organization_id,
      survey_type_id,
      commission_override,
    }: UpdateUserArgs,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ users: Partial<User>[] }> {
    const __args = {
      id,
      name,
      email,
      avatar,
      title,
      is_fulltime,
      roles,
      manager_id,
      managers,
      managees,
      reviewees,
      department_id,
      departments,
      organization_id,
      survey_type_id,
      commission_override,
    };

    const query = this.toGraphQL({
      mutation: {
        updateUser: {
          __args: this.filterUndefinedNullEmpty(__args, ['commission_override', 'managers', 'managees', 'reviewees']),
          ...(projection || this.defaultUserProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { users: [response.updateUser] };
  }

  async deleteClientUser(
    {
      user_id,
      client_id,
    }: DeleteClientUserApiParameters): Promise<Pick<User, 'id' | 'name'>> {
    const query = this.toGraphQL({
      mutation: {
        deleteClientUser: {
          __args: {
            user_id,
            client_id,
          },
          ...{ id: true, name: true },
        },
      },
    });

    const { deleteClientUser } = await this.sendRequest(query);
    return deleteClientUser;
  }

  async deleteUser(id: number): Promise<User> {
    const query = this.toGraphQL({
      mutation: {
        deleteUser: {
          __args: { id },
          ...{ id: true, name: true }
        }
      }
    });
    const { deleteUser } = await this.sendRequest(query);
    return deleteUser;
  }

  async createClientUser({
    type_of_email,
    client_id,
    email,
    name,
    is_pdm_employee,
    data_source_ids
  }: CreateClientUserApiParameters ): Promise<User> {
    const query = this.toGraphQL({
      mutation: {
        createClientUser: {
          __args: this.filterUndefinedNullEmpty({
            type_of_email,
            client_id,
            email,
            name,
            is_pdm_employee,
            data_source_ids,
          }),
          ...{ id: true, name: true, email: true },
        },
      },
    });

    const { createClientUser } = await this.sendRequest(query);
    return createClientUser;
  }

  /**
   * Tasks
   */
  defaultTaskProjection = {
    id: true,
    name: true,
    hours: true,
    hours_per_unit: true,
    executive_summary: true,
    projected_hours: true,
    outsource_budget: true,
    recommended_outsource_budget: true,
    is_sub_task: true,
    is_configurable: true,
    unit_type: true,
    snippet: true,
    minimum_hours: true,
    maximum_hours: true,
    recommended_hours: true,
    control_type: true,
    children: {
      name: true,
      id: true,
      hours: true,
      service: {
        id: true,
        name: true,
        department: {
          id: true,
          name: true,
        },
      },
    },
    service: {
      id: true,
      name: true,
      department: {
        id: true,
        name: true,
      },
    },
    multiplier: {
      name: true,
      slug: true,
      id: true,
    },
    pricing_version: {
      name: true,
      slug: true,
    },
    strategy_frequency: {
      id: true,
      name: true,
      slug: true,
    },
    pricing_type: {
      id: true,
      name: true,
      slug: true,
    },
    pricing_tiers: {
      id: true,
      name: true,
      deliverable: true,
      snippet: true,
      price: true,
      quantity: true,
      quantity_price: true,
      spend_min: true,
      spend_max: true,
    }
  };

  async fetchTasks({
    filter = {},
    pricing_version,
    projection,
  }: {
    pricing_version?: string;
    projection?: Projection;
    filter?: FetchTasksFilter;
  }): Promise<Response & { tasks: Task[] }> {
    const __args = filter;

    __args.pricing_version = pricing_version;
    // Fuzzy search
    if (__args.name) {
      __args.name = `*${__args.name}*`;
    }

    const query = this.toGraphQL({
      query: {
        tasks: {
          ...(Object.keys(__args).length ? { __args } : {}),
          ...(projection || this.defaultTaskProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return response;
  }

  async fetchTaskById(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ tasks: Partial<Task>[] }> {
    const __args = { id };
    const query = this.toGraphQL({
      query: {
        tasks: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultTaskProjection),
        },
      },
    });

    const { tasks } = await this.sendRequest(query);
    return { tasks };
  }

  async createTask(
    values: Partial<TaskFormValues>,
    pricing_version?: string,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ tasks: Partial<Task>[] }> {
    const query = this.toGraphQL({
      mutation: {
        createTask: {
          __args: {
            ...this.filterUndefinedNullEmpty(values),
            pricing_version: pricing_version || values.pricing_version,
            /** Allow nullable unit_type */
            unit_type: values.unit_type ? values.unit_type : null
          },
          ...(projection || this.defaultTaskProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { tasks: [response.createTask] };
  }

  async updateTask(
    values: Partial<TaskFormValues>,
    { projection }: { projection?: Projection } = {}
  ) {
    const query = this.toGraphQL({
      mutation: {
        updateTask: {
          __args: this.filterUndefinedNullEmpty(values, ['children']),
          ...(projection || this.defaultTaskProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { tasks: [response.updateTask] };
  }

  async deleteTask(id: number) {
    const query = this.toGraphQL({
      mutation: {
        deleteTask: {
          __args: { id },
          id: true,
        },
      },
    });
    const response = await this.sendRequest(query);
    return { tasks: [response.deleteTask] };
  }

  /**
   * Strategies
   */
  defaultStrategyMonthProjection = {
    cost: true,
    date: true,
    hour: true,
    hours: true,
    management_hours: true,
    suggested_hourly_rate: true,
    id: true,
    name: true,
    profit: true,
    retainer: true,
    suggestion: true,
    hourly_rate: true,
    costs: true,
    value: true,
  };

  defaultStrategyCostProjection = {
    id: true,
    name: true,
    notes: true,
    months: this.defaultStrategyMonthProjection,
    created_at: true,
    updated_at: true,
    department: {
      name: true,
      id: true,
      slug: true,
    },
  };

  defaultStrategyServiceSummaryProjection = {
    id: true,
    name: true,
    months: {
      id: true,
      name: true,
      value: true,
      date: true,
      hours: true,
    },
  };

  defaultStrategyTaskProjection = {
    id: true,
    name: true,
    notes: true,
    hours: true,
    projected_hours: true,
    is_configurable: true,
    hours_per_unit: true,
    unit_type: true,
    units: true,
    order: true,
    months: this.defaultStrategyMonthProjection,
    snippet: true,
    service: {
      id: true,
      name: true,
      department: {
        name: true,
        id: true,
      },
    },
    multiplier: {
      name: true,
      slug: true,
      id: true,
    },
    created_at: true,
    updated_at: true,
    control_type: true,
    minimum_hours: true,
    maximum_hours: true,
    recommended_hours: true,
    slug: true
  };

  defaultStrategyProjection = {
    ad_spend: true,
    adjusted_commission_percent: true,
    aging: true,
    id: true,
    name: true,
    billing_name: true,
    billing_phone: true,
    billing_email: true,
    billing_address: true,
    billing_city: true,
    billing_state: true,
    billing_zip: true,
    billing_country: true,
    created_at: true,
    commission_flat_fee: true,
    commission_percent_adjustment: true,
    commission_percent: true,
    commission_version: true,
    default_base_commission: true,
    date: true,
    costs: this.defaultStrategyCostProjection,
    months: this.defaultStrategyMonthProjection,
    tasks: this.defaultStrategyTaskProjection,
    salesforce_opportunity_id: true,
    salesforce_opportunity_name: true,
    salesforce_opportunity_link: true,
    salesforce_is_primary: true,
    salesforce_opportunity: {
      reason: true,
      explanation: true,
      close_date: true
    },
    lost_reason_type: true,
    follow_up_date: true,
    detailed_reason: true,
    reviewed_at: true,
    reviewer: { id: true, name: true },
    // reviewer_status: true,
    grade: {
      name: true,
      hourly_rate: true,
    },
    multipliers: {
      hours: true,
      id: true,
      name: true,
      value: true,
    },
    author: {
      name: true,
      id: true,
      commission_override: true,
    },
    executive_sponsor: {
      name: true,
      id: true,
    },
    account_manager: {
      name: true,
      id: true,
    },
    business_type: {
      name: true,
      id: true,
    },
    client: {
      id: true,
      name: true,
      netsuite_id: true,
      salesforce_client_id: true,
      industry: {
        id: true,
        name: true,
      },
      organization: {
        id: true,
        name: true,
      },
      business_type: {
        id: true,
        slug: true,
        name: true,
      },
      tier:{
        name: true,
      },
    },
    users: {
      id: true,
      name: true,
      commission_percent: true,
      strategy_commission: true,
      department: {
        name: true,
      },
    },
    lead_source: {
      name: true,
      id: true,
      slug: true,
    },
    lead_source_employee: {
      name: true,
      id: true,
    },
    partner: {
      name: true,
      company: true,
      id: true,
      percent: true,
    },
    pillar: {
      id: true,
      name: true,
      slug: true,
    },
    audit: {
      id: true,
      name: true,
      audits: {
        id: true,
        strategy: {
          id: true,
          name: true,
          date: true,
          length: true,
          monthly_gross_profit: true,
          total_retainer: true,
          tasks: {
            name: true,
            service: {
              name: true,
              id: true,
            }
          },
          departments: {
            name: true,
            id: true,
          },
          client: {
            name: true,
          },
        }
      }
    },
    proposal_url: true,
    embed_url: true,
    hourly_rate: true,
    target_hourly_rate: true,
    length: true,
    monthly_gross_profit: true,
    sync_to_salesforce: true,
    type: true,
    total_commission: true,
    total_commission_expense: true,
    total_executive: true,
    total_executive_expense: true,
    total_expenses: true,
    total_gross_profit: true,
    total_partner: true,
    total_partner_expense: true,
    total_profit: true,
    total_real_profit: true,
    total_hours: true,
    total_costs: true,
    total_retainer: true,
    status: true,
    departments: {
      name: true,
      id: true,
      reviewer: {
        id: true,
        name: true,
      },
      reviewer_status: true,
      reviewed_at: true ,
      require_commission_approval: true,
    },
    deny_reason: true,
    revenue_calculation_version: true,
    department_summary:
      {
        ...this.defaultStrategyServiceSummaryProjection,
      },
    service_summary: {
      ...this.defaultStrategyServiceSummaryProjection,
      department: { id: true, name: true },
    },
    guaranteed_term: true,
    salesforce_connection_status: true,
    presentation: {
      deck_url: true,
    },
    finance_summary: {
      department_name: true,
      service_name: true,
      type: true,
      months: {
        date: true,
        value: true
      }
    },
    finance_reviewer: {
      id: true,
      name: true,
    },
    finance_reviewer_status: true,
    finance_reviewed_at: true,
    parent: {
      id: true
    }
  };

  formatStrategyArgs = (values: Record<string, unknown>, exclude: string[] = []) =>
    this.filterUndefinedNullEmpty(
      omit(values, exclude),
      // Optional fields (can be reset)
      [
        'executive_sponsor_id',
        'account_manager_id',
        'partner_id',
        'audit_id',
        'departments',
        'salesforce_is_primary',
        'salesforce_opportunity_id',
        'salesforce_opportunity_name',
        'commission_flat_fee',
        'commission_percent_adjustment',
        'guaranteed_term',
        'ad_spend',
      ]
    );

  async fetchStrategies({
    filter,
    pagination: { page, limit } = this.defaultPagination,
    projection,
  }: FetchManyPaginatedConfig & {
    filter?: FetchStrategiesFilter;
  }): Promise<PaginatedResponse & { strategies: Partial<Strategy>[] }> {
    const __args = { page, limit, ...filter };

    // Fuzzy search
    if (__args.name) {
      __args.name = `*${__args.name}*`;
    }

    const query = this.toGraphQL({
      query: {
        strategies: {
          __args: this.filterUndefinedNullEmpty(__args),
          data: projection || this.defaultStrategyProjection,
          ...this.paginationProjection,
        },
      },
    });

    const response = await this.sendRequest(query);
    const {
      strategies: { data: strategies, ...meta },
    } = response;
    return {
      strategies,
      ...meta,
    };
  }

  async fetchStrategyById(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ strategies: Partial<Strategy>[] }> {
    const __args = { id };
    const queryObj = {
      query: {
        strategy: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultStrategyProjection),
        },
      },
    };
    const query = this.toGraphQL(queryObj);
    const response = await this.sendRequest(query);
    const strategy = response.strategy;

    return { strategies: [strategy] };
  }

  async fetchStrategyServiceSummary({
    id,
    round_nearest,
  }: {
    id: number;
    round_nearest: string;
  }): Promise<{ strategies: Partial<Strategy>[] }> {
    const query = this.toGraphQL({
      query: {
        strategy: {
          __args: { id },
          department_summary: {
            __args: { round_nearest },
            ...this.defaultStrategyServiceSummaryProjection,
            calculation_notes: true,
          },
          service_summary: {
            __args: { round_nearest },
            ...this.defaultStrategyServiceSummaryProjection,
          },
        },
      },
    });

    const response = await this.sendRequest(query);
    const strategy = response.strategy;

    return { strategies: [strategy] };
  }

  async createStrategy(
    values: StrategyFormValues,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ strategies: Partial<Strategy>[] }> {
    const query = this.toGraphQL({
      mutation: {
        createStrategy: {
          __args: this.formatStrategyArgs(values, ['author']),
          ...(projection || this.defaultStrategyProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { strategies: [response.createStrategy] };
  }

  async createStrategyDuplicate(
    values: StrategyDuplicateValues,
    { projection }: { projection?: Projection } = {}
  ): Promise<Partial<Strategy>> {
    const query = this.toGraphQL({
      mutation: {
        createStrategyDuplicate: {
          __args: values,
          ...(projection || this.defaultStrategyProjection),
        },
      },
    });

    const { createStrategyDuplicate } = await this.sendRequest(query);
    return createStrategyDuplicate;
  }

  defaultSalesforceDataProjection = {
    nova_monthly_gross_profit: true,
    salesforce_monthly_gross_profit: true,
    nova_length: true,
    salesforce_length: true,
    nova_status: true,
    salesforce_status: true,
    nova_close_date: true,
    salesforce_close_date: true,
    nova_start_date: true,
    salesforce_start_date: true,
    nova_lost_reason: true,
    salesforce_lost_reason: true,
    nova_type_of_deal: true,
    salesforce_type_of_deal: true,
    nova_rollover_date: true,
    salesforce_rollover_date: true,
    nova_rainmaker_link: true,
    salesforce_rainmaker_link: true,
    nova_follow_up_date: true,
    salesforce_follow_up_date: true,
    nova_disqualified_reason: true,
    salesforce_disqualified_reason: true,
    nova_lost_deal_explanation: true,
    salesforce_lost_deal_explanation: true,
    nova_partner_email: true,
    salesforce_partner_email: true,
    nova_total_partner_commission: true,
    salesforce_total_partner_commission: true,
    nova_blueprint_link: true,
    salesforce_blueprint_link: true,
  };

  async salesforceDataToSync(
    id: string,
    { projection }: { projection?: Projection } = {}
  ) {
    const query = this.toGraphQL({
      query: {
        salesforce_data_to_sync: {
          __args: { strategy_id: id },
          ...(projection || this.defaultSalesforceDataProjection),
        },
      },
    });
    const response = await this.sendRequest(query);
    return response.salesforce_data_to_sync;
  }


  async syncNovaToSalesforce(
    id: number,
    { projection }: { projection?: Projection } = {}
  ) {
    const query = this.toGraphQL({
      mutation: {
        syncNovaToSalesforce: {
          __args: { id },
          ...(projection || { id: true, name: true }),
        },
      },
    });
    const response = await this.sendRequest(query);
    return response.syncNovaToSalesforce;
  }

  async updateStrategy(
    values: StrategyFormValues,
    { projection }: { projection?: Projection } = {}
  ) {
    const query = this.toGraphQL({
      mutation: {
        updateStrategy: {
          __args: this.formatStrategyArgs(values, ['client_id', 'author']),
          ...(projection || this.defaultStrategyProjection),
        },
      },
    });
    const response = await this.sendRequest(query);
    return { strategies: [response.updateStrategy] };
  }

  async deleteStrategy(id: number) {
    const query = this.toGraphQL({
      mutation: {
        deleteStrategy: {
          __args: { id },
          id: true,
        },
      },
    });
    const response = await this.sendRequest(query);
    return { strategies: [response.deleteStrategy] };
  }

  async createStrategyProposal(
    values: { id: number, update_salesforce_stage?: number }) {
    const query = this.toGraphQL({
      mutation: {
        createStrategyProposal: {
          __args: values,
          id: true,
          proposal_url: true,
          salesforce_opportunity_id: true,
          salesforce_is_primary: true,
          status: true,
        },
      },
    });

    const { createStrategyProposal: strategy } = await this.sendRequest(query);
    return strategy;
  }

  async createStrategyTask(
    values: Partial<
      StrategyTask & {
        strategy_id: number;
        task: number;
      }
    >,
    { projection }: { projection?: Projection } = {}
  ): Promise<Partial<Strategy>> {
    const query = this.toGraphQL({
      mutation: {
        createStrategyTask: {
          __args: values,
          ...(projection || {
            id: true,
            tasks: this.defaultStrategyTaskProjection,
          }),
        },
      },
    });

    const { createStrategyTask: strategy } = await this.sendRequest(query);
    return strategy;
  }

  async createStrategyTasks(
    values: {
      id: number;
      tasks: Partial<TaskInput> & { id: number }[];
    },
    { projection }: { projection?: Projection } = {}
  ): Promise<Strategy> {
    const query = this.toGraphQL({
      mutation: {
        createStrategyTasks: {
          __args: values,
          ...(projection || {
            id: true,
            tasks: this.defaultStrategyTaskProjection,
          }),
        },
      },
    });

    const { createStrategyTasks: strategy } = await this.sendRequest(query);
    return strategy;
  }

  async updateStrategyTask(
    values: Partial<StrategyTask> & { task_id: number; strategy_id: number },
    config: { projection?: Projection } = {}
  ) {
    const query = this.toGraphQL({
      mutation: {
        updateStrategyTask: {
          __args: values,
          ...(config.projection || {
            id: true,
            tasks: this.defaultStrategyTaskProjection,
          }),
        },
      },
    });

    const { updateStrategyTask: strategy } = await this.sendRequest(query);
    return strategy;
  }

  async deleteStrategyTask(
    {
      task_id,
      strategy_id,
    }: {
      task_id: number;
      strategy_id: number;
    },
    { projection }: { projection?: Projection } = {}
  ): Promise<Partial<StrategyCost>> {
    const query = this.toGraphQL({
      mutation: {
        deleteStrategyTask: {
          __args: {
            task_id,
            strategy_id,
          },
          ...(projection || { id: true }),
        },
      },
    });

    const { deleteStrategyTask } = await this.sendRequest(query);
    return deleteStrategyTask;
  }

  async deleteStrategyTasks(
    {
      task_ids,
      strategy_id,
    }: {
      task_ids: number[];
      strategy_id: number;
    },
    { projection }: { projection?: Projection } = {}
  ): Promise<Partial<StrategyTask>[]> {
    const query = this.toGraphQL({
      mutation: {
        deleteStrategyTasks: {
          __args: {
            task_ids,
            strategy_id,
          },
          ...(projection || { id: true }),
        },
      },
    });

    const { deleteStrategyTasks } = await this.sendRequest(query);
    return deleteStrategyTasks;
  }

  async updateStrategyTaskMonth(
    values: {
      task_id: number;
      strategy_id: number;
      hours: number;
      month_id: number;
    },
    config: { projection?: Projection } = {}
  ) {
    const query = this.toGraphQL({
      mutation: {
        updateStrategyTaskMonth: {
          __args: values,
          //  ...(config.projection || this.defaultStrategyTaskProjection),
          ...(config.projection || this.defaultStrategyTaskProjection),
        },
      },
    });

    const { updateStrategyTaskMonth } = await this.sendRequest(query);
    return updateStrategyTaskMonth;
  }

  async updateStrategyTaskOrder(
    values: {
      strategy_id: number;
      tasks: number[]; // Array of task IDs
    },
    config: { projection?: Projection } = {}
  ) {
    const query = this.toGraphQL({
      mutation: {
        updateStrategyTaskOrder: {
          __args: values,
          ...(config.projection || {
            id: true,
            tasks: this.defaultStrategyTaskProjection,
          }),
        },
      },
    });

    const { updateStrategyTaskOrder } = await this.sendRequest(query);
    return updateStrategyTaskOrder;
  }

  async updateStrategyMonth(
    values: Partial<StrategyMonth> & { id: number },
    config: { projection?: Projection } = {}
  ) {
    const query = this.toGraphQL({
      mutation: {
        updateStrategyMonth: {
          __args: values,
          ...(config.projection || this.defaultStrategyMonthProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return response;
  }

  async createStrategyCost(
    values: Partial<
      StrategyCost & {
        strategy_id: number;
        department_id: number;
      }
    >,
    { projection }: { projection?: Projection } = {}
  ): Promise<Partial<StrategyCost>> {
    const query = this.toGraphQL({
      mutation: {
        createStrategyCost: {
          __args: values,
          ...(projection || this.defaultStrategyCostProjection),
        },
      },
    });

    const { createStrategyCost } = await this.sendRequest(query);
    return createStrategyCost;
  }

  async updateStrategyCost(
    values: Partial<
      StrategyCost & {
        id: number;
      }
    >,
    { projection }: { projection?: Projection } = {}
  ): Promise<Partial<StrategyCost>> {
    const query = this.toGraphQL({
      mutation: {
        updateStrategyCost: {
          __args: values,
          ...(projection || this.defaultStrategyCostProjection),
        },
      },
    });

    const { updateStrategyCost } = await this.sendRequest(query);
    return updateStrategyCost;
  }

  async updateStrategyCostMonth(
    { id, month_id, cost }: { id: number; month_id: number; cost: number },
    { projection }: { projection?: Projection } = {}
  ): Promise<Partial<StrategyCost>> {
    const query = this.toGraphQL({
      mutation: {
        updateStrategyCostMonth: {
          __args: { id, month_id, cost },
          ...(projection || this.defaultStrategyCostProjection),
        },
      },
    });

    const { updateStrategyCostMonth } = await this.sendRequest(query);
    return updateStrategyCostMonth;
  }

  async deleteStrategyCost(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<Partial<StrategyCost>> {
    const query = this.toGraphQL({
      mutation: {
        deleteStrategyCost: {
          __args: {
            id,
          },
          ...(projection || this.defaultStrategyCostProjection),
        },
      },
    });

    const { deleteStrategyCost } = await this.sendRequest(query);
    return deleteStrategyCost;
  }

  async syncSalesforceToBlueprint(id: number): Promise<Partial<Strategy>> {
    const query = this.toGraphQL({
      mutation: {
        syncSalesforceToBlueprint: {
          __args: { id },
          id: true,
        },
      },
    });

    const { syncSalesforceToBlueprint: strategy } = await this.sendRequest(query);
    return strategy;
  }

  async createStrategyUser(
    values: { strategy_id: number; user_id: number; commission_percent: number; },
    { projection }: { projection?: Projection } = {}
  ): Promise<{ strategies: Partial<Strategy>[] }> {
    const query = this.toGraphQL({
      mutation: {
        createStrategyUser: {
          __args: values,
          ...(projection || this.defaultStrategyProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { strategies: [response.createStrategyUser] };
  }

  async updateStrategyUser(
    values: { strategy_id: number; user_id: number; commission_percent: number; },
    { projection }: { projection?: Projection } = {}
  ): Promise<{ strategies: Partial<Strategy>[] }> {
    const query = this.toGraphQL({
      mutation: {
        updateStrategyUser: {
          __args: values,
          ...(projection || this.defaultStrategyProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { strategies: [response.updateStrategyUser] };
  }

  async deleteStrategyUser(
    values: { strategy_id: number; user_id: number; },
    { projection }: { projection?: Projection } = {}
  ): Promise<Partial<User>> {
    const query = this.toGraphQL({
      mutation: {
        deleteStrategyUser: {
          __args: values,
          ...(projection || this.defaultStrategyProjection),
        },
      },
    });

    const { deleteStrategyUser } = await this.sendRequest(query);
    return deleteStrategyUser;
  }

  /**
   * Create and update KickoffDeck by strategy ID.
   */

  async createKickoffDeck({
    strategy_id,
    integrations,
    users,
  }: {
    strategy_id: number;
    integrations?: [number] | [];
    users?: number[];
  }): Promise<any> {
    // }): Promise<Partial<KickoffDeckInfoData>> {
    const __args = { strategy_id, integrations, users };
    const mutation = this.toGraphQL({
      mutation: {
        createKickoffDeck: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...{
            id: true,
            deck_url: true,
          },
        },
      },
    });

    const response = await this.sendRequest(mutation);
    const { createKickoffDeck, ...meta } = response;

    return { createKickoffDeck: createKickoffDeck, ...meta };
  }

  async updateKickoffDeck({
    id,
    integrations,
    users,
  }: {
    id: number;
    integrations: [number] | [];
    users: number[];
  }): Promise<any> {
    // }): Promise<Partial<KickoffDeckInfoData>> {
    const __args = { id, integrations, users };
    const mutation = this.toGraphQL({
      mutation: {
        updateKickoffDeck: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(this.defaultStrategyProjection as any).presentation,
        },
      },
    });

    const response = await this.sendRequest(mutation);
    const { updateKickoffDeck, ...meta } = response;

    return { updateKickoffDeck: updateKickoffDeck, ...meta };
  }

  /**
   * Me
   */
  defaultMeProjection: {
    id: boolean;
    name: boolean;
    email: boolean;
    avatar: boolean;
    permissions: boolean;
    roles: boolean;
    partners?: {
      id: boolean;
    };
    highest_talent_role: Record<string, unknown> | boolean;
    accounts: Record<string, unknown> | boolean;
    organization: Record<string, unknown> | boolean;
    department: Record<string, unknown> | boolean;
    departments: Record<string, unknown> | boolean;
    manager: Record<string, unknown> | boolean;
    title: boolean;
    job_title: Record<string, unknown> | boolean;
  } = {
      id: true,
      name: true,
      email: true,
      avatar: true,
      permissions: true,
      roles: true,
      accounts: { id: true, username: true, type: true, updated_at: true },
      highest_talent_role: {
        id: true,
        name: true,
        slug: true
      },
      organization: {
        id: true,
        slug: true,
        name: true,
      },
      department: {
        id: true,
        slug: true,
        name: true,
      },
      departments:  {
        id: true,
        slug: true,
        name: true,
      },
      partners: {
        id: true,
      },
      manager: {
        id: true,
        name: true,
      },
      title: true,
      job_title: {
        id: true,
        name: true,
      },
    };
  /**
   * @deprecated Use `useGetMeQuery` instead.
   */
  async fetchMe({ projection }: { projection?: Projection } = {}): Promise<{
    me: User;
  }> {
    const query = this.toGraphQL({
      query: {
        me: projection || this.defaultMeProjection,
      },
    });

    const response = await this.sendRequest(query);
    return { me: response.me };
  }

  /**
   * Reports
   */
  defaultWidgetProjection = {
    id: true,
    slug: true,
    is_active: true,
    row: true,
    position: true,
    title: true,
    data: {
      id: true,
      title: true,
      content: true,
    },
  };
  /**
   * Indicators
   */
  defaultStatusesProjection = {
    id: true,
    statuses: {
      __args: { limit: 10 },
      id: true,
      headline: true,
      date: true,
      status: true,
      author: {
        id: true,
        name: true,
      },
      sections: {
        id: true,
        title: true,
        content: true,
        section: {
          id: true,
          name: true,
          slug: true,
        },
      },
    },
  };
  defaultIndicatorsProjection = {
    id: true,
    name: true,
    slug: true,
    category: true,
    format: true,
    tooltip: true,
    equation: true,
    is_filterable: true,
    business_types: {
      id: true,
      name: true,
      slug: true,
    },
  };
  defaultReportProjection = {
    id: true,
    updated_at: true,
    google_analytics_auth_method: true,
    business_type: {
      id: true,
      slug: true,
      name: true,
    },
    indicators: {
      ...this.defaultIndicatorsProjection,
      widgets: {
        id: true,
        name: true,
      },
    },
    accounts: {
      id: true,
      url: true,
      slug: true,
      auth_method: true,
      updated_at: true,
      account: {
        id: true,
        name: true,
        group: true,
      },
    },
    fusion_integrations: {
      data_source: {
        id: true,
        slug: true,
        group: true
      }
    },
    client: {
      id: true,
      signature: true,
      name: true,
      business_type: { id: true, name: true },
      accounts: {
        id: true,
        url: true,
        auth_method: true,
        account: {
          id: true,
          slug: true,
          name: true,
          group: true,
        },
      },
    },
    statuses: {
      __args: { limit: 10 },
      id: true,
      headline: true,
      date: true,
      status: true,
      author: {
        id: true,
        name: true,
      },
      sections: {
        id: true,
        title: true,
        content: true,
        section: {
          id: true,
          name: true,
          slug: true,
        },
      },
    },
    pacing_goals: {
      id: true,
      type: true,
      term: true,
      start: true,
      metric: true,
      value: true,
      // data: {
      //   date: true,
      //   actual: true,
      //   goal: true,
      //   pace: true,
      // },
      current_value: true,
      prediction: true,
      goal_value: true,
      percent_on_pace: true,
      percent_of_goal: true,
      percent_of_goal_platform: {
        platform: true,
        title: true,
        value: true,
      },
      current_platform: {
        platform: true,
        title: true,
        value: true,
      },
      percent_on_pace_platform: {
        platform: true,
        title: true,
        value: true,
      },
      prediction_platform: {
        platform: true,
        title: true,
        value: true,
      },
    },
    author: { id: true, name: true },
    widgets: {
      id: true,
      name: true,
      slug: true,
      is_active: true,
      row: true,
      position: true,
      title: true,
      settings: true,
      indicators: {
        id: true,
        name: true,
      },
      data: {
        id: true,
        title: true,
        content: true,
      },
    },
    excluded_data_sources: {
      id: true
    },
  };

  defaultReportStatusProjection = {
    id: true,
    statuses: {
      __args: { limit: 10 },
      id: true,
      name: true,
      date: true,
      headline: true,
      status: true,
      author: {
        id: true,
        name: true,
      },
      sections: {
        id: true,
        title: true,
        content: true,
        order: true,
        section: {
          id: true,
          name: true,
          slug: true,
          question: true,
          description: true,
        },
      },
    },
    accounts: {
      id: true,
      url: true,
    },
  };
  async fetchReports({
    args: {
      id,
      name,
      author_id,
      account_manager_id,
      executive_sponsor_id,
      business_type_id,
      sort,
      ...args } = {},
    pagination: { page, limit } = this.defaultPagination,
    projection,
  }: FetchManyPaginatedConfig & {
    args?: FetchReportsFilter;
  }): Promise<PaginatedResponse & { reports: Partial<Report>[] }> {
    const __args = {
      page,
      limit,
      id,
      name,
      author_id,
      account_manager_id,
      executive_sponsor_id,
      business_type_id,
      sort: ['-created_at'],
      ...args,
    };
    const query = this.toGraphQL({
      query: {
        reports: {
          __args: this.filterUndefinedNullEmpty(__args),
          data: projection || this.defaultReportProjection,
          ...this.paginationProjection,
        },
      },
    });

    const response = await this.sendRequest(query);

    const {
      reports: { data: reports, ...meta },
    } = response;
    return {
      reports,
      ...meta,
    };
  }

  async fetchReportById(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ reports: Partial<Report>[] }> {
    const __args = { id };
    const query = this.toGraphQL({
      query: {
        report: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultReportProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    const report = response.report;

    return { reports: [report] };
  }

  async updateReportWidget(
    {
      id,
      is_active,
      title,
      settings,
      row,
      position,
      size,
      indicator_ids,
    }: {
      id: number;
      is_active?: YesNo;
      title?: string;
      settings?: string;
      row?: number;
      position?: number;
      size?: number;
      indicator_ids?: number[];
    },
    { projection }: { projection?: Projection } = {}
  ) {
    const __args = {
      id,
      is_active,
      title,
      settings,
      row,
      position,
      size,
      indicator_ids,
    };
    const query = this.toGraphQL({
      mutation: {
        updateReportWidget: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultReportProjection['widgets']),
        },
      },
    });
    const response = await this.sendRequest(query);
    return { widgets: [response.updateReportWidget] };
  }

  async updateReport(
    {
      id,
      name,
      website,
      widgets,
      business_type_id,
      logo,
      goal_name_filter,
      refreshed_at,
      excluded_data_source_ids,
    }: {
      id: number;
      name?: string;
      website?: string;
      widgets?: any;
      business_type_id?: number;
      logo?: string;
      goal_name_filter?: string[];
      refreshed_at?: null | string;
      excluded_data_source_ids?: number[];
    },
    { projection }: { projection?: Projection } = {}
  ) {
    const __args = {
      id,
      name,
      website,
      widgets,
      business_type_id,
      logo,
      goal_name_filter,
      refreshed_at,
      excluded_data_source_ids,
    };
    const filteredArgs =
      refreshed_at === null
        ? { ...this.filterUndefinedNullEmpty(__args), refreshed_at: null }
        : this.filterUndefinedNullEmpty(__args);

    // Explicitly handle empty arrays for goal_name_filter and excluded_data_source_ids
    const argsWithExplicitEmptyArrays = {
      ...filteredArgs,
      ...(goal_name_filter?.length === 0 && { goal_name_filter: [] }),
      ...(excluded_data_source_ids?.length === 0 && { excluded_data_source_ids: [] }),
    };

    const mutation = {
      mutation: {
        updateReport: {
          __args: argsWithExplicitEmptyArrays,
          ...(projection || { ...this.defaultReportProjection, job_running: true }),
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }

  async createReport(
    {
      client_id,
      name,
      website,
      business_type_id,
      logo,
      excluded_data_source_ids,
    }: {
      client_id: number;
      name: string;
      website?: string;
      business_type_id?: number;
      logo?: string;
      excluded_data_source_ids?: number[];
    },
    { projection }: { projection?: Projection } = {}
  ) {
    const __args = {
      client_id,
      name,
      website,
      business_type_id,
      logo,
      excluded_data_source_ids,
    };
    const mutation = {
      mutation: {
        createReport: {
          __args: {
            ...(this.filterUndefinedNullEmpty(__args)),
            ...(excluded_data_source_ids?.length === 0 && { excluded_data_source_ids: [] })
          },
          ...(projection || this.defaultReportProjection),
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }

  defaultAccountTypesProjection = {
    id: true,
    name: true,
    slug: true,
    description: true,
    group: true,
  };

  async fetchAccountTypes({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { accounts: Accounts[] }> {
    const query = this.toGraphQL({
      query: {
        accounts: projection || this.defaultAccountTypesProjection,
      },
    });
    const response = await this.sendRequest(query);
    const { accounts, ...meta } = response;
    return { accounts, ...meta };
  }

  async createReportIndicator(
    {
      report_id,
      indicator_id,
      report_widget_id,
      settings,
    }: {
      report_id: number;
      indicator_id: number;
      report_widget_id?: number;
      settings?: string;
    },
    { projection }: { projection?: Projection } = {}
  ) {
    const __args = {
      report_id,
      indicator_id,
      report_widget_id,
      settings,
    };
    const mutation = {
      mutation: {
        createReportIndicator: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultReportProjection),
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }

  async deleteReportIndicator(
    {
      report_id,
      indicator_id,
    }: {
      report_id: number;
      indicator_id: number;
    },
    { projection }: { projection?: Projection } = {}
  ) {
    const __args = {
      report_id,
      indicator_id,
    };
    const mutation = {
      mutation: {
        deleteReportIndicator: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultReportProjection),
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }

  async deleteReport(id: number) {
    const __args = { id };
    const mutation = {
      mutation: {
        deleteReport: {
          __args: this.filterUndefinedNullEmpty(__args),
          id,
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return { reports: [response.deleteReport] };
  }

  /**
   * Report Status
   */
  async fetchReportStatus(
    {
      report_id,
      limit,
    }: {
      report_id: number;
      limit?: number;
    },
    { projection }: { projection?: string } = {}
  ) {
    const __args = {
      id: report_id,
    };
    const fetch = {
      query: {
        report: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...this.defaultReportStatusProjection,
        },
      },
    };
    const query = this.toGraphQL(fetch);
    const response = await this.sendRequest(query);
    return response;
  }
  async deleteReportSection(id: number) {
    const query = `
      mutation {
        deleteReportSection(
          id: ${id}
        ){
          id
        }
      }
    `;
    const response = await this.sendRequest(query);
    return response.deleteReportSection;
  }
  async updateReportSection(
    {
      id,
      title,
      content,
      order,
    }: {
      id: number;
      title?: string;
      content?: string;
      order?: number;
    },
    { projection }: { projection?: Projection } = {}
  ) {
    const __args = {
      id,
      title,
      content,
      order,
    };
    const mutation = {
      mutation: {
        updateReportSection: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || { id: true }),
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }

  async updateReportIndicator(
    {
      report_id,
      indicator_id,
      report_widget_id,
      settings,
    }: {
      report_id: number;
      indicator_id: number;
      report_widget_id?: number;
      settings?: string;
    },
    { projection }: { projection?: Projection } = {}
  ) {
    const __args = {
      report_id,
      indicator_id,
      report_widget_id,
      settings,
    };
    const mutation = {
      mutation: {
        updateReportIndicator: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...{ id: true },
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }

  async updateReportStatus(
    {
      id,
      date,
      name,
      headline,
      status,
      auto_save,
      is_draft,
      publish_date,
      sections,
    }: {
      id: number;
      date?: string;
      name?: string;
      headline?: string;
      status?: string;
      auto_save?: boolean;
      is_draft?: boolean;
      publish_date?: string;
      sections?: any[];
    },
    { projection }: { projection?: Projection } = {}
  ) {
    const __args = {
      id,
      date,
      name,
      headline,
      status,
      auto_save,
      is_draft,
      publish_date,
      sections
    };
    const mutation = {
      mutation: {
        updateReportStatus: {
          __args: {
            ...this.filterUndefinedNullEmpty(__args),
            publish_date: publish_date === undefined ? null : publish_date},
          ...(projection || { id: true }),
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }
  async deleteReportStatus(id: number) {
    const query = `
      mutation {
        deleteReportStatus(
          id: ${id}
        ){
          id
        }
      }
    `;
    const response = await this.sendRequest(query);
    return response.deleteReportStatus;
  }

  async deleteReportAccount(
    {
      report_id,
      client_account_id,
    }: {
      report_id: number;
      client_account_id: number;
    },
    { projection }: { projection?: string } = {}
  ) {
    const __args = {
      report_id,
      client_account_id,
    };
    const mutation = {
      mutation: {
        deleteReportAccount: {
          __args: this.filterUndefinedNullEmpty(__args),
          // ...this.defaultReportStatusProjection["accounts"]
          ...{ id: true },
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }

  async updateReportAccount(
    {
      report_id,
      client_account_id,
    }: {
      report_id: number;
      client_account_id: number;
    },
    { projection }: { projection?: string } = {}
  ) {
    const __args = {
      report_id,
      client_account_id,
    };
    const mutation = {
      mutation: {
        updateReportAccount: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...this.defaultReportStatusProjection['accounts'],
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }
  async createReportAccount(
    {
      report_id,
      client_account_id,
      client_account_ids,
    }: {
      report_id: number;
      client_account_id?: number;
      client_account_ids?: number[];
    },
    { projection }: { projection?: string } = {}
  ) {
    const __args = {
      ...this.filterUndefinedNullEmpty({
        report_id,
        client_account_id,
      }),
      client_account_ids,
    };
    const mutation = {
      mutation: {
        createReportAccount: {
          __args,
          ...this.defaultReportStatusProjection['accounts'],
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }

  async createReportSection(
    {
      report_status_id,
      section_id,
      title,
      content,
      order,
    }: {
      report_status_id: number;
      section_id: number;
      title?: string;
      content?: string;
      order?: number;
    },
    { projection }: { projection?: string } = {}
  ) {
    const __args = {
      report_status_id,
      section_id,
      title,
      content,
      order,
    };
    const mutation = {
      mutation: {
        createReportSection: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...this.defaultReportStatusProjection['statuses']['sections'],
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }
  async updatePacingGoal(
    {
      id,
      type,
      start,
      term,
      metric,
      value,
      months,
      goal_channel_filter,
      goal_source_filter,
    }: {
      id: number;
      type: string;
      start: string;
      term: number;
      metric: string;
      value: number | null;
      goal_channel_filter: string[];
      goal_source_filter: string[];
      months?: {
        date: string;
        value: number;
      }[];
    },
    { projection }: { projection?: Projection } = {}
  ) {
    const __args = {
      id,
      type,
      start,
      term,
      metric,
      value,
      months,
      goal_channel_filter,
      goal_source_filter,
    };
    const formattedArgs = {
      ...this.filterUndefinedNullEmpty(__args),
      goal_channel_filter: goal_channel_filter ? goal_channel_filter : [],
      goal_source_filter: goal_source_filter ? goal_source_filter : [],
      months: months ? months : []
    };
    const mutation = {
      mutation: {
        updatePacingGoal: {
          __args: formattedArgs,
          ...this.defaultReportProjection['pacing_goals'],
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }
  async deletePacingGoal(
    {
      id,
    }: {
      id: number;
    },
    { projection }: { projection?: Projection } = {}
  ) {
    const __args = {
      id,
    };
    const mutation = {
      mutation: {
        deletePacingGoal: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...this.defaultReportProjection['pacing_goals'],
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }
  async createPacingGoal(
    {
      report_id,
      type,
      start,
      term,
      metric,
      value,
      months,
      goal_channel_filter,
      goal_source_filter,
    }: {
      report_id: number;
      type: string;
      start: string;
      term: number;
      metric: string;
      value: number | null;
      goal_channel_filter: string[];
      goal_source_filter: string[];
      months?: {
        date: string;
        value: number;
      }[];
    },
    { projection }: { projection?: Projection } = {}
  ) {
    const __args = {
      report_id,
      type,
      start,
      term,
      metric,
      value,
      months,
      goal_channel_filter,
      goal_source_filter,
    };
    const mutation = {
      mutation: {
        createPacingGoal: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...this.defaultReportProjection['pacing_goals'],
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }
  async updateReportWidgetData(
    {
      id,
      title,
      content,
    }: {
      id: number;
      title: number;
      content: string;
    },
    { projection }: { projection?: string } = {}
  ) {
    const __args = {
      id,
      title,
      content,
    };
    const mutation = {
      mutation: {
        updateReportWidgetData: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...this.defaultReportProjection['widgets']['data'],
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }
  async createReportWidgetData(
    {
      report_widget_id,
      title,
      content,
    }: {
      report_widget_id: number;
      title?: string;
      content?: string;
    },
    { projection }: { projection?: Projection } = {}
  ) {
    const __args = {
      report_widget_id,
      title,
      content,
    };
    const mutation = {
      mutation: {
        createReportWidgetData: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || { id: true }),
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }
  async saveReportWidgetData(
    {
      report_widget_id,
      data,
      delete_missing,
    }: {
      report_widget_id: number;
      data: any[];
      delete_missing: string;
    },
    { projection }: { projection?: Projection } = {}
  ) {
    const __args = {
      report_widget_id,
      data,
      delete_missing,
    };
    const mutation = {
      mutation: {
        saveReportWidgetData: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultWidgetProjection),
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }
  async createReportWidget(
    {
      report_id,
      widget_id,
      title,
      position,
      indicator_ids,
      is_active,
      settings,
    }: {
      report_id: number;
      widget_id: number;
      title?: string;
      position?: number;
      indicator_ids?: number[];
      is_active?: YesNo;
      settings?: string;
    },
    { projection }: { projection?: Projection } = {}
  ) {
    const __args = {
      report_id,
      widget_id,
      title,
      position,
      indicator_ids,
      is_active,
      settings,
    };
    const mutation = {
      mutation: {
        createReportWidget: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultWidgetProjection),
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }
  async deleteReportWidget(
    {
      id,
    }: {
      id?: number;
    },
    { projection }: { projection?: Projection } = {}
  ) {
    const __args = {
      id,
    };
    const mutation = {
      mutation: {
        deleteReportWidget: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultWidgetProjection),
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }
  async createReportStatus(
    {
      report_id,
      date,
      name,
      headline,
      status,
      sections,
      auto_save,
      is_draft,
      publish_date,
    }: {
      report_id: number;
      date?: string;
      name?: string;
      headline?: string;
      status?: string;
      sections?: Array<any>;
      auto_save?: boolean;
      is_draft?: boolean;
      publish_date?: string;
    },
    projection: { projection?: Projection }
  ) {
    const __args = {
      report_id,
      date,
      name,
      headline,
      status,
      sections,
      auto_save,
      is_draft,
      publish_date,
    };

    const mutation = {
      mutation: {
        createReportStatus: {
          __args: {
            ...this.filterUndefinedNullEmpty(__args)
          },
          ...{ id: true },
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
    // return { reports: [response.createReportStatus] };
  }
  async cloneReportStatus(id: number) {
    const __args = { id };
    const mutation = {
      mutation: {
        cloneReportStatus: {
          __args: {
            ...this.filterUndefinedNullEmpty(__args)
          },
          ...{ id: true },
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }
  /**
   * Sections
   */
  defaultSectionsProjection = {
    id: true,
    name: true,
    slug: true,
  };
  async fetchWidgets(): Promise<Response> {
    const query = this.toGraphQL({
      query: {
        widgets: {
          id: true,
          name: true,
        },
      },
    });
    const response = await this.sendRequest(query);
    const { widgets } = response;
    return widgets;
  }
  async fetchSections(): Promise<Response & { sections: Sections[] }> {
    const query = this.toGraphQL({
      query: {
        sections: this.defaultSectionsProjection,
      },
    });

    const response = await this.sendRequest(query);
    const { sections, ...meta } = response;
    return { sections, ...meta };
  }

  /**
   * Departments
   */
  defaultDepartmentProjection = {
    id: true,
    name: true,
  };

  async fetchDepartments({
    filter = {},
    projection,
  }: {
    projection?: Projection;
    filter?: FetchDepartmentsFilter;
  }): Promise<Response & { departments: Department[] }> {
    const __args = filter;

    // Fuzzy search
    if (__args.name) {
      __args.name = `*${__args.name}*`;
    }

    const query = this.toGraphQL({
      query: {
        departments: {
          ...(Object.keys(__args).length ? { __args } : {}),
          ...(projection || this.defaultDepartmentProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return response;
  }

  async fetchDepartmentById(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ departments: Partial<Department>[] }> {
    const __args = { id };
    const query = this.toGraphQL({
      query: {
        departments: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultDepartmentProjection),
        },
      },
    });

    const { departments } = await this.sendRequest(query);
    return { departments };
  }

  async createDepartment(
    values: Partial<Department>,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ departments: Partial<Department>[] }> {
    const query = this.toGraphQL({
      mutation: {
        createDepartment: {
          __args: values,
          ...(projection || this.defaultDepartmentProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { departments: [response.createDepartment] };
  }

  async updateDepartment(
    values: Partial<Department>,
    { projection }: { projection?: Projection } = {}
  ) {
    const query = this.toGraphQL({
      mutation: {
        updateDepartment: {
          __args: values,
          ...(projection || this.defaultDepartmentProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { departments: [response.updateDepartment] };
  }

  async deleteDepartment(id: number) {
    const query = this.toGraphQL({
      mutation: {
        deleteDepartment: {
          __args: { id },
          id: true,
        },
      },
    });
    const response = await this.sendRequest(query);
    return { departments: [response.deleteDepartment] };
  }

  /**
   * Organizations
   */
  defaultOrganizationProjection = {
    id: true,
    name: true,
    slug: true,
  };

  async fetchOrganizations({
    filter = {},
    projection,
  }: {
    projection?: Projection;
    filter?: FetchOrganizationsFilter;
  }): Promise<Response & { organizations: Organization[] }> {
    const __args = filter;

    // Fuzzy search
    if (__args.name) {
      __args.name = `*${__args.name}*`;
    }

    const query = this.toGraphQL({
      query: {
        organizations: {
          ...(Object.keys(__args).length ? { __args } : {}),
          ...(projection || this.defaultOrganizationProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return response;
  }

  async fetchOrganizationById(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ organizations: Partial<Organization>[] }> {
    const __args = { id };
    const query = this.toGraphQL({
      query: {
        organizations: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultOrganizationProjection),
        },
      },
    });

    const { organizations } = await this.sendRequest(query);
    return { organizations };
  }

  /**
   * Services
   */
  defaultServiceProjection = {
    id: true,
    name: true,
    department: {
      id: true,
      name: true,
    },
    business_types: {
      id: true,
      name: true,
    },
  };

  async fetchServices({
    filter = {},
    projection,
  }: {
    projection?: Projection;
    filter?: FetchServicesFilter;
  }): Promise<Response & { services: Service[] }> {
    const __args = filter;

    // Fuzzy search
    if (__args.name) {
      __args.name = `*${__args.name}*`;
    }

    const query = this.toGraphQL({
      query: {
        services: {
          ...(Object.keys(__args).length ? { __args } : {}),
          ...(projection || this.defaultServiceProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return response;
  }

  async fetchServiceById(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ services: Partial<Service>[] }> {
    const __args = { id };
    const query = this.toGraphQL({
      query: {
        services: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultServiceProjection),
        },
      },
    });

    const { services } = await this.sendRequest(query);
    return { services };
  }

  async createService(
    values: Partial<ServiceFormValues>,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ services: Partial<Service>[] }> {
    const query = this.toGraphQL({
      mutation: {
        createService: {
          __args: values,
          ...(projection || this.defaultServiceProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { services: [response.createService] };
  }

  async updateService(
    values: Partial<Service>,
    { projection }: { projection?: Projection } = {}
  ) {
    const query = this.toGraphQL({
      mutation: {
        updateService: {
          __args: values,
          ...(projection || this.defaultServiceProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { services: [response.updateService] };
  }

  async deleteService(id: number) {
    const query = this.toGraphQL({
      mutation: {
        deleteService: {
          __args: { id },
          id: true,
        },
      },
    });
    const response = await this.sendRequest(query);
    return { services: [response.deleteService] };
  }

  /**
   * Pillars
   */
  defaultPillarsProjection = {
    id: true,
    name: true,
    slug: true,
  };

  async fetchPillars({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { pillars: Pillar[] }> {
    const query = this.toGraphQL({
      query: {
        pillars: projection || this.defaultPillarsProjection,
      },
    });

    const response = await this.sendRequest(query);
    const { pillars, ...meta } = response;

    return {
      pillars,
      ...meta,
    };
  }

  /**
   * Salesforce Accounts
   */
  defaultSalesforceAccountProjection = {
    description: true,
    id: true,
    lead_status: true,
    name: true,
  };

  async fetchSalesforceAccounts({
    filter,
    limit,
    projection,
  }: FetchManyPaginatedConfig & {
    filter?: { salesforce_account_id?: string; search_name?: string };
    limit?: number;
    projection?: Projection;
  }): Promise<{ salesforce_accounts: SalesforceAccount[] }> {
    const __args = { limit, projection, ...filter };
    const query = this.toGraphQL({
      query: {
        salesforce_accounts: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultSalesforceAccountProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    const { salesforce_accounts } = response;

    return {
      salesforce_accounts,
    };
  }

  /**
   * Lead Sources
   */
  defaultLeadSourceProjection = {
    id: true,
    name: true,
    slug: true,
  };

  async fetchLeadSources({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { lead_sources: LeadSource[] }> {
    const query = this.toGraphQL({
      query: {
        lead_sources: projection || this.defaultLeadSourceProjection,
      },
    });

    const response = await this.sendRequest(query);
    const { lead_sources, ...meta } = response;

    return {
      lead_sources,
      ...meta,
    };
  }

  /**
   * Salesforce Opportunities
   */
  defaultSalesforceOpportunityProjection = {
    id: true,
    name: true,
    blueprint_id: true,
    stage: true,
    total_montly_gp: true,
    sync_status: true,
    account_id: true,
    display_value: true,
  };

  async fetchSalesforceOpportunities(args?: {
    account_id: string;
    projection?: Projection;
  }): Promise<{ salesforce_opportunities: SalesforceOpportunity[] }> {
    const query = this.toGraphQL({
      query: {
        salesforce_opportunities: {
          __args: this.filterUndefinedNullEmpty({
            account_id: args?.account_id,
          }),
          ...(args?.projection || this.defaultSalesforceOpportunityProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    const { salesforce_opportunities } = response;

    return {
      salesforce_opportunities,
    };
  }

  /**
   * Industries
   */
  defaultIndustryProjection = {
    id: true,
    name: true,
    slug: true,
  };

  async fetchIndustries({
    flattened = 'yes',
    projection,
  }: {
    flattened?: YesNo;
    projection?: Projection;
  }): Promise<Response & { industries: Industry[] }> {
    const __args = { flattened };
    const query = this.toGraphQL({
      query: {
        industries: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultIndustryProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    const { industries, ...meta } = response;

    return {
      industries,
      ...meta,
    };
  }

  /**
   * Tiers
   */
  defaultTierProjection = {
    id: true,
    name: true,
    slug: true,
  };

  async fetchTiers({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { tiers: Tier[] }> {
    const query = this.toGraphQL({
      query: {
        tiers: {
          ...(projection || this.defaultTierProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    const { tiers, ...meta } = response;

    return {
      tiers,
      ...meta,
    };
  }

  /**
   * Verticals
   */
  defaultVerticalProjection = {
    id: true,
    name: true,
    slug: true,
  };

  async fetchVerticals({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { verticals: Vertical[] }> {
    const query = this.toGraphQL({
      query: {
        verticals: {
          ...(projection || this.defaultVerticalProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    const { verticals, ...meta } = response;

    return {
      verticals,
      ...meta,
    };
  }

  /**
   * Goals
   */
  defaultGoalProjection = {
    id: true,
    name: true,
  };

  async fetchGoals({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { goals: Goal[] }> {
    const query = this.toGraphQL({
      query: {
        goals: projection || this.defaultGoalProjection,
      },
    });

    const response = await this.sendRequest(query);
    const { goals, ...meta } = response;

    return {
      goals,
      ...meta,
    };
  }

  /**
   * Channels
   */
  defaultChannelProjection = {
    id: true,
    name: true,
  };

  async fetchChannels({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { channels: Channel[] }> {
    const query = this.toGraphQL({
      query: {
        channels: projection || this.defaultChannelProjection,
      },
    });

    const response = await this.sendRequest(query);
    const { channels, ...meta } = response;

    return {
      channels,
      ...meta,
    };
  }

  /**
   * Lead Channels
   */
  defaultLeadChannelProjection = {
    id: true,
    name: true,
  };

  async fetchLeadChannels({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { lead_channels: LeadChannel[] }> {
    const query = this.toGraphQL({
      query: {
        lead_channels: projection || this.defaultLeadChannelProjection,
      },
    });

    const response = await this.sendRequest(query);
    const { lead_channels, ...meta } = response;

    return {
      lead_channels,
      ...meta,
    };
  }

  /**
   * CRMs
   */
  defaultCrmProjection = {
    id: true,
    name: true,
    slug: true,
  };

  async fetchCrms({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { crms: CRM[] }> {
    const query = this.toGraphQL({
      query: {
        crms: projection || this.defaultCrmProjection,
      },
    });

    const response = await this.sendRequest(query);
    const { crms, ...meta } = response;

    return {
      crms,
      ...meta,
    };
  }

  /**
   * E-Commerce Platforms
   */
  defaultEcommercePlatformProjection = {
    id: true,
    name: true,
    slug: true,
  };

  async fetchEcommercePlatforms({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { ecommerce_platforms: EcommercePlatform[] }> {
    const query = this.toGraphQL({
      query: {
        ecommerce_platforms:
          projection || this.defaultEcommercePlatformProjection,
      },
    });

    const response = await this.sendRequest(query);
    const { ecommerce_platforms, ...meta } = response;

    return {
      ecommerce_platforms,
      ...meta,
    };
  }

  /**
   * Business Types
   */
  defaultBusinessTypeProjection = {
    id: true,
    name: true,
  };

  async fetchBusinessTypes({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { business_types: BusinessType[] }> {
    const query = this.toGraphQL({
      query: {
        business_types: projection || this.defaultBusinessTypeProjection,
      },
    });

    const response = await this.sendRequest(query);
    const { business_types, ...meta } = response;

    return {
      business_types,
      ...meta,
    };
  }

  async fetchReportIndicatorsEarliestDate({
    id,
    indicator_id,
    projection,
  }: {
    id: number;
    indicator_id?: number;
    projection?: Projection;
  }): Promise<Response & { early_date_data_available: string }> {
    const indicatorEarlyDate = indicator_id ? { __args: { indicator_id } } : {};
    const query = this.toGraphQL({
      query: {
        report: {
          __args: { id },
          early_date_data_available: {
            ...indicatorEarlyDate,
            ...projection,
          },
        }
      },
    });
    const response = await this.sendRequest(query);
    const { report } = response;
    const { early_date_data_available } = report;
    return early_date_data_available;
  }

  async fetchReportIndicators({
    id,
    name,
    business_type_id,
    goal_pacing,
    projection,
  }: {
    id?: number;
    name?: string;
    business_type_id?: number;
    goal_pacing?: YesNo;
    projection?: Projection;
  }): Promise<Response & { indicators: Indicators[] }> {
    const __args = {
      id,
      name,
      business_type_id,
      goal_pacing
    };
    const query = isEmpty(this.filterUndefinedNullEmpty(__args))
      ? this.toGraphQL({
        query: {
          indicators: {
            ...(projection || this.defaultIndicatorsProjection),
          }
        },
      })
      : this.toGraphQL({
        query: {
          indicators: {
            __args: this.filterUndefinedNullEmpty(__args),
            ...(projection || this.defaultIndicatorsProjection),
          }
        },
      });
    const response = await this.sendRequest(query);
    const { indicators, ...meta } = response;
    return { indicators, ...meta };
  }

  /**
   * Partners
   */

  defaultPartnerProjection = {
    address: true,
    address2: true,
    city: true,
    company: true,
    country: true,
    created_at: true,
    email: true,
    id: true,
    name: true,
    percent: true,
    phone: true,
    state: true,
    updated_at: true,
    zip: true,
    url: true,
    report: {
      key: true,
      value: true,
    },
    author: {
      id: true,
      name: true,
    },
    users: {
      id: true,
      is_accepted: true,
      email: true,
      name: true,
    },
    strategies: {
      created_at: true,
      date: true,
      id: true,
      name: true,
      length: true,
      type: true,
      status: true,
      monthly_gross_profit: true,
      hourly_rate: true,
      total_profit: true,
      total_retainer: true,
      total_partner: true,
      departments: {
        id: true,
        name: true,
      },
      client: {
        id: true,
        name: true,
        logo: true,
        industry: {
          id: true,
          name: true,
        }
      },
    }
  };

  async fetchPartners({
    args: { id, search } = {},
    pagination: { page, limit } = this.defaultPagination,
    projection,
  }: {
    args?: { id?: number; search?: string; };
    pagination?: Pagination;
    projection?: Projection;
  } = {}): Promise<PaginatedResponse & { partners: Partial<Partner>[] }> {
    const __args = { page, limit, id, sort: ['-created_at'], search };

    const query = this.toGraphQL({
      query: {
        partners: {
          __args: this.filterUndefinedNullEmpty(__args),
          data: projection || this.defaultPartnerProjection,
          ...this.paginationProjection,
        },
      },
    });

    const response = await this.sendRequest(query);

    const {
      partners: { data: partners, ...meta },
    } = response;
    return {
      partners,
      ...meta,
    };
  }

  async  fetchPartnerById(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ partners: Partial<Partner>[] }> {
    const __args = { id };
    const query = this.toGraphQL({
      query: {
        partner: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultPartnerProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    const partner = response.partner;

    return { partners: [partner] };
  }

  async createPartner(
    values: Partial<Partner>,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ partners: Partial<Partner>[] }> {
    const query = this.toGraphQL({
      mutation: {
        createPartner: {
          __args: this.filterUndefinedNullEmpty(values),
          ...(projection || this.defaultPartnerProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { partners: [response.createPartner] };
  }

  async createPartnerUser(
    args: CreatePartnerUserArgs,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ partners: Partial<Partner>[] }> {
    const query = this.toGraphQL({
      mutation: {
        createPartnerUser: {
          __args: this.filterUndefinedNullEmpty(args, ['company', 'name']),
          ...(projection || this.defaultUserProjection),
        },
      },
    });

    const { createPartnerUser: user } = await this.sendRequest(query);
    return { partners: [{ id: args.partner_id, users: [user] }] };
  }

  async updatePartner(
    values: Partial<Partner>,
    { projection }: { projection?: Projection } = {}
  ) {
    const query = this.toGraphQL({
      mutation: {
        updatePartner: {
          __args: this.filterUndefinedNullEmpty(values, ['company', 'name']),
          ...(projection || this.defaultPartnerProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { partners: [response.updatePartner] };
  }

  async deletePartner(id: number) {
    const query = this.toGraphQL({
      mutation: {
        deletePartner: {
          __args: { id },
          id: true,
        },
      },
    });
    const response = await this.sendRequest(query);
    return { partners: [response.deletePartner] };
  }

  /**
   * Multipliers
   */
  defaultMultiplierProjection = {
    id: true,
    name: true,
    value: true,
    slug: true,
  };

  async fetchMultipliers({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { multipliers: Multiplier[] }> {
    const query = this.toGraphQL({
      query: {
        multipliers: projection || this.defaultMultiplierProjection,
      },
    });

    const response = await this.sendRequest(query);
    const { multipliers, ...meta } = response;

    return {
      multipliers,
      ...meta,
    };
  }

  /**
   * DataQ Clients
   */
  defaultDataQClientsProjection = {
    id: true,
    name: true,
  };

  async fetchDataQClients(
    method: string,
    { projection }: { projection?: Projection } = {}
  ): Promise<Response & { dataq: DataQClient[] }> {
    const __args = { method };
    const query = this.toGraphQL({
      query: {
        dataq: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultDataQClientsProjection),
        },
      },
    });

    const response = await this.sendRequest(query);

    const { dataq, ...meta } = response;
    return { dataq, ...meta };
  }

  /**
   * Fetch Facebook Marketing Data by type integration ID
   */
  defaultFacebookMarketingProjection = {
    id: true,
    name: true,
    error: true,
    status_message: true,
  };

  async fetchFacebookMarketingData({
    integration_id,
  }: {
    integration_id?: number;
  }): Promise<{ facebookMarketingData: FacebookMarketingDataType[] }> {
    const __args = { integration_id };
    const query = this.toGraphQL({
      query: {
        facebook_ads: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...this.defaultFacebookMarketingProjection,
        },
      },
    });

    const response = await this.sendRequest(query);

    const { facebook_ads, ...meta } = response;
    return { facebookMarketingData: facebook_ads, ...meta };
  }

  /**
   * Fetch TikTok Data by type integration ID.
   */
  defaultTikTokProjection = {
    id: true,
    name: true,
    error: true,
    status_message: true,
  };

  async fetchTikTokData({
    integration_id,
  }: {
    integration_id?: number;
  }): Promise<{ tikTokData: TikTokDataType[] }> {
    const __args = { integration_id };
    const query = this.toGraphQL({
      query: {
        tiktok_integration_accounts: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...this.defaultTikTokProjection,
        },
      },
    });

    const response = await this.sendRequest(query);

    return { tikTokData: response?.tiktok_integration_accounts };
  }

  /**
   * Fetch MicrosoftBing Data by type integration ID.
   */
  defaultMicrosoftBingAdAccountsProjection = {
    id: true,
    customer_id: true,
    name: true,
    error: true,
    status_message: true,
  };

  async fetchMicrosoftBingAdAccounts({
    integration_id,
  }: {
    integration_id?: number;
  }): Promise<{ microsoftBingAdAccounts: MicrosoftBingAdAccountType[] }> {
    const __args = { integration_id };
    const query = this.toGraphQL({
      query: {
        microsoft_bing_integration_accounts: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...this.defaultMicrosoftBingAdAccountsProjection,
        },
      },
    });

    const response = await this.sendRequest(query);

    return { microsoftBingAdAccounts: response?.microsoft_bing_integration_accounts };
  }

  /**
   * Fetch Amazon Ads Data by type integration ID
   */
  defaultAmazonAdsProjection = {
    id: true,
    name: true,
    profile_id: true,
    dsp_advertiser_id: true,
    marketplace_id: true,
    error: true,
    status_message: true,
  };

  async fetchAmazonAdsData({
    call_type,
    integration_id,
    marketplace_id
  }: {
    call_type: 'advertising' | 'dsp' | 'profiles';
    integration_id?: number;
    marketplace_id?: string;
  }): Promise<{ amazonMarketingData: AmazonAdsDataType[] }> {
    const __args = { integration_id, call_type, marketplace_id };
    const query = this.toGraphQL({
      query: {
        amazon_ads: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...this.defaultAmazonAdsProjection,
        },
      },
    });

    const response = await this.sendRequest(query);

    const { amazon_ads, ...meta } = response;
    return { amazonMarketingData: amazon_ads, ...meta };
  }

  /**
   * Fetch Google Analytics data by type (account, property, view)
   */
  defaultGoogleAnalyticsDataTypeProjection = {
    id: true,
    name: true,
    error: true,
    status_message: true,
  };

  async fetchGoogleAnalyticsData({
    integration_id,
    type,
    account_id,
    property_id,
  }: {
    integration_id?: number;
    type: string;
    account_id?: string;
    property_id?: string;
  }): Promise<{ googleAnalyticsData: GoogleAnalyticsDataType[] }> {
    const __args = { integration_id, type, account_id, property_id };
    const query = this.toGraphQL({
      query: {
        google_analytics: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...this.defaultGoogleAnalyticsDataTypeProjection,
        },
      },
    });

    const response = await this.sendRequest(query);

    const { google_analytics, ...meta } = response;
    return { googleAnalyticsData: google_analytics, ...meta };
  }

  /**
   * Fetch Google Analytics 4 data by type (account, property)
   */
  defaultGoogleAnalytics4DataTypeProjection = {
    id: true,
    name: true,
    error: true,
    status_message: true,
  };

  async fetchGoogleAnalytics4Data({
    integration_id,
    type,
    account_id,
    property_id,
  }: {
    integration_id?: number;
    type: string;
    account_id?: string;
    property_id?: string;
  }): Promise<{ googleAnalytics4Data: GoogleAnalytics4DataType[] }> {
    const __args = { integration_id, type, account_id, property_id };
    const query = this.toGraphQL({
      query: {
        google_analytics_4: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...this.defaultGoogleAnalytics4DataTypeProjection,
        },
      },
    });

    const response = await this.sendRequest(query);

    const { google_analytics_4, ...meta } = response;
    return { googleAnalytics4Data: google_analytics_4, ...meta };
  }

  /**
   * Fetch Google Ads data by type (Integration ID, Customer ID)
   */
  defaultGoogleAdsDataTypeProjection = {
    id: true,
    name: true,
    canManageClients: true,
    error: true,
    status_message: true,
  };

  async fetchGoogleAdsData({
    integration_id,
    customer_id,
  }: {
    integration_id?: number;
    customer_id?: string;
  }): Promise<{ googleAdsData: GoogleAdsDataType[] }> {
    const __args = { integration_id, customer_id };
    const query = this.toGraphQL({
      query: {
        google_ads: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...this.defaultGoogleAdsDataTypeProjection,
        },
      },
    });

    const response = await this.sendRequest(query);

    const { google_ads, ...meta } = response;
    return { googleAdsData: google_ads, ...meta };
  }

  /**
   * Fetch all available data sources for a client
   */
  defaultClientDataSourceProjection = {
    // id: true,
    name: true,
    source: true,
    property_id: true,
    property_name: true,
    earliest_date: true,
    latest_date: true,
  };

  async fetchClientDataSources({
    client_id,
    account_id,
  }: {
    client_id?: number;
    account_id?: number;
  }): Promise<{ clientDataSources: ClientDataSource[] }> {
    const __args = {
      client: {
        id: client_id,
      },
      data_sources: {
        account_id: account_id,
      },
    };

    /** query for data sources filtered by client */
    // const query = this.toGraphQL({
    //   query: {
    //     client: {
    //       __args: this.filterUndefinedNullEmpty(__args.client),
    //       data_sources: {
    //         __args: this.filterUndefinedNullEmpty(__args.data_sources),
    //         ...this.defaultClientDataSourceProjection,
    //       },
    //     },
    //   },
    // });

    /** response for data sources filtered by client */
    // const response = await this.sendRequest(query);
    // const { client, ...meta } = response;
    // return { clientDataSources: client.data_sources, ...meta };

    /** query for all available data sources */
    const query = this.toGraphQL({
      query: {
        data_sources: {
          __args: this.filterUndefinedNullEmpty(__args.data_sources),
          ...this.defaultClientDataSourceProjection,
        },
      },
    });

    /** response for all available data sources */
    const response = await this.sendRequest(query);
    const { data_sources, ...meta } = response;
    return { clientDataSources: data_sources, ...meta };
  }

  /**
   * Fetch audience insights data from DataQ for a specific client
   */
  defaultAudienceInsightsProjection = {
    id: true,
    name: true,
    description: true,
    num_customers: true,
    gross_revenue: true,
    repurchase_rate: true,
    lifetime_value: true,
  };

  async fetchAudienceInsights({
    method,
    client_id,
  }: {
    method: string;
    client_id: string;
  }): Promise<{ audienceInsights: AudienceInsights[] }> {
    const __args = { method, client_id };
    const query = this.toGraphQL({
      query: {
        dataq: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...this.defaultAudienceInsightsProjection,
        },
      },
    });

    const response = await this.sendRequest(query);
    const audienceInsights = response.dataq;
    return { audienceInsights: audienceInsights };
  }

  /**
   * Survey Types
   */
  defaultSurveyTypeProjection = {
    id: true,
    name: true,
  };

  async fetchSurveyTypes({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { survey_types: SurveyType[] }> {
    const query = this.toGraphQL({
      query: {
        survey_types: projection || this.defaultSurveyTypeProjection,
      },
    });

    const response = await this.sendRequest(query);
    const { survey_types, ...meta } = response;

    return {
      survey_types,
      ...meta,
    };
  }

  /**
   * Skillset Grades
   */
  defaultSkillsetGradeProjection = {
    id: true,
    name: true,
    percent: true,
  };

  async fetchSkillsetGrades({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { skillset_grades: SkillsetGrade[] }> {
    const query = this.toGraphQL({
      query: {
        skillset_grades: projection || this.defaultSkillsetGradeProjection,
      },
    });

    const response = await this.sendRequest(query);
    const { skillset_grades, ...meta } = response;

    return {
      skillset_grades,
      ...meta,
    };
  }

  /**
   * Role Types
   */
  defaultRoleTypeProjection = {
    id: true,
    name: true,
    slug: true,
  };

  async fetchRoleTypes({
    type,
    types,
    slugs,
    projection,
  }: {
    type?: string;
    types?: string[];
    slugs?: string[];
    projection?: Projection;
  }): Promise<Response & { roles: RoleType[] }> {
    const __args = this.filterUndefinedNullEmpty({ type, types, slugs});
    const query = isEmpty(__args) ? this.toGraphQL({
      query: {
        roles: {
          ...(projection || this.defaultRoleTypeProjection)
        },
      },
    }) : this.toGraphQL({
      query: {
        roles: {
          __args,
          ...(projection || this.defaultRoleTypeProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    const { roles, ...meta } = response;

    return {
      roles,
      ...meta,
    };
  }

  /**
   * Talent Surveys
   */

  sharedTalentSurveyProjectionFields = {
    id: true,
    buckets: {
      id: true,
      criteria: {
        name: true,
        id: true,
        score: true,
        bucket: {
          name: true,
          id: true,
          score: true,
          max: true,
        },
      },
      description: true,
      max: true,
      name: true,
      notes: true,
      question_group: {
        id: true,
        slug: true,
      },
      score: true,
    },
    children: {
      id: true,
      score: true,
      max: true,
      status: true,
      user: {
        id: true,
        name: true,
        avatar: true,
        title: true,
        roles: true,
        department: {
          id: true,
        },
      },
      reviewee: {
        id: true,
        department: {
          id: true,
        },
        roles: true,
        name: true,
        avatar: true,
        title: true,
      },
      scores: {
        id: true,
        score: true,
        slug: true,
        name: true,
        max: true,
        buckets: {
          id: true,
          name: true,
          score: true,
          max: true,
        },
      },
    },
    count: true,
    isExpired: true,
    isSelfSurvey: true,
    manager_score: true,
    max: true,
    notes: true,
    number_one_skill: true,
    reviewee: {
      name: true,
      id: true,
      title: true,
      avatar: true,
      department: {
        id: true,
      },
      roles: true,
      managers: {
        id: true,
        name: true,
      }
    },
    review_type: true,
    score: true,
    scores: {
      id: true,
      name: true,
      score: true,
      slug: true,
      notes: true,
      max: true,
      type: true,
      survey_type: {
        id: true,
        name: true,
        slug: true,
      },
      buckets: {
        name: true,
        id: true,
        max: true,
        score: true,
        description: true,
        notes: true,
        criteria: {
          name: true,
          id: true,
          score: true,
          bucket: {
            name: true,
            id: true,
            score: true,
            max: true,
          },
        },
        question_group: {
          id: true,
          slug: true,
        }
      },
    },
    status: true,
    total: true,
    type: {
      id: true,
      name: true,
      pairable: true,
      shared_slug: true,
    },
    user: {
      name: true,
      id: true,
      title: true,
      avatar: true,
      department: {
        id: true,
      },
      reviewees: {
        id: true,
      },
      roles: true,
      manager: {
        id: true,
      },
      managers: {
        id: true,
      },
    },
  };

  defaultTalentSurveyProjection = {
    ...this.sharedTalentSurveyProjectionFields,
    parent: this.sharedTalentSurveyProjectionFields,
  };

  async fetchTalentSurveyById(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ surveys: TalentSurvey[] }> {
    const __args = { id };
    const query = this.toGraphQL({
      query: {
        survey: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultTalentSurveyProjection),
        },
      },
    });

    const { survey } = await this.sendRequest(query);

    return { surveys: [survey] };
  }

  async fetchSubordinates({
    include_self = 'yes',
    name,
    pagination: { page, limit } = this.defaultPagination,
    projection,
  }: FetchManyPaginatedConfig & {
    include_self?: YesNo;
    name?: string;
  }): Promise<PaginatedResponse & { users: Partial<User>[] }> {
    const __args = { include_self, name, page, limit };
    const query = this.toGraphQL({
      query: {
        subordinates: {
          __args: this.filterUndefinedNullEmpty(__args),
          data: projection || this.defaultUserProjection,
          ...this.paginationProjection,
        },
      },
    });

    const response = await this.sendRequest(query);
    const {
      subordinates: { data: subordinates, ...meta },
    } = response;
    return {
      users: subordinates,
      ...meta,
    };
  }

  async fetchSurveys({
    include_self = 'yes',
    flattened = 'yes',
    name,
    user_id,
    sort = ['-updated_at'],
    pagination: { page, limit } = this.defaultPagination,
    projection,
  }: FetchManyPaginatedConfig & {
    include_self?: YesNo;
    flattened?: YesNo;
    name?: string;
    user_id?: number;
    sort?: string[];
  }): Promise<PaginatedResponse & { surveys: Partial<TalentSurvey>[] }> {
    const __args = {
      include_self,
      flattened,
      name,
      user_id,
      sort,
      page,
      limit,
    };
    const query = this.toGraphQL({
      query: {
        surveys: {
          __args: this.filterUndefinedNullEmpty(__args),
          data: projection || this.defaultTalentSurveyProjection,
          ...this.paginationProjection,
        },
      },
    });

    const response = await this.sendRequest(query);
    const {
      surveys: { data: surveys, ...meta },
    } = response;
    return {
      surveys,
      ...meta,
    };
  }

  async createSkillsetSurvey(
    args: {
      user_id: number;
      reviewee_id?: number;
      survey_type_id: number;
      survey_cycle_id?: number;
      parent_id?: number;
    },
    { projection }: { projection?: Projection } = {}
  ): Promise<TalentSurvey> {
    const query = this.toGraphQL({
      mutation: {
        createSkillsetSurvey: {
          __args: this.filterUndefinedNullEmpty(args),
          ...(projection || this.defaultTalentSurveyProjection),
        },
      },
    });

    const { createSkillsetSurvey } = await this.sendRequest(query);

    return createSkillsetSurvey;
  }

  async updateTalentSurvey(
    args: UpdateTalentSurveyArgs,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ surveys: Partial<TalentSurvey>[] }> {
    const query = this.toGraphQL({
      mutation: {
        updateSkillsetSurvey: {
          __args: this.filterUndefinedNullEmpty(args),
          ...(projection || this.defaultTalentSurveyProjection),
        },
      },
    });

    const { updateSkillsetSurvey } = await this.sendRequest(query);
    return { surveys: [updateSkillsetSurvey] };
  }

  async updateTalentSurveyBucket(
    args: UpdateTalentSurveyBucketArgs,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ surveys: TalentSurvey[] }> {
    const { bucket_id, survey_id, score, notes } = args;
    const query = this.toGraphQL({
      mutation: {
        updateSkillsetSurveyBucket: {
          __args: this.filterUndefinedNullEmpty({
            skillset_bucket_id: bucket_id,
            skillset_survey_id: survey_id,
            score,
            notes,
          }),
          ...(projection || this.defaultTalentSurveyProjection),
        },
      },
    });

    const { updateSkillsetSurveyBucket: survey } = await this.sendRequest(query);
    return { surveys: [survey] };
  }

  async updateTalentSurveyScore(
    args: {
      skillset_survey_id: number;
      skillset_score_id: number;
      notes: string;
    },
    { projection }: { projection?: Projection } = {}
  ): Promise<TalentSurvey> {
    const query = this.toGraphQL({
      mutation: {
        updateSkillsetSurveyScore: {
          __args: this.filterUndefinedNullEmpty(args),
          ...(projection || this.defaultTalentSurveyProjection),
        },
      },
    });

    const { updateSkillsetSurveyScore } = await this.sendRequest(query);

    return updateSkillsetSurveyScore;
  }

  /**
   * Rules
   */
  defaultRuleProjection = {
    id: true,
    name: true,
    type: true,
    criteria: {
      id: true,
      name: true,
      entity: {
        id: true,
        name: true,
        slug: true,
        type: true,
        value: true,
      },
      operator: true,
      value: true,
    },
    tasks: {
      id: true,
    },
  };

  async fetchRules({
    filter = {},
    projection,
  }: {
    projection?: Projection;
    filter?: FetchRulesFilter;
  }): Promise<Response & { rules: Rule[] }> {
    const __args = filter;

    // Fuzzy search
    if (__args.name) {
      __args.name = `*${__args.name}*`;
    }

    const query = this.toGraphQL({
      query: {
        rules: {
          ...(Object.keys(__args).length ? { __args } : {}),
          ...(projection || this.defaultRuleProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return response;
  }

  async fetchRuleById(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ rules: Partial<Rule>[] }> {
    const __args = { id };
    const query = this.toGraphQL({
      query: {
        rules: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultRuleProjection),
        },
      },
    });

    const { rules } = await this.sendRequest(query);
    return { rules };
  }

  async createRule(
    values: Partial<RuleFormValues>,
    { projection }: { projection?: Projection } = {}
  ): Promise<{ rules: Partial<Rule>[] }> {
    const query = this.toGraphQL({
      mutation: {
        createRule: {
          __args: this.filterUndefinedNullEmpty(values),
          ...(projection || this.defaultRuleProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { rules: [response.createRule] };
  }

  async updateRule(
    values: Partial<RuleFormValues>,
    { projection }: { projection?: Projection } = {}
  ) {
    const query = this.toGraphQL({
      mutation: {
        updateRule: {
          __args: this.filterUndefinedNullEmpty(values),
          ...(projection || this.defaultRuleProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    return { rules: [response.updateRule] };
  }

  async deleteRule(id: number) {
    const query = this.toGraphQL({
      mutation: {
        deleteRule: {
          __args: { id },
          id: true,
        },
      },
    });
    const response = await this.sendRequest(query);
    return { rules: [response.deleteRule] };
  }

  /**
   * Criteria Entities
   */
  defaultCriteriaEntityProjection = {
    id: true,
    name: true,
    slug: true,
    type: true,
    entity_id: true,
    entity_type: true,
    value: true,
    business_type: {
      id: true,
      name: true,
      slug: true,
    },
    parent: {
      name: true,
      entity_id: true,
      entity_type: true,
    },
  };
  async fetchCriteriaEntities({
    filter = {},
    projection,
  }: {
    projection?: Projection;
    filter?: { query?: string };
  }): Promise<{ entities: Entity[] }> {
    const __args = filter;

    // Fuzzy search
    if (__args.query) {
      __args.query = `*${__args.query}*`;
    }

    const query = this.toGraphQL({
      query: {
        criteria_search: {
          ...(Object.keys(__args).length ? { __args } : {}),
          ...(projection || this.defaultCriteriaEntityProjection),
        },
      },
    });

    const response = await this.sendRequest(query);

    const { criteria_search } = response;

    return { entities: criteria_search };
  }

  /**
   * DataQ API.
   */
  dataqDataSourceProjection = {
    id: true,
    frequency_mins: true,
    is_ingesting: true,
    is_active: true,
    last_error: true,
    progress: true,
    manual_sync_at: true,
    created_at: true,
    updated_at: true,
    secret_arn: true,
  };

  async getDataQClientAccount(
    {
      workspace_id,
      datasource_id
    }:
    {
      workspace_id?: string;
      datasource_id?: string
    }): Promise<{ clientAccount: Partial<DataQDataSource> }> {
    const __args = { workspace_id, datasource_id };
    const query = this.toGraphQL({
      query: {
        dataqDataSource: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...this.dataqDataSourceProjection,
        },
      },
    });

    const { dataqDataSource } = await this.sendRequest(query);

    return {
      clientAccount: dataqDataSource,
    };
  }

  dataqSecretDataProjection = {
    data: true
  };

  async getDataQSecretValues(
    {
      secret_arn,
    }:
    {
      secret_arn?: string;
    }): Promise<{ responseData?: string }> {
    const __args = { secret_arn };
    const query = this.toGraphQL({
      query: {
        dataqDataSourceSecret: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...this.dataqSecretDataProjection,
        },
      },
    });

    const { dataqDataSourceSecret } = await this.sendRequest(query);

    return {
      responseData: dataqDataSourceSecret?.data,
    };
  }

  async updateDataQIntegration(
    {
      id,
      form_values
    }:
    {
      id?: number;
      form_values?: string;
    }): Promise<ReturnStatusApi> {
    const __args = { id, form_values};

    const query = this.toGraphQL({
      mutation: {
        updateDataQIntegration: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...returnStatusProjection,
        },
      },
    });

    const response = await this.sendRequest(query);

    return response;
  }

  async getDashboardReport(): Promise<{
    data: any;
    dashboard_report: { [key: string]: string };
    rest: any;
  }> {
    const query = this.toGraphQL({
      query: {
        dashboard_report: {
          key: true,
          value: true,
        },
      },
    });
    const { data, dashboard_report, ...rest } = await this.sendRequest(query);

    return { data, dashboard_report, ...rest };
  }

  async fetchTotalClients({
    args: {
      business_type_ids,
      industry_ids,
      client_ids,
      // service_ids,
    } = {},
    projection,
  }: {
    args?: {
      business_type_ids?: number[];
      industry_ids?: number[];
      client_ids?: number[];
      // service_ids?: number[];
    };
    projection?: any;
  } = {}): Promise<any> {
    const __args = {
      business_type_ids,
      industry_ids,
      client_ids,
      // service_ids,
    };
    const args = this.filterUndefinedNullEmpty(__args) || null;

    const query = isEmpty(args) ? this.toGraphQL({
      query: {
        clients: {
          ...(projection || { limit: true })
        },
      },
    }) : this.toGraphQL({
      query: {
        clients: {
          __args: args,
          ...(projection || { limit: true })
        },
      },
    });
    const response = await this.sendRequest(query);
    const { clients } = response;
    return { clients: clients.total };
  }

  defaultAlertProjection = {
    id: true,
    author: {
      id: true,
      name: true,
    },
    type: true,
    style: true,
    is_active: true,
    start_at: true,
    end_at: true,
    title: true,
    text: true,
    show_button: true,
    created_at: true,
    deleted_at: true,
    updated_at: true,
    recipients: {
      id: true,
      entity: {
        id: true,
        type: true,
        name: true,
      }
    }
  };

  async fetchAlerts({
    args: {
      id,
      is_active,
      title,
      business_type_id,
      industry_id,
      sort,
      ...args
    } = {},
    pagination: { page, limit } = this.defaultPagination,
    projection,
  }: {
    args?: FetchAlertsFilter;
    pagination?: Pagination;
    projection?: Projection;
  } = {}): Promise<PaginatedResponse & { alerts: Partial<Alert>[] }> {
    const __args = {
      page,
      limit,
      id,
      title,
      sort,
      business_type_id,
      industry_id,
      is_active,
      ...args,
    };
    const query = (isEmpty(__args)) ? this.toGraphQL({
      query: {
        alerts: {
          data: projection || this.defaultAlertProjection,
          ...this.paginationProjection,
        },
      },
    }) : this.toGraphQL({
      query: {
        alerts: {
          __args: this.filterUndefinedNullEmpty(__args),
          data: projection || this.defaultAlertProjection,
          ...this.paginationProjection,
        },
      },
    });

    const response = await this.sendRequest(query);
    const {
      alerts: { data: alerts, ...meta },
    } = response;
    return {
      alerts,
      ...meta,
    };
  }

  async fetchAlertById(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<any> {
    const { alerts } = await this.fetchAlerts({
      args: { id },
      pagination: { page: 1, limit: 1 },
      projection,
    });
    return { alerts };
  }

  async deleteAlert(
    id: number,
    { projection }: { projection?: Projection } = {}
  ): Promise<any> {
    const mutation = await this.toGraphQL({
      mutation: {
        deleteAlert: {
          __args: { id },
          ...(projection || { id: true }),
        },
      },
    });
    const response = await this.sendRequest(mutation);
    return response;
  }

  async createAlert(
    {
      type,
      style,
      is_active,
      start_at,
      end_at,
      title,
      text,
      show_button,
    }: {
      type: string;
      style: 'sticky' | 'popup' | 'section' | 'badge';
      is_active: YesNo;
      start_at: string;
      end_at: string;
      title: string;
      text: string;
      show_button: YesNo;
    },
    { projection }: { projection?: Projection } = {}
  ): Promise<any> {
    const __args = {
      type,
      style,
      is_active,
      start_at,
      end_at,
      title,
      text,
      show_button,
      projection
    };
    const mutation = {
      mutation: {
        createAlert: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || { id: true }),
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }

  async updateAlert(
    {
      id,
      type,
      style,
      is_active,
      start_at,
      end_at,
      title,
      text,
      show_button,
    }: {
      id: number;
      type: string;
      style: 'sticky' | 'popup' | 'section' | 'badge';
      is_active: YesNo;
      start_at: string;
      end_at: string;
      title: string;
      text: string;
      show_button: YesNo;
    },
    { projection }: { projection?: Projection } = {}
  ): Promise<any> {
    const __args = {
      id,
      type,
      style,
      is_active,
      start_at,
      end_at,
      title,
      text,
      show_button,
      projection
    };
    const mutation = {
      mutation: {
        updateAlert: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || { id: true }),
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }

  async createAlertRecipients(
    {
      alert_id,
      entity_ids,
      entity_type
    }: {
      alert_id: number;
      entity_ids: number[];
      entity_type: string;
    },
    { projection }: { projection?: Projection } = {}
  ): Promise<any> {
    const __args = {
      alert_id,
      entity_ids,
      entity_type
    };
    const mutation = {
      mutation: {
        createAlertRecipients: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || { id: true }),
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }

  async saveAlertRecipients(
    {
      alert_id,
      recipients,
      delete_missing
    }: {
      alert_id: number;
      recipients: { entity_id: number, entity_type: 'BusinessType' | 'Industry' | 'Client' }[];
      delete_missing: YesNo;
    },
    { projection }: { projection?: Projection } = {}
  ): Promise<any> {
    const __args = {
      alert_id,
      recipients,
      delete_missing
    };
    const mutation = {
      mutation: {
        saveAlertRecipients: {
          __args,
          ...(projection || { id: true }),
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }

  async createAlertClient(
    {
      alert_id,
      client_id,
      is_active
    }: {
      alert_id: number;
      client_id: number;
      is_active?: string;
    },
    { projection }: { projection?: Projection } = {}
  ): Promise<any> {
    const __args = {
      alert_id,
      client_id,
      is_active
    };
    const mutation = {
      mutation: {
        createAlertClient: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || { id: true }),
        },
      },
    };
    const query = this.toGraphQL(mutation);
    const response = await this.sendRequest(query);
    return response;
  }

  /**
   * Analyst Survey Automation
   */
  defaultAutomationServicesProjection = {
    service: {
      id: true,
      name: true,
      data_sources: {
        id: true,
        name: true,
        slug: true
      },
      department: {
        id: true,
        name: true,
        slug: true,
      }
    }
  };
  async fetchAutomationServices({ projection }: {
    projection?: Projection;
  }): Promise<Response & { automationServices: AutomationService[] }> {
    const query = this.toGraphQL({
      query: {
        enable_services_auto_score: {
          ...(projection || this.defaultAutomationServicesProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    const { enable_services_auto_score: automationServices, ...meta } = response;

    return {
      automationServices,
      ...meta,
    };
  }

  defaultAutomationDataSourcesProjection = {
    id: true,
    name: true,
    slug: true,
  };
  async fetchAutomationDataSources({ projection }: {
    projection?: Projection;
  }): Promise<Response & { automationDataSources: { id: number, name: string, slug: string }[] }> {
    const query = this.toGraphQL({
      query: {
        enable_datasource_auto_score: {
          ...(projection || this.defaultAutomationDataSourcesProjection),
        },
      },
    });

    const response = await this.sendRequest(query);
    const { enable_datasource_auto_score: automationDataSources, ...meta } = response;

    return {
      automationDataSources,
      ...meta,
    };
  }

  async updateAutomatedScores({
    audit_id,
    id,
    ids,
    integration_id,
    data_source_slug,
    status,
  }: {
    audit_id: number;
    id?: number;
    ids?: number[];
    integration_id?: number;
    data_source_slug?: string;
    status?: string;
  }): Promise<AutomatedScore[]> {
    const __args = {
      audit_id,
      id,
      ids,
      integration_id,
      data_source_slug,
      status
    };
    const query = this.toGraphQL({
      mutation: {
        updateAutomatedScore: {
          __args: this.filterUndefinedNullEmpty(__args),
          id: true,
          integration_id: true,
          status: true,
        },
      },
    });

    const { updateAutomatedScore } = await this.sendRequest(query);

    return updateAutomatedScore;
  }

  defaultDashboardProjection = {
    id: true,
    client: {
      id: true,
      name: true,
      industry: {
        name: true,
      },
      account_manager: {
        id: true,
        name: true,
        avatar: true,
      },
      executive_sponsor: {
        id: true,
        name: true,
        avatar: true,
      },
      tier: {
        id: true,
        name: true,
        slug: true
      }
    },
    is_active: true,
    pace: {
      id: true,
      name: true,
      slug: true,
      color: true,
      is_active: true,
    },
    rapport: {
      id: true,
      name: true,
      slug: true,
      color: true,
      is_active: true,
    },
    churn: {
      id: true,
      name: true,
      slug: true,
      color: true,
      is_active: true,
    },
    escalation: true,
    contract: true,
    comment: true,
    notice_date: true,
    start_date: true,
    end_date: true,
    referrals: true,
    retainer: true,
    renewal_terms: true,
    monthly_goal_forecast: true,
    organization: {
      id: true,
      name: true,
      slug: true,
    },
    revisions: {
      id: true,
      updated_at: true,
      churn: {
        id: true,
        name: true,
        slug: true,
        color: true,
        is_active: true,
      },
    },
    notes: {
      id: true,
      date: true,
      title: true,
      text: true,
      author: {
        id: true,
        name: true,
        avatar: true,
        roles_list: {
          name: true,
        }
      },
      children: {
        id: true,
        date: true,
        text: true,
        author: {
          id: true,
          name: true,
          avatar: true,
          roles_list: {
            name: true,
          }
        },
      },
      parent: {
        id: true,
        date: true,
        text: true,
      }
    },
    created_at: true,
    updated_at: true,
  };
  defaultDashboardNoteProjection = {
    id: true,
    dashboard: {
      id: true
    },
    date: true,
    author: {
      id: true,
      name: true,
      avatar: true,
      roles: true,
      roles_list: {
        id: true,
        name: true,
      },
    },
    title: true,
    text: true,
    parent: {
      id: true,
    },
    children: {
      id: true,
    },
    created_at: true,
    updated_at: true,
  };

  async fetchDashboards({
    args: {
      id,
      client_id,
      dashboard_pace_ids,
      dashboard_rapport_ids,
      dashboard_churn_ids,
      account_manager_ids,
      executive_sponsor_ids,
      notice_date,
      referrals,
      organization_id,
      is_active,
      sort,
      ...args
    } = {},
    pagination: { page, limit } = this.defaultPagination,
    projection,
  }: {
    args?: FetchDashboardsFilter;
    pagination?: Pagination;
    projection?: Projection;
  } = {}): Promise<PaginatedResponse & { dashboards: Partial<Dashboard>[] }> {
    const __args = this.filterUndefinedNullEmpty({
      id,
      client_id,
      dashboard_pace_ids,
      dashboard_rapport_ids,
      dashboard_churn_ids,
      account_manager_ids,
      executive_sponsor_ids,
      notice_date,
      referrals,
      organization_id,
      is_active,
      page,
      limit,
      sort,
      ...args,
    });
    const query = (isEmpty(__args)) ? this.toGraphQL({
      query: {
        dashboards: {
          __args: { sort: 'clients.name' },
          data: {
            ...(projection || this.defaultDashboardProjection),
            notes: {
              __args: { is_parent: 'yes' },
              ...(projection?.['notes'] || this.defaultDashboardProjection['notes']),
            }
          },
          ...this.paginationProjection,
        },
      },
    }) : this.toGraphQL({
      query: {
        dashboards: {
          __args,
          data: {
            ...(projection || this.defaultDashboardProjection),
            notes: {
              __args: { is_parent: 'yes' },
              ...(projection?.['notes'] || this.defaultDashboardProjection['notes']),
            }
          },
          ...this.paginationProjection,
        },
      },
    });
    const response = await this.sendRequest(query);
    const {
      dashboards: { data: dashboards, ...meta },
    } = response;
    return {
      dashboards,
      ...meta,
    };
  }

  async fetchDashboardById({
    id,
    client_id,
    projection
  }: {
    id?: number;
    client_id?: number;
    projection?: Projection;
  }): Promise<{ dashboards: Dashboard[] }> {
    const __args = { id, client_id };
    const query = this.toGraphQL({
      query: {
        dashboard: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection ||
            { ...this.defaultDashboardProjection,
              departments: {
                id: true,
                name: true,
                slug: true,
              },
            }
          ),
          notes: {
            __args: {
              is_parent: 'yes',
            },
            ...(projection?.['notes'] || this.defaultDashboardProjection['notes'])
          }
        },
      },
    });

    const response = await this.sendRequest(query);
    const dashboard = response.dashboard;

    return { dashboards: [dashboard] };
  }

  async fetchDashboardNotes({
    args: {
      id,
      dashboard_id,
      date,
      date_start,
      date_end,
      author_id,
      parent_id,
      ...args
    } = {},
    pagination: { page, limit } = this.defaultPagination,
    projection,
  }: {
    args?: FetchDashboardNotesFilter;
    pagination?: Pagination;
    projection?: Projection;
  } = {}): Promise<PaginatedResponse & { dashboards: Partial<Note>[] }> {
    const __args = this.filterUndefinedNullEmpty({
      id,
      dashboard_id,
      date,
      date_start,
      date_end,
      author_id,
      page,
      limit,
      parent_id,
      ...args,
    });
    const query = (isEmpty(__args)) ? this.toGraphQL({
      query: {
        dashboard_notes: {
          data: projection || this.defaultDashboardNoteProjection,
          ...this.paginationProjection,
        },
      },
    }) : this.toGraphQL({
      query: {
        dashboard_notes: {
          __args,
          data: projection || this.defaultDashboardNoteProjection,
          ...this.paginationProjection,
        },
      },
    });
    const response = await this.sendRequest(query);
    const {
      dashboard_notes: { data: dashboard_notes, ...meta },
    } = response;
    return {
      dashboard_notes,
      ...meta,
    };
  }

  async createDashboard({
    client_id,
    is_active,
    dashboard_pace_id,
    dashboard_rapport_id,
    dashboard_churn_id,
    escalation,
    contract,
    notice_date,
    start_date,
    end_date,
    retainer,
    renewal_terms,
    monthly_goal_forecast,
    referrals,
    organization_id
  }: {
    client_id?: number;
    dashboard_pace_id?: number;
    dashboard_rapport_id?: number;
    dashboard_churn_id?: number;
    organization_id?: number;
  } & Partial<DashboardAndRevision>,
  { projection }: { projection?: Projection } = {}): Promise<any> {
    const __args = {
      client_id,
      is_active,
      dashboard_pace_id,
      dashboard_rapport_id,
      dashboard_churn_id,
      escalation,
      contract,
      notice_date,
      start_date,
      end_date,
      retainer,
      renewal_terms,
      monthly_goal_forecast,
      referrals,
      organization_id
    };
    const query = this.toGraphQL({
      mutation: {
        createDashboard: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultDashboardProjection)
        }
      }
    });
    const response = await this.sendRequest(query);
    return { dashboards: [response.createDashboard] };
  }

  async updateDashboard({
    id,
    is_active,
    dashboard_pace_id,
    dashboard_rapport_id,
    dashboard_churn_id,
    escalation,
    comment,
    contract = null,
    notice_date,
    start_date,
    end_date,
    retainer,
    renewal_terms,
    monthly_goal_forecast = null,
    referrals,
    organization_id
  }: Partial<DashboardAdditional> & Partial<DashboardAndRevision>,
  { projection }: { projection?: Projection } = {}): Promise<any> {
    const __args = {
      id,
      is_active,
      dashboard_pace_id,
      dashboard_rapport_id,
      dashboard_churn_id,
      escalation,
      comment,
      contract,
      notice_date,
      start_date,
      end_date,
      retainer,
      renewal_terms,
      monthly_goal_forecast,
      referrals,
      organization_id
    };
    const query = this.toGraphQL({
      mutation: {
        updateDashboard: {
          __args: this.filterUndefinedNullEmpty(__args, ['contract', 'monthly_goal_forecast']),
          ...(projection || this.defaultDashboardProjection)
        }
      }
    });
    const response = await this.sendRequest(query);
    return { dashboards: [response.updateDashboard] };
  }

  async deleteDashboard({
    id,
  }: {
    id: number;
  },
  { projection }: { projection?: Projection } = {}): Promise<any> {
    const __args = { id };
    const query = this.toGraphQL({
      mutation: {
        deleteDashboard: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || { id: true })
        }
      }
    });
    const { deleteDashboard } = await this.sendRequest(query);
    return deleteDashboard;
  }

  async createDashboardNote({
    dashboard_id,
    parent_id,
    author_id,
    title,
    text,
  }: {
    dashboard_id: number;
    parent_id?: number;
    author_id: number;
    title?: string;
    text?: string;
  },
  { projection }: { projection?: Projection } = {}
  ): Promise<{ dashboards: Partial<Note>[] }> {
    const __args = {
      dashboard_id,
      parent_id,
      author_id,
      title,
      text,
    };
    const query = this.toGraphQL({
      mutation: {
        createDashboardNote: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultDashboardProjection['notes'])
        }
      }
    });
    const { createDashboardNote } = await this.sendRequest(query);
    return createDashboardNote;
  }

  async updateDashboardNote({
    id,
    date,
    text,
  }: {
    id: number;
    date?: string;
    text?: string;
  },
  { projection }: { projection?: Projection } = {}
  ): Promise<{ dashboards: Partial<Note>[] }> {
    const __args = {
      id,
      date,
      text,
    };
    const query = this.toGraphQL({
      mutation: {
        updateDashboardNote: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultDashboardProjection['notes'])
        }
      }
    });
    const { updateDashboardNote } = await this.sendRequest(query);
    return updateDashboardNote;
  }

  async deleteDashboardNote({
    id,
  }: {
    id: number;
  },
  { projection }: { projection?: Projection } = {}
  ): Promise<{ dashboards: Partial<Note>[] }> {
    const __args = {
      id,
    };
    const query = this.toGraphQL({
      mutation: {
        deleteDashboardNote: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultDashboardProjection['notes'])
        }
      }
    });
    const { deleteDashboardNote } = await this.sendRequest(query);
    return deleteDashboardNote;
  }

  async createDashboardChurn({
    name,
    slug,
    color,
    is_active
  }: {
    name?: string;
    slug?: string;
    color?: string;
    is_active?: YesNo;
  },
  { projection }: { projection?: Projection } = {}
  ): Promise<{ dashboard_churns: Partial<Churn>[] }> {
    const __args = {
      name,
      slug,
      color,
      is_active
    };
    const query = this.toGraphQL({
      mutation: {
        createDashboardChurn: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultDashboardProjection['churn'])
        }
      }
    });
    const response = await this.sendRequest(query);
    return { dashboard_churns: [response.createDashboardChurn] };
  }

  async updateDashboardChurn({
    id,
    name,
    slug,
    color,
    is_active
  }: {
    id: number;
    name?: string;
    slug?: string;
    color?: string;
    is_active?: YesNo;
  },
  { projection }: { projection?: Projection } = {}
  ): Promise<{ dashboard_churns: Partial<Churn>[] }> {
    const __args = {
      id,
      name,
      slug,
      color,
      is_active
    };
    const query = this.toGraphQL({
      mutation: {
        updateDashboardChurn: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultDashboardProjection['churn'])
        }
      }
    });
    const response = await this.sendRequest(query);
    return { dashboard_churns: [response.updateDashboardChurn] };
  }

  async deleteDashboardChurn(
    { id }: { id: number },
    { projection }: { projection?: Projection } = {}
  ): Promise<{ dashboard: { churn: Churn[] } } | any> {
    const __args = { id };

    const query = this.toGraphQL({
      mutation: {
        deleteDashboardChurn: {
          __args,
          ...(projection || this.defaultDashboardProjection['churn'])
        }
      }
    });
    const response = await this.sendRequest(query);
    return { dashboard_churns: [response.deleteDashboardChurn] };
  }

  async createDashboardRapport({
    name,
    slug,
    color,
    is_active
  }: {
    name?: string;
    slug?: string;
    color?: string;
    is_active?: YesNo;
  },
  { projection }: { projection?: Projection } = {}
  ): Promise<{ dashboard_rapports: Partial<Rapport>[] }> {
    const __args = {
      name,
      slug,
      color,
      is_active
    };
    const query = this.toGraphQL({
      mutation: {
        createDashboardRapport: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultDashboardProjection['rapport'])
        }
      }
    });
    const response = await this.sendRequest(query);
    return { dashboard_rapports: [response.createDashboardRapport] };
  }

  async updateDashboardRapport({
    id,
    name,
    slug,
    color,
    is_active
  }: {
    id: number;
    name?: string;
    slug?: string;
    color?: string;
    is_active?: YesNo;
  },
  { projection }: { projection?: Projection } = {}
  ): Promise<{ dashboard_rapports: Partial<Rapport>[] }> {
    const __args = {
      id,
      name,
      slug,
      color,
      is_active
    };
    const query = this.toGraphQL({
      mutation: {
        updateDashboardRapport: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultDashboardProjection['rapport'])
        }
      }
    });
    const response = await this.sendRequest(query);
    return { dashboard_rapports: [response.updateDashboardRapport] };
  }

  async deleteDashboardRapport(
    { id }: { id: number },
    { projection }: { projection?: Projection } = {}
  ): Promise<{ dashboard: { rapport: Rapport[] } }> {
    const __args = { id };

    const query = this.toGraphQL({
      mutation: {
        deleteDashboardRapport: {
          __args,
          ...(projection || this.defaultDashboardProjection['rapport'])
        }
      }
    });
    const { deleteDashboardRapport } = await this.sendRequest(query);
    return deleteDashboardRapport;
  }

  async createDashboardPace({
    name,
    slug,
    color,
    is_active
  }: {
    name?: string;
    slug?: string;
    color?: string;
    is_active?: YesNo;
  },
  { projection }: { projection?: Projection } = {}
  ): Promise<{ dashboard_paces: Partial<Pace>[] }> {
    const __args = {
      name,
      slug,
      color,
      is_active
    };
    const query = this.toGraphQL({
      mutation: {
        createDashboardPace: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultDashboardProjection['pace'])
        }
      }
    });
    const response = await this.sendRequest(query);
    return { dashboard_paces: [response.createDashboardPace] };
  }

  async updateDashboardPace({
    id,
    name,
    slug,
    color,
    is_active
  }: {
    id: number;
    name?: string;
    slug?: string;
    color?: string;
    is_active?: YesNo;
  },
  { projection }: { projection?: Projection } = {}
  ): Promise<{ dashboard_paces: Partial<Pace>[] }> {
    const __args = {
      id,
      name,
      slug,
      color,
      is_active
    };
    const query = this.toGraphQL({
      mutation: {
        updateDashboardPace: {
          __args: this.filterUndefinedNullEmpty(__args),
          ...(projection || this.defaultDashboardProjection['pace'])
        }
      }
    });
    const response = await this.sendRequest(query);
    return { dashboard_paces: [response.updateDashboardPace] };
  }

  async deleteDashboardPace(
    { id }: { id: number },
    { projection }: { projection?: Projection } = {}
  ): Promise<{ dashboard: { pace: Pace[] } }> {
    const __args = { id };

    const query = this.toGraphQL({
      mutation: {
        deleteDashboardPace: {
          __args,
          ...(projection || this.defaultDashboardProjection['pace'])
        }
      }
    });
    const { deleteDashboardPace } = await this.sendRequest(query);
    return deleteDashboardPace;
  }

  async fetchChurns({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { dashboard_churns: Churn[] }> {
    const query = this.toGraphQL({
      query: {
        dashboard_churns: {
          __args: { sort: 'id' },
          ...(projection || this.defaultDashboardProjection['churn']),
        },
      },
    });

    const response = await this.sendRequest(query);
    return response;
  }

  async fetchRapports({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { dashboard_rapports: Rapport[] }> {
    const query = this.toGraphQL({
      query: {
        dashboard_rapports: {
          __args: { sort: 'id' },
          ...(projection || this.defaultDashboardProjection['rapport']),
        },
      },
    });

    const response = await this.sendRequest(query);
    return response;
  }

  async fetchPaces({
    projection,
  }: {
    projection?: Projection;
  }): Promise<Response & { dashboard_paces: Pace[] }> {
    const query = this.toGraphQL({
      query: {
        dashboard_paces: {
          __args: { sort: 'id' },
          ...(projection || this.defaultDashboardProjection['pace']),
        },
      },
    });

    const response = await this.sendRequest(query);
    return response;
  }
}

export const novaGraphQLClient = new NovaGraphQLClient();
