export const compareField = (field: string) => {
  return (a: { [x: string]: any }, b: { [x: string]: any }) => {
    try {
      // if (!a[field]) {
      if (
        !Object.prototype.hasOwnProperty.call(a, field) ||
        a[field] === null
      ) {
        // Change this values if you want to put `null` values at the end of the array
        return -1;
      }

      if (
        !Object.prototype.hasOwnProperty.call(b, field) ||
        b[field] === null
      ) {
        // Change this values if you want to put `null` values at the end of the array
        return +1;
      }
      return a[field].toString().localeCompare(b[field]);
    } catch (e) {
      console.error('🚒 e', e);
    }
  };
};
