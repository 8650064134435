import React, { FC, lazy } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectAuditById } from 'features/entitiesRedux/models/audit/selectors';
import { formatAuditTitle } from './utils';

export const AuditOverviewPage = lazy(() => import('./AuditOverviewPage'));

export const AuditOverviewPageTitle: FC = () => {
  const { id: idRaw = '' } = useParams<{ [x: string]: string }>();
  const id = Number.parseInt(idRaw);
  const audit = useSelector(selectAuditById(id));
  const isLoading = !audit;
  return <>{formatAuditTitle({ audit, isLoading })}</>;
};