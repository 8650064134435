/**
 * Partners -> External -> Partners List Page
 * 
 * Note: This page currently only redirects to the first partner of a user but it could later 
 * be used for listing out the partners of a user if the requirements should change
 */

import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { LoadingOutlined } from '@ant-design/icons';
import { useAccount } from 'features/global/hooks';
import { Alert } from 'components';
import css from './PartnersListPage.module.scss';

export const PartnersListPage: FC = () => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const { account } = useAccount();

  useEffect(() => {
    if (!error) {
      if (account && !account?.partners?.length) {
        setError(true);
      } else if (account?.partners?.length) {
        const partnerId = account.partners[0]?.id;
        history.replace(`/p/${partnerId}`);
      }
    }
  }, [error, account]);

  return (
    <div className={css.root}>
      {error ? (
        <Alert message="No partner dashboard found" type="error" />
      ) : (
        <LoadingOutlined className={css.spinner} />
      )}
    </div>
  );
};

