/**
 * SectionBuilder -> Fields -> SelectField
 */

import React, { FC } from 'react';
import {
  FormItem,
  Select,
  SelectBusinessType,
  SelectCrm,
  SelectEcommercePlatform,
  SelectIndustry,
  SelectLeadSource,
  SelectPillar
} from 'components';
import { convertToSlug } from '../../../../../../../../utils';
import { FieldProps } from '../../types';

const SelectField: FC<FieldProps> = ({ name, options, placeholder }) => {
  const renderSelect = () => {
    // handle special cases
    if (name === 'business_type_id') return <SelectBusinessType placeholder={placeholder} />;
    if (name === 'industry_id') return <SelectIndustry placeholder={placeholder} />;
    if (name === 'lead_source_id') return <SelectLeadSource placeholder={placeholder} excludeSlugs={['factorial-lead']} />;
    if (name === 'pillar_id') return <SelectPillar placeholder={placeholder} />;
    if (name === 'question_crm') return <SelectCrm valueKey="slug" placeholder={placeholder} />;
    if (name === 'question_ecommerce_platform') return <SelectEcommercePlatform valueKey="slug" placeholder={placeholder} />;

    if (options?.length) {
      return (
        <Select placeholder={placeholder}>
          {options?.map((opt, index) => (
            <Select.Option key={`${convertToSlug(opt)}-${index}`} value={convertToSlug(opt)}>
              {opt}
            </Select.Option>
          ))}
        </Select>
      );
    }
    return <Select />;
  };

  return (
    <FormItem name={name}>
      {renderSelect()}
    </FormItem>
  );
};

export default SelectField;
