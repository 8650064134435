/**
 * useDevelopmentStatuses
 * Fetches all development statuses
 */

import { useGetDevelopmentStatusesQuery } from 'api/crudGraphQL/talent_report/development_statuses/getDevelopmentStatuses';
import { DevelopmentStatus } from '../../api/crudGraphQL/talent_report/development_statuses/types';
import { Projection } from '../../api/entityGraphQL/types';

 type Type = {
   loading: boolean;
   error: string;
   development_statuses: DevelopmentStatus[];
 };

 type UseDevelopmentStatusesArgs = { projection?: Projection };

export const useDevelopmentStatuses = ({
  projection,
}: UseDevelopmentStatusesArgs = {}): Type => {
  const { data: development_statuses = [], isLoading, error } = useGetDevelopmentStatusesQuery({
    projection: projection ?? {
      name: true,
      id: true
    }
  });

  return {
    development_statuses,
    loading: isLoading,
    error: error?.message ?? 'Unknown error'
  };
};

export default useDevelopmentStatuses;
