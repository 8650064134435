import { useEffect, useState } from 'react';

/**
 * A custom hook that returns the number of pixels by which the document is currently scrolled vertically.
 *
 * @returns A number, which may be floating point, indicating the number of pixels scrolled vertically.
 */
const useScrollPosition = (): number => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (): void => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return (): void => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollPosition;
};

export default useScrollPosition;
