import React, { useCallback, useState, memo, useMemo, useImperativeHandle, forwardRef, useEffect } from 'react';
import { AutocompleteRenderGetTagProps } from '@mui/material';
import { Chip, SearchIcon, TextField } from '@sprnova/nebula';
import { useGetDepartmentsQuery } from 'api/crudGraphQL/departments/getDepartments';
import { useGetUsersQuery } from 'api/crudGraphQL/users/getUsers';
import classNames from 'classnames';
import { Department, User } from 'features/entitiesRedux';
import { Dropdown } from 'features/intelligence/components/library-components';
import { valuePresentStyles } from 'features/users/UserOverviewPage/components/constants';
import { FIELD_NAMES } from './constants';
import css from './BlueprintsPackageReviewFilter.module.scss';

interface BlueprintsPackageReviewFilterProps {
  setFilter: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  className?: string;
}

const BlueprintsPackageReviewFilter = forwardRef(({
  setFilter,
  className,
}: BlueprintsPackageReviewFilterProps, ref: any): JSX.Element => {
  /** Using local states because register form is not compatible with current Dropdown component in nova. */
  const [salesRep, setSalesRep] = useState<Partial<User>>();
  const [departments, setDepartments] = useState<Partial<Department>[]>();
  const [search, setSearch] = useState<string>('');

  // Debounce the search input to improve FE experience
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFilter((prevValue) => ({ ...prevValue, name: search }));
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [search, setFilter]);

  // Fetch all users
  const { data: usersData, isFetching: usersIsFetching } = useGetUsersQuery({
    page: 1,
    limit: 9999,
    projection: { id: true, name: true },
  });

  // Fetch all departments
  const {
    data: departmentsData,
    isFetching: departmentsIsFetching,
  } = useGetDepartmentsQuery({
    projection: { id: true, name: true },
  });

  const users = useMemo(() => {
    if (usersData?.data) {
      return usersData.data;
    }
    return [];
  }, [usersData]);

  /**
   * onChange handler for the Search filter
   *
   * @param event React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
   * @returns void
   */
  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearch(event.target.value);
  }, []);

  /**
   * onChange handle for Sales Rep filter
   *
   * @param event React.SyntheticEvent<Element, Event>
   * @param value User object selected
   * @param fieldName string - The name of the field
   * @returns void
   */
  const handleChangeFilterSalesRep = useCallback((event: React.SyntheticEvent<Element, Event>, value: Partial<User>, fieldName: string): void => {
    setSalesRep(value);
    const selectedValue = value ? value.id : null;
    setFilter((prevValue) => ({ ...prevValue, [`${fieldName}`]: selectedValue }));
  }, [setFilter]);

  /**
   * onChange handler for select fields which are <Dropdown /> component with type='autocomplete' and multiple props
   * The value is an array because of the multiple select option and that it is an <AutoComplete /> component
   *
   * @param event any
   * @param value Array of Department selected
   * @param fieldName string - The name of the field
   * @returns void
   */
  const handleChangeFilterDepartment = (event: any, value: Partial<Department>[], fieldName: string): void => {
    const inputId = event.target.id.substr(0, event.target.id.indexOf('-'));
    const departmentIds = value.map((department) => department.id);
    setFilter((prevValue) => ({ ...prevValue, [`${fieldName}`]: prevValue.departments_ids ? [...prevValue.departments_ids, ...departmentIds] : [...departmentIds] }));
    // if the inputId is empty, it means that the event is coming from the delete chip button
    if (!inputId) {
      setDepartments(value);
    } else {
      setDepartments([...value]);
    }
  };

  /**
   * Clear all filters when the clear button is clicked which means clear the departments and sales rep states
   * The Clear button is on the EmptyState component which is rendered when there are no strategies to be displayed.
   *
   * @returns void
   */
  const handleClearFilter = (): void => {
    setDepartments([]);
    setSalesRep(undefined);
    setSearch('');
  };

  useImperativeHandle(ref, () => ({
    clearFilter(): void {
      handleClearFilter();
    }
  }));

  /**
   * Render the Chip elements for the multi select department dropdown
   *
   * @param department
   * @returns JSX.Element
   */
  const departmentChip = (department: Department, index: number, getTagProps: AutocompleteRenderGetTagProps): JSX.Element => {
    return (
      <Chip
        {...getTagProps({ index })}
        key={`department-${department.id}`}
        className={css.chip}
        label={department.name}
        variant="outlined"
      />
    );
  };

  return (
    <div className={classNames(css.root, className)}>
      <form className={css.form}>
        <TextField
          id='search'
          className={css.form__search}
          placeholder='Search'
          onChange={(event): void => handleSearch(event)}
          InputProps={{
            startAdornment: (
              <SearchIcon />
            ),
          }}
          value={search}
        />
        <Dropdown
          handleSelect={(event, value): void => handleChangeFilterDepartment(event, value, FIELD_NAMES.department_ids)}
          id='select-departments'
          multiple
          isOptionEqualToValue={(option: Department, value: Department): boolean => (option.id === value.id)}
          keyLabel={'name'}
          label='Department(s)'
          loading={departmentsIsFetching}
          name='select-departments'
          options={departmentsData ?? []}
          sx={departments && departments.length > 0 ? valuePresentStyles : {}}
          type='autocomplete'
          value={departments ?? []}
          renderTags={(tagValues, getTagProps): JSX.Element[] => {
            return tagValues.map((value: Department, index) => departmentChip(value, index, getTagProps));
          }}
        />
        <Dropdown
          handleSelect={(event, value): void => handleChangeFilterSalesRep(event, value, FIELD_NAMES.author_id)}
          id={'select-sales-rep'}
          isOptionEqualToValue={(option: User, value: User): boolean => (option.id === value.id)}
          keyLabel={'name'}
          label={'Sales Rep'}
          loading={usersIsFetching}
          name={'select-sales-rep'}
          options={users ?? []}
          sx={salesRep ? valuePresentStyles : {}}
          type='autocomplete'
          value={salesRep}
        />
      </form>
    </div>
  );
});

export default memo(BlueprintsPackageReviewFilter);
