import React, { memo, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Autocomplete } from '@sprnova/nebula';
import { Client } from './types';
import VirtualizedListBox from './VirtualizedListBox';
import css from './VirtualizedAutocomplete.module.scss';

interface Option {
  label: string;
  value: number | Client;
}

interface VirtualizedAutocompleteProps<T> {
  id: string;
  name: string;
  label: string;
  control: Control<any>;
  loading: boolean;
  setSelected?: React.Dispatch<React.SetStateAction<any>>;
  helperText?: string;
  noOptionsText?: string;
  data: T[];
  getOptionLabel: (option: T) => string;
  getOptionValue: (option: T) => number | Client;
  disabled?: boolean;
}

/**
 * VirtualizedAutocomplete is a reusable React component that integrates with react-hook-form
 * and react-window to provide a high-performance, virtualized autocomplete input.
 *
 * This component is designed to handle large datasets efficiently by virtualizing the list
 * of options using react-window, which only renders the visible items in the dropdown list.
 *
 * @template T - The type of the items in the autocomplete options.
 *
 * @param {VirtualizedAutocompleteProps<T>} props - The props for the VirtualizedAutocomplete component.
 * @returns {JSX.Element} The rendered VirtualizedAutocomplete component.
 */
const VirtualizedAutocomplete = <T,>({
  id,
  name,
  label,
  control,
  loading,
  setSelected,
  helperText = 'Please select an option',
  noOptionsText = 'No Options Available',
  data,
  getOptionLabel,
  getOptionValue,
  disabled,
}: VirtualizedAutocompleteProps<T>): JSX.Element => {
  // Convert data to options array
  const options: Option[]  = useMemo(
    () => data?.map((item: any) => ({ label: getOptionLabel(item), value: getOptionValue(item) })),
    [data, getOptionLabel, getOptionValue]
  );

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, ...field }, fieldState: { error } }) => {
          const selectedValue = options?.find((option) => option.value === value) || null;
          const handleChange = (event: any, value: { label: string; value: number } | null): void => {
            onChange(value?.value);
            if (setSelected) {
              const selectedOption = data?.find((item) => getOptionValue(item) === value?.value);
              setSelected(selectedOption);
            }
          };

          return (
            <Autocomplete
              {...field}
              id={id}
              label={label}
              options={options || []}
              onChange={handleChange}
              loading={loading}
              value={selectedValue}
              helperText={error ? error.message : helperText}
              error={!!error}
              noOptionsText={noOptionsText}
              isOptionEqualToValue={(option, value) => option.value === value}
              getOptionLabel={(option) => option.label}
              skeleton={loading}
              ListboxComponent={VirtualizedListBox} // Virtualize list for large data sets
              ListboxProps={{
                className: css.hideScrollbar,
              }}
              disabled={disabled}
            />
          );
        }}
      />
    </>
  );
};

export default memo(VirtualizedAutocomplete);
