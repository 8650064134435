/**
 * Components -> SurveyCriteriaPopover
 */

import React, { FC, useMemo, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { AnalystQuestion } from 'api/crudGraphQL/analyst_surveys/types';
import { Popover } from 'components/Popover';
import { TalentSurveyBucket } from 'features/talent/types';
import css from './SurveyCriteriaPopover.module.scss';

type Props = {
  bucket: (TalentSurveyBucket | AnalystQuestion) & {
    description?: string;
  };
  trigger?: 'click' | 'hover';
  placement?: TooltipPlacement;
  children?: React.ReactNode;
}

const SurveyCriteriaPopover: FC<Props> = ({ 
  bucket,
  children,
  placement = 'rightTop',
  trigger = 'click',
}) => {
  const { criteria, name, description } = bucket || { criteria: [] };
  const [showCriteria, setShowCriteria] = useState(false);

  const renderCriteria = useMemo(() => {
    const criteriaList = [...criteria]
      .sort((a, b) => a.score - b.score)
      .map((c) => (
        <div key={`criteria-${c.id}`} className={css.critieraItem}>
          <span style={{ fontWeight: 'bold' }}>{c.score}</span>
          <span> = </span>
          <span>{c.name}</span>
        </div>
      ));
    return (
      <div className={css.popover}>
        {description ? <div className={css.description}>{description}</div> : <></>}
        {criteriaList ? <div className={css.criteriaList}>{criteriaList}</div> : <></>}
      </div>
    );
  }, [criteria]);

  return (
    <Popover
      title={`Scoring criteria: ${name}`}
      content={renderCriteria}
      trigger={trigger}
      placement={placement}
      arrowPointAtCenter
    >
      {children || (
        <InfoCircleOutlined
          className={css.icon}
          onClick={() => setShowCriteria(!showCriteria)}
        />
      )}
    </Popover>
  );
};

export default SurveyCriteriaPopover;