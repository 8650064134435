import { BlockList as Root } from './BlockList';
import { Column, Header, Row, Skeleton, ActionsColumn } from './components';

export const BlockList = Object.assign(Root, {
  Column,
  ActionsColumn,
  Header,
  Row,
  Skeleton,
});
