import React, { FC, lazy } from 'react';
import { useParams } from 'react-router';
import { useGetClientQuery } from 'api/crudGraphQL/clients/getClient';
import { LABELS_TYPES } from 'utils/types/types';

export const AccountDetailsPage = lazy(() => import('./AccountDetailsPage'));

export const AccountDetailsPageTitle: FC = () => {
  const { clientId: idRaw = '' } = useParams<{ [x: string]: string | undefined }>();
  const clientId = Number.parseInt(idRaw);

  const { data: client, isLoading } = useGetClientQuery({
    id: clientId || 0,
    projection: { id: true, name: true }
  }, { refetchOnMountOrArgChange: true });

  return !isLoading ? <>{client?.name}</> : <>{LABELS_TYPES.CAPITALIZE_CLIENT} Details</>;
};