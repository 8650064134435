/**
 * Audits -> DiscoveryForm -> SalesforceOpportunityStage -> UpdateSalesforceStageModal
 */

import React, { useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useGetOpportunityStagesQuery } from 'api/crudGraphQL/salesforce_opportunities/getOpportunityStages';
import debounce from 'lodash/debounce';
import moment from 'moment-timezone';
import { Audit, UpdateSalesforceStageFormValues } from 'features/entitiesRedux/models/audit';
import { Button } from 'components';
import { StageSelection, UpdateSalesforceStageForm } from './components';
import css from './UpdateSalesforceStageModal.module.scss';

enum STEPS {
  STAGE_SELECTION = 'stage_selection',
  FORM_INPUTS = 'form-inputs',
}

type Props = {
  audit: Pick<Audit, 'id' | 'salesforce_opportunity'>;
  onSubmit: (values: UpdateSalesforceStageFormValues) => void;
  onToggle: () => void;
  open: boolean;
  updating: boolean;
};

const UpdateSalesforceStageModal = ({
  audit,
  onSubmit,
  onToggle,
  open,
  updating,
}: Props) => {
  const initialOpportunityStage = audit?.salesforce_opportunity?.stage?.slug;
  const [selectedStage, setSelectedStage] = useState<string | undefined>(initialOpportunityStage);
  const [currentStep, setCurrentStep] = useState(STEPS.STAGE_SELECTION);

  /**
   * Fetch Salesforce Opportunity Stages for the Appraisal module
   */
  const { data: allOpportunityStages = [] } = useGetOpportunityStagesQuery({
    module: 'appraisal',
    projection: {
      id: true,
      name: true,
      slug: true,
    },
  });

  const initialValues: Partial<UpdateSalesforceStageFormValues> = {
    salesforce_stage_id: allOpportunityStages.find(opt => opt.slug === selectedStage)?.id,
    salesforce_meeting_date: audit?.salesforce_opportunity?.meeting_date
      ? moment(audit?.salesforce_opportunity?.meeting_date)
      : undefined,
    salesforce_reason: audit?.salesforce_opportunity?.reason,
    salesforce_explanation: audit?.salesforce_opportunity?.explanation,
    salesforce_close_date: audit?.salesforce_opportunity?.close_date
      ? moment(audit?.salesforce_opportunity?.close_date)
      : moment()
  };
  const [formValues, setFormValues] = useState(initialValues);

  const handleValuesChange = debounce((values: UpdateSalesforceStageFormValues) => {
    setFormValues({ ...formValues, ...values });
  }, 400);

  const hasMissingFormValues = () => {
    if (selectedStage === 'meeting-set' || selectedStage === 'appraisal' || selectedStage === 'reschedule') {
      if (!formValues?.salesforce_meeting_date) {
        return true;
      }
    }

    if (selectedStage === 'disqualified' || selectedStage === 'closed-lost' || selectedStage === 'nurture') {
      if (!formValues?.salesforce_reason || !formValues?.salesforce_explanation || !formValues?.salesforce_close_date) {
        return true;
      }
    }

    return false;
  };

  const disableSubmit = updating || hasMissingFormValues();

  const handleSelectStage = (option: string) => {
    const stageId = allOpportunityStages.find(opt => opt.slug === option)?.id;
    setSelectedStage(option);
    setFormValues({
      ...formValues,
      salesforce_stage_id: stageId ? stageId : undefined
    });
  };

  const handleContinue = () => {
    setCurrentStep(STEPS.FORM_INPUTS);
    setFormValues(initialValues);
  };

  const handleGoBack = () => {
    setCurrentStep(STEPS.STAGE_SELECTION);
    setFormValues(initialValues);
  };

  const handleCancel = () => {
    onToggle();
    setCurrentStep(STEPS.STAGE_SELECTION);
    setSelectedStage(initialOpportunityStage);
    setFormValues(initialValues);
  };

  const handleFinish = () => {
    if (typeof onSubmit === 'function') {
      onSubmit(formValues);
    }
  };

  const renderContent = () => {
    // Select a Salesforce opportunity stage
    if (currentStep === STEPS.STAGE_SELECTION || selectedStage === 'qualified-access') {
      return (
        <StageSelection
          onSelectStage={handleSelectStage}
          selectedStage={selectedStage}
          stageOptions={allOpportunityStages}
        />
      );
    }

    // Complete form inputs for the selected opportunity stage
    if (currentStep === STEPS.FORM_INPUTS) {
      return (
        <UpdateSalesforceStageForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          onValuesChange={handleValuesChange}
          selectedStage={selectedStage}
        />
      );
    }

    return null;
  };

  const renderCancelButton = (
    <Button
      type="text"
      htmlType="button"
      onClick={handleCancel}
      disabled={updating}
    >
      Cancel
    </Button>
  );

  const renderNextButton = (
    <Button
      size="large"
      type="primary"
      disabled={!selectedStage}
      htmlType="button"
      onClick={handleContinue}
    >
      Next
      <RightOutlined />
    </Button>
  );

  const renderSubmitButton = (
    <Button
      size="large"
      type="primary"
      htmlType="button"
      onClick={handleFinish}
      disabled={disableSubmit}
    >
      {updating ? 'Updating...' : 'Update'}
    </Button>
  );

  const footer =
    currentStep === STEPS.STAGE_SELECTION
      ? [
        <div className={css.footerButtons} key="buttons">
          {renderCancelButton}
          {selectedStage === 'qualified-access' ? renderSubmitButton : renderNextButton}
        </div>,
      ]
      : [
        <Button
          key="backButton"
          icon={<LeftOutlined />}
          type="text"
          htmlType="button"
          onClick={handleGoBack}
          className={css.backButton}
          disabled={updating}
        >
          Back
        </Button>,
        <div className={css.footerButtons} key="buttons">
          {renderCancelButton}
          {renderSubmitButton}
        </div>,
      ];

  return (
    <Modal
      className={css.root}
      visible={open}
      onCancel={handleCancel}
      destroyOnClose
      maskClosable={false}
      title="Salesforce Opportunity Stage"
      width={1000}
      footer={footer}
    >
      <div className={css.modalContent}>
        {renderContent()}
      </div>
    </Modal>
  );
};

export default UpdateSalesforceStageModal;
