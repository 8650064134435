import { Client, defaultClientProjection, UpdateClientIntegrationApiParameters, ClientIntegration, defaultClientIntegrationProjection, CreateClientIntegrationApiParameters, CreateClientIntegrationReminderApiParameters, CreateClientIntegrationRequestApiParameters, UpdateClientIntegrationRequestApiParameters } from 'features/entitiesRedux';
import { returnStatusProjection } from '../global_projections';
import { novaGraphQLClient } from '../NovaGraphQLClient';
import { PaginatedResponse, Pagination, Projection, ReturnStatusApi } from '../types';
import { filterUndefinedNullEmpty, getClient, toGraphQL } from '../utils';

// TODO: Remove hardcoded API client once BE is ready to expose those endpoints

/** Public & Private */
export const fetchClientsQuery = async ({
  args: {
    id,
    client_ids,
    name,
    author_id,
    account_manager_id,
    executive_sponsor_id,
    business_type_id,
    business_type_ids,
    timezone_id,
    industry_ids,
    salesforce_client_id,
    salesforce_client_name,
    tier_id,
    status
  } = {},
  pagination: { page, limit } = { page: 1, limit: 10, sort: ['id'] },
  projection,
}: {
  args?: {
    id?: number;
    client_ids?: number[];
    name?: string;
    author_id?: number;
    account_manager_id?: number;
    executive_sponsor_id?: number;
    business_type_id?: number;
    business_type_ids?: number[];
    timezone_id?: number;
    industry_ids?: number[];
    salesforce_client_id?: number;
    salesforce_client_name?: string;
    tier_id?: number;
    status?: string;
  };
  pagination?: Pagination;
  projection?: Projection;
} = {}): Promise<PaginatedResponse & { clients: Client[] }> => {
  // GraphQL client.
  const graphQlClient = getClient();

  const __args = {
    page,
    limit,
    id,
    client_ids,
    name,
    author_id,
    account_manager_id,
    executive_sponsor_id,
    business_type_id,
    business_type_ids,
    timezone_id,
    industry_ids,
    salesforce_client_id,
    salesforce_client_name,
    tier_id,
    status
  };

  const query = toGraphQL({
    query: {
      clients: {
        __args: filterUndefinedNullEmpty(__args),
        data: { ...(projection || defaultClientProjection) },
        ...graphQlClient.paginationProjection,
      },
    },
  });

  const { clients: { data: clients, ...meta }, } = await graphQlClient.sendRequest(query);

  return {
    clients,
    ...meta,
  };
};

/** Public */
export const fetchClientByIdQuery = async (
  id: number,
  { projection }: { projection?: Projection } = {}
): Promise<{ clients: Client[] }> => {
  const { clients } = await fetchClientsQuery({
    args: { id },
    projection,
    pagination: { page: 1, limit: 1 },
  });
  return { clients };
};

export const updateClientMutation = async (
  {
    id,
    name,
    legal_name,
    website,
    status,
    account_manager_id,
    executive_sponsor_id,
    organization_id,
    business_type_id,
    industry_id,
    lead_source_id,
    salesforce_client_id,
    netsuite_id,
    tier_id,
  }: {
    id: number;
    name?: string;
    legal_name?: string;
    website?: string;
    account_manager_id?: number;
    executive_sponsor_id?: number;
    organization_id?: number;
    status?: string;
    business_type_id?: number;
    industry_id?: number;
    lead_source_id?: number;
    salesforce_client_id?: string | null;
    netsuite_id?: number;
    tier_id?: number;
  },
  { projection }: { projection?: Projection } = {}
): Promise<Client>  => {
  // GraphQL client.
  const graphQlClient = getClient();

  const __args = {
    id,
    name,
    legal_name,
    website,
    status,
    account_manager_id,
    executive_sponsor_id,
    organization_id,
    business_type_id,
    industry_id,
    lead_source_id,
    salesforce_client_id,
    netsuite_id,
    tier_id,
  };

  const query = toGraphQL({
    mutation: {
      updateClient: {
        __args: salesforce_client_id === null ?
          filterUndefinedNullEmpty({...__args, salesforce_client_id}, ['salesforce_client_id']) :
          filterUndefinedNullEmpty(__args),
        ...(projection || defaultClientProjection)
      }
    }
  });
  const { updateClient } = await graphQlClient.sendRequest(query);

  return updateClient;
};

export const createClientMutation = async (
  {
    name,
    legal_name,
    website,
    logo,
    account_manager_id,
    executive_sponsor_id,
    organization_id,
    status,
    business_type_id,
    industry_id,
    lead_source_id,
    salesforce_client_id,
    netsuite_id,
    tier_id,
  }: {
    name: string;
    legal_name?: string;
    website?: string;
    logo?: string;
    account_manager_id?: number;
    executive_sponsor_id?: number;
    organization_id?: number;
    status?: 'prospect' | 'audit' | 'strategy' | 'reporting' | 'archive'
    business_type_id?: number;
    industry_id?: number;
    lead_source_id?: number;
    salesforce_client_id?: string | null;
    netsuite_id?: number;
    tier_id?: number;
  },
  { projection }: { projection?: Projection } = {}
): Promise<{ clients: Client[] }> => {
  // GraphQL client.
  const graphQlClient = getClient();

  const __args = {
    name,
    legal_name,
    website,
    logo,
    account_manager_id,
    executive_sponsor_id,
    organization_id,
    status,
    business_type_id,
    industry_id,
    lead_source_id,
    salesforce_client_id,
    netsuite_id,
    tier_id,
  };

  const query = toGraphQL({
    mutation: {
      createClient: {
        __args: filterUndefinedNullEmpty(__args),
        ...(projection || defaultClientProjection)
      }
    }
  });

  const { createClient } = await graphQlClient.sendRequest(query);

  return { clients: [createClient] };
};

export const deleteClientMutation = async (id: number): Promise<{ clients: Client[] }> => {
  // GraphQL client.
  const graphQlClient = getClient();

  const query = `
    mutation {
      deleteClient(
        id: ${id}
      ){
        id
      }
    }
  `;

  const { deleteClient } = await graphQlClient.sendRequest(query);

  return { clients: [deleteClient] };
};

export const updateClientAccountMutation = async (
  {
    id,
    url,
    data_source_account_id,
    value2,
    grant_pdm_access,
    page_access_token,
    meta_page_id
  }: UpdateClientIntegrationApiParameters): Promise<ClientIntegration>  => {
  const __args = {
    id,
    url,
    value2,
    data_source_account_id,
    grant_pdm_access,
    page_access_token,
    meta_page_id
  };

  // GraphQL client.
  const graphQlClient = getClient();

  const query = toGraphQL({
    mutation: {
      updateClientAccount: {
        __args: filterUndefinedNullEmpty(__args),
        ...defaultClientIntegrationProjection['accounts'],
      },
    },
  });

  const { updateClientAccount } = await novaGraphQLClient.sendRequest(query);
  return updateClientAccount;
};

export const createClientAccountMutation = async (
  {
    client_id,
    account_id,
    url,
    value2,
    auth_method,
    data_source_account_id,
    data_q_data,
    is_created_by_client,
    created_by
  }: CreateClientIntegrationApiParameters): Promise<ClientIntegration> => {
  const __args = {
    client_id,
    account_id,
    data_source_account_id,
    url,
    value2,
    auth_method,
    data_q_data,
    is_created_by_client,
    created_by,
  };

  const query = toGraphQL({
    mutation: {
      createClientAccount: {
        __args: filterUndefinedNullEmpty(__args),
        ...defaultClientIntegrationProjection['accounts'],
      },
    },
  });

  const { createClientAccount } = await novaGraphQLClient.sendRequest(query);
  return createClientAccount;
};

export const deleteClientAccountMutation = async ({ id }: { id: number }): Promise<ReturnStatusApi> => {
  const query = toGraphQL({
    mutation: {
      deleteClientAccount: {
        __args: filterUndefinedNullEmpty({ id }),
        ...returnStatusProjection
      },
    },
  });

  const { deleteClientAccount } = await novaGraphQLClient.sendRequest(query);
  return deleteClientAccount;
};

export const createClientIntegrationReminderMutation = async ({
  client_id
}: CreateClientIntegrationReminderApiParameters ): Promise<ReturnStatusApi> => {
  // GraphQL client.
  const graphQlClient = getClient();

  const query = toGraphQL({
    mutation: {
      createClientIntegrationReminder: {
        __args: filterUndefinedNullEmpty({
          client_id,
        }),
        ...returnStatusProjection,
      },
    },
  });

  const { createClientIntegrationReminder } = await graphQlClient.sendRequest(query);
  return createClientIntegrationReminder;
};

export const createClientIntegrationRequestMutation = async({
  client_id,
  data_source_id,
  requested_user_id
}: CreateClientIntegrationRequestApiParameters ): Promise<Partial<Client>> => {
  // GraphQL client.
  const graphQlClient = getClient();

  const query = toGraphQL({
    mutation: {
      createClientIntegrationRequest: {
        __args: filterUndefinedNullEmpty({
          client_id,
          data_source_id,
          requested_user_id
        }),
        ...defaultClientIntegrationProjection,
      },
    },
  });

  const { createClientIntegrationRequest } = await graphQlClient.sendRequest(query);
  return createClientIntegrationRequest;
};

export const updateClientIntegrationRequestMutation = async({
  id,
  status,
  completed_by
}: UpdateClientIntegrationRequestApiParameters ): Promise<Partial<Client>> => {
  const query = toGraphQL({
    mutation: {
      updateClientIntegrationRequest: {
        __args: filterUndefinedNullEmpty({
          id,
          status,
          completed_by
        }),
        ...defaultClientIntegrationProjection,
      },
    },
  });

  const { updateClientIntegrationRequest } = await novaGraphQLClient.sendRequest(query);
  return updateClientIntegrationRequest;
};
