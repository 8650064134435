/**
 * Entities -> Services -> Reducers
 */

import { EntitiesState } from '../../types';
import { servicesAdapter } from './adapter';

export default {
  'deleteService/fulfilled'(state: EntitiesState, action: { payload:  number }) {
    state.isLoading = false;
    servicesAdapter.removeOne(state.data.services, action.payload);
  }
};
