import React, { FC, useMemo, memo } from 'react';
import { Alert, Box, GridColDef } from '@sprnova/nebula';
import { useGetScoreboardsPublicQuery } from 'api/crudGraphQL/public/scoreboards/getScoreboardsPublic';
import { useGetScoreboardsQuery } from 'api/crudGraphQL/scoreboards/getScoreboards';
import { Projection } from 'api/entityGraphQL';
import ClientStageTag from 'features/clients/components/ClientStageTag/ClientStageTag';
import StageInfoTooltip from 'features/clients/components/Tooltip/StageInfoTooltip/StageInfoTooltip';
import { useFilterContext } from 'features/scoreboards/utils/Filters';
import AccountCell from './AccountCell';
import DropdownActionMenu from './DropdownActionMenu';
import FiltersForm from './FiltersForm';
import { myAccountsProjection } from './projections';
import ScoreboardsList from './ScoreboardsList';


type MyAccountsProps = {
  query?: typeof useGetScoreboardsQuery | typeof useGetScoreboardsPublicQuery;
  projection?: Projection;
  isExternal?: boolean;
  clientId?: number;
};
const MyAccounts: FC<MyAccountsProps> = ({
  query = useGetScoreboardsQuery,
  projection = myAccountsProjection,
  isExternal = false,
  clientId,
}) => {
  const { filter } = useFilterContext();
  const { data: scoreboardsData, isLoading: scoreboardsLoading, isFetching: scoreboardsFetching, error } = query({
    projection,
    ...{
      my_scoreboards: isExternal ? undefined : 1,
      client_id: isExternal ? clientId : undefined,
      ...filter
    }
  });

  const columns: GridColDef[] = useMemo( () => {
    if (isExternal) {
      return [
        {
          field: 'client_name',
          headerName: 'Scoreboard Name',
          renderCell: (params) => <AccountCell data={params.formattedValue} isExternal />,
          flex: 3,
          sortable: false,
        },
      ];
    }
    return [
      {
        field: 'client_name',
        headerName: 'Account',
        renderCell: (params) => <AccountCell data={params.formattedValue} />,
        flex: 3,
      },
      {
        field: 'account_stage',
        headerName: 'Stage',
        renderHeader: () => <StageInfoTooltip />,
        renderCell: (params) => <ClientStageTag stage={params.row.account_stage} tooltip />,
        flex: 1,
      },
      {
        field: 'business_type',
        headerName: 'Business Unit',
        flex: 1,
      },
      {
        field: 'my_role',
        headerName: 'My Role',
        flex: 1,
        sortable: false,
      },
      {
        field: 'extra',
        headerName: '',
        renderCell: (params) => <DropdownActionMenu
          scoreboardId={params.row.id}
          scoreboardName={params.row.client_name.name}
          clientId={params.row.client_name.client.id}
          clientName={params.row.client_name.client.name}
        />,
        width: 65,
        sortable: false,
      }
    ];
  }, [isExternal]);

  const rows = useMemo(() => {
    if (scoreboardsData) {
      return scoreboardsData.data.map((scoreboard) => {
        return {
          id: scoreboard.id,
          client_name: scoreboard,
          account_stage: scoreboard.client?.account_stage,
          business_type: scoreboard.client?.business_type?.name,
          my_role: scoreboard.my_role,
          extra: scoreboard.id,
        };
      });
    }
    return [];
  }, [scoreboardsData]);

  return (
    <Box>
      {
        error ? (
          /**
           * Only show actual error message for internal users
           */
          <Alert severity="error">Error loading scoreboards{(error.message && !isExternal) && `: ${error.message}`}</Alert>
        ) : (
          <>
            {!isExternal &&
              <FiltersForm onMyAccounts />
            }
            <ScoreboardsList
              rows={rows}
              columns={columns}
              isLoading={scoreboardsLoading || scoreboardsFetching}
              total={scoreboardsData?.total ?? 0}
              isMyAccounts
              isExternal={isExternal}
              clientId={clientId}
            />
          </>
        )
      }
    </Box>
  );
};

export default memo(MyAccounts);
