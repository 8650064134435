import React, { forwardRef, memo } from 'react';
import { Button as AntdButton } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button';
import classNames from 'classnames';
import { useTheme } from '../../utils/hooks/useThemeContext';
import css from './Button.module.scss';

export interface ButtonProps extends AntdButtonProps {
  className?: string;
  inline?: boolean;
  header?: boolean;
  iconOnly?: boolean;
  menuItem?: boolean;
}

export const Button = forwardRef(
  (
    {
      className,
      header = false,
      inline = false,
      ghost = false,
      iconOnly = false,
      menuItem = false,
      ...props
    }: ButtonProps,
    ref: any
  ) => {
    const { isTalent } = useTheme();
    const createClassName = (): string => {
      const { children } = props ?? '';
      if (typeof children === 'string') return children?.replace(/\s+/g, '-').toLowerCase();
      return '';
    };
    return (
      <AntdButton
        ghost={ghost}
        {...props}
        className={classNames(
          className,
          css.root,
          { [css.header]: header },
          { [css.inline]: inline },
          { [css.iconOnly]: iconOnly },
          { [css.menuItem]: menuItem },
          { [css.talent]: isTalent },
          createClassName()
        )}
        ref={ref}
      />
    );
  }
);

Button.displayName = 'Button';