import React, { FC, ReactNode, useMemo } from 'react';
import { Checkbox, InfoPopover } from 'components';
import css from './ChannelOverview.module.scss';

export type ChannelOverviewChannel = { 
  name: string; 
  id: number; 
};

export type ChannelOverviewProps = {
  label?: ReactNode;
  currentChannels?: ChannelOverviewChannel[];
  availableChannels?: ChannelOverviewChannel[];
  selected?: number[];
  onChange?: (channelId: number) => void;
  disabled?: boolean;
}

export const ChannelOverview: FC<ChannelOverviewProps> = ({ 
  label = 'Channel Summary', 
  availableChannels = [], 
  currentChannels = [], 
  onChange, 
  selected = [],
  disabled
}) => {
  // Filter out current channels from available channels
  const filteredAvailableChannels = useMemo(() => availableChannels.filter(({ id }) => !currentChannels.map((c) => c.id).includes(id)), []);

  const renderChannel = (channel: ChannelOverviewChannel) => {
    const isChecked = selected.includes(channel.id);

    return (
      <div className={css.channels__item} key={channel.id}>
        {onChange ? (
          <Checkbox
            disabled={disabled}
            onChange={() => onChange((channel.id))} 
            checked={isChecked} 
            className={css.channels__item__checkbox}
          >
            {channel.name}
          </Checkbox>
        ) : channel.name}
      </div>
    );
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <h3 className={css.header__label}>
          {label}
        </h3>
      </div>
      <div className={css.container}>
        <div className={css.channels}>
          <div className={css.channels__label}>
            Current Channels
          </div>
          <div className={css.channels__items}>
            {currentChannels.map(renderChannel)}
          </div>
        </div>
        <div className={css.channels}>
          <div className={css.channels__label}>
            Available channels
            <InfoPopover
              margin="left"
              size="medium"
              title="What are available channels?"
            >
              Available channels are channels we have the capability of forecasting that are not currently being run for a client. 
            </InfoPopover>
          </div>
          <div className={css.channels__items}>
            {filteredAvailableChannels.length ? 
              filteredAvailableChannels.map(renderChannel) 
              : <div className={css.emptyText}>No additional channels available.</div>}
          </div>
        </div>
      </div>
    </div>
  );
};