import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import loadable from '@loadable/component';
import { selectRuleById } from 'features/entitiesRedux/models/rule';

const EditRulePage = loadable(() => import('./EditRulePage'));

const Title = () => {
  const { id } = useParams<{ [x: string]: any }>();
  const rule = useSelector(selectRuleById(Number.parseInt(id)));

  if (!rule) {
    return <span>Edit</span>;
  }

  return <>{rule.name}</>;
};

Title.displayName = 'EditRulePageTitle';

export default Object.assign(EditRulePage, { Title });
