import { getClient } from 'api/entityGraphQL/utils';
import { QuestionHandler } from '../actions';

export const businessTypeHandler: QuestionHandler = (id, key, value, additionalInfo) => {
  const apiClient = getClient();
  return apiClient.updateDiscovery({
    id: additionalInfo?.discoveryId,
    business_type_id: additionalInfo?.businessTypeId,
  });
};
