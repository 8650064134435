import { createAsyncThunk } from '@reduxjs/toolkit';
import { FetchSubordinatesFilter, FetchSurveysFilter, novaGraphQLClient, Projection } from '../../../api/entityGraphQL';
import { ActionFetchManyPaginatedConfig, ActionFetchPaginatedResponse, normalizeEntities } from '../../entitiesRedux';
import { TalentSurvey, UpdateTalentSurveyArgs, UpdateTalentSurveyBucketArgs } from '../types';
import { isWhitespace } from '../utils';

export const fetchSubordinatesPaginated = createAsyncThunk(
  'entities/fetchSubordinatesPaginated',
  async ({
    filter,
    projection,
    pagination,
  }: ActionFetchManyPaginatedConfig & {
    filter?: FetchSubordinatesFilter;
  }): Promise<ActionFetchPaginatedResponse> => {
    const {
      users,
      ...paginationResponse
    } = await novaGraphQLClient.fetchSubordinates({
      ...filter,
      // if filter.name has a value and is not only whitespace, then don't include self
      include_self: filter?.name && !isWhitespace(filter?.name) ? 'no' : 'yes',
      name: filter?.name
        ? `*${String(filter?.name).replace(/\s+/g, '*')}*`
        : undefined,
      projection,
      pagination,
    });
    return {
      ...normalizeEntities({ users }),
      pagination: paginationResponse,
    };
  }
);

export const fetchSurveysPaginated = createAsyncThunk(
  'entities/fetchSurveysPaginated',
  async ({
    filter,
    projection,
    pagination,
  }: ActionFetchManyPaginatedConfig & {
    filter?: FetchSurveysFilter;
  }): Promise<ActionFetchPaginatedResponse> => {
    const {
      surveys,
      ...paginationResponse
    } = await novaGraphQLClient.fetchSurveys({
      ...filter,
      // if filter.name has a value and is not only whitespace, then don't include self
      include_self: filter?.name && !isWhitespace(filter?.name) ? 'no' : 'yes',
      name: filter?.name
        ? `*${String(filter?.name).replace(/\s+/g, '*')}*`
        : undefined,
      user_id: filter?.user_id,
      sort: filter?.sort,
      projection,
      pagination,
    });
    return {
      ...normalizeEntities({ surveys }),
      pagination: paginationResponse,
    };
  }
);

export const updateTalentSurveyBucket = createAsyncThunk(
  'entities/updateTalentSurveyBucket',
  async ({
    projection,
    ...args
  }: {
    projection?: Projection;
  } & UpdateTalentSurveyBucketArgs) => {
    const response = await novaGraphQLClient.updateTalentSurveyBucket(args, {
      projection,
    });

    return { ...normalizeEntities(response) };
  }
);

export const fetchTalentSurveyById = createAsyncThunk(
  'entities/fetchTalentSurveyById',
  async ({
    id,
    projection,
  }: {
    projection?: Projection;
  } & { id: TalentSurvey['id']}) => {
    const response = await novaGraphQLClient.fetchTalentSurveyById(id, {
      projection,
    });

    return { ...normalizeEntities(response) };
  }
);

export const updateTalentSurvey = createAsyncThunk(
  'entities/updateSkillsetSurvey',
  async ({
    projection,
    ...args
  }: {
    projection?: Projection;
  } & UpdateTalentSurveyArgs) => {
    const response = await novaGraphQLClient.updateTalentSurvey(args, {
      projection,
    });

    return { ...normalizeEntities(response) };
  }
);
