import React from 'react';

export const PulseIcon = (): JSX.Element => (
  <svg width="108" height="112" viewBox="0 0 108 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="16.6533" y="111.961" width="16" height="50" rx="8" transform="rotate(-179.935 16.6533 111.961)" fill="#D9D9D9"/>
    <rect x="50.6719" y="96" width="16" height="50" rx="8" transform="rotate(90.0653 50.6719 96)" fill="#D9D9D9"/>
    <rect x="91.2324" y="0.708984" width="16" height="50.7323" rx="8" transform="rotate(-0.0634691 91.2324 0.708984)" fill="#D9D9D9"/>
    <rect x="57.3467" y="16.7471" width="16" height="50" rx="8" transform="rotate(-90.0635 57.3467 16.7471)" fill="#D9D9D9"/>
    <rect x="29.2266" y="81.2266" width="50" height="50" rx="10" transform="rotate(-90.2601 29.2266 81.2266)" fill="#FFA600"/>
    <circle cx="54" cy="56" r="8" transform="rotate(-90 54 56)" fill="#4A1FFF"/>
    <circle cx="8.66244" cy="103.952" r="8" transform="rotate(-179.935 8.66244 103.952)" fill="#A94882"/>
    <circle cx="99.2619" cy="8.72054" r="8" transform="rotate(-179.985 99.2619 8.72054)" fill="#E65957"/>
  </svg>
);
