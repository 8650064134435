import { DataSourceProgress } from 'features/entitiesRedux';
import { notification } from 'components';

/**
 * If the integration being created is Shopify, we may need to redirect the user to
 * complete authentication.
 */
export const handleCreateIntegrationDataQ = (
  slug: 'bigcommerce' | 'shopify',
  resultAction: any,
): void => {
  const progressResponse: string | undefined = resultAction?.payload?.progress;

  if (progressResponse) {
    const progressData: DataSourceProgress | undefined = ( progressResponse !== 'null' ? JSON.parse(progressResponse) : undefined );

    if (progressData && progressData?.status === 'auth') {
      const redirectUrl: string | undefined = resultAction?.payload?.reauthenticate_url;

      if (redirectUrl?.length && redirectUrl?.length > 1) {
        if (slug === 'shopify')
          window.open(redirectUrl, '_blank');
        if (slug === 'bigcommerce')
          navigator.clipboard.writeText(redirectUrl).then(() =>
            notification.success({
              message: 'BigCommerce authentication URL copied to clipboard',
            })
          );
      }
    }
  }
};
