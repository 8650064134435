/**
 * Update pacing goal
 */

import { Projection } from 'api/entityGraphQL';
import { baseApi, cleanArguments } from '../..';
import clientTagTypes from '../../clients/tagTypes';
import tagTypes from '../tagTypes';
import { Scoreboard } from '../types';

export type UpdatePacingGoalMutation = {
  id: number;
  type?: 'monthly' | 'long_term';
  start?: string;
  term?: number;
  metric?: string;
  value?: number;
  months?: {
    date: Date;
    value: number;
  }[];
  projection?: Projection;
}

export type UpdatePacingGoalResult = Scoreboard;

export const UPDATE_PACING_GOAL = 'updatePacingGoal';

export const { useUpdatePacingGoalMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [UPDATE_PACING_GOAL]: build.mutation<UpdatePacingGoalResult, UpdatePacingGoalMutation>({
      transformResponse: (response: { updatePacingGoal: Scoreboard }) => response.updatePacingGoal,
      invalidatesTags: result => [
        result?.id ? { id: result.id, type: tagTypes.SCOREBOARD } : tagTypes.SCOREBOARD,
        tagTypes.SCOREBOARDS,
        tagTypes.SCOREBOARD,
        clientTagTypes.CLIENTS,
        clientTagTypes.CLIENT,
      ],
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<UpdatePacingGoalMutation> = {
          ...values
        };
        return {
          body: {
            mutation: {
              updatePacingGoal: {
                __args: cleanArguments(__args),
                ...projection,
                id: true
              }
            }
          }
        };
      }
    })
  })
});
