/**
 * Update scoreboard (report)
 */

import { Projection } from 'api/entityGraphQL';
import tagTypes from './tagTypes';
import { Scoreboard } from './types';
import { baseApi, cleanArguments } from '..';
import clientTagTypes from '../clients/tagTypes';

export type UpdateScoreboardMutation = {
  id: number;
  name?: string;
  business_type_id?: number;
  website?: string;
  logo?: string;
  goal_name_filter?: string[];
  range?: string;
  refreshed_at?: string | null;
  num_days_refresh_period?: string;
  excluded_data_source_ids?: number[];
  projection?: Projection;
}

export type UpdateScoreboardResult = Scoreboard;

export const UPDATE_SCOREBOARD = 'updateScoreboard';

export const { useUpdateScoreboardMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [UPDATE_SCOREBOARD]: build.mutation<UpdateScoreboardResult, UpdateScoreboardMutation>({
      transformResponse: (response: { updateReport: Scoreboard }) => response.updateReport,
      invalidatesTags: result => [
        result?.id ? { id: result.id, type: tagTypes.SCOREBOARD } : tagTypes.SCOREBOARD,
        tagTypes.SCOREBOARDS,
        tagTypes.SCOREBOARD,
        clientTagTypes.CLIENTS,
        clientTagTypes.CLIENT,
      ],
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<UpdateScoreboardMutation> = {
          ...values
        };
        return {
          body: {
            mutation: {
              updateReport: {
                __args: cleanArguments(__args),
                ...projection,
                id: true
              }
            }
          }
        };
      }
    })
  })
});
