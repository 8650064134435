import Cookies from 'js-cookie';

const urlParams = new URLSearchParams(window.location.search);
const signatureQueryParam = urlParams.get('signature');
const entityIdQueryParam = urlParams.get('entityId');
const signatureCookie = Cookies.get('signature');
const entityIdCookie = Cookies.get('entityId');

let authorization = '';
if (entityIdQueryParam && signatureQueryParam) {
  authorization = `Bearer ${entityIdQueryParam}-${signatureQueryParam}`;
} else if (entityIdCookie && signatureCookie) {
  authorization = `Bearer ${entityIdCookie}-${signatureCookie}`;
}

const versionConfig: { [apiVersion: string]: Record<string, string> } = {
  v1: {
    Authorization: `Bearer ${import.meta.env.REACT_APP_PLATFORM_API_TOKEN || ''}`,
    'Content-Type': 'application/json', // to send Graphql Request
    'Accept': 'application/json',
  },
  v2: {
    authorization: `Bearer ${Cookies.get('BEARER-TOKEN')}` ?? '',
    'Content-Type': 'application/json', // to send Graphql Request
    'Accept': 'application/json'
  },
  public: {
    Authorization: authorization,
    authorization: `Bearer ${Cookies.get('BEARER-TOKEN')}` ?? '',
    'Accept': 'application/json'
  },
};

export default versionConfig;
