/**
 * BlockList -> Skeleton
 */

import React, { FC } from 'react';
import { Skeleton } from 'antd';
import random from 'lodash/random';
import css from './Skeleton.module.scss';

type Props = {
  rows?: number;
}

const BlockListSkeleton: FC<Props> = ({ rows = 10 }) => (
  <div className={css.root}>
    <div className={css.header}>
      <Skeleton.Input
        active
        className={css.skeleton}
        size="small" 
        style={{ width: 175 }} 
      />
    </div>
    {Array.from(new Array(rows)).map((_, i) => (
      <div key={i} className={css.item}>
        <Skeleton.Input
          active
          className={css.skeleton}
          size="small" 
          style={{ width: `${random(180, 275)}px` }} 
        />
      </div>
    ))}
  </div>
);

export default BlockListSkeleton;
