/**
 * Entities -> Partners -> Actions
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { novaGraphQLClient, novaGraphQLPublicClient, Pagination, Projection } from 'api/entityGraphQL';
import { normalizeEntities } from 'features/entitiesRedux/utils';
import {
  ActionFetchManyPaginatedConfig,
  ActionFetchPaginatedResponse,
} from '../types';
import { FetchPartnersFilter, Partner, CreatePartnerUserArgs } from './partner';

/** Fetch Many Paginated */
type FetchPartnersPaginatedConfig = ActionFetchManyPaginatedConfig & {
  filter?: FetchPartnersFilter;
  projection?: Projection;
  pagination?: Pagination;
};
export const fetchPartnersPaginated = createAsyncThunk(
  'entities/fetchPartnersPaginated',
  async ({
    filter: { search } = {},
    projection,
    pagination,
  }: FetchPartnersPaginatedConfig): Promise<ActionFetchPaginatedResponse> => {
    const { partners, ...rest } = await novaGraphQLClient.fetchPartners({
      args: {
        search: search ? `*${String(search).replace(/\s+/g, '*')}*` : undefined,
      },
      projection,
      pagination,
    });
    return {
      ...normalizeEntities({ partners }),
      pagination: rest,
    };
  }
);

/** Fetch one */
export const fetchPartnerById = createAsyncThunk(
  'entities/fetchPartnerById',
  async ({ id, ...config }: { id: number; projection?: Projection }) => {
    const { partners } = await novaGraphQLClient.fetchPartnerById(id, {
      ...config,
    });
    return { ...normalizeEntities({ partners }) };
  }
);

/** Create */
export const createPartner = createAsyncThunk(
  'entities/createPartner',
  async (values: Partial<Partner>) => {
    const response = await novaGraphQLClient.createPartner(values);
    return { ...normalizeEntities(response) };
  }
);

/* Update */
export const updatePartner = createAsyncThunk(
  'entities/updatePartner',
  async (values: Partial<Partner>) => {
    const response = await novaGraphQLClient.updatePartner(values);
    return { ...normalizeEntities(response) };
  }
);

/** Delete */
export const deletePartner = createAsyncThunk(
  'entities/deletePartner',
  async (id: number) => {
    try {
      await novaGraphQLClient.deletePartner(id);
      return id;
    } catch (error) {
      console.error('Error deleting partner', error);
      return { ...normalizeEntities({}) };
    }
  }
);

/** Create Partner User */
export const createPartnerUser = createAsyncThunk(
  'entities/createPartnerUser',
  async (args: CreatePartnerUserArgs) => {
    const response = await novaGraphQLClient.createPartnerUser(args);
    return { ...normalizeEntities(response) };
  }
);

/* Fetch public referral partner */
export const fetchExternalPartnerById = createAsyncThunk(
  'entities/fetchExternalPartnerById',
  async ({ id, ...config }: { id: number; projection?: Projection }) => {
    const { partners } = await novaGraphQLPublicClient.fetchExternalPartnerById(id, {
      ...config,
    });

    return { ...normalizeEntities({ partners }) };
  }
);
