import React, { memo } from 'react';
import { ArrowUpIcon, Button, DrawerFooter, Grid, IconButton, NavigationSparkleFilledIcon, TextField } from '@sprnova/nebula';
import { NovaAiChallengeType } from 'features/entitiesRedux/models/nova_ai';
import ChallengeMessage from '../ChallengeMessage';
import css from '../../NovaGpt.module.scss';

type NovaGptFooterProps = {
    prompt: string;
    currentChallenge?: NovaAiChallengeType;
    questionLimitReached?: boolean;
    loadingChat?: boolean;
    isLoading?: boolean;
    handleSubmit: (e?: React.SyntheticEvent) => Promise<void>;
    handleLockButtonClick: (() => void) | null;
    onInputChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const NovaGptFooter = ({prompt, currentChallenge, questionLimitReached, loadingChat, isLoading, handleSubmit, handleLockButtonClick, onInputChange}: NovaGptFooterProps): JSX.Element => {
  return (
    <DrawerFooter className={css.footer}>
      <form onSubmit={handleSubmit}>
        {(currentChallenge && questionLimitReached) ?
          <Button
            startIcon={<NavigationSparkleFilledIcon/>}
            variant='primary'
            sx={{width: '100%'}}
            onClick={handleLockButtonClick}
          >
            <ChallengeMessage message={currentChallenge?.message} htmlResponse={currentChallenge?.action}/>
          </Button>
          :
          <Grid container paddingTop={'20px'} paddingBottom={'20px'}>
            <Grid item flex={1}>
              <TextField
                id='novaGPT-text-input'
                label='Enter prompt here'
                value={prompt}
                name="prompt"
                onChange={onInputChange}
                skeleton={loadingChat}
              />
            </Grid>
            <Grid item paddingLeft={2}>
              <IconButton
                id='novaGPT-submit-button'
                size="xl"
                color="primary"
                type="submit"
                disabled={!prompt.trim().length || isLoading}
              >
                <ArrowUpIcon />
              </IconButton>
            </Grid>
          </Grid>
        }
      </form>
    </DrawerFooter>
  );
};

export default memo(NovaGptFooter);
