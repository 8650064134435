import React, { useCallback, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { SelectClient } from 'features/intelligence/components/SelectClient';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Card, NebulaButton } from 'features/intelligence/components/library-components';
import { useCreateNovaIntelligenceProductDemosMutation } from 'api/crudGraphQL/intelligence/products/createNovaIntelligenceProductDemos';
import { Spin } from 'components';

export const DemoClientPage = (): JSX.Element => {
  const [selectedId, setSelectedId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();
  const [createNovaIntelligenceProductDemosRequest] = useCreateNovaIntelligenceProductDemosMutation();

  const handleSubmit = useCallback(async () => {
    if (!selectedId) return;
    try {
      setLoading(true);
      const demo = await createNovaIntelligenceProductDemosRequest({
        client_id: selectedId,
      }).unwrap();
      if (typeof demo === 'string') setMessage(demo);
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  }, [selectedId]);

  const sx = {
    display: 'flex',
    alignItems: 'center',
  };

  const handleSelectClient = useCallback((clientId: any) => {
    setMessage(undefined);
    setSelectedId(clientId);
  }, []);

  return (
    <div>
      <Card
        sx={{
          padding: '20px',
        }}
        overflow='visible'
        content={<>
          <Grid container spacing={3}>
            <Grid item xs={5} sx={sx}>
              <Typography variant='h4'>nova Demo Client</Typography>
            </Grid>
            <Grid item xs={2} sx={sx}>
              <ArrowForwardIcon style={{ fontSize: '30px' }} />
            </Grid>
            <Grid item xs={5} sx={sx}>
              <SelectClient
                label='Attach a Client to nova Demo Client'
                handleSelect={handleSelectClient}
                defaultValue={selectedId}
                args={{
                  available_for_cohort_analysis: 1,
                  has_consumer_insights: 1,
                  available_for_horizon_forecast: 1
                  /** Temporarily disable  */
                  // is_finance_active: 1,
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sx={{ ...sx, justifyContent: 'flex-end' }}>
              <NebulaButton size='large' onClick={handleSubmit} disabled={loading}>
                {loading ? <><Spin /> Loading...</> : 'Submit'}
              </NebulaButton>
            </Grid>
            <Grid item xs={12} sx={sx}>
              {
                message && <Typography variant='body1' sx={{ color: 'red' }}>{message}</Typography>
              }
            </Grid>
          </Grid>
        </>}
      />
    </div>
  );
}
