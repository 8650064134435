/**
 * DebouncedField
 */

import React, { FC, useState, useRef, useEffect, useCallback, memo } from 'react';

type Props = {
  component: any;
  isDisabled?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onUpdate: (value: any) => void,
  syncValue?: boolean;
  timeout?: number;
  updateOnBlur?: boolean;
  value: any;
  // All other props
  [x:string]: any;
};

const DebouncedField: FC<Props> = ({
  component: Component,
  timeout = 500,
  value: defaultValue,
  syncValue = true,
  onUpdate,
  isDisabled = false,
  updateOnBlur = false,
  onBlur,
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);
  const timeoutRef = useRef<number>();
  const handleUpdate = useCallback(onUpdate, [value, defaultValue]);
  const handleChange: (event: any) => void = eventOrValue => {
    setValue(eventOrValue?.type ? eventOrValue?.target?.value : eventOrValue);
  };

  // Change internal value when default value changes
  useEffect(() => {
    if (syncValue) {
      setValue(defaultValue);
    }
  }, [defaultValue, syncValue]);

  useEffect(() => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = window.setTimeout(() => {
      if (defaultValue !== value) {
        handleUpdate(value);
      }
    }, timeout);

    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, [value, defaultValue, handleUpdate, timeout]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // Update field on blur
    if (updateOnBlur && defaultValue !== value) {
      handleUpdate(value);
      window.clearTimeout(timeoutRef.current);
    }

    onBlur?.(e);
  };

  return (
    <Component
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={isDisabled}
      {...rest}
    />
  );
};

export default memo(DebouncedField);
