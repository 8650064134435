/**
 * Audits -> DiscoveryForm -> SalesforceOpportunityStage -> Modal -> UpdateSalesforceStageForm
 */

import React from 'react';
import { Form as AntdForm } from 'antd';
import startCase from 'lodash/startCase';
import { UpdateSalesforceStageFormValues } from 'features/entitiesRedux/models/audit';
import { DatePicker, Form, FormItem, SelectSalesforceReason, TextArea } from 'components';
import css from './UpdateSalesforceStageForm.module.scss';

type Props = {
  initialValues: Partial<UpdateSalesforceStageFormValues>;
  onSubmit: (values: UpdateSalesforceStageFormValues) => void;
  onValuesChange: (values: UpdateSalesforceStageFormValues) => void;
  selectedStage?: string;
};

const UpdateSalesforceStageForm = ({
  initialValues,
  onSubmit,
  onValuesChange,
  selectedStage
}: Props) => {
  const [form] = AntdForm.useForm();
  const renderContent = () => {
    switch (selectedStage) {
      case 'meeting-set':
      case 'appraisal':
      case 'reschedule':
        return (
          <>
            <FormItem
              name="salesforce_meeting_date"
              label="Meeting set date"
              rules={[{ required: true, message: 'A meeting set date is required' }]}
              description={<span className={css.description}>Select a date for the next meeting</span>}
            >
              <DatePicker format="MM/DD/YYYY" />
            </FormItem>
          </>
        );

      case 'disqualified':
      case 'closed-lost':
      case 'nurture':
      default:
        return (
          <>
            <FormItem
              name="salesforce_reason"
              label={`Primary reason for updating to "${startCase(selectedStage)}" stage`}
              rules={[{ required: true, message: 'A reason for updating the stage is required' }]}
              description={<span className={css.description}>Select the reason that best fits</span>}
            >
              <SelectSalesforceReason type={selectedStage} />
            </FormItem>
            <FormItem
              name="salesforce_explanation"
              label="Additional context"
              rules={[{ required: true, message: 'An explanation for updating the stage is required' }]}
              description={<span className={css.description}>Expand on the reason selected for this stage update</span>}
            >
              <TextArea />
            </FormItem>
            <FormItem
              name="salesforce_close_date"
              label="Confirm close date"
              rules={[{ required: true, message: 'A close date is required' }]}
              description={<span className={css.description}>Updating this stage will result in a closed opportunity</span>}
            >
              <DatePicker format="MM/DD/YYYY" />
            </FormItem>
          </>
        );
    }
  };

  return (
    <Form
      className={css.root}
      layout="vertical"
      name="updateStageForm"
      form={form}
      initialValues={initialValues}
      onFinish={onSubmit}
      onValuesChange={onValuesChange}
    >
      {renderContent()}
    </Form>
  );
};

export default UpdateSalesforceStageForm;
