import EntityPropertyType from './EntityPropertyType';
import Order from './Order';

/**
 * Sort with ascending/descending order based on provided order
 * Use orderBy to determine which entity and property to sort by
 *
 * @param Order order
 * @param orderBy object with properties to order by
 * @returns
 */
export function getComparator(
  order: Order,
  orderBy: EntityPropertyType,
): (
  a: any,
  b: any,
) => number {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 *
 * Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
 * stableSort() brings sort stability to non-modern browsers (notably IE11). If you
 * only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
 * with exampleArray.slice().sort(exampleComparator)
 *
 * @param array
 * @param function comparator
 * @returns
 */
export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


/**
   * Given two objects, compare them by the property
   *
   * Some objects will have nested properties so they will access those via [entity][property]
   *
   * If the object does not have a nested property, it will just access the entity directly that
   * represents the value needed for comparison
   *
   * @param a
   * @param b
   * @param EntityPropertyType orderBy
   * @returns number sort order
   */
export function descendingComparator<T>(a: any, b: any, orderBy: EntityPropertyType): number {
  if (orderBy.entity) {
    if (orderBy.property) {
      if (b[orderBy.entity][orderBy.property] < a[orderBy.entity][orderBy.property]) {
        return -1;
      }
      if (b[orderBy.entity][orderBy.property] > a[orderBy.entity][orderBy.property]) {
        return 1;
      }
      return 0;
    }
    else {
      if (b[orderBy.entity] < a[orderBy.entity]) {
        return -1;
      }
      if (b[orderBy.entity] > a[orderBy.entity]) {
        return 1;
      }
      return 0;
    }
  }
  return 0;

}

/**
 * Comparator for sorting by name
 * Given two objects, compare them by name
 * and return the sort order for the sort function
 *
 * @param a
 * @param b
 * @returns sort order
 */
export const compareByName = (a: any, b: any) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};
