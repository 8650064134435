import React from 'react';
import { useHistory } from 'react-router-dom';
import loadable from '@loadable/component';

const EditFieldsPage = loadable(
  () => import('./EditFieldsPage')
);

const Title = (): JSX.Element => {
  const history = useHistory();
  const pathname = history.location.pathname.split('/');
  const url = pathname[pathname.length - 1];

  const fields = [
    {
      name: 'Re-sign %',
      url: 'resign'
    },
    {
      name: 'Rapport',
      url: 'rapport'
    },
    {
      name: 'Pacing to Goal',
      url: 'pacing-to-goal'
    },
    {
      name: 'Sales Division',
      url: 'sales-division'
    },
  ];

  return <>{fields.filter(field => field.url === url)[0]?.name}</>;
};

export default Object.assign(EditFieldsPage, { Title });
