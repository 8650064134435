import { NovaAIResponseStatus, NovaAIResponseType } from 'features/entitiesRedux/models/nova_ai';

/**
 * Validates a Nova AI response.
 *
 * @param response The response that is to be validated.
 * @returns The validated response (throws an error if validation fails).
 */
const validateNovaAIResponse = (response: NovaAIResponseType): NovaAIResponseType => {
  const statusCodeValidationResult = validateStatusCode(response);

  if (statusCodeValidationResult === ValidationResult.Empty) {
    throw new Error('The nova EI response contains an empty status.');
  } else if (statusCodeValidationResult === ValidationResult.Invalid) {
    throw new Error('The nova EI response contains an invalid or unhandled status.');
  }

  return response;
};

/**
 * Validates the status code of a Nova AI response.
 *
 * @param response The Nova AI response object containing the status code to be validated.
 * @returns A ValidationResult enum value indicating the validation outcome:
 *   - ValidationResult.Valid if the status code is one of the expected values:
 *      - `"cancelled"`
 *      - `"completed"`
 *      - `"failed"`
 *      - `"in_progress"`
 *   - ValidationResult.Empty if the status code is empty or consists only of whitespace.
 *   - ValidationResult.Invalid otherwise (unexpected status code).
 */
export const validateStatusCode = (response: NovaAIResponseType): ValidationResult => {
  const isValid = (
    response.status === NovaAIResponseStatus.Cancelled ||
    response.status === NovaAIResponseStatus.Completed ||
    response.status === NovaAIResponseStatus.Failed ||
    response.status === NovaAIResponseStatus.InProgress);

  const isEmpty = !response.status?.trim();

  return toValidationResult(isValid, isEmpty);
};

export enum ValidationResult {
  Valid,
  Invalid,
  Empty,
}

/**
 * Maps a combination of validity and emptiness flags to a corresponding ValidationResult enum value.
 *
 * @param isValid A boolean indicating if the data is considered valid.
 * @param isEmpty A boolean indicating if the data is empty.
 * @returns A ValidationResult enum value representing the validation status derived from the input flags.
 */
const toValidationResult = (isValid: boolean, isEmpty: boolean): ValidationResult => {
  let result: ValidationResult;

  if (isValid) {
    result = ValidationResult.Valid;
  } else if (isEmpty) {
    result = ValidationResult.Empty;
  } else {
    result = ValidationResult.Invalid;
  }

  return result;
};

export default validateNovaAIResponse;
