import React, { FC, memo, useState } from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import DebouncedField from 'components/DebouncedField';
import { formatNumber } from 'features/audits/utils';
import { HorizonBudget } from 'features/entitiesRedux/models/horizon';
import { BudgetTableValue } from '../../../../BudgetTable';
import { Cell } from '../../../Cell';
import css from './FunnelCell.module.scss';

// This should be removed when Nebula arrives 
// A custom implementation / hack of the InputNumber that prevents the field from changing value if the value is the same
// This is needed due to some funky behavior with the InputNumber component 
// where the onChange handler is fired onBlur for some reason
const InputNumberHack: FC<InputNumberProps & { value: string }> = ({value, onChange, ...rest}) => {
  const handleChange = (nextValue: string | number): void => {
    if (value === formatNumber(Number(nextValue), '', 2)) {
      return;
    }

    onChange?.(nextValue);
  };
  return <InputNumber onChange={handleChange} value={value} {...rest} />;
};

export type FunnelCellProps = {
  value: number;
  onUpdateTotal: (date: string, id: number, value: number) => void;
  date: string;
  onUpdateCell?: ({ id, date, value }: { id?: number, date: string, value: number }) => Promise<HorizonBudget>;
  id?: number;
  onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  appearance?: BudgetTableValue['appearance'];
}

export const FunnelCell: FC<FunnelCellProps> = memo(({
  date,
  id,
  onPaste,
  onUpdateCell,
  onUpdateTotal,
  value: initialValue,
  appearance,
}) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>): void => onPaste(e);

  const handleUpdate = async (nextValue: string): Promise<void> => {
    const value = Number(nextValue.replace(/\$\s?|(,*)/g, ''));

    if (!onUpdateCell || value === initialValue) {
      return;
    }

    try {
      setError(false);
      setLoading(true);

      const { id: newId } = await onUpdateCell({
        id,
        date,
        value,
      });

      onUpdateTotal(date, newId, value);
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
    }
  };

  const formattedInitialValue = formatNumber(initialValue, '', 2);

  return (
    <div className={css.root}>
      <Cell key={date} prefix="$" isBold isLoading={loading} isError={error} appearance={appearance}>
        {!onUpdateCell ? formattedInitialValue : (
          <DebouncedField
            onUpdate={handleUpdate}
            timeout={1250}
            updateOnBlur
            keyboard={false}
            min={0}
            precision={2}
            component={InputNumberHack}
            parser={(value: string): string => value.replace(/\$\s?|(,*)/g, '')}
            value={formattedInitialValue}
            readOnly={loading}
            id={`budget-table-funnel-input-${date}`}
            stringMode
            onPaste={handlePaste}
          />
        )}
      </Cell>
    </div>
  );
}, (prev, next) => prev.value === next.value && prev.id === next.id);

FunnelCell.displayName = 'FunnelCell';
