/**
 * Get lead sources
 */

import { Projection } from 'api/entityGraphQL/types';
import { LeadSource } from 'features/entitiesRedux';
import { baseApi, cleanArguments } from '..';
import tagTypes from './tagTypes';

export type GetLeadSourcesQuery = {
  id?: number;
  name?: string;
  pricing_version?: string;
  projection: Projection;
};

export type GetLeadSourcesResult = LeadSource[];

export const GET_LEAD_SOURCE_ENDPOINT_KEY = 'getLeadSources';

export const { useGetLeadSourcesQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_LEAD_SOURCE_ENDPOINT_KEY]: build.query<GetLeadSourcesResult, GetLeadSourcesQuery>({
      transformResponse: (response: { lead_sources: GetLeadSourcesResult }) => response.lead_sources,
      providesTags: (result) => {
        const data = result ?? [];

        return [
          tagTypes.LEAD_SOURCES,
          ...data?.map((lead_source: LeadSource) => ({
            id: lead_source.id,
            type: tagTypes.LEAD_SOURCE
          }))
        ];
      },
      query: ({
        projection = { id: true },
        name,
        ...args
      }) => {
        const __args: Partial<GetLeadSourcesQuery> = args;

        if (name) {
          __args.name = `*${name}*`;
        }

        return {
          body: {
            query: {
              lead_sources: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        };
      }
    })
  })
});
