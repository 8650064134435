/**
 * Components -> InfoPopover
 */

import React, { FC, ReactNode, useMemo } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { Popover } from '../Popover';
import css from './InfoPopover.module.scss';

export type InfoPopoverProps = {
  children: ReactNode;
  title?: ReactNode;
  renderButton?: ReactNode;
  center?: boolean;
  margin?: 'left' | 'right';
  size?: 'small' | 'medium' | 'large';
}

export const InfoPopover: FC<InfoPopoverProps> = ({ children, center, size, margin, renderButton, title, ...props }) => {
  const popoverClasses = classnames(
    css.root,
    { [css.center]: center},
    [css[`size-${size}`]],
  );

  const iconClasses = classnames(
    css.icon,
    [css[`margin-${margin}`]],
  );

  const renderContent = useMemo(() => (
    <>
      {title ? <div className={css.title}>{title}</div> : null}
      <div className={css.content}>
        {children}
        {renderButton && (
          <div className={css.buttonWrap}>
            {renderButton}
          </div>
        )}
      </div>
    </>
  ), [children, renderButton]);

  return (
    <Popover
      className={popoverClasses}
      placement="bottom"
      content={renderContent}
      destroyTooltipOnHide
      {...props}
    >
      <InfoCircleOutlined
        className={iconClasses}
      />
    </Popover>
  );
};
