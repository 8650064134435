/**
 * Add Task Button
 */

import React, {
  FC, useState, useRef, /* , useMemo, useEffect */
  memo, useMemo
} from 'react';
import { useDispatch } from 'react-redux';
import { PlusCircleFilled } from '@ant-design/icons';
import { TreeSelect } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { TreeNode } from 'antd/lib/tree-select';
import RcTreeSelect from 'rc-tree-select';
import {StringParam, useQueryParam, withDefault} from 'use-query-params';
import { useTasks, useDepartments } from 'utils/hooks';
import { Task, createStrategyTask } from 'features/entitiesRedux';
import { PricingVersion, pricingVersionString } from 'features/library/constants';
import { Button, Popover /* , Select */, notification } from 'components';
import css from './AddTaskButton.module.scss';

const sortByName = function (a: { name: string }, b: { name: string }) {
  const textA = a.name.toUpperCase();
  const textB = b.name.toUpperCase();
  return textA < textB ? -1 : textA > textB ? 1 : 0;
};

type Props = {
  children: React.ReactNode;
  exclude: number[];
  order: number;
  popoverPlacement?: TooltipPlacement;
  strategyId: number;
  [x: string]: any;
};

const AddTaskButton: FC<Props> = ({
  strategyId,
  children,
  exclude,
  popoverPlacement = 'topRight',
  order,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [pricingVersionQueryParam, setPricingVersionQueryParam] = useQueryParam<string>(
    pricingVersionString,
    useMemo(() => withDefault(StringParam, PricingVersion.HOURLY as string), [])
  );
  const { tasks, loading: loadingTasks } = useTasks( { pricingVersion: pricingVersionQueryParam } );
  const {
    departments,
    loading: loadingDepartments /* , loading, error */,
  } = useDepartments({
    projection: {
      name: true,
      id: true,
      services: {
        id: true,
        name: true,
        tasks: { id: true, name: true, hours: true },
      },
    },
  });
  const loading = loadingTasks || loadingDepartments;
  // const excludeSet = new Set(exclude);
  // const selectableTasks = useMemo(
  //   () => tasks.filter((task) => !excludeSet.has(task.id)),
  //   [tasks, excludeSet]
  // );
  const [creating, setCreating] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);

  /**
   * Create a new task
   */
  const handleCreateTask = async ({ id: task_id }: Task) => {
    setCreating(true);
    const createAction: any = await dispatch(
      createStrategyTask({
        strategy_id: strategyId,
        order,
        task_id,
      })
    );

    if (createStrategyTask.fulfilled.match(createAction)) {
      setCreating(false);
      notification.success({
        message: 'Service added',
      });
    }
  };

  const ref = useRef<typeof RcTreeSelect>();
  const handleTogglePopover = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  const handleSelect = (id: number) => {
    setPopoverVisible(false);
    const task = tasks.find((task: Task) => task.id === id);
    if (task) {
      handleCreateTask(task);
    }
  };

  // console.log("😴 AddTaskButton.selectableTasks", selectableTasks);
  // console.log("😴 AddTaskButton.departments", departments);
  const popover = (
    <div className={css.popover}>
      <TreeSelect
        // showSearch
        style={{ width: '100%' }}
        // value={this.state.value}
        dropdownStyle={{ overflow: 'auto' }}
        placeholder="Please select"
        // allowClear
        // treeDefaultExpandAll
        // onChange={this.onChange}
        onSelect={handleSelect}
        // autoFocus={true}
        open={true}
        ref={ref as any}
      >
        {departments?.sort(sortByName).map((department) => (
          <TreeNode
            value={`department:${department.id}`}
            key={`department:${department.id}`}
            selectable={false}
            title={department.name}
          >
            {department?.services?.sort(sortByName).map(
              (service) =>
                (
                  <TreeNode
                    value={`department:${department.id}:service:${service.id}`}
                    key={`department:${department.id}:service:${service.id}`}
                    selectable={false}
                    title={service.name}
                  >
                    {service?.tasks
                      ?.sort(sortByName)
                      .map((task: Task) => (
                        <TreeNode
                          value={task.id}
                          key={task.id}
                          selectable={true}
                          title={task.name}
                        ></TreeNode>
                      )) || (
                      <TreeNode
                        value={`department:${department.id}:service:${service.id}:task:0`}
                        key={`department:${department.id}:service:${service.id}:task:0`}
                        selectable={false}
                        title={'no tasks'}
                      ></TreeNode>
                    )}
                  </TreeNode>
                ) || (
                  <TreeNode
                    value={0}
                    key={0}
                    selectable={false}
                    title={'no services'}
                  ></TreeNode>
                )
            )}
          </TreeNode>
        ))}
      </TreeSelect>
    </div>
  );
  // const popoverFlat = (
  //   <div className={css.popover}>
  //     <Select
  //       autoFocus
  //       block
  //       filterOption={(input: string, option) =>
  //         option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  //       }
  //       onSelect={handleSelect}
  //       optionFilterProp="children"
  //       placeholder="Find service..."
  //       ref={selectRef}
  //       showSearch
  //       defaultOpen
  //     >
  //       {selectableTasks.map((task: Partial<Task>) => (
  //         <Select.Option key={task.id} value={task.id || ""}>
  //           {task.name}
  //         </Select.Option>
  //       ))}
  //     </Select>
  //   </div>
  // );

  return (
    <Popover
      destroyTooltipOnHide
      content={popover}
      trigger="click"
      visible={popoverVisible}
      onVisibleChange={handleTogglePopover}
      // placement={popoverPlacement}
      placement="topLeft"
    >
      <Button
        type="default"
        icon={<PlusCircleFilled />}
        {...rest}
        disabled={creating || loading /* && !selectableTasks.length */}
        onClick={() => {}}
      >
        {creating ? 'Please wait...' : children}
      </Button>
    </Popover>
  );
};

export default memo(AddTaskButton);
