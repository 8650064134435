import React from 'react';
import { Link } from 'react-router-dom';
import { AlertRibbon, NebulaButton } from 'features/intelligence/components/library-components';
import css from './InsufficientData.module.scss';

type InsufficientDataProps = {
  clientId: number;
}

const InsufficientData = ({ clientId }: InsufficientDataProps): JSX.Element => {
  return (
    <AlertRibbon
      className={css.root}
      title="Insufficient historical data"
      content={
        <div className={css.description}>
          Although your client has the proper integrations set up, there isn’t
          enough historical data available to make intelligent predictions with.
        </div>
      }
      extra={
        <Link to={`/power-view/accounts/${clientId}/integrations/add`}>
          <NebulaButton buttonType='tertiary' size='medium'>
            Add Integrations
          </NebulaButton>
        </Link>
      }
      extraPosition='middle'
      type="warning"
    />
  );
};

export default InsufficientData;
