/**
 * Intelligence -> Horizons -> View Horizon -> Forecast Output
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, DownloadIcon, Grid } from '@sprnova/nebula';
import { Action } from 'api/accessControl/Action';
import { Resource } from 'api/accessControl/Resource';
import classNames from 'classnames';
import { AccessControl } from 'components/AccessControl';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import Card, { CardHeader, CardContent } from 'components/nebula/Card';
import ArrowRight from 'components/nebula/icons/ArrowRight';
import ErrorOutline from 'components/nebula/icons/ErrorOutline';
import { uniqBy } from 'lodash';
import moment from 'moment';
import exportCSV from 'utils/actions/exportCSV';
import trackExport from 'utils/actions/trackExport';
import { HorizonPrediction } from 'features/entitiesRedux/models/horizon';
import { useAccount } from 'features/global';
import { LineChart, SelectBudgetScenario, HorizonScatterChart } from 'features/intelligence/horizon/components';
import { BudgetTable } from 'features/intelligence/horizon/components/BudgetTable';
import { LineChartDataType } from 'features/intelligence/horizon/components/LineChart/types';
import { useGetHorizonByUrlParam } from 'features/intelligence/horizon/hooks/useGetHorizonByUrlParam';
import { HorizonLayout } from 'features/intelligence/horizon/HorizonLayout';
import { getHorizonUncertaintyRangeAreas } from 'features/intelligence/horizon/utils';
import { formatHorizonDataForCSVExport } from 'features/intelligence/horizon/utils/formatHorizonDataForCSVExport';
import { Alert, FormItem, Spin } from 'components';
import InferencePipelineProgressBar from '../InferencePipelineProgressBar';
import css from './ForecastOutput.module.scss';

const ForecastOutput = (): JSX.Element => {
  // used for re-fetching horizon when budget scenario has status of in_progress
  const [pollingInterval, setPollingInterval] = useState<number>();
  const [exporting, setExporting] = useState<boolean>(false);

  /**
   * Get horizon by id from URL
   */
  const { data: horizon, isLoading, isFetching } = useGetHorizonByUrlParam({ pollingInterval, refetchOnMountOrArgChange: true });

  /** Mixpanel Event Tracking */
  const mixpanel = useMixpanel();
  const mixpanelOptions = {
    horizonId: horizon?.id,
    horizonName: horizon?.name,
    clientId: horizon?.client?.id,
    clientName: horizon?.client?.name,
  };
  const { account } = useAccount();
  const options = useMemo(() => {
    return {
      userId: account?.id,
      userName: account?.name,
    };
  }, [account]);
  const mixpanelTitle = 'nova Forecast - Export Forecast Output';


  useEffect(() => {
    if (!horizon?.id || !mixpanel?.track) return;
    console.log('🛤 Track: Forecast Output page viewed', mixpanelOptions);
    if (mixpanel?.track) {
      mixpanel.track('Horizon forecast output page viewed', mixpanelOptions);
    }
  }, []);

  /**
   * Get the primary budget scenario so we can set it as the default selection
   */
  const primaryScenario = horizon?.scenarios?.filter(scenario => scenario?.is_primary)?.[0];
  const [scenarioId, setScenarioId] = useState<number | undefined>(primaryScenario?.id);
  useEffect(() => {
    if (primaryScenario?.id) {
      setScenarioId(primaryScenario?.id);
    }
  }, [primaryScenario]);

  /**
   * Track the currently selected budget scenario
   */
  const selectedScenario = useMemo(() => {
    return horizon?.scenarios?.filter(scenario => scenario?.id === scenarioId)?.[0];
  }, [horizon, scenarioId]);


  /**
   * Create array containing all months in the horizon
   */
  const months = useMemo(() => {
    return Array.from(Array(horizon?.length), (e, i) => {
      return moment(horizon?.start_date)?.add(i, 'month')?.format('MMM YYYY');
    });
  }, [horizon]);

  /**
   * Format data for currently selected scenario so it will work with the <LineChart> component
   */
  const formattedChartData = months?.map((month, i) => {
    if (selectedScenario?.predicted_outputs?.length) {
      const netSalesPredictions = selectedScenario?.predicted_outputs?.filter(output => output?.channel?.name === 'Net Sales');
      const totalAdSpend = selectedScenario?.predicted_outputs?.filter(output => output?.channel?.name === 'Ad Spend');
      return {
        month: month,
        net_sales_prediction: netSalesPredictions[i]?.value,
        total_ad_spend: totalAdSpend[i]?.value,
        net_sales_prediction_10_percent_above: netSalesPredictions[i]?.value * 1.1,
        net_sales_prediction_10_percent_below: netSalesPredictions[i]?.value * 0.9,
        net_sales_prediction_20_percent_above: netSalesPredictions[i]?.value * 1.2,
        net_sales_prediction_20_percent_below: netSalesPredictions[i]?.value * 0.8,
      };
    }
  });

  /**
   * Assemble the LineChart data object
   */
  const lineChartData: LineChartDataType = {
    data: formattedChartData,
    title: `${horizon?.client?.name}\uFF5C${horizon?.model?.name} Model Predictions`,
    xAxisDataKey: 'month',
    areas: getHorizonUncertaintyRangeAreas('net_sales_prediction'),
    lines: [
      { key: 'net_sales_prediction', dataKey: 'net_sales_prediction', name: 'Net Sales Predictions', stroke: '#1c5e20' },
      { key: 'total_ad_spend', dataKey: 'total_ad_spend', name: 'Total Ad Spend', stroke: '#a94882' },
    ],
  };

  /**
   * Variables for the <BudgetTable> component
   */
  const outputFunnels: any = [selectedScenario?.predicted_outputs?.[0]?.funnel];
  const { scenarios, start_date, length } = horizon || { scenarios: [], start_date: '', length: 0 };

  /**
   * Check if we have any budget scenarios with a status of in_progress
   */
  const scenariosInProgress = scenarios?.filter(scenario => scenario?.status === 'in_progress');
  const hasScenarioInProgress = !!scenariosInProgress?.length;

  /**
   * Fetch horizon every 30 seconds until no budget scenarios have a status of in_progress
   */
  useEffect(() => {
    if (hasScenarioInProgress) {
      setPollingInterval(30000);
    } else {
      setPollingInterval(0);
    }
  }, [hasScenarioInProgress]);

  /**
   * Alert message for budget scenarios with a status of "failed"
   */
  const renderErrorAlert = (): JSX.Element => {
    return (
      <Alert
        className={css.alertError}
        type="error"
        showIcon
        icon={<ErrorOutline />}
        message={<strong>Unable to generate forecast output</strong>}
        description={
          <div className={css.alertContent}>
            <p>Forecast was unable to generate a forecast for the selected budget scenario. You can submit a support ticket and one of our team members will gladly help diagnose the issue and assist with creating budget scenarios if needed.</p>
            <p><u>Common reasons for a failed forecast output include:</u></p>
            <ul>
              <li>Using a budget scenario with <strong>extremely high or extremely low media budgets in comparison to previous years</strong>, which could generate unrealistic forecast results</li>
              <li>Using a budget scenario which <strong>deviates greatly from last year&apos;s channel mix</strong>, which could generate unrealistic forecast results</li>
              <li>Using a budget scenario that <strong>removes spend from high performing channel tactics</strong>, which could generate unrealistic forecast results</li>
            </ul>
          </div>
        }
        action={
          <a href="https://form.asana.com/?k=Y0Uh0wpSRgfi9S8TIfvZzA&d=22908445599079" target="_blank" rel="noreferrer" className={css.supportLink}>
            Contact nova Support<ArrowRight />
          </a>
        }
      />
    );
  };

  /**
   * Handle export CSV
   */
  const handleExportCSV = useCallback(async (predictions: [HorizonPrediction] | undefined, outputFunnels: []) => {
    await setExporting(true);

    const title = 'Forecast Output';

    const formattedData = formatHorizonDataForCSVExport({
      title: `${horizon?.client?.name} | Predicted Net Sales & Total Budgeted Spend`,
      moduleName: title,
      data: predictions,
      channels: Array.from(
        new Set(predictions?.map(prediction => prediction.channel.name))
      ) || [],
      funnels: outputFunnels?.map((funnel: { name: string }) => funnel?.name) || [],
      firstDate: start_date,
      length: length,
    });

    await exportCSV({
      data: formattedData ? formattedData : [],
      moduleName: title,
    }).finally(() => {
      setExporting(false);
      trackExport({ title: mixpanelTitle, type: 'CSV', mixpanel, options });
    });
  }, [horizon?.client?.name, start_date, length, mixpanelTitle, mixpanel, options]);

  const renderContent = (): JSX.Element => {
    if (hasScenarioInProgress) {
      return <InferencePipelineProgressBar />;
    }

    if (isFetching) {
      return <Spin className={css.spin} loading fontSize={100} />;
    }

    return (
      <div className={css.root}>
        <FormItem className={css.selectScenarioWrapper} label="Budget Scenario">
          <SelectBudgetScenario
            scenarios={scenarios}
            onSelect={(id: number) => setScenarioId(id)}
            value={scenarioId}
          />
        </FormItem>
        {selectedScenario?.status === 'failed' ? renderErrorAlert() :
          <Card className={css.card} hasShadow>
            <CardHeader
              title="Forecast Output"
              sub="A prediction of what future revenue looks like based on the trained model and budget scenario"
            />
            <CardContent>
              {!scenarioId ? <Alert type="warning" message="Please select a budget scenario to view the forecast output" showIcon /> :
                <>
                  <Card className={classNames(css.card, css.chart)}>
                    <HorizonScatterChart
                      performances={selectedScenario?.performances_predictions}
                      isForecast
                      title={`${horizon?.client?.name}: Media Channel - Tactic Predicted Performance`}
                      subtitle="Scoring Media Channel - Tactics Performance against Budgeted Cost and Historical ROAS"
                    />
                  </Card>
                  <Card className={classNames(css.card, css.chart)}>
                    <LineChart data={lineChartData} isBiaxial />
                  </Card>
                  <Card className={classNames(css.card, css.table)}>
                    <CardHeader
                      title={`${horizon?.client?.name} | Predicted Net Sales & Total Budgeted Spend`}
                      renderEnd={
                        <Grid item>
                          <Button
                            onClick={() => handleExportCSV(selectedScenario?.predicted_outputs, outputFunnels)}
                            size="large"
                            startIcon={<DownloadIcon />}
                            variant="secondary"
                            disabled={exporting || isLoading}
                          >
                            Export
                          </Button>
                        </Grid>
                      }
                      sub="Projected Net Sales based on the given Budget Scenario"
                    />
                    <CardContent>
                      <BudgetTable
                        channels={uniqBy(selectedScenario?.predicted_outputs?.map(output => output?.channel), 'name') || []}
                        data={selectedScenario?.predicted_outputs || []}
                        funnels={outputFunnels}
                        length={length}
                        startDate={start_date}
                      />
                    </CardContent>
                  </Card>
                </>
              }
            </CardContent>
          </Card>
        }
      </div>
    );
  };

  return (
    <HorizonLayout horizon={horizon} isLoading={isLoading}>
      <AccessControl action={[Action.read]} resource={Resource.forecast}>
        {renderContent()}
      </AccessControl>
    </HorizonLayout>
  );
};

export default ForecastOutput;
