/**
 * Card -> Header
 */

import React, { FC } from 'react';
import classNames from 'classnames';
import css from './CardContent.module.scss';

export interface CardContentProps {
  component?: keyof JSX.IntrinsicElements | React.ElementType;
  className?: string;
  children?: React.ReactNode;
  [key: string]: any;
}

export const CardContent: FC<CardContentProps> = ({ 
  component: Component = 'div',
  children,
  className,
  ...props
}) => {

  return (
    <Component 
      className={classNames(
        css.root, 
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
};
