/**
 * Library -> Analyst Surveys -> Components -> Status
 */

import React, { FC } from 'react';
import { AnalystQuestion, AnalystQuestionGroup } from 'api/crudGraphQL/analyst_surveys/types';
import classNames from 'classnames';
import { Tag } from 'components/Tag';
import { Tooltip } from 'components/Tooltip';
import css from './Status.module.scss';

export type StatusProps = {
  status?: AnalystQuestionGroup['status'] | AnalystQuestion['status'] | string;
  circle?: boolean;
  hideLabel?: boolean;
  className?: string;
}

export const Status: FC<StatusProps> = ({ status, circle, hideLabel, className }) => {
  let label, color;

  if (status === 'deleted') {
    label = 'Deleted';
    color = 'red';
  }

  if (status === 'created') {
    label = 'New';
    color = 'green';
  }

  if (status === 'updated') {
    label = 'Modified';
    color = 'orange';
  }

  if (!label && !color) {
    return null;
  }

  if (circle) {
    return (
      <Tooltip title={label} disabled={!hideLabel}>
        <span className={classNames(css.circle, { [css[`color-${color}`]]: color }, className)}>
          {!hideLabel ? 
            <span className={css.circle__label}>
              {label}
            </span>
            : null}
        </span>
      </Tooltip>
    );
  }
  
  return (
    <Tag 
      className={css.tag} 
      color={color}
    >
      {label}
    </Tag>
  );
};

