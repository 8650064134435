import { flatten } from 'lodash';
import { Score } from 'features/entitiesRedux';
import { getScoreStatus } from './getScoreStatus';

export const getDepartmentStatus = (department?: { scores: Score[] }) => {
  if (!department) {
    return undefined;
  }
  const buckets = flatten(
    department?.scores?.map((score: Score) => score.buckets)
  );
  return getScoreStatus({ buckets });
};
