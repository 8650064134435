/**
 * View strategy -> DescriptionField
 */

import React, { FC, memo } from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { Input, DebouncedField, Tooltip } from 'components';
import cssViewStrategyPage from '../../ViewStrategyPage.module.scss';

type Props = {
  value: string;
  onUpdate: (value: string) => void;
  isLocked?: boolean;
  className?: string
}

const DescriptionField: FC<Props> = ({ value, onUpdate, isLocked = false, className}) => {
  return (
    <DebouncedField
      syncValue={false}
      timeout={1000}
      onUpdate={onUpdate}
      component={Input}
      value={value}
      isDisabled={isLocked}
      className={isLocked ? `${cssViewStrategyPage.editingDisabled} ${className}` : className}
      addonAfter={value && value.length > 30 ? (
        <Tooltip title={value}>
          <EyeOutlined />
        </Tooltip>
      ) : null}
    />
  );
};

export default memo(DescriptionField);
