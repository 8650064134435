import React, { ReactNode } from 'react';

export type Term =
  | 'actual-gross-profit'
  | 'actual-hourly-rate'
  | 'avg-monthly-gp'
  | 'avg-monthly-gp-v2'
  | 'commission-percent'
  | 'deal-value'
  | 'es-am-commission'
  | 'es-am-expense'
  | 'es-percent'
  | 'exec-sponsor-fee'
  | 'flat-fee-commission'
  | 'grade'
  | 'internal-commission'
  | 'internal-sales-commission'
  | 'internal-sales-expense'
  | 'outsource-costs'
  | 'partner-costs'
  | 'partner-percent'
  | 'real-profit'
  | 'referral-partner-commission'
  | 'referral-partner-expense'
  | 'refresh-data'
  | 'sales-commission'
  | 'subtotal-gross-profit'
  | 'suggested-hourly-rate'
  | 'total-expenses'
  | 'total-hours'
  | 'total-profit';

export const terms: {
  [title: string]: { title: string; content: ReactNode };
} = {
  grade: {
    title: 'Profitability Grade',
    content: (
      <>
        <p>
          A letter grade <em>(A-F)</em> expression profitability of a deal
        </p>
        <p>
          Grade of <strong>A</strong> is achieved when the{' '}
          <em>Actual Hourly Rate</em> is greater than or equal to the{' '}
          <em>Suggested Hourly Rate</em>
        </p>
        <p>
          You can increase the <em>Grade</em> by increasing the{' '}
          <em>Monthly Retainer</em> amounts
        </p>
      </>
    ),
  },
  'deal-value': {
    title: 'Deal Value',
    content: (
      <p>
        Total of all <em>Monthly Retainer</em> values in the{' '}
        <em>Monthly Retainer & Profit</em> tab
      </p>
    ),
  },
  'outsource-costs': {
    title: 'Outsource Costs',
    content: (
      <p>
        Total amount of all months and services in the <em>Outsource Costs</em>{' '}
        tab
      </p>
    ),
  },
  'exec-sponsor-fee': {
    title: 'ES and AM Costs',
    content: (
      <p>
        Cost allocated for <em>Account Director</em> management and AM
        communication
      </p>
    ),
  },
  'sales-commission': {
    title: 'Sales Commission',
    content: (
      <p>
        Amount allocated for <em>Sales</em> commission payments
      </p>
    ),
  },
  'internal-commission': {
    title: 'Internal Commission',
    content: (
      <p>
        Amount allocated for <em>Sales</em> commission or{' '}
        <em>Account Management</em> team commission payments
      </p>
    ),
  },
  'real-profit': {
    title: 'Real Profit',
    content: (
      <>
        <p>
          Total profit before <em>Partner Costs</em>
        </p>
        <pre>
          = Deal Value <br />- Outsource Costs <br />- ES and AM Costs <br />-
          Sales Commission
        </pre>
      </>
    ),
  },
  'refresh-data': {
    title: 'When should I refresh data?',
    content: (
      <>
        <p>
          If your data is out-of-date or there was an error when importing, you can use <em>Refresh Data</em> to start a new import.
        </p>
      </>
    ),
  },
  'partner-costs': {
    title: 'Partner Costs',
    content: (
      <p>
        Amount allocated for <em>Partner</em> commission payments
      </p>
    ),
  },
  'total-profit': {
    title: 'Total Profit',
    content: (
      <>
        <p>
          Total profit after <em>All Costs</em>
        </p>
        <pre>
          = Real Profit <br />- Partner Costs
        </pre>
      </>
    ),
  },
  'avg-monthly-gp': {
    title: 'Avg. Monthly Gross Profit',
    content: (
      <>
        <p>
          Total profit per month before paying <em>Partner Costs</em>
        </p>
        <pre>
          = Real Profit <br />/ Length of Contract
        </pre>
      </>
    ),
  },
  'avg-monthly-gp-v2': {
    title: 'Avg. Monthly Gross Profit',
    content: (
      <>
        <p>
          Actual Gross Profit per month after paying <em>all</em> expenses.
        </p>
        <pre>
          = Actual Gross Profit <br />/ Length of Contract
        </pre>
      </>
    ),
  },
  'actual-hourly-rate': {
    title: 'Actual Hourly Rate',
    content: (
      <>
        <p>
          Total profit per month before paying <em>Sales Commissions</em> and{' '}
          <em>Partner Costs</em>
        </p>
        <pre>
          = Total Profit <br /> / Total Hours
        </pre>
      </>
    ),
  },
  'suggested-hourly-rate': {
    title: 'Suggested Hourly Rate',
    content: (
      <>
        <p>
          The recommended <em>Hourly Rate</em> for a deal based on the number of{' '}
          <em>Senior, Standard, and Junior</em> hours assigned in the{' '}
          <em>Services (Hours)</em> tab
        </p>
      </>
    ),
  },
  'total-hours': {
    title: 'Total Hours',
    content: (
      <>
        <p>
          Total combined hours of all months in the <em>Services (Hours)</em>{' '}
          tab
        </p>
      </>
    ),
  },
  'es-percent': {
    title: 'AD Percent',
    content: (
      <p>
        Cost allocated for <em>Account Director</em> management
      </p>
    ),
  },
  'commission-percent': {
    title: 'Commission Percent',
    content: (
      <p>
        Cost allocated for <em>Sales</em> or <em>Team Commissions</em> payments.
      </p>
    ),
  },
  'partner-percent': {
    title: 'Partner Percent',
    content: (
      <p>
        Cost allocated for <em>Partner referral</em> Commission payments.
      </p>
    ),
  },
  'es-am-expense': {
    title: 'AD/AM Expense',
    content: (
      <>
        <p>
          Amount allocated for <em>Account Director</em> & <em>Account Management</em> costs.
        </p>
        <pre>
        = Subtotal Gross Profit <br />x AD Percent
        </pre>
      </>
    ),
  },
  'internal-sales-expense': {
    title: 'Internal (Sales) Expense',
    content: (
      <>
        <p>
          Amount allocated for <em>Sales</em> or <em>Team Commissions</em> costs.
        </p>
        <pre>
        = Subtotal Gross Profit <br />x Commission Percent
        </pre>
      </>
    ),
  },
  'referral-partner-expense': {
    title: 'Referral Partner Expense',
    content: (
      <>
        <p>
          Amount allocated for <em>Partner referrals</em> costs.
        </p>
        <pre>
        = Subtotal Gross Profit <br />x Partner Percent <br />+ Flat Fee Commission
        </pre>
      </>
    ),
  },
  'total-expenses': {
    title: 'Total Expenses',
    content: (
      <p>
        Includes commissions, partner referrals, management and other internal costs.
      </p>
    ),
  },
  'es-am-commission': {
    title: 'AD/AM Commission',
    content: (
      <>
        <p>
          Amount allocated for <em>Account Director</em> & <em>Account Management</em> payments.
        </p>
        <pre>
        = Actual Gross Profit <br />x AD Percent
        </pre>
      </>
    ),
  },
  'internal-sales-commission': {
    title: 'Internal (Sales) Commission',
    content: (
      <>
        <p>
          Amount allocated for <em>Sales</em> or <em>Team Commission</em> payments.
        </p>
        <pre>
        = Actual Gross Profit <br />x Commission Percent
        </pre>
      </>
    ),
  },
  'referral-partner-commission': {
    title: 'Referral Partner Commission',
    content: (
      <>
        <p>
          Amount allocated for <em>Partner referral</em> commission payments.
        </p>
        <pre>
        = Actual Gross Profit <br />x Partner Percent
        </pre>
      </>
    ),
  },
  'flat-fee-commission': {
    title: 'Flat Fee Commission',
    content: (
      <>
        <p>
          Flat fee paid out for <em>Partner referral</em> in addition to partner&apos;s commission payment.
        </p>
      </>
    ),
  },
  'actual-gross-profit': {
    title: 'Actual Gross Profit',
    content: (
      <>
        <p>
          Actual Gross Profit after paying <em>all</em> expenses.
        </p>
        <pre>
        = Subtotal Gross Profit <br />- Total Expenses
        </pre>
      </>
    ),
  },
  'subtotal-gross-profit': {
    title: 'Subtotal Gross Profit',
    content: (
      <>
        <p>
          Total Gross Profit after <em>Outsource Costs</em>.
        </p>
        <pre>
          = Deal Value <br />- Outsource Costs
        </pre>
      </>
    ),
  },
};
