import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container } from '@sprnova/nebula';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { PageHero } from 'layouts/components';
import {
  updateAudit,
  deleteAudit,
  useAuditByParamId,
  Audit,
  AuditFormArgs,
  Client,
  Role,
} from 'features/entitiesRedux';
import { selectError, selectIsLoading } from 'features/entitiesRedux/selectors';
import {
  Card,
  Skeleton,
  Alert,
  notification,
  message,
} from 'components';
import { Warning } from '../../../components/AccessControl/Warning';
import { useClientById } from '../../../utils';
import { useRoleAccess } from '../../global';
import { AuditForm } from '../AuditForm';
import { AuditPageHeaderExtra } from '../AuditPageHeaderExtra';
import css from './EditAuditPage.module.scss';

type FormattedTitleProps = {
  formattedTitle?: string | number;
};

const formatAuditTitle = ({
  audit,
  isLoading,
}: {
  audit?: Audit;
  isLoading: boolean;
}) => {
  return !audit && isLoading ? (
    <Skeleton title={{ width: 200 }} paragraph={false} active />
  ) : audit ? (
    (() => {
      if (audit.name) return `${audit.name}`; // Try the name field
      if (audit?.client?.name) return `${audit.client.name} Appraisal`; // Try the client field
      return `Appraisal #${audit.id}`; // Fallback to the id
    })()
  ) : (
    '...'
  );
};

const EditAuditPage: React.FC<FormattedTitleProps> = () => {
  /**
   * Routing
   */
  const history = useHistory();

  /**
   * Redux State
   */
  const error = useSelector(selectError);
  const isLoading = useSelector(selectIsLoading);

  /**
   * Load variables from path
   */
  const audit = useAuditByParamId();

  /**
   * Get client appraisals
   */
  const client: Partial<Client> | undefined = (audit?.client?.id && useClientById(audit?.client?.id)?.client) || undefined;
  const clientAppraisals = client?.audits?.filter(clientAudit => clientAudit?.id !== audit?.id);

  /**
   * Format variables
   */
  const title = formatAuditTitle({ audit, isLoading });

  const mixpanel = useMixpanel();
  const trackStatusChange = (status?: string, origStatus?: string) => {
    if ((!!status || !!origStatus) && status !== origStatus) {
      try {
        const options = {
          title: audit?.name,
          appraisalId: audit?.id,
          appraisal: audit?.name,
          clientId: audit?.client?.id,
          clientName: audit?.client?.name,
          businessType: audit?.client?.business_type?.name,
          businessTypeId: audit?.client?.business_type?.id,
          businessTypeSlug: audit?.client?.business_type?.slug,
          status: status,
        };
        console.log('🛤 Track: Appraisal Status ' + status, { options });
        if (mixpanel?.track) {
          mixpanel.track('Appraisal Status ' + status, options);
        }
      } catch (error) {
        console.error('Mixpanel error', error);
      }
    }
  };

  /**
   * Form handler
   */
  const dispatch = useDispatch();
  const handleSubmit = async (values: AuditFormArgs) => {
    if (values && Object.prototype.hasOwnProperty.call(values, 'id')) {
      await dispatch(updateAudit(values));
      trackStatusChange(values?.status, audit?.status);
      message.success('Appraisal saved');
    }
  };
  const onDelete = async () => {
    await dispatch(deleteAudit(audit.id));
    history.push('/appraisals');
    notification.success({
      message: 'Appraisal deleted',
      description: (
        <span>
          <em>{title}</em> was successfully deleted
        </span>
      ),
    });
  };

  const hasAccess = useRoleAccess([Role.superAdmin, Role.vpLevel, Role.cLevel, Role.departmentDirectors]);

  if (!hasAccess && !isLoading) {
    return <Warning container />;
  }

  return (
    <>
      <Helmet>
        <title>
          {title !== '...' ? `Edit ${title} Appraisal` : 'Edit Appraisal'}
        </title>
      </Helmet>
      <PageHero
        title={title}
        description="Edit appraisal"
        end={<AuditPageHeaderExtra audit={audit} />}
      />
      <Container hasVerticalPadding>
        <Card className={css.root}>
          {error && <Alert message={error} type="error" className={css.error} />}
          <AuditForm
            audit={audit || undefined}
            clientAppraisals={clientAppraisals}
            onSubmit={handleSubmit}
            onDelete={onDelete}
            isLoading={isLoading}
          />
        </Card>
      </Container>
    </>
  );
};

export const FormattedTitle = (): JSX.Element => {
  const audit = useAuditByParamId();
  const isLoading = !audit;
  return <span>{formatAuditTitle({ audit, isLoading })}</span>;
};

export default EditAuditPage;
