import { useGetMeQuery } from 'api/crudGraphQL/me/getMe';
import { useGetMePublicQuery } from 'api/crudGraphQL/public/me/getMePublic';
import { Projection } from 'api/entityGraphQL';
import isExternalUser from 'utils/helpers/isExternalUser';
import { ExternalUser, User } from 'features/entitiesRedux';

const defaultInternalUserProjection: Projection = {
  id: true,
  created_at: true,
  name: true,
  email: true,
  avatar: true,
  permissions: true,
  roles: true,
  accounts: {
    id: true,
    username: true,
    type: true,
    updated_at: true
  },
  organization: {
    id: true,
    slug: true,
    name: true,
  },
  department: {
    id: true,
    slug: true,
    name: true,
  },
  departments:  {
    id: true,
    slug: true,
    name: true,
  },
  partners: {
    id: true,
  },
  manager: {
    id: true,
    name: true,
  },
  title: true,
  job_title: {
    id: true,
    name: true,
  },
};

const defaultExternalUserProjection: Projection = {
  id: true,
  created_at: true,
  name: true,
  email: true,
  avatar: true,
  permissions: true,
  roles: true,
  organization: {
    id: true,
    slug: true,
    name: true,
  },
  department: {
    id: true,
    slug: true,
    name: true,
  },
  departments:  {
    id: true,
    slug: true,
    name: true,
  },
  partners: {
    id: true,
  },
  title: true,
};

type UseUserConfig = {
  /**
   * The projection to be used to query the internal user.
   */
  internalUserProjection?: Projection,
  /**
   * The projection to be used to query the external user.
   */
  externalUserProjection?: Projection,
  /**
   * Prevents the query from automatically running.
   */
  skip?: boolean,
}

type UseUserResult = {
  /**
   * A reference to the external user or `null` if the user is internal.
   */
  externalUser: ExternalUser | null,
  /**
   * A reference to te internal user or `null` if the user is external.
   */
  internalUser: User | null,
  /**
   * Query is currently loading for the first time. No data yet.
   */
  isLoading: boolean,
  /**
   * Query is currently fetching, but might have data from an earlier request.
   */
  isFetching: boolean,
}

/**
 * A hook that simplifies fetching of the user, depending on the current user type.
 *
 * @param config.internalUserProjection The projection to be used to query the internal user.
 * @param config.externalUserProjection The projection to be used to query the external user.
 * @param config.skip Prevents the query from running.
 * @returns An object with the following properties:
 * - `externalUser`: The external user data, or `null` if the user is internal.
 * - `internalUser`: The internal user data, or `null` if the user is external.
 * - `isFetching`: A boolean indicating whether the data is currently being fetched.
 * - `isLoading`: A boolean indicating whether the initial data is still loading.
 */
export const useUser = (config: UseUserConfig = {}): UseUserResult => {
  const { internalUserProjection, externalUserProjection, skip } = config;
  const isExternal = isExternalUser();

  const {
    data: externalUser = null,
    isFetching: isFetchingExternalUser,
    isLoading: isLoadingExternalUser,
  } = useGetMePublicQuery({
    projection: externalUserProjection || defaultExternalUserProjection
  }, { skip: !isExternal || skip });

  const {
    data: internalUser = null,
    isFetching: isFetchingInternalUser,
    isLoading: isLoadingInternalUser,
  } = useGetMeQuery({
    projection: internalUserProjection || defaultInternalUserProjection
  }, { skip: isExternal || skip });

  const isFetching = isFetchingExternalUser || isFetchingInternalUser;
  const isLoading = isLoadingExternalUser || isLoadingInternalUser;

  return {
    externalUser,
    internalUser,
    isFetching,
    isLoading,
  };
};
