import React, { useCallback, useEffect, useMemo } from 'react';
import { Autocomplete, Skeleton, Spinner } from '@sprnova/nebula';
import { useGetCustomerInsightsLTVGroupQuery } from 'api/crudGraphQL/customer_insights/getConsumerInsightsLTVGroup';
import FormV2 from 'components/nebula/Form/FormV2';
import { startCase } from 'lodash';
import { JsonParam, useQueryParams } from 'use-query-params';

export const SelectLTV = ({ onSubmit, isLoading }: { onSubmit?: (data: any) => void; isLoading?: boolean }): React.ReactElement => {
  const [queryParams, setQueryParams] = useQueryParams({ ltv: JsonParam });

  const { data, isLoading: ltvGroupLoading } = useGetCustomerInsightsLTVGroupQuery({});

  const LTVTypes = useMemo(() => {
    if (data) return data;
    return [];
  }, [data]);

  const handleSubmit = useCallback((data: Record<string, unknown>): void => {
    if (onSubmit) {
      onSubmit(data);
    }
  }, [onSubmit]);

  useEffect(() => {
    /**
     * Run this only on mount
     */
    if (!queryParams.ltv) {
      setQueryParams({ ltv: { id: 1004, label: 'States' } });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderForm = useMemo(() => {
    if (!queryParams.ltv) {
      return <Skeleton variant='rectangular' height={35} />;
    }
    if (!LTVTypes.length) return <></>;
    return (
      <FormV2
        withQueryParams
        submitOnChange
        button={false}
        onSubmit={handleSubmit}
        gridSpacing={1}
        gridItemBreakpoints={[
          { xs: 6 },
          { xs: 3 },
          { xs: 3 },
        ]}
        debounceTime={300}
        style={{ width: '250px', height: '35px' }}
        items={[
          {
            component: Autocomplete,
            name: 'ltv',
            props: {
              id: 'ltv',
              label: 'Choose trait',
              defaultValue: queryParams.ltv,
              disableClearable: true,
              disabled: isLoading || ltvGroupLoading,
              popupIcon: isLoading || ltvGroupLoading ? <Spinner /> : undefined,
              sx: {
                '.MuiAutocomplete-endAdornment': {
                  position: 'absolute',
                  top: 'auto',
                }
              },
              options: LTVTypes.map((item) => ({ id: item.id, label: startCase(item.name) })),
            },
            paramType: JsonParam,
            grid: {
              xs: 12
            }
          },
        ]}
      />
    );

  }, [LTVTypes, handleSubmit, isLoading, ltvGroupLoading, queryParams.ltv]);

  return renderForm;
};
