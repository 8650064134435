/**
 * useDepartments
 * Fetches all departments
 */

import { useGetDepartmentsQuery } from 'api/crudGraphQL/departments/getDepartments';
import { Department } from 'features/entitiesRedux/models/department/department';
import { Projection } from '../../api/entityGraphQL/types';

type Type = {
  loading: boolean;
  error: string;
  departments: Department[];
};

type UseDepartmentArgs = { projection?: Projection };

export const useDepartments = ({
  projection,
}: UseDepartmentArgs = {}): Type => {
  const { data: departments = [], isLoading, error } = useGetDepartmentsQuery({
    projection: projection ?? {
      name: true,
      id: true
    }
  });

  return {
    departments,
    loading: isLoading,
    error: error?.message ?? 'Unknown error'
  };
};

export default useDepartments;
