/**
 * SectionBuilder -> Fields -> TextareaField
 */

import React, { FC } from 'react';
import classNames from 'classnames';
import { TextEditor, FormItem } from 'components';
import { FieldProps } from '../../types';
import css from './TextareaField.module.scss';

const TextareaField: FC<FieldProps> = ({ name, placeholder }) => {
  /** expand text editor height for certain fields */
  const extendHeight = name === 'question_acquire_leads' || name === 'question_acquire_customers';

  return (
    <FormItem name={name}>
      <TextEditor className={classNames(css.root, { [css.extendHeight]: extendHeight })} placeholder={placeholder} />
    </FormItem>
  );
};

export default TextareaField;
