/**
 * SectionBuilder -> Fields -> NotFound
 * 
 * This section is rendered when we don't 
 * have a matching field component for the question type key
 */

import React, { FC } from 'react';
import { Alert } from 'components';
import { FieldProps } from '../../types';

const NotFound: FC<FieldProps> = (props) => {
  return (
    <Alert 
      type="error" 
      message={
        <>
          <strong>No field component found</strong>
          <br />
          <pre>{JSON.stringify(props, null, 4)}</pre>
        </>
      } 
    />
  );
};

export default NotFound;
