/**
 * SectionBuilder -> Fields -> LifetimeProfitMarginAfterAcquisitionCosts
 */

import React, { FC, useEffect } from 'react';
import { FormItem, Input } from 'components';
import { MagicOutlined } from '../../../../../../../../../components/Icon';
import { FieldProps } from '../../../types';
import css from '../../outputs_shared.module.scss';

const LifetimeProfitMarginAfterAcquisitionCosts: FC<FieldProps> = ({ name, form }) => {
  // get form values for calculation
  const currentAcquisitionCost = form.getFieldValue('question_current_acquisition_cost');
  const calculatedLTV = form.getFieldValue('output_ltv');
  const blendedProfitMargin = form.getFieldValue('question_blended_margin_ecomm');

  const calculateOutput = () => {
    // check if both values are truthy
    if (!!currentAcquisitionCost && !!calculatedLTV && !!blendedProfitMargin) {
      // remove all non-numeric characters and cast as a number
      const blendedMargin = typeof blendedProfitMargin === 'number'
        ? blendedProfitMargin
        : parseInt(blendedProfitMargin?.replace(/[^0-9]+/g, ''));
      const ltv = typeof calculatedLTV === 'number'
        ? calculatedLTV
        : parseInt(calculatedLTV?.replace(/[^0-9]+/g, ''));
      // calculate output
      const output = (((ltv * (blendedMargin / 100)) - currentAcquisitionCost) / ltv).toFixed(1) + '%';
      return form.setFieldsValue({[name]: output});
    }
  };

  useEffect(() => {
    calculateOutput();
  }, [form, currentAcquisitionCost, calculatedLTV, blendedProfitMargin]);

  return (
    <FormItem
      name={name}
      className={css.root}
      extra={
        <div className={css.extra}>Lifetime Profit Margin After Acquisition Costs = ((LTV * Blended profit margin) - Blended CAC) / LTV</div>
      }
    >
      <Input 
        addonAfter={
          <span role="img" className="anticon">
            <MagicOutlined />
          </span>
        }
        readOnly
      />
    </FormItem>
  );
};

export default LifetimeProfitMarginAfterAcquisitionCosts;
