export enum ScoreLabel {
  unknown = 'unknown',
  bad = 'bad',
  ok = 'ok',
  good = 'good',
  great = 'great',
}

export const scoreToLabel = ({
  score,
  max,
  isNovaTalent,
}: {
  score?: number | string;
  max?: number;
  isNovaTalent?: boolean;
}) => {
  if (!score || !max || typeof score !== 'number') return ScoreLabel.unknown;

  const percent = score / max;

  // need to use different coloring for nova Talent
  if (isNovaTalent) {
    if (percent > 0.5) return ScoreLabel.great;
    if (percent <= 0.5) return ScoreLabel.good;
  }

  if (percent >= 0.67) return ScoreLabel.great;
  if (percent >= 0.34) return ScoreLabel.good;
  return ScoreLabel.ok;
};
