/**
 * Audits -> DiscoveryForm
 */

import React, { FC, useState, useEffect, ReactElement, useRef } from 'react';
import { Form as AntdForm, message } from 'antd';
import { Audit } from 'features/entitiesRedux';
import { ScrollForm, Form } from 'components';
import { SectionBuilder } from './components';
import useInitialValues, { DiscoveryFormValues } from './useInitialValues';

const AUTOSAVE_PERSIST_KEY = 'discovery-form-autosave';

const getChangedValuesByKeys: (changedKeys: string[], values: DiscoveryFormValues) => DiscoveryFormValues = (changedKeys = [], values) => {
  return changedKeys.reduce((acc, key) => Object.assign(acc, { [key]: values[key] }), {});
};

type Props = {
  audit: Audit;
  businessTypeId: number;
  loading: boolean;
  onSave: (values: DiscoveryFormValues) => void;
  renderStart?: ReactElement;
  saving: boolean;
  extra?: ReactElement;
}

const DiscoveryForm: FC<Props> = ({ audit, businessTypeId, onSave, loading, renderStart, saving, extra }) => {
  const previousSaving = useRef(false);
  const changedValuesKeys = useRef<string[]>([]);
  const [dirty, setDirty] = useState<boolean>(false);
  const autosaveTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const initialValues = useInitialValues(audit);
  const [form] = AntdForm.useForm();
  const [autosaveEnabled, setAutosaveEnabled] = useState<boolean>(() => {
    const persisted = localStorage.getItem(AUTOSAVE_PERSIST_KEY);
    return typeof persisted === 'string' ? JSON.parse(persisted) : true;
  });

  // Re-initiate some values when the initial values change but only after saving is complete
  useEffect(() => {
    if (previousSaving.current && !saving) {
      form.setFieldsValue({
        contacts: initialValues.contacts
      });
    }

    previousSaving.current = saving;
  }, [initialValues, saving]);

  // Make sure we clear the autosave timeout on unmount
  useEffect(() => {
    return () => {
      if (autosaveTimeout.current) {
        clearTimeout(autosaveTimeout.current);
      }
    };
  }, []);

  /**
   * Prevents the form initiating before the initial values are built from the passed audit
   */
  if (!audit || !initialValues || loading) {
    return <ScrollForm skeleton />;
  }

  /**
   * Enable/disable autosave
   */
  const handleAutosaveToggle = () => {
    setAutosaveEnabled(prev => {
      const enabled = !prev;
      localStorage.setItem(AUTOSAVE_PERSIST_KEY, JSON.stringify(enabled));
      return enabled;
    });
  };

  /**
   * Submit form
   */
  const handleSubmit = async (values: DiscoveryFormValues) => {
    // Prevent a scheduled autosave from running
    if (autosaveTimeout.current) {
      clearTimeout(autosaveTimeout.current);
    }

    // Get the changed values from the changed value keys we track in handleValuesChange
    const changedValues = getChangedValuesByKeys(changedValuesKeys.current, values);

    // Only update the changed values
    await onSave(changedValues);

    // Reset changed value keys after save
    changedValuesKeys.current = [];

    setDirty(false);
  };

  /**
   * On fields change / autosave
   */
  const handleValuesChange = async (changedValues: DiscoveryFormValues) => {
    setDirty(true);

    // Get the keys of the recently updated values
    // (And merge with other changed value keys if any)
    // We use these keys to build the updated values object later
    changedValuesKeys.current = [...Object.keys(changedValues), ...changedValuesKeys.current];

    // Clear delay to avoid autosaving until the user has finished updating
    if (autosaveTimeout.current) {
      clearTimeout(autosaveTimeout.current);
    }

    // Only autosave if it's enabled and we are not currently saving data
    if (autosaveEnabled && !saving) {
      autosaveTimeout.current = setTimeout(form.submit, 3000);
    }
  };

  /**
   * Submit failed
   */
  const handleSubmitFailed = () => {
    message.error('Validation failed. Please check your inputs and try again.');
  };

  /**
   * Filter discovery topics down to those containing active questions
   */
  const discoveryTopics = audit?.discovery?.topics?.filter((topic) => {
    return topic?.questions?.filter((q: any) => q.is_active === 'yes')?.length;
  });

  return (
    <Form
      layout="vertical"
      name="discovery-form"
      initialValues={initialValues}
      form={form}
      onFinish={handleSubmit}
      scrollToFirstError={{
        block: 'center',
        inline: 'center'
      }}
      onValuesChange={handleValuesChange}
      onFinishFailed={handleSubmitFailed}
    >
      <ScrollForm
        id="discovery-form"
        skeleton={loading}
        saving={saving}
        resetId={businessTypeId}
        saveButtonProps={{
          disabled: !dirty,
        }}
        autosaveEnabled={autosaveEnabled}
        renderStart={renderStart}
        extra={extra}
        onAutosaveToggle={handleAutosaveToggle}
      >
        { !loading && Array.isArray(audit?.discovery?.topics) ? (
          <SectionBuilder
            form={form}
            topics={discoveryTopics}
            onValuesChange={handleValuesChange}
          />
        ) : null }
      </ScrollForm>
    </Form>
  );
};

export default DiscoveryForm;
