/**
 * Library -> Fields
 */

import React from 'react';
import { Container } from '@sprnova/nebula';
import { PageHero } from 'layouts/components';
import { List } from './components';

const Fields = (): JSX.Element => (
  <>
    <PageHero title='Rapport and Goals – Fields' />
    <Container hasVerticalPadding>
      <List
        fields={[
          {
            name: 'Re-sign %',
            url: 'resign'
          },
          {
            name: 'Rapport',
            url: 'rapport'
          },
          {
            name: 'Pacing to Goal',
            url: 'pacing-to-goal'
          },
          {
            name: 'Sales Division',
            url: 'sales-division'
          },
        ]}
      />
    </Container>
  </>
);

export default Fields;
