import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Action, Resource } from 'api/accessControl';
import { Field } from 'features/entitiesRedux/models/field';
import { AccessControl, Button, Table } from 'components';
import css from './List.module.scss';

type Props = {
  fields: Field[] | any;
  loading?: boolean;
}
const FieldsList = ({ fields, loading }: Props): JSX.Element => {
  const renderActions = (value: string, field: Field | any) => (
    <div className={css.actions}>
      <AccessControl
        action={[Action.update]}
        resource={Resource.department}
        showWarning={false}
      >
        <Link to={`/library/fields/${field.url}`}>
          <Button type='text' icon={<EditOutlined />} />
        </Link>
      </AccessControl>
    </div>
  );

  const renderName = (name: string, row: any) => <Link to={`/library/fields/${row.url}`}>{name}</Link>;

  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: renderName
    },
    {
      title: '',
      key: 'actions',
      dataIndex: 'actions',
      width: 65,
      render: renderActions
    }
  ];

  return (
    <Table
      loading={loading}
      rowKey='id'
      columns={columns}
      dataSource={fields}
      pagination={false}
    />
  );
};

export default memo(FieldsList);
