/**
 * SectionBuilder -> Fields -> DateField
 */

import React, { FC } from 'react';
import { DatePicker, FormItem } from 'components';
import { FieldProps } from '../../types';
import css from './DateField.module.scss';

const DateField: FC<FieldProps> = ({ name }) => {
  return (
    <FormItem name={name}>
      <DatePicker className={css.root} format="MM/DD/YYYY" />
    </FormItem>
  );
};

export default DateField;
