import React, { useCallback } from 'react';
import { List, ListItemText, Popper, Typography} from '@mui/material';
import { styled } from '@sprnova/nebula';
import Info from 'components/nebula/icons/Info';
import { Tag } from 'features/intelligence/components/library-components/Tag/Tag';
import { WidgetSubCard } from './WidgetSubcard';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';

const benchmarkColors: { [key: string]: string } = {
  'on track': '#0288D1',
  'above': '#2E7D32',
  'below': '#ED6C02',
};

//styling
const Primary = styled('div')(() => ({
  color: '#000000',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '19px',
  display: 'flex',
}));

const Secondary = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: '10px',
  color: '#000000',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16px',
}));

const MatchPercentage = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Inter',
  fontStyle: 'italic',
  color: '#575757',
  lineHeight: '22px',
  position: 'absolute',
  right: '0',
  paddingRight: '16px',
}));

type InsightListProps = {
  data?: any;
  height?: number;
}

type StatusTabType = {
  status: 'on track' | 'above' | 'below';
  color?: 'blue' | 'green' | 'orange';
  width: string;
}

const InsightList = ({data=[], height}: InsightListProps): JSX.Element => {

  //calculate and setup state and color for each insight statistics value

  const mixpanel = useMixpanel();

  const handleMixpanelHoverInfo = useCallback((row?: any) => {
    try {
      const { label, definition } = row ?? {};
      const options = { label, definition };
      if (label && definition) {
        console.log('🛤 Track: Customer Insights Hover Info', { options });
        if (mixpanel?.track) {
          mixpanel.track('Customer Insights Hover Info', { options });
        }
      }
    } catch (error) {
      console.error('Error sending mixpanel event', error);
    }
  }, [mixpanel]);

  const setStatus = useCallback((value: number, benchmark: number): StatusTabType => {
    if (Math.abs(value - benchmark) <= 5) {
      return { status: 'on track', color: 'blue', width: '83px' };
    } else if (value - benchmark > 5) {
      return { status: 'above', color: 'green', width: '63px' };
    } else {
      return { status: 'below', color: 'orange', width: '63px' };
    }
  }, []);

  //variables and functions below are for the info icon popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [activePopper, setActivePopper] = React.useState<string | number>();

  const handlePopoverOpen = useCallback((event: React.MouseEvent<HTMLElement>, row?: any) => {
    setAnchorEl(event.currentTarget);
    setActivePopper(Number(event.currentTarget.id));
    handleMixpanelHoverInfo(row);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
    setActivePopper(undefined);
  }, []);

  const renderContent = useCallback((children: JSX.Element, statusTab: StatusTabType) => {
    return (
      <span>
        Compared to&nbsp;
        <span
          style={{
            color: benchmarkColors[statusTab.status],
            display: 'inline-flex',
            alignItems: 'center',
            margin: 0,
            fontWeight: 600
          }}
        >
          {children}
        </span>
        &nbsp;on average based on competitors in your industry.
      </span>
    );
  }, []);

  return (
    <List sx={{
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      bgcolor: 'background.paper',
      overflow: 'hidden',
      padding: '0px',
      borderRadius: '8px',
    }}
    >
      {data?.map((row: any, index: number) => {
        const widgetValueType: string = row?.format;
        const benchmark: number = row?.benchmark_percentage || row?.ltv_benchmark;
        const statistics: number = row?.percentage || row?.ltv;
        const statusTab = setStatus(statistics, benchmark);

        return (
          <WidgetSubCard
            key={`insightsList-${row}-${index}`}
            index={index+1}
            statistics={statistics}
            valueType={widgetValueType}
            color={benchmarkColors[statusTab.status]}
            height='82px'
          >
            <ListItemText
              primary={
                <Primary>
                  {row?.display}
                  {row?.definition &&
                  <>
                    <div
                      onMouseEnter={(event) => handlePopoverOpen(event, row)}
                      onMouseLeave={handlePopoverClose}
                      id={row?.id}
                      style={{ display: 'inline-block', cursor: 'pointer' }}
                    >
                      <div style={{ marginLeft: '10px' }}>{<Info />}</div>
                    </div>
                    <Popper
                      placement='top'
                      key={row?.id}
                      open={activePopper === row?.id}
                      anchorEl={anchorEl}
                      nonce={undefined}
                      sx={{
                        zIndex: 90,
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                        borderRadius: '3px',
                        padding: '8px 16px'
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '22px',
                          maxWidth: '720px'
                        }}
                      >
                        {row?.definition}
                      </Typography>
                    </Popper>
                  </>
                  }
                  {row?.match_percentage &&
                    <MatchPercentage>
                      Customer Count: {(row?.match_percentage * 100).toFixed(0)}%
                    </MatchPercentage>
                  }
                </Primary>
              }
              secondary={
                <Secondary>
                  <Tag
                    color={statusTab.color}
                    tagName={statusTab.status}
                    width={statusTab.width}
                    margin='0 8px 0 0'
                  />
                  {(() => {
                    switch (widgetValueType) {
                      case '$':
                        return renderContent(<>{widgetValueType}{benchmark}</>, statusTab);
                      case '#':
                        return renderContent(<>{benchmark}yrs</>, statusTab);
                      default:
                        return renderContent(<>{benchmark}{widgetValueType}</>, statusTab);
                    }
                  })()}
                </Secondary>
              }
              secondaryTypographyProps={{ component: 'div' }}
            />
          </WidgetSubCard>

        );
      })}
    </List>
  );
};

export default InsightList;
