/**
 * AddTasksModal -> SelectionList -> Item -> ServiceItem
 */

import React, { FC, useMemo, memo, useState } from 'react';
import { PlusSquareTwoTone, MinusSquareTwoTone } from '@ant-design/icons';
import classnames from 'classnames';
import { Checkbox } from 'components/Checkbox';
import { Service, Task } from 'features/entitiesRedux';
import { PricingVersion } from 'features/library/constants';
import TaskItem from '../TaskItem';
import css from './ServiceItem.module.scss';

type Props = {
  service: Service;
  onSelect: (tasks: Task[]) => void;
  onUnselect: (tasks: Task[]) => void;
  selectedIds: number[];
  currentId?: number;
};

const ServiceItem: FC<Props> = ({
  service,
  onSelect,
  onUnselect,
  selectedIds,
  currentId,
}) => {
  const tasks = service?.tasks.filter((task: Task) => task.pricing_version?.slug === PricingVersion.HOURLY && task.id !== currentId);
  const [expanded, setExpanded] = useState(false);
  const selectedTasks = useMemo(
    () => tasks?.filter((task) => selectedIds.includes(task.id)),
    [selectedIds, tasks]
  );
  const allSelected = !!tasks.length && selectedTasks.length === tasks.length;

  const handleToggleAll = () => {
    if (allSelected) {
      onUnselect(tasks);
    } else {
      onSelect(tasks);
      setExpanded(true);
    }
  };

  const toggleListVisibility = () => setExpanded(!expanded);

  const renderTask = (task: Task) => {
    const checked = selectedIds.includes(task.id);

    const handleToggle = () => {
      if (checked) {
        onUnselect([task]);
      } else {
        onSelect([task]);
      }
    };

    return (
      <TaskItem
        key={task.id}
        task={task}
        onToggle={handleToggle}
        checked={checked}
      />
    );
  };

  return (
    <li>
      {expanded ? (
        <MinusSquareTwoTone
          className={!tasks.length ? css.hideExpandButton : undefined}
          onClick={toggleListVisibility}
        />
      ) : (
        <PlusSquareTwoTone
          className={!tasks.length ? css.hideExpandButton : undefined}
          onClick={toggleListVisibility}
        />
      )}
      <Checkbox
        className={classnames(css.selectAllCheckbox, {
          [css.hasSelectedTasks]: !!selectedTasks.length,
        })}
        checked={allSelected}
        onChange={handleToggleAll}
        disabled={!tasks.length}
      >
        {service.name}{' '}
        {selectedTasks.length ? `(${selectedTasks.length})` : null}
      </Checkbox>
      {expanded && <ul className={css.tasks}>{tasks.map(renderTask)}</ul>}
    </li>
  );
};

export default memo(ServiceItem);
