import React, { memo, useMemo } from 'react';
import { Grid, GridProps, Legend as NebulaLegend, Typography, theme } from '@sprnova/nebula';
import { PacingData } from 'api/crudGraphQL/scoreboards/types';
import {
  CartesianGrid,
  Legend,
  Line,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatScoreboardsDate, formatYAxisTick, hexToRgba } from '../../../components/utils';
import CustomToolTip from '../CustomToolTip';

type MonthlyChartProps = {
  monthlyData: PacingData[];
  lastUpdated?: string | null;
  format?: string;
  metricName?: string;
};

const commonGridProps: GridProps = {
  display: 'flex',
  alignItems: 'center',
};

const MonthlyChart = ({ monthlyData, lastUpdated, format, metricName }: MonthlyChartProps): JSX.Element => {
  const ActualColor = theme.variables?.colors.data_visualization.sequential[90];
  const EstPaceColor= hexToRgba(ActualColor, 0.4);
  const GoalColor = theme.variables?.colors.data_visualization.sequential[50];
  const CartesianGridStroke  = theme.variables.colors.neutrals.ghost[400];
  const HeaderFooterColor = theme.variables?.colors.primary.charcoal[200];


  const formattedMonthlyData = useMemo(() => {
    return monthlyData?.map(item => ({
      ...item,
      formattedActual: item.actual === 0 ? null : item.actual,
      formattedPace: item.pace === item.actual
        ? item.pace :
        item.pace === 0 || (item.pace && item.actual !== 0)
          ? null : item.pace,
      formattedDate: item.date ? formatScoreboardsDate(item.date, 'axis_value', 'MonthlyChart') : 'no date'
    }));
  }, [monthlyData]);

  const renderLegend = useMemo((): JSX.Element => {
    return (
      <Grid
        container
        display='flex'
        flexDirection='row'
        wrap='nowrap'
        justifyContent='end'
      >
        <NebulaLegend.Group direction="horizontal">
          <NebulaLegend
            variant="line"
            color={theme.variables?.colors.data_visualization.sequential[90]}
            label="Actual"
          />
          <NebulaLegend
            variant="line"
            color={theme.variables?.colors.data_visualization.sequential[90]}
            label="Pace"
            sx={{
              '& > div:first-of-type': {
                opacity: 0.5,
              }
            }}
          />
          <NebulaLegend
            variant="line"
            color={theme.variables?.colors.data_visualization.sequential[50]}
            label="Goal"
          />
        </NebulaLegend.Group>
      </Grid>
    );
  }, []);

  const renderMonthlyChartFooter = useMemo(() => {
    if (!lastUpdated) return null;
    return (
      <Grid display='flex' justifyContent='start' width='100%'>
        <Typography variant='caption' fontStyle='italic' color={HeaderFooterColor} paddingTop='40px'>
          Last updated: {formatScoreboardsDate(lastUpdated, 'timestamp')}
        </Typography>
      </Grid>
    );
  }, [HeaderFooterColor, lastUpdated]);

  return (
    <Grid direction='column' height='600px' width='100%' padding='24px 0' {...commonGridProps}>
      <ResponsiveContainer width='100%' height='100%'>
        <ComposedChart
          data={formattedMonthlyData}
          margin={{
            top: 5,
            right: 30,
            left: 30,
            bottom: 40,
          }}
        >
          <CartesianGrid vertical={false} stroke={CartesianGridStroke} />
          <XAxis
            tickLine={false}
            axisLine={false}
            dataKey='formattedDate'
            interval={Math.floor(formattedMonthlyData?.length / 8)}
          >
          </XAxis>
          <YAxis
            tickLine={false}
            axisLine={false}
            label={{ value: metricName, angle: -90, position: 'left', offset: 15 }}
            tickFormatter={formatYAxisTick}
          >
          </YAxis>
          <Tooltip content={<CustomToolTip metricName={metricName} format={format}/>} />
          <Legend
            verticalAlign='top'
            content={renderLegend}
            height={50}
            align='right'
          />
          <Line
            type='monotone'
            dataKey='goal'
            dot={false}
            stroke={GoalColor}
            strokeWidth={2}
            isAnimationActive={true}
          />
          <Line
            type='monotone'
            dataKey='formattedActual'
            dot={false}
            stroke={ActualColor}
            strokeWidth={2}
            isAnimationActive={true}
          />
          <Line
            type='monotone'
            dataKey='formattedPace'
            dot={false}
            stroke={EstPaceColor}
            strokeWidth={2}
            isAnimationActive={true}
            strokeDasharray='5 5'
          />
        </ComposedChart>
      </ResponsiveContainer>
      {renderMonthlyChartFooter}
    </Grid>
  );
};

export default memo(MonthlyChart);

