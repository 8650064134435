/**
 * SectionBuilder -> Fields -> AnnualCACToLTVRatio
 */

import React, { FC, useEffect } from 'react';
import { FormItem, Input } from 'components';
import { MagicOutlined } from '../../../../../../../../../components/Icon';
import { FieldProps } from '../../../types';
import css from '../../outputs_shared.module.scss';

const AnnualCACToLTVRatio: FC<FieldProps> = ({ name, form }) => {
  // get form values for calculation
  const currentAcquisitionCost = form.getFieldValue('question_current_acquisition_cost');
  const autoCalculatedAnnualLTV = form.getFieldValue('output_annual_ltv');

  const calculateOutput = () => {
    // check if both values are truthy
    if (!!currentAcquisitionCost && !!autoCalculatedAnnualLTV) {
      // remove all non-numeric characters and cast as a number
      const annualLTV = typeof autoCalculatedAnnualLTV === 'number'
        ? autoCalculatedAnnualLTV
        : parseInt(autoCalculatedAnnualLTV?.replace(/[^0-9]+/g, ''));
      // calculate output
      const output = (annualLTV / currentAcquisitionCost).toFixed(1) + 'X';
      return form.setFieldsValue({[name]: output});
    }
  };

  useEffect(() => {
    calculateOutput();
  }, [form, currentAcquisitionCost, autoCalculatedAnnualLTV]);

  return (
    <FormItem
      name={name}
      className={css.root}
      extra={
        <div className={css.extra}>Annual CAC to LTV Ratio = Annual LTV / CAC</div>
      }
    >
      <Input 
        addonAfter={
          <span role="img" className="anticon">
            <MagicOutlined />
          </span>
        }
        readOnly
      />
    </FormItem>
  );
};

export default AnnualCACToLTVRatio;
