/**
 * Get clients
 */

import { Pagination, Projection } from 'api/entityGraphQL/types';
import { Client } from 'features/entitiesRedux';
import tagTypes from './tagTypes';
import { baseApi, cleanArguments, paginationProjection } from '..';

export type GetClientsQuery = {
  limit?: number;
  page?: number;
  sort?: string[];
  id?: number;
  name?: string;
  author_id?: number;
  account_manager_id?: number;
  account_status_id?: number;
  account_status_ids?: number[];
  account_stage_ids?: number[];
  executive_sponsor_id?: number;
  group_director_id?: number;
  strategist_id?: number;
  business_type_id?: number;
  business_type_ids?: number[];
  timezone_id?: number;
  industry_ids?: number[];
  salesforce_client_id?: string;
  salesforce_client_name?: string;
  status?: 'prospect' | 'audit' | 'strategy' | 'reporting' | 'archive';
  client_ids?: number[];
  tier_id?: number;
  tier_ids?: number[];
  available_for_horizon_forecast?: number;
  available_for_cohort_analysis? : number;
  is_finance_active?: number;
  is_dataq_active?: number;
  has_consumer_insights?: number;
  industry?: string;
  has_playbook_campaigns?: number;
  is_onboarded?: boolean;
  projection: Projection;
};

export type GetClientsResult = Pagination & {
  data: Client[]
};

export const { useGetClientsQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getClients: build.query<GetClientsResult, GetClientsQuery>({
      transformResponse: (response: { clients: GetClientsResult }) => response.clients,
      providesTags: (result) => {
        const { data } = result ?? { data: [] };

        return [
          tagTypes.CLIENTS,
          ...data.map((client: any) => ({
            id: client.id,
            type: tagTypes.CLIENT
          }))
        ];
      },
      query: ({
        projection = { id: true },
        page = 1,
        limit = 10,
        sort,
        name,
        ...args
      }) => {
        const __args: Partial<GetClientsQuery> = {
          page,
          limit,
          sort,
          ...args,
        };

        if (name) {
          __args.name = `*${name}*`;
        }

        return {
          body: {
            query: {
              clients: {
                __args: cleanArguments(__args),
                data: projection,
                ...paginationProjection
              }
            }
          }
        };
      }
    })
  })
});
