import { Projection } from 'api/entityGraphQL';
import { FusionIntegration } from 'features/entitiesRedux';
import { basePublicApi } from '../basePublicApi';
import { cleanArguments } from 'api/crudGraphQL/utils';
import tagTypes from './tagTypes';

export type GetClientFusionIntegrationsPublicQuery = {
  clientId: number;
  dataSourceGroupName?: string;
  projection?: Projection;
};

export type GetClientFusionIntegrationsPublicResult = FusionIntegration[];

/**
 * Hook to fetch client fusion integration details.
 *
 * This hook is designed to be used in the public context, where the client ID is known.
 * It queries for integration details associated with a specific client ID.
 * It leverages the baseApi to execute a GraphQL query, fetching information such as
 * the classic integration ID, warehouse source, and data source details.
 *
 * The response is transformed to directly return the `nova_fusion_integrations` part
 * of the data, ensuring the hook returns the structure expected by the frontend.
 *
 * It also specifies cache tags for efficient invalidation and re-fetching strategies,
 * tagging the fetched data with the client's ID.
 *
 * @param {GetClientFusionIntegrationsPublicQuery} query - The query parameters, including the client ID.
 * @returns A query result object containing `data`, `error`, `isLoading`, and other properties
 *          to manage the query state.
 */
export const { useGetClientFusionIntegrationsPublicQuery } = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    getClientFusionIntegrationsPublic: build.query<GetClientFusionIntegrationsPublicResult, GetClientFusionIntegrationsPublicQuery>({
      transformResponse: (response: { nova_fusion_integrations: GetClientFusionIntegrationsPublicResult } ) => response?.nova_fusion_integrations ?? [],
      providesTags: (result) => result ? [{ type: tagTypes.CLIENT, id: result[0]?.client_id }] : [], //empty array when there's no result
      query: ({
        clientId,
        dataSourceGroupName,
        projection = {
          id: false,
          classic_integration_id: true,
          client_id: true,
          warehouse_source: true,
          data_source: {
            id: true,
            name: true,
            slug: true,
          },
        },
      }) => ({
        body: {
          query: {
            nova_fusion_integrations: {
              __args: {
                client_id: clientId,
                ...cleanArguments({data_source_group_name: dataSourceGroupName })
              },
              ...projection
            }
          }
        }
      })
    }),
  }),
});
