export const removeExtension = (url: string): string => {
  // Regular expression to match common image file extensions
  const imageExtensionRegex = /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i;

  // Check if the url ends with an image extension
  if (imageExtensionRegex.test(url)) {
    // Remove the extension
    return url.replace(imageExtensionRegex, '');
  } else {
    // Return the original url if it's not an image
    return url;
  }
};
