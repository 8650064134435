/**
 * SectionBuilder -> Fields -> RetentionTimeFrame
 */

import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import { formatDuration, intervalToDuration } from 'date-fns';
import { FormItem, Input } from 'components';
import { MagicOutlined } from '../../../../../../../../../components/Icon';
import { FieldProps } from '../../../types';
import css from '../../outputs_shared.module.scss';

const RetentionTimeFrame: FC<FieldProps> = ({ name, form }) => {
  // get form values for calculation
  const numRepeatPurchases = form.getFieldValue('question_num_customer_repeat_purchases');
  const timeBetweenPurchases = form.getFieldValue('question_time_between_purchases');

  const calculateOutput = () => {
    // check if both values are truthy
    if (!!numRepeatPurchases && !!timeBetweenPurchases) {
      // calculate retention time frame
      const date1 = new Date();
      const date2 = new Date();
      date2.setDate(date2.getDate() + (numRepeatPurchases * timeBetweenPurchases));
      const interval = intervalToDuration({ start: date1, end: date2 });
      const output = formatDuration(interval, { delimiter: ', ' });

      return form.setFieldsValue({[name]: output});
    }
  };

  useEffect(() => {
    calculateOutput();
  }, [form, numRepeatPurchases, timeBetweenPurchases]);

  return (
    <FormItem
      name={name}
      className={classNames(css.root, css.retentionTimeFrame)}
      extra={
        <div className={css.extra}>Retention Time Frame = Times a customer purchases within their lifetime with you * Time between purchases</div>
      }
    >
      <Input 
        addonAfter={
          <span role="img" className="anticon">
            <MagicOutlined />
          </span>
        }
        readOnly
      />
    </FormItem>
  );
};

export default RetentionTimeFrame;
