import React, { ReactNode, useMemo } from 'react';
import { Action, Resource } from 'api/accessControl';
import { Role } from 'api/accessControl/Role';
import { PulseIcon } from 'components/Icon/svg/PulseIcon';
import {TalentAllCyclesLightIcon} from 'components/Icon/svg/TalentAllCyclesLightIcon';
import {TalentHedgehogLightIcon} from 'components/Icon/svg/TalentHedgehogLightIcon';
import {TalentMyReviewLightIcon} from 'components/Icon/svg/TalentMyReviewLightIcon';
import {TalentReviewDashboardLightIcon} from 'components/Icon/svg/TalentReviewDashboardLightIcon';
import {TalentReviewLogLightIcon} from 'components/Icon/svg/TalentReviewLogLightIcon';
import {TalentVital5LightIcon} from 'components/Icon/svg/TalentVital5LightIcon';
import { useAccessControl, useAccount } from 'features/global';
import { useRoleAccess } from 'features/global/hooks/useRoleAccess';
import { TalentMenuItems } from 'features/talent/constants';

const TALENT_BASE_PATH = '/talent';

export const useTalentMenuItems = (): {
  id: number;
  name: string;
  path: string;
  is_authorized: boolean;
  illustration: ReactNode;
}[] => {
  const isTalentSuperAdmin = useRoleAccess([Role.talentSuperAdmin], {talentOnly: true});
  const { account } = useAccount();
  const { can } = useAccessControl();
  const canReadTalentItem = can(Action.read, Resource.talent, account?.id);

  const items = useMemo(()=> [
    {
      id: 1002,
      name: TalentMenuItems.MyReviewCycle,
      path: `${TALENT_BASE_PATH}/reviews`,
      is_authorized: canReadTalentItem,
      illustration: <TalentMyReviewLightIcon />,
    },
    {
      id: 1001,
      name: TalentMenuItems.HedgeHog,
      path: `${TALENT_BASE_PATH}/hedgehog`,
      is_authorized: canReadTalentItem,
      illustration: <TalentHedgehogLightIcon />,
    },
    {
      id: 1003,
      name: TalentMenuItems.Vital5,
      path: `${TALENT_BASE_PATH}/vital-5`,
      is_authorized: canReadTalentItem,
      illustration: <TalentVital5LightIcon />,
    },
    {
      id: 1004,
      name: 'Pulse',
      path: `${TALENT_BASE_PATH}/pulse`,
      is_authorized: canReadTalentItem,
      illustration: <PulseIcon />,
    },
    {
      id: 1005,
      name: TalentMenuItems.ReviewLog,
      path: `${TALENT_BASE_PATH}/review/log`,
      is_authorized: canReadTalentItem,
      illustration: <TalentReviewLogLightIcon />,
    },
    {
      id: 1006,
      name: TalentMenuItems.ReviewDashboard,
      path: `${TALENT_BASE_PATH}/reporting/review`,
      is_authorized: canReadTalentItem,
      illustration: <TalentReviewDashboardLightIcon />,
    },
    {
      id: 1007,
      name: TalentMenuItems.AllCycles,
      path: `${TALENT_BASE_PATH}/cycles`,
      is_authorized: isTalentSuperAdmin,
      illustration: <TalentAllCyclesLightIcon />,
    },
  ],[canReadTalentItem, isTalentSuperAdmin]);

  return items;
};
