
import { Projection } from 'api/entityGraphQL';
import { baseApi, cleanArguments,  } from '..';
import { customerInsightDateRangesDefaultProjection } from './customerInsightDateRangesDefaultProjection';
import tagTypes from './tagTypes';
import { CustomerInsightDateRangesType } from './types';

export type GetCustomerInsightDateRangesQuery = {
  id?: number;
  range?: string;
  projection?: Projection;
}

export type GetCustomerInsightDateRangesResult = CustomerInsightDateRangesType[];

export const { useGetCustomerInsightDateRangesQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCustomerInsightDateRanges: build.query<GetCustomerInsightDateRangesResult, GetCustomerInsightDateRangesQuery>({
      transformResponse: (response: { consumer_insight_date_ranges: GetCustomerInsightDateRangesResult }) => response.consumer_insight_date_ranges,
      providesTags: (result) => {
        return [
          tagTypes.CUSTOMER_INSIGHT_DATE_RANGES,
          ...result?.map((date_range: CustomerInsightDateRangesType) => ({
            id: date_range.id,
            type: tagTypes.CUSTOMER_INSIGHT_DATE_RANGES,
          })) || [],
        ];
      },
      query: ({
        projection = customerInsightDateRangesDefaultProjection,
        id,
        range,
      }) => {
        return {
          body: {
            query: {
              consumer_insight_date_ranges: {
                __args: cleanArguments({
                  id,
                  range,
                }),
                ...projection
              },
            }
          }
        };
      }
    })
  })
});

