import React, { memo } from 'react';
import { Box, Card, CardHeader, Chip } from '@sprnova/nebula';
import css from './SuggestedPrompts.module.scss';

interface SuggestedPromptsProps {
    response?: string;
    suggestions: string[];
    onQuestionSelect: (question: string) => void;
    questionLimitReached?: boolean;
}

const SuggestedPrompts: React.FC<SuggestedPromptsProps> = ({ response, suggestions, onQuestionSelect, questionLimitReached }) => {
  if (suggestions?.length) {
    return (
      <div className={css.root} style={{ width: '100%', paddingTop: 20 }}>
        <Box className={css.suggestionsContainer} mt={'auto'} pb={2}>
          <Card className={css.suggestions} 
            header={
              <CardHeader 
                className={css.suggestionsHeader}
                title={response} 
              />
            }
          >
            {suggestions.map((suggestion, index) => (
              <div key={index}>
                <Chip label={suggestion} clickable onClick={() => onQuestionSelect(String(suggestion))} disabled={questionLimitReached}/>
              </div>
            ))}
          </Card>
        </Box>
      </div>
    );
  }
  else return <></>;
};

export default memo(SuggestedPrompts);
