/**
 * useLeadChannels
 * Fetches all Lead Generation Channel types (lead_channels table)
 */

import { useState, useEffect } from 'react';
import { novaGraphQLClient, Projection } from 'api/entityGraphQL';
import { LeadChannel } from '../../features/entitiesRedux';

type Type = {
  loading: boolean;
  error: string;
  leadChannels: LeadChannel[];
};

type UseLeadChannelsArgs = { projection?: Projection };
export const useChannels = ({
  projection: defaultProjection,
}: UseLeadChannelsArgs = {}): Type => {
  const [leadChannels, setLeadChannels] = useState<LeadChannel[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [projection /* , setProjection */] = useState(defaultProjection);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      setLoading(true);
      try {
        const { lead_channels } = await novaGraphQLClient.fetchLeadChannels({ projection });
        if (!isCancelled) {
          setError('');
          setLeadChannels(lead_channels);
          setLoading(false);
        }
      } catch (error) {
        if (!isCancelled) {
          setLoading(false);
          setError(error?.response?.errors?.[0]?.message || 'Unknown error');
        }
      }
    })();

    return () => {
      isCancelled = true;
    };

  }, [projection]);

  return { leadChannels, loading, error };
};

export default useChannels;
