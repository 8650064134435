import { useState, useEffect /* , useCallback */ } from 'react';
import { useDispatch, useSelector, Selector } from 'react-redux';
import { /* useHistory, */ useParams /* , useLocation */ } from 'react-router';
import { ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';
// import queryString from "query-string";
import { Projection, Pagination } from 'api/entityGraphQL';
import {
  useQueryParams,
  NumberParam,
  StringParam,
  ArrayParam,
} from 'use-query-params';
import { DEFAULT_PAGE, DEFAULT_LIMIT } from 'features/entitiesRedux/constants';
import { selectIsLoading, selectError } from 'features/entitiesRedux/selectors';
// import { isEqual } from "lodash";

type FetchPaginated = (config: {
  filter?: object;
  projection?: Projection;
  pagination?: Pagination;
}) => any & ReturnType<typeof createAsyncThunk>;

type UseEntitiesPaginatedConfig = {
  page?: number;
  limit?: number;
  filter?: object;
  filterParams?: {
    [x: string]: typeof NumberParam | typeof StringParam | typeof ArrayParam;
  };
  projection?: Projection;
  trackHistory?: boolean;
};

/**
 * Fetch and select a list of entities from the redux store
 * @param fetchPaginated
 * @param config
 */
export const useEntitiesPaginated = (
  fetchPaginated: FetchPaginated,
  {
    page: defaultPage = DEFAULT_PAGE,
    limit: defaultLimit = DEFAULT_LIMIT,
    filter: defaultFilter = {},
    projection: defaultProjection,
    filterParams = {},
    trackHistory = true,
  }: UseEntitiesPaginatedConfig = {}
) => {
  /**
   * Decode filter from querystring
   */
  const [queryParams, setQueryParams] = useQueryParams({
    page: NumberParam,
    limit: NumberParam,
    ...filterParams,
  });
  const filterKeys = Object.keys(filterParams);
  const queryParamsFilter = filterKeys.reduce(
    (a, key) => ({
      ...a,
      [key]: (queryParams as { [x: string]: any })[key],
    }),
    {}
  );
  /**
   * Local State (page, filter)
   */
  const [page, setPage] = useState(queryParams.page || defaultPage);
  const [limit, setLimit] = useState(queryParams.limit || defaultLimit);
  const [filter, setFilterState] = useState(
    // Get all queryParams that are passed as filterParams (not reserved keys like pagination's `page` and `limit`)
    filterKeys.length ? queryParamsFilter : defaultFilter
  );
  const [projection /* , setProjection */] = useState(defaultProjection);
  // console.log("👠 page", page, queryParams.page);
  // console.log("👠 limit", limit, queryParams.limit);
  // console.log("👠 queryParamsFilter", queryParamsFilter);

  // const location = useLocation();

  /**
   * Local State (entities, query)
   */
  const [entities, setEntities] = useState({
    clients: [],
    contacts: [],
    departments: [],
    audits: [],
    partners: [],
    users: [],
    strategies: [],
    reports: [],
    surveys: [],
    rules: [],
    forecasts: [],
    alerts: [],
    dashboards: [],
  });
  const [paginationGQL, setPaginationGQL] = useState({
    total: 0,
    per_page: 0,
    current_page: 1,
    from: 0,
    to: 0,
    last_page: 1,
    has_more_pages: false,
  });

  /** Reset page on filter change */
  const setFilter = (values: object) => {
    setPage(1);
    setFilterState(values);

    /* Sync URL params if a filterParams object is provided */
    if (filterKeys.length) {
      setQueryParams(values);
    }
  };

  /** Sync changes from state to querystring */
  // const history = useHistory();
  // useEffect(() => {
  //   console.log("🔰 Sync changes from state to querystring", {
  //     page,
  //     limit,
  //     filter,
  //   });
  //   if (trackHistory) {
  //     const nextParams = { page, limit, ...filter };
  //     console.log("🔰 queryParams", queryParams);
  //     console.log("🔰 nextParams", nextParams);
  //     if (!isEqual(queryParams, nextParams)) {
  //       // console.log("🔰 setQueryParams(nextParams)", nextParams);
  //       // setQueryParams(nextParams);
  //       const url =
  //         history.location.pathname + "?" + queryString.stringify(nextParams);
  //       console.log("🔰 history.push(url)", url);
  //       history.push(url);
  //     }
  //   }
  // }, [page, limit, filter, trackHistory, setQueryParams, history, queryParams]);

  /** Sync filters with query params */
  // useEffect(() => {
  //   setQueryParams(filter);
  // }, [filter, setQueryParams]);

  /**
   * Sync querystrings and pagination config
   */
  // useEffect(() => {
  //   console.log("👠 queryParams", queryParams);
  //   if (trackHistory) {
  //     // const { page, limit /*, ...filters*/ } = queryParams;
  //     // setPage(page || DEFAULT_PAGE);
  //     // setLimit(limit || DEFAULT_LIMIT);
  //     // setFilter(filters);
  //     history.push(
  //       history.location.pathname + "?" + queryString.stringify(queryParams)
  //     );
  //   }
  // }, [history, queryParams, trackHistory, page, limit]);

  /**
   * Event Handlers
   */
  const handleOnPaginationChange = (
    page: number,
    pageSize: number = DEFAULT_LIMIT
  ) => {
    // if (trackHistory) {
    //   setQueryParams({ page, limit: pageSize });
    //   history.push(
    //     history.location.pathname + "?" + queryString.stringify(queryParams)
    //   );
    // }
    // if (trackHistory) {
    //   const path =
    //     history.location.pathname +
    //     "?" +
    //     queryString.stringify({ page, limit: pageSize });
    //   history.push(path);
    // } else {
    setPage(page);
    setLimit(pageSize);
    // }
  };
  const handleOnShowSizeChange = (
    current: number,
    pageSize: number = DEFAULT_LIMIT
  ) => {
    handleOnPaginationChange(current, pageSize);
  };

  /**
   * Redux actions
   */
  const dispatch = useDispatch();
  useEffect(() => {
    let isCancelled = false;

    (async () => {
      // console.log("🏄‍♂️ useEffect", {
      //   dispatch,
      //   fetchPaginated,
      //   filter,
      //   limit,
      //   page,
      //   projection,
      // });
      // console.log("🙅‍♂️ requestCount", requestCount);
      // // console.log("🙅‍♂️ useEntitiesPaginated.updateData.projection", projection);
      // // return;
      // // TODO: troubleshoot repeat request
      // if (requestCount > 3) {
      //   console.error("🙅‍♂️ Exceeded requestCount", requestCount);
      //   return;
      // }
      // setRequestCount(requestCount + 1);
      // console.log("🏄‍♂️ useEntitiesPaginated.projection", projection);
      const resultAction: any = await dispatch(
        fetchPaginated({
          pagination: { page, limit },
          filter,
          projection,
        })
      );

      if (
        (fetchPaginated as ReturnType<typeof createAsyncThunk>).fulfilled.match(
          resultAction
        )
      ) {
        const {
          total,
          per_page,
          current_page,
          from,
          to,
          last_page,
          has_more_pages,
        } = (resultAction as any).payload.pagination;

        if (!isCancelled) {

          setPaginationGQL({
            total,
            per_page,
            current_page,
            from,
            to,
            last_page,
            has_more_pages,
          });
          const {
            clients,
            contacts,
            audits,
            partners,
            users,
            reports,
            surveys,
            rules,
            forecasts,
            alerts,
            dashboards,
            ...entities
          } = (resultAction as any).payload.result;
          setEntities({
            clients,
            partners,
            contacts,
            audits,
            users,
            reports,
            surveys,
            rules,
            forecasts,
            alerts,
            dashboards,
            ...entities,
          });
        }
      }
    })();

    return () => {
      isCancelled = true;
    };
  }, [dispatch, fetchPaginated, filter, limit, page, projection]);
  // useEffect(() => {
  //   updateData();
  // }, [dispatch, updateData]);

  const pagination = {
    current: paginationGQL.current_page,
    pageSize: paginationGQL.per_page,
    showTotal: (total: number, range: [number, number]) =>
      `${range[0]}-${range[1]} of ${total}`,
    total: paginationGQL.total,
    onChange: handleOnPaginationChange,
    onShowSizeChange: handleOnShowSizeChange,
    showSizeChanger: true,
  };
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);

  return {
    entities,
    pagination,
    isLoading,
    error,
    paginationGQL,
    page,
    limit,
    filter,
    setFilter,
  };
};


/**
 * Fetch and select an entity from the redux store using an ID from the path parameter
 * @param selector
 * @param action
 * @param idParameter
 */
export const useEntityByParamIdRefresh = (
  selector: (id: number) => Selector<any, any>,
  fetchAction: ({
    projection,
  }: {
    id: number;
    projection?: Projection;
  }) => ThunkAction<any, any, any, any>,
  { projection: defaultProjection }: { projection?: Projection } = {},
  idParameter = 'id'
) => {
  /**
   * Load variables from path
   */
  const { [idParameter]: idRaw = '' } = useParams<{ [x: string]: any }>();
  const id = Number.parseInt(idRaw);

  /**
   * Local state
   */
  const [projection] = useState(defaultProjection);

  /**
   * Load data from Redux
   */
  // const { isLoading, error } = useSelector(selectAudits);
  const entity = useSelector(selector(id));

  const refreshData = (extraProjection?: Projection) => {
    if (id) {
      const useProjection = extraProjection !== undefined ? extraProjection : projection;
      dispatch(fetchAction({ id, projection: useProjection }));
    }
  };

  /**
   * Fetch Data
   */
  const dispatch = useDispatch();
  useEffect(() => {
    refreshData();
  }, [fetchAction, dispatch, id, projection]);

  return { entity, refreshData };
};


export const useEntityByParamId = (
  selector: (id: number) => Selector<any, any>,
  fetchAction: ({
    projection,
  }: {
    id: number;
    projection?: Projection;
  }) => ThunkAction<any, any, any, any>,
  { projection: defaultProjection }: { projection?: Projection } = {},
  idParameter = 'id'
) => {
  const { entity, refreshData } = useEntityByParamIdRefresh(selector, fetchAction, { projection: defaultProjection }, idParameter);
  return entity;
};
