import React, { memo, useCallback, useMemo, useState } from 'react';
import { ActionMenu, ArrowLeftIcon, ArrowRightIcon, Button, CaretDownIcon, CaretUpIcon, Dropdown, NewWindowIcon, ReloadIcon, Tooltip } from '@sprnova/nebula';
import { useSyncSalesforceToBlueprintMutation } from 'api/crudGraphQL/strategies/additional/salesforce/syncSalesforceToBlueprint';
import { Strategy } from 'features/entitiesRedux';
import { notification } from 'components';

type SalesforceMenuProps = {
  blueprint: Strategy;
  className?: string;
  refetch: () => void;
  refetchBlueprintDataToSync: () => void;
  setOpenSyncModal: React.DispatchWithoutAction;
};
const SalesforceMenu = ({ blueprint, className, refetch, refetchBlueprintDataToSync, setOpenSyncModal }: SalesforceMenuProps): JSX.Element => {
  const { id, salesforce_is_primary, sync_to_salesforce } = blueprint;
  const [syncSalesforceToBlueprint] = useSyncSalesforceToBlueprintMutation();
  const [isPullingFromSalesforce, setIsPullingFromSalesforce] = useState<boolean>(false);

  const handleSuccessPull = useCallback((): void => {
    setIsPullingFromSalesforce(false);
    notification.success({
      message: 'Data pulled from Salesforce',
    });
    refetch();
  }, [refetch]);

  const handleErrorPull = (): void => {
    setIsPullingFromSalesforce(false);
    notification.error({
      message: 'Unable to pull data from Salesforce. Please try again.',
    });
  };

  const handleSyncWithSalesforce = useCallback(async () => {
    setIsPullingFromSalesforce(true);
    try {
      await syncSalesforceToBlueprint({ id }).unwrap();
      handleSuccessPull();
    } catch (error) {
      console.error('Error pulling data from Salesforce', error);
      handleErrorPull();
    }
  }, [handleSuccessPull, id, syncSalesforceToBlueprint]);

  /**
   * onClick handler for action menu items that open in a new tab
   * @param path void
   */
  const onClickItemNewTab = (path: string): void => {
    window.open(`${path}`, '_blank', 'noopener,noreferrer');
  };

  const renderAnchor = (props: any, { isOpen }: { isOpen: boolean }): JSX.Element => (
    <Button className={className} {...props} variant='tertiary' size='large' endIcon={isOpen ? <CaretUpIcon /> : <CaretDownIcon />}>
			Salesforce
    </Button>
  );

  const onClickPushData = useCallback((): void => {
    setOpenSyncModal();
    refetchBlueprintDataToSync();
  }, [refetchBlueprintDataToSync, setOpenSyncModal]);

  /**
   * Action menu options for syncing data to/from Salesforce
   *
   * @returns ActionMenu Items for syncing data to/from Salesforce
   */
  const syncActionOptions = useMemo((): JSX.Element => {
    return (
      <>
        <ActionMenu.Item
          disabled={!salesforce_is_primary}
          endIcon={isPullingFromSalesforce ? <ReloadIcon /> : <ArrowLeftIcon />}
          onClick={handleSyncWithSalesforce}
        >
          Pull data from Salesforce
        </ActionMenu.Item>
        <ActionMenu.Item
          disabled={!salesforce_is_primary || !sync_to_salesforce}
          endIcon={isPullingFromSalesforce ? <ReloadIcon /> : <ArrowRightIcon />}
          onClick={onClickPushData}
        >
          Push data to Salesforce
        </ActionMenu.Item>
      </>
    );
  }, [handleSyncWithSalesforce, isPullingFromSalesforce, onClickPushData, salesforce_is_primary, sync_to_salesforce]);

  /**
   * Render the sync action options:
   * - If the blueprint is not set as the primary deal, render a tooltip explaining why the sync action options are disabled
   * - Otherwise, render the sync action options
   */
  const renderSyncAction = useCallback(() => {

    if (!salesforce_is_primary) {
      return (
        <Tooltip
          heading="Blueprint not set as Primary Deal"
          content="Blueprint must be the primary deal for this opportunity before you can refresh from Salesforce."
          placement="top"
        >
          {syncActionOptions}
        </Tooltip>
      );
    } else {
      return syncActionOptions;
    }
  }, [salesforce_is_primary, syncActionOptions]);

  const renderContent = ({ onClose }: { onClose: () => void }): JSX.Element => (
    <ActionMenu onItemClick={onClose}>
      {renderSyncAction()}
      <ActionMenu.Item
        endIcon={<NewWindowIcon />}
        disabled={!blueprint?.salesforce_opportunity_link}
        onClick={(): void => onClickItemNewTab(`${blueprint?.salesforce_opportunity_link}`)}
      >
        View Salesforce Opportunity
      </ActionMenu.Item>
    </ActionMenu>
  );

  return (
    <Dropdown placement="bottom-start" anchor={renderAnchor}>
      {renderContent}
    </Dropdown>
  );
};

export default memo(SalesforceMenu);
