/**
 * Create strategy
 */

import { Projection } from 'api/entityGraphQL';
import { Strategy } from 'features/entitiesRedux';
import { CreateStrategyArgs } from './strategyArgs';
import { baseApi, cleanArguments } from '..';

export type CreateStrategyMutation = {
  projection?: Projection;
} & CreateStrategyArgs;

export type CreateStrategyResult = Strategy;

export const CREATE_STRATEGY_ENDPOINT_KEY = 'createStrategy';

export const { useCreateStrategyMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [CREATE_STRATEGY_ENDPOINT_KEY]: build.mutation<CreateStrategyResult, CreateStrategyMutation>({
      transformResponse: (response: { createStrategy: Strategy }) => response.createStrategy,
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<CreateStrategyMutation> = {
          ...values
        };
        return {
          body: {
            mutation: {
              createStrategy: {
                __args: cleanArguments(__args),
                ...projection,
                id: true
              }
            }
          }
        };
      }
    })
  })
});
