/**
 * SelectBusinessType
 */

import React, { forwardRef } from 'react';
import { useGetBusinessTypesQuery } from 'api/crudGraphQL/business_types/getBusinessTypes';
import { Pillar } from 'features/entitiesRedux';
import Select, { Props as SelectProps } from '../Select/Select';

const SelectBusinessType = forwardRef(({ skeleton, ...props }: SelectProps, ref: any) => {
  const { data: business_types = [], isLoading } = useGetBusinessTypesQuery({
    projection: {
      name: true,
      id: true
    }
  });

  return (
    <Select
      showSearch
      skeleton={isLoading || skeleton}
      optionFilterProp="children"
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      {...props}
    >
      {business_types.map((business_type: Pillar) => (
        <Select.Option key={business_type.id} value={business_type.id}>
          {business_type.name}
        </Select.Option>
      ))}
    </Select>
  );
});

SelectBusinessType.displayName = 'SelectBusinessType';

export default SelectBusinessType;
