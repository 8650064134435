/**
 * BlockListItem -> Column
 */

import React, { FC } from 'react';
import classnames from 'classnames';
import css from './Column.module.scss';

export type Props = {
  alignLeft?: boolean;
  alignRight?: boolean;
  avatar?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  description?: React.ReactNode;
  ellipsis?: boolean;
  onTop?: boolean;
  value?: React.ReactNode;
};

const Column: FC<Props> = ({
  alignLeft,
  alignRight,
  avatar,
  children,
  className,
  description,
  ellipsis = true,
  onTop,
  value,
}) => {
  return (
    <div
      className={classnames(
        css.root,
        { [css.hasEllipsis]: ellipsis },
        { [css.onTop]: onTop },
        className
      )}
    >
      {avatar && <div className={css.avatar}>{avatar}</div>}
      <div className={css.content}>
        <div className={classnames(css.content__inner, {
          [css.alignLeft]: alignLeft,
          [css.alignRight]: alignRight
        })}>
          { children ? <div className={css.children}>{children}</div> : (
            <>
              <div className={css.value}>
                {value}
              </div>
              {description && (
                <div className={css.description}>
                  {description}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Column;
