import { Projection } from 'api/entityGraphQL/types';
import { NovaAIResponseType } from 'features/entitiesRedux/models/nova_ai';
import { baseApi, cleanArguments } from '..';

export type StartNovaAIPromptMutation = {
  client_id: number;
  question: string;
  debug?: string;
  projection?: Omit<Projection, 'id'>;
  prompt_guid?: string;
  session_guid?: string;
}

export type StartNovaAIPromptResult = NovaAIResponseType;

export const { useStartNovaAIPromptMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    startNovaAIPrompt: build.mutation<StartNovaAIPromptResult, StartNovaAIPromptMutation>({
      transformResponse: (response: { startNovaAIPrompt: StartNovaAIPromptResult }) =>  response.startNovaAIPrompt,
      query: ({ projection = {response: true}, ...values }) => {
        const __args: Partial<StartNovaAIPromptMutation> = values;
        return ({
          body: {
            mutation: {
              startNovaAIPrompt: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        }
        );
      }
    }),
  }),
});
