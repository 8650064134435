import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import loadable from '@loadable/component';
import { Action, Resource } from 'api/accessControl';
import { useGetDepartmentQuery } from 'api/crudGraphQL/departments/getDepartment';
import { useAccessControl } from 'features/global';
import { Skeleton } from 'components';
import projection from './projection';

const EditDepartmentPage = loadable(
  () => import('./EditDepartmentPage')
);

const Title: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { can } = useAccessControl();
  const canEditLibraryDepartmentPackage = can(Action.update, Resource.libraryDepartment);
  const { data: department, isLoading } = useGetDepartmentQuery({
    id: Number(id),
    projection
  });

  if (isLoading) {
    return <Skeleton title={{ width: 150 }} paragraph={false} active />;
  }

  if (!department || !canEditLibraryDepartmentPackage) {
    return null;
  }

  return <>{department.name}</>;
};

Title.displayName = 'EditDepartmentPageTitle';

export default Object.assign(EditDepartmentPage, { Title });
