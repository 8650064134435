import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin as AntdSpin } from 'antd';
import { SpinProps } from 'antd/lib/spin';
import classNames from 'classnames';
import css from './Spin.module.scss';

const LoadingIcon = ({ fontSize = 24, ...props }) => (
  <LoadingOutlined style={{ fontSize }} spin {...props} />
);

interface Props extends SpinProps {
  className?: string;
  fontSize?: number;
  loading?: boolean;
  fixedPosition?: boolean;
}
const Spin = ({ className, loading = true, fontSize = 16, fixedPosition, ...props}: Props): JSX.Element | null => {
  if (!loading) return null;

  return (
    <AntdSpin
      indicator={<LoadingIcon fontSize={fontSize} />}
      {...props}
      className={classNames(
        css.root,
        { [css.fixedPosition]: fixedPosition },
        className, 
      )}
    />
  );
};

export default Spin;
